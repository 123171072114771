import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { ModalComponent } from 'src/app/components/modal/modal.component';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent implements OnInit
{
	@ViewChild('download_survey') private download_survey!: ModalComponent;

	config = Config;
	leed_id = this.appData.get('leed_id');
	loading: boolean = false;
	myagreements: any = [];
	document_type: string = 'change_of_owner_agreement';
	uploading_agreement: boolean = false;
	upload_btn_text: string = 'Upload';
	uploaded_data: any = undefined;
	uploadName: any = undefined;
	delete_obj: any = undefined;
	deleting_text: string = 'Yes';
	other_legal_documents: any = [];
	other_doc_categories: any = {
		"change_of_owner_agreement": "Change of owner agreement",
		"agent_authority_agreement": "Confirmation of agent's authority",
		"primary_owner_agreement": "Confirmation of primary owner's authority",
		"amaendments_to_cert_service_agreements": "Amendments to cert/service agreements",
	};

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private alertService: alertService
	)
	{
		
	}

	ngOnInit(): void
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/documents/'
		).subscribe(
			data =>
			{
				this.getOtherFiles();
				this.myagreements = data.EtFile;
			},
			error =>
			{
				this.getOtherFiles();
			}
		);
		
	}

	getOtherFiles(){
		this.loading = true;
		let url_arr = [
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=change_of_owner_agreement',
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=agent_authority_agreement',
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=primary_owner_agreement',
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=amaendments_to_cert_service_agreements',
		];
		this.api.async(url_arr).subscribe(data =>
		{
			this.other_legal_documents = [];
			for (let index = 0; index < data[0].length; index++) {
				this.other_legal_documents.push(data[0][index]);
				
			}
			for (let index = 0; index < data[1].length; index++) {
				this.other_legal_documents.push(data[1][index]);
				
			}
			for (let index = 0; index < data[2].length; index++) {
				this.other_legal_documents.push(data[2][index]);
				
			}
			for (let index = 0; index < data[3].length; index++) {
				this.other_legal_documents.push(data[3][index]);
				
			}
			if (this.other_legal_documents.length){
				this.other_legal_documents.sort(this.global.sortList('-uploaded_at'));
			}
			this.loading = false;
		},
		error =>
		{
			this.loading = false;
		});
	}

	openDeleteModal(obj: any){
		this.delete_obj = obj;
		$('#agreement_delete_confirmation').modal('show');
	}

	deleteFile() {
		this.loading = true;
		this.deleting_text = 'Deleting...';
        var putData = {
            doc_id: this.delete_obj.id
        };

        this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
            data => {
				this.loading = false;
				this.deleting_text = 'Yes';
				$('#agreement_delete_confirmation').modal('hide');
				this.getOtherFiles();
            }, error => {
				this.deleting_text = 'Yes';
                this.alertService.alert('error', 'Something went wrong. Try again.', 5);
            }
        );
    }

	downloadAgreement(agreements: any)
	{
		this.global.anchorDownload("Agreement.pdf", this.config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/documents/download/?Docfile=Agreement.pdf&IDocNumber="+ agreements.Documentnumber +"&IDocPart="+ agreements.Documentpart +"&IDocType="+ agreements.Documenttype +"&IDocVersion="+ agreements.Documentversion +"&IFileApplicationId="+ agreements.ApplicationId +"&IFileId="+ agreements.FileId +"&subscription-key="+ this.config.sub_key +"&access-token=" + this.appData.get('token'));
	}

	nameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		var data;
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			data.append('action_file', $(elem)[0].files[a]);
			this.uploaded_data = data;
		}
		this.uploadName = filePath;
	};

	uploadDocument() {
		if(this.uploaded_data == undefined)
		{
			return;
		}
        (<any>$('.uploadFile')[0]).value = "";
        this.uploading_agreement = true;
		this.upload_btn_text = "Uploading...";
        var custom_basic_config_header = {
            headers:
            {
                "responseType": "arraybuffer",
                "Ocp-Apim-Subscription-Key": this.config.sub_key,
                "Authorization": "Bearer " + this.appData.get('token')
            }
        };
        this.uploaded_data.append('upload_category', this.document_type);
        this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/uploadS3/?upload_category=' + this.document_type, this.uploaded_data, custom_basic_config_header).subscribe(
            data => {
                $('#other_legal_doc').modal('hide');
				this.uploading_agreement = false;
				this.upload_btn_text = "Upload";
                this.alertService.alert('success', 'File successfully uploaded.', 5);
				this.getOtherFiles();
            }, error => {
                this.uploading_agreement = false;
				this.upload_btn_text = "Upload";
				this.alertService.alert('error', 'Error uploading file. Please try again.', 5);
            }
        );
    };

	resetSelection(){
		this.uploadName = undefined;
		this.uploaded_data = undefined;
		this.uploading_agreement = false;
		this.upload_btn_text = "Upload";
	}

	openModal(class_name: string)
	{
		this.resetSelection();
		$('#' + class_name).modal('show');
	}

	openDownload()
	{
		this.download_survey.open({ windowClass: 'certificate_modal', size: 'lg' });
	}

	closeSurvey(type: string)
	{
		switch(type)
		{ 
			case 'download_survey':
			{ 
				this.download_survey.close();
				break; 
			}
		} 
	}

	downloadSurvey(type: string)
	{
		switch(type)
		{ 
			case 'change_of_owner_agreement':
			{ 
				window.open('https://www.usgbc.org/resources/change-owner-agreement', '_blank');
				break;
			}
			case 'agent_authority_agreement':
			{ 
				window.open('https://www.usgbc.org/resources/agent-authority', '_blank');
				break; 
			}
			case 'primary_owner_agreement':
			{ 
				window.open('https://www.usgbc.org/resources/confirmation-primary-owners-authority', '_blank');
				break; 
			}
		} 
	}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit
{
	config = Config;
	@Output() success_data = new EventEmitter<any>();
	@Output() close_data = new EventEmitter<any>();
	
	formValidation: any = {};
	regex = this.validator.regex;

	error_msg_org: string = '';
	orgNameError: boolean = false;
	creating_org: boolean = false;
	error_org: boolean = false;
	orgContactNameError: boolean = false;
	orgContactEmailError: boolean = false;
	orgWebSiteError: boolean = false;
	orgSubCategoryError: boolean = false;
	orgCategory: string = '2';
	orgSubCategory: string = '27';
	isFormSubmittedOwner: boolean = false;
	orgContactEmailErrorLength: boolean = false;

	orgName: string = '';
	orgContactName: string = '';
	orgContactEmail: string = '';
	orgWebSite: string = '';

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{

	}

	validateOrgValues(type: string)
	{
		if(type == 'orgName')
		{
			if(this.isFormSubmittedOwner && (this.orgName == undefined || this.orgName.length == 0))
			{
				this.orgNameError = true;
			}
			else
			{
				this.orgNameError = false;
			}
		}
		
		if(type == 'orgContactEmail')
		{
			if(this.isFormSubmittedOwner && (this.regex.email.test(this.orgContactEmail) == false))
			{
				this.orgContactEmailError = true;
			}
			else
			{
				this.orgContactEmailError = false;
			}
		}
		
		if(type == 'orgContactName')
		{
			if(this.isFormSubmittedOwner && (this.orgContactName == undefined || this.orgContactName.length == 0))
			{
				this.orgContactNameError = true;
			}
			else
			{
				this.orgContactNameError = false;
			}
		}
		
		if(type == 'orgWebSite')
		{
			if(this.isFormSubmittedOwner && (this.orgWebSite.length > 0) && (this.regex.weburl.test(this.orgWebSite) == false))
			{
				this.orgWebSiteError = true;
			}
			else
			{
				this.orgWebSiteError = false;
			}
		}
	};

	resetSubCat(elem: any)
	{
		this.orgSubCategory = elem.orgCategory;
	}

	createOrganization()
	{
		this.creating_org = true;
		this.isFormSubmittedOwner = true;
		if(this.isFormSubmittedOwner && (this.orgName == undefined || this.orgName.length == 0))
		{
			this.orgNameError = true;
		}
		else
		{
			this.orgNameError = false;
		}
		if(this.isFormSubmittedOwner && (this.regex.email.test(this.orgContactEmail) == false))
		{
			this.orgContactEmailError = true;
		}
		else
		{
			this.orgContactEmailError = false;
		}
		if(this.isFormSubmittedOwner && (this.orgContactName == undefined || this.orgContactName.length == 0))
		{
			this.orgContactNameError = true;
		}
		else
		{
			this.orgContactNameError = false;
		}
		if(this.isFormSubmittedOwner && (this.orgSubCategory == undefined || this.orgSubCategory.length == 0))
		{
			this.orgSubCategoryError = true;
		}
		else
		{
			this.orgSubCategoryError = false;
		}
		
		if(this.isFormSubmittedOwner && (this.orgWebSite.length > 0) && (this.regex.weburl.test(this.orgWebSite) == false))
		{
			this.orgWebSiteError = true;
		}
		else
		{
			this.orgWebSiteError = false;
		}
		
		if(this.orgNameError == true || this.orgSubCategoryError == true || this.orgContactEmailError == true || this.orgContactNameError == true || this.orgContactEmailErrorLength == true || this.orgWebSiteError == true)
		{
			this.creating_org = false;
			return 
		}
		
		var postData = 
		{
			orgName:  this.orgName, 
			orgContactName:  this.orgContactName, 
			orgContactEmail: this.orgContactEmail,
			orgWebSite: this.orgWebSite,
			orgCategory: this.orgCategory,
			orgSubCategory: this.orgSubCategory
		};
		
		this.api.post('/assets/searchowner/', postData).subscribe(
			//success handler
			data =>
			{
				this.isFormSubmittedOwner = false;
				this.creating_org = false;
				if(data['record_duplicate'] != undefined)
				{
					this.error_org = true;
					this.error_msg_org = data['record_duplicate'];
					return
				}
				$('#add_owner_modal').modal('hide'); 
				this.error_org = false;
				this.error_msg_org = '';

				this.success_data.emit({
					"formdata": this.orgName,
					"org_query": this.orgName,
					"isUpdateOrgModal": true,
					"isOrganizationBlocked": false
				});
			},
			//error handler
			error =>
			{
				this.creating_org = false;
				$('#add_owner_modal').modal('hide');
			}
		);
	}

	closeModal()
	{
		this.close_data.emit();
	}

}

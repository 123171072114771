<!doctype html>
<title>Arc</title>
<style type="text/css">
  body {
      background: #EEE;
      margin: 0;
      padding: 0;
      }

      a {
      color: #7C7C7C
      }

      h1 {
      width: 300px;
      height: 190px;
      background: url('assets/images/maintenance/logo.gif') 50% 50% no-repeat;
      text-indent: -9999px;
      margin: 30px auto ;
      }

      .container {
      width: 820px;
      margin: 0 auto;
      background: #fff;
      border: 1px solid #ccc;
      -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
      -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
      box-shadow: 0 1px 2px rgba(0,0,0,.1);
      -moz-border-radius: 5px;
      border-radius: 5px;
      }

      .container h2, .container h3, .container p {
      text-align: center;
      }

      .container h2 {
      font-size: 38px;
      color: #333;
      margin: 50px 0 30px;
      }

      .container p {
      font-size: 19px;
      color: #888;
      margin-bottom: 50px;
      }

      .container .lower {
      border-top: 1px solid #ddd;
      *zoom: 1; }

      .container .lower:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      }

      .container .lower h3 {
      font-size: 14px;
      color: #888;
      background: white;
      width: 130px;
      margin: 0 auto;
      margin-top: -10px;
      font-weight: normal;
      }

      .container .lower .contacts {
      margin: 0 0 0 230px;
      padding: 25px 0 30px;
      overflow: hidden;
      *zoom: 1; }

      .container .lower span {
      font-size: 16px;
      color: #7C7C7C;
      display: block;
      float: left;
      margin-right: 40px;
      padding: 5px 0 5px 22px;
      background-position: left center;
      background-repeat: no-repeat;
      }

      .container .lower .phone {
      background-image: url('assets/images/maintenance/phone.gif');
      }

      .container .lower .email {
      background-image: url('assets/images/maintenance/email.gif');
      padding-left: 26px;
      }

      .copyright {
      text-align: center;
      font-size: 12px;
      color: #999;
      }
</style>

<h1>U.S. Green Building Council</h1>
<div class="container">
    <h2>We will be back soon!</h2>
    <p>
        We are performing maintenance and will be back online shortly
            <br/>
        <br/>
        <br/>Thank you for your patience.
        
    </p>
    <div class="lower">
        <h3>Need assistance?</h3>
        <div class="contacts">
            <span class="phone">1-800-795-1747</span>
            <span class="email">
                <a href="mailto:contact@arcskoru.com">contact@arcskoru.com</a>
            </span>
        </div>
    </div>
</div>
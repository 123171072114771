import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { API } from 'src/app/services/api.service';
import { ArcAuthService } from 'src/app/services/auth.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit
{
	title: string = '';
	loading: boolean = false;
	leed_id: number = this.global.getURLFromParam('leed_id');
	session_id: string = this.global.getURLFromParam('session_id');

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private auth: ArcAuthService
	)
	{

	}

	closeSuccess()
	{
		if(this.global.getURLFromParam('payment_type') == 'arc_pro')
		{
			this.loading = true;

			this.api.get('/auth/usgbc/userprofile/').subscribe(
				data =>
				{
					this.appData.set('is_admin', data.is_admin);
					this.appData.set('user_role', data.user_role);
					this.appData.set('profileimg', data.profileimg);
					this.appData.set('user_guid', data.guid);
					this.appData.set('gsa_admin', data.gsa_admin);

					this.appData.set('user_firstname', data.firstname);
					this.appData.set('user_lastname', data.lastname);
					this.appData.set('username', data.username);
					this.appData.set('user_id', data.username);

					this.auth.getAuth().subscribe(
						//success handler
						data =>
						{
							if(data.ItRoles[0].AgrName == "Z_LEEDV3_GBCIADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_USGBCADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_GBCICBADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_CBADMIN")
								this.appData.set('is_user_gbci_usgbc_admin', true);
							else
								this.appData.set('is_user_gbci_usgbc_admin', false);
						}, 
						//error handler
						error =>{
						}
					);

					if (this.appData.get('user_firstname') && this.appData.get('user_firstname') != "")
					{
						if (this.appData.get('user_lastname') && this.appData.get('user_lastname') != "")
						{
							this.appData.set('username', this.appData.get('user_firstname') + " " + this.appData.get('user_lastname'));
						}
						else
						{
							this.appData.set('username', this.appData.get('user_firstname'));
						}
					}
					else if (this.appData.get('user_id') && this.appData.get('user_id') != "")
					{
						this.appData.set('username', this.appData.get('user_id').split("@")[0]);
					}

					this.loading = false;
					this.global.goToApp('user-billing');
				}
			);
		}
		else if(this.global.getURLFromParam('payment_type') == 'climate_risk_report')
		{
			this.loading = true;
			this.global.searchMoveToProject({
				"leed_id": this.leed_id
			}, null, 'climate-risk');
		}
		else
		{
			this.loading = true;
			this.global.searchMoveToProject({
				"leed_id": this.leed_id
			}, null, 'performance-certificates');
		}
	};

	settleArcCertificate()
	{
		this.loading = true;
		this.api.get('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/'+ this.session_id +'/').subscribe(
			data =>
			{
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	};

	settleArcPro()
	{
		this.loading = true;
		this.api.get('/auth/user/subscriptions/'+ this.session_id +'/').subscribe(
			data =>
			{
				this.loading = false;
			},
			error =>
			{

			}
		);
	};

	ngOnInit(): void
	{
		if(this.global.getURLFromParam('payment_type') == 'arc_performance_certificate')
		{
			this.title = 'Arc Performance Certificate Subscription';
			this.settleArcCertificate();
		}
		else if(this.global.getURLFromParam('payment_type') == 'arc_pro')
		{
			this.title = Config.arc_pro_label + ' Susbcription';
			this.settleArcPro();
		}
	}

}

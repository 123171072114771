<div [ngClass]="uploading_excel || loading ? 'not-active' : ''" class="p1632 semi-md project_creation_modal">
    <app-loader *ngIf="loading"></app-loader>
    <div class="left-right-align mb15 pb16">
        <div class="fs24 line_height_40 fw-500">
            <span class="spreadsheet_icon mr10"></span>
            <span translate>Add multiple projects</span>
        </div> 
    </div>
    
    <div class="align_lr">
        <div class="left_div">
            <p class="fw600" translate>Add project data with an .XLS file</p>
            <div>
                <input #elem (input)="uploadProjectExcel(elem)" class="hide uploadProjectExcelFile" type="file">
                <span *ngIf="projectExcelName == null" class="mr10" translate>Select File</span>
                <span style="display: block;" class="projectExcelName" *ngIf="projectExcelName != null" title="{{projectExcelName}}">{{projectExcelName}}</span>
                <button [ngClass]="config.stream_upload ? 'not-active' : ''" (click)="selectProjectExcel()" class="upload_button btn btn-default btn-md mt10">
                    <i class="fa fa-upload mr10"></i><span translate>Browse</span>
                </button>
            </div>
        </div>
        <div style="border-right: 1px solid #D8DCDE;"></div>
        <div class="right_div">
            <p class="fw600" translate>Need a Spreadsheet Template?</p>
            <a (click)="downloadExcel()" translate>Download a template</a>
            <p class="mt10">{{last_updated}}</p>
        </div>
    </div>

    <div class="pt16"> 
        <div>
            <span class="fw600">Note:</span> With a recent update, Microsoft Excel blocks macros when a macro-enabled file is opened for the first time. You will have to enable macros before using this file. Please follow the below steps to unblock it.</div>
        <div>1. Open File Explorer and go to the folder where you saved the file.</div>
        <div>2. Right-click the file and choose Properties from the context menu.</div>
        <div>3. At the bottom of the General tab, select the Unblock checkbox and select OK.</div> 
    </div>

    <div [hidden]="config.stream_upload || config.show_refresh" class="pt16 mt20">
        <div class="checkbox pl20">
            <input id="sign_agreement_project" style="width: initial;" [(ngModel)]="sign_agreement_project" type="checkbox" value="confidential">
            <label for="checkbox" translate>
                I agree to the terms and conditions of the <span (click)='global.openLink(global.getServiceAgreementUrl(), true)' class='agreement_tag'>service agreement</span>
            </label>
        </div>
    </div>
    
    <div>
        <p *ngIf="config.stream_upload" class="fw600 mt20 mb20">Please wait while your projects are still being created. You will be notified via an email.</p>
        <p *ngIf="config.show_refresh" class="fw600 mt20 mb20">Your projects have been created. Click <a (click)="global.triggerReload()">here</a> to refresh.</p>
        <button [hidden]="config.stream_upload || config.show_refresh" [ngClass]="config.stream_upload || !sign_agreement_project ? 'not-active' : ''" (click)="startUpload()" type="button" class="btn btn-primary btn-md mr25">{{excel_upload_status}}</button>
        <button (click)="sign_agreement_project = false;resetExcelUpload();cancel()"  data-dismiss="modal" type="button" class="btn btn-default btn-md">
            {{config.stream_upload || config.show_refresh ? ('Close') : ('Cancel')}}
        </button>
    </div>
</div>
<div *ngIf="app == 'arc'" class="relative-position">
	<div class="backdrop_opaque"></div>
	<div class="materialSpinner--wrapper {{type}}">
		<svg class="materialSpinner-svg" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="circle" fill="none" stroke="#555555" stroke-width="3" stroke-linecap="round" cx="33" cy="33" r="30">
			</circle>
		</svg>
	</div>
</div>

<div *ngIf="app == 'hub'" class="progress-materializecss">
	<div class="indeterminate"></div>
</div>
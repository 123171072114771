import { Component, NgZone } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AppData } from './services/appdata.service';
import { Global } from './services/global.service';
import { Config } from './models/env-vars.model';
import { InsightService } from './services/insight.service';
import { alertService } from './services/alert-service.service';

import * as $ from 'jquery';
import * as FS from '@fullstory/browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent
{
	title = 'arc-app';
	first_app: string = 'app/home/overview';
	config = Config;
	navigator = window.navigator;

	constructor(
		private router: Router,
		public appData: AppData,
		public global: Global,
		private _ngZone: NgZone,
		private route: ActivatedRoute,
		public insight: InsightService,
		private alertService: alertService
	)
	{
		FS.init(
			{
				"orgId": '222SZ',
				"debug": false,
				"host": 'fullstory.com',
				"script": 'edge.fullstory.com/s/fs.js',
				"namespace": "FS"
			}
		);

		this.router.events.forEach((event) =>
		{
			if(event instanceof NavigationStart)
			{
				Config.hide_sidebar = false;
				Config.hide_navbar = false;
				if(this.appData.get('current_app') != 'selected-admin-app' && (this.appData.get('current_app') == 'login' || event.url == '/login' || event.url.indexOf("app/") == -1))
				{
					Config.show_navbar = false;
				}
				else
				{
					Config.show_navbar = (event.url == '/login' || event.url == '/admin' || event.url == '/cagbc-admin' || event.url == '/arc-japan-admin') ? false : true;
				}
			}
		});

		window.onpopstate = (e) =>
		{
			if(this.appData.get('previous_current_app') == 'selected_project')
			{
				this.global.goToApp(this.appData.get('previous_current_app'),
				{
					"leed_id": this.appData.get('leed_id'),
					"app_name": this.appData.get('previous_app_name')
				});
			}
			else
			{
				this.global.goToApp(this.appData.get('previous_current_app'));
			}
		};

		window.onload = (e: any) =>
		{
			for(let i in this.router.config)
			{
				if(this.router.config[i].path == e.currentTarget.location.pathname.replace("/app", "app"))
				{
					this.appData.set('app_name', (<any>this.router.config[i]).data.app);
					this.appData.set('current_app', (<any>this.router.config[i]).data.app);
					this.appData.set('current_nav', e.currentTarget.location.pathname.replace("/app", "app"));
				}
			}
		};

		$(() =>
		{
            window.addEventListener('message', (e: any) =>
			{
				if(e.data['trigger'] == 'adjust-height' || e.data['trigger'] == 'adjust-height-no-scroll')
                {
                    let height = e.data['value']
					let iFrameID = <any>document.querySelector("#datainput-widget iframe");
					if(parseInt(height) < 700)
					{
						height = "700";
					}
					if(iFrameID) 
					{
						$(iFrameID).attr("style", "border:none;")
						iFrameID.height = String(height) + "px";
						iFrameID.width = "100%";
					}   
                }
				else if (e.data['trigger'] == 'slider-adjust-height')
				{
                    var iFrameID = <any>document.querySelector("#" + e.data['div_id'] + " iframe");
                    var new_height = e.data['value'] + e.data['extra_height'];
                    if (e.data['div_id'] == 'transportation-slider-widget')
					{
                        new_height += 100;
                    }
                    else if ((e.data['div_id']).includes('table-widget'))
					{
                        new_height += 50;
                    }
                    iFrameID.height = String(new_height) + "px";
                    iFrameID.width = "100%";

                }
				if(e.data['trigger'] == 'app.project_performance')
                {
                    $('.tab_item.' + e.data['value']).click();
                    var body = $("html, body");
                    body.stop().animate({scrollTop:0}, 500, 'swing', function() {});
                }
				else if(e.data['trigger'] == 'adjust-height-notifications-all')
                {
                    $('#arcnotification_all iframe').height(e.data['value']);
                }
				else if(e.data['trigger'] == 'data_input_with_action')
				{
                    this.global.goToApp("selected_project", 
					{
						"leed_id": this.appData.get('leed_id'),
						"app_name": 'data-input',
						'type': e.data['type'],
						'action': e.data['value']
					});
					this.global.refreshCycle();
                }
				else if(e.data['trigger'] == 'action')
                {
                    if(e.data['value'] == 'open_apps')
                    {
                        this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": 'apps'
						});
						this.global.refreshCycle();
                    }
                    else if(e.data['value'] == 'open_certification')
                    {
						this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": 'certifications'
						});
						this.global.refreshCycle();
                    }
                    else if(e.data['value'] == 'open_performance')
                    {
                        this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": 'project-performance'
						});
						this.global.refreshCycle();
                    }
                    else
                    {
                        this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": 'data-input',
							'type': 'all',
							'action': e.data['value']
						});
						this.global.refreshCycle();
                    }
                }
				else if(e.data['trigger'] == 'open sidebar')
                {
                    if(e.data['value'] == 'arc_pro')
                    {
                        this.global.learnMore(e.data['value']);
                    }
                    else if(e.data['value'] == 're_entry' || e.data['value'] == 'ptz')
                    {
                        this.global.goToApp('projects', {'project-type':'building'});
                    }
                    else if(e.data['value'] == 'certificate')
                    {
                        this.global.learnMore(e.data['value']);
                    }
                    else if(e.data['value'] == 'Energy Star Portfolio Manager')
                    {
                        this.global.openEnergyStar();
                    }
                    else if(e.data['value'] == 'project_ptz')
                    {
                        this.global.goToApp('selected_project', 
						{
							"app_name": 'play-to-zero',
							'leed_id': this.appData.get('leed_id')
						})
                    }
                    else
                    {
                        this.global.openHelpSidebar(e.data['value']);
                    }
					this.global.refreshCycle();
                }
				else if(e.data['value'] == 'openFM')
                {
                    this.global.goToApp('selected_project', 
					{
						"app_name": 're-entry',
						'leed_id': this.appData.get('leed_id')
					});
					this.global.refreshCycle();
                }
                else if(e.data['value'] == 'openOE')
                {
                    this.global.goToApp('selected_project', 
					{
						"app_name": 're-entry',
						'leed_id': this.appData.get('leed_id')
					});
					this.global.refreshCycle();
                }
				else if(e.data['trigger'] == 'scroll-top')
                {
                    var body = $("html, body");
                    body.stop().animate({scrollTop:0}, 500, 'swing', function() {});
                }
				else if(e.data['trigger'] == 'new_dates')
                {
                    // let buildingData = this.appData.get('buildingData');
					// buildingData.performance_end_date = e.data["value"];
					// this.appData.set('buildingData', buildingData);
                    // // this.global.initRanges(e.data["value"]);
					// this.global.refreshCycle();
                }
				else if(e.data['trigger'] == 'height')
				{
					let iframes = document.getElementsByTagName('iframe');
					for(let i = 0; i < iframes.length; i++)
					{
						if(iframes[i].contentWindow == e.source)
						{
							iframes[i].height = e.data['value'];
							break;
						}
					}
				}
				else if(e.data['trigger'] == 'phone home')
                {
                    if(e.data['value'] == 'trial')
                    {
                        this.global.fullAccessModal();
                    }
                    else
                    {
                        this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": 'apps'
						});
                    }
					this.global.refreshCycle();
				}
				else if(e.data['trigger'] == 'alertService')
                {
                    this.alertService.alert(e.data["value"]["type"], e.data["value"]["message"], e.data["value"]["time"]);
                }
				else if(e.data['trigger'] == 'weather_normalized') {
					let frames: any = document.querySelectorAll('.weather_normalized_reload iframe');
					for(let i = 0; i < frames.length; i++) {
						frames[i].src = frames[i].src;
					}
				}
			});
		});

		global.initApp();
	}

	setOptionalStorage()
	{
		this.appData.setLocal("optional_logout", (<any>document.getElementById("optional_storage")).checked)
	}
}

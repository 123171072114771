<div class="content-wrap">
	<div id="content" class="content eco_dashboard" [ngClass]="loading ? 'not-active' : ''">
		<app-loader *ngIf="loading"></app-loader>
		<div>
			<h3 class="fw-500 black-color pl15 mb10 pt5">
				Arc Connected Services by EcoStruxure
			</h3>
			<div class="w100p manage_note pl15 open_invoice" *ngIf="open_invoice">
				You have an open invoice. Please pay the invoice to sync the data and scores between the EcoStruxure platform and to access the Arc Essentials and Arc Performance Certificate.
			</div>
		
			<div [hidden]="loading" *ngIf="app_integrated == false" class="pl15 mt20" [ngClass]="loading ? 'not-active' : ''">
				<div class="cert_banner_img col-md-12"></div>
				<div id="inactive_certificate" class="bg-white">
					<div class="pb24 border-bottom mb16">
						<div class="fs18 line_height_24 fw-500 mb16">Arc Connected Services by EcoStruxure</div>
						<div class="col-md-12 pl0" style="float: none;">
							<button (click)="appLogin()" class="btn btn-primary inverse fs16 h40 br2p">Get Started</button>
						</div>
					</div>
					<div>
						<p class="fs16 line_height_22 mb16">
							Receive access to Arc’s Performance Certificates and Arc Essentials tools. Performance Certificates provide recognition of your project’s performance in individual Arc categories. Arc Essentials gives you tools and features for deeper analysis and comparison of your performance for all of your projects.
						</p>
						<ul class="fs16 mb16">
							<li>Benchmark your project  against your portfolio, it’s peers or building performance standards and global frameworks</li>
							<li>Create what-if scenarios to model performance improvement</li>
							<li>Identify LEED Readiness for all of your projects</li>
							<li>Display an Arc Performance Score</li>
							<li>Display an Arc Improvement Score</li>
							<li>Display category-specific Key Performance Indicators</li>
						</ul>
					</div>
				</div>
			</div>
		
			<div *ngIf="app_integrated == true" class="pl15 mt20" [ngClass]="loading ? 'not-active' : ''">
				<div class="flex mb16 mt0 ml0">
					<div (click)="changeTab('My Projects')" [ngClass]="selected_tab == 'My Projects' ? 'active' : ''" class="tab_item">
						My Projects
					</div>
					<div (click)="changeTab('Add Projects')" [ngClass]="selected_tab == 'Add Projects' ? 'active' : ''" class="tab_item">
						Add Projects
					</div>
					<div (click)="changeTab('Past Invoices')" [ngClass]="selected_tab == 'Past Invoices' ? 'active' : ''" class="tab_item">
						Past Invoices
					</div>
				</div>

				<div *ngIf="selected_tab == 'Past Invoices'" id="project_selection_table" class="ml0 mr15 widget_mimic" style="display: block;">
					<table class="table table-striped arc-table">
						<thead>
							<tr>
								<th>Date Issued</th>
								<th>Order ID</th>
								<th>Total</th>
								<th>Invoice/Receipt</th>
							</tr>
						</thead>
						<tbody *ngIf="invoices.length != 0">
							<tr *ngFor="let data of invoices">
								<td>
									{{global.dateFormat(data.payment_date)}}
								</td>
								<td>
									{{data.sap_order_id}}
								</td>
								<td>
									${{global.addCommas(data.amount_paid)}}
								</td>
								<td (click)="downloadInvoice(data)">
									<a target="_blank">Download</a>
								</td>
							</tr>				    
						</tbody>
						<tbody *ngIf="invoices.length == 0">
							<tr>
								<td colspan="5" class="noProjects">
									No invoices found for your account.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<div *ngIf="selected_tab == 'My Projects'" id="project_selection_table" class="ml0 mr15 widget_mimic" style="display: block;">
					<div class="mt8 fs15 line_height_19 mb24">
						Showing: <span class="fw-500">{{page_size}} of {{count}} projects</span>
					</div>
					<table class="table table-striped arc-table">
						<thead>
							<tr>
								<th class="unselectable cursor-pointer">
									Name  
									<span *ngIf="sort_column == 'name'" class="fa fa-caret-{{sort_order == '-' ? 'down': 'up'}}"></span>
								</th>
								<th class="hidden-xs unselectable cursor-pointer">
									Location
									<span *ngIf="sort_column == 'city'" class="fa fa-caret-{{sort_order == '-' ? 'down': 'up'}}"></span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let project of myprojects; let idx = index;" class="project_row" [ngClass]="{'not-active' : (loading)}" id="{{project.uuid}}">
								<td class="vertical-align-middle cursor-pointer" 
								(click)="global.moveToProject(project, idx)">
									<div class="w90p pull-left ellipsis_v2">
										<span ng-mouseover="insertTippy($event)" class="fw600">{{project.name}}</span>
									</div>                               	
								</td>
								<td class="hidden-xs vertical-align-middle cursor-pointer"
								(click)="global.moveToProject(project, idx)">
									<div class="w90p pull-left ellipsis_v2">
										<span ng-mouseover="insertTippy($event)">{{project.street}}, {{project.city}}, {{getCountry(project.country)}}</span>
									</div>                              	
								</td>
							</tr>				    
						</tbody>
					</table>
					<div class="noProjects" *ngIf="myprojects.length == 0">
						Hello, Can't see your project? 
						<a (click)="global.openEmail('contact@arcskoru.com')">Email </a> us the name or address and we will get back to you.
					</div>
				
					<div class="align_r list_controls fw-500" *ngIf="myprojects.length > 0">
						<div class="dropdown display-inline ml10">
							<span class="mr10">Rows per page:</span>
							<button class="btn dropdown-toggle dropdown-clean btn-default btn-sm" type="button" data-toggle="dropdown" >
								<span>{{page_size}}</span>
								<i class="fa fontSize16 ml10 fa-caret-down"></i>
							</button>
							<ul class="dropdown-menu" style="top: -122px;">
								<li (click)="getSyncedProjects(1, 10, sort_order + sort_column)">
									<a>10</a>
								</li>
								<li (click)="getSyncedProjects(1, 20, sort_order + sort_column)">
									<a>20</a>
								</li>
								<li (click)="getSyncedProjects(1, 30, sort_order + sort_column)">
									<a>30</a>
								</li>
								<li (click)="getSyncedProjects(1, 40, sort_order + sort_column)">
									<a>40</a>
								</li>
								<li (click)="getSyncedProjects(1, 50, sort_order + sort_column)">
									<a>50</a>
								</li>
							</ul>
						</div>
						<div class="flex pt2 ml40">
							<span class="mr30">
								Page {{page}} of {{page_count}}
							</span>
							<div class="flex">
								<div class="cursor-pointer" [ngClass]="(page - 1) == 0 ? 'not-active' : ''" (click)="getSyncedProjects(page - 1, page_size, (sort_order + sort_column))">
									<i class="mr40 fa fa-chevron-left"></i>
								</div>
								<div class="cursor-pointer" [ngClass]="page == page_count ? 'not-active' : ''" (click)="getSyncedProjects(page + 1, page_size, (sort_order + sort_column))">
									<i class="fa fa-chevron-right"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		

	</div>
</div>

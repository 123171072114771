import { Component, OnInit } from '@angular/core';

import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-user-data-masking',
  templateUrl: './user-data-masking.component.html',
  styleUrls: ['./user-data-masking.component.css']
})
export class UserDataMaskingComponent implements OnInit {
  regex: any = this.validator.regex;

  check_get_user: boolean = false;
  check_delete_user: boolean = false;
  get_loader: boolean = false;
  table_area: boolean = false;
  delete_loader: boolean = false;
  user_email_get: any = '';
  user_email_delete: any = '';
  userData: any = {};

  constructor(
    private api: API,
    private alertService: alertService,
    private validator: ValidationService
  ) { }

  validateEmail(email: any) {
    if (this.regex["email"].test(email)) {
      return true;
    }
    else {
      return false;
    }
  };

  checkGetUser() {
      if (this.validateEmail(this.user_email_get)) {
        this.check_get_user = true;
      }
      else {
        this.check_get_user = false;
      }
    };

  checkDeleteUser() {
      if (this.validateEmail(this.user_email_delete)) {
        this.check_delete_user = true;
      }
      else {
        this.check_delete_user = false;
      }
    };

  getModuleName(val: any) {
      if (val == 'user_profile') { return "User profile" }
      else if (val == 'agreements') { return "Registration/Addendum agreements" }
      else if (val == 'enduser_agr') { return "End user license agreements" }
      else if (val == 'assets') { return "Projects" }
      else if (val == 'cctoken') { return "Credit card token" }
      else if (val == 'payments') { return "Registration/Review payments" }
      else if (val == 'measurabl_subscription') { return "Measurabl subscription payments" }
      else if (val == 'ldp_subscriptions') { return "LDP subscription payments" }
      else if (val == 'other_review_payment') { return "Other review subscription" }
      else if (val == 'meters') { return "Meters" }
      else if (val == 'consumptions') { return "Meter readings" }
      else if (val == 'wastes') { return "Waste meter data" }
      else if (val == 'measures') { return "Measure data" }
      else if (val == 'unclaimed_schools') { return "Claimed school" }
      else if (val == 'documents') { return "Project/meter documents" }
      else if (val == 'notification_subscriptions') { return "Email notification" }
      else if (val == 'thirdparty_partners') { return "Third party app" }
      else if (val == 'portfolio_partners') { return "Portfolio partners" }
      else if (val == 'portfolios') { return "Portfolios" }
      else if (val == 'portfolio_team') { return "Portfolio team" }
      else if (val == 'portfolio_documents') { return "Portfolio document" }
      else if (val == 'activity') { return "User activity" }
      else if (val == 'fullstory') { return "Fullstory activity" }
      else if (val == 'gbig_favorites') { return "GBIG favorites" }
      else if (val == 'gbig_follows') { return "GBIG follows" }
      else if (val == 'gbig_collections') { return "GBIG collections" }
      else if (val == 'subscription_customer') { return "Arc Stripe subscription customer" }
      else if (val == 'subscribers') { return "Arc Essential subscribers" }
      else if (val == 'subscriptions') { return "Arc essential/performance certificate subscriptions" }
      else if (val == 'triconsumption') { return "Performance re-entry data" }
      else if (val == 'teammember') { return "Team member" }
      else if (val == 'leagueuser') { return "GSA league manager" }
      else if (val == 'essentildiscount') { return "Arc Essential discount" }
      else if (val == 'subscriptionpayment') { return "Arc subscriptions payment" }
    };

    searchUser() {
      this.get_loader = true;
      this.table_area = false;
      this.api.get('/auth/user/' + this.user_email_get + '/').subscribe(
        data => {
          this.table_area = true;
          this.userData = data;
          this.get_loader = false;
        },
        error => {
          this.get_loader = false;
          if (error.detail) {
            this.alertService.alert('error', error.detail, 5);
          }
          else {
            this.alertService.alert('error', 'Something went wrong.', 5);
          }
        }
      );
    };
  
    deletehUser() {
      this.delete_loader = true;
      this.api.delete('/auth/user/' + this.user_email_delete + '/', {} ).subscribe(
        data => {
          this.alertService.alert('success', data, 5);
          this.delete_loader = false;
        },
        error => {
          this.delete_loader = false;
          if (error.detail) {
            this.alertService.alert('error', error.detail, 5);
          }
          else {
            this.alertService.alert('error', 'Something went wrong.', 5);
          }
        }
      );
    }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { PortfoliosService } from 'src/app/services/portfolios.service';

import { Config } from 'src/app/models/env-vars.model';
import {} from 'googlemaps';
declare var MarkerClusterer: any;

@Component({
  selector: 'app-portfolio-overview',
  templateUrl: './portfolio-overview.component.html',
  styleUrls: ['./portfolio-overview.component.css']
})
export class PortfolioOverviewComponent implements OnInit
{
	config = Config;
	loading: boolean = false;
	map_loading: boolean = false;

	analytics_summary: any = {};
	user_preference: any = {};
	si_unit_label: string = "IP units (feet)";
    unit_type: string = "ip";
	annual_emission: number = 0;
	total_certified_area_sqft: number = 0;
	map_provider: any = [];
	portfolio_id: any = this.appData.get('portfolio_id');

	constructor(
		public global: Global,
		private api: API,
		private portfolios: PortfoliosService,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		this.getPreference();
        this.initMapData();
	}

	openPortfolioProjectsSideBar(type: string, event: any)
	{

	}

	changeUnit(unit: any)
	{
		this.unit_type = unit;
		if (unit == "si")
			this.si_unit_label = "SI units (meters)";
		else if (unit == "ip")
			this.si_unit_label = "IP units (feet)";

		this.getAnalyticsSummary(unit.toUpperCase());
	};

	getAnalyticsSummary(unit_type: string)
	{
		this.loading = true;
		this.portfolios.getAnalyticsSummary(this.portfolio_id, 'total', unit_type).subscribe(
			response =>
			{
				this.analytics_summary = response;
				this.total_certified_area_sqft = response.total_certified_area_sqft;
				this.annual_emission = response.total_emissions;
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	};

	drawMap(map_provider: any)
	{
		this.map_loading = false;
		var map_meta: any = { zoom: 4 };
		if (map_provider.length > 0)
		{
			map_meta["center"] = map_provider[0];
			map_meta["styles"] = this.config.map_styles;
		}
		var infoWin = new google.maps.InfoWindow();
		var bounds = new google.maps.LatLngBounds();
		var map = new google.maps.Map(<any>document.getElementById('portfolio_map'), map_meta);
		var markers = map_provider.map(function(location: any, i: any)
		{
			var marker = new google.maps.Marker(
			{
				position: location
			});
			bounds.extend(location)
			google.maps.event.addListener(marker, 'click', function(evt)
			{
				infoWin.setContent(location.name + " - " + String(parseInt(location.id)));
				infoWin.open(map, marker);
			})

			return marker;
		});
		var marker = new google.maps.Marker({ position: map_meta["center"], map: map });
		var markerCluster = new MarkerClusterer(map, markers,
		{
			imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
		});
	};

	initMapData()
	{
		this.map_loading = true;
		this.api.get('/portfolios/ID:' + this.portfolio_id + '/assets/geolocation/').subscribe(
			//success handler
			response =>
			{
				try
				{
					var data = response;
					for (var i = 0; i < data.length; i++)
					{
						if (data[i].geoLat != null && data[i].geoLang != null)
						{
							this.map_provider.push(
							{
								'name': data[i].name,
								'id': data[i].leed_id,
								'lat': parseFloat(data[i].geoLat),
								'lng': parseFloat(data[i].geoLang)
							});
						}
					}
					this.drawMap(this.map_provider);
				}
				catch(e)
				{
					this.map_loading = false;
				}
			},
			//error handler
			data =>
			{
				this.map_loading = false;
			}
		);
	};

	getPreference()
	{
		this.api.get('/auth/user/preference/').subscribe(
			//success handler
			response =>
			{
				if(response.length > 0)
				{
					this.user_preference = response[0];
					if(this.user_preference.gfa_unit == 'sqft')
					{
						this.si_unit_label = "IP units (feet)";
						this.unit_type = "ip";
					}
					else
					{
						this.si_unit_label = "SI units (meters)";
						this.unit_type = "si";
					}
				}
				else
				{
					this.si_unit_label = "IP units (feet)";
					this.unit_type = "ip";
				}
				this.getAnalyticsSummary(this.unit_type.toUpperCase());
			},
			//error handler
			response =>
			{
			}
		);
	};

}


<div class="content-wrap">
    <div id="content" class="content admin_tool_content">		
		<div class="admin_cancel_subscription_body">
			<h3 class="cancelSubscriptionWrapper">Cancel Subscription<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group col-md-12">
                                <label for="normal-field" class="control-label">Subscription type</label>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown" >
                                    <span class="pr5">{{subscription_value}}</span>
                                    <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeSubscriptionType('arc_essentials', 'Arc essentials')"  class="energy_unit"><a>Arc essentials</a></li>
                                        <li (click)="changeSubscriptionType('performance_certificate', 'Arc performance certificate')"  class="energy_unit"><a>Arc performance certificate</a></li>
                                    </ul>
                                </div>
                            </div>
    
                            <div class="form-group pt30">
                                <div class="col-md-12" class="form-group" [ngClass]="get_loader ? 'not-active' : ''">
                                    <div class="col-md-10" *ngIf="subscription_type == 'arc_essentials'">
                                        <input [(ngModel)]="user_email_get" name="user_email_get" type="email" class="form-control" placeholder="User email" (input)='checkInputs()'>
                                    </div>
                                     <div class="col-md-10" *ngIf="subscription_type == 'performance_certificate'">
                                        <input (input)='checkInputs()' [(ngModel)]="leed_id" name="leed_id" class="form-control" placeholder="Property ID or LEED ID">
                                    </div>
                                    <div class="wizard display-block">
                                        <button (click)="searchUser()" class="btn btn-default pull-left mr10" [ngClass]="check_get_user ? '' : 'not-active'"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                        <div *ngIf="get_loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div> 
                            </div>
                        </fieldset>
                    </form>
                    <table class="table table-striped mt30">
                        <thead>
                            <tr>
                                <th *ngIf="subscription_type == 'performance_certificate'">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</th>
                                <th *ngIf="subscription_type == 'arc_essentials'">User name</th>
                                <th>User email</th>
                                <th *ngIf="subscription_type == 'arc_essentials'">Number of subscribers</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="subs_canceled || get_loader? 'not-active' : ''" *ngIf="user_data.subscription_id">
                                <td *ngIf="subscription_type == 'performance_certificate'">{{leed_id}}</td>
                                <td *ngIf="subscription_type == 'arc_essentials'">{{user_data.first_name}} {{user_data.user_data}}</td>
                                <td>{{user_data.email}}</td>
                                <td *ngIf="subscription_type == 'arc_essentials'">{{user_data.subscribers.length}}</td>
                                <td>
                                    <a (click)="action('cancel')">Cancel</a> / 
                                    <a (click)="action('endtrial')">Endtrial</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</div>

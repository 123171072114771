import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-insight-search',
  templateUrl: './insight-search.component.html',
  styleUrls: ['./insight-search.component.css']
})
export class InsightSearchComponent implements OnInit
{
	project: any = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');
	searching_zip: boolean = false;
	@Output() search_complete = new EventEmitter<any>();

	zipcode_list: any = [];
	zipcode: any = ''
	climate_code: any = ''
	zipcode_input: any = '';
	range_in_mile: any = 1;
	canceler: any;
	range_in_mile_input: any = 1;
	country: any = '';
	project_types_input: any = [];
	country_list: any = [];
	range_list: any = [];
	project_types: any = [];
	project_reduction: any = {};
	project_type_list: any = ["Datacenter", "Educational Facilities", "Health Care", "Higher Ed", "Industrial Manufacturing", "K-12", "Laboratory", "Lodging", "Military Base", "Multi-Family Residential", "Neighborhood Development", "Office: Mixed Use", "Office", "Other", "Public Assembly", "Public Order and Safety", "Religious Worship", "Retail", "Service", "Single-Family Home", "Warehouse and Distribution"];

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		this.country = this.project.country;
		this.zipcode = this.project.street + ", " + this.project.city + ", " + this.project.state + ", " + this.project.country + ", " + this.project.zip_code;
		this.zipcode_input = this.project.street + ", " + this.project.city + ", " + this.project.state + ", " + this.project.country + ", " + this.project.zip_code;

		this.range_list = this.generateRange(100, 5);

		if(Config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				Config.countries = data;
				this.country_list = this.global.getCountryList();
				$('.dropdown').on('show.bs.dropdown', function() {
                    $(this).find('.dropdown-menu').first().stop(true, true).slideDown(200);
                });
        
                $('.dropdown').on('hide.bs.dropdown', function() {
                    $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
                });
			});
		}
		else
		{
			this.country_list = this.global.getCountryList();
			$('.dropdown').on('show.bs.dropdown', function() {
				$(this).find('.dropdown-menu').first().stop(true, true).slideDown(200);
			});
	
			$('.dropdown').on('hide.bs.dropdown', function() {
				$(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
			});
		}

		this.searchData(this.range_in_mile_input, this.zipcode_input, this.project_types_input);
	}

	generateRange(max: number, diff: number)
	{
		var range = [1];
		for(var i = 1; i <= max; i++)
		{    
			if(i % diff == 0)
			{
				range.push(i);
			}
		}
		return range;
	};

	searchZipCode(zipcode: any)
	{
		this.searching_zip = true;
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}

		this.canceler = this.insight.getZipList(zipcode).subscribe(
			data =>
			{
				this.searching_zip = false;
				this.zipcode_list = data;
				this.showDropdown();
			},
			error =>
			{
				this.searching_zip = false;
			}
		);
	}

	changeZipCode(zipcode: any)
	{
		this.zipcode_input = zipcode;
	}

	showDropdown()
	{
		if(this.zipcode_list.length > 0)
		{
			$('.dropdown.zipcode .dropdown-menu').slideDown(200);
		}
	}	

	hideDropdown()
	{
		$('.dropdown.zipcode .dropdown-menu').slideUp(200);
	}

	changeRange(range_in_mile: any)
	{
		this.range_in_mile_input = range_in_mile;
	};

	projectTypeChange()
	{
		this.disableSearch();
	}

	searchData(range_in_mile_input: any, zipcode_input: any, project_types_input: any)
	{
		this.search_complete.emit({
			"range_in_mile_input": range_in_mile_input,
			"zipcode_input": zipcode_input,
			"project_types_input": project_types_input
		});
	}

	exportInsight(range: any, zipcode: any)
	{
		Config.app_loader = true;
		var page = 'project';

		var url = '/overviewexport/?zip_code='+ String(zipcode) +'&range_in_mile=' + String(range) + "&timezone_offset=" + new Date().getTimezoneOffset() + "&export_type=" + page + "&leed_id="+ this.project.leed_id + "&version=" + this.version_id;

		if(this.project_types_input.length > 0)
		{
			url += "&project_type=" + this.insight.getProjectTypes(this.project_types_input);
		}
		
		this.insight.get(url).subscribe(response =>
		{
			Config.app_loader = false;

			this.global.anchorDownload("Insight Model Export "+ this.project.leed_id +".pdf", JSON.parse(response.data_export));
		},
		function(error)
		{
			Config.app_loader = false;
		});
	}

	disableSearch(): any
	{
		if(this.zipcode == this.zipcode_input && this.range_in_mile == this.range_in_mile_input && this.project_types_input.join(',').trim().replace(/ /g, "").replace(/\n/g, "") == this.project_types.join(',').trim().replace(/ /g, "").replace(/\n/g, ""))
		{
			return true;
		}  
	}

}

import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

@Component({
  selector: 'app-insight-manage',
  templateUrl: './insight-manage.component.html',
  styleUrls: ['./insight-manage.component.css']
})
export class InsightManageComponent implements OnInit
{
	saving: boolean = false;

	current_tab: string = "Model details";
	modal_current_version_name: string = "";

	project: any = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');
	project_reduction: any = {};

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		this.getReductionData();
	}

	cancel()
	{
		$('.save_as_modal').modal('hide');
	}

	getReductionData()
	{
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.selected_version.version_id).subscribe(
			//success handler
			response =>
			{
				this.project_reduction = response.reduction_data;
			},
			//error handler
			response =>
			{                   
			}
		);
	}

	saveScoreCard(saveAs: any, version_name: any, setLatest: any, version_id: any)
	{
		var payload = {
			'reduction_data': this.project_reduction
		};
		var url = '/assets/LEED:'+ this.project.leed_id +'/reduction/?saveAs='+ saveAs +'&version_name='+ version_name +'&setLatest='+ setLatest;
		if(version_id != undefined)
		{
			url += '&version_id=' + version_id;
		}
		
		this.saving = true;
		this.insight.post(url , payload).subscribe(
			response =>
			{
				this.saving = false;
				if(setLatest)
				{
					this.appData.get('selected_version').version_name = version_name;
					this.appData.get('selected_version').version_id = response.version;
					this.appData.get('buildingData').version = response.version;
				}
				if(saveAs)
				{
					this.selected_version = {};
					this.project_reduction = response.reduction_data;
					this.selected_version["version_id"] = response.version;
					this.selected_version["version_name"] = this.global.copy(version_name);

					this.cancel();
				}
			},
			error =>
			{
				this.saving = false;
			}
		);
	}

	openSaveAs()
	{
		$('.save_as_modal').modal('show');
	}

	toggleTab(tab: string)
	{
		this.current_tab = tab;
	}

}

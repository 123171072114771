import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';
import { AppData } from 'src/app/services/appdata.service';
import { alertService } from 'src/app/services/alert-service.service';
import { provideCloudinaryLoader } from '@angular/common';
import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.component.html',
  styleUrls: ['./order-update.component.css']
})
export class OrderUpdateComponent implements OnInit {

  config = Config;
  checkInput: boolean = false;
  checkInputReview: boolean = false;
  loader: boolean = false;
  loaderCert: boolean = false;
  leed_id: any = "";
  leed_id_review: any = "";
  payment_date: any = "";
  actual_amount: any = "";
  amount_paid: any = "";
  order_number: any = "";
  status: any = "";
  quantity: any = "";
  mypayments: any = [];
  showThis: boolean = false;
  save_text: string = 'Edit';
  saving: boolean = false;
  first_name: any = "";
  last_name: any = "";
  street: any = "";
  country: any = '';
  states: any = '';
  countries: any = [];
  delete: boolean = false;
  selected_row: any = undefined;
  projectType: string = 'assets/LEED:';

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService
  ) { }
  
  getOrders(){
    this.loader = false;
    this.showThis = true;

    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
      {
        this.projectType = 'assets/property:';
      }

    
      else if (this.leed_id > '4900000000' && this.leed_id < '6000000000')
      {
        this.projectType = 'project/';
      }
      else
      {
        this.projectType = 'assets/LEED:';
      }

    this.api.get('/' + this.projectType + this.leed_id + '/review/').subscribe(
      data => {
        if (data.review_payment.length != 0) {
            data.review_payment = data.review_payment;
            this.mypayments = data.review_payment;
        }
      },
      error => {
      }
    );
  };

  editOrder(index: any, curPayment:any)
	{
		this.showThis = false;
    this.selected_row = curPayment.id;
    console.log('The row selected is.....', this.selected_row);
    this.save_text = 'Save';
	}

  updateOrder(index: any, curPayment: any){

		this.showThis = true;
    var payment_id = curPayment.id;
    this.save_text = 'Save';
    var putData = {};
   
    if(curPayment.status == 'canceled'){

      this.delete = true;
      putData =
      {
        "payment_date": curPayment.payment_date,
        "actual_amount": curPayment.actual_amount,
        "amount_paid": curPayment.amount_paid,
        "status": curPayment.status,
        "order_number": curPayment.order_number,
        "quantity": curPayment.quantity,
        "delete": true
      };
    }
    else {
      putData =
      {
        "payment_date": curPayment.payment_date,
        "actual_amount": curPayment.actual_amount,
        "amount_paid": curPayment.amount_paid,
        "status": curPayment.status,
        "order_number": curPayment.order_number,
        "quantity": curPayment.quantity,
      };

    }

    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
      {
        this.projectType = 'assets/property:';
      }

    
      else if (this.leed_id > '4900000000' && this.leed_id < '6000000000')
      {
        this.projectType = 'project/';
      }
      else
      {
        this.projectType = 'assets/LEED:';
      }

    this.api.put('/' + this.projectType + this.leed_id + '/review/ID:' + payment_id + '/', putData ).subscribe(
      data => {
        this.save_text = 'Edit';
        this.showThis = false;
        this.selected_row = undefined;
        this.alertService.alert('success', 'Payment order updated successfully.', 5);
      },
      error => {
        this.alertService.alert('error', 'Please try again.', 5);
      }
    );

  };

  checkInputsReview(){
    if (isNaN(this.leed_id_review ) || String(this.leed_id_review).length == 0 ||  this.formdata.order_number == '' ||
      this.formdata.status == ''){
      this.checkInputReview = false;
    }
    else {
      this.checkInputReview = true;
    }
  };
  
  refreshForms ()
  {
    this.leed_id_review = '';
    this.formdata.first_name = '';
    this.formdata.last_name = '';
    this.formdata.email = '';
    this.formdata.phone = '';
    this.formdata.zip_code = '';
    this.formdata.city = '',
    this.formdata.street = '';
    this.formdata.order_number = '';
    this.formdata.status = '';
  }

  formdata: any = {
    'first_name': '',
    'last_name': '',
    'email': '',
    'street': '',
    'city': '',
    'state': 'DC',
    'country': 'US',
    'zip_code': "",
    'phone': "",
    'quantity': '',
    'payment_mode': '',
    "is_sez": false
};

  no_error = {
    'error': false,
    'help': undefined
  };

  formdata_error: any = {
    'first_name': this.no_error,
    'last_name': this.no_error,
    'order_number': this.no_error,
    'status':this.no_error,
    'email': this.no_error,
    'street': this.no_error,
    'city': this.no_error,
    'state':this.no_error,
    'phone': this.no_error,
    'zip_code': this.no_error
  };

  saveNewOrder (){

    var putData: any =
    {
      "order_number": this.formdata.order_number,
      "order_status": this.formdata.status,
      "first_name": this.formdata.first_name,
      "last_name": this.formdata.last_name,
      "street": this.formdata.street,
      "city": this.formdata.city,
      "state": this.formdata.state,
      "country": this.formdata.country,
      "zip_code": this.formdata.zip_code,
      "phone": this.formdata.phone,
      "email": this.formdata.email,
      "payment_mode": 'check'
    };

    if (this.leed_id_review > '3900000000' && this.leed_id_review < '5000000000' )
    {
      this.projectType = 'assets/property:';
    }
    else if (this.leed_id > '4900000000' && this.leed_id < '6000000000')
    {
      this.projectType = 'project/';
    }
    else
    {
      this.projectType = 'assets/LEED:';
    }


    this.api.post('/' + this.projectType + this.leed_id_review + '/review/payment/', putData).subscribe(
      data => {
        this.refreshForms();
        this.alertService.alert('success', 'Review order added', 5);
        this.loaderCert = false;

      }, error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  }

  initStates()
	{
		if(this.config.countries.divisions[this.formdata.country] == undefined)
		{
			this.states = this.global.getStateList('US');
		}
		else
		{
			this.states = this.global.getStateList(this.formdata.country);
		}
		
		if(this.formdata.country == null || this.formdata.country == undefined || this.formdata.country.length == 0)
		{
			this.formdata.country  = 'US';
		}
		if(this.formdata.state == null || this.formdata.state == undefined || this.formdata.state.length == 0)
		{
			this.formdata.state    = 'DC';
			this.formdata.country  = 'US';
		}
	}

  updateCountry(country: string)
	{
		this.states = this.global.getStateList(country);
		if(this.states.length > 0)
		{
			this.formdata['state'] = this.states[0].code;
		}
		else
		{
			this.states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.formdata['state'] = "";
		}
	}

	updateState(state: string)
	{
    
	}
  ngOnInit(): void {

    if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
        this.initStates();
        // this.states = this.global.getStateList('US');
			});
		}
		else
		{
			this.countries = this.global.getCountryList();
      this.initStates();
		}
  }

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { Projects } from '../../services/projects.service';

import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-performance',
  templateUrl: './project-performance.component.html',
  styleUrls: ['./project-performance.component.css']
})
export class ProjectPerformanceComponent implements OnInit
{
	loading: boolean = false;
	selected_tab: string = 'total';
	config = Config;
	arc_pro_label: string = Config.arc_pro_label;
	carbon: any = '0';
	sub_header_text: string = '';

	constructor(
		public global: Global,
		private project: Projects,
		public appData: AppData,
		private api: API
	)
	{}

	changeScoreType(score_type: string)
	{
		this.config.score_type = score_type;
	}

	getAnalysis()
	{
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/analysis/'
		).subscribe(
			data =>
			{
				let energy: any = 0, transit: any = 0;
				if("info_json" in data.energy && "Attributes for this project" in data.energy.info_json)
				{
					energy = data.energy.info_json["Attributes for this project"]["Raw GHG (mtco2e/day)"];
				}
				if("info_json" in data.transit && "Attributes for this project" in data.transit.info_json)
				{
					transit = data.transit.info_json["Attributes for this project"]["Annual Transit CO2e"];
				}
				this.carbon = this.global.addCommas(((this.convertAnalysisNumber(energy, 'per_day', 'project', 'annual', 'project', 4)) + this.convertAnalysisNumber(transit, 'annual', 'project', 'annual', 'project', 4)).toFixed(4))
			},
			error =>
			{

			}
		)
	}

	convertAnalysisNumber(value: any, value_duration: any, value_type: any, result_duratiom: any, result_type: any, decimals: any): any
	{
		var gross_area = isNaN(parseFloat(this.appData.get('buildingData').gross_area)) ? 0 : this.appData.get('buildingData').gross_area;
		var occupancy = isNaN(parseFloat(this.appData.get('buildingData').occupancy)) ? 0 : this.appData.get('buildingData').occupancy;

		if(this.appData.get('buildingData').unitType == 'SI'){
			gross_area *= 10.7639;
		}

		value = parseFloat(value);
		if(isNaN(value) || value == Infinity)
		{
			value = 0.0000;
		}
		else
		{
			value = parseFloat(value);
		}

		if(value_duration == 'per_day')
		{
			value *= 365;
		}
		if(value_duration == 'per_month')
		{
			value *= 12;
		}

		if(result_duratiom == 'per_month')
		{
			value /= 12;
		}
		if(result_duratiom == 'per_day')
		{
			value /= 365;
		}

		return isNaN(parseFloat(value.toFixed(decimals))) || value == Infinity ? '-' : parseFloat(value.toFixed(decimals));
	};

	changeTab(tab: string)
	{
		this.sub_header_text = '';
		this.loading = true;
		this.project.getAssetDetails(this.appData.get('leed_id')).subscribe(
			data =>
			{
				this.loading = false;
				if(!(this.appData.get('is_admin') && this.appData.get('EUserRole') != 'GBCI Reviewer'))
				{
					this.appData.set('buildingData', data);
					this.getSubHeaderText(tab);
				}
			}
		);
		window.scrollTo(0, 0);
		this.selected_tab = tab;
	};

	getSubHeaderText(tab: string)
	{
		this.sub_header_text = '';
		if (tab == 'energy' && (this.appData.get('buildingData').energy_score_calculation_factor == 'espm_ashrae' || this.appData.get('buildingData').energy_score_calculation_factor == 'ashrae')){
			this.sub_header_text = 'The project is using the ENERGY STAR and ASHRAE Standard 100 ACP';
		}
		else if (tab == 'water' && this.appData.get('buildingData').water_score_calculation_factor == 'percentage_reduction'){
			this.sub_header_text = 'The project is using the Calculated Indoor Water Use ACP';
		}
		return this.sub_header_text;
	};

	ngOnInit(): void
	{
		if(this.appData.get('project_type') == 'transit')
		{
			this.getAnalysis();
		}
		$(function()
		{
            function receiveMessage(e: any) 
            {
                if(e.origin != "https://api.usgbc.org")
				{
                    return;
                }

                if(e.data['trigger'] == 'app.project_performance')
                {
                    $('.tab_item.' + e.data['value']).click();
                }
				else if(e.data['trigger'] == 'app.city_performance')
                {
                    $('.tab_item.' + e.data['value']).click();
                }
            }
            window.addEventListener('message', receiveMessage);
        });

		if(String(this.appData.get('leed_id'))[0] == '1')
        {
            this.config.score_type = 'weighted';
        }
        else
        {
            this.config.score_type = 'score';
        }
	}

}

import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from 'src/app/services/global.service';
import { Config } from 'src/app/models/env-vars.model';
import { AppData } from 'src/app/services/appdata.service';

@Component({
  selector: 'app-arc-japan-invoice',
  templateUrl: './arc-japan-invoice.component.html',
  styleUrls: ['./arc-japan-invoice.component.css']
})
export class ArcJapanInvoiceComponent implements OnInit {
  query: any = null;
  requested_data: any = [];
  selected_invoice: any = {};
  loading: boolean = false;
  canceler: any;

  constructor(
    private api: API,
    private alertService: alertService,
    public global: Global,
    public appData: AppData
  ) { }

  exportInvoices() {
    this.loading = true;
    var url = Config.basic_api_url + '/subscriptions/invoices/export/';
    var xhttp: any = new XMLHttpRequest();
		xhttp.onreadystatechange = () =>
		{
			var a, today;
			if (xhttp.readyState === 4 && xhttp.status === 200)
			{
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(xhttp.data, "Invoice List.xlsx");
					this.loading = false;
				}
				else
				{
					this.loading = false;
					a = document.createElement('a');
					a.download = "Invoice List.xlsx";
					a.style.display = 'none';
					a.href = window.URL.createObjectURL(xhttp.response);
					document.body.appendChild(a);
					return a.click();
				}
			}
		};
		xhttp.open("GET", url, true);
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", Config.sub_key);
		xhttp.setRequestHeader("Authorization", "Bearer " + this.appData.get('token'));
		xhttp.responseType = 'blob';
		xhttp.send();
  };

  downloadFile(url: any) {
    window.open(url, '_blank');
  };

  selectPayment(invoice: any) {
    $('.clearpayment').modal('show');
    this.selected_invoice = invoice;
  };

  clearPayment() {
    var payload = {
      "sap_order_id": this.selected_invoice.sap_order_id
    };
    this.loading = true;
    this.api.post('/subscriptions/arcjapan/', payload ).subscribe(
      data => {
        this.loading = false;
        $('.clearpayment').modal('hide');
        this.searchInvoices('');
      }, error => {
        this.loading = false;
      }
    );
  };

  searchInvoices(query: any) {
    if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
    var url = '/subscriptions/arcjapan/';
    if (typeof (query) == 'string' && query.length > 0) {
      url += '?sap_order_id=' + query;
    }
    this.loading = true;
    this.canceler = this.api.get(url).subscribe(
      data => {
        this.loading = false;
        for (var i = 0; i < data.length; i++) {
          if (data[i].type == 'performance') {
            data[i].type = 'Arc Performance Certificate - <span class="fs13 gray_color_707479">' + data[i].object + '</span>';
          }
          else if (data[i].type == 'climaterisk') {
            data[i].type = 'Arc Climate Risk - <span class="fs13 gray_color_707479">' + data[i].object + '</span>';
          }
          else {
            data[i].type = 'Arc Essentials - <span class="fs13 gray_color_707479">' + data[i].object + '</span>';
          }
        }
        this.requested_data = data;
      },
      error => {
        this.loading = false;
      }
    );
  };

  ngOnInit(): void {
    this.searchInvoices('');
  }

}

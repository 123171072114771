<div [ngClass]="loading ? 'not-active' : ''" class="team_search" id="myproject_body">
    <app-loader *ngIf="loading"></app-loader>
    <div class="left-right-align pb15 custom_header">
        <div class="fs24 line_height_40 fw-500">
            Manage team members
        </div> 
    </div>

    <div class="custom_body">
        <ul class="meterNav mb15 flex">
            <li (click)="toggleTab('1. Select users')" class="w33p meterNav--item">
                <span class="meterNav--item__active fw-500">1. Select users</span>
            </li>
            <li (click)="toggleTab('2. Add/Remove user')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' || current_tab == '3. Select projects' || current_tab == '2. Add/Remove user' ? 'meterNav--item__active fw-500' : ''">2. Select action</span>
            </li>
            <li (click)="toggleTab('3. Select projects')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' || current_tab == '3. Select projects' ? 'meterNav--item__active fw-500' : ''">3. Select projects</span>
            </li>
            <li (click)="toggleTab('4. Summary')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' ? 'meterNav--item__active fw-500' : ''">4. Summary</span>
            </li>
        </ul>
        <div *ngIf="current_tab == '1. Select users'">
            <div class="w100p manage_note pl0 mb15">
                Search and select user(s) which you want to add or remove from projects
            </div>
            <div class="arc-pro-add-user">
                <div class="relative-position">
                    <input autocomplete="off" (focus)="isSearch(true)" (input)="searchUser(search_user)" [(ngModel)]="search_user" placeholder="Search" class="arc-pro-search" />
                    <svg class="arc-pro-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    <div *ngIf="search_results.length > 0 && is_search" class="user-list">
                        <div [hidden]="selectedUser(user.email)" class="user-item" *ngFor="let user of search_results">
                            <div class="" [innerHTML]="getUser(user, search_user)"></div>
                            <button style="font-size: 14px;padding: 1px 15px;" (click)="addUser(user)" class="add-user-button">Add</button>
                            <div *ngIf="user.user_role == 'arc_pro'" class="fs14 pt3 fw-500">
                                <div class="arc_logo_racetrack"></div>
                                {{config.arc_pro_label}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="search_results.length == 0 && is_search && search_user.length > 0 && !loading" class="user-list">
                        <div class="user-item align-center fs16 gray_color_707479 block">
                            No user found
                        </div>
                    </div>
                </div>
                <table *ngIf="selected_user.length > 0" class="table table-striped arc-table mt15">
                    <thead>
                        <tr>
                            <th class="cursor-pointer">
                                Name
                            </th>
                            <th class="">Email</th>
                            <th class=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of selected_user; let idx = index;">
                            <td class="">
                                <div class="initials">
                                    {{getInitials(user.name)}}
                                </div>
                                <div style="position: relative;top: 5px;">
                                    {{user.Firstname}} {{user.Lastname}}
                                </div>
                            </td>
                            <td class="">{{user.email}}</td>
                            <td>
                                <div title="Remove User" class="ml10 delete_report tippy_init floatNone" (click)="removeUser(idx)"></div>
                            </td>
                        </tr>			    
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="current_tab == '2. Add/Remove user'">
            <div class="w100p manage_note pl0 mb15">
                Select whether you want to add or remove the selected users from project(s):
            </div>
            <div class="radio_buttons mb30" style="display: inline-block;">
                <div class="flex mt8">
                    <div class="mr25">
                        <span class="checkcontainer" [ngClass]="operation ? 'fw-500' : ''" (click)="toggleType(true)">
                            Add to project
                            <input type="radio" name="operation" [value]="true" [(ngModel)]="operation">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
        
                    <div>
                        <span class="checkcontainer" [ngClass]="operation == false ? 'fw-500' : ''" (click)="toggleType(false)">
                            Remove from project
                            <input type="radio" name="operation" [value]="false" [(ngModel)]="operation">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="operation" id="details-form" class="w33p pt6">
                <div class="select-group group pb0 mb40">   
                    <label class="label-color fs14">Select default user role:</label>   
                    <div class="pt5">
                        <select [(ngModel)]="user_role" id="user_role" class="" name="user_role">
                            <option value="ZRPO80">Arc Administrator</option>
                            <option selected value="ZRPO81">Team Member</option>
                            <option value="ARC01">Read Only</option>
                        </select>
                        <i style="top: 20px;" class="fa fa-caret-down" aria-hidden="true"></i>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="current_tab == '3. Select projects'">
            <div class="w100p manage_note pl0 mb15">
                Select projects for which you want to {{operation ? 'add users to:' : 'remove users from:'}}
            </div>
            
            <app-projects (project_selection)="getSelectedProjects($event)" [type]="'teams_list'"></app-projects>
        </div>
        <div *ngIf="current_tab == '4. Summary'">
            <div>
                <div class="w100p black_color_28323a fs15 pl0 mb10 floatl w100p">
                    Selected user(s):
                </div>
                <div class="user_tab_wrapper floatl w100p mb20 pb10">
                    <div class="user_select_team mr20" *ngFor="let user of selected_user">
                        <span>{{user.email}}</span>
                    </div>
                    <span class="edit_sel">
                        <a (click)="toggleTab('1. Select users')">Edit</a>
                    </span>
                </div>
            </div>
            <div>
                <div class="w100p black_color_28323a fs15 user_tab_wrapper mb20 pb20 pl0 floatl">
                    <div>
                        Selected action: User(s) will be {{operation ? 'added as ' + (getRole(user_role)) : 'removed'}}
                    </div>
                    <div>
                        <span class="edit_sel">
                            <a (click)="toggleTab('2. Add/Remove user')">Edit</a>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="w100p black_color_28323a fs15 pl0 mb10 floatl w100p">
                    Selected project(s):
                </div>
                <div class="user_tab_wrapper floatl w100p mb20 pb10">
                    <div class="user_select_team mr20" *ngFor="let user of selected_projets">
                        <span>{{user}}</span>
                    </div>
                    <span class="edit_sel">
                        <a (click)="toggleTab('3. Select projects')">Edit</a>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="mt15 custom_footer">
        <button *ngIf="current_tab == '1. Select users'" (click)="cancel()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
        <button *ngIf="current_tab != '1. Select users'" (click)="goBack()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Back</button>
        <button *ngIf="current_tab != '4. Summary'" [ngClass]="(selected_user.length == 0 && current_tab == '1. Select users') || (operation == null && current_tab == '2. Add/Remove user') || (selected_projets.length == 0 && current_tab == '3. Select projects') ? 'not-active' : ''" (click)="continueTeam()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">
            Continue
            <span class="arrow_next"></span>
        </button>
        <button *ngIf="current_tab == '4. Summary'" [ngClass]="selected_user.length == 0 ? 'not-active' : ''" (click)="saveTeam()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">
            Save
        </button>
    </div>
</div>
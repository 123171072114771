import { Component, OnInit } from '@angular/core';
import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from '../../services/global.service';
import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-export-racetrack',
  templateUrl: './export-racetrack.component.html',
  styleUrls: ['./export-racetrack.component.css']
})
export class ExportRacetrackComponent implements OnInit {
  config = Config;
  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  energy_score: any = "";
  water_score: any = "";
  waste_score: any = "";
  transportation_score: any = "";
  human_experience_score: any = "";
  base_score_cert: any = "";
  certified_flag: boolean = false;

  checkInputsCert() {
    if (parseInt(this.energy_score) > 33 || parseInt(this.water_score) > 15 || parseInt(this.waste_score) > 8 || parseInt(this.transportation_score) > 14 || parseInt(this.human_experience_score) > 20 || parseInt(this.base_score_cert) > 10 || this.energy_score == '' || this.water_score == '' || this.waste_score == '' || this.transportation_score == '' || this.human_experience_score == '' || this.base_score_cert == '') {
      this.checkInputCert = false;
    }
    else {
      this.checkInputCert = true;
    }
  };

  refreshCertForms() {
    this.checkInputCert = false;
    this.loaderCert = false;
    this.energy_score = "";
    this.water_score = "";
    this.waste_score = "";
    this.transportation_score = "";
    this.human_experience_score = "";
    this.base_score_cert = "";
    this.certified_flag = false;
  };

  exportRacetrack() {
    this.checkInputsCert();
    if (!this.checkInputCert){
      return;
    }
    var final_cert: any = "not-certified";
    if (this.certified_flag){
      final_cert = 'certified';
    }
    this.loaderCert = true;
    this.api.get("/racetrack/screenshot/?access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key + "&energy=" + this.energy_score + "&water=" + this.water_score + "&waste=" + this.waste_score + "&transport=" + this.transportation_score + "&human_experience=" + this.human_experience_score + "&base=" + this.base_score_cert + "&certification=" + final_cert + "&bg_color=black" + "&type=downloadable").subscribe(
      data => {
        this.loaderCert = false;
        this.global.anchorDownload("racetrack.png", data.url);
        this.refreshCertForms();
      },
      error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService
  ) { }

  ngOnInit(): void {
  }

}

<div class="content-wrap">
    <div id="content" class="content">
        <div>
            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">{{title}}</h4>            
                </div>
                <div class="pt20">
                    <svg (click)="closeSuccess()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>     
            
            <div [ngClass]="loading ? 'not-active': ''" class="mt24 payment-card align-center">
                <app-loader *ngIf="loading"></app-loader>
                
                <h1 class="mt0 fs32 mb25 fw-500 line_height_40">
                    Thank you, {{appData.get('username')}}
                </h1>
                <div class="center-div mb25">
                    <div class="done_green_icon mr10"></div>
                    <h3 class="green-color fw-500 line_height_32 mt0">Purchase successful!</h3>
                </div>
                <p class="fs16 line_height_24 mb16">
                    Your order was successfully placed on <span class="fw-500">{{global.dateFormat()}}</span>
                </p>
                <p class="fs16 line_height_24 mb16">
                    Your confirmation email was sent to <span class="fw-500">{{appData.get('user_id')}}</span>
                </p>
                <!-- <div class="cursor-pointer mb32 center-div recert_guidance">
                    <p class="line_height_24 fs16 line_height_24">Download Receipt</p>
                </div> -->
                <div>
                    <button (click)="closeSuccess()" class="btn btn-primary inverse">Close</button>
                </div>
            </div>
        
            <div style="margin: 24px auto;" class="account_upgrade_info">
                <div class="header_image"></div>
                <div class="account_upgrade_data">
                    <div class="fw-500 fs15">
                        Arc Training and Events
                    </div>
                    <div class="gray_color_707479 mt30 fs16">
                        Looking for opportunities to learn more about the ways Arc can help you meet your sustainablility goals? Attend an upcoming Arc event to learn more about what Arc can do for you.
                    </div>
                    <div (click)="global.openLink('//arcskoru.com/events')" class="learn_more_link">
                        Learn more
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

// Angular Imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import { APP_INITIALIZER } from '@angular/core';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// Component Imports
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

// Service Imports
import { ValidationService } from './services/validation.service';
import { API } from './services/api.service';
import { AppData } from './services/appdata.service';
import { Global } from './services/global.service';
import { ArcAuthService } from './services/auth.service';
import { Projects } from './services/projects.service';
import { AppBootstrapService } from './services/app-bootstrap.service';
import { LoaderComponent } from './components/loader/loader.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CertificationTagComponent } from './components/certification-tag/certification-tag.component';
import { WidgetComponent } from './components/widget/widget.component';
import { CacheSrcDirective } from './directives/cache-src.directive';
import { ProjectsComponent } from './projects/projects.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SelectedProjectComponent } from './selected_project/selected-project.component';
import { ProjectOverviewComponent } from './selected_project/project-overview/project-overview.component';
import { ProjectPerformanceComponent } from './selected_project/project-performance/project-performance.component';
import { NgInitDirective } from './directives/ng-init.directive';
import { DataInputComponent } from './selected_project/data-input/data-input.component';
import { AdminToolComponent } from './admin-tool/admin-tool/admin-tool.component';
import { AdminTeamComponent } from './admin-tool/team/admin_team.component';
import { AdminAnalysisComponent } from './admin-tool/analysis/admin_analysis.component';
import { TeamExportComponent } from './admin-tool/team-export/team-export.component';
import { ModelsComponent } from './selected_project/models/models.component';
import { ProjectCreationComponent } from './forms/project-creation/project-creation.component';
import { PerformanceDatePickerComponent } from './components/performance-date-picker/performance-date-picker.component';
import { ProjectToolsHomeComponent } from './selected_project/project-tools/project-tools-home/project-tools-home.component';
import { PerformanceCertificatesComponent } from './selected_project/project-tools/performance-certficates/performance-certificates.component';
import { UpgradeArcComponent } from './forms/upgrade-arc/upgrade-arc.component';
import { PaymentSuccessComponent } from './forms/payment-success/payment-success.component';
import { NgIncludeDirective } from './directives/ng-include.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './components/modal/modal.component';
import { PurchaseCertificatesComponent } from './forms/purchase-certificates/purchase-certificates.component';
import { AdminReviewComponent } from './admin-tool/review/admin-review.component';
import { SubscriptionDiscountComponent } from './admin-tool/subscription-discount/subscription-discount.component';
import { CancelSubscriptionComponent } from './admin-tool/cancel-subscription/cancel-subscription.component';
import { ExportDataComponent } from './admin-tool/export-data/export-data.component';
import { CertificationBaseScoreComponent } from './admin-tool/certification-base-score/certification-base-score.component';
import { TestProjectComponent } from './admin-tool/test-project/test-project.component';
import { RecomputeScoreComponent } from './admin-tool/recompute-score/recompute-score.component';
import { AdvancedScoringComponent } from './selected_project/project-tools/advanced-scoring/advanced-scoring.component';
import { ClimateRiskComponent } from './selected_project/project-tools/climate-risk/climate-risk.component';
import { FinancialModelsComponent } from './selected_project/project-tools/financial-models/financial-models.component';
import { CreateModelsComponent } from './forms/create-models/create-models.component';
import { EfficiencyAnalysisComponent } from './selected_project/project-tools/efficiency-analysis/efficiency-analysis.component';
import { ReEntryComponent } from './selected_project/re-entry/re-entry.component';
import { ProjectComponent } from './selected_project/manage/project/project.component';
import { AppsComponent } from './selected_project/manage/apps/apps.component';
import { TeamComponent } from './selected_project/manage/team/team.component';
import { MultipleTeamManageComponent } from './selected_project/manage/multiple-team-manage/multiple-team-manage.component';
import { PlayToZeroComponent } from './selected_project/project-tools/play-to-zero/play-to-zero.component';
import { BillingComponent } from './selected_project/manage/billing/billing.component';
import { AgreementComponent } from './selected_project/manage/agreement/agreement.component';
import { SettingsComponent } from './selected_project/manage/settings/settings.component';
import { ScoreVersionComponent } from './selected_project/manage/score-version/score-version.component';
import { KeysPipe } from './pipes/keys.pipe';
import { ReportsComponent } from './selected_project/reports/reports.component';
import { ActionsComponent } from './selected_project/actions/actions.component';
import { ActionsFormComponent } from './selected_project/actions-form/actions-form.component';
import { MeasuresComponent } from './selected_project/measures/measures.component';
import { MeasureFormComponent } from './selected_project/measure-form/measure-form.component';
import { PortfoliosComponent } from './portfolios/portfolios.component';
import { PortfolioOverviewComponent } from './selected_portfolio/portfolio_overview/portfolio-overview.component';
import { SelectedPortfolioComponent } from './selected_portfolio/selected-portfolio.component';
import { PortfolioPerformanceComponent } from './selected_portfolio/portfolio_performance/portfolio-performance.component';
import { MultipleTargetManageComponent } from './selected_project/project-tools/multiple-target-manage/multiple-target-manage.component';
import { EstimateComponent } from './components/modal/estimate/estimate.component';
import { PortfolioReportsComponent } from './selected_portfolio/portfolio-reports/portfolio-reports.component';
import { PortfolioComponent } from './selected_portfolio/manage/portfolio/portfolio.component';
import { PortfolioTeamComponent } from './selected_portfolio/manage/portfolio-team/portfolio-team.component';
import { OrganizationComponent } from './components/modal/organization/organization.component';
import { ActivityComponent } from './selected_portfolio/manage/activity/activity.component';
import { AddProjectComponent } from './selected_portfolio/add_project/add-project.component';
import { CertificationsComponent } from './selected_project/project-tools/certifications/certifications.component';
import { PaymentComponent } from './selected_project/payment/payment.component';
import { PortfolioCreationComponent } from './forms/portfolio-creation/portfolio-creation.component';
import { CreateMultipleProjectsComponent } from './components/modal/create-multiple-projects/create-multiple-projects.component';
import { ReviewPaymentComponent } from './selected_project/review-payment/review-payment.component';
import { ReviewSelectionComponent } from './selected_project/review-selection/review-selection.component';
import { ReviewSuccessComponent } from './selected_project/review-success/review-success.component';
import { ProjectPaymentComponent } from './forms/project-payment/project-payment.component';
import { PaymetricComponent } from './components/paymetric/paymetric.component';
import { ProjectSetupComponent } from './forms/project-setup/project-setup.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { UserBillingComponent } from './user-billing/user-billing.component';
import { UserReportsComponent } from './user-reports/user-reports.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ClaimSchoolComponent } from './claim-school/claim-school.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { SurveyComponent } from './selected_project/survey/survey.component';
import { ReEntrySurveyComponent } from './selected_project/re-entry-survey/re-entry-survey.component';
import { SyncProjectComponent } from './admin-tool/sync-project/sync-project.component';
import { AdminScoreVersionComponent } from './admin-tool/admin-score-version/admin-score-version.component';
import { ReturnReviewComponent } from './admin-tool/return-review/return-review.component';
import { UserDataMaskingComponent } from './admin-tool/user-data-masking/user-data-masking.component';
import { RecomputeImprovementScoresComponent } from './admin-tool/recompute-improvement-scores/recompute-improvement-scores.component';
import { DowngradeScoreVersionComponent } from './admin-tool/downgrade-score-version/downgrade-score-version.component';
import { RecomputeReentryScoreComponent } from './admin-tool/recompute-reentry-score/recompute-reentry-score.component';
import { OfflineSubscriptionComponent } from './admin-tool/offline-subscription/offline-subscription.component';
import { PartialMaintenanceComponent } from './admin-tool/partial-maintenance/partial-maintenance.component';
import { DataTransferComponent } from './admin-tool/data-transfer/data-transfer.component';
import { TransitSurveyComponent } from './admin-tool/transit-survey/transit-survey.component';
import { DeactivateComponent } from './admin-tool/deactivate/deactivate.component';
import { RecomputeAnalysisinfoComponent } from './admin-tool/recompute-analysisinfo/recompute-analysisinfo.component';
import { CovidFlagComponent } from './admin-tool/covid-flag/covid-flag.component';
import { AdminPrePaymentComponent } from './admin-tool/admin-pre-payment/admin-pre-payment.component';
import { CAGBCInvoiceComponent } from './admin-tool/cagbc-invoice/cagbc-invoice.component';
import { ArcJapanComponent } from './admin-tool/arc-japan/arc-japan.component';
import { ArcJapanInvoiceComponent } from './admin-tool/arc-japan-invoice/arc-japan-invoice.component';
import { PlaqueComponent } from './plaque/plaque.component';
import { InsightHomeComponent } from './insight/insight-home/insight-home.component';
import { InsightComponent } from './insight/insight/insight.component';
import { InsightMarketComponent } from './insight/insight-market/insight-market.component';
import { InsightModelComponent } from './insight/insight-model/insight-model.component';
import { InsightReductionComponent } from './insight/insight-reduction/insight-reduction.component';
import { InsightSearchComponent } from './insight/insight-search/insight-search.component';
import { InsightStrategiesComponent } from './insight/insight-strategies/insight-strategies.component';
import { PaynowComponent } from './selected_project/paynow/paynow.component';
import { InsightComparisonComponent } from './insight/insight-comparison/insight-comparison.component';
import { InsightZepiScoreComponent } from './insight/insight-zepi-score/insight-zepi-score.component';
import { InsightTeamComponent } from './insight/insight-team/insight-team.component';
import { CreateModelComponent } from './insight/create-model/create-model.component';
import { ModelDetailsComponent } from './insight/create-model/model-details/model-details.component';
import { ModelStrategiesComponent } from './insight/create-model/model-strategies/model-strategies.component';
import { ModelReductionComponent } from './insight/create-model/model-reduction/model-reduction.component';
import { InsightManageComponent } from './insight/insight-manage/insight-manage.component';
import { MiddlewareComponent } from './middleware/middleware.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { GsaPanelComponent } from './gsa-panel/gsa-panel.component';
import { SelectedAdminAppComponent } from './admin-tool/selected-admin-app.component';
import { DataTooltipDirective } from './directives/data-tooltip.directive';
import { PortfolioEfficiencyAnalysisComponent } from './selected_portfolio/portfolio-tools/portfolio-efficiency-analysis/portfolio-efficiency-analysis.component';
import { ExportRacetrackComponent } from './admin-tool/export-racetrack/export-racetrack.component';
import { EuTaxonomyComponent } from './selected_project/project-tools/eu-taxonomy/eu-taxonomy.component';
import { InputTypeDirective } from './directives/input-type.directive';
import { EcostruxureDashboardComponent } from './ecostruxure/dashboard/dashboard.component';
import { EcostruxureRedirectComponent } from './ecostruxure/redirect/redirect.component';
import { EcostruxureSelectProjectComponent } from './ecostruxure/select-project/select-project.component';
import { OwnerInfoComponent } from './admin-tool/owner-info/owner-info.component';
import { CagbcAdminComponent } from './admin-tool/cagbc-admin/cagbc-admin.component';
import { OrderUpdateComponent } from './admin-tool/order-update/order-update.component';
import { SurveysUpdateComponent } from './admin-tool/surveys-update/surveys-update.component';


import { Config } from './models/env-vars.model';
import { HubSwitchoverComponent } from './home/hub-switchover/hub-switchover.component';
const appRoutes: Routes = [
	{
		path: "login",
		component: LoginComponent,
		data: {
			app: "login"
		}
	},
	{
		path: "login_middleware",
		component: MiddlewareComponent,
		data: {
			app: "login_middleware"
		}
	},
	{
		path: "app/home/overview",
		component: HomeComponent,
		data: {
			app: "home"
		}
	},
	{
		path: "app/projects/my-projects",
		component: ProjectsComponent,
		data: {
			app: "projects"
		}
	},
	{
		path: "app/schools/claim-school",
		component: ClaimSchoolComponent,
		data: {
			app: "claim-school"
		}
	},
	{
		path: "app/project/:leed_id/:app_name",
		component: SelectedProjectComponent,
		data: {
			app: "selected_project"
		}
	},
	{
		path: "app/ecostruxure",
		component: EcostruxureDashboardComponent,
		data: {
			app: "ecostruxure"
		}
	},
	{
		path: "ecostruxure/redirect",
		component: EcostruxureRedirectComponent,
		data: {
			app: "ecostruxure-redirect"
		}
	},
	{
		path: "app/ecostruxure/select-project",
		component: EcostruxureSelectProjectComponent,
		data: {
			app: "ecostruxure-select-project"
		}
	},
	{
		path: "app/ecostruxure",
		component: EcostruxureDashboardComponent,
		data: {
			app: "ecostruxure"
		}
	},
	//ADMIN TOOL URLS START
	{
		path: "admin/:app_name",
		component: SelectedAdminAppComponent,
		data: {
			app: "selected-admin-app"
		}
	},
	{
		path: "admin",
		component: AdminToolComponent,
		data: {app: "admin-tool"}
	},
	{
		path: "arc-japan-admin",
		component: ArcJapanComponent,
		data: {app: "arc_japan"}
	},
	{
		path: "cagbc-admin",
		component: CagbcAdminComponent,
		data: {app: "cagbc_admin"}
	},
	//ADMIN TOOL URLS END
	{
		path: "app/registration",
		component: ProjectCreationComponent,
		data: {
			app: "forms"
		}
	},
	{
		path: "registration/:leed_id/payment",
		component: ProjectPaymentComponent,
		data: {
			app: "project-payment"
		}
	},
	{
		path: "registration/:leed_id/setup",
		component: ProjectSetupComponent,
		data: {
			app: "project-setup"
		}
	},
	{
		path: "app/upgrade-arc",
		component: UpgradeArcComponent,
		data: {
			app: "upgrade_arc"
		}
	},
	{
		path: "app/payment-success",
		component: PaymentSuccessComponent,
		data: {
			app: "payment-success"
		}

	},
	{
		path: "app/portfolio/my-portfolios",
		component: PortfoliosComponent,
		data: {
			app: "portfolios"
		}
	},
	{
		path: "app/portfolio/:portfolio_id/:app_name",
		component: SelectedPortfolioComponent,
		data: {
			app: "selected_portfolio"
		}
	},
	{
		path: "app/portfolio/create",
		component: PortfolioCreationComponent,
		data: {
			app: "portfolio-creation"
		}
	},
	{
		path: "paymetric",
		component: PaymetricComponent,
		data: {
			app: "paymetric"
		}
	},
	{
		path: "app/notifications",
		component: NotificationsComponent,
		data: {
			app: "notifications"
		}
	},
	{
		path: "app/billing",
		component: UserBillingComponent,
		data: {
			app: "user-billing"
		}
	},
	{
		path: "app/reports",
		component: UserReportsComponent,
		data: {
			app: "user-reports"
		}
	},
	{
		path: "app/profile",
		component: UserProfileComponent,
		data: {
			app: "user-profile"
		}
	},
	{
		path: "app/learn-more",
		component: LearnMoreComponent,
		data: {
			app: "learn-more"
		}
	},
	{
		path: "plaque/:leed_id/:key",
		component: PlaqueComponent,
		data: {
			app: "plaque"
		}
	},
	{
		path: "app/model/registration",
		component: CreateModelComponent,
		data: {
			app: "create-model"
		}
	},
	{
		path: "maintenance",
		component: MaintenanceComponent,
		data: {
			app: "maintenance"
		}
	},
	{
		path: "app/gsa-panel",
		component: GsaPanelComponent,
		data: {
			app: "gsa-panel"
		}
	},
	// ** path should alway be at the last !!INDEX!! of appRoutes
	{
		path: "app/project/:leed_id/:app_name/v2",
		redirectTo: 'app/project/:leed_id/:app_name'
	},
	{
		path: '**',
		redirectTo: '/login_middleware',
		pathMatch: 'full'
	}
];

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader
{
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    HomeComponent,
    NavbarComponent,
    CertificationTagComponent,
    WidgetComponent,
    CacheSrcDirective,
    ProjectsComponent,
    SidebarComponent,
    SelectedProjectComponent,
    ProjectOverviewComponent,
    ProjectPerformanceComponent,
    NgInitDirective,
    DataInputComponent,
    ModelsComponent,
	AdminToolComponent,
	AdminTeamComponent,
	AdminAnalysisComponent,
 	TeamExportComponent,
    ProjectCreationComponent,
    PerformanceDatePickerComponent,
    ProjectToolsHomeComponent,
    PerformanceCertificatesComponent,
    UpgradeArcComponent,
    PaymentSuccessComponent,
    NgIncludeDirective,
    ModalComponent,
    PurchaseCertificatesComponent,
    AdminReviewComponent,
    SubscriptionDiscountComponent,
    CancelSubscriptionComponent,
    ExportDataComponent,
    CertificationBaseScoreComponent,
    TestProjectComponent,
    RecomputeScoreComponent,
    ClimateRiskComponent,
    FinancialModelsComponent,
    CreateModelsComponent,
    AdvancedScoringComponent,
    EfficiencyAnalysisComponent,
    ReEntryComponent,
    ProjectComponent,
    AppsComponent,
    TeamComponent,
    MultipleTeamManageComponent,
    PlayToZeroComponent,
    BillingComponent,
    AgreementComponent,
    SettingsComponent,
    ScoreVersionComponent,
    KeysPipe,
    ReportsComponent,
    ActionsComponent,
    ActionsFormComponent,
    MeasuresComponent,
    MeasureFormComponent,
    PortfoliosComponent,
    PortfolioOverviewComponent,
    SelectedPortfolioComponent,
    PortfolioPerformanceComponent,
    MultipleTargetManageComponent,
    EstimateComponent,
    PortfolioReportsComponent,
    PortfolioComponent,
    PortfolioTeamComponent,
    OrganizationComponent,
    ActivityComponent,
    AddProjectComponent,
    CertificationsComponent,
    PaymentComponent,
    PortfolioCreationComponent,
    CreateMultipleProjectsComponent,
    ReviewPaymentComponent,
    ReviewSelectionComponent,
    ReviewSuccessComponent,
    ProjectPaymentComponent,
    PaymetricComponent,
    ProjectSetupComponent,
    SanitizeHtmlPipe,
    NotificationsComponent,
    UserBillingComponent,
    UserReportsComponent,
    UserProfileComponent,
    ClaimSchoolComponent,
    LearnMoreComponent,
    SurveyComponent,
    ReEntrySurveyComponent,
    SyncProjectComponent,
    AdminScoreVersionComponent,
    ReturnReviewComponent,
    UserDataMaskingComponent,
    RecomputeImprovementScoresComponent,
    DowngradeScoreVersionComponent,
    RecomputeReentryScoreComponent,
    OfflineSubscriptionComponent,
    PartialMaintenanceComponent,
    DataTransferComponent,
    TransitSurveyComponent,
    DeactivateComponent,
    RecomputeAnalysisinfoComponent,
    CovidFlagComponent,
	CAGBCInvoiceComponent,
    AdminPrePaymentComponent,
    ArcJapanComponent,
    ArcJapanInvoiceComponent,
    PlaqueComponent,
    InsightHomeComponent,
    InsightComponent,
    InsightMarketComponent,
    InsightModelComponent,
    InsightReductionComponent,
    InsightSearchComponent,
    InsightStrategiesComponent,
    PaynowComponent,
    InsightComparisonComponent,
    InsightZepiScoreComponent,
    InsightTeamComponent,
    CreateModelComponent,
    ModelDetailsComponent,
    ModelStrategiesComponent,
    ModelReductionComponent,
    InsightManageComponent,
    MiddlewareComponent,
    MaintenanceComponent,
    GsaPanelComponent,
    SelectedAdminAppComponent,
    DataTooltipDirective,
    PortfolioEfficiencyAnalysisComponent,
	ExportRacetrackComponent,
	EuTaxonomyComponent,
	InputTypeDirective,
	EcostruxureDashboardComponent,
 	EcostruxureRedirectComponent,
  	EcostruxureSelectProjectComponent,
  	OwnerInfoComponent,
  	CagbcAdminComponent,
    OrderUpdateComponent,
	SurveysUpdateComponent,
	HubSwitchoverComponent
  ],
  imports: [
	BrowserModule,
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	RouterModule.forRoot(appRoutes),
 	NgbModule,
	BrowserAnimationsModule,
	HttpClientModule,
	TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: HttpLoaderFactory,
			deps: [HttpClient]
		}
	}),

	AuthModule.forRoot({
		domain: Config.auth0_domain,
		clientId: Config.sso_client
	})
  ],
  providers: [
	ValidationService,
	API,
	AppData,
	Global,
	ArcAuthService,
	Projects,
	{
        provide: HTTP_INTERCEPTORS,
        useClass: ValidationService,
        multi: true
    },
	{ 
		provide: APP_INITIALIZER,
		useFactory: (configService: AppBootstrapService) => () => configService.load(),
		deps: [
			AppBootstrapService
		], 
		multi: true
	} 
  ],
  bootstrap: [
	AppComponent
  ]
})
export class AppModule { }

<div class="content-wrap">
    <div id="content" class="content admin_tool_content">		
		<div class="test_project_body">
			<h3 class="testProjectWrapper">Test Properties<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb10">Enter Property ID or LEED ID to view the properties.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <div class="col-sm-10" class="form-group" [ngClass]="loader ? 'not-active' : ''">
                                    <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                    <div class="col-sm-4">
                                        <input [(ngModel)]="leed_id" name="leed_id" type="text" id="normal-field" class="form-control" placeholder="Property ID or LEED ID" (input)=checkLEEDID()>
                                    </div>
                                    <div class="wizard display-block">
                                        <button (click)="searchProject()" class="btn btn-default pull-left mr10" [ngClass]="checkLEED ? '' : 'not-active'"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div> 
                            </div>             
                        </fieldset>
                    </form>
                    <table class="table table-striped mt30" [ngClass]="not_active ? 'not-active' : ''">
                        <thead>
                            <tr>
                                <th>Property ID or LEED ID</th>
                                <th>Name</th>
                                <th>City</th>
                                <th>Country/Region</th>
                                <th>Project Type</th>
                                <th>Rating System</th>
                                <th *ngIf="btn_text != 'ADDED'">ACTION</th>
                                <th *ngIf="btn_text == 'ADDED'">STATUS</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="bld_data.leed_id">
                            <tr>
                                <td>{{bld_data.leed_id}}</td>
                                <td>{{bld_data.name}}</td>
                                <td>{{bld_data.city}}</td>
                                <td>{{bld_data.country}}</td>
                                <td>{{bld_data.project_type}}</td>
                                <td>{{bld_data.rating_system}}</td>
                                <td>
                                    <button *ngIf="btn_text != 'ADDED'" (click)="makeAction(bld_data)" class="btn btn-sm" [ngClass]="bld_data.test_project ? 'btn-danger' : 'btn-success'">{{btn_text}}</button>
                                    <span *ngIf="btn_text == 'ADDED'">ADDED</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
		</div>
	</div>
</div>
<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Certifications</span>
        </h1>
        
        <div class="row">
            <app-loader *ngIf="loader || config.cert_loader || registration_loading"></app-loader>
            <section class="widget"  [ngClass]="loader || config.cert_loader || registration_loading ? 'not-active' : ''">
                <div class="col-md-8 pb40" id="certification-div">
                    <div class="review_container" *ngIf="global.needReviewTab(appData.get('buildingData'))">
                        <div [hidden]="registration_status == 'registered' || registration_status == 'not_applicable' || appData.get('buildingData').building_status == 'activated_under_review'" class="divider_bottom col-md-12 pl0 pr0 vertical_center_flex pb25">
                            <span class="col-md-7 vertical_center_flex pl0 pr0">
                                <img src="assets/images/logo/leed.png" class="cert_list_image">
                                <span class="fs16">This is an important step to signify your intent to pursue recertification.</span>
                            </span>
                            <span class="col-md-5 pr0 align-center">
                                <button class="{{config.access == 'R' || sanctioned_country ? 'not-active' : ''}} review_button fw-500 fs15 line_height_24 white_color floatr pl15 pr10" (click)="showRegistrationModal(false)">Register for recertification <img src="assets/images/arrow_right_white.svg" class="pl5"></button>
                                <span *ngIf="sanctioned_country" class="danger-color col-md-12 pt5 align-left">Project cannot be registered due to Project or Owner country being sanctioned. Please contact GBCI for details.</span>
                             </span>
                        </div>
                        <div [hidden]="registration_status == 'not_registered' && appData.get('buildingData').building_status != 'activated_under_review'" class="divider_bottom col-md-12 pl0 pr0 vertical_center_flex pb25">
                            <span class="col-md-7 vertical_center_flex" [ngClass]="registration_status == 'registered' ? 'pl0' : ''">
                                <img src="assets/images/logo/leed.png" class="cert_list_image" [ngClass]="registration_status == 'registered' ? 'w125px' : ''">
                                <span class="row">
                                    <span class="col-md-12">
                                        <span class="cert_list_rs">{{cert_rating_system}}</span>
                                        <span *ngIf="registration_status == 'registered' && appData.get('buildingData').building_status != 'activated_under_review'" class="pl10 cursor-pointer" (click)="showRegistrationModal(true)">
                                            <i class="icon-pencil"></i>
                                        </span>
                                    </span>
                                    <span class="col-md-12 fs16 line_height_24 pt8" *ngIf="registration_status == 'registered'">
                                        <span class="mr5">Registration date: </span>
                                        <span>{{global.dateFormat(registration_date)}}</span>
                                    </span>
                                </span>
                            </span>
                            <span class="col-md-5 pr0 align-center">
                               <button [hidden]="appData.get('buildingData').building_status == 'activated_under_review'" class="{{config.access == 'R' || sanctioned_country ? 'not-active' : ''}} review_button fw-500 fs15 line_height_24 white_color floatr" [ngClass]="review_btn_txt == 'Submit for Review' ? 'w200px' : 'w280px'" (click)="global.gotoReview()">{{review_btn_txt}} <img src="assets/images/arrow_right_white.svg" class="pl5"></button>
                               <span [hidden]="appData.get('buildingData').building_status != 'activated_under_review'" class="col-md-12 align-left" [ngClass]="under_review_text.indexOf('LEEDcertification@cagbc.org') > -1 ? 'danger-color fs14' : 'fs16'">
                                    <span *ngIf="under_review_text.indexOf('LEEDcertification@cagbc.org') > -1">Review will start once the review payment is received. If you haven't received invoice, please contact us at <a href = "mailto:LEEDcertification@cagbc.org">LEEDcertification@cagbc.org</a></span>
                                    <span *ngIf="under_review_text.indexOf('LEEDcertification@cagbc.org') <= -1">{{under_review_text}}</span>
                               </span>
                               <span *ngIf="sanctioned_country" class="danger-color col-md-12 pt5 align-left">Project cannot be submitted for review due to Project or Owner country being sanctioned. Please contact GBCI for details.</span>
                            </span>
                        </div>
                        <!-- <div [hidden]="registration_status == 'not_registered' && appData.get('buildingData').building_status != 'activated_under_review'" class="divider_bottom col-md-12 pr0 vertical_center_flex pb20 fs16 line_height_24 pt20 pl0">
                            <span class="mr8">Registration date: </span>
                            <span>{{global.dateTimeFormat(registration_date)}}</span>
                        </div> -->
                        <div [hidden]="appData.get('buildingData').building_status != 'activated_under_review'" class="height-55" *ngFor="let data of filtered_data">
                            <span class="col-md-9 cert_expiration vertical_center_flex height-100-perc mt15">
                                <span class="fw-500 fs15 black-color line_height_24">Date submitted</span>
                                <span class="capitalize">
                                    {{global.dateTimeFormat(review_submitted_on)}}
                                </span>
                            </span>
                            <span class="col-md-3 cert_expiration vertical_center_flex height-100-perc mt15">
                                <span class="fw-500 fs15 black-color line_height_24">Download snapshot</span>
                                <span class="anchor_blue_color cursor-pointer" (click)="downloadKey(data)">Download</span>
                            </span>
                        </div>
                        <div class="col-md-12 pl0 pr0 fs16 line_height_24 pt25" [hidden]="appData.get('buildingData').building_status == 'activated_under_review'">{{cert_eligible_text}}</div>
                        <div class="col-md-12 pl0 pr0 pt25" [hidden]="appData.get('buildingData').building_status == 'activated_under_review'">
                            <div *ngIf="!(appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community')" class="col-md-6 pl0 divider_right">
                                <iframe id="cert_score_bar" frameborder="0" src="" width="100%" height="155px"></iframe>
                            </div>
                            <div class="col-md-6 pl30">
                                <div class="cert_score_item vertical_center_flex">
                                    <div class="cert_score_category vertical_center_flex">
                                        <img class="mr7" src="assets/images/icons/energy.svg">
                                        <span>Energy</span>
                                    </div>
                                    <div class="cert_reading">
                                        <span class="value" *ngIf="scores">{{global.formatNumber(scores.energy, 0)}}</span>
                                        <span class="value" *ngIf="!scores">0</span>
                                        <span class="cert_unit"> / {{maxima.energy}}</span>
                                    </div>
                                </div>
                                <div class="cert_score_item vertical_center_flex">
                                    <div class="cert_score_category vertical_center_flex">
                                        <img class="mr7" src="assets/images/icons/water.svg">
                                        <span>Water</span>
                                    </div>
                                    <div class="cert_reading">
                                        <span class="value" *ngIf="scores">{{global.formatNumber(scores.water, 0)}}</span>
                                        <span class="value" *ngIf="!scores">0</span>
                                        <span class="cert_unit"> / {{maxima.water}}</span>
                                    </div>
                                </div>
                                <div class="cert_score_item vertical_center_flex">
                                    <div class="cert_score_category vertical_center_flex">
                                        <img class="mr4 mln4" src="assets/images/icons/waste.svg">
                                        <span>Waste</span>
                                    </div>
                                    <div class="cert_reading">
                                        <span class="value" *ngIf="scores">{{global.formatNumber(scores.waste, 0)}}</span>
                                        <span class="value" *ngIf="!scores">0</span>
                                        <span class="cert_unit"> / {{maxima.waste}}</span>
                                    </div>
                                </div>
                                <div class="cert_score_item vertical_center_flex">
                                    <div class="cert_score_category vertical_center_flex">
                                        <img class="mr4 mln4" src="assets/images/icons/transport.svg">
                                        <span>Transportation</span>
                                    </div>
                                    <div class="cert_reading">
                                        <span class="value" *ngIf="scores">{{global.formatNumber(scores.transport, 0)}}</span>
                                        <span class="value" *ngIf="!scores">0</span>
                                        <span class="cert_unit"> / {{maxima.transport}}</span>
                                    </div>
                                </div>
                                <div class="cert_score_item vertical_center_flex">
                                    <div class="cert_score_category vertical_center_flex">
                                        <img class="mr7" src="assets/images/icons/human.svg">
                                        <span>{{appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community' ? 'Quality of Life' : 'Human Experience'}}</span>
                                    </div>
                                    <div class="cert_reading">
                                        <span class="value" *ngIf="scores">{{global.formatNumber(scores.human_experience, 0)}}</span>
                                        <span class="value" *ngIf="!scores">0</span>
                                        <span class="cert_unit"> / {{maxima.human_experience}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cert_list pl15 mb20" id="cert_{{certification.id}}" *ngFor="let certification of mycertifications">
                        <span class="col-md-5 vertical_center_flex height-100-perc cursor-pointer" (click)="getProfileURL()">
                            <img src="assets/images/logo/leed.png" class="cert_list_image"><span class="cert_list_rs">{{global.showRatingSystemLabel(certification.rating_system.value)}}</span>
                        </span>
                        <span class="col-md-2 cert_expiration vertical_center_flex height-100-perc cursor-pointer" (click)="getProfileURL()">
                            <span class="fw-500 fs15 black-color line_height_24 mbn10">Level</span>
                            <span class="mb10 capitalize">{{certification.certification_level}}</span>
                        </span>
                        <span class="col-md-2 cert_expiration vertical_center_flex height-100-perc cursor-pointer" (click)="getProfileURL()">
                            <span class="fw-500 fs15 black-color line_height_24 mbn10">Points</span>
                            <span class="mb10">{{certification.certification_points}}</span>
                        </span>
                        <span class="col-md-2 cert_expiration vertical_center_flex height-100-perc cursor-pointer" (click)="getProfileURL()">
                            <span class="fw-500 fs15 black-color line_height_24 mbn10">Date</span>
                            <span class="mb10">{{certification.date_certified}}</span>
                        </span>
                        <span *ngIf="showCertificationInfo(certification)" class="col-md-1 vertical_center_flex height-100-perc cursor-pointer" (click)="showCertificationDetail(certification)">
                            <img src="assets/images/arrow_left.svg" id="cert_img_{{certification.id}}">
                        </span>
                    </div>
                </div>
                <div class="col-md-4" id="accomplishments-div">
                    <div *ngIf="registration_status == 'registered' || registration_status == 'not_registered'" class="col-md-12 recent-certification-card mb30">
                        <div class="recent-certification mb15">LEED recertification</div>
                        <div class="fs14 lh24 pre_pay_card">LEED recertification is an important step in protecting your building asset. Recertification helps you maintain and improve your building, while keeping your sustainability investment in place. Get started by following the steps under 'How Recertification Works' in the <a href="https://www.usgbc.org/resources/leed-v41-om-beta-guide" target="_blank">v4.1 O+M Guide</a>.</div>
                    </div>
                    <div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school') && global.isLEEDCertified(appData.get('buildingData').certification)" class="{{sanctioned_country ? 'not-active' : ''}} col-md-12 recent-certification-card mb30 cursor-pointer-hover" (click)="makePayment('recertification', $event)">
                        <div class="recent-certification mb15">Pre-pay LEED Recertification Fee</div>
                        <div class="fs14 lh24 pre_pay_card">You can elect to pre-pay LEED v4.1 Operations and Maintenance recertification fees. <a href="https://www.usgbc.org/tools/leed-certification/fees" target="_blank">LEED recertification fees</a> are based on your project's Gross Floor Area. Ensure the Gross Floor Area is accurate prior to pre-paying fees.</div>
                    </div>
                    <div *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" class="col-md-12 performance_tools-card mb30">
                        <div class="performance_tools_title">Performance Tools for LEED</div>
                        <div class="performance_tools_text">
                            <span>Expanded performance tools are now available for most LEED registered and certified projects. You can now enjoy free access to many Arc Essentials capabilities for your LEED project.</span> 
                        </div>
                        <div class="performance_tools_text pt10">Learn more <a href="https://arc.gbci.org/explore-performance-tools-leed-projects" target="_blank">here</a></div>
                    </div>
                    <div class="col-md-12 recent-certification-card">
                        <div class="recent-certification">Recent certifications</div>
                        <div class="certification-number-container">
                            <div id="logo_leed" class="col-md-4"></div>
                            <div class="certification_number col-md-4">{{cert_count_since_last_month}}</div>
                        </div>
                        <div class="certification_number_text">
                            Projects achieved LEED certification since <span>{{global.unixDateFormat(last_month_date)}}</span>.
                        </div>
                        <div class="certification_number_text pt10 align-right">
                            <a class="anchor_blue_color" href="https://www.usgbc.org/projects" target="_blank">Learn more <img src="assets/images/ic-arrow-forward.svg"></a>
                        </div>
                    </div>
                    <div [ngClass]="config.access == 'R' ? 'not-active' : ''" class="col-md-12 mt30 fs15">Missing a LEED certification? <a (click)="openRequestForm()">Click here</a></div>
                </div>
            </section>
        </div>

    </div>
</div>

<div class="modal fade score_version_modal" id="requestCertificationModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md" [hidden]="request_submitted">
        <div class="modal-content modal_radius" [ngClass]="request_loader ? 'not-active removeOpacity' : ''">
            <app-loader *ngIf="request_loader"></app-loader>
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Request to add certification</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body score_version_modal_body mb20">
                <form>
                    <div class="form-group">
                        <label class="fw-500">Project ID</label>
                        <input name="requested_leed_id" type="text" maxlength="50" class="form-control" [(ngModel)]="requested_leed_id" (input)="checkLEEDID()">
                        <p class="mt5 danger-color" *ngIf="requested_leed_id_error">This field is required</p>
                    </div>
                    <div class="form-group">
                        <label class="fw-500">Certification source</label>
                        <select name="requested_cert" [(ngModel)]="requested_cert_type" class="form-control cursor-pointer">
                            <option value="LEED Online" selected>LEED Online</option>
                            <option value="LEED Online version 2">LEED Online version 2</option>
                            <option value="LEED India">LEED India</option>
                            <option value="LEED Canada">LEED Canada</option>
                        </select>
                    </div>
                    <div class="form-group mb50" [ngClass]="request_loader || requested_leed_id_error ? 'not-active' : ''">
                        <button class="review_button fw-500 fs15 line_height_24 white_color floatr pl30 pr30" (click)="requestCertification()">{{request_button}}</button>
                    </div>
                    <div class="form-group pt15 align-right mb0" *ngIf="request_submission_error">
                        <p class="mt5 danger-color mb0">{{request_submission_text}}</p>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-dialog modal-md" [hidden]="!request_submitted">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Your request has been received</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body score_version_modal_body mb20">
                <p >We have received your request. Our team will review this information and reach out to you if we have any questions.</p>
            </div>
        </div>
    </div>
</div>

<div class="block_pre_pay modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p40 modal_radius fs16 line_height_24">
            <p class="col-md-12 pr0 danger-color mt10 mb30">
                You have already prepaid the certification fees for this project. You will be able to make another prepay payment once the project is submitted for review. For questions, please <a href="mailto:contact@arcskoru.com">contact us</a>.
            </p>
            <div class="pt50 align-center">
                <button class="btn btn-default" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal overflow-auto" id="register_for_leed" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <app-loader *ngIf="registration_loading"></app-loader>
    <div class="modal-dialog modal-md diplay_grid">
        <div class="modal-content modal_radius removeOpacity pb30">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs24 fw-600" *ngIf="!registration_edit_mode">Register for recertification</h5>
                <h5 class="modal-title fs24 fw-600"  *ngIf="registration_edit_mode">Update Registration Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body score_version_modal_body pt30" [ngClass]="registration_loading ? 'not-active' : ''">
                <div class="row">
                    <div class="col-md-12">
                        <div class="lh32 fs18 fw-only-600 mb10">Select the rating system for your recertification</div>
                        <div class="relative-position">
                            <button class="btn dropdown-toggle btn-default black-color h40 w100p align-left gray-border" type="button" data-toggle="dropdown" [ngClass]="registration_RS_error ? 'error-border' : ''">
                                <span>{{registration_RS == '' ? 'Select the rating system' : registration_RS}}</span>
                                <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                            </button>
                            <ul class="dropdown-menu w100p">
                                <li (click)="updateRegistrationRS('LEED v4.1 O+M: Existing Buildings recertification')">
                                    <a>LEED v4.1 O+M: Existing Buildings recertification</a>
                                </li>
                                <li (click)="updateRegistrationRS('LEED v4.1 O+M: Interiors recertification')">
                                    <a>LEED v4.1 O+M: Interiors recertification</a>
                                </li>
                                <li [hidden]="appData.get('buildingData').score_code_version.name >= 3" (click)="updateRegistrationRS('LEED v4 O+M: Existing Buildings recertification')">
                                    <a>LEED v4 O+M: Existing Buildings recertification</a>
                                </li>
                                <li [hidden]="appData.get('buildingData').score_code_version.name >= 3" (click)="updateRegistrationRS('LEED v4 O+M: Interiors recertification')">
                                    <a>LEED v4 O+M: Interiors recertification</a>
                                </li>
                            </ul>
                        </div>
                        <div class="ct_error" *ngIf="registration_RS_error">This field is required.</div>
                    </div>
                </div>
                <div class="row pt20">
                    <div class="col-md-12">
                        <div class="lh32 fs18 fw-only-600 mb10">Anticipated submittal date</div>
                        <div class="relative-position">
                            <div class="vertical_center_flex cursor-pointer" (click)="triggerAnticipatedDate()">
                                <input type='text' placeholder="MM/DD/YYYY" class="h40 w100p black-color fs14 pl6" id='anticipated_date' readonly="readonly">
                                <img class="cert_calendar w30px pl6" src="assets/images/calendar.png">
                            </div>
                        </div>
                        <div class="ct_error" *ngIf="anticipated_date_error">This field is required.</div>
                    </div>
                </div>
                
                <div class="row pt30">
                    <div class="col-md-12" *ngIf="registration_edit_mode">
                        <button class="btn btn-default fs16 h40" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                    <div class="col-md-12" *ngIf="!registration_edit_mode">
                        <button [ngClass]="{'pl20 pr20' : registration_loading, 'not-active' : (config.access == 'R' || registration_RS_error || anticipated_date_error)}" class="btn btn-primary fs16 h40" (click)="createCertRegistration()">
                            <div >
                                <div *ngIf="registration_loading" class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                            </div>
                            {{register_btn_text}}
                        </button>
                        <button class="btn btn-default fs16 h40 ml8" data-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                    <div *ngIf="!registration_edit_mode && (registration_RS_error || anticipated_date_error)" class="ct_error pl15">Please provide valid data</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="score_version_notification" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md">
        <div class="modal-content p30 modal_radius pt50">
            <p class="fs16 align-center">Selecting this rating system requires a score version upgrade.</p>
            <div class="mt30 align-center">
                <button class="btn btn-primary fs16 h40" (click)="moveToScoreVersion()" [ngClass]="config.access == 'R' ? 'not-active' : ''">Continue</button>
                <button class="btn btn-default fs16 h40 ml15" data-dismiss="modal" aria-label="Close" (click)="showPreviousRegistrationModal()">Cancel</button>
            </div>
        </div>
    </div>
</div>

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit
{
	@ViewChild('modal') private modal!: ModalComponent;
	@Output() finish = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();
	selected_projets: any = [];

	constructor(
		public global: Global,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{

	}

	cancel()
	{
		this.finish.emit('close');
		this.modal.close();
	}

	getSelectedProjects(data: any)
	{
		this.selected_projets = data;
	}

	addProject(mode: string)
	{
		this.close.emit('close');
		if(mode == 'new')
		{
			this.global.goToApp("forms", 
			{
				"app_name": "project_creation",
				"project_type": 'building',
				'pf_id': this.appData.get('portfolio_id'),
				'name': this.appData.get('portfolio').name
			});
		}
		else
		{
			this.modal.open({
				'fullscreen': true 
			});
		}
	}

}

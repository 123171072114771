<div class="modal-body"> 
    <div class="row p20 fw-400">
        <p> 
            <b>Performance Score and LEED:</b><br><br>

            The performance score for each category is on a scale of 0-100 based on peer benchmarking. Higher scores indicate better performance. LEED v4.1 for Cities and Communities: Existing requires projects to achieve a minimum score of 40 in each of the 5 performance categories - Energy, Water, Waste, Transportation and Quality of Life. Points are awarded for higher scores. Enter your data in each of the 5 categories to see how your city or community performs.<br><br>

            Note: A pilot Alternative Compliance Path is available for projects to meet the performance prerequisites under Energy, Water, Waste and Transportation. No points are awarded for this path. Visit the <a href="https://www.usgbc.org/pilotcredits?Version=%22v4.1%22&Rating+System=%22Cities%3A+Existing%22" target="_blank">USGBC Pilot Credit</a> library for more information. 

        </p>
        <br>
        <p> 
            <b>Category Scores:</b><br><br>          
            
        </p>

        <div>
            <b>Energy:</b>  The score reflects annual greenhouse gas (GHG) emissions per capita. A city or community score increases as it lowers annual GHG emissions per capita. You can view the Energy score on a scale of 0 – 100. Points are awarded in LEED for an Energy Score above 40, according to the table below. <br>
            <table>
                <thead>
                    <tr>
                        <th class="fw-500">
                            Energy Score on Arc 
                        </th>
                        <th></th>
                        <th class="fw-500" rowspan="2">
                            LEED Points
                        </th>
                    </tr>
                    <tr>
                        <th class="fw-500">
                            Cities
                        </th>
                        <th class="fw-500">
                            Communities
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of config.config.settings.city_help_energy">
                        <td>
                            {{data.city}}
                        </td>
                        <td>
                            {{data.community}}
                        </td>
                        <td>
                            {{data.prereq}}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>
            
            <b>Water:</b> The score reflects domestic water use per capita. A city or community increases its score as it uses less water per capita. You can view the Water score on a scale of 0 – 100. Points are awarded in LEED for a Water Score above 40, according to the table below.<br>
            <table class="mt10">
                <thead>
                    <tr>
                        <th class="fw-500">
                            Water Score on Arc 
                        </th>
                        <th class="fw-500">
                            Points
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of config.config.settings.city_help_water">
                        <td>
                            {{data.city}}
                        </td>
                        <td>
                            {{data.prereq}}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>
            
            <b>Waste:</b> The score reflects two inputs: the weight of the municipal solid waste (MSW) generated per capita and the weight of MSW diverted from landfills and incineration via recycling, compost, and other means. A city or community increases its score as it generates less waste and as it diverts more waste from landfill and incineration. You can view the Waste score on a scale of 0 – 100.  Points are awarded in LEED for a Waste Score above 40, according to the table below.<br>
            <table class="mt10">
                <thead>
                    <tr>
                        <th class="fw-500">
                            Waste Score on Arc 
                        </th>
                        <th></th>
                        <th class="fw-500" rowspan="2">
                            LEED Points
                        </th>
                    </tr>
                    <tr>
                        <th class="fw-500">
                            Cities
                        </th>
                        <th class="fw-500">
                            Communities
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of config.config.settings.city_help_waste">
                        <td>
                            {{data.city}}
                        </td>
                        <td>
                            {{data.community}}
                        </td>
                        <td>
                            {{data.prereq}}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>
            
            <b>Transportation:</b> The score reflects distance traveled in individual vehicles per day per capita. A city or community increases its score as it reduces distance traveled per day per capita. You can view the Transportation score on a scale of 0 – 100. Points are awarded in LEED for a Transportation Score above 40, according to the table below.<br><table class="mt10">
                <thead>
                    <tr>
                        <th class="fw-500">
                            Transportation Score on Arc 
                        </th>
                        <th class="fw-500">
                            Points
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of config.config.settings.city_help_water">
                        <td>
                            {{data.city}}
                        </td>
                        <td>
                            {{data.prereq}}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>

            <b>Quality of Life:</b> The score reflects nine inputs across four sub-categories - Education, Equitability, Prosperity, and Health & Safety. Each sub-category counts equally towards the total Quality of Life category score. You can view the Quality of Life score on a scale of 0 – 100.  <br><br>

            <b>Education:</b> The Education sub-category score reflects two inputs - the % of the population 25 years and older that is at least a High School graduate (or equivalent), and the % of the population 25 years and older with at least a Bachelor’s degree (or equivalent). A city or community increases its score as both percentages increase. <br><br>

            <b>Equitability:</b> The Equitability sub-category score reflects two inputs - the Median Gross Rent as a % of Household Income and Gini coefficient of income. A city or community increases its score as both figures decrease (a Gini coefficient of zero reflects a perfectly equal distribution of income).<br><br>

            <b>Prosperity:</b> The Prosperity sub-category score reflects two inputs - Median Household Income and Unemployment rate. A city or community increases its score as Median Income increases and as Unemployment rate decreases.<br><br>

            <b>Health & Safety:</b> The Health & Safety sub-category score reflects three inputs across two sub-sub-categories, Health and Safety, which contribute equally to the Health & Safety sub-category.<br><br>
            The Health score reflects two inputs: Median Air Quality Index (AQI) and the number of Air Quality Days Unhealthy for Sensitive Groups. A city or community increases its score as both figures decrease.<br><br>
            The Safety score reflects the rate of violent crime per year per capita. A city or community increases its score as the annual rate of violent crime per capita decreases. <br><br>
            Points are awarded in LEED for a Quality of Life Score above 40, according to the table below.<br>
            <table class="mt10">
                <thead>
                    <tr>
                        <th class="fw-500">
                            Quality of Life Score on Arc 
                        </th>
                        <th class="fw-500">
                            Points
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of config.config.settings.city_help_water">
                        <td>
                            {{data.city}}
                        </td>
                        <td>
                            {{data.prereq}}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>
        </div>

    </div>
</div>

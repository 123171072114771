import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { API } from 'src/app/services/api.service';
import { ValidationService } from '../../services/validation.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-upgrade-arc',
  templateUrl: './upgrade-arc.component.html',
  styleUrls: ['./upgrade-arc.component.css']
})
export class UpgradeArcComponent implements OnInit
{
	config = Config;
	arc_pro_label = Config.arc_pro_label;
	title: string = '';
	mode: string = this.global.getURLFromParam('mode');
	leed_id: string = this.global.getURLFromParam('leed_id');

	annual: any = {};
	monthly: any = {};
	selected_users: any = [];
	price: any = {};
	subscriptions: any = {
		"card": {}
	};
	order_id: any;
	countries: any;
	states: any;
	promocode: any;
	receipt_url: any;
	paymentdata_error: any = {
		"first_name": {
			'error': false,
			'help': undefined
		},
		"last_name": {
			'error': false,
			'help': undefined
		},
		"email": {
			'error': false,
			'help': undefined
		},
		"street": {
			'error': false,
			'help': undefined
		},
		"city": {
			'error': false,
			'help': undefined
		},
		"phone": {
			'error': false,
			'help': undefined
		},
		"country": {
			'error': false,
			'help': undefined
		},
		"state": {
			'error': false,
			'help': undefined
		},
		"zip_code": {
			'error': false,
			'help': undefined
		},
	};
	promocode_price: any = {};
	paymentdata: any = {};
	arc_japan_org: any = '';

	type_label: string = '';
	extra_charge: number = 0;
	free_until: string | boolean = '';
	next_billing: string = '';
	purchase_button: string = '';
	payment_type: string = '';
	currency: string = '';
	payment_mode: string = '';
	league_user: boolean = true;
	loading: boolean = false;
	extra: boolean = false;
	show_tax: boolean = false;
	prorate_price: boolean = false;
	submit_error_payment: boolean = false;
	invalid_promo_code: boolean = false;
	promocode_applied: boolean = false;
	recurring_payment: boolean = false;
	saved_cc: boolean = true;
	billing_cycle: boolean | null = null;
	terms_required: boolean | null = null;
	regex = this.validator.regex;
	search_user: any = '';
	canceler: any;
	search_results: any = []
	is_search: boolean = false;
	searching_user: boolean = false;

	deadline: any = '';
	product_name_invoice: any = '';
	hide_project_invoice: any = '';
	price_invoice: any = '';
	tax_invoice: any = '';
	total_invoice: any = '';

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private validator: ValidationService,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.state('subscriptions') == undefined || this.config.state('subscriptions') == null)
        {
            this.loading = true;

            if(this.mode == 'purchase_arc_pro' || this.mode == 'add_subscription' || this.mode == 'update_billing_information')
            {
                this.api.get('/auth/user/subscriptions/').subscribe(
                    data =>
                    {
                        this.subscriptions = data;
                        if(this.subscriptions.subscribers == undefined)
                        {
                            this.subscriptions = {
                                "subscribers": []
                            }
                        }
                        this.loading = false;
                        this.initPage();
                    },
                    error =>
                    {
                        this.loading = false;
                    }
                );
            }
            else if(this.mode == 'add_certificate' || this.mode == 'edit_certificate_billing' || this.mode == 'renew_certificate')
            {
                this.api.get('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/').subscribe(
                    data =>
                    {
                        this.subscriptions = data;
                        this.loading = false;
                        this.initPage();
                    },
                    error =>
                    {
                        this.loading = false;
                    }
                );
            }
            else if(this.mode == 'climate_risk_report' || this.mode == 'climate_risk_certificate')
            {
                this.api.get('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/climaterisk/').subscribe(
                    data =>
                    {
                        this.subscriptions = data;
                        this.loading = false;
                        this.initPage();
                    },
                    error =>
                    {
                        this.loading = false;
                    }
                );
            }
        }
        else
        {
            this.subscriptions = this.config.state('subscriptions');
            this.initPage();
        }

        if(this.appData.get('buildingData') != undefined && this.mode != 'purchase_arc_pro' && this.appData.get('buildingData').test_project)
        {
            $('.review_not_allowed_modal').modal('toggle');
        }
	}

	searchUser(search_user: any)
	{
		if(search_user.length == 0)
		{
			this.search_results = [];
			return;
		}
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}

		this.searching_user = true;
		this.canceler = this.api.get(
			"/auth/user/search/?search=" + search_user
		).subscribe(
			data =>
			{
				this.searching_user = false;
				this.search_results = data.results;
			}
		)
	}

	selectedUser(email: any): any
	{
		var found = false;
		for(var n in this.selected_users)
		{
			if(this.selected_users[n].email == email)
			{
				found = true;
				break;
			}
		}
		return found;
	}

	addUser(user: any)
	{
		this.selected_users.push({
			"name": user.name,
			"email": user.email,
		});
		this.isSearch(false);
		this.getPrice();
	}

	getUser(user: any, query: string)
	{
		var search = String(user.name) + " ("+ user.email +")";
		var replace = "<span class='fw-500'>"+ query +"</span>";
		var regEx = new RegExp(query, "ig");
		return search.replace(regEx, replace);
	};

	isSearch(is_search: boolean)
	{
		this.is_search = is_search;
	};

	initPage()
	{
		if(this.mode == 'purchase_arc_pro' || this.mode == 'update_billing_information' || this.mode == 'add_subscription')
		{
			this.payment_mode = this.appData.get('user_type') == 'arc_japan' ? 'arc_japan' : 'arc_global';
			if(this.payment_mode == 'arc_japan')
			{
				this.api.get(
					'/auth/usgbc/userprofile/'
				).subscribe(data =>
					{
						this.arc_japan_org = data.company;
					}
				);
			}
		}
		else
		{
			if(this.appData.get('buildingData') != undefined && this.appData.get('buildingData').country == 'JP')
			{
				this.payment_mode = 'arc_japan';
			}
			else
			{
				this.payment_mode = 'arc_global';
			}
			this.arc_japan_org = this.appData.get('buildingData').organization;
		}

		this.selected_users = [{
			"name": this.appData.get('username'),
			"email": this.appData.get('user_id'),
		}];
		
		if(this.mode == 'purchase_arc_pro')
		{
			this.title = "Purchase " + this.config.arc_pro_label;
			this.type_label = this.config.arc_pro_label;
			this.purchase_button = "Continue to Payment";
			this.paymentdata = {
				'email': this.appData.get('user_id'),
				'street': '',
				'city': '',
				'state': 'DC',
				'country': 'US',
				'zip_code':"",
				"first_name": this.appData.get('user_firstname') != undefined ? this.appData.get('user_firstname') : "",
				"last_name": this.appData.get('user_lastname') != undefined ? this.appData.get('user_lastname') : "",
				"phone": ""
			};
			this.selected_users = [{
				"name": this.appData.get('username'),
				"email": this.appData.get('user_id'),
			}];
			this.billing_cycle = false;
			this.checkInvoice(this.mode);
		}
		else if(this.mode == 'update_billing_information')
		{
			this.title = "Update billing information";
			this.type_label = this.config.arc_pro_label;
			this.purchase_button = "Save";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': this.subscriptions.street,
				'city': this.subscriptions.city,
				'state': this.subscriptions.state,
				'country': this.subscriptions.country,
				'zip_code': this.subscriptions.zip_code,
				"first_name": this.subscriptions.first_name,
				"last_name": this.subscriptions.last_name,
				"phone": this.subscriptions.phone
			};
			this.selected_users = [];
			for(var n = 0; n < this.subscriptions.subscribers.length; n++)
			{
				if(this.subscriptions.subscribers[n].status == 'active')
				{
					this.selected_users.push(this.subscriptions.subscribers[n]);
				}
			}
			try
			{
				this.billing_cycle = this.subscriptions.subscribers[0].interval == 'month' ? true : false;
			}
			catch(e){}
		}
		else if(this.mode == 'add_subscription')
		{
			this.title = "Add subscription";
			this.type_label = this.config.arc_pro_label;
			this.selected_users = [];
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': this.subscriptions.street,
				'city': this.subscriptions.city,
				'state': this.subscriptions.state,
				'country': this.subscriptions.country,
				'zip_code': this.subscriptions.zip_code,
				"first_name": this.subscriptions.first_name,
				"last_name": this.subscriptions.last_name,
				"phone": this.subscriptions.phone
			};
			this.purchase_button = "Add subscription";
			try
			{
				this.billing_cycle = this.subscriptions.subscribers[0].interval == 'month' ? true : false;
			}
			catch(e){}
			this.prorate_price = true;
		}
		else if(this.mode == 'add_certificate')
		{
			this.title = "Arc Performance Certificate Subscription";
			this.type_label = "Arc Performance Certificate";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': '',
				'city': '',
				'state': 'DC',
				'country': 'US',
				'zip_code':"",
				"first_name": this.appData.get('user_firstname') != undefined ? (this.appData.get('user_firstname')) : "",
				"last_name": this.appData.get('user_lastname') != undefined ? (this.appData.get('user_lastname')) : "",
				"phone": ""
			};
			this.purchase_button = "Continue to Payment";
			this.billing_cycle = false;
			this.checkInvoice(this.mode);
		}
		else if(this.mode == 'climate_risk_certificate')
		{
			this.title = "Arc Climate Risk";
			this.type_label = "Arc Climate Risk";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': '',
				'city': '',
				'state': 'DC',
				'country': 'US',
				'zip_code':"",
				"first_name": this.appData.get('user_firstname') != undefined ? (this.appData.get('user_firstname')) : "",
				"last_name": this.appData.get('user_lastname') != undefined ? (this.appData.get('user_lastname')) : "",
				"phone": ""
			};
			this.purchase_button = "Continue to Payment";
			this.billing_cycle = false;
			this.checkInvoice(this.mode);
		}
		else if(this.mode == 'renew_certificate')
		{
			this.title = "Arc Performance Certificate Subscription";
			this.type_label = "Arc Performance Certificate";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': this.subscriptions.street,
				'city': this.subscriptions.city,
				'state': this.subscriptions.state,
				'country': this.subscriptions.country,
				'zip_code': this.subscriptions.zip_code,
				"first_name": this.subscriptions.first_name,
				"last_name": this.subscriptions.last_name,
				"phone": this.subscriptions.phone
			};
			this.purchase_button = "Renew";
			this.billing_cycle = false;
		}
		else if(this.mode == 'edit_certificate_billing')
		{
			this.title = "Arc Performance Certificate Subscription";
			this.type_label = "Arc Performance Certificate";
			this.purchase_button = "Save";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': this.subscriptions.street,
				'city': this.subscriptions.city,
				'state': this.subscriptions.state,
				'country': this.subscriptions.country,
				'zip_code': this.subscriptions.zip_code,
				"first_name": this.subscriptions.first_name,
				"last_name": this.subscriptions.last_name,
				"phone": this.subscriptions.phone
			};
			this.billing_cycle = false;
		}
		else if(this.mode == 'climate_risk_report')
		{
			this.title = "Climate Risk Report";
			this.type_label = this.config.arc_pro_label;
			this.purchase_button = "Continue to Payment";
			this.paymentdata = {
				'email': (this.appData.get('user_id')),
				'street': '',
				'city': '',
				'state': 'DC',
				'country': 'US',
				'zip_code':"",
				"first_name": this.appData.get('user_firstname') != undefined ? (this.appData.get('user_firstname')) : "",
				"last_name": this.appData.get('user_lastname') != undefined ? (this.appData.get('user_lastname')) : "",
				"phone": ""
			};
			this.billing_cycle = false;
		}

		if(this.payment_mode == 'arc_japan')
		{
			this.currency = '¥';
			this.payment_type = 'yearly';
			this.billing_cycle = false;
			this.terms_required = false;
			this.recurring_payment = true;
			this.purchase_button = "Submit";
		}
		else
		{
			this.currency = '$';
			this.payment_type = 'all';
			this.terms_required = true;
		}

		if(this.mode == 'climate_risk_certificate')
		{
			this.recurring_payment = true;
		}

		this.getCountries();
		if(this.mode == 'purchase_arc_pro' || this.mode == 'add_subscription')
		{
			this.getAuthDetail();
		}
		this.getPrice();
	}

	goBack()
	{
		$('.invoice_generated').modal('hide');
		$('.review_not_allowed_modal').modal('hide');
		if(this.mode == 'add_subscription' || this.mode == 'update_billing_information')
		{
			this.global.goToApp('user-billing');
			return;
		}
		else if(this.mode == 'purchase_arc_pro')
		{
			this.global.goBack();
			return;
		}
		else if(location.href.indexOf('login') != -1 && location.href.indexOf('leed_id') != -1)
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.leed_id,
				"app_name": 'project-tools-home'
			});
			return;
		}
		else if(location.href.indexOf('login') != -1)
		{
			this.global.goToApp('home');
			return;
		}
		else if(this.global.getURLFromParam('payment_type') == 'arc_pro')
		{
			this.global.goToApp('user-profile');
			return;
		}
		else if(this.mode == 'climate_risk_report' || this.mode == 'climate_risk_certificate')
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "climate-risk"
			});
			return;
		}
		else
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "performance-certificates"
			});
			return;
		}
	}

	getPrice()
	{
		this.loading = true;
		
		// Monthly Price
		let period = 'monthly', monthly_url: string = '', annually_url: string = '';
		if(this.mode == 'purchase_arc_pro' || this.mode == 'add_subscription' || this.mode == 'update_billing_information')
		{
			let user_count = this.selected_users.length;
			monthly_url = '/subscriptions/price/premium/?period='+ period +'&user_count=' + String(user_count);
		}
		else if(this.mode == 'add_certificate' || this.mode == 'edit_certificate_billing' || this.mode == 'renew_certificate')
		{
			monthly_url = '/subscriptions/price/performance/?period='+ period +'&object=' + String(this.leed_id);
		}
		else if(this.mode == 'climate_risk_certificate' || this.mode == 'climate_risk_report')
		{
			monthly_url = '/subscriptions/price/climaterisk/?period='+ period +'&object=' + String(this.leed_id);
		}
		monthly_url += "&prorate=" + this.prorate_price;

		// Annual Price
		period = 'annually';
		if(this.mode == 'purchase_arc_pro' || this.mode == 'add_subscription' || this.mode == 'update_billing_information')
		{
			let user_count = this.selected_users.length;
			annually_url = '/subscriptions/price/premium/?period='+ period +'&user_count=' + String(user_count);
		}
		else if(this.mode == 'add_certificate' || this.mode == 'edit_certificate_billing' || this.mode == 'renew_certificate')
		{
			annually_url = '/subscriptions/price/performance/?period='+ period +'&object=' + String(this.leed_id);
		}
		else if(this.mode == 'climate_risk_certificate' || this.mode == 'climate_risk_report')
		{
			annually_url = '/subscriptions/price/climaterisk/?period='+ period +'&object=' + String(this.leed_id);
		}
		annually_url += "&prorate=" + this.prorate_price;
		if(this.payment_mode == 'arc_japan')
		{
			monthly_url += '&source=arcjapan';
			annually_url += '&source=arcjapan';
		}
		
		this.api.async(
			[
				monthly_url,
				annually_url
			]
		).subscribe(data =>
		{
			this.monthly = data[0];
			this.annual = data[1];

			if(this.prorate_price)
			{
				this.monthly['price'] = this.monthly['prorate'];
				this.annual['price'] = this.annual['prorate'];
			}

			if(jQuery.isEmptyObject(this.monthly))
			{
				this.league_user = true;
				this.billing_cycle = false;
			}
			else
			{
				this.league_user = false;
			}

			if(this.billing_cycle)
			{
				this.price = this.monthly;
			}
			else
			{
				this.price = this.annual;
			}

			if(this.price.tax == undefined || this.price.tax == 0  || isNaN(parseInt(this.price.tax)))
			{
				this.price.tax = 0;
				this.show_tax = false;
			}
			else
			{
				this.show_tax = true;
			}

			
			this.loading = false;
			this.setText();
			this.initInvoiceJP();
		}, error =>
		{
			this.alertService.alert('error', 'Error fetching price. Try again.', 5);
			this.loading = false;
		});
	}

	getAuthDetail()
	{
		this.api.get(
			'/auth/detail/'
		).subscribe(data =>
		{
			this.paymentdata["street"] = data.Street;
			this.paymentdata["city"] = data.City;
			this.paymentdata["zip_code"] = data.PostlCode;
			this.paymentdata["country"] = data.Country;
			this.states = this.global.getStateList(data.Country);
			this.updateCountry(data.Country);
			let valid_state_found = false;
			for(let a = 0; a < this.states.length; a++)
			{
				if(this.states[a].code == data.Region)
				{
					this.paymentdata["state"] = data.Region;
					valid_state_found = true;
					break;
				}
			}
			if(!valid_state_found)
			{
				this.states.unshift({
					code: "select_state",
					name: "Select State"
				});
				this.paymentdata['state'] = "select_state";
			}
		});
	}

	initStates()
	{
		if(this.config.countries.divisions[this.paymentdata.country] == undefined)
		{
			this.states = this.global.getStateList('US');
		}
		else
		{
			this.states = this.global.getStateList(this.paymentdata.country);
		}
		
		if(this.paymentdata.country == null || this.paymentdata.country == undefined || this.paymentdata.country.length == 0)
		{
			this.paymentdata.country  = 'US';
		}
		if(this.paymentdata.state == null || this.paymentdata.state == undefined || this.paymentdata.state.length == 0)
		{
			this.paymentdata.state    = 'DC';
			this.paymentdata.country  = 'US';
		}
	}

	getCountries()
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
				this.initStates();
			});
		}
		else
		{
			this.countries = this.global.getCountryList();
			this.initStates();
		}
	}

	initInvoiceJP()
	{
		if(this.payment_mode == 'arc_global')
		{
			return;
		}
		this.deadline = moment(new Date()).add('month', 1).subtract('day', 1).format('YYYY-MM-DD');
		if(this.mode == 'purchase_arc_pro' || this.mode == 'update_billing_information' || this.mode == 'add_subscription')
		{
			this.product_name_invoice = "Arc Essentialサブスクリプション（年間）";
			this.hide_project_invoice = true;
		}
		else if(this.mode == 'climate_risk_certificate')
		{
			this.product_name_invoice = "Arc Climate Risk";
			this.hide_project_invoice = false;
		}
		else
		{
			this.product_name_invoice = "Arc Performance Certificateサブスクリプション（年間）";
			this.hide_project_invoice = false;
		}
		this.price_invoice = this.currency + this.global.formatNumberCommas(this.price.price);
		this.tax_invoice = this.currency + this.global.formatNumberCommas(this.price.tax);
		this.total_invoice = this.currency + this.global.formatNumberCommas(this.price.price + this.price.tax);
		if(this.mode == 'edit_certificate_billing' || this.mode == 'update_billing_information')
		{
			this.purchase_button = "Save";
		}
		else
		{
			this.purchase_button = "Create Invoice";
		}
	}

	setText()
	{
		this.extra = false;
		this.next_billing = moment(new Date()).add(1, this.billing_cycle ? 'month' : 'year').format('MMM DD, YYYY');
		if(this.price.discount == null)
		{
			this.free_until = false;
		}
		else
		{
			this.free_until = moment(new Date()).add(this.price.discount.months, 'month').subtract(1, 'day').format('MMM DD, YYYY');
			this.next_billing = moment(new Date()).add(this.billing_cycle ? this.price.discount.months : 1, this.billing_cycle ? 'month' : 'year').format('MMM DD, YYYY');

			if(this.price.price - this.price.discounted_amount > 0)
			{
				this.extra = true;
				this.free_until = moment(this.next_billing).subtract(1, 'month').subtract(1, 'day').format('MMM DD, YYYY');
				this.extra_charge = this.price.price - this.price.discounted_amount;
			}
		}
	}

	checkInvoice(mode: string)
	{
		if(mode == 'add_certificate' || mode == 'climate_risk_certificate')
		{
			this.loading = true;
			let url = '/subscriptions/'+ this.leed_id +'/payments/history/?page_size=0';
			this.api.get(url).subscribe(
				data =>
				{
					this.loading = false;
					var payment_history = data, invoice_generated = false;
					for(var i = 0; i < payment_history.length; i++)
					{
						if((payment_history[i].type == "performance" && mode == 'add_certificate' && payment_history[i].status == "processing") || (payment_history[i].type == "climaterisk" && mode == 'climate_risk_certificate' && payment_history[i].status == "processing"))
						{
							invoice_generated = true;
							this.order_id = payment_history[i].sap_order_id;
							break;
						}
					}
					if(invoice_generated)
					{
						$('.invoice_generated').modal('show');
					}
				},
				error =>
				{
					this.loading = false;
				}
			);
		}
		else if(mode == 'purchase_arc_pro')
		{
			this.loading = true;
			var url = '/subscriptions/'+ this.appData.get('user_id') +'/payments/history/?page_size=0';
			this.api.get(url).subscribe(
				data =>
				{
					this.loading = false;
					var payment_history = data, invoice_generated = false;
					for(var i = 0; i < payment_history.length; i++)
					{
						if(payment_history[i].type == "essentials" && payment_history[i].status == "processing")
						{
							invoice_generated = true;
							this.order_id = payment_history[i].sap_order_id;
							this.receipt_url = payment_history[i].receipt_url;
							break;
						}
					}
					if(invoice_generated)
					{
						$('.invoice_generated').modal('show');
					}
				},
				error =>
				{
					this.loading = false;
				}
			);
		}
	}

	changeBillingCycle(val: boolean)
	{
		if(val == this.billing_cycle)
		{
			return;
		}
		this.billing_cycle = !this.billing_cycle;
		this.getPrice();
	}

	changeCC(val: boolean)
	{
		this.saved_cc = !this.saved_cc;
	}
	
	toggleFlag(val: string)
	{
		this.recurring_payment = !this.recurring_payment;
	}

	removeUser(idx: number)
	{
		this.selected_users.splice(idx, 1);
		this.getPrice();
	}

	removePromocode(term: number)
	{

	}

	submitPayment()
	{
		if(this.mode == 'add_subscription')
		{
			this.addSubscription();
			return;
		}

		for(var a in this.paymentdata)
		{
			this.verifyField(this.paymentdata[a], a);
		}

		this.submit_error_payment = false;
		for(var a in this.paymentdata_error)
		{
			if(this.paymentdata_error[a].error)
			{
				this.submit_error_payment = true;
				return;
			}
		}

		let data;
		if (this.paymentdata.state == '' || this.paymentdata.state == null)
		{
			data = {
				"country": this.paymentdata.country,
				"zip_code": this.paymentdata.zip_code
			}
		}
		else
		{
			data = {
				"state": this.paymentdata.state,
				"country": this.paymentdata.country,
				"zip_code": this.paymentdata.zip_code
			}
		}

		this.loading = true;
		this.purchase_button = "Validating info..."
		this.api.post(
			'/assets/validation/'
			, data
		).subscribe(
			//success handler
			data =>
			{         
				$("#zip_code").removeClass("parsley-error")
				$("#zip_code").next('ul.parsley-errors-list').remove();

				this.submit_error_payment = false;
				
				if(this.mode == 'purchase_arc_pro')
				{
					this.continuePayment();
				}
				else if(this.mode == 'update_billing_information' || this.mode == 'edit_certificate_billing')
				{
					this.updateBilling();
				}
				else if(this.mode == 'add_subscription')
				{
					this.addSubscription();
				}
				else if(this.mode == 'add_certificate')
				{
					this.addCertificate();
				}
				else if(this.mode == 'renew_certificate')
				{
					this.renewCertificate();
				}
				else if(this.mode == 'climate_risk_report')
				{
					this.climateReportPayment();
				}
				else if(this.mode == 'climate_risk_certificate')
				{
					this.climateCertificatePayment();
				}
			},
			error =>
			{
				this.submit_error_payment = true;
				try
				{
					this.paymentdata_error['zip_code'] = {
						'error': true,
						'help': "Invalid zip code/state combination"
					};
				}
				catch(e)
				{
					this.paymentdata_error['zip_code'] = {
						'error': true,
						'help': "Enter a valid zip code"
					};
				}

				if(this.mode == 'edit_certificate_billing' || this.mode == 'update_billing_information')
				{
					this.purchase_button = "Save";
				}
				else
				{
					this.purchase_button = "Create Invoice";
				}
				this.loading = false;
			}
		);
	}

	continuePayment()
	{
		this.loading = true;
		let payload: any = {
			"users": this.selected_users,
			"interval": this.billing_cycle ? 'month' : 'year',
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"phone": this.paymentdata.phone,
			'email': this.paymentdata.email
		};

		if(this.payment_mode == 'arc_japan')
		{
			payload["source"] = 'arcjapan';

			this.api.post('/auth/user/subscriptions/', payload).subscribe(
				data =>
				{
					$('.invoice_generated').modal('show');
					this.loading = false;
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
		else
		{
			this.api.post('/auth/user/subscriptions/', payload).subscribe(
				data =>
				{
					this.loading = false;
					var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
					window.open(stripe_url, "_self");
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}   
	}

	downloadInvoice()
	{
		window.open(this.receipt_url, "_blank");
	}

	updateBilling()
	{
		this.loading = true;            
		let payload: any = {
			"subscription_id": this.subscriptions.subscription_id,
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"phone": this.paymentdata.phone,
			'email': this.paymentdata.email
		};

		if(this.mode == 'edit_certificate_billing')
		{
			this.api.put('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/', payload).subscribe(
				data =>
				{
					this.loading = false;
					this.global.goToApp("selected_project", 
					{
						"leed_id": this.appData.get('leed_id'),
						"app_name": "performance-certificates"
					});
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Save";
				}
			);
		}
		else if(this.mode == 'update_billing_information')
		{
			this.api.put('/auth/user/subscriptions/', payload).subscribe(
				data =>
				{
					this.loading = false;
					this.global.goToApp('user-billing');
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Save";
				}
			);
		}
	}

	addSubscription()
	{
		this.loading = true; 
		if(this.saved_cc)
		{
			var payment_method = 'off_session';
		}
		else
		{
			var payment_method = 'new_session';
		}
		let payload: any = {
			"users": this.selected_users,
			'payment_method': payment_method,
			"subscription_id": this.subscriptions.subscription_id
		};

		this.api.put('/auth/user/subscriptions/', payload).subscribe(
			data =>
			{
				if(payment_method == 'off_session')
				{
					this.api.get('/auth/usgbc/userprofile/').subscribe(
						data =>
						{
							this.loading = false;
							this.appData.set('user_role', data.user_role);
							this.global.goToApp('user-billing');
						}
					);
				}
				else
				{                     
					var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
					window.open(stripe_url, "_self");
				}
			},
			error =>
			{
				this.loading = false
				this.alertService.alert('error', 'Something went wrong. Try again.', 5);
				this.purchase_button = "Continue to Payment";
			}
		);
	}

	addCertificate()
	{
		this.loading = true;
		let payload: any = {
			"interval": this.billing_cycle ? 'month' : 'year',
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"phone": this.paymentdata.phone,
			'email': this.paymentdata.email
		};

		if(this.payment_mode == 'arc_japan')
		{
			payload["source"] = 'arcjapan';
			var html_id = "arc-jp-invoice-final";

			var form_data = new FormData();
			for (var key in payload)
			{
				form_data.append(key, payload[key]);
			}
			var mimeType = 'text/html';
			if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
			{
				var file: any = new Blob([$('#' + html_id).html()], { type: mimeType});
				file.lastModifiedDate = new Date();
				form_data.append('invoice', file, "file.html");
			}
			else
			{
				var file: any = new File([$('#' + html_id).html()], "file.html", {type: mimeType, lastModified: <any>(new Date())});
				form_data.append('invoice', file);
			}
			
			let custom_basic_config_header = 
			{
				headers: 
				{
					"responseType": "arraybuffer",
					"Ocp-Apim-Subscription-Key": this.config.sub_key,
					"Authorization": "Bearer " + this.appData.get('token')
				}
			};

			this.loading = true;
			this.api.post('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/', form_data, custom_basic_config_header).subscribe(
				data =>
				{
					$('.invoice_generated').modal('show');
					this.loading = false;
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
		else
		{
			this.api.post('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/', payload).subscribe(
				data =>
				{
					this.loading = false;
					let stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
					window.open(stripe_url, "_self");
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
	}

	renewCertificate()
	{
		this.loading = true; 
            
		if(this.payment_mode == 'arc_japan')
		{
			let payload: any = {
				"subscription_id": this.subscriptions.subscription_id,
				"payment_method": 'off_session',
				"renewsub": true,
				"interval": this.billing_cycle ? 'month' : 'year',
			};
			payload["source"] = 'arcjapan';

			this.loading = true;
			this.api.put('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/', payload).subscribe(
				data =>
				{
					$('.invoice_generated').modal('show');
					this.loading = false;
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
		else
		{
			if(this.saved_cc)
			{
				var payment_method = 'off_session';
			}
			else
			{
				var payment_method = 'new_session';
			}
			let payload: any = {
				"subscription_id": this.subscriptions.subscription_id,
				"payment_method": payment_method,
				"renewsub": true,
				"interval": this.billing_cycle ? 'month' : 'year',
			};

			this.api.put('/assets/LEED:'+ this.leed_id +'/subscriptions/performance/', payload).subscribe(
				data =>
				{
					this.loading = false;
					if(payment_method == 'off_session')
					{
						this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": "performance-certificates"
						});
					}
					else
					{
						var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
						window.open(stripe_url, "_self");
					}
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
	}

	climateReportPayment()
	{
		this.loading = true;
		let payload: any = {
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"phone": this.paymentdata.phone,
			'email': this.paymentdata.email
		};

		this.api.post('/assets/LEED:'+ this.leed_id +'/climaterisk/report/generate/', payload).subscribe(
			data =>
			{
				this.loading = false;
				var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
				window.open(stripe_url, "_self");
			},
			error =>
			{
				this.loading = false
				this.alertService.alert('error', 'Something went wrong. Try again.', 5);
				this.purchase_button = "Continue to Payment";
			}
		);
	}

	climateCertificatePayment()
	{
		this.loading = true;
		let payload: any = {
			"interval": this.billing_cycle ? 'month' : 'year',
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"phone": this.paymentdata.phone,
			'email': this.paymentdata.email
		};

		if(this.payment_mode == 'arc_japan')
		{
			payload["source"] = 'arcjapan';
			var html_id = "arc-jp-invoice-final";

			var form_data = new FormData();
			for (var key in payload)
			{
				form_data.append(key, payload[key]);
			}
			var mimeType = 'text/html';
			if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
			{
				var file: any = new Blob([$('#' + html_id).html()], { type: mimeType});
				file.lastModifiedDate = new Date();
				form_data.append('invoice', file, "file.html");
			}
			else
			{
				var file: any = new File([$('#' + html_id).html()], "file.html", {type: mimeType, lastModified: <any>new Date()});
				form_data.append('invoice', file);
			}
			
			var custom_basic_config_header = 
			{
				headers: 
				{
					"responseType": "arraybuffer",
					"Ocp-Apim-Subscription-Key": this.config.sub_key,
					"Authorization": "Bearer " + this.appData.get('token')
				}
			};

			this.api.post('/assets/LEED:'+ this.leed_id +'/subscriptions/climaterisk/', form_data, custom_basic_config_header).subscribe(
				data =>
				{
					$('.invoice_generated').modal('show');
					this.loading = false;
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
		else
		{
			this.api.post('/assets/LEED:'+ this.leed_id +'/subscriptions/climaterisk/', payload).subscribe(
				data =>
				{
					this.loading = false;
					var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
					window.open(stripe_url, "_self");
				},
				error =>
				{
					this.loading = false
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
					this.purchase_button = "Continue to Payment";
				}
			);
		}
	}


	verifyField(data: any, key: string, optional?: boolean)
	{
		if(this.regex[key] == undefined || key == 'state' || key == 'organization')
		{
			this.checkSubmitError();
			return;
		}

		if(data == undefined || data == null || data.length == 0)
		{
			if(optional)
			{
				this.paymentdata_error[key] = {
					'error': false,
					'help': undefined
				};
				this.checkSubmitError();
				return;
			}
			this.paymentdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.paymentdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.paymentdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		this.checkSubmitError();
	}

	checkSubmitError()
	{
		this.submit_error_payment = false;
		for(var a in this.paymentdata_error)
		{
			if(this.paymentdata_error[a].error)
			{
				this.submit_error_payment = true;
				break;
			}
		}
	}

	updateCountry(country: string)
	{
		this.states = this.global.getStateList(country);
		if(this.states.length > 0)
		{
			this.paymentdata['state'] = this.states[0].code;
		}
		else
		{
			this.states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.paymentdata['state'] = "";
		}
	}

	updateState(state: string)
	{

	}

}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';

import { Config } from 'src/app/models/env-vars.model';
import { Projects } from 'src/app/services/projects.service';

@Component({
  selector: 'app-data-input',
  templateUrl: './data-input.component.html',
  styleUrls: ['./data-input.component.css']
})
export class DataInputComponent implements OnInit, OnDestroy
{
	loading: boolean = false;
	type: string = 'energy';
	action: string = '';

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private projects: Projects
	)
	{

	}

	ngOnDestroy(): void
	{
		this.projects.getAssetDetails(this.appData.get('leed_id')).subscribe(
			(buildingData) => {
				this.appData.set('buildingData', buildingData);
				this.global.initRanges(buildingData.performance_end_date);
				this.global.refreshCycle();
			}
		);
	}

	ngOnInit(): void
	{
		if(Config.state('type') != undefined)
		{
			this.type = Config.state('type');
		}
		if(Config.state('action') != undefined)
		{
			this.action = Config.state('action');
		}
	}
}

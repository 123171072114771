import { Component, OnInit } from '@angular/core';

import { Config } from 'src/app/models/env-vars.model';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';

@Component({
  selector: 'app-project-tools-home',
  templateUrl: './project-tools-home.component.html',
  styleUrls: ['./project-tools-home.component.css']
})
export class ProjectToolsHomeComponent implements OnInit
{

	constructor(
		private appData: AppData,
		private global: Global
	)
	{

	}

	goTo(app: string)
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": app
		});
	}

	ngOnInit(): void
	{
		(<any>document.getElementById('cert_score_bar')).src = Config.basic_api_url + "/assets/LEED:"+ this.appData.get('leed_id') +"/overview/projectscore/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
    	(<any>document.getElementById('cert_score_bar_1')).src = Config.basic_api_url + "/assets/LEED:"+ this.appData.get('leed_id') +"/overview/projectcertification/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
	}

}

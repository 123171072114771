<div class="content-wrap">
	<div id="content" class="content">

        <h1 class="page-title ">
            <span class="fw-semi-bold" translate>Performance Certificates</span>
        </h1>

        <div class="row">
            <app-loader *ngIf="loading"></app-loader>
            <section class="widget"  [ngClass]="loading ? 'not-active' : ''">
        
                <div>
                    <ul class="meterNav mb25">
                        <li *ngIf="current_status == 'active' || current_status == 'cancelled_active' || current_status == 'expired'" (click)="toggleTab('Current Certificates')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Current Certificates' ? 'meterNav--item__active' : ''" translate>Current Certificates</span>
                        </li>
                        <li *ngIf="current_status == 'inactive' || current_status == 'expired'" [hidden]="current_status == 'expired'" (click)="toggleTab('Subscription Purchase')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Subscription Purchase' ? 'meterNav--item__active' : ''" translate>Subscription</span>
                        </li>
                        <li (click)="toggleTab('Past Certificates')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Past Certificates' ? 'meterNav--item__active' : ''" translate>Past Certificates</span>
                        </li>
                        <li *ngIf="current_status == 'active' || current_status == 'expired' || current_status == 'cancelled_active'" [hidden]="current_status == 'expired'" (click)="toggleTab('Subscription')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Subscription' ? 'meterNav--item__active' : ''" translate>Subscription</span>
                        </li>
                    </ul>
        
                    <div *ngIf="current_status == 'inactive' && current_tab == 'Subscription Purchase'">
                        <div class="cert_banner_img col-md-12"></div>
                        <div id="inactive_certificate">
                            <div class="pb24 border-bottom mb16">
                                <div class="fs18 line_height_24 fw-500 mb16" translate>Arc Performance Certificates</div>
                                <div class="fs16 line_height_22 mb16" translate>
                                    Performance Certificates are available by subscription for:<br>
                                    <span class="fw-500" translate>{{config.currency}}{{global.formatNumberCommas(config.price["certificate_annually"], 0)}} per project when billed annually.</span>
                                </div>
                                <div class="col-md-12 pl0" style="float: none;">
                                    <button *ngIf="invoice_generated == false" [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10" (click)="puchaseSubs()" translate>Purchase Subscription</button>
                                    <div class="fs16 line_height_22 mb16" *ngIf="invoice_generated == true">
                                        We have emailed a copy of the invoice for this product with Order ID: <span class="fw-500">{{order_id}}</span> to <span class="fw-500">{{appData.get('user_id')}}</span>. The invoice is also available in the billing page. Please follow the instructions in the invoice to make the payment.
                                        <br><br>
                                        Click <a (click)="downloadInvoice(order_id)">here</a> to download the invoice.
                                    </div>
                                    <button (click)="global.learnMore('certificate', appData.get('leed_id'))" class="btn btn-primary inverse fs16 h40 br2p" translate>Learn more</button>
                                </div>
                            </div>
                            <div>
                                <p class="fs16 line_height_22 mb16" translate>
                                    Performance Certificates recognize performance in indiviual Arc categories. They provide external affirmation of project performance backed by automated data tests.
                                </p>
                                <ul class="fs16 mb16">
                                    <li translate>Snapshot of project performance relative to peers around the world</li>
                                    <li translate>Show performance gains before and after an invervention</li>
                                    <li translate>Generate an Energy Rating for GRESB</li>
                                    <li translate>Display an Arc Performance Score</li>
                                    <li translate>Display an Arc Improvement Score</li>
                                    <li translate>Display category-specific Key Performance Indicators</li>
                                </ul>
                                <!-- <div>
                                    <a class="leadership_links" (click)="learnMore('certificate')" target="_blank">Learn More <img src="assets/images/ic-arrow-forward.svg"></a>
                                </div> -->
                            </div>
                        </div>
                    </div>
        
                    <div id="certificate_subscription" *ngIf="current_tab == 'Subscription'">
                        <div *ngIf="current_status == 'active'" class="align_lr green-color w100p mb5">
                            <div class="flex">
                                <svg class="mr5" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" style="position: relative;top: -3px;">
                                    <g id="ICONS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="svg/more_done_icon">
                                            <path d="M15,25 C20.5228475,25 25,20.5228475 25,15 C25,9.4771525 20.5228475,5 15,5 C9.4771525,5 5,9.4771525 5,15 C5,20.5228475 9.4771525,25 15,25 Z" id="Oval-12" stroke="#50AE54" stroke-width="1.5"></path>
                                            <path d="M11.7520872,15.1860598 C11.5118429,15.1860598 11.295037,15.2992231 11.1544062,15.4898138 C11.0313542,15.6565807 10.9786177,15.8531274 11.0079157,16.05563 C11.0372138,16.2581327 11.142687,16.4368115 11.3008966,16.5618867 L14.301021,18.8489754 C14.4592307,18.9680946 14.670177,19.0216983 14.869404,18.9919185 C15.068631,18.9561827 15.2502792,18.8430195 15.3674715,18.6762526 L19.8617985,12.1961678 C20.1020428,11.8507221 20.0200082,11.3742452 19.68601,11.1300509 C19.3578714,10.8977684 18.8656634,10.9871078 18.6371383,11.3146856 L14.5822827,17.1515267 L12.2032778,15.3409148 C12.0685066,15.2396635 11.9161565,15.1860598 11.7520872,15.1860598 Z" id="✓-copy" fill="#50AE54"></path>
                                        </g>
                                    </g>
                                </svg>
                                <div class="fs18 line_height_24 fw-500">Subscription active</div>
                            </div>
                            <div *ngIf="config.access == 'CRUD'">
                                <a (click)="cancelSubs()">Cancel subscription</a>
                            </div>
                        </div>
                        <div *ngIf="current_status == 'cancelled_active'" class="flex error-color w100p mb5">
                            <div class="mr5 error_symbol">
                                
                            </div>
                            <div class="fs18 line_height_24 fw-500">Subscription cancelled</div>
                        </div>
                        <div (click)="global.openPolicy('Arc Performance Certificate')" class="floatr"><a>Subscription policy</a></div>
                        <div *ngIf="current_status == 'active'" class="fs16 line_height_24 mb24">
                            Your subscription to Arc Performance Certificates is valid through {{global.dateFormat(subscription.next_billing_date)}}. <a *ngIf="config.access == 'CRUD'" (click)="editBilling()">Update billing information</a>
                        </div>
                        <div *ngIf="current_status == 'cancelled_active'" class="fs16 line_height_24 mb24">
                            Your subscription to Arc Performance Certificates is cancelled and will end on {{global.dateFormat(subscription.next_billing_date)}}. <a *ngIf="config.access == 'CRUD'" (click)="editBilling()">Update billing information</a>
                        </div>
                        <div id="cert_subscription_details" *ngIf="offline_sub">
                            <div class="fw-500 fs16 line_height_24">
                                Your subscription will expire on {{global.dateFormat(subscription.next_billing_date)}}
                            </div>
                        </div>
                        <div id="cert_subscription_details" *ngIf="current_status == 'active' && offline_sub == false">
                            <div class="fw-500 fs16 line_height_24 mb16">Your next bill for {{appData.get('currency')}}{{global.addCommas(subscription.amount)}} on {{global.dateFormat(subscription.next_billing_date)}}</div>
                            <div class="b_item" [hidden]="appData.get('buildingData').country == 'JP'">
                                <div class="flex"> 
                                    <i class="fa fa-credit-card fs40 gray_color_68737D mr10 pt15"></i>
                                    <div>
                                        <div class="fw-500 line_height_24 fs15">
                                            <span class="capitalize">{{subscription.card.brand}}</span> ending in {{subscription.card.last4}}
                                        </div>
                                        <div [ngClass]="getMonthDiff(3) ? 'error-color' : ''" class="line_height_24 fs15 gray_color_68737D">
                                            Expires: {{subscription.card.exp_month}}/{{subscription.card.exp_year}}
                                        </div>
                                        <div *ngIf="config.access == 'CRUD'" class="b_item line_height_24 fs14">
                                            <a (click)="editCard()">Update payment method</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div id="certificate_list" *ngIf="current_tab == 'Current Certificates' && loading == false">
                        <div *ngIf="current_status == 'expired'" class="certificate">
                            <div class="w100p pl30 pt2 error_symbol error-color fs18 line_height_24 fw-500 mb16">Subscription expired</div>
                            <div class="fs16 line_height_22">Your subscription to Arc Performance Certificates has expired. Renew now to create new certificates.</div>
                            <div class="fs16 line_height_22 mb18">
                                <a (click)="global.openLink('//arcskoru.com/arc-performance-certificates', '_blank')">View Arc Performance Certificates Subscription pricing details</a>
                            </div>
                            <button *ngIf="invoice_generated == false" [ngClass]="config.access == 'R' ? 'not-active' : ''" (click)="renewSubs()" class="btn btn-primary fs16">
                                Renew Subscription
                            </button>
        
                            <div class="fs16 line_height_22 mb16" *ngIf="invoice_generated == true">
                                We have emailed a copy of the invoice for this product with Order ID: <span class="fw-500">{{order_id}}</span> to <span class="fw-500">{{appData.get('user_id')}}</span>. The invoice is also available in the billing page. Please follow the instructions in the invoice to make the payment.
                                <br><br>
                                Click <a (click)="downloadInvoice(order_id)">here</a> to download the invoice.
                            </div>
                        </div>
                        <div *ngFor="let item of data_labels" class="certificate">
                            <div class="left-right-align header">
                                <h4 class="flex">
                                    <div class="{{item.type}}_white_icon category_icon"></div>
                                    {{item.label}} Performance Certificate
                                </h4>
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" *ngIf="download_cert[item.type] == null" (click)="goToCertificate(item.type, item.label)" class="btn btn-primary">Create a Certificate</button>
                                <button *ngIf="download_cert[item.type] != null" (click)="downloadCert(download_cert[item.type].certificate.id)" class="btn btn-primary inverse">View Certificate</button>
                            </div>
                            <div class="flex qualifiers relative-position">
                                <div class="mr20">
                                    <p>Performance Score</p>
                                    <p class="light_gray fs18"><span class="black-color">{{global.formatNumber(project_scores[item.type], 0, true)}}</span>/100
                                        <sup *ngIf="global.isNA(project_scores[item.type])" (click)="global.showHelpText('na_help')">
                                            <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('na_help')"></span>
                                        </sup>
                                    </p>
                                </div>
                                <div class="mr20">
                                    <p>Improvement Score</p>
                                    <p class="light_gray fs18"><span class="black-color">
                                        {{global.formatNumber((improvement_scores[item.type] == null ? 0 : improvement_scores[item.type].score), 0, true)}}
                                    </span>/100
                                        <sup *ngIf="global.isNA(improvement_scores[item.type])" (click)="global.showHelpText('na_help')">
                                            <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true"></span>
                                        </sup>
                                    </p>
                                </div>
                                <div *ngIf="download_cert[item.type] != null" class="mr20">
                                    <p>Reporting Period</p>
                                    <p><span class="ff-ss-black black-color">{{global.dateFormat(download_cert[item.type].date_certified)}}</span></p>
                                </div>
                                <div *ngIf="download_cert[item.type] != null" class="mr20">
                                    <p>Certificate Valid</p>
                                    <p *ngIf="download_cert[item.type].certification_expire">
                                        <span class="ff-ss-black black-color">{{global.dateFormat(download_cert[item.type].date_certified)}} to {{global.dateFormat(download_cert[item.type].certification_expire)}}</span>
                                    </p>
                                    <p *ngIf="!download_cert[item.type].certification_expire">
                                        <span class="ff-ss-black black-color">N/A</span>
                                    </p>
                                </div>
                                <div class="create_cert">
                                    <a *ngIf="download_cert[item.type] != null" (click)="goToCertificate(item.type, item.label)">Create a Certificate</a>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div id="past_certificates" *ngIf="current_tab == 'Past Certificates'">
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th class="cursor-pointer" (click)="sortLocalList(local_sort_order, 'date_certified', past_certificates)">
                                        Date Issued
                                        <span *ngIf="local_sort_column == 'date_certified'" class="fa fa-caret-{{local_sort_order == '-' ? 'down': 'up'}}"></span>
                                    </th>
                                    <th class="cursor-pointer" (click)="sortLocalList(local_sort_order, 'certification_level', past_certificates)">
                                        Certificate Type
                                        <span *ngIf="local_sort_column == 'certification_level'" class="fa fa-caret-{{local_sort_order == '-' ? 'down': 'up'}}"></span>
                                    </th>
                                    <th class="cursor-pointer" (click)="sortLocalList(local_sort_order, 'certification_expire', past_certificates)">
                                        Certificate Valid
                                        <span *ngIf="local_sort_column == 'certification_expire'" class="fa fa-caret-{{local_sort_order == '-' ? 'down': 'up'}}"></span>
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>View Certificate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let certificate of past_certificates">
                                    <td class="vertical-align-middle">
                                        {{global.dateFormat(certificate.date_certified)}}
                                    </td>
                                    <td class="vertical-align-middle">
                                        {{certificate.certification_level}} Performance Certificate
                                    </td>
                                    <td *ngIf="certificate.certification_expire" class="vertical-align-middle">
                                        {{global.dateFormat(certificate.date_certified)}} to {{global.dateFormat(certificate.certification_expire)}}
                                    </td>
                                    <td *ngIf="!certificate.certification_expire" class="vertical-align-middle">
                                        N/A
                                    </td>
                                    <td class="vertical-align-middle">
                                        <span [innerHTML]="getCertStatus(certificate)"></span>
                                    </td>
                                    <td class="vertical-align-middle">
                                        <a (click)="downloadCert(certificate.certificate.id)">Download</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="past_certificates.length == 0">
                                <tr>
                                    <td colspan="5" class="noProjects">
                                        No certificates created.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>

    </div>
</div>

<modal #modal>
    <div class="p2535">

        <div class="left-right-align">
            <div class="flex fontSize18 mb25 fw-600 error-color">
                <span class="error_symbol_trial"></span>
                <span class="error-lg-msg"> Subscription Expired!</span>
            </div> 
        </div>
    
        <p class="mb20 fontSize16 fw-400">To create new certificates you need to renew your Arc Performance Certificates Subscription</p>
        <p class="mb20 fontSize16 fw-400">
            <a>View Arc Performance Certificates Subscription pricing details</a>
        </p>
    
        <div class="mt30 pb50">
            <button (click)="renewSubs();modal.close()" type="button" class="btn btn-primary btn-md floatl">Renew Subscription</button>
            <button (click)="modal.close()" ng-click="cancel()" type="button" class="btn grey btn-default btn-md floatr">Close</button>
        </div>
    </div>
</modal>
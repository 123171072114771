import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from '../../services/validation.service';

import { Config } from '../../models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.css']
})
export class EcostruxureSelectProjectComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	@ViewChild('projects_modal') private projects_modal!: ModalComponent;
	@ViewChild('error_modal') private error_modal!: ModalComponent;

	steps = ["Select Projects", "Billing Information", "Generate Invoice"];
	select_all = false;

	current_step = this.steps[0];
	cost_of_one = 120;
	all_organizations: any = [];
	selected_projects: any = [];
	ownerType = [];
	sign_agreement = false;
	invoice_details: any = '';
	error_project: any = {};
	fields_mapping: any = {
		"spacetype": "Space type",
		"grossArea": "Gross area",
		"weeklyOperatingHours": "Operating hours",
		"buildingOccupants": "Occupants",
		"visitDuration": "Visit duration",
		"numberOfVisitorsPerDay": "Visitors per day",
		"daysPerWeekWithVisitors": "Days per week with visitors"
	}
	
	count = 0;
	page = 1;
	page_size = 30;
	page_count = 1;
	sort_column = '';
	sort_order = '';

	no_error: any = {
		'error': false,
		'help': undefined
	};
	paymentdata: any = {
		'first_name': '',
		'last_name': '',
		'email': this.appData.get('user_id'),
		'street': '',
		'city': '',
		'phone': '',
		'country': undefined,
		'state': undefined,
		'zip_code': '',
		'organization': '',
		"owner_email": "",
		"manageEntityCountry": undefined,
		"owner_name": ''
	};
	paymentdata_error: any = {
		'first_name': this.no_error,
		'last_name': this.no_error,
		'email': this.no_error,
		'street': this.no_error,
		'city': this.no_error,
		'phone': this.no_error,
		'country': this.no_error,
		'state': this.no_error,
		'zip_code': this.no_error,
		'organization': this.no_error,
		"owner_email": this.no_error,
		"manageEntityCountry": this.no_error,
		"ownerType": this.no_error,
		"owner_name": this.no_error
	};

	total_price: number = 0;
	loading: boolean = false;
	searchingOrganization: boolean = false;
	app_integrated: boolean = false;
	submit_error: boolean = false;
	country_list: any = [];
	states: any = [];
	app_assets: any = [];
	regex: any = this.validator.regex;
	canceler: any;

	query: string = '';

  	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService,
		private validator: ValidationService
	)
	{
		Config.hide_navbar = true;
	}

	ngOnInit(): void
	{
		this.api.getAsset('/assets/json/ownerType.json').subscribe(
			data =>
			{
				this.ownerType = data;
				this.paymentdata.ownerType = undefined;
			}
		);

		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.country_list = this.global.getCountryList();
				this.states = this.global.getStateList('US');
			});
		}
		else
		{
			this.country_list = this.global.getCountryList();
			this.states = this.global.getStateList('US');
		}

		this.getProjects(1, 30, this.sort_order + this.sort_column);
	}

	toggleFlag(key: string)
	{
		this.sign_agreement = !this.sign_agreement;
	};

	getProjects(page: number, page_size: number, ordering?: string)
	{
		$("#project_filters").hide();
		if(page > this.page_count || page <= 0)
		{
			return;
		}
		// this.selected_projects = [];
		//get projects
		this.loading = true;

		this.api.get('/ecostruxure/assets/').subscribe(
			//success handler
			data =>
			{
				this.app_assets = data;
				if(this.app_assets.constructor === Array)
				{
					this.app_integrated = true;
					this.count = this.app_assets.length;
					this.page_size = 10;
					this.page_count = Math.ceil(this.count / this.page_size);

					this.select_all = true;
					this.toggleCheck('all');
				}
				else
				{
					this.app_integrated = false;
				}

				this.loading = false;
			},
			//error handler
			error =>
			{
				this.app_integrated = false;
				this.loading = false;
			}
		);
	};

	getPricing(count: number)
	{
		this.loading = true;
		this.api.get('/ecostruxure/registration/price/?count=' + count).subscribe(
			//success handler
			data =>
			{
				this.total_price = data;
				this.loading = false;
			},
			//error handler
			error =>
			{
				this.total_price = 0;
				this.loading = false;
			}
		);
	};

	toggleCheck(index: any, select_all?: any)
	{
		if(select_all != undefined)
		{
			this.select_all = select_all;
		}
		if(index == 'all')
		{
			if(!this.select_all)
			{
				this.selected_projects = [];
			}
			for (var i = 0; i < this.app_assets.length; i++)
			{
				this.app_assets[i].select_all = this.select_all;
			}
		}
		else
		{
			this.app_assets[index].select_all = !this.app_assets[index].select_all;
			if(!this.app_assets[index].select_all)
			{
				this.select_all = false;
			}
		}

		for (var i = 0; i < this.app_assets.length; i++)
		{
			if(this.app_assets[i].synced || this.app_assets[i].datavalidate.length > 0)
			{
				continue
			}
			if(this.app_assets[i].select_all && this.selected_projects.indexOf(this.app_assets[i].id) == -1)
			{
				this.selected_projects.push(this.app_assets[i].id);
			}
			else if(!this.app_assets[i].select_all && this.selected_projects.indexOf(this.app_assets[i].id) > -1)
			{
				this.selected_projects.splice(this.selected_projects.indexOf(this.app_assets[i].id), 1)
			}
		}

		if(this.selected_projects.length == this.count)
		{
			this.select_all = true;
		}

		if(this.selected_projects.length == 0)
		{
			$('#bottom_nav').slideUp();
		}
		else
		{
			$('#bottom_nav').slideDown();
		}

		this.getPricing(this.selected_projects.length);
	};

	toggleList()
	{
		if($('#project_selection_table').css('display') == 'none')
		{
			$('#caret_project_selection').removeClass('rotate90');
			$('#project_selection_table').slideDown(500);
		}
		else
		{
			$('#caret_project_selection').addClass('rotate90');
			$('#project_selection_table').slideUp(500);
		}
	};

	downloadInvoice()
	{
		this.global.openLink(this.invoice_details, '_blank');
		this.global.goToApp('ecostruxure');
	};

	goToInvoice()
	{
		this.current_step = this.steps[2];
	};

	goToBilling()
	{
		this.current_step = this.steps[1];
	};

	goToProject()
	{
		this.current_step = this.steps[0];
	};

	goBack()
	{
		this.global.goToApp('ecostruxure');
	};

	submitDetails ()
	{
		var sync_ids = [];
		for(var i = 0; i < this.selected_projects.length; i++)
		{
			sync_ids.push(this.selected_projects[i]);
		}
		var payload = {
			"first_name": this.paymentdata.first_name,
			"last_name": this.paymentdata.last_name,
			"email": this.paymentdata.email,
			"phone": this.paymentdata.phone,
			"street": this.paymentdata.street,
			"city": this.paymentdata.city,
			"state": this.paymentdata.state,
			"country": this.paymentdata.country,
			"zip_code": this.paymentdata.zip_code,
			"owner_name": this.paymentdata.owner_name,
			"owner_type": this.paymentdata.ownerType,
			"organization": this.paymentdata.organization,
			"owner_email": this.paymentdata.owner_email,
			"owner_country": this.paymentdata.manageEntityCountry,
			"sync_ids": sync_ids,
			"sign_agreement": true
		}
		
		this.loading = true;
		this.api.post('/ecostruxure/registration/', payload).subscribe(
			//success handler
			data =>
			{
				this.invoice_details = data;
				this.goToInvoice();
				this.loading = false;
				this.alertService.alert('success', 'Project(s) submitted for registration. You will get a notification by E-Mail when project registration is done.', 10);
			},
			//error handler
			error =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Error registring projects', 10);
			}
		);
	};

	verifyField(data: any, key: any, optional?: any)
	{
		if(this.regex[key] == undefined)
		{
			return;
		}

		if(key == 'state')
		{
			this.paymentdata_error[key] = {
				'error': false,
				'help': undefined
			};
			return;
		}

		if(data == undefined || data == null || data.length == 0 || (data == 'null' && ['ownerType', 'manageEntityCountry', 'country'].indexOf(key) != -1))
		{
			if(optional)
			{
				this.paymentdata_error[key] = {
					'error': false,
					'help': undefined
				};
				delete this.paymentdata[key];
				return;
			}
			this.paymentdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.paymentdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.paymentdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		this.submit_error = false;
		for(var a in this.paymentdata_error)
		{
			if(this.paymentdata_error[a].error)
			{
				this.submit_error = true;
				break;
			}
		}
	};


	verifyDetails()
	{
		for(var a in this.paymentdata)
		{
			var optional = false;
			this.verifyField(this.paymentdata[a], a, optional);
		}

		this.submit_error = false;
		for(var a in this.paymentdata_error)
		{
			if(this.paymentdata_error[a].error)
			{
				this.submit_error = true;
				return;
			}
		}

		//OFAC validation starts
		this.loading = true;
		var org_country = this.paymentdata.manageEntityCountry;
		var org_name = this.paymentdata.organization;

		var org_full_country = this.global.getCountry(org_country);
		this.validator.validateOrganization(org_name, org_full_country).subscribe(
			data =>
			{
				if (data.status)
				{
					if (data.is_blocked)
					{
						this.loading = false;
						$('.ofac_invalid_modal').modal('show');
					}
					else
					{
						this.loading = false;
						this.submitDetails();
					}
				}
				else if("message" in data)
				{
					this.loading = false;
					this.alertService.alert('error', data.message, 10);
				}
				else
				{
					this.loading = false;
					this.alertService.alert('error', 'Could not verify Owner/Organization details', 10);
				}
			},
			data =>
			{
				this.loading = false;
				if("message" in data)
				{
					this.alertService.alert('error', data.message, 10);
				}
				else
				{
					this.alertService.alert('error', 'Something went wrong. Please try again.', 10);
				}
			}
		);
		//OFAC validation ends
	};

	openModal()
	{
		// $('#payment_owner_modal').modal('show');  
		// this.resetOrganizationDetails();
		this.modal.open();
	}

	closeModal()
	{
		this.modal.close();
	}

	getDataFromModal(event: any)
	{
		this.paymentdata.organization = event.formdata;
		this.paymentdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.modal.close();
	}

	updateCountry(selectedCountry: string)
	{
		this.states = this.global.getStateList(selectedCountry);
		if(this.states.length > 0)
		{
			this.paymentdata['state'] = this.states[0].code;
		}
		else
		{
			this.states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.paymentdata['state'] = "";
		}
		this.verifyField(selectedCountry, 'country');
	};

	searchDisableOrganization()
	{
		this.searchingOrganization = false;
	}
	
	searchEnableOrganization()
	{
		this.searchingOrganization = true;
	}

	searchOrganization(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		this.searchEnableOrganization();
		query = query.substr(0, 100);
		this.searchingOrganization = true;
		this.canceler = this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(data =>
		{
			this.all_organizations = data.owner_type;
		}, error =>
		{
		});
	}

	selectOrganization(org: string)
	{
		this.paymentdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.paymentdata.organization = org;
		this.searchDisableOrganization();
	}

	resetOrganizationDetails()
	{
		this.paymentdata.manageEntityCountry = undefined;
		this.paymentdata.organization = "";
		this.paymentdata_error['organization'] =
		{
			'error': false,
			'help': undefined
		};
		$('.ofac_invalid_modal').modal('hide');
	}

	disableSearch()
	{
		this.query = '';
		this.searchProjects(this.query);
	}

	searchProjects(query: string)
	{
		let exists = this.app_assets.every((obj: any) =>
		{
			return Object.values(obj).some(item =>
			{
				return typeof item === "string" && item.includes(query);
			});
		});

		for(let i = 0; i < this.app_assets.length; i++)
		{
			if(
				this.app_assets[i].name.toLowerCase().includes(query.toLowerCase()) ||
				(this.app_assets[i].postalAddress.address != undefined && this.app_assets[i].postalAddress.address.toLowerCase().includes(query.toLowerCase())) ||
				(this.app_assets[i].postalAddress.city != undefined && this.app_assets[i].postalAddress.city.toLowerCase().includes(query.toLowerCase())) ||
				(this.app_assets[i].postalAddress.countryIsoCode != undefined && this.app_assets[i].postalAddress.countryIsoCode.toLowerCase().includes(query.toLowerCase())) ||
				(this.app_assets[i].postalAddress.stateProvince != undefined && this.app_assets[i].postalAddress.stateProvince.toLowerCase().includes(query.toLowerCase())) ||
				(this.app_assets[i].postalAddress.zipCode != undefined && this.app_assets[i].postalAddress.zipCode.toLowerCase().includes(query.toLowerCase()))
			)
			{
				this.app_assets[i].found = true;
			}
			else
			{
				this.app_assets[i].found = false;
			}
		}
	}

	closeProjectsModal()
	{
		this.projects_modal.close();
	}

	openProjectsModal()
	{
		this.projects_modal.open({size: 'lg'});
		window.scrollTo(
		{
			top: 0,
			behavior: 'smooth',
		});
	}

	openErrorModal(project: any)
	{
		this.error_modal.open({size: 'md'});
		this.error_project = project;
	}

	closeErrorModal()
	{
		this.error_modal.close();
	}
}

<div class="content-wrap">
	<div id="content" class="content">
       
        <h1 class="page-title">
            <span class="fw-semi-bold visible-xs-inline">Manage</span>
            <span class="fw-semi-bold" translate>Score Version</span>
        </h1>
        
        <section [ngClass]="loading ? 'not-active' : ''" class="widget overflow-auto fw-500 pl30 pb25">
            <app-loader *ngIf="loading"></app-loader>
            <h3 class="mb20 mt10 fw-500" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">Arc score v{{appData.get('buildingData').score_code_version.name}}</h3>
            <h3 class="mb20 mt10 fw-500" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">Arc score for 
                <span *ngIf="appData.get('buildingData').project_type == 'city'">cities</span>
                <span *ngIf="appData.get('buildingData').project_type == 'community'">communities</span>
             version {{appData.get('buildingData').score_code_version.name}}</h3>
            <div *ngIf="!appData.get('buildingData').score_version_update_pending">
                <div *ngIf="!appData.get('buildingData').score_code_version.latest" class="row override_row_margin">
                    <h4 class="fw-500 mb15">
                        <i class="score_version_notuptodate_icon fa fa-exclamation-circle pr5"></i> 
                        <span *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'"> Update to Arc score v{{config.new_score_version_detail.name}}</span>
                        <span *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'"> Update to Arc scoring algorithm version {{config.new_score_version_detail.name.split('.')[0]}}</span>
                    </h4>
                    <p class="fw-500 pt5 fs16" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">Get all the answers and learn more about updating to the latest score version.</p>
                    <p class="fw-500 pt5 fs16" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">We have made some important updates to the scoring algorithm for {{appData.get('buildingData').project_type}} projects in Arc! Get all the answers, and learn more about the latest score version and how {{appData.get('buildingData').project_type}} projects are scored in Arc. If you have any questions please email <a (click)="global.scoreVrsionMailto()">contact@arcskoru.com</a>.</p>
                    
                    <ul class="pb5" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                        <li class="gray-color pt5">What are the changes under the performance score v{{config.new_score_version_detail.name}}?</li>
                        <li class="gray-color pt5" *ngIf="config.new_score_version_detail.name == '2.1'">How does my project score in LEED v4 O+M?</li>
                        <li class="gray-color pt5" *ngIf="config.new_score_version_detail.name != '2.1'">How does my project score in LEED v4.1 O+M?</li>
                        <li class="gray-color pt5 pb5">Learn the details of this update and previous score updates.</li>
                    </ul>
                    <ul class="pb5" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">
                        <li class="gray-color pt5">What are the changes under version 2 of Arc's scoring algorithm for LEED for 
                            <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities?</span>
                            <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities?</span>
                        </li>
                        <li class="gray-color pt5">How does my project score in version 2 of Arc's scoring algorithm for LEED for 
                            <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities?</span>
                            <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities?</span>
                        </li>
                    </ul>
                    
                    <p *ngIf="appData.get('buildingData').score_code_version.sunset_date_notification">After {{config.final_date_to_update_string_val}}, this version of the score will be required in order to submit  
                        <span *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">for all initial performance based certifications and most performance based recertifications.</span> 
                        <span *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">for review for all initial and recertifications.</span> 
                    </p>
                    <p *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'" class="pb20">Do you have a project submitting for review that will be impacted by this change? Let us know at <a href="mailto:contact@arcskoru.com">contact@arcskoru.com</a></p>
                    <div class="col-md-12 pl0 mb15">
                        <button class="btn btn-primary mr20 arc-primary col-md-4 score_version_remind_button mb10" role="button" (click)="global.goToScoreComparison(config.new_score_version_detail.name, config.new_score_version_detail.id)"><i class="fas fa-sync mr10 pr3"></i> Learn More & Update</button>
                    </div>
        
                    <div>
                        <p class="pb10 fs16">Choose a date when you’d like to receive a reminder:</p>
                        <p>REMINDER DATE</p>
                        <p class='input-group date pb5'>
                            <input type='text' [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="form-control score_version_form_readonly" id="score_notification_reminder" [(ngModel)]='config.score_notification_reminder' [ngInit]="enableFdatePicker()" value={{config.score_notification_reminder}} placeholder="MMM DD, YYYY" readonly>
                            <span class="ml10 remindMeSpinner score_version_remind_me" *ngIf="remind_me_on_loader"></span>
                        </p>
                        <button [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="btn btn-default mr20 arc-default mb5" role="button" (click)="remind_me_on()">SUBMIT</button>
                    </div>
                </div>
                <div *ngIf="appData.get('buildingData').score_code_version.latest">
                    <h4 class="fw-500 mb20">
                        <img src="/assets/images/done_green_icon.svg" class="score_version_uptodate_icon">You are all up to date!
                    </h4>
                </div>
            </div>
            <div *ngIf="appData.get('buildingData').score_version_update_pending" class="row">
                <div class="override_row_margin">
                    <h4 class="fw-500 mb20">
                        <i class="score_version_notuptodate_icon_yellow fa fa-exclamation-triangle pr5"></i> Update to Arc score v{{pending_to_version_name}} is pending
                    </h4>
                    <p>Your score version will be updated to v{{pending_to_version_name}} once the current certification review is complete.</p>
                </div>
            </div>
            <div>
                <div class="card_divider"></div>
                <h4 class="fw-600 mt20 mb20">Activity</h4>
                <table>
                    <tbody>
                        <tr class="pb10" *ngFor="let value of version_update_log">
                            <td class="fw-500 pb10">{{logDateTime(value.created_at, 'date')}}</td>
                            <td class="pl20 pb10">{{logDateTime(value.created_at, 'time')}}</td>
                            <td class="pl20 pb10" *ngIf="value.log_type == 'version_update'">
                                <div *ngIf="value.direct_update">
                                    {{value.created_by.first_name}} updated to Arc score v{{value.updated_to}}
                                </div>
                                <div *ngIf="!value.direct_update">
                                    <div *ngIf="value.completed">
                                        Arc score updated to v{{value.updated_to}}
                                    </div>
                                    <div *ngIf="!value.completed">
                                        {{value.created_by.first_name}} requested an update to Arc score v{{value.updated_to}} - this update will take effect once the certification review is complete
                                    </div>
                                </div>
                            </td>
                            <td class="pl20 pb10" *ngIf="value.log_type == 'extension_request'">
                                {{value.created_by.first_name}} requested extension for Arc score v{{value.current_version}}
                            </td>
                            <td class="pl20 pb10" *ngIf="value.log_type == 'version_downgrade'">
                                {{value.created_by.first_name}} downgraded to Arc score v{{value.updated_to}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>

    </div>
</div>

<div class="content-wrap" [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
	<div id="content" class="content">
        
        <div id="" class="view-animate fade-up overflow_y_initial" role="main">
            <h1 class="page-title">
                <span class="fw-semi-bold">Pay Now</span>
            </h1>
            <div class="row">
                <div class="col-md-12 pl0">
                    <section class="widget" [ngClass]="submitPaymentLoader ? 'not-active' : ''">
                        <div class="row">
                            <div id="pay_now_by_card" class="col-md-8 pl0">
                                <div class="gray_bg pt10">
                                    <!-- Card details starts -->
                                    <div class="row ml0 mr0 pl20 mt20 pr20">
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.name.test(billing_history.first_name) && isFormSubmitted) || FN_fail_CC}">
                                            <label for="firstname" class="fw-600">First Name</label>
                                            <input [(ngModel)]="billing_history.first_name" maxlength="20" type="text" id="firstname" placeholder="" name="firstname" placeholder="" class="form-control" (blur)="validateFNCC()">
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.name.test(billing_history.first_name) && isFormSubmitted) || FN_fail_CC">This field is required.</span>
                                        </div>
        
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.name.test(billing_history.last_name) && isFormSubmitted) || LN_fail_CC}">
                                            <label for="lastname" class="fw-600">Last Name</label>
                                            <input [(ngModel)]="billing_history.last_name" maxlength="19" type="text" id="lastname" placeholder="" name="lastname" placeholder="" class="form-control" (blur)="validateLNCC()">
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.name.test(billing_history.last_name) && isFormSubmitted) || LN_fail_CC">This field is required.</span>
                                        </div>
                                    </div>
        
                                    <div class="row ml0 mr0 pl20 pr20 fs-block">
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.CC_number.test(billing_history.cc_details.CcNumber) && isFormSubmitted) || cc_fail}">
                                            <label for="cardnumber" class="fw-600">Card Number</label>
                                            <input [(ngModel)]="billing_history.cc_details.CcNumber" maxlengt="40" type="text" id="cardnumber" placeholder="" name="cardnumber" placeholder="" class="form-control pull-left fs-block" (input)=detectCreditCard() autocomplete="off" (blur)="validateCC()">
                                            <i class="fa fa-lock pull-right" aria-hidden="true"></i>
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.CC_number.test(billing_history.cc_details.CcNumber) && isFormSubmitted) || cc_fail">{{regex.CC_numberMsg}}</span>
                                        </div>
        
                                        <div class="form-group col-md-6 pl0 mt30">
                                            <label for="credit-card-type width-100-perc mr20"></label>
                                                            
                                            <img [ngClass]="mastercard_select ? 'removeOpacity' : ''" src="../../../../../assets/images/mastercard.png" class="card_img_paynow mastercard"/>
        
                                            <img [ngClass]="visa_select ? 'removeOpacity' : ''" src="../../../../../assets/images/visa.png" class="card_img_paynow visa"/>
        
                                            <img [ngClass]="discover_select ? 'removeOpacity' : ''" src="../../../../../assets/images/discover.png" class="card_img_paynow discover"/>
        
                                            <img [ngClass]="amex_select ? 'removeOpacity' : ''" src="../../../../../assets/images/amex.png" class="card_img_paynow amex"/>
                                        </div>
                                    </div>
        
                                    <div class="row ml0 mr0 pl20 pr20 fs-block">
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.CC_expiry.test(billing_history.cc_details.CcValidT) && isFormSubmitted) || exp_validation_fail}">
                                            <label for="expiration-date" class="fw-600">Expiration Date</label>
                                            <input [(ngModel)]="billing_history.cc_details.CcValidT" id="expiration-date" placeholder="mm/yyyy" maxlength="7" name="CC_expiry" class="form-control pull-right fs-block" autocomplete="off" (keyup)="validateCardExpiry()" (blur)="validateCardExpiry()">
                                            <i class="fa fa-lock pull-right" aria-hidden="true"></i>
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.CC_expiry.test(billing_history.cc_details.CcValidT) && isFormSubmitted) || exp_validation_fail">{{regex.CC_expiryMsg}}</span>
                                        </div>
                                            
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.CC_CVV.test(billing_history.cc_details.CcVerifValue) && isFormSubmitted) || cvv_fail}">
                                            <label for="CC_number" class="fw-600">CVV</label>
                                            <input [(ngModel)]="billing_history.cc_details.CcVerifValue" id="CC_number" placeholder="CVV Number" maxlength="7" name="CC_number" class="form-control fs-block" autocomplete="off" (keyup)="validateCVV()" (blur)="validateCVV()">
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.CC_CVV.test(billing_history.cc_details.CcVerifValue) && isFormSubmitted) || cvv_fail">{{regex.CC_CVVMsg}}</span>
                                        </div>
                                    </div>
                                    <div class="row ml0 pl20 mt10 pr50">
                                        <div class="col-md-12 light_gray_border"></div>
                                    </div>
                                    <!-- Card details ends -->
        
                                    <!-- Address details starts -->
                                    <div class="row ml0 mr0 pl20 mt20 pr20 CC_valid" [ngClass]="{'has-error': (!regex.name.test(billing_history.streetaddress) && isFormSubmitted) || street_fail_CC}">
                                        <div class="form-group col-md-12 pl0">
                                            <label for="streetaddress" class="fw-600">Street Address</label>
                                            <input [(ngModel)]="billing_history.street" maxlength="40" type="text" id="streetaddress" placeholder="" name="streetaddress" placeholder="" class="form-control" (keyup)="validateStreetCC()" (blur)="validateStreetCC()">
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.name.test(billing_history.streetaddress) && isFormSubmitted) || street_fail_CC">This field is required.</span>
                                        </div>
                                    </div>
        
                                    <div class="row ml0 mr0 pl20 pr20">
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': (!regex.name.test(billing_history.city) && isFormSubmitted) || city_fail_CC}">
                                            <label for="city" class="fw-600">City</label>
                                            <input [(ngModel)]="billing_history.city" maxlength="40" type="text" id="city" placeholder="" name="city" placeholder="" class="form-control" (keyup)="validateCityCC()" (blur)="validateCityCC()"> 
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.name.test(billing_history.city) && isFormSubmitted) || city_fail_CC">This field is required.</span>
                                        </div>
        
                                        <div class="form-group col-md-6 pl0 pr0">
                                            <label for="lastname" class="fw-600">State</label>
                                            <select id="state" class="form-control w95p" name="state" [(ngModel)]="billing_history.state">
                                                <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                    <div class="row ml0 mr0 pl20 pr20">
                                        <div class="form-group col-md-6 pl0">
                                            <label for="country" class="fw-600">Country/Region</label>
                                            <select [(ngModel)]="billing_history.country" (change)="updateCountry(billing_history.country)" id="country" class="form-control">
                                                <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                            </select>
                                        </div>
        
                                        <div class="form-group col-md-6 pl0 CC_valid" [ngClass]="{'has-error': validation_error || postalcode_fail_CC}">
                                            <label for="zip_code" class="fw-600">Zip Code</label>
                                            <input [(ngModel)]="billing_history.zip_code" maxlength="40" type="text" id="postalcode" placeholder="" name="postalcode" placeholder="" class="form-control" (focus)="removeError()" (keyup)="validatePostalcodeCC()" (blur)="validatePostalcodeCC()">
                                            <span class="help-block required-field" *ngIf="(!regex.name.test(billing_history.zip_code) && isFormSubmitted) || postalcode_fail_CC">This field is required.</span>
                                            <span class="help-block has-error" *ngIf="validation_error">Please enter a valid zip code.</span>
                                        </div>
                                    </div>
        
                                    <div class="row ml0 mr0 pl20 pr20">
                                        <div class="form-group col-md-12 pl0 CC_valid" [ngClass]="{'has-error': (!regex.phone_number.test(billing_history.phone) && isFormSubmitted) || phone_fail_CC}">
                                            <label for="phone" class="fw-600">Phone</label>
                                            <input [(ngModel)]="billing_history.phone" maxlength="40" type="text" id="phone" placeholder="" name="phone" placeholder="" class="form-control" (keyup)="validatePhoneCC()" (blur)="validatePhoneCC()">
                                            
                                            <span class="help-block required-field" *ngIf="(!regex.phone_number.test(billing_history.phone) && isFormSubmitted) || phone_fail_CC">
                                                <span *ngIf="billing_history.phone.length > 0">Please enter a valid phone number.</span>
                                                <span *ngIf="billing_history.phone.length == 0">This field is required.</span>
                                                </span>
                                        </div>
                                    </div>
                                    <!-- Address details ends -->
        
                                    <div class="row ml0 pl20 pr50">
                                        <div class="col-md-12 light_gray_border"></div>
                                    </div>
                                    
                                    <!-- Recipient details starts -->
                                    <div class="row ml0 mr0 pl20 pr20">
                                        <div class="form-group col-md-12 pl0 mt20" [ngClass]="{'has-error': recipient_email_fail}">
                                            <label for="phone" class="fw-600 mb10">A copy of the invoice will be sent to the following email addresses. Separate by comma (,)</label>
                                            <input [(ngModel)]="billing_history.recipient_email" type="text" id="recipient_email" name="recipient_email" class="form-control resize_none" (keyup)="validateRecipientEmail()" (blur)="validateRecipientEmail()">
                                            
                                            <span class="help-block" *ngIf="recipient_email_fail">
                                                <span *ngIf="billing_history.recipient_email.length > 0">Please enter valid email(s) separate by comma.</span>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Recipient details ends -->
                                    <div class="row ml0 mr0 pl20 pr20">
                                        <div class="form-group col-md-6 pl0" >
                                            <h2 class="mt10">Total: 
                                                <span class="project-price" *ngIf="mypayments.Currency == 'INR'">₹{{global.formatNumberCommas(mypayments.Netwr, 2)}}</span>
                                                <span class="project-price" *ngIf="mypayments.Currency != 'INR'">${{global.formatNumberCommas(mypayments.Netwr, 2)}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="align-center mt30">
                                    <button [ngClass]="{'not-active': submitPaymentLoader}" class="mr10 light_blue_btn btn-primary btn cursor-pointer floatl" (click)="cancelPayment()"> 
                                        <span class="font-weight-600">Cancel</span>
                                    </button>
                                    <button [ngClass]="{'not-active': submitPaymentLoader}" class="light_blue_btn btn-primary btn cursor-pointer floatr" (click)="submitPayment()"> 
                                        <span *ngIf="submitPaymentLoader" class="dataInputLoaderSpinner" style="border-top-color: white;border-left-color: white;float: left;margin-right: 10px;margin-top: 6px;"></span>
                                        <span class="font-weight-600">{{paynow_btn_text}}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div id="project-details">
                                    <div class="edit-header pb10">
                                        <span class="fs16 font-weight-600">Order Details</span>
                                    </div>
                                    <table cellspacing="0" cellpadding="0" class="paymentSidebarTable">
                                        <tbody>
                                            <tr>
                                                <th>Invoice #</th>
                                                <td>{{mypayments.Vbeln}}</td>
                                            </tr>
                                            <tr>
                                                <th>Invoice date</th>
                                                <td>{{global.dateFormat(mypayments.Erdat)}}</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <span *ngIf="mypayments.Currency == 'INR'">₹</span>
                                                    <span *ngIf="mypayments.Currency == 'USD'">$</span>
                                                    <span>{{global.formatNumberCommas(mypayments.Netwr, 2)}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- Paymetric form starts-->
                        <iframe id="paymetric_iframe">
                        </iframe>
                        <!-- Paymetric form ends -->
                    </section>
                </div>
            </div>
        </div>

    </div>
</div>

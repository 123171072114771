<div class="content-wrap">
	<div id="content" class="content">
        
        <h3 class="fw-500 black-color pl15 mb25 pt5" translate>
            Overview
        </h3>
        
        <div [ngClass]="loading || map_loading ? 'not-active' : ''">
            <app-loader *ngIf="loading || map_loading"></app-loader>
            <div class="mb16">
                <div class="portfolioWidgets--wrapper">
                    <div class="portfolioWidget" (click)="openPortfolioProjectsSideBar('portfolio-projects', $event)">
                        <div class="portfolioWidget--label" translate>Portfolio Projects</div>
                        <div class="portfolioWidget-data--wrapper">
                            <p class="portfolioWidget-data--value">{{global.handleNaN(global.formatNumber(analytics_summary.total_projects, 0))}}</p>
                        </div>
                    </div>
                    <div class="portfolioWidget" (click)="openPortfolioProjectsSideBar('total-floor-area', $event)">
                        <div class="portfolioWidget--label" translate>Total Floor Area</div>
                        <div class="portfolioWidget-data--wrapper">
                            <p class="portfolioWidget-data--value">
                                {{global.handleNaN(global.formatNumber(analytics_summary.total_gross_area, 2))}}
                            </p>
                            <div class="portfolioWidget-data--units">
                                <div class="dropdown">
                                    <button type="button" data-toggle="dropdown" class="stop--default__behaviour">
                                        <span>{{si_unit_label}}</span>
                                        <span class="portfolioWidget--unitSelector__downArrow"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeUnit('si')" class="stop--default__behaviour">
                                            <a class="stop--default__behaviour">SI units (meters)</a>
                                        </li>
                                        <li (click)="changeUnit('ip')" class="stop--default__behaviour">
                                            <a class="stop--default__behaviour">IP units (feet)</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="portfolioWidget" (click)="openPortfolioProjectsSideBar('annual-emissions', $event)">
                        <div class="portfolioWidget--label" translate>Estimated Annual Emissions</div>
                        <div class="portfolioWidget-data--wrapper">
                            <p class="portfolioWidget-data--value">
                                {{global.handleDecimals(annual_emission)}}
                            </p>
                            <div class="portfolioWidget-data--units">
                                <span>mtCO2e</span>
                            </div>
                        </div>
                    </div>
                    <div class="portfolioWidget" (click)="openPortfolioProjectsSideBar('total-occupants', $event)">
                        <div class="portfolioWidget--label" translate>Total Occupants</div>
                        <div class="portfolioWidget-data--wrapper">
                            <p class="portfolioWidget-data--value">
                                {{global.handleNaN(global.formatNumber(analytics_summary.total_occupancy, 0))}}
                            </p>
                        </div>
                    </div>
                    <div class="portfolioWidget" (click)="openPortfolioProjectsSideBar('total-certified-area', $event)">
                        <div class="portfolioWidget--label" translate>Total Certified Area</div>
                        <div class="portfolioWidget-data--wrapper">
                            <p class="portfolioWidget-data--value">
                                {{global.handleNaN(total_certified_area_sqft)}}
                            </p>
                            <div class="portfolioWidget-data--units">
                                <div class="dropdown">
                                    <button type="button" data-toggle="dropdown" class="stop--default__behaviour">
                                        <span>{{si_unit_label}}</span>
                                        <span class="portfolioWidget--unitSelector__downArrow"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li (click)="changeUnit('si')" class="stop--default__behaviour">
                                            <a class="stop--default__behaviour">SI units (meters)</a>
                                        </li>
                                        <li (click)="changeUnit('ip')" class="stop--default__behaviour">
                                            <a class="stop--default__behaviour">IP units (feet)</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
        
            <div class="mb16" id="portfolio_map"></div>
        
            <div class="widget_container" style="width: 98%;margin-left: 15px;">
                <app-widget class="mr15" [leed_id]="null" [src]="'arcpartner'" [param]="'&partner=certificate'" [width]="'32%'" [height]="'300px'"></app-widget>
                <app-widget class="mr15" [leed_id]="null" [src]="'arcpartner'" [param]="'&partner=arbnco'" [width]="'32%'" [height]="'300px'"></app-widget>
                <app-widget [leed_id]="null" [src]="'arcpartner'" [param]="'&carousel=true&exclude=arbnco'" [width]="'32%'" [height]="'300px'"></app-widget>
            </div>
        </div>
        

    </div>
</div>

<div>
	<div>
       
        <div>

            <div [ngClass]="loading ? 'not-active' : ''" class="pl15 pr15">
                <app-loader *ngIf="loading"></app-loader>
                
                <div class="insight_widget insight_project_reduction">
                    <div id="details-form" class="flex">
                        <div class="w34p fs16 line_height_24">
                            Start comparing by selecting up to 3 projects and models of your choice
                        </div>
                        <div class="comparison_project">
                            <div class="select-group group">   
                                <label class="fs14">Project</label>   
                                <select (change)="modelSelected('project_1', scope.project_1)" [(ngModel)]="scope.project_1" name="scope.project_1">
                                    <option *ngFor="let asset of assets" [value]="asset.leed_id">
                                        {{asset.name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                            <div class="select-group group">   
                                <label class="fs14">Model</label>   
                                <select [(ngModel)]="scope.project_1_model" name="scope.project_1_model">
                                    <option *ngFor="let version of scope.project_1_model_versions" [value]="version.version_id">
                                        {{version.version_name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </div>

                        <div class="comparison_project">
                            <div class="select-group group">   
                                <label [hidden]="scope.project_2 == undefined" class="fs14">Project</label>   
                                <select (change)="modelSelected('project_2', scope.project_2)" [(ngModel)]="scope.project_2" name="scope.project_2">
                                    <option *ngFor="let asset of assets; let idx = index;" [value]="asset.leed_id"> 
                                        {{asset.name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                            <div class="select-group group">   
                                <label [hidden]="scope.project_2_model == undefined" class="fs14">Model</label>   
                                <select [(ngModel)]="scope.project_2_model" name="scope.project_2_model">
                                    <option *ngFor="let version of scope.project_2_model_versions" [value]="version.version_id">
                                        {{version.version_name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </div>

                        <div class="comparison_project">
                            <div class="select-group group">   
                                <label [hidden]="scope.project_3 == undefined" class="fs14">Project</label>   
                                <select (change)="modelSelected('project_3', scope.project_3)" [(ngModel)]="scope.project_3" name="scope.project_3">
                                    <option *ngFor="let asset of assets; let idx = index;" [value]="asset.leed_id"> 
                                        {{asset.name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                            <div class="select-group group">
                                <label [hidden]="scope.project_3_model == undefined" class="fs14">Model</label>   
                                <select [(ngModel)]="scope.project_3_model" name="scope.project_3_model">
                                    <option *ngFor="let version of scope.project_3_model_versions" [value]="version.version_id">
                                        {{version.version_name}}
                                    </option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </div>
                    </div>

                    <table id="comparison_performance" class="mt13 table table-striped arc-table arc-table-insight">
                        <thead>
                            <tr>
                                <th class="w34p">
                                    <div class="fs18 fw-500 line_height_24">Performance</div>
                                </th>
                                <th>
                                    <div class="fs14 gray_color_707479 fw-400 align-center line_height_24">{{scope.project_1_object.rating_system}}</div>
                                </th>
                                <th>
                                    <div class="fs14 gray_color_707479 fw-400 align-center line_height_24">{{scope.project_2_object.rating_system}}</div>
                                </th>
                                <th>
                                    <div class="fs14 gray_color_707479 fw-400 align-center line_height_24">{{scope.project_3_object.rating_system}}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="flex">
                                    <div class="energy_plq_icon_circle"></div>
                                    <div class="ml15 fs16 line_height_24">Energy Reduction</div>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_1_energy_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_2_energy_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_3_energy_estimate}}%</span>
                                </td>
                            </tr>	
                            <tr>
                                <td class="flex">
                                    <div class="water_plq_icon_circle"></div>
                                    <div class="ml15 fs16 line_height_24">Water Reduction</div>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_1_water_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_2_water_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_3_water_estimate}}%</span>
                                </td>
                            </tr>	
                            <tr>
                                <td class="flex">
                                    <div class="waste_plq_icon_circle"></div>
                                    <div class="ml15 fs16 line_height_24">Waste Reduction</div>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_1_waste_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_2_waste_estimate}}%</span>
                                </td>
                                <td class="align-center">
                                    <span class="fw-500 fs18 line_height_24">-{{scope.project_3_waste_estimate}}%</span>
                                </td>
                            </tr>		    
                        </tbody>
                    </table>
                    <table id="comparison_strategies" class="mt13 table table-striped arc-table arc-table-insight">
                        <thead>
                            <tr>
                                <th class="w34p">
                                    <div class="fs18 fw-500 line_height_24">Strategies</div>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/lt-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Location and Transportation</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['LT'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['LT'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['LT'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['LT'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['LT'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['LT'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['LT'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['LT'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['LT'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['LT'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['LT'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['LT'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['LT'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['LT'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['LT'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/ss-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Sustainable Sites</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['SS'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['SS'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['SS'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['SS'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['SS'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['SS'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['SS'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['SS'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['SS'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['SS'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['SS'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['SS'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['SS'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['SS'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['SS'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/we-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Water Efficiency</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['WE'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['WE'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['WE'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['WE'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['WE'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['WE'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['WE'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['WE'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['WE'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['WE'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['WE'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['WE'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['WE'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['WE'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['WE'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/ea-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Energy and Atmosphere</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['EA'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['EA'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['EA'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['EA'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['EA'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['EA'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['EA'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['EA'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['EA'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['EA'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['EA'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['EA'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['EA'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['EA'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['EA'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/mr-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Materials and Resources</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['MR'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['MR'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['MR'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['MR'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['MR'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['MR'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['MR'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['MR'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['MR'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['MR'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['MR'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['MR'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['MR'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['MR'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['MR'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/eq-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Indoor Environmental Quality</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['EQ'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['EQ'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['EQ'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['EQ'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['EQ'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['EQ'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['EQ'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['EQ'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['EQ'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['EQ'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['EQ'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['EQ'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['EQ'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['EQ'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['EQ'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/in-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Innovation</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['IN'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['IN'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['IN'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['IN'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['IN'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['IN'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['IN'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['IN'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['IN'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['IN'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['IN'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['IN'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['IN'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['IN'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['IN'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/rp-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Regional Priority</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['RP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['RP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['RP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['RP'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['RP'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['RP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['RP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['RP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['RP'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['RP'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['RP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['RP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['RP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['RP'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['RP'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="flex">
                                    <img style="width: 28px;height: 28px;" src="../../../assets/images/icons/leed/ip-border.png" class="ui rounded image">
                                    <div class="ml15 fs16 line_height_24">Integrative Process</div>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_1']['IP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_1']['IP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_1']['IP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['IP'] == null && scope.project_1_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_1']['IP'] == null && scope.project_1_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_2']['IP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_2']['IP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_2']['IP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['IP'] == null && scope.project_2_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_2']['IP'] == null && scope.project_2_object != undefined">
                                        NA
                                    </span>
                                </td>
                                <td class="align-center">
                                    <span *ngIf="comparison['project_3']['IP'] != null" class="fw-500 fs18 line_height_24">
                                        <span>{{comparison['project_3']['IP'].attempted}}</span><span class="grey_color_light">/{{comparison['project_3']['IP'].max_points}}</span>
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['IP'] == null && scope.project_3_object == undefined">
                                        -
                                    </span>
                                    <span class="fw-500 fs18 line_height_24" *ngIf="comparison['project_3']['IP'] == null && scope.project_3_object != undefined">
                                        NA
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    </div>
</div>

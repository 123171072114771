import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { alertService } from '../services/alert-service.service';
import { Projects } from '../services/projects.service';

import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-selected-project',
  templateUrl: './selected-project.component.html',
  styleUrls: ['./selected-project.component.css']
})

export class SelectedProjectComponent implements OnInit
{
	config = Config;
	portfolios: any = [];
	selected_portfolios: any = [];

	constructor(
		public appData: AppData,
		public global: Global,
		private route: ActivatedRoute,
		private projects: Projects,
		private alertService: alertService,
	)
	{
		route.params.subscribe((params) =>
		{
			this.appData.set('leed_id', params['leed_id']);
			this.appData.set('app_name', params['app_name']);
			this.appData.set('current_app', "selected_project");
		});
	}

	ngOnInit(): void
	{
		if(this.appData.get('app_name').indexOf('survey') == -1)
		{
			this.getPortfolios();
		}
		this.appData.set('project_type', this.appData.get('buildingData').project_type);
	}

	getPortfolios()
	{
		this.projects.getPortfolios().subscribe((data: any) =>
		{
			this.portfolios = data.results;
		});  
	};

	createPortfolio()
	{
		this.appData.set("selected_projects_session", [this.appData.get('leed_id')]);
		this.global.goToApp('portfolio-creation');
	};

	selectPortfolio(portfolio: any)
	{
		if(this.selected_portfolios.indexOf(portfolio.pf_id) == -1)
		{
			this.selected_portfolios.push(portfolio.pf_id);
			// $("input[name='"+ portfolio.pf_id +"']").prop('checked', true);
		}
		else
		{
			this.selected_portfolios.splice(this.selected_portfolios.indexOf(portfolio.pf_id), 1);
			// $("input[name='"+ portfolio.pf_id +"']").prop('checked', false);
		}
	}

	addToPortfolio()
	{
		Config.app_loader = true;
		this.global.addToPortfolio(
			this.selected_portfolios,
			[this.appData.get('leed_id')]
		).subscribe(
			data =>
			{
				Config.app_loader = false;
				this.selected_portfolios = [];
				this.alertService.alert('success', 'Projects added to portfolio', 5);
			},
			error =>
			{
				Config.app_loader = false;
				try
				{
					this.alertService.alert('error', error.error.detail, 5);
				}
				catch(e)
				{
					this.alertService.alert('error', "Error adding project to Portfolio", 5);
				}
			}
		);
	}

}

<div *ngIf="mode != 'arc'" class="h100p bg-white" [ngClass]="loading ? 'not-active' : ''">
	<app-loader *ngIf="loading"></app-loader>
	<div class="row login-container height-auto">
		<form name="login_form">
			<div class="col-md-12 mb25 align-center mt40">
				<img src="../../assets/images/arc-logo-black.png" class="login-arc-logo">
			</div>
			<div *ngIf="mode == 'arc' || mode == 'admin' || mode == 'arc_japan_invoice' || mode == 'cagbc_invoice'" translate class="col-md-12 fs24 ls0 line_height_32 mb8 align-center">Log in with your USGBC account.</div>
			<div *ngIf="mode == 'insight'" translate class="col-md-12 fs24 ls0 line_height_32 mb8 align-center">Please login with a DEV user to access the Arc Insight Tool.</div>
			<div class="col-md-12 fs16 ls0 line_height_24 mb20 align-center">{{'New to USGBC?'|translate}} <a (click)="loginWithRedirect('signup')" translate>Create account.</a></div>
			
			<!-- <div *ngIf="mode == 'arc' || mode == 'admin' || mode == 'arc_japan_invoice' || mode == 'cagbc_invoice'" translate class="col-md-12 fs14 ls0 line_height_18 mb16">By clicking on "Log In" below, you affirm that you continue to abide by our <a target="_blank" href="{{config.config.settings.landingPageURL}}cookie-statement">Cookies Statement</a>, <a target="_blank" href="{{config.config.settings.landingPageURL}}privacy-statement">Privacy Statement</a> and <a target="_blank" href="{{config.config.settings.landingPageURL}}terms">Terms of Use</a> for the lawful use of this website, as these policies have recently been changed.</div> -->

			<div class="col-md-12">
				<button *ngIf="mode == 'arc' || mode == 'admin' || mode == 'arc_japan_invoice' || mode == 'cagbc_invoice'" (click)="loginWithRedirect()" type="submit" class="w100p login-button-new">{{login_btn_text}}</button>
				<button *ngIf="mode == 'insight'" (click)="insight.insightLogin(insight.insight_user_id, insight.insight_password)" type="submit" class="w100p login-button-new">{{login_btn_text}}</button>
			</div>
			<div *ngIf="config.isAuthenticated">
				<a (click)="global.logout()">
					Logout
				</a>
			</div>
			<div class="col-md-12 mt8" [hidden]="!error_flag">
				<p id="error-text" class="danger-color">{{error_msz}}</p>
			</div>
		</form>
	</div>
</div>

<div *ngIf="mode == 'arc'" class="middle_ware_loading">

    <app-loader></app-loader>
    <div class="middle_ware_loading_text">
        Verifying User Session...
    </div>
</div>

<div id="cookies_confirmation">
	<div class="row">
		<div class="col-md-10">
			<p translate>This website collects and uses cookies to ensure you get the best user experience.</p>
			<p translate>Please click on "Accept and Close" button to affirm your consent and continue to use our website. For more information, please view our <a target="_blank" href="{{config.config.settings.landingPageURL}}cookie-statement">Cookies Statement</a>.</p>
		</div>
		<div class="col-md-2 align-center accept_cookie_btn">
			<button class="btn accept_and_close_btn" translate (click)="acceptedArcCookiesPolicy()">ACCEPT AND CLOSE</button>
		</div>
	</div>
</div>
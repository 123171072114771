<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Leadership</span>
        </h1>
        
        <div class="widget">
            <div class="row">
                <div class="col-md-6 pl0 pr10">
                    <div class="col-md-12 align-center leadership_cart">
                        <div class="leadership_heading col-md-12">Arc Performance Certificates</div>
                        <div class="leadership_subheading col-md-12">Generate certificates for leadership in individual Arc categories</div>
                        <div class="col-md-12 mt8 mb32">
                            <div class="col-md-7 align-right">
                                <a class="leadership_links" (click)="goTo('performance-certificates')">Arc Performance Certificates <img src="assets/images/ic-arrow-forward.svg"></a>
                            </div>
                            <div class="col-md-5 align-left">
                                <a class="leadership_links" href="https://arc.gbci.org/arc-performance-certificates" target="_blank">Learn More <img src="assets/images/ic-arrow-forward.svg"></a>
                            </div>
                        </div>
                        
                        <div class="label_frame">
                            <iframe id="cert_score_bar" frameborder="0" src="" width="100%" height="400px"></iframe>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pr0 pl10">
                    <div class="col-md-12 align-center leadership_cart">
                        <div class="leadership_heading col-md-12">LEED Certifications</div>
                        <div class="leadership_subheading col-md-12">Earn certification by meeting LEED requirements for overall project performance.</div>
                        <div class="col-md-12 mt8 mb32">
                            <div class="col-md-7 align-right">
                                <a class="leadership_links" (click)="goTo('certifications')">LEED Certifications <img src="assets/images/ic-arrow-forward.svg"></a>
                            </div>
                            <div class="col-md-5 align-left">
                                <a class="leadership_links" href="https://new.usgbc.org/leed" target="_blank">Learn More <img src="assets/images/ic-arrow-forward.svg"></a>
                            </div>
                        </div>
                        
                        <div class="label_frame">
                            <iframe id="cert_score_bar_1" frameborder="0" src="" width="100%" height="400px"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold visible-xs-inline" translate>Manage</span>
            <span class="fw-semi-bold" translate>Integration Partners</span>
        </h1>
        
        <section [ngClass]="loading ? 'not-active' : ''" class="widget apps-page-padding" id="apps-page">
            <app-loader *ngIf="loading"></app-loader>
            <div class="row">
                <div *ngIf="appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school'">
                    <!-- <h4 class="fw-500" translate>Data Integration Partners</h4> -->
        
                    <div class="col-md-3 pl0 mt20" [ngClass]="apps_received ? '' : 'not-active'">
                        <div class="project-apps cursor-pointer" (click)="global.openEnergyStar()">
                            <div class="mt30 text-center">
                                   <img id="energy_star_img" src="../assets/images/apps/energy_star.png">
                               </div>
                               <br>
                               <h4 class="app_title pt10" id="energy_star_title">Energy Star Portfolio Manager</h4>
                                <br>
                                   <br>
                               <p class="text-muted app_desc">Pull data from your Energy Star Portfolio Manager account to use for performance scores</p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Measurabl')">
                            <div class="mt20 text-center">
                                <img width="100px" src="../assets/images/measurabl.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600" style="margin-top: 18px;">Measurabl</h4>
                            <br>
                            <br>
                            <p class="text-muted app_desc">
                                Measure, manage and improve your ESG (environmental, social, governance) data
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Commutifi')">
                            <div class="mt20 text-center">
                                <img width="100px" src="../assets/images/commutifi.svg">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Commutifi</h4>
                            <br>
                            <br>
                            <p class="text-muted app_desc">
                                Commuter Scoring technology
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('QLEAR')">
                            <div class="text-center" style="margin-bottom: 28px;margin-top: 41px;">
                                <img width="100px" src="../assets/images/qlear.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">QLEAR</h4>
                            <br>
                            <br>
                            <p class="text-muted app_desc">
                                Real-time data with our complete environmental data management system.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('arbnco')">
                            <div class="text-center" style="margin-bottom: 45px;margin-top: 40px;">
                                <img width="150px" src="../assets/images/arbnco.png">
                            </div>
                             <h4 class="pl15 fw-600 mt0">arbnco</h4>
                            <p class="text-muted app_desc">
                                Interactive, digital infrastructure solution for monitoring indoor air quality, thermal comfort and human experience
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('B-Line')">
                            <div class="text-center" style="margin-top: 30px;margin-bottom: 22px;">
                                <img width="130px" src="../assets/images/B-Line.png">
                            </div>
                            <h4 class="pl15 fw-600 mt0">B-Line</h4>
                            <p class="text-muted app_desc">
                                B-Line is an artificial intelligence platform that helps green building owners optimize occupant comfort and commuting surveys.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Schneider Electric')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="130px" src="../assets/images/Schneider.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Schneider Electric</h4>
                            <p class="text-muted app_desc">
                                We provide energy and automation digital solutions for efficiency and sustainability.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('EnergyWatch')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="130px" src="../assets/images/logo/watchwire-cloud-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">WatchWire</h4>
                            <p class="text-muted app_desc">
                                WatchWire is a sustainability and energy management software-as-a-service (EMSaaS) provider.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Wattics')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="130px" src="../assets/images/logo/wattics.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Wattics</h4>
                            <p class="text-muted app_desc">
                                Business energy analytics and asset management platform.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Idun')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="130px" src="../assets/images/logo/Idun-logotype-RGB.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Idun</h4>
                            <p class="text-muted app_desc">
                                Making the world’s buildings able inhabitants of the smart city.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Autocase')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="130px" src="../assets/images/logo/Autocase-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Autocase</h4>
                            <p class="text-muted app_desc">
                                Create a more sustainable, resilient and cost-effective built environment, by unleashing the power of economic insights.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Bractlet')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="100px" src="../assets/images/logo/Bractlet-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Bractlet</h4>
                            <p class="text-muted app_desc">
                                Better Decisions. Optimized Buildings.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Moody')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="200px" src="../assets/images/logo/Moodys-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Moody's</h4>
                            <p class="text-muted app_desc">
                                Moody’s Climate Solutions suite integrates forward-looking, location-specific and globally comparable climate data into our world-class economic and financial risk models.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('FGB-Studio')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="200px" src="../assets/images/logo/FGB-Logo.jpg">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">FGB Studio</h4>
                            <p class="text-muted app_desc">
                                A leading consulting company focusing on activities and projects related to environmental sustainability and human well-being.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Renteknik-Group')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="200px" src="../assets/images/logo/renteknik-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Renteknik Group</h4>
                            <p class="text-muted app_desc">
                                We have the power to help you waste less energy and fuel the success of your business.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('GSA')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="100px" src="../assets/images/logo/gsalogo_color_new0101.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Green Sports Alliance</h4>
                            <p class="text-muted app_desc">
                                Guiding the sports sector towards net-zero carbon and global development goals.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('Energenius')">
                            <div class="text-center" style="margin-top: 36px;margin-bottom: 16px;">
                                <img width="150px" src="../assets/images/logo/energenius-logo.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">Energenius</h4>
                            <p class="text-muted app_desc">
                                Energenius provides AI-based software solutions for monitoring and analyzing energy and environmental parameters for industrial, commercial, and retail buildings.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>

                    <div *ngIf="(appData.get('buildingData').activation_source == 'GRESB' || !appData.get('buildingData').is_trial_selected) && (appData.get('project_type') == 'building' || appData.get('project_type') == 'transit' ||  appData.get('project_type') == 'school')" class="col-md-3 pl0 mt20">
                        <div class="project-apps cursor-pointer" (click)="global.openHelpSidebar('bee')">
                            <div class="text-center" style="margin-top: 40px;margin-bottom: 15px;">
                                <img width="150px" src="../assets/images/logo/bee.png">
                            </div>
                            <br>
                            <h4 class="pl15 fw-600 mt0">BEE Inc.</h4>
                            <p class="text-muted app_desc">
                                BEE Sense is one of the first systems to be fully integrated with Arc for the transmission of energy, water, and air quality data for LEED O+M Certification.
                                <br>
                                <br>
                                <a translate>Learn more</a>
                            </p>
                        </div>
                    </div>
        
                </div>
        
            </div>
        </section>
        

    </div>
</div>
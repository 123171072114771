import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ArcAuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { HUB_FEATURES, HUB_FEATURES_COMING_SOON } from './hub-switchover.contants';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-hub-switchover',
  templateUrl: './hub-switchover.component.html',
  styleUrls: ['./hub-switchover.component.scss']
})
export class HubSwitchoverComponent
{
	public hubFeatures = HUB_FEATURES;
	public hubFeaturesComingSoon = HUB_FEATURES_COMING_SOON;
	public page: number = 1;
	public isLoading: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private authService: ArcAuthService,
		private alertService: alertService
	)
	{}

	setPage(page: number): void {
		this.page = page;
	}

	close() {
		this.activeModal.close('Close click');
	};

	switchToHub() {
		this.isLoading = true;
		this.authService.switchToHub()
		.subscribe(
			() => {
				this.isLoading = false;
				window.location.replace(environment.HUB_URL);
			},
			() => {
				this.isLoading = false;
				this.alertService.alert('error', 'Something went wrong! Please try again', 5);
			}
		);
	}
}

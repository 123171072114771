<div class="modal-body"> 
    <div class="row p20 fw-400">
        <p> 
            <b>Performance score:</b><br><br>

            The Performance score is on a scale of <br>0 - 100. It is an aggregation of the weighted scores across five categories- Energy, Water, Waste, Transportation and Human Experience, and the Base Score. Higher scores indicate better performance. Click on the racetrack to see additional information.

        </p>
        <br>
        <p> 
            <b>Category Score:</b><br><br>          
            The Performance Score is based on a global data set. At any point in time, the score reflects the most recent 365 day rolling average in each category. <span *ngIf="!(appData.get('project_type') == 'city' || appData.get('project_type') == 'community' || appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" >More information is available under <b><a ng-click="cancel()">Analytics</a></b></span>
        </p>

        <p>
            <b>Energy:</b>  The total energy use from the most recent 365 days is converted into equivalent carbon emissions and source energy sub-scores. Each sub-score is equally weighted within the total energy score. In general, the lower the overall carbon emissions and source energy the better the score. A perfect score reflects zero emissions and source energy. You can view the energy score on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').energy}}. <br><br>
            
            <b>Water:</b> The total water use from the most recent 365 days, is reflected in the score. Generally the lower the overall water consumption the better the score, with a perfect score reflecting zero potable water use. You can view the scores on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').water}}.<br><br>
            
            <b>Waste:</b> The weight of the materials generated and diverted from landfills and incineration is reflected in the score. You can view the scores on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').waste}}.<br><br>
            
            <b>Transportation:</b> Commuting distances and modes of travel provided through survey responses are converted to carbon emissions. The lower the emissions the better the score. You can view the scores on a scale of 1- 100 or weighted to a score of 0 - {{appData.get('maxima').transport}}.<br><br>

            <b>Human Experience:</b> Occupant satisfaction survey responses, interior carbon dioxide (CO2) levels, and Interior total volatile organic compound levels (TVOC) are sub-scores within Human Experience. Occupant satisfaction represents 50% of the total score, while CO2 and TVOC each represent the remaining 25%. Increased occupant satisfaction and environmental quality typically results in higher scores. You can view the scores on a scale of 1- 100 or weighted to a score of 0 – {{appData.get('maxima').human_experience}}.<br><br>
            
            <b><a href="https://www.usgbc.org/resources/leed-v41-om-beta-guide" target="_blank">Here</a></b> is more information on data management.<br><br>
        </p>

        <p *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'transit')">
            && (appData.project_rating_system!="other" && appData.project_rating_system!="none")'>
            <b>Base points:</b> <br><br> 
            
            The base score represents prior LEED Certification credit. Projects with a prior LEED certification will be automatically awarded a base score of 10.
        </p>

    </div>
</div>

<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Re-Entry</span>
        </h1>
        
        <div [ngClass]="loading ? 'not-active' : ''" class="between-div res_block widget">
            <app-loader *ngIf="loading"></app-loader>
            <section class="w70p">
                <div id="re_entry">
                    <div class="fs18 fw-500 line_height_32 mb15">Re-Entry Comprehensiveness Score</div>
                    <div class="flex mb15">
                        <svg width="35" height="35" viewBox="0 0 64 64">
                            <g fill="none" fill-rule="evenodd">
                              <circle cx="32" cy="32" r="32" fill="#4E5EBB" fill-rule="nonzero"/>
                              <path fill="#FFF" d="M32,13 C34.6776316,13 36.8812327,15.1331371 36.9953574,17.7839654 L37,18 L47,18 C48.1045695,18 49,18.8954305 49,20 L49,48 C49,49.1045695 48.1045695,50 47,50 L35.6666667,50 L35.5,39.4166667 L31.65,35.75 L32.75,30.25 C35.1333333,33 38.8,34.8333333 42.8333333,34.8333333 L42.8333333,31.1666667 C39.35,31.1666667 36.4166667,29.3333333 34.95,26.7666667 L33.1166667,23.8333333 C32.3833333,22.7333333 31.2833333,22 30,22 C29.45,22 29,22 28.5333333,22.1833333 C28.2222222,22.3055556 25.0444444,23.65 19,26.2166667 L19,34.8333333 L22.6666667,34.8333333 L22.6666667,28.6 L25.9666667,27.3166667 L21.15,50 L17,50 C15.8954305,50 15,49.1045695 15,48 L15,20 C15,18.8954305 15.8954305,18 17,18 L27,18 C27,15.25 29.25,13 32,13 Z M27.9833333,38.5 L31.8333333,42.1666667 L32,50 L25,50 L27.9833333,38.5 Z M32,15 C30.35,15 29,16.35 29,18 C29,19.65 30.35,21 32,21 C33.65,21 35,19.65 35,18 C35,16.35 33.65,15 32,15 Z"/>
                            </g>
                        </svg>
                        <div class="ml10">
                            <span class="re_entry_reading">{{global.formatNumber(re_entry_scores.reentry, 0)}}%</span>
                        </div>		
                        <div *ngIf="!data_given" class="grey-text ml15 pt5 fs12">Complete the facility manager and occupant experience surveys and provide IAQ data to get a score.</div>  
                    </div>
            
                    <!-- <div class="fs18 fw-500 line_height_32 mb15">Comprehensiveness Score</div> -->
            
                    <div class="flex res_block">
                        <div class="h220px relative-position w30p border-right-light">
                            <div class="fs18 line_height_32 mb10 fw-500">
                                Facility Management
                            </div>
                            <div *ngIf="survey_submitted" class="mb15 grey-text">
                                Last response: <br>{{global.dateFormat(fmpolicy.created_at)}}
                            </div>
                            <div *ngIf="!survey_submitted" class="fs14 mb15 grey-text">
                                No survey submitted
                            </div>
                            <div class="bottom-abs">
                                <div class="mb20">
                                    <span class="re_entry_reading">{{global.formatNumber(re_entry_scores.facility, 0)}}%</span>
                                </div>
                                <button (click)="openFM()" class="bg-white btn btn-default anchor-color">
                                    Send Survey
                                </button>
                            </div>
                        </div>
            
                        <div class="h220px relative-position w30p pl24 border-right-light">
                            <div class="fs18 line_height_32 mb10 fw-500">
                                Occupant Observations
                            </div>
                            <div class="fs14 mb15 grey-text">
                                Survey responses from {{monthRange()}}
                            </div>
                            <div class="bottom-abs">
                                <div class="mb20">
                                    <span class="re_entry_reading">{{global.formatNumber(re_entry_scores.occupant, 0)}}%</span>
                                </div>
                                <button (click)="openOE()" class="bg-white btn btn-default anchor-color">
                                    Send Survey
                                </button>
                            </div>
                        </div>
            
                        <div class="h220px relative-position w30p pl24">
                            <div class="fs18 line_height_32 mb10 fw-500">
                                Indoor Air Quality
                            </div>
                            <div *ngIf="!iaq_data_submitted" class="fs14 mb15 grey-text">
                                No data entered
                            </div>
                            <div *ngIf="iaq_data_submitted" class="fs14 mb15 grey-text">
                                Data for {{global.dateFormat(re_entry_scores.iaq_date.minima)}} through {{global.dateFormat(re_entry_scores.iaq_date.maxima)}}
                            </div>
                            <div class="bottom-abs">
                                <div class="mb20">
                                    <span class="re_entry_reading">{{global.formatNumber(re_entry_scores.iaq, 0)}}%</span>
                                </div>
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" (click)="openDI()" class="bg-white btn btn-default anchor-color">
                                    Add Data
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="fs18 fw-500 line_height_32">How Arc Re-Entry works</div>
                <div class="fs17 line_height_32">
                    Arc Re-Entry provides tools to help facility managers create effective infection control policies and procedures, engage occupants to provide targeted feedback based on their experiences, and use indoor air quality measurements to inform operations.
                    <br>
                    <span class="fw-500">Every Arc user</span> can collect, manage, and score data through the Re-Entry section under Meters & Surveys.
                    <br>
                    <span class="fw-500">Arc Essentials users</span> can create, download, and share customized reports for project and portfolios.
                    <br>
                </div>
                <div class="fs17 line_height_32">
                    <a (click)="global.openHelpSidebar('re_entry')">Learn more</a>
                </div>
        
            </section>
            
            <div class="account_upgrade_info">
                <div *ngIf="appData.get('user_role') == 'arc_basic'" class="account_upgrade_info w100p mb15">
                    <div>
                        <div class="header_image upgrade"></div>
                        <div class="account_upgrade_data">
                            <div class="fw-500 fs15">
                                Upgrade your Arc account
                            </div>
                            <div class="gray_color_707479 mt30 fs16">
                                View Energy, Water, Waste, Transportation and Human Experience KPIs.
                            </div>
                            <div class="learn_more_link" (click)="global.learnMore('arc_pro')">
                                Learn more
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account_upgrade_info w100p mb15">
                    <div>
                        <div class="account_upgrade_data border-top">
                            <div class="fw-500 basic_practices fs15">
                                LEED Safety First Pilot Credits
                            </div>
                            <div class="gray_color_707479 mt15 fs16">
                                Developed in response to COVID-19, these focus on the safety of people working in a building as it's re-occupied. They outline sustainable best practices related to cleaning and disinfecting, workplace re-occupancy, HVAC and plumbing operations.
                            </div>
                            <div class="learn_more_link" (click)="global.openLink('https://www.usgbc.org/credits?Version=%22v4.1%22&Rating+System=%22Existing+Buildings%22&Search+Library=%22Safety+First%22', true)">
                                Learn more
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account_upgrade_info w100p mb15">
                    <div>
                        <div class="account_upgrade_data border-top">
                            <div class="fw-500 basic_practices_well fs15">
                                Well Health-Safety Rating
                            </div>
                            <div class="gray_color_707479 mt15 fs16">
                                Take advantage of strategies in WELL’s Health-Safety Rating. This evidence-based, third-party verified rating focuses on operational policies, cleaning protocols and design strategies to address a post COVID-19 environment.
                            </div>
                            <div class="learn_more_link" (click)="global.openLink('https://resources.wellcertified.com/tools/strategies-from-the-well-building-standard-to-support-in-the-fight-against-covid-19/', true)">
                                Learn more
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)='global.openLink("https://arbnwell.com/covid-19-re-entry/", "_blank")' class="account_upgrade_info w100p pr25 mb15">
                    <div class="arbnwell_bg_full"></div>
                </div>
            </div>
        </div>

    </div>
</div>

<modal #fm_modal>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
        <app-loader *ngIf="loading"></app-loader>
        <div class="left-right-align mb15 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Facilities and Management Survey
            </div> 
        </div>
        <div class="line_height_32 fs18 mb15">Copy survey link to share:</div>
        <div class="flex mb55">
            <div class="w60p">
                <input class="minimal_input pr30 w100p" [(ngModel)]="fm_link" [readonly]="true">
            </div>
            <div (click)="copyLink(fm_link)" class="cursor-pointer" style="position: relative;right: 21px;">
                <svg style="fill: gray;" height="24" width="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
            </div>
            <div class="flex">
                <button (click)="copyLink(fm_link)" class="bg-white btn btn-default">
                    <a>Copy Link</a>
                </button>
                <div class="ml10 copy_notif">Copied!</div>
            </div>
        </div>
    
        <div class="pt16">
            <button (click)="cancel()" data-dismiss="modal" type="button" class="btn bg-white grey btn-default btn-md">Close</button>
        </div>
    </div>
</modal>

<modal #oe_modal>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
        <app-loader *ngIf="loading"></app-loader>
        <div class="left-right-align mb15 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Occupant Experience Survey
            </div> 
        </div>
    
        <div class="line_height_32 fs18 mb15 fw-500">Select survey:</div>
        <div id="details-form" class="w33p pt6">
            <div class="select-group group">   
                <label class="label-color fs14">Select scope</label>   
                <div class="pt5">
                    <select (change)="generateLink()" class="fw-500" [(ngModel)]="scope" name="scope">
                        <!-- <option disabled value="null"> Select scope</option> -->
                        <option value="common">Common Space</option>
                        <option value="tenant">Tenant Space</option>
                        <option value="whole">Whole Space</option>
                    </select>
                    <i style="top: 20px;" class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
            </div>
    
            <div class="select-group group">   
                <label class="label-color fs14">Select group to respond</label>   
                <div class="pt5">
                    <select (change)="generateLink()" class="fw-500" [(ngModel)]="group" name="group">
                        <!-- <option disabled value="null"> Select group</option> -->
                        <option value="owner">Management</option>
                        <option value="tenant">Tenant</option>
                        <option value="visitor">Visitor</option>
                    </select>
                    <i style="top: 20px;" class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
            </div>
        </div>
        <div class="line_height_32 fs18 mb15">
            <span class="fw-500">Optional</span> - Provide a tenant name or a unique group name which can help you identify the responses from this group.
        </div>
        <div class="mb35">
            <input placeholder="Enter a name" type="text" (input)="generateLink()" [(ngModel)]="tenant" class="tenant_inp w100p">
        </div>
        
        <div class="line_height_32 fs18 mb15">Copy survey link to share:</div>
        <div class="flex mb55">
            <div class="w60p">
                <input class="minimal_input pr30 w100p" [(ngModel)]="oe_link" [readonly]="true">
            </div>
            <div [ngClass]="group == undefined || scope == undefined ? 'not-active' : ''" (click)="copyLink(oe_link)" class="cursor-pointer" style="position: relative;right: 21px;">
                <svg style="fill: gray;" height="24" width="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
            </div>
            <div [ngClass]="group == undefined || scope == undefined ? 'not-active' : ''" class="flex">
                <button (click)="copyLink(oe_link)" class="bg-white btn btn-default">
                    <a>Copy Link</a>
                </button>
                <div class="ml10 copy_notif">Copied!</div>
            </div>
        </div>
    
        <div class="pt16">
            <button (click)="cancelOE()" data-dismiss="modal" type="button" class="btn bg-white grey btn-default btn-md">Close</button>
        </div>
    </div>
</modal>
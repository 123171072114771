import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { ValidationService } from 'src/app/services/validation.service';
import { InsightService } from 'src/app/services/insight.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.css']
})
export class InsightComponent implements OnInit
{
	config = Config;
	loading: boolean = false;

	survey_permission: boolean = false;
	survey_screen: any = 0;
	survey_options: any = [
		{
			"title": "We would love to hear about your experience using the Insight Tool. Thanks for taking the time to complete this quick feedback. It will help us continue to improve.",
			"button_text": "Start",
			"splash_screen": true
		},
		{
			"title": "I found the program intuitive and easy to use:",
			"button_text": "Next",
			"is_required": true,
			"options": [
				{
					"title": "Strongly disagree",
					"class": "frown",
					"value": -1,
					"status": false
				},
				{
					"title": "Neutral",
					"class": "meh",
					"value": 0,
					"status": false
				},
				{
					"title": "Strongly agree",
					"class": "smile",
					"value": 1,
					"status": false
				}
			],
			"comments": ""
		},
		{
			"title": "The information it generated was meaningful:",
			"button_text": "Next",
			"is_required": true,
			"options": [
				{
					"title": "Strongly disagree",
					"class": "frown",
					"value": -1,
					"status": false
				},
				{
					"title": "Neutral",
					"class": "meh",
					"value": 0,
					"status": false
				},
				{
					"title": "Strongly agree",
					"class": "smile",
					"value": 1,
					"status": false
				}
			],
			"comments": ""
		},
		{
			"title": "This experience made me think about improving the green performance of my building or registering my project:",
			"button_text": "Next",
			"is_required": true,
			"options": [
				{
					"title": "Strongly disagree",
					"class": "frown",
					"value": -1,
					"status": false
				},
				{
					"title": "Neutral",
					"class": "meh",
					"value": 0,
					"status": false
				},
				{
					"title": "Strongly agree",
					"class": "smile",
					"value": 1,
					"status": false
				}
			],
			"comments": ""
		},
		{
			"title": "I would use the Insight Tool for future projects:",
			"button_text": "Next",
			"is_required": true,
			"options": [
				{
					"title": "Strongly disagree",
					"class": "frown",
					"value": -1,
					"status": false
				},
				{
					"title": "Neutral",
					"class": "meh",
					"value": 0,
					"status": false
				},
				{
					"title": "Strongly agree",
					"class": "smile",
					"value": 1,
					"status": false
				}
			],
			"comments": ""
		},
		{
			"title": "Is there anything else we should know?",
			"button_text": "Next",
			"comments": ""
		},
		{
			"title": "May we contact you with follow up questions?",
			"button_text": "Next",
			"form": ["email", "phone"]
		}
	];
	survey_form_error: any = {
		"email": false,
		"phone": false,
		"organization": false,
	};

	user_role: string = this.appData.get('user_role')

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('buildingData').arctool_permission)
        {
            this.user_role = 'arc_pro';
        }
	}

	filterAccessInsights(app: string)
	{
		this.appData.set('insight_app', app);
	}

	createModel()
	{
		this.appData.set("current_step", "Model details");
		this.global.goToApp('create-model')
	}

	insightSurvey()
	{
		$('.feedback_modal').modal('show');
	}

	checkBoxListner(survey_permission: any)
	{
		this.survey_permission = survey_permission;
	};

	isSurveyCorrect()
	{
		if(this.survey_permission)
		{
			if(this.validator.regex.email.test($('#survey_email').val()))
			{
				this.survey_form_error.email = false;
			}
			else
			{
				this.survey_form_error.email = true;
			}

			if(this.validator.regex.phone.test($('#survey_phone').val()))
			{
				this.survey_form_error.phone = false;
			}
			else
			{
				this.survey_form_error.phone = true;
			}

			if(this.validator.regex.name.test($('#survey_organization').val()))
			{
				this.survey_form_error.organization = false;
			}
			else
			{
				this.survey_form_error.organization = true;
			}

			for(var a in this.survey_form_error)
			{
				if(this.survey_form_error[a])
				{
					return false;
				}
			}
		}
		return true;
	};

	selectFace(face: any, options: any)
	{
		for(var i = 0; i < options.length; i++)
		{
			if(options[i].class == face.class)
			{
				options[i].status = true;
			}
			else
			{
				options[i].status = false;
			}
		}
	};

	changeSurvey(index: any)
	{
		if(this.survey_screen == this.survey_options.length - 1)
		{
			this.submitSurvey();
			return;
		}
		this.survey_screen += index;
	};

	getNavigatorInfo()
	{
		var unknown = '-';

		// screen
		var screenSize = '';
		if (screen.width)
		{
			var width = (screen.width) ? screen.width : '';
			var height = (screen.height) ? screen.height : '';
			screenSize += '' + width + " x " + height;
		}

		// browser
		var nVer = navigator.appVersion;
		var nAgt = navigator.userAgent;
		var browser = navigator.appName;
		var version = '' + parseFloat(navigator.appVersion);
		var majorVersion = parseInt(navigator.appVersion, 10);
		var nameOffset, verOffset, ix;

		// Opera
		if ((verOffset = nAgt.indexOf('Opera')) != -1)
		{
			browser = 'Opera';
			version = nAgt.substring(verOffset + 6);
			if ((verOffset = nAgt.indexOf('Version')) != -1)
			{
				version = nAgt.substring(verOffset + 8);
			}
		}
		// Opera Next
		if ((verOffset = nAgt.indexOf('OPR')) != -1)
		{
			browser = 'Opera';
			version = nAgt.substring(verOffset + 4);
		}
		// Edge
		else if ((verOffset = nAgt.indexOf('Edge')) != -1)
		{
			browser = 'Microsoft Edge';
			version = nAgt.substring(verOffset + 5);
		}
		// MSIE
		else if ((verOffset = nAgt.indexOf('MSIE')) != -1)
		{
			browser = 'Microsoft Internet Explorer';
			version = nAgt.substring(verOffset + 5);
		}
		// Chrome
		else if ((verOffset = nAgt.indexOf('Chrome')) != -1)
		{
			browser = 'Chrome';
			version = nAgt.substring(verOffset + 7);
		}
		// Safari
		else if ((verOffset = nAgt.indexOf('Safari')) != -1)
		{
			browser = 'Safari';
			version = nAgt.substring(verOffset + 7);
			if ((verOffset = nAgt.indexOf('Version')) != -1)
			{
				version = nAgt.substring(verOffset + 8);
			}
		}
		// Firefox
		else if ((verOffset = nAgt.indexOf('Firefox')) != -1)
		{
			browser = 'Firefox';
			version = nAgt.substring(verOffset + 8);
		}
		// MSIE 11+
		else if (nAgt.indexOf('Trident/') != -1)
		{
			browser = 'Microsoft Internet Explorer';
			version = nAgt.substring(nAgt.indexOf('rv:') + 3);
		}
		// Other browsers
		else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/')))
		{
			browser = nAgt.substring(nameOffset, verOffset);
			version = nAgt.substring(verOffset + 1);
			if (browser.toLowerCase() == browser.toUpperCase())
			{
				browser = navigator.appName;
			}
		}
		// trim the version string
		if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
		if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
		if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

		majorVersion = parseInt('' + version, 10);
		if (isNaN(majorVersion))
		{
			version = '' + parseFloat(navigator.appVersion);
			majorVersion = parseInt(navigator.appVersion, 10);
		}

		// mobile version
		var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

		// cookie
		var cookieEnabled = (navigator.cookieEnabled) ? true : false;

		if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled)
		{
			document.cookie = 'testcookie';
			cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
		}

		// system
		var os = unknown;
		var clientStrings = [
		{
			s: 'Windows 10',
			r: /(Windows 10.0|Windows NT 10.0)/
		},
		{
			s: 'Windows 8.1',
			r: /(Windows 8.1|Windows NT 6.3)/
		},
		{
			s: 'Windows 8',
			r: /(Windows 8|Windows NT 6.2)/
		},
		{
			s: 'Windows 7',
			r: /(Windows 7|Windows NT 6.1)/
		},
		{
			s: 'Windows Vista',
			r: /Windows NT 6.0/
		},
		{
			s: 'Windows Server 2003',
			r: /Windows NT 5.2/
		},
		{
			s: 'Windows XP',
			r: /(Windows NT 5.1|Windows XP)/
		},
		{
			s: 'Windows 2000',
			r: /(Windows NT 5.0|Windows 2000)/
		},
		{
			s: 'Windows ME',
			r: /(Win 9x 4.90|Windows ME)/
		},
		{
			s: 'Windows 98',
			r: /(Windows 98|Win98)/
		},
		{
			s: 'Windows 95',
			r: /(Windows 95|Win95|Windows_95)/
		},
		{
			s: 'Windows NT 4.0',
			r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
		},
		{
			s: 'Windows CE',
			r: /Windows CE/
		},
		{
			s: 'Windows 3.11',
			r: /Win16/
		},
		{
			s: 'Android',
			r: /Android/
		},
		{
			s: 'Open BSD',
			r: /OpenBSD/
		},
		{
			s: 'Sun OS',
			r: /SunOS/
		},
		{
			s: 'Linux',
			r: /(Linux|X11)/
		},
		{
			s: 'iOS',
			r: /(iPhone|iPad|iPod)/
		},
		{
			s: 'Mac OS X',
			r: /Mac OS X/
		},
		{
			s: 'Mac OS',
			r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
		},
		{
			s: 'QNX',
			r: /QNX/
		},
		{
			s: 'UNIX',
			r: /UNIX/
		},
		{
			s: 'BeOS',
			r: /BeOS/
		},
		{
			s: 'OS/2',
			r: /OS\/2/
		},
		{
			s: 'Search Bot',
			r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
		}];
		for (var id in clientStrings)
		{
			var cs = clientStrings[id];
			if (cs.r.test(nAgt))
			{
				os = cs.s;
				break;
			}
		}

		var osVersion: any = '';

		if (/Windows/.test(os))
		{
			osVersion = (<any>/Windows (.*)/).exec(os)[1];
			os = 'Windows';
		}

		switch (os)
		{
			case 'Mac OS X':
				osVersion = (<any>/Mac OS X (10[\.\_\d]+)/).exec(nAgt)[1];
				break;

			case 'Android':
				osVersion = (<any>/Android ([\.\_\d]+)/).exec(nAgt)[1];
				break;

			case 'iOS':
				osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
				osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
				break;
		}

		var flashVersion = 'no check';

		return {
			screen: screenSize,
			browser: browser,
			browserVersion: version,
			browserMajorVersion: majorVersion,
			mobile: mobile,
			os: os,
			osVersion: osVersion,
			cookies: cookieEnabled,
			flashVersion: flashVersion
		}
	};

	cancel()
	{
		$('.feedback_modal').modal('hide');
	}

	submitSurvey()
	{
		if(!this.isSurveyCorrect())
		{
			return
		}
		var payload: any = [];
		for(var i = 1; i < this.survey_options.length; i++)
		{
			var item = this.survey_options[i];
			var title = item.title;
			var smiley = undefined;
			var comments = undefined;
			if(item.options != undefined)
			{
				smiley = "Neutral";
				for(var j = 0; j < item.options.length; j++)
				{
					if(item.options[j].status)
					{
						smiley = item.options[j].title;
						item.options[j].status = false;
						break;
					}
				}
			}
			if(item.comments != undefined)
			{
				comments = item.comments;
				item.comments = "";
			}
			payload.push({
				'title': title,
				'smiley': smiley,
				'comments': comments
			});
		};

		var navigator_info = this.getNavigatorInfo();

		payload = {
			'questionnaire': payload,
			'phone': $('#survey_phone').val(),
			'email': $('#survey_email').val(),
			'organization': $('#survey_organization').val(),
			'browser': navigator_info.browser,
			'browser_version': navigator_info.browserVersion,
			'cookies': navigator_info.cookies,
			'flash_version': navigator_info.flashVersion,
			'mobile': navigator_info.mobile,
			'os': navigator_info.os,
			'os_version': navigator_info.osVersion,
			'screen': navigator_info.screen,
		};

		this.loading = true;

		this.insight.post('/survey/', payload).subscribe(
			data =>
			{
				this.loading = false;
				this.cancel();
			},
			data =>
			{
				this.loading = false;
				this.cancel();
			}
		);
	};

}

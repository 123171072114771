import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ArcAuthService } from 'src/app/services/auth.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.css']
})
export class PaynowComponent implements OnInit
{
	config = Config;
	leed_id: number = this.appData.get('leed_id');
	loading: boolean = false;
	submitPaymentLoader: boolean = false;
	regex: any = this.validator.regex;

	countries: any = [];
	states: any = [];
	mypayments: any = [];
	billing_history: any = {
		"invoice_number":  this.global.getURLFromParam('invoice_number'),
		"recipient_email": '',
		"state": '',
		"phone": '',
		"cc_details": {}
	};

	CC_type: string = 'American Express';
	mastercard_select: boolean = false;
	isFormSubmitted: boolean = false;
	LN_fail_CC: boolean = false;
	cvv_fail: boolean = false;
	street_fail_CC: boolean = false;
	city_fail_CC: boolean = false;
	postalcode_fail_CC: boolean = false;
	phone_fail_CC: boolean = false;
	cc_fail: boolean = false;
	visa_select: boolean = false;
	FN_fail_CC: boolean = false;
	discover_select: boolean = false;
	amex_select: boolean = false;
	first_change_card: boolean = false;
	first_change_ccv: boolean = false;
	countryStateData: any = [];
	validation_error: boolean = false;
	continue_btn_txt: string = 'Continue';
	exp_validation_fail: boolean = false;
	show_billing_section: boolean = false;
	has_error: boolean = false;
	updated_cc_details: any = false;
	updated_cvv_details: boolean = false;
	updated_expiry_details: boolean = false;
	paynow_btn_text: string = "Pay now"
	recipient_email_fail: boolean = false;
	lock: boolean = false;
	
	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData,
		public auth: ArcAuthService,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/payments/order/'
		).subscribe(
			data =>
			{
				for (let i = 0; i < data.ItOrders.length; i++) {
                    if (data.ItOrders[i].Vbeln == this.global.getURLFromParam('invoice_number'))
					{
                        this.mypayments = data.ItOrders[i];
						this.loading = false;
                        break;
                    }
                }
			}
		);

        this.auth.getAuthDetail().subscribe(
            //success handler
            data =>
			{
                this.billing_history.first_name    = data.Firstname;
                this.billing_history.last_name     = data.Lastname;
                this.billing_history.street        = (data.Street + " " + data.StrSuppl3).trim().substring(0, 60);
                this.billing_history.city          = data.City;
                this.billing_history.state         = data.Region;
                this.billing_history.country       = data.Country;
                this.billing_history.zip_code      = data.PostlCode;
                this.billing_history.email         = this.appData.get('user_id');
                this.updateCountry(this.billing_history.country);
            }, 
            //error handler
            function(data)
			{
            }
        ); 

		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
				this.states = this.global.getStateList(this.appData.get('buildingData').country);
			});
		}
		else
		{
			this.countries = this.global.getCountryList();
			this.states = this.global.getStateList(this.appData.get('buildingData').country);
		}

		this.createPaymetricForm();
	}

	validateFNCC()
	{
		let val = this.billing_history.first_name;
		if(!this.regex.name.test(val)){
			this.FN_fail_CC = true;
		}
		else{
			this.FN_fail_CC = false;
		}
	}

	validateLNCC()
	{
		let val = this.billing_history.last_name;
		if(!this.regex.name.test(val)){
			this.LN_fail_CC = true;
		}
		else{
			this.LN_fail_CC = false;
		}
	}

	validateCC()
	{
		if (this.billing_history.cc_details != undefined){
			this.updated_cc_details = true;
		}
		else{
			this.updated_cc_details = true;
		}

		if(this.updated_cc_details == false)
		{
			return;
		}

		if(this.billing_history.cc_details != undefined && (!this.regex.CC_number.test(this.billing_history.cc_details.CcNumber))) {
			this.cc_fail = true;
		}
		else{
			this.cc_fail = false;
		}
	}

	normalizeYear(year: any)
	{
		// Century fix
		let YEARS_AHEAD = 20;
		if (year<100){
			let nowYear = new Date().getFullYear();
			year += Math.floor(nowYear/100)*100;
			if (year > nowYear + YEARS_AHEAD){
				year -= 100;
			} else if (year <= nowYear - 100 + YEARS_AHEAD) {
				year += 100;
			}
		}
		return year;
	};

	validateCardExpiry()
	{
		if (this.billing_history.cc_details != undefined){
			if (this.billing_history.cc_details.CcValidT){
				this.updated_expiry_details = true;
			}
		}
		else{
			this.updated_expiry_details = true;
		}

		if(this.updated_expiry_details == false)
		{
			return;
		}

		if (this.billing_history.cc_details == undefined){
			return false;
		}

		let card_expiry = this.billing_history.cc_details.CcValidT

		let match=card_expiry.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
		if (!match){
			this.exp_validation_fail = true;
			return true;
		}
		else{
			this.exp_validation_fail = false;
		}

		let exp = new Date(this.normalizeYear(1*match[2]),1*match[1]-1,1).valueOf();
		let now=new Date();
		let currMonth = new Date(now.getFullYear(),now.getMonth(),1).valueOf();
		if (exp<=currMonth || card_expiry.length < 7){
			this.exp_validation_fail = true;
			return true;
		}
		else{
			this.exp_validation_fail = false;
			return false;
		}
	}

	validateCVV()
	{
		if (this.billing_history.cc_details != undefined){
			this.updated_cvv_details = true;
		}
		else{
			this.updated_cvv_details = false;
		}

		if(this.updated_cvv_details == false)
		{
			return;
		}

		if( this.billing_history.cc_details != undefined && (!this.regex.CC_CVV.test(this.billing_history.cc_details.CcVerifValue))){
			this.cvv_fail = true;
		}
		else{
			this.cvv_fail = false;
		}
	}

	validateStreetCC()
	{
		let val = this.billing_history.street;
		if(!this.regex.name.test(val)){
			this.street_fail_CC = true;
		}
		else{
			this.street_fail_CC = false;
		}
	}

	validateCityCC()
	{
		let val = this.billing_history.city;
		if(!this.regex.name.test(val)){
			this.city_fail_CC = true;
		}
		else{
			this.city_fail_CC = false;
		}
	}

	validatePostalcodeCC()
	{
		let val = this.billing_history.zip_code;
		if(!this.regex.name.test(val)){
			this.postalcode_fail_CC = true;
		}
		else{
			this.postalcode_fail_CC = false;
		}
	}

	validatePhoneCC()
	{
		let val = this.billing_history.phone;
		if(!this.regex.phone_number.test(val)){
			this.phone_fail_CC = true;
		}
		else{
			this.phone_fail_CC = false;
		}
	}

	validateRecipientEmail()
	{
		this.billing_history.recipient_email = this.billing_history.recipient_email.replace(/(\r\n|\n|\r)/gm,"");
		let val = this.billing_history.recipient_email;

		if (val.trim() == ''){
			this.recipient_email_fail = false;
			return;
		}

		let error = false;
		for (let i = 0; i < val.split(',').length; i++) {
			if (!this.regex.email.test(val.split(',')[i].trim())){
				error = true;
			}
		} 

		if(error){
			this.recipient_email_fail = true;
		}
		else{
			this.recipient_email_fail = false;
		}
	}

	getCardType(number: any)
	{
		// visa
		let re = new RegExp("^4");
		if(number.match(re) != null) return "Visa";
		// Mastercard
		re = new RegExp("^5[1-5]");
		if(number.match(re) != null) return "Mastercard";
		// AMEX
		re = new RegExp("^3[47]");
		if(number.match(re) != null) return "American Express";
		// Discover
		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if(number.match(re) != null) return "Discover";
		
		return "";
	}

	detectCreditCard()
	{
		this.mastercard_select = false;
		this.visa_select = false;
		this.discover_select = false;
		this.amex_select = false;
		
		if(this.getCardType(this.billing_history.cc_details.CcNumber) == 'Visa')
		{
			this.CC_type = 'Visa';
			this.visa_select = true;
		}
		if(this.getCardType(this.billing_history.cc_details.CcNumber) == 'Mastercard')
		{
			this.CC_type = 'Mastercard';
			this.mastercard_select = true;
		}
		if(this.getCardType(this.billing_history.cc_details.CcNumber) == 'American Express')
		{
			this.CC_type = 'American Express';
			this.amex_select = true;
		}
		if(this.getCardType(this.billing_history.cc_details.CcNumber) == 'Discover')
		{
			this.CC_type = 'Discover';
			this.discover_select = true;
		}
	}

	removeError()
	{
		this.validation_error = false;
	}

	cancelPayment()
	{
		this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing'});
	}

	submitPayment()
	{
		this.isFormSubmitted = true;
		this.submitPaymentLoader = true;
		this.paynow_btn_text = "Processing...";
		this.lock = false;
		this.checkForErrors();
	}


	updateCountry(selectedCountry: string)
	{
		this.states = this.global.getStateList(selectedCountry);
		if(this.states.length > 0)
		{
			this.billing_history['state'] = this.states[0].code;
		}
		else
		{
			this.states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.billing_history['state'] = "";
		}
	}

	validateZipCode(state: string, country: string, zip_code: string)
	{
		let data: any;
		if(state == '' || state == null || state == 'Not Available')
		{
			data = {
				"country": country,
				"zip_code": zip_code
			}
		} 
		else
		{
			data = {
				"state": state,
				"country": country,
				"zip_code": zip_code
			}
		}

		this.api.post(
			'/assets/validation/', 
			data
		).subscribe(
			//success handler
			data =>
			{
				this.validation_error = false;
				this.fillPaymetricForm();
			},
			//error handler
			error =>
			{
				this.submitPaymentLoader = false;
				this.paynow_btn_text = "Pay now";
				this.validation_error = true;
			}
		);
	}

	fillPaymetricForm()
	{
		if (this.CC_type=="American Express"){
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 0;
		  }
		  else if(this.CC_type=="Visa"){
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 1;
		  }
		  else if(this.CC_type=="Mastercard"){
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 2;
		  }
		  else if(this.CC_type=="Discover"){
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 3;
		  }

		  var CC_expiry =  (this.billing_history.cc_details.CcValidT).split("/");

		  
		  (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardNumber').value =  this.billing_history.cc_details.CcNumber;
		  (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Month').value = CC_expiry[0];
		  (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Year').value = CC_expiry[1].slice(-2);
		  (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CVV').value =  this.billing_history.cc_details.CcVerifValue;
		  (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('PayNowButton').click();

		if((<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML != '')
		{   
			this.first_change_card = false;
			this.first_change_ccv = false;
			this.submitPaymentLoader = false;
			this.paynow_btn_text = "Pay now";
			this.isFormSubmitted = false;
			var msz =(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML;
			if ( msz.indexOf('Exp Date') > -1){
				msz = 'Enter valid expiry date';
			}
			this.alertService.alert('error', msz, 5);
		}
	}

	submitPaymentHTTP()
	{
		let put_data: any = {};
		for (let key in this.billing_history) {
			if (key != 'cc_details'){
				put_data[key] = this.billing_history[key];
			}
		}

		if (put_data['state'] == "Not Available" || put_data['state'] == ""){
			put_data['state'] = ""
		}

		put_data['name'] = put_data['first_name'] + ' ' + put_data['last_name']

		if (put_data['email'] != this.appData.get('user_id')){
			if (put_data['recipient_email'].trim() == ''){
				put_data['recipient_email'] = put_data['email'];
			}
			else{
				put_data['recipient_email'] += "," + put_data['email'];
			}
		}

		this.api.post('/assets/LEED:'+ this.appData.get('leed_id') +'/payments/paynow/', put_data).subscribe(
			//success handler
			data =>
			{
				this.alertService.alert('success', 'Payment received', 5);
				this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing'});
			},
			//error handler
			error =>
			{
				let data = error.error;
				this.first_change_card = false;
				this.first_change_ccv = false;
				this.submitPaymentLoader = false;
				this.isFormSubmitted = false;
				this.createPaymetricForm();
				this.paynow_btn_text = "Pay now";
				for (let i = 0; i < data.length; i++) {
					if (data[i].Type == 'E' || data[i].Type == 'A'){
						this.alertService.alert('error', data[i].message, 5);
					}
				}
			}
		);
	}

	checkPaymetricReturn()
	{
		$('iframe#paymetric_iframe').off();
		$('iframe#paymetric_iframe').load(<any>(() =>
		{
			this.billing_history.paymetric_r = this.global.getParameterFromURLByName('r', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);
			this.billing_history.paymetric_s = this.global.getParameterFromURLByName('s', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);

			if (((<any>document.getElementById('paymetric_iframe')).contentWindow.location.href).indexOf("paymetric") > -1 && this.billing_history.paymetric_r && this.billing_history.paymetric_s){
				if (!this.lock)
				{
					this.lock = true;
					this.submitPaymentHTTP();
				}
			}
		}));
	}

	createPaymetricForm()
	{
		let redirectURLHost = window.location.origin;
		this.api.get('/assets/LEED:' + this.leed_id + '/payments/paymetricform/?redirectURLHost=' + redirectURLHost, 'text/html').subscribe(
			//success handler
			data =>
			{
				let doc = (<any>document.getElementById('paymetric_iframe')).contentWindow.document;
				doc.open();
				doc.write(data);
				doc.close();
				this.checkPaymetricReturn();
			},
			//error handler
			data =>
			{
			}
		);
	}


	checkForErrors()
	{
		this.has_error = false;
		this.validation_error = false;
		
		if(this.billing_history.first_name == undefined || (!this.regex.name.test(this.billing_history.first_name)))
		{
			this.has_error = true;
			this.FN_fail_CC = true;
		}
		if(this.billing_history.last_name == undefined || (!this.regex.name.test(this.billing_history.last_name)))
		{
			this.has_error = true;
			this.LN_fail_CC = true;
		}
		if(this.billing_history.cc_details == undefined || (!this.regex.CC_number.test(this.billing_history.cc_details.CcNumber) && this.updated_cc_details))
		{
			this.has_error = true;
		}
		if(this.billing_history.cc_details == undefined || ((!this.regex.CC_expiry.test(this.billing_history.cc_details.CcValidT) ||  this.validateCardExpiry()) && this.updated_expiry_details))
		{
			this.has_error = true;
		}
		if(this.billing_history.cc_details == undefined || (!this.regex.CC_CVV.test(this.billing_history.cc_details.CcVerifValue) && this.updated_cvv_details))
		{
			this.has_error = true;
		}
		if(this.billing_history.street == undefined || (!this.regex.name.test(this.billing_history.street)))
		{
			this.has_error = true;
			this.street_fail_CC = true;
		}
		if(this.billing_history.city == undefined || (!this.regex.name.test(this.billing_history.city)))
		{
			this.has_error = true;
			this.city_fail_CC = true;
		}
		if(!this.regex.name.test(this.billing_history.zip_code))
		{
			this.validation_error = true;
			this.has_error       = true;
		}
		if(!this.regex.phone_number.test(this.billing_history.phone))
		{
			this.has_error = true;
		}
		if(!this.regex.invoice.test(this.billing_history.invoice_number))
		{
			this.has_error = true;
		}
		if(String(this.billing_history.state).length == 0 || this.billing_history.state == null)
		{
			this.billing_history.state = '';
		}

		if (this.recipient_email_fail){
			this.has_error = true;
		}
		
		if(this.has_error == false)
		{
			this.continue_btn_txt = 'Validating...';
			this.validateZipCode(this.billing_history.state, this.billing_history.country, this.billing_history.zip_code);
			
		}
		else
		{
			this.submitPaymentLoader = false;
			this.paynow_btn_text = "Pay now";
		}
	}
}

import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

@Component({
  selector: 'app-insight-zepi-score',
  templateUrl: './insight-zepi-score.component.html',
  styleUrls: ['./insight-zepi-score.component.css']
})
export class InsightZepiScoreComponent implements OnInit
{
	loading: boolean = false;

	project: any = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');
	zepi_score: any = {};
	project_reduction: any = {};
	
	net_zero_energy: number = 0;
	net_zero_water: number = 0;
	net_zero_waste: number = 0;
	energy_estimate: number = 0;
	water_estimate: number = 0;
	waste_estimate: number = 0;
	energy_reduction_points: number = 0;

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		this.loading = true;
		this.insight.get(
			'/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.version_id
		).subscribe(
            //success handler
            response =>
            {
                this.project_reduction = response;
                var reduction_data = response.reduction_data;
                for(var k in reduction_data)
                {
                    if(reduction_data[k].credit_id == 'net_zero_energy')
                    {
                        this.net_zero_energy = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_water')
                    {
                        this.net_zero_water = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_waste')
                    {
                        this.net_zero_waste = reduction_data[k].estimation;
                    }

                    if(reduction_data[k].credit_id == 'energy_estimate')
                    {
                        this.energy_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'water_estimate')
                    {
                        this.water_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'waste_estimate')
                    {
                        this.waste_estimate = reduction_data[k].estimation;
                    }
                    
                    if(reduction_data[k].credit_id == 'EA903')
                    {
                        this.energy_reduction_points = reduction_data[k].estimation;
                    }
                }

                if(this.net_zero_energy)
                {
                    this.energy_estimate = 0;
                }
                if(this.net_zero_water)
                {
                    this.water_estimate = 100;
                }
                if(this.net_zero_waste)
                {
                    this.waste_estimate = 100;
                }

                this.insight.getZepi(this.project, this.energy_reduction_points, this.net_zero_energy).then((response) =>
                {
                    this.zepi_score = response;
                    this.loading = false;
                });
            },
            //error handler
            error =>
            {
                this.loading = false;
            }
        );
	}

}

import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { PortfoliosService } from 'src/app/services/portfolios.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';
import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-portfolio-performance',
  templateUrl: './portfolio-performance.component.html',
  styleUrls: ['./portfolio-performance.component.css']
})
export class PortfolioPerformanceComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent
	loading: boolean = false;
	portfolio_id: number = this.appData.get('portfolio_id')

	current_tab: string = 'Summary';
	myportfolioprojects: any = [];
	count: number = 0;
	page: number = 1;
	page_size: number = 10;
	page_count: number = 1;
	sort_column: string = 'total_score';
	sort_order: string = 'desc';
	is_projects_available: any = null;
	nextPage: any = null;
	score_type: string = 'weighted';
	score_sort: string = 'Sort: Total Score';
	project_selected: any = {};
	score_categories: any =
	[            
		{name:'Total Score', value:'total_score', filter: false},
		{name:'Alphabetical', value:'name', filter: false},
		{name:'Energy', value:'energy', filter: false},
		{name:'Water', value:'water', filter: false},
		{name:'Waste', value:'waste', filter: false},
		{name:'Transportation', value:'transport', filter: false},
		{name:'Human Experience', value:'human_experience', filter: false},
		{name:'Base Points', value:'base', filter: false}         
	];
	score_filters: string[] = ['total_score', 'energy', 'water', 'waste', 'transport', 'human_experience', 'base'];

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private portfolios: PortfoliosService,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		$('#total_score_check').prop("checked", true);
		this.goToTab(this.current_tab);
        this.getPortfolioProjectsData(1, 10);
	}

	goToTab(tab: string)
	{
		this.current_tab = tab;
	}

	changeScoreType(score_type: string)
	{
		if(score_type == 'score')
		{
			this.sort_column = '100_total_score';
		}
		else
		{
			this.sort_column = 'total_score';
		}
		this.score_sort = 'Sort: Total';
		this.sort_order = 'desc';
		this.score_type = score_type;
		this.getPortfolioProjectsData(this.page, this.page_size);
	};

	filterScore(category: any)
	{
		$('#' + category.value + '_check').prop("checked", true);
		if(category.value == 'total_score')
		{
			this.score_filters = ['total_score', 'energy', 'water', 'waste', 'transport', 'human_experience', 'base'];
		}
		else
		{
			this.score_filters = [category.value];
		}
		this.changeScoreSort(category.value, category.name);
	};

	changeScoreSort(key: string, name: string)
	{
		this.score_type = 'weighted';
		this.score_sort = 'Sort: ' + name;
		this.sort_order = key == 'name' ? 'asc' : 'desc';
		this.sort_column = key;
		this.getPortfolioProjectsData(this.page, this.page_size);
	};

	getFilters(date: any)
	{
		return '?order='+ this.sort_order +'&category='+ this.sort_column +'&page_size='+ this.page_size +'&page='+ this.page +'&at=' + date;
	};

	getPortfolioProjectsData(page: number, page_size: number)
	{   
		if(page_size == 0 || page_size < 10)
		{
			page_size = 30;
		}
		if(page > this.page_count || page <= 0)
		{
			return;
		}
		this.page = page;
		this.page_size = page_size;
		var ranges = this.global.getSavedRanges();
		var end_date = ranges[2]
		if(moment(end_date).isAfter(moment(new Date()).format('YYYY-MM-DD')))
		{
			var filters = this.getFilters(moment(new Date()).format('YYYY-MM-DD'));
		}
		else
		{
			var filters = this.getFilters(end_date);
		}
		this.loading = true;
		this.portfolios.getProjects(this.portfolio_id, filters).subscribe(
			//success handler
			data =>
			{   
				var asset_list = data.results;
				for(var i = 0; i < asset_list.length; i++)
				{
					if(asset_list[i].building.activation_source == 'GRESB')
					{
						asset_list[i]['is_gresb'] = true;
						if (asset_list[i].building.building_status != 'activated_addendum_agreement_pending'){
							asset_list[i]['is_gresb_activated'] = true;
						}
						else{
							asset_list[i]['is_gresb_activated'] = false;
						}
					}
					else
					{
						asset_list[i]['is_gresb'] = false;
					}

				}
				this.loading = false;
				if(data.count == 0)
				{
					this.is_projects_available = false;
				}
				else if(data.count > 0)
				{
					this.is_projects_available = true;
					this.count = data.count;
					this.page = page;
					this.page_size = page_size > this.count ? this.count : page_size;
					this.page_size = this.page_size < 10 ? 10 : this.page_size;
					this.page_count = Math.ceil(this.count / this.page_size);

					for(var i = 0; i < data.results.length; i++)
					{
						data.results[i].building.base_score = isNaN(parseInt(data.results[i].building.base_score)) ? 0 : data.results[i].building.base_score;
						data.results[i].building.energy = isNaN(parseInt(data.results[i].building.energy)) ? 0 : data.results[i].building.energy;
						data.results[i].building.water = isNaN(parseInt(data.results[i].building.water)) ? 0 : data.results[i].building.water;
						data.results[i].building.waste = isNaN(parseInt(data.results[i].building.waste)) ? 0 : data.results[i].building.waste;
						data.results[i].building.transport = isNaN(parseInt(data.results[i].building.transport)) ? 0 : data.results[i].building.transport;
						data.results[i].building.human_experience = isNaN(parseInt(data.results[i].building.human_experience)) ? 0 : data.results[i].building.human_experience;
						data.results[i].building['base'] = data.results[i].building.base_score / 10;
					}

					this.myportfolioprojects = data.results;
					if (data.next == null)
					{
						this.nextPage = null;
					}
					else
					{
						this.nextPage = data.next.split('?page=')[1];
					}
				}
			},
			//error handler
			data =>
			{
				this.loading = false;
			}
		);
	}

	sortList(sort_order: string, sort_column: string)
	{
		if(sort_column == 'category_score')
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
		}
		else if(sort_column == 'score')
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
			this.sort_column = '100_total_score';
		}
		else if(sort_column == 'name')
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
			this.sort_column = 'name';
		}
		else if(sort_column == 'weighted')
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
			this.sort_column = 'total_score';
		}
		else if((this.sort_column == 'total_score' || this.sort_column == '100_total_score') && this.score_type == 'score')
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
			this.sort_column = '100_total_score';
		}
		else if(sort_column == this.sort_column)
		{
			this.sort_order = sort_order == 'desc' ? 'asc' : 'desc';
		}
		else
		{
			this.sort_order = 'desc';
			this.sort_column = sort_column;
		}
		this.getPortfolioProjectsData(1, 10);
	};

	searchMoveToProjectLock(project: any, index: number)
	{
		if (project.building_status == 'Pending Registration')
		{
			let space_type_mapping: any = {
				"RSM": "Retail: Enclosed Mall",
				"OFF": "Office: Other Office",
				"DWH": "Warehouse: Nonrefrigerated Distribution/Shipping",
				"MAN": "Industrial Manufacturing",
				"RMF": "Multifamily Residential: Apartment",
				"RFA": "Single family home (attached)",
				"RSE": "Lodging: Other lodging",
				"RST": "Lodging: Other lodging",
				"HOT": "Lodging: Hotel/Motel/Report, Full Service",
				"HEC": "Healthcare: Clinic/Other outpatient",
				"MED": "Office: Medical (non-diagnostic)",
				"LEI": "Public Assembly: Recreation",
				"DAT": "Data Center",
				"SST": "Warehouse: Self Storage Units",
				"null": ""
			}
			var space_type = space_type_mapping[String(project.space_type)];

			this.global.goToApp("forms", 
			{
				"app_name": "project_creation",
				"project_type": project.project_type,
				"name": project.name,
				"country": project.country,
				"address": project.address,
				"space_type": space_type,
				"partner_asset_id": project.partner_asset_id
			});
		}
		else{
			this.api.get('/assets/LEED:' + project.leed_id + '/').subscribe(
				//success handler
				response =>
				{
					this.global.moveToProject(response, index, undefined);
				},
				//error handler
				response =>
				{
					this.alertService.alert('success', 'Sorry! You don\'t have access to this project.', 5);
				}
			);
		}
	};

	getScoreWidth(score: number, type: string): number
	{
		score = isNaN(score) ? 0 : score;
		if(this.score_type == 'weighted')
		{
			return score;
		}
		else if(type == 'energy')
		{
			return Math.round((score/100) * 33);
		}
		else if(type == 'water')
		{
			return Math.round((score/100) * 15);
		}
		else if(type == 'waste')
		{
			return Math.round((score/100) * 8);
		}
		else if(type == 'transport')
		{
			return Math.round((score/100) * 14);
		}
		else if(type == 'human_experience')
		{
			return Math.round((score/100) * 20);
		}
		else if(type == 'base')
		{
			return score / 10;
		}
		return 0;
	};

	cancel()
	{
		this.modal.close();
	}

	openEstimateModal(project: any)
	{
		this.project_selected = project;
		this.modal.open({ windowClass: 'estimate_modal', size: 'lg' });
	};

}

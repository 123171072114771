import { Component, NgModule, OnInit } from '@angular/core';

import { alertService } from 'src/app/services/alert-service.service';
import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './admin_analysis.component.html',
  styleUrls: ['./admin_analysis.component.css']
})
export class AdminAnalysisComponent implements OnInit {
  energyAnalysisInfo: any = {};
  sourceAnalysisInfo: any = {};
  waterAnalysisInfo: any = {};
  wasteAnalysisInfo: any = {};
  transportAnalysisInfo: any = {};
  humanAnalysisInfo: any = {};
  loader: boolean = false;
  leed_id: any = '';
  Object: any = Object;
  
  constructor(
    public global: Global,
    public appData: AppData,
    private api: API,
    private alertService: alertService
  ) { }

  getInfo() {
    this.loader = true;
    this.api.get('/assets/LEED:' + this.leed_id + '/analysis/recompute/?invocation_type=sync').subscribe(
      data => {
        if (data.result.energy !== null && !data.result.energy.errors) {
          this.energyAnalysisInfo = data.result.energy;          
        }
        else {
          this.energyAnalysisInfo = {};
        }
        if (data.result.source !== null && !data.result.source.errors) {
          this.sourceAnalysisInfo = data.result.source; 
        }
        else {
          this.sourceAnalysisInfo = {};
        }

        if (data.result.water !== null && !data.result.water.errors) {
          this.waterAnalysisInfo = data.result.water;
        }
        else {
          this.waterAnalysisInfo = {};
        }

        if (data.result.waste != null && !data.result.waste.errors) {
          this.wasteAnalysisInfo = data.result.waste;
        }
        else {
          this.wasteAnalysisInfo = {};
        }

        if (data.result.transport !== null && !data.result.transport.errors) {
          this.transportAnalysisInfo = data.result.transport;
        }
        else {
          this.transportAnalysisInfo = {};
        }

        if (data.result.human_experience !== null && !data.result.human_experience.errors) {
          this.humanAnalysisInfo = data.result.human_experience;
        }
        else {
          this.humanAnalysisInfo = {};
        }
        if(data.result.energy.errors && data.result.source.errors && data.result.water.errors && data.result.waste.errors && data.result.transport.errors && data.result.human_experience.errors){
          this.alertService.alert('success', 'This project does not have enough data to recompute Analysis', 5);
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  isValidObject(value: any) 
  {
      if (typeof value === "object" && !Array.isArray(value) && value !== null){
        return true;
      }
      else{
        return false;
      }
  };

  ngOnInit(): void {
  }

}
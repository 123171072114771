<div class="modal-body"> 
        <div class="row p20 fw-400">
            <p>         
                To select an option, click one of the check boxes. Once you have selected an option, the documentation requirements for that option will appear.
            </p>
            <p> 
                Upload each of the required documents using the button to the left of the word “Upload” on the right hand side of your screen. Indicate which documents you have uploaded by clicking the check boxes next to each of the documentation requirements.
            </p>    
            <p>
                To reference the measure language for this measure, click the “Measure Language (PDF)” button on the right hand side of your screen.
    
            </p>
    
        </div>
    </div>
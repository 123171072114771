<app-sidebar [sidebar_type]="'user-nav'"></app-sidebar>

<div class="content-wrap" [ngClass]="loading ? 'not-active' : ''">
	<div id="content" class="content">

        <h1 class="page-title">
            <span class="fw-semi-bold">Reports</span>
        </h1>
        
        <div class="">
        <div class="row">
            <div>        
                <section [ngClass]="loading ? 'not-active' : ''" class="widget manage_agreements">
                    <app-loader *ngIf="loading"></app-loader>
                    <div>
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th class="unselectable cursor-pointer fs15 init_text_transform">
                                        Project name({{reports.length}})
                                    </th>
                                    <th class="unselectable cursor-pointer fs15 init_text_transform">
                                        Report type
                                    </th>
                                    <th class="unselectable cursor-pointer fs15 init_text_transform">
                                        Date created
                                    </th>
                                    <th class="fs15 init_text_transform">
                                        View report
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="fs15" *ngFor="let certificate of reports">
                                    <td>
                                        <span>{{certificate.name}} - <span class="fs13 gray_color_707479">{{certificate.leed_id}}</span></span>
                                    </td>
                                    <td>
                                        <svg *ngIf="certificate.file_type == 'pdf'" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-pdf" class="svg-inline--fa fa-file-pdf fa-w-12 pdf_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"></path></svg>
                                        <svg *ngIf="certificate.file_type == 'excel'" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-excel" class="svg-inline--fa fa-file-excel fa-w-12 pdf_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"></path></svg>
                                        <div>
                                            {{certificate.type}}
                                            <span *ngIf="certificate.autocase">- <span class="fs13 gray_color_707479">Autocase Report</span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{{global.dateTimeFormat(certificate.date)}}</span>
                                    </td>
                                    <td>
                                        <a (click)="downloadCert(certificate)">Download</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
        
                        <div class="noProjects" *ngIf="reports.length == 0">
                            No reports created
                        </div>
        
                        <div class="align_r list_controls fw-500" *ngIf="false">
                            <div class="dropdown display-inline ml10">
                                <span class="mr10">Rows per page:</span>
                                <button class="btn dropdown-toggle dropdown-clean btn-default btn-sm" type="button" data-toggle="dropdown" >
                                    <span>{{page_size}}</span>
                                    <i class="fa fontSize16 ml10 fa-caret-down"></i>
                                </button>
                                <ul class="dropdown-menu" style="top: -122px;">
                                    <li (click)="getReports(1, 10)">
                                        <a>10</a>
                                    </li>
                                    <li (click)="getReports(1, 20)">
                                        <a>20</a>
                                    </li>
                                    <li (click)="getReports(1, 30)">
                                        <a>30</a>
                                    </li>
                                    <li (click)="getReports(1, 40)">
                                        <a>40</a>
                                    </li>
                                    <li (click)="getReports(1, 50)">
                                        <a>50</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex pt2 ml40">
                                <span class="mr30">
                                    Page {{page}} of {{page_count}}
                                </span>
                                <div>
                                    <i [ngClass]="(page - 1) == 0 ? 'not-active' : ''" (click)="getReports(page - 1, page_size)" class="mr40 fa fa-chevron-left"></i>
                                    <i [ngClass]="page == page_count ? 'not-active' : ''" (click)="getReports(page + 1, page_size)" class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </section>
         
            </div>
        </div>
        
        </div>

    </div>
</div>
<div class="content-wrap" [ngClass]="loader ? 'not-active': ''">
    <app-loader *ngIf="loader"></app-loader>
	<div id="content" class="content">
        
        <ng-template let-option="option" #recursive_renderer>
            <div [hidden]="checkView(option) || option.hidden" [ngClass]="option.disabled || config.access != 'CRUD'? 'not-active' : ''">
                <div [ngStyle]="{'padding-left': (option.indent_level * 25) + 'px'}" class="mt10 cursor-pointer">

                    <div #parent [ngClass]="loader ? 'not-active' : ''" *ngIf="option.content_type == 'checkbox' || option.content_type == 'button' || option.content_type == 'input' || option.content_type == 'textarea'" class="{{option.content_type}}">
                        <div class="affirmationLoaderSpinner" *ngIf="loader"></div>
                        <input [id]="option.id" (click)="changeMeasure(option, parent)" [(checked)]="option.data" name="measure_check" type="{{option.content_type}}"/> 
                    
                        <label>
                            <span *ngIf="option.points != undefined">
                                <span *ngIf="option.points == '1'">[{{option.points}} Point]</span>
                                <span *ngIf="option.points != '1'">[{{option.points}} Points]</span>
                            </span>            
                            <span [innerHTML]="option.content"></span>
                        </label>
                    </div>

                    <div *ngIf="option.content_type == 'list_item'">
                        <span><div class="measureBullet fa fa-square fontSize7" aria-hidden="true"></div> </span>
                        <span [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'heading_item'">
                        <span class="fw-bold" [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'info_item'">
                        <span [innerHTML]="option.content"></span>
                    </div>
                </div>
                <div *ngIf="option.children != null">
                    <ng-container *ngFor="let option of option.children" [ngTemplateOutlet]="recursive_renderer" 
                            [ngTemplateOutletContext] ="{option: option}">
                    </ng-container>
                </div>
            </div>
        </ng-template> 

        <div class="ng-scope">
            <div class="dataTables_paginate paging_bootstrap pull-right" id="DataTables_Table_1_paginate">
                <ul class="pagination no-margin">
                    <button type="button" class="btn btn-default prev mr10" (click)="measureScreenChange('previous')"><i class="font11 mr7 fa fa-chevron-left" aria-hidden="true"></i> Previous</button>
                    <button type="button" class="mr0 btn btn-default next" (click)="measureScreenChange('next')">Next <i class="font11 ml7 fa fa-chevron-right" aria-hidden="true"></i></button>
                </ul>
            </div>
            <div class="row" id="actions-form">
                <div class="display-inline col-md-8 pr0 pl0">
                    <h2 class="floatl mb0 page-title"><span class="fw-semi-bold">
                        Measure {{measure.measure_id}} - {{measure.name}}</span>
                    </h2>
                    <span class="floatr mb20 mt20 pr30"> 
                        {{points_pursued}} of {{measure.max_points}} Points Pursued
                    </span>
                </div>
                <div class="col-md-8">
                    <section class="widget">

                        <div *ngIf="measure_list.length == 0" style="font-size: 19px;color: darkgrey;" class="align-center">
                                
                            <span style="display: inline-block;margin-top:0px;width: 12px; height: 12px;margin-bottom: 1px;" class="deleteText dataInputLoaderSpinner pull-left floatNone mr5 height10 width10"></span><span>Loading Measure...</span>
                        
                        </div>

                        <div class="align_lr">
                            <div *ngIf="measure_list.length > 0" class="display-inline width-100-perc">
                                <span *ngIf="measure_list.length == 1" class="floatl w70p fw-semi-bold">Select the option your project is pursuing and upload required documentation</span>
                                <span *ngIf="measure_list.length > 1" class="floatl w70p fw-semi-bold">Select the {{getOptionLabel(measure.measure_id)}} your project is pursuing and upload required documentation</span>
                            </div>
                            <div class="widget-controls">
                                <div class="cursor-pointer" (click)="global.showHelpText('parksmart')">
                                    <i class="fa fa-question-circle"></i>
                                </div> 
                            </div>
                        </div>

                        <ng-container *ngFor="let option of measure_list" [ngTemplateOutlet]="recursive_renderer" 
                            [ngTemplateOutletContext] ="{option: option}">
                        </ng-container>

                    </section>

                    <section class="widget">
                        <h3>Activity</h3>
                        <section class="event activity_container">
                            <ul>
                                <li *ngFor="let measure_activity of measure_activities">
                                    <div class="comment-body">
                                        <h5 class="author fw-semi-bold">
                                            <span [innerHTML]="measure_activity.verb.replace('<a', '<span').replace('</a>', '</span>')"></span>
                                            <span class="fs-sm text-muted">on {{global.dateTimeFormat(measure_activity.timestamp)}}</span></h5>
                                    </div>
                                </li>
                            </ul>
                            <div *ngIf="measure_activities.length == 0" class='text-center no_act_div'>No activity present</div>
                        </section>
                    </section>  
                    
                    <div class="align-right">
                        <a class='blue_a_tag mr10' href="http://parksmart.gbci.org/faq" target="_blank" tabindex="1">FAQs</a>
                        <a class='blue_a_tag mr10' href="mailto:parksmart@gbci.org" tabindex="2">Contact us</a>
                        <a class='blue_a_tag' href="http://parksmart.gbci.org/resources" target="_blank" tabindex="3">Parksmart Resources</a>
                    </div>
                </div>

                <div class="col-md-4" >
                    <section class="widget col-md-12">
                        <div class="widget-body">
                            
                                <button (click)="downloadMeasure(measure)" type="button" class="mb15 mt10 btn btn-default" title="">Measure Language (PDF)</button>

                                <h3 class="page-title mt0 pl0 mb10 fs20">Upload Documentation</h3>
                                <div>
                                    <div *ngFor="let uploaded_file of config.uploaded_files" [ngClass]="appData.get('credit').CreditStatus == 'Ready for Review' ||  appData.get('credit').CreditStatus == 'Under Review' ? 'not-active' : ''" class="mb5 flex">
                                        <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" (click)=global.deleteFile(uploaded_file)>
                                            <span  class='far fa-trash-alt cursor-pointer align_file_name mr10'></span>
                                        </div>
                                        <div>
                                            <a (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                        </div>
                                    </div>
                                    
            
                                    <div *ngIf="config.currentUpload">
                                        <span class='uploadLoaderSpinner'></span>
                                        <span [innerHTML]="config.currentUploadName"></span>
                                    </div>
                                    
                                </div>
                                <div class="width-100-perc floatl mt10" [ngClass]="appData.get('credit').CreditStatus == 'Ready for Review' ||  appData.get('credit').CreditStatus == 'Under Review' ? 'not-active' : ''">

                                    <div *ngIf="!global.getBrowser().ie">
                                            <input (input)="global.fileNameChanged(elem)" #elem class="hide uploadFile" type="file">
                                            <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" data-tooltip-placement="top" data-tooltip="Upload using computer" (click)="global.openUploadDialog('laptop', measure.measure_id, 'S3')" class="viewUpload laptop"><i class="fa fa-upload" aria-hidden="true"></i></div>
                                        </div>
                
                                        <div *ngIf="global.getBrowser().ie" class="btn-group dropdown open" data-dropdown="" style="">
                                            <button class="btn btn-default dropdown-toggle" data-dropdown-toggle="" data-original-title="" title="" aria-haspopup="true" aria-expanded="true"><i class="fa fa-upload"></i> Upload<i class="ml10 fa fa-caret-down"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" (click)="global.openUploadDialog('laptop', measure.measure_id, 'S3')" class="pl10" href="#">
                                                        <i class="fa fa-desktop"></i> From Computer 
                                                    </a>
                                                    <input (input)="global.fileNameChanged(elem)" #elem class="hide uploadFile" type="file">
                                                </li>
                                            </ul>
                                        </div>
                                </div>
                        </div>
                    </section>
                </div>
            
        </div>

        
    </div>
</div>

<app-sidebar [sidebar_type]="'user-nav'"></app-sidebar>

<div class="content-wrap" [ngClass]="loading ? 'not-active' : ''">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Billing</span>
        </h1>
        
        <div>
            <div class="row">
                <div>        
                    <section [ngClass]="loading ? 'not-active' : ''" class="widget manage_agreements">
                        <app-loader *ngIf="loading"></app-loader>
            
                        <ul class="meterNav mb25">
                            <li (click)="toggleTab('Subscriptions')" class="meterNav--item">
                                <span [ngClass]="current_tab == 'Subscriptions' ? 'meterNav--item__active' : ''">Subscriptions</span>
                            </li>
                            <li *ngIf="subscriptions.subscribed_by.username == appData.get('user_id') && subscriptions.subscribers.length != 0" (click)="toggleTab('Billing & Payments')" class="meterNav--item">
                                <span [ngClass]="current_tab == 'Billing & Payments' ? 'meterNav--item__active' : ''">Billing & Payments</span>
                            </li>
                            <div *ngIf="subscriptions.subscribed_by.username == appData.get('user_id') && appData.get('user_type') != 'arc_japan' && !cancelled_self" class="ff-ss-black black-color cert_status p0">
                                <button (click)="addSubs()" class="fw-500 btn btn-primary inverse">
                                    Add Subscription
                                </button>
                            </div>
                        </ul>
            
                        <div *ngIf="current_tab == 'Subscriptions'">
                            <div *ngIf="status_subs == 'active' && subscriptions.next_billing_date != null && subscriptions.subscribed_by.username == appData.get('user_id') && subscriptions.subscribers.length != 0" class="mb25">
                                <div class="line_height_24 mb15 align_lr w100p">
                                    <div class="fw-500 fs18">Your next bill is for {{config.currency}}{{global.addCommas(subscriptions.amount)}} on {{global.dateFormat(subscriptions.next_billing_date)}}</div>
                                    <div (click)="global.openPolicy()"><a>Subscription policy</a></div>
                                </div>
                                <div class="flex" [hidden]="appData.get('user_type') == 'arc_japan'"> 
                                    <div class="flex w50p">
                                        <i class="fa fa-credit-card fs40 gray_color_68737D mr10 pt15"></i>
                                        <div class="mr40">
                                            <div class="fw-500 line_height_24 fs15">
                                                <span class="capitalize">{{subscriptions.card.brand}}</span> ending in {{subscriptions.card.last4}}
                                            </div>
                                            <div class="flex">
                                                <div [ngClass]="getMonthDiff(2) ? 'error-color' : ''" class="line_height_24 fs15 gray_color_68737D">
                                                    Expires: {{subscriptions.card.exp_month}}/{{subscriptions.card.exp_year}}
                                                </div>
                                                <div class="ml8 mt4" (click)="editCard()">
                                                    <a>Update payment method</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div *ngIf="subscriptions.subscribed_by.username != appData.get('user_id') && subscriptions.subscribers.length != 0 && offline_sub == false" class="mb25">
                                <div class="fw-500 line_height_24 mb15 fs18">
                                    Your subscription is paid by {{subscriptions.subscribed_by.first_name + " " + subscriptions.subscribed_by.last_name}}
                                </div>
                            </div>
                            <div *ngIf="status_subs == 'cancelled_active'" class="mb25">
                                <div class="fw-500 line_height_24 mb15 fs18">
                                    Your subscription to Arc Essentials is cancelled and will end on {{global.dateFormat(subscriptions.next_billing_date)}}.
                                </div>
                            </div>
                            <div *ngIf="status_subs == 'expired'" class="mb25">
                                <div class="fw-500 line_height_24 mb15 fs18">
                                    Your subscription to Arc Essentials is expired/cancelled.
                                </div>
                            </div>
                
                            <div>
                                <table class="table table-striped arc-table">
                                    <thead>
                                        <tr>
                                            <th class="cursor-pointer">
                                                Subscriber
                                            </th>
                                            <th class="cursor-pointer">
                                                Email
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="subscriptions.subscribers.length != 0">
                                        <tr *ngFor="let data of subscriptions.subscribers">
                                            <td [ngClass]="data.status != 'active' ? 'not-active' : ''" class="fw-500">
                                                {{data.name}}
                                            </td>
                                            <td [ngClass]="data.status != 'active' ? 'not-active' : ''">
                                                {{data.email}}
                                            </td>
                                            <td>
                                                <a *ngIf="data.status == 'active'" (click)="openCancel(data)">Cancel</a>
                                                <span *ngIf="data.status == 'cancelled_active'" (click)="cancelSubs(data)">Cancelled</span>
                                                <span *ngIf="data.status == 'expired'" (click)="cancelSubs(data)">Expired</span>
                
                                                <i *ngIf="data.status == 'cancelled_active'" ng-mouseover="insertTippy($event, 'Your subscription has been cancelled')" class="cursor-pointer fa fontSize16 ml10 fa-exclamation-circle"></i>
                                                <i *ngIf="data.status == 'expired'" ng-mouseover="insertTippy($event, 'Your subscription has expired')" class="cursor-pointer fa fontSize16 ml10 fa-exclamation-circle"></i>
                                            </td>
                                        </tr>				    
                                    </tbody>
                                    <tbody *ngIf="subscriptions.subscribers.length == 0">
                                        <tr>
                                            <td colspan="5" class="noProjects">
                                                No subscription active for your account.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
            
                        <div *ngIf="current_tab == 'Billing & Payments'">
                            <div [hidden]="subscriptions.subscribers.length == 0" class="subs_billing">
                                <div class="b_item fw-500 fs16">
                                    Billing
                                </div>
                                <div class="b_item fs16 line_height_22">
                                    <div class="flex">
                                        {{subscriptions.street}} <br>
                                        {{subscriptions.city}}, {{subscriptions.state}} {{subscriptions.zip_code}} <br>
                                        {{subscriptions.phone}} <br>
                                    </div>
                                </div>
                                <div class="b_item fs14 align-right">
                                    <a (click)="editBilling()">Update billing information</a>
                                </div>
                            </div>
                            <div [hidden]="subscriptions.subscribers.length == 0 || appData.get('user_type') == 'arc_japan'" class="subs_billing pt24 mb25">
                                <div class="b_item fw-500 fs16">
                                    Payment method
                                </div>
                                <div class="b_item">
                                    <div class="flex"> 
                                        <i class="fa fa-credit-card fs40 gray_color_68737D mr10 pt15"></i>
                                        <div>
                                            <div class="fw-500 line_height_24 fs15">
                                                <span class="capitalize">{{subscriptions.card.brand}}</span> ending in {{subscriptions.card.last4}}
                                            </div>
                                            <div [ngClass]="getMonthDiff(3) ? 'error-color' : ''" class="line_height_24 fs15 gray_color_68737D">
                                                Expires: {{subscriptions.card.exp_month}}/{{subscriptions.card.exp_year}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="b_item fs14 align-right">
                                    <a (click)="editCard()">Update payment method</a>
                                </div>
                            </div>
            
                            <div class="fs16 fw-500 line_height_20 mb5">Billing history</div>
                            <table class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th>Date Issued</th>
                                        <th>Order ID</th>
                                        <th>Order Type</th>
                                        <th>Total</th>
                                        <th>Invoice/Receipt</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="billing_history.length != 0">
                                    <tr *ngFor="let data of billing_history">
                                        <td>
                                            {{global.dateFormat(data.created_at)}}
                                        </td>
                                        <td>
                                            {{data.sap_order_id}}
                                        </td>
                                        <td>
                                            {{data.order_type == 'month' ? 'Monthly' : 'Yearly'}}
                                        </td>
                                        <td>
                                            {{config.currency}}{{global.addCommas(data.amount)}}
                                        </td>
                                        <td>
                                            <a href="{{data.receipt_url}}" target="_blank">Download</a>
                                        </td>
                                    </tr>				    
                                </tbody>
                                <tbody *ngIf="billing_history.length == 0">
                                    <tr>
                                        <td colspan="5" class="noProjects">
                                            No subscription active for your account.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
            
                    </section>
            
                </div>
            </div>
        
        </div>

    </div>
</div>

<modal #modal>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
        <div class="left-right-align pb16">
            <div class="fs24 line_height_40 fw-500">
                Cancel Subscription
            </div> 
        </div>
        <!-- <div class="line_height_32 fs15 fw-600">Select user(s) </div> -->
        <div>
            <table class="table table-striped arc-table">
                <thead>
                    <tr>
                        <th>
                            
                        </th>
                        <th style="text-transform: initial !important;" class="cursor-pointer">
                            Subscriber
                        </th>
                        <th style="text-transform: initial !important;" class="cursor-pointer" >
                            Email
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="subscriptions.subscribers.length != 0">
                    <tr *ngFor="let data of subscriptions.subscribers; let idx = index;">
                        <td [ngClass]="data.status != 'active' ? 'not-active' : ''" (click)="toggleCheck(idx)">
                            <div class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
                                <input [(ngModel)]="data.select_all" name="{{data.email}}" type="checkbox">                
                                <label for="checkbox"></label>
                            </div>
                        </td>
                        <td [ngClass]="data.status != 'active' ? 'not-active' : ''" class="fw-500">
                            {{data.name}}
                        </td>
                        <td [ngClass]="data.status != 'active' ? 'not-active' : ''">
                            {{data.email}}
                        </td>
                    </tr>				    
                </tbody>
                <tbody *ngIf="subscriptions.subscribers.length == 0">
                    <tr>
                        <td colspan="5" class="noProjects">
                            No subscription active for your account.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <div class="pt16">
            <button [ngClass]="!enable ? 'not-active' : ''" (click)="cancelSubsModal()" data-dismiss="modal" type="button" class="btn btn-primary btn-md mr25">Cancel</button>
            <button (click)="modal.close()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Close</button>
        </div>
    </div>
</modal>
<div>
	<div>
       
        <div>

            <div [ngClass]="loading ? 'not-active' : ''" class="">
                <app-loader *ngIf="loading"></app-loader>
                
                <div class="insight_project_reduction">
                    <div class="fw-500 line_height_24 fs20 mb25">zEPI Score</div>

                    <div class="between-div">
                        <div class="w25p">
                            <div class="fs30 line_height_32 mb3">{{global.formatNumber(zepi_score.zepi_score, 2)}}</div>
                            <div class="line_height_24 fs14 grey_color">Current zEPI Score</div>
                        </div>
                        <div class="w33p">
                            <div class="fs15 fw-500 line_height_18 zepi_bottom_line pb5 mb8">Score Inputs</div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Space type</div>
                                <div>{{project.spaceType}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Climate Zone</div>
                                <div>{{zepi_score.zepi_climateZone}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">LEED version</div>
                                <div>{{project.rating_system}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Energy Points</div>
                                <div>{{energy_reduction_points}}</div>
                            </div>
                        </div>
                        <div class="w33p">
                            <div class="fs15 fw-500 line_height_18 zepi_bottom_line pb5 mb8">Score Details</div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Baseline code</div>
                                <div>{{zepi_score.baseline_code}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Baseline EUI</div>
                                <div>{{zepi_score.baseline_eui == null ? '-' : zepi_score.baseline_eui}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Percent reduction</div>
                                <div>{{zepi_score.percent_red}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">Estimated building EUI</div>
                                <div>{{global.formatNumber(zepi_score.est_building_eui, 0)}}</div>
                            </div>
                            <div class="fs15 between-div line_height_24 mb8">
                                <div class="grey_color">zEPI baseline EUI</div>
                                <div>{{zepi_score.zepi_baseline_eui == null ? '-' : zepi_score.zepi_baseline_eui}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

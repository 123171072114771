import { Component, OnInit } from '@angular/core';

import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-recompute-improvement-scores',
  templateUrl: './recompute-improvement-scores.component.html',
  styleUrls: ['./recompute-improvement-scores.component.css']
})
export class RecomputeImprovementScoresComponent implements OnInit {
  leed_id: any = '';
  energyArray: any = [];
  waterArray: any = [];
  wasteArray: any = [];
  transportArray: any = [];
  humanExperienceArray: any = [];
  loader: boolean = false;
  energyScoreInfo: any = {};
  waterScoreInfo: any = {};
  wasteScoreInfo: any = {};
  transportScoreInfo: any = {};
  humanScoreInfo: any = {};

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  getInfo(){
      this.loader = true;
      this.humanExperienceArray = [];

      this.api.get('/assets/LEED:' + this.leed_id + '/scores/improvement/recompute/').subscribe(
        data => {
          if(data.energy != null && data.energy != "None")
          {
              this.energyArray = [];
              this.energyScoreInfo = data.energy;
          }
          else    
          {
              this.energyScoreInfo = {};
              this.energyArray = [];
          }
          
          if(data.water != null && data.water != "None")
          {
              this.waterArray = [];
              this.waterScoreInfo = data.water;                    
          }

          else
          {
              this.waterScoreInfo = {};
              this.waterArray = [];
          }
          
          if(data.waste != null && data.waste != "None")
          {
              this.wasteArray = [];
              this.wasteScoreInfo = data.waste;                    
          }
          else
          {
              this.wasteScoreInfo = {};
              this.wasteArray = [];
          }
          
          if(data.transportation != null && data.transportation != "None")
          {
              this.transportArray = [];
              this.transportScoreInfo = data.transportation;
              
          }
          else
          {
              this.transportScoreInfo = {};
              this.transportArray = [];
          }
          
          if(data.human_experience != null && data.human_experience != "None")
          {
              this.humanExperienceArray = [];
              this.humanScoreInfo = data.human_experience;
          }
          else
          {
              this.humanScoreInfo = {};
              this.humanExperienceArray = [];
          } 
          
          for(var key in this.energyScoreInfo)
          {
              this.energyArray.push({'attr': key, 'value': this.energyScoreInfo[key]});
          }

          for(var key in this.waterScoreInfo)
          {
              
              this.waterArray.push({'attr': key, 'value': this.waterScoreInfo[key]});                                        
          }                                
          
          for(var key in this.wasteScoreInfo)
          {                    
              this.wasteArray.push({'attr': key, 'value': this.wasteScoreInfo[key]});
          }  

          for(var key in this.transportScoreInfo)
          {
              this.transportArray.push({'attr': key, 'value': this.transportScoreInfo[key]});
          }

          for(var key in this.humanScoreInfo)
          {
              this.humanExperienceArray.push({'attr': key, 'value': this.humanScoreInfo[key]});                                     
          }
                          
          this.loader = false;
        },
        error => {
          this.loader = false;
          this.alertService.alert('error', 'Something went wrong.', 5);
        }
      );
  };

  isValidObject(x: any): x is Object {
    return x != null && typeof x === 'object';
  }

  ngOnInit(): void {
  }

}

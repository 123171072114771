import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';
import { ValidationService } from 'src/app/services/validation.service';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';

@Component({
  selector: 'app-insight-team',
  templateUrl: './insight-team.component.html',
  styleUrls: ['./insight-team.component.css']
})
export class InsightTeamComponent implements OnInit
{
	loading: boolean = false;
	valid_email: boolean = true;
    new_user_role: boolean = true;
	new_user: string = '';
	error_msg: string = '';

	project: any = this.appData.get('buildingData_insight');
    access_links: any = this.appData.get('buildingData_insight').access_keys;
	team: any = [];

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		this.getTeam();
	}

	getTeam()
	{
		this.loading = true; 
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/teams/').subscribe(
			//success handler
			response =>
			{
				for(var n in response)
				{
					response[n].user_level = response[n].user_level.trim();
					response[n].edit = false;
				}
				this.team = response;
				this.loading = false; 
			},
			//error handler
			response =>
			{
				this.loading = false; 
			}
		);
	}

	getInitials(string: string)
	{
		var names = string.split(' '), initials = names[0].substring(0, 1).toUpperCase();
		if (names.length > 1)
		{
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}

	saveRole(elem: any, user: any, index: number)
	{
		let payload: any;
		if(user.user_level == 'none')
		{
			payload = {
				'username': user.user.username
			};

			this.loading = true;
			user.edit = false;
			this.insight.delete('/assets/LEED:'+ this.project.leed_id + '/teams/', payload).subscribe(response =>
			{
				this.team.splice(index, 1);
				this.loading = false;
			},
			response =>
			{
				this.loading = false; 
				user.edit = true;
			});
		}
		else
		{
			payload = {
				"username": user.user.username,
				"user_level": user.user_level
			};

			this.loading = true;
			user.edit = false;
			this.insight.put('/assets/LEED:'+ this.project.leed_id + '/teams/', payload).subscribe(
				//success handler
				response =>
				{
					this.loading = false;
				},
				//error handler
				response =>
				{
					user.edit = true;
					this.loading = false; 
				}
			);
		}
	}

	cancel()
	{
		$('.team_modal').modal('hide');
	};

	toggleField(key?: string)
	{
		this.new_user_role = !this.new_user_role;
	};

	validateEmail(value: any)
	{
		if(!this.validator.regex.email.test(value))
		{
			this.valid_email = false;
			this.error_msg = "Please enter valid email";
			return;
		}

		for(var n in this.team)
		{
			if(value == this.team[n].user.username)
			{
				this.valid_email = false;
				this.error_msg = "User already added to Team";
				return;
			}
		}

		this.valid_email = true;
	};

	addUser(username: string, user_level: string)
	{
		var payload = {
			'username': username,
			'user_level': user_level
		};

		this.loading = true;
		this.insight.post('/assets/LEED:'+ this.project.leed_id + '/teams/', payload).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.cancel();
				this.getTeam();
			},
			//error handler
			response =>
			{
				this.loading = false; 
			}
		);
	};

	openAddUser()
	{
		$('.team_modal').modal('show');
	}

	editRole(elem: any)
	{
		elem.edit = true;
	};


}

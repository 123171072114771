
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <h1 class="page-title pl0 pb10">Score Version Extension Request<span class="fw-semi-bold"></span></h1>
        <div class="row">
            <div class="">
                <section class="widget">
                    <div class="widget-body">
                        <form class="form-horizontal pt20" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-2 control-label">Search</label>
                                    <div class="col-sm-7">
                                        <input autocomplete="off" id="search_leed_id" [(ngModel)]="query"
                                            (input)="searchProject(query)" type="text" class="form-control"
                                            placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <table class="table table-striped no-footer">
                            <thead>
                                <tr>
                                    <th class="align-center">ID</th>
                                    <th class="align-center">Name</th>
                                    <th class="align-center">Current Version</th>
                                    <!-- <th class="align-center">Request for Version</th> -->
                                    <th class="align-center">Reason</th>
                                    <th class="align-center">Requested till</th>
                                    <th class="align-center">Approve till</th>
                                    <th class="align-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of requested_data">
                                    <td height="70" class="w10p align-center vertical-align-middle">
                                        {{item.asset.leed_id}}</td>
                                    <td height="70" class="w15p align-center vertical-align-middle">{{item.asset.name}}
                                    </td>
                                    <td height="70" class="w10p align-center vertical-align-middle">Arc
                                        {{item.current_version.name}}</td>
                                    <!-- <td height="70" class="w5p align-center vertical-align-middle">Arc {{item.requested_version.name}}</td> -->
                                    <td height="70" class="w25p align-center vertical-align-middle">{{ item.reason.substring(0,20)}}{{item.reason.length > 20 ? '...' : ''}}<a
                                            *ngIf="item.reason.length > 20" (click)="showAllConetnt(item)"> more</a>
                                    </td>
                                    <td height="70" class="w10p align-center vertical-align-middle">
                                        {{global.dateFormat(item.requested_till)}}</td>
                                    <td height="70" class="w10p align-center vertical-align-middle">
                                        <input *ngIf="item.is_granted == null || item.edit_request_flag"
                                            [(ngModel)]="extension_approve_till" name="extension_approve_till"
                                            class="align-center extension_approve_till_{{item.id}} approval_date score_version_extension_gray_border date_input_height"
                                            [ngInit]="enableFdatePicker()" value={{extension_approve_till}} readonly
                                            [disabled]="!item.latest">
                                        <div *ngIf="item.is_granted && !item.edit_request_flag">
                                            {{global.dateFormat(item.grant_till)}}</div>
                                        <div
                                            *ngIf="item.is_granted != null && !item.is_granted && !item.edit_request_flag">
                                            -</div>
                                    </td>
                                    <td height="70" [ngClass]="item.admin_extension_loader ? 'not-active': ''"
                                        class="w20p align-center vertical-align-middle"
                                        *ngIf="item.is_granted == null || item.edit_request_flag">
                                        <button class="btn btn-primary btn-sm"
                                            (click)="approveDenyRequest(item, 'approved')"
                                            [disabled]="!item.latest">Approve</button>
                                        <button class="btn btn-default btn-sm ml5"
                                            (click)="approveDenyRequest(item, 'denied')"
                                            [disabled]="!item.latest">Deny</button>
                                        <div *ngIf="item.admin_extension_loader" class="dataInputLoaderSpinner"></div>
                                    </td>
                                    <td height="70" class="w20p align-center vertical-align-middle"
                                        *ngIf="item.is_granted && !item.edit_request_flag">
                                        <span class="green-color">Approved</span>
                                        <span class="mr20 floatr cursor-pointer" (click)="edit_request(item)">
                                            <i class="icon-pencil" *ngIf="item.latest"></i>
                                        </span>
                                    </td>
                                    <td height="70" class="w20p align-center vertical-align-middle"
                                        *ngIf="item.is_granted != null && !item.is_granted && !item.edit_request_flag">
                                        <span class="danger-color">Denied</span>
                                        <span class="mr20 floatr cursor-pointer" (click)="edit_request(item)">
                                            <i class="icon-pencil" *ngIf="item.latest"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="loading_more_projects" class="ajaxLoader">
                        <div class="loaderSpinner"></div>
                        <div>Loading...</div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="show_all_content" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p50 modal_radius">
            <div>{{complete_content}}</div>
            <div class="mt30 align-center">
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
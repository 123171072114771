import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import * as stream from 'getstream';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent
	
	loading: boolean = false;
	picker_loaded: boolean = false;
	export_start_date_error: boolean = false;
	export_end_date_error: boolean = false;
	overlapping_export: boolean = false;
	leed_id = this.appData.get('leed_id');
	current_tab: string = 'Report Settings';
	doc_category: string = 'report_title';
	report_type: any = true;
	selected_icon: any = null;
	icons: any = {};
	feeds: any = [];
	retry: any = [];
	reports: any = [];
	ranges: any = [];
	export_end_date: any = '';
	export_start_date: any = '';

	alignment_format: string = 'center_align_format';
	user_role: string = this.appData.get('user_role')

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('buildingData').arctool_permission)
        {
            this.user_role = 'arc_pro';
        }
		
		if(!this.appData.get('is_admin'))
		{
			this.global.selectMonth(undefined, this.appData.get('buildingData').performance_end_date);
			this.global.initRanges(this.appData.get('buildingData').performance_end_date);
		}
		else
		{
			this.global.selectMonth(undefined, this.global.changeDateFormat(this.config.range_end, 'YYYY-MM-DD'));
			this.global.initRanges(this.global.changeDateFormat(this.config.range_end, 'YYYY-MM-DD'));
		}

		if(this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1)
		{
			this.selectImage('gsa');
		};

		this.getIcons();
		this.initFeed();
	}

	initReportDates()
	{
		if(!this.picker_loaded)
		{
			this.picker_loaded = true;

			(<any>$('.export-date-picker-modal')).fdatepicker(
			{
				format: 'M dd, yyyy',
			}).on('changeDate', (e: any) =>
			{
				if(e.target.getAttribute('id') == 'export_start_date')
				{
					this.export_start_date = e.target.value;
				}
				else
				{
					this.export_end_date = e.target.value;
				}
			});
		}
	};

	downloadReport(start_date?: any, end_date?: any)
	{
		if(!moment(start_date).isValid() || start_date == undefined)
		{
			this.export_start_date_error = true;
		}
		else
		{
			this.export_start_date_error = false;
		}
		if(!moment(end_date).isValid() || end_date == undefined)
		{
			this.export_end_date_error = true;
		}
		else
		{
			this.export_end_date_error = false;
		}

		if(this.export_start_date_error || this.export_end_date_error)
		{
			return;
		}

		if(moment(start_date).isAfter(end_date))
		{
			this.overlapping_export = true;
		}
		else
		{
			this.overlapping_export = false;
		}

		if(this.overlapping_export)
		{
			return;
		}

		var url = '/assets/LEED:' + this.leed_id + '/export/';
		this.ranges = this.global.getSavedRanges();
		url += "?start_date=" + moment(start_date).format('YYYY-MM-DD');
		url += "&end_date=" + moment(end_date).format('YYYY-MM-DD');
		
		this.loading = true;
		this.api.get(url, 'blob').subscribe(
			data =>
			{
				let a, today;
				this.loading = false;
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(data, "Export Data.xlsx");
					this.loading = false;
				}
				else
				{
					a = document.createElement('a');
					a.href = window.URL.createObjectURL(data);
					today = new Date();
					a.download = "Export Data.xlsx";
					a.style.display = 'none';
					document.body.appendChild(a);
					this.loading = false;
					return a.click();
				}
			},
			error =>
			{
				this.loading = false;
			}
		)
	};

	deleteFeed(feed: any, index?: any)
	{
		var payload = {
			"foreign_id": feed.foreign_id
		}

		this.loading = true;
		this.api.delete('/assets/LEED:'+ this.leed_id + '/notificationitems/', payload).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.retry.splice(index, 1);
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};

	getDate(date: any)
	{
		return moment(date).format("MMM DD, YYYY")
	};

	retryReport(feed: any, index: any)
	{
		var url = '/assets/LEED:' + this.leed_id + '/createreport/';
		url += "?key=" + feed.foreign_id.split("/")[2];
		url += "&report_title=" + feed.verb;
		this.loading = true;
		this.deleteFeed(feed, index);
		this.api.get(url).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				if ("data" in response && "message" in response)
				{
					this.alertService.alert('success', response['message'], 5);
				}
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};

	deleteReport(document: any)
	{
		var putData = 
		{
			doc_id : document.id 
		};

		this.loading = true;
		this.api.put('/assets/LEED:'+ this.leed_id + '/s3documents/', putData).subscribe(
			//success handler
			response =>
			{
				this.getReports();
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		); 
	};

	toggleTab(tab: string)
	{
		if(tab == this.current_tab)
		{
			return;
		}
		this.current_tab = tab;
		this.picker_loaded = false;
		if(tab == 'Past Reports')
		{
			this.getReports();
		}
	};

	createReport()
	{
		var url = '/assets/LEED:' + this.leed_id + '/createreport/';

		if(this.report_type == true)
		{
			url += '?report_type=project_report';
		}
		else
		{
			url += '?report_type=re_entry_report';
		}

		if(this.selected_icon == 'gsa' && this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1)
		{
			url += '&report_title=play_to_zero.jpg';
		}
		else if(this.icons[this.selected_icon] != undefined || this.icons[this.selected_icon] != null)
		{
			url += '&report_title=' + this.icons[this.selected_icon].id;
		}
		this.ranges = this.global.getSavedRanges();
		url += "&START=" + this.ranges[1];
		url += "&END=" + this.ranges[2];
		this.loading = true;
		this.api.get(url).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				if("message" in response)
				{
					this.alertService.alert('success', response['message'], 10);
				}
				this.cancel();
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};

	toggleType(report_type: boolean)
	{
		this.report_type = report_type;
	};

	cancel()
	{
		this.modal.close();
	};

	createReportModal()
	{
		this.modal.open({ windowClass: 'certificate_modal' })
	};

	selectImage(sequence: any, modal?: boolean)
	{
		this.selected_icon = sequence;
		if(!modal)
		{
			$('.upload_report_title').click();
		}
	};

	fileNameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		//var type = $(this).attr('category');
		var data;
		var temp_uploading = [];
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			var mimeType = $(elem)[0].files[a].type;

			if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
			{
				var file: any = new Blob([$(elem)[0].files[a]], { type: mimeType});
				file.lastModifiedDate = new Date();
				data.append('action_file', file, this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			}
			else
			{
				var file: any = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1], {type: mimeType, lastModified: (<any>new Date())});
				data.append('action_file', file);
			}

			// var file = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			// data.append('action_file', file);
			this.upload(data, this.doc_category, $(elem)[0].files[a].type, $(elem)[0].files[a].size);
		}
	};

	getPath(doc: any, id?: any)
	{
		if(id != undefined && isNaN(parseInt(id)))
		{
			if(id == 'play_to_zero.jpg' && this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1)
			{
				id = "play_to_zero.jpg";
			}
			return window.location.origin + "/assets/images/" + id;
		}
		let url: string = '';
		try
		{
			if(doc == undefined)
			{
				doc = {"id": id};
			}
			url = this.config.basic_api_url + "/assets/LEED:" + this.leed_id + "/documents/download/?doc_id=" + doc["id"] + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		}
		catch(e)
		{}
		return url;
	}

	getIcons()
	{
		this.api.get(
			'/assets/LEED:'+ this.leed_id + '/s3documents/?doc_category=' + this.doc_category
		).subscribe(
			data =>
			{
				this.loading = false;

				for(var n = 0; n < data.length; n++)
				{
					var file_name = this.global.getFileName(data[n]);
					file_name = file_name.split('.')[0];
					if(file_name == 'first')
					{
						this.icons['first'] = data[n];
					}
					else if(file_name == 'second')
					{
						this.icons['second'] = data[n];
					}
					else if(file_name == 'third')
					{
						this.icons['third'] = data[n];
					}
					else if(file_name == 'fourth')
					{
						this.icons['fourth'] = data[n];
					}
					else if(file_name == 'fifth')
					{
						this.icons['fifth'] = data[n];
					}
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	};

	getReports()
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.leed_id + '/s3documents/?doc_category=report_pdf'
		).subscribe(
			data =>
			{
				data = data.sort(function(a: any,b: any) 
				{ 
					return new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime() 
				}).reverse();
				this.loading = false;
				for(var n = 0; n < data.length; n++)
				{
					data[n].document_metadata = JSON.parse(data[n].document_metadata.replace(/'/g, '"'));
				}
				this.reports = data;
			},
			error =>
			{
				this.loading = false;
			}
		);
	};

	downloadCert(data: any)
	{
		var url = this.config.basic_api_url + "/assets/LEED:" + this.leed_id + "/documents/download/?doc_id=" + data.id + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key
		var name   = this.global.getFileName(data);
		this.global.anchorDownload(name, url);
	};

	deleteIcon(uploaded_file: any, sequence: any)
	{
		var putData = 
		{
			doc_id : uploaded_file.id 
		};

		this.loading = true;
		this.api.put('/assets/LEED:'+ this.leed_id + '/s3documents/', putData).subscribe(
			//success handler
			response =>
			{
				this.icons[sequence] = undefined;
				this.loading = false;
			},
			//error handler
			response =>
			{
				this.alertService.alert('error', 'Not able to remove logo.', 5);
				this.loading = false;
			}
		);  
	};
	
	upload(form_data: any, category: any, type: any, size: any)
	{        
		(<any>$('.upload_report_title'))[0].value = "";  
		if(type.split('/')[0] != 'image')
		{
			this.alertService.alert('error', 'Only images are allowed', 5);
			return;
		}
		
		if((size / 1024000) > 5.0)
		{
			this.alertService.alert('error', 'File size should not exceed 5MB', 5);
			return;
		}
		
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', category)

		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/uploadS3/?upload_category=' + category, form_data, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				this.getIcons();
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Not able to upload logo.', 5);
			}
		);
	};

	removeFeed()
	{
		var url = '/assets/LEED:'+ this.leed_id +'/notificationitems/';

		this.api.delete(url, {}).subscribe(
			response =>
			{

			}
		);
	};

	initFeed()
	{
		var url = '/assets/LEED:'+ this.leed_id +'/notificationitems/';

		this.api.get(url).subscribe(
			response =>
			{
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				var retry = []
				
				for(var i = 0; i < response.activities.length; i++)
				{
					if(response.activities[i].foreign_id.indexOf("data_report_wait_") != -1)
					{
						var time = moment(response.activities[i].time);
						var date = moment(new Date);
						if(time.diff(date, 'days') > 1)
						{
							this.deleteFeed(response.activities[i]);
						}
						else
						{
							this.feeds.push(response.activities[i]);
						}
					}
					else if(response.activities[i].foreign_id.indexOf("data_report_error_") != -1)
					{
						retry.push(response.activities[i]);
					}
					
					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						this.removeFeed();
					}
				}
				
				this.retry = retry.sort(function(a,b) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});
				this.feeds = this.feeds.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});

				var subscription = notification.subscribe((data: any) => 
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < this.feeds.length; a++)
						{
							if(this.feeds[a].id == data.deleted[d])
							{
								this.getReports();
								this.feeds.splice(a, 1);
							}
						}
					}

					if(data.new.length > 0)
					{
						if(data.new[0].foreign_id.indexOf("data_report_wait_") != -1)
						{
							this.feeds.push(data.new[0]);
						}
					}
					
					this.feeds = this.feeds.sort(function(a: any,b: any) 
					{ 
						return new Date(a.time).getTime() - new Date(b.time).getTime() 
					});
				});
			},
			function(error)
			{

			}
		);
	};

}

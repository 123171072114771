<div class="content-wrap">
	<div id="content" class="content">
		
		<div class="relative-position" [ngClass]="loading ? 'not-active' : ''">
			<app-loader *ngIf="loading"></app-loader>
			<div class="align_lr">
				<h3 class="fw-500 black-color pl15 mb25 pt5" translate>
					Performance
					<span *ngIf="(selected_tab == 'energy' && this.appData.get('buildingData').energy_score_calculation_factor != 'default') || (selected_tab == 'water' && this.appData.get('buildingData').water_score_calculation_factor != 'default')">
						<br>
						<span class="font-family-America fs14 gray_color_68737D">{{sub_header_text}}</span>
					</span>
				</h3>

				<div>
					<div class="mt20 pt5 mr15 flex relative-position">
						<div class="flex mt5 mr25">
							<span class="mr5 gray_color_68737D" translate>How scores and metrics work</span>
							<span *ngIf="this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community'" (click)="global.showHelpText('total_leed_arc_city')" class="cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
							</span>
							<span *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school' || this.appData.get('project_type') == 'transit'" (click)="global.showHelpText('total_leed_arc')" class="cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
							</span>
						</div>
						<div *ngIf="appData.get('project_type') != 'city' && appData.get('project_type') != 'community' && appData.get('project_type') != 'transit'" class="dropdown dropdown_v2 pt2 mb5 align-right">
							<div class="cursor-pointer fw-500 dropdown-toggle" data-toggle="dropdown">
								<span class="mr10">{{config.score_type == 'score' ? 'LEED Performance Score' : 'LEED Points'}}</span>
								<i class="toggle fa fa-angle-down initial-position"></i>
							</div>
							<ul class="dropdown-menu left width75p">
								<li (click)="changeScoreType('score')">
									<a translate>
										LEED Performance Score
									</a>
								</li>
								<li (click)="changeScoreType('weighted')">
									<a translate>
										LEED Points
									</a>
								</li>
							</ul>
						</div>
					</div>
					<app-performance-date-picker *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'"></app-performance-date-picker>
				</div>
			</div>
		
			<div class="home_tab mt5">
				<div (click)="changeTab('total')" [ngClass]="selected_tab == 'total' ? 'active' : ''" class="tab_item" translate>
					Total
				</div>
				<div *ngIf="this.appData.get('project_type') == 'transit'" (click)="changeTab('base')" [ngClass]="selected_tab == 'base' ? 'active' : ''" class="tab_item base" translate>
					Base Points
				</div>
				<div (click)="changeTab('energy')" [ngClass]="selected_tab == 'energy' ? 'active' : ''" class="tab_item energy" translate>
					Energy
				</div>
				<div (click)="changeTab('water')" [ngClass]="selected_tab == 'water' ? 'active' : ''" class="tab_item water" translate>
					Water
				</div>
				<div (click)="changeTab('waste')" [ngClass]="selected_tab == 'waste' ? 'active' : ''" class="tab_item waste" translate>
					Waste
				</div>
				<div (click)="changeTab('transport')" [ngClass]="selected_tab == 'transport' ? 'active' : ''" class="tab_item transport" translate>
					Transportation
				</div>
				<div (click)="changeTab('humanexperience')" [ngClass]="selected_tab == 'humanexperience' ? 'active' : ''" class="tab_item humanexperience human_experience" translate>
					{{this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school' || this.appData.get('project_type') == 'transit' ? 'Human Experience' : 'Quality of Life'}}
				</div>
				<div *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'" (click)="changeTab('emissions')" [ngClass]="selected_tab == 'emissions' ? 'active' : ''" class="tab_item" translate>
					Emissions
				</div>
				<div *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'" (click)="changeTab('re_entry')" [ngClass]="selected_tab == 're_entry' ? 'active' : ''" class="tab_item" translate>
					Re-Entry
				</div>
			</div>
		
			<div class="home_tab_dropdown">
				<div class="dropdown dropdown_v2">
					<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
						<span class="mr20">{{selected_tab}}</span>
						<i class="toggle fa fa-angle-down"></i>
					</div>
					<ul class="dropdown-menu left">
						<li (click)="changeTab('total')" [ngClass]="selected_tab == 'total' ? 'active' : ''">
							<a translate>
								Total
							</a>
						</li>
						<li (click)="changeTab('energy')" [ngClass]="selected_tab == 'energy' ? 'active' : ''">
							<a translate>
								Energy
							</a>
						</li>
						<li (click)="changeTab('water')" [ngClass]="selected_tab == 'water' ? 'active' : ''">
							<a translate>
								Water
							</a>
						</li>
						<li (click)="changeTab('waste')" [ngClass]="selected_tab == 'waste' ? 'active' : ''">
							<a translate>
								Waste
							</a>
						</li>
						<li (click)="changeTab('transport')" [ngClass]="selected_tab == 'transport' ? 'active' : ''">
							<a translate>
								Transportation
							</a>
						</li>
						<li (click)="changeTab('humanexperience')" [ngClass]="selected_tab == 'humanexperience' ? 'active' : ''">
							<a translate>
								{{this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school' ? 'Human Experience' : 'Quality of Life'}}
							</a>
						</li>
						<li (click)="changeTab('emissions')" [ngClass]="selected_tab == 'emissions' ? 'active' : ''" *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'">
							<a translate>
								Emissions
							</a>
						</li>
						<li (click)="changeTab('re_entry')" [ngClass]="selected_tab == 're_entry' ? 'active' : ''" *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'">
							<a translate>
								Re-Entry
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div *ngIf="this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'">
				<div *ngIf="selected_tab == 're_entry'" class="widget_container" >
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=re_entry&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=re_entry&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'percent_reduction'" [param]="'&type=reentry'" [width]="'69%'" [height]="'410px'"></app-widget>
	
					<div *ngIf="appData.get('user_role') == 'arc_pro'" class="widget_mimic floatr mr20" style="width: 28%;height: 410px;">
						<div class="fs18 line_height_24 fw-500 mb20" translate>Improve the Overall Comprehensiveness Score</div>
						<ul class="mb15 fs15 pl15">
							<li class="mb25" translate>Create integrated infection control plans</li>
							<li class="mb25" translate>Reference public health authorities</li>
							<li class="mb25" translate>Evaluate real world experiences</li>
							<li class="mb25" translate>Measure relevant IAQ</li>
						</ul>
						<div class="fs15" translate>
							Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf" >Arc Re-Entry Guide</a>
						</div>
					</div>
			
					<div *ngIf="appData.get('user_role') != 'arc_pro'" id="arc_premium_dark" class="arc_premium_light" style="width: 98.5%;height: auto;margin: initial;    background-color: white;color: black;">
						<div class="flex">
							<div class="banner_content">
								<div class="align-left mr35 pt10">
									<div class="fs20 fw-500 line_height_20 mb8" translate>Upgrade to {{arc_pro_label}}</div>
									<div class="fs16 line_height_22" translate>Use advanced features to improve performance</div>
								</div>
								<div class="flex pt15">
									<button class="btn btn-primary inverse fs16 h40 br2p mr10" (click)="global.filterAccess('app.arc_pro_purchase')" translate>Get Essentials</button>
									<button (click)="global.learnMore('arc_pro')" class="btn btn-default inverse fs16 h40 br2p" translate>Learn more</button>
								</div>
							</div>
							<div class="re_entry_cta"></div>
						</div>
					</div>
			
					<div class="widget_heading fs24 mb15 mt15" translate>Facility Management</div>
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=fm&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'fm_survey'" [param]="'&type=fm&utc=' + (appData.get('selected_range') == 'Last 12 months') + '&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'percent_reduction'" [param]="'&type=fmpolicy'" [width]="'69%'" [height]="'410px'"></app-widget>
	
					<div *ngIf="appData.get('user_role') == 'arc_pro'" class="widget_mimic floatr mr20" style="width: 28%;height: 410px;">
						<div class="fs18 line_height_24 fw-500 mb20" translate>Improve Facility Management</div>
						<div class="fs15 mb10" translate>Explore public health authorities:</div>
						<ul class="mb15 fs15 pl15">
							<li class="mb25">US CDC <a target="_blank" href="https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-business-response.html">Interim Guidance</a></li>
							<li class="mb25">ASHRAE <a target="_blank" href="https://www.ashrae.org/file%20library/technical%20resources/ashrae%20journal/2020journaldocuments/72-74_ieq_schoen.pdf">Guidance for Building Operations</a></li>
							<li class="mb25">REHVA <a target="_blank" href="https://www.rehva.eu/fileadmin/user_upload/REHVA_COVID-19_guidance_document_ver2_20200403_1.pdf">COVID-19 guidance document</a></li>
						</ul>
						<div class="fs15">
							Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
						</div>
					</div>
			
					<div class="widget_heading fs24 mb15 mt15" translate>Occupant Observations</div>
					
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=oe&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=occupantexp&utc=' + (appData.get('selected_range') == 'Last 12 months') + '&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" *ngIf="appData.get('user_role') == 'arc_pro'" [leed_id]="appData.get('leed_id')" [src]="'percent_reduction'" [param]="'&type=occupant'" [width]="'69%'" [height]="'410px'"></app-widget>
	
					<div *ngIf="appData.get('user_role') == 'arc_pro'" class="widget_mimic floatr mr20" style="width: 28%;height: 410px;">
						<div class="fs18 line_height_24 fw-500 mb20" translate>Improve Occupant Experience</div>
						<div class="fs15 mb10" translate>Send more occupant surveys</div>
						<ul class="mb15 fs15 pl15">
							<li class="mb25" translate>Target groups: e.g., tenants, fans, more</li>
							<li class="mb25" translate>Target areas: e.g. “lobby”, “cafe”</li>
						</ul>
						<div class="fs15">
							Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
						</div>
					</div>
					
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'oe_survey'" [param]="'&utc=' + (appData.get('selected_range') == 'Last 12 months')" [width]="'98%'" [height]="'490px'"></app-widget>
	
					<div class="widget_heading fs24 mb15 mt15" translate>Indoor Air Quality</div>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=iaq&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'iaq_data'" [param]="null" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget *ngIf="appData.get('user_role') == 'arc_pro'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'percent_reduction'" [param]="'&type=iaq'" [width]="'69%'" [height]="'410px'"></app-widget>
	
					<div *ngIf="appData.get('user_role') == 'arc_pro'" class="widget_mimic floatr mr20" style="width: 28%;height: 410px;">
						<div class="fs18 line_height_24 fw-500 mb20" translate>Improve Indoor Air Quality</div>
						<ul class="mb15 fs15 pl15">
							<li class="mb25" translate>Improve spatial coverage: measure air quality in more places</li>
							<li class="mb25" translate>Improve temporal coverage: take more air quality measurements over time</li>
							<li class="mb25" translate>Improve performance: operate HVAC systems to maintain superior conditions</li>
						</ul>
						<div class="fs15">
							Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
						</div>
					</div>
			
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=RH'" [width]="'48.5%'" [height]="'425px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=CO2'" [width]="'48.5%'" [height]="'425px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=TVOC'" [width]="'48.5%'" [height]="'425px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=PM1.0'" [width]="'48.5%'" [height]="'425px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=PM2.5'" [width]="'48.5%'" [height]="'425px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'stack_plot'" [param]="'&type=PM10'" [width]="'48.5%'" [height]="'425px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'total'" class="widget_container mt16">
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=total'" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=total&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragescoremini'" [param]="'&type=energy&score_type=' + config.score_type" [width]="'31.5%'" [height]="'470px'"></app-widget>
					
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragescoremini'" [param]="'&type=water&score_type=' + config.score_type" [width]="'32%'" [height]="'470px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescoremini'" [param]="'&type=waste&score_type=' + config.score_type" [width]="'32%'" [height]="'470px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragescoremini'" [param]="'&type=transport&score_type=' + config.score_type" [width]="'31.5%'" [height]="'500px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescoremini'" [param]="'&type=humanexperience&score_type=' + config.score_type" [width]="'32%'" [height]="'500px'"></app-widget>
					
				</div>
			
				<div *ngIf="selected_tab == 'energy'" class="widget_container mt16" >
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=energy&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=energy&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=electricity'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=fuel'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=site_energy'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=source_energy'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=energy'" [width]="'98.5%'" [height]="'450px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'water'" class="widget_container mt16" >
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=water&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=water&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=water'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=water&intensity=occupancy'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=water'" [width]="'98.5%'" [height]="'450px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'waste'" class="widget_container mt16">
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=waste&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=waste&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=generated_waste'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=diverted_waste'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=waste'" [width]="'98.5%'" [height]="'450px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'transport'" class="widget_container mt16" >
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=transport&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=transport&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=transport'" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'projectresponserate'" [param]="'&type=transit'" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=transport_emission'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=transport_distance'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=transport'" [width]="'98.5%'" [height]="'450px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'humanexperience'" class="widget_container mt16" >
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=human_experience&score_type=' + config.score_type" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=humanexperience&score_type=' + config.score_type" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=humanexperience'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'projectresponserate'" [param]="'&type=environment'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=environment_summarize'" [width]="'98.5%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'satisfaction'" [param]="null" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'dissatisfaction'" [param]="null" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=voc'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=co2'" [width]="'48.5%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=humanexperience'" [width]="'98.5%'" [height]="'450px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'emissions'" class="widget_container mt16" >
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaverageemissions'" [param]="null" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'projectemissions'" [param]="null" [width]="'34%'" [height]="'395px'"></app-widget>
	
					<app-widget class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=carbon_emission'" [width]="'63%'" [height]="'395px'"></app-widget>
	
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'arcemissions'" [param]="null" [width]="'34%'" [height]="'395px'"></app-widget>
				</div>
			</div>

			<div *ngIf="this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community'">
				<div *ngIf="selected_tab == 'total'" class="widget_container mt16">
					<!-- <app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=re_entry'" [width]="'63%'" [height]="'395px'"></app-widget> -->
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=total'" class="mr15" [width]="'98.9%'" [height]="'900px'"></app-widget>
				</div>
				<div *ngIf="selected_tab == 'energy'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=energy'" class="mr15" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragescore'" [param]="'&type=energy'" [width]="'63%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=265&type=energy'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
				</div>
				<div *ngIf="selected_tab == 'water'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=water'" class="mr15" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragescore'" [param]="'&type=water'" [width]="'63%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=266&type=water'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
				</div>
				<div *ngIf="selected_tab == 'waste'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=waste'" class="mr15" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragescore'" [param]="'&type=waste'" [width]="'63%'" [height]="'395px'"></app-widget>
					
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=267&type=generated_waste'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=268&type=diverted_waste'" [width]="'48.5%'" [height]="'395px'"></app-widget>
				</div>
				<div *ngIf="selected_tab == 'transport'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=transport'" class="mr15" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragescore'" [param]="'&type=transport'" [width]="'63%'" [height]="'395px'"></app-widget>
		
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=269&type=transport'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
				</div>
				<div *ngIf="selected_tab == 'humanexperience'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arccurrentscore'" [param]="'&type=human_experience'" class="mr15" [width]="'34%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragescore'" [param]="'&type=humanexperience'" [width]="'63%'" [height]="'395px'"></app-widget>
		
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=270&type=aqi'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=271&type=bachelor_degree'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=272&type=gini'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=273&type=high_school'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=274&type=median_household'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=275&type=median_rent'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=276&type=unemployment'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=277&type=sensitive_groups'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
					<app-widget [leed_id]="appData.get('leed_id')"[src]="'arcaveragecategoryusage'" [param]="'&fuel_type=278&type=violent_crime'" class="mr15" [width]="'48.5%'" [height]="'395px'"></app-widget>
				</div>
			</div>

			<div *ngIf="this.appData.get('project_type') == 'transit'">
				<div *ngIf="selected_tab == 'total'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type=overview&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'base'" class="widget_container mt16" >
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type='+ selected_tab +'&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'energy'" class="widget_container mt16" >
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type='+ selected_tab +'&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'water'" class="widget_container mt16" >
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type='+ selected_tab +'&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'waste'" class="widget_container mt16">
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type='+ selected_tab +'&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'transport'" class="widget_container mt16" >
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type=transportation&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>
			
				<div *ngIf="selected_tab == 'humanexperience'" class="widget_container mt16" >
					<app-widget [leed_id]="appData.get('leed_id')" [src]="'plaque'" [param]="'&pdf=true&type='+ selected_tab +'&mode=ui'" [width]="'100%%'" [height]="'750px'"></app-widget>
				</div>

				<div class="widget_container">
					<div class="center_align_format w100p align-center">
						<div class="fs18 w80p center_align_format align-left fw-500 mb20">Category Score</div>
						<app-widget *ngIf="selected_tab == 'total'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=total&score_type=score&mode=ui'" [width]="'80%'" [height]="'195px'"></app-widget>
	
						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'energy'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=energy&score_type=score&mode=ui'" [width]="'80%'" [height]="'220px'"></app-widget>

						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'water'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=water&score_type=score&mode=ui'" [width]="'80%'" [height]="'195px'"></app-widget>
	
						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'waste'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=waste&score_type=score&mode=ui'" [width]="'80%'" [height]="'195px'"></app-widget>
	
						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'transport'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=transport&score_type=score&mode=ui'" [width]="'80%'" [height]="'195px'"></app-widget>
	
						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'humanexperience'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=human_experience&score_type=score&mode=ui'" [width]="'80%'" [height]="'220px'"></app-widget>

						<app-widget *ngIf="selected_tab == 'total' || selected_tab == 'base'" class="mr15" [leed_id]="appData.get('leed_id')" [src]="'arccurrentscore'" [param]="'&type=base&score_type=score&mode=ui'" [width]="'80%'" [height]="'220px'"></app-widget>

						<div class="mt20 pb20">
							<div>
								<span class="fs18 w80p align-left fw-500">Carbon: </span> <span>{{carbon}}</span>MTCO2e/year
							</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
		
		

	</div>
</div>
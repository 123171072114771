import { Component, OnInit, Input } from '@angular/core';

import { Global } from '../../services/global.service';

@Component({
  selector: 'app-certification-tag',
  templateUrl: './certification-tag.component.html',
  styleUrls: ['./certification-tag.component.css']
})

export class CertificationTagComponent implements OnInit
{
	@Input() project: any;

	constructor(
		public global: Global
	)
	{

	}

	ngOnInit(): void
	{}
}

<div class="content-wrap">
    <div id="content" class="content admin_tool_content">		
		<div class="admin_recompute_body">
			<h3 class="recomputeWrapper">Performance Scores<span class="fw-semi-bold"></span></h3>
            <section class="widget overflow-auto widget_content">
                <h5 class="mb10 pt20">Enter the below details to view the scores for the Recompute Date selected of a property.</h5>
                <form class="form-horizontal" role="form">
                    <fieldset>
                        <div class="form-group">
                            <label for="normal-field" class="col-sm-5 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                            <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                <input (input)="checkInputsCert()" (focus)="checkInputsCert()" [(ngModel)]="leed_id" name="leed_id" type="text" id="normal-field" class="form-control" placeholder="Property ID or LEED ID">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-5 control-label" for="prepended-input">Recompute Date</label>
                            <div class="col-sm-6">                                
                                <input (input)="checkInputsCert()" [ngInit]="enableFdatePicker()" type="text" [(ngModel)]="end_date" id="end_date" name="end_date" class="col-sm-7 form-control"  [ngClass]="loaderCert ? 'not-active' : ''">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-5 control-label" for="prepended-input"></label>
                            <div class="col-sm-6">
                                <button (click)="recomputeScore()" [ngClass]="checkInputCert ? '' : 'not-active'" [disabled]="loaderCert" class="btn btn-default pull-left mr10">
                                    <i class="icon-save" [hidden]="true"></i> Recompute</button>
                                <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                            </div>
                        </div>
                        <div *ngIf="scores_received">
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col" class="w70p"></th>
                                    <th scope="col" class="w70p"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Energy score out of 33</td>
                                        <td>{{scores.scores.energy}}</td>
                                    </tr>
                                    <tr>
                                        <td>Energy score out of 100</td>
                                        <td>{{scores.energy}}</td>
                                    </tr>
                                    <tr *ngIf="scores.subscores_carbon">
                                        <td class="pl50">Carbon score out of 100</td>
                                        <td>{{scores.subscores_carbon}}</td>
                                    </tr>
                                    <tr *ngIf="scores.subscores_source">
                                        <td class="pl50">Source score out of 100</td>
                                        <td>{{scores.subscores_source}}</td>
                                    </tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                    <tr>
                                        <td>Water score out of 15</td>
                                        <td>{{scores.scores.water}}</td>
                                    </tr>
                                    <tr>
                                        <td>Water score out of 100</td>
                                        <td>{{scores.water}}</td>
                                    </tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                    <tr>
                                        <td>Waste score out of 8</td>
                                        <td>{{scores.scores.waste}}</td>
                                    </tr>
                                    <tr>
                                        <td>Waste score out of 100</td>
                                        <td>{{scores.waste}}</td>
                                    </tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                    <tr>
                                        <td>Transportation score out of 14</td>
                                        <td>{{scores.scores.transport}}</td>
                                    </tr>
                                    <tr>
                                        <td>Transportation score out of 100</td>
                                        <td>{{scores.transport}}</td>
                                    </tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                    <tr>
                                        <td>Human Experience score out of 20</td>
                                        <td>{{scores.scores.human_experience}}</td>
                                    </tr>
                                    <tr>
                                        <td>Human Experience score out of 100</td>
                                        <td>{{scores.human_experience}}</td>
                                    </tr>
                                    <tr *ngIf="scores.leed_subscores_co2">
                                        <td class="pl50">CO2 score out of 100</td>
                                        <td>{{scores.leed_subscores_co2}}</td>
                                    </tr>
                                    <tr *ngIf="scores.leed_subscores_voc">
                                        <td class="pl50">VOC score out of 100</td>
                                        <td>{{scores.leed_subscores_voc}}</td>
                                    </tr>
                                    <tr *ngIf="scores.leed_subscores_occupant">
                                        <td class="pl50">Occupant satisfaction score out of 100</td>
                                        <td>{{scores.leed_subscores_occupant}}</td>
                                    </tr>
                                    <tr *ngIf="scores.subscores_co2">
                                        <td class="pl50">CO2 score out of 100</td>
                                        <td>{{scores.subscores_co2}}</td>
                                    </tr>
                                    <tr *ngIf="scores.subscores_voc">
                                        <td class="pl50">VOC score out of 100</td>
                                        <td>{{scores.subscores_voc}}</td>
                                    </tr>
                                    <tr *ngIf="scores.occupant_satisfaction">
                                        <td class="pl50">Occupant satisfaction score out of 100</td>
                                        <td>{{scores.occupant_satisfaction}}</td>
                                    </tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                    <tr>
                                        <td>Base score out of 10</td>
                                        <td>{{scores.scores.base}}</td>
                                    </tr>
                                    <tr>
                                        <td>Base score out of 100</td>
                                        <td>{{(scores.scores.base * 10)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </form>
            </section>
        </div>
        <!-- Recompute improvement scores starts -->
        <div>
            <h1 class="page-title pl0 pb10">Improvement Scores<span class="fw-semi-bold"></span></h1>
            <section class="widget">
                <header>
                    <h5 class="mb10">Enter the below details to get the improvement scores data of the property</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset [ngClass]="loader ? 'not-active' : ''">
                            <div class="form-group mb10">
                                <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                <div class="col-sm-4">
                                    <input [(ngModel)]="leed_id_improvement" type="text" id="normal-field" class="form-control"
                                        placeholder="Property ID or LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                                <ul class="wizard display-block">
                                    <li class="next list_style_none">
                                        <button (click)=getImprovementInfo() class="pull-left mr10 btn btn-default"><i
                                                class="fa fa-search" aria-hidden="true"></i> Recompute</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </li>
                                </ul>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
        <div>

            <div class="clearfix">
                <ul id="tabs1" class="nav nav-tabs pull-left w100p">
                    <li class="active"><a data-target="#tab1a" data-toggle="tab" aria-expanded="true">Energy</a>
                    </li>
                    <li class=""><a data-target="#tab2a" data-toggle="tab" aria-expanded="false">Water</a>
                    </li>
                    <li class=""><a data-target="#tab3a" data-toggle="tab" aria-expanded="false">Waste</a>
                    </li>
                    <li class=""><a data-target="#tab4a" data-toggle="tab" aria-expanded="false">Transportation </a>
                    </li>
                    <li class=""><a data-target="#tab5a" data-toggle="tab" aria-expanded="false">Human Experience </a>
                    </li>
                </ul>
            </div>
            <div id="tabs1c" class="tab-content mb-lg">
                <!-- Energy Score -->
                <div class="tab-pane clearfix active" id="tab1a">
                    <div *ngIf="!energyArray.length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        No Energy Data
                    </div>

                    <table *ngIf="energyArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of energyArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Energy Score -->

                <!-- Water Score -->
                <div class="tab-pane" id="tab2a">
                    <div *ngIf="!waterArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Water Data</div>

                    <table *ngIf="waterArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of waterArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Water Score -->

                <!-- Waste Score -->
                <div class="tab-pane" id="tab3a">
                    <div *ngIf="!wasteArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Waste Data</div>

                    <table *ngIf="wasteArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of wasteArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Waste Score -->

                <!-- Transport Score -->
                <div class="tab-pane" id="tab4a">
                    <div *ngIf="!transportArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Transport Data</div>

                    <table *ngIf="transportArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left w40p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of transportArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Transport Score -->

                <!-- Human Experience Score -->
                <div class="tab-pane" id="tab5a">
                    <div *ngIf="!humanExperienceArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Human Experience Data</div>

                    <table *ngIf="humanExperienceArray.length" class="table no-footer">
                        <thead>
                            <tr role="row">
                                <th class="" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of humanExperienceArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Human Experience Score -->
            </div>
        </div>
        <!-- Recompute improvement scores ends -->
        <!-- Recompute Analysis scores starts -->
        <div>
            <h1 class="page-title pl0 pb20">Analysis<span class="fw-semi-bold"></span></h1>
            <section class="widget">
                <header>
                    <h5 class="mb10 pt10">Enter the below details to get the performance score analysis of a property</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset [ngClass]="loader ? 'not-active' : ''">
                            <div class="form-group mb10">
                                <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                <div class="col-sm-4">
                                    <input [(ngModel)]="leed_id_analysis" type="text" id="normal-field" class="form-control" placeholder="Property ID or LEED ID">
                                </div>
                                <ul class="wizard display-block">
                                    <li class="next list_style_none">
                                        <button (click)=getAnalysisInfo() class="pull-left mr10 btn btn-default"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </li>
                                </ul>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div>* THE FOLLOWING INFORMATION IS CONFIDENTIAL AND IS NOT PERMITTED TO BE SHARED WITH NON ARC/USGBC/GBCI STAFF.</div>
            </section>
        </div>
        <div>
            <div class="clearfix">
                <ul id="tabs1" class="nav nav-tabs pull-left w100p">
                    <li class="active"><a data-target="#tab1" data-toggle="tab" aria-expanded="true">GHG</a>
                    </li>
                    <li class=""><a data-target="#tab2" data-toggle="tab" aria-expanded="false">Source Energy</a>
                    </li>
                    <li class=""><a data-target="#tab3" data-toggle="tab" aria-expanded="false">Water</a>
                    </li>
                    <li class=""><a data-target="#tab4" data-toggle="tab" aria-expanded="false">Waste</a>
                    </li>
                    <li class=""><a data-target="#tab5" data-toggle="tab" aria-expanded="false">Transportation </a>
                    </li>
                    <li class=""><a data-target="#tab6" data-toggle="tab" aria-expanded="false">Human Experience </a>
                    </li>
                </ul>
            </div>
            <div id="tabs1c" class="tab-content mb-lg">
                <!-- Energy Analysis -->
                <div class="tab-pane clearfix active" id="tab1">
                    <div *ngIf="!Object.keys(energyAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No GHG Data
                    </div>
                    <table *ngIf="Object.keys(energyAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(energyAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="fw-semi-bold align-left fs15" >
                                    <div *ngIf="!isValidObject(energyAnalysisInfo[key])">{{energyAnalysisInfo[key]}}</div>
                                    <div *ngIf="isValidObject(energyAnalysisInfo[key])"> </div> 
                                </td>                                                   
                            </tr>
                            <tr *ngFor="let key1 of energyAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(energyAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness Score'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer admin-analysis-tooltip" aria-hidden="true" data-tooltip-placement="top" 
                                            data-tooltip="If x > 80: Excellent, x > 60: Very Good, x > 40: Good, 
                                            x > 20: Fair, x < 20: Poor,where x = Energy Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="fw-semi-bold align-left" *ngIf="isValidObject(energyAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>                            
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Source energy -->
                <div class="tab-pane" id="tab2">
                    <div *ngIf="!Object.keys(sourceAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Source Energy Data
                    </div>
                    <table *ngIf="Object.keys(sourceAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(sourceAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="fw-semi-bold align-left fs15" >
                                    <div *ngIf="!isValidObject(sourceAnalysisInfo[key])">{{sourceAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(sourceAnalysisInfo[key])"> </div> 
                                </td>                                                   
                            </tr>
                            <tr *ngFor="let key1 of sourceAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(sourceAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer admin-analysis-tooltip" aria-hidden="true" data-tooltip-placement="top" 
                                            data-tooltip="If x > 80: Excellent, x > 60: Very Good, x > 40: Good, 
                                            x > 20: Fair, x < 20: Poor,where x = Energy Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="fw-semi-bold align-left" *ngIf="isValidObject(sourceAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>                            
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Energy Analysis -->
        
                <!-- Water Analysis -->
                <div class="tab-pane" id="tab3">
                    <div *ngIf="!Object.keys(waterAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Water Data
                    </div>                    
                    <table *ngIf="Object.keys(waterAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(waterAnalysisInfo)">                    
                            <tr>                       
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(waterAnalysisInfo[key])">{{waterAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(waterAnalysisInfo[key])"> </div>                        
                                </td>                            
                            </tr>
                            <tr *ngFor="let key1 of waterAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(waterAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                            x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Water Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold" *ngIf="isValidObject(waterAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Water Analysis -->
        
                <!-- Waste Analysis -->
                <div class="tab-pane" id="tab4">
                    <div *ngIf="!Object.keys(wasteAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Waste Data</div>
                    
                    <table *ngIf="Object.keys(wasteAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>           
                         <tbody *ngFor="let key of Object.keys(wasteAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(wasteAnalysisInfo[key])">{{wasteAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(wasteAnalysisInfo[key])"> </div>
                                </td>  
                            </tr>
                            <tr *ngFor="let key1 of Object.keys(wasteAnalysisInfo[key])">
                                <td class="pl70 fw-semi-bold">
                                    {{key1}}
                                    <sup *ngIf="key1 == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                            x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Waste Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1 == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="wasteAnalysisInfo[key][key1] != null">
                                        {{wasteAnalysisInfo[key][key1]}}
                                        <span *ngIf="key1 == 'Waste Diversion Rate (%)'">
                                            %
                                        </span>
                                    </div>
                                    <div *ngIf="wasteAnalysisInfo[key][key1] == null"> - </div>
                                </td>
        
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Waste Analysis -->
        
                <!-- Transport Analysis -->
                <div class="tab-pane" id="tab5">
                    <div *ngIf="!Object.keys(transportAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Transport Data</div>
                    
                    <table *ngIf="Object.keys(transportAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left w40p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>                
                        <tbody *ngFor="let key of Object.keys(transportAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(transportAnalysisInfo[key])">{{transportAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(transportAnalysisInfo[key])"> </div>
                                </td>  
                            </tr>
                            <tr *ngFor="let key1 of Object.keys(transportAnalysisInfo[key])">
                                <td class="pl70 fw-semi-bold">
                                    {{key1}}
                                    <sup *ngIf="key1 == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Transportation Reference 
                                                    Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1 == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="transportAnalysisInfo[key][key1] != null">{{transportAnalysisInfo[key][key1]}}</div>
                                    <div *ngIf="transportAnalysisInfo[key][key1] == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Transport Analysis -->
                <!-- Human Exp Analysis -->
                <div class="tab-pane" id="tab6">
                    <div *ngIf="!Object.keys(humanAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Human Experience Data
                    </div>                    
                    <table *ngIf="Object.keys(humanAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(humanAnalysisInfo)">                    
                            <tr>                       
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key])">{{humanAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key])"> </div>                        
                                </td>                            
                            </tr>
                            <tr *ngFor="let key1 of humanAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Human Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Human Education Reference 
                                                Set Closeness">
                                        </span>
                                    </sup> 
                                    <sup *ngIf="key1.key == 'Prosperity Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, 
                                                where x = Prosperity Reference Set Closeness">
                                        </span>
                                    </sup> 
                                    <sup *ngIf="key1.key == 'Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Equitability Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Human Exp Analysis -->
        
                <!-- Human Experience Analysis -->
                <!-- <div class="tab-pane" id="tab5">
                    <div *ngIf="!Object.keys(humanAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Human Experience Data</div>
                          
                    <table *ngIf="Object.keys(humanAnalysisInfo).length" class="table no-footer">
                        <thead>
                            <tr role="row">
                                <th class="" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(humanAnalysisInfo)">
                            <tr>
                                <td class="fw-600 fs15">
                                    {{key}}
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key])">{{humanAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key])"> </div>
                                </td>
                                <td></td>
                                <td></td> 
                                <td></td> 
                            </tr>
                            <tr  *ngFor="let key1 of Object.keys(humanAnalysisInfo[key])">
                                <td class="fw-600 align-left cursor-pointer pl70 w33p">
                                    <span>
                                        {{key1}}
                                    </span>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1])">{{humanAnalysisInfo[key][key1]}}</div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1])"> 
                                        <table class="simple_table table mb0">
                                            <tr *ngFor="let key2 of Object.keys(humanAnalysisInfo[key][key1])">
                                                <td class="fw-600 fs13">
                                                    {{key2}}
                                                    <sup *ngIf="key2 == 'Human Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Human Education Reference 
                                                                Set Closeness">
                                                        </span>
                                                    </sup> 
                                                    <sup *ngIf="key2 == 'Prosperity Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                x > 40: Good, x > 20: Fair, x < 20: Poor, 
                                                                where x = Prosperity Reference Set Closeness">
                                                        </span>
                                                    </sup> 
                                                    <sup *ngIf="key2 == 'Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                                        </span>
                                                    </sup>
                                                    <sup *ngIf="key2 == 'Equitability Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                                        </span>
                                                    </sup>
                                                </td>
                                                <td class="align-left fw-semi-bold">
                                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1][key2])">{{humanAnalysisInfo[key][key1][key2]}}</div>
                                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1][key2])"> 
                                                        <table class="table">
                                                            <tr *ngFor="let key3 of Object.keys(humanAnalysisInfo[key][key1][key2])">
                                                                <td class="fw-600 fs13">
                                                                    {{key3}}
                                                                    <sup *ngIf="key3 == 'Airquality Reference Set Closeness'" class="admin-analysis-tooltip">
                                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: 
                                                                                Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Airquality Reference Set Closeness">
                                                                        </span>
                                                                    </sup>
                                                                    <sup *ngIf="key3 == 'Crime Reference Set Closeness'" class="admin-analysis-tooltip">
                                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                                        data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                        x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Crime Reference Set Closeness">
                                                                        </span>
                                                                    </sup>
                                                                </td>
                                                                <td class="align-right fw-semi-bold">
                                                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1][key2][key3])">{{humanAnalysisInfo[key][key1][key2][key3]}}</div>
                                                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1][key2][key3])"> </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>                                        
                                            </tr>
                                        </table>
                                    </div>
                                </td>                        
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>                                       
                        </tbody>
                    </table>
                </div> -->
                <!-- Human Experience Analysis -->
            </div>
        </div>   
        <!-- Recompute Analysis scores ends -->
        <!-- Re-entry scores starts-->
        <div>
            <h1 class="page-title pl0 pb10">Re-entry Score<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto pt30">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                <div class="col-sm-4" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)=checkInputs() (focus)=checkInputs() [(ngModel)]="leed_id_re_entry"
                                        type="text" id="normal-field" class="form-control" placeholder="Property ID or LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label" for="prepended-input">Recompute Date</label>
                                <div class="col-sm-4">
                                    <input (input)="checkInputs()" id="end_date_reentry" [(ngModel)]="end_date_reentry"
                                        class="col-sm-7 form-control" name="end_date_reentry"
                                        [ngClass]="loaderCert ? 'not-active' : ''" [ngInit]="enableFdatePicker()" value={{end_date_reentry}}>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label" for="prepended-input"></label>
                                <div class="col-sm-4">
                                    <button (click)="recomputeReentryScore()" [ngClass]="checkInput ? '' : 'not-active'"
                                        [disabled]="loaderCert" class="btn btn-default pull-left mr10"><i
                                            class="icon-save" aria-hidden="true"></i> Recompute Re-entry</button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                            <div *ngIf="reentry_scores_received">
                                <div class="row p10">
                                    Facility management : <span>{{reentry_scores.facility}}</span>
                                </div>
                                <div class="row p10">
                                    Occupant experience : <span>{{reentry_scores.occupant}}</span>
                                </div>
                                <div class="row p10">
                                    IAQ : <span>{{reentry_scores.iaq}}</span>
                                </div>
                                <div class="row p10 fw-bold">
                                    Re-Entry Comprehensive Score : <span>{{reentry_scores.reentry}}</span>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
        <!-- Re-entry scores ends -->
    </div>
</div>

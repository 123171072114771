
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-6">
            <h1 class="page-title pl0 pb10">Export Data<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto">
                <header>
                    <h5 class="mb10 pt20">Please provide details below to add a user to a project</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input (input)="checkFields()" [(ngModel)]="leed_id" type="text" id="normal-field"
                                        class="form-control" placeholder="LEED ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">E-Mail ID</label>
                                <div class="col-sm-7">
                                    <div class="input-group"><span class="input-group-addon"><i
                                                class="fa fa-user"></i></span>
                                        <input (input)="checkFields()" [(ngModel)]="email_id" id="prepended-input"
                                            class="form-control" size="16" type="text" placeholder="E-Mail ID">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="prepended-input">Range (Optional)</label>
                                <div class="col-sm-4">
                                    <input (input)="checkFields()" class="form-control input-sm dateRange"
                                    [(ngModel)]="start_date" id="start_date">
                                </div>
                                <span class="pull-left"> to </span>
                                <div class="col-sm-4">
                                    <input (input)="checkFields()" class="form-control input-sm dateRange"
                                    [(ngModel)]="end_date" id="end_date">
                                </div>
                            </div>

                        </fieldset>
                        <div class="mt13 pd20">
                            <ul class="wizard display-block" style="padding-left:0px;">
                                <li class="next list_style_none align-left pull-left">
                                    <button [disabled]="emptyInputs" (click)="downloadExport()"
                                        class="btn btn-default"><i class="fa fa-envelope" aria-hidden="true"></i> E-mail
                                        report</button>
                                </li>
                                <div *ngIf="loader" class="dataInputLoaderSpinner pull-left ml10"></div>
                            </ul>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
<div class="content-wrap">
	<div id="content" class="content">
        <div>
            <h1 class="page-title">
                <span class="fw-semi-bold">GSA Panel</span>
            </h1>
            
            <button (click)="editLeague()" class="btn ml30 mb25 btn-primary inverse">
                Create League/Team
            </button>
        </div>
        
        <div>
            <div class="row">
                <div>        
                    <section [ngClass]="loading ? 'not-active' : ''" class="widget manage_agreements">
                        <app-loader *ngIf="loading"></app-loader>
            
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th>League/Team Name</th>
                                    <th>User Email</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
            
                                <tr class="project_row" *ngFor="let league of leagues">
                                    <td class="vertical-align-middle">
                                        <span>{{league.league_name}}</span>
                                    </td>
                                    <td class="vertical-align-middle">
                                        <span>{{league.user_email}}</span>
                                    </td>
                                    <td class="vertical-align-middle">
                                        <a (click)="editLeague(league)" class="cursor-pointer">Edit</a>
                                    </td>
                                    <td class="vertical-align-middle">
                                        <div (click)="deleteLeague(league)" class="delete_report"></div>
                                    </td>
                                </tr>				    
                            </tbody>
                        </table>
                    </section>
            
                </div>
            </div>
        
        </div>
    </div>
</div>

<div class="gsa_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content modal_radius">
            <div class="p2535">    
                <div [ngClass]="loading ? 'not-active' : ''">
                    <div class="left-right-align">
                        <h3 class="mb20 mt0 fw-600">Edit Details</h3> 
                    </div>
                
                    <div id="details-form" class="mt10 arc-list blue fontSize16 pl0 mb10">
                        <div class="group" [ngClass]="formdata_error.name.error ? 'has-error' : ''">      
                            <input (input)="verifyField(league.league_name, 'name')" [(ngModel)]="league.league_name" name="league_name" type="text" required maxlength="241">
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>League/Team Name</label>
                            <span class="error">{{formdata_error.name.help}}</span>
                        </div>
                        <div class="group" [ngClass]="formdata_error.owner_email.error ? 'has-error' : ''">      
                            <input (input)="verifyField(league.user_email, 'owner_email')" [(ngModel)]="league.user_email" name="user_email" type="text" required maxlength="241">
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>User Email</label>
                            <span class="error">{{formdata_error.owner_email.help}}</span>
                        </div>
            
                        <div class="left-right-align">
                            <div [ngClass]="submit_error ? 'not-active' : ''" class="align-left">
                                <button (click)="saveData()" type="button" class="btn btn-primary btn-md">{{save_button}}</button>
                            </div>
                    
                            <div class="align-right">
                                <button data-dismiss="modal" type="button" class="btn btn-default btn-md">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
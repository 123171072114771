import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit
{
	loading: boolean = false;
	myprojects: any = [];

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
	)
	{

	}

	ngOnInit(): void
	{
		this.api.get(
			'/portfolios/ID:' + this.appData.get('portfolio_id') + '/history/?page_size=0'
		).subscribe(
			data =>
			{
				this.myprojects = data;
			},
			error =>
			{

			}
		);
	}

	date_format_timeline(date: any)
	{
		return date.split(" at ")[0]
	};

	getInitials(string: string)
	{
		var names = string.split(' '), initials = names[0].substring(0, 1).toUpperCase();
		if (names.length > 1)
		{
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	};

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';
import { ValidationService } from 'src/app/services/validation.service';
import { alertService } from 'src/app/services/alert-service.service';
import { API } from 'src/app/services/api.service';

import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-model-details',
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.css']
})
export class ModelDetailsComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	@Input() create_mode: boolean = true;
	@Input() project: any = {};
	@Output() success = new EventEmitter<any>();

	loading: boolean = false;
	isFormSubmitted: boolean = false;
	searchingOrganization: boolean = false;
	loading_org_list: boolean = false;
	sign_agreement: boolean = false;
	saving_project: boolean = false;
	submit_error: boolean = false;
	manageEntityCountry: string = '';
	project_detail_nxt_btn: string = "Next";

	energy_model: any = ["Yes the project already has one", "Yes in the future", "No", "Hadn’t thought about it"];
    carbon_footprint: any = ["Yes the project already has one", "Yes in the future", "No", "Hadn’t thought about it"];
	formValidation = this.validator.regex;
	all_organizations: any = [];
	spaceType: any = [];
	ownerType: any = [];
	countries: any = [];
	states: any = [];
	canceler: any;
	blur_comparison: any = {};
	formdata: any = {
		'project_type': 'building',
		'unitType': 'IP',
		'country': 'US',
		'state': 'DC',
		'name': "",
		'gross_area': "",
		'street': "",
		'city': "",
		'zip_code': "",
		"year_constructed": moment(new Date()).format("YYYY"),
		"owner_email": "",
		"manageEntityCountry": "US",
		"organization": "",
		"rating_system": "LEED 2009",
		
		"version": "",
		"cm_arch": "",
		"architect": "",
		"cm_arch_number": "",
		"leed_project_number": "",
		"energy_model_1": "Yes the project already has one",
		"gsi": "Yes the project already has one",
	}
	no_error: any = {
		'error': false,
		'help': undefined
	};
	formdata_error: any = {
		'project_type': this.no_error,
		'unitType': this.no_error,
		'country': this.no_error,
		'state': this.no_error,
		'name': this.no_error,
		'gross_area': this.no_error,
		'street': this.no_error,
		'city': this.no_error,
		'zip_code': this.no_error,
		"year_constructed": this.no_error,
		"owner_email": this.no_error,
		"manageEntityCountry": this.no_error,
		"organization": this.no_error,
		"rating_system": this.no_error,
		
		"version": this.no_error,
		"cm_arch": this.no_error,
		"architect": this.no_error,
		"cm_arch_number": this.no_error,
		"leed_project_number": this.no_error,
		"energy_model_1": this.no_error,
		"gsi": this.no_error,
	}

	places: any = [];
	searching_place: boolean = false;

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService,
		private alertService: alertService,
		private api: API,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		this.insight.getAsset(
			'../../assets/insight_json/spaceTypes.json'
		).subscribe(data => 
		{
			this.spaceType = data;
			if(this.create_mode)
            {
                this.formdata.spaceType = this.spaceType[0];
            }
		});

		this.insight.getAsset(
			'../../assets/insight_json/ownerType.json'
		).subscribe(data => 
		{
			this.ownerType = data;
			if(this.create_mode)
            {
                this.formdata.ownerType = this.ownerType[0];
            }
		});

		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
				this.states = this.global.getStateList('US');
				this.manageEntityCountry = this.global.getCountry(this.project.manageEntityCountry)
			});
		}
		else
		{
			this.countries = this.global.getCountryList();
			this.states = this.global.getStateList('US');
			this.manageEntityCountry = this.global.getCountry(this.project.manageEntityCountry)
		}

		if(this.create_mode == false)
		{
			this.formdata = this.project;
            this.blur_comparison = Object.assign({}, this.project);
		}
	}

	showDropdown()
	{
		$('.dropdown-menu.address').slideDown(200);
	};

	hideDropdown()
	{
		$('.dropdown-menu.address').slideUp(200);
	};

	checkDropdown()
	{
		if(this.places.length > 0)
		{
			this.showDropdown();
		}
		else
		{
			this.hideDropdown();
		}
	};

	getPlace(address: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		this.verifyField(this.formdata.street, 'street');
		this.canceler = this.api.get(
			'/address/predictions/' + "?address=" + address
		).subscribe(data =>
		{
			this.places = data;
			this.searching_place = false;
			this.checkDropdown();
		});
	}

	selectPlace(place: any)
	{
		this.hideDropdown();
		let postal_code, state, country, city, state_long_name;
		for(let i = 0; i < place.address_components.length; i++)
		{
			let comp = place.address_components[i];
			if(comp.types.indexOf("postal_code") != -1)
			{
				postal_code = comp.short_name;
			}
			else if(comp.types.indexOf("administrative_area_level_1") != -1)
			{
				state = comp.short_name;
				state_long_name = comp.long_name;
			}
			else if(comp.types.indexOf("locality") != -1)
			{
				city = comp.long_name;
			}
			else if(comp.types.indexOf("country") != -1)
			{
				country = comp.short_name;
			}
		}
		if(city == undefined)
		{
			city = state_long_name;
		}
		this.formdata.street = place.name
		this.formdata.city = city;
		this.formdata.zip_code = postal_code;
		this.formdata.country = country;
		this.updateCountry(country);
		let found = false;
		for(let i = 0; i < this.states.length; i++)
		{
			if(state_long_name == this.states[i].name)
			{
				this.formdata.state = this.states[i].code.substr(0, 2);
				found = true;
				break;
			}
		}
		if(!found)
		{
			this.formdata.state = this.states[0].code.substr(0, 2);
		}
		this.getGeocode();
		this.verifyField(this.formdata.state, 'state');
		this.verifyField(this.formdata.street, 'street');
		this.verifyField(this.formdata.city, 'city');
		this.verifyField(this.formdata.zip_code, 'zip_code');
	};

	getDataFromModal(event: any)
	{
		this.formdata.organization = event.formdata;
		this.modal.close();
	}

	getMax(unit: string): any
	{
		if(unit == 'IP')
		{
			return '20499999';
		}
		else if(unit == 'SI')
		{
			return '1904514';
		}
	}

	getUnit(unit: string): any
	{
		if(unit == 'IP')
		{
			return 'square feet';
		}
		else if(unit == 'SI')
		{
			return 'square meters';
		}
	}

	saveProjectEdit(data: any, key: string)
	{
		if(key == 'country')
		{
			this.updateCountry(data);
		}
		if(!this.create_mode && !this.submit_error && data != this.blur_comparison[key])
		{
			this.createProject();
		}
	}

	createProject()
	{
		var payload = this.formdata;
		this.loading = true;
		
		if(this.create_mode)
		{
			var url = '/assets/?version=' + this.formdata.version;
			this.insight.post(url, payload).subscribe(
				response =>
				{
					this.project = response;
					this.appData.set('buildingData_insight', response);
					this.appData.set('reductionData', []);
					this.appData.set('selected_version', {'project_id': response.leed_id, 'project': response, 'selected_version': response.versions[0], 'version': response.versions[0].version_id});
					this.success.emit(response);
					
				},
				error =>
				{
					this.project_detail_nxt_btn = "Next";
					this.loading = false;
				}
			);
		}
		else
		{
			var url = '/assets/LEED:'+ this.project.leed_id +'/';
			this.insight.put(url, payload).subscribe(
				response =>
				{
					this.project = response;
					this.formdata = response;
					this.blur_comparison = Object.assign({}, response);
					this.appData.set('buildingData_insight', response);
					this.loading = false;
				},
				error =>
				{
					this.loading = false;
				}
			);
		}
	}

	verifyField(data: any, key: string, optional?: boolean)
	{
		if(this.formValidation[key] == undefined || key == 'state')
		{
			return;
		}
		
		if(data == undefined || data == null || data.length == 0)
		{
			if(optional)
			{
				this.formdata_error[key] = {
					'error': false,
					'help': undefined
				};
				delete this.formdata[key];
				return;
			}
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.formValidation[key].test(data))
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.formValidation[key + 'Msg']
			};
		}

		if(key == 'gross_area')
		{
			var max = this.getMax(this.formdata.unitType);
			if(parseFloat(data) > parseFloat(max))
			{
				this.formdata_error[key] = {
					'error': true,
					'help': 'Exceeded maximum value'
				};
			}
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				break;
			}
		}
	}

	searchDisableOrganization()
    {
		this.searchingOrganization = false;
	}
	
	searchEnableOrganization()
	{
		this.searchingOrganization = true;
	}

	searchOrganization(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		query = query.substr(0, 100);
		this.searchEnableOrganization();

		this.all_organizations = [];
		this.loading_org_list = true;

		this.formdata_error['organization'] = {
			'error': true,
			'help': 'This field is required'
		};

		this.canceler = this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(
			data =>
			{
				this.loading_org_list = false;
				this.all_organizations = data.owner_type;
			},
			error =>
			{
				this.loading_org_list = false;
			}
		);
	}

	updateCountry(selectedCountry: string)
	{
		this.states = this.global.getStateList(selectedCountry);
		if(this.states.length > 0)
		{
			this.formdata['state'] = this.states[0].code;
		}
		else
		{
			this.states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.formdata['state'] = "";
		}
		this.verifyField(selectedCountry, 'country');
	}

	getGeocode()
	{

	}

	openModal()
	{
		this.modal.open();
	}

	selectOrganization(org: string)
	{
		this.formdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.formdata.organization = org;
		this.searchDisableOrganization();
	}

	submitDetails()
	{
		this.isFormSubmitted = true;
		let data: any = {};
		// check to make sure the form is completely valid

		if (this.states[0].name != 'Not Available' && this.isFormSubmitted && this.formdata.state.length == 0 || this.formdata.state == null)
		{
			return;
		}

		this.project_detail_nxt_btn = "Validating info...";
		if (this.formdata.country != "SS")
		{
			if (this.formdata.state == '' || this.formdata.state == null)
			{
				data = {
					"country": this.formdata.country,
					"zip_code": this.formdata.zip_code
				}
			}
			else
			{
				data = {
					"state": this.formdata.state,
					"country": this.formdata.country,
					"zip_code": this.formdata.zip_code
				}
			}

			this.api.post('/assets/validation/', data).subscribe(
				//success handler
				response =>
				{
					$("#zip_code").removeClass("parsley-error")
					$("#zip_code").next('ul.parsley-errors-list').remove();

					this.createProject();
				},
				//error handler
				response =>
				{
					try
					{
						this.formdata_error['zip_code'] = {
							'error': true,
							'help': response[0]
						};
					}
					catch(e)
					{
						this.formdata_error['zip_code'] = {
							'error': true,
							'help': "Enter a valid zip code"
						};
					}
					this.submit_error = true;
					this.project_detail_nxt_btn = "Next";
					this.loading = false;
				}
			);
		}
		else
		{
			this.createProject();
		}
	}

	verifyDetails()
	{
		if(this.loading)
			return;
		for(var a in this.formdata)
		{
			this.verifyField(this.formdata[a], a, false);
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				return;
			}
		}
		//OFAC validation starts
		this.loading = true;
		var org_country = this.formdata.manageEntityCountry;
		var org_name = this.formdata.organization;

		var org_full_country = this.global.getCountry(org_country)
		this.validator.validateOrganization(org_name, org_full_country).subscribe(
			data =>
			{
				if (data.status)
				{
					if (data.is_blocked)
					{
						this.loading = false;
						$('.ofac_invalid_modal').modal('show');
					}
					else
					{
						this.submitDetails();
					}
				}
			},
			error =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
			}
		);
		//OFAC validation ends
	}

	resetOrganizationDetails()
	{
		this.formdata.manageEntityCountry = "US";
		this.formdata.organization = "";
		this.formdata_error['organization'] = {
			'error': true,
			'help': 'This field is required'
		};
		$('.ofac_invalid_modal').modal('hide');
	}
}

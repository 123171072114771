<div class="p2535">
    
    <div class="left-right-align">
        <div class="flex fontSize18 mb25 fw-600 error-color">
            <span class="error_symbol_trial"></span>
            <span class="error-lg-msg"> {{heading_title}}</span>
        </div> 
    </div>

    <div class="mb30 fontSize15 fw-500" *ngIf="error_json != false">
        {{error_json.title}}
    </div>
    <div class="mb30 fontSize15">
        {{message}}
    </div>

    <div *ngIf="autocase_modal == false" class="flex mt20 res_flex">
        <div class="mr20 mb15 align-left">
            <button (click)="adjustPeriod()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">Adjust Reporting Period</button>
        </div>

        <div class="mr20 mb15 align-left">
            <button (click)="goToDI()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">Edit my data</button>
        </div>

        <div *ngIf="data_coverage.performance.score != null" class="mr20 mb15 align-left">
            <button (click)="submit()" data-dismiss="modal" type="button" class="btn btn-default btn-md">Proceed anyway</button>
        </div>
    </div>
    <div *ngIf="autocase_modal" class="flex mt20 res_flex">
        <div class="mr20 mb15 align-left">
            <button (click)="cancel()" data-dismiss="modal" type="button" class="btn btn-default btn-md">Close</button>
        </div>
    </div>
</div>
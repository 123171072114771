<div id="portfolio_component" class="content-wrap" [ngClass]="saving? 'not-active': ''">
    <app-loader *ngIf="saving"></app-loader>
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold" translate>Portfolio</span>
        </h1>
        
        <div class="row bg-white">
            <div class="portfolio_settings">
                <div class="mb15 align_lr" style="border-bottom: 1px solid #D8DCDE;">
                    <div class="w50p">
                        <h4 translate>
                            Portfolio Settings 
                        </h4>
                    </div>
                    <div [ngClass]="appData.get('is_admin') ? 'not-active' : ''" class="w50p align-right">
                        <button [ngClass]="{'not-active': saving || config.portfolio_access != 'can_edit'}" *ngIf="showThis" (click)="editPort()" class="editPort" translate> Edit</button>
                        
                        <button [ngClass]="{'not-active': name_pattern || owner_email_pattern}"  *ngIf="!showThis" (click)="savePort()" class="savePort"> {{save_text}}</button>
                    </div>
                </div>
                <div class="col-sm-12 pl0">
                    <section> 
                        <table style="border: none;" class="width-100-perc port_table manage hidden-xs">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Portfolio Name</div>
                                            <textarea rows=1 (input)="nameValidation(formdata.portfolio_name)" maxlength="40" style="resize: none;" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_name" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.name}} ></textarea>
                                        
                                            <span class=" error_message help-block" *ngIf='name_pattern' translate>
                                                Please enter valid name.
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Portfolio organization Email</div>
                                            <textarea rows=1 (input)="emailValidation(formdata.portfolio_email)" maxlength="254" class="form-control input-sm input-port  w100p" [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_email" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.organization_email}}></textarea>
                                        
                                            <span class=" error_message help-block" *ngIf='owner_email_pattern' translate>
                                                Please enter valid email.
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Portfolio organization</div>
                                            <textarea rows=1 type="text" id="organization" name="organization" (input)='searchOrganization(org_query)' [(ngModel)]="org_query" [ngClass]="{'border-bottom': !showThis}" class="form-control input-sm input-port " autocomplete="off" [required]="true" [readonly]="showThis" [disabled]="showThis"></textarea>
                                            <span class="fontSize12" *ngIf="formdata.organization.length == 0">(<span (click)="openAddOrgModal()" class="cursor-pointer">This organization is not available. <a>Click here</a></span> to add it.)</span>
                                            <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization" style="margin-top: -15px;">
                                                <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name)">
                                                    <div class="cursor-pointer vertical_align_middle">          
                                                        <span>{{org_name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                     <td>
                                        <div>
                                            <div class="port_label font-weight-600" translate>Portfolio Description</div>
                                            <textarea rows=1 maxlength="100" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_description" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.description}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                   <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Portfolio organization Country/Region</div>
                                            <select id="manageEntityCountry" class="form-control input-port " [ngClass]="{'border-bottom': !showThis, 'deactivateSelectOnPortfolio': showThis}" [(ngModel)]="formdata.portfolio_organization_country" [disabled]="showThis">
                                                <option *ngFor="let country of countries" value="{{country.code}}"  selected="{{country.code==formdata.portfolio_organization_country? true : false}}">{{country.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Portfolio organization Contact</div>
                                            <textarea rows=1 maxlength="40" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_organization_contact" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.organization_contact}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="">
                                            <div class="port_label font-weight-600" translate>Location</div>
                                            <textarea rows=1 maxlength="40" class="form-control input-sm input-port" [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_location" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.address}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
            
                        <table style="border: none;" class="width-100-perc port_table visible-xs">
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <div class="font-weight-600"><i class="fa fa-building-o" aria-hidden="true"></i> <span translate>Portfolio Name</span>:</div>
                                            <textarea rows=1 (input)="nameValidation(formdata.portfolio_name)" maxlength="40" style="resize: none;" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_name" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.name}} ></textarea>
                                        
                                            <span class=" error_message help-block" *ngIf='name_pattern'>
                                                Please enter valid name.
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-building-o" aria-hidden="true"></i> <span translate>Portfolio organization</span>:</div>
                                            <textarea rows=1 type="text" id="organization" name="organization" (input)='searchOrganization(org_query)' [(ngModel)]="org_query" [ngClass]="{'border-bottom': !showThis}" class="form-control input-sm input-port " autocomplete="off" [required]="true" [readonly]="showThis" [disabled]="showThis"></textarea>
                                            <span class="fontSize12" *ngIf="formdata.organization.length == 0">(<span (click)="openAddOrgModal()" class="cursor-pointer">This organization is not available. <a>Click here</a></span> to add it.)</span>
                                            <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization" style="margin-top: -15px;">
                                                <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name)">
                                                    <div class="cursor-pointer vertical_align_middle">          
                                                        <span>{{org_name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-users" aria-hidden="true"></i> <span translate>Portfolio organization Country/Region</span>:</div>
                                            <select id="manageEntityCountry" class="form-control input-port " [ngClass]="{'border-bottom': !showThis, 'deactivateSelectOnPortfolio': showThis}" [(ngModel)]="formdata.portfolio_organization_country" [disabled]="showThis">
                                                <option *ngFor="let country of countries" value="{{country.code}}"  selected="{{country.code==formdata.portfolio_organization_country? true : false}}">{{country.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-users" aria-hidden="true"></i> <span translate>Portfolio organization Contact</span>:</div>
                                            <textarea rows=1 maxlength="40" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_organization_contact" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.organization_contact}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-map-marker" aria-hidden="true"></i> <span translate>Location</span>:</div>
                                            <textarea rows=1 maxlength="40" class="form-control input-sm input-port ml10" [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_location" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.address}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-envelope-o" aria-hidden="true"></i> <span translate>Email</span>:</div>
                                            <textarea rows=1 (input)="emailValidation(formdata.portfolio_email)" maxlength="254" class="form-control input-sm input-port  w100p" [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_email" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.organization_email}}></textarea>
                                        
                                            <span class=" error_message help-block" *ngIf='owner_email_pattern'>
                                                Please enter valid email.
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="mb10">
                                            <div class="font-weight-600"><i class="fa fa-building-o" aria-hidden="true"></i> <span translate>Portfolio Description</span>:</div>
                                            <textarea rows=1 maxlength="100" style="height: 87px;" class="form-control input-sm input-port " [ngClass]="{'border-bottom': !showThis}" [(ngModel)]="formdata.portfolio_description" [readonly]="showThis" [disabled]="showThis" value={{portfolio_obj.description}} ></textarea>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        
            <h1 class="page-title">
                <span class="fw-semi-bold" translate>Projects</span>
            </h1>
            <div class="col-sm-12 pl0">
                <section class="widget pt0">
                    <div class="projectList--toolsAndInfoWrapper">
                        <div class="col-lg-6 col-sm-6 col-md-6 projectList--numOfProjectsWrapper">
                            <span class="projectList--numOfProjectsLabel" translate>Showing: </span>
                            <span class="projectList--numOfProjectsCount">{{myportfolioprojects.length}} of {{total_projects}} Projects</span>
                        </div>
        
                        <div class="col-lg-6 col-sm-6 col-md-6 projectList--toolsWrapper">
                            <div *ngIf="portfolio_source != 'gresb'" class="arcFilter--wrapper ml20">
                                <div class="dropdown">
                                    <button class="dropdown-toggle arcFilter-btn" type="button" data-toggle="dropdown">
                                        <span class="arcFilter-btn--title" 
                                            [ngClass]="(filterCount > 0) ? 'arcFilter-btn--title__filterSelected':''" translate>Filter</span>
                                        <span *ngIf="filterCount > 0" class="arcFilter-btn--filterCount" >{{filterCount}}</span> 
                                        <span class="arcFilter-btn__downArrow"></span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-form">
                                        <li class="arcFilter--options__cert">
                                            <div class="checkbox">
                                                <input id="LEED (All)" type="checkbox" value="all" 
                                                [checked]="selected_cert.indexOf('all') > -1" 
                                                (click)="filterProjectsBasedOnCert('all')" />
                                                <label for="LEED (All)">LEED (All)</label>
                                            </div>
                                        </li>
                                        <li class="arcFilter--options__cert" *ngFor="let cert of certifications">
                                            <div class="checkbox">
                                                <input id="{{cert.value}}" type="checkbox" value="{{cert.value}}" 
                                                [checked]="selected_cert.indexOf(cert.value) > -1" 
                                                (click)="filterProjectsBasedOnCert(cert.value)" />
                                                <label for="{{cert.name}}">{{cert.name}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
        
                            <div class="floatr pt5" *ngIf="is_any_greb_agreement_pending" [ngClass]="accept_agreement_flag_gresb ? 'not-active' : ''">
                                <button class="btn btn-default arc-btn arc-btn-sm" data-toggle="modal" data-target="#sign_all_confirmation">{{accept_agreement_text_gresb_all}}</button>
                            </div>
                        </div>
                    </div>
                    <app-loader *ngIf="page_loading"></app-loader>
                    <table class="table table-sorted arcTbl">
                        <thead>
                            <tr [ngClass]="is_filter_applied_no_response ? 'pointer--none' : ''">
                                <th class="arcTbl--header"></th>
                                <th class="arcTbl--header vertical_align_middle">
                                    <!-- <span class="fa fa-caret-down"></span> -->
                                    <span translate>Name</span>
                                </th>
                                <th class="arcTbl--header vertical_align_middle">
                                    <!-- <span class="fa fa-caret-down"></span> -->
                                    <span translate>Address</span>
                                </th>
                                <th class="arcTbl--header vertical_align_middle">
                                    <!-- <span class="fa fa-caret-down visibility_hidden"></span> -->
                                    <span translate>Certification</span>
                                </th>
                                <th></th>
                                <th class="arcTbl--header hidden-sm vertical_align_middle">
                                    <!-- <span class="fa fa-caret-down visibility_hidden"></span> -->
                                    <span translate>LEED ID</span>
                                </th>
                                <th *ngIf="portfolio_source == 'gresb'" class="arcTbl--header vertical_align_middle align-center flex border_bottom_0 pr0">
                                    <div translate>
                                        <!-- <span class="pr5 fa fa-caret-down visibility_hidden"></span> -->
                                        SCORE COMPLETENESS
                                    </div>
                                    <div (click)="openEstimateModal()" class="gray-color cursor-pointer fa fa-question-circle pl5 pt10"></div>
                                </th>
                                <th class="arcTbl--header w10p vertical_align_middle text-right">
                                    <!-- <span class="fa fa-caret-down visibility_hidden"></span> -->
                                    <span translate>Score</span>
                                </th>
                                <th style="background-image: none;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="is_filter_applied_no_response">
                                <td colspan="5" class="noDataForFilter--container">
                                    <p class="noDataForFilter--title">No Project match your selection.</p>
                                    <p class="noDataForFilter--clearFilter">
                                        <span>Try another filter or</span>
                                        <span class="noDataForFilter--clearFilter--btn" (click)="clearFilters('certification')">Clear all Filters.</span>
                                    </p>
                                </td>
                            </tr>
                            <tr *ngFor="let project of myportfolioprojects; let idx = index;" class="arcTbl--row project_row" [ngClass]="accept_agreement_flag ? 'not-active' : ''">
                                <td (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    <img *ngIf="project.is_gresb" src="../../../../assets/images/apps/gresb-only.png" class="w25px">    
                                </td>
                                <td (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    <span class="arcTbl--projectName fw-500">{{project.building.name}}</span>
                                </td>
                                <td (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    {{project.building.street}}
                                </td>
                                
                                <!-- td for Status starts-->
                                <td *ngIf="!project.is_gresb && project.building.building_status != 'activated_addendum_agreement_pending'" (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    <app-certification-tag [project]="project.building"></app-certification-tag>
                                </td>
                                <td *ngIf="!project.is_gresb && project.building.building_status == 'activated_addendum_agreement_pending'">
                                    <div class="flex">
                                        <svg *ngIf="!accept_agreement_flag_gresb" class="mr4 mt4 vertical_align_middle error_symbol error_symbol-sm"></svg>
                                        <div *ngIf="accept_agreement_flag_gresb" class="mt10 dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                        <button (click)="searchMoveToProjectLock(project.building, idx)" class="btn btn-default arc-btn arc-btn-sm" [ngClass]="accept_agreement_flag_gresb ? 'not-active' : ''">
                                            {{accept_agreement_text_gresb}}
                                        </button>
                                    </div>
                                </td>
                                <td *ngIf="project.is_gresb && project.is_gresb_activated" (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    <app-certification-tag [project]="project.building"></app-certification-tag>
                                </td>
                                <td *ngIf="project.is_gresb && !project.is_gresb_activated" class="cursor-default">
                                    <div class="flex">
                                        <svg *ngIf="!accept_agreement_flag_gresb" class="mr4 mt4 vertical_align_middle error_symbol error_symbol-sm"></svg>
                                        <div *ngIf="accept_agreement_flag_gresb" class="mt10 dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                        <button (click)="searchMoveToProjectLock(project.building, idx, project)" class="btn btn-default arc-btn arc-btn-sm" [ngClass]="accept_agreement_flag_gresb ? 'not-active' : ''">
                                            {{accept_agreement_text_gresb}}
                                        </button>
                                    </div>
                                </td>
                                <td>
                                </td>
                                <!-- td for Status ends-->
        
                                <td [ngClass]="'project-row-'+ idx" (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()">
                                    <span class="arcTbl--projectId">{{project.building.leed_id}}</span>
                                </td> 
                                <td *ngIf="portfolio_source == 'gresb'" class="align-center" (click)="global.removeDynamicDiv()">
                                    <div *ngIf="project.building.estimated_data_color != null">
                                        <div (click)="openEstimateModal(project.building)" class="arc-breadcrumbs {{project.building.estimated_data_color}}">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div *ngIf="project.building.estimated_data_color == null">
                                        <span>-</span>
                                    </div>
                                </td>
                                <td (click)="global.removeDynamicDiv()" *ngIf="project.building.project_type == 'parksmart'"><div class="floatr">N/A</div></td>
                                <td (click)="global.removeDynamicDiv()" *ngIf="project.building.project_type != 'parksmart'">
                                    <div (click)="searchMoveToProjectLock(project.building, idx)" class="floatr" *ngIf="!project.is_gresb">
                                        <div [ngClass]='project.building.certification == "" || project.building.certification == "Denied" || project.building.certification == "None" || project.building.certification == null || project.building.certification == "pre-certified" ? "smallScorepuckBlank" : "smallScorepuck"'>
                                            <span>{{global.reportFormatNumber(project.building.total_score, 0)}}</span>
                                        </div>
                                    </div>
                                    <div class="floatr" *ngIf="project.is_gresb">
                                        <div (click)="openEstimateModal(project.building)" class="btn btn-default arc-btn arc-btn-sm" *ngIf="project.building.estimated_data_color != 'None' && project.building.estimated_data_color != 'green'">
                                            <span class="fontSize13 fw-400">Estimated: </span><span class="ml5 fw600">{{global.reportFormatNumber(project.building.total_score, 0)}}</span>
                                        </div>
                                        <div (click)="searchMoveToProjectLock(project.building, idx)" *ngIf="project.building.estimated_data_color == 'None' || project.building.estimated_data_color == 'green'" [ngClass]='project.building.certification == "" || project.building.certification == "Denied" || project.building.certification == "None" || project.building.certification == null || project.building.certification == "pre-certified" ? "smallScorepuckBlank" : "smallScorepuck"'>
                                            <span>{{global.reportFormatNumber(project.building.total_score, 0)}}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="vertical-align-middle" [ngClass]="{'not-active': appData.get('is_admin') || deleting || config.portfolio_access != 'can_edit' || project.building.building_status == 'activated_review_payment_pending'}">
                                    <!-- <i *ngIf="project.building.building_status != 'Pending Registration'" class="cursor-pointer fa fa-times" aria-hidden="true" (click)="removeProject(project, idx)" style="color:#dd5826;"></i> -->
                                    <button *ngIf="project.building.building_status != 'Pending Registration'" (click)="removeProject(project, idx)" class="btn btn-sm btn-primary floatr"> Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="arcTbl--pagination" *ngIf="!is_filter_applied_no_response">
                        <div class="arcTbl--noOfRows">
                            <div class="arcTbl--rowsPerPageTitle" translate>Page size:</div>
                            <div class="dropdown arcTbl--pageSizeDrp">
                                <button class="dropdown-toggle arcTbl--pageSizeDrp-btn" type="button" data-toggle="dropdown">
                                    <span class="arcTbl--pageSize">{{page_size}}</span>
                                    <span class="fa fa-caret-down"></span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li (click)="getPortfolioProjectsData(current_page, 10, sort_order, sort_col)" class="energy_unit"><a>10</a></li>
                                    <li (click)="getPortfolioProjectsData(current_page, 20, sort_order, sort_col)" class="energy_unit"><a>20</a></li>
                                    <li (click)="getPortfolioProjectsData(current_page, 30, sort_order, sort_col)" class="energy_unit"><a>30</a></li>
                                    <li (click)="getPortfolioProjectsData(current_page, 40, sort_order, sort_col)" class="energy_unit"><a>40</a></li>
                                    <li (click)="getPortfolioProjectsData(current_page, 50, sort_order, sort_col)" class="energy_unit"><a>50</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="arcTbl--pageCounter">
                            <span>Page {{current_page}} of {{total_pages}}</span>
                        </div>
                        <div class="arcTbl--paginationNav">
                            
                            <button [ngClass]="previous_page == null ? 'not-active': ''" 
                                (click)="getPortfolioProjectsData(current_page - 1, page_size, sort_order, sort_col)" 
                                    class="arcTbl--nav-btn__prev">
                            </button>            
                            <button [ngClass]="next_page == null ? 'not-active': ''" 
                                (click)="getPortfolioProjectsData(current_page + 1, page_size, sort_order, sort_col)" 
                                    class="arcTbl--nav-btn__next">
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</div>

<div id="update_organization_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog w30p" *ngIf="isUpdateOrgModal">
        <div class="modal-content">
            <div class="modal-header">
                <span class="fw-600 fs16">Update Portfolio organization Details</span>
            </div>
            <form name="ownerForm" novalidate> 
                <div class="modal-body ">
                      <fieldset>
                            <!-- Owner Organization -->
                            <div class="form-group w90p" [ngClass]="{ 'has-error' : (formdata.organization.length == 0)}">
                                <label for="organization">Portfolio organization</label> 
                                
                                
                                <input maxlength="40" type="text" id="organization1" name="organization" (input)='searchOrganization(org_query)' [(ngModel)]="org_query" class="form-control" [required]="true" autocomplete="off">
                                <span class="help-block required-field mbn5" *ngIf="(formdata.organization.length == 0)">This field is required.</span>
                                <span class="fontSize12">(<span (click)="openAddOrgModal()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>

                                <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization" style="margin-top: -15px;">
                                    <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name)">

                                        <div class="cursor-pointer vertical_align_middle">          
                                            <span>{{org_name}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Owner Organization -->

                            <!-- Country -->
                            <div class="form-group w90p">
                                <label for="organization_country">Portfolio organization Country/Region</label>
                                <select name="organization_country" id="organization_country" [(ngModel)]="formdata.portfolio_organization_country" class="form-control" data-parsley-trigger="change">
                                    <option *ngFor="let country of countries" value="{{country.code}}"  selected="{{country.code==formdata.portfolio_organization_country? true : false}}">{{country.name}}</option>
                                </select>

                            </div>
                            <!-- Country -->

                            <!-- Organization Contact -->
                            <div class="form-group w90p">
                                <label>Portfolio organization Contact</label>
                                <input class="form-control" maxlength="40" type="text" [(ngModel)]="formdata.portfolio_organization_contact" name="portfolio_organization_contact" ng-required="true">

                                <span class="help-block required-field">This field is required.</span>
                            </div>
                            <!-- Organization Contact -->

                      </fieldset>
                        
                </div>
                <div class="modal-footer">
                    <!-- <button class="floatl btn btn-primary fs0_85em" type="submit" (click)="validatePortfolioData(ownerForm.$valid, 'update')" ng-disabled="!ownerForm.$valid">{{update_label}}</button> -->
                    <button type="button" class="floatl btn fs0_85em cancel-btn" (click)="cancel()">
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-dialog modal-md" *ngIf="isOrganizationBlocked">
        <div class="modal-content">
            
            <div class="modal-header">
                <span class="fw-600 fs16">Portfolio organization details are blocked</span>
            </div>
            <div class="modal-body pl25 mb20">
                <p class="fw-400">
                    This project is located in a sanctioned country or belongs to a sanctioned entity 
                    according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                    or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                    contact us</a> for more details.
                </p>      
            </div>
            <div class="modal-footer" style='overflow: auto;'>
                <button class="floatl btn btn-primary fs0_85em" (click)="resetPortfolioDetails()">Continue</button>
                <button type="button" class="floatl btn fs0_85em cancel-btn" (click)="resetPortfolioDetails()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal" id="sign_all_confirmation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs16 fw-600 ">Activate all</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body">
                <p class="fw-500 sub-heading mb0">I accept to terms and conditions of service agreement for all the projects within my portfolio</p>
            </div>
            <div class="score_version_modal_footer mb40"> 
                <button class="btn btn-primary mr30" role="button" (click)="signAgreement('all', portfolio_id, this)" data-dismiss="modal">Accept</button>
                <div class="display-inline">
                    <button class="btn btn-white" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<modal #modal>
    <app-organization (success_data)="getDataFromModal($event)"></app-organization>
</modal>

<modal #modal_estimate>
    <app-estimate (close)="cancel()" [project]="project_selected"></app-estimate>
</modal>
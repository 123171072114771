import { Injectable } from '@angular/core';
declare const Messenger: any;

@Injectable({
  providedIn: 'root'
})
export class alertService
{
	constructor(
	)
	{

	}

	Messenger: any['options'] = {
		extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-right',
		theme: 'air'
	}
	
	alert(type:string, message:string, hideAfter?:any, msz_id?:any){
		hideAfter = hideAfter < 10 ? 10 : hideAfter;
		Messenger().post({
			message: message,
			type: type,
			showCloseButton: true,
			hideAfter: hideAfter,
			hideOnNavigate: true,
			id: msz_id
		});
		return false;
	}
}

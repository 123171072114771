import { Component, Injectable, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
@Injectable()

export class ModalComponent implements OnInit
{
	@ViewChild('modal') private modalContent!: TemplateRef<ModalComponent>
	private modalRef!: NgbModalRef
	modal_open: boolean = false;

	constructor(private modalService: NgbModal) { }

	ngOnInit(): void { }

	open(options?: any)
	{
		this.modal_open = true;
		this.modalRef = this.modalService.open(this.modalContent, options)
		this.modalRef.result.then(
			(result) =>
			{
				console.log(`Closed with: ${result}`)
			},
			(reason) =>
			{
				console.log(`Dismissed ${this.getDismissReason(reason)}`)
			},
		)
	}

	getDismissReason(reason: any): string
	{
		this.modal_open = false;
		if (reason === ModalDismissReasons.ESC)
		{
			return 'by pressing ESC';
		}
		else if (reason === ModalDismissReasons.BACKDROP_CLICK)
		{
			return 'by clicking on a backdrop';
		}
		else 
		{
			return `with: ${reason}`;
		}
	}

	close()
	{
		if(this.modalRef == undefined)
			return;
		this.modalRef.close()
	}

	dismiss()
	{
		this.modalRef.dismiss()
	}
}
<div class="content-wrap">
    <div id="content" class="content">

        <main class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="" style="">
            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">{{heading}}</h4>      
                </div>
                <div class="pt20">
                    <svg (click)="goBack()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>
            <div id="payment-details-wrapper">
                <app-loader *ngIf="loading"></app-loader>
                
                <main *ngIf="data_template == 'details'" id="payment-details" class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="">
                    <div class="payment-card flex" [ngClass]="loading ? 'not-active' : ''">
                        <div id="details-form" class="w100p pt0">
                            <h4 class="fw600 fontSize20 mb20">{{title}}</h4>
                            <div *ngIf='mode == "free"' class="w100p manage_note pl0 mb0">
                                The below field is not editable in the past performance analysis. To enable them, please purchase an <a (click)='global.learnMore("certificate")'>Arc performance certificate subscription</a>.
                            </div>  
                            <div>
                                <table class="w50p">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-margin-20">
                                        <tr>
                                            <td colspan="4">
                                                <span class="head fw-500 fs16 lh30">1. Select a date range for this model:</span>
                                                <div class="pl0 w100p fs12 manage_note" *ngIf="report_mode == 'free_report' && !(current_status == 'expired' || current_status == 'inactive')">
                                                    NOTE: The below field is not editable in the past performance analysis. To enable them, please select Business Case Analysis.
                                                </div>
                                                <div class="pl0 w100p fs12 manage_note" *ngIf="report_mode == 'free_report' && (current_status == 'expired' || current_status == 'inactive')">
                                                    NOTE: The below field is not editable in the past performance analysis. To enable them, please purchase an <a (click)="puchaseSubs()">Arc performance certificate subscription</a> 
                                                </div>
                                                <div class="group mt20 {{formdata.baseline_date_readonly || report_mode == 'free_report' ? 'not-active' : ''}}" [ngClass]="formdata_error.baseline_date.error ? 'has-error' : ''"> 
                                                    <input [ngInit]="enableFdatePicker()" id="baseline_date" (input)="verifyField(formdata.baseline_date, 'baseline_date')" [(ngModel)]="formdata.baseline_date" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>End date reporting period</label>
                                                    <span class="error">{{formdata_error.baseline_date.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <span class="head fw-500 fs16 lh30">2. Select a model type:</span>
                                                <div class="pl0 w100p fs12 manage_note" *ngIf="report_mode == 'free_report' && !(current_status == 'expired' || current_status == 'inactive')">
                                                    NOTE: The below field is not editable in the past performance analysis. To enable them, please select Business Case Analysis.
                                                </div>
                                                <div class="pl0 w100p fs12 manage_note" *ngIf="report_mode == 'free_report' && (current_status == 'expired' || current_status == 'inactive')">
                                                    NOTE: The below field is not editable in the past performance analysis. To enable them, please purchase an <a (click)="puchaseSubs()">Arc performance certificate subscription</a>
                                                </div>
                                                <div class="select-group group mt20" [ngClass]="formdata.model_type_readonly || report_mode == 'free_report' ? 'not-active' : ''">   
                                                    <label>Model Type</label>   
                                                    <select (change)="modelTypeChange()" [(ngModel)]="formdata.model_type">
                                                        <option value="null" disabled>{{report_mode == 'free_report' ? 'Past performance analysis' : 'Select model'}}</option>
                                                        <option value="net zero">Net zero energy</option>
                                                        <option value="percentage reduction">Percentage reduction</option>
                                                        <option value="target">Absolute target</option>
                                                    </select>
                                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <p *ngIf="submit_error && formdata.model_type == 'null' && report_mode != 'free_report'" class="fw-600 danger-color mt10">Please select a model</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                            <td colspan="4">
                                                <span class="head fw-500 fs16 lh30">3. Select reductions:</span>
                                                <div class="group mt20" [ngClass]="formdata_error.elec_percent_reduction.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.elec_percent_reduction, 'elec_percent_reduction')" [(ngModel)]="formdata.elec_percent_reduction" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Non-Renewable Electricity percent reduction (%)</label>
                                                    <span class="error">{{formdata_error.elec_percent_reduction.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.ng_percent_reduction.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.ng_percent_reduction, 'ng_percent_reduction')" [(ngModel)]="formdata.ng_percent_reduction" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Natural gas percent reduction (%)</label>
                                                    <span class="error">{{formdata_error.ng_percent_reduction.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.water_percent_reduction.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.water_percent_reduction, 'water_percent_reduction')" [(ngModel)]="formdata.water_percent_reduction" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Potable water percent reduction (%)</label>
                                                    <span class="error">{{formdata_error.water_percent_reduction.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.water_reclaimed_percent_reduction.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.water_reclaimed_percent_reduction, 'water_reclaimed_percent_reduction')" [(ngModel)]="formdata.water_reclaimed_percent_reduction" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Reclaimed water reduction (%)</label>
                                                    <span class="error">{{formdata_error.water_reclaimed_percent_reduction.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.daily_average_renewable_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_average_renewable_design, 'daily_average_renewable_design')" [(ngModel)]="formdata.daily_average_renewable_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Renewable energy reduction (%) - optional</label>
                                                    <span class="error">{{formdata_error.daily_average_renewable_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'target'">
                                            <td colspan="4">
                                                <span class="head fw-500 fs16 lh30">3. Select targets:</span>
                                                <div class="group mt20" [ngClass]="formdata_error.daily_average_energy_elect_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_average_energy_elect_design, 'daily_average_energy_elect_design')" [(ngModel)]="formdata.daily_average_energy_elect_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Target daily average non-renewable electricity (kBTU/day)</label>
                                                    <span class="error">{{formdata_error.daily_average_energy_elect_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'target'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.daily_average_energy_ng_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_average_energy_ng_design, 'daily_average_energy_ng_design')" [(ngModel)]="formdata.daily_average_energy_ng_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Target daily average natural gas (kBTU/day)</label>
                                                    <span class="error">{{formdata_error.daily_average_energy_ng_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'target'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.daily_water_potable_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_water_potable_design, 'daily_water_potable_design')" [(ngModel)]="formdata.daily_water_potable_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Target daily average potable water (gallons/day)</label>
                                                    <span class="error">{{formdata_error.daily_water_potable_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'target'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.daily_water_reclaimed_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_water_reclaimed_design, 'daily_water_reclaimed_design')" [(ngModel)]="formdata.daily_water_reclaimed_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Target daily average reclaimed water (gallons/day)</label>
                                                    <span class="error">{{formdata_error.daily_water_reclaimed_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formdata.model_type == 'target'">
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.daily_average_renewable_design.error ? 'has-error' : ''">      
                                                    <input float-input (input)="verifyField(formdata.daily_average_renewable_design, 'daily_average_renewable_design')" [(ngModel)]="formdata.daily_average_renewable_design" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Target daily average renewable energy (kBTU/day) - optional</label>
                                                    <span class="error">{{formdata_error.daily_average_renewable_design.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="hide900">
                                            <td colspan="2">
                                                <div (click)="verifyDetails()" class="mr20 pl20 pr20 btn btn-primary" [ngClass]="loading ? 'not-active' : ''">
                                                    <div *ngIf="loading">
                                                        <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                    </div>
                                                    {{button_text}}
                                                </div>
                                                <div (click)="goBack()" class="pl20 pr20 btn btn-default fs16 line_height_24">
                                                    <div *ngIf="loading">
                                                        <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                    </div>
                                                    <span>Cancel</span>
                                                </div>
                                                <p *ngIf="submit_error" class="fw-600 danger-color mt10">Please provide valid data</p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>

                <main *ngIf="data_template == 'overview'" id="payment-details" class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="">
                    <div class="payment-card flex" [ngClass]="loading ? 'not-active' : ''">
                        <div id="details-form"  class="w100p pt0">
                            <h4 class="fw600 mt0 mb20 fontSize20">{{title}}</h4>
                            <div class="head fw-500 fs16 lh30">
                                Data submitted to Autocase to create your report
                            </div>
                            <table id="model-overview" class="table table-striped arc-table" style="width: 80% !important;">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Project Location
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{appData.get('buildingData').street}}, {{appData.get('buildingData').state}}, {{appData.get('buildingData').zip_code}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Gross Area
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.formatNumberCommas(baseline_data.building_gross_area)}} sq. feet
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Project Type
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{appData.get('buildingData').spaceType}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline period end date
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{formdata.baseline_date}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline daily average Non-renewable Electricity
                                        </td>
                                        <td class="vertical-align-middle flex">
                                            {{global.formatNumberCommas(baseline_data.daily_average_energy_elect, 2)}} kBTU/day
                                            <div title="<div class='quality_tip'><p>Data Quality</p><p class='capitalize'>{{data_coverage.daily_average_energy_elect.coverage_flag}}</p><p>The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.</p></div>" class="cursor-pointer tippy_init ml8" [ngClass]="'data_coverage_' + data_coverage.daily_average_energy_elect.coverage_flag"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline daily average Natural gas
                                        </td>
                                        <td class="vertical-align-middle flex">
                                            {{global.formatNumberCommas(baseline_data.daily_average_energy_ng, 2)}} kBTU/day
                                            <div title="<div class='quality_tip'><p>Data Quality</p><p class='capitalize'>{{data_coverage.daily_average_energy_ng.coverage_flag}}</p><p>The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.</p></div>" class="cursor-pointer tippy_init ml8" [ngClass]="'data_coverage_' + data_coverage.daily_average_energy_ng.coverage_flag"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline daily average renewable energy
                                        </td>
                                        <td class="vertical-align-middle flex">
                                            {{global.formatNumberCommas(baseline_data.daily_average_renewable, 2)}} kBTU/day
                                            <div title="<div class='quality_tip'><p>Data Quality</p><p class='capitalize'>{{data_coverage.daily_average_renewable.coverage_flag}}</p><p>The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.</p></div>" class="cursor-pointer tippy_init ml8" [ngClass]="'data_coverage_' + data_coverage.daily_average_renewable.coverage_flag"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline daily average potable water
                                        </td>
                                        <td class="vertical-align-middle flex">
                                            {{global.formatNumberCommas(baseline_data.daily_water_potable, 2)}} gallons/day
                                            <div title="<div class='quality_tip'><p>Data Quality</p><p class='capitalize'>{{data_coverage.daily_water_potable.coverage_flag}}</p><p>The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.</p></div>" class="cursor-pointer tippy_init ml8" [ngClass]="'data_coverage_' + data_coverage.daily_water_potable.coverage_flag"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="fw-500 vertical-align-middle">
                                            Baseline daily average reclaimed water
                                        </td>
                                        <td class="vertical-align-middle flex">
                                            {{global.formatNumberCommas(baseline_data.daily_water_reclaimed, 2)}} gallons/day
                                            <div title="<div class='quality_tip'><p>Data Quality</p><p class='capitalize'>{{data_coverage.daily_water_reclaimed.coverage_flag}}</p><p>The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.</p></div>" class="cursor-pointer tippy_init ml8" [ngClass]="'data_coverage_' + data_coverage.daily_water_reclaimed.coverage_flag"></div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target' || formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target end date 
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{future_date}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target' || formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target type
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.capitalFirstLetter(formdata.model_type)}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Non-Renewable Electricity percent reduction
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{formdata.elec_percent_reduction}}%
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Natural gas percent reduction
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{formdata.ng_percent_reduction}}%
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Potable water percent reduction
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{formdata.water_percent_reduction}} %
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Reclaimed water reduction
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{formdata.water_reclaimed_percent_reduction}}%
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'percentage reduction'">
                                        <td class="fw-500 vertical-align-middle">
                                            Renewable energy reduction
                                        </td>
                                        <td class="vertical-align-middle" *ngIf="formdata.daily_average_renewable_design != null">
                                            {{formdata.daily_average_renewable_design}} %
                                        </td>
                                        <td class="vertical-align-middle" *ngIf="formdata.daily_average_renewable_design == null">
                                            NA
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target daily average non-renewable electricity
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.formatNumberCommas(formdata.daily_average_energy_elect_design, 2)}} kBTU/day
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target daily average natural gas
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.formatNumberCommas(formdata.daily_average_energy_ng_design, 2)}} kBTU/day
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target daily average potable water
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.addCommas(formdata.daily_water_potable_design)}} gallons/day
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target daily average reclaimed water
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.addCommas(formdata.daily_water_reclaimed_design)}} gallons/day
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.model_type == 'target'">
                                        <td class="fw-500 vertical-align-middle">
                                            Target daily average renewable energy
                                        </td>
                                        <td class="vertical-align-middle" *ngIf="formdata.daily_average_renewable_design != null">
                                            {{formdata.daily_average_renewable_design}} kBTU/day
                                        </td>
                                        <td class="vertical-align-middle" *ngIf="formdata.daily_average_renewable_design == null">
                                            NA
                                        </td>
                                    </tr>
                                    <tr class="hide900">
                                            <td colspan="2" class="pl0 w100p fs12 manage_note">
                                                By clicking 'Create' I accept Autocase <a (click)="global.openLink('https://autocase.com/wp-content/uploads/2020/10/terms.pdf')">agreement</a>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    <tr class="hide900">
                                        <td colspan="2">
                                            <div (click)="autocaseSubmit()" class="mr20 pl20 pr20 btn btn-primary" [ngClass]="loading ? 'not-active' : ''">
                                                <div *ngIf="loading">
                                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                </div>
                                                <span>{{button_text}}</span>
                                            </div>
                                            <div (click)="changeView()" class="pl20 pr20 btn btn-default fs16 line_height_24">
                                                <div *ngIf="loading">
                                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                </div>
                                                <span>Back</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="autocae_help" class="floatNone">
                            <div class="bottom_info">
                                <div class="fs16 fw-500 mb12">Data Quality</div>
                                <div class="flex mb12" id="legend">
                                    <span class="mr25 flex">
                                        <div class="data_coverage_red"></div>
                                        <span>Poor</span>
                                    </span>
                                    <span class="mr25 flex">
                                        <div class="data_coverage_yellow"></div>
                                        <span>Good</span>
                                    </span>
                                    <span class="mr25 flex">
                                        <div class="data_coverage_green"></div>
                                        <span>Best</span>
                                    </span>
                                </div>
                                <div class="fs14">
                                    The color code indicates the completeness of required data. Poor ('Red') indicates less than or equal 9 months of data. Moderate ('Yellow') indicates between 9 and less than 12 months of data. Good ('Green') indicates a complete 12 months of data.
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </main>

    </div>
</div>

<modal #modal>
    <div class="p2535">
    
        <div class="left-right-align">
            <div class="flex fontSize18 mb25 fw-600 error-color">
                <span class="error_symbol_trial"></span>
                <span class="error-lg-msg"> {{heading_title}}</span>
            </div> 
        </div>
    
        <div class="mb30 fontSize15 fw-500" *ngIf="error_json != false">
            {{error_json.title}}
        </div>
        <div class="mb30 fontSize15">
            {{message}}
        </div>
    
        <div *ngIf="autocase_modal == false" class="flex mt20 res_flex">
            <div class="mr20 mb15 align-left">
                <button (click)="adjustPeriod()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">Adjust Reporting Period</button>
            </div>
    
            <div class="mr20 mb15 align-left">
                <button (click)="goToDI()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">Edit my data</button>
            </div>
    
            <div *ngIf="data_coverage.performance.score != null" class="mr20 mb15 align-left">
                <button data-dismiss="modal" type="button" class="btn btn-default btn-md">Proceed anyway</button>
            </div>
        </div>
        <div *ngIf="autocase_modal" class="flex mt20 res_flex">
            <div class="mr20 mb15 align-left">
                <button (click)="cancel()" data-dismiss="modal" type="button" class="btn btn-default btn-md">Close</button>
            </div>
        </div>
    </div>
</modal>
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-8">
            <div class="admin_certification_body">
                <h3>Export Racetrack<span class="fw-semi-bold"></span></h3>
                <section class="widget widget_content pt20 mt30">
                    <div class="widget-body">
                        <h5 class="mb20">Please use this <a href="../../assets/pdf/Racetrack_export.pdf" download>guide</a> to learn how to use this tool.</h5>
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Energy score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="energy_score"
                                            name="energy_score" type="text" id="energy_score" class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 33</div>
                                </div>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Water score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="water_score" name="water_score"
                                            type="text" id="water_score" class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 15</div>
                                </div>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Waste score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="waste_score" name="waste_score"
                                            type="text" id="waste_score" class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 8</div>
                                </div>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Transportation
                                        score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="transportation_score"
                                            name="transportation_score" type="text" id="transportation_score"
                                            class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 14</div>
                                </div>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Human experience
                                        score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="human_experience_score"
                                            name="human_experience_score" type="text" id="human_experience_score"
                                            class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 20</div>
                                </div>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-3 control-label">Base score</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input (input)="checkInputsCert()" [(ngModel)]="base_score_cert"
                                            name="base_score_cert" type="text" id="base_score_cert"
                                            class="form-control">
                                    </div>
                                    <div class="fw-500 fs15 mt6 col-sm-2 pl0">/ 10</div>
                                </div>
                                <div class="form-group">
                                    <label for="certified_flag" class="col-sm-3 control-label">Certified</label>
                                    <div class="col-sm-5" [ngClass]="loaderCert ? 'not-active' : ''">
                                        <input class="cursor-pointer mt10" [(ngModel)]="certified_flag" type="checkbox"
                                            name="certified_flag" id=certified_flag>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-3 control-label" for="prepended-input"></label>
                                    <div class="col-sm-5">
                                        <button (click)="exportRacetrack()"
                                            [ngClass]="checkInputCert ? '' : 'not-active'"
                                            class="btn btn-default pull-left mr10">
                                            <i class="icon-download-alt" aria-hidden="true"></i> Download
                                        </button>
                                        <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
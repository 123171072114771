<app-sidebar [sidebar_type]="'user-nav'"></app-sidebar>

<div class="content-wrap" [ngClass]="loading ? 'not-active' : ''">
	<div id="content" class="content">
        <div class="flex">
            <section class="width-100-perc overflow-auto" style="border-right: 1px solid #D8DCDE;">
                <app-loader *ngIf="loading"></app-loader>
                <div class="pr32 pl32">
                    <div class="inline-100 mb25">
                        <h3 class="floatl mt20 lh30 fw-500">
                            <span class="fw-semi-bold">Profile</span>
                        </h3>
                        
                        <button (click)="editProfile()" class="floatr mt20 btn btn-primary inverse">
                            <a class="fw-500 fs14">Edit my profile at USGBC.org <i class="fa rotate50 fa-arrow-up"></i></a>
                        </button>
                    </div>
            
                    <div class="account_info">
                        <div class="account_image_wrapper">
                            <div *ngIf="!getVal(userprofile.profileimg)" class="account_image">
                                <svg style="background-color:#BAC1CA" fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29 31" xml:space="preserve">
                                <g id="User_2">
                                    <path d="M14.5,1C6.505,1,0,7.505,0,15.5S6.505,30,14.5,30S29,23.495,29,15.5S22.495,1,14.5,1z M14.5,5c2.206,0,4,1.794,4,4
                                        s-1.794,4-4,4c-2.206,0-4-1.794-4-4S12.294,5,14.5,5z M22,24H7c-0.276,0-0.5-0.224-0.5-0.5c0-4.687,3.589-8.5,8-8.5s8,3.813,8,8.5
                                        C22.5,23.776,22.276,24,22,24z"/>
                                </g>
                                </svg>
                            </div>
                            <img *ngIf="getVal(userprofile.profileimg)" style="height: 150px;width: 150px;" src="{{userprofile.profileimg}}" />
                        </div>
                        <div class="account_data">
                            <h4 class="fw-500 mt0 fs20 mb30">{{appData.get('username')}}</h4>
                            <div class="account_user_info">
                                <div class="data_row">
                                    <div class="data_label">
                                        Email
                                    </div>
                                    <div class="data_value fw-500">
                                        {{appData.get('user_id')}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Date of birth
                                    </div>
                                    <div class="data_value fw-500" [ngClass]="getVal(userprofile.dob) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.dob) ? dateFormat(userprofile.dob) : 'You have not provided D.O.B.'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Gender
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.gender) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.gender) ? userprofile.gender : '-'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Mailing address
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.mailingaddressstreet) ? 'fw-500' : 'no_value'">
                                        <span *ngIf="!getVal(userprofile.mailingaddressstreet)">
                                            You currently have no mailing address
                                        </span>
                                        <span *ngIf="getVal(userprofile.mailingaddressstreet)">
                                            {{userprofile.mailingaddressattentionto}}<br *ngIf="getVal(userprofile.mailingaddressattentionto)">
                                            {{userprofile.mailingaddressstreet}}<br>
                                            {{userprofile.mailingaddressadditional1}}<br *ngIf="getVal(userprofile.mailingaddressadditional1)">
                                            {{userprofile.mailingaddresscity}}, {{userprofile.mailingaddressprovince}}<br>
                                            {{userprofile.mailingaddresspostalcode}}<br>
                                            {{userprofile.mailingaddresscountry}}
                                        </span>
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Job title
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.jobtitle) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.jobtitle) ? userprofile.jobtitle : 'You currently have no job title'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Industry
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.industy_title) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.industy_title) ? userprofile.industy_title : 'You have no industry'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Occupation
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.occupation_title) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.occupation_title) ? userprofile.occupation_title : 'You have no occupation title'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Department
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.department) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.department) ? userprofile.department : 'You currently have no department'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Company
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.company) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.company) ? userprofile.company : 'You currently have no company'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Bio
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.bio) ? 'fw-500' : 'no_value'">
                                        {{getVal(userprofile.bio) ? userprofile.bio : 'You currently have no bio'}}
                                    </div>
                                </div>
                                <div class="data_row">
                                    <div class="data_label">
                                        Location
                                    </div>
                                    <div class="data_value" [ngClass]="getVal(userprofile.city) ? 'fw-500' : 'no_value'">
                                        <div *ngIf="!getVal(userprofile.city)">
                                            You currently have no address
                                        </div>
                                        <div *ngIf="getVal(userprofile.city)">
                                            {{userprofile.address1}}
                                            <br>
                                            <span *ngIf="getVal(userprofile.address2)">{{userprofile.address2}}<br></span>
                                            {{userprofile.city}}, {{userprofile.province}}
                                            <br>
                                            {{userprofile.postal_code}}
                                            <br>
                                            {{userprofile.country}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="my_reports">
                    <div class="fs18 fw-500 mb10">Unit Preferences</div>
                    <div class="w100p manage_note pl0">
                        NOTE: Please select a unit of preference for the categories below. The unit selections will impact graphs within the performance, reports and models. It will not impact the unit of measurement for the meters. 
                    </div>
                    <div class="preference_item">
                        <div class="preference_label">
                            Gross Area
                        </div>
                        <div class="dropdown dropdown_v2 pt2">
                            <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown">
                                <span>{{user_preference.gfa_unit.replace('sqft', 'Square feet(sqft)').replace('sqm', 'Square meters(sqm)')}}</span>
                                <i class="toggle fa fa-angle-down initial-position"></i>
                            </button>
                            <ul class="dropdown-menu right">
                                <li (click)="changePreference('gfa_unit', 'sqft')">
                                    <a>
                                        Square feet(sqft)
                                    </a>
                                </li>
                                <li (click)="changePreference('gfa_unit', 'sqm')">
                                    <a>
                                        Square meters(sqm)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="preference_item">
                        <div class="preference_label">
                            Energy
                        </div>
                        <div class="dropdown dropdown_v2 pt2">
                            <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown">
                                <span>{{user_preference.electricity_unit.replace('kbtu', 'kBTU').replace('kwh', 'kWh')}}</span>
                                <i class="toggle fa fa-angle-down initial-position"></i>
                            </button>
                            <ul class="dropdown-menu right">
                                <li (click)="changePreference('electricity_unit', 'kbtu')">
                                    <a>
                                        kBTU
                                    </a>
                                </li>
                                <li (click)="changePreference('electricity_unit', 'kwh')">
                                    <a>
                                        kWh
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="preference_item">
                        <div class="preference_label">
                            Water
                        </div>
                        <div class="dropdown dropdown_v2 pt2">
                            <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown">
                                <span>{{user_preference.water_unit == 'gal' ? user_preference.water_unit.replace('gal', 'Gallons(gal)') : user_preference.water_unit.replace('l', 'Litres(l)')}}</span>
                                <i class="toggle fa fa-angle-down initial-position"></i>
                            </button>
                            <ul class="dropdown-menu right">
                                <li (click)="changePreference('water_unit', 'gal')">
                                    <a>
                                        Gallons(gal)
                                    </a>
                                </li>
                                <li (click)="changePreference('water_unit', 'l')">
                                    <a>
                                        Litres(l)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="preference_item">
                        <div class="preference_label">
                            Waste
                        </div>
                        <div class="dropdown dropdown_v2 pt2">
                            <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown">
                                <span>{{user_preference.waste_unit.replace('lbs', 'Pounds(lbs)').replace('kg', 'Kilograms(kg)')}}</span>
                                <i class="toggle fa fa-angle-down initial-position"></i>
                            </button>
                            <ul class="dropdown-menu right">
                                <li (click)="changePreference('waste_unit', 'lbs')">
                                    <a>
                                        Pounds(lbs)
                                    </a>
                                </li>
                                <li (click)="changePreference('waste_unit', 'kg')">
                                    <a>
                                        Kilograms(kg)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div class="preference_item">
                        <div class="preference_label">
                            Transportation
                        </div>
                        <div class="dropdown dropdown_v2 pt2">
                            <button class="btn dropdown-toggle btn-default" type="button" data-toggle="dropdown">
                                <span>{{user_preference.transport_unit.replace('miles', 'Miles').replace('km', 'Kilometers(km)')}}</span>
                                <i class="toggle fa fa-angle-down initial-position"></i>
                            </button>
                            <ul class="dropdown-menu right">
                                <li (click)="changePreference('transport_unit', 'miles')">
                                    <a>
                                        Miles
                                    </a>
                                </li>
                                <li (click)="changePreference('transport_unit', 'km')">
                                    <a>
                                        Kilometers(km)
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="my_reports">
                    <div class="fs18 fw-500 mb25">My Reports</div>
        
                    <table class="table table-striped arc-table">
                        <thead>
                            <tr>
                                <th class="fs15 init_text_transform">Recent reports({{total_reports}})</th>
                                <th class="fs15 init_text_transform">Report type</th>
                                <th class="fs15 init_text_transform">Date created</th>
                                <th class="fs15 init_text_transform">View report</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="fs15" *ngFor="let certificate of reports">
                                <td>
                                    <span>{{certificate.name}} - <span class="fs13 gray_color_707479">{{certificate.leed_id}}</span></span>
                                </td>
                                <td>
                                    <span>{{certificate.type}} Performance Certificate</span>
                                </td>
                                <td>
                                    <span>{{global.dateFormat(certificate.date)}}</span>
                                </td>
                                <td>
                                    <a (click)="downloadCert(certificate.id, certificate.source, certificate.leed_id)">Download</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
        
                    <div (click)="global.goToApp('user-reports')" class="fs13 align-right">
                        <span class="help_type">
                            <a>View all Reports</a>
                        </span>
                        <svg style="fill: #165c82;position: relative;top: 5px;" width="18" height="18" viewBox="0 0 24 24">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
                        </svg>
                    </div>
                </div>
            </section>
        
            <div *ngIf="appData.get('user_role') == 'arc_pro'" class="p24 account_upgrade_info">
                <div class="header_image"></div>
                <div class="account_upgrade_data">
                    <div class="fw-500 fs15">
                        Arc Training and Events
                    </div>
                    <div class="gray_color_707479 mt30 fs16">
                        Looking for opportunities to learn more about the ways Arc can help you meet your sustainablility goals? Attend an upcoming Arc event to learn more about what Arc can do for you.
                    </div>
                    <div (click)="global.openLink('//arcskoru.com/events')" class="learn_more_link">
                        Learn more
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
        
            <div *ngIf="appData.get('user_role') == 'arc_basic'" class="p24 account_upgrade_info">
                <div>
                    <div class="header_image upgrade"></div>
                    <div class="account_upgrade_data">
                        <div class="fw-500 fs15">
                            Upgrade your Arc account
                        </div>
                        <div class="gray_color_707479 mt30 fs16">
                            View Energy, Water, Waste, Transportation and Human Experience KPIs.
                        </div>
                        <div class="learn_more_link" (click)="global.learnMore('arc_pro')">
                            Learn more
                            <i class="fa fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
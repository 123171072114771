
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">

        <div class="col-md-12">
            <h1 class="page-title pl0 pb10">Recompute Re-entry Score<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto pt30">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)=checkInputsCert() (focus)=checkInputsCert() [(ngModel)]="leed_id"
                                        type="text" id="normal-field" class="form-control" placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input">Recompute Date</label>
                                <div class="col-sm-7">
                                    <input (input)="checkInputsCert()" id="end_date" [(ngModel)]="end_date"
                                        class="col-sm-7 form-control" name="end_date"
                                        [ngClass]="loaderCert ? 'not-active' : ''" [ngInit]="enableFdatePicker()" value={{end_date}}>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <button (click)="recomputeScore()" [ngClass]="checkInputCert ? '' : 'not-active'"
                                        [disabled]="loaderCert" class="btn btn-default pull-left mr10"><i
                                            class="icon-save" aria-hidden="true"></i> Recompute</button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                            <div *ngIf="scores_received">
                                <div class="row p10">
                                    Facility management : <span>{{scores.facility}}</span>
                                </div>
                                <div class="row p10">
                                    Occupant experience : <span>{{scores.occupant}}</span>
                                </div>
                                <div class="row p10">
                                    IAQ : <span>{{scores.iaq}}</span>
                                </div>
                                <div class="row p10 fw-bold">
                                    Re-Entry Comprehensive Score : <span>{{scores.reentry}}</span>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
<div class="content-wrap">
	<div id="content" class="content">

        <div>
            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">{{mode == 'certificate' ? 'Arc Performance Certificates' : config.arc_pro_label}}</h4>            
                </div>
                <div class="pt20">
                    <svg (click)="global.goBack()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>
        
            <div *ngIf="mode == 'certificate'">
                <div class="top_header">
                    <div class="w50p pt80 pr15" style="padding-left: 128px;">
                        <div class="mb28 flex">
                            <svg class="mr12" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48"><title>Arc_Performance_Certificates_circle_icon</title><circle cx="24" cy="24" r="24" fill="#174173"/><path d="M24,28.26A4.26,4.26,0,1,0,19.74,24,4.27,4.27,0,0,0,24,28.26Z" fill="#fff"/><path d="M13,21l-3,3A14,14,0,1,0,24,10H13v6.09H24A7.91,7.91,0,1,1,16.09,24" fill="#fff"/></svg>
                              <span class="fs22 line_height_39 fw-500 bold">Arc Performance Certificates</span>
                            </div>
                        <div class="fs36 line_height_40 fw-500 bold mb15">
                            Recognize leadership for <br> spaces and buildings
                        </div>
                        <div class="fs20 mb10">
                            Arc Performance Scores and key performance indicators for any project
                        </div>
                        <div class="fs18 line_height_32 mb16">
                            <a target="_blank" href="{{config.config.settings.landingPageURL + 'getting-started-performance-certificates'}}">Learn more</a>
                            <span>|</span>
                            <a target="_blank" href="{{config.config.settings.landingPageURL + 'pricing'}}">Pricing</a>
                        </div>
                        <button (click)="global.learnMoreCertAction()" class="btn btn-primary fs16 fw-500 h40" style="width: fit-content;">
                            {{global.learnMoreCertText()}}
                        </button>
                    </div>
                    <div class="w50p">
                        <div class="cert_banner_img_lm"></div>
                    </div>
                </div>
        
                <div class="pt192">
                    <div class="fs36 line_height_40 fw-500 bold mb70 align-center">
                        Communicate improvement and share performance
                    </div>
                    <div class="learn_more_fetures">
                        <div class="feature">
                            <div class="feature_logo communicate"></div>
                            <div class="feature_heading">
                                Communicate
                            </div>
                            <div class="feature_desc">
                                Communicate improvement score and share performance information with third parties.
                            </div>
                        </div>
        
                        <div class="feature">
                            <div class="feature_logo arc_any"></div>
                            <div class="feature_heading">
                                Any Arc category
                            </div>
                            <div class="feature_desc">
                                Get recognized for operational performance in any of the five Arc categories.
                            </div>
                        </div>
        
                        <div class="feature">
                            <div class="feature_logo perf"></div>
                            <div class="feature_heading">
                                Performance periods
                            </div>
                            <div class="feature_desc">
                                Certificates are valid for up to 1 year from the date they are issued.
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="cta_clone">
                    <div class="cta">
                        <div class="mb15">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><title>Arc_Performance_Certificates_circle_icon</title><circle cx="24" cy="24" r="24" fill="#174173"/><path d="M24,28.26A4.26,4.26,0,1,0,19.74,24,4.27,4.27,0,0,0,24,28.26Z" fill="#fff"/><path d="M13,21l-3,3A14,14,0,1,0,24,10H13v6.09H24A7.91,7.91,0,1,1,16.09,24" fill="#fff"/></svg>
                        </div>
                        <div class="fs24 fw-500 bold line_height_32 mb16">
                            Arc Performance Certificates
                        </div>
                        <div class="fs18 fw-500 line_height_26 gray_color_707479 mb10">
                            Share performance information in any Arc category with third parties.
                        </div>
                        
                        <div class="mb16">
                            <span class="line_height_32 fw-500 fs22 bold">{{config.currency}}{{global.formatNumberCommas(config.price.certificate_annually, 0)}}</span>
                            <span class="fs16 fw-500 line_height_32">per project when billed annually</span> <a target="_blank" href="{{config.config.settings.landingPageURL + 'pricing'}}">Learn more</a>
                        </div>
                        <!-- <div class="fs16 gray_color_4A4A4A line_height_22 mb16">
                            {{appData.currency}}{{certificate_monthly}} /month when paying month to month. <span><a target="_blank" href="{{config.config.settings.landingPageURL + 'pricing'}}">Learn more</a></span>
                        </div> -->
        
                        <button (click)="global.learnMoreCertAction()" class="btn btn-primary fs20 fw-500 mb16">
                            {{global.learnMoreCertText()}}
                        </button>
                        <ul class="fs16 line_height_22 gray_color_707479">
                            <li>Validated, Arc Performance Score and Arc Improvement Score</li>
                            <li>Incremental recognition for one or more categories</li>
                            <li>Display-quality certificate with scores and Key Performance Indicators</li>
                            <li>Create an unlimited number of certificates</li>
                            <li>Share or display certificates as PDF's</li>
                            <li>Access certificates anytime</li>
                            <li>Earn GRESB points for Energy Ratings and Operational Building Certification</li>
                        </ul>
                    </div>
                    <!-- <div class="gray_color_707479 fs16 line_height_24 align-center">
                        * Cancelation will incur an immediate charge for the remaining amount on the date of cancellation.
                    </div> -->
                </div>
        
                <div class="info1">
                    <div class="mb160 flex">
                        <div class="w50p">
                            <div class="info1_img"></div>
                        </div>
                        <div class="w50p vertical_center pl120">
                            <div class="vc_anchor">
                                <div class="fs32 fw-500 bold line_height_40 mb16">Get recognized for operational performance</div>
                                <div class="fs20 line_height_28">
                                    Arc Performance Certificates are suitable for a variety of purposes, including display to tenants, communication with management and submission to GRESB.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w50p vertical_center">
                            <div class="vc_anchor">
                                <div class="fs32 fw-500 bold line_height_40 mb16">Certificates in any of the five Arc categories</div>
                                <div class="fs20 line_height_28">
                                    Performance-based, third-party recognition for leadership or improvement in energy, water, waste, transportation, or human experience.
                                </div>
                            </div>
                        </div>
                        <div class="w50p pl120">
                            <div class="info2_img floatr"></div>
                        </div>
                    </div>
                </div>
        
                <div class="lm_footer">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 48 48"><title>Arc_Performance_Certificates_circle_icon</title><circle cx="24" cy="24" r="24" fill="#174173"/><path d="M24,28.26A4.26,4.26,0,1,0,19.74,24,4.27,4.27,0,0,0,24,28.26Z" fill="#fff"/><path d="M13,21l-3,3A14,14,0,1,0,24,10H13v6.09H24A7.91,7.91,0,1,1,16.09,24" fill="#fff"/></svg>
                    </div>
                    <div class="fs32 line_height_40 fw-500 bold mt24 mb16">
                        Subscribe to Arc Performance Certificates
                    </div>
                    <div class="fs20 line_height_22 mb24">
                        Get recognized for operational performance in any of the five Arc categories.
                    </div>
                    <button (click)="global.filterAccess('app.arc_pro_purchase', {'mode': 'add_certificate', 'leed_id': appData.get('leed_id')})" class="btn btn-primary fs16 fw-500 h40 w125px">
                        Get Started
                    </button>
                </div>
            </div>
        
            <div *ngIf="mode == 'arc_pro'">
                <div class="top_header">
                    <div class="w50p pt80 pr15" style="padding-left: 128px;">
                        <div class="mb28 flex">
                            <svg class="mr12" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 35 35">
                                <g fill="none" fill-rule="evenodd">
                                  <circle cx="17.5" cy="17.5" r="17.5" fill="#28323B"/>
                                  <g fill="#FFF" transform="translate(8 8)">
                                    <path d="M9.8584,7.58432 C10.9536,7.58512 11.84,8.60112 11.84,9.71232 C11.84,10.83232 10.952,11.74432 9.856,11.74432 C9.456,11.74432 8.816,11.49632 8.496,11.27152 C8.368,11.27152 7.328,11.40032 7.328,11.40032 C7.328,11.40032 7.392,12.08032 7.456,12.51232 C8.112,13.12832 8.96,13.47152 9.856,13.47152 C11.848,13.47152 13.472,11.79232 13.472,9.71232 C13.472,7.63312 11.8496,5.96112 9.8584,5.96032 L-4.08562073e-14,5.95712 L-4.08562073e-14,7.58992 L9.8584,7.58432 Z"/>
                                    <path d="M15.10208,9.86088 C15.10208,12.75768 12.78448,15.10248 9.92928,15.10248 C8.63728,15.10248 7.42208,14.62168 6.47888,13.76648 C5.55968,12.93208 4.96448,11.80008 4.80208,10.55848 C4.62688,10.41288 3.89168,9.86088 3.89168,9.86088 C3.89168,9.86088 3.36448,10.47448 3.17008,10.67128 C3.36448,12.33608 4.15168,13.85928 5.38208,14.97528 C6.62208,16.10088 8.22768,16.73448 9.92928,16.73448 C13.69008,16.73448 16.73488,13.65528 16.73488,9.86088 C16.73488,6.06728 13.69008,2.98728 9.92928,2.98728 L0.00048,2.98728 L0.00048,4.62008 L9.92928,4.62008 C12.78448,4.62008 15.10208,6.96408 15.10208,9.86088"/>
                                    <path d="M10.00016,0 L0.00016,0 L0.00016,1.6328 L10.00016,1.6328 C14.62096,1.6328 18.36736,5.3792 18.36736,10 C18.36736,14.6208 14.62096,18.3672 10.00016,18.3672 C7.90896,18.3672 5.93936,17.5984 4.41456,16.2304 C3.86336,15.736 3.38016,15.1704 2.97696,14.5504 C2.60976,13.984 2.09056,12.7384 2.09056,12.7384 L1.00336,12.2488 L0.56976,13.3072 C0.56976,13.3072 1.14256,14.7224 1.60736,15.44 C2.08896,16.1808 2.66656,16.8552 3.32416,17.4456 C5.14576,19.08 7.50256,20 10.00016,20 C15.52256,20 20.00016,15.5232 20.00016,10 C20.00016,4.4776 15.52256,0 10.00016,0"/>
                                  </g>
                                </g>
                              </svg>
                              <span class="fs22 line_height_39 fw-500 bold">{{config.arc_pro_label}}</span>
                            </div>
                        <div class="fs36 line_height_40 fw-500 bold mb15">
                            Measure and communicate <br> performance
                        </div>
                        <div class="fs20 mb10">
                            Professional tools to plan, analyze, and communicate project and portfolio performance
                        </div>
                        <div class="fs18 line_height_32 mb16">
                            <a target="_blank" href="{{config.config.settings.landingPageURL + 'introducing-arc-essentials'}}">Learn more</a>
                            <span>|</span>
                            <a target="_blank" href="{{config.config.settings.landingPageURL + 'pricing'}}">Pricing</a>
                        </div>
                        <button (click)="global.filterAccess('app.arc_pro_purchase')" class="btn btn-primary fs16 fw-500 h40 w125px">
                            Get Started
                        </button>
                    </div>
                    <div class="w50p">
                        <div class="pro_banner_img_lm"></div>
                    </div>
                </div>
        
                <div class="pt192">
                    <div class="fs36 line_height_40 fw-500 bold mb70 align-center">
                        Share performance of spaces, buildings, and places
                    </div>
                    <div class="learn_more_fetures">
                        <div class="feature">
                            <div class="feature_logo Insights_Icon"></div>
                            <div class="feature_heading">
                                Insights
                            </div>
                            <div class="feature_desc">
                                Plan for leadership by analyzing markets and creating models.
                            </div>
                        </div>
        
                        <div class="feature">
                            <div class="feature_logo perf"></div>
                            <div class="feature_heading">
                                Performance
                            </div>
                            <div class="feature_desc">
                                Analyze and score the real world performance of spaces, buildings, and places.
                            </div>
                        </div>
        
                        <div class="feature">
                            <div class="feature_logo Communication_Icon"></div>
                            <div class="feature_heading">
                                Communication
                            </div>
                            <div class="feature_desc">
                                Share scores and key performance indicators with project reports and email updates.
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="cta_clone">
                    <div class="center-div">
                        <div class="cta margin_init w-min">
                            <div class="mb15">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                    <g fill="none" fill-rule="evenodd">
                                      <circle cx="24" cy="24" r="24" fill="#1CA7DC"/>
                                      <g fill="#FFF" transform="rotate(7 -31.716 129.999)">
                                        <path d="M10.1086138,2.07876516 C15.2623769,6.17012906 18.5674419,12.4897009 18.5674419,19.5813042 C18.5674419,25.1258479 16.5469904,30.1986176 13.2021903,34.1034691 L15.2344032,35.9349101 C19.0160579,31.5448452 21.3023256,25.8300108 21.3023256,19.5813042 C21.3023256,11.6915891 17.6577053,4.65317546 11.9603022,0.0577150689 L10.1086138,2.07876516 Z"/>
                                        <path d="M7.60351669,4.7645576 C12.0628524,8.1793517 14.9384405,13.556318 14.9384405,19.60456 C14.9384405,24.2091221 13.2717292,28.4247169 10.5083521,31.6816347 L12.543043,33.5112228 C15.7424804,29.7692388 17.6744186,24.9123443 17.6744186,19.60456 C17.6744186,12.7634553 14.4651026,6.67148602 9.46907448,2.75023716"/>
                                        <path d="M5.10530094,7.44545308 C8.8403341,10.180781 11.26571,14.5976622 11.26571,19.5807912 C11.26571,23.2450326 9.95422437,26.6031388 7.77490533,29.2116208 L9.80224208,31.0485172 C12.4210353,27.9538536 14,23.9516963 14,19.5807912 C14,13.8035192 11.2415415,8.6702909 6.96946832,5.42543983 L5.10530094,7.44545308 Z"/>
                                        <path d="M2.59991567,10.1183118 C5.63818159,12.1664779 7.6365603,15.6404254 7.6365603,19.5813041 C7.6365603,22.3146278 6.67525533,24.8233377 5.07243426,26.7875179 L7.11394183,28.6124732 C9.14858998,26.162957 10.372093,23.0150594 10.372093,19.5813041 C10.372093,14.8483544 8.04753537,10.6585112 4.47830445,8.09207278 L2.59991567,10.1183118 Z"/>
                                        <path d="M0.0695370553,12.8191517 C2.42054805,14.154581 4.00715564,16.6854106 4.00715564,19.5874677 C4.00715564,21.3830834 3.39716279,23.0345597 2.37707156,24.350747 L4.40271237,26.1825632 C6.03570305,24.2575679 6.74418605,21.9558489 6.74418605,19.5874677 C6.74418605,15.8969491 4.84806646,12.6503678 1.97912363,10.7733175 L0.0695370553,12.8191517 Z"/>
                                      </g>
                                    </g>
                                  </svg>                      
                            </div>
                            <div class="fs24 fw-500 bold line_height_32 mb16">
                                Arc for All
                            </div>
                            <div class="fs18 fw-500 line_height_26 gray_color_707479 mb10">
                                Track your project's performance and apply for certification
                            </div>
                            <div class="mb16">
                                <span class="fs16 line_height_32 fw-500 fs22 bold">Free</span>
                            </div>
                            <div style="width: 308px;"></div>
                            <ul class="fs16 line_height_22 gray_color_707479">
                                <li>Create a project</li>
                                <li>Add data</li>
                                <li>Connect to partners</li>
                                <li>Score performance</li>
                                <li>Communicate by email</li>
                                <li>Analyze LEED Readiness</li>
                            </ul>
                        </div>
                        <div class="cta margin_init w-min">
                            <div class="mb15">
                                <svg class="mr12" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 35 35">
                                    <g fill="none" fill-rule="evenodd">
                                      <circle cx="17.5" cy="17.5" r="17.5" fill="#28323B"/>
                                      <g fill="#FFF" transform="translate(8 8)">
                                        <path d="M9.8584,7.58432 C10.9536,7.58512 11.84,8.60112 11.84,9.71232 C11.84,10.83232 10.952,11.74432 9.856,11.74432 C9.456,11.74432 8.816,11.49632 8.496,11.27152 C8.368,11.27152 7.328,11.40032 7.328,11.40032 C7.328,11.40032 7.392,12.08032 7.456,12.51232 C8.112,13.12832 8.96,13.47152 9.856,13.47152 C11.848,13.47152 13.472,11.79232 13.472,9.71232 C13.472,7.63312 11.8496,5.96112 9.8584,5.96032 L-4.08562073e-14,5.95712 L-4.08562073e-14,7.58992 L9.8584,7.58432 Z"/>
                                        <path d="M15.10208,9.86088 C15.10208,12.75768 12.78448,15.10248 9.92928,15.10248 C8.63728,15.10248 7.42208,14.62168 6.47888,13.76648 C5.55968,12.93208 4.96448,11.80008 4.80208,10.55848 C4.62688,10.41288 3.89168,9.86088 3.89168,9.86088 C3.89168,9.86088 3.36448,10.47448 3.17008,10.67128 C3.36448,12.33608 4.15168,13.85928 5.38208,14.97528 C6.62208,16.10088 8.22768,16.73448 9.92928,16.73448 C13.69008,16.73448 16.73488,13.65528 16.73488,9.86088 C16.73488,6.06728 13.69008,2.98728 9.92928,2.98728 L0.00048,2.98728 L0.00048,4.62008 L9.92928,4.62008 C12.78448,4.62008 15.10208,6.96408 15.10208,9.86088"/>
                                        <path d="M10.00016,0 L0.00016,0 L0.00016,1.6328 L10.00016,1.6328 C14.62096,1.6328 18.36736,5.3792 18.36736,10 C18.36736,14.6208 14.62096,18.3672 10.00016,18.3672 C7.90896,18.3672 5.93936,17.5984 4.41456,16.2304 C3.86336,15.736 3.38016,15.1704 2.97696,14.5504 C2.60976,13.984 2.09056,12.7384 2.09056,12.7384 L1.00336,12.2488 L0.56976,13.3072 C0.56976,13.3072 1.14256,14.7224 1.60736,15.44 C2.08896,16.1808 2.66656,16.8552 3.32416,17.4456 C5.14576,19.08 7.50256,20 10.00016,20 C15.52256,20 20.00016,15.5232 20.00016,10 C20.00016,4.4776 15.52256,0 10.00016,0"/>
                                      </g>
                                    </g>
                                  </svg>
                            </div>
                            <div class="fs24 fw-500 bold line_height_32 mb16">
                                {{config.arc_pro_label}}
                            </div>
                            <div class="fs18 fw-500 line_height_26 gray_color_707479 mb10">
                                Professional tools to plan, analyze, and communicate project and portfolio performance.
                            </div>
                            <div class="mb16">
                                <span class="line_height_32 fw-500 fs22 bold">{{config.currency}}{{config.price.arc_pro_annually}}</span>
                                <span class="fs16 fw-500 line_height_32">user/month billed annually</span> 
                            </div>
                            <div class="fs16 gray_color_4A4A4A line_height_22 mb16">
                                {{config.currency}}{{config.price.arc_pro_monthly}} user/month when paying month to month. <span><a target="_blank" href="{{config.config.settings.landingPageURL + 'pricing'}}">Learn more</a></span>
                            </div>
                            <div class="fs16 gray_color_4A4A4A line_height_22 mb20">
                                <a href="mailto:contact@arcskoru.com">Contact Arc</a> for enterprise pricing for teams with >5 users or >25 projects.
                            </div>
                            <button (click)="global.filterAccess('app.arc_pro_purchase')" class="btn btn-primary fs20 fw-500 mb16">
                                Get Started
                            </button>
                            <ul class="fs16 line_height_22 gray_color_707479">
                                <li>Analyze LEED energy, water, and waste design goals</li>
                                    <li>Model energy, water, and waste strategies for new construction and major renovation</li>
                                    <li>Predict Arc Scores based on project performance</li>
                                    <li>Select custom date ranges for analysis and communications</li>
                                    <li>Quickly create and manage portfolios with advanced filters</li>
                                    <li>Generate <a href="https://arc.gbci.org/sites/default/files/report_1596052198.pdf" target="_blank">custom performance reports</a> for projects and portfolios</li>
                                    <li>Evaluate "LEED Readiness" for projects and portfolios</li>
                                    <li>Analyze portfolio-level scores and greenhouse gas emissions</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="gray_color_707479 fs16 line_height_24 align-center mt30">
                        * Cancelation will incur an immediate charge for the remaining amount on the date of cancellation.
                    </div> -->
                </div>
        
                <div class="info1">
                    <div class="mb160 flex">
                        <div class="w50p vertical_center">
                            <div class="vc_anchor">
                                <div class="fs32 fw-500 bold line_height_40 mb16">Improve your project's performance with custom modeling tools</div>
                                <div class="fs20 line_height_28">
                                    Score modeling tools enable users to explore relationships between performance metrics and the Arc Performance Score, This provides insights to improve performance and increase scores.
                                </div>
                            </div>
                        </div>
                        <div class="w50p">
                            <div class="info3_img floatr"></div>
                        </div>
                    </div>
                    <div class="mb160 flex">
                        <div class="w50p">
                            <div class="info4_img"></div>
                        </div>
                        <div class="w50p vertical_center pl120">
                            <div class="vc_anchor">
                                <div class="fs32 fw-500 bold line_height_40 mb16">Share scores and key performance indicators</div>
                                <div class="fs20 line_height_28">
                                    Arc reports reveal details about project performance. The detailed report summarize data coverage, Arc Performance Scores, key performance Indicators, and LEED readiness.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w50p vertical_center">
                            <div class="vc_anchor">
                                <div class="fs32 fw-500 bold line_height_40 mb16">Analyze markets across the United States</div>
                                <div class="fs20 line_height_28">
                                    Arc Insight users can explore energy, water. and waste design goals for LEED for New Construction Projects. They can review the achievement rate of green building strategies and create reports to inform project design and policy development.
                                </div>
                            </div>
                        </div>
                        <div class="w50p">
                            <div class="info5_img floatr"></div>
                        </div>
                    </div>
                </div>
        
                <div class="lm_footer">
                    <div class="">
                        <svg class="mr12" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 35 35">
                            <g fill="none" fill-rule="evenodd">
                              <circle cx="17.5" cy="17.5" r="17.5" fill="#28323B"/>
                              <g fill="#FFF" transform="translate(8 8)">
                                <path d="M9.8584,7.58432 C10.9536,7.58512 11.84,8.60112 11.84,9.71232 C11.84,10.83232 10.952,11.74432 9.856,11.74432 C9.456,11.74432 8.816,11.49632 8.496,11.27152 C8.368,11.27152 7.328,11.40032 7.328,11.40032 C7.328,11.40032 7.392,12.08032 7.456,12.51232 C8.112,13.12832 8.96,13.47152 9.856,13.47152 C11.848,13.47152 13.472,11.79232 13.472,9.71232 C13.472,7.63312 11.8496,5.96112 9.8584,5.96032 L-4.08562073e-14,5.95712 L-4.08562073e-14,7.58992 L9.8584,7.58432 Z"/>
                                <path d="M15.10208,9.86088 C15.10208,12.75768 12.78448,15.10248 9.92928,15.10248 C8.63728,15.10248 7.42208,14.62168 6.47888,13.76648 C5.55968,12.93208 4.96448,11.80008 4.80208,10.55848 C4.62688,10.41288 3.89168,9.86088 3.89168,9.86088 C3.89168,9.86088 3.36448,10.47448 3.17008,10.67128 C3.36448,12.33608 4.15168,13.85928 5.38208,14.97528 C6.62208,16.10088 8.22768,16.73448 9.92928,16.73448 C13.69008,16.73448 16.73488,13.65528 16.73488,9.86088 C16.73488,6.06728 13.69008,2.98728 9.92928,2.98728 L0.00048,2.98728 L0.00048,4.62008 L9.92928,4.62008 C12.78448,4.62008 15.10208,6.96408 15.10208,9.86088"/>
                                <path d="M10.00016,0 L0.00016,0 L0.00016,1.6328 L10.00016,1.6328 C14.62096,1.6328 18.36736,5.3792 18.36736,10 C18.36736,14.6208 14.62096,18.3672 10.00016,18.3672 C7.90896,18.3672 5.93936,17.5984 4.41456,16.2304 C3.86336,15.736 3.38016,15.1704 2.97696,14.5504 C2.60976,13.984 2.09056,12.7384 2.09056,12.7384 L1.00336,12.2488 L0.56976,13.3072 C0.56976,13.3072 1.14256,14.7224 1.60736,15.44 C2.08896,16.1808 2.66656,16.8552 3.32416,17.4456 C5.14576,19.08 7.50256,20 10.00016,20 C15.52256,20 20.00016,15.5232 20.00016,10 C20.00016,4.4776 15.52256,0 10.00016,0"/>
                              </g>
                            </g>
                          </svg>
                    </div>
                    <div class="fs32 line_height_40 fw-500 bold mt24 mb16">
                        Upgrade to {{config.arc_pro_label}}
                    </div>
                    <div class="fs20 line_height_22 mb24">
                        Use advanced features to improve performance
                    </div>
                    <button (click)="global.filterAccess('app.arc_pro_purchase')" class="btn btn-primary fs16 fw-500 h40 w125px">
                        Get Started
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
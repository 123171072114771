<div class="content-wrap">
	<div id="content" class="content">
		
		<div class="relative-position">

			<div class="align_lr mb15">
				<h3 class="fw-500 black-color pl15 mb25 pt5">
					LEED O+M score modeling
					<span *ngIf="(selected_tab == 'energy' && this.appData.get('buildingData').energy_score_calculation_factor != 'default') || (selected_tab == 'water' && this.appData.get('buildingData').water_score_calculation_factor != 'default')">
						<br>
						<span class="font-family-America fs14 gray_color_68737D">{{sub_header_text}}</span>
					</span>
				</h3>
				<div class="mt5 pt5 relative-position">
					<div class="flex mt5 mr25">
						<span class="mr5 gray_color_68737D">How scores and metrics work</span>
						<span (click)="global.showHelpText('total_leed_arc')" class="cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
						</span>
					</div>
				</div>
			</div>
		
			<div [hidden]="appData.get('user_role') == 'arc_pro' || config.EUserRole == 'GBCI Reviewer' || appData.get('buildingData').arctool_permission">
				<div class="row pl15">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-10 pl0 pr0 border_lrb pb30 mb30 bg-white">
								<div class="model_banner_img col-md-12"></div>
								<div class="col-md-12 fs18 fw-500 pt25 pb10">Upgrade to access Models</div>
								<div class="col-md-12 fs16 pb5">Use advanced features to improve performance.</div>
								<div class="col-md-12 fs16 fw-500">${{this.global.getPrice('arc_pro_annually')}}/user/month when billed annually or ${{this.global.getPrice('arc_pro_monthly')}}/user/month when paying month to month.</div>
								<div class="col-md-12 pt20 pb20">
									<button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.filterAccess('app.arc_pro_purchase')">Purchase Subscription</button>
									<button (click)="global.learnMore('arc_pro')" class="btn btn-primary inverse fs16 h40 br2p">Learn more</button>
								</div>
								<div class="col-md-12 mt10 mb20">
									<div class="models_divider"></div>
								</div>
								<div class="col-md-12">
									<div class="fs16">Analyze Arc Performance Scores across a range of parameters:</div>
									<ul>
										<li class="pt15 fs16">Calculate scores with changes in measured performance.  </li>
										<li class="pt15 fs16">Estimate the performance needed to achieve a specific Performance Score.</li>
										<li class="pt15 fs16">Inform plans to achieve or improve LEED certification level.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row">
							<div class="col-md-12 models_border pl0 pr0 pb30 bg-white">
								<div class="align-center models_how_arc_works mb20">
									<div class="arc_models_labels"></div>
								</div>
								<div class="fs18 fw-500 pl15 pr15">How Arc scores work</div>
								<div class="learn_more_link align-left fs16 pl15 pr15 mt10" (click)="global.showHelpText('na_help')">Learn more</div>
							</div>
						</div>
						<div class="model_vertical_divider"></div>
					</div>
				</div>
			</div>
		
			<div [hidden]="!(appData.get('user_role') == 'arc_pro' || config.EUserRole == 'GBCI Reviewer' || appData.get('buildingData').arctool_permission)">

				<div class="home_tab mt0 mb0 mr15">
					<div (click)="changeTab('energy')" [ngClass]="selected_tab == 'energy' ? 'active' : ''" class="tab_item energy">
						Energy
					</div>
					<div (click)="changeTab('water')" [ngClass]="selected_tab == 'water' ? 'active' : ''" class="tab_item water">
						Water
					</div>
					<div (click)="changeTab('waste')" [ngClass]="selected_tab == 'waste' ? 'active' : ''" class="tab_item waste">
						Waste
					</div>
					<div (click)="changeTab('transport')" [ngClass]="selected_tab == 'transport' ? 'active' : ''" class="tab_item transport">
						Transportation
					</div>
					<div (click)="changeTab('humanexperience')" [ngClass]="selected_tab == 'humanexperience' ? 'active' : ''" class="tab_item humanexperience">
						Human Experience
					</div>
				</div>
		
				<div class="home_tab_dropdown mb0">
					<div class="dropdown dropdown_v2">
						<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
							<span class="mr20">{{selected_tab}}</span>
							<i class="toggle fa fa-angle-down"></i>
						</div>
						<ul class="dropdown-menu left">
							<li (click)="changeTab('energy')" [ngClass]="selected_tab == 'energy' ? 'active' : ''">
								<a>
									Energy
								</a>
							</li>
							<li (click)="changeTab('water')" [ngClass]="selected_tab == 'water' ? 'active' : ''">
								<a>
									Water
								</a>
							</li>
							<li (click)="changeTab('waste')" [ngClass]="selected_tab == 'waste' ? 'active' : ''">
								<a>
									Waste
								</a>
							</li>
							<li (click)="changeTab('transport')" [ngClass]="selected_tab == 'transport' ? 'active' : ''">
								<a>
									Transportation
								</a>
							</li>
							<li (click)="changeTab('humanexperience')" [ngClass]="selected_tab == 'humanexperience' ? 'active' : ''">
								<a>
									Human Experience
								</a>
							</li>
						</ul>
					</div>
				</div>
		
				<div *ngIf="selected_tab == 'energy'" class="widget_container mt16">
					<div [ngClass]="backdrop ? 'not-active' : ''">
						<app-widget id="energy-slider-widget" [leed_id]="appData.get('leed_id')" [src]="'slider'" [param]="'energy'" [width]="'100%'" [height]="'500px'"></app-widget>

						<div class="widget_heading fs24 mb15 mt15" *ngIf="appData.get('buildingData').energy_score_calculation_factor == 'default'">Metric reductions</div>
						<div class="widget_heading fs24 mb15 mt15" *ngIf="appData.get('buildingData').energy_score_calculation_factor != 'default'">LEED points table</div>
						
						<app-widget id="energy-table-widget" [leed_id]="appData.get('leed_id')" [src]="'table'" [param]="'energy'" [width]="'100%'" [height]="'500px'"></app-widget>
					</div>
				</div>
		
				<div *ngIf="selected_tab == 'water'" class="widget_container mt16">
					<div [ngClass]="backdrop ? 'not-active' : ''">
						<app-widget id="water-slider-widget" [leed_id]="appData.get('leed_id')" [src]="'slider'" [param]="'water'" [width]="'100%'" [height]="'500px'"></app-widget>

						<div class="widget_heading fs24 mb15 mt15" *ngIf="appData.get('buildingData').water_score_calculation_factor == 'default'">Metric reductions</div>
						<div class="widget_heading fs24 mb15 mt15" *ngIf="appData.get('buildingData').water_score_calculation_factor != 'default'">LEED points table</div>
						
						<app-widget id="water-table-widget" [leed_id]="appData.get('leed_id')" [src]="'table'" [param]="'water'" [width]="'100%'" [height]="'500px'"></app-widget>
					</div>
				</div>
		
				<div *ngIf="selected_tab == 'waste'" class="widget_container mt16">
					<div [ngClass]="backdrop ? 'not-active' : ''">
						<app-widget id="waste-slider-widget" [leed_id]="appData.get('leed_id')" [src]="'slider'" [param]="'waste'" [width]="'100%'" [height]="'500px'"></app-widget>

						<div class="widget_heading fs24 mb15 mt15">Metric reductions</div>
						
						<app-widget id="waste-table-widget" [leed_id]="appData.get('leed_id')" [src]="'table'" [param]="'waste'" [width]="'100%'" [height]="'500px'"></app-widget>
					</div>
				</div>
		
				<div *ngIf="selected_tab == 'transport'" class="widget_container mt16">
					<div [ngClass]="backdrop ? 'not-active' : ''">
						<app-widget id="transportation-slider-widget" [leed_id]="appData.get('leed_id')" [src]="'slider'" [param]="'transportation'" [width]="'100%'" [height]="'500px'"></app-widget>

						<div class="widget_heading fs24 mb15 mt15">Metric reductions</div>
						
						<app-widget id="transportation-table-widget" [leed_id]="appData.get('leed_id')" [src]="'table'" [param]="'transportation'" [width]="'100%'" [height]="'500px'"></app-widget>
					</div>
				</div>
		
				<div *ngIf="selected_tab == 'humanexperience'" class="widget_container mt16">
					<div [ngClass]="backdrop ? 'not-active' : ''">
						<app-widget id="human-experience-slider-widget" [leed_id]="appData.get('leed_id')" [src]="'slider'" [param]="'human_experience'" [width]="'100%'" [height]="'500px'"></app-widget>

						<div class="widget_heading fs24 mb15 mt15">Metric reductions</div>
						
						<app-widget id="human-experience-table-widget" [leed_id]="appData.get('leed_id')" [src]="'table'" [param]="'human_experience'" [width]="'100%'" [height]="'500px'"></app-widget>
					</div>
				</div>
			</div>
		</div>
		

	</div>
</div>
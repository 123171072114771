import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';

import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from '../components/modal/modal.component';

@Component({
  selector: 'app-user-billing',
  templateUrl: './user-billing.component.html',
  styleUrls: ['./user-billing.component.css']
})
export class UserBillingComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	
	cancelled_self: boolean = true;
	loading: boolean = false;
	enable: boolean = false;
	current_tab: string = 'Subscriptions';
	status_subs: any = null;
	offline_sub: any = null;
	active_subs: any = null;

	subscriptions: any = {
		"subscribers": [],
		"card": {},
		"appData": {},
		"subscribed_by": {}
	};
	billing_history: any = [];
	address: any = {};

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('session_id') != undefined)
		{
			this.successURL();
		}
		else
		{
			this.getSubs();
		}
	}

	successURL()
	{
		this.loading = true;
		this.api.get('/auth/user/subscriptions/'+ this.appData.get('session_id') +'/').subscribe(
			data =>
			{
				this.appData.set('session_id', undefined);
				this.getSubs();
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	getMonthDiff(month_diff: number): any
	{
		if(this.subscriptions.card == null)
		{
			return;
		}
		var date = "01-" + String(this.subscriptions.card.exp_month) + "-" + String(this.subscriptions.card.exp_year);
		return moment(date, "DD-MM-YYYY").diff(new Date(), 'months', true) > month_diff ? false : true;
	}

	toggleTab(tab: string)
	{
		this.current_tab = tab;
	}

	addSubs()
	{
		this.global.upgrade("add_subscription", undefined, this.subscriptions);
	}

	editCard()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscriptions.subscription_id,
			"pmupdate": true
		};

		this.api.put('/auth/user/subscriptions/', payload).subscribe(
			data =>
			{
				var stripe_url = Config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ Config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
				window.open(stripe_url, "_self");
			},
			error =>
			{
				this.loading = false
			}
		);
	}

	openCancel(user: any)
	{
		for(var i = 0; i < this.subscriptions.subscribers.length; i++)
		{
			if(this.subscriptions.subscribers[i].email == user.email)
			{
				this.subscriptions.subscribers[i]['select_all'] = true;
				this.enable = true;
			}
			else
			{
				this.subscriptions.subscribers[i]['select_all'] = false;
			}
		}
		this.modal.open({windowClass: "certificate_modal"});
	}

	getBillingHistory()
	{
		if(this.subscriptions.subscription_id == undefined || this.subscriptions.subscription_id == null || this.subscriptions.subscription_id.length == 0)
		{
			return;
		}
		this.api.get('/subscriptions/'+ this.subscriptions.subscription_id +'/payments/history/').subscribe(
			data =>
			{
				this.billing_history = data.results;
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	getSubs()
	{
		this.loading = true;
		this.api.get('/auth/user/subscriptions/').subscribe(
			response =>
			{
				this.loading = false;
				if(response.card == null)
				{
					response.card = {};
				}
				this.subscriptions = response;
				if(this.subscriptions.subscribers == undefined)
				{
					this.subscriptions = {
						"subscribers": [],
						"subscription_id": "",
						"card": {}
					}
				}
				this.address = {
					'email': this.appData.get('user_id'),
					'subscription_id': this.subscriptions.subscription_id,
					'street': this.subscriptions.street,
					'city': this.subscriptions.city,
					'state': this.subscriptions.state,
					'country': this.subscriptions.country,
					'zip_code':this.subscriptions.zip_code
				};
				if(this.subscriptions.subscription_id.indexOf('sub_free') != -1 || this.subscriptions.subscription_id.indexOf('sub_offline_paid') != -1)
				{
					this.offline_sub = true;
				}
				else
				{
					this.offline_sub = false;
				}
				if(this.subscriptions.subscription_id.indexOf('japan') != -1)
				{
					this.appData.set('user_type', 'arc_japan');
					this.appData.set('currency', '¥');
				}
				else
				{
					this.appData.set('user_type', 'arc_global');
					this.appData.set('currency', '$');
				}
				this.getBillingHistory();

				this.active_subs = null;
				this.status_subs = null;
				for(var i = 0; i < this.subscriptions.subscribers.length; i++)
				{
					if(this.subscriptions.subscribers[i].status == 'active' && this.subscriptions.subscribers[i].email == this.appData.get('user_id'))
					{
						this.cancelled_self = false;
						this.active_subs = true;
						this.status_subs = 'active';
						break;
					}
					else if(this.subscriptions.subscribers[i].status == 'cancelled_active' && this.subscriptions.subscribers[i].email == this.appData.get('user_id'))
					{
						this.cancelled_self = true;
						this.active_subs = true;
						this.status_subs = 'cancelled_active';
						break;
					}
					else if(this.subscriptions.subscribers[i].status == 'expired' && this.subscriptions.subscribers[i].email == this.appData.get('user_id'))
					{
						this.cancelled_self = true;
						this.active_subs = false;
						this.status_subs = 'expired';
						break;
					}
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	cancelSubs(subscriptions: any)
	{
		this.loading = true;
		let payload = {
			"subscription_id": this.subscriptions.subscription_id,
			"email": subscriptions
		};

		this.api.delete('/auth/user/subscriptions/', payload).subscribe(
			data =>
			{
				this.getSubs()
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	editBilling()
	{
		this.global.upgrade("update_billing_information", undefined, this.subscriptions);
	}

	cancelSubsModal()
	{
		var subscriptions = [];
		for(var i = 0; i < this.subscriptions.subscribers.length; i++)
		{
			if(this.subscriptions.subscribers[i]['select_all'])
			{
				subscriptions.push(this.subscriptions.subscribers[i].email);
			}
		}
		
		this.loading = true;
		var payload = {
			"subscription_id": this.subscriptions.subscription_id,
			"email": subscriptions
		};

		this.api.delete('/auth/user/subscriptions/', payload).subscribe(
			data =>
			{
				this.getSubs();
				this.modal.close();
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	toggleCheck(index: any)
	{
		this.subscriptions.subscribers[index]['select_all'] = !this.subscriptions.subscribers[index]['select_all'];
		this.enable = false;
		for(var i = 0; i < this.subscriptions.subscribers.length; i++)
		{
			if(this.subscriptions.subscribers[i]['select_all'])
			{
				this.enable = true;
			}
		}
	}
}

import { Component, OnInit, ViewChild } from '@angular/core';

import { Global } from 'src/app/services/global.service';
import { AppData } from 'src/app/services/appdata.service';

import { ModelDetailsComponent } from './model-details/model-details.component';
import { ModelStrategiesComponent } from './model-strategies/model-strategies.component';
import { ModelReductionComponent } from './model-reduction/model-reduction.component';

@Component({
  selector: 'app-create-model',
  templateUrl: './create-model.component.html',
  styleUrls: ['./create-model.component.css']
})
export class CreateModelComponent implements OnInit
{
	loading: boolean = false;
	steps: string[] = ["Model details", "Strategies", "Reduction view"];

	@ViewChild(ModelDetailsComponent) model_details!: ModelDetailsComponent;
	@ViewChild(ModelStrategiesComponent) model_strategies!: ModelStrategiesComponent;
	@ViewChild(ModelReductionComponent) model_reduction!: ModelReductionComponent;

	constructor(
		public global: Global,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		
	}

	emitFromChild(data: any)
	{
		this.appData.set("current_step", this.steps[this.steps.indexOf(this.appData.get("current_step")) + 1] == undefined ? this.steps[0] : this.steps[this.steps.indexOf(this.appData.get("current_step")) + 1]);
	}

	emitToChild()
	{
		if(this.appData.get("current_step") == 'Model details')
		{
			this.model_details.verifyDetails();
		}
		else if(this.appData.get("current_step") == 'Strategies')
		{
			this.appData.set("current_step", "Reduction view");
			this.appData.set("reductionData", this.model_strategies.project_reduction);
		}
		else if(this.appData.get("current_step") == 'Reduction view')
		{
			this.loading = true;
			this.model_reduction.saveReduction();
		}
	}

	goBack()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": 'insight'
		});
		this.appData.set('insight_app', 'market');
	}
}

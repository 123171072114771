import { Injectable } from '@angular/core';

import { AppData } from './appdata.service';
import { API } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService
{
	constructor(
		private appData: AppData,
		private api: API
	)
	{}

	getPortfolios()
	{
		return this.api.get(
			'/portfolios/?page_size=50'
		);
	}

	get()
	{                
		return this.api.get(
			'/portfolios/ID:' + this.appData.get('portfolio_id') + '/'
		);
	}

	getAnalyticsSummary(portfolio_id: string | number, category: any, unit_type: any)
	{
		category = typeof category !== 'undefined' ? category : false;
		unit_type = typeof unit_type !== 'undefined' ? unit_type : false;

		var url = '/portfolios/ID:' + portfolio_id + '/analytics/summary/';

		if (category)
		{
			url += '?category=' + category;
		}

		if (unit_type)
		{
			url += '&unit=' + unit_type;
		}

		return this.api.get(
			url
		);
	}

	getEASummary(portfolio_id: string | number, unit_type: any)
	{
		unit_type = typeof unit_type !== 'undefined' ? unit_type : false;

		var url = '/portfolios/ID:' + portfolio_id + '/efficiency/summary/';

		if (unit_type)
		{
			url += '?unit=' + unit_type;
		}

		return this.api.get(
			url
		);
	}

	getProjects(portfolio_id: any, filters: any)
	{
		var url = '/portfolios/ID:' + portfolio_id + '/assets/';
		url += filters;
		return this.api.get(
			url
		);
	}

	getPortfolioProjects(portfolio_id: number, order: any, category: any, page_number: any, page_size: any, unit_type: any, cert_type: any, date?: any)
	{
		order = typeof order !== 'undefined' ? order : false;
		category = typeof category !== 'undefined' ? category : false;
		page_size = typeof page_size !== 'undefined' ? page_size : "0";
		page_number = typeof page_number !== 'undefined' ? page_number : false;
		unit_type = typeof unit_type !== 'undefined' ? unit_type : false;
		cert_type = typeof cert_type !== 'undefined' ? cert_type : false;
		date = typeof date !== 'undefined' ? date : false;

		let url = '/portfolios/ID:' + portfolio_id + '/assets/';

		if (order && category && page_size){
			url += '?order=' + order + '&category=' + category + '&page_size=' + page_size;
		}

		else if (page_size){
			url += '?page_size=' + page_size;
		}

		else if (order){
			url += '?order=' + order;
		}

		if(page_number){
			url += '&page=' + page_number;
		}

		if(unit_type){
			url += '&unit=' + unit_type;
		}

		if(cert_type){
			url += '&cert_type=' + cert_type;
		}

		if(date){
			url += '&at=' + date;
		}

		return this.api.get(
			url
		);
	}

	savePerformancePeriod(payload: any)
	{
		return this.api.put(
			'/portfolios/ID:' + this.appData.get('portfolio_id') + '/',
			payload
		);
	}
}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.css']
})
export class AppsComponent implements OnInit
{
	config = Config;

	apps_received = false;

	energy_star_status = false;
	dropbox_status = false;
	onedrive_status = false;
	google_drive_status = false;

	energy_star_value = "Add";
	dropbox_value = "Add";
	onedrive_value = "Add";
	google_drive_value = "Add";

	energy_star_flag = false;
	dropbox_flag = false;
	onedrive_flag = false;
	google_drive_flag = false;
	selected_app = "";
	loading = true;
	modifying_apps: boolean = false;

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	){ }

	ngOnInit(): void
	{
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/apps/'
		).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.apps_received = true;
				for (var i = 0; i < data.length; i++)
				{
					if (data[i].apps.id == 1)
					{
						this.energy_star_status = true;
					}
					else if (data[i].apps.id == 2)
					{
						this.dropbox_status = true;
					}
					else if (data[i].apps.id == 3)
					{
						this.onedrive_status = true;
					}
					else if (data[i].apps.id == 4)
					{
						this.google_drive_status = true;
					}
				}
	
				if (this.energy_star_status)
				{
					this.energy_star_value = "Added";
				}
	
				if (this.dropbox_status)
				{
					this.dropbox_value = "Added";
				}
	
				if (this.onedrive_status)
				{
					this.onedrive_value = "Added";
				}
	
				if (this.google_drive_status)
				{
					this.google_drive_value = "Added";
				}
			},
			//error handler
			data =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Something went wrong.', 5);
				this.apps_received = false;
			}
		);
	}

	successStatus(request: string)
	{
		if (request == "delete")
		{
			this.alertService.alert('success', 'Removed successfully.', 5);
		}
		else
		{
			this.alertService.alert('success', 'Added successfully.', 5);
		}

		if (this.selected_app == "Energy Star Portfolio Manager")
		{
			if (request == "delete")
			{
				this.energy_star_value = "Add";
				this.energy_star_status = false;
			}
			else
			{
				this.energy_star_value = "Added";
				this.energy_star_status = true;
			}
			this.energy_star_flag = false;
		}
		else if (this.selected_app == "Dropbox")
		{
			if (request == "delete")
			{
				this.dropbox_value = "Add";
				this.dropbox_status = false;
			}
			else
			{
				this.dropbox_value = "Added";
				this.dropbox_status = true;
			}
			this.dropbox_flag = false;
		}
		else if (this.selected_app == "OneDrive")
		{
			if (request == "delete")
			{
				this.onedrive_value = "Add";
				this.onedrive_status = false;
			}
			else
			{
				this.onedrive_value = "Added";
				this.onedrive_status = true;
			}
			this.onedrive_flag = false;
		}
		else if (this.selected_app == "Google Drive")
		{
			if (request == "delete")
			{
				this.google_drive_value = "Add";
				this.google_drive_status = false;
			}
			else
			{
				this.google_drive_value = "Added";
				this.google_drive_status = true;
			}
			this.google_drive_flag = false;
		}
	};

	errorStatus(request: string)
	{
		this.alertService.alert('error', 'Please try again.', 5);
		if (this.selected_app == "Energy Star Portfolio Manager")
		{
			if (request == "delete")
			{
				this.energy_star_value = "Added";
			}
			else
			{
				this.energy_star_value = "Add";
			}
			this.energy_star_flag = false;
		}
		else if (this.selected_app == "Dropbox")
		{
			if (request == "delete")
			{
				this.dropbox_value = "Added";
			}
			else
			{
				this.dropbox_value = "Add";
			}
			this.dropbox_flag = false;
		}
		else if (this.selected_app == "OneDrive")
		{
			if (request == "delete")
			{
				this.onedrive_value = "Added";
			}
			else
			{
				this.onedrive_value = "Add";
			}
			this.onedrive_flag = false;
		}
		else if (this.selected_app == "Google Drive")
		{
			if (request == "delete")
			{
				this.google_drive_value = "Added";
			}
			else
			{
				this.google_drive_value = "Add";
			}
			this.google_drive_flag = false;
		}
	};

	saveApp(app: any, appId: any)
	{
		this.selected_app = app;
		var delete_request = false;
		this.modifying_apps = true;

		if (this.selected_app == "Energy Star Portfolio Manager")
		{
			this.energy_star_flag = true;
			if (this.energy_star_status)
			{
				this.energy_star_value = "Removing...";
				delete_request = true;
			}
			else
			{
				this.energy_star_value = "Adding...";
			}
		}
		else if (this.selected_app == "Dropbox")
		{
			this.dropbox_flag = true;
			if (this.dropbox_status)
			{
				this.dropbox_value = "Removing...";
				delete_request = true;
			}
			else
			{
				this.dropbox_value = "Adding...";
			}
		}
		else if (this.selected_app == "OneDrive")
		{
			this.onedrive_flag = true;
			if (this.onedrive_status)
			{
				this.onedrive_value = "Removing...";
				delete_request = true;
			}
			else
			{
				this.onedrive_value = "Adding...";
			}
		}
		else if (this.selected_app == "Google Drive")
		{
			this.google_drive_flag = true;
			if (this.google_drive_status)
			{
				this.google_drive_value = "Removing...";
				delete_request = true;
			}
			else
			{
				this.google_drive_value = "Adding...";
			}
		}

		if (delete_request)
		{
			var payload = {
				"app": appId
			};
			this.api.delete('/assets/LEED:' + this.appData.get('leed_id') + '/apps/?app=' + appId, payload).subscribe(
				//success handler
				response =>
				{
					this.successStatus("delete");
					this.modifying_apps = false;
				},
				//error handler
				response =>
				{
					this.successStatus("delete");
					this.modifying_apps = false;
				}
			);
		}
		else
		{
			var data = {
				"app": appId
			};
			this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/apps/', data).subscribe(
				//success handler
				response =>
				{
					this.successStatus("post");
					this.modifying_apps = false;
				},
				//error handler
				response =>
				{
					this.errorStatus("post");
					this.modifying_apps = false;
				}
			);
		}
	};

}

<div class="p2535">
    
    <div class="left-right-align">
        <div class="flex fontSize18 mb25 fw-600 green-color">
            <img src="assets/images/done_green_icon.svg" class="w35px mt-n3px ">
            <span class="error-lg-msg"> Certificate created</span>
        </div> 
    </div>
    <div id="certificate_list">
        <div *ngFor="let item of data_labels" class="certificate">
            <div *ngIf="item.type != 'climaterisk'">
                <div class="left-right-align header">
                    <h4 class="flex">
                        <div class="{{item.type}}_white_icon category_icon"></div>
                        {{item.label}} Performance Certificate
                    </h4>
                    <button [ngClass]="global.formatNumber(project_scores[item.type], 0, true) > 0 ? '' : 'not-active'" *ngIf="download_cert[item.type] == null" (click)="goToCertificate(item.type, item.label)" class="btn btn-primary">Add Certificate</button>
                    <button *ngIf="download_cert[item.type] != null" (click)="downloadCert(download_cert[item.type].certificate)" class="btn btn-primary inverse">View Certificate</button>
                </div>
                <div class="flex relative-position">
                    <div class="mr20">
                        <p>Performance Score</p>
                        <p class="light_gray fs18"><span class="black-color">{{global.formatNumber(project_scores[item.type], 0, true)}}</span>/100</p>
                    </div>
                    <div class="mr20">
                        <p>Improvement Score</p>
                        <p class="light_gray fs18"><span class="black-color">{{global.formatNumber(improvement_scores[item.type].score, 0, true)}}</span>/100</p>
                    </div>
                    <div *ngIf="download_cert[item.type] != null" class="create_cert">
                        <p *ngIf="download_cert[item.type].certification_expire">
                            <span class="ff-ss-black black-color">Valid until {{global.dateFormat(payload.certification_expire)}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="item.type == 'climaterisk'">
                <div class="left-right-align header">
                    <h4 class="flex">
                        {{item.label}} Certificate
                    </h4>
                    <button [ngClass]="global.formatNumber(project_scores.score_submitted, 0, true) > 0 ? '' : 'not-active'" *ngIf="download_cert[item.type] == null" (click)="goToCertificate(item.type, item.label)" class="btn btn-primary">Add Certificate</button>
                    <button *ngIf="download_cert[item.type] != null" (click)="downloadCert(download_cert[item.type].certificate)" class="btn btn-primary inverse">View Certificate</button>
                </div>
                <div class="flex relative-position">
                    <div class="mr20">
                        <p>Arc Carbon Score</p>
                        <p class="light_gray fs18"><span class="black-color">{{global.formatNumber(project_scores.score_submitted, 0, true)}}</span>/100</p>
                    </div>
                    <div class="mr20">
                        <p>Arc Electrification Score</p>
                        <p class="light_gray fs18"><span class="black-color">{{global.formatNumber(project_scores.electrification_score, 0, true)}}</span>/100</p>
                    </div>
                    <div *ngIf="download_cert[item.type] != null" class="create_cert">
                        <p *ngIf="download_cert[item.type].certification_expire">
                            <span class="ff-ss-black black-color">Valid until {{global.dateFormat(payload.certification_expire)}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content-wrap" [hidden]="config.hide_sidebar" [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
	<div id="content" class="content">
        <h3 class="fw-500 black-color ml15 mr20 mb30 pt5">
            European Union Sustainable Finance Taxonomy
        </h3>

        <div class="ml15 mr20" *ngIf="user_role != 'arc_pro'">
            <div class="row widget_mimic mb30 mt20 p0 tax_text" style="width: 570px;">
                <div class="col-md-12 pl0 pr0">
                    <div class="ea_banner_img floatNone col-md-12 w100p"></div>
                    <div class="col-md-12 fs18 fw-500 pt25 pb10">European Union Sustainable Finance Taxonomy</div>
                    <div class="col-md-12 fs16 pb5">
                        The European Union (EU) Sustainable Finance Taxonomy is a classification system that translates climate and environmental objectives into criteria for specific economic activities for investment purposes.
                        <br>
                        <br>
                        The Arc self-assessment tool assesses Taxonomy alignment through a five-step process:
                        <ul>
                            <li>
                                Select a project type and enter basic information.
                            </li>
                            <li>
                                Document at least one environmental objective.
                            </li>
                            <li>
                                Document all “do no significant harm” criteria.
                            </li>
                            <li>
                                Document all “minimum safeguard”.
                            </li>
                            <li>
                                Generate an alignment report.
                            </li>
                        </ul>
                        <br>
                        Learn about EU criteria on the <a href="https://ec.europa.eu/sustainable-finance-taxonomy/taxonomy-compass" target="_blank">Taxonomy Compass website</a>.
                    </div>
                    <div class="col-md-12 pt20 pb20 mb20">
                        <div class="flex">
                            <button [ngClass]="config.access == 'R' ? 'not-active' : ''" (click)="global.learnMore('arc_pro')" class="btn btn-primary fs16 h40 br2p mr10 flex">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="user_role == 'arc_pro'">
            <div class="ml15 mr20 widget_mimic fs15 line_height_18 flex">
                <div>
                    <span class="fw-500">What is the European Union Sustainable Finance Taxonomy?</span>
                    <br>
                    <br>
                    The European Union (EU) Sustainable Finance Taxonomy is a classification system that translates climate and environmental objectives into criteria for specific economic activities for investment purposes.
                    <br>
                    <br>
                    
                    The Arc self-assessment tool assesses Taxonomy alignment through a five-step process:
                    <ul>
                        <li>
                            Select a project type and enter basic information.
                        </li>
                        <li>
                            Document at least one environmental objective.
                        </li>
                        <li>
                            Document all “do no significant harm” criteria.
                        </li>
                        <li>
                            Document all “minimum safeguard”.
                        </li>
                        <li>
                            Generate an alignment report.
                        </li>
                    </ul>

                    <br>
                    Learn about EU criteria on the <a href="https://ec.europa.eu/sustainable-finance-taxonomy/taxonomy-compass" target="_blank">Taxonomy Compass website</a>.
                </div>
            </div>

            <div class="widget_mimic ml15 mr20 tax_forms">
                <div class="align_lr border_bottom mb20">
                    <div class="fs18 line_height_24 fw-500 pt5">Project Surveys</div>
                    <div>
                        <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary line_height_22" (click)="newSurvey()">
                            Begin a new survey
                        </button>
                    </div>
                </div>
                
                <div class="table_wrapper" *ngIf="surveys.length == 0">
                    <div class="empty_table">
                        No surveys done, please begin a new survey
                    </div>
                </div>

                <div class="w100p" *ngIf="surveys.length > 0">
                    <table class="table table-striped arc-table">
                        <thead>
                            <tr>
                                <th>Date last changed</th>
                                <th>Survey Type</th>
                                <th>Percentage of Completion</th>
                                <th>Compliance</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of surveys" [hidden]="row.id == undefined">
                                <td>
                                    {{global.dateTimeFormat(row.created_at)}}
                                </td>
                                <td class="fw-500">
                                    {{row.label}}
                                </td>
                                <td>
                                    {{global.formatNumber(row.completion, 0)}}%
                                </td>
                                <td>
                                    {{row.compliance ? "Yes" : "No"}}
                                </td>
                                <td class="align-right">
                                    <button *ngIf="!row.surveycompleted" class="btn btn-primary small fs12 mr12" (click)="editSurvey(row)">
                                        Edit
                                    </button>
                                    <button (click)="openDownload(row)" class="btn btn-primary small fs12" [ngClass]="row.surveycompleted && row.compliance ? '' : 'not-active'">
                                        Download
                                    </button>
                                </td>
                            </tr>			    
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="content-wrap center-content-wrap" *ngIf="config.hide_sidebar" [ngClass]="loading ? 'not-active' : ''">

    <div id="certificate_score" [hidden]="true">
        <div class="certificate" id="myCanvas">    
            <div id="cert_banner" class="cert_banner energy_banner_gradient">
                <div class="cert_appLogo"></div>
            </div>
            <div id="cert_content">
                <div class="heading1">ARC SKORU RECOGNIZES</div>
                <h2 id="project_name">{{appData.get('buildingData').name}}</h2>
                <div id="project_address">{{appData.get('buildingData').street}}</div>
                <div class="heading2">
                    EU SUSTAINABLE FINANCE TAXONOMY SELF-ASSESSMENT RESULT:
                </div>
                <div id="title" class="category">
                    ALIGNED
                </div>
        
                <div class="metrics">
                    <div class="key_metrics energy">
                        <div class="heading">Asset Characteristics</div>
                        <div class="cert_block"></div>
                        <div class="data">
                            <span class="left">Project Type</span>
                            <span class="right">
                                <span class="cert_bold" id="site_energy_intensity">
                                    <span [innerHTML]="selected_survey.steps[0].assettype"></span>
                                </span>
                            </span>
                        </div>
                        <div class="data">
                            <span class="left">Gross Area</span>
                            <span class="right">
                                <span class="cert_bold" id="site_energy_intensity">
                                    <span [innerHTML]="global.addCommas(selected_survey.steps[0].gross_area)"></span>

                                    {{appData.get('unit_preference').gfa_unit == 'sqm' ? 'sq. meters' : 'sq. feet'}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="key_metrics energy mt45 ml25">
                        <div class="data">
                            <span class="left">Year Construction / Renovation began</span>
                            <span class="right">
                                <span class="cert_bold" id="site_energy_intensity">
                                    <span [innerHTML]="selected_survey.steps[0].startyear"></span>
                                </span>
                            </span>
                        </div>
                        <div class="data">
                            <span class="left">Year Construction / Renovation Conclude</span>
                            <span class="right">
                                <span class="cert_bold" id="site_energy_intensity">
                                    <span [innerHTML]="selected_survey.steps[0].endyear"></span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
    
                <div class="metrics" style="width: 88%;">
                    <div class="key_metrics energy w100p">
                        <div class="heading">Self-Assessment Summary</div>
                        <div style="font-size: 14px;margin-bottom: 20px;">
                            Alignment with the European Union Sustainable Finance Taxonomy is based on the follow self-reported information: 
                        </div>
                        <table class="w100p">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="cert_bold">Responses</th>
                                    <th class="cert_bold">Documentation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Environmental Objective
                                    </td>
                                    <td>
                                        <div style="margin-left: 20px;" class="done_green_icon w25px mt-n3px"></div>
                                    </td>
                                    <td>
                                        <div style="margin-left: 40px;" class="done_green_icon w25px mt-n3px"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Do No Significant Harm
                                    </td>
                                    <td><div style="margin-left: 20px;" class="done_green_icon w25px mt-n3px"></div></td>
                                    <td><div style="margin-left: 40px;" class="done_green_icon w25px mt-n3px"></div></td>
                                </tr>
                                <tr>
                                    <td>
                                        Minimum Safeguard 
                                    </td>
                                    <td><div style="margin-left: 20px;" class="done_green_icon w25px mt-n3px"></div></td>
                                    <td><div style="margin-left: 40px;" class="done_green_icon w25px mt-n3px"></div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-loader *ngIf="loading"></app-loader>
	<div id="content" class="content">

        <div class="left-right-align">
            <h3 class="fw-500 black-color mr20 mb30 pt5">
                {{selected_survey.label}}
            </h3>
            <div class="flex" [ngClass]="error_year ? 'not-active' : ''">
                <div class="pt20">
                    <button class="btn grey btn-default btn-md line_height_24 mr15" (click)="exitSurvey()">
                        Exit without Saving
                    </button>
                    <button class="btn grey btn-default btn-md line_height_24 mr15" (click)="checkSurvey('save')">
                        Save and Exit
                    </button>
                    <button class="btn btn-primary line_height_24" (click)="checkSurvey('submit')">
                        Complete Survey
                    </button>
                </div>
            </div>
        </div>

        <div id="survey_wrapper">
            <div class="heading_survey">
                <div class="fs22 line_height_24 fw-500 pt5">
                    Step #{{current_step + 1}}: {{selected_survey.steps[current_step].label}}
                </div>
                <div class="flex" [ngClass]="error_year ? 'not-active' : ''">
                    <button class="btn btn-primary mr10 fs14 line_height_24" (click)="previousStep()" [hidden]="current_step == 0">
                        Previous Step
                    </button>
                    <button class="btn btn-primary fs14 line_height_24" (click)="nextStep()" [hidden]="current_step == (selected_survey.steps.length - 1)">
                        Proceed to Next Step
                    </button>
                </div>
            </div>
            <div class="content_survey" *ngIf="selected_survey.steps[current_step].type == 'basic_form'">
                <div class="mb20">
                    <div class="form_label">
                        <span>1.1 </span>
                        <span class="fw500">Asset Name for Assessment</span>
                    </div>
                    <input class="arc-input w24p" [(ngModel)]="appData.get('buildingData').name" [readOnly]="true"/>
                </div>
                <div class="flex w100p mb20">
                    <div class="mr20 w25p">
                        <div class="form_label">
                            <span>1.2 </span>
                            <span class="fw500">Street Address</span>
                        </div>
                        <input class="arc-input" [(ngModel)]="appData.get('buildingData').street" [readOnly]="true"/>
                    </div>
                    <div class="mr20 w25p">
                        <div class="form_label">
                            <span>1.3 </span>
                            <span class="fw500">State/Province</span>    
                        </div>
                        <input class="arc-input" [(ngModel)]="state_full" [readOnly]="true"/>
                    </div>
                    <div class="mr20 w25p">
                        <div class="form_label">
                            <span>1.4 </span>
                            <span class="fw500">Postal Code</span>    
                        </div>
                        <input class="arc-input" [(ngModel)]="appData.get('buildingData').zip_code" [readOnly]="true"/>
                    </div>
                    <div class="w25p">
                        <div class="form_label">
                            <span>1.5 </span>
                            <span class="fw500">Country</span>    
                        </div>
                        <input class="arc-input" [(ngModel)]="country_full" [readOnly]="true"/>
                    </div>
                </div>
                <div class="radio_buttons seps group mb20">
                    <div class="form_label">
                        <span>1.6 </span>
                        <span class="fw500">Asset Type</span>    
                    </div>
                    <div class="flex mt10">
                        <div class="mr25" (click)="changeRadio('Commercial (Non Residential)', selected_survey.steps[current_step], 'assettype')">
                            <span class="checkcontainer" [ngClass]="selected_survey.steps[current_step].assettype == 'Commercial (Non Residential)' ? 'fw500' : ''">
                                Commercial (Non Residential)
                                <input type="radio" value="Commercial (Non Residential)" name="asset_type" [(ngModel)]="selected_survey.steps[current_step].assettype">
                                <span class="radiobtn"></span>
                            </span>
                        </div>

                        <div (click)="changeRadio('Residential', selected_survey.steps[current_step], 'assettype')">
                            <span class="checkcontainer" [ngClass]="selected_survey.steps[current_step].assettype == 'Residential' ? 'fw500' : ''">
                                Residential
                                <input type="radio" value="Residential" name="asset_type" [(ngModel)]="selected_survey.steps[current_step].assettype">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex w60p mb20" [ngInit]="enableFdatePicker()">
                    <div class="mr20 w60p" [ngClass]="error_year ? 'has-error' : ''">
                        <div class="form_label">
                            <span>1.7 </span>
                            <span class="fw500">Year Construction / Renovation began</span>    
                        </div>
                        <input [readOnly]="true" id="startyear" placeholder="Start Year" class="arc-input startyear year-inp" [(ngModel)]="selected_survey.steps[current_step].startyear"/>
                        <div *ngIf="error_year" class="error">
                            Please correct year inputs.
                        </div>
                    </div>
                    <div class="w60p" [ngClass]="error_year ? 'has-error' : ''">
                        <div class="form_label">
                            <span>1.8 </span>
                            <span class="fw500">Year Construction / Renovation Conclude</span>    
                        </div>
                        <input [readOnly]="true" id="endyear" placeholder="End Year" class="arc-input endyear year-inp" [(ngModel)]="selected_survey.steps[current_step].endyear"/>
                    </div>
                </div>
                <div class="flex w60p" [ngClass]="selected_survey.survey_type == 'existing_before_dec2020' || selected_survey.survey_type == 'existing_after_dec2020' ? 'mb20' : ''">
                    <div class="mr20 w60p">
                        <div class="form_label">
                            <span>1.9 </span>
                            <span class="fw500">Gross Floor Area ({{appData.get('unit_preference').gfa_unit}})</span>    
                        </div>
                        <input placeholder="{{appData.get('unit_preference').gfa_unit}}" class="arc-input" [(ngModel)]="selected_survey.steps[current_step].gross_area" [readOnly]="true"/>
                    </div>
                    <div class="w60p">
                        <div class="form_label">
                            <span>1.10 </span>
                            <span class="fw500">HVAC-effective rated output (kW)</span>    
                        </div>
                        <input placeholder="kW" class="arc-input" [(ngModel)]="selected_survey.steps[current_step].heating_system" inputType="float" />
                    </div>
                </div>

                <div class="flex w60p" [hidden]="!(selected_survey.survey_type == 'existing_before_dec2020' || selected_survey.survey_type == 'existing_after_dec2020')">
                    <div class="w50p">
                        <div class="form_label">
                            <span>1.11 </span>
                            <span class="fw500">Building permit application year</span>    
                        </div>
                        <input id="permityear" [readOnly]="true" placeholder="Permit Year" class="arc-input permityear year-inp" [(ngModel)]="selected_survey.steps[current_step].permityear" inputType="float" />
                    </div>
                </div>
            </div>

            <div class="content_survey" *ngIf="selected_survey.steps[current_step].type == 'basic_questionnaire'">
                <ng-container 
                    *ngFor="let question of selected_survey.steps[current_step].questions; let idx = index" 
                    [ngTemplateOutlet]="question_template" 
                    [ngTemplateOutletContext] ="{question: question, idx: idx, parent: null}">
                </ng-container>
            </div>

            <div class="content_survey" *ngIf="selected_survey.steps[current_step].type == 'basic_list'">
                <ul>
                    <li *ngFor="let question of selected_survey.steps[current_step].content; let idx = index" class="mb10 fs15 line_height_24">
                        {{question}}
                    </li>
                </ul>
            </div>
        </div>

    </div>
</div>

<ng-template let-question="question" let-idx="idx" let-parent="parent" #question_template>
    <div class="mb20" [ngClass]="question.heading != null && idx != 0 ? 'heading_separator' : ''">

        <div *ngIf="isValid(question, selected_survey.steps)">
            
            <div class="fs15 line_height_24 fw500 mb10" *ngIf="question.heading != null">
                {{question.heading}}
            </div>

            <div class="flex fw500">
                <span class="line_height_24 mr5">
                    {{current_step + 1}}.{{ idx + 1 }}{{question.option}}
                </span>
                <div class="fs15 line_height_24" [innerHTML]="question.text"></div>
            </div>
            
            <div class="radio_buttons group mb10">
                <div class="flex mt10">

                    <ng-container *ngFor="let option of question.options; let index = index;">
                        <div class="mr25"  *ngIf="option != 'na'">
                            <span (click)="changeRadio((option == 'yes'), question)" class="checkcontainer" [ngClass]="question.radio_value == (option == 'yes') ? 'fw500' : ''">
                                <span [innerHTML]="question.options_label[index]"></span>
                                <input type="radio" [value]="(option == 'yes')" name="{{question.id}}" [(ngModel)]="question.radio_value">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </ng-container>

                    <div *ngIf="question.options.indexOf('na') != -1">
                        <span (click)="changeRadio('na', question)" class="checkcontainer" [ngClass]="question.radio_value == 'na' ? 'fw500' : ''">
                            <span [innerHTML]="question.options_label[2]"></span>
                            <input type="radio" [value]="'na'" name="{{question.id}}" [(ngModel)]="question.radio_value">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="question.radio_value == true && question.upload_value != undefined" class="uploader mt15">
                <div class="upload_label_text" *ngIf="question.has_model">
                    <div class="flex">
                        <span class="pt5 mr10" [innerHTML]="question.upload_label_text_pre"></span>
                        <input inputType="float" placeholder="#" class="arc-input label_model" [(ngModel)]="question.upload_label_model"/>
                        <span class="pt5 ml10" [innerHTML]="question.upload_label_text_post"></span>
                    </div>
                </div>
                <div class="uploader_wrapper">
                    <input #elem (input)="fileSelected(elem, question)" class="hide upload_doc {{question.key}}" type="file">
                    <div class="left-right-align">
                        <div class="fs15 line_height_24 pt3" *ngIf="question.upload_label_text == undefined">
                            Support by uploading your document(s)
                        </div>
                        <div class="fs15 line_height_24 pt3" *ngIf="question.upload_label_text != undefined">
                           <span [innerHTML]="question.upload_label_text"></span>
                        </div>
                        <button class="btn btn-primary small fs12" (click)="selectFile(question.key)" [ngClass]="uploading ? 'not-active' : ''">
                            Upload
                        </button>
                    </div>
                    <div class="inline-100 mt10">
                        <div class="file_item" *ngFor="let file of question.upload_value;let idx = index;">
                            <span *ngIf="file.status == 'uploading'" class='uploadLoaderSpinner'></span>
                            <svg *ngIf="file.status != 'uploading'" (click)="removeFile(idx, question.upload_value)" width="10" height="10" viewBox="0 0 19 19" fill="none" data-dismiss="modal">
                                <path d="M18.5 2.31286L16.6871 0.5L9.5 7.68714L2.31286 0.5L0.5 2.31286L7.68714 9.5L0.5 16.6871L2.31286 18.5L9.5 11.3129L16.6871 18.5L18.5 16.6871L11.3129 9.5L18.5 2.31286Z" fill="#68737D"/>
                            </svg>
                            <div (click)="global.downloadFile(file)" class="fs15 line_height_24 file_name" [ngClass]="uploading && file.status == 'uploading' ? 'not-active' : ''">
                                {{global.getFileName(file)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="question.radio_value == false && question.textarea_value != false" class="no_explain mt15">
                <div class="fs15 line_height_24 mb10">Explanation:</div>
                <textarea [(ngModel)]="question.textarea_value" class="arc-input"></textarea>
            </div>
            
            <div class="mt20" *ngIf="question.children != null && question.radio_value">
                <ng-container *ngFor="let option of question.children" [ngTemplateOutlet]="question_template" 
                        [ngTemplateOutletContext] ="{question: option, idx: idx, parent: question}">
                </ng-container>
            </div>
        </div>

    </div>
</ng-template> 

<modal #new_survey>
    <div class="p1632 semi-lg">
        <div class="left-right-align mb40 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Select a Survey Type
            </div> 
            <div class="pt10 cursor-pointer" (click)="closeSurvey('new_survey')">
                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" data-dismiss="modal">
                    <path d="M18.5 2.31286L16.6871 0.5L9.5 7.68714L2.31286 0.5L0.5 2.31286L7.68714 9.5L0.5 16.6871L2.31286 18.5L9.5 11.3129L16.6871 18.5L18.5 16.6871L11.3129 9.5L18.5 2.31286Z" fill="#68737D"/>
                </svg>    
            </div>
        </div>

        <div *ngFor="let type of survey_template" class="left-right-align survey_option">
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{type.label}}</div>
                <div class="fs16 line_height_24">{{type.description}}</div>
            </div>
            <div>
                <button class="btn btn-primary line_height_24" (click)="chooseSurvey(type)">
                    Choose
                </button>
            </div>
        </div>
    </div>
</modal>

<modal #download_survey>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-lg">
        <div class="left-right-align mb40 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Which would you like to download?
            </div> 
            <div class="pt10 cursor-pointer" (click)="closeSurvey('download_survey')">
                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" data-dismiss="modal">
                    <path d="M18.5 2.31286L16.6871 0.5L9.5 7.68714L2.31286 0.5L0.5 2.31286L7.68714 9.5L0.5 16.6871L2.31286 18.5L9.5 11.3129L16.6871 18.5L18.5 16.6871L11.3129 9.5L18.5 2.31286Z" fill="#68737D"/>
                </svg>    
            </div>
        </div>

        <div *ngFor="let type of download_options" class="left-right-align survey_option">
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{type.label}}</div>
                <div class="fs16 line_height_24 pr20">{{type.description}}</div>
            </div>
            <div>
                <button class="btn btn-primary line_height_24" (click)="downloadSurvey(type.type)" [ngClass]="type.type == 'documentation' && feed_report.length > 0 ? 'not-active' : ''">
                    {{
                        type.type == 'documentation' && feed_report.length > 0 ? 'Creating Report' : 'Download'
                    }}
                </button>
            </div>
        </div>
    </div>
</modal>

<modal #check_survey>
    <div class="p1632 semi-lg" *ngIf="selected_survey != null">
        <div class="left-right-align mb40 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Survey Submission Check
            </div> 
            <div class="pt10 cursor-pointer" (click)="closeSurvey('check_survey')">
                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" data-dismiss="modal">
                    <path d="M18.5 2.31286L16.6871 0.5L9.5 7.68714L2.31286 0.5L0.5 2.31286L7.68714 9.5L0.5 16.6871L2.31286 18.5L9.5 11.3129L16.6871 18.5L18.5 16.6871L11.3129 9.5L18.5 2.31286Z" fill="#68737D"/>
                </svg>    
            </div>
        </div>

        <div *ngFor="let type of selected_survey.steps;let idx = index;" class="left-right-align mb40 survey_option" #itr>
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{type.label}}</div>
                <div class="fs16 line_height_24 survey_status">{{getStatus(type, itr)}}</div>
            </div>
            <div [hidden]="type.type == 'basic_list'">
                <button class="btn btn-primary line_height_24" (click)="changeStep(idx)">
                    Edit
                </button>
            </div>
        </div>

        <div class="flex mt30 mb20">
            <button *ngIf="mode == 'submit'" class="btn btn-primary line_height_24 mr20" (click)="saveSurvey(true)" [ngClass]="errors || loading ? 'not-active' : ''">
                Submit Survey
            </button>
            <button *ngIf="mode == 'save'" class="btn btn-primary line_height_24 mr20" (click)="saveSurvey(false)" [ngClass]="loading ? 'not-active' : ''">
                Save
            </button>
            <button class="btn btn-default line_height_24" (click)="closeSurvey('check_survey')">
                Cancel
            </button>
        </div>
    </div>
</modal>
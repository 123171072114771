import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';

import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.css']
})
export class LearnMoreComponent implements OnInit
{
	config = Config;
	mode = Config.state('mode');

	constructor(
		public global: Global,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		if((this.mode == undefined || this.mode == 'arc_pro'))
		{
		  this.mode = 'arc_pro';
		}
		else
		{
		  this.mode = 'certificate';
		}
	}

}

<div id="portfolio_component" class="content-wrap" [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
    <div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Team</span>
        </h1>
        
        <div>
            <div class="row manage_teams">
                <div class="col-lg-8 pl0">
                    <section class="widget">
                        <div class="no-footer">	
                            <table class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th class="">Email</th>
                                        <th class="hidden-xs">Access</th>
                                        <th class="hidden-xs no-sort">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let team_member of team_members_port; let idx = index;">
                                        <td class="vertical-align-middle">
                                            {{team_member.username}}
                                        </td>
                                        <td class="vertical-align-middle" *ngIf="!makeEditable(team_member)">{{getPermission(team_member.permission)}}</td>
                                        <td class="vertical-align-middle" *ngIf="makeEditable(team_member)">
                                            <div class="dropdown display-inline">
                                                <button class="btn dropdown-toggle btn-sm" type="button" data-toggle="dropdown" >
                                                <span>{{getPermission(team_member.permission)}}</span>
                                                <span class="caret"></span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li (click)="changePortAccess('can_read', team_member)"  class="energy_unit"><a>Can Read</a></li>
                                                    <li (click)="changePortAccess('can_edit', team_member)"  class="energy_unit"><a>Can Edit</a></li>
                                                    <li (click)="changePortAccess('none', team_member)"  class="energy_unit"><a>None</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td [ngClass]="appData.get('is_admin') ? 'not-active' : ''" class="no-sort vertical-align-middle">
                                            <div class="edit_buttons">
                                                <button [ngClass]="{'not-active': config.portfolio_access == 'can_read'}" *ngIf="!makeEditable(team_member)" type="button" class="btn btn-default btn-sm" (click)="editRole(team_member)">Edit</button>
                                                <button *ngIf="makeEditable(team_member)" type="button" class="btn btn-default btn-sm" (click)="saveRole(team_member, team_member)">{{save_role_msz}}</button>
                                            </div>
                                        </td>
                                    </tr>			    
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
                <div class="col-lg-3 team_invite">
                    <section class="">
                        <div class="">
                            <h4 class="mt0">Invite team members</h4>
                               <form name="addteamForm" class="row" >
                                   
                                <div [ngClass]="{'not-active': config.portfolio_access == 'can_read'}" id="details-form">
                                    <div class="group pb0 mb25" [ngClass]="error_add_team_flag ? 'has-error' : ''">      
                                        <input (input)="checkError()" maxlength="150" [(ngModel)]="new_email" name="input" type="email" required>
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label>Enter email address</label>
                                        <span class="error">
                                            <p *ngIf="error_add_team_flag">{{error_add_team_msz}}</p>
                                        </span>
                                    </div>
                                </div>
        
                                <div [ngClass]="{'not-active': config.portfolio_access == 'can_read'}">
                                       <button id="invite_team" class="btn btn-success pr5 pl5" [ngClass]="appData.get('is_admin') || error_add_team_flag ? 'not-active' : ''" (click)="addMemberToTeam()">{{add_team_msz}}</button>
                                   </div>   
                                                             
                               </form>
                               <p class="help-block">Note: Adding a collaborator gives them access to view the project list within the Portfolio, including the current score of each project.</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        
        <div class="delte_user_port modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-sm" style="width: 460px;">
                <div class="modal-content p50 modal_radius">
                    <p class="align-center">You won't be able to access this portfolio.</p>
                    <div class="mt30 align-center"> 
                        <button class="btn action-button btnGreen mr15" (click)="removeUser()" >Ok</button>
                        <button class="btn action-button btnGreen" data-dismiss="modal" >Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

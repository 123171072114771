<div [ngClass]="loading ? 'not-active' : ''" class="team_search" id="myproject_body">
    <app-loader *ngIf="loading"></app-loader>
    <div class="left-right-align pb15 custom_header">
        <div class="fs24 line_height_40 fw-500" translate>
            Manage programs
        </div> 
    </div>

    <div class="custom_body">
        <ul class="meterNav mb15 flex">
            <li (click)="toggleTab('1. Select programs')" class="w33p meterNav--item">
                <span class="meterNav--item__active fw-500" translate>1. Select programs</span>
            </li>
            <li (click)="toggleTab('2. Add/Remove program')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' || current_tab == '3. Select projects' || current_tab == '2. Add/Remove program' ? 'meterNav--item__active fw-500' : ''" translate>2. Select action</span>
            </li>
            <li (click)="toggleTab('3. Select projects')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' || current_tab == '3. Select projects' ? 'meterNav--item__active fw-500' : ''" translate>3. Select projects</span>
            </li>
            <li (click)="toggleTab('4. Summary')" class="w33p meterNav--item">
                <span [ngClass]="current_tab == '4. Summary' ? 'meterNav--item__active fw-500' : ''" translate>4. Summary</span>
            </li>
        </ul>
        <div *ngIf="current_tab == '1. Select programs'">
            <div class="w100p manage_note pl0 mb15">
                Select program(s) which you want to add or remove from projects
            </div>
            <div class="row no-margin-15">
                <div class="col-md-6" *ngFor="let program of selected_program">
                    <span class="checkcontainer mb10 mt10 as_radio_border" (click)="addRemoveProgram(program, $event)">
                        {{program.name}}
                        <i class="advanced_scoring_learn_more_sc mtn2" *ngIf="program.learn_more_link"> 
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                        </i>
                        <input type="checkbox" [(ngModel)]="program.selected" [value]="true" attr.program_id="{{program.id}}" class="removeOpacity l12">
                        <span class="top2 relative-position"></span>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="current_tab == '2. Add/Remove program'">
            <div class="w100p manage_note pl0 mb15">
                Select whether you want to add or remove the selected programs from project(s):
            </div>
            <div class="radio_buttons mb30" style="display: inline-block;">
                <div class="flex mt8">
                    <div class="mr25">
                        <span class="checkcontainer" [ngClass]="operation ? 'fw-500' : ''" (click)="toggleType(true)">
                            Add to project
                            <input type="radio" name="operation" [value]="true" [(ngModel)]="operation">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
        
                    <div>
                        <span class="checkcontainer" [ngClass]="operation == false ? 'fw-500' : ''" (click)="toggleType(false)">
                            Remove from project
                            <input type="radio" name="operation" [value]="false" [(ngModel)]="operation">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="current_tab == '3. Select projects'">
            <div class="w100p manage_note pl0 mb15">
                Select projects for which you want to {{operation ? 'add programs to:' : 'remove programs from:'}}
            </div>
            <app-projects (project_selection)="getSelectedProjects($event)" [type]="'teams_list'"></app-projects>
        </div>
        <div *ngIf="current_tab == '4. Summary'">
            <div>
                <div class="w100p black_color_28323a fs15 pl0 mb10 floatl w100p">
                    Selected program(s):
                </div>
                <div class="user_tab_wrapper floatl w100p mb20 pb10">
                    <div class="user_select_team mr20" *ngFor="let program of final_selected_program">
                        <span>{{program.name}}</span>
                    </div>
                    <span class="edit_sel">
                        <a (click)="toggleTab('1. Select programs')">Edit</a>
                    </span>
                </div>
            </div>
            <div>
                <div class="w100p black_color_28323a fs15 user_tab_wrapper mb20 pb20 pl0 floatl">
                    <div>
                        Selected action: <span class="fw-only-600">{{operation ? 'Add to project' : 'Remove from project'}}</span>
                    </div>
                    <div>
                        <span class="edit_sel">
                            <a (click)="toggleTab('2. Add/Remove program')">Edit</a>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div class="w100p black_color_28323a fs15 pl0 mb10 floatl w100p">
                    Selected project(s):
                </div>
                <div class="user_tab_wrapper floatl w100p mb20 pb10">
                    <div class="user_select_team mr20" *ngFor="let project of selected_projets">
                        <span>{{project}}</span>
                    </div>
                    <span class="edit_sel">
                        <a (click)="toggleTab('3. Select projects')">Edit</a>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="mt15 custom_footer">
        <button *ngIf="current_tab == '1. Select programs'" (click)="cancel()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
        <button *ngIf="current_tab != '1. Select programs'" (click)="goBack()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Back</button>
        <button *ngIf="current_tab != '4. Summary'" [ngClass]="(final_selected_program.length == 0 && current_tab == '1. Select programs') || (operation == null && current_tab == '2. Add/Remove program') || (selected_projets.length == 0 && current_tab == '3. Select projects') ? 'not-active' : ''" (click)="continueProgram()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">
            Continue
            <span class="arrow_next"></span>
        </button>
        <button *ngIf="current_tab == '4. Summary'" [ngClass]="final_selected_program.length == 0 ? 'not-active' : ''" (click)="saveProgram()" data-dismiss="modal" type="button" class="btn btn-primary btn-md">
            Save
        </button>
    </div>
</div>
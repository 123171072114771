import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppData } from '../services/appdata.service';

@Component({
  selector: 'app-selected-portfolio',
  templateUrl: './selected-portfolio.component.html',
  styleUrls: ['./selected-portfolio.component.css']
})
export class SelectedPortfolioComponent implements OnInit
{

	constructor(
		public appData: AppData,
		private route: ActivatedRoute
	)
	{
		this.route.params.subscribe((params) =>
		{
			this.appData.set('portfolio_id', params['portfolio_id']);
			this.appData.set('app_name', params['app_name']);
			this.appData.set('current_app', "selected_portfolio");
		});
	}

	ngOnInit(): void
	{

	}

}

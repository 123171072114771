<app-sidebar *ngIf="type =='projects'" [sidebar_type]="'projects'" (sidebar_selection)="getProjects(1, 30, (sort_order + sort_column))"></app-sidebar>

<div class="content-wrap" [ngClass]="type != 'projects' ? 'compact' : ''">
	<div id="content" class="content" [ngClass]="type != 'projects' ? 'compact' : ''">
		
		<div id="myproject_body">
			<div class="projectListWrapper">

				<div class="mb5 relative-position">
					<button (click)="global.slideToggle('project_filters', 300)" class="show_filters btn btn-default btn-md gray_button btn-border">
						Show Filters
						<span *ngIf="total_filters > 0" class="blue-color fs12">({{total_filters}})</span>
					</button>
					<div id="project_filters" class="display_none">
						<div class="project_filter_header">
							<div class="fw-500">Filter by</div>
							<div>
								<a (click)="resetFilters()">Clear all filters</a>
							</div>
						</div>
						<div class="project_filter_item">
							<div class="country_filter relative-position mr25">
								<div class="country_name_filter country_name_anchor align_lr w250px dropdown-toggle"  data-toggle="dropdown">
									<span class="country_name_anchor" *ngIf="selected_country.length == 0">Country</span>
									<span *ngIf="selected_country.length > 0" class="country_name_anchor ellipsis_80">
										{{selected_country_name.join(", ")}}
									</span>
									<span *ngIf="selected_country.length > 0" class="country_name_anchor blue-color fs12">({{selected_country.length}})</span>
									<i class="fs16 fas fa-angle-down country_name_anchor"></i>
								</div>
								<div id="country-list" class="user-list country-list dropdown-menu">
									<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let country of country_list" (click)="selectCountry(country)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input name="{{country.code}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{country.name}}
											</label>
										</div>
									</div>
								</div>
							</div>
		
							<div *ngIf="state_list.length == 0 && selected_country.length == 0" class="dropdown dropdown_v2 w250px">
								<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
									<span class="mr20">Select country to view states</span>
								</div>
							</div>
							<div *ngIf="state_list.length == 0 && selected_country.length == 1" class="dropdown dropdown_v2 w250px">
								<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
									<span class="mr20">Not Available</span>
								</div>
							</div>
		
							<div *ngIf="state_list.length > 0 && selected_country.length == 1" class="state_filter relative-position">
								<div class="state_name_filter align_lr dropdown-toggle"  data-toggle="dropdown">
									<span *ngIf="selected_state.length == 0">State</span>
									<span *ngIf="selected_state.length > 0" class="ellipsis_80">
										{{selected_state_name.join(", ")}}
									</span>
									<span *ngIf="selected_state.length > 0" class="blue-color fs12">({{selected_state.length}})</span>
									<i class="fs16 fas fa-angle-down"></i>
								</div>
								<div id="state-list" class="user-list state-list dropdown-menu">
									<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let state of state_list" (click)="selectState(state)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input ng-model="state.select_all" name="{{state.code}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{state.name}}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
		
						<div class="project_filter_item">
							<div class="spaceType_filter relative-position mr25">
								<div class="space_type_name_filter align_lr dropdown-toggle" data-toggle="dropdown">
									<span *ngIf="selected_space_type.length == 0">Space Type</span>
									<span *ngIf="selected_space_type.length > 0" class="ellipsis_80">
										{{selected_space_type.join(", ")}}
									</span>
									<span *ngIf="selected_space_type.length > 0" class="blue-color fs12">({{selected_space_type.length}})</span>
									<i class="fs16 fas fa-angle-down"></i>
								</div>
								<div class="user-list spaceType-list dropdown-menu">
									<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let space of spaceType" (click)="selectSpaceType(space)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input name="{{space}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{space}}
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="buildingStatus_filter relative-position">
								<div class="building_status_filter align_lr dropdown-toggle" data-toggle="dropdown">
									<span *ngIf="selected_building_status.length == 0">Status</span>
									<span *ngIf="selected_building_status.length > 0" class="ellipsis_80">
										{{selected_building_status.join(", ")}}
									</span>
									<span *ngIf="selected_building_status.length > 0" class="blue-color fs12">({{selected_building_status.length}})</span>
									<i class="fs16 fas fa-angle-down"></i>
								</div>
								<div class="user-list building_status-list dropdown-menu">
									<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let status of building_status" (click)="selectStatus(status)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input name="{{status}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{status}}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="project_filter_item">
							<div class="cert_filter relative-position mr25">
								<div class="cert_name_filter cert_name_filter_anchor align_lr dropdown-toggle" data-toggle="dropdown">
									<span *ngIf="selected_certification.length == 0" class="cert_name_filter_anchor">Certification</span>
									<span *ngIf="selected_certification.length > 0" class="cert_name_filter_anchor ellipsis_80">
										{{selected_certification.join(", ")}}
									</span>
									<span *ngIf="selected_certification.length > 0" class="cert_name_filter_anchor blue-color fs12">({{selected_certification.length}})</span>
									<i class="fs16 fas fa-angle-down cert_name_filter_anchor"></i>
								</div>
								<div class="user-list cert_name_filter-list dropdown-menu">
									<div [ngClass]="appData.get('project_type') == 'certified' && (cert == 'Not-Certified' || cert == 'Pre-Certified') ? 'not-active' : ''" class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let cert of certifications" (click)="selectCert(cert)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input name="{{cert}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{cert}}
											</label>
										</div>
									</div>
								</div>
							</div>

							<div class="relative-position">
								<div class="tags_filter tags_filter_anchor align_lr dropdown-toggle" data-toggle="dropdown">
									<span *ngIf="selected_tags.length == 0" class="tags_filter_anchor">Tags</span>
									<span *ngIf="selected_tags.length > 0" class="tags_filter_anchor ellipsis_80">
										{{selected_tags.join(", ")}}
									</span>
									<span *ngIf="selected_tags.length > 0" class="tags_filter_anchor blue-color fs12">({{selected_tags.length}})</span>
									<i class="fs16 fas fa-angle-down tags_filter_anchor"></i>
								</div>
								<div class="user-list tags-list dropdown-menu">
									<div *ngIf="building_tags.length == 0">
										<div class="fs14 line_height_24 mb5">Tags not found</div>
									</div>
									<div *ngIf="building_tags.length > 0">
										<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let tag of building_tags" ng-click="selectTags(tag)">
											<div class="checkbox mr5 blue_check ck_gray_border">
												<input name="{{tag}}" type="checkbox">                
												<label class="line_height_18" for="checkbox">
													{{tag}}
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
		
						<div class="project_filter_item">
							<div class="mr25">
								<button (click)="changeFilter(1, page_size, sort_order + sort_column)" class="btn btn-primary btn-md">
									Apply
								</button>
							</div>
							<div>
								<button class="btn btn-default btn-md inverse close_filter" (click)="global.slideToggle('project_filters', 300)">
									Cancel
								</button>
							</div>
						</div>
						
					</div>
				</div>

				<div class="flex">
					<div class="checkbox mr5 blue_check select_all">
						<input (change)="toggleCheck('all')" [(ngModel)]="select_all" name="select_all" type="checkbox" value="select_all">                
						<label for="checkbox"></label>
					</div>
					<div class="project_list_status">
						<span class="fw-500">Showing: </span>
						<span class="fw600">{{myprojects.length}} of {{global.addCommas(count)}} Projects</span>
					</div>
					<div class="relative-position" *ngIf="type == 'projects'">
						<button *ngIf="selected_projects.length > 0" class="add_to_portfolio ml15 btn btn-default btn-md gray_button btn-border pl40 mt10 dropdown-toggle" data-toggle="dropdown" translate>
							<img class="plus_icon" src="../../../assets/images/plus_grey.svg">
							Add to Portfolio
						</button>
						<div id="portfolio_filters" class="dropdown-menu">
							<div class="project_filter_header">
								<div class="fw-500">Select a portfolio</div>
								<div>
									<a (click)="createPortfolio()">Create a new portfolio</a>
								</div>
							</div>
		
							<div class="project_filter_header">
								<div id="portfolio_list" class="user-list name-list" (scroll)="onWindowScroll($event)">
									<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let port of portfolios" (click)="selectPortfolio(port)">
										<div class="checkbox mr5 blue_check ck_gray_border">
											<input name="{{port.pf_id}}" type="checkbox">                
											<label class="line_height_18" for="checkbox">
												{{port.name}} <span class="fs13 gray_color_707479 pt2">{{port.pf_id}}</span>
											</label>
										</div>
									</div>
								</div>
							</div>
		
							<div [ngClass]="selected_portfolios.length == 0 ? 'not-active' : ''" class="project_filter_item">
								<div class="mr25">
									<button (click)="addToPortfolio()" class="btn btn-primary btn-md">
										Add
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="" [ngClass]="project_loader ? 'not-active' : ''">
					<app-loader *ngIf="project_loader"></app-loader>
					<table class="table table-striped arc-table">
						<thead>
							<tr>
								<th class="unselectable"></th>
								<th class="unselectable cursor-pointer" (click)="sortList(sort_order, 'leed_id')">
									<span translate>ID</span>
									<span *ngIf="sort_column == 'leed_id'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="unselectable cursor-pointer" (click)="sortList(sort_order, 'name')">
									<span translate>Name</span>  
									<span *ngIf="sort_column == 'name'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="hidden-xs unselectable cursor-pointer" (click)="sortList(sort_order, 'city')">
									<span translate>Address</span>
									<span *ngIf="sort_column == 'city'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="hidden-xs unselectable cursor-pointer" (click)="sortList(sort_order, 'project_type')">
									<span translate>Type</span><span *ngIf="sort_column == 'project_type'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="unselectable cursor-pointer" (click)="sortList(sort_order, 'certification')">
									<span translate>Certification</span>
									<span *ngIf="sort_column == 'certification'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="hidden-xs unselectable cursor-pointer" (click)="sortList(sort_order, 'total_score')">
									<span translate>Score</span>
									<span *ngIf="sort_column == 'total_score'" class="{{sort_order == '-' ? 'icon-caret-down': 'icon-caret-up'}}"></span>
								</th>
								<th class="hidden-xs" *ngIf="appData.get('is_user_gbci_usgbc_admin')">Confidential</th>
							</tr>
						</thead>
						<tbody>
				
							<tr *ngFor="let project of myprojects; let idx = index" class="project_row" [ngClass]="{'not-active' : (accept_agreement_flag || loading)}" id="{{project.leed_id}}">
								<td (click)="toggleCheck(idx)">
									<div class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
										<input [(ngModel)]="project.select_all" name="{{project.leed_id}}" type="checkbox">                
										<label for="checkbox"></label>
									</div>
								</td>
								<td class="vertical-align-middle cursor-pointer" (click)="global.moveToProject(project, idx)" >
									<span>{{project.leed_id}}
									</span>
									<div *ngIf="idx == myprojects.length - 1" ng-init="tippyClassInit('member_tippy')"></div>
								</td>
								<td class="vertical-align-middle cursor-pointer" (click)="global.moveToProject(project, idx)" 
								>
									<div class="pull-left ellipsis_v2">
										<span ng-mouseover="insertTippy($event)" class="fw600">{{project.name}}</span>
									</div>                               	
								</td>
								<td class="hidden-xs vertical-align-middle cursor-pointer" (click)="global.moveToProject(project, idx)" 
								>
									<div class="pull-left ellipsis_v2">
										<span *ngIf="project.state == ''" ng-mouseover="insertTippy($event)">{{project.city}}, {{global.getCountry(project.country)}}</span>
										<span *ngIf="project.state != ''" ng-mouseover="insertTippy($event)">{{project.city}}, {{global.getState(project.state, project.country)}}, {{global.getCountry(project.country)}}</span>
									</div>                              	
								</td>
								<td class="hidden-xs vertical-align-middle cursor-pointer" (click)="global.moveToProject(project, idx)" >
									<span>{{global.capitalFirstLetter(project.project_type)}}</span>
								</td>
								<td  class="{{'project-row-'+idx}} vertical-align-middle cursor-pointer">
									<app-certification-tag (click)="global.moveToProject(project, idx)" [project]="project"></app-certification-tag>
									<a class="fontSize13 ml5" (click)="global.showCertDetails(project)" *ngIf="global.isCertDetailsReq(project.certifications)">Details</a>
								</td>
								<td class="hidden-xs vertical-align-middle cursor-pointer" [hidden]="!(project.project_type.toLowerCase() == 'city' || project.project_type.toLowerCase() == 'community' || project.project_type.toLowerCase() == 'parksmart')" style="padding: 15px  !important;">
									-
								</td>
								<td class="hidden-xs vertical-align-middle cursor-pointer" [hidden]="project.project_type.toLowerCase() == 'city' || project.project_type.toLowerCase() == 'community' || project.project_type.toLowerCase() == 'parksmart'">
									<div attr.project_type="{{project.project_type}}" id="{{project.leed_id}}_puck" attr.index="{{idx}}" (click)="global.moveToProject(project.building, idx)"  *ngIf ='!project.is_gresb && (project.certification == "" || project.certification == "Denied" || project.certification == "None" || project.certification == null || project.certification == "pre-certified")' class="smallScorepuckBlank">
										<span>{{global.getProjectScore(project)}}</span>
									</div>
									<div attr.project_type="{{project.project_type}}" id="{{project.leed_id}}_puck" attr.index="{{idx}}" (click)="global.moveToProject(project.building, idx)"  *ngIf = '!project.is_gresb && (!(project.certification == "" || project.certification == "Denied" || project.certification == "None" || project.certification == null || project.certification == "pre-certified"))' class="smallScorepuck">
										<span>{{global.getProjectScore(project)}}</span>
									</div>
				
									<div *ngIf="project.is_gresb">
										<div (click)="openEstimateModal(project)" class="btn btn-default arc-btn arc-btn-sm" *ngIf="project.estimated_data_color != 'None' && project.estimated_data_color != 'green'">
											<span class="fontSize13 fw-400">Estimated: </span><span class="ml5 fw600">{{global.getProjectScore(project)}}</span>
										</div>
										<div *ngIf="project.estimated_data_color == 'None' || project.estimated_data_color == 'green'" [ngClass]='project.certification == "" || project.certification == "Denied" || project.certification == "None" || project.certification == null || project.certification == "pre-certified" ? "smallScorepuckBlank" : "smallScorepuck"'>
											<span>{{global.getProjectScore(project)}}</span>
										</div>
									</div>
								</td>
								<td *ngIf="appData.get('is_user_gbci_usgbc_admin')" class="hidden-xs vertical-align-middle cursor-pointer" (click)="global.moveToProject(project, idx)" >
									<span *ngIf="project.confidential">Yes</span>
									<span *ngIf="!project.confidential">No</span>
								</td>
							</tr>		    
						</tbody>
					</table>
					<div class="noProjects" *ngIf="myprojects.length == 0 && !project_loader">
						Hello, Can't see your building, community or city? 
						<a (click)="global.openEmail('contact@arcskoru.com')">Email </a> us the name or address and we will get back to you.
					</div>
				
					<div class="align_r list_controls fw-500" *ngIf="myprojects.length > 0">
						<div class="dropdown display-inline ml10">
							<span class="mr10"><span translate>Rows per page</span>:</span>
							<button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
								<span>{{page_size}}</span>
								<i class="fa fontSize16 ml10 fa-caret-down"></i>
							</button>
							<ul class="dropdown-menu" style="top: -122px;">
								<li (click)="getProjects(1, 10, ordering)">
									<a>10</a>
								</li>
								<li (click)="getProjects(1, 20, ordering)">
									<a>20</a>
								</li>
								<li (click)="getProjects(1, 30, ordering)">
									<a>30</a>
								</li>
								<li (click)="getProjects(1, 40, ordering)">
									<a>40</a>
								</li>
								<li (click)="getProjects(1, 50, ordering)">
									<a>50</a>
								</li>
							</ul>
						</div>
						<div class="flex pt10 ml40">
							<span class="mr30">
								Page {{page}} of {{page_count}}
							</span>
							<div class="flex">
								<div (click)="getProjects(page - 1, page_size, (sort_order + sort_column))" [ngClass]="(page - 1) == 0 ? 'not-active' : ''">
									<i class="mr40 fa fa-chevron-left"></i>
								</div>
								<div (click)="getProjects(page + 1, page_size, (sort_order + sort_column))" [ngClass]="page == page_count ? 'not-active' : ''">
									<i class="fa fa-chevron-right"></i>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div [ngClass]="project_loader ? 'not-active' : ''" id="bottom_nav" class="bottom_nav display_none">
				<div class="flex">
					<div class="fw-500 fs16 mr15 line_height_35">
						{{selected_projects.length == 0 ? 'No' : selected_projects.length}} {{selected_projects.length == 1 ? 'project' : 'projects'}} selected
					</div>
					<div class="mr15" *ngIf="type == 'portfolio_list'">
						<button class="btn btn-primary relative-position" (click)="addToPortfolio()">
							<div class="fs15 fw-500">Add projects</div>
						</button>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>

<modal #modal_estimate>
    <app-estimate (close)="cancel()" [project]="project_selected"></app-estimate>
</modal>
import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from 'src/app/services/global.service';
import { AppData } from 'src/app/services/appdata.service';

@Component({
  selector: 'app-return-review',
  templateUrl: './return-review.component.html',
  styleUrls: ['./return-review.component.css']
})
export class ReturnReviewComponent implements OnInit {
  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  leed_id: any = '';

  loader: boolean = false;
  downloading: boolean = false;
  review_data: any = [];
  leed_id_snapshot: any = "";
  sorted_data: any = [];
  filtered_data: any = [];
  projectType: string = '';

  constructor(
    private api: API,
    public appData: AppData,
    private alertService: alertService,
    public global: Global
  ) { }

  checkInputsCert() {
    if (isNaN(this.leed_id)) {
      this.checkInputCert = false;
    }
    else {
      this.checkInputCert = true;
    }
  };
  
  returnReview() {
    this.loaderCert = true;
    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
    {
      this.projectType = 'assets/property:';
    }
    else if (this.leed_id > '4900000000' && this.leed_id < '6000000000')
    {
      this.projectType = 'project/';
    }
    else
    {
      this.projectType = 'assets/LEED:';
    }
    this.api.get('/' + this.projectType + this.leed_id + '/review/return/').subscribe(
      data => {
        this.loaderCert = false;
        this.alertService.alert('success', data.message, 5);
      },
      error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
      }
    );
  }

  getReviewDataFromS3() {
    this.filtered_data = [];
    this.review_data = [];
    if (this.leed_id_snapshot == "") {
      return false;
    }

    this.loader = true;

    if (this.leed_id_snapshot > '3900000000' && this.leed_id_snapshot < '5000000000' )
    {
      this.projectType = 'assets/property:';
      
    }

    else if (this.leed_id_snapshot > '4900000000' && this.leed_id_snapshot < '6000000000')
    {
      this.projectType = 'project/';
    }
    
    else
    {
      this.projectType = 'assets/LEED:';
    }

    this.api.get('/' + this.projectType + this.leed_id_snapshot + '/review/snapshot/keys/').subscribe(
      data => {
        this.loader = false;
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].document_metadata == null && data[i].doc_category == null && data[i].doc_description == null) {
              var name = data[i].doc_key.split('/')[1];
              if (data[i].doc_key.split('/')[1] != data[i].doc_key.split('/')[2]) {
                name = data[i].doc_key.split('/')[1] + ' (' + data[i].doc_key.split('/')[2] + ')';
              }
              var uploaded_at = data[i].uploaded_at;
              if (name.indexOf('LEED Certification') > -1) {
                this.loader = true;
                this.api.get('/' + this.projectType + this.leed_id_snapshot + '/certifications/registration/').subscribe(
                  data => {
                    if (data.length > 0) {
                      this.review_data.push({ "name": data[0].rating_system, "last_modified": uploaded_at })
                    }
                    else {
                      this.review_data.push({ "name": name, "last_modified": uploaded_at })
                    }
                    this.loader = false;
                    this.filtered_data = this.removeDuplicates(this.review_data, "name");
                  },
                  error => {
                    this.loader = false;
                    this.review_data.push({ "name": name, "last_modified": uploaded_at });
                    this.filtered_data = this.removeDuplicates(this.review_data, "name");
                  }
                );
              }
              else {
                this.review_data.push({ "name": name, "last_modified": uploaded_at })
              }
            }
          }
        }
        this.filtered_data = this.removeDuplicates(this.review_data, "name");
      },
      error => {
        this.loader = false;
      }
    );
  };

  removeDuplicates(originalArray: any, prop: any) {
    var newArray = [];
    var lookupObject: any = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  downloadKey(data: any) {
    this.global.anchorDownload(data.name + ".zip", Config.basic_api_url + '/' + this.projectType + this.leed_id_snapshot + '/review/snapshot/?access-token=' + this.appData.get('token') + '&subscription-key=' + Config.sub_key);
  };

  ngOnInit(): void {
  }

}


<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-8">
            <h1 class="page-title pl0 pb10">Partial Maintenance<span class="fw-semi-bold"></span></h1>
            <section class="widget pt30" [ngClass]="loading ? 'not-active' : ''">
                <app-loader *ngIf="loading"></app-loader>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="radio_buttons group pt5 pb45">
                                <span class="head fs16 lh30">Partial Maintenance Mode:</span>
                                <div class="flex mt30">
                                    <div class="mr40">
                                        <span class="checkcontainer" (click)="updateMaintenanceMode(true)">
                                            ON
                                            <input type="radio" name="mode_user" [value]="'ON'" [(ngModel)]="mode_user">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>

                                    <div>
                                        <span class="checkcontainer" (click)="updateMaintenanceMode(false)">
                                            OFF
                                            <input type="radio" name="mode_user" [value]="'OFF'" [(ngModel)]="mode_user">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
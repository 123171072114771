<div class="content-wrap">
	<div id="content" class="content">
        
        <div *ngIf="is_projects_available == true" [ngClass]="loading ? 'not-active' : ''">
            <app-loader *ngIf="loading"></app-loader>
            <div class="align_lr">
                <h3 class="fw-500 black-color pl15 mb25 pt5" translate>
                    Performance
                </h3>
                <div class="mt20 pt5 mr15 flex relative-position">
                    <div class="flex mt5 mr25">
                        <span class="mr5 gray_color_68737D" translate>How scores and metrics work</span>
                        <span (click)="global.showHelpText('total_leed_arc')" class="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
                        </span>
                    </div>
                    <app-performance-date-picker class="mt5" [mode]="'portfolio'"></app-performance-date-picker>
                </div>
            </div>
        
            <div class="home_tab mt0">
                <div translate (click)="goToTab('Summary')" [ngClass]="current_tab == 'Total' || current_tab == 'Summary' || current_tab == 'Project Scores' ? 'active' : ''" class="tab_item">
                    Total
                </div>
                <div translate (click)="goToTab('Energy')" [ngClass]="current_tab == 'Energy' ? 'active' : ''" class="tab_item energy">
                    Energy
                </div>
                <div translate (click)="goToTab('Water')" [ngClass]="current_tab == 'Water' ? 'active' : ''" class="tab_item water">
                    Water
                </div>
                <div translate (click)="goToTab('Waste')" [ngClass]="current_tab == 'Waste' ? 'active' : ''" class="tab_item waste">
                    Waste
                </div>
                <div translate (click)="goToTab('Transportation')" [ngClass]="current_tab == 'Transportation' ? 'active' : ''" class="tab_item transport">
                    Transportation
                </div>
                <div translate (click)="goToTab('Human Experience')" [ngClass]="current_tab == 'Human Experience' ? 'active' : ''" class="tab_item humanexperience">
                    Human Experience
                </div>
                <div translate (click)="goToTab('Emissions')" [ngClass]="current_tab == 'Emissions' ? 'active' : ''" class="tab_item">
                    Emissions
                </div>
                <div translate (click)="goToTab('Re-Entry')" [ngClass]="current_tab == 'Re-Entry' ? 'active' : ''" class="tab_item reentry">
                    Re-Entry
                </div>
            </div>
        
            <div class="home_tab home_tab_white" *ngIf="current_tab == 'Total' || current_tab == 'Summary' || current_tab == 'Project Scores'">
                <div translate (click)="goToTab('Summary')" [ngClass]="current_tab == 'Summary' ? 'active' : ''" class="tab_item water">
                    Summary
                </div>
                <div translate (click)="goToTab('Project Scores')" [ngClass]="current_tab == 'Project Scores' ? 'active' : ''" class="tab_item water">
                    Project Scores
                </div>
            </div>
        
            <div class="home_tab_dropdown">
                <div class="dropdown dropdown_v2">
                    <div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
                        <span class="mr20">{{current_tab}}</span>
                        <i class="toggle fa fa-angle-down"></i>
                    </div>
                    <ul class="dropdown-menu left">
                        <li (click)="goToTab('Scores')" [ngClass]="current_tab == 'Scores' ? 'active' : ''">
                            <a translate>
                                Scores
                            </a>
                        </li>
                        <li (click)="goToTab('Projects')" [ngClass]="current_tab == 'Projects' ? 'active' : ''">
                            <a translate>
                                Projects
                            </a>
                        </li>
                        <li (click)="goToTab('Emissions')" [ngClass]="current_tab == 'Emissions' ? 'active' : ''">
                            <a translate>
                                Emissions
                            </a>
                        </li>
                        <li (click)="goToTab('Re-Entry')" [ngClass]="current_tab == 'Re-Entry' ? 'active' : ''">
                            <a translate>
                                Re-Entry
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        
        
            <!-- Score Tab starts-->
            <div class="ml15 mr15" *ngIf="current_tab == 'Project Scores'">
                <div class="portfolioContent--wrapper">     
                    
                    <div class="align_lr">
                        <div class="project_list_status mt0">
                            <span class="fw-500">Showing:</span>
                            <span class="fw600">{{myportfolioprojects.length}} of {{global.addCommas(count)}} Projects</span>
                        </div>
                        <div class="flex">
                            <div class="dropdown dropdown_v2 pt2 mb5 align-right mr30">
                                <div class="cursor-pointer fw-500 dropdown-toggle" data-toggle="dropdown">
                                    <span class="mr10">{{score_type == 'score' ? 'LEED Performance Score' : 'LEED Points'}}</span>
                                    <i class="toggle fa fa-angle-down relative-position" style="top: 2px;left: 0px;"></i>
                                </div>
                                <ul class="dropdown-menu left width75p">
                                    <li (click)="changeScoreType('score')">
                                        <a translate [ngClass]="score_type == 'score' ? 'bold_underline' : ''">
                                            LEED Performance Score
                                        </a>
                                    </li>
                                    <li (click)="changeScoreType('weighted')">
                                        <a translate [ngClass]="score_type == 'weighted' ? 'bold_underline' : ''">
                                            LEED Points
                                        </a>
                                    </li>
                                </ul>
                            </div>
                
                            <div class="dropdown dropdown_v2 pt2 mb5 align-right">
                                <div class="cursor-pointer fw-500 dropdown-toggle" data-toggle="dropdown">
                                    <span class="mr10">
                                        Filter
                                    </span>
                                    <i class="toggle fa fa-angle-down relative-position" style="top: 2px;left: 0px;"></i>
                                </div>
                                <ul class="dropdown-menu filter-menu left width75p" ng-init="initDropDownHold()" style="min-width: 200px;">
                                    <li *ngFor="let category of score_categories" [hidden]="category.value == 'name'">
                                        <div class="filter_portfolio_option" (click)="filterScore(category)">
                                            <span class="checkcontainer">
                                                {{category.name}}
                                                <input type="radio" id="{{category.value}}_check" name="filter_name" value="{{category.value}}">
                                                <span class="radiobtn"></span>
                                            </span>
                                        </div>
                
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="scoreCategory--Wrapper">        
                        <div class="scoreCategory--energy">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Energy <span *ngIf="score_type == 'weighted'">- of 33</span></div>
                        </div>
                        <div class="scoreCategory--water">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Water <span *ngIf="score_type == 'weighted'">- of 15</span></div>
                        </div>
                        <div class="scoreCategory--waste">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Waste <span *ngIf="score_type == 'weighted'">- of 8</span></div>
                        </div>
                        <div class="scoreCategory--transport">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Transportation <span *ngIf="score_type == 'weighted'">- of 14</span></div>
                        </div>
                        <div class="scoreCategory--human">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Human Experience <span *ngIf="score_type == 'weighted'">- of 20</span></div>
                        </div>
                        <div class="scoreCategory--base">
                            <div class="scoreCategory--box"></div>
                            <div class="scoreCategory--title">Base Points <span *ngIf="score_type == 'weighted'">- of 10</span></div>
                        </div>
                    </div>
                    <table class="table table-striped arc-table">
                        <thead>
                            <tr>
                                <th class="unselectable cursor-pointer w25p" (click)="sortList(sort_order, 'name')">
                                    <span translate>Name</span>  
                                    <span *ngIf="sort_column == 'name'" class="fa fa-caret-{{sort_order == 'desc' ? 'down': 'up'}}" (click)="sortList(sort_order, 'name')"></span>
                                </th>
                                <th *ngIf="sort_column == 'total_score' || sort_column == '100_total_score' || score_sort == 'Sort: Total' || sort_column == 'energy' || sort_column == 'water' || sort_column == 'waste' || sort_column == 'transport' || sort_column == 'human_experience' || sort_column == 'base' || score_sort != 'Sort: Total'" class="unselectable cursor-pointer w75p" (click)="sortList(sort_order, score_type)">
                                    <span translate>Score</span>
                                    <span *ngIf="sort_column == 'total_score' || sort_column == '100_total_score' || sort_column == 'energy' || sort_column == 'water' || sort_column == 'waste' || sort_column == 'transport' || sort_column == 'human_experience' || sort_column == 'base'" class="fa fa-caret-{{sort_order == 'desc' ? 'down': 'up'}}"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                
                            <tr *ngFor="let project of myportfolioprojects; let idx = index;" class="project_row" [ngClass]="{'not-active' : (config.app_loader)}" id="{{project.building.leed_id}}">
                                <td class="vertical-align-middle cursor-pointer" (click)="searchMoveToProjectLock(project.building, idx)" 
                                (click)="global.removeDynamicDiv()">
                                    <div class="w90p pull-left ellipsis_v2">
                                        <span ng-mouseover="insertTippy($event)" class="fw600">{{project.building.name}}</span>
                                    </div>                               	
                                </td>
                                <td class="vertical-align-middle cursor-pointer">
                                    <div [hidden]="score_type == 'score'" id="{{project.building.leed_id}}_puck" (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()" *ngIf ='!project.building.is_gresb && (project.building.certification == "" || project.building.certification == "Denied" || project.building.certification == "None" || project.building.certification == null || project.building.certification == "pre-certified")' class="smallScorepuckBlank mr10">
                                        <span>{{global.getProjectScore(project.building)}}</span>
                                    </div>
                                    <div [hidden]="score_type == 'score'" id="{{project.building.leed_id}}_puck" (click)="searchMoveToProjectLock(project.building, idx)" (click)="global.removeDynamicDiv()" *ngIf = '!project.building.is_gresb && (!(project.building.certification == "" || project.building.certification == "Denied" || project.building.certification == "None" || project.building.certification == null || project.building.certification == "pre-certified"))' class="smallScorepuck mr10">
                                        <span>{{global.getProjectScore(project.building)}}</span>
                                    </div>
                
                                    <div *ngIf="project.building.is_gresb" class="mr10">
                                        <div (click)="openEstimateModal(project.building)" class="btn btn-default arc-btn arc-btn-sm" *ngIf="project.building.estimated_data_color != 'None' && project.building.estimated_data_color != 'green'">
                                            <span class="fontSize13 fw-400">Estimated: </span><span class="ml5 fw600">{{global.getProjectScore(project.building)}}</span>
                                        </div>
                                        <div (click)="searchMoveToProjectLock(project, idx)" *ngIf="project.building.estimated_data_color == 'None' || project.building.estimated_data_color == 'green'" [ngClass]='project.building.certification == "" || project.building.certification == "Denied" || project.building.certification == "None" || project.building.certification == null || project.building.certification == "pre-certified" ? "smallScorepuckBlank" : "smallScorepuck"'>
                                            <span>{{global.getProjectScore(project.building)}}</span>
                                        </div>
                                    </div>
                                    
                                    <div class="portfolio_score_progress">
                                        <div title="Energy Score: {{project.building.energy}}" [hidden]="project.building.energy == 0 || score_filters.indexOf('energy') == -1" [ngStyle]="{'width': getScoreWidth(project.building.energy, 'energy') + '%'}" class="energy_portfolio_progress progress_item">
                                            <span [hidden]="getScoreWidth(project.building.energy, 'energy') < 3">{{project.building.energy}}</span>
                                        </div>
                                        <div title="Water Score: {{project.building.water}}" [hidden]="project.building.water == 0 || score_filters.indexOf('water') == -1" [ngStyle]="{'width': getScoreWidth(project.building.water, 'water') + '%'}" class="water_portfolio_progress progress_item">
                                            <span [hidden]="getScoreWidth(project.building.water, 'water') < 3">{{project.building.water}}</span>
                                        </div>
                                        <div title="Waste Score: {{project.building.waste}}" [hidden]="project.building.waste == 0 || score_filters.indexOf('waste') == -1" [ngStyle]="{'width': getScoreWidth(project.building.waste, 'waste') + '%'}" class="waste_portfolio_progress progress_item">
                                            <span [hidden]="getScoreWidth(project.building.waste, 'waste') < 3">{{project.building.waste}}</span>
                                        </div>
                                        <div title="Transportation Score: {{project.building.transport}}" [hidden]="project.building.transport == 0 || score_filters.indexOf('transport') == -1" [ngStyle]="{'width': getScoreWidth(project.building.transport, 'transport') + '%'}" class="transport_portfolio_progress progress_item">
                                            <span [hidden]="getScoreWidth(project.building.transport, 'transport') < 3">{{project.building.transport}}</span>
                                        </div>
                                        <div title="Human Experience Score: {{project.building.human_experience}}" [hidden]="project.building.human_experience == 0 || score_filters.indexOf('human_experience') == -1" [ngStyle]="{'width': getScoreWidth(project.building.human_experience, 'human_experience') + '%'}" class="human_experience_portfolio_progress progress_item">
                                            <span [hidden]="getScoreWidth(project.building.human_experience, 'human_experience') < 3">{{project.building.human_experience}}</span>
                                        </div>
                                        <div title="Base Score: {{project.building.base}}" [hidden]="project.building.base == 0 || score_filters.indexOf('base') == -1" [ngStyle]="{'width': getScoreWidth(project.building.base, 'base') + '%'}" class="base_portfolio_progress progress_item">
                                            <span [hidden]="score_type == 'score'">{{project.building.base}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>				    
                        </tbody>
                    </table>
                    <div class="noProjects" *ngIf="myportfolioprojects.length == 0">
                        Hello, Can't see your building, community or city? 
                        <a (click)="global.openEmail('contact@arcskoru.com')">Email </a> us the name or address and we will get back to you.
                    </div>
                
                    <div class="align_r list_controls fw-500" *ngIf="myportfolioprojects.length > 0">
                        <div class="dropdown display-inline ml10">
                            <span class="mr10"><span translate>Page size</span>:</span>
                            <button class="btn dropdown-toggle dropdown-clean btn-default btn-sm" type="button" data-toggle="dropdown" >
                                <span>{{page_size}}</span>
                                <i class="fa fontSize16 ml10 fa-caret-down"></i>
                            </button>
                            <ul class="dropdown-menu" style="top: -172px;">
                                <li (click)="getPortfolioProjectsData(1, 10)">
                                    <a>10</a>
                                </li>
                                <li (click)="getPortfolioProjectsData(1, 20)">
                                    <a>20</a>
                                </li>
                                <li (click)="getPortfolioProjectsData(1, 30)">
                                    <a>30</a>
                                </li>
                                <li (click)="getPortfolioProjectsData(1, 40)">
                                    <a>40</a>
                                </li>
                                <li (click)="getPortfolioProjectsData(1, 50)">
                                    <a>50</a>
                                </li>
                            </ul>
                        </div>
                        <div class="flex pt2 ml40">
                            <span class="mr30">
                                Page {{page}} of {{page_count}}
                            </span>
                            <div>
                                <i [ngClass]="(page - 1) == 0 ? 'not-active' : ''" (click)="getPortfolioProjectsData(page - 1, page_size)" class="mr40 fa fa-chevron-left"></i>
                                <i [ngClass]="page == page_count ? 'not-active' : ''" (click)="getPortfolioProjectsData(page + 1, page_size)" class="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Score Tab Ends -->
        
            <div *ngIf="(current_tab == 'Energy' || current_tab == 'Water' || current_tab == 'Waste' || current_tab == 'Transportation' || current_tab == 'Human Experience' || current_tab == 'Emissions' || current_tab == 'Re-Entry') && this.appData.get('user_role') != 'arc_pro'" class="widget_container mt16 ml15 mr15">
                <div id="arc_premium_dark" class="arc_premium_light" style="width: 100%;height: auto;background-color: white;color: black;">
                    <div class="flex">
                        <div class="banner_content">
                            <div class="align-left mr35 pt10">
                                <div class="fs20 fw-500 line_height_20 mb8" translate>Upgrade to {{config.arc_pro_label}}</div>
                                <div class="fs16 line_height_22" translate>Use advanced features to improve performance</div>
                            </div>
                            <div class="flex pt15">
                                <button class="btn btn-primary inverse fs16 h40 br2p mr10" (click)="global.filterAccess('upgrade_arc', undefined, {'mode': 'purchase_arc_pro'})" translate>Get Essentials</button>
                                <button (click)="global.learnMore('arc_pro')" class="btn btn-default inverse fs16 h40 br2p" translate>Learn more</button>
                            </div>
                        </div>
                        <div class="re_entry_cta"></div>
                    </div>
                </div>
            </div>
        
            <div *ngIf="current_tab == 'Re-Entry' && this.appData.get('user_role') == 'arc_pro'" class="widget_container mt16 ml15 mr15">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=re_entry'" [width]="'35%'" [height]="'433px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=reentry'" [width]="'63%'" [height]="'433px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'percent_reduction'" [param]="'&type=reentry'" [width]="'69%'" [height]="'410px'"></app-widget>

                <div class="floatr widget_mimic" style="width: 28%;height: 410px;">
                    <div class="fs18 line_height_24 fw-500 mb20" translate>Improve the Overall Comprehensiveness Score</div>
                    <ul class="mb15 fs15 pl15">
                        <li class="mb25" translate>Create integrated infection control plans</li>
                        <li class="mb25" translate>Reference public health authorities</li>
                        <li class="mb25" translate>Evaluate real world experiences</li>
                        <li class="mb25" translate>Measure relevant IAQ</li>
                    </ul>
                    <div class="fs15" translate>
                        Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
                    </div>
                </div>
        
                <div class="widget_heading fs24 mb15 mt15" translate>Facility Management</div>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=fm'" [width]="'35%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'fm_survey'" [param]="'&type=fm&utc=' + (appData.get('selected_range') == 'Last 12 months')" [width]="'63%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'percent_reduction'" [param]="'&type=fmpolicy'" [width]="'69%'" [height]="'410px'"></app-widget>

                <div class="floatr widget_mimic" style="width: 28%;height: 410px;">
                    <div class="fs18 line_height_24 fw-500 mb20" translate>Improve Facility Management</div>
                    <div class="fs15 mb10" translate>Explore public health authorities:</div>
                    <ul class="mb15 fs15 pl15">
                        <li class="mb25">US CDC <a target="_blank" href="https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-business-response.html">Interim Guidance</a></li>
                        <li class="mb25">ASHRAE <a target="_blank" href="https://www.ashrae.org/file%20library/technical%20resources/ashrae%20journal/2020journaldocuments/72-74_ieq_schoen.pdf">Guidance for Building Operations</a></li>
                        <li class="mb25">REHVA <a target="_blank" href="https://www.rehva.eu/fileadmin/user_upload/REHVA_COVID-19_guidance_document_ver2_20200403_1.pdf">COVID-19 guidance document</a></li>
                    </ul>
                    <div class="fs15">
                        Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
                    </div>
                </div>
        
                <div class="widget_heading fs24 mb15 mt15" translate>Occupant Observations</div>
                
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=oe'" [width]="'35%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=occupantexp&utc=' + (appData.get('selected_range') == 'Last 12 months')" [width]="'63%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'percent_reduction'" [param]="'&type=occupant'" [width]="'69%'" [height]="'410px'"></app-widget>

                <div class="floatr widget_mimic" style="width: 28%;height: 410px;">
                    <div class="fs18 line_height_24 fw-500 mb20" translate>Improve Occupant Experience</div>
                    <div class="fs15 mb10" translate>Send more occupant surveys</div>
                    <ul class="mb15 fs15 pl15">
                        <li class="mb25" translate>Target groups: e.g., tenants, fans, more</li>
                        <li class="mb25" translate>Target areas: e.g. “lobby”, “cafe”</li>
                    </ul>
                    <div class="fs15">
                        Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
                    </div>
                </div>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'oe_survey'" [param]="'&utc=' + (appData.get('selected_range') == 'Last 12 months')" [width]="'98%'" [height]="'410px'"></app-widget>
        
                <div class="widget_heading fs24 mb15 mt15" translate>Indoor Air Quality</div>
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=iaq'" [width]="'35%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'iaq_data'" [param]="null" [width]="'63%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'percent_reduction'" [param]="'&type=iaq'" [width]="'69%'" [height]="'410px'"></app-widget>
                <div class="floatr widget_mimic" style="width: 28%;height: 410px;">
                    <div class="fs18 line_height_24 fw-500 mb20" translate>Improve Indoor Air Quality</div>
                    <ul class="mb15 fs15 pl15">
                        <li class="mb25" translate>Improve spatial coverage: measure air quality in more places</li>
                        <li class="mb25" translate>Improve temporal coverage: take more air quality measurements over time</li>
                        <li class="mb25" translate>Improve performance: operate HVAC systems to maintain superior conditions</li>
                    </ul>
                    <div class="fs15">
                        Find more resource in the <a target="_blank" href="https://arc.gbci.org/sites/default/files/Arc%20Guide%20to%20Re-Entry.pdf">Arc Re-Entry Guide</a>
                    </div>
                </div>
        
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=RH'" [width]="'49%'" [height]="'425px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=CO2'" [width]="'49%'" [height]="'425px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=TVOC'" [width]="'49%'" [height]="'425px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=PM1.0'" [width]="'49%'" [height]="'425px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=PM2.5'" [width]="'49%'" [height]="'425px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'stack_plot'" [param]="'&type=PM10'" [width]="'49%'" [height]="'425px'"></app-widget>
            </div>
        
            <!-- Summary Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Summary'" ng-init="getSyncData(current_tab)">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=performance'" [width]="'32%'" [height]="'433px'"></app-widget>

                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=total'" [width]="'65.5%'" [height]="'433px'"></app-widget>

                <app-widget class="mr15" [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=energy'" [width]="'32%'" [height]="'510px'"></app-widget>
                <app-widget class="mr15" [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=water'" [width]="'32%'" [height]="'510px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=waste'" [width]="'32%'" [height]="'510px'"></app-widget>
                <app-widget class="mr15" [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=transport'" [width]="'32%'" [height]="'510px'"></app-widget>
                <app-widget class="mr15" [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=humanexperience'" [width]="'32%'" [height]="'510px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score_mini'" [param]="'&type=reentry'" [width]="'32%'" [height]="'510px'"></app-widget>
            </div>
            <!-- Summary Tab Ends -->
            
            <!-- Energy Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Energy' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=energy'" [width]="'32%'" [height]="'433px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=energy'" [width]="'65.5%'" [height]="'433px'"></app-widget>
                
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=top&category=energy'" [width]="'49%'" [height]="'380px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=lowest&category=energy'" [width]="'48.5%'" [height]="'380px'"></app-widget>

                <app-widget class="mr15" [data_url]="'/resampled/electricity/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=electricity'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [data_url]="'/resampled/natural_gas/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=natural_gas'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=electricity&intensity=gross_area'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=natural_gas&intensity=gross_area'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [data_url]="'/resampled/site_energy/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=site_energy'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [data_url]="'/resampled/source_energy/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=source_energy'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=site_energy&intensity=gross_area'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=source_energy&intensity=gross_area'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'improvement'" [param]="'&type=energy'" [width]="'98.8%'" [height]="'395px'"></app-widget>
            </div>
            <!-- Energy Tab Ends -->
        
            <!-- Water Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Water' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=water'" [width]="'32%'" [height]="'433px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=water'" [width]="'65.5%'" [height]="'433px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=top&category=water'" [width]="'49%'" [height]="'380px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=lowest&category=water'" [width]="'48.5%'" [height]="'380px'"></app-widget>

                <app-widget class="mr15" [data_url]="'/resampled/water/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=water'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=water&intensity=occupancy'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=water&intensity=gross_area'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'improvement'" [param]="'&type=water'" [width]="'48.5%'" [height]="'395px'"></app-widget>
            </div>
            <!-- Water Tab Ends -->
        
            <!-- Waste Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Waste' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=waste'" [width]="'32%'" [height]="'433px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=waste'" [width]="'65.5%'" [height]="'433px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=top&category=waste'" [width]="'49%'" [height]="'380px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=lowest&category=waste'" [width]="'48.5%'" [height]="'380px'"></app-widget>

                <app-widget [data_url]="'/resampled/generated_waste/'" class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=generated_waste'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [data_url]="'/resampled/diverted_waste/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=diverted_waste'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=generated_waste&intensity=gross_area'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=diverted_waste&intensity=gross_area'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'improvement'" [param]="'&type=waste'" [width]="'98.8%'" [height]="'395px'"></app-widget>
            </div>
            <!-- Waste Tab Ends -->
        
            <!-- Transportation Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Transportation' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=transport'" [width]="'32%'" [height]="'433px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=transport'" [width]="'65.5%'" [height]="'433px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=top&category=transport'" [width]="'49%'" [height]="'380px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=lowest&category=transport'" [width]="'48.5%'" [height]="'380px'"></app-widget>

                <app-widget [data_url]="'/resampled/transport/'" class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=transport'" [width]="'65.5%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcprogressbar'" [param]="'&type=transport'" [width]="'32%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcstackchart'" [param]="'&type=travel_mode'" [width]="'65.5%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcemissions'" [param]="'&type=transport_emission'" [width]="'32%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=transport_emission&intensity=occupant'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=transport_distance&intensity=occupant'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'improvement'" [param]="'&type=transport'" [width]="'65.5%'" [height]="'395px'"></app-widget>
            </div>
            <!-- Transportation Tab Ends -->
        
            <!-- Human Experience Tab starts-->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Human Experience' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arccurrentscore'" [param]="'&type=human_experience'" [width]="'32%'" [height]="'433px'"></app-widget>
                <app-widget [data_url]="'/resampled/total_score/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'portfolio_average_score'" [param]="'&type=human_experience'" [width]="'65.5%'" [height]="'433px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=top&category=human_experience'" [width]="'49%'" [height]="'380px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcperformers'" [param]="'&type=lowest&category=human_experience'" [width]="'48.5%'" [height]="'380px'"></app-widget>

                <app-widget [data_url]="'/resampled/humanexperience/'" class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=humanexperience'" [width]="'65.5%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcprogressbar'" [param]="'&type=humanexperience'" [width]="'32%'" [height]="'395px'"></app-widget>
                
                <app-widget [data_url]="'/satisfactionmode/summarize/'" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=environment_summarize'" [width]="'98.8%'" [height]="'395px'"></app-widget>
                
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'satisfaction'" [param]="null" [width]="'32%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'dissatisfaction'" [param]="null" [width]="'65.5%'" [height]="'395px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=co2&intensity=True'" [width]="'49%'" [height]="'395px'"></app-widget>
                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=voc&intensity=True'" [width]="'48.5%'" [height]="'395px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'improvement'" [param]="'&type=humanexperience'" [width]="'65.5%'" [height]="'395px'"></app-widget>
            </div>
            <!-- Human Experience Tab Ends -->
        
            <!-- Emissions Start -->
            <div class="ml15 mr15 widget_container" *ngIf="current_tab == 'Emissions' && this.appData.get('user_role') == 'arc_pro'" ng-init="getSyncData(current_tab)">
                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcstackchart'" [param]="'&type=carbon_emission'" [width]="'65.5%'" [height]="'440px'"></app-widget>

                <app-widget [portfolio_id]="appData.get('portfolio_id')" [src]="'arcemissions'" [param]="'&type=emissions'" [width]="'32%'" [height]="'440px'"></app-widget>

                <app-widget class="mr15" [portfolio_id]="appData.get('portfolio_id')" [src]="'arcfloatingbarcharts'" [param]="'&type=carbon_emission&intensity=gross_area'" [width]="'65.5%'" [height]="'440px'"></app-widget>
                <div class="widget_mimic relative-position floatr" style="width: 32.2%;height: 440px;padding-top: 224px;">
                    <div class="emissions_banner"></div>
                    <div class="fs18 line_height_32 fw-500" translate>How Arc calculates GHG emissions</div>
                    <div class="fs16 line_height_22 mt8" translate>GHG Emissions are central to Arc Performance Score</div>
                    <div class="line_height_24 fs16 mt8">
                        <a (click)="global.openLink('https://arc.gbci.org/ghg-emissions-are-central-arc-performance-score-learn-how-they-are-estimated', '_blank')" translate>Learn more</a>
                    </div>
                </div>
            </div>
            <!-- Emissions End -->
        </div>
        
        <!-- No projects available starts-->
        <h3 *ngIf="is_projects_available == false" class="fw-500 black-color pl15 mb25 pt5" translate>
            Projects
        </h3>
        <div *ngIf="is_projects_available == false">
            <app-add-project (finish)="getPortfolioProjectsData(page, page_size)"></app-add-project>
        </div>
        <!-- Score Tab Ends -->

    </div>
</div>

<modal #modal>
    <app-estimate (close)="cancel()" [project]="project_selected"></app-estimate>
</modal>
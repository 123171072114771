import { Component, OnInit } from '@angular/core';

import { Global } from 'src/app/services/global.service';
import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';

@Component({
  selector: 'app-ecostruxure-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class EcostruxureRedirectComponent implements OnInit
{

	constructor(
		private global: Global,
		private api: API
	)
	{
		Config.hide_navbar = true;
	}

	ngOnInit(): void
	{
		let access_token: any = this.global.getParameterFromURLByName('state', location.href);
        this.api.setAuth(access_token);

        this.api.get("/ecostruxure/oauth/access-token/?code=" + this.global.getParameterFromURLByName('code', location.href)).subscribe(
            data =>
            {
                window.location.href = window.location.origin + "/login_middleware/?" + "authorization_token=" + access_token + "&next=ecostruxure-select-project";
            },
            error =>
            {
                window.location.href = window.location.origin + "/login_middleware/?" + "authorization_token=" + access_token + "&next=ecostruxure-select-project";
            }
        );
	}

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ScoreCodeVersionService } from 'src/app/services/score-code-version.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-score-version',
  templateUrl: './score-version.component.html',
  styleUrls: ['./score-version.component.css']
})
export class ScoreVersionComponent implements OnInit
{
	config = Config;
	leed_id = this.appData.get('leed_id');

	loading: boolean = true;
	picker_loaded: boolean = false;
	remind_me_on_loader: boolean = false;
	pending_to_version_name: string = '';
	version_update_log: any = [];

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData,
		private ScoreCodeVersion: ScoreCodeVersionService
	)
	{

	}

	ngOnInit(): void
	{
		this.ScoreCodeVersion.getNotification().subscribe(
			data =>
			{
				this.loading = false;
                this.global.initScoreCodeVersion(true);
                this.config.score_version_notification = data[0];
                if (data.length && data[0].notify_on != '9999-12-31'){
                    this.config.score_notification_reminder = moment(new Date(this.global.formatDateT(data[0].notify_on))).format("MMM DD, YYYY");
                }
                else{
                    $('#score_notification_reminder').val("");
                }
			},
			error =>
			{
				this.loading = false;
                this.global.initScoreCodeVersion(true);
			}
		);

		this.ScoreCodeVersion.getCombinedLog().subscribe(
            //success handler
            data =>
			{
                this.version_update_log = data;
                for (var i = 0; i < data.length; i++)
				{
                    if (data[i].log_type == 'version_update' && !data[i].completed)
					{
                        this.pending_to_version_name = data[i].updated_to;
                        break;
                    }
                }
            }
        );
	}

	remind_me_on()
	{
		this.remind_me_on_loader = true;
		var date = moment($('#score_notification_reminder').val()).format("YYYY-MM-DD");
		this.ScoreCodeVersion.postNotification(String(date)).subscribe(
			//success handler
			data =>
			{
				this.config.score_notification_reminder = moment(new Date(this.global.formatDateT(data.notify_on))).format("MMM DD, YYYY");
				this.remind_me_on_loader = false;
				this.alertService.alert('success', 'Successfully saved your changes.', 5);
			}, 
			//error handler
			data =>
			{
				this.remind_me_on_loader = false;
				if (data.non_field_errors == undefined)
				{
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
				else
				{
					var error_text = data.non_field_errors[0];
					this.alertService.alert('error', error_text, 5);
				}
			}

		);
	};

	enableFdatePicker()
	{
		if(this.picker_loaded == false)
		{
			this.picker_loaded = true;
			(<any>$('#score_notification_reminder')).fdatepicker(
			{
				format: 'M dd, yyyy'
			});
		}
	};

	logDateTime(value: any, type: any)
	{
		var date_time = this.global.dateTimeFormat(value);
		if (type=='date')
		{
			return date_time.split(' at ')[0];
		}
		else if (type=='time')
		{
			return date_time.split(' at ')[1];
		}
	};

}

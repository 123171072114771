<div>
    <div class="center-div">
        <section [ngClass]="submitting_survey ? 'not-active' : ''" class="widget col-md-6 p0 mb0">
            
            <div class="survey-header">
                <div class="survey-logo"></div>
            </div>
            <div class="p10 align-center borderBottom survey-heading">
                {{building.name}}, {{building.street}}
            </div>

            <div *ngIf="!survey_submitted">
                <div [ngClass]="loading ? 'not-active' : ''" class="survey-card">
                    <app-loader *ngIf="loading"></app-loader>
                
                    <div *ngIf="type == 'oe'" class="fs12 line_height_26 mb10">
                        Note: All occupant observations are anonymous. We do not collect or use individually identifiable information.
                    </div>
                
                    <div class="survey-label">
                        {{type_text}}
                    </div>
                    <div class="fs13 line_height_26 mb10" [innerHTML]="note"></div>
                    <div *ngIf="type == 'oe'" class="survey-form-heading">
                        {{scope_text}}
                    </div>
                    
                    <div *ngIf="type == 'oe'" class="survey-question old_divider">
                        <div class="question" [ngClass]="showSliderError ? 'sliderError' : ''">
                            1. Use the slider to indicate how satisfied you are with the environment of this building
                            <p *ngIf="showSliderError" class="error">{{lang.getText("blank_satisfaction_error")}}</p>
                        </div>
                        
                        <h4 *ngIf="!selected_slider" class="slider_heading">{{lang.getText("slide_to_select")}}</h4>
                        <h4 *ngIf="satisfaction == 0 && selected_slider" class="slider_heading">{{lang.getText("neutral")}}</h4>
                        <h4 *ngIf="satisfaction == 1 && selected_slider" class="slider_heading">{{lang.getText("satisfied")}}</h4>
                        <h4 *ngIf="satisfaction == 2 && selected_slider" class="slider_heading">{{lang.getText("very_satisfied")}}</h4>
                        <h4 *ngIf="satisfaction == 3 && selected_slider" class="slider_heading">{{lang.getText("extremely_satisfied")}}</h4>
                        <h4 *ngIf="satisfaction == -1 && selected_slider" class="slider_heading">{{lang.getText("unsatisfied")}}</h4>
                        <h4 *ngIf="satisfaction == -2 && selected_slider" class="slider_heading">{{lang.getText("very_unsatisfied")}}</h4>
                        <h4 *ngIf="satisfaction == -3 && selected_slider" class="slider_heading">{{lang.getText("extremely_unsatisfied")}}</h4>
                        
                    
                        <div class="mb20 slider" [ngClass]="selected_slider ? 'selected' : 'blank'">
                            <div class="flex">
                                <div class="slider_val">Extremely<br>Unsatisfied</div>
                                <div class="pt10 w50p">
                                    <input (input)="satisfactionChange(satisfaction)" type="range" min="-3" max="3" value="0" step="1" value="{{satisfaction}}" [(ngModel)]="satisfaction"> 
                                </div>
                                <div class="slider_val ml10">Extremely<br>Satisfied</div>
                            </div>
                        </div>
                    
                        <div class="flex">
                            <div class="slider_val white unselectable">Extremely<br>Unsatisfied</div>
                            <div class="pt10 w50p">
                                <div class="satisfaction_plus1" *ngIf="satisfaction == 1"></div>
                                <div class="satisfaction_plus2" *ngIf="satisfaction == 2"></div>
                                <div class="satisfaction_plus3" *ngIf="satisfaction == 3"></div>
                                <div class="satisfaction_0" *ngIf="satisfaction == 0"></div>
                                <div class="satisfaction_minus1" *ngIf="satisfaction == -1"></div>
                                <div class="satisfaction_minus2" *ngIf="satisfaction == -2"></div>
                                <div class="satisfaction_minus3" *ngIf="satisfaction == -3"></div> 
                            </div>
                            <div class="slider_val white unselectable ml10">Extremely<br>Satisfied</div>
                        </div>
                        
                        <div *ngIf="satisfaction > 0 && isLatest && selected_slider" class="mt30">
                            <span class="question col-md-12 pl0">
                                2. We're glad to hear that. Please select the options below that significantly enhance your satisfaction:
                            </span>
                            
                            <div class="col-md-4 survey-options mt20 pr0" *ngFor="let option of enhance">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                    
                        <div *ngIf="(satisfaction < 0 && isLatest) || (satisfaction < 5 && !isLatest)" class="mt30">
                            <span class="question col-md-12 pl0">
                                2. We're sorry to hear that. Please select the options below that significantly reduce your satisfaction:
                            </span>
                    
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce">
                                <div class="checkbox" *ngIf="(isLatest) || (!isLatest && !option.latest)">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                    
                        </div>
                    
                        <div class="col-md-12 pl0" *ngIf="selected_slider">
                            <p class="fontSize13 fw-600 mt30">{{lang.getText("comments")}}</p>
                            <input value="{{other_attributes}}" [(ngModel)]="other_attributes" class="other_attributes input-md fw400 w70p" maxlength="256"/>
                            <p class="fontSize13 fw-600 mt15">{{lang.getText("other_location")}}</p>
                            <input value="{{location}}" [(ngModel)]="location" class="location input-md fw400 w70p" maxlength="128"/>
                            <p class="fontSize13 fw-600 mt15">{{lang.getText("name")}}</p>
                            <input value="{{name}}" [(ngModel)]="name" class="name input-md fw400 w70p mb15" maxlength="64"/>
                    
                            <div class="fontSize16 fw-600 {{occupantError ? 'mb15' : ''}}" [ngClass]="occupantError ? 'sliderError' : ''">
                                <p class="fontSize13 fw-600">{{lang.getText("occupant_type_question")}}</p>
                                <p *ngIf="occupantError" class="error" style="margin-bottom: 0px;">{{lang.getText("mandatory_field_error")}}</p>
                            </div>
                            <div class="mb20 relative-position">
                                <select [(ngModel)]="occupant_category" id="occupant_category" class="w70p">
                                    <!-- <option value="select_type" selected="true" disabled="disabled">{{lang.getText("occupant_type")}}</option>     -->
                                    <option value="regular_occupant">{{lang.getText("regular")}}</option>
                                    <option value="visitor_occupant">{{lang.getText("visitor")}}</option>
                                </select>
                                <i class="fa fa-chevron-down" aria-hidden="true" style="right: 38px;position: relative;"></i>
                            </div>
                        </div>
                    </div>
                
                    <div *ngFor="let question of questions; let idx = index;" class="survey-question {{question.type}}">
                        <div class="question" [ngClass]="question.error ? 'sliderError' : ''">
                            {{idx + (!selected_slider || satisfaction == 0 ? (type == 'oe' ? 2 : 1) : 3)}}. {{question.question}}
                            <p *ngIf="question.error" class="error">{{question.error_message}}</p>
                        </div>
                
                        <div *ngIf="question.type == 'radio-button-group'">
                            <div class="mb30 {{option.question_key}}" *ngFor="let option of question.options">
                                <div class="radio_buttons">
                                    <div class="radio-label">
                                        <span [innerHTML]='option.question'></span>
                                    </div>
                                    <div class="flex">
                                        <div class="mr25">
                                            <span (click)="radioChange(option, true)" class="checkcontainer">
                                                Yes
                                                <input type="radio" name="{{option.question_key}}" [value]="true" [(ngModel)]="option.value">
                                                <span class="radiobtn"></span>
                                            </span>
                                        </div>
                        
                                        <div [ngClass]="type == 'fm' ? 'mr25' : ''">
                                            <span (click)="radioChange(option, false)" class="checkcontainer">
                                                No
                                                <input type="radio" name="{{option.question_key}}" [value]="false" [(ngModel)]="option.value">
                                                <span class="radiobtn"></span>
                                            </span>
                                        </div>
                
                                        <div *ngIf="type == 'fm'">
                                            <span (click)="radioChange(option, null)" class="checkcontainer">
                                                Not applicable
                                                <input type="radio" name="{{option.question_key}}" [value]="null" [(ngModel)]="option.value">
                                                <span class="radiobtn"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt10 pt5 pl30" *ngIf="option.sub_question && option.value">
                                    <div class="fs13 fw-500">Select all that apply</div>
                                    <div *ngFor="let checkbox of option.questions" class="ml20 pl0 checkbox">
                                        <input style="width: initial;" [(ngModel)]="checkbox.value" type="checkbox" value="confidential">
                                        <label for="checkbox" style="font-size: 13px !important;">
                                            {{checkbox.question}}
                                        </label>
                                    </div>
                                </div>
                                <div class="mt16" *ngIf="option.link.show && option.value == option.link.value">
                                    <div class="fs16 line_height_24 mb8">
                                        {{option.upload.text}}
                                    </div>
                                    <input #elem (input)="fileNameChanged(elem, question.question_key)" type="file" [hidden]="true" id="{{option.question_key}}_upload_picker">
                                    <div class="mb5 flex" *ngFor="let file of option.upload.file_name; let idx = index;">
                                        <div class="pt3 ellipsis ">{{file}}</div>
                                        <div (click)="removeFile(option, idx)" class="icon_deleteDoc"></div>
                                    </div>
                                    <div (click)="initUpload(option)" class="btn btn-default mt10">
                                        <span>Upload file</span>
                                    </div>
                                </div>
                                <div class="mt16" *ngIf="option.link.show && option.value == option.link.value">
                                    <div class="fs16 line_height_24 mb8">
                                        {{option.link.text}}
                                    </div>
                                    <div class="survey-link copy">
                                        <div class="flex w50p">
                                            <div class="w100p">
                                                <div class="mb5 flex" *ngFor="let link of option.link.data">
                                                    <div class="pt3 ellipsis ">{{link}}</div>
                                                    <div (click)="removeLink(option, link_value)" class="icon_deleteDoc"></div>
                                                </div>
                                                <div class="flex">
                                                    <input class="link_value" [ngClass]="option.error ? 'error_underline' : ''" (input)="changeLink(option, link_value)" [(ngModel)]="link_value" placeholder="Please provide a link" (blur)="addLink(option, link_value, this)">
                                                    <div *ngIf="!option.invalid_url && link_value.length > 0" (click)="addLink(option, link_value, this)" class="success_symbol_grey">Add link</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt5 error-color">
                                            {{option.error_message}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div *ngIf="question.type == 'radio-button'">
                            <div class="radio_buttons">
                                <div class="flex">
                                    <div class="mr25">
                                        <span (click)="radioChange(question, true)" class="checkcontainer">
                                            Yes
                                            <input type="radio" name="{{question.question_key}}" [value]="true" [(ngModel)]="question.value">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                    
                                    <div class="mr25">
                                        <span (click)="radioChange(question, false)" class="checkcontainer">
                                            No
                                            <input type="radio" name="{{question.question_key}}" [value]="false" [(ngModel)]="question.value">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                
                                    <div>
                                        <span (click)="radioChange(question, null)" class="checkcontainer">
                                            Not applicable
                                            <input type="radio" name="{{question.question_key}}" [value]="null" [(ngModel)]="question.value">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                
                            <div class="mt16" *ngIf="question.link != undefined && question.link.show && question.value == question.link.value">
                                <div class="fs16 line_height_24 mb8">
                                    <span [innerHTML]='question.upload.text'></span>
                                </div>
                                <input #elem (input)="fileNameChanged(elem, question.question_key)" type="file" [hidden]="true" id="{{question.question_key}}_upload_picker">
                                <div class="mb5 flex" *ngFor="let file of question.upload.file_name; let idx = index;">
                                    <div class="pt3 ellipsis ">{{file}}</div>
                                    <div (click)="removeFile(question, idx)" class="icon_deleteDoc"></div>
                                </div>
                                <div (click)="initUpload(question)" class="btn btn-default mt10">
                                    <span>Upload file</span>
                                </div>
                            </div>
                
                            <div class="mt16" *ngIf="question.link != undefined && question.link.show && question.value == question.link.value">
                                <div class="fs16 line_height_24 mb8">
                                    <span [innerHTML]='question.link.text'></span>
                                </div>
                                <div class="survey-link copy">
                                    <div class="flex w50p">
                                        <div class="w100p">
                                            <div class="mb5 flex" *ngFor="let link of question.link.data">
                                                <div class="pt3 ellipsis ">{{link}}</div>
                                                <div (click)="removeLink(question, link_value)" class="icon_deleteDoc"></div>
                                            </div>
                                            <div class="flex">
                                                <input class="link_value" [ngClass]="question.error ? 'error_underline' : ''" (input)="changeLink(question, link_value)" [(ngModel)]="link_value" placeholder="Please provide a link" (blur)="addLink(question, link_value, this)">
                                                <div *ngIf="!question.invalid_url && link_value.length > 0" (click)="addLink(question, link_value, this)" class="success_symbol_grey">Add link</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt5 error-color">
                                        {{question.error_message}}
                                    </div>
                                </div>
                            </div>
                
                            <div class="mt16" *ngIf="question.contact != undefined && question.contact.show && question.value == question.contact.value">
                                <div class="fs16 line_height_24 mb8">
                                    <span [innerHTML]='question.contact.text'></span>
                                </div>
                                <div class="survey-link w50p">
                                    <input class="link_value" [(ngModel)]="question.contact.data" placeholder="Please enter contact info">
                                </div>
                            </div>
                
                            <div *ngIf="question.reason != undefined && question.reason.show && question.value == question.reason.value">
                                <div class="survey-link free-response">
                                    <div class="free-response-label" [innerHTML]='question.reason.text'></div>
                                    <textarea class="link_value" [(ngModel)]="question.reason.data"></textarea>
                                </div>
                            </div>
                        </div>
                
                        <div *ngIf="question.type == 'survey-slider'" class="slider {{question.slider_changed ? '' : 'blank'}}">
                            <div class="slider_heading">
                                {{question.value_label}}
                            </div>
                            <div class="flex">
                                <div class="slider_val">Never</div>
                                <div class="pt10 w50p">
                                    <input (click)="sliderChange(question, question.value)" [(ngModel)]="question.value" class="init-range {{question.question_key}}" max="{{question.max}}" min="{{question.min}}" value="{{question.value}}" step="1" type="range">
                                </div>
                                <div class="slider_val ml10">Always</div>
                            </div>
                
                            <div class="free-response" *ngIf="question.slider_changed && question.value < question.max">
                                <div class="free-response-label">{{question.text_question}}</div>
                                <textarea #elem (input)="textAreaChange(question, elem)"></textarea>
                                <div class="max_info">{{question.max_length}} Characters max</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button *ngIf="type == 'fm'" type="button" class="btn btn-primary" (click)="submitFMSurvey()">
                            Submit
                        </button>
                        <button *ngIf="type == 'oe'" type="button" class="btn btn-primary" (click)="submitOESurvey()">
                            Submit
                        </button>
                        <div *ngIf="has_error" class="mt10 fw-600 error-color">
                            Please answer all the questions
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="survey_submitted">
                <div class="survey-card align-center">
                    <div class="buildings_green"></div>
                    <h4 class="fw-400 mb20">Thank you for taking our survey!</h4>
                
                    <div *ngIf="type == 'oe'" class="align-left">
                        <div class="pt20 borderBottom"></div>
                        <div class="h4 pt20">
                            Have you reviewed the following resources?
                        </div>
                        <div class="mtn15">
                            <div class="row no-margin-15">
                                <div class="col-md-6 pt20">
                                    <div class="re-entry-fm-box">
                                        <div class="re-entry-fm-box-header leed-logo-registered fs14">
                                            <a href="https://www.usgbc.org/credits?Version=%22v4.1%22&Rating+System=%22Existing+Buildings%22&Search+Library=%22Safety+First%22" target="_blank">LEED Safety First Pilot Credits</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pt20">
                                    <div class="re-entry-fm-box">
                                        <div class="re-entry-fm-box-header cert-nolvl-solid fs14">
                                            <a href="https://resources.wellcertified.com/tools/strategies-from-the-well-building-standard-to-support-in-the-fight-against-covid-19/" target="_blank">Well Health-Safety Rating</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

        </section>
    </div>
    <p class="survey-footer">
        <span (click)="goTo('//arcskoru.com/about')" class="cursor-pointer">{{lang.getText("about_arc")}}</span> - <span (click)="goTo('//arcskoru.com/privacy-statement')" class="cursor-pointer">{{lang.getText("arc_policy")}}</span>
    </p>
</div>
<!-- <script>
    var $element = $('input[type="range"]');

    $element.rangeslider({
        polyfill: false,
    });
</script> -->
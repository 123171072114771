import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../models/env-vars.model';
import { AppData } from './appdata.service';

@Injectable({providedIn: 'root'})
export class API extends Config
{

	constructor(
		private http: HttpClient,
		appData: AppData
	)
	{
		super(appData);
	}

	reset()
	{
		delete Config.headers.Authorization;
		this.appData.set('headers', Config.headers);
	}

	setAuth(token: string)
	{
		Config.headers.Authorization = "Bearer " + token;
		this.appData.set('headers', Config.headers);
	}

	post(url: string, payload: any, headers?: any)
	{
		if(headers == undefined)
		{
			headers = Config.headers;
		}
		else
		{
			headers = headers.headers;
		}
		return this.http.post<any>(Config.basic_api_url + url, payload, { headers: headers });
	}

	put(url: string, payload: any, header?: any)
	{
		if(header == undefined)
		{
			header = Config.headers;
		}
		else
		{
			header = header.headers;
		}
		return this.http.put<any>(Config.basic_api_url + url, payload, { headers: header });
	}

	get(url: string, responseType?: string, header?: any)
	{
		if(responseType == undefined)
		{
			responseType = 'json'
		}

		if(header == undefined)
		{
			header = new HttpHeaders(Config.headers);
		}
		else
		{
			header = new HttpHeaders(header.headers);
		}
		return this.http.get<any>(Config.basic_api_url + url, { headers: header, responseType: responseType as 'json' });
	}

	delete(url: string, payload: any)
	{
		let options = {
			body: payload,
			headers: 
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		return this.http.delete<any>(Config.basic_api_url + url, options);
	}

	getAsset(url: string, responseType?: string)
	{
		if(responseType == undefined)
		{
			responseType = 'json'
		}
		return this.http.get<any>(url, {responseType: responseType as 'json'});
	}

	async(url: string[], payload?: any, type?: string)
	{
		let apis = [];
		for(let i in url)
		{
			if(payload != undefined)
			{
				if(type == undefined || type == 'post')
				{
					apis.push(
						this.post(url[i], payload)
					);
				}
				else if(type == 'delete')
				{
					apis.push(
						this.delete(url[i], payload)
					);
				}
			}
			else
			{
				apis.push(
					this.get(url[i])
				);
			}
		}
		return forkJoin(apis);
	}
}
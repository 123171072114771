import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit
{
	config = Config;
	leed_id = this.appData.get('leed_id');
	loading_integrations: boolean = false;
	loading: boolean = false;
	allow_popups: boolean = false;
	current_tab: string = this.global.getURLFromParam('tab') != undefined ? this.global.getURLFromParam('tab') : 'history';
	mypayments: any = [];
	review_history: any = [];
	uploaded_files: any = [];
	orderNo: string = '';
	cc_avenue_block_modal_msg: string = '';
	paymentGatewayRedirect: any = {};

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{
		this.paymentGatewayRedirect = {
			status: false,
			windowName: false,
			monitor: false,
			complete: (url: string) =>
			{
				if (this.paymentGatewayRedirect.monitor)
				{
					clearInterval(this.paymentGatewayRedirect.monitor);
				}
	
				this.paymentGatewayRedirect.status = false;
				this.paymentGatewayRedirect.windowName.close();
	
				window.location.reload();
			}
		};
	}

	ngOnInit(): void
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/payments/order/'
		).subscribe(
			data =>
			{
				this.mypayments = [];
				for (var i = 0; i < data.ItOrders.length; i++)
				{
					if (data.ItOrders[i]['Currency'] == 'YEN' || data.ItOrders[i]['OrderType'] == 'REGISTRATION' || this.config.config.settings.all_arc_material.indexOf(data.ItOrders[i]['material']) > -1 || data.ItOrders[i]['material'] == 'canada_invoice')
					{
						this.mypayments.push(data.ItOrders[i]);
					}
				}
				this.mypayments.sort(this.global.sortList('Erdat'));
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);

		if (window.location.href.indexOf("transactionStatus=success") != -1)
		{
			$("#cc_avenue_block_modal").modal("hide");
			window.close();
		}
	}

	downloadInvoice(payment: any)
	{
		if ((['SUBSCRIPTION', 'RISKFOOTPRINT'].indexOf(payment.OrderType) != -1 || payment.material == 'canada_invoice') && 'receipt_url' in payment)
		{
			window.open(payment.receipt_url, "_blank");
		}
		else
		{
			this.global.anchorDownload("Reciept(" + payment.sap_order_id + ").pdf", this.config.basic_api_url + "/assets/LEED:" + this.leed_id + "/payments/receipt/" + payment.Vbeln + "/?access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key);
		}
	}

	toggleTab(tab: string)
	{
		this.current_tab = tab;
	};

	getTotalPriceAndTaxes(price: string, currency: string)
	{
		var total_price = this.global.formatNumberCommas(parseFloat(price).toFixed(2));
		if (currency == "INR")
		{
			total_price = "₹" + total_price;
		}
		else if (currency == "CAD")
		{
			total_price = "$" + total_price + " CAD";
		}
		else if (currency == "EUR")
		{
			total_price = "€" + total_price;
		}
		else if (currency == "USD")
		{
			total_price = "$" + total_price;
		}
		else
		{
			total_price = this.config.currency + this.global.addCommas(total_price);
		}
		return total_price;
	}

	checkStatus(name: any)
	{
		if (name.toLowerCase() == "payment cleared")
		{
			return "Completed";
		}
		else if (name.toLowerCase() == "payment cancelled" || name.toLowerCase() == "auto cancelled")
		{
			return "Cancelled";
		}
		else
		{
			return name.length == "" ? "Pending" : name;
		}
	}

	checkTooltipStatus(name: string)
	{
		if (name.toLowerCase() == "payment cleared")
		{
			return name;
		}
		else if (name.toLowerCase() == "payment cancelled" || name.toLowerCase() == "auto cancelled")
		{
			return "Payment Cancelled";
		}
		else
		{
			return "Payment under process...";
		}
	}

	getSEZDocsForOrder(orderNo: string)
	{
		this.uploaded_files = [];
		this.api.async(
			[
				'/assets/LEED:'+ this.leed_id + '/s3documents/?doc_category=' + "sez_agreement/" + orderNo,
				'/assets/LEED:'+ this.leed_id + '/s3documents/?doc_category=' + "sez_docs/" + orderNo
			]
		).subscribe(
			data =>
			{
				this.uploaded_files.concat(data[0]);
				this.uploaded_files.concat(data[1]);
				this.orderNo = orderNo;
				$("#billing-sez-docs-modal").modal("show");
			},
			error =>
			{
				this.alertService.alert('success', 'Something went wrong. Try again.', 5);
			}
		);
	}

	closeBillingDocsModal()
	{
		$("#billing-sez-docs-modal").modal("hide");
	}

	payNowCcAvenue(order_id: string)
	{

		this.loading = true;
		this.allow_popups = false;
		if (this.global.getBrowser().ie)
		{
			this.loading = false;
			this.cc_avenue_block_modal_msg = "Internet Explorer is not compatible for Net Banking. Please use Chrome, Safari or Firebox browser to proceed.";
			$("#cc_avenue_block_modal").modal("show");

			return;
		}

		this.cc_avenue_block_modal_msg = "Processing... please wait.";
		$("#cc_avenue_block_modal").modal("show");

		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/payments/ccavenue/' + order_id + '/'
		).subscribe(
			data =>
			{
				this.cc_avenue_block_modal_msg = "Page will be auto refreshed when opened payment window is closed."
				this.allow_popups = true;
				if (data.status)
				{
					var redirect_url = encodeURIComponent(window.location.href);
					var payment_url = data.url + redirect_url;

					this.paymentGatewayRedirect.status = true;

					var screenLeft = 0,
						screenTop = 0,
						width = (window.screen.width / 100) * 80,
						height = (window.screen.height / 100) * 80;

					if (typeof window.screenLeft !== 'undefined')
					{
						screenLeft = window.screenLeft;
						screenTop = window.screenTop;
					}
					else if (typeof window.screenX !== 'undefined')
					{
						screenLeft = window.screenX;
						screenTop = window.screenY;
					}

					screenLeft = screenLeft + (window.screen.width - width) / 2;
					screenTop = screenTop + (window.screen.height - height - 100) / 2;
					this.paymentGatewayRedirect.windowName = window.open(payment_url, "paymentRedirect", "status=yes,toolbar=no,menubar=no,location=no,addressbar=no,scrollbars=yes,resizable=yes,width=" + width + "px,height=" + height + "px,left=" + screenLeft + ",top=" + screenTop);

					this.paymentGatewayRedirect.monitor = setInterval(() =>
					{
						if (
							this.paymentGatewayRedirect.status === true &&
							this.paymentGatewayRedirect.windowName.closed
						)
						{
							clearInterval(this.paymentGatewayRedirect.monitor);
							window.location.reload();
						}
					}, 1000);
				}
				else
				{
					this.cc_avenue_block_modal_msg = "We are facing issues in getting your payment details. Please contact us at contact@arcskoru.com for payment for this project."

					setTimeout(function()
					{
						$("#cc_avenue_block_modal").modal("hide");
					}, 3000);
				}
			},
			error =>
			{

			}
		);
	};

	IsArcOrder(payment: any)
	{
		var v_4_1_city_comm = ["LEED v4.1 Cities: Existing", "LEED v4.1 Cities: Plan and Design", "LEED v4.1 Communities: Existing", "LEED v4.1 Communities: Plan and Design"];
		if (this.config.config.settings.show_paynow_mat.indexOf(payment.material) > -1 && v_4_1_city_comm.indexOf(this.appData.get('project_rating_system')) < 0)
		{
			return true;
		}
		else
		{
			return false;
		}

	}

	makePayment(type: string, $event: any)
	{
		if ($event.target.nodeName == 'A' && type == 'recertification')
		{
			return;
		}
		if (type == 'recertification')
		{
			this.checkForPrePayment();
		}
		else if (type == 'climaterisk')
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "climate-risk"
			});
		}
		else if (type == 'certificate')
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "performance-certificates"
			});
		}
	};

	checkForPrePayment()
	{
		this.loading = true;
		this.api.get('/assets/LEED:' + this.leed_id + '/review/').subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.review_history = response;
				if (this.review_history.review_payment != undefined && this.review_history.review_payment[0].bypass_payment)
				{
					$(".block_pre_pay").modal("show");
				}
				else
				{
					this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'payment', 'initiator': 'billing'}, {'type': 'recertification'});
				}
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};

	payNow(payments: any)
	{
		this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'paynow', 'invoice_number': payments.Vbeln}, {'invoice_number': payments.Vbeln});
	}

}

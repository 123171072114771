import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalComponent } from 'src/app/components/modal/modal.component';
import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from '../../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { PerformanceDatePickerComponent } from 'src/app/components/performance-date-picker/performance-date-picker.component';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import * as stream from 'getstream';

@Component({
  selector: 'app-efficiency-analysis',
  templateUrl: './efficiency-analysis.component.html',
  styleUrls: ['./efficiency-analysis.component.css']
})
export class EfficiencyAnalysisComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent
	@ViewChild('check_models') check_models: any;
	arc_pro_label: string = Config.arc_pro_label;
	
	baseline_period: any;
	baseline_period_start: any;
	performance_period: any;
	performance_period_start: any;
	dataupdated_models: any = [];
	feed_report: any = [];
	feed_years: any = [];
	feeds: any = [];
	retry: any = [];
	reports: any = [];
	models: any = [];
	model_years: any = [];
	icons: any = {};
	subscription: any = {};
	selected_icon: any = {};

	leed_id: number = this.appData.get('leed_id')
	change_range: string = 'range_end';
	selected_tab: string = 'Summary';
	current_tab: string = 'Report Settings';
	doc_category: string = "ea_report_title";
	header: string = "Create Efficiency Analysis Report";
	error_message: string = '';
	mode: string = 'paid';
	model_created: boolean | null = null;
	loading: boolean = false;
	refresh_models: boolean = false;
	report_sent: boolean = false;
	report_type: boolean = true;
	datepicker_obj: PerformanceDatePickerComponent = new PerformanceDatePickerComponent(
		this.global,
		this.api,
		this.appData,
		this.alertService,
	);
	selectDate: any = this.datepicker_obj.selectDate;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService
	)
	{
		
	}

	ngOnInit(): void
	{
		this.initFeed();
        this.getData();
        this.getIcons();
		this.global.selectYear(undefined, this.performance_period);
        this.global.initRanges(this.performance_period);
	}

	changeTab(tab: string)
	{
		if(tab == 'Report')
		{
			this.getReports();
		}
		this.selected_tab = tab;
	}

	toggleTab(tab: string)
	{
		this.current_tab = tab;
	}

	addMonthHighlights(day: any, type: string)
	{
		if(this.config.range_start == undefined)
		{
			return;
		}
		var attr = "";
		var start = this.config.range_start.clone();
		var end = this.config.range_end.clone();
		if(day.isBetween(start, end, undefined, '[]'))
		{
			attr += "selected ";
		}
		if(day.isSame(start.format('YYYY-MM-DD'), 'month'))
		{
			attr += "start ";
		}
		if(day.isSame(end.format('YYYY-MM-DD'), 'month'))
		{
			attr += "end ";
		}
		
		if(type == 'baseline' && day.isAfter(moment(this.performance_period_start).format('YYYY-MM-DD'), 'day'))
		{
			attr += "old not-active";
		}
		if(type == 'performance' && day.isSameOrBefore(moment(this.baseline_period).format('YYYY-MM-DD'), 'day'))
		{
			attr += "old not-active";
		}
		if(day.isAfter(moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), 'day'))
		{
			attr += "old not-active";
		}
		return attr;
	};

	toggleCal(type: string, range_start: any, range_end: any)
	{
		if(type == 'custom_cal')
		{
			$('#custom_cal_performance').hide();
		}
		else
		{
			$('#custom_cal').hide();
		}

		$('#' + type).slideToggle(300);
		this.config.range_start = moment(range_start);
		this.config.range_end = moment(range_end);
		this.global.selectYear(undefined, range_end);
	};

	hideCal(type: string)
	{
		$('#' + type).slideToggle(300);
	};

	createModel(end_date: string)
	{
		end_date = moment(end_date).format("YYYY-MM-DD");
		var start_date =  moment(end_date).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD')
		var data = {
			'start_date': start_date, 
			'end_date': end_date
		};
		this.loading = true;
		this.api.post('/assets/LEED:' + this.leed_id + '/se/model/createmodel/', data).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				if(data.response_data != 'Your data is being computed.')
				{
					this.error_message = data.response_data;
					$(".error_message").modal('show');
				}
				else
				{
					this.alertService.alert('success', 'Generating model for ' + moment(end_date).format('MMM, YYYY'), 5);
				}
			},
			//error handler
			data =>
			{
				this.loading = false;
			}   
		);
	};

	regenModel()
	{
		for(var i = 0; i < this.dataupdated_models.length; i++)
		{
			this.createModel(moment(this.dataupdated_models[i].date_time).endOf('month').format("YYYY-MM-DD"));
		}
		this.dataupdated_models = [];
	};

	saveDates(type: string)
	{
		let payload: any;
		if(type == 'performance')
		{
			payload = {
				"performance_efficiency_date": moment(this.config.range_end).format('YYYY-MM-DD')
			};
		}
		else
		{
			payload = {
				"baseline_efficiency_date": moment(this.config.range_end).format('YYYY-MM-DD')
			};
		}

		this.loading = true;
		this.api.put('/assets/LEED:' + this.leed_id + '/', payload).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.appData.set('buildingData', data);
				if(type == 'baseline')
				{

					this.baseline_period = moment(this.config.range_end).clone().format('YYYY-MM-DD');
					this.baseline_period_start = moment(this.config.range_start).clone().format('YYYY-MM-DD');

					this.hideCal('custom_cal');
				}
				else
				{
					this.performance_period = moment(this.config.range_end).clone().format('YYYY-MM-DD');
					this.performance_period_start = moment(this.config.range_start).clone().format('YYYY-MM-DD');

					this.hideCal('custom_cal_performance');
				}

				if(this.model_years.indexOf(moment(this.config.range_end).startOf('month').format('YYYY-MM-DD')) == -1)
				{
					this.createModel(this.config.range_end);
				}
			},
			//error handler
			data =>
			{
				this.loading = false;
			}   
		);
	};

	getStarted()
	{
		var data = {
			'start_date': moment(new Date()).subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), 
			'end_date': moment(new Date()).subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
		};
		this.loading = true;
		this.api.post('/assets/LEED:' + this.leed_id + '/se/model/createmodel/', data).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				if(data.response_data != 'Your data is being computed.')
				{
					this.error_message = 'data.response_data';
					$(".error_message").modal('show');
				};

				var data_base = {
					'start_date': moment(new Date()).subtract(2, 'year').startOf('year').format('YYYY-MM-DD'), 
					'end_date': moment(new Date()).subtract(2, 'year').endOf('year').format('YYYY-MM-DD')
				};
				this.loading = true;
				this.api.post('/assets/LEED:' + this.leed_id + '/se/model/createmodel/', data_base).subscribe(
					//success handler
					data =>
					{
						this.loading = false;
						if(data.response_data != 'Your data is being computed.')
						{
							this.error_message = 'data.response_data';
							$(".error_message").modal('show');
						}
					},
					//error handler
					data =>
					{
						this.loading = false;
					}   
				);
			},
			//error handler
			data =>
			{
				this.loading = false;
			}   
		);
	};  

	initDates()
	{
		var payload = {
			"performance_efficiency_date": moment(this.performance_period).format('YYYY-MM-DD'),
			"baseline_efficiency_date": moment(this.baseline_period ).format('YYYY-MM-DD')
		};

		this.loading = true;
		this.api.put('/assets/LEED:' + this.leed_id + '/', payload).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.appData.set('buildingData', data);
				this.getStarted();
			},
			//error handler
			data =>
			{
				this.loading = false;
			}   
		);
	};

	isModelCreated(year: any)
	{
		if(this.model_years.indexOf(moment(year).clone().startOf('month').format('YYYY-MM-DD')) == -1)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	getData()
	{
		var urls = [
			'/assets/LEED:' + this.leed_id + '/se/model/createmodel/?page_size=0',
			'/assets/LEED:' + this.leed_id + '/subscriptions/efficiencyanalysis/'
		]

		this.api.async(urls).subscribe(
			data =>
			{
				this.models = data[0];
				this.subscription = data[1];

				for(var i = 0; i < this.models.length; i++)
				{
					this.model_years.push(this.models[i].date_time);
				}

				if(this.models.length == 0)
				{
					this.model_created = false;
				}
				else
				{
					this.model_created = true;
				}

				if(this.subscription == "No subscription active for the project.")
				{
					this.mode = 'free';
				}
				else
				{
					this.mode = 'paid';
				}
				
				if(this.mode == 'free')
				{
					this.performance_period = moment(new Date()).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
					this.performance_period_start = moment(new Date()).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');

					this.baseline_period = moment(this.performance_period).subtract(1, 'year').format('YYYY-MM-DD');
					this.baseline_period_start = moment(this.baseline_period).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
				}
				else
				{
					if(this.appData.get('buildingData').performance_efficiency_date != null)
					{
						this.performance_period = moment(this.appData.get('buildingData').performance_efficiency_date).format('YYYY-MM-DD');
						this.performance_period_start = moment(this.performance_period).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
					}
					else
					{
						this.performance_period = moment(new Date()).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
						this.performance_period_start = moment(new Date()).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
					}

					if(this.appData.get('buildingData').baseline_efficiency_date != null)
					{
						this.baseline_period = moment(this.appData.get('buildingData').baseline_efficiency_date).format('YYYY-MM-DD');
						this.baseline_period_start = moment(this.baseline_period).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
					}
					else
					{
						this.baseline_period = moment(this.performance_period).subtract(1, 'year').format('YYYY-MM-DD');
						this.baseline_period_start = moment(this.performance_period_start).subtract(1, 'year').format('YYYY-MM-DD');
					}
				}

				for(var i = 0; i < this.models.length; i++)
				{
					if(this.models[i].dataupdated && ((moment(this.models[i].date_time).endOf("month").format("YYYY-MM-DD") == this.baseline_period) || moment(this.models[i].date_time).endOf("month").format("YYYY-MM-DD") == this.performance_period))
					{
						this.dataupdated_models.push(this.models[i]);
					}
				}
			}
		);
	};

	isGeneratingModel(date: any)
	{
		if(this.feed_years.indexOf(moment(date).format("YYYY-MM")) != -1)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	deleteFeed(feed: any, index?: number)
	{
		this.loading = true;
		this.api.delete('/assets/LEED:'+ this.leed_id + '/notificationitems/', {
			"foreign_id": feed.foreign_id
		}).subscribe(
			//success handler
			data =>{
				this.loading = false;
				this.retry.splice(index, 1);
			},
			//error handler
			data =>{
				this.loading = false;
			}
		);
	};

	initFeed()
	{
		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/notificationitems/'
		).subscribe(
			response =>
			{
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				
				for(var i = 0; i < response.activities.length; i++)
				{
					if(response.activities[i].foreign_id.indexOf("schneiderelectric_createmodel_") != -1)
					{
						var time = moment(response.activities[i].time);
						var date = moment(new Date);
						if(time.diff(date, 'days') > 1)
						{
							this.deleteFeed(response.activities[i]);
						}
						else
						{
							this.feeds.push(response.activities[i]);
						}
					}

					if(response.activities[i].foreign_id.indexOf("ea_analysis") != -1)
					{
						var time = moment(response.activities[i].time);
						var date = moment(new Date);
						if(time.diff(date, 'days') > 1)
						{
							this.deleteFeed(response.activities[i]);
						}
						else
						{
							this.feed_report.push(response.activities[i]);
						}
					}
					else if(response.activities[i].foreign_id.indexOf("ea_analysis") != -1)
					{
						this.retry.push(response.activities[i]);
					}
					
					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						// this.removeFeed(response.activities[i].foreign_id);
					}
				};

				for(var i = 0; i < this.feeds.length; i++)
				{
					this.feed_years.push(moment(this.feeds[i].foreign_id.replace("schneiderelectric_createmodel_", "")).format("YYYY-MM"));
				};

				this.retry = this.retry.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});

				this.feed_report = this.feed_report.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});

				var subscription = notification.subscribe((data: any) =>
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < this.feeds.length; a++)
						{
							if(this.feeds[a].id == data.deleted[d])
							{
								this.feeds.splice(a, 1);
								this.refresh_models = true;
							}
						}

						for(var a = 0; a < this.feed_report.length; a++)
						{
							if(this.feed_report[a].id == data.deleted[d])
							{
								this.feed_report.splice(a, 1);
								this.getReports();
							}
						}
					}

					if(data.new.length > 0)
					{
						if(data.new[0].foreign_id.indexOf("schneiderelectric_createmodel_") != -1)
						{
							this.feeds.push(data.new[0]);
						}
						if(data.new[0].foreign_id.indexOf("ea_analysis") != -1)
						{
							this.feed_report.push(data.new[0]);
						}
					}
					
					this.feed_report = this.feed_report.sort(function(a: any,b: any) 
					{ 
						return new Date(a.time).getTime() - new Date(b.time).getTime() 
					});

					for(var i = 0; i < this.feeds.length; i++)
					{
						this.feed_years.push(moment(this.feeds[i].foreign_id.replace("schneiderelectric_createmodel_", "")).format("YYYY-MM"));
					};
					if(this.feeds.length == 0)
					{
						this.feed_years = [];
					}

					this.global.refreshCycle();
				});
			},
			error =>
			{

			}
		);
	};

	deleteIcon(uploaded_file: any, sequence: any)
	{
		var putData = 
		{
			doc_id : uploaded_file.id 
		};

		this.loading = true;
		this.api.put('/assets/LEED:'+ this.leed_id + '/s3documents/', putData).subscribe(
			//success handler
			data =>
			{
				this.icons[sequence] = undefined;
				this.loading = false;
			},
			//error handler
			data =>
			{
				this.alertService.alert('error', 'Not able to remove logo.', 5);
				this.loading = false;
			}
		);  
	};

	upload(form_data: any, category: string, type: string, size: number)
	{        
		(<any>$('.upload_report_title'))[0].value = "";  
		if(type.split('/')[0] != 'image')
		{
			this.alertService.alert('error', 'Only images are allowed', 5);
			return;
		}
		
		if((size / 1024000) > 5.0)
		{
			this.alertService.alert('error', 'File size should not exceed 5MB', 5);
			return;
		}
		
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', category)

		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/uploadS3/?upload_category=' + category, form_data, custom_basic_config_header).subscribe(
			//success handler
			data =>
			{
				this.getIcons();
			},
			//error handler
			data =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Not able to upload logo.', 5);
			}
		);
	};

	selectImage(sequence: any)
	{
		this.selected_icon = sequence;
		$('.upload_report_title').click();
	};

	deleteReport(document: any)
	{
		var putData = 
		{
			doc_id : document.id 
		};

		this.loading = true;
		this.api.put('/assets/LEED:'+ this.leed_id + '/s3documents/', putData).subscribe(
			//success handler
			data =>
			{
				this.getReports();
			},
			//error handler
			data =>
			{
				this.loading = false;
			}
		); 
	};

	fileNameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		//var type = $(this).attr('category');
		var data;
		var temp_uploading = [];
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			var mimeType = $(elem)[0].files[a].type;

			if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
			{
				var file: any = new Blob([$(elem)[0].files[a]], { type: mimeType});
				file.lastModifiedDate = new Date();
				data.append('action_file', file, this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			}
			else
			{
				var file: any = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1], {type: mimeType, lastModified: <any>new Date()});
				data.append('action_file', file);
			}

			// var file = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			// data.append('action_file', file);
			this.upload(data, this.doc_category, $(elem)[0].files[a].type, $(elem)[0].files[a].size);
		}
	};
	
	getPath(doc: any, id?: any)
	{
		if(doc == undefined)
		{
			return '';
		}
		if(id != undefined && isNaN(parseInt(id)))
		{
			if(id == 'play_to_zero.jpg' && this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1)
			{
				id = "play_to_zero.jpg";
			}
			return window.location.origin + "/assets/images/" + id;
		}
		
		let url: string = '';
		try
		{
			if(doc == undefined)
			{
				doc = {"id": id};
			}
			url = this.config.basic_api_url + "/assets/LEED:" + this.leed_id + "/documents/download/?doc_id=" + doc["id"] + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		}
		catch(e)
		{
		}
		return url;
	};

	getIcons()
	{
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=' + this.doc_category
		).subscribe(data =>
		{
			this.loading = false;

			for(var n = 0; n < data.length; n++)
			{
				var file_name = this.global.getFileName(data[n]);
				file_name = file_name.split('.')[0];
				if(file_name == 'first')
				{
					this.icons['first'] = data[n];
				}
				else if(file_name == 'second')
				{
					this.icons['second'] = data[n];
				}
				else if(file_name == 'third')
				{
					this.icons['third'] = data[n];
				}
				else if(file_name == 'fourth')
				{
					this.icons['fourth'] = data[n];
				}
				else if(file_name == 'fifth')
				{
					this.icons['fifth'] = data[n];
				}
			}
		}, 
		error =>
		{
			this.loading = false;
		});
	};

	getReports()
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=ea_analysis'
		).subscribe(data =>
		{
			data = data.sort(function(a: any,b: any) 
			{ 
				return new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime() 
			}).reverse();
			this.loading = false;
			for(var n = 0; n < data.length; n++)
			{
				data[n].document_metadata = JSON.parse(data[n].document_metadata.replace(/'/g, '"'));
				if(String(data[n].document_metadata.report_title) == 'undefined')
				{
					data[n].document_metadata.report_title = null;
				}
			}
			this.reports = data;
		}, error =>
		{
			this.loading = false;
		})
	};

	downloadCert(data: any)
	{
		var url = this.config.basic_api_url + "/assets/LEED:" + this.leed_id + "/documents/download/?doc_id=" + data.id + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		var name   = this.global.getFileName(data);
		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
	};

	cancel()
	{
		this.modal.close();
	};

	createReport()
	{
		this.modal.open({ windowClass: 'certificate_modal' })
	};

	submitReport()
	{
		var url = this.config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/report/efficiency-analysis/?access-token=<access-token>&subscription-key="+ this.config.sub_key + "&START="+ this.performance_period_start + "&END=" + this.performance_period + "&START_BASELINE="+ this.baseline_period_start + "&END_BASELINE=" + this.baseline_period;
		
		if(this.icons[this.selected_icon] != undefined || this.icons[this.selected_icon] != null)
		{
			url += '&report_title=' + this.icons[this.selected_icon].id;
		}
		
		var payload = {
			"source_url": url,
			"doc_category": "ea_analysis",
			"file_name": "Efficiency Analysis",
			"callback": "ea_analysis",
			"crawl": false
		};
		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/createPDF/', payload).subscribe(
			data =>
			{
				this.loading = false;
				if ("message" in data)
				{
					this.alertService.alert('success', data['message'], 5);
				}
				this.cancel();
			},
			data =>
			{
				this.loading = false
				this.alertService.alert('error', 'Something went wrong. Try again.', 5);
			}
		);
	};

	upgrade()
	{
		this.global.upgrade("purchase_arc_pro", this.appData.get('leed_id'));
	}

}

<div id="navbar_comp" class="page-controls navbar navbar-default" [hidden]="
	['forms', 'upgrade_arc', 'payment-success', 'project-payment', 'claim-school', 'learn-more', 'create-model'].indexOf(appData.get('current_app')) != -1 || 
	['purchase-certificates', 'create-models', 'portfolio-creation', 'payment', 'survey', 're-entry-survey', 'review-selection', 'review-payment'].indexOf(appData.get('app_name')) != -1"
	[ngClass]="appData.get('LO_referer') ? 'top40' : ''"
	>
	<div id="LO_referer" class="ui-version-banner align_r" *ngIf="appData.get('LO_referer')" style="border-bottom: 1px solid #28323B;">
		<span (click)="goBackToLO()" class="pr10 cursor-pointer">Go back to LEED Online</span>
		<span (click)="goBackToLO()" class="cross pr5 cursor-pointer">
			<svg class="" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black"/></svg>
		</span>
	</div>

	<div class="align_lr">
		<div class="flex">
			<div class="hamburger" (click)="global.toggleMenu()">
				<i class="fas fa-bars"></i>
			</div>
			<a href="//arc.gbci.org" class="appLogo"></a>
		
			<div class="nav_options" *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'insight_admin' && appData.get('app_type') != 'climate_admin_tool' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'" [hidden]="isSearchActive">
				<div translate [ngClass]="appData.get('current_nav') == 'app/home/overview' ? 'active': ''" (click)="goTo('home')">
					Home
				</div>
				<div translate [ngClass]="appData.get('current_nav') == 'app/projects/my-projects' || appData.get('current_app') == 'selected_project' ? 'active': ''" (click)="goTo('projects')">Projects</div>
				<div translate [ngClass]="appData.get('current_app') == 'portfolios' ? 'active': ''" (click)="goTo('portfolios')">Portfolios</div>

				<div class="dropdown dropdown_v2">
					<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
						<span [ngClass]="appData.get('current_app') == 'ecostruxure' ? 'white_color': ''">
							Connected Services
						</span>
						<i class="fs16 fas fa-angle-down country_name_anchor"></i>
					</div>
					<ul class="user-dropdown dropdown-menu left connected_services">
						<li (click)="goTo('ecostruxure')">
							EcoStruxure
						</li>
					</ul>
				</div>

				<div [ngClass]="appData.get('current_app') == 'gsa-panel' ? 'active': ''" (click)="goTo('gsa-panel')" *ngIf="appData.get('gsa_admin')">GSA Panel</div>
			</div>
			<div class="nav_options" *ngIf="appData.get('app_type') == 'arc_japan_invoice'">
				<div class="active">Arc Japan Admin - {{username}}</div>
			</div>
			<div class="nav_options" *ngIf="appData.get('app_type') == 'cagbc_invoice'">
				<div class="active">CAGBC Admin - {{username}}</div>
			</div>
			<div class="nav_options" *ngIf="appData.get('app_type') == 'climate_admin_tool'">
				<div class="active">Climate Risk Admin - {{username}}</div>
			</div>
		</div>
	
		<div class="user_options flex">
	
			<div class="search-wrapper" [ngClass]="isSearchActive ? 'active' : ''" *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'insight_admin' && appData.get('app_type') != 'climate_admin_tool' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'">
				<div class="input-holder">
					<input id="search-input" (focus)="searchEnable()" [(ngModel)]="query" (ngModelChange)=search() type="text" class="search-input" placeholder="Search projects" />
					<button class="search-icon"><span></span></button>
				</div>
				<span (click)="deleteSearch()" class="close"></span>
	
				<div [hidden]=!nav_search class="searchDropdown">
					<div class="searchLabels">
						Project(s) - {{config.searchResults_leed_id.length}}
					</div>
					<table class="table table-striped table-hover table-project-lists">
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody [ngClass]="loading_prj_access ? 'not-active' : ''">
							<tr [hidden]="!(config.searchResults_leed_id.length == 0)">
								<td>
									<div class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Projects Found</div>
								</td>
							</tr>
							<tr [hidden]="!(config.searchResults_leed_id.length > 0)" class="project_row" *ngFor="let project of config.searchResults_leed_id; let idx = index">
								<td class="cursor-pointer vertical_align_middle" (click)="deleteSearch();global.searchMoveToProject(project, idx)"><span>{{project.leed_id}}</span></td>
								<td class="cursor-pointer vertical_align_middle" (click)="deleteSearch();global.searchMoveToProject(project, idx)">
									<div class="ellipsis_0w w90p pull-left">
										<span class="fw-semi-bold">{{project.name}}</span>
									</div>  
								</td>
								<td class="align-right" ngClass="{{'search-row-'+idx}} capitalize cursor-pointer" (click)="deleteSearch();global.searchMoveToProject(project, idx)">
								<td class="align-right" ngClass="{{'search-row-'+idx}} capitalize cursor-pointer" (click)="deleteSearch();global.searchMoveToProject(project, idx)">
									<app-certification-tag [project]="project"></app-certification-tag>
								</td>
							</tr>				    
						</tbody>
					</table>
					<div class="searchLabels">
						Portfolio(s) - {{config.searchResults_pf_id.length}}
					</div>
					<table class="table table-striped table-hover table-project-lists">
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr [hidden]="!(config.searchResults_pf_id.length == 0)">
								<td>
									<div class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Portfolios Found</div>
								</td>
							</tr>
							<tr class="project_row" *ngFor="let portfolio of config.searchResults_pf_id; let idx = index" (click)="deleteSearch();global.searchMoveToPortfolio(portfolio.pf_id)">
								<td colspan="1" class="cursor-pointer vertical_align_middle"><span>{{portfolio.pf_id}}</span></td>
								<td colspan="2" class="cursor-pointer vertical_align_middle">
									<div class="ellipsis_0w w90p pull-left">
										<span class="fw-semi-bold">{{portfolio.name}}</span>
									</div>  
								</td>
							</tr>				    
						</tbody>
					</table>
				</div>
			</div>
	
			<div class="search_bar" *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'insight_admin' && appData.get('app_type') != 'climate_admin_tool' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'">
				<svg (click)="searchToggle();" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
			</div>
			<div class="notification_bar relative-position" *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'insight_admin' && appData.get('app_type') != 'climate_admin_tool' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'">
				<div class="popup_notification"></div>
				<svg [ngClass]="appData.get('app_name') == 'notifications' ? 'active': ''" (click)="global.goToApp('notifications')" width="24" height="24" viewBox="0 0 24 24">
					<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/>
				</svg>
			</div>
			<div class="user_bar">
				<div class="dropdown dropdown_v2">
					<div class="cursor-pointer dropdown-toggle" data-toggle="dropdown">
						<img *ngIf="appData.get('profileimg') != null" style="height: 32px;width: 32px;border: 2px solid white;margin-left: 32px;margin-top: -3px;border-radius: 20px;" src="{{appData.get('profileimg')}}" />
					</div>
					<div *ngIf="appData.get('profileimg') == null" class="user cursor-pointer icon dropdown-toggle" data-toggle="dropdown"></div>
					<ul class="user-dropdown dropdown-menu left">
						<li class="header border-bottom">
						   <div class="mb10 fs16 fw-500">
							{{appData.get('username')}}
						   </div> 
						   <div *ngIf="appData.get('app_type') != 'admin' && appData.get('user_role') == 'arc_pro'" class="fs15 fw-500">
							   <div class="arc_logo_racetrack"></div>
							   {{arc_pro_label}}
							</div> 
						</li>
						<li *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'" (click)="global.filterAccess('user-profile')">
							Profile
						</li>
						<li (click)="global.changePassword()">
							Change password
						</li>
						<li *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'" (click)="global.filterAccess('user-reports')">
						
							Reports
						</li>
						<li *ngIf="appData.get('app_type') != 'admin' && appData.get('app_type') != 'arc_japan_invoice' && appData.get('app_type') != 'cagbc_invoice'" (click)="global.filterAccess('user-billing')">
							Billing
						</li>
						<li (click)="global.logoutConfirm()">
							Sign out
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<nav class="page-controls navbar_info navbar-default" *ngIf="appData.get('current_nav') == 'app/projects/my-projects'">
	<div class="align_lr">
		<div class="flex">
			<h3 translate>Projects</h3>
			<button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.upgradeUser('purchase_arc_pro')" class="mt15 ml15 arc_pro_button btn btn-default">
				<div class="arc_logo_racetrack"></div>
				<a class="fw-500 fs14">Upgrade my account</a>
			</button>
			<div *ngIf="appData.get('user_role') == 'arc_pro'" class="mt22 ml15">
				<div class="arc_logo_racetrack"></div>
				<span class="fw-500 fs15">{{arc_pro_label}}</span>
			</div>
			<div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20">
				<div class="arc_gsa_logo"></div>
				<span class="fw-500 fs15">{{arc_gsa_label}}</span>
			</div>
		</div>
		<div class="nav_buttons">
			<button *ngIf="appData.get('project_type') == 'schools'" (click)="global.claimSchool()" class="blue outline mr25 mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Claim your School</span>
			</button>
			<button *ngIf="!(appData.get('project_type') == 'schools')" (click)="global.addProject(appData.get('project_type'))" class="blue outline mr25 mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Add a Project</span>
			</button>
			<button (click)="createProjects()" class="blue outline mr25 mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Add multiple projects</span>
			</button>
		</div>
		<div class="dropdown dropdown_v2 nav_buttons_dropdown">
			<div class="dropdown-toggle" data-toggle="dropdown">
				<button class="blue align-left outline mt15" translate>
					Add/Create
				</button>
			</div>
			<ul class="dropdown-menu left">
				<li (click)="global.addProject(appData.get('project_type'))">
					<a>
						<i class="mr5 fa fa-plus"></i>
						<span translate>Add a Project</span>
					</a>
				</li>
				<li (click)="global.goToApp('portfolio-creation')">
					<a>
						<i class="mr5 fa fa-plus"></i>
						<span translate>Create a portfolio</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<nav class="page-controls navbar_info navbar-default" *ngIf="appData.get('current_nav') == 'app/home/overview'">
	<div class="align_lr">
		<div class="flex">
			<h3 translate class="capitalize">{{appData.get('current_app')}}</h3>
			<button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.upgradeUser('purchase_arc_pro')" class="mt15 ml15 arc_pro_button btn btn-default">
				<div class="arc_logo_racetrack"></div>
				<a translate class="fw-500 fs14">Upgrade my account</a>
			</button>
			<div *ngIf="appData.get('user_role') == 'arc_pro'" class="mt22 ml15">
				<div class="arc_logo_racetrack"></div>
				<span class="fw-500 fs15">{{arc_pro_label}}</span>
			</div>
			<div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20">
				<div class="arc_gsa_logo"></div>
				<span class="fw-500 fs15">{{arc_gsa_label}}</span>
			</div>
		</div>
		<div class="nav_buttons">
			<button (click)="global.addProject('building')" class="blue outline mr25 mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Add a Project</span>
			</button>
			<button *ngIf="appData.get('current_nav') == 'app/home/overview'" (click)="global.goToApp('portfolio-creation')" class="blue outline mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Create a portfolio</span>
			</button> 
			<button *ngIf="appData.get('current_nav') == 'app/projects/my-projects'" (click)="createProjects()" class="blue outline mt15">
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				<span translate>Add multiple projects</span>
			</button>
		</div>
		<div class="dropdown dropdown_v2 nav_buttons_dropdown">
			<div class="dropdown-toggle" data-toggle="dropdown">
				<button class="blue outline mt15">
					<span translate>Add/Create</span> <i class="toggle fa fa-angle-down"></i>
				</button>
			</div>
			<ul class="dropdown-menu left">
				<li (click)="global.addProject('building')">
					<a>
						<i class="mr5 fa fa-plus"></i>
						<span translate>Add a Project</span>
					</a>
				</li>
				<li (click)="global.goToApp('portfolio-creation')">
					<a>
						<i class="mr5 fa fa-plus"></i>
						<span translate>Create a portfolio</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<nav class="page-controls navbar_info navbar-default" *ngIf="appData.get('current_app') == 'selected_portfolio'">
	<div class="align_lr">
		<div class="flex">
			<div>
				<h4 class="mr15 mb5 fw-400 fs17 mt13 project_name_banner">{{appData.get('portfolio').name}}</h4>
				<div>
					<span class="fs13 gray_color_707479">{{appData.get('portfolio').pf_id}}</span>
				</div>
			</div>
			<button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.upgradeUser('purchase_arc_pro')" class="mt15 ml20 arc_pro_button btn btn-default">
				<div class="arc_logo_racetrack"></div>
				<a class="fw-500 fs14">Upgrade my account</a>
			</button>

			<div *ngIf="appData.get('user_role') == 'arc_pro'" class="arc_essential_label mt20 ml20">
				<div class="arc_logo_racetrack"></div>
				<span class="fw-500 fs15">{{arc_pro_label}}</span>
			</div>

			<div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20">
				<div class="arc_gsa_logo"></div>
				<span class="fw-500 fs15">{{arc_gsa_label}}</span>
			</div>
		</div>
		<div>
			<button [ngClass]="config.portfolio_access != 'can_edit' || appData.get('is_admin') ? 'not-active' : ''" (click)="addProjectToPortfolioModal()" class="blue outline mt15">
				<img class="plus_icon" src="../../assets/images/plus.svg">
				Add a project
			</button>
		</div>
	</div>
</nav>

<nav class="page-controls navbar_info navbar-default"  *ngIf="['user-billing', 'user-reports', 'user-profile'].indexOf(appData.get('current_app')) != -1">
	<div class="flex">
		<img *ngIf="appData.get('profileimg') != null" style="height: 40px;width: 40px;margin-right: 16px;margin-right: 16px;margin-top: 10px;border-radius: 20px;" src="{{appData.get('profileimg')}}" />
		<h3 class="mt10 fw-400 mr15">{{global.getUsername()}}</h3>
		<button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.filterAccess('app.arc_pro_purchase')" class="mt15 arc_pro_button btn btn-default">
			<div class="arc_logo_racetrack"></div>
			<a class="fw-500 fs14">Upgrade my account</a>
		</button>
		<div *ngIf="appData.get('user_role') == 'arc_pro'" class="mt20">
			<div class="arc_logo_racetrack"></div>
			<span class="fw-500 fs15">{{arc_pro_label}}</span>
		</div>
		<div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20">
			<div class="arc_gsa_logo"></div>
			<span class="fw-500 fs15">{{arc_gsa_label}}</span>
		</div>
	</div>
</nav>

<modal #modal *ngIf="appData.get('current_app') == 'selected_portfolio'">

	<div class="modal-header pl25">
		<h5 class="fw-semi-bold fs1_25em">Add a project</h5>
	</div>

	<div class="modal-body pl25 pb30">    
		<app-add-project (close)="modal.close()"></app-add-project>
		<div class="row ml8">
			<button class="btn fs15 btn-default" (click)="modal.close()">
				Close
			</button>
		</div>
	</div>

</modal>

<modal #create_projects *ngIf="appData.get('current_nav') == 'app/projects/my-projects'">
	<app-create-multiple-projects (close)="closeModal()"></app-create-multiple-projects>
</modal>
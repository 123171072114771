<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="row">
            <div class="col-md-12">
                <h1 class="page-title pl0 pb10">Surveys Update<span class="fw-semi-bold"></span></h1>
                <!-- <div class="pb10">
                    <label for="normal-field" class="control-label mr10">Survey type</label>
                    <div class="dropdown display-inline">
                        <button class="btn dropdown-toggle btn-default" type="button"
                            data-toggle="dropdown">
                            <span class="pr5">{{survey_value}}</span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li (click)="changeSurveyType('transportation', 'Transportation')" ><a>Transportation</a></li>
                            <li (click)="changeSurveyType('human_experience', 'Human Experience')" ><a>Human Experience</a></li>
                        </ul>
                    </div>
                </div> -->
                <section class="widget overflow-auto">
                    <div class="widget-body">
                        <h5 class="mb20">Please use this <a href="../../assets/pdf/Survey-Update.pdf" download>guide</a> to learn how to use this tool.</h5>
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="header_tab form-group ml0 w100p">
                                    <div (click)="toggleTab('Transfer')" [ngClass]="current_tab == 'Transfer' ? 'active' : ''" class="tab_item">
                                        Transfer
                                    </div>
                                    <div (click)="toggleTab('Update')" [ngClass]="current_tab == 'Update' ? 'active' : ''" class="tab_item">
                                        Update
                                    </div>
                                    <div (click)="toggleTab('Delete')" [ngClass]="current_tab == 'Delete' ? 'active' : ''" class="tab_item">
                                        Delete
                                    </div>
                                </div>
                                <div class="form-group col-sm-12">
                                    <label for="normal-field" class="col-sm-4 control-label">Survey type</label>
                                    <div class="col-sm-4 dropdown display-inline">
                                        <button class="btn dropdown-toggle btn-default" type="button"
                                            data-toggle="dropdown">
                                            <span class="pr5">{{survey_value}}</span>
                                            <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="changeSurveyType('transportation', 'Transportation')" ><a>Transportation</a></li>
                                            <li (click)="changeSurveyType('human_experience', 'Human Experience')" ><a>Human Experience</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Transfer'">
                                    <label for="normal-field" class="col-sm-4 control-label">Source Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                    <div class="col-sm-6" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="source_leed_id" (input)=checkInputs() type="text" name="source_leed_id"
                                            class="form-control" placeholder="Enter Property ID or LEED ID">
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Transfer'" >
                                    <label for="normal-field" class="col-sm-4 control-label">Target Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                    <div class="col-sm-6" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="destination_leed_id" (input)=checkInputs() name="destination_leed_id" type="text" class="form-control"
                                            placeholder="Enter Property ID or LEED ID">
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Transfer'">
                                    <label class="col-sm-4 control-label" for="prepended-input">Range (Optional)</label>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()" (input)="checkDates()" class="form-control input-sm dateRange"
                                        [(ngModel)]="start_date" name="start_date" id="start_date">
                                    </div>
                                    <span class="pull-left"> to </span>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()" (input)="checkDates()" class="form-control input-sm dateRange"
                                        [(ngModel)]="end_date" name="end_date" id="end_date">
                                    </div>
                                    <div *ngIf="overlapping_date" class="mt5">
                                        <p class="reading_error_label floatNone" [ngClass]="overlapping_date ? 'Showing' : ''">{{overlapping_error_text}}</p>
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Transfer'">
                                    <label class="col-sm-4 control-label" for="prepended-input"></label>
                                    <div class="col-sm-4">
                                        <button (click)="openModal()" class="btn btn-default">Transfer</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                                <!-- Update tab -->
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                    <div class="col-sm-6" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="leed_id_update" type="text" name="leed_id_update"
                                            class="form-control" placeholder="Enter Property ID or LEED ID">
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label class="col-sm-4 control-label" for="prepended-input">Range (Optional)</label>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()"  class="form-control input-sm dateRange" name="start_date"
                                        [(ngModel)]="start_date" id="start_date">
                                    </div>
                                    <span class="pull-left"> to </span>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()" class="form-control input-sm dateRange" name="end_date"
                                        [(ngModel)]="end_date" id="end_date">
                                    </div>
                                    <div *ngIf="overlapping_date" class="mt5">
                                        <p class="reading_error_label floatNone" [ngClass]="overlapping_date ? 'Showing' : ''">{{overlapping_error_text}}</p>
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label for="normal-field" class="col-sm-4 control-label">New Survey Date</label>
                                    <div class="col-sm-4" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="new_survey_date" [ngInit]="enableFdatePicker()" name="new_survey_date"
                                            class="form-control" id="new_survey_date">
                                    </div>
                                </div>
                                <!-- <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label for="normal-field" class="col-sm-2 control-label">Occupant Category</label>
                                    <div class="col-sm-7" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="occupant_category" type="text" name="occupant_category"
                                            class="form-control" placeholder="Enter occupant catergory">
                                    </div>
                                </div> -->
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label for="normal-field" class="col-sm-4 control-label"></label>
                                    <div class="col-sm-4" [ngClass]="loader ? 'not-active' : ''">
                                        OR
                                    </div>
                                </div>
                                <div class="form-group col-sm-12" *ngIf="current_tab == 'Update'">
                                    <label for="normal-field" class="col-sm-4 control-label">Occupant Category</label>
                                    <div class="dropdown display-inline col-sm-4">
                                        <button class="btn dropdown-toggle btn-default" type="button"
                                            data-toggle="dropdown">
                                            <span class="pr5">{{occupant_value}}</span>
                                            <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="changeOccupantType('regular_occupant', 'Regular Occupant')" ><a>Regular Occupant</a></li>
                                            <li (click)="changeOccupantType('visitor_occupant', 'Visitor Occupant')" ><a>Visitor Occupant</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 pt30" *ngIf="current_tab == 'Update'">
                                    <label class="col-sm-4 control-label" for="prepended-input"></label>
                                    <div class="col-sm-4" [ngClass]="loader ? 'not-active' : ''">
                                        <button (click)="openModal()" [disabled]="emptyInputs" class="btn btn-default">Update</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                                
                                <!-- Delete tab -->
                                <div class="form-group" *ngIf="current_tab == 'Delete'">
                                    <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                    <div class="col-sm-6" [ngClass]="loader ? 'not-active' : ''">
                                        <input [(ngModel)]="leed_id_delete" name="leed_id_delete" type="text"
                                            class="form-control" placeholder="Enter Property ID or LEED ID">
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="current_tab == 'Delete'">
                                    <label class="col-sm-4 control-label" for="prepended-input">Range (Optional)</label>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()" (input)="checkDates()" class="form-control input-sm dateRange" name="start_date"
                                        [(ngModel)]="start_date" id="start_date">
                                    </div>
                                    <span class="pull-left"> to </span>
                                    <div class="col-sm-3">
                                        <input [ngInit]="enableFdatePicker()" (input)="checkDates()" class="form-control input-sm dateRange overlapping_date"  name="end_date"
                                        [(ngModel)]="end_date" id="end_date">
                                    </div>
                                    <div *ngIf="overlapping_date" class="mt5">
                                        <p class="reading_error_label floatNone" [ngClass]="overlapping_date ? 'Showing' : ''">{{overlapping_error_text}}</p>
                                    </div>
                                    <!-- <div *ngIf="!overlapping_date" class="mt5">
                                        <p class="reading_error_label floatNone" [ngClass]="overlapping_date ? 'Showing' : ''"></p>
                                    </div> -->
                                </div>
                                <div class="form-group" *ngIf="current_tab == 'Delete'">
                                    <label class="col-sm-4 control-label" for="prepended-input"></label>
                                    <div class="col-sm-3" [ngClass]="response_loader ? 'not-active' : ''">
                                        <button (click)="getAllResponses(page, page_size)" [disabled]="emptyInputs" class="btn btn-default">Get Responses</button>
                                        <div *ngIf="response_loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                                <div class="flex" *ngIf="leed_id_delete && is_response_available == true">
                                    <div class="checkbox mr5 blue_check select_all">
                                        <input (change)="toggleCheck('all')" [(ngModel)]="select_all" name="select_all" type="checkbox" value="select_all">                
                                        <label for="checkbox"></label>
                                    </div>
                                    <div class="responses_list">
                                        <span class="fw-500">Showing: </span>
                                        <span class="fw600">{{responses.length}} of {{count}} responses</span>
                                    </div>
                                </div>
                                <table class="table table-striped mt30" *ngIf="leed_id_delete && survey_type == 'transit' && is_response_available == true">
                                    <thead>
                                        <tr>
                                            <th class="w10p">Submitted at</th>
                                            <th class="w5p align-center">walk</th>
                                            <th class="w5p align-center">bike</th>
                                            <th class="w10p align-center">telecommute</th>
                                            <th class="w5p align-center">bus</th>
                                            <th class="w10p align-center">light rail</th>
                                            <th class="w10p align-center">heavy rail</th>
                                            <th class="w5p align-center">motorcycle</th>
                                            <th class="w5p align-center">car</th>
                                            <th class="w5p align-center">car23</th>
                                            <th class="w5p align-center">cars4</th>
                                            <th class="w10p align-center">Occupant Category</th>
                                            <th class="unselectable align-center">Select to delete</th>
                                        </tr>
                                    </thead>
                                    <tbody [hidden]="!leed_id_delete" *ngIf="is_response_available == true">
                                        <!-- <div *ngIF="(responses !== undefined && responses?.length > 0)">  -->
                                        <tr *ngFor="let response of responses; let idx = index" class="project_row" id="{{response.id}}">
                                            <td>{{response.created_at}}</td>
                                            <td class="align-center">{{response.routes[0].walk}}</td>
                                            <td class="align-center">{{response.routes[0].bike}}</td>
                                            <td class="align-center">{{response.routes[0].telecommute}}</td>
                                            <td class="align-center">{{response.routes[0].bus}}</td>
                                            <td class="align-center">{{response.routes[0].light_rail}}</td>
                                            <td class="align-center">{{response.routes[0].heavy_rail}}</td>
                                            <td class="align-center">{{response.routes[0].motorcycle}}</td>
                                            <td class="align-center">{{response.routes[0].car}}</td>
                                            <td class="align-center">{{response.routes[0].car23}}</td>
                                            <td class="align-center">{{response.routes[0].cars4}}</td>
                                            <td class="align-center">
                                                <div *ngIf="response.occupant_category == 'regular_occupant'">Regular Occupant</div>
                                                <div *ngIf="response.occupant_category == 'visitor_occupant'">Visitor Occupant</div>
                                                <div *ngIF="!response.occupant_category">-</div>
                                            </td>
                                            <td class="align-center" (click)="toggleCheck(idx)">
                                                <div class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
                                                    <input [(ngModel)]="response.select_all" name="{{response.id}}" type="checkbox">                
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                                <table class="table table-striped mt30" *ngIf="leed_id_delete && survey_type == 'environment' && is_response_available == true">
                                    <thead>
                                        <tr>
                                            <th class="w20p">Submitted at</th>
                                            <th class="w20p align-center">Satisfaction</th>
                                            <th class="w20p align-center">Feedbacks</th>
                                            <th class="w20p align-center">Occupant Category</th>
                                            <th class="w20p unselectable align-center">Select to delete</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="leed_id_delete && is_response_available == true">
                                        <tr *ngFor="let response of responses; let idx = index" class="project_row">
                                            <td>{{response.created_at}}</td>
                                            <td class="align-center">{{response.satisfaction}}</td>
                                            <td class="align-center">{{response.feedbacks}}</td>
                                            <td class="align-center">
                                                <div *ngIf="response.occupant_category == 'regular_occupant'">Regular Occupant</div>
                                                <div *ngIf="response.occupant_category == 'visitor_occupant'">Visitor Occupant</div>
                                                <div *ngIF="!response.occupant_category">-</div>
                                            </td>
                                            <td (click)="toggleCheck(idx)" class="align-center">
                                                <div class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
                                                    <input [(ngModel)]="response.select_all" name="{{response.id}}" value="response.select_all" type="checkbox">                
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                                <div class="align_r list_controls fw-500" *ngIf="leed_id_delete && is_response_available == true">
                                    <div class="dropdown display-inline ml10">
                                        <span class="mr10"><span translate>Rows per page</span>:</span>
                                        <button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
                                            <span>{{page_size}}</span>
                                            <i class="fa fontSize16 ml10 fa-caret-down"></i>
                                        </button>
                                        <ul class="dropdown-menu" style="top: -122px;">
                                            <li (click)="getAllResponses(1, 10, ordering)">
                                                <a>10</a>
                                            </li>
                                            <li (click)="getAllResponses(1, 20, ordering)">
                                                <a>20</a>
                                            </li>
                                            <li (click)="getAllResponses(1, 30, ordering)">
                                                <a>30</a>
                                            </li>
                                            <li (click)="getAllResponses(1, 40, ordering)">
                                                <a>40</a>
                                            </li>
                                            <li (click)="getAllResponses(1, 50, ordering)">
                                                <a>50</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex pt10 ml40" *ngIf="leed_id_delete && is_response_available == true" [hidden]="!leed_id_delete">
                                        <span class="mr30">
                                            Page {{page}} of {{page_count}}
                                        </span>
                                        <div class="flex">
                                            <div (click)="getAllResponses(page - 1, page_size, ordering)" [ngClass]="(page - 1) == 0 ? 'not-active' : ''">
                                                <i class="mr40 fa fa-chevron-left"></i>
                                            </div>
                                            <div (click)="getAllResponses(page + 1, page_size, ordering)" [ngClass]="page == page_count ? 'not-active' : ''">
                                                <i class="fa fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="leed_id_delete && is_response_available == true">
                                    <div class="col-sm-3" [ngClass]="loader ? 'not-active' : ''">
                                        <button (click)="openModal()" [disabled]="emptyInputs" class="btn btn-default">Delete</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<modal #modal>
    <div class="p20">
        <div>
            <div class="fs14 line_height_40 " *ngIf="current_tab == 'Delete'">
                Are you sure you want to delete the selected responses? 
            </div> 
            <div class="fs14 line_height_40 " *ngIf="current_tab == 'Transfer'">
                Are you sure you want to transfer the responses?
            </div> 
            <div class="fs14 line_height_40 " *ngIf="current_tab == 'Update'">
                Are you sure you want to update the responses?
            </div> 
        </div>
        <div>
            <button (click)="closeModal()" data-dismiss="modal" class="btn btn-default mr10">Cancel</button>
            <button (click)="deleteSelectedResponses()" *ngIf="current_tab == 'Delete'" data-dismiss="modal" class="btn btn-default mr10">Delete</button>
            <button (click)="transferSurveyData()" *ngIf="current_tab == 'Transfer'" data-dismiss="modal" class="btn btn-default mr10">Transfer</button>
            <button (click)="updateSurveyData()" *ngIf="current_tab == 'Update'" data-dismiss="modal" class="btn btn-default mr10">Update</button>
        </div>
    </div>
    
</modal>

<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="">
            <h1 class="page-title pl0">Data Transfer<span class="fw-semi-bold"></span></h1>
            <section class="widget overflow-auto">
                <header>
                    <h5 class="mb30 pt10">Enter the below details to transfer data from <span class="fw-500 underline">source</span> property to
                        <span class="fw-500 underline">target</span> property. Please use this <a href="../../assets/pdf/Data Transfer.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-5 control-label">Source Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                <div class="col-sm-5">
                                    <input [(ngModel)]="source_leed_id" type="text" id="normal-field" class="form-control"
                                        placeholder="Enter Property ID or LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-5 control-label">Target Property ID (starting with 4) or LEED ID (starting with 1 or 8)</label>
                                <div class="col-sm-5">
                                    <input [(ngModel)]="destonation_leed_id" type="text" id="normal-field"
                                        class="form-control" placeholder="Enter Property ID or LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-5 control-label" for="prepended-input"></label>
                                <div class="col-sm-5">
                                    <div [ngClass]="loader ? 'not-active' : ''">
                                        <ul style="padding-left: 0px;" class="wizard display-block pull-left mr10">
                                            <li class="next list_style_none align-right">
                                                <button (click)="transferData()" class="btn btn-default">Transfer</button>
                                            </li>
                                        </ul>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
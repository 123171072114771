import { Component, OnInit, Input, EventEmitter, Output, HostListener, ViewChild } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { Projects } from '../services/projects.service';
import { API } from '../services/api.service';
import { alertService } from '../services/alert-service.service';

import { Config } from '../models/env-vars.model';

import * as $ from 'jquery';
import * as stream from 'getstream';
import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from '../components/modal/modal.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit
{
	config = Config;
	@Input() type: string = 'projects';
	@Input() selected_portfolio: any = null;
	@Output() project_selection = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();

	page: number = 1;
	page_size: number = (this.type == 'projects' || this.type == 'portfolio_list' ? 30 : 10);
	count: number = 0;
	page_count: number = 1;
	total_filters: number = 0;

	ordering: string = '';
	sort_order: string = '';
	project_type: string | null = this.appData.get('project_type');

	filters: any = null;
	next_page: string | null = null;
	sort_column: string = '';

	loading: boolean = false;
	project_loader: boolean = false;
	select_all: boolean = false;
	accept_agreement_flag: boolean = false;

	myprojects: any = [];
	portfolios: any = [];
	selected_portfolios: any = [];
	selected_projects: any = [];
	state_list: any = [];
	filter_country: any = [];
	selected_country: any = [];
	selected_country_name: any = [];
	selected_state: any = [];
	selected_state_name: any = [];
	selected_space_type: any = [];
	selected_building_status: any = [];
	selected_certification: any = [];
	selected_tags: any = [];
	building_tags: any = [];

	country_list: any;
	building_status: string[] = ["Activated", "Not-Activated"];
	certifications: string[] = ["Platinum", "Gold", "Silver", "Certified", "Pre-Certified", "Not-Certified"];
	spaceType: any = [];

	arc_pro_label = Config.arc_pro_label
	arc_gsa_label = Config.arc_gsa_label
	loading_more_portfolios: boolean = false;
	nextPagePortfolios: any = null;

	project_selected: any = {};
	@ViewChild('modal_estimate') private modal_estimate!: ModalComponent

	constructor(
		public global: Global,
		public appData: AppData,
		private projects: Projects,
		private api: API,
		private alertService: alertService
	)
	{
		this.initFeed(this.appData.get('user_pkey'), false);
	}

	ngOnInit(): void
	{
		this.page_size = (this.type == 'projects' || this.type == 'portfolio_list' ? 30 : 10);
		if(this.type == 'portfolio_list')
		{
			this.appData.set('project_type', 'building,school');
		}
		this.getProjects(1, this.page_size, (this.sort_order + this.sort_column));
		this.api.getAsset(
			'../../assets/json/spaceTypes.json'
		).subscribe(data => 
		{
			this.spaceType = data;
		});

		this.api.get(
			'/projects/tags/'
		).subscribe(data => 
		{
			for (let i = data.length - 1; i >= 0; i--)
			{
				this.building_tags.push(data[i]['value']);
			}
		});

		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.country_list = this.global.getCountryList();
			});
		}
		else
		{
			this.country_list = this.global.getCountryList()
		}

		if(this.selected_portfolio != undefined)
		{
			this.selectPortfolio(this.selected_portfolio);
		}
		
		this.getPortfolios();
	}

	onWindowScroll(event: any)
	{
		if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1)
		{
			if(this.loading_more_portfolios) return;
			if(this.nextPagePortfolios == null)
			{
				return;
			}
			this.loading_more_portfolios = true;
			this.api.get('/portfolios/?page_size=50&' + this.nextPagePortfolios).subscribe(
				//success handler
				data =>
				{
					for(var i in data.results)
					{
						this.portfolios.push(data.results[i]);
					}
					this.loading_more_portfolios = false;
					if(data.next == null)
					{
						this.nextPagePortfolios = null;
					}
					else
					{
						this.nextPagePortfolios = data.next.split('?')[data.next.split('?').length - 1];
					}
				},
				//error handler
				error =>
				{
					this.loading_more_portfolios = false;
				}
			);
		}
	}

	removeFeed(key: string)
	{
		var url = "/portfolios/myportfolios/notifications/?feed=" + String(key);

		this.api.delete(url, {}).subscribe(
			data =>
			{}
		);
	};

	initFeed(key: string, refresh: boolean)
	{
		this.loading = true;
		this.api.get(
			'/portfolios/'+ key +'/notifications/'
		).subscribe(
			response =>
			{
				let feeds = [];
				this.loading = false;
				
				for(var i = 0; i < response.activities.length; i++)
				{
					feeds.push(response.activities[i]);

					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						this.removeFeed(response.activities[i].foreign_id);
					}
				}
				if(feeds.length > 0)
				{
					this.config.stream_upload = true;
					this.global.showTippy();

					setTimeout(() =>
					{
						this.initFeed(this.appData.get('user_pkey'), true);
					}, 5000);
				}
				else
				{
					this.config.stream_upload = false;
					this.config.show_refresh = refresh;
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	createPortfolio()
	{
		this.appData.set("selected_projects_session", this.selected_projects);
		this.global.goToApp('portfolio-creation');
	};

	selectPortfolio(portfolio: any)
	{
		if(this.selected_portfolios.indexOf(portfolio.pf_id) == -1)
		{
			this.selected_portfolios.push(portfolio.pf_id);
			$("input[name='"+ portfolio.pf_id +"']").prop('checked', true);
		}
		else
		{
			this.selected_portfolios.splice(this.selected_portfolios.indexOf(portfolio.pf_id), 1);
			$("input[name='"+ portfolio.pf_id +"']").prop('checked', false);
		}
	};
	
	getPortfolios()
	{
		this.projects.getPortfolios().subscribe(data =>
		{
			this.portfolios = data.results;
			if(data.next != null)
				this.nextPagePortfolios = data.next.split('?')[data.next.split('?').length - 1];
		});  
	};

	addToPortfolio()
	{
		if(this.selected_portfolios.length == 0)
		{
			this.selectPortfolio(this.appData.get('portfolio'));
		}
		Config.app_loader = true;
		this.global.addToPortfolio(
			this.selected_portfolios,
			this.selected_projects
		).subscribe(
			data =>
			{
				this.close.emit('close');
				this.selected_portfolios = [];
				Config.app_loader = false;
				$("#portfolio_filters input[type='checkbox']").prop('checked', false);
				this.alertService.alert('success', 'Projects added to portfolio', 5);
				window.location.reload();
			},
			error =>
			{
				this.close.emit('close');
				Config.app_loader = false;
				$("#portfolio_filters input[type='checkbox']").prop('checked', false);
			}
		);
	}

	resetFilters()
	{
		this.state_list = [];
		this.filter_country = '';
		this.selected_country = [];
		this.selected_country_name = [];
		this.selected_state = [];
		this.selected_state_name = [];
		this.selected_space_type = [];
		this.selected_building_status = [];
		this.selected_certification = [];
		this.selected_projects = [];
		this.selected_tags = [];

		$("#project_filters input[type='checkbox']").prop('checked', false);

		this.getProjects(1, 30, this.sort_order + this.sort_column);
	};

	selectCountry(country: any)
	{
		if(this.selected_country.indexOf(country.code) == -1)
		{
			this.selected_country.push(country.code);
			this.selected_country_name.push(country.name);
			$("input[name='"+ country.code +"']").prop('checked', true);
		}
		else
		{
			this.selected_country.splice(this.selected_country.indexOf(country.code), 1);
			this.selected_country_name.splice(this.selected_country_name.indexOf(country.name), 1);
			$("input[name='"+ country.code +"']").prop('checked', false);
		}

		if(this.selected_country.length == 0 || this.selected_country.length > 1)
		{
			this.state_list = [];
			this.selected_state = [];
			this.selected_state_name = [];
		}
		else if(this.selected_country.length == 1)
		{
			this.state_list = this.global.getStateList(this.selected_country[0]);
			this.selected_state = [];
			this.selected_state_name = [];
		}
	};

	selectState(state: any)
	{
		if(this.selected_state.indexOf(state.code) == -1)
		{
			this.selected_state.push(state.code);
			this.selected_state_name.push(state.name);
			state.select_all = true;
		}
		else
		{
			this.selected_state.splice(this.selected_state.indexOf(state.code), 1);
			this.selected_state_name.splice(this.selected_state_name.indexOf(state.name), 1);
			state.select_all = false;
		}
	};

	selectSpaceType(space: any)
	{
		if(this.selected_space_type.indexOf(space) == -1)
		{
			this.selected_space_type.push(space);
			$("input[name='"+ space +"']").prop('checked', true);
		}
		else
		{
			this.selected_space_type.splice(this.selected_space_type.indexOf(space), 1);
			$("input[name='"+ space +"']").prop('checked', false);
		}
	};

	selectStatus(status: string)
	{
		if(this.selected_building_status.indexOf(status) == -1)
		{
			this.selected_building_status.push(status);
			$("input[name='"+ status +"']").prop('checked', true);
		}
		else
		{
			this.selected_building_status.splice(this.selected_building_status.indexOf(status), 1);
			$("input[name='"+ status +"']").prop('checked', false);
		}
	};

	selectCert(cert: string)
	{
		if(this.selected_certification.indexOf(cert) == -1)
		{
			this.selected_certification.push(cert);
			$("input[name='"+ cert +"']").prop('checked', true);
		}
		else
		{
			this.selected_certification.splice(this.selected_certification.indexOf(cert), 1);
			$("input[name='"+ cert +"']").prop('checked', false);
		}
	};

	changeFilter(page: number, page_size: number, ordering: string)
	{
		if(this.select_all)
		{
			this.getAllIDs();
		}
		this.getProjects(page, page_size, ordering);
	};

	getFilters()
	{
		var country = (this.selected_country.length == 0 ? '' : this.selected_country.join(","));
		var state = this.selected_state.join(",");
		var spaceType = this.selected_space_type.join(",");
		var certification = this.selected_certification.join(",");
		var building_status = this.selected_building_status.join(",");
		var tags = this.selected_tags.join(",");

		country = country.length == 0 ? '' : "&country=" + country;
		state = state.length == 0 ? '' : "&state=" + state;
		spaceType = spaceType.length == 0 ? '' : "&spaceType=" + spaceType;
		tags = tags.length == 0 ? '' : "&tags=" + tags;
		certification = certification.length == 0 ? '' : "&certification=" + certification;
		if(building_status.length > 0 && building_status == 'Not-Activated')
		{
			building_status = "&building_status=activated_payment_pending,setup_incomplete,activated_addendum_agreement_pending,deactivated,activated_timeline_pending";
		}
		else if(building_status.length > 0 && building_status == 'Activated')
		{
			building_status = "&building_status=activated_payment_done,activated_under_review";
		}
		else
		{
			building_status = "";
		}

		this.total_filters = this.selected_country.length + this.selected_state.length + this.selected_space_type.length + this.selected_certification.length + this.selected_building_status.length + this.selected_tags.length;

		return country + state + spaceType + certification.toLowerCase().replace("not-certified", "denied,") + building_status + tags;
	};

	selectTags(tag: string)
	{
		if(this.selected_tags.indexOf(tag) == -1)
		{
			this.selected_tags.push(tag);
			$("input[name='"+ tag +"']").prop('checked', true);
		}
		else
		{
			this.selected_tags.splice(this.selected_tags.indexOf(tag), 1);
			$("input[name='"+ tag +"']").prop('checked', false);
		}
	};

	getAllIDs()
	{
		this.global.removeDynamicDiv();
		let filters = this.getFilters();
		filters += "&leed_id_only=true";
		this.filters = filters;
		this.loading = true;
		this.projects.getProjects(1, 0, this.ordering, this.appData.get('project_type'), this.filters, this.type).subscribe(
			data =>
			{
				this.selected_projects = [];
				for(let i = 0; i < data.length; i++)
				{
					if(this.selected_projects.indexOf(data[i].leed_id) == -1)
					{
						this.selected_projects.push(data[i].leed_id);
					}
				}
				this.loading = false;
				this.project_selection.emit(this.selected_projects);
			}
		);
	};

	toggleCheck(index: string | number)
	{
		if(index == 'all')
		{
			if(!this.select_all)
			{
				this.selected_projects = [];
			}
			else
			{
				this.getAllIDs();
			}
			for (var i = 0; i < this.myprojects.length; i++)
			{
				this.myprojects[i].select_all = this.select_all;
			}
		}
		else
		{
			this.myprojects[index].select_all = !this.myprojects[index].select_all;
			if(!this.myprojects[index].select_all)
			{
				this.select_all = false;
			}
		}

		for (var i = 0; i < this.myprojects.length; i++)
		{
			if(this.myprojects[i].select_all && this.selected_projects.indexOf(this.myprojects[i].leed_id) == -1)
			{
				this.selected_projects.push(this.myprojects[i].leed_id);
			}
			else if(!this.myprojects[i].select_all && this.selected_projects.indexOf(this.myprojects[i].leed_id) > -1)
			{
				this.selected_projects.splice(this.selected_projects.indexOf(this.myprojects[i].leed_id), 1)
			}
		}

		if(this.selected_projects.length == this.count)
		{
			this.select_all = true;
		}

		if(this.type == 'projects' || this.type == 'portfolio_list')
		{
			if(this.selected_projects.length == 0)
			{
				$('#bottom_nav').slideUp();
				$('.projectListWrapper').css('margin-bottom', '30px');
			}
			else
			{
				$('#bottom_nav').slideDown();
				$('.projectListWrapper').css('margin-bottom', '65px');
			}
		}

		this.project_selection.emit(this.selected_projects);
	};

	getProjects(page: number, page_size: number, ordering: string)
	{
		this.global.removeDynamicDiv();
		if(this.page_size == 0 || this.page_size < 10)
		{
			this.page_size = 30;
		}
		
		this.filters = this.getFilters();
		$("#project_filters").hide();
		if(this.page > this.page_count || this.page <= 0)
		{
			return;
		}
		
		this.page = page;
		this.page_size = page_size;
		this.ordering = ordering;
		
		this.project_loader = true;
		this.projects.getProjects(this.page, this.page_size, this.ordering, this.appData.get('project_type'), this.filters, this.type).subscribe(response =>
		{
			this.selected_portfolios = [];
			this.selected_projects = [];
			this.select_all = false;
			$('#bottom_nav').slideUp();
			if(this.page_size == 0)
			{
				var asset_list = response;
			}
			else
			{
				var asset_list = response.results;
			}
			for (var i = 0; i < asset_list.length; i++)
			{
				if (asset_list[i].activation_source == 'GRESB')
				{
					asset_list[i]['is_gresb'] = true;
					if (asset_list[i].building_status != 'activated_addendum_agreement_pending')
					{
						asset_list[i]['is_gresb_activated'] = true;
					}
					else
					{
						asset_list[i]['is_gresb_activated'] = false;
					}
				}
				else
				{
					asset_list[i]['is_gresb'] = false;
				}
				asset_list[i]['select_all'] = false;
			}

			this.count = response.count;
			this.page = this.page;
			this.page_size = this.page_size > this.count ? this.count : this.page_size;
			this.page_count = Math.ceil(this.count / this.page_size);

			for(var i = 0; i < response.results.length; i++)
			{
				if(this.selected_projects.indexOf(response.results[i].leed_id) > -1 || this.select_all)
				{
					response.results[i].select_all = true;
				}
			}

			this.myprojects = response.results;
			if (response.next == null)
			{
				this.next_page = null;
			}
			else
			{
				this.next_page = response.next.split('?page=')[1];
			}
			this.project_loader = false;
		});
  	}

	sortList(sort_order: string, sort_column: string)
	{
		if(sort_column == this.sort_column)
		{
			this.sort_order = sort_order == '-' ? '' : '-';
		}
		else
		{
			this.sort_order = '-';
			this.sort_column = sort_column;
		}
		this.getProjects(1, this.page_size, this.sort_order + this.sort_column);
	}

	openEstimateModal(project?: any)
	{
		this.project_selected = project;
		this.modal_estimate.open({ windowClass: 'estimate_modal', size: 'lg' });
	};

	cancel()
	{
		this.modal_estimate.close();
	}
}

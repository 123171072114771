<div id="payment-details-wrapper">
    <app-loader *ngIf="loading"></app-loader>
    <div class="content-wrap">
        <div id="content" class="content">

            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">Claim a School</h4>            
                </div>
                <div (click)="goBack()" class="pt20">
                    <svg class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>

            <div class="floatNone col-sm-12 pt15" style="z-index: 1;">
                <section class="claim_school">
                    <div class="input-group input-group-no-border w100p">
                        <span class="input-group-addon">
                            <i class="fa fa-search"></i>
                        </span>
                        <input (input)="searchSchool(query)" [(ngModel)]="query" type="text" class="form-control floatNone" placeholder="Search by School Name or Address">
                    </div>
                </section>
                <section *ngIf="searchInit" [ngClass]="searching ? 'not-active' : ''" class="widget">
                    <div class="projectList--toolsAndInfoWrapper">
                        <div class="floatNone col-lg-6 col-sm-6 col-md-6 projectList--numOfProjectsWrapper w100p mb25">
                            <span class="projectList--numOfProjectsLabel">Showing: </span>
                            <span class="projectList--numOfProjectsCount">{{myschools.length}} of {{global.handleNaN(searchParams.total)}} Schools</span>
        
                        </div>
                    </div>
                    <div class="noProjects" *ngIf="myschools.length == 0">
                        Not seeing your school? Make sure you spelled the name correctly based on the National Center for Education Statistics (NCES) listing. Still having trouble, email <a (click)="global.openEmail('contact@arcskoru.com')">contact@arcskoru.com </a> for help.
                    </div>
                    <app-loader *ngIf="searching"></app-loader>
                    <table [hidden]="myschools.length == 0" class="table table-sorted arcTbl">
                        <thead>
                            <tr >
                                <th class="arcTbl--header vertical_align_middle">
                                    <div (click)="changeSortOrder('sch_name')">
                                        <span class="fa fa-caret-up" *ngIf="searchParams.category == 'sch_name' && searchParams.order == 'asec'"></span>
                                        <span class="fa fa-caret-down" *ngIf="searchParams.category == 'sch_name' && searchParams.order == 'desc'"></span>
                                        <span>Name</span>
                                    </div>
                                </th>
                                <th class="arcTbl--header vertical_align_middle w20p">
                                    <div (click)="changeSortOrder('mstreet1')">
                                        <span class="fa fa-caret-up" *ngIf="searchParams.category == 'mstreet1' && searchParams.order == 'asec'"></span>
                                        <span class="fa fa-caret-down" *ngIf="searchParams.category == 'mstreet1' && searchParams.order == 'desc'"></span>
                                        <span>Location</span>
                                    </div>
                                </th>
                                <th class="arcTbl--header vertical_align_middle">
                                    <div>
                                        <span class="fa fa-caret-up" *ngIf="searchParams.category == 'claimed_by' && searchParams.order == 'asec'"></span>
                                        <span class="fa fa-caret-down" *ngIf="searchParams.category == 'claimed_by' && searchParams.order == 'desc'"></span>
                                        <span>Status</span>
                                    </div>
                                </th>
                                
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let school of myschools; let idx = index;" class="arcTbl--row project_row">
                                <td (click)="filter_moveToProject(school, idx)">
                                    <span class="arcTbl--projectName">{{school.sch_name}}</span>
                                </td>
                                <td (click)="filter_moveToProject(school, idx)">
                                    <span class="arcTbl--projectGeo">
                                        {{school.mstreet1}}, {{school.mcity}}, {{school.statename}}
                                    </span>
                                </td>
                                <td class="{{'project-row-'+ idx}}" (click)="filter_moveToProject(school, idx)">
                                    <span *ngIf="school.building == null">Unclaimed</span>
                                    <span *ngIf="school.building != null" [innerHTML]="global.underscoreless(school.building)"></span>
                                </td>
        
                                <!-- <td (click)="filter_moveToProject(school, idx)">
                                    <span *ngIf="school.building != null" [innerHTML]="underscoreless(school.building)"></span>
                                </td> -->
        
                                <td class="cursor-default">
                                    <div *ngIf="school.building != null && (school.permission != 'request_accepted' && school.permission != 'request_access')">
                                        <span>{{getSchoolStatus(school)}}</span>
                                    </div>
                                    <div *ngIf="school.building != null && school.permission == 'request_accepted'" class="flex">
                                        <div (click)="global.goToPayment(school.building)" *ngIf="global.trialStatus(school.building)" class="btn btn-default arc-btn arc-btn-xs">
                                            Pay Now
                                        </div>
                                    </div>
                                    <div *ngIf="school.building != null && school.permission == 'request_access'" class="flex">                                
                                        <button class="btn btn-default arc-btn arc-btn-sm" (click)="reqAccess(school)">
                                            <span>Request Access</span>
                                        </button>
                                    </div>
                                    <div *ngIf="school.building == null" class="flex">
                                        <button (click)="addProject(school)" class="btn btn-default arc-btn arc-btn-sm"><span>Claim now</span>
                                        </button> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div [hidden]="myschools.length == 0" style="width: 45%;padding: 8px 0px;" class="arcTbl--pagination">
                        <div class="arcTbl--noOfRows">
                            <div class="arcTbl--rowsPerPageTitle">Rows per page:</div>
                            <div class="dropdown arcTbl--pageSizeDrp">
                                <button class="dropdown-toggle arcTbl--pageSizeDrp-btn" type="button" data-toggle="dropdown">
                                    <span class="arcTbl--pageSize">{{searchParams.page_size}}</span>
                                    <span class="fa fa-caret-down"></span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li (click)="changeSearchParams(1, 10, searchParams.order, searchParams.category, searchParams.query)" class="energy_unit"><a>10</a></li>
                                    <li (click)="changeSearchParams(1, 20, searchParams.order, searchParams.category, searchParams.query)" class="energy_unit"><a>20</a></li>
                                    <li (click)="changeSearchParams(1, 40, searchParams.order, searchParams.category, searchParams.query)" class="energy_unit"><a>40</a></li>
                                    <li (click)="changeSearchParams(1, 50, searchParams.order, searchParams.category, searchParams.query)" class="energy_unit"><a>50</a></li>
                                    <li (click)="changeSearchParams(1, 100, searchParams.order, searchParams.category, searchParams.query)" class="energy_unit"><a>100</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="arcTbl--pageCounter">
                            <span>Page {{searchParams.page_number}} of {{global.handleNaN(searchParams.total_pages)}}</span>
                        </div>
                        <div class="arcTbl--paginationNav">
                            
                            <button [ngClass]="searchParams.page_number == 1 ? 'not-active' : ''" (click)="changeSearchParams(searchParams.page_number - 1, searchParams.page_size, searchParams.order, searchParams.category, searchParams.query)" class="arcTbl--nav-btn__prev">
                            </button>            
                            <button [ngClass]="searchParams.page_number >= searchParams.total_pages ? 'not-active' : ''" (click)="changeSearchParams(searchParams.page_number + 1, searchParams.page_size, searchParams.order, searchParams.category, searchParams.query)" class="arcTbl--nav-btn__next">
                            </button>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    </div>
</div>
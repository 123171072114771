import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { Projects } from '../../services/projects.service';

import { Config } from '../../models/env-vars.model';

@Component({
  selector: 'app-ecostruxure-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class EcostruxureDashboardComponent implements OnInit
{
	config: any = Config;
	myprojects: any = [];
	selected_country: any = [];
	selected_state: any = [];
	selected_space_type: any = [];
	selected_certification: any = [];
	selected_building_status: any = [];
	state_list: any = [];
	selected_country_name: any = [];
	selected_state_name: any = [];
	selected_projets: any = [];
	app_assets: any = [];
	invoices: any = [];

	loading: boolean = true;
	select_all: boolean = false;
	app_integrated: boolean = false;
	open_invoice = false;

	page_size: number = 10;
	count: number = 0;
	total_filters: number = 0;
	page: number = 1;
	page_count: number = 1;

	sort_order: string = '';
	sort_column: string = '';
	filter_country: string = '';
	selected_tab: string = 'My Projects';
	nextPage: string | null = null;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private projects: Projects
	)
	{}

	ngOnInit(): void
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
			});
		}

		this.appAssets();
		this.getInvoices();
	}

	getInvoices()
	{
		this.api.get('/ecostruxure/invoices/').subscribe(
			//success handler
			data =>
			{
				this.invoices = data;
				for(let i = 0; i < data.length; i++)
				{
					if(data[i].payment_status == 'pending')
					{
						this.open_invoice = true;
						break;
					}
				}
			},
			//error handler
			error =>
			{}
		);
	}

	downloadInvoice(invoice: any)
	{
		this.loading = true;
		this.api.get('/ecostruxure/invoices/'+ invoice.sap_order_id +'/download/').subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.global.anchorDownload(invoice.sap_order_id + ".pdf", data);
			},
			//error handler
			error =>
			{
				this.loading = false;
			}
		);
	}

	getCountry(country: string)
	{
		return this.global.getCountry(country);
	};

	appLogin()
	{
		this.loading = true;
		this.api.get('/ecostruxure/authorize/').subscribe(
			//success handler
			data =>
			{
				var url = data.authorize_url;
				window.open(url, "_self");
			},
			//error handler
			error =>
			{
				this.loading = false;
			}
		);
	};

	getFilters()
	{
		var country = (this.selected_country.length == 0 ? '' : this.selected_country.join(","));
		var state = this.selected_state.join(",");
		var spaceType = this.selected_space_type.join(",");
		var certification = this.selected_certification.join(",");
		var building_status = this.selected_building_status.join(",");
		var source = "&activation_source=Ecostruxure";

		country = country.length == 0 ? '' : "&country=" + country;
		state = state.length == 0 ? '' : "&state=" + state;
		spaceType = spaceType.length == 0 ? '' : "&spaceType=" + spaceType;
		certification = certification.length == 0 ? '' : "&certification=" + certification;
		if(building_status.length > 0 && building_status == 'Not-Activated')
		{
			building_status = "&building_status=activated_payment_pending,setup_incomplete,activated_addendum_agreement_pending,deactivated,activated_timeline_pending";
		}
		else if(building_status.length > 0 && building_status == 'Activated')
		{
			building_status = "&building_status=activated_payment_done,activated_under_review";
		}
		else
		{
			building_status = "";
		}

		this.total_filters = this.selected_country.length + this.selected_state.length + this.selected_space_type.length + this.selected_certification.length + this.selected_building_status.length;

		return country + state + spaceType + certification.toLowerCase().replace("not-certified", "denied,") + building_status + source;
	};

	getSyncedProjects(page: number, page_size: number, ordering: string)
	{
		this.state_list = [];
		this.filter_country = '';
		this.selected_country = [];
		this.selected_country_name = [];
		this.selected_state = [];
		this.selected_state_name = [];
		this.selected_space_type = [];
		this.selected_building_status = [];
		this.selected_certification = [];
		this.selected_projets = [];

		if(page_size == 0 || page_size < 10)
		{
			page_size = 30;
		}
		var filters = this.getFilters();
		$("#project_filters").hide();
		if(page > this.page_count || page <= 0)
		{
			return;
		}
		// this.selected_projets = [];
		//get projects
		this.loading = true;
		this.projects.getProjects(page, page_size, ordering, "all", filters).subscribe(
			//success handler
			data =>
			{
				this.count = data.count;
				this.page = page;
				this.page_size = page_size > this.count ? this.count : page_size;
				this.page_count = Math.ceil(this.count / this.page_size);

				for(var i = 0; i < data.results.length; i++)
				{
					if(this.selected_projets.indexOf(data.results[i].leed_id) > -1 || this.select_all)
					{
						data.results[i].select_all = true;
					}
				}

				this.myprojects = data.results;
				if (data.next == null)
				{
					this.nextPage = null;
				}
				else
				{
					this.nextPage = data.next.split('?page=')[1];
				}
				this.loading = false;
			},
			//error handler
			error =>
			{
				this.loading = false;
			}
		);
	};

	appAssets()
	{
		this.api.get('/ecostruxure/assets/').subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.app_assets = data;
				if(this.app_assets.constructor === Array)
				{
					this.app_integrated = true;
					this.getSyncedProjects(1, 30, this.sort_order + this.sort_column);
				}
				else
				{
					this.app_integrated = false;
				}
			},
			//error handler
			data =>
			{
				this.loading = false;
				this.app_integrated = false;
			}
		);
	};

	changeTab(tab: string)
	{
		this.selected_tab = tab;
		if(tab == 'Add Projects')
		{
			this.global.goToApp('ecostruxure-select-project');
		}
	}
}

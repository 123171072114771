import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';  
import { Component, Input, OnInit } from '@angular/core';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { fakeAsync } from '@angular/core/testing';

@Component({
	selector: 'totalscore_leed-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/totalscore_leed.html',
	imports: [CommonModule],
})
export class totalscore_leed implements OnInit
{
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}
}

@Component({
	selector: 'totalscore-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/totalscore.html',
	imports: [CommonModule],
})
export class totalscore implements OnInit
{
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}
}

@Component({
	selector: 'team_roles_help-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/team_roles_help.html',
	imports: [CommonModule],
})
export class team_roles_help implements OnInit
{
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}
}

@Component({
	selector: 'totalscore_city-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/totalscore_city.html',
	imports: [CommonModule],
})
export class totalscore_city implements OnInit
{
	config = Config;
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}
}


@Component({
	selector: 'arc_pro_policy-content',
	standalone: true,
	templateUrl: '../../../assets/templates/arc_pro_policy.html',
	imports: [CommonModule],
})
export class arc_pro_policy implements OnInit
{
	config = Config;
	@Input() type: string = '';
	
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}
}


@Component({
	selector: 'sidebar-app-content',
	standalone: true,
	templateUrl: '../../../assets/templates/sidebar-app.html',
	imports: [CommonModule],
})
export class VendorAppHelp implements OnInit
{
	@Input() type: string = '';
	checking_integration: boolean = false;
	
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService
	)
	{}

	ngOnInit(): void
	{
		
	}

	cancel()
	{
		this.activeModal.close()
	}

	confirmMeasurablIntegration()
	{
		(<any>$('.confirm_measurabl_integration')).modal('show');
	}

	addMeasurablIntegration()
	{
		var data = {"partner_details": 3};
		this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/partners/integration/',data).subscribe(
			//success handler
			response =>
			{
				this.confirmMeasurablIntegration();
				this.cancel();
			},
			//error handler
			response =>
			{
				this.alertService.alert('error', 'Something went wrong during integration.', 5);
			}   
		);
	};

	goToPayment(project: any, integration: any)
	{
		$('.trial-banner').slideUp();
		this.appData.set('integration', integration);
		this.appData.set('payment_init', this.appData.get('current_app'));
		if(integration != undefined)
		{
			this.checking_integration = true;
			Config.app_loader = true;
			this.api.get('/assets/LEED:' + project.leed_id + '/partners/integration/').subscribe(
				response =>
				{
					Config.app_loader = false;
					this.checking_integration = false;
					for(var i = 0; i < response.length; i++)
					{
						if(response[i].partner_details == 3)
						{
							(<any>$('.integration_invalid')).modal('show');
							this.cancel();
							return;
						}
					}

					this.addMeasurablIntegration();
					this.cancel();
				},
				errpr =>
				{
					Config.app_loader = false;
					this.checking_integration = false;
				}
			);
		}
		else
		{
			this.global.goToPayment(this.appData.get('buildingData'), {'soreference': 'MEASURABL MONTHLY SUBSCRIPTION', 'app_name': 'measurabl'})
			this.cancel();
		}
	};
}

@Component({
	selector: 'cert_details-content',
	standalone: true,
	templateUrl: '../../../assets/templates/cert_details.html',
	imports: [CommonModule],
})
export class CertDetails implements OnInit
{
	@Input() project: any = {};
	certification: any = {};
	expire: any = undefined;
	loading: boolean = true;
	
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
		private api: API
	)
	{}

	ngOnInit(): void
	{
		this.api.get('/assets/LEED:' + this.project.leed_id + '/certifications/').subscribe(
			response =>
			{
				this.loading = false;
				if(response.certificates.length > 0)
				{
					var certs = response.certificates, certification = response.certificates[0];
					for(var i = 0; i < certs.length; i++)
					{
						if(new Date(certs[i].date_certified) > new Date(certification.date_certified))
						{
							certification = certs[i];
						}
					}
					this.certification = certification;
					this.expire = this.global.dateFormat(this.certification.certification_expire, true);
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	cancel()
	{
		this.activeModal.close()
	}
}

@Component({
	selector: 'na_help-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/na_help.html',
	imports: [CommonModule],
})
export class na_help implements OnInit
{
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}

	cancel()
	{
		this.activeModal.close()
	}
}

@Component({
	selector: 'parksmart-content',
	standalone: true,
	templateUrl: '../../../assets/templates/helpbox/parksmart.html',
	imports: [CommonModule],
})
export class parksmart implements OnInit
{
	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		
	}

	cancel()
	{
		this.activeModal.close()
	}
}
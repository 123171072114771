import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.css']
})
export class ProjectSetupComponent implements OnInit
{
	config = Config;
	project: any = this.appData.get('buildingData');
	saving_project: boolean = false;

	mode: string = '';
	pf_id: any = '';

	constructor(
		public appData: AppData,
		public global: Global,
		private api: API,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.state('pf_id') != undefined)
        {
            this.pf_id = this.config.state('pf_id');
            this.mode = 'project_add_portfolio';
        }
        else
        {
            this.mode = 'project_creation';
        }
	}

	addToPortfolio()
	{
		this.global.addToPortfolio([this.pf_id], [this.project.leed_id]).subscribe(
			data =>
			{
				this.saving_project = false;
				this.global.goToApp("selected_portfolio", 
				{
					"portfolio_id": this.pf_id,
					"app_name": "portfolio-overview"
				});
				this.alertService.alert('success', 'Project(s) added to portfolio', 5);
			},
			error =>
			{
				this.saving_project = false;
				this.alertService.alert('error', 'Error adding projects to portfolio', 5);
			}
		);
	}

	selectTrial()
	{
		var payload = {
			'is_trial_selected': true
		};
		
		this.saving_project = true;
		this.api.put('/assets/LEED:'+ this.project.leed_id +'/?sync_sap=False', payload).subscribe(
			response =>
			{
				this.saving_project = false;
				if(this.mode == 'project_add_portfolio')
				{
					this.addToPortfolio();
					return;
				}
				this.global.moveToProject(response, null);
			},
			error =>
			{
				if(this.mode == 'project_add_portfolio')
				{
					this.addToPortfolio();
				}
				this.saving_project = false;
			}
		);
	}

	goToPayment(project: any, integration?: any)
	{
		$('.trial-banner').slideUp();
		this.appData.set('leed_id', project.leed_id);
		this.appData.set('buildingData', project);
		this.appData.set('integration', integration);
		this.appData.set('payment_init', this.appData.get('current_app'));
		
		if(this.mode == 'project_add_portfolio')
		{
			this.global.goToApp("project-payment", 
			{
				"leed_id": project.leed_id,
				"app_name": 'project-payment',
				'init': this.appData.get('current_app'),
				'integration': integration,
				'pf_id': this.pf_id
			});
		}
		else
		{
			this.global.goToApp("project-payment", 
			{
				"leed_id": project.leed_id,
				"app_name": 'project-payment',
				'init': this.appData.get('current_app'),
				'integration': integration
			});
		} 
	}

}

<div>
	<div>
        
        <div [ngClass]="saving ? 'not-active' : ''" class="pl15 pr15">
            <app-loader *ngIf="saving"></app-loader>
            <div class="align_lr mb20">
                <h3 class="fw-500 black-color pl32 mb15">
                    
                </h3>
            
                <div class="dropdown dropdown_v2 display-inline cursor-pointer">
                    <button class="blue btn outline dropdown-toggle" type="button" data-toggle="dropdown">
                        Save as 
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                    <ul class="dropdown-menu left insight-left">
                        <li (click)="openSaveAs()" class="water_unit">
                            <a>Save as new version</a>
                        </li>
                        <li (click)="saveScoreCard(false, selected_version.version_name, true, selected_version.version_id)" class="water_unit">
                            <a>Set as latest version</a>
                        </li>
                    </ul>
                </div>
            </div>

            <section class="widget pl0" style="background-color: white;overflow: auto;">
                <div>
                    <ul class="meterNav mb16 ml32 mr32 pt15">
                        <li (click)="toggleTab('Model details')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Model details' ? 'meterNav--item__active' : ''">Model details</span>
                        </li>
                        <li (click)="toggleTab('Strategies')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Strategies' ? 'meterNav--item__active' : ''">Strategies</span>
                        </li>
                        <li (click)="toggleTab('Reduction view')" class="meterNav--item">
                            <span [ngClass]="current_tab == 'Reduction view' ? 'meterNav--item__active' : ''">Reduction view</span>
                        </li>
                    </ul>
                </div>
                
                <div class="" style="padding: 25px 32px;">
                    <app-model-details [create_mode]="false" [project]="appData.get('buildingData')" *ngIf="current_tab == 'Model details'"></app-model-details>
                    <app-model-strategies *ngIf="current_tab == 'Strategies'"></app-model-strategies>
                    <app-model-reduction *ngIf="current_tab == 'Reduction view'"></app-model-reduction>
                </div>
            </section>
        </div>


    </div>
</div>

<div class="modal save_as_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            
            <div class="p2535" [ngClass]="saving ? 'not-active' : ''">
                <app-loader *ngIf="saving"></app-loader>
                <div class="left-right-align">
                    <div class="flex fontSize18 mb25 fw-600">
                        <span>Save as new version</span>
                    </div> 
                </div>
            
                <div id="details-form">
                    <table id="model_details">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="table-margin-20">
                            <tr>
                                <td colspan="4">
                                    <div class="group pr20 pb0">      
                                        <input maxlength="40" [(ngModel)]="modal_current_version_name" name="name" type="text" required>
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label>Version Name</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            
                <div class="flex mt20 res_flex">
            
                    <div class="mr20 mb15 align-left">
                        <button (click)="saveScoreCard(true, modal_current_version_name, true, undefined)" type="button" class="btn btn-primary btn-md">Save</button>
                    </div>
            
                    <div class="mr20 mb15 align-left">
                        <button id="closeButton" (click)="cancel()" data-dismiss="modal" type="button" class="btn btn-default btn-md">Cancel</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
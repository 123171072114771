import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-arc-japan',
  templateUrl: './arc-japan.component.html',
  styleUrls: ['./arc-japan.component.css']
})
export class ArcJapanComponent implements OnInit {


  ngOnInit(): void {
  }
}

import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-partial-maintenance',
  templateUrl: './partial-maintenance.component.html',
  styleUrls: ['./partial-maintenance.component.css']
})
export class PartialMaintenanceComponent implements OnInit {
  loading: boolean = true;
  mode_user: any = '';
  initial_mode: any = '';

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  updateMaintenanceMode(val: any)
    {
        if ((val && this.mode_user == 'ON') || (!val && this.mode_user == 'OFF')){
            return false;
        }
        this.initial_mode = this.mode_user;
        if (val){
              this.mode_user = 'ON';
        }
        else{
            this.mode_user = 'OFF';
        }
        var payload = {
            "partial_maintenance": val
        };
        this.loading = true;
        this.api.post('/partial-maintenance/', payload ).subscribe(
          data => {
            this.loading = false;
            this.alertService.alert('success', 'Successfully updated maintenance mode.', 5);
          }, error => {
            this.loading = false;
            this.mode_user = this.initial_mode;
            try{
              this.alertService.alert('error', error.error.detail, 5);
            }
            catch(err) {
              this.alertService.alert('error', 'Something went wrong.', 5);
            }
          }
        );
    };

  ngOnInit(): void {
    this.api.get('/partial-maintenance/').subscribe(
      data => {
        this.loading = false;
        if (data.partial_maintenance){
              this.mode_user = 'ON';
        }
        else{
            this.mode_user = 'OFF';
        }
      },
      error => {
        this.loading = false;
        try{
            this.alertService.alert('error', error.error.detail, 5);
        }
        catch(err) {
          this.alertService.alert('error', 'Something went wrong.', 5);
        }
      }
    );
  }

}

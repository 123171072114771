import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-portfolio-team',
  templateUrl: './portfolio-team.component.html',
  styleUrls: ['./portfolio-team.component.css']
})
export class PortfolioTeamComponent implements OnInit
{
	config = Config;
	portfolio_id: number = this.appData.get('portfolio_id');
	team_members_port_original: any = [];
	team_members_port: any  = [];
	new_email: string = "";
	add_team_msz: string = "Add";
	loading: boolean = false;
	addingRole: boolean = false;
	editPort: boolean = false;
	team_member_permission: string = "";
	save_role_msz: string  = "Save";
	selected: any = undefined;
	cacheData: any = undefined;
	cacheElement: any = undefined;
	add_team_flag: boolean = false;
	error_add_team_flag: boolean = false;
	error_add_team_msz: string = '';

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		this.getPortfolioTeam();
	}

	editRole(team_member: any)
	{
		for(var a in this.team_members_port_original)
		{
			this.team_members_port[a].permission = this.team_members_port_original[a].permission
		}
		this.selected = team_member;
	};
	
	makeEditable(team_role: any)
	{
		return this.selected === team_role;
	};

	getPortfolioTeam()
	{
		this.loading = true;
		this.api.get(
			'/portfolios/ID:' + this.appData.get('portfolio_id') + '/teams/'
		).subscribe(
			data =>
			{
				this.loading = false;
				var found = false;
				for(var i = 0; i < data.length; i++)
				{
					if(this.appData.get('user_id') == data[i].username)
					{
						found = true;
						Config.portfolio_access = data[i].permission;
						break;
					}
				}
				if(found == false)
				{
					Config.portfolio_access = 'can_edit';
				}
				this.team_members_port = data;
				this.team_members_port_original = data;
			},
			error =>
			{
				this.loading = false;
			}
		)
	}
	
	saveRole(team_member: any, elem: any)
	{
		var edit_flag = false;
		for(var a in this.team_members_port)
		{
			if(this.team_members_port[a].permission == 'can_edit')
			{
				edit_flag = true;
				break;
			}
		}
		
		if(!edit_flag)
		{
			this.alertService.alert('error', 'Atleast one user with Edit access should be present.', 5);
			return;
		}
		
		this.loading = true;
		elem.save_role_msz  = "Saving...";
		
		var data = 
		{
			"username": team_member.username,
			"permission": elem.permission
		};
		
		if(data.permission == 'none' && team_member.username == this.appData.get('user_id'))
		{
			$('.delte_user_port').modal('toggle');
			this.cacheData = data;
			this.cacheElement = elem;
			this.loading = false;
			elem.save_role_msz  = "Save";
			return;
		} 

		this.api.put('/portfolios/ID:' + this.portfolio_id + '/teams/',data).subscribe(
			//success handler
			data =>
			{
				this.getPortfolioTeam();
				this.loading = false;
				elem.save_role_msz  = "Save";
				this.alertService.alert('success', 'Team member updated successfully.', 5);
				
				if(team_member.username == this.appData.get('user_id') && elem.permission == 'none')
				{
					this.global.goToApp("portfolios");
				}
			},
			//error handler
			error =>
			{
				this.loading = false;
				elem.save_role_msz  = "Save";
				this.alertService.alert('error', 'Please try again.', 5);
			}
		);
	};

	checkError()
	{
		if(this.new_email.length > 0 && this.validator.validate(this.new_email, 'email'))
		{
			this.add_team_flag = false;
			this.add_team_msz = "Add";
			this.error_add_team_flag = false;
			this.error_add_team_msz = "";
		}
		else
		{
			this.error_add_team_flag = true;
			this.error_add_team_msz = "Not a valid email";
		}
	};
	
	removeUser()
	{
		$('.delte_user_port').modal('toggle');
		this.loading = true;
		this.cacheElement.save_role_msz  = "Saving...";
		this.api.put('/portfolios/ID:' + this.portfolio_id + '/teams/',this.cacheData).subscribe(
			//success handler
			data =>
			{
				this.getPortfolioTeam();
				this.loading = false;
				this.cacheElement.save_role_msz  = "Save";
				this.alertService.alert('success', 'Team member removed successfully.', 5);
				
				if(this.cacheData.username == this.appData.get('user_id') && this.cacheData.permission == 'none')
				{
					this.global.goToApp("portfolios");
				}
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.cacheElement.save_role_msz  = "Save";
				this.alertService.alert('error', 'Please try again.', 5);
			}
		);
	}
	
	changePortAccess(permission: string, elem: any)
	{
		elem.permission = permission;
	}
	
	getPermission(permission: string)
	{
		if(permission == 'can_read')
		{
			return 'Can Read';
		}
		else if(permission == 'can_edit')
		{
			return 'Can Edit';
		}
		else
		{
			return 'None';
		}
	}
	
	addMemberToTeam()
	{
		var acr_admin_email = "";
		var acr_admin_count = 0;
		if(this.new_email.length == 0)
		{
			this.add_team_flag = false;
			this.add_team_msz = "Add";
			this.error_add_team_flag = true;
			this.error_add_team_msz = "This field cannot be blank.";
			return;
		}
		
		for(var i = 0; i < this.team_members_port.length; i++)
		{
			if(this.new_email.trim() == this.team_members_port[i].username)
			{
				this.add_team_flag = false;
				this.add_team_msz = "Add";
				this.error_add_team_flag = true;
				this.error_add_team_msz = "This ID is already added to the team.";
				return;
			}
		}
		
		this.add_team_flag = true;
		this.add_team_msz  = "Adding...";
		this.addingRole    = true;
		
		var data = 
		{
			"username": this.new_email.trim().toLowerCase(),
			"permission": "can_edit"
		};
		
		this.api.post('/portfolios/ID:' + this.portfolio_id + '/teams/', data).subscribe(
			//success handler
			response =>{
				this.add_team_flag = false;
				this.addingRole    = false;
				this.add_team_msz = "Add";
				this.error_add_team_flag = false;
				this.new_email = "";
				this.getPortfolioTeam();
				this.alertService.alert('success', 'Team member added successfully.', 5);
			},
			//error handler
			response =>{
				this.add_team_flag = false;
				this.addingRole    = false;
				this.add_team_msz = "Add";
				this.error_add_team_flag = true;
				this.error_add_team_msz = "Please try again.";
				this.new_email = "";
			}
		);
	};

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';

@Component({
  selector: 'app-selected-admin-app',
  templateUrl: './selected-admin-app.component.html',
  styleUrls: ['./selected-admin-app.component.css']
})
export class SelectedAdminAppComponent implements OnInit
{

	constructor(
		public appData: AppData,
		private global: Global
	)
	{
		if(this.appData.get('login') == null)
		{
			let next = window.location.pathname.replace('/admin/', '');
			this.global.goToApp('admin-tool', undefined, {
				"next": next
			})
		}
	}

	ngOnInit(): void
	{

	}

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import * as stream from 'getstream';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-portfolio-reports',
  templateUrl: './portfolio-reports.component.html',
  styleUrls: ['./portfolio-reports.component.css']
})
export class PortfolioReportsComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	@Input() type: string = 'portfolio_report';
	loading: boolean = false;
	portfolio_id: number = this.appData.get('portfolio_id');

	current_tab: string = 'Report Settings';
	doc_category: string = 'report_title';

	report_category: any = ['report_pdf', 'report_export'];
	selected_icon: any = null;
	icons: any = {};
	reports: any = [];
	feeds: any = [];
	retry: any = [];
	report_type: boolean = true;

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{}

	ngOnInit(): void
	{
		if(this.type == 'ea_report')
		{
			this.report_category = [this.type];
			this.doc_category = 'ea_report_title';
			let container = (<any>document.querySelector('#portfolio_report'));
			container.removeAttribute('class');
			container.querySelector('#content').style.top = '0px';
			container.querySelector('.page-title').style.display = 'none';
			container.querySelector('.meterNav').style.marginTop = '15px';
		}
		this.initFeed();
		this.getIcons();
	}

	toggleType()
	{
		this.report_type = !this.report_type;
	}

	cancel()
	{
		this.modal.close();
	}

	selectImageModal(sequence: string | null)
	{
		this.selected_icon = sequence;
	}

	createReport()
	{
		let ranges: any = this.global.getSavedRanges();
		if(this.report_type)
		{
			var url = '/portfolios/ID:' + this.portfolio_id + '/createreport/';
			url += '?report_type=' + this.type;
			if(this.icons[this.selected_icon] != undefined || this.icons[this.selected_icon] != null)
			{
				url += '&report_title=' + this.icons[this.selected_icon].id;
			}
			url += "&START=" + ranges[1];
			url += "&END=" + ranges[2];
		}
		else
		{
			var url = '/portfolios/ID:' + this.portfolio_id + '/export/';
		}

		this.loading = true;
		this.api.get(url).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				if ("data" in response && "message" in response['data']){
					this.alertService.alert('success', response['data']['message'], 10);
				}
				else
				{
					this.alertService.alert('success', "Your report is being created and you will be notified via email", 5);
				}
				this.cancel();
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	}

	getIcons()
	{
		this.loading = true;
		var url = '/portfolios/ID:'+ this.portfolio_id +'/documents/';

		this.api.get(url).subscribe(
			response =>
			{
				response = response.sort(function(a: any,b: any) 
				{ 
					return new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime() 
				}).reverse();
				// this.documents = response;
				var data = response;
				this.loading = false;
				this.reports = [];
				for(var n = 0; n < data.length; n++)
				{
					if(data[n].doc_source == this.doc_category)
					{
						var file_name = this.global.getFileName(data[n]);
						file_name = file_name.split('.')[0];
						if(file_name == 'first')
						{
							this.icons['first'] = data[n];
						}
						else if(file_name == 'second')
						{
							this.icons['second'] = data[n];
						}
						else if(file_name == 'third')
						{
							this.icons['third'] = data[n];
						}
						else if(file_name == 'fourth')
						{
							this.icons['fourth'] = data[n];
						}
						else if(file_name == 'fifth')
						{
							this.icons['fifth'] = data[n];
						}
					}
					else if(this.report_category.indexOf(data[n].doc_source) != -1)
					{
						if(data[n].document_metadata != null)
						{
							data[n].document_metadata = JSON.parse(data[n].document_metadata.replace(/'/g, '"'));
						}
						this.reports.push(data[n]);
					}
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	removeFeed()
	{
		var url = '/portfolios/'+ this.portfolio_id +'/notifications/';

		this.api.delete(url, {}).subscribe(
			data =>
			{

			}
		);
	};

	getReports()
	{
		this.getIcons();
	};

	initFeed()
	{
		var url = '/portfolios/'+ this.portfolio_id +'/notifications/?stream=assets';

		this.api.get(url).subscribe(
			response =>
			{
				response = response;
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				var retry = []
				
				for(var i = 0; i < response.activities.length; i++)
				{
					if(response.activities[i].foreign_id.indexOf("data_report_wait_") != -1)
					{
						var time = moment(response.activities[i].time);
						var date = moment(new Date);
						if(time.diff(date, 'days') > 1)
						{
							this.deleteFeed(response.activities[i]);
						}
						else
						{
							this.feeds.push(response.activities[i]);
						}
					}
					else if(response.activities[i].foreign_id.indexOf("data_report_error_") != -1)
					{
						retry.push(response.activities[i]);
					}
					
					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						this.removeFeed();
					}
				}
				
				this.retry = retry.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});
				this.feeds = this.feeds.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});

				var subscription = notification.subscribe((data: any) => 
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < this.feeds.length; a++)
						{
							if(this.feeds[a].id == data.deleted[d])
							{
								this.getReports();
								this.feeds.splice(a, 1);
							}
						}
					}

					if(data.new.length > 0)
					{
						if(data.new[0].foreign_id.indexOf("data_report_wait_") != -1)
						{
							this.feeds.push(data.new[0]);
						}
					}
					
					this.feeds = this.feeds.sort(function(a: any,b: any) 
					{ 
						return new Date(a.time).getTime() - new Date(b.time).getTime() 
					});
				});
			},
			error =>
			{

			}
		);
	}

	getPath(doc: any, id?: any)
	{
		if(doc == undefined)
		{
			return Config.basic_api_url + '/portfolios/ID:'+ this.portfolio_id +'/documents/download/?doc_id=' + id + "&access-token="+ this.appData.get('token') +"&subscription-key=" + Config.sub_key;
		}
		return doc.url;
	}

	toggleTab(tab: string)
	{
		if(tab == this.current_tab)
		{
			return;
		}
		this.current_tab = tab;
		if(tab == 'Past Reports')
		{
			this.getReports();
		}
	}

	createReportModal()
	{
		this.modal.open({ windowClass: 'certificate_modal' })
	}

	selectImage(sequence: string)
	{
		this.selected_icon = sequence;
		$('.upload_report_title').click();
	}

	deleteIcon(uploaded_file: any, sequence: string)
	{
		let payload = {
			"doc_id": uploaded_file.id
		};

		this.loading = true;
		this.api.delete('/portfolios/ID:'+ this.portfolio_id + '/documents/', payload).subscribe(
			//success handler
			response =>{
				this.icons[sequence] = undefined;
				this.loading = false;
			},
			//error handler
			response =>{
				this.alertService.alert('error', 'Not able to remove logo.', 5);
				this.loading = false;
			}
		);
	}

	deleteFeed(feed: any, index?: number)
	{
		this.loading = true;
		this.api.delete('/portfolios/'+ this.portfolio_id + '/notifications/?feed=' + feed.foreign_id + "&stream=assets", {}).subscribe(
			//success handler
			response =>{
				this.loading = false;
				this.retry.splice(index, 1);
			},
			//error handler
			response =>{
				this.loading = false;
			}
		);
	}

	downloadCert(data: any)
	{
		let url = data.url;
		let name   = this.global.getFileName(data);
		this.global.anchorDownload(name, url);
	}

	deleteReport(document: any)
	{
		let payload = {
			"doc_id": document.id
		};

		this.loading = true;
		this.api.delete('/portfolios/ID:'+ this.portfolio_id + '/documents/', payload).subscribe(
			//success handler
			response =>{
				this.getReports();
			},
			//error handler
			response =>{
				this.alertService.alert('error', 'Not able to delete report.', 5);
				this.loading = false;
			}
		);
	}

	upload(form_data: any, category: string, type: string, size: number)
	{        
		(<any>$('.upload_report_title')[0]).value = "";  
		if(type.split('/')[0] != 'image')
		{
			this.alertService.alert('error', 'Only images are allowed', 5);
			return;
		}
		
		if((size / 1024000) > 5.0)
		{
			this.alertService.alert('error', 'File size should not exceed 5MB', 5);
			return;
		}
		
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', category);

		this.loading = true;
		this.api.post('/portfolios/ID:'+ this.portfolio_id + '/documents/', form_data, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				this.getIcons();
			},
			//error handler
			error =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Not able to upload logo.', 5);
			}
		);
	};

	fileNameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		//var type = $(this).attr('category');
		var data;
		var temp_uploading = [];
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			var mimeType = $(elem)[0].files[a].type;
			let file: any;
			if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
			{
				file = new Blob([$(elem)[0].files[a]], { type: mimeType});
				file.lastModifiedDate = new Date();
				data.append('action_file', file, this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			}
			else
			{
				file = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1], {type: mimeType, lastModified: (<any>new Date())});
				data.append('action_file', file);
			}

			// var file = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
			// data.append('action_file', file);
			this.upload(data, this.doc_category, $(elem)[0].files[a].type, $(elem)[0].files[a].size);
		}
	}

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Projects } from 'src/app/services/projects.service';
import { Global } from '../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { CreditListService } from 'src/app/services/credit-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
 selector: 'app-actions-form',
 templateUrl: './actions-form.component.html',
 styleUrls: ['./actions-form.component.css']
})
export class ActionsFormComponent implements OnInit
{
	config = Config;
	leed_id: number = this.appData.get('leed_id');
	parent_loader: boolean = false;

	downloading_flag = false;
	download_msz = "Download";
	uploading_form_flag = false;
	uploading_file_flag = false;
	upload_form_msz = "Upload";
	upload_file_msz = "Upload";
	selected_form = "";
	selected_file = "";
	error_form_flag = false;
	error_msz_form_upload = "Please try again.";
	error_file_flag = false;
	error_msz_file_upload = "Please try again.";
	makeEditable = false;
	makeCreditEditable = false;
	credit_lib_link = "";
	project_team: any = [];
	credit_status = ["Attempted", "Ready for Review"];
	changing_assignee_flag= false;
	changing_credit_flag = false;
	credit_activities: any = [];
	process_list = [];
	project_type = this.appData.get('project_type');
	CreditShortId = this.appData.get('credit').CreditShortId;
	team = {"teamMember": this.appData.get('credit').AssignedTo, email: ""}
	resource_url = this.config.config.settings.resource_url;
	new_credit_status = this.appData.get('credit').CreditStatus;
	selectedCredit: any = {};
	process_status = false;
	upload_queue = [];
	uploading_files = [];
	measure_list: any = [];
	project_title = "";
	points_pursued: any;
	credit: any =  this.appData.get('credit');

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private alertService: alertService,
		private CreditService: CreditListService,
		private projects: Projects
	)
	{

	}

	ngOnInit(): void
	{
		this.parent_loader = true;
		this.teamMembers();
	}

	getCredits(){
		this.projects.getActionList(this.appData.get('project_type')).subscribe(data =>
		{
			this.appData.set('all_actions', data);
			this.appData.set('all_actions', this.global.insertAction(this.appData.get('buildingData').project_type, this.appData.get('all_actions').EtScorecardList));
			for(var i = 0; i < this.appData.get('all_actions').length; i++){
				if(this.appData.get('all_actions')[i].CreditShortId == this.credit.CreditShortId){
					this.credit = this.appData.get('all_actions')[i];
					this.appData.set('credit', this.credit);
					break;
				}
			}
			this.initData();
			this.parent_loader = false;
		},
		error =>
		{
			this.initData();
			this.parent_loader = false;
		});
	}

	initData(){
		this.measure_list = "";
		this.credit = this.appData.get('credit');
		this.CreditShortId = this.appData.get('credit').CreditShortId;
		this.config.upload_category = this.credit.CreditShortId;
		this.config.resource_credits = false;
		this.config.static_credit = false;
		this.creditActivity();
		if (this.project_type == "building")
		{
            this.project_title = "Buildings";
        }
        else if (this.project_type == "city")
		{
            this.project_title = "Cities";
        }
        else if (this.project_type == "community")
		{
            this.project_title = "Communities";
        }
        else if (this.project_type == "district")
		{
            this.project_title = "Districts";
        }
        else if (this.project_type == "neighborhood")
		{
            this.project_title = "Neighborhoods";
        }
        else if (this.project_type == "transit")
		{
            this.project_title = "Transit";
        }
        else if (this.project_type == "parksmart")
		{
            this.project_title = "Parking";
        }
        else if (this.project_type == "sites")
		{
            this.project_title = "Sites";
        }

		for(var i = 0; i < this.config.config.settings.static_credits.length; i++)
		{
			if(this.config.config.settings.static_credits[i] == this.CreditShortId)
			{
				this.config.static_credit = true;
			}
		}
		
		for(var i = 0; i < this.config.config.settings.resource_credits.length; i++)
		{
			if(this.config.config.settings.resource_credits[i] == this.CreditShortId)
			{
				this.config.resource_credits = true;
			}
		}
	
		if(this.credit.CreditStatus == 'Under Review')
		{
			this.credit_status.push('Under Review');
		}
		
		if(this.credit.IvAttchPolicy == 'X')
		{
			this.config.policy_check = true;
			$("#policy_check").prop("checked", true)
		}
		else
		{
			this.config.policy_check = false;
			$("#policy_check").prop("checked", false)
		}
		if(this.credit.IvReqFileupload == 'X')
		{
			this.config.file_check = true;
			$("#file_check").prop("checked", true)
		}
		else
		{
			this.config.file_check = false;
			$("#file_check").prop("checked", false)
		}

		if(this.project_type == 'city')
		{
			this.global.getUploadedFiles(this.CreditShortId);
		}

		this.project_type = this.appData.get('project_type');
		this.CreditShortId = this.appData.get('credit').CreditShortId;
		this.team = {"teamMember": this.appData.get('credit').AssignedTo, email: ""}
		this.new_credit_status = this.appData.get('credit').CreditStatus;

		var prj_ratingSystem = this.appData.get('project_type')

		this.getCreditLibraryURL(prj_ratingSystem, this.credit.CreditShortId);
		if(this.project_type == 'building' || this.project_type == 'transit')
		{
			this.global.getUploadedFiles(this.credit.CreditShortId); 
		}
		//get data from s3 for all the types except building
		if(this.project_type != "building" && this.project_type != "transit")
		{
			this.global.getUploadedFiles(this.CreditShortId);
		}
		// city logic for prerequisites

		this.global.installedApps();
	}
	
	getChildrenObject(list: any, child_list: any)
	{
		for(var i = 0; i < child_list.length; i++)
		{
			for(var a = 0; a < list.length; a++)
			{
				if(list[a].parent != null && child_list[i].id == list[a].id)
				{
					if(list[a].children != null)
					{
						list[a].children = this.getChildrenObject(list, list[a].children);
					}
					child_list[i] = list[a]
					list.splice(a, 1)
				}
			}
		}

		return child_list;
	};

	getParent(option: any, list: any): any
	{
		if(option.parent != null)
		{
			for(var a = 0; a < list.length; a++)
			{
				if(list[a].id == option.parent)
				{
					return list[a];
				}
				else if(list[a].children != null)
				{
					var result = this.getParent(option, list[a].children);
					if(result != undefined)
						return result
				}
			}   
		}
		else
		{
			return option.parent;
		}
	};

	bindData(data: any)
	{
		var left = data.indexOf("{{");
		var right = data.indexOf("}}");
		if(left != -1 && right != -1)
		{
			var variable = data.substring(left + 2, right)
			// data = data.replaceBetween(left, right + 2, this[variable] == undefined ? "" : $scope[variable])
		}
		if(data.indexOf("{{") != -1)
		{
			data = this.bindData(data);
		}
		return data;
	}

	constructTree(list: any, measure_data: any)
	{
		for(var b = 0; b < list.length; b++)
		{
			list[b]['content'] = this.bindData(list[b]['content']);
			for(var a = 0; a < measure_data.length; a++)
			{
				if(list[b].id == measure_data[a].measure_id)
				{
					list[b]['data'] = decodeURIComponent(encodeURI(measure_data[a].data));
					if(measure_data[a].content_type == 'checkbox')
					{
						measure_data[a].data = (measure_data[a].data == 'true');
						list[b]['data'] = measure_data[a].data;
					}
					list[b]['key'] = measure_data[a].id;
					list[b].hidden = false;

					if(list[b].allow_toggle == true && list[b].children != null)
					{
						list[b].collapsed_children = list[b]['data'] ? false : true;
					}
					break;
				}
			}
		}

		for(var i = 0; i < list.length; i++)
		{
			if(list[i].children != null)
			{
				list[i].children = this.getChildrenObject(list, list[i].children);
			}
		}
		return list;
	}

	count_points(points_pursued: any, measure_list: any)
	{
		for(var a = 0; a < measure_list.length; a++)
		{
			if(measure_list[a].content_type == 'checkbox' && measure_list[a].data == true)
			{
				points_pursued += isNaN(measure_list[a].points) || measure_list[a].points == null ? 0 : measure_list[a].points;
			}
			if(measure_list[a].children != null)
			{
				points_pursued = this.count_points(points_pursued, measure_list[a].children);
			}
		}

		if(points_pursued < 0)
		{
			points_pursued = 0;
		}
		return points_pursued;
	}

	getActionDescription(option: any)
	{
		var description = option.action_description;
		if(description == undefined)
		{
			return description
		}
		else if(option.content_type == 'checkbox')
		{
			if(option.data)
			{
				description = "checked the option" + description;
			}
			else
			{
				description = "un-checked the option" + description;
			}
		}
		else if(option.content_type == 'textarea')
		{
			description = "updated the input field" + description;
		}
		return description
	}

	changeMeasure(option: any, element: any, parent: any, doc_id: any)
	{
		var version = "V1";

		var payload = {
			"measure_id": option.id,
			"content_type": option.content_type,
			"data": String(option.data)
		}
		var measure_id = this.CreditShortId;
		var points_pursued = this.count_points(0, this.measure_list);
		parent.loader = true;

		var measure_url = '/assets/LEED:'+ this.leed_id +'/measures/'+ measure_id +"/?points_pursued=" + points_pursued;
		// var description = this.getActionDescription(option);
		// if(description != undefined)
		// {
		//     measure_url = measure_url + "&description=" + description
		// }
		
		if(option["key"] == undefined)
		{
			this.api.post(measure_url, payload).subscribe(
				//success handler
				response =>{
					var data = response;
					option["key"] = data.id;
					parent.loader = false;
					if(option.allow_toggle)
					{
						option.collapsed_children = !option.collapsed_children;
					}
					else
					{
						return;
					}
					
					this.points_pursued = points_pursued;
				},
				//error handler
				response =>{
					
				}
			);
		}
		else
		{
			let payload = {
				"measure_id": option.id,
				"data": String(option.data)
			}
			this.api.put(measure_url, payload).subscribe(
				//success handler
				response =>{
					var data = response;
					option["key"] = data.id;
					parent.loader = false;
					if(option.allow_toggle)
					{
						option.collapsed_children = !option.collapsed_children;
					}
					else
					{
						return;
					}
					
					this.points_pursued = points_pursued;
				},
				//error handler
				response =>{
					
				}
			);
		}
	};

	checkView(option: any)
	{
		if( option != undefined && option.parent != null)
		{
			var parent = this.getParent(option, this.measure_list);
			if(parent == undefined)
				return
		}
		else
			return
		
		return parent.collapsed_children;
	};

	// this.$on('loading:finish', function (state, response)
	// {
	// 	try
	// 	{
	// 		if(response.config.method != 'GET')
	// 		{
	// 			this.creditActivity();    
	// 		}
	// 	}
	// 	catch(e)
	// 	{}
	// });

	creditChanged(status: any, el: any)
	{
		this.new_credit_status = status;
		this.changeCreditStatusConfirm(el);
	}
	
	teamChanged(team: any)
	{
		this.team.teamMember = team.name;
		this.team.email = team.email;
		this.assignToNew();
	}
	
	only_base(obj: any) {
		return ((obj.Mandatory != 'X') && (obj.CreditcategoryDescrption != 'Performance Category') && (obj.CreditcategoryDescrption != 'Performance'));
	};

	downloadCreditForm()
	{
		let href: any;
		
		if(this.config.static_credit)
		{
			href = "../../../assets/pdf/" + this.CreditShortId + ".pdf";
		}
		else
		{
			href = this.config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/actions/ID:"+ this.credit.CreditId +"/forms/download/?access-token="+ this.appData.get('token') +"&subscription-key="+ this.config.sub_key +"&Docfile=Form-"+ this.credit.CreditDescription.split(" ").join("-") +".pdf"
		}
		this.global.anchorDownload('Form-' + this.credit.CreditDescription.split(" ").join("-") + ".pdf", href);
	};

	creditActivity()
	{
		var short_id = this.credit.CreditId;
		
		if(this.credit.CreditDescription == 'Innovation')
		{
			short_id = short_id.substring(4, short_id.indexOf('L-'))
		}
		else
		{
			short_id = this.credit.CreditShortId;
		}
		
		this.CreditService.getCreditActivity(short_id, this.credit.CreditId).subscribe(
			//success handler
			data => {
				this.credit_activities = data;
			}, 
			//error handler
			data =>{
			}
		);
	};
	
	creditActivityPush()
	{
		var short_id = this.credit.CreditId;
		
		if(this.credit.CreditDescription == 'Innovation')
		{
			short_id = short_id.substring(4, short_id.indexOf('L-'))
		}
		
		this.CreditService.getCreditActivity(short_id, this.credit.CreditId).subscribe(
			//success handler
			data => {
				var timestamp = new Date(data[0].timestamp);
				var obj:any = data[0];
				for(var a = 1; a < data.length; a++)
				{
					if(data[a].timestamp > timestamp)
					{
						timestamp = data[a].timestamp;
						obj = data[a];
					}
				}
				
				this.credit_activities.push(obj);
			}, 
			//error handler
			data =>{
			}
		);
	}

	uploadForm(files: any)
	{
		files = [$(files)[0].files[0]];
		this.selected_form       = files[0].name;
		this.uploading_form_flag = true;
		this.upload_form_msz     = "Uploading...";

		var splited_name = this.selected_form.split('.');
		if (splited_name[splited_name.length-1] != "pdf"){
			this.error_form_flag       = true;
			this.error_msz_form_upload = "Only .pdf supported.";
			this.uploading_form_flag   = false;
			this.upload_form_msz       = "Upload";
			(<any>document.getElementById("credit_form")).value = "";
		}
		else{
			var file_data = new FormData();
			file_data.append('action_file', files[0]);
			file_data.append('file_description', this.credit.CreditId);
			var custom_basic_config_header = 
			{
				headers: 
				{
					"responseType": "arraybuffer",
					"Ocp-Apim-Subscription-Key": this.config.sub_key,
					"Authorization": "Bearer " + this.appData.get('token')
				}
			};

			this.api.post( '/assets/LEED:' + this.leed_id + '/actions/ID:' + this.credit.CreditId + '/forms/upload/', file_data, custom_basic_config_header).subscribe(
				//success handler
				response =>{
					this.uploading_form_flag = false;
					this.upload_form_msz     = "Upload";
					this.error_form_flag     = false;
					this.alertService.alert('success', 'Credit form successfully saved.', 5);
					this.creditActivity();
				},
				//error handler
				response =>{
					this.uploading_form_flag   = false;
					this.upload_form_msz       = "Upload";
					
					try
					{
						if(response.error[0].message.indexOf("Parse error on document") > -1)
							this.error_msz_form_upload = "Please upload the correct form.";
						else
							this.error_msz_form_upload = "Incorrect form uploaded.";
					}
					catch(e)
					{
						this.error_msz_form_upload = "Please upload the correct form.";
					}
					
					this.error_form_flag       = true;
					(<any>document.getElementById("credit_form")).value = "";
				}
			);
		}
	};

	downloadCreditFile (file_obj: any)
	{
		var url = this.config.config.settings.LO_PROXY_URL + '/fileApi/action/download.php';
		var k = this.appData.get('LO_key');

		if(file_obj.status == 'processing')
		{
			return;
		}

		file_obj.status = 'processing';

		var download_proxy_url = url + "?k=" + k + '&p[]=' + file_obj.Documentnumber + '&p[]=' + file_obj.Documentpart + '&p[]=' + file_obj.Documenttype + '&p[]=' + file_obj.Documentversion + '&p[]=' + file_obj.FileId + '&p[]=' + file_obj.ApplicationId + '&p[]=' + this.leed_id + '&p[]=' + file_obj.Docfile;
		this.api.get(download_proxy_url).subscribe(
			//success handler
			response =>{
				var data = response;
				if(data.downloadUrl != undefined)
				{
					file_obj.status = 'ok';
					this.global.downloadFromProxy(data.downloadUrl);
				}
				else
				{
					var url = this.config.config.settings.LO_PROXY_URL + '/fileApi/action/getReport.php?k=' + k + '&processId=' + data.processId;

					var refreshIntervalId = setInterval(() =>
					{
						this.api.get(url).subscribe(
							//success handler
							response =>{
								var result = response;
								if(result.processStatus == 'SUCCESS')
								{
									clearInterval(refreshIntervalId);
									file_obj.status = 'ok';
									this.global.downloadFromProxy(result.processReport.downloadUrl);
								}
							},
							//error handler
							response =>{
								
							}
						);

					}, 1000);
				}
			},
			//error handler
			response =>{                    
			}
		);
	};

	clearAllForm()
	{
		this.uploading_form_flag = false;
		this.selected_form  = "";
		this.error_form_flag     = false;
		(<any>document.getElementById("credit_form")).value = "";
	};

	clearAllFiles() {
		this.uploading_file_flag = false;
		this.selected_file  = "";
		this.error_file_flag     = false;
		(<any>document.getElementById("credit_file")).value = "";

		if(this.global.getBrowser().ie)
		{
			$('#credit_file').click();
		}
	};

	teamMembers()
	{            
		let commonEmails: any = [];

		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/teams/'
		).subscribe(
			data =>
			{
				for (var i = 0; i < data.EtTeamMembers.length; i++) {
					if (commonEmails.indexOf(data.EtTeamMembers[i].Useralias) <= -1 && data.EtTeamMembers[i].Responsibility != 'D')
					{
						this.project_team.push({email: data.EtTeamMembers[i].Useralias, name: data.EtTeamMembers[i].Firstname + " " + data.EtTeamMembers[i].Lastname});
					}
					commonEmails.push(data.EtTeamMembers[i].Useralias);
				}
				this.getCredits();
			},
			error =>
			{	
				this.getCredits();

			}
		);
	};

	assignToNew(){
		if(this.team.teamMember==""){
			return;
		}

		this.changing_assignee_flag = true;
		this.makeEditable           = false;

		var data = {
			"emails": this.team.email
		}
		this.api.post('/assets/LEED:' + this.leed_id + '/actions/ID:' + this.credit.CreditId + '/teams/', data).subscribe(
			//success handler
			response =>{
				this.changing_assignee_flag = false;
				this.credit.AssignedTo = this.team.teamMember;
				this.creditActivity();
			},
			//error handler
			response =>{
				this.changing_assignee_flag = false;
			}
		);
	};
	
	changeCreditStatusConfirm(elem: any)
	{
		this.selectedCredit = elem;
		if ((this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'transit') && this.credit.Mandatory != 'X' && this.credit.CreditcategoryDescrption != 'Performance')
		{
			$('.confirmation_modal_credit').modal('show');     
		}
		else
		{
			this.changeCreditStatus(this.selectedCredit);
		}
	};
	
	changeCreditStatusCancel()
	{
		this.changing_credit_flag = false;
		this.selectedCredit.makeCreditEditable = false;
		this.new_credit_status = this.credit.CreditStatus;
	};

	changeCreditStatus(elem: any)
	{
		this.changing_credit_flag = true;
		elem.makeCreditEditable     = false;
		var data = {
			"is_readyForReview": false
		}

		if (this.new_credit_status == "Ready for Review"){
			data = {
				"is_readyForReview": true
			}
		}
		else{
			data = {
				"is_readyForReview": false
			}
		}
		
		if(this.credit.CreditStatus == this.new_credit_status)
		{
			this.changing_credit_flag = false;
			return;
		}

		this.api.put('/assets/LEED:'+ this.leed_id +'/actions/ID:' + this.credit.CreditId + '/', data).subscribe(
			//success handler
			response =>{
				if ((this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'transit') && this.credit.Mandatory != 'X' && this.credit.CreditcategoryDescrption != 'Performance'){
					this.credit.CreditStatus = this.new_credit_status;
					var final_base_score = this.appData.get('buildingData').base_score;
					var points_available = this.credit.PointsAvailable;

					if (points_available.split("-")[1] != undefined){
						points_available = points_available.split("-")[1];
					} 

					if (this.new_credit_status == "Ready for Review"){
						final_base_score += parseFloat(points_available) * 10
					}
					else{
						final_base_score -= parseFloat(points_available) * 10
					}

					if (final_base_score >= 100){
						final_base_score = 100;
					}
					else if(final_base_score < 0){
						final_base_score = 0;
					}

					var bld_data = {'base_score': final_base_score, 'name': this.appData.get('buildingData').name};
					this.api.put('/assets/LEED:'+ this.leed_id + '/?recompute_score=1&send_mail=True', bld_data).subscribe(
						//success handler
						response =>{
							var data = response;
							this.appData.set('buildingData', data)
							// if (ScoreAnimationService.data){
							// 	ScoreAnimationService.data.base.score = data.base_score/10;
							// }
							this.changing_credit_flag = false;
							this.creditActivity();
						},
						//error handler
						response =>{
							this.changing_credit_flag = false;
							this.creditActivity();
						}
					);
				}
				else{
					this.changing_credit_flag = false;
					this.credit.CreditStatus = this.new_credit_status;
					this.creditActivity();
				}

				let credit_nav = this.global.copy(this.appData.get('creditNavigator'));
				for(var a = 0; a < credit_nav.length; a++)
				{
					if(credit_nav[a].CreditShortId == this.credit.CreditShortId)
					{
						credit_nav[a].CreditStatus = this.new_credit_status;
						this.credit = credit_nav[a];
						this.appData.set('credit', this.credit);
						this.appData.set('creditNavigator', this.global.copy(credit_nav));
						this.appData.set('all_actions', this.global.copy(credit_nav));
						break;
					}
				}
			},
			//error handler
			response =>{
				this.changing_credit_flag = false;
			}
		);
	};

	getCreditLibraryURL(ratingSystem: any, creditShortId: any){
		var rs       = "";
		if (ratingSystem.indexOf("LEED V4") <= -1){
			rs = "v2009";
		}
		else{
			rs = "v4";
		}

		var pattens: any = {

			'(BD\\\+C: SC)|(LEED FOR SCHOOLS)' : 'schools---new-construction',
			'(BD\\\+C: Retail)|(NC RETAIL)' : 'retail---new-construction',
			'(BD\\\+C: DC)' : 'data-centers---new-construction',
			'(BD\\\+C: HP)' : 'hospitality---new-construction',
			'(BD\\\+C: WDC)' : 'warehouse-and-distribution-centers---new-construction',
			'(BD\\\+C: NC)|(LEED-NC)' : 'new-construction',

			'(ID\\\+C: Retail)|(CI RETAIL)' : 'retail---commercial-interiors',
			'(ID\\\+C: HP)' : 'hospitality---commercial-interiors',
			'(ID\\\+C: CI)|(LEED-CI)' : 'commercial-interiors',

			'(O\\\+M: SC)' : 'schools---existing-buildings',
			'(O\\\+M: Retail)' : 'retail---existing-buildings',
			'(O\\\+M: DC)' : 'data-centers---existing-buildings',
			'(O\\\+M: HP)' : 'hospitality---existing-buildings',
			'(O\\\+M: WDC)' : 'warehouse-and-distribution-centers---existing-buildings',
			'(O\\\+M: EB)|(LEED-EB)' : 'existing-buildings',

			'(BD\\\+C: HOLR)|(BD\\\+C: HOMR)|(LEED-HOMES)' : 'homes',
			'(BD\\\+C: HC)|(LEED-HC)' : 'healthcare',
			'(LEED-IT)|(LEED Italia)' : 'leed-italia',
			'(LEED-ND)' : 'neighborhood-development',
			'(BD\\\+C: CS)|(LEED-CS)' : 'core-and-shell',

		};

		var path = false;
		for (var i in pattens) {
			if(ratingSystem.match(new RegExp(i,'g'))) {
				path = pattens[i];
				break;
			}
		}

		var host = '//www.usgbc.org/';

		var creditLinkArray: any = {
			"SS103" : "node/2612883?return=/credits", //Site Management Policy
			"WE103": "node/2611378?return=/credits", //Indoor Water Use Reduction
			"EA102": "node/2612340?return=/credits", //Energy Efficiency Best Management Practi
			"EA105": "node/2613421?return=/credits", //Minimum Energy Performance
			"EA109": "node/2612351?return=/credits", //Fundamental Refrigerant Management
			"MR105": "node/2613412?return=/credits", //Ongoing Purchasing and Waste Policy
			"MR106": "node/2613431?return=/credits", //Facility Maintenance and Renovation Poli
			"EQ103": "node/2612633?return=/credits", //Minimum Indoor Air Quality Performance
			"EQ106": "node/2612508?return=/credits", //Environmental Tobacco Smoke Control
			"EQ108": "node/2614211?return=/credits/existing-buildings/v4", //Green Cleaning Policy
			
			"SS106" : "node/2612363?return=/credits", //Site Development - Protect or Restore Ha 
			"SS109" : "node/2613282?return=/credits", //Rainwater Management
			"SS111" : "node/2614015?return=/credits", //Heat Island Reduction
			"SS113" : "node/2600383?return=/credits", //Light Pollution Reduction
			"SS118" : "node/2613787?return=/credits", //Site Management
			"SS119" : "node/2613795?return=/credits", //Site Improvement Plan
			"EA127" : "node/2613596?return=/credits", //Enhanced Refrigerant Management
			"MR121" : "node/2613470?return=/credits", //Purchasing - ongoing
			"MR116" : "node/2613477?return=/credits", //Purchasing - lamps
			"MR122" : "node/2613487?return=/credits", //Purchasing - facility maintenance and re
			"MR111" : "node/2613452?return=/credits", //Solid waste management - facility mainte
			"EQ116" : "node/2614083?return=/credits", //Thermal Comfort
			"EQ120" : "node/2600945?return=/credits", //Interior Lighting
			"EQ122" : "node/2614122?return=/credits", //Daylight and Quality Views
			"EQ125" : "node/2614323?return=/credits", //Green Cleaning - Custodial Effectiveness
			"EQ126" : "node/2614343?return=/credits", //Green Cleaning - Products and Materials
			"EQ127" : "node/2614356?return=/credits", //Green Cleaning - Equipment
			"EQ128" : "node/2614668?return=/credits", //Integrated Pest Management,
			"EA125" : "node/2612844?return=/credits", //Renewable energy and carbon offsets
			
			"IN101.1" : "node/4378390?return=/credits", //Innovation
			"IN101.2" : "node/4378390?return=/credits", //Innovation
			"IN101.3" : "node/4378390?return=/credits", //Innovation
			"IN101.4" : "node/4378390?return=/credits", //Innovation
			"IN101.5" : "node/4378390?return=/credits" //Innovation
		}
		
		this.credit_lib_link = host + creditLinkArray[creditShortId];

		return false;
	};

	creditScreenChange(direction: string)
	{
		console.log(this.appData.get('all_actions'))
		console.log(this.appData.get('creditNavigator'))
		var short_id, actual_short_id;
		this.appData.set('creditNavigator', this.appData.get('all_actions'));
		if(this.appData.get('creditNavigator') == undefined || this.appData.get('creditNavigator').length == 0)
		{
			this.credit['CreditId'] = this.CreditShortId;
		}
		if(direction == 'next')
		{
			for(var i = 0; i < this.appData.get('creditNavigator').length; i++)
			{
				if(this.appData.get('creditNavigator')[i].CreditShortId == this.credit.CreditShortId)
				{
					if(i == this.appData.get('creditNavigator').length - 1)
					{
						short_id = this.appData.get('creditNavigator')[0].CreditId.toLowerCase();
						actual_short_id = this.appData.get('creditNavigator')[0].CreditShortId.toLowerCase();
						
						for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
						{
							if(this.appData.get('creditNavigator')[a].CreditId.toLowerCase() == short_id)
							{
								this.credit = this.appData.get('creditNavigator')[a];
								this.appData.set('credit', this.credit);
							}
						}
						this.initData();
						break;
					}
					else
					{
						short_id = this.appData.get('creditNavigator')[i + 1].CreditId.toLowerCase();
						actual_short_id = this.appData.get('creditNavigator')[i + 1].CreditShortId.toLowerCase();
						
						for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
						{
							if(this.appData.get('creditNavigator')[a].CreditId.toLowerCase() == short_id)
							{
								this.credit = this.appData.get('creditNavigator')[a];
								this.appData.set('credit', this.credit);
							}
						}
						this.initData();
						break;
					}
				}
			}
		}
		else if(direction == 'previous')
		{
			for(var i = 0; i < this.appData.get('creditNavigator').length; i++)
			{
				if(this.appData.get('creditNavigator')[i].CreditShortId == this.credit.CreditShortId)
				{
					if(i == 0)
					{
						short_id = this.appData.get('creditNavigator')[this.appData.get('creditNavigator').length - 1].CreditId.toLowerCase();
						actual_short_id = this.appData.get('creditNavigator')[this.appData.get('creditNavigator').length - 1].CreditShortId.toLowerCase();
						
						for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
						{
							if(this.appData.get('creditNavigator')[a].CreditId.toLowerCase() == short_id)
							{
								this.credit = this.appData.get('creditNavigator')[a];
								this.appData.set('credit', this.credit);
							}
						}
						this.initData();
						break;
					}
					else
					{
						short_id = this.appData.get('creditNavigator')[i - 1].CreditId.toLowerCase();
						actual_short_id = this.appData.get('creditNavigator')[i - 1].CreditShortId.toLowerCase();
						
						for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
						{
							if(this.appData.get('creditNavigator')[a].CreditId.toLowerCase() == short_id)
							{
								this.credit = this.appData.get('creditNavigator')[a];
								this.appData.set('credit', this.credit);
							}
						}
						this.initData();
						break;
					}
				}
			} 
		}

		if (actual_short_id.substring(0, 3) == "pf9")
		{
			if(this.appData.get('project_type') == "building" || this.appData.get('project_type') == "transit" || this.appData.get('project_type') == "school" || this.appData.get('project_type') == "city" || this.appData.get('project_type') == "community")
			{
				if((actual_short_id == 'pf904' || actual_short_id == 'pf903' || actual_short_id == 'pf902' || actual_short_id == 'pf901') && direction == 'previous')
				{
					for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
					{
						if(this.appData.get('creditNavigator')[a].CreditShortId.toLowerCase() == 'pf901')
						{
							this.credit = this.appData.get('creditNavigator')[a - 1];
							this.appData.set('credit', this.credit);
							this.initData();
							return;
						}
					}
				}
				else if((actual_short_id == 'pf902' || actual_short_id == 'pf903' || actual_short_id == 'pf904' || actual_short_id == 'pf905') && direction == 'next')
				{
					for(var a = 0; a < this.appData.get('creditNavigator').length; a++)
					{
						if(this.appData.get('creditNavigator')[a].CreditShortId.toLowerCase() == 'pf905')
						{
							this.credit = this.appData.get('creditNavigator')[a - 1];
							this.appData.set('credit', this.credit);
							this.initData();
							return;
						}
					}
				}
				else if(actual_short_id == 'pf901' && direction == 'next')
				{
					this.global.goToApp("selected_project", 
					{
						"leed_id": this.leed_id,
						"app_name": 'data-input',
						'type': 'energy'
					});
					return;
				}
				else if(actual_short_id == 'pf905' && direction == 'previous')
				{
					this.global.goToApp("selected_project", 
					{
						"leed_id": this.leed_id,
						"app_name": 'data-input',
						'type': 'human'
					});
					return;
				}
			}
		}
		else if(actual_short_id == 'base_score')
		{
			// if($state.current.name != 'app.cc_basepoints')
			// {
			// 	$state.go('app.cc_basepoints', {"project_id": $rootScope.appData.project_id});
			// }
			// else
			// {
			// 	this.appData.set('credit', this.appData.get('creditNavigator')[0]);
			// 	$state.go('app.form', {"project_id": $rootScope.appData.project_id});   
			// }
		}
		else
		{
			this.initData();
		}
	}

	toggleAffirmation(affirmation: string, credit_id: string)
	{
		var policy = this.config.policy_check
		var file = this.config.file_check
		
		if(affirmation == 'policy_check')
		{
			this.config.policy_loader = true;
			policy = this.config.policy_check;
		}
		else if(affirmation == 'file_check')
		{
			this.config.file_loader = true;
			file = this.config.file_check;
		}
		
		var payload =
		{
			IvAttchPolicy : policy,
			IvReqFileupload : file
		};

		this.api.put('/assets/LEED:'+ this.appData.get('leed_id') +'/actions/ID:' + credit_id + '/', payload).subscribe(
			//success handler
			response =>
			{
				this.config.file_check = file;
				this.config.policy_check = policy;
				let obj = this.global.copy(this.appData.get('all_actions'));
				
				for(var i = 0; i < obj.length; i++)
				{
					if(obj[i].CreditId == credit_id)
					{
						obj[i].IvReqFileupload = this.config.file_check == true ? 'X' : '';
						obj[i].IvAttchPolicy = this.config.policy_check == true ? 'X' : '';
						this.credit = obj[i];
					}
				}
				
				this.appData.set('all_actions', this.global.copy(obj));
				this.config.policy_loader = false;
				this.config.file_loader = false;
				this.creditActivity();
			},
			//error handler
			response =>
			{
				this.config.policy_loader = false;
				this.config.file_loader = false;
			}
		);
	}

}

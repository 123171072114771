import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-user-reports',
  templateUrl: './user-reports.component.html',
  styleUrls: ['./user-reports.component.css']
})
export class UserReportsComponent implements OnInit
{
	config = Config;
	loading: boolean = false;
	count: number = 0;
	page: number = 1;
	page_size: number = 1;
	page_count: number = 1;
	nextPage: any = null;

	reports: any = [];

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		this.getReports(1, 10);
	}

	getReports(page: number, page_size: number)
	{
		if(page > this.page_count || page <= 0)
		{
			return;
		}
		
		var url = '/auth/user/certifications/';
		url += '?page=' + (typeof page !== 'undefined' ? String(page) : '1') + '&page_size='+ (typeof page_size !== 'undefined' ? String(page_size) : '50');

		this.loading = true;
		this.api.get(url).subscribe(
			data =>
			{
				this.count = data.count;
				this.page = page;
				this.page_size = page_size > this.count ? this.count : page_size;
				this.page_count = Math.ceil(this.count / this.page_size);
				if (data.next == null)
				{
					this.nextPage = null;
				}
				else
				{
					this.nextPage = data.next.split('?page=')[1];
				}

				var certs = data.certificates;
				var reports = data.reports;
				var portfolio_reports = data.portfolio_reports;
				var total = [];
				let obj: any;

				for(var n in portfolio_reports)
				{
					if(portfolio_reports[n].doc_source == 'report_export')
					{
						var type = 'Portfolio Excel Report';
					}
					else
					{
						var type = 'Portfolio Performance Report';
					}
					obj = {
						'name': portfolio_reports[n].name,
						'type': type,
						'date': portfolio_reports[n].uploaded_at,
						'id': portfolio_reports[n].id,
						'source': 'S3',
						'leed_id': portfolio_reports[n].pf_id
					};
					total.push(obj);
				}
				for(var n in certs)
				{
					if(certs[n].certification_level == 'Climate risk')
					{
						var type = 'Climate Risk Certificate'
					}
					else
					{
						var type = certs[n].certification_level + " Performance Certificate";
					}
					obj = {
						'name': certs[n].name,
						'type': type,
						'date': certs[n].created_at,
						'id': certs[n].certificate,
						'source': 'certificate',
						'leed_id': certs[n].leed_id
					};
					total.push(obj);
				}
				for(var n in reports)
				{
					var type = '', autocase = false;
					var file_type = "";
					if(reports[n].doc_category == 'report_pdf')
					{
						type = 'Project Report';
						try
						{
							var meta = JSON.parse(reports[n].document_metadata.replace(/'/g, '"'));
							if(meta.report_type == 're_entry_report')
							{
								type = 'Re-Entry Report';
							}
						}
						catch(e){}
					}
					else if(reports[n].doc_category == 'physicalrisk_report')
					{
						type = 'Physical Risk Report';
						if(reports[n].doc_description == 'physicalrisk_report_excel')
						{
							file_type = 'excel';
						}
						else
						{
							file_type = 'pdf';
						}
					}
					else if(reports[n].doc_category == 'climaterisk_report')
					{
						type = 'Climate Risk Report';
					}
					else if(reports[n].doc_category == 'advanced_scoring')
					{
						type = 'Advanced Scoring Report';
					}
					else if(reports[n].doc_category == 'ea_analysis')
					{
						type = 'Efficiency Analysis Report';
					}
					else if(reports[n].doc_category == 'autocase_report')
					{
						autocase = true;
						try
						{
							var meta = JSON.parse(reports[n].document_metadata.replace(/'/g, '"'));
							if(meta.report_mode == "free_report")
							{
								type = 'Past Performance Report';
							}
							else
							{
								type = 'Business Case Analysis';
							}
						}
						catch(e)
						{
							type = 'Autocase Report';
						}
					}
					obj = {
						'name': reports[n].name,
						'type': type,
						'date': reports[n].uploaded_at,
						'id': reports[n].id,
						'source': 'S3',
						'leed_id': reports[n].leed_id,
						'autocase': autocase,
						'file_type': file_type
					};
					total.push(obj);
				}
				this.reports = total;
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	downloadCert(certificate: any)
	{
		var doc_id = certificate.id;
		var source = certificate.source;
		var leed_id = certificate.leed_id;

		if(certificate.type == 'Portfolio Performance Report' || certificate.type == 'Portfolio Excel Report')
		{
			var url = Config.basic_api_url + "/portfolios/ID:" + leed_id + "/documents/download/?doc_id=" + doc_id + "&doc_source="+ source +"&access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		}
		else
		{
			var url = Config.basic_api_url + "/assets/LEED:" + leed_id + "/documents/download/?doc_id=" + doc_id + "&doc_source="+ source +"&access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		}
		var name   = "Cerfiticate.pdf";
		this.global.anchorDownload(name, url);
	}

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit
{
	range_start: string = '';
	range_end: string = '';
	last_certified_date: string = '';
	range_month: string = '';

	weekdays: any = [];
	calendar_matrix: any = [];

	loading: boolean = false;
	show_overview: boolean | undefined = undefined;
	current_status: string | undefined = undefined;

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	getSubscription()
	{
		this.current_status = undefined; //inactive, active, expired;
		this.loading = true;
		var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/';
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/'
		).subscribe(data =>
		{
			if(data.status == undefined)
			{
				this.current_status = 'inactive';
			}					
			else if(data.status == 'settled')
			{
				this.current_status = 'active';
			}					
			else if(data.status == 'cancelled_active')
			{
				this.current_status = 'cancelled_active';
			}					
			else if(data.status == 'expired')
			{
				this.current_status = 'expired';
			}		
			this.loading = false;
		});
	};

	showOverview()
	{   
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/survey/environment/summarize/'
		).subscribe(data =>
		{
			if(data.responses != 0)
			{
				this.show_overview = false;
			}
			else
			{
				let data_check = ['data_input_human', 'data_input_waste', 'data_input_water', 'data_input_energy'];
				this.api.get(
					'/assets/LEED:'+ this.appData.get('leed_id') +'/score-requirments/'
				).subscribe(data =>
				{
					let activities = data;
					for(let i = 0; i < activities.length; i++)
					{
						if(data_check.indexOf(activities[i].foreign_id) != -1)
						{
							data_check.splice(data_check.indexOf(activities[i].foreign_id), 1);
						}
					}

					if(data_check.length != 0)
					{
						this.show_overview = false;
					}
					else
					{
						this.show_overview = true;
					}
				});
			}
			this.loading = false;
		});
	};

	ngOnInit(): void
	{
		this.showOverview();
		this.getSubscription();
	}

}

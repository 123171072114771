import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit
{

	constructor()
	{
		$('body').css('backgroundColor', '#eee')
	}

	ngOnInit(): void
	{

	}

}

<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Financial Models</span>
        </h1>
        
        <div class="row">
            <app-loader *ngIf="loading"></app-loader>
            <section class="widget"  [ngClass]="loading ? 'not-active' : ''">
        
                <div>
                    <ul class="meterNav mb25">
                        <li (click)="toggleTab('Current Models')" class="meterNav--item">
                            <span [ngClass]="tab == 'Current Models' ? 'meterNav--item__active' : ''">Current Models</span>
                        </li>
                        <li (click)="toggleTab('Past Models')" class="meterNav--item">
                            <span [ngClass]="tab == 'Past Models' ? 'meterNav--item__active' : ''">Past Models</span>
                        </li>
                        <li *ngIf="current_status == 'active' || current_status == 'expired' || current_status == 'cancelled_active'" [hidden]="current_status == 'expired'" (click)="toggleTab('Subscription')" class="meterNav--item">
                            <span [ngClass]="tab == 'Subscription' ? 'meterNav--item__active' : ''">Subscription</span>
                        </li>
                    </ul>
        
                    <div>
                        <div *ngIf="tab == 'Current Models'">
                            <div id="inactive_certificate" class="mb25">
                                <div class="autocase_banner col-md-12 mb24"></div>
                                <div class="border-bottom mb16">
                                    <div class="fs18 line_height_24 fw-500 mb16">Free Past Performance Report</div>
                                    <div class="fs16 line_height_22">
                                        This report provides the quantified impacts from past enviornmental costs from operations. Get a free report that attributes past year performance with the corresponding enviornmental and economic impacts.
                                    </div>
                                    <div *ngIf="current_status == 'active' || current_status == 'cancelled_active'" class="col-md-12 pt20 pb20 pl0" style="float: none;">
                                        <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10" (click)="freeReport()">Create Free Report</button>
                                    </div>
                                    <div (click)="freeReport()" class="mt15 mb16 fs16 line_height_24" *ngIf="!(current_status == 'active' || current_status == 'cancelled_active')">
                                        <a><svg style="position: relative;top: 5px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#218BC3"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/></svg> Download your free Autocase performance report</a>
                                    </div>
                                </div>
        
                                <div class="border-bottom mb16 pb20">
                                    <div class="fs18 line_height_24 fw-500 mb16">Business Case Analysis</div>
                                    <div class="fs16 line_height_24 fw-500 mb16" *ngIf="current_status == 'expired' || current_status == 'inactive'">
                                        Subscribe to Arc Performance Certificates to get config.access to Autocase.
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <ul>
                                            <li>Evaluate water and energy efficiencies, and renewable energy</li>
                                            <li>Easily compare building efficiency targets</li>
                                            <li>See how your design goals can affect stakeholders: tenants, owner, community</li>
                                            <li>Quantify in economic dollars, the community benefits created from improving your building's performance related to energy, water and carbon reductions</li>
                                        </ul>
                                    </div>
                                    <div *ngIf="current_status == 'active' || current_status == 'cancelled_active'" class="col-md-12 pt20 pl0" style="float: none;">
                                        <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10" (click)="paidReport()">Create Model</button>
                                    </div>
                                </div>
        
                                <div class="">
                                    <div class="fs18 line_height_24 fw-500 mb16">Advanced Analysis</div>
                                    <div class="fs16 line_height_22">
                                        Connect your Arc project to your Autocase account, to assess other goals related to your new building design or retrofit. See and download the expanded business case, including the full carbon story and dollar value impacts on intangible benefits like community impacts and occupant health. Use Autocase's workflow to see your design goals through to construction and get up to 2 LEED pilot points.
                                    </div>
                                    <div *ngIf="current_status == 'active' || current_status == 'cancelled_active'" class="col-md-12 pt20 pb20 pl0" style="float: none;">
                                        <button (click)="importReport()" [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10">Sync data to Autocase</button>
                                    </div>
                                </div>
        
                                <div *ngIf="current_status == 'expired' || current_status == 'inactive'" class="border-bottom mb16 mt15 pb20">
                                    <div class="fs18 line_height_24 fw-500 mb16">
                                        <svg class="mr12" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48"><title>Arc_Performance_Certificates_circle_icon</title><circle cx="24" cy="24" r="24" fill="#174173"></circle><path d="M24,28.26A4.26,4.26,0,1,0,19.74,24,4.27,4.27,0,0,0,24,28.26Z" fill="#fff"></path><path d="M13,21l-3,3A14,14,0,1,0,24,10H13v6.09H24A7.91,7.91,0,1,1,16.09,24" fill="#fff"></path></svg>
                                        <span style="position: relative;top: -15px;">Subscribe to Arc Performance Certificates</span>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        Performance Certificates are available by subscription for:
                                        <span class="fw-500">{{config.currency}}{{global.formatNumberCommas(certificate_annually, 0)}} per project when billed annually.</span>
                                    </div>
                                    <div style="float: none;" class="col-md-12 pt20 pb20 pl0">
                                        <button (click)="puchaseSubs()" [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10">Purchase Subscription</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div id="past_certificates" *ngIf="tab == 'Past Models'">
                            <table id="reports_table" class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th>Report</th>
                                        <th>Created by</th>
                                        <th>Date Created</th>
                                        <th>Report Type</th>
                                        <th>View Report</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr title="{{data.message}}" class="tippy_init cursor-pointer" on-last-repeat="ngRepeatFinish" *ngFor="data in feeds"> -->
                                    <tr title="{{data.message}}" class="tippy_init cursor-pointer" *ngFor="let data of feeds">
                                        <td class="flex">
                                            {{data.data.file_name}}
                                        </td>
                                        <td class="fw-500">
                                            {{data.data.username}}
                                        </td>
                                        <td class="">
                                            {{data.data.timestamp}}
                                        </td>
                                        <td>
                                            {{data.data.report_mode == 'premium_report' ? 'Business Case Analysis' : 'Past Performance Report'}}
                                        </td>
                                        <td class="relative-position">
                                            In Progress...
                                        </td>
                                        <td></td>
                                    </tr>				    
                                    <!-- <tr on-last-repeat="ngRepeatFinish" *ngIf="reports.length != 0" *ngFor="let data of reports"> -->
                                    <tr *ngFor="let data of reports">
                                        <td class="flex">
                                            {{global.getFileName(data)}}
                                        </td>
                                        <td class="fw-500">
                                            {{data.uploaded_by}}
                                        </td>
                                        <td class="">
                                            {{global.dateFormat(data.uploaded_at)}}
                                        </td>
                                        <td>
                                            {{data.document_metadata.report_mode == 'premium_report' ? 'Business Case Analysis' : 'Past Performance Report'}}
                                        </td>
                                        <td class="">
                                            <a (click)="downloadCert(data)">
                                                Download
                                            </a>
                                        </td>
                                        <td [ngClass]="appData.get('is_admin') ? 'not-active' : ''">
                                            <div title="Delete Report" class="delete_report tippy_init" (click)="deleteReport(data)"></div>
                                        </td>
                                    </tr>			    
                                </tbody>
                                <tbody *ngIf="reports.length == 0 && feeds.length == 0">
                                    <tr>
                                        <td colspan="5" class="noProjects">
                                            No reports created.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
        
                        <div id="certificate_subscription" *ngIf="tab == 'Subscription'">
                            <div *ngIf="current_status == 'active'" class="align_lr green-color w100p mb5">
                                <div class="flex">
                                    <svg class="mr5" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" style="position: relative;top: -3px;">
                                        <g id="ICONS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="svg/more_done_icon">
                                                <path d="M15,25 C20.5228475,25 25,20.5228475 25,15 C25,9.4771525 20.5228475,5 15,5 C9.4771525,5 5,9.4771525 5,15 C5,20.5228475 9.4771525,25 15,25 Z" id="Oval-12" stroke="#50AE54" stroke-width="1.5"></path>
                                                <path d="M11.7520872,15.1860598 C11.5118429,15.1860598 11.295037,15.2992231 11.1544062,15.4898138 C11.0313542,15.6565807 10.9786177,15.8531274 11.0079157,16.05563 C11.0372138,16.2581327 11.142687,16.4368115 11.3008966,16.5618867 L14.301021,18.8489754 C14.4592307,18.9680946 14.670177,19.0216983 14.869404,18.9919185 C15.068631,18.9561827 15.2502792,18.8430195 15.3674715,18.6762526 L19.8617985,12.1961678 C20.1020428,11.8507221 20.0200082,11.3742452 19.68601,11.1300509 C19.3578714,10.8977684 18.8656634,10.9871078 18.6371383,11.3146856 L14.5822827,17.1515267 L12.2032778,15.3409148 C12.0685066,15.2396635 11.9161565,15.1860598 11.7520872,15.1860598 Z" id="✓-copy" fill="#50AE54"></path>
                                            </g>
                                        </g>
                                    </svg>
                                    <div class="fs18 line_height_24 fw-500">Subscription active</div>
                                </div>
                                <div *ngIf="config.access == 'CRUD'">
                                    <a (click)="cancelSubs()">Cancel subscription</a>
                                </div>
                            </div>
                            <div *ngIf="current_status == 'cancelled_active'" class="flex error-color w100p mb5">
                                <div class="mr5 error_symbol">
                                    
                                </div>
                                <div class="fs18 line_height_24 fw-500">Subscription cancelled</div>
                            </div>
                            <div (click)="global.openPolicy('Arc Performance Certificate')" class="floatr"><a>Subscription policy</a></div>
                            <div *ngIf="current_status == 'cancelled_active'" class="fs16 line_height_24 mb24">
                                Your subscription to Arc Performance Certificates is cancelled and will end on {{global.dateFormat(subscription.next_billing_date)}}. <a *ngIf="config.access == 'CRUD'" (click)="editBilling()">Update billing information</a>
                            </div>
                            <div id="cert_subscription_details" *ngIf="offline_sub">
                                <div class="fw-500 fs16 line_height_24">
                                    Your subscription will expire on {{global.dateFormat(subscription.next_billing_date)}}
                                </div>
                            </div>
                            <div id="cert_subscription_details" *ngIf="current_status == 'active' && offline_sub == false">
                                <div class="fw-500 fs16 line_height_24 mb16">Your next bill for {{config.currency}}{{subscription.amount}} on {{global.dateFormat(subscription.next_billing_date)}}</div>
                                <div class="b_item">
                                    <div class="flex"> 
                                        <i class="fa fa-credit-card fs40 gray_color_68737D mr10 pt15"></i>
                                        <div>
                                            <div class="fw-500 line_height_24 fs15">
                                                <span class="capitalize">{{subscription.card.brand}}</span> ending in {{subscription.card.last4}}
                                            </div>
                                            <div [ngClass]="getMonthDiff(3) ? 'error-color' : ''" class="line_height_24 fs15 gray_color_68737D">
                                                Expires: {{subscription.card.exp_month}}/{{subscription.card.exp_year}}
                                            </div>
                                            <div *ngIf="config.access == 'CRUD'" class="b_item line_height_24 fs14">
                                                <a (click)="editCard()">Update payment method</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div id="autocae_help" *ngIf="false">
                            <div class="autocae_help_logo">
                                <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1">
                                    <defs>
                                        <polygon id="path-1" points="2.13162821e-14 0.108346885 79.539504 0.108346885 79.539504 79.3150297 2.13162821e-14 79.3150297"></polygon>
                                    </defs>
                                    <g id="Web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Autocase-Web-01-01" transform="translate(-122.000000, -6578.000000)">
                                            <g id="Footer" transform="translate(0.000000, 6498.000000)">
                                                <g id="Group-10" transform="translate(122.000000, 80.000000)">
                                                    <path d="M57.2417963,57.6907333 L62.566587,70.3371257 C62.8995243,71.3355893 64.5635141,72.0011155 65.5618616,71.3355893 C66.560209,71.0027681 67.2259675,69.6715995 66.8930302,68.3403147 L61.5682395,55.6939222 L57.2417963,57.6907333 Z" id="Fill-1" fill="#FFFFFF"></path>
                                                    <path d="M42.0605164,10.0767248 L42.1846275,10.3629789 C42.1510675,10.2638061 42.1088428,10.1686978 42.0605164,10.0767248" id="Fill-3" fill="#FFFFFF"></path>
                                                    <path d="M38.2814767,10.3669273 C38.2654664,10.414075 38.2529312,10.4625001 38.24052,10.5109253 L38.2772569,10.4328876 C38.2772569,10.4109396 38.2799874,10.3888754 38.2814767,10.3669273" id="Fill-5" fill="#FFFFFF"></path>
                                                    <g id="Group-9" transform="translate(0.000000, 0.007781)">
                                                        <mask id="mask-2" fill="white">
                                                            <use xlink:href="#path-1"></use>
                                                        </mask>
                                                        <g id="Clip-8"></g>
                                                        <path d="M18.9696467,65.3373757 L39.6033994,16.0827422 L53.5810767,49.6957086 L18.9696467,65.3373757 Z M74.5475344,0.108253983 L4.99208564,0.108253983 C2.32963223,0.108253983 2.13162821e-14,2.43788621 2.13162821e-14,5.10033963 L2.13162821e-14,74.3229672 C2.13162821e-14,77.3182418 2.32963223,79.3150529 4.99208564,79.3150529 L74.2148294,79.3150529 C77.2099879,79.3150529 79.2067989,76.9854207 79.2067989,74.3229672 L79.2067989,47.6988976 L74.5475344,50.0285298 L74.5475344,74.9886096 L4.99208564,74.9886096 L4.99208564,5.10033963 L74.5475344,5.10033963 L74.5475344,40.0444746 L69.8883861,42.0412857 L57.5746987,47.6988976 L42.178525,10.3552216 L42.0605396,10.0689675 C41.7248152,9.39577686 41.0315345,8.93219619 40.228165,8.93219619 C39.3135454,8.93219619 38.5395561,9.53176207 38.2761791,10.35917 C38.2747856,10.381118 38.2722308,10.4031823 38.2722308,10.4251303 L38.2378571,10.503168 L12.6465086,68.6653553 C12.3136874,69.6638189 12.6465086,70.9949876 13.9776772,71.6606299 C14.6432035,71.993335 15.6416671,71.993335 16.3071933,71.3278088 L69.8883861,47.0332552 L74.5475344,45.0365603 L77.2099879,43.7052755 C78.5410404,43.0396331 79.539504,41.7084645 79.539504,40.0444746 L79.539504,37.7148424 L79.539504,5.10033963 C79.539504,2.43788621 77.2099879,0.108253983 74.5475344,0.108253983 L74.5475344,0.108253983 Z" id="Fill-7" fill="#FFFFFF" mask="url(#mask-2)"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div class="bottom_info">
                                <div class="fs18 line_height_24 fw-500 mb16">Getting started with Autocase</div>
                                <div class="fs16 line_height_22">
                                    How Cost-Benefit & Business Case Analysis works
                                    <br>
                                    <br>
                                    <a>Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </div>
</div>
import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';

import { Config } from '../models/env-vars.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HubSwitchoverComponent } from './hub-switchover/hub-switchover.component';
import { ArcAuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	arc_pro_label: string = Config.arc_pro_label;
	showSwitchoverInArc!: boolean;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private modalWindow: NgbModal
	) 
	{
		this.showSwitchoverInArc = this.appData.get('show_switchover_in_arc') && this.appData.get('user_type') != 'arc_japan';
	}

	ngOnInit(): void
	{
		try
		{
			if(this.appData.getLocal('auth0').is_admin)
			{
				this.global.goToApp('projects');
			}
		}
		catch(e)
		{}
		
		this.api.get('/auth/usgbc/userprofile/').subscribe(
			data =>
			{},
			function(error)
			{}
		);
	}

	openSwitchoverModal() {
		let modalRef = this.modalWindow.open(HubSwitchoverComponent, { windowClass: 'switchover_modal' });
	}
}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { LanguageService } from 'src/app/services/language.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from 'src/app/models/env-vars.model';
import { ActivatedRoute } from '@angular/router';
import { lang } from 'moment';
import { alertService } from 'src/app/services/alert-service.service';
declare var rangeslider: any;

@Component({
  selector: 'app-re-entry-survey',
  templateUrl: './re-entry-survey.component.html',
  styleUrls: ['./re-entry-survey.component.css']
})
export class ReEntrySurveyComponent implements OnInit
{
	config = Config;
	building: any = {};

	slider_text: string = '';
	satisfaction: number = 0;
	selected_slider: boolean = false;
	survey_submitted: boolean = false;
	submittedOESurvey: boolean = false;
	isLatest: boolean = true;
	selected_question: any = null;
	reduce: any = [{
		'value': 'dirty',
		'label': "dirty",
		'data': false
	},
	{
		'value': 'cold',
		'label': "cold",
		'data': false
	},
	{
		'value': 'drafty',
		'label': "drafty",
		'data': false,
		'latest': true
	},
	{
		'value': 'smelly',
		'label': "smelly",
		'data': false
	},
	{
		'value': 'dark',
		'label': "dark",
		'data': false
	},
	{
		'value': 'bright',
		'label': "bright",
		'data': false,
		'latest': true
	},
	{
		'value': 'stuffy',
		'label': "stuffy",
		'data': false
	},
	{
		'value': 'glare',
		'label': "glare",
		'data': false
	},
	{
		'value': 'views to outdoors',
		'label': "views",
		'data': false,
		'latest': true
	},
	{
		'value': 'loud',
		'label': "loud",
		'data': false
	},
	{
		'value': 'privacy',
		'label': "privacy",
		'data': false
	},
	{
		'value': 'sound',
		'label': "sound",
		'data': false,
		'latest': true
	},
	{
		'value': 'hot',
		'label': "hot",
		'data': false
	},
	{
		'value': 'humid',
		'label': "humid",
		'data': false,
		'latest': true
	}
	];
	enhance: any = [{
			'value': "thermal comfort",
			'label': "thermal_comfort",
			'data': false
		},
		{
			'value': "sound",
			'label': "sound_satisfied",
			'data': false
		},
		{
			'value': 'air quality',
			'label': "air_quality",
			'data': false
		},
		{
			'value': 'cleanliness',
			'label': "cleanliness",
			'data': false
		},
		{
			'value': 'light',
			'label': "light",
			'data': false
		},
		{
			'value': 'privacy',
			'label': "privacy_satisfied",
			'data': false
		},
		{
			'value': 'views to outdoors',
			'label': "views_satisfied",
			'data': false
		},
		{
			'value': 'daylight',
			'label': "daylight",
			'data': false
		}
	];

	type: string = '';
	type_text: string = '';
	scope_text: string = '';
	note: string = '';
	other_attributes: any = '';
	slider_val: any = null;
	key: any = '';
	link_value: any = '';
	location: any = '';
	textarea_val: any = '';
	group: any = '';
	scope: any = '';
	group_name: any = '';
	name: any = '';
	loading: boolean = false;
	submitting_survey: boolean = false;
	has_error: boolean = false;
	occupantError: boolean = false;
	showSliderError: boolean = false;
	questions: any = [];
	occupant_category: string = 'regular_occupant';
	leed_id: any;
	config_header: any = 
	{
		headers:
		{
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.sub_key,
		}
	};

	constructor(
		public global: Global,
		private api: API,
		public lang: LanguageService,
		public appData: AppData,
		private activatedRoute: ActivatedRoute,
		private validator: ValidationService,
		private alertService: alertService
	)
	{
		this.activatedRoute.params.subscribe((params) =>
		{
			this.leed_id = params['leed_id'];
			this.getBuildingDetails();
		});
	}

	ngOnInit(): void
	{
		
	}

	getBuildingDetails()
	{
		this.slider_text = this.lang.getText("slide_to_select");
		this.group = this.global.getURLFromParam('group');
        this.scope = this.global.getURLFromParam('scope');
        this.group_name = this.global.getURLFromParam('tenant');
        this.type = this.global.getURLFromParam('type'); 
        this.key = this.global.getURLFromParam('key'); 

		if(this.type == 'oe')
        {
            this.note = "This survey is one component of Arc Re-Entry -- a set of tools provided by GBCI. The questions below help facility managers understand how occupants experience infection control actions. This information helps the management team improve practices and reduce risks. Your responses are anonymous and used only to inform and improve facility management. Thank you in advance for your help.";
            this.type_text = 'OBSERVATIONS';
            this.questions = [
                {
                    "question_key": "infection_control",
                    "question": "Do you know how to contact facility management with concerns or observations about infection control at this facility?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "reason": {
                        "text": "Do you have questions about who to contact or responses to comments?",
                        "show": true,
                        "value": true,
                        "data": "",
                        "max_length": 512
                    }
                },
                {
                    "question_key": "disease_control",
                    "question": "Do you see disease control communication and signage where you expect it?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Where is disease control communication and signage missing?",
                    "max_length": 512
                },
                {
                    "question_key": "occupant_screening",
                    "question": "Do you see occupant screening where you expect it (e.g. at the entrance)?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Where is occupant screening missing?",
                    "max_length": 512
                },
                {
                    "question_key": "disinfecting",
                    "question": "Do you have access to hand washing and disinfection where you expect them (e.g. sanitizing gel, wipes)?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Where are hand washing and disinfection products missing (e.g. sanitizing gel, wipes)?",
                    "max_length": 512
                },
                {
                    "question_key": "social_distance1",
                    "question": "Does the facility support social distancing where you need it (e.g. floor markers, capacity constraints, seating spacing)?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Do any areas not support social distancing (e.g. floor markers, capacity constraints, seating spacing)?",
                    "max_length": 512
                },
                {
                    "question_key": "social_distance2",
                    "question": "Do people maintain social distance in the space?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Where is social distance reduced or limited?",
                    "max_length": 512
                },
                {
                    "question_key": "disease_transmission",
                    "question": "Overall, do you feel protected from disease transmission in this facility or venue?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "What could improve your sense of protection from disease transmission in this facility or venue?",
                    "max_length": 512
                },
                {
                    "question_key": "ppe_facility",
                    "question": "Do facilities staff use PPE appropriately (e.g., wearing masks properly)?",
                    "type": "survey-slider",
                    "max": 4,
                    "min": 0,
                    "value": 2,
                    "value_label": "Slide to your selection",
                    "slider_changed": false,
    
                    "text_question": "Observations about how PPE use by facilities staff could be improved?",
                    "max_length": 512
                }
            ];

            if(this.scope == 'common')
            {
                this.scope_text = "Describe your experience in building common areas, including the lobby, corridors, elevators, and other shared space.";
            }
            else if(this.scope == 'tenant')
            {
                this.scope_text = "Describe your experience in the area occupied by your organization (e.g. your workspace, kitchen, conference rooms).";
            }
            else if(this.scope == 'whole')
            {
                this.scope_text = "Describe your experience in the building occupied by your organization, including the lobby, corridors, and workspaces.";
            }
        }
        else
        {
            this.type_text = 'FACILITIES AND MANAGEMENT';
            this.questions = [
                // 1514 questions
                {
                    "question_key": "infectious_control",
                    "question": "Is a specific individual responsible for the development and implementation of infectious disease control policies, plans, and procedures for this facility?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "upload": {
                        "text": "Can you provide information about the professional background of this individual (e.g., a LinkedIn profile)?",
                        "show": true,
                        "value": true,
                        "data": []
                    },
                    "link": {
                        "text": "",
                        "show": true,
                        "value": true,
                        "data": []
                    },
                    "contact": {
                        "text": "Can you provide contact information for the individual?",
                        "show": true,
                        "value": true,
                        "data": ""
                    }
                },
                {
                    "question_key": "infectious_control_groupteam",
                    "question": "Is the responsible individual part of or supported by a group or team (e.g., a working group)?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "upload": {
                        "text": "Can you describe the composition and professional background of the group or team?",
                        "show": true,
                        "value": true,
                        "data": []
                    },
                    "link": {
                        "text": "",
                        "show": true,
                        "value": true,
                        "data": []
                    }
                },
                {
                    "question_key": "infectious_control_strategy",
                    "question": "Are there processes or mechanisms used by the individual or group to ensure accountability for implementation and effectiveness of infection control strategies?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "upload": {
                        "text": "Can you describe these processes or mechanisms?",
                        "show": true,
                        "value": true,
                        "data": []
                    },
                    "link": {
                        "text": "",
                        "show": true,
                        "value": true,
                        "data": []
                    }
                },
                // 1514 questions
                {
                    "question_key": "disease_control",
                    "question": "Does the facility or venue have infectious disease control policies, plans, and procedures?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "upload": {
                        "text": "",
                        "show": false,
                        "value": true,
                        "data": []
                    },
                    "link": {
                        "text": "",
                        "show": false,
                        "value": true,
                        "data": []
                    }
                },
                {
                    "question_key": "disease_authority",
                    "question": "Are the policies, plans, and procedures aligned with one of more authorities (e.g. government, academic institution)?",
                    "type": "radio-button",
                    "max": 1,
                    "min": 0,
                    "value": false,
                    "upload": {
                        "text": "Can you upload files identifying specific authorities used to guide facility operations (e.g. <a href='../../../../../assets/pdf/CDC Guidance.pdf' target='_blank'>CDC guidance</a>)? Upload one or more files.",
                        "show": true,
                        "value": true,
                        "data": []
                    },
                    "link": {
                        "text": "Can you provide links identifying specific authorities used to guide facility operations (e.g. <a href='https://www.usgbc.org/credits/safety-first-136-v4.1?return=/credits/Existing%20Buildings/v4.1' target='_blank'>https://www.usgbc.org/credits/safety-first-136-v4.1?return=/credits/Existing%20Buildings/v4.1</a>)?",
                        "show": true,
                        "value": true,
                        "data": []
                    }
                },
                {
                    "question_key": "RE_FM_3",
                    "question": "Do the infection control plans or policies for this facility include:",
                    "type": "radio-button-group",
                    "link": {
                        "show": false
                    },
                    "upload": {
                        "show": false
                    },
                    "options":[
                        {
                            "question": "Disease control communication and signage",
                            "question_key": "disease_comm_signage",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Hand washing and disinfection to address disease transmission (e.g. <a href='https://www.usgbc.org/credits/safety-first-137-v4.1?return=/credits/Existing%20Buildings/v4.1' target='_blank'>Safety First: Green Cleaning</a>)",
                            "question_key": "clean_disinfactant",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Providing PPE and encouraging the proper use by facility management staff and contractors (e.g., masks, gloves, etc.)",
                            "question_key": "ppe_facility",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Occupant screening (e.g. symptom or temperature checks)",
                            "question_key": "occupant_screening",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Paid sick leave (facility or venue staff, contractor)",
                            "question_key": "sick_leave",
                            "value": false,
                            "sub_question": true,
                            "questions": [
                                {
                                    "question": "Facility or venue staff",
                                    "question_key": "sick_leave_staff",
                                    "value": false
                                },
                                {
                                    "question": "Contractors",
                                    "question_key": "sick_leave_contractor",
                                    "value": false
                                }
                            ],
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Health insurance",
                            "question_key": "health_insurance",
                            "value": false,
                            "sub_question": true,
                            "questions": [
                                {
                                    "question": "Facility or venue staff",
                                    "question_key": "health_insurance_staff",
                                    "value": false
                                },
                                {
                                    "question": "Contractors",
                                    "question_key": "health_insurance_contractor",
                                    "value": false
                                }
                            ],
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Physical controls to encourage social distancing (e.g. floor markers, capacity constraints, seat spacing)",
                            "question_key": "social_distance",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "HVAC system operation to address disease transmission",
                            "question_key": "hvac_system",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Increased outdoor air supply",
                            "question_key": "hvac_outdoor",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Increase ventilation rates",
                            "question_key": "hvac_ventilation",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Enhance air filtration",
                            "question_key": "hvac_filtration",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Elevator management (e.g. occupancy limits, targeted cleaning)?",
                            "question_key": "elevator_man",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "Potable water system management (e.g. flush out, testing)?",
                            "question_key": "potable_water",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        },
                        {
                            "question": "IAQ assessment or commissioning process focused on systems and procedures related to infection control?",
                            "question_key": "iaq_assessment",
                            "value": false,
                            "upload": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            },
                            "link": {
                                "text": "",
                                "show": true,
                                "value": true,
                                "data": []
                            }
                        }
                    ]
                }
            ];

            this.loading = true;
            this.api.get('/assets/LEED:'+ this.leed_id + '/survey/fmpolicy/?key=' + this.key).subscribe(
                response =>
                {
                    var survey_data = response;
                    if(survey_data['created_at'] != undefined)
                    {
                        this.note = "The following responses were provided on "+ this.global.changeDateFormat(survey_data['created_at'], 'MMM DD, YYYY') +". Please edit, add, or delete responses to update facility management information. Remember to click the check box next to each link to confirm and hit submit at the bottom when it is complete.";

                        for(var i = 0; i <= 4; i++)
                        {
                            this.questions[i] = this.insertData(this.questions[i], survey_data);
                        }
                        for(var i = 0; i < this.questions[5].options.length; i++)
                        {
                            this.questions[5].options[i] = this.insertData(this.questions[5].options[i], survey_data);
                        }
                    }
                    else
                    {
                        this.note = "This survey is one component of Arc Re-Entry -- a set of tools provided by GBCI. The questions below are designed to help communicate infection control plans, policies, and procedures and document connections with public health guidance. When combined with occupant experiences and measured IAQ, this information contributes to continuous improvement and builds stakeholder confidence in facility operations. Responses are provided directly to the facility's Arc project team, and they are not available for public review without the express permission of the facility's Arc users. Please email <a href='mailto:contact@arcskoru.com'>contact@arcskoru.com</a> with any questions about this survey.";
                    }
                    this.loading = false;
                },
                error =>
                {
                    this.loading = false;
                }
            );
        };

		this.api.get('/assets/LEED:'+ this.leed_id + '/?key=' + this.key).subscribe(
            response =>
            {
				// let $element: any = $('input[type="range"]');
                
				// $element.rangeslider({
				// 	polyfill: false,
				// 	onSlide: (position: any, value: any) => 
				// 	{
				// 	},
				// });
                this.building = response;
                if (this.building.score_code_version.name >= 3){
                    this.isLatest = true;
                }
                else{
                    this.isLatest = false;
                }
            }
        );
	}

	insertData(obj: any, survey_data: any)
	{
		obj.value = survey_data[obj.question_key];
		obj.upload.file_name = [];
		obj.upload.data = [];

		if(obj.question_key + "_contact" in survey_data)
		{
			obj.contact.data = JSON.parse(survey_data[obj.question_key + "_contact"]);
			obj.contact.data = obj.contact.data == null ? [] : "";
		}
		if(obj.question_key + "_link" in survey_data)
		{
			obj.link.data = JSON.parse(survey_data[obj.question_key + "_link"]);
			obj.link.data = obj.link.data == null ? [] : obj.link.data;
		}
		if(obj.question_key + "_upload" in survey_data)
		{
			for(var j = 0; j < survey_data[obj.question_key + "_upload"].length; j++)
			{
				var doc = survey_data[obj.question_key + "_upload"][j];
				obj.upload.data.push(doc.id);
				obj.upload.file_name.push(this.global.getFileName(doc));
			}
		}
		if("sub_question" in obj && obj.sub_question)
		{
			obj.questions[0].value = survey_data[obj.question_key + "_staff"];
			obj.questions[1].value = survey_data[obj.question_key + "_contractor"];
		}
		return obj;
	};

	satisfactionChange(satisfaction: any)
	{
		if(satisfaction != 0)
		{
			this.selected_slider = true;
		}
		this.showSliderError = false;
		this.satisfaction = satisfaction;
		
		if(this.isLatest)
		{
			if(satisfaction == 3)
			{
				this.slider_text = this.lang.getText("extremely_satisfied");
			}
			else if(satisfaction == 2)
			{
				this.slider_text = this.lang.getText("very_satisfied");
			}
			else if(satisfaction == 1)
			{
				this.slider_text = this.lang.getText("satisfied");
			}
			else if(satisfaction == 0)
			{
				this.slider_text = this.lang.getText("satisfaction_none");
			}
			else if(satisfaction == -1)
			{
				this.slider_text = this.lang.getText("unsatisfied");
			}
			else if(satisfaction == -2)
			{
				this.slider_text = this.lang.getText("very_unsatisfied"); 
			}
			else if(satisfaction == -3)
			{
				this.slider_text = this.lang.getText("extremely_unsatisfied"); 
			}
		}
		else
		{
			if(satisfaction > 9)
			{
				this.slider_text = 'Extremely satisfied';
			}
			else if(satisfaction <= 9 && satisfaction >=8)
			{
				this.slider_text = 'Very satisfied';
			}
			else if(satisfaction < 8 && satisfaction >= 6)
			{
				this.slider_text = 'Satisfied';
			}
			else if(satisfaction <6 && satisfaction >4)
			{
				this.slider_text = 'Neither satisfied nor dissatisfied';
			}
			else if(satisfaction <= 4 && satisfaction >2)
			{
				this.slider_text = 'Dissatisfied';
			}
			else if(satisfaction <=2 && satisfaction > 1)
			{
				this.slider_text = 'Very Dissatisfied';
			}
			else if(satisfaction < 1)
			{
				this.slider_text = 'Extremely dissatisfied';
			}
		}
	}

	radioChange(question: any, radio_val: any)
	{
		question.value = radio_val;
	}

	removeFile(question: any, index: any)
	{
		question.upload.file_name.splice(index, 1);
		question.upload.data.splice(index, 1);
	}

	initUpload(question: any)
	{
		this.selected_question = question;
		$('#' + question.question_key + "_upload_picker").click();
	}

	removeLink(question: any, data: any)
	{
		var idx = question.link.data.indexOf(data);
		question.link.data.splice(idx, 1);
	}

	changeLink(question: any, data: any)
	{
		data = decodeURIComponent(data);
		if(!this.validator.regex.weburl.test(data))
		{
			question["error"] = true;
			question["error_message"] = "Please provide a valid URL";
			question["invalid_url"] = true;
		}
		else
		{
			question["error"] = false;
			question["error_message"] = "";
			question["invalid_url"] = false;
		}
	}

	verifyOEData()
	{
		var has_error = false;
		if(!this.selected_slider && this.submittedOESurvey)
		{
			has_error = true;
			this.showSliderError = true;
		}
		for(var i = 0; i < this.questions.length; i++)
		{
			if(!this.questions[i].slider_changed && this.submittedOESurvey && this.questions[i].type == 'survey-slider')
			{
				has_error = true;
				this.questions[i]["error"] = true;
				this.questions[i]["error_message"] = "Required question. Use the slider to make a selection.";
			}
			else
			{
				this.questions[i]["error"] = false;
				this.questions[i]["error_message"] = "";
			}
		}
		return has_error;
	}

	insertError(response?: any)
	{
		for(var i = 0; i < this.questions.length; i++)
		{
			this.questions[i]["error"] = false;
			this.questions[i]["error_message"] = "";

			if(response == undefined)
			{
				continue;
			}
			else if(response[this.questions[i].question_key + "_link"] != undefined)
			{
				this.questions[i]["error"] = true;
				this.questions[i]["error_message"] = "Please provide a valid URL";
			}
			else if(response[this.questions[i].question_key + "_upload"] != undefined)
			{
				this.questions[i]["error"] = true;
				this.questions[i]["error_message"] = "Please provide a valid documentation";
			}
			else if(response[this.questions[i].question_key] != undefined)
			{
				this.questions[i]["error"] = true;
				this.questions[i]["error_message"] = "Invalid Data";
			}

			if(this.questions[i].question_key == 'RE_FM_3')
			{
				for(var j = 0; j < this.questions[i].options.length; j++)
				{
					var item = this.questions[i].options[j];
					item["error"] = false;
					item["error_message"] = "";

					if(response == undefined)
					{
						continue;
					}
					else if(response[item.question_key + "_link"] != undefined)
					{
						item["error"] = true;
						item["error_message"] = "Please provide a valid URL";
					}
					else if(response[item.question_key + "_upload"] != undefined)
					{
						item["error"] = true;
						item["error_message"] = "Please provide a valid documentation";
					}
					else if(response[item.question_key] != undefined)
					{
						item["error"] = true;
						item["error_message"] = "Invalid Data";
					}
				}
			}
		}
	}

	verifyData()
	{
		this.insertError();
		var has_error = false;
		for(var i = 0; i < this.questions.length; i++)
		{

			if(this.questions[i].value == false)
			{
				this.questions[i].link.data = [];
				this.questions[i].upload.data = [];
			}

			if(this.questions[i].question_key == 'RE_FM_3')
			{
				for(var j = 0; j < this.questions[i].options.length; j++)
				{
					var item = this.questions[i].options[j];

					if(item.value == false)
					{
						item.link.data = [];
						item.upload.data = [];
					}
				}
			}
		}
		return has_error;
	}

	addLink(question: any, data: any, elem: any)
	{
		data = decodeURIComponent(data);
		if(!this.validator.regex.weburl.test(data))
		{
			question["error"] = true;
			question["error_message"] = "Please provide a valid URL";
			question["invalid_url"] = true;
			return;
		}
		else
		{
			question["error"] = false;
			question["error_message"] = "";
			question["invalid_url"] = false;
		}

		if(question.link.data.indexOf(data) == -1)
		{
			question.link.data.push(data);
		}
		if(this.type == 'oe')
		{
			this.has_error = this.verifyOEData();
		}
		else
		{
			this.has_error = this.verifyData();
		}
		elem.link_value = "";
	}

	sliderChange(question: any, slider_val: any)
	{
		question.value = parseInt(slider_val);
		if(!question.slider_changed && slider_val != '2')
		{
			question.slider_changed = true;
		}

		if(slider_val == '0')
		{
			question.value_label = 'Never';
		}
		else if(slider_val == '1')
		{
			question.value_label = 'Rarely';
		}
		else if(slider_val == '2')
		{
			question.value_label = question.slider_changed ? 'Sometimes' : 'Slide to your selection';
		}
		else if(slider_val == '3')
		{
			question.value_label = 'Usually';
		}
		else if(slider_val == '4')
		{
			question.value_label = 'Always';
		}
		this.has_error = this.verifyOEData();
	}

	textAreaChange(question: any, elem: any)
	{
		question[question.question_key + "_reason"] = elem.value;
	}

	submitFMSurvey()
	{
		var payload: any = {};
		for(var i = 0; i < this.questions.length; i++)
		{
			var key = this.questions[i].question_key;
			if(key == 'RE_FM_3')
			{
				for(var j = 0; j < this.questions[i].options.length; j++)
				{
					var item = this.questions[i].options[j];
					payload[item.question_key] = item.value;
					if(typeof item.value == 'boolean' && item.value)
					{
						if(item.upload.show)
						{
							payload[item.question_key + "_upload"] = JSON.stringify(item.upload.data);
						}
						if(item.link.show)
						{
							payload[item.question_key + "_link"] = JSON.stringify(item.link.data);
						}
					}
					else
					{
						payload[item.question_key + "_upload"] = "[]";
						payload[item.question_key + "_link"] = "[]";
					}

					if(item.sub_question)
					{
						for(var k = 0; k < item.questions.length; k++)
						{
							var key = item.questions[k].question_key;
							payload[key] = item.questions[k].value;
						}
					}
				}
				continue;
			}
			payload[key] = this.questions[i].value;

			if("contact" in this.questions[i] && this.questions[i].contact.show)
			{
				payload[key + "_contact"] = JSON.stringify(this.questions[i].contact.data);
			}
			if(this.questions[i].upload.show)
			{
				payload[key + "_upload"] = typeof this.questions[i].value == 'boolean' && this.questions[i].value ? JSON.stringify(this.questions[i].upload.data) : "[]";
			}
			if(this.questions[i].link.show)
			{
				payload[key + "_link"] = typeof this.questions[i].value == 'boolean' && this.questions[i].value ? JSON.stringify(this.questions[i].link.data) : "[]";
			}
		}

		var data = new FormData();
		for(let key in payload)
		{
			if(payload[key] == undefined)
			{
				continue;
			}
			data.append(key, payload[key]);
		}

		this.loading = true;
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": Config.sub_key
			}
		};
		this.api.post('/assets/LEED:'+ this.leed_id +'/survey/fmpolicy/?key=' + this.key, data, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				this.survey_submitted = true;
				this.loading = false;
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.alertService.alert('error', "Error submitting survey", 20);
				if(response.status == 400)
				{
					this.insertError(response);
				}
			}
		);
	}

	submitOESurvey()
	{
		this.submittedOESurvey = true;
		this.has_error = this.verifyOEData();
		if(this.has_error)
		{
			return
		}

		var payload: any = {};
		for(var i = 0; i < this.questions.length; i++)
		{
			var key = this.questions[i].question_key;
			payload[key] = this.questions[i].value;
			if('reason' in this.questions[i] && this.questions[i].reason.data != undefined && this.questions[i].reason.data.length > 0)
			{
				payload[key + "_reason"] = this.questions[i].reason.data;
			}
			if(this.questions[i][key + "_reason"] != undefined)
			{
				if(key == 'social_distance1')
				{
					payload["social_distance_reason1"] = this.questions[i][key + "_reason"];
				}
				else if(key == 'social_distance2')
				{
					payload["social_distance_reason2"] = this.questions[i][key + "_reason"];
				}
				else
				{
					payload[key + "_reason"] = this.questions[i][key + "_reason"];
				}
			}
			if(key == 'infection_control')
			{
				switch(payload[key])
				{
					case false:
						payload[key] = false
						break;
					case true:
						payload[key] = true
						break;
					case null || undefined:
						payload[key] = null
				}
			}
		}

		var compliments = [];
		if(this.satisfaction > 0)
		{
			for(var a in this.enhance)
			{
				if(this.enhance[a].data)
				{
					compliments.push(this.enhance[a].value)
				}
			}
		}
		else
		{
			for(var a in this.reduce)
			{
				if(this.reduce[a].data)
				{
					compliments.push(this.reduce[a].value)
				}
			}
		}
		payload["feedbacks"] = JSON.stringify(compliments);
		payload["response_method"] = "web";
		payload["language"] = "English";
		payload["location"] = $('.location').val();
		payload["scope_type"] = this.scope;
		payload["group_type"] = this.group;
		payload["tenant_name"] = $('.name').val();
		payload["satisfaction"] = this.satisfaction;
		if(this.group_name != undefined && this.group_name.length > 0)
		{
			payload["group_name"] = this.group_name;
		}
		payload["other_complaint"] = $('.other_attributes').val();
		payload["occupant_category"] = $('#occupant_category').val();

		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/survey/environment/?key=' + this.key, payload, this.config_header).subscribe(
			//success handler
			response =>{
				this.survey_submitted = true;
				this.loading = false;
			},
			//error handler
			response =>{
				this.loading = false;
				this.alertService.alert('error', "Error submitting survey", 20);
			}
		);
	}

	goTo(url: string)
	{
		window.open(url, '_blank');
	}

	uploadToS3(form_data: any, category: any, type: any, size: any, file_name: any, question: any)
	{
		if((size / 1024000) > 25.0 && category == 'project-structure-images')
		{
			this.alertService.alert('error', 'File size should not exceed 25MB', 5);
			return;
		}
		
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				// "Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', question.question_key);
		if(question.upload['file_name'] == undefined)
		{
			question.upload['file_name'] = [file_name];
		}
		else
		{
			question.upload['file_name'].push(file_name);
		}
		// return;
		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/uploadS3/?upload_category=' + question.question_key + "&key=" + this.key, form_data, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				question.upload.data.push(response.id);

				if(this.type == 'oe')
				{
					this.has_error = this.verifyOEData();
				}
				else
				{
					this.has_error = this.verifyData();
				}
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.alertService.alert('error', 'Error uploading file. Please try again.', 5);
			}
		);
	}

	fileNameChanged(elem: any, category: string)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		//var type = $(this).attr('category');
		var data;
		var temp_uploading = [];
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			// selected_question.upload.data =  $(elem)[0].files[a];
			// selected_question.upload['file_name'] = filePath;

			data = new FormData();
			data.append('action_file', $(elem)[0].files[a]);
			this.uploadToS3(data, category, $(elem)[0].files[a].type, $(elem)[0].files[a].size, $(elem)[0].files[a].name, this.selected_question);
		}
	}

}

<div *ngIf="type == 're_entry'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Re-Entry Comprehensiveness Score
            </span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb20">
                Arc calculates a Comprehensiveness Score (“Comp Score”). The Comp Score is a 0 to 100% measure of the fraction of positive responses compared to the total number of potential positive responses. The Comp Score is based on equal contributions (⅓ each) from facility management, occupant observations, and indoor air quality. 
            </div>
    
            <div class="arcp pb20">
                In some cases, specific indicators may not be applicable to a given facility. When a user selects “not applicable”, the question is removed from both the numerator and the denominator of the Comp Score equation. For example, a question about increasing outdoor air supply might be not applicable for an open air venue. In this case, the number of responses required to get a 100% Comp Score is reduced. There is no way for Arc to independently verify the applicability of strategies, and users should consider this when evaluating Comp Scores.
            </div>
    
            <div class="arcp">
                It is also important to note that Comp Scores are not a direct indicator of health or the risk of infectious disease transmission. They are exactly what the name implies, an indicator of the relative comprehensiveness of management practices, occupant experiences, and indoor air quality measurements. 
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Measurabl'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Measurabl</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp">
                <a href="//www.measurabl.com/" target="_blank">Company Website</a>
            </div>
    
            <div *ngIf="!(appData.get('current_app') == 'home')" [ngClass]="checking_integration ? 'not-active' : ''" (click)="goToPayment(appData.get('buildingData'), {'soreference': 'MEASURABL MONTHLY SUBSCRIPTION', 'app_name': 'measurabl'})" class="mt20 mb20 btn btn-primary">
                <span>Get Started</span>
            </div>
    
            <div class="arcp">
                Measurabl is precision software built to help you collect, report and act upon non-financial data. User Measurabl for FREE then upgrade when you're ready for investment grade sustainability.
            </div>
    
            <div class="arcp">
                <ul>
                    <li>Effortlessly collect utility, waste and travel data.</li>
                    <li>Create, export, and e-File investment-grade reports on demand.</li>
                    <li>Benchmark performance and sport opportunities to improve.</li>
                </ul>
            </div>
    
            <h4 class="arch mt30 mb10">
                How to start using Measurabl with Arc:
            </h4>
            <div class="arcp">
                <ul>
                    <li>Have data ready - 12 months of energy and/or water data.</li>
                    <li>Create Measurabl account (if you don’t already have one).</li>
                    <li>Activate the integration for this project by clicking the get started button above and requesting data pull from Measurabl. If you would like to sync multiple projects, please <a href="mailto:contact@arcskoru.com?subject=Arc <-> Measurabl integration">contact us</a> to get started.</li>
                    <li>Apply for LEED Certification in minutes.</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Commutifi'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Commutifi</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <!-- <iframe width="100%" height="220" src="https://www.youtube.com/embed/6pKhC2_1tII" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    
            <div class="arcp" class="pb10">
                <a href="//www.commutifi.com/" target="_blank">Company Website</a>
            </div>
    
            <div class="arcp">
                Commuter Scoring technology helps you, your tenants, and your commuters save time and money while reducing harmful carbon emissions. Attract more tenants and avoid wasted capital with programs specifically designed for each unique situation.
            </div>
    
            <div class="arcp">
                <ul>
                    <li>Understand commuting behaviors with advanced analytics and heatmaps.</li>
                    <li>Develop effective amenities and manage all your mobility solutions in a single marketplace.</li>
                    <li>Measure impact and ROI of programs with real-time metrics at the individual commuter, building, and complex scales</li>
                </ul>
            </div>
    
            <div class="arch mt30 mb10">
                Connecting Commutifi and Arc
            </div>
            <div class="arcp">
                Complete the following steps to connect your Commutifi data to Arc:
            </div>
            <div class="arcp">
                <ul>
                    <li>Create an account with Commutifi Create an account with Commutifi</li>
                    <li>Building occupants register with Commutifi via a link you provide them</li>
                    <li>Connect your Arc account on your Commutifi dashboard</li>
                    <li>Your LEED Transportation Performance score is automatically calculated using our data and added to your Arc account</li>
                    <li>Improve your LEED Transportation Performance score using Commutifi tools and tracking</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'QLEAR'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">QLEAR</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <!-- <iframe width="100%" height="220" src="https://www.youtube.com/embed/6pKhC2_1tII" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    
            <div class="arcp pb10">
                <a href="//www.qlear.io/" target="_blank">Company Website</a>
            </div>
    
            <div class="arcp">
                QLEAR’s platform provides an easy way to manage your building’s environmental data. As an integrated indoor environmental quality (IEQ) management system, data is collected, organized, and analyzed in real-time from air quality, electricity, and other readily available data sources.
            </div>
    
            <div class="arcp">
                <ul>
                    <li>CENTRALIZE your network of environmental and health data into a single hardware-agnostic platform.</li>
                    <li>DISCOVER what your data says. Use our analytics tools to identify trends and reveal narratives.</li>
                    <li>IMPROVE the health of your occupants, the environmental impact of your locations, and the bottom line of your organization.</li>
                </ul>
            </div>
    
            <div class="arch mt30 mb10">
                Getting more out of QLEAR and Arc
            </div>
            <div class="arcp">
                QLEAR can provide real-time air quality and energy data for your projects on Arc. To see how QLEAR and Arc can work for your project, please reach out to us <a href="//qlear.io/web/contact" target="_blank">here</a>.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'arbnco'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">arbnco</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <!-- <iframe width="100%" height="220" src="https://www.youtube.com/embed/6pKhC2_1tII" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    
            <div class="arcp pb10">
                <a href="https://arbnco.com/" target="_blank" (click)="global.goToExternalLink('https://arbnco.com/')">Company Website</a>
            </div>
    
            <div class="arcp">
                Based in Glasgow, UK and Detroit, Michigan, arbnco is a building performance software company helping businesses to measure, analyze and improve energy efficiency and Indoor Environmental Quality, which makes for healthier buildings and occupants.
            </div>
            
            <div class="arch mt30 mb10">
                Indoor Environmental Quality monitoring platform
            </div>
            <div class="arcp">
                arbnco’s Indoor Environmental Quality platform combines cutting edge sensor technology with intelligent data analytics to optimize Indoor Environmental Quality for healthy, high-performing and sustainable buildings. The system comprises:
            </div>
            <div class="arcp">
                <ul>
                    <li>Sensors for continuous and high-resolution monitoring of air quality and thermal comfort</li>
                    <li>Occupant engagement tools to collect feedback and survey data</li>
                    <li>Dashboard for centralized management, automated alerts and real-time visualization</li>
                    <li>Data analytics and reporting to inform building optimization</li>
                    <li>Building controls for integration with Niagara, BACnet and other protocols</li>
                </ul>
            </div>
    
            <div class="arch mt30 mb10">
                Integration with Arc Platform
            </div>
            <div class="arcp">
                The one-click integration between the arbnco and Arc platforms provides a reliable and seamless user experience with harmonized dashboards and metrics to enable:
            </div>
            <div class="arcp">
                <ul>
                    <li>Standardization: arbnco and Arc customers can compare scores for global portfolios and measure performance objectively</li>
                    <li>Streamlining: faster and easier certification, re-certification and reporting</li>
                    <li>Gap analysis: target scoring improvement opportunities</li>
                    <li>Stakeholder engagement: data collection tools to record human experience metrics</li>
                </ul>
            </div>
            <div class="arcp pb10">
                Meet your project’s Indoor Environmental Quality requirements with the Arc-arbnco Human Experience bundle <a href="https://arbnwell.com/arc-integration/" target="_blank" (click)="global.goToExternalLink('https://arbnwell.com/arc-integration/')">here</a>.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'B-Line'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">B-Line</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="https://bline.io/" target="_blank">Company Website</a>
            </div>
    
            <div class="arcp pb20">
                B-Line is an artificial intelligence platform that speeds the path to green building certification by enabling environmental consultants and organizations to quickly and easily and collect occupant comfort and commuting data. Beyond the data needed for green building certifications, B-Line's data helps occupants carpool, enhances a cities biking and transit infrastructure, and helps citizens get to work faster.
            </div>
    
            <div class="pb20">
               Through the use of mobile devices, B-Line passively collects user's mobility patterns and generates real-time transportation reporting on user's trip time, duration, distance, purpose, and mode. This data is automatically uploaded into Arc and enables real estate developers and property managers to understand carbon emissions as well as how building occupants navigate the built environment without having to purchase automated traffic counters and self-reporting travel surveys.
            </div>
    
            <div class="arcp">
               No hiring staff to collect data at entrances. No annoyed building residents. No double counts. And no time spent processing days of self-reporting surveys. B-Line makes green building compliance easy and affordable.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Schneider Electric'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Schneider Electric</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="http://www.se.com" target="_blank">Company Website</a>
            </div>
    
            <div class="arcp pb20">
                At Schneider, we believe access to energy and digital is a basic human right. We empower all to make the most of their energy and resources, ensuring Life Is On everywhere, for everyone, at every moment. We provide energy and automation digital solutions for efficiency and sustainability. We combine world-leading energy technologies, real-time automation, software and services into integrated solutions for Homes, Buildings, Data Centers, Infrastructure and Industries.We are committed to unleash the infinite possibilities of an open, global, innovative community that is passionate about our Meaningful Purpose, Inclusive and Empowered values.
            </div>
    
        </div>
    </div>
</div>

<div *ngIf="type == 'EnergyWatch'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">WatchWire</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
           <div class="arcp pb10">
                <a href="https://watchwire.ai/" target="_blank">Company Website</a>
            </div>
    
            <div class="arcp">
                WatchWire is a sustainability and energy management software-as-a-service (EMSaaS) provider. Across the globe, WatchWire helps commercial and corporate real estate portfolios, Fortune 500 industrial/manufacturing and big-box retail, government, healthcare, and educational facilities reduce emissions and expenses while simplifying sustainability and carbon reporting.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Wattics'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Wattics</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
           <div class="arcp pb10">
                <a href="https://www.wattics.com/" target="_blank">Company Website</a>
            </div>
    
            <div class="arch">
                Business energy analytics and asset management platform
            </div>
            <br>
            <div class="arcp">
                The Wattics platform is the preferred choice of over 200 energy service companies in 52 countries. The platform allows the ESCO to have a fully branded energy analytics platform that they can personalise, manage, connect meters and IoT devices and create a recurring revenue stream through energy services and performance reporting.
            </div>
            <br>
            <div class="arcp">
                Wattics features unique machine learning tools to recognise abnormal patterns of consumption, keep track of energy-efficiency projects, renewables upgrades, energy-saving programs and certifications. In addition, the ESCOs love the ability to tune how much they pay at each giving month by starting/stopping data streaming as they need and the ability to create formula-based data points to track KPIs, compliances and events.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Idun'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Idun</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
            <div class="arcp pb10">
                <a href="https://idunrealestate.com/" target="_blank" (click)="global.goToExternalLink('https://idunrealestate.com/')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                Making the world’s buildings able inhabitants of the smart city.
            </div>
    
            <div class="arcp pb10">
                Idun is an IoT Operating System designed for and by real estate owners that are making the world’s buildings smarter faster. Powered by the data mapping capabilities of the open-source semantic language RealEstateCore, Idun ProptechOS enables you to unlock a host of smart building benefits. Like better sustainability, well-being, productivity, and business.
            </div>
    
            <div class="arcp pb10">
                By leveraging the potential of your own building data you can facilitate better building utilization, new customer services, and more efficient building communications. Greater overall well-being and productivity that everybody benefits from.
            </div>
    
            <div class="arcp pb10">
                Idun ProptechOS is a property Operating System for smart building owners. It’s the easiest way to get started using RealEstateCore and transforming your property portfolio into an application platform ready to optimize.
            </div>
    
            <div class="arcp pb10">
                Free from reliance on proprietary systems, real estate owners can connect their smart buildings to a shared marketplace of development and a host of new services and possibilities on a far larger scale—including the latest applications for energy, certification, utilization, and communication.
            </div>
    
            <div class="arcp pb10">
                Building information processing facilitates integration and enables scaling beyond one unique building. When two or more buildings share the same language and operational efficiency goals, they can work together to create even more value. Smart cities benefit surrounding communities and are net positive for the environment.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Autocase'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Autocase</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="https://autocase.com/" target="_blank" (click)="global.goToExternalLink('https://autocase.com/')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                Create a more sustainable, resilient and cost-effective built environment, by unleashing the power of economic insights.
            </div>
    
            <div class="arcp pb10">
                Autocase’s mission is to empower owners and designers to create a more sustainable, resilient and cost-effective built environment, by unleashing the power of economic insights at scale.
            </div>
    
            <div class="arcp">
                Autocase and Arc provides you:
            </div>
    
            <div class="arcp pb10">
                <ul>
                    <li>Quantified impacts from past financial and environmental costs from operations of your building,</li>
                    <li>The business case for achieving your building performance targets in economic dollars for owners, occupants and the environment, and</li>
                    <li>The ability to use your performance data, automating the workflow, to inform retrofit projects.</li>
                </ul>
            </div>
    
            <div class="arcp">
                Use the Autocase business case metrics to:
            </div>
            <div class="arcp pb10">
                <ul>
                    <li>Contribute to environmental, social, and governance (ESG) reporting.</li>
                    <li>Inform interventions to improve financial and environmental performance.</li>
                    <li>Support marketing and communications.</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Bractlet'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Bractlet</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="https://bractlet.com/" target="_blank" (click)="global.goToExternalLink('https://bractlet.com/')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                Better Decisions. Optimized Buildings
            </div>
    
            <div class="arcp pb10">
                Bractlet is a software and intelligence company pioneering Digital Energy Twin™ technology to allow real-estate operators to make better decisions on building upgrades and run more efficient buildings, at scale. Bractlet’s tiered offering provides owners and property teams the flexibility to select the product features and depth of analysis they need based on their investment strategy and budget.
            </div>
    
            <div class="arcp pb10">
                Bractlet Benchmark™ collects, organizes, and stores utility bill and weather data in a user-friendly format plus provides energy consumption benchmarks versus comparable buildings. It also enables the seamless storage and upload of EnergyStar, LEED, and GRESB data.
            </div>
    
            <div class="arcp pb10">
                Bractlet Analytics™ In addition to Benchmark features, Analytics provides real-time building performance analytics with comfort, equipment operation, and energy consumption notifications, diagnostics and prescriptive actions.
            </div>
    
            <div class="arcp pb10">
                Bractlet Simulation™ Simulation includes all the functionality of Benchmark and Analytics, while offering the industry’s only true Digital Energy Twin™ building simulation. It produces the most predictive building performance forecasts to aid in the identification, evaluation, and implementation of building optimization and upgrade projects across portfolios.
            </div>
    
            <div class="arcp pb10">
                Bractlet’s platform brings significantly greater transparency to the built environment and provides tools to help building owners and their property teams make better investment decisions; streamline and improve the effectiveness of building optimization and upgrade projects; and ensure buildings sustain their performance over time.
            </div>
            
        </div>
    </div>
</div>

<div *ngIf="type == 'Moody' || type == 'Moody\'s'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Moody's ESG Solutions</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="https://esg.moodys.io/climate-solutions" target="_blank" (click)="global.goToExternalLink('https://esg.moodys.io/climate-solutions')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                Moody’s Climate Solutions suite integrates forward-looking, location-specific and globally comparable climate data into our world-class economic and financial risk models.
            </div>
    
            <div class="arcp pb10">
                Committed to forging a sustainable future, Moody’s ESG Solutions can help your organization to better understand ESG performance, assess climate and environmental risk exposure, strengthen sustainability action plans and meet disclosure requirements. One of our five business lines, Moody’s Climate Solutions suite integrates forward-looking, location-specific and globally comparable climate data into our world-class economic and financial risk models. We help organizations navigate the financial and social impacts of climate change and seize new opportunities for value creation. Our Climate on Demand application allows users to score thousands of facilities in real time, to receive information on their exposure to floods, heat stress, hurricanes & typhoons, sea level rise, water stress and wildfires to inform due diligence and risk management.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'FGB-Studio' || type == 'FGB Studio'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">FGB Studio</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="http://www.fgb-studio.com" target="_blank" (click)="global.goToExternalLink('http://www.fgb-studio.com')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                FGB Studio is a leading consulting company focused on sustainability, human well-being and the triple bottom line of people, planet and profit.
            </div>
    
            <div class="arcp pb10">
                We work side-by-side with our clients with a tailor made approach that never forgets the specificity of every company and office we deal with, providing, with extreme accuracy and proficiency, certifications from LEED, WELL, Envision, GRESB, Fitwel, and beyond. Our company also provides complete building lifecycle assessments and monitoring services.
            </div>
    
            <div class="arcp pb10">Last year forced us to get used to an intense indoor lifestyle - a new reality to which the FGB Studio would answer with a sustainable response for keeping air quality parameters under control. FGB studio air quality monitoring system offers a tailored cloud-based system, with a management platform that is able to monitor and collect real-time data. The system can monitor indoor parameters for CO2, TVOC, HUMIDITY, TEMPERATURE, PM 2.5 and PM10.</div>
            
            <div class="arcp pb10">Moreover, the system can also monitor and collect other real-time data as for consumption of electricity, water, etc. The data are stored and accessible through the FGB studio APP, available on PCs and smartphones.</div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Renteknik-Group' || type == 'Renteknik Group'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Renteknik Group</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
            <div class="arcp pb10">
                <a href="http://www.renteknikgroup.com" target="_blank" (click)="global.goToExternalLink('http://www.renteknikgroup.com')">Company Website</a>
            </div>
    
            <div class="arcp pb10">
                Renteknik uses the best-in-class technologies and unique energy engineering solutions to empower our clients to maximize operational efficiency, achieve energy savings and improve productivity and performance.
            </div>
    
            <div class="arcp pb10">
                We are an all-inclusive team of engineers, project managers, financial and business experts. We take the worry out of managing your operations and your energy. Our team of professionals understand system and building operations, finance and energy which means we will create an Operational and Energy Management solution that suits all of your individual needs.
            </div>
    
            <div class="arcp pb10">OPERATIONAL EFFICIENCY AND ENERGY MANAGEMENT SERVICES:
                <ul>
                    <li>Efficiency/Performance Monitoring</li>
                    <li>Monitoring, Targeting & Reporting</li>
                    <li>LEED, submetering and performance scoring</li>
                    <li>Energy Management and Information Systems (EMIS)</li>
                    <li>Global Adjustment and Demand Response Management</li>
                    <li>Existing Building Commissioning/Recommissioning (Full Building, Systems)</li>
                    <li>BTU and Smart Water submetering</li>
                </ul>
            </div>
    
            <div class="arcp pb10">INDUSTRY FOCUS:
                <ul>
                    <li>Industrial – Manufacturing, waste management, processing/production</li>
                    <li>Commercial – Retail, Grocery Stores</li>
                    <li>Multi-residential</li>
                    <li>MASH – municipalities</li>
                </ul>
            </div>
    
            <div class="arcp pb10">TECHNOLOGY SUPPLY AND TECHNICAL SUPPORT:
                <ul>
                    <li>Panoramic Power Energy Management Solution</li>
                    <li>Kamstrup Smart Metering Solutions</li>
                    <li>Circutor Energy Management Systems</li>
                    <li>HD Watch – Food Retail/Grocery Real-Time Performance Monitoring Platform</li>
                    <li>ClimaCheck HVAC Performance Analyzer</li>
                    <li>RDM – HVAC/R control and energy management</li>
                </ul>
            </div>
    
            <div class="arcp pb10">SUPPORT SERVICES:
                <ul>
                    <li>Project Management</li>
                    <li>Project Financing</li>
                </ul>
            </div>
    
            <div class="arcp pb10">Contact:
                <ul>
                    <li><strong>Contact Details: </strong>Marti Faura, VP Engineering and Business Development</li>
                    <li><strong>Email: </strong> <a href="mailto:m.faura@renteknikgroup.com">m.faura@renteknikgroup.com</a></li>
                </ul>
            </div>
    
            <div class="arcp pb10">Social handles:
                <ul>
                    <li><a target="_blank" href="https://www.linkedin.com/company/2462145/">LinkedIn</a></li>
                    <li><a target="_blank" href="https://www.facebook.com/Renteknik-Group-Inc-1106900359339476/">Facebook</a></li>
                    <li><a target="_blank" href="https://www.youtube.com/channel/UCpxMCntbTiM9uZFMZUXzflw">YouTube</a></li>
                    <li><a target="_blank" href="https://www.instagram.com/renteknikgroup/">Instagram</a></li>
                </ul>
            </div>
            
        </div>
    </div>
</div>

<div *ngIf="type == 'GSA' || type == 'Green Sports Alliance'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Green Sports Alliance</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
           <div class="arcp pb10">
                <a href="https://greensportsalliance.org/" target="_blank">Company Website</a>
            </div>
            <br>
            <div class="arcp">
                The <a href="https://greensportsalliance.org/" target="_blank">Green Sports Alliance</a> and <a href="https://arc.gbci.org/" target="_blank">Arc</a> have teamed up to launch Play to Zero to help sports teams, leagues, venues and conferences around the world reduce their GHG emissions, work toward net zero milestones and goals and bring greater health and wellness, resilience and sustainability to the industry.
            </div>
            <br>
            <div class="arcp">This program will provide sporting venues with a pathway to LEED certification. LEED is the world's most widely used green building program with projects in182 countries and territories. Already, hundreds of sporting facilities around the world have achieved LEED certification. Play to Zero will continue to scale this growth, helping the sporting industry take a portfolio-level approach to green building certification through the use of Arc. <a href="https://www.prnewswire.com/news-releases/arc-partners-with-the-green-sports-alliance-to-drive-the-sports-sector-toward-net-zero-301271739.html" target="_blank">Learn more.</a> </div>
            <br>
            <div class="arcp">About the Green Sports Alliance: The Green Sports Alliance is the environmentally-focused trade organization that convenes stakeholders from around the sporting world to promote healthy, sustainable communities where we live and play.</div>
        </div>
    </div>
</div>

<div *ngIf="type == 'Energenius'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Energenius</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
           <div class="arcp pb10">
                <a href="https://www.energenius.it/" target="_blank">Company Website</a>
            </div>
            <br>
            <div class="arcp">
                Energenius Srl is an innovative small-scale Italian start-up - founded in 2017 - that integrates existing hardware products for energy monitoring systems and offers added value through advanced energy consumptions analysis. This combination of systems integration and energy consultancy within a unique offer has ensured a clear positioning within the energy market.
            </div>
            <br>
            <div class="arcp">
                Energenius caters to large industrial and tertiary sector customers, providing consulting services and innovative products ranging from field data collection through smart meters to the automated analysis of buildings and industrial production processes. Experience in the field of energy analysis has also made it possible to identify patterns and recurrent behaviors on specific types of buildings.
            </div>
            <br>
            <div class="arcp">
                Research tasks play a fundamental role in the ordinary activities of the company, trying to provide and offer innovative products compared to the state of the art and to competitors’ offer.
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'AS_disclaimer'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">Disclaimer</span>
            <i (click)="cancel()" class="fa cancel fa-times"></i>
        </div>
        <div class="p25">
            <div class="pb20 arcp">
                Building performance calculations and interpretations are for informational purposes only. They are subject to significant limitations, such as: Calculations are based on user-supplied data. These data may not represent the total energy and emissions associated with a facility. Electricity-related emissions reflect regional or national statistics (e.g., EPA eGRID). Performance standard criteria and emissions factors may change without notice. Consequently, the scores and rating generated in this tool are best used for exploratory data analysis, and they are not intended for regulatory compliance.
            </div>
            <div class="arcp">
                Users are encouraged to send feedback to <a href="mailto:contact@arcskoru.com">contact@arcskoru.com</a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="type == 'bee' || type == 'BEE Inc.'">
    <div id="arc_help">
        <div class="header_container">
            <span class="title">BEE Inc</span>
            <div (click)="cancel()">
                <i class="fa cancel fa-times"></i>
            </div>
        </div>
    
        <div class="p2535">
    
           <div class="arcp fw500">
                <a href="https://bee-inc.com/" target="_blank">Company Website</a>
            </div>
            <br>
            <div class="arcp bold">
                BEE Sense is the first system to be fully integrated with Arc for the transmission of energy, water, and air quality data for LEED O+M Certification.  
            </div>
            <br>
            <div class="arcp">
                We seamlessly collect, organize, and analyze air, energy, and water data in real-time. Our cloud-based, ESG reporting tool not only focuses on indoor and outdoor air quality but also integrates electricity, gas, water consumption, water quality, and sound levels.
            </div>
            <br>
            <div class="arcp">
                We are a hardware-agnostic platform that can collect data from anywhere and conduct carbon footprint analyses on Scope 1 and Scope 2 greenhouse gas emissions, from single projects to entire portfolios. 
            </div>
            <br>

            <div class="arcp">
                Our growing list of data parameters includes:
                <ul>
                    <li>Air: PM2.5, PM10, TVOC, CO2, Temperature, Relative Humidity, Ozone, Carbon Monoxide, Formaldehyde, Fresh Air Volume Flow</li>
                    <li>Electricity: Active Power, Apparent Power, Reactive Power, Power Factor, Voltage, L-L Voltage, Current, Cumulative Energy </li>
                    <li>Energy: Gas Consumption, Thermal Energy (chilled & hot water)</li>
                    <li>Renewable On-Site Renewable Energy Supply</li>
                    <li>Water: Flow Rate Consumption, Water Leakage Detection, Total Dissolved Solids), Water Temperature, pH</li>
                    <li>Sound: Average dBA levels</li>
                    <li>Light: Average Lux levels </li>
                </ul>
            </div>
            <br>

            <div class="arcp">
                Features List:
                <ul>
                    <li>Portfolio Management, Analysis, and KPI Benchmarking</li>
                    <li>Flexible Data Visualization & Calculations </li>
                    <li>Measure, Verify, and Audit</li>
                    <li>ESG Compliance & Carbon Footprinting</li>
                    <li>Machine-Learning Analytics</li>
                    <li>ARC Integration for LEED Certification & WELL Air Compliance </li>
                    <li>RESET Viral Index </li>
                    <li>Cost-Saving Identification & Tariff Diagnostics </li>
                    <li>Automated Custom Reporting</li>
                    <li>Tailored Alerts & Notifications</li>
                    <li>Modular Functionality with Open API Integration </li>
                    <li>Utility Bill Management </li>
                </ul>
            </div>
        </div>
    </div>
</div>

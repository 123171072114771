import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { Projects } from '../../../services/projects.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import * as Tagify from '@yaireo/tagify';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit
{
	config = Config;
	regex = this.validator.regex;

	leed_id: number = this.appData.get('leed_id')
	loading: boolean = true;
	picker_loaded: boolean = false;
	loader: string = "<svg class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>";
	countries: any = [];
	states: any = [];
	OtherCertProg: any = [];
	spaceType: any = [];
	ownerType: any = [];
	ongoing: any = {};
	actions: any = {};
	no_error = {
		'error': false,
		'help': undefined
	};
	manageEntityState: string = '';
	manageEntityCountry: string = '';
	formdata: any = {};
	error_in_tags: boolean = false;
	error_in_tags_text: string = '';
	unitType: string[] = ["SI", "IP"];
	structure: any = {
		'city': {
			'name': 'Project Name',
			'confidential': 'Private',
			'year_constructed': 'Year Founded',
			'manageEntityName': 'Managing entity Name',
			'manageEntityAdd1': 'Managing entity Address (line 1)',
			'manageEntityAdd2': 'Managing entity Address (line 2)',
			'manageEntityCity': 'Managing entity City',
			'manageEntityCountry': 'Managing entity Country/Region',
			'manageEntityState': 'Managing entity State',
			'intentToPrecertify': 'Intend to precertify?',
			'targetCertDate': 'Target certification date',
			'test_project': 'This is a test project'
		},
		'community': {
			'name': 'Project Name',
			'confidential': 'Private',
			'year_constructed': 'Year Founded',
			'manageEntityName': 'Managing entity Name',
			'manageEntityAdd1': 'Managing entity Address (line 1)',
			'manageEntityAdd2': 'Managing entity Address (line 2)',
			'manageEntityCity': 'Managing entity City',
			'manageEntityCountry': 'Managing entity Country/Region',
			'manageEntityState': 'Managing entity State',
			'intentToPrecertify': 'Intend to precertify?',
			'targetCertDate': 'Target certification date',
			'test_project': 'This is a test project'
		},
		'building': {
			'name': 'Project Name',
			'confidential': 'Private',
			'spaceType': 'Space Type',
			'ownerType': 'Owner Type',
			'year_constructed': 'Year Built',
			'noOfFloors': 'Floors above ground',
			'IsResidential': 'Contains residential units?',
			'noOfResUnits': 'Number of residential units',
			'OtherCertProg': 'Other certification program pursued',
			'IsLovRecert': 'Previously LEED Certified?',
			'PrevCertProdId': 'Project ID for previously certified project',
			'intentToPrecertify': 'Intend to precertify?',
			'targetCertDate': 'Target certification date',
			'AffiliatedHigherEduIns': 'Is project affiliated with a higher education institute?',
			'nameOfSchool': 'Name of the School',
			'test_project': 'This is a test project'
		},
		'school': {
			'name': 'Project Name',
			'confidential': 'Private',
			'spaceType': 'Space Type',
			'ownerType': 'Owner Type',
			'year_constructed': 'Year Built',
			'noOfFloors': 'Floors above ground',
			'IsResidential': 'Contains residential units?',
			'noOfResUnits': 'Number of residential units',
			'OtherCertProg': 'Other certification programs pursued',
			'IsLovRecert': 'Previously LEED Certified?',
			'PrevCertProdId': 'Project ID for previously certified project',
			'intentToPrecertify': 'Intend to precertify?',
			'targetCertDate': 'Target certification date',
			'AffiliatedHigherEduIns': 'Is project affiliated with a higher education institute?',
			'nameOfSchool': 'Name of the School',
			'test_project': 'This is a test project'
		},
		'transit': {
			'name': 'Project Name',
			'confidential': 'Private',
			'spaceType': 'Space Type',
			'ownerType': 'Owner Type',
			'IsLovRecert': 'Previously LEED Certified?',
			'PrevCertProdId': 'Project ID for previously certified project',
			'OtherCertProg': 'Other certification programs pursued',
			'IsResidential': 'Contains residential units?',
			'noOfResUnits': 'Number of residential units',
			'year_constructed': 'Year Built',
			'noOfFloors': 'Floors above ground',
			'intentToPrecertify': 'Intend to precertify?',
			'targetCertDate': 'Target certification date',
			'annual_ridership': 'Annual ridership',
			'full_time_staff': 'Full time staff at station',
			'time_spent_by_riders': 'Average time spent by riders at the station (in minutes)',
			'occupancy': 'Weighted Daily Occupancy',
			'station_type': 'Station Type',
			'AffiliatedHigherEduIns': 'Is project affiliated with a higher education institute?',
			'nameOfSchool': 'Name of the School',
			'test_project': 'This is a test project'
		},
		'parksmart': {
			'name': 'Project Name',
			'unitType': 'Unit Type',
			'confidential': 'Private',
			'ownerType': 'Owner Type',
			'year_constructed': 'Date Commissioned',
			'noOfParkingSpace': 'Number of parking spaces',
			'noOfParkingLevels': 'How many levels in your parking structure?',
			'IsLovRecert': 'Previously LEED Certified?',
			'PrevCertProdId': 'Project ID for previously certified project',
			'projectWebsite': 'Project Website',
			'gross_area': 'Gross area',
			'projectInfo': 'Please tell us about your project in about 500 letters'
		},
	};
	year_constructed = (function()
	{
		var input = [];
		var min = 1900;
		var current_year = new Date().getFullYear();
		var max = current_year;
		for (var i=min; i<=max; i++)
			input.push(i.toString());
		return input.reverse();
	})();
	formdata_error: any = {
		"name": this.no_error,
		"confidential": this.no_error,
		"year_constructed": this.no_error,
		"manageEntityName": this.no_error,
		"manageEntityAdd1": this.no_error,
		"manageEntityAdd2": this.no_error,
		"manageEntityCity": this.no_error,
		"manageEntityCountry": this.no_error,
		"manageEntityState": this.no_error,
		"intentToPrecertify": this.no_error,
		"targetCertDate": this.no_error,
		"test_project": this.no_error,
		"spaceType": this.no_error,
		"ownerType": this.no_error,
		"noOfFloors": this.no_error,
		"IsResidential": this.no_error,
		"noOfResUnits": this.no_error,
		"OtherCertProg": this.no_error,
		"IsLovRecert": this.no_error,
		"PrevCertProdId": this.no_error,
		"AffiliatedHigherEduIns": this.no_error,
		"nameOfSchool": this.no_error,
		"annual_ridership": this.no_error,
		"full_time_staff": this.no_error,
		"time_spent_by_riders": this.no_error,
		"occupancy": this.no_error,
		"station_type": this.no_error,
		"unitType": this.no_error,
		"noOfParkingSpace": this.no_error,
		"noOfParkingLevels": this.no_error,
		"projectWebsite": this.no_error,
		"gross_area": this.no_error,
		"projectInfo": this.no_error
	};


	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private projects: Projects,
		private alertService: alertService,
		private validator: ValidationService
	)
	{
		this.formdata = this.appData.get('buildingData');
	}

	ngOnInit(): void
	{
		this.projects.getAssetDetails(this.leed_id).subscribe(
			data =>
			{
				this.loading = false;
				this.formdata = data;
				if(this.formdata.OtherCertProg == null)
				{
					this.formdata.OtherCertProg = 'None';
				}
				this.initValues();
			}
		), (error: any) =>
		{
			this.loading = false;
		};
		this.initTags();
		this.setupGeoNames();
		if(this.appData.get('project_type') == 'parksmart')
		{
			this.global.getUploadedFiles('project-structure-images');
		}
	}

	enableFdatePicker()
	{
		if(this.picker_loaded == false)
		{
			this.picker_loaded = true;
			(<any>$('#year_constructed')).fdatepicker(
			{
				format: 'M dd, yyyy'
			}).on('changeDate', (ev: any) =>
			{
				this.formdata.year_constructed = ev.target.value;
				this.verifyField(this.formdata.year_constructed, 'year_constructed', true);
			});
		}
	};

	isRequired(type: string)
	{
		return this.structure[this.appData.get('project_type')][type] == undefined ? false : true;
	};

	setupGeoNames()
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.formdata.country = this.global.getCountry(this.formdata.country);
				this.manageEntityCountry = this.global.getCountry(this.formdata.manageEntityCountry);
				this.manageEntityState = this.global.getState(this.formdata.manageEntityState, this.formdata.manageEntityCountry);

				this.countries = this.global.getCountryList();
				this.states = this.global.getStateList(this.appData.get('buildingData').manageEntityCountry);

				if(this.states.length == 0)
				{
					this.states = [{
						"code": "NA",
						"name": "Not Available"
					}]
				}

				if(this.manageEntityState == undefined)
				{
					this.manageEntityState = this.global.getState(this.states[0].code, this.formdata.manageEntityCountry);
					this.formdata.manageEntityState = this.states[0].code;
				}
			});
		}
		else
		{
			this.formdata.country = this.global.getCountry(this.formdata.country);
			this.manageEntityCountry = this.global.getCountry(this.formdata.manageEntityCountry);
			this.manageEntityState = this.global.getState(this.formdata.manageEntityState, this.formdata.manageEntityCountry);

			this.countries = this.global.getCountryList();
			this.states = this.global.getStateList(this.appData.get('buildingData').manageEntityCountry);

			if(this.states.length == 0)
			{
				this.states = [{
					"code": "NA",
					"name": "Not Available"
				}]
			}

			if(this.manageEntityState == undefined)
			{
				this.manageEntityState = this.global.getState(this.states[0].code, this.formdata.manageEntityCountry);
				this.formdata.manageEntityState = this.states[0].code;
			}
		}
	};

	initValues()
	{
		this.api.getAsset(
			'../../assets/json/spaceTypes.json'
		).subscribe(data => 
		{
			this.spaceType = data;
		});
		this.api.getAsset(
			'../../assets/json/ownerType.json'
		).subscribe(data => 
		{
			this.ownerType = data;
		});

		this.OtherCertProg = [
			"WELL",
			"Sustainable SITES",
			"PEER",
			"Parksmart",
			"GRESB",
			"EDGE",
			"Green Star",
			"DGNB",
			"BREEAM",
			"Zero Waste",
			"ENERGY STAR",
			"Beam",
			"CASBEE",
			"Green Mark",
			"Pearl",
			"Other",
			"None"
		];

		var date_fields: any = $('.date_field');
		for(var i = 0; i < date_fields.length; i++)
		{
			date_fields.fdatepicker(
			{
				format: <any>$('.date_field')[0].getAttribute('max')
			}).on('changeDate', (ev: any) =>
			{
				this.formdata.targetCertDate = ev.target.value;
				this.submitDetails(moment(this.formdata.targetCertDate).format('YYYY-MM-DD'), 'targetCertDate', true)
			});
		}

		if(moment(this.formdata['targetCertDate']).isValid())
		{
			this.formdata['targetCertDate'] = moment(this.formdata['targetCertDate']).format("MMM DD, YYYY");
		}
	};

	checkStatus(name: string)
	{
		if(name.toLowerCase() == "payment cleared")
		{
			return "Completed";
		}
		else if(name.toLowerCase() == "payment cancelled" || name.toLowerCase() == "auto cancelled")
		{
			return "Cancelled";
		}
		else
		{
			return "Pending";
		}
	};

	validateInfo(data: any, key: string, force_save?: boolean)
	{
		this.submitDetails(data, key, force_save);
		return;
	};
	
	submitDetails(data: any, key: string, force_save?: boolean)
	{
		if(this.loading)
		{
			return;
		}
		if(['IsResidential', 'confidential', 'IsLovRecert', 'intentToPrecertify', 'AffiliatedHigherEduIns'].indexOf(key) != -1)
		{
			data = !data;
			this.formdata[key] = data;
		}
		var payload: any = {};
		if(force_save == undefined)
		{
			force_save = false;
		}
		if(force_save || (this.formdata_error[key] != undefined && !this.formdata_error[key].error))
		{
			if(['annual_ridership', 'full_time_staff', 'time_spent_by_riders'].indexOf(key) != -1)
			{
				payload['occupancy'] = this.getTransitOccupancy();
			}
			if($('input[name=' + key)[0] != undefined && (<any>$('input[name=' + key))[0].getAttribute('class').indexOf('date_field') != -1)
			{
				data = moment(data).format("YYYY-MM-DD");
			}
			payload[key] = data;
			this.ongoing[key] = document.createElement('div');
			this.ongoing[key].innerHTML = this.loader;
			$('[name='+ key +']')[0].before(this.ongoing[key]);
			$('[name='+ key +']')[0].classList.add('not-active');

			if('manageEntityCountry' in payload)
			{
				this.states = this.global.getStateList(this.appData.get('buildingData').manageEntityCountry);
				if (this.states.length)
				{
					payload['manageEntityState'] = this.states[0].code;
				}
			}

			this.loading = true;
			var url = '/assets/LEED:'+ this.leed_id + "/";
			this.api.put(url, payload).subscribe(
				data =>
				{
					this.loading = false;
					this.loading = false;
					this.formdata_error[key] = this.no_error;
					this.ongoing[key].remove();
					$('[name='+ key +']')[0].classList.remove('not-active');

					this.formdata = data;
					this.formdata['targetCertDate'] = moment(this.formdata['targetCertDate']).format("MMM DD, YYYY");
					if(this.formdata['targetCertDate'] == 'Invalid date')
					{
						this.formdata['targetCertDate'] = '';
					}
					this.appData.set('buildingData', data);

					this.states = this.global.getStateList(this.appData.get('buildingData').manageEntityCountry);
				}
				,
				error =>
				{
					this.formdata[key] = this.appData.get('buildingData')[key];
					this.loading = false;
					this.loading = false;
					this.formdata_error[key] = this.no_error;
					this.ongoing[key].remove();
					$('[name='+ key +']')[0].classList.remove('not-active');

					if("error" in error.data)
					{
						this.alertService.alert('error', error.data.error, 10);
					}
					else if (error.data.non_field_errors == undefined){
						this.alertService.alert('error', 'Something went wrong. Please try again.', 10);
					}
					else{
						var error_text = error.data.non_field_errors[0].result;
						if (error_text.includes("details are blocked")){
							$('.ofac_invalid_modal').modal('show');
						}
					}
				}
			);
		}
	};

	getTransitOccupancy(): any
	{
		var annual_ridership = parseInt(this.formdata.annual_ridership);
		var operating_hours = parseInt(this.formdata.operating_hours);
		var full_time_staff = parseInt(this.formdata.full_time_staff);
		var time_spent_by_riders = parseInt(this.formdata.time_spent_by_riders);
		var occupancy: any;
		if(annual_ridership && operating_hours && full_time_staff && time_spent_by_riders)
		{
			var daily_avg_ridership = annual_ridership/365;
			var time_spent_by_riders_in_hours = time_spent_by_riders/60;
			var daily_operating_hours = operating_hours/7;

			occupancy = full_time_staff + Math.round(daily_avg_ridership * (time_spent_by_riders_in_hours/daily_operating_hours));

			if(isNaN(occupancy) || occupancy == Infinity)
			{
				$('input[name=occupancy]').removeClass('occ_valid');
				this.formdata.occupancy =  "";
			}
			else
			{
				$('input[name=occupancy]').addClass('occ_valid');
				this.formdata.occupancy =  occupancy;
			}
		}
		else
		{
			$('input[name=occupancy]').removeClass('occ_valid');
			this.formdata.occupancy = "";
		}
		return occupancy;
	};

	verifyField(data: any, key: string, save?: boolean)
	{
		if(this.regex[key] == undefined || key == 'state')
		{
			return;
		}

		if($('input[name=' + key)[0] != undefined && (<any>$('input[name=' + key))[0].getAttribute('class').indexOf('date_field') != -1)
		{
			var max = $('input[name=' + key)[0].getAttribute('max');
			if(max == 'yyyy-mm-dd')
			{
				data = moment(data).format("MMM DD, YYYY");
			}
		}

		if(save == undefined)
		{
			save = false;
		}
		
		if(data == undefined || data == null || data.length == 0)
		{
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		if(key == 'gross_area')
		{
			let max = this.global.getMax(this.formdata.unitType, this.formdata.project_type);
			if(parseFloat(data) > parseFloat(max))
			{
				this.formdata_error[key] = {
					'error': true,
					'help': 'Exceeded maximum value'
				};
			}
		}
		if(key == 'time_spent_by_riders')
		{
			let max = '1440';
			if(parseFloat(data) > parseFloat(max))
			{
				this.formdata_error[key] = {
					'error': true,
					'help': 'Exceeded maximum value'
				};
			}
		}

		if(!this.formdata_error[key].error && save)
		{
			this.submitDetails(data, key, false);
		}
	};

	resetOrganizationDetails()
	{
		$('.ofac_invalid_modal').modal('hide');
	};

	initTags()
	{
		this.api.async([
			'/assets/LEED:' + this.leed_id + '/tags/',
			'/user/tags/'
		]).subscribe(
			results =>
			{
				this.actions = results[0];

				var tag_input: any = document.querySelector('input[name=tags]');
				var tagify = new Tagify(tag_input, {
					editTags: false,
					placeholder: 'Type something',
					transformTag : transformTag,
					whitelist: results[1],
					dropdown : {
						enabled       : 0,
						maxItems      : 10,
						position      : "all",
						closeOnSelect : false,
						highlightFirst: true
					}
				});

				tagify.addTags(results[0]);

				// Chainable event listeners
				tagify
				.on('add', (e: any)=>
				{
					tagify.loading(true);
					var data = {
						"value": e.detail.data.value
					};
					this.api.post('/assets/LEED:' + this.leed_id + '/tags/', data).subscribe(
						//success handler
						response =>{
							tagify.loading(false);
						},
						//error handler
						response =>{
							this.error_in_tags = true;
							this.error_in_tags_text = response.data[0];
							tagify.loading(false);
							tagify.removeTags(e.detail.data.value);
						}
					);
				})
				.on('remove', (e: any)=>
				{
					tagify.loading(true);
					let payload = {"value": e.detail.data.value};
					this.api.delete('/assets/LEED:' + this.leed_id + '/tags/', payload).subscribe(
						//success handler
						response =>{
							tagify.loading(false);
						},
						//error handler
						response =>{
							tagify.loading(false);
						}
					);
				})
				.on('focus', (e: any)=>
				{
					this.error_in_tags = false;
					this.error_in_tags_text = '';
				})
				.on('blur', (e: any) =>
				{
					this.error_in_tags = false;
					this.error_in_tags_text = '';
				});

				function transformTag( tagData: any )
				{
					tagData.value = tagData.value.toLowerCase();
				};
			},
			errors =>
			{
				this.alertService.alert('error', 'Error while fetching project tags. Please try again.', 5);
			}
		);
	};

}

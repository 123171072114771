<div class="content-wrap">
	<div id="content" class="content">

        <div class="align_lr">
            <div>
                <h3 class="black-color pl30 pt5" translate>Advanced Scoring</h3>
                <div class="flex">
                    <span class="fs12 cursor-pointer pl30 pr3" (click)="global.showHelpText('total_leed_arc')">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 12C3.5 7.032 7.532 3 12.5 3C17.468 3 21.5 7.032 21.5 12C21.5 16.968 17.468 21 12.5 21C7.532 21 3.5 16.968 3.5 12ZM13.4 17.3996H11.6V15.5996H13.4V17.3996ZM12.5 19.2008C8.53105 19.2008 5.30005 15.9698 5.30005 12.0008C5.30005 8.03178 8.53105 4.80078 12.5 4.80078C16.469 4.80078 19.7 8.03178 19.7 12.0008C19.7 15.9698 16.469 19.2008 12.5 19.2008ZM8.90002 10.1996C8.90002 8.21061 10.511 6.59961 12.5 6.59961C14.489 6.59961 16.1 8.21061 16.1 10.1996C16.1 11.3542 15.389 11.9756 14.6967 12.5806C14.0399 13.1545 13.4 13.7138 13.4 14.6996H11.6C11.6 13.0605 12.4479 12.4105 13.1934 11.8391C13.7782 11.3909 14.3 10.9909 14.3 10.1996C14.3 9.20961 13.49 8.39961 12.5 8.39961C11.51 8.39961 10.7 9.20961 10.7 10.1996H8.90002Z" fill="#68737D"/>
                        </svg>
                    </span>
                    <span class="as_label" translate>How scores and metrics work</span>
                </div>
            </div>
            <div class="mt20 pt5 mr40 flex relative-position" *ngIf="user_role == 'arc_pro'">
                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs14 h40 br3p" (click)="editProgramForMultipleProjects()" translate>Edit Programs for Multiple Projects</button>
            </div>
        </div>
        <div class="row" id="advanced_scoring_container">
            <app-loader *ngIf="scorecard_loading || loading || recomputing_analysis"></app-loader>
            <section class="widget advanced_scoring" [ngClass]="scorecard_loading || loading || recomputing_analysis ? 'not-active' : ''">
                <div>
                    <div class="row">
                        <ul class="meterNav mb25 mt15">
                            <li (click)="toggleTab('Dashboard')" class="meterNav--item">
                                <span [ngClass]="current_tab == 'Dashboard' ? 'meterNav--item__active' : ''" translate>Dashboard</span>
                            </li>
                            <li (click)="toggleTab('Reports')" class="meterNav--item">
                                <span [ngClass]="current_tab == 'Reports' ? 'meterNav--item__active' : ''" translate>Reports</span>
                            </li>
                        </ul>
                    </div>
                    <div class="row pl0 pt15" [hidden]="!(current_tab == 'Dashboard' && user_role != 'arc_pro')">
                        <div class="col-md-8 pl0">
                            <div class="row">
                                <div class="col-md-10 pl0 pr0 border_lrb pb30 mb30 bg-white">
                                    <div class="model_banner_img col-md-12"></div>
                                    <div class="col-md-12 fs18 fw-500 pt25 pb10">Upgrade to access Advanced Scoring</div>
                                    <div class="col-md-12 pt20 pb20">
                                        <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br3p mr10" (click)="global.filterAccess('app.arc_pro_purchase')">Purchase Subscription</button>
                                        <button (click)="global.learnMore('arc_pro')" class="btn btn-primary inverse fs16 h40 br3p">Learn more</button>
                                    </div>
                                    <div class="col-md-12 mt10 mb20">
                                        <div class="models_divider"></div>
                                    </div>
                                    <div class="col-md-12 fs16 pb10 pl25 pr25">Advanced Scoring tools compare measured performance to benchmarks and Building Performance Standards.</div>
                                    <div class="col-md-12 fs16 pb10 pl25 pr25">Users can evaluate compliance with a growing library of global, national, and local standards.</div>
                                    <div class="col-md-12 fs16 pb10 pl25 pr25">Users can generate a summary scorecard or report.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overview_widget" class="widget_container" [hidden]="!(current_tab == 'Dashboard' && user_role == 'arc_pro')">
                        <div class="align_lr">
                            <div class="fw500 fs18 lh24 flex pt15" translate>Overview
                                <i class="advanced_scoring_learn_more_sc cursor-pointer relative-position" (click)="global.openHelpSidebar('AS_disclaimer')">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                </i>
                            </div>
                            <div class="">
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs14 h40 br3p mb20" (click)="openOverviewSelection()" translate>Edit overview selection</button>
                            </div>
                        </div>
                        <div class="align_lr bg-white">
                            <div class="fs18 ml15 mt15 mb15">{{top_scorecard.name|translate}}</div>
                        </div>
                        <div class="col-md-12 pl0 pr0">
                            <div class="advancedScoringWidgets--wrapper row ml0 mr0 mb32">
                                <div class="col-md-12 bg-white cursor-default score_version_modal mb0 p0">
                                    <iframe id="AS_overview_widget" frameBorder="0" width="100%" height="400px" scrolling="no" class="box-shadow-none mb0 overflow-hidden"></iframe>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pl0 pr0 widget_container align_lr">
                            <div class="fw500 fs18 lh24 flex pt15" translate>Scorecard</div>
                            <div>
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs14 h40 br3p mb20 mr20" (click)="showPreferencesModal(true)" translate>Edit Programs for this Project</button>
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs14 h40 br3p mb20" (click)="showCustomTargetModal()" translate>Create a Custom Target</button>
                            </div>
                        </div>
                        <div class="col-md-12 pl0 pr0">
                            <div class="bg-white advanced_scoring_overview pt10 row">
                                <div class="col-md-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="unselectable cursor-pointer" (click)="sortBy('name')">
                                                    <span translate>Program name</span>
                                                    <span [hidden]="!(propertyName == 'name')" class="pl3">
                                                        <span [hidden]="!reverse" class="fa fa-caret-down"></span>
                                                        <span [hidden]="reverse" class="fa fa-caret-up"></span>
                                                    </span>
                                                </th>
                                                <th class="unselectable cursor-pointer" (click)="sortBy('geographic_scope')">
                                                    <span translate>Geographic area</span>
                                                    <span [hidden]="!(propertyName == 'geographic_scope')" class="pl3">
                                                        <span [hidden]="!reverse" class="fa fa-caret-down"></span>
                                                        <span [hidden]="reverse" class="fa fa-caret-up"></span>
                                                    </span>
                                                </th>
                                                <th class="cursor-init" translate>Performance end date</th>
                                                <th class="cursor-init" translate>Result</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let program of AS_program_data" [hidden]="!program.scorecard_selected" (click)="showContentModal($event, program)" attr.name="{{program.name}}" attr.program_id="{{program.id}}" attr.id_name="{{program.id_name}}" id={{program.id_name}}>
                                                <td>{{program.name}}
                                                    <i class="advanced_scoring_learn_more_sc" *ngIf="program.learn_more_link">
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                                    </i>
                                                </td>
                                                <td>{{program.geographic_scope}}</td>
                                                <td>{{foramtPerformanceDate(program.end_date)}}</td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'arc_performance_score'">
                                                    <img *ngIf="formatvalue(energy_performance_score) != 'NA*'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                    <img *ngIf="formatvalue(energy_performance_score) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                    {{formatvalue(energy_performance_score)}}
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'arc_improvement_score'">
                                                    <img *ngIf="formatvalue(energy_improvement_score) != 'NA*'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                    <img *ngIf="formatvalue(energy_improvement_score) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                    {{formatvalue(energy_improvement_score)}}
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'arc_peer_score'">
                                                    <img *ngIf="formatvalue(peer_score) != 'NA*'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                    <img *ngIf="formatvalue(peer_score) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                    {{formatvalue(peer_score)}}
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'energy_star'">
                                                    <div [hidden]="!espm_score_recomputing" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="espm_score_recomputing">
                                                        <img *ngIf="formatvalue(espm_score) != 'NA*'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(espm_score) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{formatvalue(espm_score)}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'gresb'">
                                                    <div [hidden]="!program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="program.result_loader">
                                                        <img *ngIf="formatvalue(gresb_data_result) == 'Higher intensity than average'" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(gresb_data_result) == 'Lower intensity than average' || formatvalue(gresb_data_result) == 'Performing average'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(gresb_data_result) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{formatvalue(gresb_data_result)}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'gresb_2021'">
                                                    <div [hidden]="!program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="program.result_loader">
                                                        <img *ngIf="formatvalue(gresb_2021_data_result) == 'Fail'" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(gresb_2021_data_result) == 'Pass'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(gresb_2021_data_result) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{formatvalue(gresb_2021_data_result)}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'seattle_washington'">
                                                    <div [hidden]="!espm_score_recomputing" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="espm_score_recomputing">
                                                        <img *ngIf="formatvalue(seattle_data_result) == 'No'" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(seattle_data_result) == 'Yes'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(seattle_data_result) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{formatvalue(seattle_data_result)}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'city_of_orlando'">
                                                    <div [hidden]="!espm_score_recomputing" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="espm_score_recomputing">
                                                        <img *ngIf="formatvalue(city_of_orlando_data_espm_result) == 'No'" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(city_of_orlando_data_espm_result) == 'Yes'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="formatvalue(city_of_orlando_data_espm_result) == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{formatvalue(city_of_orlando_data_espm_result)}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'gsa' || program.id_name == 'colorado_bps' || program.id_name == 'energize_denver'">
                                                    <div [hidden]="!program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="program.result_loader">
                                                        <img [src]="getIconForResult(program.summary_result)" class="w20px mtn3">
                                                        {{program.summary_result}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="program.id_name == 'st_louis_missouri' || program.id_name == 'washington_state'">
                                                    <div [hidden]="!program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="program.result_loader">
                                                        <img *ngIf="program.summary_result == 'Fail'" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="program.summary_result == 'Pass'" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="program.summary_result == 'NA*'" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        {{program.summary_result}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="new_approach_program.indexOf(program.id_name) > -1">
                                                    <div [hidden]="!program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                    <div [hidden]="program.result_loader">
                                                        <img *ngIf="program.summary_result == 'NA*' || program.summary_result.indexOf('Not applicable') > -1" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="program.summary_result.indexOf('Higher performance') > -1 || program.summary_result.indexOf('Lower intensity') > -1 || program.summary_result.indexOf('Higher than mean') > -1" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="program.summary_result.indexOf('Some') > -1" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                        <img *ngIf="program.summary_result.indexOf('Lower performance') > -1 || program.summary_result.indexOf('Higher intensity') > -1 || program.summary_result.indexOf('Lower than mean') > -1" src="assets/images/notdone_x_icon.svg" class="w20px mtn3">
                                                        {{program.summary_result}}
                                                    </div>
                                                </td>
                                                <td class="fw-600 pr20" *ngIf="(hard_coded_programs.indexOf(program.id_name) == -1) && program.id_name != 'gsa' && program.id_name != 'colorado_bps' && program.id_name != 'energize_denver' && program.id_name != 'st_louis_missouri' && program.id_name != 'washington_state' && new_approach_program.indexOf(program.id_name) == -1">
                                                    <div *ngIf="program.kpi_used_for_result == 'source_energy_use_intensity_espm_score'">
                                                        <div [hidden]="!espm_score_recomputing || program.result_loader" class="dataInputLoaderSpinner dataInputLoaderSpinner-md">
                                                        </div>
                                                        <div [hidden]="espm_score_recomputing || program.result_loader">
                                                            <img *ngIf="program.summary_result == 'NA*' || program.summary_result.indexOf('Pass') < 0" src="assets/images/alert_circle_icon.svg" class="w20px mtn3">
                                                            <img *ngIf="program.summary_result.indexOf('Pass') > -1" src="assets/images/done_green_icon.svg" class="w20px mtn3">
                                                            {{program.summary_result}}
                                                        </div>
                                                    </div>
                                                    <div *ngIf="program.kpi_used_for_result != 'source_energy_use_intensity_espm_score'">
                                                        <img [src]="getIconForResult(program.summary_result)" class="w20px mtn3">
                                                        {{program.summary_result}}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12 pb20" *ngIf="show_required_text">
                                    <div class="gray_color_68737D ml8">* 
                                        <span translate>To generate a result, populate data inputs and complete the required drop-down selections within the selected standard.</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="current_tab == 'Reports'">
                        <div *ngIf="user_role == 'arc_pro'" class="bg-white w100p flex bs-gray-top-0">
                            <div class="w70p">
                                <div class="pt24 pr32 pb24 pl32 mb25">
                                    <div class="fs16 line_height_24 mb16">
                                        Create a downloadable report for this project
                                    </div>
                                    <button (click)="createReportModal()" [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary inverse fs15 fw-500 mb40">
                                        Create a report
                                    </button>
                                    <div id="report_settings">
                                        <div class="fw-500 fs18 line_height_32">Report title page images</div>
                                        <div class="line_height_24 gray_color_68737D mb24">Upload company logo for inclusion on your report title pages.</div>
                        
                                        <div [ngClass]="config.access == 'R' ? 'not-active' : ''" class="flex mb16">
                                            <input accept="image/*" #elem (input)="fileNameChanged(elem)" class="hide upload_report_title" type="file">
                                            <div class="title_images">
                                                <div *ngIf="icons.first == undefined" (click)="selectImage('first')" class="placeholder">
                                                    <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                                </div>
                                                <div class="icon_wrapper" *ngIf="icons.first != undefined">
                                                    <svg (click)="deleteIcon(icons.first, 'first')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                                    <img src="{{getPath(icons.first)}}">
                                                </div>
                                            </div>
                                            <div class="title_images">
                                                <div *ngIf="icons.second == undefined" (click)="selectImage('second')" class="placeholder">
                                                    <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                                </div>
                                                <div class="icon_wrapper" *ngIf="icons.second != undefined">
                                                    <svg (click)="deleteIcon(icons.second, 'second')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                                    <img src="{{getPath(icons.second)}}">
                                                </div>
                                            </div>
                                            <div class="title_images">
                                                <div *ngIf="icons.third == undefined" (click)="selectImage('third')" class="placeholder">
                                                    <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                                </div>
                                                <div class="icon_wrapper" *ngIf="icons.third != undefined">
                                                    <svg (click)="deleteIcon(icons.third, 'third')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                                    <img src="{{getPath(icons.third)}}">
                                                </div>
                                            </div>
                                            <div class="title_images">
                                                <div *ngIf="icons.fourth == undefined" (click)="selectImage('fourth')" class="placeholder">
                                                    <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                                </div>
                                                <div class="icon_wrapper" *ngIf="icons.fourth != undefined">
                                                    <svg (click)="deleteIcon(icons.fourth, 'fourth')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                                    <img src="{{getPath(icons.fourth)}}">
                                                </div>
                                            </div>
                                            <div class="title_images">
                                                <div *ngIf="icons.fifth == undefined" (click)="selectImage('fifth')" class="placeholder">
                                                    <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                                </div>
                                                <div class="icon_wrapper" *ngIf="icons.fifth != undefined">
                                                    <svg (click)="deleteIcon(icons.fifth, 'fifth')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                                    <img src="{{getPath(icons.fifth)}}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="line_height_24 gray_color_68737D">Drag photos to the boxes above or chose files from your computer.<br> Images should be no larger than 5MB</div>
                                    </div>
                                </div>
                            </div>
                            <div class="w25p ml20 pt24 pr25 pb24 pl25 mb15 mt24 bs-gray h300p" *ngIf="appData.get('is_gsa_user') && user_role == 'arc_pro' && appData.get('space_type_gsa').indexOf(appData.get('buildingData').spaceType) > -1">
                                <div class="ptz-badge-container bg-white align-center fs16 gg20">
                                    <div class="ptz-badge-logo"></div>
                                    <div class="bold mt20 fs15">Play to Zero is support by members of the Solutions Team.</div>
                                    <button onclick="location.href='mailto:analytics@greensportsalliance.org';" class="btn btn-primary fs16 h40 br3p mr10 mt25">Contact Us</button>
                                </div>
                            </div>
                        </div>
                        <div class="widget_mimic">
                            <table class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th>Report</th>
                                        <th>Created by</th>
                                        <th>Date Created</th>
                                        <th>View Report</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let report of reports">
                                        <td class="vertical-align-middle">
                                            {{global.getFileName(report)}}
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{report.uploaded_by}}
                                        </td>
                                        <td class="vertical-align-middle">
                                            {{global.dateTimeFormat(report.uploaded_at)}}
                                        </td>
                                        <td class="">
                                            <a (click)="downloadCert(report)">
                                                Download
                                            </a>
                                        </td>
                                        <td [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                            <div title="Delete Report" class="delete_report tippy_init" (click)="deleteReport(report)"></div>
                                        </td>
                                    </tr>
                                    <!-- <tr title="{{data.message}}" class="tippy_init cursor-pointer" on-last-repeat="ngRepeatFinish" *ngFor="let data of feeds"> -->
                                    <tr title="{{data['message']}}" class="tippy_init cursor-pointer" *ngFor="let data of feeds">
                                        <td class="vertical-align-middle">
                                            <span>Advanced Scoring Report</span>
                                        </td>
                                        <td class="vertical-align-middle">
                                            <span>{{data['username']}}</span>
                                        </td>
                                        <td  class="vertical-align-middle">
                                            In Progress
                                        </td>
                                        <td *ngIf="data['document'] == null" class="vertical-align-middle">
                                            N/A
                                        </td>
                                        <td *ngIf="data['document'] != null" class="vertical-align-middle">
                                            N/A
                                        </td>
                                    </tr>	
                                </tbody>
                                <tbody *ngIf="reports.length == 0 && feeds.length == 0">
                                    <tr>
                                        <td colspan="5" class="noProjects">
                                            No report created.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </section>
        </div>
        
        <div class="modal fade score_version_modal overflow-auto" id="advanced_scoring_preferences" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <app-loader *ngIf="preferences_loading"></app-loader>
            <div class="modal-dialog modal-lg">
                <div class="modal-content modal_radius removeOpacity" [ngClass]="preferences_loading ? 'not-active' : ''">
                    <div class="modal-header score_version_modal_header">
                        <h5 class="modal-title fs20 fw-600" translate>Select Performance Standard</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="refreshTopScorecard()">
                        </button>
                    </div>
                    <div class="modal-body score_version_modal_body">
                        <div class="mb5 relative-position vertical_center_flex">
                            <button class="show_filters btn btn-default btn-md gray_button btn-border vertical_center_flex">
                                <span class="vertical_center_flex">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z" fill="#68737D"></path></svg>
                                </span>
                                <span class="pl5" translate>Show Filters</span>
                                <span *ngIf="total_AS_filters > 0" class="blue-color fs12 pl5" translate>({{total_AS_filters}})</span>
                            </button>
                             <div id="AS_program_filters" class="display_none col-md-10" style="right: 0px;">
                                <div class="project_filter_header">
                                    <div class="fw-500" translate>Filter by</div>
                                    <div>
                                        <a (click)="resetSearchFilters()" translate>Clear all filters</a>
                                    </div>
                                </div>
                                <div class="row mt10">
                                    <div class="col-md-4 pl0">
                                        <div class="fs16 fw-600 pb15" translate>Select by name</div>
                                        <div class="">
                                            <input type="text" [(ngModel)]="search_name" class="form-control mr10">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="fs16 fw-600 pb15" translate>Select by location</div>
                                        <div *ngFor="let option of search_option.location" class="flex line_height_22">
                                            <input name="{{option}}" type="checkbox" class="search_checkbox_location cursor-pointer mr10">
                                            <label for="checkbox">{{option|translate}}</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">  
                                        <div class="fs16 fw-600 pb15" translate>Select by metric</div>
                                        <div *ngFor="let option of search_option.metric" class="flex line_height_22">
                                            <input name="{{option}}" type="checkbox" class="search_checkbox_metric cursor-pointer mr10">
                                            <label for="checkbox">{{option|translate}}</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row mb10">Learn more about <a href="">Benchmarks and Performance Standards</a>.</div> -->
                                <div class="project_filter_item border_bottom_0">
                                    <div class="mr25">
                                        <button (click)="searchProgram()" class="btn btn-primary btn-md" translate>
                                            Apply
                                        </button>
                                    </div>
                                    <div>
                                        <button class="btn btn-default btn-md inverse close_filter" translate>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
        
                            <div class="position-absolute r0">
                                <button class="btn btn-default btn-md gray_button btn-border align-left" type="button" data-toggle="dropdown" [ngClass]="ct_error_target_type ? 'error-border' : ''">
                                    <span [innerHTML]="preference_sort_by == '' ? 'Sort by' : preference_sort_by" translate></span>
                                    <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                                </button>
                                <ul class="dropdown-menu w100p">
                                    <li (click)="updatePreferenceSort('Alphabetical')">
                                        <a translate>Alphabetical</a>
                                    </li>
                                    <li (click)="updatePreferenceSort('Geographical')">
                                        <a translate>Geographical</a>
                                    </li>
                                    <li (click)="updatePreferenceSort('Metric')">
                                        <a translate>Metric</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row gray_border mt20">
                            <table class="table w100p">
                                <thead>
                                    <tr>
                                        <th class="fs20 capitalize" translate>Custom Target</th>
                                        <th></th>
                                        <th class="w100px"></th>
                                    </tr>
                                </thead>
                                <tbody [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                    <tr *ngFor="let program of AS_program_data_for_search" [hidden]="program.program_type != 'custom'">
                                        <td>
                                            <span class="pr10 top4 relative-position">
                                                <input [(ngModel)]="program.scorecard_selected" (click)="updateSelection(program, $event)" type="checkbox" attr.program_id="{{program.id}}" [disabled]="(program.id_name == 'arc_performance_score' || program.id_name == 'arc_improvement_score') && program.scorecard_selected" class="cursor-pointer" attr.id_name="{{program.id_name}}">
                                            </span>
                                            <span class="top2 relative-position" translate>
                                                {{program.name}}
                                            </span>
                                            <span>
                                                <i class="advanced_scoring_learn_more_sc cursor-pointer" *ngIf="program.learn_more_link" (click)="openLearnMoreLink(program.id_name)">
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                                </i>
                                            </span>
                                            
                                        </td>
                                        <td></td>
                                        <td>
                                            <span class="cursor-pointer" (click)="showEditCustomTarget(program.id, true)" *ngIf="program.can_edit">
                                                <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.728 9.68608L14.314 8.27208L5 17.5861V19.0001H6.414L15.728 9.68608ZM17.142 8.27208L18.556 6.85808L17.142 5.44408L15.728 6.85808L17.142 8.27208ZM7.242 21.0001H3V16.7571L16.435 3.32208C16.6225 3.13461 16.8768 3.0293 17.142 3.0293C17.4072 3.0293 17.6615 3.13461 17.849 3.32208L20.678 6.15108C20.8655 6.33861 20.9708 6.59292 20.9708 6.85808C20.9708 7.12325 20.8655 7.37756 20.678 7.56508L7.243 21.0001H7.242Z" fill="#68737D"/> </svg> 
                                            </span>
                                            <span class="cursor-pointer pl10" (click)="showDeleteCTModal(program)" *ngIf="program.can_edit">
                                                <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.989258V2.0185H12V3.99277H0V2.0185H3V0.989258H9ZM0 14.7624C0 15.9912 1 16.9893 2.23145 16.9893H9.76562C10.9971 16.9893 11.9971 15.9912 11.9971 14.7624V5.01265H0V14.7624ZM2 7.00875H10V14.2509C10 14.6594 9.66602 14.9932 9.25586 14.9932H2.74414C2.33398 14.9932 2 14.6594 2 14.2509V7.00875ZM7 7.97562H9V13.9639H7V7.97562ZM5 7.97562H3V13.9639H5V7.97562Z" fill="#6B6C7E"/></svg>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="pl15 pr15">
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs14 h40 br3p mb20 w100p" (click)="showCustomTargetModal()" translate>Add Custom Target</button>
                            </div>
                        </div>
        
                        <div class="row search_result gray_border mt20" [hidden]="!(preference_sort_by == '' || preference_sort_by == 'Alphabetical')">
                            <app-loader *ngIf="searchng_program"></app-loader>
                            <table class="table advanced_scoring_overview_container w100p">
                                <thead>
                                    <tr>
                                        <th class="fs20 capitalize" translate>All Programs</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                    <tr *ngFor="let program of AS_program_data_for_search" [hidden]="program.program_type == 'custom'">
                                        <td>
                                            <span class="pr10 top4 relative-position">
                                                <input [(ngModel)]="program.scorecard_selected" (click)="updateSelection(program, $event)" type="checkbox" attr.program_id="{{program.id}}" [disabled]="(program.id_name == 'arc_performance_score' || program.id_name == 'arc_improvement_score') && program.scorecard_selected" class="cursor-pointer" attr.id_name="{{program.id_name}}">
                                            </span>
                                            <span class="top2 relative-position">{{program.name}}</span>
                                            <span>
                                                <i class="advanced_scoring_learn_more_sc cursor-pointer" *ngIf="program.learn_more_link" (click)="openLearnMoreLink(program.id_name)">
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                                </i>
                                            </span>
                                        </td>
                                        <td>{{program.geographic_scope}}</td>
                                        <td>{{program.bps_category}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
        
                        <div class="row search_result gray_border mt20" *ngFor="let location of ['USA', 'United Kingdom', 'Global']" [hidden]="!(preference_sort_by == 'Geographical')">
                            <app-loader *ngIf="searchng_program"></app-loader>
                            <table class="table advanced_scoring_overview_container w100p">
                                <thead>
                                    <tr>
                                        <th class="fs20 capitalize">{{location}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                    <tr *ngFor="let program of AS_program_data_for_search" [hidden]="!ShowSortBy(preference_sort_by, location, program)">
                                        <td>
                                            <span class="pr10 top4 relative-position">
                                                <input [(ngModel)]="program.scorecard_selected" (click)="updateSelection(program, $event)" type="checkbox" attr.program_id="{{program.id}}" [disabled]="(program.id_name == 'arc_performance_score' || program.id_name == 'arc_improvement_score') && program.scorecard_selected" class="cursor-pointer" attr.id_name="{{program.id_name}}">
                                            </span>
                                            <span class="top2 relative-position">{{program.name}}</span>
                                            <span>
                                                <i class="advanced_scoring_learn_more_sc cursor-pointer" *ngIf="program.learn_more_link" (click)="openLearnMoreLink(program.id_name)">
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                                </i>
                                            </span>
                                        </td>
                                        <td>{{program.geographic_scope}}</td>
                                        <td>{{program.bps_category}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
        
                        <div class="row search_result gray_border mt20" *ngFor="let item of {espm: 'ENERGY STAR Score', ghg: 'GHG Intensity', site: 'Site EUI', source: 'Source EUI', waste: 'Waste Diversion Rate', water: 'Water Intensity'} | keyvalue" [hidden]="!(preference_sort_by == 'Metric')">
                            <app-loader *ngIf="searchng_program"></app-loader>
                            <table class="table advanced_scoring_overview_container w100p">
                                <thead>
                                    <tr>
                                        <th class="fs20 capitalize">{{item.value}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                    <tr *ngFor="let program of AS_program_data_for_search" [hidden]="!(program.program_type != 'custom' && program.metric.indexOf(item.key) > -1)">
                                        <td>
                                            <span class="pr10 top4 relative-position">
                                                <input [(ngModel)]="program.scorecard_selected" (click)="updateSelection(program, $event)" type="checkbox" attr.program_id="{{program.id}}" [disabled]="(program.id_name == 'arc_performance_score' || program.id_name == 'arc_improvement_score') && program.scorecard_selected" class="cursor-pointer" attr.id_name="{{program.id_name}}">
                                            </span>
                                            <span class="top2 relative-position">{{program.name}}</span>
                                            <span>
                                                <i class="advanced_scoring_learn_more_sc cursor-pointer" *ngIf="program.learn_more_link" (click)="openLearnMoreLink(program.id_name)">
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                                                </i>
                                            </span>
                                        </td>
                                        <td>{{program.geographic_scope}}</td>
                                        <td>{{program.bps_category}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
        
                        <div class="align-center">
                            <button class="btn btn-primary fs14 h40 br3p mb20 mt20" (click)="refreshTopScorecard()" data-dismiss="modal" aria-label="Close" translate>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade score_version_modal" id="advanced_scoring_overview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg diplay_grid">
                <app-loader *ngIf="overview_loading"></app-loader>
                <div class="modal-content modal_radius removeOpacity pb50" [ngClass]="overview_loading ? 'not-active' : ''">
                    <div class="modal-header score_version_modal_header vertical_center_flex border_bottom_0 mt30">
                        <div>
                            <h5 class="modal-title fs23 lh24 fw-500" translate>Select the one you would like to highlight</h5>
                            <h2 class="modal-title fs14 lh24 pt5" translate>Choose the program you would like to have pinned to the top of Advanced Scoring.</h2>
                        </div>
                        <div class="position-absolute r30">
                            <button class="btn btn-default fs14 h40" data-dismiss="modal" aria-label="Close" (click)="refreshTopScorecard()" translate>Cancel</button>
                            <button class="btn btn-primary fs14 h40 ml8" data-dismiss="modal" aria-label="Close" (click)="refreshTopScorecard()" translate>Continue</button>
                        </div>
                    </div>
                    <div class="fw-500 modal-body score_version_modal_body pl15 pr15">
                        <div [hidden]="!program.scorecard_selected" *ngFor="let program of AS_program_data" [ngClass]="config.access == 'R' ? 'not-active' : ''">
                            <div class="col-md-6">
                                <span class="checkcontainer mb10 mt10 as_radio_border" (click)="updateOverviewSelection($event, program)">
                                    {{program.name}}
                                    <i class="advanced_scoring_learn_more_sc mtn2" attr.id_name="{{program.id_name}}" *ngIf="program.learn_more_link"> <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg> </i>
                                    <input type="radio" attr.name="{{program.id_name}}" [value]="true" [(ngModel)]="program.selected_for_overview" attr.program_id="{{program.id}}">
                                    <span class="radiobtn top14 left13"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade advanced_scoring_widget_modal score_version_modal overflow-hidden" id="advanced_scoring_widget" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <app-loader *ngIf="widget_loading"></app-loader>
                    <div class="modal-header score_version_modal_header pr20 pl20" [ngClass]="custom_target_program ? 'pb10' : '' ">
                        <h5 class="modal-title fs24 fw-600 ">{{program_name}}<div *ngIf="last_opened_widget == 'gsa'" class="arc_gsa_logo t0"></div></h5>
                        <div *ngIf="custom_target_program" class="fs16 lh36"><span class="fw-600 pr5">Description:</span><span>{{program_description}}</span></div>
                        <div *ngIf="custom_target_program" class="position-absolute r40 top30">
                            <button class="btn btn-primary fs16 h40" (click)="showEditCustomTarget(advanced_modal_active_program_id, false)" [ngClass]="{'not-active' : config.access == 'R', 'pl20 pr20' : fetching_ct_data}">
                                <div>
                                    <div *ngIf="fetching_ct_data" class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                </div>
                                <span *ngIf="fetching_ct_data">Loading...</span>
                                <span *ngIf="!fetching_ct_data">Edit Custom Target</span>
                            </button>
                            <button class="btn btn-default fs16 h40 ml8" data-dismiss="modal" aria-label="Close" (click)="hideContentModal()">Close</button>
                        </div>
                        <button *ngIf="!custom_target_program" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideContentModal()"></button>
                    </div>
                    <div class="fw-500 modal-body p0 h100p w100p position-fixed">
                        <iframe id="advanced_scoring_widget_iframe" frameBorder="0" width="100%" height="100%"></iframe>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade score_version_modal overflow-auto" id="advanced_scoring_custom_target" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <app-loader *ngIf="custom_target_loading"></app-loader>
            <div class="modal-dialog modal-lg diplay_grid">
                <div class="modal-content modal_radius removeOpacity pb50">
                    <div class="modal-header score_version_modal_header">
                        <h5 class="modal-title fs24 fw-600 ">{{custom_target_header|translate}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openPreviousModal()"></button>
                    </div>
                    <div class="modal-body score_version_modal_body pl15 pr15 pt30" [ngClass]="custom_target_loading ? 'not-active' : ''">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Name</div>
                                <input type="text" [(ngModel)]="ct_create_target_name" maxlength="100" class="form-control lh18 fs15 h40" placeholder="{{'Name of target'|translate}}" (blur)="updateCTData('name', ct_create_target_name, null)" (change)="removeErroClass('name')" [ngClass]="ct_error_name ? 'error-border' : ''">
                                <div class="ct_error" *ngIf="ct_error_name" translate>This field is required.</div>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-6">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Performance Indicator</div>
                                <div class="relative-position">
                                    <button class="btn dropdown-toggle btn-default black-color h40 w100p align-left" type="button" data-toggle="dropdown" [ngClass]="ct_error_kpi ? 'error-border' : ''">
                                        <span [innerHTML]="ct_create_target_kpi == '' ? 'Select performance indicator' : ct_create_target_kpi" translate></span>
                                        <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                                    </button>
                                    <ul class="dropdown-menu w100p">
                                        <li (click)="updateCTData('kpi', 'Site EUI', null)">
                                            <a translate>Site EUI</a>
                                        </li>
                                        <li (click)="updateCTData('kpi', 'Source EUI', null)">
                                            <a translate>Source EUI</a>
                                        </li>
                                        <li (click)="updateCTData('kpi', 'GHG Intensity', null)">
                                            <a translate>GHG Intensity</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ct_error" *ngIf="ct_error_kpi" translate>This field is required.</div>
                            </div>
                            <div class="col-md-6">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Unit</div>
                                <div class="relative-position">
                                    <button class="btn dropdown-toggle btn-default black-color h40 w100p align-left" type="button" data-toggle="dropdown" [ngClass]="ct_error_unit ? 'error-border' : ''">
                                        <span [innerHTML]="ct_create_target_unit == '' ? 'Select unit' : ct_create_target_unit" translate></span>
                                        <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                                    </button>
                                    <ul class="dropdown-menu w100p">
                                        <li *ngFor="let ct_create_target_unit of ct_create_target_unit_options" (click)="updateCTData('unit', ct_create_target_unit, null)">
                                            <a>{{ct_create_target_unit}}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ct_error" *ngIf="ct_error_unit" translate>This field is required.</div>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-12">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Description</div>
                                <textarea [(ngModel)]="ct_create_target_description" maxlength="2000" rows="6" class="form-control resize_none lh18 fs15" placeholder="{{'Description'|translate}}" (blur)="updateCTData('description', ct_create_target_description, null)" (change)="removeErroClass('description')" [ngClass]="ct_error_description ? 'error-border' : ''"></textarea>
                                <div class="ct_error" *ngIf="ct_error_description" translate>This field is required.</div>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-4">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Target Type</div>
                                <div class="relative-position">
                                    <button class="btn dropdown-toggle btn-default black-color h40 w100p align-left" type="button" data-toggle="dropdown" [ngClass]="ct_error_target_type ? 'error-border' : ''">
                                        <span [innerHTML]="ct_create_target_type == '' ? 'Select target type' : ct_create_target_type" translate></span>
                                        <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                                    </button>
                                    <ul class="dropdown-menu w100p">
                                        <li (click)="updateCTData('target_type', 'Linear', null)">
                                            <a translate>Linear</a>
                                        </li>
                                        <li (click)="updateCTData('target_type', 'Stepped', null)">
                                            <a translate>Stepped</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ct_error" *ngIf="ct_error_target_type" translate>This field is required.</div>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-12">
                                <div class="lh32 fs18 fw-only-600 mb10" translate>Graph</div>
                                <div id="ct_graph" class="h250px width-100-perc"></div>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-12">
                                <table class="width-100-perc" id="custom_target_table">
                                    <thead class="border-bottom">
                                        <tr class="fs16 fw-only-600">
                                            <th class="w40p" translate>Year</th>
                                            <th class="w60p" translate>Target</th>
                                            <th class="w10p"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let target_data of ct_create_target_data">
                                            <td>
                                                <div class="dropdown display-inline w100p">
                                                    <button class="btn dropdown-toggle btn-default black-color h40 w100p align-left" type="button" data-toggle="dropdown">
                                                        <span>{{target_data.year == null ? 'Select year' : target_data.year}}</span>
                                                        <svg class="as_chevron_down" aria-hidden="true" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                                                    </button>
                                                    <ul class="dropdown-menu w100p custom_target_table_year_drp">
                                                        <li *ngFor="let year of ct_static_years" (click)="updateCTData('year', year, target_data)">
                                                            <a>{{year}}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <input float-input-decimal [(ngModel)]="target_data.target" class="form-control lh18 fs15 h40" (change)="refreshChart()" (blur)="updateCTData('value', target_data.target, target_data)" id=target_value_{{target_data.year}}>
                                            </td>
                                            <td>
                                                <div class="cursor-pointer" (click)="removeCTDataRow(target_data)">
                                                    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.989258V2.0185H12V3.99277H0V2.0185H3V0.989258H9ZM0 14.7624C0 15.9912 1 16.9893 2.23145 16.9893H9.76562C10.9971 16.9893 11.9971 15.9912 11.9971 14.7624V5.01265H0V14.7624ZM2 7.00875H10V14.2509C10 14.6594 9.66602 14.9932 9.25586 14.9932H2.74414C2.33398 14.9932 2 14.6594 2 14.2509V7.00875ZM7 7.97562H9V13.9639H7V7.97562ZM5 7.97562H3V13.9639H5V7.97562Z" fill="#6B6C7E"/></svg>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row pt30 pb30 pl5 border-bottom">
                            <div class="col-md-12">
                                <button class="btn btn-default fs16 h40 ml8" (click)="addCTDataRow()" [ngClass]="config.access == 'R' ? 'not-active' : ''" translate>Add A Row</button>
                            </div>
                        </div>
                        <div class="row pt20">
                            <div class="col-md-12" *ngIf="edit_CT_active">
                                <button class="btn btn-default fs16 h40 ml8" data-dismiss="modal" aria-label="Close" (click)="openPreviousModal()">Close</button>
                            </div>
                            <div class="col-md-12" *ngIf="!edit_CT_active">
                                <button [ngClass]="{'pl20 pr20' : custom_target_loading, 'not-active' : (config.access == 'R' || ct_error_name || ct_error_kpi || ct_error_unit || ct_error_description || ct_error_target_type)}" class="btn btn-primary fs16 h40" (click)="createCustomTarget()" translate>
                                    <div >
                                        <div *ngIf="custom_target_loading" class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                    </div>
                                    {{ct_btn_text}}
                                </button>
                                <button class="btn btn-default fs16 h40 ml8" data-dismiss="modal" aria-label="Close" translate>Cancel</button>
                            </div>
                            <div *ngIf="!edit_CT_active && (ct_error_name || ct_error_kpi || ct_error_unit || ct_error_description || ct_error_target_type)" class="ct_error pl15" translate>Please provide valid data</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="delete_ct_confirmation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog modal-md">
                <div class="modal-content p30 modal_radius">
                    <p class="danger-color fs16">Deleting this target will delete it from all projects. Please click 'Delete' to confirm.</p>
                    <div class="mt30 align-center" [ngClass]="deleting_ct_target ? 'not-active' : ''">
                        <button class="btn btn-primary fs16 h40" (click)="deleteCustomTarget()" [ngClass]="config.access == 'R' ? 'not-active' : ''">{{deleting_ct_target_btn_txt}}</button>
                        <button class="btn btn-default fs16 h40 ml8" data-dismiss="modal" aria-label="Close" (click)="showPreferencesModal(true)">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal #modal>
    <app-multiple-target-manage (close)="cancel()" *ngIf="modal.modal_open"></app-multiple-target-manage>
</modal>

<modal #report>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
        <app-loader *ngIf="loading"></app-loader>
        <div class="left-right-align mb8 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Create a report
            </div> 
        </div>
        <div class="line_height_32 fs18 fw-500 mb15">2. Select a 12-month range end date</div>
        <div class="relative-position mb30">
            <app-performance-date-picker [type]="'custom_cal'"></app-performance-date-picker>
        </div>
        <div class="line_height_32 fs18 fw-500 mb15">2. Select title page image:</div>
        <div class="left-right-align mb15">
            <div id="select_title">
                <div class="flex mb16">
                    <div class="title_images" [ngClass]="selected_icon == null ? 'active' : ''" (click)="selectImage(null, true)">
                        <div class="icon_wrapper">
                            <div class="arc_logo_white"></div>
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'gsa' ? 'active' : ''" (click)="selectImage('gsa', true)" *ngIf="appData.get('is_gsa_user') && config.config.settings.space_type_gsa.indexOf(appData.get('buildingData').spaceType) > -1">
                        <div class="icon_wrapper">
                            <div class="arc_gsa_logo_report gsa_icon_bg"></div>
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'first' ? 'active' : ''" (click)="selectImage('first', true)" *ngIf="icons.first != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.first)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'second' ? 'active' : ''" (click)="selectImage('second', true)" *ngIf="icons.second != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.second)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'third' ? 'active' : ''" (click)="selectImage('third', true)" *ngIf="icons.third != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.third)}}">
                        </div>
                    </div>
                    <br>
                </div>
                <div class="flex mb16">
                    <div class="title_images" [ngClass]="selected_icon == 'fourth' ? 'active' : ''" (click)="selectImage('fourth', true)" *ngIf="icons.fourth != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.fourth)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'fifth' ? 'active' : ''" (click)="selectImage('fifth', true)" *ngIf="icons.fifth != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.fifth)}}">
                        </div>
                    </div>
                </div>
            </div>
    
            <div>
                <div class="fs16 line_height_24 fw-500 mb8">Report preview:</div>
                <div id="report_preview">
                    <div [ngClass]="selected_icon != null ? 'white_bg' : ''" class="banner">
                        <div class="title_images center_align_format" *ngIf="selected_icon != null && selected_icon != 'gsa'">
                            <div class="icon_wrapper">
                                <img src="{{getPath(icons[selected_icon])}}">
                            </div>
                        </div>
                        <div class="title_images banner_format" *ngIf="selected_icon == 'gsa'">
                            <div class="icon_wrapper">
                                <!-- <div class="gsa_logo_banner logo_source"></div> -->
                                <img class="logo_source" src="../../../assets/images/play_to_zero.jpg">
                            </div>
                        </div>
                        <div class="title_images center_align_format" *ngIf="selected_icon == null">
                            <div class="icon_wrapper">
                                <div class="arc_logo_white"></div>
                            </div>
                        </div>
                    </div>
                    <div class="report_content">
                        <div class="thin w50p" style="margin-bottom: 9.31px;"></div>
                        <div class="fat w100p" style="margin-bottom: 3.49px;"></div>
                        <div class="fat w100p" style="margin-bottom: 9.31px;"></div>
                        <div class="thin w25p" style="margin-bottom: 4.65px;"></div>
                        <div class="thin w25p"></div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="line_height_32 fs18 fw-500 mb15">3. Select programs:</div>
        <div class="advanced_scoring_overview mb55" style="padding: 0px;box-shadow: none;">
            <table class="table">
                <thead>
                    <tr>
                        <th style="padding: 0px;"></th>
                        <th style="padding: 0px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let program of AS_program_data" [hidden]="program.scorecard_selected == false" id={{program.id_name}} (click)="toggleCheck(program)">
                        <td>
                            <div class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
                                <input [(ngModel)]="program.selected" name="{{program.id}}" type="checkbox">                
                                <label for="checkbox"></label>
                            </div>
                        </td>
                        <td>
                            {{program.name}}
                            <i class="advanced_scoring_learn_more_sc cursor-pointer" *ngIf="program.learn_more_link" (click)="openLearnMoreLink(program.id_name)">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_2078_30065)"> <path d="M12.5 22.8945C6.977 22.8945 2.5 18.4175 2.5 12.8945C2.5 7.37153 6.977 2.89453 12.5 2.89453C18.023 2.89453 22.5 7.37153 22.5 12.8945C22.5 18.4175 18.023 22.8945 12.5 22.8945ZM11.5 11.8945V17.8945H13.5V11.8945H11.5ZM11.5 7.89453V9.89453H13.5V7.89453H11.5Z" fill="#BAC1CA"/> </g> <defs> <clipPath id="clip0_2078_30065"> <rect width="24" height="24" fill="white" transform="translate(0.5 0.894531)"/> </clipPath> </defs> </svg>
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="border-top pt16">
            <button (click)="createReport()" data-dismiss="modal" type="button" class="btn btn-primary btn-md mr25">Create</button>
            <button (click)="cancelReport()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
        </div>
    </div>
</modal>
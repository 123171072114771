import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { InsightService } from 'src/app/services/insight.service';
import { Projects } from '../../services/projects.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css']
})
export class ModelsComponent implements OnInit
{
	config = Config;

	selected_tab: string = 'energy';
	parent_tab: string = 'Models';
	sub_header_text: string = '';

	backdrop: boolean = false;

	constructor(
		public global: Global,
		public appData: AppData,
		private insight: InsightService,
		private project: Projects,
	)
	{
		
	}

	ngOnInit(): void
	{
		this.appData.set('insight_app', null);
		this.project.getAssetDetails(this.appData.get('leed_id')).subscribe(
			data =>
			{
				this.appData.set('buildingData', data);
				this.getSubHeaderText(this.selected_tab);
			}
		);
	}

	changeTab(tab: string)
	{
		window.scrollTo(0, 0);
		this.selected_tab = tab;
		this.getSubHeaderText(tab);
	};

	getSubHeaderText(tab: string)
	{
		this.sub_header_text = '';
		if (tab == 'energy' && (this.appData.get('buildingData').energy_score_calculation_factor == 'espm_ashrae' || this.appData.get('buildingData').energy_score_calculation_factor == 'ashrae')){
			this.sub_header_text = 'The project is using the ENERGY STAR and ASHRAE Standard 100 ACP';
		}
		else if (tab == 'water' && this.appData.get('buildingData').water_score_calculation_factor == 'percentage_reduction'){
			this.sub_header_text = 'The project is using the Calculated Indoor Water Use ACP';
		}
		return this.sub_header_text;
	};

	changeParentTab(tab: string)
	{
		if(tab == 'Insight')
		{
			this.insight.openInsights();
		}
		else
		{
			this.appData.set('insight_app', null);
		}
		window.scrollTo(0, 0);
	};

	createModel()
	{
		this.appData.set("current_step", "Model details");
		this.global.goToApp('create-model')
	}

	insightSurvey()
	{
		$('.feedback_modal').modal('show');
	}

	filterAccessInsights(app: string)
	{
		this.appData.set('insight_app', app);
	}
}

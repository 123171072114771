import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

@Component({
  selector: 'app-insight-comparison',
  templateUrl: './insight-comparison.component.html',
  styleUrls: ['./insight-comparison.component.css']
})
export class InsightComparisonComponent implements OnInit
{
	project: any = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');
	loading: boolean = false;
	comparison: any = {
		"project_1": 
		{
			"LT": null,
			"SS": null,
			"WE": null,
			"EA": null,
			"MR": null,
			"EQ": null,
			"IN": null,
			"RP": null,
			"IP": null,
		},
		"project_2": 
		{
			"LT": null,
			"SS": null,
			"WE": null,
			"EA": null,
			"MR": null,
			"EQ": null,
			"IN": null,
			"RP": null,
			"IP": null,
		},
		"project_3": 
		{
			"LT": null,
			"SS": null,
			"WE": null,
			"EA": null,
			"MR": null,
			"EQ": null,
			"IN": null,
			"RP": null,
			"IP": null,
		},
	};

	assets: any = [];
	scope: any = {
		"project_1": {},
		"project_1_object": {},
		"project_1_model_versions": [],
		"project_1_model": {},
		"project_1_energy_estimate": null,
		"project_1_water_estimate": null,
		"project_1_waste_estimate": null,

		"project_2": {},
		"project_2_object": {},
		"project_2_model_versions": [],
		"project_2_model": {},
		"project_2_energy_estimate": null,
		"project_2_water_estimate": null,
		"project_2_waste_estimate": null,

		"project_3": {},
		"project_3_object": {},
		"project_3_model_versions": [],
		"project_3_model": {},
		"project_3_energy_estimate": null,
		"project_3_water_estimate": null,
		"project_3_waste_estimate": null

	};

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{ 

	}

	ngOnInit(): void 
	{
		this.loading = true;
		this.insight.getAssets(1, 0).subscribe(
			data =>
            {
				this.loading = false;
                this.assets = data;
                this.scope.project_1 = JSON.parse(JSON.stringify(this.project.leed_id));
                this.scope.project_1_model = JSON.parse(JSON.stringify(this.version_id));

                this.modelSelected('project_1', this.project.leed_id);
            },
            error =>
            {
                this.loading = false;
            }
        );
	}

	modelSelected(type: any, leed_id: any)
	{
		this.loading = true;
		this.scope[type] = leed_id;
		for(var n in this.assets)
		{
			if(this.assets[n].leed_id == leed_id)
			{
				this.scope[type + '_object'] = this.assets[n];
				this.scope[type + '_model_versions'] = this.assets[n].versions;
				this.scope[type + '_model'] = this.assets[n].versions[0].version_id;

				this.getScoreCard(this.assets[n], this.scope[type + '_model'], type);
				break;
			}
		}
	}

	getScoreCard(project: any, version_id: any, type: string)
	{
		this.insight.get('/assets/LEED:'+ project.leed_id + '/reduction/?version_id=' + version_id).subscribe(
			data =>
			{
				var reduction_data = data.reduction_data;
				this.insight.getAsset('/assets/insight_json/'+ project.rating_system +'.json').subscribe((response) =>
				{
					var service_data =  this.insight.initScorecard(reduction_data, JSON.parse(JSON.stringify(response)), project.rating_system);
					var score_card = service_data.score_card;
					
					for(var k in score_card)
					{
						var credit_id = score_card[k].id;
						this.comparison[type][credit_id] = {
							"attempted": score_card[k].attempted,
							"max_points": score_card[k].max_points
						}
					}

					this.scope[type + '_energy_estimate'] = service_data.energy_estimate;
					this.scope[type + '_water_estimate'] = service_data.water_estimate;
					this.scope[type + '_waste_estimate'] = service_data.waste_estimate;
					
					this.loading = false;
				});
			}
		);
	};

}

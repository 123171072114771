
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <h1 class="page-title pl0 pb10">Impacted by COVID-19</h1>

        <div class="row">
            <div class="">
                <section class="widget">
                    <header>
                        <h5 class="mb10">Enter LEED ID to view the project.</h5>
                    </header>
                    <div class="widget-body">
                        <form class="form-horizontal" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <div class="col-sm-10" class="form-group" [ngClass]="loader ? 'not-active' : ''">
                                        <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                        <div class="col-sm-7">
                                            <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control"
                                                placeholder="LEED ID" (input)="checkLEEDID()" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="wizard display-block">
                                            <button (click)="searchProject()" class="btn btn-default pull-left mr10"
                                                [ngClass]="checkLEED ? '' : 'not-active'"><i class="fa fa-search"
                                                    aria-hidden="true"></i> Search</button>
                                            <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <table class="table table-striped mt30" id="impacted_by_covid_table"
                            [ngClass]="not_active ? 'not-active' : ''">
                            <thead>
                                <tr>
                                    <th class="w20p">Project ID</th>
                                    <th class="w20p">Name</th>
                                    <th class="w15p">Approved COVID extension</th>
                                    <th class="w15p">Impacted by COVID-19</th>
                                    <th class="w15p">Energy & Water : Time Weighted Occupancy</th>
                                    <th class="w15p">Waste: Use Occupancy at the time of the audit</th>
                                </tr>
                            </thead>
                            <tbody [hidden]="!bld_data.leed_id">
                                <tr>
                                    <td>{{bld_data.leed_id}}</td>
                                    <td>{{bld_data.name}}</td>
                                    <td class="align-center">
                                        <input class="cursor-pointer"
                                            (change)="makeAction(bld_data, 'approved_covid_extension')" type="checkbox"
                                            name="approved_covid_extension" id=approved_covid_extension>
                                        <label for="approved_covid_extension"></label>
                                    </td>
                                    <td class="align-center">
                                        <input class="cursor-pointer"
                                        (change)="makeAction(bld_data, 'impacted_by_covid')" type="checkbox"
                                            name="impacted_by_covid" id=impacted_by_covid>
                                        <label for="impacted_by_covid"></label>
                                    </td>
                                    <td class="align-center">
                                        <input class="cursor-pointer"
                                        (change)="makeAction(bld_data, 'time_weighted_occupancy')" type="checkbox"
                                            name="time_weighted_occupancy" id=time_weighted_occupancy>
                                        <label for="time_weighted_occupancy"></label>
                                    </td>
                                    <td class="align-center">
                                        <input class="cursor-pointer"
                                        (change)="makeAction(bld_data, 'time_weighted_waste')" type="checkbox"
                                            name="time_weighted_waste" id=time_weighted_waste>
                                        <label for="time_weighted_waste"></label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<div class="manage_model relative-position">
    <div class="w60p">
        <app-loader *ngIf="loading"></app-loader>
        <div class="net_zero w70p">
            <div class="settings_row fix-width">
                <div class="fieldset">
                    <div class="w80p">
                        <div class="label">Net Zero Energy</div>
                        <p class="desc">This project is pursuing Net Zero Energy</p>
                    </div>
                    <div class="pull-left mr10">
                        <input (change)="netZeroChanged()" type="checkbox" class="net_zero_energy" [(ngModel)]="net_zero_energy"/>
                    </div>  
                </div>
            </div>
    
            <div class="settings_row fix-width">
                <div class="fieldset">
                    <div class="w80p">
                        <div class="label">Net Zero Water</div>
                        <p class="desc">This project is pursuing Net Zero Water</p>
                    </div>
                    <div class="pull-left mr10">
                        <input (change)="netZeroChanged()" type="checkbox" class="net_zero_water" [(ngModel)]="net_zero_water"/>
                    </div>  
                </div>
            </div>
            
            <div class="settings_row fix-width">
                <div class="fieldset">
                    <div class="w80p">
                        <div class="label">Net Zero Waste</div>
                        <p class="desc">This project is pursuing Net Zero Waste</p>
                    </div>
                    <div class="pull-left mr10">
                        <input (change)="netZeroChanged()" type="checkbox" class="net_zero_waste" [(ngModel)]="net_zero_waste"/>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    
    <h3 class="fw-500 mb25 mt50 fs20 fix-width">Reduction types</h3>
    <div class="fix-width w60p w50p" id="details-form">
        <div class="reduction_row mb30">
            <span class="fw-500 fs16">ENERGY:</span>
            <span class="grey_color fs15 ml10">Provide estimated energy reduction for your project: </span>
            <div [ngClass]="net_zero_energy ? 'not-active' : ''" class="group w70p pb0 mt20">      
                <input *ngIf="!net_zero_energy" (blur)="reductionChanged('energy_estimate', energy_estimate)" maxlength="40" [(ngModel)]="energy_estimate" name="energy_estimate" type="text" required>
                <input *ngIf="net_zero_energy" maxlength="40" value="100" name="energy_estimate" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Percent reduction (%)</label>
            </div>
        </div>
        <div class="reduction_row mb30">
            <span class="fw-500 fs16">WATER:</span>
            <span class="grey_color fs15 ml10">Provide estimated water reduction below EPAct 1992 for your project: </span>
            <div [ngClass]="net_zero_water ? 'not-active' : ''" class="group w70p pb0 mt20">      
                <input *ngIf="!net_zero_water" (blur)="reductionChanged('water_estimate', water_estimate)" maxlength="40" [(ngModel)]="water_estimate" name="water_estimate" type="text" required>
                <input *ngIf="net_zero_water" maxlength="40" value="100" name="water_estimate" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Percent reduction (%)</label>
            </div>
        </div>
        <div class="reduction_row mb30">
            <span class="fw-500 fs16">WASTE:</span>
            <span class="grey_color fs15 ml10">What percentage of demolition and construction waste will be diverted from landfill?</span>
            <div [ngClass]="net_zero_waste ? 'not-active' : ''" class="group w70p pb0 mt20">      
                <input *ngIf="!net_zero_waste" (blur)="reductionChanged('waste_estimate', waste_estimate)" maxlength="40" [(ngModel)]="waste_estimate" name="waste_estimate" type="text" required>
                <input *ngIf="net_zero_waste" maxlength="40" value="100" name="waste_estimate" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Percent reduction (%)</label>
            </div>
        </div>
    </div>
    
    <div class="strategies_summary summary_box" style="top: 50px;">
        <h4 class="fw-500">Percentage reduction ({{project.rating_system}})</h4>
    
        <div class="summary_row">
            <span *ngIf="net_zero_energy" class="fw-500">100%</span>
            <span *ngIf="!net_zero_energy" class="fw-500">{{energy_estimate}}%</span> Average energy reduction
        </div>
        <div class="summary_row">
            <span *ngIf="net_zero_water" class="fw-500">100%</span>
            <span *ngIf="!net_zero_water" class="fw-500">{{water_estimate}}%</span> Average water reduction
        </div>
        <div class="summary_row">
            <span *ngIf="net_zero_waste" class="fw-500">100%</span>
            <span *ngIf="!net_zero_waste" class="fw-500">{{waste_estimate}}%</span> Average waste diverted from landfill
        </div>
    </div>
</div>

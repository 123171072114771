import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { LanguageService } from 'src/app/services/language.service';

import { Config } from 'src/app/models/env-vars.model';
import { ActivatedRoute, Router } from '@angular/router';

declare var tippy: any;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit
{
	config = Config;
	building: any = {};
	selected_route: any = {};
	routes: any = [];
	enhance: any = [{
			'value': "thermal comfort",
			'label': "thermal_comfort",
			'data': false
		},
		{
			'value': "sound",
			'label': "sound_satisfied",
			'data': false
		},
		{
			'value': 'air quality',
			'label': "air_quality",
			'data': false
		},
		{
			'value': 'cleanliness',
			'label': "cleanliness",
			'data': false
		},
		{
			'value': 'light',
			'label': "light",
			'data': false
		},
		{
			'value': 'privacy',
			'label': "privacy_satisfied",
			'data': false
		},
		{
			'value': 'views to outdoors',
			'label': "views_satisfied",
			'data': false
		},
		{
			'value': 'daylight',
			'label': "daylight",
			'data': false
		}
	];
	reduce: any = [];
	reduce_1: any = [];
	reduce_2: any = [];
	reduce_3: any = [];
	reduce_4: any = [];
	reduce_5: any = [];
	drop_init: boolean = false;
	config_header: any = 
	{
		headers:
		{
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.sub_key,
		}
	};

	selected_index: any = null;
	compliments: any = undefined;
	found_bad_input: any = undefined;
	other_attributes: string = '';
	location: string = '';
	occupantError: boolean = false;
	routeError: boolean = false;
	visitorRouteError: boolean = false;
	showSliderError: boolean = false;
	loading: boolean = false;

	leed_id: any;
	key = this.global.getURLFromParam('key');

	slider_text = this.lang.getText("slide_to_select");
	selected_slider: boolean = false;
	survey_submitted: boolean = false;
	isLatest: any = null;
	name: string = "";
	routeadded: boolean = false;
	occupant_category: string = "regular_occupant";
	current_step: string = 'Transportation Survey';
	skipped_t: boolean = false;
	skipped_he: boolean = false;

	satisfaction_slider_new: number = 0;
	satisfaction_slider: number = 0;
	min: number = 0;
	max: number = 0;
	satisfaction: number = 0;
	selected_route_data: any = {};
	route_template: any = {};
	init_slider: boolean = false;

	route_days: string = '1';
	error_for_days: string = '';
	error_for_days_flag: boolean = false;

	noOfDays(days: any){
		this.route_days = days;
		this.error_for_days_flag = false;
	}
	
	constructor(
		public global: Global,
		private api: API,
		private activatedRoute: ActivatedRoute,
		public lang: LanguageService,
		private router: Router,
		public appData: AppData
	)
	{
		this.appData.set('login', true);
		this.activatedRoute.params.subscribe((params) =>
		{
			this.leed_id = params['leed_id'];
			this.getBuildingDetails();
		});
	}

	ngOnInit(): void
	{
		$("body").on("keypress", ".numbers-only", function (event: any) {
			if ((event.which != 46 || (<any>$(this)).val().indexOf('.') != -1) &&
				((event.which < 48 || event.which > 57) &&
				(event.which != 0 && event.which != 8))) {
				event.preventDefault();
			}
		
			var text = (<any>$(this)).val();
		
			if ((text.indexOf('.') != -1) &&
				(text.substring(text.indexOf('.')).length > 2) &&
				(event.which != 0 && event.which != 8) &&
				($(this)[0].selectionStart >= text.length - 2)) {
				event.preventDefault();
			}
		});
	}

	initRangeSlider()
	{	
		if(!this.init_slider)
		{
			this.init_slider = true;
			let $element: any = $('input[type="range"]');
                
			$element.rangeslider(
			{
				polyfill: false,
				onSlide: (position: any, value: any) =>
				{
					if(this.isLatest)
					{
						this.satisfaction_slider_new = value;
						this.satisfactionChange(this.satisfaction_slider_new, true);
					}
					else
					{
						this.satisfaction_slider = value;
						this.satisfactionChange(this.satisfaction_slider, false);
					}
					this.global.refreshCycle();
				}
			});
		}
	}

	getBuildingDetails()
	{
		this.loading = true;
		let header: any = 
		{
			headers: 
			{
				"responseType": "json",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
			}
		};
		this.api.get('/assets/LEED:'+ this.leed_id + '/?key=' + this.key).subscribe(
            response =>
            {
				this.loading = false;
                this.building = response;
                if(this.building.score_code_version.name >= 3)
                {
                    this.isLatest = true;
                    this.min = -3;
                    this.max = 3;
                    this.satisfaction = 0;
                    this.selected_route_data = {"walk":"","bike":"","telecommute":"","motorcycle":"","heavy_rail":"","car23":"","cars4":"","light_rail":"","bus":"","car":"", "noofdays": "1"};

                    this.reduce_4 = [{
                            'value': 'loud',
                            'label': "loud",
                            'data': false
                        },
                        {
                            'value': 'privacy',
                            'label': "privacy",
                            'data': false
                        },
                        {
                            'value': 'sound',
                            'label': "sound",
                            'data': false,
                            'latest': true
                        }
                    ];
                    
                    this.reduce_5 = [{
                            'value': 'hot',
                            'label': "hot",
                            'data': false
                        },
                        {
                            'value': 'humid',
                            'label': "humid",
                            'data': false,
                            'latest': true
                        }
                    ];
                }
                else
                {
                    this.isLatest = false;
                    this.min = 0;
                    this.max = 10;
                    this.satisfaction = 5;
                    this.selected_route_data = {"walk_bike_telecommute":"","motorcycle":"","heavy_rail":"","car23":"","cars4":"","light_rail":"","bus":"","car":"", "noofdays": "1"};
                    
                    this.reduce_4 = [{
                            'value': 'loud',
                            'label': "loud_v2",
                            'data': false
                        },
                        {
                            'value': 'privacy',
                            'label': "privacy",
                            'data': false
                        },
                        {
                            'value': 'sound',
                            'label': "sound",
                            'data': false,
                            'latest': true
                        }
                    ];
                    
                    this.reduce_5 = [{
                            'value': 'hot',
                            'label': "hot",
                            'data': false
                        },
                        {
                            'value': 'humid',
                            'label': "humid",
                            'data': false,
                            'latest': true
                        }
                    ];
                }

                this.reduce_1 = [{
                        'value': 'dirty',
                        'label': "dirty",
                        'data': false
                    },
                    {
                        'value': 'cold',
                        'label': "cold",
                        'data': false
                    },
                    {
                        'value': 'drafty',
                        'label': "drafty",
                        'data': false,
                        'latest': true
                    }
                ];
        
                this.reduce_2 = [{
                        'value': 'smelly',
                        'label': "smelly",
                        'data': false
                    },
                    {
                        'value': 'dark',
                        'label': "dark",
                        'data': false
                    },
                    {
                        'value': 'bright',
                        'label': "bright",
                        'data': false,
                        'latest': true
                    }
                ];
                
                this.reduce_3 = [{
                        'value': 'stuffy',
                        'label': "stuffy",
                        'data': false
                    },
                    {
                        'value': 'glare',
                        'label': "glare",
                        'data': false
                    },
                    {
                        'value': 'views to outdoors',
                        'label': "views",
                        'data': false,
                        'latest': true
                    }
                ];
    
                this.reduce = this.reduce_1.concat(this.reduce_2, this.reduce_3, this.reduce_4, this.reduce_5);

                if(this.lang.language.label == 'English')
                {
                    this.route_template = {
                        "unit": "miles",
                        "ingnored_routes": [],
                        "data": JSON.parse(JSON.stringify(this.selected_route_data))
                    };
                }
                else if(this.lang.language.label != 'English')
                {
                    this.route_template = {
                        "unit": "kilometers",
                        "ingnored_routes": [],
                        "data": JSON.parse(JSON.stringify(this.selected_route_data))
                    };
                }
                this.routes = [this.route_template];
            }
        );
	}
	
	showDivBorder(data: any)
	{
		for(var a in data)
		{
			if(data[a] != "")
			{
				return true;
			}
		}
		return false;
	}

	checkMileage(route: any, key: any, ignore_routes?: any)
	{
		if(ignore_routes == undefined)
		{
			ignore_routes = this.selected_route['ingnored_routes'];
		}
		// if(key != undefined && ignore_routes.indexOf(key) > -1)
		// {
		//     ignore_routes.splice(ignore_routes.indexOf(key), 1);
		// }
		var found = false;
		for(var a in route.data)
		{
			if((a == 'walk' || a == 'walk_bike_telecommute') && route.data[a] > 3 && route.unit == 'miles' && ignore_routes.indexOf(a) == -1)
			{
				route[a] = {};
				route[a]['error'] = true;
				found = true;
			}
			else if(route.data[a] > 30 && route.unit == 'miles' && ignore_routes.indexOf(a) == -1)
			{
				route[a] = {};
				route[a]['error'] = true;
				found = true;
			}
			else if((a == 'walk' || a == 'walk_bike_telecommute') && route.data[a] > 4.82 && route.unit == 'kilometers' && ignore_routes.indexOf(a) == -1)
			{
				route[a] = {};
				route[a]['error'] = true;
				found = true;
			}
			else if(route.data[a] > 48.28 && route.unit == 'kilometers' && ignore_routes.indexOf(a) == -1)
			{
				route[a] = {};
				route[a]['error'] = true;
				found = true;
			}
			else
			{
				route[a] = {};
				route[a]['error'] = false;
			}
		}
		this.found_bad_input = found;
	}

	satisfactionChange(satisfaction: any, isLatest: any)
	{
		if(isLatest != this.isLatest)
		{
			return;
		}
		this.selected_slider = true;
		this.showSliderError = false;
		this.satisfaction = satisfaction;
		if(isLatest)
		{
			if(satisfaction == 3)
			{
				this.slider_text = this.lang.getText("extremely_satisfied");
			}
			else if(satisfaction == 2)
			{
				this.slider_text = this.lang.getText("very_satisfied");
			}
			else if(satisfaction == 1)
			{
				this.slider_text = this.lang.getText("satisfied");
			}
			else if(satisfaction == 0)
			{
				this.slider_text = this.lang.getText("satisfaction_none");
			}
			else if(satisfaction == -1)
			{
				this.slider_text = this.lang.getText("unsatisfied");
			}
			else if(satisfaction == -2)
			{
				this.slider_text = this.lang.getText("very_unsatisfied"); 
			}
			else if(satisfaction == -3)
			{
				this.slider_text = this.lang.getText("extremely_unsatisfied"); 
			}
		}
		else
		{
			if(satisfaction > 9)
			{
				this.slider_text = 'Extremely satisfied';
			}
			else if(satisfaction <= 9 && satisfaction >=8)
			{
				this.slider_text = 'Very satisfied';
			}
			else if(satisfaction < 8 && satisfaction >= 6)
			{
				this.slider_text = 'Satisfied';
			}
			else if(satisfaction <6 && satisfaction >4)
			{
				this.slider_text = 'Neither satisfied nor dissatisfied';
			}
			else if(satisfaction <= 4 && satisfaction >2)
			{
				this.slider_text = 'Dissatisfied';
			}
			else if(satisfaction <=2 && satisfaction > 1)
			{
				this.slider_text = 'Very Dissatisfied';
			}
			else if(satisfaction <= 1)
			{
				this.slider_text = 'Extremely dissatisfied';
			}
		}
	}

	onChange(option: any)
	{
		for(let i = 0; i < this.routes.length; i++)
		{
			this.routes[i].unit = option.label == 'English' ? 'miles' : 'kilometers';
		}

		const queryParams: any = { language: this.lang.language.code };

		this.router.navigate(
		[], 
		{
			relativeTo: this.activatedRoute,
			queryParams: queryParams, 
			queryParamsHandling: 'merge',
		});

		try
		{
			(<any>document.querySelector('.tippy_init')).setAttribute('title', this.lang.getText('one_day'));
			tippy(".tippy_init", {size: 'large', animation: 'shift-away', theme: 'light'});
			setTimeout(() =>
			{
				(<any>document.querySelector('.tippy_init')).removeAttribute('title');
			});
		}
		catch(e)
		{
			console.log('error', e);
		}
	}

	langDropInit()
	{
		if(this.drop_init == false)
		{
			this.drop_init = true;
			var selectedItem = localStorage.getItem("SelectedItem"); 

            $('#dropdown').change(function() { 
                var dropVal: any = $(this).val();
                sessionStorage.setItem("SelectedItem", dropVal);
            });   
		}
	}

	removeRoute(index: number)
	{
		this.routes.splice(index, 1);
		this.checkVisitorRoute();
	}

	editRoute(route: any, index: number)
	{
		this.error_for_days_flag = false;
		this.routeadded = true;
		this.selected_index = JSON.parse(JSON.stringify(index));
		this.selected_route = JSON.parse(JSON.stringify(route));;
		
		$('.route_list').modal('show');
	}

	addRoute()
	{
		this.error_for_days_flag = false;
		if(this.occupant_category == 'visitor_occupant')
		{
			this.visitorRouteError = true;
		}
		if (this.lang.language.label == 'English')
		{
			this.routes.push({
				"unit": "miles",
				"ingnored_routes": [],
				"data": JSON.parse(JSON.stringify(this.selected_route_data))
			});
		}            

		else if(this.lang.language.label != 'English')
		{
			this.routes.push({
			"unit": "kilometers",
			"ingnored_routes": [],
			"data": JSON.parse(JSON.stringify(this.selected_route_data))
		});
		}
	}

	checkHE(): any
	{
		var occupant_category = $('#occupant_category').val();

		if(!this.selected_slider)
		{
			this.showSliderError = true;
		}
		if(occupant_category == null && this.selected_slider)
		{
			this.occupantError = true;
		}
		else
		{
			this.occupantError = false;
		}

		if(!this.isLatest)
		{
			this.occupantError = false;
		}

		return this.showSliderError || this.occupantError;
	}

	submitTransitSurvey()
	{
		var routes = this.checkRoutes();
		console.log('Occupanrt cat is' + this.occupant_category);

		if(this.routeError || this.visitorRouteError)
		{
			return;
		}
		var payload = 
		{
			"tenant_name": $('.name').val(),
			"response_method": "web",
			"occupant_category": this.occupant_category,
			"routes": routes,
			"language": this.lang.language.label
		};

		this.loading = true;
		var survey_url = '/assets/LEED:'+ this.leed_id + '/survey/transit/?key=' + this.key;
		this.api.post(survey_url, payload, this.config_header).subscribe(
			data =>
			{
				this.loading = false;
				this.survey_submitted = true;
			},

			error =>
			{
				this.loading = false;
			}
		);
	}

	submitBothSurvey()
	{
		var compliments = [];
		var occupant_category = $('#occupant_category').val();

		if(!this.selected_slider)
		{
			this.showSliderError = true;
		}
		if(occupant_category == null && this.selected_slider)
		{
			this.occupantError = true;
		}
		else
		{
			this.occupantError = false;
		}

		if(!this.isLatest)
		{
			this.occupantError = false;
		}
		
		var routes = this.checkRoutes();

		if(this.routeError || this.showSliderError || this.occupantError || this.visitorRouteError)
		{
			return;
		}

		if(this.isLatest)
		{
			if(this.satisfaction > 0)
			{
				for(var a in this.enhance)
				{
					if(this.enhance[a].data)
					{
						compliments.push(this.enhance[a].value)
					}
				}
			}
			else
			{
				for(var a in this.reduce)
				{
					if(this.reduce[a].data)
					{
						compliments.push(this.reduce[a].value)
					}
				}
			}
		}
		else if(!this.isLatest && this.satisfaction < 5)
		{
			for(var a in this.reduce)
			{
				if(this.reduce[a].data)
				{
					compliments.push(this.reduce[a].value)
				}
			}
		}

		this.compliments = compliments.length;

		var survey_payload = 
		{
			"satisfaction": this.satisfaction,
			"routes": routes,
			"feedbacks": JSON.stringify(compliments),
			"tenant_name": $('.name').val(),
			"response_method": "web",                
			"location": $('.location').val(),
			"other_complaint": $('.other_attributes').val(),
			"language": this.lang.language.label,
			"occupant_category": occupant_category
		}

		if(!this.isLatest)
		{
			delete survey_payload["occupant_category"];
		}

		this.loading = true;
		var survey_url = '/assets/LEED:'+ this.leed_id + '/survey/?key=' + this.key;
		this.api.post(survey_url, survey_payload, this.config_header).subscribe(
			data =>
			{
				this.loading = false;
				this.survey_submitted = true;
			},

			error =>
			{
				this.loading = false;
			}
		);
	}

	checkRoutes()
	{
		var routes = [];

		for(var a in this.routes)
		{
			var route_item: any = {};
			var valid_route = false;
			var unit = this.routes[a]['unit'];
			for(var b in this.routes[a]['data'])
			{
				if(!isNaN(parseFloat(this.routes[a]['data'][b])))
				{
					valid_route = true;
					if(b != 'noofdays')
					{
						route_item[b] = unit == 'kilometers' ? this.routes[a]['data'][b] * 0.621 : this.routes[a]['data'][b];
					}
					else{
						route_item[b] = unit == 'kilometers' ? this.routes[a]['data'][b] : this.routes[a]['data'][b];
					}
					
					if(b == 'walk_bike_telecommute')
					{
						route_item['walk'] = route_item[b];
						delete route_item["walk_bike_telecommute"];
					}
				}
				else
				{
					route_item[b] = 0;
				}
			}
			if(valid_route)
			{
				routes.push(route_item);
			}
			delete this.routes[a]['data'].noofdays;
		}

		if(routes.length == 0 )
		{
			this.routeError = true;
		}

		else if(route_item.walk == '' && route_item.bike == '' && route_item.telecommute == '' && route_item.motorcycle == '' && route_item.heavy_rail == '' && route_item.car23 == '' && route_item.cars4 == '' && route_item.car == '' && route_item.light_rail == '' && route_item.bus == '')
		{
			this.routeError = true;
		}
		else
		{
			this.routeError = false;
		}

		return routes
	}

	submitHumanSurvey()
	{
		var compliments = [];
		var occupant_category = $('#occupant_category').val();

		if(this.isLatest)
		{
			if(this.satisfaction > 0)
			{
				for(var a in this.enhance)
				{
					if(this.enhance[a].data)
					{
						compliments.push(this.enhance[a].value)
					}
				}
			}
			else
			{
				for(var a in this.reduce)
				{
					if(this.reduce[a].data)
					{
						compliments.push(this.reduce[a].value)
					}
				}
			}
		}
		else if(!this.isLatest && this.satisfaction < 5)
		{
			for(var a in this.reduce)
			{
				if(this.reduce[a].data)
				{
					compliments.push(this.reduce[a].value)
				}
			}
		}

		this.compliments = compliments.length;
		
		var payload  = {
			"tenant_name": $('.name').val(),
			"response_method": "web",
			"satisfaction": this.satisfaction,
			"location": $('.location').val(),
			"feedbacks": JSON.stringify(compliments),
			"other_complaint": $('.other_attributes').val(),
			"language": this.lang.language.label,
			"occupant_category": occupant_category
		};

		this.loading = true;
		var survey_url = '/assets/LEED:'+ this.leed_id + '/survey/environment/?key=' + this.key;
		this.api.post(survey_url, payload, this.config_header).subscribe(
			response =>
			{
				this.loading = false;
				this.survey_submitted = true;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	submitSurvey()
	{
		if(this.checkHE())
		{
			$('.survey_submission_blank').modal('show');
			return;
		}

		if(this.skipped_t)
		{
			this.submitHumanSurvey();
		}

		if(!this.skipped_t && !this.skipped_he)
		{
			this.submitBothSurvey();
		}
	}

	reloadSurvey()
	{
		window.location.reload();
	}

	goTo(url: string)
	{
		window.open(url, '_blank');
	};

	ingnoreMileage(route: any)
	{
		this.selected_route[route]['error'] = false;
		this.selected_route['ingnored_routes'].push(route);
		this.checkMileage(this.selected_route, undefined, this.selected_route['ingnored_routes'])
	};

	clearRoute()
	{
		this.selected_route = {
			"unit": this.selected_route.unit,
			"ingnored_routes": [],
			"data": JSON.parse(JSON.stringify(this.selected_route_data))
		};
	};

	updateRoute (route: any, index: number){

		for(var a in route.data){
			if((route.data['walk'] != '' || route.data['bike'] != '' || route.data['telecommute'] != '' || route.data['motorcycle'] != '' || 
			route.data['heavy_rail'] != '' || route.data['car23'] != '' || route.data['cars4'] != '' || route.data['car'] != '' || 
			route.data['light_rail'] != '' || route.data['bus'] != '')) {
				this.routes[index] = route;
				$('.route_list').modal('hide');
				this.checkRoutes();
			}
		}
	}

	routeUnitChanged (unit: any)
	{
		this.selected_route.unit = unit;
	};

	skipStep(skip_step: string)
	{
		if(skip_step == 'Transportation Survey')
		{
			this.skipped_t = true;
			this.routeError = false;
			this.current_step = 'Human Experience Survey';
		}
		else if(skip_step == 'Human Experience Survey')
		{
			this.skipped_he = true;
			if(this.checkHE())
			{
				this.occupantError = false;
				this.showSliderError = false;
				this.submitTransitSurvey();
			}
			else
			{
				this.skipped_t = false;
				this.skipped_he = false;
				this.submitBothSurvey();
			}
		}
	};

	checkVisitorRoute()
	{
		if(this.occupant_category == 'visitor_occupant' && this.routes.length > 1)
		{
			this.visitorRouteError = true;
		}
		else
		{
			this.visitorRouteError = false;
		}
	}

	goToStep(next_step: string)
	{
		this.checkVisitorRoute();
		if(this.current_step == 'Transportation Survey')
		{
			this.skipped_t = false;
			this.checkRoutes();
			if(this.routeError || this.visitorRouteError)
			{
				return;
			}
		}
		else if(this.current_step == 'Human Experience Survey')
		{
			this.occupantError = false;
			this.showSliderError = false;
		}
		this.current_step = next_step;
	};
}

<div class="noProjects-addProjects--container">
    <!--<div class="addProjects--buildingIcon"></div>-->
    <img src="../../assets/images/buildings_green.svg" alt="" class="addProjects--buildingIcon">
    <p class="noProjects-addProjects--content">Begin comparing performance within your portfolio!</p>
    <div class="center-div">
        <div class="blank_port_option">
            <div class="fs18 line_height_24 mb8 fw-500">Existing Project</div>
            <div class="fs16 line_height_24 mb15">Add projects already in Arc to this portfolio</div>
            <button class="btn btn-primary inverse relative-position pl35" (click)="addProject('existing')" ng-click="addProject('app.portfolio_myprojects_list', {'pf_id': appData.portfolio_id, 'name': appData.portfolio_name})">
                <img class="plus_icon" src="../../../assets/images/plus.svg">
                <div class="fs15 fw-500">Add</div>
            </button>
        </div>
        <div class="blank_port_option">
            <div class="fs18 line_height_24 mb8 fw-500">New Project</div>
            <div class="fs16 line_height_24 mb15">Add a new project to Arc and this portfolio</div>
            <button class="btn btn-primary inverse relative-position pl35" (click)="addProject('new')" ng-click="addProject('app.projectRegistrationNew', {'pf_id': appData.portfolio_id, 'name': appData.portfolio_name})">
                <img class="plus_icon" src="../../../assets/images/plus.svg">
                <div class="fs15 fw-500">Add</div>
            </button>
        </div>
    </div>
</div>

<modal #modal>
    <div class="modal-header">
		<nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
            <div class="align-center mt11 width-100-perc">
                <h4 class="fw-500">Add a project</h4>            
            </div>
            <div class="pt20">
                <svg (click)="modal.close()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
            </div>
        </nav>
	</div>
	<div class="modal-body">
        
        <div class="content-wrap">
            <div id="content" class="content">
                <div class="fs20 fw-500 line_height_40">
                    Select projects to add to {{appData.get('portfolio').name}}
                </div>
                <app-projects (close)="cancel()" [type]="'portfolio_list'" [selected_portfolio]="appData.get('portfolio')"></app-projects>
            </div>
        </div>
	</div>
</modal>
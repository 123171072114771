<div [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
    <div id="details-form">
        <div *ngIf="!create_mode" class="mb25 static_info ml0 pb0 pl0" [ngClass]="loading ? 'not-active' : ''">
            <div class="w50p">
                <div class="info">
                    <div class="title">Owner Organization</div>
                    <div class="value">{{formdata.organization}}</div>
                </div>
                <div class="info">
                    <div class="title">Owner Email</div>
                    <div class="value">{{formdata.owner_email}}</div>
                </div>
                <div class="info">
                    <div class="title">Owner Country/Region</div>
                    <div class="value">{{manageEntityCountry}}</div>
                </div>
            </div>
        </div>
        <div class="flex">
            <table id="model_details">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="table-margin-20">
                    <tr>
                        <td colspan="2">
                            <div class="group pr20" [ngClass]="formdata_error.name.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.name, 'name')" (input)="verifyField(formdata.name, 'name')" [(ngModel)]="formdata.name" name="name" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Project Name</label>
                                <span class="error">{{formdata_error.name.help}}</span>
                            </div>
                        </td>
                        <td *ngIf="create_mode" colspan="2">
                            <div class="group" [ngClass]="formdata_error.version.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.version, 'version')" (input)="verifyField(formdata.version, 'version')" [(ngModel)]="formdata.version" name="version" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Project Version</label>
                                <span class="error">{{formdata_error.version.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="group pr20" [ngClass]="formdata_error.cm_arch.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.cm_arch, 'cm_arch')" (input)="verifyField(formdata.cm_arch, 'cm_arch')" [(ngModel)]="formdata.cm_arch" name="cm_arch" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Construction Manager</label>
                                <span class="error">{{formdata_error.cm_arch.help}}</span>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="group" [ngClass]="formdata_error.cm_arch_number.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.cm_arch_number, 'cm_arch_number')" (input)="verifyField(formdata.cm_arch_number, 'cm_arch_number')" [(ngModel)]="formdata.cm_arch_number" name="cm_arch_number" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>CM Project Number</label>
                                <span class="error">{{formdata_error.cm_arch_number.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="group" [ngClass]="formdata_error.architect.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.architect, 'architect')" (input)="verifyField(formdata.architect, 'architect')" [(ngModel)]="formdata.architect" name="architect" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Architect</label>
                                <span class="error">{{formdata_error.architect.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            <div class="select-group group">   
                                <label>Rating System</label>   
                                <select [(ngModel)]="formdata.rating_system" (change)="saveProjectEdit(formdata.rating_system, 'rating_system')" name="rating_system">
                                    <option value="LEED 2009">LEED 2009</option>
                                    <option value="LEED v4">LEED v4</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="group" [ngClass]="formdata_error.leed_project_number.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.leed_project_number, 'leed_project_number')" (input)="verifyField(formdata.leed_project_number, 'leed_project_number')" [(ngModel)]="formdata.leed_project_number" name="leed_project_number" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>LEED Project Number</label>
                                <span class="error">{{formdata_error.leed_project_number.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="group pb5 pr20" [ngClass]="formdata_error.gross_area.error ? 'has-error' : ''">      
                                <input float-input id="gross_area" (blur)="saveProjectEdit(formdata.gross_area, 'gross_area')" (input)="verifyField(formdata.gross_area, 'gross_area')" [(ngModel)]="formdata.gross_area" name="gross_area" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Gross Area</label>
                                <span class="error">{{formdata_error.gross_area.help}}</span>
                            </div>
                        </td>
                        <td colspan="1">
                            <div class="select-group pb5 group">   
                                <label>Unit Type</label>   
                                <select (change)="saveProjectEdit(formdata.unitType, 'unitType')" [(ngModel)]="formdata.unitType" name="unitType">
                                    <option value="IP">square feet</option>
                                    <option value="SI">square meters</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb30" [ngClass]="!create_mode ? 'pt15' : ''" colspan="4">
                            <span class="fontSize12 grey">
                                Enter total built area in {{getUnit(formdata.unitType)}} (Max: <span class="fw-400">{{global.addCommas(getMax(formdata.unitType))}}</span> {{getUnit(formdata.unitType)}})
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="select-group group pr20">   
                                <label>Do you plan to complete an Energy Model?</label>   
                                <select (change)="saveProjectEdit(formdata.energy_model_1, 'energy_model_1')" [(ngModel)]="formdata.energy_model_1" name="energy_model_1">
                                    <option value="Yes the project already has one">Yes the project already has one</option>
                                    <option value="Yes in the future">Yes in the future</option>
                                    <option value="No">No</option>
                                    <option value="Hadn’t thought about it">Hadn’t thought about it</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="select-group group">   
                                <label>Do you plan to complete a project Carbon Footprint?</label>   
                                <select (change)="saveProjectEdit(formdata.gsi, 'gsi')" [(ngModel)]="formdata.gsi" name="gsi">
                                    <option value="Yes the project already has one">Yes the project already has one</option>
                                    <option value="Yes in the future">Yes in the future</option>
                                    <option value="No">No</option>
                                    <option value="Hadn’t thought about it">Hadn’t thought about it</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="select-group group">   
                                <label>Space Type</label>   
                                <select (change)="saveProjectEdit(formdata.spaceType, 'spaceType')" [(ngModel)]="formdata.spaceType" name="spaceType">
                                    <option *ngFor="let type of spaceType" value="{{type}}">{{type}}</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="create_mode">
                        <td colspan="4">
                            <div class="select-group group">   
                                <label>Owner Type</label>   
                                <select (change)="saveProjectEdit(formdata.ownerType, 'ownerType')" [(ngModel)]="formdata.ownerType" name="ownerType">
                                    <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="create_mode">
                        <td colspan="4">
                            <div class="group" [ngClass]="formdata_error.organization.error ? 'has-error' : ''">      
                                <input id="organization" (input)="searchOrganization(formdata.organization)" [(ngModel)]="formdata.organization" name="organization" type="text" required autocomplete="off">
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>
                                    Owner Organization
                                </label>
                                <span class="error">{{formdata_error.organization.help}}</span>
                                
                                <span class="">(<span (click)="openModal()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>

                                <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization">
                                    <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name);">

                                        <div class="cursor-pointer vertical_align_middle">          
                                            <span>{{org_name}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="create_mode">
                        <td colspan="4">
                             <div class="group" [ngClass]="formdata_error.owner_email.error ? 'has-error' : ''">      
                                 <input (blur)="saveProjectEdit(formdata.owner_email, 'owner_email')" (input)="verifyField(formdata.owner_email, 'owner_email')" [(ngModel)]="formdata.owner_email" name="owner_email" type="text" required>
                                 <span class="highlight"></span>
                                 <span class="bar"></span>
                                 <label>Owner Email</label>
                                 <span class="error">{{formdata_error.owner_email.help}}</span>
                             </div>
                         </td>
                     </tr>
                     <tr *ngIf="create_mode">
                         <td colspan="4">
                             <div class="select-group group">   
                                 <label>Owner Country/Region</label>   
                                 <select (change)="saveProjectEdit(formdata.manageEntityCountry, 'manageEntityCountry')" name="manageEntityCountry" [(ngModel)]="formdata.manageEntityCountry">
                                     <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                 </select>
                                 <i class="fa fa-caret-down" aria-hidden="true"></i>
                                 <span class="highlight"></span>
                                 <span class="bar"></span>
                             </div>
                         </td>
                     </tr>
                </tbody>
            </table>
        </div>
        
        <span class="fw-600 fontSize20">Enter Project Location</span>
        <div class="desktop_flex">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="4" class="pt20">
                            <div class="group" [ngClass]="formdata_error.street.error ? 'has-error' : ''">      

                                <input maxlength="60" [(ngModel)]="formdata.street" (focus)="checkDropdown()" (input)="getPlace(formdata.street)" name="street" type="text" required>
                                <div [hidden]="(!searching_place)" class="address_loader">
                                    <svg class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>
                                </div>
                                <ul class="dropdown-menu address normal dropdown-menu-fixed address_dropdown">
                                    <li (click)="selectPlace(place)" *ngFor="let place of places" class="water_unit">
                                        <a>
                                            <span class="place_name fw-500">{{place.name}}</span> - 
                                            <span class="formatted_address">{{place.formatted_address}}</span>
                                        </a>
                                    </li>
                                </ul>

                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Address</label>
                                <span class="error">{{formdata_error.street.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="group" [ngClass]="formdata_error.city.error ? 'has-error' : ''">      
                                <input maxlength="40" (blur)="saveProjectEdit(formdata.city, 'city')" (input)="verifyField(formdata.city, 'city')" [(ngModel)]="formdata.city" name="city" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>City</label>
                                <span class="error">{{formdata_error.city.help}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="width50p" colspan="1">
                            <div class="select-group group pr20">   
                                <label>Country/Region</label>   
                                <select (change)="saveProjectEdit(formdata.country, 'country')" name="country" [(ngModel)]="formdata.country">
                                    <option *ngFor="let country of countries" [ngValue]="country.code">{{country.name}}</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="select-group group">   
                                <label>State</label>   
                                <select (change)="saveProjectEdit(formdata.state, 'state')" [(ngModel)]="formdata.state" name="state">
                                    <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                </select>
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="width50p project_zip_code group pr20" [ngClass]="formdata_error.zip_code.error ? 'has-error' : ''">      
                                <input maxlength="10" (blur)="saveProjectEdit(formdata.zip_code, 'zip_code')" (input)="verifyField(formdata.zip_code, 'zip_code')" [(ngModel)]="formdata.zip_code" name="zip_code" type="text" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Zip Code</label>
                                <span class="error">{{formdata_error.zip_code.help}}</span>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
            </table>

            <table class="show900 save_project_button">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div (click)="verifyDetails()" class="pl20 pr20 btn btn-primary" [ngClass]="!sign_agreement ? 'not-active' : ''">
                                <div *ngIf="saving_project">
                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                </div>
                                <span>{{project_detail_nxt_btn}}</span>
                            </div>
                            <p *ngIf="submit_error" class="fw-600 danger-color mt10">Please provide valid data</p>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="modal fade portfolio-modal-wbg ofac_invalid_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                
                <div class="modal-header">
                    <span class="fw-600 fs16">Owner organization is blocked</span>
                </div>
                <div class="modal-body pl25 mb20">
                    <p class="fw-400">
                        This project is located in a sanctioned country or belongs to a sanctioned entity 
                        according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                        or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                        contact us</a> for more details.
                    </p>  
                    </div>
                <div class="modal-footer">
                    <button class="floatl btn btn-primary mr10" (click)="resetOrganizationDetails()">Continue</button>
                    <button type="button" class="floatl btn btn-default" (click)="resetOrganizationDetails()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>

<modal #modal>
    <app-organization (success_data)="getDataFromModal($event)"></app-organization>
</modal>

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { alertService } from 'src/app/services/alert-service.service';
import { API } from 'src/app/services/api.service';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css']
})
export class ExportDataComponent implements OnInit {

  emptyInputs: boolean = true;
  loader: boolean = false;
  leed_id: any = '';
  email_id: any = '';
  start_date: any = '';
  end_date: any = '';

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService

  ) { }

  downloadExport() {
    var start_date = moment($('#start_date').val()).format("YYYY-MM-DD");
    var end_date =  moment($('#end_date').val()).format("YYYY-MM-DD");
    var url = "";

    if (moment(start_date, 'YYYY-MM-DD').isValid() && moment(end_date, 'YYYY-MM-DD').isValid()) {
      url = '/assets/LEED:' + this.leed_id + '/export/?email=' + this.email_id + "&start_date=" + start_date + "&end_date=" + end_date;
    }
    else {
      url = '/assets/LEED:' + this.leed_id + '/export/?email=' + this.email_id;
    }
    this.emptyInputs = true;
    this.loader = true;
    this.api.get(url).subscribe(
      data => {
        this.alertService.alert('success', 'E-Mail Sent', 5);
        this.emptyInputs = false;
        this.loader = false;
      },
      error => {
        this.alertService.alert('error', error.error, 5);
        this.emptyInputs = false;
        this.loader = false;
      }
    );
  };

  checkFields() {
    if (this.leed_id.length != 0 && this.email_id.length != 0) {
      this.emptyInputs = false;
    }
    else {
      this.emptyInputs = true;
    }

    if (this.end_date != undefined && this.start_date != undefined) {
      if (this.leed_id.length != 0 && this.email_id.length != 0) {
        this.emptyInputs = moment(this.end_date).isSameOrBefore(this.start_date);
      }
    }
  };

  ngOnInit(): void {
    (<any>$('.dateRange')).fdatepicker(
      {
        format: 'M dd, yyyy'
      });
  }

}

import { Injectable } from '@angular/core';

import { API } from './api.service';
import { AppData } from './appdata.service';

@Injectable({
  providedIn: 'root'
})
export class CreditListService
{
	constructor(
		private api: API,
		private appData: AppData,
	)
	{

	}

	getDataInputActivity(credit_short_id: string)
	{   
		return this.api.get(
			'/assets/activity/?type=credit&leed_id=' + this.appData.get('leed_id') + '&credit_short_id=' + credit_short_id
		);
	}

	getCreditActivity(credit_short_id: string, credit_id: string)
	{
		return this.api.get(
			'/assets/activity/?type=credit&leed_id=' + this.appData.get('leed_id') + '&credit_id=' + credit_id + '&credit_short_id=' + credit_short_id
		);
	}

	getFueltypes()
	{
		return this.api.get(
			'/fuel/category/?page_size=300'
		);
	}

	getDataInputActionsData(credit_id: string)
	{
		return this.api.get(
			'/assets/LEED:' + this.appData.get('leed_id') + '/actions/ID:'+credit_id+'/data/'
		);
	}

	getDataInputActionsDataByYear(credit_id: string, year: string)
	{
		return this.api.get(
			'/assets/LEED:' + this.appData.get('leed_id') + '/actions/ID:'+credit_id+'/data/'+year+'/'
		);
	}

	getMeterData(page_size: any, page_count: any, type: any)
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/meters/?page_size='+ page_size + '&page=' + page_count + '&kind='+ type
		);
	}

	getHumanMeterData()
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/meters/?kind=humanexperience'
		);
	}

	getWasteReadingsData(type: string)
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/waste/?page_size=12'
		);
	}

	getSurveyData(type: string)
	{
		let survey_path: string = '';
		if(type=="transportation")
		{
			survey_path = '/assets/LEED:'+ this.appData.get('leed_id') +'/survey/transit/summarize/';
		}
		else if(type=="human")
		{
			survey_path = '/assets/LEED:'+ this.appData.get('leed_id') +'/survey/environment/summarize/';
		}
		return this.api.get(
			survey_path
		);
	}

	getMeasureList(id: string, version: string)
	{
		let measure_list_path: string = '';
		if(this.appData.get('project_type') == "parksmart")
			measure_list_path = "../../assets/json-v2/parksmart/measure_list/"+ version +"/"+ id +".json";
		else if(this.appData.get('project_type') == "city" || this.appData.get('project_type') == "community")
			measure_list_path = "../../assets/json-v2/city_data_input/"+ version +"/"+ id +".json";
		else
			measure_list_path = "../../assets/json-v2/sites/action_list/"+ version +"/"+ id +".json";
		return this.api.getAsset(
			measure_list_path
		);
	}

	getBasePointsList(id: string, version: string)
	{
		let measure_list_path: string = "../../assets/json-v2/base_score/"+ version +"/"+ id +".json";
		return this.api.getAsset(
			measure_list_path
		);
	}

	getBaseData(measure_id: string)
	{
		let measure_response: string = "/assets/LEED:" + this.appData.get('leed_id') + "/measures/"+ measure_id +"/";
		return this.api.get(
			measure_response
		);
	}
}

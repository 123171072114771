<div class="modal-body"> 
    <div class="row p20 fw-400">
        <p> 
            <b class="fw-500">Arc Administrator:</b><br>

            The Arc Administrator has complete access to the project including data entry, submitting for review, and is granted rights to add additional team members. The Arc Administrator will also be the point of contact for automatic notifications and billing information.

        </p>
        <br>
        <p> 
            <b>Note</b>: While multiple “Arc Administrators” can exist on a project team, we strongly recommend that you designate only one person in this role to avoid confusion and so that a single point of contact is receiving notifications about billing.
        </p>
        <br>
        <p [hidden]="appData.get('project_type') == 'parksmart'"> 
            <b class="fw-500">Primary Point of Contact:</b><br>

            The Primary Point of Contact is the single point of contact for review correspondence (i.e. preliminary review report, mid review clarification requests, and final review reports).  Any Arc team member may be designated as the Primary Point of Contact. The Arc Administrator may change the Primary Point of Contact once identified.
        </p>
        <br>
        <p [hidden]="appData.get('project_type') == 'parksmart'"> 
            <b class="fw-500">Team Manager:</b><br>

            A Team Manager has access to the project including data entry and submitting for review, and can make changes to the project team.
        </p>
        <br>
        <p> 
            <b class="fw-500">Team Member:</b><br>

            A Team Member has access to the project including data entry and submitting for review, but cannot make any changes to the project team.
        </p>
        <br>
        <p [hidden]="appData.get('project_type') == 'parksmart'"> 
            <b class="fw-500">Project Administrator:</b><br>

            A Project Administrator is the original LEED Project Administrator and will only appear on the project team for LEED projects that were previously or are currently using LEED Online.
        </p>
        <br>
        <p [hidden]="appData.get('project_type') == 'parksmart'"> 
            <b class="fw-500">Read Only:</b><br>

            Read Only members can access the project to view data but cannot make any changes to the project, data entry or the project team.
        </p>

    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit
{
	@ViewChild('modal') private modal!: ModalComponent
	config = Config;

	loading = true;
	review_data_loading = false;

	point_of_contact_options: any = [];
	cert_point_of_contact: any = "";
	cert_point_of_contact_id: any = "";

	isUserProjectAdmin: boolean = false;
	leed_id: number = this.appData.get('leed_id');
	orderByField = 'Firstname';
	reverseSort = false;
	team_members: any = [];
	auth_levels: any = [];
	requests: any = [];
	add_team_flag = false;
	add_team_msz = "Invite";
	error_add_team_flag = false;
	remove_team_flag = false;
	remove_team_msz = "Get Started";
	error_remove_team_flag = false;
	selected: string | undefined = undefined;
	auth_level = "";
	selected_index: any = undefined;
	save_role_flag = false;
	save_role_msz = "Save";
	role = "";
	dtReadingOptions = {
		paging: false,
		"order": [
			[0, 'desc']
		],
		"aoColumnDefs": [
		{
			'bSortable': false,
			'aTargets': [2, 3]
		}]
	};

	email_form = {
		'new_email': ''
	};
	remove_email_form = {
		'new_email': ''
	};
	access_team = 'R';
	savingPermission = false;
	savingPermissionAccept = false;
	savingPermissionDenied = false;
	is_school_trial = false;

	error_add_team_msz: string = '';

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		if (this.appData.get('buildingData').is_trial_selected && this.appData.get('buildingData').project_type == 'school')
        {
            this.is_school_trial = true;
        }
        this.getTeamRoles();
        this.getRequest();
	}

	toCamel(str: string)
	{
		return str.replace(/\w\S*/g, function(txt)
		{
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

	getTeam()
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/teams/'
		).subscribe(
			//success handler
			(data: any) =>
			{
				this.loading = false;
				this.appData.set("team_members", data.EtTeamMembers);
				this.selected = undefined;
				this.save_role_flag = false;
				this.save_role_msz = "Save";

				if (this.is_school_trial)
				{
					this.team_members = data.EtTeamMembers;
					this.access_team = 'CRUD';
					return;
				}

				if (!data.EtTeamMembers.length)
				{
					setTimeout(function()
					{
						$('.dataTables_empty').css('display', 'table-cell');
					}, 500);
					return false;
				}
				this.team_members = [];
				this.point_of_contact_options = [];

				for (var i = 0; i < data.EtTeamMembers.length; i++)
				{
					if (data.EtTeamMembers[i].Useralias == this.appData.get('user_id').substring(0, 40))
					{
						if (data.EtTeamMembers[i].Roleid == 'ZRPO80' || data.EtTeamMembers[i].Roleid == 'ZRPO81')
						{
							data.EUserRole = data.EtTeamMembers[i].Roledescription;
							break;
						}
					}
				}

				for (var i = 0; i < data.EtTeamMembers.length; i++)
				{
					if (data.EtTeamMembers[i].Roleid == "ZRPO80" || data.EtTeamMembers[i].Roleid == "ZRPO81" || data.EtTeamMembers[i].Roleid == "ARC01")
					{
						this.team_members.push(data.EtTeamMembers[i]);
					}

					if (data.EtTeamMembers[i].Roleid == "ZRPO80" || data.EtTeamMembers[i].Roleid == "ZRPO81")
					{
						this.point_of_contact_options.push(data.EtTeamMembers[i]);
					}

				}

				if (data.EUserRole.toLowerCase() == 'project team member' || data.EUserRole.toLowerCase() == 'project team manager' || data.EUserRole.toLowerCase() == 'arc project team member' || data.EUserRole.toLowerCase() == '' || data.EUserRole.toLowerCase() == 'arc project read only' || data.EUserRole.toLowerCase() == 'gbci reviewer')
				{
					this.access_team = 'R';
				}

				else if (data.EUserRole.toLowerCase() == 'project admin')
				{
					for (var i = 0; i < data.EtTeamMembers.length; i++)
					{
						if (data.EtTeamMembers[i].Useralias == this.appData.get('user_id'))
						{
							if(data.EtTeamMembers[i].Roleid == 'ZRPO80')
							{
								this.access_team = 'CRUD';
							}
							else
							{
								this.access_team = 'R';
							}
						}
					}
				}

				else
				{
					this.access_team = 'CRUD';
				}

				this.getReviewDetails();
			},
			//error handler
			data =>
			{
				this.loading = false;
				this.access_team = 'R';
				if (data.status == 403)
				{
					this.global.noAccessModal();
				}
			}
		);
	};

	removeByAttr(arr: any, attr: any, value: any)
	{
		var i = arr.length;
		while (i--)
		{
			if (arr[i] &&
				arr[i].hasOwnProperty(attr) &&
				(arguments.length > 2 && arr[i][attr] === value))
			{

				arr.splice(i, 1);
			}
		}
		return arr;
	};

	getTeamRoles()
	{
		this.api.get(
			'/assets/LEED:' + this.leed_id + '/teams/roles/'
		).subscribe(
			//success handler
			data =>
			{
				if (this.appData.get('project_type') == 'parksmart')
				{
					this.auth_levels = [
					{
						Rtitl: "Arc Administrator",
						Reltyp: "ZRPO80"
					},
					{
						Rtitl: "Team Member",
						Reltyp: "ZRPO81"
					}];
				}
				else
				{
					this.auth_levels = [
					{
						Rtitl: "Arc Administrator",
						Reltyp: "ZRPO80"
					},
					{
						Rtitl: "Team Member",
						Reltyp: "ZRPO81"
					},
					{
						Rtitl: "Read Only",
						Reltyp: "ARC01"
					}];
				}
			},
			//error handler
			data => {}
		);
	};

	getReviewDetails()
	{
		this.api.get(
			'/assets/LEED:' + this.leed_id + '/review/'
		).subscribe(
			//success handler
			data =>
			{
				if (data.review_data != undefined) {
					this.cert_point_of_contact = data.review_data[0].point_of_contact;
					this.cert_point_of_contact_id = data.review_data[0].id;
					$('#cert_point_of_contact').val(this.cert_point_of_contact);
				}
			},
			//error handler
			data => {}
		);
	};

	updatePointOfContact(email: any)
	{
		this.loading = true;
		let payload = {'point_of_contact': email.toLowerCase()};
		this.api.put('/assets/LEED:' + this.leed_id + '/reviewdata/ID:' + this.cert_point_of_contact_id + '/', payload).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
			},
			//error handler
			data => {
				this.loading = false;
			}
		);
	};

	addMemberToTeam()
	{

		var acr_admin_email = "";
		var acr_admin_count = 0;

		if (this.email_form.new_email.length == 0)
		{
			this.add_team_flag = false;
			this.add_team_msz = "Invite";
			this.error_add_team_flag = true;
			this.error_add_team_msz = "This field cannot be blank.";
			return;
		}

		for (var i = 0; i < this.team_members.length; i++)
		{
			if (this.team_members[i].Roleid == "ZRPO80")
			{
				acr_admin_count += 1;
				acr_admin_email = this.team_members[i].email;
			}
		}

		if ((acr_admin_count == 1) && acr_admin_email == this.email_form.new_email.trim())
		{
			this.add_team_flag = false;
			this.add_team_msz = "Invite";
			this.error_add_team_flag = true;
			this.error_add_team_msz = "Project should have at least one Arc Project Admin.";
			return;
		}

		for (var i = 0; i < this.team_members.length; i++)
		{
			if (this.email_form.new_email.trim().toLowerCase() == this.team_members[i].Useralias)
			{
				this.add_team_flag = false;
				this.add_team_msz = "Invite";
				this.error_add_team_flag = true;
				this.error_add_team_msz = "This ID is already added to the team.";
				return;
			}
		}

		this.add_team_flag = true;
		this.add_team_msz = "Adding...";
		var data = {
			"user_email": this.email_form.new_email,
			"Reltyp": "ZRPO81"
		};
		this.api.post('/assets/LEED:' + this.leed_id + '/teams/', data).subscribe(
			//success handler
			response =>
			{
				this.add_team_flag = false;
				this.add_team_msz = "Invite";
				this.error_add_team_flag = false;
				this.email_form.new_email = "";
				this.getTeam();
				this.alertService.alert('success', 'Team member added successfully.', 5);
				this.global.getActionList(true);
			},
			//error handler
			response =>
			{
				var data = response;
				this.add_team_flag = false;
				this.add_team_msz = "Invite";
				this.error_add_team_flag = true;
				try
				{
					this.error_add_team_msz = data[0];
				}
				catch (e)
				{
					this.error_add_team_msz = "Please try again.";
				}
			}
		);
	};

	clearAll()
	{
		this.error_add_team_flag = false;
	};

	clearAllRemove()
	{
		this.error_remove_team_flag = false;
	};

	editRole(team_role: string)
	{
		this.selected = team_role;
	};

	makeEditable(team_role: string)
	{
		return this.selected === team_role;
	};

	saveRole(team_role: any, index: any)
	{
		if(team_role.Responsibility == 'D')
		{
			this.auth_level = 'None';
		}
		else if(team_role.Responsibility != 'D')
		{
			this.auth_level = team_role.Roleid;
		}

		var acr_admin_email = "";
		var acr_admin_count = 0;
		for (var i = 0; i < this.team_members.length; i++)
		{
			if (this.team_members[i].Roleid == "ZRPO80" && this.team_members[i].Responsibility != "D")
			{
				acr_admin_count += 1;
				acr_admin_email = this.team_members[i].email;
			}

			if (team_role.Useralias == this.team_members[i].Useralias)
			{
				index = i;
			}
		}

		if ((acr_admin_count == 1) && acr_admin_email == team_role.Useralias.trim())
		{
			this.save_role_flag = false;
			this.save_role_msz = "Save";
			this.getTeam();
			this.alertService.alert('error', 'Project should have at least one Arc Administrator.', 5);
			return;
		}

		this.save_role_flag = true;
		this.save_role_msz = "Saving...";
		this.selected_index = index;

		let data: any;
		if (this.team_members[index].Responsibility == "D" && this.auth_level == this.team_members[index].Roleid)
		{
			data = {
				"user_email": team_role.Useralias,
				"Reltyp": this.auth_level,
				"Responsibility": this.team_members[index].Responsibility
			};
		}
		else
		{
			data = {
				"user_email": team_role.Useralias,
				"Reltyp": this.auth_level,
			};
		}

		if (this.auth_level == "")
		{
			this.save_role_flag = false;
			this.save_role_msz = "Save";
			return false;
		}

		if (this.auth_level == 'None')
		{
			let payload = 
			{
				"user_email": team_role.Useralias,
				"Reltyp": this.team_members[index].Roleid,
				"Responsibility": String(this.leed_id)
			};

			this.api.delete('/assets/LEED:' + this.leed_id + '/teams/update/', payload).subscribe(
				//success handler
				response =>
				{
					this.getTeam();
					this.alertService.alert('success', 'Team member removed successfully.', 5);
					this.global.getActionList(true);
				},
				//error handler
				response =>
				{
					var data = response;
					this.save_role_flag = false;
					this.save_role_msz = "Save";
					if("detail" in data.error)
					{
						this.alertService.alert('error', data.error.detail, 5);
					}
					else if (data.error[0].Type == "E")
					{
						this.alertService.alert('error', data.error[0].message, 5);
					}
					else
					{
						this.alertService.alert('error', 'Please try again.', 5);
					}
				}
			);
		}
		else
		{
			this.api.put('/assets/LEED:' + this.leed_id + '/teams/update/', data).subscribe(
				//success handler
				response =>
				{
					this.getTeam();
					this.alertService.alert('success', 'Team member updated successfully.', 5);
					this.global.getActionList(true);
				},
				//error handler
				response =>
				{
					this.getTeam();
					var data = response;
					this.save_role_flag = false;
					this.save_role_msz = "Save";
					if("detail" in data.error)
					{
						this.alertService.alert('error', data.error.detail, 5);
					}
					else if (data.error[0].Type == "E")
					{
						this.alertService.alert('error', data.error[0].message, 5);
					}
					else
					{
						this.alertService.alert('error', 'Please try again.', 5);
					}
				}
			);
		}
	};

	updateAuthLevel(val: any)
	{
		this.auth_level = val;
	};

	getRequest()
	{
		this.api.get('/assets/LEED:' + this.leed_id + '/permissions/requests/').subscribe(
			//success handler
			response =>
			{
				var data = response;
				this.savingPermission = false;
				this.savingPermissionDenied = false;
				this.savingPermissionAccept = false;
				this.requests = data;
				this.getTeam();
			},
			//error handler
			response => {
				this.getTeam();
			}
		);
	}

	approveRequest(request: any, elem: any)
	{
		this.savingPermission = true;
		elem.savingPermissionAccept = true;
		var data = {
			"user_name": request.user_name,
			"is_granted": "True"
		}
		this.api.put('/assets/LEED:' + this.leed_id + '/permissions/requests/', data).subscribe(
			//success handler
			response =>
			{
				this.getRequest();
			},
			//error handler
			response => {}
		);
	}

	denyRequest(request: any, elem: any)
	{
		this.savingPermission = true;
		elem.savingPermissionDenied = true;
		var data = {
			"user_name": request.user_name,
			"is_granted": "False"
		}
		this.api.put('/assets/LEED:' + this.leed_id + '/permissions/requests/', data).subscribe(
			//success handler
			response =>
			{
				this.getRequest();
			},
			//error handler
			response => {}
		);
	};

	getInitials(string: string)
	{
		var names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();
		if (names.length > 1)
		{
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	};

	addUser(team_role: any)
	{
		var data = {
			"user_email": team_role.Useralias,
			"Reltyp": 'ZRPO81',
			"Responsibility": team_role.Responsibility
		};
		this.loading = true;
		this.api.put('/assets/LEED:' + this.leed_id + '/teams/update/', data).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.add_team_flag = false;
				this.add_team_msz = "Invite";
				this.getTeam();
				this.alertService.alert('success', 'Team member added successfully.', 5);
				this.global.getActionList(true);
				this.email_form.new_email = "";
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.add_team_flag = false;
				this.add_team_msz = "Invite";
				var data = response;
				this.save_role_flag = false;
				this.save_role_msz = "Save";
				if("detail" in data.error)
				{
					this.alertService.alert('error', data.error.detail, 5);
				}
				else if (data.error[0].Type == "E")
				{
					this.alertService.alert('error', data.error[0].message, 5);
				}
				else
				{
					this.alertService.alert('error', 'Please try again.', 5);
				}
			}
		);
	};

	removeUser(team_role: any)
	{
		var acr_admin_email = "";
		var acr_admin_count = 0;
		for (var i = 0; i < this.team_members.length; i++)
		{
			if (this.team_members[i].Roleid == "ZRPO80" && this.team_members[i].Responsibility != "D")
			{
				acr_admin_count += 1;
				acr_admin_email = this.team_members[i].email;
			}
		}

		if ((acr_admin_count == 1) && acr_admin_email == team_role.Useralias.trim())
		{
			this.save_role_flag = false;
			this.save_role_msz = "Save";
			this.getTeam();
			this.alertService.alert('error', 'Project should have at least one Arc Administrator.', 5);
			return;
		}

		let payload = 
		{
			"user_email": team_role.Useralias,
			"Reltyp": team_role.Roleid,
			"Responsibility": String(this.leed_id)
		};

		this.loading = true;
		this.api.delete('/assets/LEED:' + this.leed_id + '/teams/update/', payload).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.getTeam();
				this.alertService.alert('success', 'Team member removed successfully.', 5);
				this.global.getActionList(true);
			},
			//error handler
			response =>
			{
				this.loading = false;
				var data = response;
				this.save_role_flag = false;
				this.save_role_msz = "Save";
				if("detail" in data.error)
				{
					this.alertService.alert('error', data.error.detail, 5);
				}
				else if (data.error[0].Type == "E")
				{
					this.alertService.alert('error', data.error[0].message, 5);
				}
				else
				{
					this.alertService.alert('error', 'Please try again.', 5);
				}
			}
		);
	};

	cancel()
	{
		this.modal.close();
		this.getTeam();
	}

	manageMembers()
	{
		this.modal.open({ windowClass: 'certificate_modal manage_team_modal', size: 'lg' });
	};

}

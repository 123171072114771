<div class="content-wrap">
	<div id="content" class="content">
        <main class="view-animate fade-up" role="main" data-ui-view="">
            <div class="row">
                <main class="view-animate fade-up full-screen-row" role="main" data-ui-view="" style="margin-top: -55px;background-color: #F4F5F8;">
                    <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                        <div class="align-center mt11 width-100-perc">
                            <h4 class="fw-500">Payment</h4>
                        </div>
                        <div class="pt20" (click)="goBack()">
                            <svg class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </div>
                    </nav>
                    <div class="row pb100">
                        <div class="mt15 col-md-12" [hidden]="!canada_payment">
                            <div class="payment-card mb0 fs15">
                                <div class="mb20">
                                    The Canada Green Building Council (CAGBC) administers LEED certification in Canada. The pricing for projects located in Canada can be found on <a href="https://www.cagbc.org/" target="_blank">www.cagbc.org</a>. Complete the following information for the project you would like to invoice for certification fees. CAGBC will provide you with an invoice by email. If you have a question, or you are not contacted in the next 5 business days, please email <a href = "mailto:LEEDcertification@cagbc.org">LEEDcertification@cagbc.org</a>.
                                </div>
                                <div>
                                    Le Conseil du bâtiment durable du Canada (CBDCA) administre la certification LEED au Canada.Les frais pour les projets situés au Canada peuvent être trouvés sur <a href="https://www.cagbc.org/" target="_blank">www.cagbc.org</a>. Remplissez les informations suivantes pour le projet que vous souhaitez facturer pour les frais de certification.Le CBDCA vous fournira une facture par courriel.Si vous avez une question ou si vous n'êtes pas contacté dans les 5 prochains jours ouvrables, veuillez envoyer un courriel à <a href = "mailto:LEEDcertification@cagbc.org">LEEDcertification@cagbc.org</a>.
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="canada_payment ? 'mt15 col-md-12' : 'mt25 col-md-8'">
                            <div [ngClass]="isPaymentFormSubmitted || refreshing_price ? 'not-active' : ''">
                                <div class="mb25">
                                    <div class="payment-card">
                                        <div id="details-form">
                                            <table class="width-100-perc">
                                                <thead>
                                                    <tr>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-margin-20">
                                                    <tr>
                                                        <td colspan="1">
                                                            <div class="">
                                                                <span class="grey fontSize16 fw600">Billing</span>
                                                            </div>
                                                        </td>
                                                        <td colspan="3">
                                                            <div class="left-right-align">
                                                                <span class="fontSize16 fw-400" *ngIf="!canada_payment">Edit Billing Details</span>
                                                                <span class="fontSize16 fw-400" *ngIf="canada_payment">Bill to/facturer à</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td class="pt30 w40p" colspan="1">
                                                            <div class="group pr20" [ngClass]="paymentdata_error.first_name.error ? 'has-error' : ''">      
                                                                <input maxlength="20" (input)="verifyField(paymentdata.first_name, 'first_name')" [(ngModel)]="paymentdata.first_name" name="first_name" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">First Name</label>
                                                                <label *ngIf="canada_payment">First Name/prénom</label>
                                                                <span class="error">{{paymentdata_error.first_name.help}}</span>
                                                            </div>
                                                        </td>
                                                        <td class="pt30" colspan="2">
                                                            <div class="group" [ngClass]="paymentdata_error.last_name.error ? 'has-error' : ''">      
                                                                <input maxlength="19" (input)="verifyField(paymentdata.last_name, 'last_name')" [(ngModel)]="paymentdata.last_name" name="last_name" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">Last Name</label>
                                                                <label *ngIf="canada_payment">Last Name/et nom</label>
                                                                <span class="error">{{paymentdata_error.last_name.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group" [ngClass]="paymentdata_error.email.error ? 'has-error' : ''">      
                                                                <input maxlength="241" (input)="verifyField(paymentdata.email, 'email')" [(ngModel)]="paymentdata.email" name="email" type="text" required maxlength="241">
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">Bill-To-Party Email</label>
                                                                <label *ngIf="canada_payment">Email/courriel</label>
                                                                <span class="error">{{paymentdata_error.email.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group" [ngClass]="paymentdata_error.street.error ? 'has-error' : ''">      
                                                                <input class="address_dropdown" maxlength="60" (focus)="checkDropdown()" (input)="getPlace(paymentdata.street)" [(ngModel)]="paymentdata.street" name="street" type="text" required>
                                                                <div class="address_loader">
                                                                    <svg *ngIf="searching_place" class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>
                                                                </div>
                                                                <ul class="dropdown-menu address normal dropdown-menu-fixed address_dropdown">
                                                                    <li (click)="selectPlace(place)" *ngFor="let place of places" class="water_unit">
                                                                        <a>
                                                                            <span class="place_name fw-500">{{place.name}}</span> - 
                                                                            <span class="formatted_address">{{place.formatted_address}}</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">Address</label>
                                                                <label *ngIf="canada_payment">Address/adresse</label>
                                                                <span class="error">{{paymentdata_error.street.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group" [ngClass]="paymentdata_error.city.error ? 'has-error' : ''">      
                                                                <input maxlength="40" (input)="verifyField(paymentdata.city, 'city')" [(ngModel)]="paymentdata.city" name="city" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">City</label>
                                                                <label *ngIf="canada_payment">City/ville</label>
                                                                <span class="error">{{paymentdata_error.city.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group" [ngClass]="paymentdata_error.phone.error ? 'has-error' : ''">      
                                                                <input maxlength="40" (input)="verifyField(paymentdata.phone, 'phone')" [(ngModel)]="paymentdata.phone" name="phone" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">Phone Number</label>
                                                                <label *ngIf="canada_payment">Phone Number</label>
                                                                <span class="error">{{paymentdata_error.phone.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="1">
                                                            <div class="select-group group pr20">   
                                                                <label *ngIf="!canada_payment">Country/Region</label> 
                                                                <label *ngIf="canada_payment">Country/Region/pays</label> 
                                                                <select autocomplete="false" name="country" (change)="updateCountry(paymentdata.country)" [(ngModel)]="paymentdata.country">
                                                                    <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                                                </select>
                                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                            </div>
                                                        </td>
                                                        <td colspan="2">
                                                            <div class="select-group group">   
                                                                <label *ngIf="!canada_payment">State</label>
                                                                <label *ngIf="canada_payment">Province/province</label>
                                                                <select autocomplete="false" [(ngModel)]="paymentdata.state" name="state" (change)="updateState()">
                                                                    <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                                                </select>
                                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group pr20" [ngClass]="paymentdata_error.zip_code.error ? 'has-error' : ''">      
                                                                <input maxlength="40" (input)="verifyField(paymentdata.zip_code, 'zip_code')" [(ngModel)]="paymentdata.zip_code" name="zip_code" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label *ngIf="!canada_payment">Zip Code</label>
                                                                <label *ngIf="canada_payment">Postal code/code postal</label>
                                                                <span class="error">{{paymentdata_error.zip_code.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="appData.get('buildingData').country == 'IN'">
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div class="group pr20" [ngClass]="paymentdata_error.gstin.error ? 'has-error' : ''">      
                                                                <input maxlength="15" (blur)="getPrice()" (input)="verifyField(paymentdata.gstin, 'gstin', true)" [(ngModel)]="paymentdata.gstin" name="gstin" type="text" required>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label>GSTIN (Optional)</label>
                                                                <span class="error">{{paymentdata_error.gstin.help}}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="canada_payment">
                                                        <td></td>
                                                        <td colspan="3">
                                                            <div [ngClass]="disablePayButton || isPaymentFormSubmitted ? 'not-active' : ''" (click)="submitPayment()" class="pl20 pr20 btn btn-primary">
                                                                <div *ngIf="isPaymentFormSubmitted">
                                                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                                </div>
                                                                {{canada_payment_nxt_btn}}
                                                            </div>
                                                            <p *ngIf="disablePayButton" class="fw-600 danger-color mt10">Please provide valid data</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div [hidden]="canada_payment">
                                    <div class="payment-card pb0">
                                        <div id="details-form">
                                            <table class="width-100-perc">
                                                <thead>
                                                    <tr>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                        <th class="w20p"></th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-margin-20">
                                                    <tr>
                                                        <td colspan="1">
                                                            <div class="">
                                                                <span class="grey fw600 fontSize16">Payment</span>
                                                            </div>
                                                        </td>
                                                        <td colspan="3">
                                                            <div class="left-right-align">
                                                                <div *ngIf="appData.get('buildingData').country != 'IN'" class="payment_mode">
                                                                    <div [ngClass]="payment_mode == 'cc' ? 'active' : ''" (click)="togglePaymentMode('cc')" class="payment_mode_option">Pay Now</div>
                                                                    <div [ngClass]="payment_mode == 'check' ? 'active' : ''" (click)="togglePaymentMode('check')" class="payment_mode_option">Pay by Check or Wire</div>
                                                                </div>
                                                                <div *ngIf="appData.get('buildingData').country == 'IN'" >
                                                                    Pay by Check or Wire
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr [hidden]="payment_mode != 'cc'">
                                                        <td></td>
                                                        <td colspan="2">
                                                            <div>
                                                                <span class="pt20 pb20 left-right-align pb10 fontSize16 fw-400">Enter credit card information</span>
                                                            </div>
                                                            <div class="group fs-block" [ngClass]="paymentdata_error.CC_number.error ? 'has-error' : ''">      
                                                                <input maxlength="19" (input)="verifyField(paymentdata.CC_number, 'CC_number')" [(ngModel)]="paymentdata.CC_number" name="CC_number" type="text" required class="fs-block">
                                                                <img *ngIf="getCardType(paymentdata.CC_number) == 'Mastercard'" src="../../../../assets/images/mastercard.png" class="card_img mastercard"/>
                                                                <img *ngIf="getCardType(paymentdata.CC_number) == 'Visa'" src="../../../../assets/images/visa.png" class="card_img visa"/>
                                                                <img *ngIf="getCardType(paymentdata.CC_number) == 'American Express'" src="../../../../assets/images/amex.png" class="card_img amex"/>
                                                                <img *ngIf="getCardType(paymentdata.CC_number) == 'Discover'" src="../../../../assets/images/discover.png" class="card_img discover"/>
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label>Card Number</label>
                                                                <span class="error">{{paymentdata_error.CC_number.help}}</span>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr [hidden]="payment_mode != 'cc'">
                                                        <td></td>
                                                        <td>
                                                            <div class="group pr20 fs-block" [ngClass]="paymentdata_error.CC_expiry.error ? 'has-error' : ''">      
                                                                <input maxlength="7" (input)="verifyField(paymentdata.CC_expiry, 'CC_expiry')" [(ngModel)]="paymentdata.CC_expiry" name="CC_expiry" type="text" (blur)="validateCardExpiry(paymentdata.CC_expiry)" required class="fs-block">
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label>Expiry (mm/yyyy)</label>
                                                                <span class="error">{{paymentdata_error.CC_expiry.help}}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="group fs-block" [ngClass]="paymentdata_error.CC_CVV.error ? 'has-error' : ''">      
                                                                <input maxlength="4" (input)="verifyField(paymentdata.CC_CVV, 'CC_CVV')" [(ngModel)]="paymentdata.CC_CVV" name="CC_CVV" type="text" required class="fs-block">
                                                                <span class="highlight"></span>
                                                                <span class="bar"></span>
                                                                <label>CVV</label>
                                                                <span class="error">{{paymentdata_error.CC_CVV.help}}</span>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr [hidden]="payment_mode != 'check'">
                                                        <td></td>
                                                        <td colspan="3" class="pt30">
                                                            <p>
                                                                <span *ngIf="appData.get('buildingData').country == 'IN'" class="payByCheckIns mt20 mb20 fs16"><span class="bold">NOTE: </span><span>Taxes display in pricing section are based on the above billing address.</span></span>
                                                            </p>
                                                            <span class="card-header fs16 fw-bold">Instructions</span>
                                                            <div class="payByCheckIns mt20">
                                                                <p>
                                                                    Upon completing the billing form above and clicking Submit Payment, an invoice will be generated and emailed to your email address.
                                                                </p>
                                                                <p *ngIf="appData.get('buildingData').country != 'IN'">
                                                                    Make checks payable to <span class="fw-bold">Green Business Certification Inc.</span> Please include the invoice number on the payment to ensure prompt processing. Due to our current teleworking status, all mail sent to our physical locations, is not being monitored. In order to avoid any service or product delays, please remit all payments by check to the Bank Lockbox info provided.
                                                                </p>
                                                                <p *ngIf="appData.get('buildingData').country != 'IN'">
                                                                    PNC Bank Lockbox Info:<br>
                                                                    Green Business Certification Inc.<br>
                                                                    P.O. Box 822964<br>
                                                                    Philadelphia, PA, 19182-2964<br>
                                                                    Telephone 1.800.795.1747
                                                                </p>
                                                                <p *ngIf="appData.get('buildingData').country == 'IN'">
                                                                    Mail the invoice and check to the following address:
                                                                </p>
                                                                <p *ngIf="appData.get('buildingData').country == 'IN'">
                                                                    Green Business Certification Institute Pvt. Ltd<br>
                                                                    10th Floor, Unit No 1006 and 1007, C-1, WTT Plot,<br>
                                                                    Sector 16, Noida, Gautam Buddha Nagar, Uttar Pradesh 201301
                                                                </p>
                                                                <p class="fw-bold">
                                                                    To avoid delays in processing, please write the invoice number on the check.
                                                                </p>
                                                                <p>
                                                                    Once GBCI receives the check, <span class="fw-bold">please allow 2-3 business days for processing.</span>
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <div class="payment-card pt0" id="details-form">
                                        <table class="width-100-perc promo_code_table">
                                            <thead>
                                                <tr>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span class="grey fw600 fontSize16">Promotional Code</span>
                                                    </td>
                                                    <td class="flex">
                                                        <div class="group pb0" [ngClass]="invalid_promo_code ? 'has-error': ''">
                                                            <input maxlength="35" (blur)="checkPromocode()" [(ngModel)]="paymentdata.promocode" name="promocode" type="text" required>
                                                            <span class="highlight"></span>
                                                            <span class="bar"></span>
                                                            <label>Enter Code</label>
                                                            <span class="error">Invalid Promocode</span>
                                                        </div>
                                                        <div *ngIf="promocode_applied || invalid_promo_code" (click)="removePromocode()">
                                                            <div data-tooltip-placement="top" data-tooltip="Remove Promotional Code" class="remove_promo cursor-pointer far fa-times-circle"></div>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <div class="payment-card" id="details-form">
                                        <table class="width-100-perc align-right">
                                            <thead>
                                                <tr>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                    <th class="w20p"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="fontSize16">
                                                        <span class="grey fw-400">USGBC Member</span>
                                                    </td>
                                                    <td class="fontSize16">
                                                        <span class="fw-400">{{project_price.usgbc_member == 'N' ? 'No' : 'Yes'}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="grey fw-400">{{promocode_applied || (taxes != false && !paymentdata.is_sez) ? 'Subtotal' : 'Total'}}</span>
                                                    </td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.price, 2))}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="taxes != false && !paymentdata.is_sez">
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="grey fw-400">Estimated Tax</span>
                                                    </td>
                                                    <td class="pt10">
                                                        <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes.price, 2))}}</span>
                                                        <span class="fw-400" *ngIf="only_IGST()"> (18% IGST)</span>
                                                        <span class="fw-400" *ngIf="!only_IGST()"> (9% CGST)</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="taxes_s != false && !paymentdata.is_sez">
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                    </td>
                                                    <td class="pt10">
                                                        <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes_s.price, 2))}}</span>
                                                        <span class="fw-400"> (9% SGST)</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="promocode_applied && !invalid_promo_code">
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="grey fw-400">Discount</span>
                                                    </td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="fw-600"><span style="color: #dd5826;">-  </span>{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.price - promocode_price.price, 2))}}</span>
                                    
                                                        <span class="grey fontSize13 cursor-pointer pl5" (click)="removePromocode()">
                                                            <u>Remove</u>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="taxes != false && !promocode_applied && !paymentdata.is_sez">
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="grey fw-400">Total</span>
                                                    </td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([selected_price.price, taxes.price, taxes_s.price]), 2))}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="promocode_applied">
                                                    <td class="pt10"></td>
                                                    <td class="pt10"></td>
                                                    <td class="fontSize16 pt10">
                                                        <span class="grey fw-400">Total</span>
                                                    </td>
                                                    
                                                    <td *ngIf="!taxes" class="fontSize16 pt10">
                                                        <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(promocode_price.price, 2))}}</span>
                                                    </td>
                                                    
                                                    <td *ngIf="taxes != false" class="fontSize16 pt10">
                                                        <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([promocode_price.price, taxes.price, taxes_s.price]), 2))}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="appData.get('buildingData').country == 'IN'">
                                                    <td></td>
                                                    <td colspan="3" class="pt30">
                                                        <!-- SEZ-->
                                                        <div class="checkbox">
                                                            <input type="checkbox" class="cursor-pointer" [(ngModel)]="paymentdata.is_sez" (change)="check_sez(paymentdata.is_sez)">
                                                            <label for="checkbox">
                                                                <span>Does this project belong to Special Economic Zone (SEZ)?</span>
                                                            </label>
                                                        </div>
                                    
                                                        <div *ngIf="paymentdata.is_sez">
                                                            <div>
                                                                <div class="mb10" *ngFor="let uploaded_file of uploaded_files">
                                                                    <span (click)="deleteFile(uploaded_file)" class='icon-trash pr5 cursor-pointer'></span>
                                                                    <a (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                                                </div>                        
                                                                
                                                                <div class="mb10" [hidden]="!currentUpload">
                                                                    <span class='uploadLoaderSpinner'></span>
                                                                    <span>{{currentUploadName}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="width-100-perc">
                                                                <input #elem (input)="fileNameChanged(elem)" class="hide uploadFile2" type="file" multiple>
                                                                <div (click)="openUploadDialog('laptop', 'sez_docs/temp', 'uploadFile2')" type="energy" class="floatNone viewUpload laptop">
                                                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                                                </div>
                                                                <span>Upload supporting documents.</span>
                                                            </div>
                                                        </div>
                                                        <!-- SEZ-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <div class="gstin_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog modal-sm" style="width: 460px;">
                                            <div class="modal-content p30 modal_radius">
                                                <p class="align-center bold">{{gstin_modal_subject}}</p>
                                                <p class="align-center pt10">{{gstin_modal_body}}</p>
                                                <div class="mt30 align-center"> 
                                                    <button class="btn action-button btnGreen gray_border" data-dismiss="modal">Ok</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="sez_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog modal-md">
                                            <div class="modal-content p30 modal_radius">
                                                <p class="align-center bold">Clause X: Taxes</p>
                                                <p class="pt10" style="height: 300px;overflow-y: scroll;">
                                                    The provisions of this Clause X shall have effect, notwithstanding anything inconsistent contained in any other part of the[1] Agreement. The [Price][2] does not include Goods and Services Tax and such other taxes, cess, levies and other charges that are now or hereafter levied in relation to the matters specified herein (together “Taxes”). The Company will separately indicate such Taxes payable in the invoices, in appropriate cases, and shall raise the invoices. If the Customer has made any statement requiring the Company to deduct (i) lesser Taxes or (ii) no Taxes, the Company shall be entitled to (without being an obligation) rely on such statements of the Customer. If however, claims for Taxes, interest, penalty and other charges (including denial of credit for such Taxes paid), are subsequently made on the Company (for any reason attributable in part or whole to the Customer), the Company shall have the right to recover all such Taxes and amounts from the Customer in any manner including without limitation, by way of setting off such amounts from any other amounts due from the Customer to the Company under this Agreement or otherwise. The Customer agrees and undertakes to comply with all applicable law (including that relating to Taxes) and if due to any act, omission or negligence on the part of the Customer, the Company suffers any damage, loss, cost, expenses and other charges (together “Loss”), the Customer agrees to indemnify the Company, its directors, officers, employees and other representatives (together “Company Indemnified Persons”) for any such Loss that the Company Indemnified Persons may suffer.                     
                                                </p>
                                                
                                                <div class="mt30 align-center">
                                                    <button class="btn btn-primary light_blue_btn mr20" data-dismiss="modal" (click)="set_sez(true)">I Agree</button>
                                                    <button class="btn action-button btnGreen" data-dismiss="modal" (click)="set_sez(false)">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="canada_payment" class="payment-card mt25 col-md-4 sticky_div">
                            <p class="access fw-500">DETAILS</p>
                            <p class="access-fee-includes">
                                Certification fee includes performance based certification for three years.
                            </p>
                            <p class="access-fee-includes">
                                <a (click)="goToCertFee()">View LEED Registration and Certification Fees</a>
                            </p>
                            <p class="border-bottom mt15 mb15"></p>
                            <p>How do I claim the Silver level or higher member discount on LEED project registration and certification fees? <a href="https://support.usgbc.org/hc/en-us/articles/4417269211411-Membership-discounts)" target="_blank">Visit the Help Center</a></p>
                            <p class="border-bottom mt15 mb15"></p>
                            <div id="details-form">
                                <!-- <app-loader *ngIf="recompute_data_lock || recompute_loading"></app-loader> -->
                                <table class="width-100-perc">
                                    <thead>
                                        <tr>
                                            <th class="w50p"></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group pb25" [ngClass]="invalid_promo_code ? 'has-error': ''">
                                                    <input maxlength="35" (blur)="checkPromocode()" [(ngModel)]="paymentdata.promocode" name="promocode" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Enter Promotional Code</label>
                                                    <span class="error">Invalid Promocode</span>
                                                </div>
                                            </td>
                                            <td class="vertical_align_top">
                                                <div *ngIf="promocode_applied || invalid_promo_code" (click)="removePromocode()">
                                                    <div data-tooltip-placement="top" data-tooltip="Remove Promotional Code" class="remove_promo cursor-pointer far fa-times-circle"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fontSize16">
                                                <span class="grey fw-400">USGBC Member</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td class="fontSize16 floatr">
                                                <span class="fw-400">{{project_price.usgbc_member == 'N' ? 'No' : 'Yes'}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fontSize16 pt10">
                                                <span class="grey fw-400">{{promocode_applied || (taxes != false && !paymentdata.is_sez) ? 'Subtotal' : 'Total'}}</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td class="fontSize16 pt10 floatr">
                                                <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.price, 2))}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="taxes != false && !paymentdata.is_sez">
                                            <td class="fontSize16 pt10">
                                                <span class="grey fw-400">Estimated Tax</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td class="pt10 floatr">
                                                <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes.price, 2))}}</span>
                                                <span class="fw-400" *ngIf="only_IGST()"> (18% IGST)</span>
                                                <span class="fw-400" *ngIf="!only_IGST()"> (9% CGST)</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="taxes_s != false && !paymentdata.is_sez">
                                            <td class="fontSize16 pt10"></td>
                                            <td></td>
                                            <td></td>
                                            <td class="pt10 floatr">
                                                <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes_s.price, 2))}}</span>
                                                <span class="fw-400"> (9% SGST)</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="promocode_applied && !invalid_promo_code">
                                            <td class="fontSize16 pt10">
                                                <span class="grey fw-400">Discount</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td class="fontSize16 pt10 floatr">
                                                <span class="fw-600"><span style="color: #dd5826;">-  </span>{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.price - promocode_price.price, 2))}}</span>

                                                <span class="grey fontSize13 cursor-pointer pl5" (click)="removePromocode()">
                                                    <u>Remove</u>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="taxes != false && !promocode_applied && !paymentdata.is_sez">
                                            <td class="fontSize16 pt10">
                                                <span class="grey fw-400">Total</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td class="fontSize16 pt10 floatr">
                                                <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([selected_price.price, taxes.price, taxes_s.price]), 2))}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="promocode_applied">
                                            <td class="fontSize16 pt10">
                                                <span class="grey fw-400">Total</span>
                                            </td>
                                            <td></td>
                                            <td *ngIf="!taxes" class="fontSize16 pt10" colspan="2">
                                                <span class="fw-600 floatr">{{payment_currency}}{{global.addCommas(global.formatNumber(promocode_price.price, 2))}}</span>
                                            </td>
                                            <td *ngIf="taxes != false" class="fontSize16 pt10" colspan="2">
                                                <span class="fw-600 floatr">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([promocode_price.price, taxes.price, taxes_s.price]), 2))}}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="appData.get('buildingData').country == 'IN'">
                                            <td colspan="4" class="pt30 align-right">
                                                <!-- SEZ-->
                                                <div class="checkbox">
                                                    <input type="checkbox" class="cursor-pointer" [(ngModel)]="paymentdata.is_sez" (change)="check_sez(paymentdata.is_sez)">
                                                    <label for="checkbox">
                                                        <span>Does this project belong to Special Economic Zone (SEZ)?</span>
                                                    </label>
                                                </div>

                                                <div *ngIf="paymentdata.is_sez">
                                                    <div>
                                                        <div class="mb10" *ngFor="let uploaded_file of uploaded_files">
                                                            <span (click)="deleteFile(uploaded_file)" class='icon-trash pr5 cursor-pointer'></span>
                                                            <a (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                                        </div>                        
                                                        
                                                        <div class="mb10" [hidden]="!currentUpload">
                                                            <span class='uploadLoaderSpinner'></span>
                                                            <span>{{currentUploadName}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="width-100-perc">
                                                        <input #elem (input)="fileNameChanged(elem)" class="hide uploadFile" type="file" multiple>
                                                        <div (click)="openUploadDialog('laptop', 'sez_docs/temp', 'uploadFile')" type="energy" class="floatNone viewUpload laptop">
                                                            <i class="fa fa-upload" aria-hidden="true"></i>
                                                        </div>
                                                        <span>Upload supporting documents.</span>
                                                    </div>
                                                </div>
                                                <!-- SEZ-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="pt30 floatr">
                                                <div [ngClass]="disablePayButton || isPaymentFormSubmitted ? 'not-active' : ''" (click)="submitPayment()" class="pl20 pr20 btn btn-primary">
                                                    <div *ngIf="isPaymentFormSubmitted">
                                                        <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                    </div>
                                                    {{project_payment_nxt_btn}}
                                                </div>
                                                <p *ngIf="disablePayButton" class="fw-600 danger-color mt10">Please provide valid data</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <!-- Paymetric form starts-->
            <iframe id="paymetric_iframe">
            </iframe>
            <!-- Paymetric form ends -->

            <div class="no_gstin_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p30 modal_radius">
                        <p class="align-center bold">Bill To Confirmation</p>
                        <p class="pt10">You have not entered GSTIN, so please confirm your following billing address to proceed.</p>
                        <div class="align-center pt10">
                            <table class="table ml10">
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">Bill-to-party name</td>
                                    <td class="align-center">{{paymentdata.name}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">Bill-to-party email</td>
                                    <td class="align-center">{{paymentdata.email}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">Address</td>
                                    <td class="align-center">{{paymentdata.street}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">City</td>
                                    <td class="align-center">{{paymentdata.city}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">State</td>
                                    <td class="align-center">{{complete_state}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">Zip Code</td>
                                    <td class="align-center">{{paymentdata.zip_code}}</td>
                                </tr>
                                <tr>
                                    <td class="vertical-align-middle text-left ff-ss-r w25p">Country/Region</td>
                                    <td class="align-center">{{complete_country}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="mt30 align-center">
                            <button class="btn btn-primary mr25" data-dismiss="modal" (click)="makePayment()">Continue<img src="assets/images/arrow_right_white.svg" class="pl5"></button>
                            <button (click)="cancelPayment()" class="btn action-button btnGreen" data-dismiss="modal" >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
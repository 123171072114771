import { Component, OnInit, Input } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { Config } from '../../models/env-vars.model';
import { API } from 'src/app/services/api.service';

import 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit
{
	@Input() width: string = '';
	@Input() height: string = '';
	@Input() src: string = '';
	@Input() param: string | null = null;
	@Input() leed_id: string | null = null;
	@Input() portfolio_id: string | null = null;
	@Input() data_url: any = null;

	@Input() START: string | null = null;
	@Input() END: string | null = null;
	@Input() START_BASELINE: string | null = null;
	@Input() END_BASELINE: string | null = null;

	loading: boolean = this.data_url != null;
	iframe_widget_src: string = '';

	constructor(
		public appData: AppData,
		public global: Global,
		private api: API
	)
	{}

	ngOnInit(): void
	{
		if(this.data_url != null && (Config.widget_data[this.data_url] == undefined && Config.widget_data['queue'].indexOf(this.data_url) == -1))
		{
			Config.widget_data['queue'].push(this.data_url);
			this.loading = true;
			let ranges: any = this.global.getSavedRanges();
			this.api.get(
				"/portfolios/ID:" + this.portfolio_id + this.data_url + ranges[0].replace("&", "?")
			).subscribe(
				data =>
				{
					Config.widget_data[this.data_url] = data;
					this.loading = false;
				}
			);
		}
	}

	getIframeSrc(widget: string, param: string | null)
	{
		let iframe_widget_src: string = '';
		if(this.leed_id == null)
		{
			iframe_widget_src = Config.basic_api_url + "/assets/overview/"+ widget +"/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		}
		if(this.portfolio_id != null)
		{
			let ranges: any = this.global.getSavedRanges();
            iframe_widget_src = Config.basic_api_url + "/portfolios/ID:"+ this.portfolio_id +"/overview/"+ widget +"/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
            iframe_widget_src += ranges[0];
			if(Config.widget_data[this.data_url] != undefined)
			{
				iframe_widget_src += '&data=' + JSON.stringify(Config.widget_data[this.data_url]);
			}
		}
		else if(this.leed_id != null)
		{
			if(widget == 'improvement')
			{
				iframe_widget_src = Config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/"+ widget +"/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
			}
			else if(widget == 'slider' || widget == 'table')
			{
				iframe_widget_src = Config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/score/" + widget + "/" + param + "/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
			}
			else if(widget == 'data-input')
			{
				iframe_widget_src = Config.basic_api_url + "/assets/LEED:" + this.leed_id + "/widget/meter/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
			}
			else
			{
				iframe_widget_src = Config.basic_api_url + "/assets/LEED:"+ this.leed_id +"/overview/"+ widget +"/?access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
			}
			
            if(widget == 'arcnotification')
            {
                iframe_widget_src += "&timezone_offset=" + new Date().getTimezoneOffset();
            }
			let ranges: any;
            if(this.START == null || this.END == null)
			{
				ranges = this.global.getSavedRanges();
				iframe_widget_src += ranges[0];
			}
			else
			{
				iframe_widget_src += "&START="+ this.START + "&END=" + this.END + "&START_BASELINE="+ this.START_BASELINE + "&END_BASELINE=" + this.END_BASELINE;
			}
		}

		if(param != undefined && (widget != 'slider' && widget != 'table'))
		{
			iframe_widget_src += param;
		}
		let language = this.appData.get('language') == null ? "en" : this.appData.get('language');
		iframe_widget_src += "&language=" + language;
		this.iframe_widget_src = iframe_widget_src;
		return iframe_widget_src;
	}

}


<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div>
            <h1 class="page-title pl0 pb20">Analysis<span class="fw-semi-bold"></span></h1>
            <section class="widget">
                <header>
                    <h5 class="mb10 pt10">Please provide details below to get the performance score analysis of the project</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset [ngClass]="loader ? 'not-active' : ''">
                            <div class="form-group mb10">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                                <ul class="wizard display-block">
                                    <li class="next list_style_none">
                                        <button (click)=getInfo() class="pull-left mr10 btn btn-default"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </li>
                                </ul>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div>* THE FOLLOWING INFORMATION IS CONFIDENTIAL AND IS NOT PERMITTED TO BE SHARED WITH NON ARC/USGBC/GBCI STAFF.</div>
            </section>
        </div>
        <div>
        
            <div class="clearfix">
                <ul id="tabs1" class="nav nav-tabs pull-left w100p">
                    <li class="active"><a data-target="#tab1" data-toggle="tab" aria-expanded="true">GHG</a>
                    </li>
                    <li class=""><a data-target="#tab2" data-toggle="tab" aria-expanded="false">Source Energy</a>
                    </li>
                    <li class=""><a data-target="#tab3" data-toggle="tab" aria-expanded="false">Water</a>
                    </li>
                    <li class=""><a data-target="#tab4" data-toggle="tab" aria-expanded="false">Waste</a>
                    </li>
                    <li class=""><a data-target="#tab5" data-toggle="tab" aria-expanded="false">Transportation </a>
                    </li>
                    <li class=""><a data-target="#tab6" data-toggle="tab" aria-expanded="false">Human Experience </a>
                    </li>
                </ul>
            </div>
            <div id="tabs1c" class="tab-content mb-lg">
                <!-- Energy Analysis -->
                <div class="tab-pane clearfix active" id="tab1">
                    <div *ngIf="!Object.keys(energyAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No GHG Data
                    </div>
                    <table *ngIf="Object.keys(energyAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(energyAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="fw-semi-bold align-left fs15" >
                                    <div *ngIf="!isValidObject(energyAnalysisInfo[key])">{{energyAnalysisInfo[key]}}</div>
                                    <div *ngIf="isValidObject(energyAnalysisInfo[key])"> </div> 
                                </td>                                                   
                            </tr>
                            <tr *ngFor="let key1 of energyAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(energyAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness Score'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer admin-analysis-tooltip" aria-hidden="true" data-tooltip-placement="top" 
                                            data-tooltip="If x > 80: Excellent, x > 60: Very Good, x > 40: Good, 
                                            x > 20: Fair, x < 20: Poor,where x = Energy Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="fw-semi-bold align-left" *ngIf="isValidObject(energyAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>                            
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Source energy -->
                <div class="tab-pane" id="tab2">
                    <div *ngIf="!Object.keys(sourceAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Source Energy Data
                    </div>
                    <table *ngIf="Object.keys(sourceAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(sourceAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="fw-semi-bold align-left fs15" >
                                    <div *ngIf="!isValidObject(sourceAnalysisInfo[key])">{{sourceAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(sourceAnalysisInfo[key])"> </div> 
                                </td>                                                   
                            </tr>
                            <tr *ngFor="let key1 of sourceAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(sourceAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer admin-analysis-tooltip" aria-hidden="true" data-tooltip-placement="top" 
                                            data-tooltip="If x > 80: Excellent, x > 60: Very Good, x > 40: Good, 
                                            x > 20: Fair, x < 20: Poor,where x = Energy Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="fw-semi-bold align-left" *ngIf="isValidObject(sourceAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>                            
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Energy Analysis -->
        
                <!-- Water Analysis -->
                <div class="tab-pane" id="tab3">
                    <div *ngIf="!Object.keys(waterAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Water Data
                    </div>                    
                    <table *ngIf="Object.keys(waterAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(waterAnalysisInfo)">                    
                            <tr>                       
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(waterAnalysisInfo[key])">{{waterAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(waterAnalysisInfo[key])"> </div>                        
                                </td>                            
                            </tr>
                            <tr *ngFor="let key1 of waterAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold" *ngIf="isValidObject(waterAnalysisInfo[key])">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                            x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Water Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold" *ngIf="isValidObject(waterAnalysisInfo[key])">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Water Analysis -->
        
                <!-- Waste Analysis -->
                <div class="tab-pane" id="tab4">
                    <div *ngIf="!Object.keys(wasteAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Waste Data</div>
                    
                    <table *ngIf="Object.keys(wasteAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>           
                         <tbody *ngFor="let key of Object.keys(wasteAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(wasteAnalysisInfo[key])">{{wasteAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(wasteAnalysisInfo[key])"> </div>
                                </td>  
                            </tr>
                            <tr *ngFor="let key1 of Object.keys(wasteAnalysisInfo[key])">
                                <td class="pl70 fw-semi-bold">
                                    {{key1}}
                                    <sup *ngIf="key1 == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                            x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Waste Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1 == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="wasteAnalysisInfo[key][key1] != null">
                                        {{wasteAnalysisInfo[key][key1]}}
                                        <span *ngIf="key1 == 'Waste Diversion Rate (%)'">
                                            %
                                        </span>
                                    </div>
                                    <div *ngIf="wasteAnalysisInfo[key][key1] == null"> - </div>
                                </td>
        
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Waste Analysis -->
        
                <!-- Transport Analysis -->
                <div class="tab-pane" id="tab5">
                    <div *ngIf="!Object.keys(transportAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Transport Data</div>
                    
                    <table *ngIf="Object.keys(transportAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left w40p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>                
                        <tbody *ngFor="let key of Object.keys(transportAnalysisInfo)">                    
                            <tr>
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(transportAnalysisInfo[key])">{{transportAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(transportAnalysisInfo[key])"> </div>
                                </td>  
                            </tr>
                            <tr *ngFor="let key1 of Object.keys(transportAnalysisInfo[key])">
                                <td class="pl70 fw-semi-bold">
                                    {{key1}}
                                    <sup *ngIf="key1 == 'Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Transportation Reference 
                                                    Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1 == 'Building Distance'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="Building Distance is Euclidean distance 
                                                of occupancy and square footage in log space.">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="transportAnalysisInfo[key][key1] != null">{{transportAnalysisInfo[key][key1]}}</div>
                                    <div *ngIf="transportAnalysisInfo[key][key1] == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Transport Analysis -->
                <!-- Human Exp Analysis -->
                <div class="tab-pane" id="tab6">
                    <div *ngIf="!Object.keys(humanAnalysisInfo).length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                        No Human Experience Data
                    </div>                    
                    <table *ngIf="Object.keys(humanAnalysisInfo).length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(humanAnalysisInfo)">                    
                            <tr>                       
                                <td class="fw-600 fs15 cursor-pointer">
                                    <span>
                                        {{key}}
                                    </span> 
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key])">{{humanAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key])"> </div>                        
                                </td>                            
                            </tr>
                            <tr *ngFor="let key1 of humanAnalysisInfo[key] | keys">
                                <td class="pl70 fw-semi-bold">
                                    {{key1.key}}
                                    <sup *ngIf="key1.key == 'Human Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Human Education Reference 
                                                Set Closeness">
                                        </span>
                                    </sup> 
                                    <sup *ngIf="key1.key == 'Prosperity Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                x > 40: Good, x > 20: Fair, x < 20: Poor, 
                                                where x = Prosperity Reference Set Closeness">
                                        </span>
                                    </sup> 
                                    <sup *ngIf="key1.key == 'Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                        </span>
                                    </sup>
                                    <sup *ngIf="key1.key == 'Equitability Reference Set Closeness'" class="admin-analysis-tooltip">
                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                        </span>
                                    </sup>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="key1.value != null">{{key1.value}}</div>
                                    <div *ngIf="key1.value == null"> - </div>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- Human Exp Analysis -->
        
                <!-- Human Experience Analysis -->
                <!-- <div class="tab-pane" id="tab5">
                    <div *ngIf="!Object.keys(humanAnalysisInfo).length" class="emptyData"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> No Human Experience Data</div>
                          
                    <table *ngIf="Object.keys(humanAnalysisInfo).length" class="table no-footer">
                        <thead>
                            <tr role="row">
                                <th class="" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" >Value</th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let key of Object.keys(humanAnalysisInfo)">
                            <tr>
                                <td class="fw-600 fs15">
                                    {{key}}
                                </td>
                                <td class="align-left fw-semi-bold fs15">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key])">{{humanAnalysisInfo[key]}} </div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key])"> </div>
                                </td>
                                <td></td>
                                <td></td> 
                                <td></td> 
                            </tr>
                            <tr  *ngFor="let key1 of Object.keys(humanAnalysisInfo[key])">
                                <td class="fw-600 align-left cursor-pointer pl70 w33p">
                                    <span>
                                        {{key1}}
                                    </span>
                                </td>
                                <td class="align-left fw-semi-bold">
                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1])">{{humanAnalysisInfo[key][key1]}}</div>
                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1])"> 
                                        <table class="simple_table table mb0">
                                            <tr *ngFor="let key2 of Object.keys(humanAnalysisInfo[key][key1])">
                                                <td class="fw-600 fs13">
                                                    {{key2}}
                                                    <sup *ngIf="key2 == 'Human Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Human Education Reference 
                                                                Set Closeness">
                                                        </span>
                                                    </sup> 
                                                    <sup *ngIf="key2 == 'Prosperity Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                x > 40: Good, x > 20: Fair, x < 20: Poor, 
                                                                where x = Prosperity Reference Set Closeness">
                                                        </span>
                                                    </sup> 
                                                    <sup *ngIf="key2 == 'Education Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                                        </span>
                                                    </sup>
                                                    <sup *ngIf="key2 == 'Equitability Reference Set Closeness'" class="admin-analysis-tooltip">
                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, 
                                                                x > 60: Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Equitability Reference Set Closeness">
                                                        </span>
                                                    </sup>
                                                </td>
                                                <td class="align-left fw-semi-bold">
                                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1][key2])">{{humanAnalysisInfo[key][key1][key2]}}</div>
                                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1][key2])"> 
                                                        <table class="table">
                                                            <tr *ngFor="let key3 of Object.keys(humanAnalysisInfo[key][key1][key2])">
                                                                <td class="fw-600 fs13">
                                                                    {{key3}}
                                                                    <sup *ngIf="key3 == 'Airquality Reference Set Closeness'" class="admin-analysis-tooltip">
                                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                                            data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: 
                                                                                Very Good, x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Airquality Reference Set Closeness">
                                                                        </span>
                                                                    </sup>
                                                                    <sup *ngIf="key3 == 'Crime Reference Set Closeness'" class="admin-analysis-tooltip">
                                                                        <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" 
                                                                        data-tooltip-placement="top" data-tooltip="If x > 80: Excellent, x > 60: Very Good, 
                                                                        x > 40: Good, x > 20: Fair, x < 20: Poor, where x = Crime Reference Set Closeness">
                                                                        </span>
                                                                    </sup>
                                                                </td>
                                                                <td class="align-right fw-semi-bold">
                                                                    <div *ngIf="!isValidObject(humanAnalysisInfo[key][key1][key2][key3])">{{humanAnalysisInfo[key][key1][key2][key3]}}</div>
                                                                    <div *ngIf="isValidObject(humanAnalysisInfo[key][key1][key2][key3])"> </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>                                        
                                            </tr>
                                        </table>
                                    </div>
                                </td>                        
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>                                       
                        </tbody>
                    </table>
                </div> -->
                <!-- Human Experience Analysis -->
            </div>
        </div>        
    </div>
</div>
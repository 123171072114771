import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from '../../models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-portfolio-creation',
  templateUrl: './portfolio-creation.component.html',
  styleUrls: ['./portfolio-creation.component.css']
})
export class PortfolioCreationComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	loading: boolean = false;
	submit_error: boolean = false;
	searchingOrganization: boolean = false;

	no_error = {
		'error': false,
		'help': undefined
	};
	formdata_error: any = {
		'name': this.no_error,
		'organization': this.no_error,
		'organization_country': this.no_error,
		'address': this.no_error,
		'organization_email': this.no_error,
		'description': this.no_error,
		'organization_contact': this.no_error
	};
	all_organizations: any = [];
	countries: any = [];
	port_obj: any = {};
	formdata: any = {
		'name': '',
		'organization': '',
		'organization_country': undefined,
		'address': '',
		'organization_email': '',
		'description': '',
		'organization_contact': ''
	}

	project_detail_nxt_btn: string = 'Create';
	canceler: any = '';

	constructor(
		public appData: AppData,
		public global: Global,
		private api: API,
		private alertService: alertService,
		private validator: ValidationService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
			});
		}
		else
		{
			this.countries = this.global.getCountryList()
		}
	}

	addToPortfolio(pf_id: number)
	{
		this.global.addToPortfolio([pf_id], this.appData.get('selected_projects_session')).subscribe(
			data =>
			{
				this.appData.set("selected_projects_session", []);
				this.loading = false;
				this.project_detail_nxt_btn = "Create";
				this.global.moveToPortfolio(this.port_obj[0].name, this.port_obj[0].pf_id, this.port_obj[0]);
				this.alertService.alert('success', 'Projects added to portfolio', 5);
			},
			error =>
			{
				this.appData.set("selected_projects_session", []);
				this.loading = false;
				this.project_detail_nxt_btn = "Create";
				this.global.moveToPortfolio(this.port_obj[0].name, this.port_obj[0].pf_id, this.port_obj[0]);
				this.alertService.alert('error', 'Error adding projects to portfolio', 5);
			}
		);
	}

	submitDetails()
	{
		this.loading = true;
		this.project_detail_nxt_btn = "Creating...";

		this.api.post('/portfolios/', this.formdata).subscribe(
			//success handler
			data =>
			{
				this.port_obj = data;
				if(this.appData.get('selected_projects_session').length > 0)
				{
					this.addToPortfolio(data[0].pf_id);
					return;
				}
				this.loading = false;
				this.project_detail_nxt_btn = "Create";
				this.global.moveToPortfolio(data[0].name, data[0].pf_id, data[0]);          
			},
			//error handler
			response =>
			{
				this.loading = false;
				this.project_detail_nxt_btn = "Create";
				this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
			}
		);
	}

	validateOwnerOrgDetails(org_country: string, org_name: string, user_name: string)
	{
		this.loading = true;
		this.project_detail_nxt_btn = "Verifying...";
		if ((org_country != null && org_country != "" && org_name != null && org_name != "") || (org_country != null && org_country != "" && user_name != null && user_name != ""))
		{
			var org_full_country = this.global.getCountry(org_country);
			//validating owner org details
			this.validator.validateOrganization(org_name, org_full_country).subscribe(
				data =>
				{
					if (data.status)
					{
						if (data.is_blocked)
						{
							this.loading = false;
							this.project_detail_nxt_btn = "Create";

							return;
						}
						else
						{
							var org_full_country = this.global.getCountry(org_country);
							this.validator.validateOwner(user_name, org_full_country).subscribe(
								data =>
								{
									if (data.status)
									{
										if (data.is_blocked)
										{
											this.loading = false;
											this.project_detail_nxt_btn = "Create";

											return;
										}
										//create portolio if owner org details not blocked
										else
										{
											this.submitDetails();
										}
									}
								},
								data =>
								{
									this.loading = false;
									this.project_detail_nxt_btn = "Create";
									this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
								}
							);
						}
					}
				},
				data =>
				{
					this.loading = false;
					this.project_detail_nxt_btn = "Create";
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
			);
		}
	}

	verifyDetails()
	{
		for(var a in this.formdata)
		{
			var optional = false;
			if(a == 'description')
			{
				optional = true;
			}
			this.verifyField(this.formdata[a], a, optional);
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				return;
			}
		}

		var org_country = this.formdata.organization_country;
		var org_name = this.formdata.organization;
		var user_name = this.formdata.organization_contact;
		this.validateOwnerOrgDetails(org_country, org_name, user_name);
	}

	getDataFromModal(event: any)
	{
		this.formdata.organization = event.formdata;
		// this.org_query = event.org_query;
		// this.isUpdateOrgModal = event.isUpdateOrgModal;
		// this.isOrganizationBlocked = event.isOrganizationBlocked;
		this.modal.close();
	}

	openModal()
	{
		this.modal.open();
	}

	searchDisableOrganization()
	{
		this.searchingOrganization = false;
	}
	
	searchEnableOrganization()
	{
		this.searchingOrganization = true;
	}

	selectOrganization(org: string)
	{
		this.formdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.formdata.organization = org;
		this.searchDisableOrganization();
	}

	verifyField(data: any, key: string, optional?: boolean)
	{
		if(this.validator.regex[key] == undefined)
		{
			return;
		}
		
		if(data == undefined || data == null || data.length == 0 || (data == 'null' && ['organization_country'].indexOf(key) != -1))
		{
			if(optional)
			{
				this.formdata_error[key] = {
					'error': false,
					'help': undefined
				};
				delete this.formdata[key];
				return;
			}
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.validator.regex[key].test(data) && key != 'organization')
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else if(key != 'organization')
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.validator.regex[key + 'Msg']
			};
		}
		

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				break;
			}
		}
	}

	searchOrganization(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		query = query.substr(0, 100);
		this.searchEnableOrganization();

		this.all_organizations = [];
		this.formdata_error['organization'] = {
			'error': true,
			'help': 'This field is required'
		};

		this.canceler = this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(
			data =>
			{
				this.all_organizations = data.owner_type;
			},
			error =>
			{

			}
		)
	}

	closeModal()
	{
		this.modal.close();
	}
}

import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[ngInit]',
  exportAs: 'ngInit'
})
export class NgInitDirective {

  constructor() { }

	@Input() values: any = {};

	@Input() ngInit: any;
	ngOnInit()
	{
		if(this.ngInit)
		{
			this.ngInit();
		}
	}  

}
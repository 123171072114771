import { Injectable } from '@angular/core';

import { API } from './api.service';
import { AppData } from './appdata.service';

@Injectable({
  providedIn: 'root'
})
export class ScoreCodeVersionService
{

	constructor(
		private api: API,
		private appData: AppData,
	)
	{

	}

	get()
	{   
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/'         
		);
	}

	compare(version_name: any)
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/compare/?version=' + version_name         
		);
	}

	getNotification()
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/notification/'         
		);
	}

	postNotification(date: any)
	{
		let payload = {"notify_on": date}
		return this.api.post(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/notification/',
			payload
		);
	}

	deleteNotification()
	{
		return this.api.delete(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/notification/',
			{}
		);
	}

	extensionRequest(reason: any, requested_version: any, till_date: any)
	{
		let payload = {"reason": reason, "requested_version": requested_version, "requested_till":till_date};
		return this.api.post(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/extension/request/update/',
			payload
		);
	}

	getExtensionRequest(leed_id?: any)
	{
		if(leed_id == undefined)
		{
			leed_id = this.appData.get('leed_id');
		}
		return this.api.get(
			'/assets/LEED:'+ leed_id + '/scores/version/extension/request/update/'         
		);
	}

	getAllExtensionRequest(page: any, page_size: any, query: any)
	{
		return this.api.get(
			'/scores/version/extension/request/?page=' + page + '&page_size=' + page_size + '&q=' + query         
		);
	}

	approveExtensionRequest(leed_id: any, request_id: any, grant_till: any, is_granted: any)
	{
		if(leed_id == undefined)
		{
			leed_id = this.appData.get('leed_id');
		}
		let payload = {"request_id": request_id, "grant_till": grant_till, "is_granted": is_granted};
		return this.api.post(
			'/assets/LEED:'+ leed_id + '/scores/version/extension/',
			payload
		);
	}

	updateVersion(new_version: any)
	{
		let payload = {"new_version": new_version}
		return this.api.post(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/update/',
			payload
		);
	}

	getUpdateLog()
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/update/'         
		);
	}

	getCombinedLog()
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/log/'         
		);
	}

	getScoreVersionUpdateUserNotification()
	{
		return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/user/notification/'         
		);
	}

	deleteScoreVersionUpdateUserNotification()
	{
		return this.api.delete(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/scores/version/user/notification/',
			{}
		);
	}

}

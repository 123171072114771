export const HUB_FEATURES = [
    {
        name: 'Performance data management',
        label: 'perfromance-data-management.svg',
    },
    {
        name: 'Performance monitoring',
        label: 'performance-monitoring.svg',
    },
    {
        name: 'LEED v4.1 O+M Readiness',
        label: 'leedv4.1.svg',
    },
    {
        name: 'Performance certificates',
        label: 'performance-certificates.svg',
    },
    {
        name: 'Climate Risk',
        label: 'climate-risk.svg',
    },
    {
        name: 'Score modeling',
        label: 'score-modeling.svg',
    },
    {
        name: 'Reports',
        label: 'reports.svg',
    },
    {
        name: 'PERFORM',
        label: 'perform.svg',
    },
    {
        name: 'EU Taxonomy',
        label: 'eu-taxonomy.svg'
    },
];


export const HUB_FEATURES_COMING_SOON = [
    {
        name: 'Advanced scoring',
        label: 'advanced-scoring.svg'
    },
    {
        name: 'Parksmart',
        label: 'parksmart.svg'
    },
    {
        name: 'LEED for Cities',
        label: 'leed-for-cities.svg'
    },
    {
        name: 'LEED for Communities',
        label: 'leed-for-cities.svg'
    },
    {
        name: 'LEED Transit',
        label: 'leed-transit.svg'
    },
    {
        name: 'LEED v4.1 O+M Recertification',
        label: 'leedv4.1.svg',
    }
];
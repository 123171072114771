<div style="height: 100%;" class="{{appData.get('LO_referer') ? 'referer_bar' : ''}}" [ngClass]="config.app_loader ? 'not-active' : ''" [hidden]="config.version_comparison_show_modal">
    <app-navbar *ngIf="config.show_navbar && !config.hide_navbar"></app-navbar>
    <app-loader *ngIf="config.app_loader"></app-loader>
    <router-outlet></router-outlet>
</div>

<div class="fade score_version_modal full-page-modal split_screen_vertically in" id="score_code_version_comparison" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false" *ngIf="config.version_comparison_show_modal">
    <div class="">
        <div class="split_background">
            <div class="modal-header score_version_modal_header pt40">
                <button type="button" class="close" aria-label="Close" (click)="global.closeScoreComparisonWindow(); global.postponeVersionNotification('close')">
                </button>
            </div>
            <div class="score_version_modal_body">
                <div class="center-div align-center mt20 overflow-hidden" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                    <div class="card mr20">
                        <div class="">
                            <div class="fw-600 fs24 p20 pb25">Current Version: <span class="fw-500">Arc score v{{appData.get('buildingData').score_code_version.name}}</span></div>
                        </div>
                        <div class="card_divider"></div>
                        <div *ngIf="config.score_scenario != null" class="card_info fw-500">
                            <div [ngClass]="config.show_score_help ? '' : 'score_version_help'">
                                <div [innerHTML]="config.score_version_help_left"></div>
                            </div>
                            <a (click)="global.toggleScoreHelp()">
                                {{config.show_score_help ? 'Show less' : 'Show more'}}
                            </a>
                            <p class="pt10 pb10">Here is your score under v{{appData.get('buildingData').score_code_version.name}}:</p>
                        </div>
                        <div class="card_score" *ngIf="config.score_comparison_received">
                            <p class="fw-600 mb15">TOTAL SCORE<p>
                            <div class="col-md-12 pl0">
                                <div class="smallScorepuckBlank floatNone">
                                    <p>{{global.getScoreTotal(config.old_version_scores)}}</p>
                                </div>
                            </div>
                            <div class="col-md-12 pl0 mt15 mb15">
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>ENERGY</p>
                                    <div class="energy-bg score_box">{{config.old_version_scores.energy}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WATER</p>
                                    <div class="water-bg score_box">{{config.old_version_scores.water}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WASTE</p>
                                    <div class="waste-bg score_box">{{config.old_version_scores.waste}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>TRANS.</p>
                                    <div class="transportation-bg score_box">{{config.old_version_scores.transport}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">HUM. EXP.</p>
                                    <div class="human-bg score_box">{{config.old_version_scores.human_experience}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">BASE PT.</p>
                                    <div class="base-bg score_box">{{config.old_version_scores.base_score}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="compare_score_version_spinner" [hidden]="config.score_comparison_received">
                            <div class="rect1"></div>
                            <div class="rect2"></div>
                            <div class="rect3"></div>
                            <div class="rect4"></div>
                        </div>
                    </div>
                    <div class="card mr20">
                        <div class="">
                            <div class="fw-600 fs24 p20 pb25">New Version: <span class="fw-500">Arc score v{{config.score_version_name_for_compare}}</span></div>
                        </div>
                        <div class="card_divider"></div>
                        <div *ngIf="config.score_scenario != null" class="card_info fw-500">
                            <div [ngClass]="config.show_score_help ? '' : 'score_version_help'">
                                <div [innerHTML]="config.score_version_help_right"></div>
                            </div>
                            <a (click)="global.toggleScoreHelp()">
                                {{config.show_score_help ? 'Show less' : 'Show more'}}
                            </a>
                            <p class="pt10 pb10">Here is your score under v{{config.score_version_name_for_compare}}:</p>
                        </div>
                        <div class="card_score" *ngIf="config.score_comparison_received">
                            <p class="fw-600 mb15">TOTAL SCORE<p>
                            <div class="col-md-12 pl0">
                                <div class="smallScorepuckBlank floatNone">
                                    <p>{{global.getScoreTotal(config.new_version_scores)}}</p>
                                </div>
                            </div>
                            <div class="col-md-12 pl0 mt15 mb15">
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>ENERGY</p>
                                    <div class="energy-bg score_box">{{config.new_version_scores.energy}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WATER</p>
                                    <div class="water-bg score_box">{{config.new_version_scores.water}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WASTE</p>
                                    <div class="waste-bg score_box">{{config.new_version_scores.waste}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>TRANS.</p>
                                    <div class="transportation-bg score_box">{{config.new_version_scores.transport}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">HUM. EXP.</p>
                                    <div class="human-bg score_box">{{config.new_version_scores.human_experience}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">BASE PT.</p>
                                    <div class="base-bg score_box">{{config.new_version_scores.base_score}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="compare_score_version_spinner" [hidden]="config.score_comparison_received">
                            <div class="rect1"></div>
                            <div class="rect2"></div>
                            <div class="rect3"></div>
                            <div class="rect4"></div>
                        </div>
                    </div>
                </div>
                <div class="center-div align-center mt20 overflow-hidden" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">
                    <div class="card mr20">
                        <div class="">
                            <div class="fw-600 fs24 p20 pb25">Current Scoring Algorithm Version {{appData.get('buildingData').score_code_version.name}} for 
                              <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities</span>
                              <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities</span>
                              : <span class="fw-500">Your {{appData.get('buildingData').project_type}}'s old score</span></div>
                        </div>
                        <div class="card_divider"></div>
                        <div class="card_info fw-500">
                            <p>This is the old version of your {{appData.get('buildingData').project_type}}'s Arc score.&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp</p>
                            <p class="pt10 pb10">Here is your previous score:</p>
                        </div>
                        <div class="card_score" *ngIf="config.score_comparison_received">
                            <p class="fw-600 mb15">TOTAL SCORE<p>
                            <div class="col-md-12 pl0">
                                <div class="smallScorepuckBlank floatNone">
                                    <p>{{global.getScoreTotal(config.old_version_scores)}}</p>
                                </div>
                            </div>
                            <div class="col-md-12 pl0 mt15 mb15">
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>ENERGY</p>
                                    <div class="energy-bg score_box">{{config.old_version_scores.energy}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WATER</p>
                                    <div class="water-bg score_box">{{config.old_version_scores.water}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WASTE</p>
                                    <div class="waste-bg score_box">{{config.old_version_scores.waste}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>TRANS.</p>
                                    <div class="transportation-bg score_box">{{config.old_version_scores.transport}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">HUM. EXP.</p>
                                    <div class="human-bg score_box">{{config.old_version_scores.human_experience}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">BASE PT.</p>
                                    <div class="base-bg score_box">{{config.old_version_scores.base_score}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="compare_score_version_spinner" [hidden]="config.score_comparison_received">
                            <div class="rect1"></div>
                            <div class="rect2"></div>
                            <div class="rect3"></div>
                            <div class="rect4"></div>
                        </div>
                    </div>
                    <div class="card mr20">
                        <div class="">
                            <div class="fw-600 fs24 p20 pb25">New Scoring Algorithm Version {{config.new_score_version_detail.name.split('.')[0]}} for 
                              <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities</span>
                              <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities</span>
                              : <span class="fw-500">Your {{appData.get('buildingData').project_type}}'s new score</span></div>
                        </div>
                        <div class="card_divider"></div>
                        <div class="card_info fw-500">
                            <p>This version of your {{appData.get('buildingData').project_type}}'s score is the result of an update in Arc's scoring algorithm for {{appData.get('buildingData').project_type}} projects, and hence a scoring change is reflected for selected 
                              <span *ngIf="appData.get('buildingData').project_type == 'city'">cities</span>
                              <span *ngIf="appData.get('buildingData').project_type == 'community'">communities</span>
                            . Note: some 
                              <span *ngIf="appData.get('buildingData').project_type == 'city'">cities</span>
                              <span *ngIf="appData.get('buildingData').project_type == 'community'">communities</span>
                             scores may still be the same as before under the new update.</p>
                            <p class="pt10 pb10">Here is your new score under version {{config.new_score_version_detail.name.split('.')[0]}}:</p>
                        </div>
                        <div class="card_score" *ngIf="config.score_comparison_received">
                            <p class="fw-600 mb15">TOTAL SCORE<p>
                            <div class="col-md-12 pl0">
                                <div class="smallScorepuckBlank floatNone">
                                    <p>{{global.getScoreTotal(config.new_version_scores)}}</p>
                                </div>
                            </div>
                            <div class="col-md-12 pl0 mt15 mb15">
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>ENERGY</p>
                                    <div class="energy-bg score_box">{{config.new_version_scores.energy}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WATER</p>
                                    <div class="water-bg score_box">{{config.new_version_scores.water}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>WASTE</p>
                                    <div class="waste-bg score_box">{{config.new_version_scores.waste}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p>TRANS.</p>
                                    <div class="transportation-bg score_box">{{config.new_version_scores.transport}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">HUM. EXP.</p>
                                    <div class="human-bg score_box">{{config.new_version_scores.human_experience}}</div>
                                </div>
                                <div class="col-md-2 pl0 fw-600 mb10">
                                    <p class="wrap_width">BASE PT.</p>
                                    <div class="base-bg score_box">{{config.new_version_scores.base_score}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="compare_score_version_spinner" [hidden]="config.score_comparison_received">
                            <div class="rect1"></div>
                            <div class="rect2"></div>
                            <div class="rect3"></div>
                            <div class="rect4"></div>
                        </div>
                    </div>
                </div>
                <div class="mt20 mb20 align-center">
                    <div class="card opacity_0 hidden_card"></div>
                    <div class="card mb40 align-right no_border_card pr0" [ngClass]="config.score_version_confirmation_flag ? 'not-active' : ''">
                        <button class="btn btn-primary" role="button" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" (click)="global.updateScoreVersion()">{{config.score_version_confirmation_text}}</button>
                        <button class="white-bg btn btn-default ml10" role="button" (click)="global.skip_score_update()">Skip</button>
                    </div>
                </div>
            </div>
            <div id="lower_half"></div>
        </div>
    </div>
</div>

<div class="token_incorrect_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">{{config.token_incorrect_modal_text}}</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" (click)="global.redirect_to_mypojects_page()">{{config.redirect_to_landing_page_text}}</button>
            </div>
        </div>
    </div>
</div>
    
<div class="project_access modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">You don't have access to this project.</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="confidential_project modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">This project is confidential and may not be communicated externally.</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="no_access_project modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">Sorry, You don't have access to this project. You will be redirected to Projects page.</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" (click)="global.goToApp('projects')" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal" id="score_code_version_notification" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">New Score Version Available</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="global.postponeVersionNotification('close')">
                </button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body">
                <p class="fw-500 pt5" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">Get all the answers and learn more about updating to the latest score version.</p>
                 <p class="fw-500 pt5" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">We have made some important updates to the scoring algorithm for {{appData.get('buildingData').project_type}} projects in Arc! Get all the answers, and learn more about the latest score version and how {{appData.get('buildingData').project_type}} projects are scored in Arc. If you have any questions please feel free to <a (click)="global.scoreVrsionMailto()">contact us</a>.</p>
                <ul class="pl20" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                    <li class="gray-color">We are updating to performance score v{{config.new_score_version_detail.name}}, what are the changes?</li>
                    <li class="gray-color" *ngIf="config.new_score_version_detail.name == '2.1'">How does my project score in LEED v4 O+M?</li>
                    <li class="gray-color" *ngIf="config.new_score_version_detail.name != '2.1'">How does my project score in LEED v4.1 O+M?</li>
                    <li class="gray-color">Learn the details of this update and previous score updates.</li>
                </ul>
                <ul class="pl20" *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">
                    <li class="gray-color">What are the changes under version 2 of Arc's scoring algorithm for LEED for 
                      <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities?</span>
                      <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities?</span>
                    </li>
                    <li class="gray-color">How does my project score in version 2 of Arc's scoring algorithm for LEED for 
                      <span *ngIf="appData.get('buildingData').project_type == 'city'">Cities?</span>
                      <span *ngIf="appData.get('buildingData').project_type == 'community'">Communities?</span>
                    </li>
                </ul>
            </div>
            <div class="score_version_modal_footer pb30">
                <button class="btn btn-primary mr30" role="button" (click)="global.goToScoreVersion()"><i class="icon-refresh pr3"></i> Learn More & Update</button>
                <div class="display-inline" *ngIf="config.notification_only_close">
                    <button class="btn btn-white" type="button" data-dismiss="modal" (click)="global.postponeVersionNotification('close')">Close</button>
                </div>
                <div class="dropdown display-inline" [hidden]="config.notification_only_close">
                    <button [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="btn btn-white" type="button" data-toggle="dropdown">
                        <span class="mr5">Not Now</span>
                        <span><i class="icon-angle-down"></i></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li (click)="global.postponeVersionNotification(obj.key)" *ngFor="let obj of config.not_now_dropdown | keys"><a>{{obj.value}}</a></li>
                    </ul>
                </div>
            </div>
            <div *ngIf="appData.get('buildingData').score_code_version != undefined && !appData.get('buildingData').score_code_version.sunset_date_notification" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="checkbox checkbox-primary pt0 mt0">
                <input id="checkbox_score_code_version_notification" type="checkbox" class="cursor-pointer" [(ngModel)]='config.scoreNotificationCheckStatus.accept' (change)="global.postponeVersionNotification('no_notification'); !config.scoreNotificationCheckStatus.accept">
                <label for="checkbox_score_code_version_notification" class="unselectable">
                    Don't remind me again.
                </label>
            </div>
        </div>
    </div>
</div>

<div id="scoreTip">
	<div class="flex">
		<div id="tipCanceler">
			<span (click)="global.hideTippy()">
				<i class="fa ml15 fa-times-circle"></i>
			</span>
		</div>
		<div class="tipInfo" style="padding: 15px 20px;">
			<div *ngIf="config.stream_upload"><span class="uploadLoaderSpinner"></span><span class="fs14">Creating projects...</span></div>
			<div *ngIf="config.show_refresh">
				<span>
					<i class="fa mr15 fa-check"></i>
				</span>
				<span class="fs14">Created. Click <a (click)="global.triggerReload()">here</a> to refresh.</span>
			</div>
		</div>
	</div>
</div>

<div class="modal fade score_version_modal" id="user_preference_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Preferences</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body score_version_modal_body mb20">
                <fieldset>
                    <h4 class="pb10 inline-flex">                
                        <input #elem (change)="global.updateUserPreference(elem)" type="checkbox" class="new_score_version_notification floatl" checked/>  
                        <div class="floatl pl20">Receive email when new score version is available</div>                 
                    </h4>
                </fieldset>
            </div>
        </div>
    </div>
</div>

<div class="modal score_version_modal" id="skip_score_update" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Keep my current score</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="global.postponeVersionNotification('close'); global.closeScoreComparisonWindow()">
                </button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body pt20 pb30">
                <p>Your current score version is valid through {{config.final_date_to_update_string_val}}, after which you’ll need to update in order to submit for certification. <a (click)="global.postponeVersionNotification('other_upgrade_option')">Let us know if you’ll need more time.</a></p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal" id="score_code_version_review_block" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs16 fw-600 ">You need to update your score to submit</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="global.postponeVersionNotification('close')">
                </button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body">
                <p class="fw-500 sub-heading mb0">All projects will be transitioned to Arc's Score Version {{config.new_score_version_detail.name}}. See how this version impacts your project's score by clicking on "Learn More". You can update now or wait to be automatically updated on {{config.final_date_to_update_string_val}}.</p>
            </div>
            <div class="score_version_modal_footer mb40"> 
                <button class="btn btn-primary mr30" role="button" (click)="global.goToScoreVersion()"><i class="icon-refresh pr3"></i> Learn More & Update</button>
                <div class="display-inline">
                    <button class="btn btn-white" type="button" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal score_version_modal" id="score_version_updated_user_notification" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Your project's score version has been upgraded</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="global.deleteUserScoreVersionUpdateNotification()"></button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body pt20 pb30">
                <p>Congratulations! Your project was upgrade to the latest version of Arc scoring. If you have any question feel free to <a href="mailto:contact@arcskoru.com">contact us</a>.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal score_version_modal" id="pending_score_update_confirmation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Score version update is pending</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="global.closePendingScoreUpdateConfirmationModal()">
                </button>
            </div>
            <div class="fw-500 modal-body score_version_modal_body pt20 pb30">
                <p>Thanks! Since your project is currently under certification review, we'll process your score version update as soon as the certification review is complete.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade request_send_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p30 modal_radius">
            <p class="align-center">Your request to access this project has been sent to the Project Admin.</p>
            <div class="mt30 align-center"> 
                <button type="button" class="mb10 btn btn-default" title="" data-popover-placement="left" data-popover="" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="home_admin_block modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">Admin users are not allowed to access Home Section</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="climate_risk_block modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center">This service is not available outside U.S. and Canada.</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="integration_invalid modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content modal_radius">
            <div class="p2535" *ngIf="appData.get('integration') != undefined">
    
                <div class="left-right-align mb20">
                    <h4 class="mb10 mt10 fw-600">Already subscribed to <span class="capitalize">{{appData.get('integration').app_name}}</span></h4> 
                </div>
            
                <div class="mb30">
                    Your project is already integrated with {{appData.get('integration').app_name}}.
                </div>
            
                <div class="left-right-align mt20">
                    <div class="align-right">
                        <button data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="confirm_measurabl_integration modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content modal_radius">
            <div class="p2535">
    
                <div class="left-right-align mb20">
                    <h4 class="mb10 mt10 fw-600">Measurabl Integration</h4> 
                </div>
            
                <div class="mb30">
                    Thanks for the integration request with the Measurabl. Your data will be pulled from Measurabl and synced to your Arc project in next 24-48 hours.
                </div>
            
                <div class="left-right-align mt20">
                    <div class="align-right">
                        <button data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    
<div eview_edit="false" class="energyStarPrompt_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                <div class="mt10 mb25 upload_meter_data_heading">ENERGY STAR Integration Guide</div>
                <iframe style="width:100%; height: 350px;" src="https://www.youtube.com/embed/byRh83icUSA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="mt10 viewUpload bottomButtons showSubmit">
                    <button data-dismiss="modal" type="button" class="btn btn-default" title="" data-popover-placement="left" data-popover="">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="login_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p>{{config.login_modal_text}}</p>
            <p *ngIf="config.login_app_redirect == 'agreements'">I accept to terms and conditions of <span (click)='global.openLink(global.getAgreementUrl(), true)' class='agreement_tag'>service agreement</span></p>
            <div class="mt30 align-center overflow-auto"> 
                <button class="floatl btn action-button btn-primary" [disabled]="config.login_modal_button_flag" (click)="global.check_login_status()">
                    <span *ngIf="config.login_modal_button_flag" style="float: left !important;margin-right: 7px;margin-top: 4px;border-top-color: white;border-left-color: white;" class="dataInputLoaderSpinner"></span>
                    {{config.redirect_to_landing_page_text}}</button>
                <button class="floatr btn action-button btnGreen only_border" [disabled]="config.login_modal_button_flag" (click)="global.redirect_to_mypojects_page()">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="full_access_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false" style="z-index: 9998;">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">

            <div class="modal-body login_body mt0 p0">
                
                <div class="p2535">
    
                    <div class="left-right-align">
                        <h3 class="mb20 mt0 fw-600">Want to do more with Arc?</h3> 
                        <!-- <span class="gray-color cursor-pointer">X</span>   -->
                    </div>
                
                    <div class="arc-list blue fontSize16 pl0 mb10">
                        <div class="arc-list-item">
                            <label *ngIf="appData.get('project_type') != 'city' && appData.get('project_type') != 'community'">Pursue LEED Recertification</label>
                            <label *ngIf="appData.get('project_type') == 'city' || appData.get('project_type') == 'community'">Pursue LEED Certification</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('project_type') != 'city' && appData.get('project_type') != 'community'">
                            <label>Access score analytics and improvement metrics</label>
                        </div>
                        <div class="arc-list-item">
                            <label>Add multiple team members</label>
                        </div>
                        <div class="arc-list-item">
                            <label>View and publish performance score animation</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('project_type') != 'city' && appData.get('project_type') != 'community'">
                            <label>View historic score trends</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('project_type') != 'city' && appData.get('project_type') != 'community'">
                            <label>Leverage our data integration partners for deeper insights</label>
                        </div>
                    </div>
                    <div class="mb30" *ngIf="appData.get('project_type') == 'building' && appData.get('buildingData').score_code_version != undefined && !appData.get('buildingData').score_code_version.latest">
                        This project is currently using the Arc Performance Score v2. Projects wishing to pursue LEED v4.1 O+M must update to Arc performance score v3 of the score by registering their project in LEED Online. <a href="mailto:contact@arcskoru.com">Contact us</a> to discuss your options.
                    </div>
                
                    <div class="left-right-align">
                        <h4 class="mb10 mt10 fw-600">Get full access to the Arc platform</h4> 
                    </div>
                
                    <div class="left-right-align mt20">
                        <div class="align-left">
                            <button (click)="global.goToPayment(appData.get('buildingData'))" data-dismiss="modal" type="button" class="btn btn-primary btn-md">Pay Now</button>
                        </div>
                
                        <div class="align-right">
                            <button data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div id="trial_expire_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false" style="z-index: 9998;">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">

            <div class="modal-body login_body mt0 p0">
                
                <div class="p2535">
    
                    <div class="left-right-align">
                        <div class="flex fontSize18 mb25 fw-600 error-color">
                            <span class="error_symbol_trial"></span>
                            <span class="error-lg-msg"> Free Term Expired</span>
                        </div> 
                    </div>
                
                    <p class="mb20 fontSize16 fw-400" *ngIf="appData.get('buildingData').project_type == 'building'">Select one of the many term options to continue accessing the full suite of Arc tools across all categories including performance benchmarking, analytics, portfolios and more!</p>
                    <p class="mb20 fontSize16 fw-400" *ngIf="appData.get('buildingData').project_type != 'building'">Get started with registering your project to continue accessing the full suite of Arc tools across all categories including performance benchmarking, analytics, and more!</p>
                    
                    <div class="arc-list blue fontSize16 pl0 mb20">
                        <div class="arc-list-item">
                            
                            <label *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">Pursue LEED Recertification</label>
                            <label *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">Pursue LEED Certification</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                            
                            <label>Access score analytics and improvement metrics</label>
                        </div>
                        <div class="arc-list-item">
                            
                            <label>Add multiple team members</label>
                        </div>
                        <div class="arc-list-item">
                            
                            <label>View and publish performance score animation</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                            
                            <label>View historic score trends</label>
                        </div>
                        <div class="arc-list-item" *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">
                            
                            <label>Leverage our data integration partners for deeper insights</label>
                        </div>
                    </div>
                
                    <div class="mt30 pb50">
                        <button id="paynow_trial" (click)="global.goToPayment(appData.get('buildingData'))" data-dismiss="modal" type="button" class="btn btn-primary btn-md floatl">Get started</button>
                        <button data-dismiss="modal" type="button" class="btn grey btn-default btn-md floatr">CLOSE</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div eview_edit="false" class="maintenance_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                <div class="p2535">
    
                    <div class="left-right-align">
                        <div class="flex fontSize18 mb25 fw-600 error-color">
                            <span class="error_symbol_trial"></span>
                            <span class="error-lg-msg"> Under Maintenance</span>
                        </div> 
                    </div>
                
                    <p class="mb20 fontSize16 fw-400">The platform is in the maintenance mode. This functionality might not be available during the maintenance mode.</p>
                
                    <div class="mt30 pb50">
                        <button data-dismiss="modal" type="button" class="btn grey btn-default btn-md floatr">CLOSE</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div eview_edit="false" class="logout_confirmation modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                <div class="p2535">

                    <p class="mb20 fontSize16 fw-400">
                        By clicking logout button below, you will be logged out of all the USGBC and GBCI applications in this browser.<br><br> Click the logout button below to continue logging out or cancel button to stay logged in.
                    </p>
                
                    <div class="mt30 fs16">
                        <div class="checkbox pl20">
                            <input (change)="setOptionalStorage()" id="optional_storage" name="optional_storage" type="checkbox" value="confidential" style="width: initial;" class="">
                            <label for="checkbox" translate="">
                                Do not show this prompt again
                            </label>
                        </div>
                    </div>
                    <div class="mt30 align_lr">
                        <button (click)="global.logout()" type="button" class="btn btn-primary btn-lg fs16">Logout</button>
                        <button data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div eview_edit="false" class="logout_external modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                <div class="p2535">

                    <p class="mb20 fontSize16 fw-400">
                        You were logged out from another application. Please login again.
                    </p>
                    <div class="mt30 align-center">
                        <button (click)="global.triggerLogin()" type="button" class="btn btn-primary btn-lg fs16">Login</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="end-user-agreement" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
       <div class="modal-content">
         <div class="modal-body" *ngIf="!global.getBrowser().safari">
             <div class="align-center">
               <object style="height: 500px;width: 100%;" data="../../assets/pdf/Arc End User License Agreement.pdf?embedded=true" type="application/pdf">
               </object>
             </div>
         </div>
         <div class="modal-footer overflow-auto">
            <div class="pb15 align-center error-color" *ngIf="global.getBrowser().safari || (navigator.pdfViewerEnabled != undefined && !navigator.pdfViewerEnabled)">
                Your browser settings doesn't allow the pdf to be opened in the browser. Please click <a href="../../assets/pdf/Arc End User License Agreement.pdf" download>here</a> to download the pdf to your computer.
            </div>
             <div class="consent-btn-container">
                <button (click)="global.SignAgreementAndGetLOToken()" translate type="button" class="btn btn-primary floatl consent-btn fs18" [ngClass]="config.app_loader ? 'not-active' : ''">Accept</button>
                <button (click)="global.logout()" data-dismiss="modal" translate type="button" class="btn btn-secondary floatr consent-btn fs18" [ngClass]="config.app_loader ? 'not-active' : ''">Deny</button>
             </div>
         </div>
       </div>
    </div>
</div>

<div class="message-banner" style="height: 60px;padding-top: 20px;">
    <div>{{config.banner_message}}</div>
    <button style=" margin-top: -25px !important;" type="button" class="close removeOpacity arc_banner_button cursor-pointer" (click)="global.remove_arc_banner()">×</button>
</div>

<div class="maintenance-banner">
    The platform is in the maintenance mode. A few functionalities might not be available during the maintenance mode.
</div>

<div data-toggle="modal" data-backdrop="static" data-keyboard="false" class="dynamic_div modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" [ngClass]="config.app_loader ? 'not-active' : ''">
        <app-loader *ngIf="config.app_loader"></app-loader>
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                <div class="p2535">
    
                    <div class='project_agreement_row'>
                        <div *ngIf="config.dynamic_row == 'permission'" class='vertical_align_middle text_row'>
                            Please contact the Arc Project Admin for access to this project.
                        </div>

                        <div *ngIf="config.dynamic_row == 'wait_permission'" class='vertical_align_middle text_row'>
                            Your request to access this project is pending with the Arc Project Admin.
                        </div>

                        <div *ngIf="config.dynamic_row == 'deny_permission'" class='vertical_align_middle text_row'>
                            Your request to access this project was denied by the Arc Project Admin.
                        </div>

                        <div *ngIf="config.dynamic_row == 'agreement'" class='vertical_align_middle text_row'>
                            I accept the terms and conditions of <span (click)='global.openLink(global.getAgreementUrl(), true)' class='agreement_tag'>service agreement</span>
                        </div>

                        <div *ngIf="config.dynamic_row == 'review_payment_pending'" colspan='2' class='vertical_align_middle text_row'>
                            An outstanding annual payment of $"
                            <span class='next_payment_amount_" + index + "'></span>"
                             was due on "
                            <span class='next_payment_date_" + index + "'></span>"
                            . Please email <a (click)="global.openEmail('contact@arcskoru.com')">contact@arcskoru.com</a> with any questions."
                        </div>
                    </div>
                
                    <div class="align_lr">

                        <button *ngIf="appData.get('buildingData').coundivy != 'IN' && config.dynamic_row == 'review_payment_pending'" [ngClass]="config.access == 'R' ? 'not-active' : ''" class='btn btn-primary btn-md' (click)='global.moveToPayNow()'>Pay now</button>

                        <button *ngIf="config.dynamic_row == 'review_payment_pending'" [ngClass]="config.access == 'R' ? 'not-active' : ''" class='btn btn-primary btn-md' (click)='global.downloadPendingReviewReceipt()'><i class='fa fa-download' aria-hidden='divue'></i> Invoice</button>

                        <button *ngIf="config.dynamic_row == 'agreement'" [ngClass]="config.access == 'R' ? 'not-active' : ''" class='btn btn-primary btn-md' (click)='global.acceptAgreement()'>Accept</button>

                        <button *ngIf="config.dynamic_row == 'permission'" class="btn btn-primary btn-md" (click)='global.sendRequest()'>Request Access</button>

                        <button class="btn btn-default btn-md" (click)="global.removeDynamicDiv()">Close</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="invalid_sso_login modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center error-color fs16">{{config.sso_error}}</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Close</button>
            </div>
        </div>
    </div>
</div>
<div class="content-wrap" [ngClass]="loading? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
    <div id="content" class="content">

        <div class="model_create_nav">
            <div class="progress_create">
                <div [ngClass]="appData.get('current_step') == 'Model details' ? 'active' : ''" class="step flex">
                    <div class="step_val">1</div>
                    <div class="step_name">Model details</div>
                </div>
                <div [ngClass]="appData.get('current_step') == 'Strategies' ? 'active' : ''" class="step flex">
                    <div class="step_val">2</div>
                    <div class="step_name">Strategies</div>
                </div>
                <div [ngClass]="appData.get('current_step') == 'Reduction view' ? 'active' : ''" class="step flex">
                    <div class="step_val">3</div>
                    <div class="step_name">Reduction view</div>
                </div>
            </div>
            <div class="model_create_nav_btn">
                <div (click)="emitToChild()" class="pl20 pr20 btn btn-primary mr25">
                    <span>Next</span>
                </div>
                <div (click)="goBack()" class="pl20 pr20 btn btn-default">
                    <span>Cancel</span>
                </div>
            </div>
        </div>

        <div class="form_wrapper">
            <h3 *ngIf="appData.get('current_step') == 'Model details'" class="fw-500 mb25">Model details</h3>
            <h3 *ngIf="appData.get('current_step') == 'Strategies'" class="fw-500 mb25">Strategies</h3>
            <h3 *ngIf="appData.get('current_step') == 'Reduction view'" class="fw-500 mb25">Reduction view</h3>
    
            <app-model-details (success)="emitFromChild($event)" *ngIf="appData.get('current_step') == 'Model details'"></app-model-details>
            <app-model-strategies *ngIf="appData.get('current_step') == 'Strategies'"></app-model-strategies>
            <app-model-reduction *ngIf="appData.get('current_step') == 'Reduction view'"></app-model-reduction>
        </div>
        
    </div>
</div>
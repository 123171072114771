<div class="content-wrap">
	<div id="content" class="content">
        
        <div class="clearfix">
            <div>
                <h1 class="page-title pull-left">{{getHeading()}}</h1>
            </div>
        </div>
        
        <section class="widget action-cards-xs">
            <div class="widget-body hidden-xs">
                <div class="">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>                        
                                <th class="no-sort bg_none" width="5%">Status</th>
                                <th width="55%">Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let measure of all_measures" [hidden]="!showRow(measure)">                        
                                <td  (click)="clickAction(measure)" class="verticle_middle text-center">
                                    <span [ngClass]="measure.points_pursued == measure.max_points ? 'fa fa-check' : 'far fa-circle'"></span>
                                </td>
                                <td  (click)="clickAction(measure)" class="verticle_middle">
                                    <a>
                                        <span class="fw-semi-bold">{{measure.measure_id}} - {{measure.name}}
                                        </span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="loading" class="ajaxLoader mt20">
                <div class="loaderSpinner"></div>
                <div>Loading...</div>
            </div>
        </section>
        
        <div class="align-right">
            <a class='blue_a_tag mr10' href="http://parksmart.gbci.org/faq" target="_blank" tabindex="1">FAQs</a>
            <a class='blue_a_tag mr10' href="mailto:parksmart@gbci.org" tabindex="2">Contact us</a>
            <a class='blue_a_tag' href="http://parksmart.gbci.org/resources" target="_blank" tabindex="3">Parksmart Resources</a>
        </div>

    </div>
</div>

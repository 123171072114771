import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';
import { Config } from 'src/app/models/env-vars.model';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-team-export',
  templateUrl: './team-export.component.html',
  styleUrls: ['./team-export.component.css']
})
export class TeamExportComponent implements OnInit {
  loader: boolean = false;
  search_results: any = [];
  projects: any = [];
  is_search: boolean = false;
  canceller_search_user: any = undefined;
  search_user_status: boolean = false;
  search_user: any = null;
  selected_user: any = {};
  searching_user: boolean = false;
  canceler: any;

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService,
  ) { }

  isSearch(is_search: boolean) {
    this.is_search = is_search;
  };

  getUser(user: any, query: any) {
    var search = String(user.name) + " (" + user.email + ")";
    var replace = "<span class='fw-500'>" + query + "</span>";
    var regEx = new RegExp(query, "ig");
    return search.replace(regEx, replace);
  };

  exportData() {
    this.loader = true;
    var url = Config.basic_api_url + '/optask/teamsproject/?searchkey=' + this.selected_user.email + '&export=True';
    var xhttp: any = new XMLHttpRequest();
		xhttp.onreadystatechange = () =>
		{
			var a, today;
			if (xhttp.readyState === 4 && xhttp.status === 200)
			{
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(xhttp.data, "Team Export.xlsx");
					this.loader = false;
				}
				else
				{
					this.loader = false;
					a = document.createElement('a');
					a.download = "Team Export.xlsx";
					a.style.display = 'none';
					a.href = window.URL.createObjectURL(xhttp.response);
					document.body.appendChild(a);
					return a.click();
				}
			}
		};
		xhttp.open("GET", url, true);
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", Config.sub_key);
		xhttp.setRequestHeader("Authorization", "Bearer " + this.appData.get('token'));
		xhttp.responseType = 'blob';
		xhttp.send();
  };

  getProjects() {
    this.loader = true;
    this.api.get('/optask/teamsproject/?searchkey=' + this.selected_user.email).subscribe(
      data => {
        this.projects = data;
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  searchUser(search_user: any) {
    if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
    if (search_user.length == 0) {
      this.search_results = [];
      return;
    }
    this.searching_user = true;
    this.canceler = this.api.get("/auth/user/search/?search=" + search_user).subscribe(
      data => {
        this.searching_user = false;
        this.search_results = data.results;
      },
      error => {
        this.searching_user = false;
      }
    );
  };

  getStatus(status: any) {
    if (status == undefined)
      return ''
    if (status == "agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "addendum_agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "activated_agreement_pending") {//use
      return "Sign agreement"
    }
    else if (status == "activated_addendum_agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "activated_payment_pending") { //use, Activate now-->Reg form with popu;ate fields
      return "Make payment"
    }
    else if (status == "activated_payment_done") { //use

      return "Registered"
    }
    else if (status == "activated_under_review") {

      return "Under Review"
    }
    else if (status == "activated_review_payment_pending") {

      return "Deactivated"
    }
    else {
      return status.replace(/\_/g, ' ').capitalize();
    }
  };

  selectUser(user: any) {
    this.selected_user = user;
    this.isSearch(false);
    this.getProjects();
  };

  ngOnInit(): void {
    $('body').click((e) => {
      if (e.target.classList.contains('arc-pro-search') || e.target.classList.contains('user-item')) {
        this.isSearch(true);
      }
      else {
        this.isSearch(false);
      }
    });
  }

}

import { Component, OnInit, TestabilityRegistry } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { Projects } from '../../../services/projects.service';
import { Config } from '../../../../../src/app/models/env-vars.model';

import * as moment from 'moment';

@Component({
  selector: 'app-play-to-zero',
  templateUrl: './play-to-zero.component.html',
  styleUrls: ['./play-to-zero.component.css']
})
export class PlayToZeroComponent implements OnInit {

  config = Config;
  current_date: any = moment(new Date()).format('MMM DD, YYYY');
  widget_loading: boolean = false;

  showContentModal(){
      this.widget_loading = true;
      var widget_url = "/assets/LEED:" + this.appData.get('leed_id') + "/advanced-scoring-widget/?program_name=gsa"
      var token = "&access-token=" + this.appData.get('token') + "&subscription-key=" +this.config.sub_key;
      var url = this.config.basic_api_url + widget_url + token;
      if(this.config.access == 'R')
      {
          url += "&readonly=true";
      }
      $('#advanced_scoring_widget').modal('show');
      $('#advanced_scoring_widget_iframe').attr('src', url)
  };

  constructor(
    public global: Global,
    private projects: Projects,
    public appData: AppData
  ) {
  }

  ngOnInit(): void {
  }

}


<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-12">
            <h1 class="page-title pl0 pb10">Downgrade Score Version to 3.0<span class="fw-semi-bold"></span>
            </h1>
            <section class="widget overflow-auto pt30">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-3 control-label">LEED ID</label>
                                <div class="col-sm-7" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsCert()" (focus)="checkInputsCert()" [(ngModel)]="leed_id"
                                        type="text" id="normal-field" class="form-control" placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" for="prepended-input"></label>
                                <div class="col-sm-7">
                                    <button (click)="returnReview()" [ngClass]="checkInputCert ? '' : 'not-active'"
                                        [disabled]="loaderCert"
                                        class="btn btn-default pull-left mr10">Downgrade</button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
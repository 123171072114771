<div class="content-wrap">
    <div id="content" class="content">
        <app-loader *ngIf="loading"></app-loader>
        <main [ngClass]="loading ? 'not-active' : ''" class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="">
            <div class="row">
                <main [hidden]="show_cert" class="view-animate fade-up overflow_y_initial full-screen-row" role="main" data-ui-view="">
                    <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                        <div class="align-center mt11 width-100-perc">
                            <h4 class="fw-500">Purchase Certificate</h4>            
                        </div>
                        <div class="pt20">
                            <svg (click)="goBack()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </div>
                    </nav>

                    <div class="payment-card certificate_pay">
                        <h3 *ngIf="type != 'climaterisk'" class="mt0 fw-500 flex mb30">
                            <div class="{{type}}_white_icon category_icon"></div>
                            {{label}} Performance Certificate
                        </h3>
                        <h3 *ngIf="type == 'climaterisk'" class="mt0 fw-500 flex mb30">
                            <!-- <div class="{{type}}_white_icon category_icon"></div> -->
                            {{label}} Certificate
                        </h3>

                        <div class="certificate_reporting_period">
                            <div class="align_lr">
                                <div class="w50p">
                                    <h4 class="mt0 mb20 fw-500">Select reporting period</h4>    
                                    <p class="small_label mb0">End date reporting period</p>    
                                    <div [ngInit]="initDatePicker('reportingDate')" id="details-form" class="w200px mb5">
                                        <div class="">
                                            <input [readonly]="true" class="date_field" max="M dd, yyyy" id="reportingDate" [(ngModel)]="reportingDate" name="reportingDate" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </div>    
                                    <a (click)="resetDate()" class="reset">Reset to Current Total Score</a>
                                </div>
                                <div style="position: relative;top: -5px;" class="w50p" *ngIf="total_errors > 0">
                                    <img src="assets/images/notdone_x_icon.svg" class="w25px mt-n3px ">
                                    <span style="position: relative;top: 3px;" class="fs18 fw-500 error-color">{{total_errors}} {{total_errors == 1 ? 'Error' : 'Errors'}}</span>
                                    <div class="">
                                        <a (click)="openScoringFactorHelp()" class="reset">How do I correct my scoring factors?</a>
                                    </div>
                                </div>
                            </div>

                            <div class="align_lr">
                                <h4 class="mt20 mb10 fw-500">Scoring Factors
                                </h4>
                                <div class="pt20">
                                    <a (click)="global.openLink('https://arc.gbci.org/automated-tests-arc-performance-certificates')" class="reset">Learn about Arc scoring factors</a>
                                </div>
                            </div>
                            <div class="requirements flex mb35">
                                <div class="data_metric" *ngIf="type == 'climaterisk'">
                                    <div class="category">Arc Carbon Score</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.performance.score, 0, true)}}<span class="maxima">/100
                                            <sup *ngIf="global.isNA(data_coverage.performance.score)" class="">
                                                <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('na_help')"></span>
                                            </sup>
                                        </span></div>
                                        <div *ngIf="!global.isNA(data_coverage.performance.score)" class="status">
                                            <img src="assets/images/done_green_icon.svg" class="w25px mt-n3px ">
                                        </div>
                                    </div>
                                </div>
                                <div class="data_metric" *ngIf="type == 'climaterisk'">
                                    <div class="category">Arc Electrification Score</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.electrification, 0, true)}}<span class="maxima">/100
                                            <sup *ngIf="global.isNA(data_coverage.electrification)" class="">
                                                <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('na_help')"></span>
                                            </sup>
                                        </span></div>
                                        <div class="status"></div>
                                    </div>
                                </div>
                                <div class="data_metric" *ngIf="type != 'climaterisk'">
                                    <div class="category">Performance Score</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.performance.score, 0, true)}}<span class="maxima">/100
                                            <sup *ngIf="global.isNA(data_coverage.performance.score)" class="">
                                                <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('na_help')"></span>
                                            </sup>
                                        </span></div>
                                        <div class="status">
                                            <img src="assets/images/done_green_icon.svg" class="w25px mt-n3px ">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="data_metric" *ngIf="type != 'climaterisk'">
                                    <div class="category">Improvement Score</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.improvement.score, 0, true)}}<span class="maxima">/100
                                            <sup *ngIf="global.isNA(data_coverage.improvement.score)" class="">
                                                <span class="fa fa-question-circle fs12 cursor-pointer" aria-hidden="true" (click)="global.showHelpText('na_help')"></span>
                                            </sup>
                                        </span></div>
                                        <div class="status"></div>
                                    </div>
                                </div> -->
                                <div class="data_metric">
                                    <div class="category">Data Completion</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.percentage, 2, true)}}%</div>
                                        <div class="status">
                                            <img *ngIf="data_coverage.coverage_flag == 'red'" src="assets/images/notdone_x_icon.svg" class="w25px mt-n3px ">
                                            <img *ngIf="data_coverage.coverage_flag != 'red'" src="assets/images/done_green_icon.svg" class="w25px mt-n3px ">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="data_metric" *ngIf="type != 'transportation'">
                                    <div class="category">Documentation</div>
                                    <div class="reading">
                                        <div class="value">{{global.formatNumber(data_coverage.documents, 0)}}</div>
                                        <div class="status">
                                            <i *ngIf="data_coverage.documents > 0" class="fa fa-paperclip"></i>
                                            <img *ngIf="data_coverage.documents == 0" src="assets/images/notdone_x_icon.svg" class="w25px mt-n3px ">
                                            <img *ngIf="data_coverage.documents > 0" src="assets/images/done_green_icon.svg" class="w25px mt-n3px ">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="data_metric">
                                    <div class="category">Outlier Check</div>
                                    <div class="reading">
                                        <div class="value fontSize16" *ngIf="!data_coverage.validation">Not Valid</div>
                                        <div class="value fontSize16" *ngIf="data_coverage.validation">Acceptable</div>
                                        <div class="status">
                                            <img *ngIf="!data_coverage.validation" src="assets/images/notdone_x_icon.svg" class="w25px mt-n3px ">
                                            <img *ngIf="data_coverage.validation" src="assets/images/done_green_icon.svg" class="w25px mt-n3px ">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div [ngClass]="received_data_for_all ? '' : 'not-active'" class="flex">
                                <button (click)="submit()" class="btn w180px fontSize16 btn-primary mr30">Continue</button>
                                <button [ngClass]="data_coverage.performance.score == null ? 'not-active' : ''" (click)="toggleCertiView()" class="btn fontSize16 btn-primary inverse">Preview Certificate</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="type != 'climaterisk'" style="background-color: white;">
                        <app-widget style="border: none;width: 73%;padding-left: 10px;" [leed_id]="appData.get('leed_id')" [src]="'arcaveragescore'" [param]="'&type=' + type.replace('_', '').replace('transportation', 'transport')" [width]="'63%'" [height]="'375px'" [END]="global.changeDateFormat(reportingDate, 'YYYY-MM-DD')" [START]="global.changeDateFormat(reportingDate, 'YYYY-MM-DD')"></app-widget>
                        <app-widget style="border: none;width: 100%;margin-top: -5px;" class="improvement_web" [leed_id]="appData.get('leed_id')" [src]="'improvement'" [param]="'&type=' + type.replace('_', '').replace('transportation', 'transport')" [width]="'98.5%'" [height]="'375px'" [END]="global.changeDateFormat(reportingDate, 'YYYY-MM-DD')" [START]="global.changeDateFormat(reportingDate, 'YYYY-MM-DD')"></app-widget>
                    </div>
                </main>

                <main [hidden]="!show_cert" class="view-animate fade-up overflow_y_initial full-screen-row" role="main" data-ui-view="">
                    <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                        <div class="align-center mt11 width-100-perc">
                            <h4 class="fw-500">Preview Label</h4>            
                        </div>
                        <div class="pt20">
                            <svg (click)="goBack()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </div>
                    </nav>

                    <div id="certificate_final">
                        <div id="certificate_score">
                            <div class="certificate" id="myCanvas">    
                                <div id="cert_banner" class="cert_banner {{type}}_banner_gradient">
                                    <div class="cert_appLogo"></div>
                                </div>
                                <div id="cert_content">
                                    <div class="heading1">ARC SKORU RECOGNIZES</div>
                                    <h2 id="project_name">{{appData.get('buildingData').name}}</h2>
                                    <div id="project_address">{{appData.get('buildingData').street}}</div>
                                    <div class="heading2">AWARDED FOR DEMONSTRATED PERFORMANCE IN:</div>
                                    <div id="title" class="category">{{title}}</div>
                            
                                    <div class="metrics">
                                        <div *ngIf="type == 'climaterisk'" class="main_score">
                                            <div class="heading">Arc Carbon Score</div>
                                            <div class="cert_block"></div>
                                            <h2 class="value">
                                                <span id="score">{{global.formatNumber(data_coverage.performance.score, 0, true)}}</span>/100
                                            </h2>
                                        </div>
                                        <div *ngIf="type == 'climaterisk'" class="main_score">
                                            <div class="heading">Arc Electrification Score</div>
                                            <div class="cert_block"></div>
                                            <h2 class="value">
                                                <span id="score">{{global.formatNumber(data_coverage.electrification, 0, true)}}</span>/100
                                            </h2>
                                        </div>
                                        <div *ngIf="type == 'climaterisk'" class="main_score">
                                            <div class="heading">RiskFootprint</div>
                                            <div class="cert_block"></div>
                                            <h2 class="value">
                                                <span id="score">{{data_coverage.risk_report ? 'Yes' : 'No'}}</span>
                                            </h2>
                                        </div>
                                        <div *ngIf="type != 'climaterisk'" class="main_score">
                                            <div class="heading">Performance Score</div>
                                            <div class="cert_block"></div>
                                            <h2 class="value">
                                                <span id="score">{{global.formatNumber(data_coverage.performance.score, 0, true)}}</span>/100
                                            </h2>
                                        </div>
                                        <div *ngIf="type == 'energy'" class="key_metrics energy">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Site Energy Intensity</span>
                                                <span class="right"><span class="cert_bold" id="site_energy_intensity">{{global.number_notation(data_coverage.performance.site_energy_intensity, 4)}}</span> {{selected_energy_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Source Energy Intensity</span>
                                                <span class="right"><span class="cert_bold" id="source_energy_intensity">{{global.number_notation(data_coverage.performance.source_energy_intensity, 4)}}</span> {{selected_energy_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">GHG Emissions</span>
                                                    <span class="right"><span class="cert_bold" id="raw_ghg">{{global.number_notation(data_coverage.performance.raw_ghg, 2)}}</span> mTCO2e</span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">GHG Intensity</span>
                                                    <span class="right"><span class="cert_bold" id="raw_ghg_intensity">{{global.number_notation(data_coverage.performance.raw_ghg_intensity, 4)}}</span> mTCO2e/{{selected_area_unit}}</span>
                                                </div>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'water'" class="key_metrics water">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Water Use</span>
                                                <span class="right"><span class="cert_bold" id="water_use">{{global.number_notation(data_coverage.performance.water_use, 2)}}</span> {{selected_volume_unit}}</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Water Use Intensity</span>
                                                <span class="right"><span class="cert_bold" id="water_use_intensity">{{global.number_notation(data_coverage.performance.water_use_intensity, 4)}}</span> {{selected_volume_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="data rt10">
                                                <span class="left">Water Use per occupant</span>
                                                <span class="right"><span class="cert_bold" id="water_use_occ">{{global.number_notation(data_coverage.performance.water_use_occ, 2)}}</span> {{selected_volume_unit}}/occupant</span>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'waste'" class="key_metrics waste">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Waste Generated</span>
                                                <span class="right"><span class="cert_bold" id="waste_generated">{{global.number_notation(data_coverage.performance.waste_generated, 2)}}</span> {{selected_weight_unit}}</span>
                                            </div>
                                            <div class="data mb27">
                                                <span class="left">Waste Generation per occ</span>
                                                <span class="right"><span class="cert_bold" id="waste_generated_occ">{{global.number_notation(data_coverage.performance.waste_generated_occ, 2)}}</span> {{selected_weight_unit}}/occupant</span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">Total Waste Diverted</span>
                                                    <span class="right"><span class="cert_bold" id="waste_diverted">{{global.number_notation(data_coverage.performance.waste_diverted, 2)}}</span> {{selected_weight_unit}}</span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">Waste Diversion per occ</span>
                                                    <span class="right"><span class="cert_bold" id="waste_diverted_occ">{{global.number_notation(data_coverage.performance.waste_diverted_occ, 2)}}</span> {{selected_weight_unit}}/occupant</span>
                                                </div>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'transportation'" class="key_metrics transportation">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Emissions</span>
                                                <span class="right"><span class="cert_bold" id="transit_co2e">{{global.number_notation(data_coverage.performance.transit_co2e, 2)}}</span> mTCO2e</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Average one-commute distance</span>
                                                <span class="right"><span class="cert_bold" id="transit_distance">{{global.number_notation(data_coverage.performance.transit_distance, 2)}}</span> {{selected_distance_unit}}</span>
                                            </div>
                                            <div class="data rt10">
                                                <span class="left">Average one-way <br> commute emissions</span>
                                                <span class="right"><span class="cert_bold" id="avg_transit_co2e">{{global.number_notation(data_coverage.performance.avg_transit_co2e, 2)}}</span> mTCO2e</span>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'human_experience'" class="key_metrics human_experience">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Number of regular occupants</span>
                                                <span class="right"><span class="cert_bold" id="regular_occupant_perc">{{global.number_notation(data_coverage.performance.regular_occupant_perc, 2)}}</span> %</span>
                                            </div>
                                            <div class="data mb27">
                                                <span class="left">Median satisfaction</span>
                                                <span class="right"><span class="cert_bold" id="satisfaction_median">{{data_coverage.performance.satisfaction_median}}</span></span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">CO2 level(95th percentile)</span>
                                                    <span class="right"><span class="cert_bold" id="co2">{{global.number_notation(data_coverage.performance.co2, 2)}}</span></span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">Maximum TVOC level</span>
                                                    <span class="right"><span class="cert_bold" id="voc">{{global.number_notation(data_coverage.performance.voc, 2)}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div class="heading2 mb5">THIS CERTIFICATE COVERS A PERFORMANCE PERIOD BETWEEN</div>
                                    <div class="heading3 mb5 bold" id="reporting_period">{{reporting_period}}</div>
                                    <div *ngIf="data_coverage.validation && data_coverage.coverage_flag == 'green'" class="heading2">AND THE PROJECT PASSED ALL DATA TESTS FOR THIS PERIOD,<br> AND THE CERTIFICATE IS VALID UNTIL <span class="bold" id="expiry_date">{{expiry_date}}</span></div>
                                    <div *ngIf="!(data_coverage.validation && data_coverage.coverage_flag == 'green')" class="heading2" class="heading2">AND THE PROJECT DID NOT PASS ALL DATA TESTS FOR THIS PERIOD.<br> THIS CERTIFICATE MAY NOT REPRESENT PERFORMANCE DURING THIS PERIOD.</div>
                            
                                    <div class="footer">
                                        <div class="signature"></div>
                                        <div class="heading4 mb0 cert_grey">PETER TEMPLETON</div>
                                        <div class="heading3 mb0 cert_grey">PRESIDENT & CEO, ARC SKORU, INC.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style="page-break-before: always" ></p>
                        <div *ngIf="type !='climaterisk'" id="certificate_improvement">
                            <div class="certificate" id="myCanvas">    
                                <div id="cert_banner" class="cert_banner {{type}}_banner_gradient">
                                    <div class="cert_appLogo"></div>
                                </div>
                                <div id="cert_content">
                                    <div class="heading1">ARC SKORU RECOGNIZES</div>
                                    <h2 id="project_name">{{appData.get('buildingData').name}}</h2>
                                    <div id="project_address">{{appData.get('buildingData').street}}</div>
                                    <div class="heading2">AWARDED FOR DEMONSTRATED PERFORMANCE IN:</div>
                                    <div id="title" class="category">{{title}}</div>
                            
                                    <div class="metrics">
                                        <div class="main_score">
                                            <div class="heading">Improvement Score</div>
                                            <div class="cert_block"></div>
                                            <h2 class="value">
                                                <span id="score">{{global.formatNumber(data_coverage.improvement.score, 0, true)}}</span>/100
                                            </h2>
                                        </div>
                                        <div *ngIf="type == 'energy'" class="key_metrics energy">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Site Energy Intensity</span>
                                                <span class="right"><span class="cert_bold" id="site_energy_intensity">{{global.number_notation(data_coverage.improvement.site_energy_intensity, 4)}}</span> {{selected_energy_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Source Energy Intensity</span>
                                                <span class="right"><span class="cert_bold" id="source_energy_intensity">{{global.number_notation(data_coverage.improvement.source_energy_intensity, 4)}}</span> {{selected_energy_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">GHG Emissions</span>
                                                    <span class="right"><span class="cert_bold" id="raw_ghg">{{global.number_notation(data_coverage.improvement.raw_ghg, 2)}}</span> mTCO2e</span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">GHG Intensity</span>
                                                    <span class="right"><span class="cert_bold" id="raw_ghg_intensity">{{global.number_notation(data_coverage.improvement.raw_ghg_intensity, 4)}}</span> mTCO2e/{{selected_area_unit}}</span>
                                                </div>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'water'" class="key_metrics water">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Water Use</span>
                                                <span class="right"><span class="cert_bold" id="water_use">{{global.number_notation(data_coverage.improvement.water_use, 2)}}</span> {{selected_volume_unit}}</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Water Use Intensity</span>
                                                <span class="right"><span class="cert_bold" id="water_use_intensity">{{global.number_notation(data_coverage.improvement.water_use_intensity, 4)}}</span> {{selected_volume_unit}}/{{selected_area_unit}}</span>
                                            </div>
                                            <div class="data rt10">
                                                <span class="left">Water Use per occupant</span>
                                                <span class="right"><span class="cert_bold" id="water_use_occ">{{global.number_notation(data_coverage.improvement.water_use_occ, 2)}}</span> {{selected_volume_unit}}/occupant</span>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'waste'" class="key_metrics waste">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Waste Generated</span>
                                                <span class="right"><span class="cert_bold" id="waste_generated">{{global.number_notation(data_coverage.improvement.waste_generated, 0)}}</span> {{selected_weight_unit}}</span>
                                            </div>
                                            <div class="data mb27">
                                                <span class="left">Waste Generation per occ</span>
                                                <span class="right"><span class="cert_bold" id="waste_generated_occ">{{global.number_notation(data_coverage.improvement.waste_generated_occ, 2)}}</span> {{selected_weight_unit}}/occupant</span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">Total Waste Diverted</span>
                                                    <span class="right"><span class="cert_bold" id="waste_diverted">{{global.number_notation(data_coverage.improvement.waste_diverted, 2)}}</span> {{selected_weight_unit}}</span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">Waste Diversion per occ</span>
                                                    <span class="right"><span class="cert_bold" id="waste_diverted_occ">{{global.number_notation(data_coverage.improvement.waste_diverted_occ, 2)}}</span> {{selected_weight_unit}}/occupant</span>
                                                </div>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'transportation'" class="key_metrics transportation">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Total Emissions</span>
                                                <span class="right"><span class="cert_bold" id="transit_co2e">{{global.number_notation(data_coverage.improvement.transit_co2e, 4)}}</span> mTCO2e</span>
                                            </div>
                                            <div class="data">
                                                <span class="left">Average one-commute distance</span>
                                                <span class="right"><span class="cert_bold" id="transit_distance">{{global.number_notation(data_coverage.improvement.transit_distance, 2)}}</span> {{selected_distance_unit}}</span>
                                            </div>
                                            <div class="data rt10">
                                                <span class="left">Average one-way <br> commute emissions</span>
                                                <span class="right"><span class="cert_bold" id="avg_transit_co2e">{{global.number_notation(data_coverage.improvement.avg_transit_co2e, 2)}}</span> mTCO2e</span>
                                            </div>
                                        </div>
                            
                                        <div *ngIf="type == 'human_experience'" class="key_metrics human_experience">
                                            <div class="heading">Key Performance Indicators</div>
                                            <div class="cert_block"></div>
                                            <div class="data">
                                                <span class="left">Number of regular occupants</span>
                                                <span class="right"><span class="cert_bold" id="regular_occupant_perc">{{global.number_notation(data_coverage.improvement.regular_occupant_perc, 2)}}</span> %</span>
                                            </div>
                                            <div class="data mb27">
                                                <span class="left">Median satisfaction</span>
                                                <span class="right"><span class="cert_bold" id="satisfaction_median">{{data_coverage.improvement.satisfaction_median}}</span></span>
                                            </div>
                                            <div class="rt10">
                                                <div class="data">
                                                    <span class="left">CO2 level(95th percentile)</span>
                                                    <span class="right"><span class="cert_bold" id="co2">{{global.number_notation(data_coverage.improvement.co2, 2)}}</span></span>
                                                </div>
                                                <div class="data">
                                                    <span class="left">Maximum TVOC level</span>
                                                    <span class="right"><span class="cert_bold" id="voc">{{global.number_notation(data_coverage.improvement.voc, 2)}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div class="heading2 mb5">THIS CERTIFICATE COVERS A PERFORMANCE PERIOD BETWEEN</div>
                                    <div class="heading3 mb5 bold" id="reporting_period">{{reporting_period}}</div>
                                    <div *ngIf="data_coverage.validation && data_coverage.coverage_flag == 'green'" class="heading2">AND THE PROJECT PASSED ALL DATA TESTS FOR THIS PERIOD,<br> AND THE CERTIFICATE IS VALID UNTIL <span class="bold" id="expiry_date">{{expiry_date}}</span></div>
                                    <div *ngIf="!(data_coverage.validation && data_coverage.coverage_flag == 'green')" class="heading2">AND THE PROJECT DID NOT PASS ALL DATA TESTS FOR THIS PERIOD.<br> THIS CERTIFICATE MAY NOT REPRESENT PERFORMANCE DURING THIS PERIOD.</div>
                            
                                    <div class="footer">
                                        <div class="signature"></div>
                                        <div class="heading4 mb0 cert_grey">PETER TEMPLETON</div>
                                        <div class="heading3 mb0 cert_grey">PRESIDENT & CEO, ARC SKORU, INC.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </main>

    </div>
</div>
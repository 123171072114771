import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';
import { Projects } from '../services/projects.service';

import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-claim-school',
  templateUrl: './claim-school.component.html',
  styleUrls: ['./claim-school.component.css']
})
export class ClaimSchoolComponent implements OnInit
{
	config = Config;
	loading: boolean = false;
	searchInit: boolean = false;
	searching: boolean = false;

	query: string = '';
	
	myschools: any = [];
	canceler: any;
	searchParams: any = {
		'page_number': 1,
		'page_size': 10,
		'order': 'asec',
		'category': 'sch_name',
		'query': '',
		'total': '',
		'total_pages': '',
	};

	constructor(
		public appData: AppData,
		public global: Global,
		private api: API,
		private projects: Projects
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
			});
		}
	}

	goBack()
	{
		this.global.goToApp('projects');
	}

	toTitleCase(str: string) 
	{
		return str.replace(/\w\S*/g, function(txt)
		{
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	getSchoolStatus(school: any)
	{
		return this.toTitleCase(school.permission.replace(/_/g , ' '))
	}

	getSchools(searchParams: any)
	{
		var page_number = searchParams.page_number;
		var page_size = searchParams.page_size;
		var order = searchParams.order;
		var category = searchParams.category;
		var query = encodeURIComponent(searchParams.query);
		
		this.searching = true;
		this.projects.getSchoolDetails(page_number, page_size, order, category, query).subscribe(
			data =>
			{
				this.myschools = data.results;
				this.searchParams.total = data.count;
				this.searchParams.total_pages = Math.ceil(data.count / this.searchParams.page_size);
				this.searching = false;
			},
			error =>
			{
				this.searching = false;
			}
		);
	}

	changeSortOrder(category: string)
	{
		if(this.searchParams.category == category)
		{
			this.searchParams.order = this.searchParams.order == 'desc' ? 'asec' : 'desc';
		}
		else
		{
			this.searchParams.category = category;
			this.searchParams.order = 'asec';
		}
		this.getSchools(this.searchParams);
	}

	filter_moveToProject(school: any, index: number)
	{
		if(school.permission == 'request_accepted')
		{
			this.searching = true;
			this.global.searchMoveToProject(school.building, index);
			setTimeout(() =>
			{
				this.searching = false;
			}, 5000);
		}
	}

	reqAccess(school: any)
	{
		var leed_id = school.building.leed_id;
		var data = {"user_name": this.appData.get('user_id')};
		this.searching = true;

		this.api.post('/assets/LEED:' + leed_id + '/permissions/requests/', data).subscribe(
			//success handler
			response =>
			{
				this.searching = false;
				school.permission = 'request_pending';
			},
			//error handler
			response =>
			{
				this.searching = false;
			}   
		);
	}

	addProject(school: any)
	{
		this.global.goToApp('forms',
		{
			"app_name": "project_creation",
			'school_id': school.id,
			'project_type': 'school',
			'name': school.sch_name,
			'street': school.mstreet1,
			'city': school.mcity,
			'state': school.st,
			'zip_code': school.mzip,
			'country': 'US'
		});
	}

	changeSearchParams(page_number: number, page_size: number, order: string, category: string, query: string)
	{
		if(page_number == 0 || page_number > this.searchParams.total_pages)
		{
			return
		}
		this.searchParams.page_number = page_number;
		this.searchParams.page_size = page_size;
		this.searchParams.order = order;
		this.searchParams.category = category;
		this.searchParams.query = query;
		this.getSchools(this.searchParams);
	}

	searchSchool(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}

		var url = '/assets/unclaimedschools/';
		this.searchParams.query = encodeURIComponent(query);
		url += '?order=' + this.searchParams.order + '&category=' + this.searchParams.category + '&page_size=' + this.searchParams.page_size + '&page=' + this.searchParams.page_number + '&q=' + this.searchParams.query;

		this.searching = true;

		this.canceler = this.api.get(url).subscribe(
			data =>
			{
				this.searchInit = true;
				this.myschools = data.results;
				this.searchParams.total = data.count;
				this.searchParams.total_pages = Math.ceil(data.count / this.searchParams.page_size);
				this.searching = false;
			},
			error =>
			{
				this.searching = false;
			}
		);
	}

}

<div>
	<div>
       
        <div>

            <div [ngClass]="loading_data ? 'not-active' : ''" class="pl15 pr15">
                <app-loader *ngIf="loading_data"></app-loader>
                
                <app-insight-search (search_complete)="searchData($event)"></app-insight-search>

                <div class="insight_widget insight_project_reduction">
                    <div class="fs-500 line_height_24 fs18 mb15">
                        Design Performance
                    </div>
                    <div class="line_height_20 mb15 fs15">
                        Comparing your project to <span class="fw-500">{{data.credits.total_projects}} LEED New Construction</span> projects with <span class="fw-500">{{range_in_mile}} miles of {{zipcode}}</span>
                    </div>
                    <div class="legend_wrapper">
                        <div class="estimated_legend">
                            <div class="sprite">
                                <div class="small_sprite"></div>    
                            </div>    
                            <span>Estimated Value</span>    
                        </div>
                        <div class="average_legend">
                            <div class="sprite"></div>
                            <span>Local Average Score</span>  
                        </div>
                        <div class="range_legend">
                            <div class="sprite"></div>
                            <span>Range</span>  
                        </div>
                    </div>

                    <div class="reduction_category zepi_score_category p80_0px">
                        <div class="metric">
                            <div class="fw-500 fs20 line_height_18 mb25">zEPI score</div>
                            <div class="fs30 line_height_32">{{global.formatNumber(zepi_score, 2)}}</div>
                            <div class="fs14 line_height_24 grey_color">Current zEPI Score</div>
                        </div>
                        <div class="w70p" style="position: relative;top: -50px;">
                            <div class="chart w100p" id="zepi_score_category"></div>
                            <div class="zepi_labels relative-position">
                                <div class="zepi_gradient"></div>
                                <div class="zepi_gradient_100 zepi_gradient_label">
                                    <p>|</p>
                                    Avg. performance of U.S. <br> building stock of 2000
                                </div>
                                <div class="zepi_gradient_60 zepi_gradient_label">
                                    <p>|</p>
                                    ASHRAE <br> 90.1 2010
                                </div>
                                <div class="zepi_gradient_50 zepi_gradient_label">
                                    <p>|</p>
                                    LEED v4 Prerequisite
                                </div>
                                <div class="zepi_gradient_0 zepi_gradient_label">
                                    <p>|</p>
                                    Net Zero Energy
                                </div>
                                <div style="top: 85px;position: relative;" class="line_height_24 mt8 align-center fw-500">zEPI score</div>
                            </div>
                        </div>
                    </div>
                    <div class="reduction_category water_category">
                        <div class="metric">
                            <div class="fw-500 fs20 line_height_18 mb25">Average Water reduction</div>
                            <div class="fs30 line_height_32">{{water_estimate}}%</div>
                            <div class="fs14 line_height_24 grey_color">Percent reduction below EPAct 1992</div>
                        </div>
                        <div class="w70p">
                            <div class="chart w100p" id="water_category"></div>
                            <div class="line_height_24 align-center fw-500">Percent reduction</div>
                        </div>
                    </div>
                    <div class="reduction_category waste_category">
                        <div class="metric">
                            <div class="fw-500 fs20 line_height_18 mb25">Average Waste diversion</div>
                            <div class="fs30 line_height_32">-{{waste_estimate}}%</div>
                            <div class="fs14 line_height_24 grey_color">Percent estimated diversion</div>
                        </div>
                        <div class="w70p">
                            <div class="chart w100p" id="waste_category"></div>
                            <div class="line_height_24 align-center fw-500">Percent diversion</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>

<div class="mr15 ml15">
	<div>
        
        <div>
            <div class="models_wrapper" [ngClass]="loading ? 'not-active' : ''" style="background-color: white;">
                <app-loader *ngIf="loading"></app-loader>
                <div class="align_lr flex-end">
                    <div style="padding: 15px 24px;">
                        <div (click)="exportProjects()" id="search_overview" class="ml20 btn btn-default flex">
                            <i style="width: 15px;height: 18px;" class="mr10 pt5 fa fa-download"></i>
                            <span>Export Models</span>
                        </div>
                    </div>
                </div>
                <div class="models_list">
                    <div class="models_list_header gray_color_707479">
                        <div>ID</div>
                        <div>Project Name</div>
                        <div>Versions</div>
                        <div>Zip Code</div>
                        <div>Space Type</div>
                    </div>
                    <div *ngIf="assets.results.length == 0" class="noProjects pb20">
                        No models created. Click 'Create a model' to get started.
                    </div>
                    <div class="parent_model_list" *ngFor="let project of assets.results" [ngClass]="project.show_versions ? 'show' : ''">
                        <div class="models_list_row" (click)="showVersions(project)">
                            <div class="data_item">
                                <span class="ellipsis">{{project.leed_id}}</span>
                            </div>
                            <div class="data_item">
                                <span class="ellipsis fw-500">{{project.name}}
                                    <span class="fs13 gray_color_707479"> - {{project.rating_system}}</span>
                                </span>
                            </div>
                            <div class="data_item">
                                {{project.versions.length}}                        
                            </div>
                            <div class="data_item">
                                {{project.zip_code}}
                            </div>
                            <div class="data_item">
                                {{project.spaceType}}
                                <div class="floatr" *ngIf="!project.show_versions">
                                    <i class="fa fa-angle-down fs20 floatr"></i>
                                </div>
                                <div class="floatr" *ngIf="project.show_versions">
                                    <i class="fa fa-angle-up fs20 floatr"></i>
                                </div>
                            </div>
                        </div>
                        <div class="version_list">
                            <div class="models_list_header">
                                <div></div>
                                <div>Version Name</div>
                                <div>Created on</div>
                                <div>Version Status</div>
                                <div></div>
                            </div>
                            <div (click)="goToModel('project_reduction', {'project_id': project.leed_id, 'project': project, 'selected_version': version, 'version': version.version_id})" class="models_list_row" *ngFor="let version of project.versions">
                                <div class="data_item">
                                    
                                </div>
                                <div class="data_item version_name">
                                    <span>{{version.version_name}}</span>
                                </div>
                                <div class="data_item">{{global.dateFormat(version.created_at)}}</div>
                                <div class="data_item">
                                    <span class="current_version_label" *ngIf="project.version == version.version_id">Current Version</span>
                                </div>
                                <div class="data_item"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

import { Directive, Input, HostListener, ElementRef } from '@angular/core';

import { Global } from '../services/global.service';

@Directive({
  selector: '[inputType]'
})
export class InputTypeDirective
{
	@Input() inputType: string = '';

	constructor(
		private element: ElementRef,
		private global: Global
	)
	{

	}

	@HostListener('keypress', ['$event'])
	onKeyPress(event: any)
    {
        if (this.inputType === 'int')
        {
            this.intOnly(event);
        }
        else if (this.inputType === 'float')
        {
            this.floatOnly(event);
        }
    }

    private intOnly(event: any)
    {
        const e = < KeyboardEvent > event;
        const code = (e.which) ? e.which : e.keyCode;
        if (
			[8, 9, 13, 27].indexOf(code) !== -1 ||
            (code === 65 && e.ctrlKey === true) ||
            (code === 67 && e.ctrlKey === true) ||
            (code === 86 && e.ctrlKey === true) ||
            (code === 88 && e.ctrlKey === true)
		)
        {
			return;
        }
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1)
        {
			e.preventDefault();
        }
    }

    private floatOnly(event: any)
    {
        const e = < KeyboardEvent > event;
        const code = (e.which) ? e.which : e.keyCode;
        if (
			[8, 9, 13, 27].indexOf(code) !== -1 ||
			(code == 46 && event.target.value.indexOf(".") == -1) ||
            (code === 65 && e.ctrlKey === true) ||
            (code === 67 && e.ctrlKey === true) ||
            (code === 86 && e.ctrlKey === true) ||
            (code === 88 && e.ctrlKey === true)
		)
        {
			return;
        }
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1)
        {
            e.preventDefault();
        }
    }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import 'moment-timezone';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-performance-date-picker',
  templateUrl: './performance-date-picker.component.html',
  styleUrls: ['./performance-date-picker.component.css']
})
export class PerformanceDatePickerComponent implements OnInit
{
	config = Config;
	weekdays = moment.weekdaysMin();
	calendar_matrix: any;
	change_range: string = '';
	@Output() success = new EventEmitter<any>();
	@Output() cancel = new EventEmitter<any>();
	@Input() type: string = '';
	@Input() mode: string = 'project';
	cal_id: string = this.global.generateString(5);

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private alertService: alertService
	)
	{}

	ngOnInit(): void
	{
		if(this.mode == 'project')
		{
			this.selectMonth(undefined, this.appData.get('buildingData').performance_end_date);
			this.initRanges(this.appData.get('buildingData'), this.appData.get('buildingData').performance_end_date);
		}
		else
		{
			this.selectYear(undefined, this.appData.get('portfolio').performance_end_date);
			this.initRanges(this.appData.get('portfolio'), this.appData.get('portfolio').performance_end_date);
		}
	}

	addHighlights(day: any)
	{
		let attr = "";
		if(day.isBetween(this.config.range_start, this.config.range_end, undefined, '[]'))
		{
			attr += "selected ";
		}
		if(day.isSame(this.config.range_start.format('YYYY-MM-DD')))
		{
			attr += "start ";
		}
		if(day.isSame(this.config.range_end.format('YYYY-MM-DD')))
		{
			attr += "end ";
		}
		if(day.isBefore(this.config.range_month.startOf('month').format('YYYY-MM-DD')) || day.isAfter(this.config.range_month.endOf('month').format('YYYY-MM-DD')))
		{
			attr += "old ";
		}
		return attr;
	}

	addMonthHighlights(day: any)
	{
		if(Config.range_start == undefined)
		{
			return;
		}
		var attr = "";
		var start = Config.range_start.clone();
		var end = Config.range_end.clone();
		if(day.isBetween(start, end, undefined, '[]'))
		{
			attr += "selected ";
		}
		if(day.isSame(start.format('YYYY-MM-DD'), 'month'))
		{
			attr += "start ";
		}
		if(day.isSame(end.format('YYYY-MM-DD'), 'month'))
		{
			attr += "end ";
		}
		if(day.isBefore(start.startOf('year').format('YYYY-MM-DD'), 'month') || day.isAfter(end.endOf('year').format('YYYY-MM-DD'), 'month'))
		{
			attr += "old ";
		}
		return attr;
	};

	initRanges(project: any, end: any)
	{
		if(!moment(end).isValid())
		{
			end = new Date();
		}
		var start = moment(end).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
		if(this.mode == 'project')
		{
			this.config.range_start = moment(start);
			this.config.range_end = moment(end);
			try
			{
				this.config.show_last_certified = false;
				if(project.certifications.length > 0)
				{
					this.config.show_last_certified = true;
					this.config.last_certified_date = moment(project.certifications[project.certifications.length - 1].date_certified).format('YYYY-MM-DD');
				}
			}
			catch(e){};

			if(end == null || moment(moment(end).format('YYYY-MM-DD')).isSame(moment(new Date()).format('YYYY-MM-DD')))
			{
				this.appData.set('selected_range', 'Last 12 months');
				return;
			}
		}
		else
		{
			end = moment(end).endOf('month');
			this.config.range_end = end;
			this.config.range_start = moment(end).subtract(1, 'year').add(1, 'day');
			if(end == null || moment(moment(end).format('YYYY-MM-DD')).isSame(moment(new Date()).format('YYYY-MM-DD')))
			{
				this.appData.set('selected_range', 'Last 12 months');
				return;
			}
		}

		if(this.config.range_end.format('YYYY-MM-DD') == moment(new Date()).subtract(1, 'year').month('Dec').date(31).format('YYYY-MM-DD'))
		{
			this.appData.set('selected_range', 'End of calendar');
		}
		else if(this.config.range_end.format('YYYY-MM-DD') == this.config.last_certified_date)
		{
			this.appData.set('selected_range', 'Last certification date');
		}
		else
		{
			this.appData.set('selected_range', 'Custom date range...');
		}
	}

	selectRange(selected_range: string)
	{
		if(this.appData.get('user_role') != 'arc_pro' && this.config.EUserRole != 'GBCI Reviewer' && this.appData.get('buildingData').arctool_permission == false)
		{
			this.global.learnMore('arc_pro');
			return;
		}
		this.config.previous_range = this.appData.get('selected_range');
		this.config.previous_range_start = this.config.range_start;
		this.config.previous_range_end = this.config.range_end;
		this.appData.set('selected_range', selected_range);
		if(selected_range != 'Custom date range...')
		{
			return this.saveRanges();
		}
		else
		{
			$('#' + this.cal_id).slideToggle(300);
			let diff_in_view = this.config.range_end.diff(this.config.range_month, 'month'), op: string = '';
			if(diff_in_view < 0)
			{
				op = 'subtract';
			}
			else if(diff_in_view > 0)
			{
				op = 'add';
				diff_in_view += 1;
			}
			for(var i = 0; i < Math.abs(diff_in_view); i++)
			{
				if(this.mode == 'project')
				{
					this.selectMonth(op);
				}
				else
				{
					// this.selectYear(op, this.config.range_end);
				}
			}
		}
	}

	selectMonth(action: string | undefined, date?: any)
	{
		if(!moment(date).isValid())
		{
			date = moment(new Date()).format('YYYY-MM-DD');
		}
		$('.range_input').removeClass('not-active');
		if(date != undefined)
		{
			this.config.range_month = moment(date).startOf('month');
		}
		var range_month = this.config.range_month;
		if(action == 'subtract')
		{
			range_month = moment(this.config.range_month).clone().subtract(1, 'month').startOf('month');
		}
		else if(action == 'add')
		{
			range_month = moment(this.config.range_month).clone().add(1, 'month').startOf('month');
			if(range_month.isAfter(this.config.max_range.format('YYYY-MM-DD')))
			{
				$('.cursor_next').addClass('not-active');
				return;
			}
		}
		this.config.range_month = range_month;
		var days_in_month = this.config.range_month.daysInMonth();
		var calendar_month = [];
		for(var i = 0; i < days_in_month; i++)
		{
			var date = this.config.range_month.clone().add(i, 'day');
			var weekday = date.weekday(), n = 0;
			while(i == 0 && n < weekday)
			{
				calendar_month.push(date.clone().subtract(weekday - n, 'day'));
				n += 1;
			}
			calendar_month.push(date);
		}
		var last_day = calendar_month[calendar_month.length - 1].day(), n = 6, calendar_month_end = [];
		while(last_day < n)
		{
			calendar_month_end.push(calendar_month[calendar_month.length - 1].clone().add(n - last_day, 'day'));
			last_day += 1;
		}
		calendar_month = calendar_month.concat(calendar_month_end.reverse());
		var calendar_matrix = [], row = [];;
		for(var i = 0; i < calendar_month.length; i++)
		{
			row.push(calendar_month[i]);
			if(row.length == 7)
			{
				calendar_matrix.push(row);
				row = [];
			}
		}
		this.calendar_matrix = calendar_matrix;
	}

	selectDate(selectDate: any)
	{
		var range_end, range_start;
		range_end = selectDate;
		range_start = selectDate.clone().subtract(1, 'year').add(1, 'day');
		if(moment(range_end.format("YYYY-MM-DD")).isAfter(this.config.max_range.format('YYYY-MM-DD')))
		{
			this.alertService.alert('error', 'Allowed 12 month period should lie before ' + this.config.max_range.add(1, 'day').format('MMM DD, YYYY'), 5);
			return;
		}
		this.config.range_end = range_end;
		this.config.range_start = range_start;
	}

	getRanges()
	{
		let start, end;
		if(this.appData.get('selected_range') == 'Last 12 months')
		{
			if(this.mode == 'project')
			{
				end = moment(new Date()).format('YYYY-MM-DD');
			}
			else
			{
				end = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
			}
		}
		else if(this.appData.get('selected_range') == 'Last certification date')
		{
			end = this.config.last_certified_date;
			end = moment(end).format('YYYY-MM-DD');
		}
		else if(this.appData.get('selected_range') == 'End of calendar')
		{
			end = moment(new Date()).subtract(1, 'year').month('Dec').date(31).format('YYYY-MM-DD');
		}
		else
		{
			end = this.config.range_end.format('YYYY-MM-DD')
		}
		start = moment(end).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
		return ["&START="+ start + "&END=" + end, start, end];
	};

	saveEARanges()
	{
		let ranges = this.getRanges();

		let data = {
			"performance_start_date": ranges[1],
			"performance_end_date": ranges[2],
			"name": this.appData.get('portfolio').name
		}
		if(this.appData.get('selected_range') == 'Last 12 months')
		{
			data["performance_end_date"] = null;
			data["performance_start_date"] = null;
		}

		this.selectYear(undefined, moment(data["performance_end_date"]));
		this.initRanges(data, data["performance_end_date"]);
		this.closeModal();

		this.success.emit({
			"start_date": moment(Config.range_start).format('YYYY-MM-DD'), 
			"end_date": moment(Config.range_end).format('YYYY-MM-DD'),
			"previous_range_start": moment(Config.previous_range_start).format('YYYY-MM-DD'),
			"previous_range_end": moment(Config.previous_range_end).format('YYYY-MM-DD')
		});
	}

	saveRanges()
	{
		if(this.appData.get('is_admin'))
		{
			this.closeModal();
			this.success.emit('success');
			return;
		}

		if(this.mode == 'project')
		{
			this.config.app_loader = true;
			var ranges = this.getRanges();
			var data = {
				"performance_end_date": ranges[2],
				"name": this.appData.get('buildingData').name
			}
			if(this.appData.get('selected_range') == 'Last 12 months')
			{
				data["performance_end_date"] = null;
			}
			
			this.api.put(
				'/assets/LEED:' + this.appData.get('leed_id') + '/?recompute_score=True',
				data
			).subscribe(data =>
			{
				this.config.app_loader = false;
				this.appData.set('buildingData', data);
				// this.refreshWidgets();
				this.initRanges(data, data["performance_end_date"]);
				this.closeModal();
			}, error =>
			{
				this.config.app_loader = false;
			});
		}
		else
		{
			this.config.app_loader = true;
			let ranges = this.getRanges();

			let data = {
				"performance_start_date": ranges[1],
				"performance_end_date": ranges[2],
				"name": this.appData.get('portfolio').name
			}
			if(this.appData.get('selected_range') == 'Last 12 months')
			{
				data["performance_end_date"] = null;
				data["performance_start_date"] = null;
			}

			this.api.put(
				'/portfolios/ID:' + this.appData.get('portfolio_id') + '/',
				data
			).subscribe(data =>
			{
				this.config.app_loader = false;
				this.appData.set('portfolio', data);
				try
				{
					this.config.widget_data = {
						"queue": []
					}
				}
				catch(e){};
				// this.refreshWidgets();
				this.initRanges(data, data["performance_end_date"]);
				this.closeModal();
			}, error =>
			{
				this.config.app_loader = false;
			});
		}
		this.success.emit('success');
	}

	selectYear(action: string | undefined, date: any)
	{
		if(!moment(date).isValid())
		{
			date = moment(new Date()).startOf('year').format('YYYY-MM-DD');
		}
		else
		{
			date = moment(date).startOf('year').format('YYYY-MM-DD');
		}
		$('.range_input').removeClass('not-active');
		if(date != undefined)
		{
			this.config.range_month = moment(date).startOf('month');
		}
		var range_month = this.config.range_month;
		if(action == 'subtract')
		{
			range_month = moment(this.config.range_month).clone().subtract(1, 'year').startOf('month');
		}
		else if(action == 'add')
		{
			range_month = moment(this.config.range_month).clone().add(1, 'year').startOf('month');
			if(range_month.isAfter(this.config.max_range.format('YYYY-MM-DD')))
			{
				$('.cursor_next').addClass('not-active');
				return;
			}
		}
		this.config.range_month = range_month;
		var days_in_month = 12;
		var calendar_month = [];
		for(var i = 0; i < days_in_month; i++)
		{
			var date = this.config.range_month.clone().add(i, 'month').endOf('month');
			calendar_month.push(date);
		}
		var calendar_matrix = [], row = [];;
		for(var i = 0; i < calendar_month.length; i++)
		{
			row.push(calendar_month[i]);
			if(row.length == 3)
			{
				calendar_matrix.push(row);
				row = [];
			}
		}
		this.calendar_matrix = calendar_matrix;
	};

	hideCal()
	{
		this.appData.set('selected_range', this.config.previous_range);
		this.config.range_start = this.config.previous_range_start;
		this.config.range_end = this.config.previous_range_end;
		$('#' + this.cal_id).slideUp(300);
		this.cancel.emit('cancel');
	}

	closeModal()
	{
		$('#' + this.cal_id).slideUp(300);
		this.cancel.emit('cancel');
	}
}

<div>
	<div>
       
        <div>

            <div [ngClass]="loading_data ? 'not-active' : ''" class="pl15 pr15">
                <app-loader *ngIf="loading_data"></app-loader>
                
                <app-insight-search (search_complete)="searchData($event)"></app-insight-search>

                <div class="insight_widget insight_project_reduction">
                    <div class="fs-500 line_height_24 fs18 mb15">
                        Estimated LEED Points Comparison
                    </div>
                    <div class="line_height_20 mb15 fs15">
                        Comparing your project to <span class="fw-500">{{data.credits.total_projects}} LEED New Construction</span> projects with <span class="fw-500">{{range_in_mile}} miles of {{zipcode}}</span>
                    </div>
                    <div class="legend_wrapper">
                        <div class="estimated_legend">
                            <div class="sprite">
                                <div class="small_sprite"></div>    
                            </div>    
                            <span>Estimated Value</span>    
                        </div>
                        <div class="average_legend">
                            <div class="sprite"></div>
                            <span>Local Average Score</span>  
                        </div>
                        <div class="range_legend">
                            <div class="sprite"></div>
                            <span>Range</span>  
                        </div>
                    </div>

                    <table id="strategies_project_table" class="table table-striped arc-table arc-table-insight">
                        <thead>
                            <tr class="fs15">
                                <th class="w35p">LEED Category</th>
                                <th class="align-center w15p">Estimated Points</th>
                                <th class="align-center w50p">Comparisons</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr #score_card_elem [hidden]="credit.id == 'IN' || credit.id == 'RP' || credit.id == 'IP'" *ngFor="let credit of score_card">
                                <td class="fw-500">
                                    <img  style="width: 30px;height: 30px;" src="../../../assets/images/icons/leed/{{credit.id.toLowerCase()}}-border.png" class="ui rounded image">
                                    
                                    <span class="fs18">
                                        {{credit.id}} - {{credit.name}}
                                    </span>
                                </td>
                                <td class="points_td fw-500 fs18">
                                    <span>{{credit.attempted}}</span><span class="grey_color_light">/{{credit.max_points}}</span>
                                </td>
                                <td>
                                    <div class="chart" id="{{credit.id}}_category"></div>
                                </td>
                            </tr>				    
                        </tbody>
                    </table>
                </div>

            </div>
        </div>


    </div>
</div>

<div id="payment-details-wrapper">
    <app-loader *ngIf="loading"></app-loader>
    <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
        <div class="align-center mt11 width-100-perc">
            <h4 class="fw-500">Project Payment</h4>            
        </div>
        <div class="pt20">
            <svg [ngClass]="isPaymentFormSubmitted ? 'not-active' : ''" (click)="global.goBack(go_back)" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
            </svg>
        </div>
    </nav>

    <div class="flex" style="padding: 0px 125px;top: 64px;position: relative;">
        <div class="payment-new-content" [ngClass]="isPaymentFormSubmitted ? 'not-active' : ''">
            <div class="header mb25">
                {{appData.get('change_payment_method') ? 'Change Payment Method' : 'Purchase'}}
            </div>

            <div *ngIf="appData.get('integration') == undefined && appData.get('buildingData').project_type != 'parksmart'" class="mb25">

                <div class="payment-card" [ngClass]="syncing_project ? 'not-active' : ''">
                    <div id="details-form">
                        <table class="width-100-perc">
                            <thead>
                                <tr>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                </tr>
                            </thead>
                            <tbody class="table-margin-20">
                                <tr>
                                    <td colspan="1">
                                        <div class="">
                                            <span class="grey fontSize16 fw600">{{project_type_full_name}}</span>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="left-right-align">
                                            <span class="fw-400 fontSize16">Edit {{project_type_full_name}} Details</span>
                                            <!-- <i (click)="hideTab('building_registration')" [ngClass]="building_registration ? 'fa-chevron-up' : 'fa-chevron-down'" class="fa cursor-pointer"></i> -->
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td class="pt30" colspan="3">
                                        <div class="select-group group">   
                                            <label>Space Type</label>   
                                            <select [(ngModel)]="formdata.spaceType" name="spaceType">
                                                <option *ngFor="let type of spaceType" value="{{type}}">{{type}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td colspan="3">
                                        <div class="select-group group">   
                                            <label>Owner Type</label>   
                                            <select [(ngModel)]="formdata.ownerType" name="ownerType">
                                                <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td colspan="3" [ngClass]='isPricingValid() ? "not-active" : ""'>
                                        <div class="group" [ngClass]="formdata_error.organization.error ? 'has-error' : ''">      
                                            <input id="organization" (input)="searchOrganization(formdata.organization)" [(ngModel)]="formdata.organization" name="organization" type="text" required autocomplete="off">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>
                                                Owner Organization
                                            </label>
                                            <span class="error">{{formdata_error.organization.help}}</span>
                                            
                                            <span class="fontSize12">(<span (click)="openModal()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>
                
                                            <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization">
                                                <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name);">
                
                                                    <div class="cursor-pointer vertical_align_middle">          
                                                        <span>{{org_name}}</span>
                                                    </div>
                
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td colspan="3" [ngClass]='isPricingValid() ? "not-active" : ""'>
                                        <div class="group" [ngClass]="formdata_error.owner_email.error ? 'has-error' : ''">      
                                            <input (input)="verifyField(formdata.owner_email, 'owner_email')" [(ngModel)]="formdata.owner_email" name="owner_email" type="text" required maxlength="241">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Owner Email</label>
                                            <span class="error">{{formdata_error.owner_email.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td colspan="3" [ngClass]='isPricingValid() ? "not-active" : ""'>
                                        <div class="group" [ngClass]="formdata_error.owner_name.error ? 'has-error' : ''">      
                                            <input (input)="verifyField(formdata.owner_name, 'owner_name')" [(ngModel)]="formdata.owner_name" name="owner_name" type="text" required maxlength="241">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Owner Representative</label>
                                            <span class="error">{{formdata_error.owner_name.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="isRemoved()">
                                    <td></td>
                                    <td colspan="3">
                                        <div [ngClass]='isPricingValid() ? "not-active" : ""' class="select-group group">   
                                            <label>Owner Country/Region</label>   
                                            <select name="manageEntityCountry" [(ngModel)]="formdata.manageEntityCountry">
                                                <option *ngFor="let country of all_countries" value="{{country.code}}">{{country.name}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school' || appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">
                                    <td></td>
                                    <td colspan="3" [ngClass]="isRemoved() ? '' : 'pt30'">
                                        <div [ngClass]="{'not-active' : isPricingValid(), 'has-error' : formdata_error.IsLeedRegistration.error }" class="radio_buttons select-group group">
                                            <span class="head fs16 lh30">Are you registering for a LEED Certification?</span>
                                            <div class="flex mt10">
                                                <div class="mr25">
                                                    <span class="checkcontainer" (click)="registerLEEDOrNonLEED('yes')">
                                                        Yes
                                                        <input type="radio" name="IsLeedRegistration" [value]="true" [(ngModel)]="formdata.IsLeedRegistration">
                                                        <span class="radiobtn"></span>
                                                    </span>
                                                </div>
                
                                                <div>
                                                    <span class="checkcontainer" (click)="registerLEEDOrNonLEED('no')">
                                                        No
                                                        <input type="radio" name="IsLeedRegistration" [value]="false" [(ngModel)]="formdata.IsLeedRegistration">
                                                        <span class="radiobtn"></span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="fontSize12 grey pt10" *ngIf="((appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school') && !formdata.IsLeedRegistration)">This project has to register for LEED Certification later by going to the certifications page in Arc.</div>
                                            <div class="error">{{formdata_error.IsLeedRegistration.help}}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr [hidden]="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school' || appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">
                                    <td></td>
                                    <td colspan="3" [ngClass]="isRemoved() ? '' : 'pt30'">
                                        <div [ngClass]='isPricingValid() ? "not-active" : ""' class="select-group group">   
                                            <label>Rating System</label>   
                                            <select (change)="ratingSystemChange(formdata.rating_system, 'formdata.rating_system')" [(ngModel)]="formdata.rating_system" name="rating_system">
                                                <option [hidden]="!(appData.get('buildingData').project_type == rating.project_type)" *ngFor="let rating of rating_system" value="{{rating.value}}">{{rating.name}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="align-right" colspan="3" [ngClass]="(isPricingValid() && (appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community')) ? 'not-active' : ''">
                                        <div (click)="verifySyncSAP()" class="pl20 pr20 btn btn-primary">
                                            <span *ngIf="!syncing_project">Save</span>
                                            <div *ngIf="syncing_project">
                                                <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                            </div>
                                            <span *ngIf="syncing_project">Saving</span>
                                        </div>
                                        <p *ngIf="submit_error" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                
                    </div>
                </div>

            </div>

            <div *ngIf="appData.get('integration') == undefined && (appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school')" class="mb25">

                <div class="payment-card"  [ngClass]="refreshing_promocode || refreshing_tax || reload_price ? 'not-active' : ''">
                    <div class="fontSize25 flex-center-align" *ngIf='reload_price'>
                        <div *ngIf="reload_price_loader" class="floatNone mt0 dataInputLoaderSpinner-lg dataInputLoaderSpinner"></div>
                        <span class="ml10">Refreshing Price</span>
                    </div>
                    <table class="width-100-perc payment-table" *ngIf='!reload_price'>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="table-margin-20">
                            <tr>
                                <td colspan="5">
                                    <img src="../../../../assets/images/arc_icon.png">
                                    <span class="card-header fw600 fontSize16">Arc Access</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td colspan="5">
                                    <span class="card-header fw-400 ml15 fontSize16">Select Arc Access</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td class="pt15 table-margin-right-10">
                                    <div class="item col-xs-3 col-lg-3 grid" *ngFor="let option of project_price.price">
                                        <div class="payment-option" (click)="selectPrice(option)" [ngClass]="selected_price.term == option.term ? 'active': ''">
                                            <h2>{{option.term}}</h2>
                                            <p class="fontSize12">Year(s)</p>
                                        </div>
                                        <p class="payment-header">{{payment_currency}}{{global.addCommas(option.annual_price)}}</p>
                                        <p class="payment-sub-header" *ngIf="option.discount_perc == 0">
                                            No Savings
                                        </p>
                                        <p class="payment-sub-header cancel" *ngIf="option.discount_perc > 0">
                                            {{payment_currency}}{{global.addCommas(option.price)}}
                                        </p>
                                        <p class="payment-sub-header green-color fw600" *ngIf="option.discount_perc > 0">
                                            Save {{option.discount_perc}}%
                                        </p>
                                        <div class="mt10" *ngIf="option.discount_perc == 20">
                                            <span class="best_value_label fontSize12">
                                                <i class="fa fa-star-o"></i>
                                                <span>Best Value</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div *ngIf="appData.get('integration') != undefined && !appData.get('change_payment_method')" class="mb25">

                <div class="payment-card">
                    <div class="left-right-align">
                        <div class="flex mb25">
                            <img style="height: 50px;position: relative;left: -20px;margin-right: -20px;" src="../../../../../assets/images/{{integration.app_name}}.png">
                            <div class="fontSize16 fw600 pt5">{{integration.app_label}}</div>
                        </div>
                        <div class="fw600 fontSize16">
                            {{integration.payment_yearly}}
                        </div>
                    </div>
                    <div class="fontSize16 fw600 mb20">Select Integration payment</div>
                
                    <div class="radio_buttons fw-400 fontSize16">
                        <div>
                            <label class="mb15 checkcontainer">
                                Annually - $120 annual payment
                                <input (input)="togglePricing()" [(ngModel)]="integration_payment_duration" [value]="false" type="radio" checked="checked" name="integration_payment_duration">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                        <div>
                            <label class="checkcontainer">
                                Monthly - $10 per month ($120 yearly contract)
                                <input (input)="togglePricing()" [(ngModel)]="integration_payment_duration" [value]="true" type="radio" name="integration_payment_duration">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>

            <div [ngClass]="!isPricingValid() ? 'not-active' : ''" class="mb25">

                <div class="payment-card" [ngClass]="refreshing_tax ? 'not-active' : ''">
                    <div id="details-form">
                        <table class="width-100-perc">
                            <thead>
                                <tr>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                </tr>
                            </thead>
                            <tbody class="table-margin-20">
                                <tr>
                                    <td colspan="1">
                                        <div class="">
                                            <span class="grey fontSize16 fw600">Billing</span>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="left-right-align">
                                            <span class="fontSize16 fw-400">Edit Billing Details</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="pt30" colspan="3">
                                        <div class="group" [ngClass]="paymentdata_error.name.error ? 'has-error' : ''">      
                                            <input maxlength="40" (input)="verifyField(paymentdata.name, 'name')" [(ngModel)]="paymentdata.name" name="name" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Name</label>
                                            <span class="error">{{paymentdata_error.name.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group" [ngClass]="paymentdata_error.email.error ? 'has-error' : ''">      
                                            <input maxlength="241" (input)="verifyField(paymentdata.email, 'email')" [(ngModel)]="paymentdata.email" name="email" type="text" required maxlength="241">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Email</label>
                                            <span class="error">{{paymentdata_error.email.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group" [ngClass]="paymentdata_error.street.error ? 'has-error' : ''">      
                                            <input maxlength="60" (input)="verifyField(paymentdata.street, 'street')" [(ngModel)]="paymentdata.street" name="street" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Address</label>
                                            <span class="error">{{paymentdata_error.street.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group" [ngClass]="paymentdata_error.city.error ? 'has-error' : ''">      
                                            <input maxlength="40" (input)="verifyField(paymentdata.city, 'city')" [(ngModel)]="paymentdata.city" name="city" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>City</label>
                                            <span class="error">{{paymentdata_error.city.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group" [ngClass]="paymentdata_error.city.error ? 'has-error' : ''">      
                                            <input maxlength="20" [(ngModel)]="phone_number" name="phone" type="text">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Phone Number</label>
                                        </div>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td></td>
                                    <td colspan="1">
                                        <div class="select-group group pr20">   
                                            <label>Country/Region</label>   
                                            <select autocomplete="false" name="country" (change)="updateCountry(paymentdata.country)" [(ngModel)]="paymentdata.country">
                                                <option *ngFor="let country of all_countries" value="{{country.code}}">{{country.name}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="select-group group">   
                                            <label>State</label>   
                                            <select autocomplete="false" [(ngModel)]="paymentdata.state" name="state" (change)="updateState(selected_price.term)">
                                                <option *ngFor="let state of all_states" value="{{state.code}}">{{state.name}}</option>
                                            </select>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group pr20" [ngClass]="paymentdata_error.zip_code.error ? 'has-error' : ''">      
                                            <input maxlength="10" (input)="verifyField(paymentdata.zip_code, 'zip_code')" [(ngModel)]="paymentdata.zip_code" name="zip_code" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Zip Code</label>
                                            <span class="error">{{paymentdata_error.zip_code.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="appData.get('buildingData').country == 'IN'">
                                    <td></td>
                                    <td colspan="3">
                                        <div class="group pr20" [ngClass]="paymentdata_error.gstin.error ? 'has-error' : ''">      
                                            <input (blur)="getPrice(selected_price.term, undefined, true)" (input)="verifyField(paymentdata.gstin, 'gstin', true)" [(ngModel)]="paymentdata.gstin" name="gstin" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>GSTIN (Optional)</label>
                                            <span class="error">{{paymentdata_error.gstin.help}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div [ngClass]="!isPricingValid() ? 'not-active' : ''">

                <div class="payment-card">
                    <div id="details-form">
                        <table class="width-100-perc">
                            <thead>
                                <tr>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                </tr>
                            </thead>
                            <tbody class="table-margin-20">
                                <tr>
                                    <td colspan="1">
                                        <div class="">
                                            <span class="grey fw600 fontSize16">Payment</span>
                                        </div>
                                    </td>
                                    <td colspan="3" *ngIf="integration == undefined">
                                        <div class="left-right-align">
                                            <div *ngIf="appData.get('buildingData').country != 'IN'" class="payment_mode">
                                                <div [ngClass]="payment_mode == 'cc' ? 'active' : ''" (click)="togglePaymentMode('cc')" class="payment_mode_option">Pay Now</div>
                                                <div [ngClass]="payment_mode == 'check' ? 'active' : ''" (click)="togglePaymentMode('check')" class="payment_mode_option">Pay by Check or Wire</div>
                                            </div>
                                            <div *ngIf="appData.get('buildingData').country == 'IN'" >
                                                Pay by Check or Wire
                                            </div>
                                        </div>
                                        <div>
                                            <span class="pt10 left-right-align pb10 fontSize16 fw-400" *ngIf="payment_mode == 'cc'">Enter credit card information</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="payment_mode == 'cc'">
                                    <td></td>
                                    <td colspan="2">
                                        <div class="group {{integration == undefined ? '' : 'mt20'}} fs-block" [ngClass]="paymentdata_error.CC_number.error ? 'has-error' : ''">      
                                            <input maxlength="19" (input)="verifyField(paymentdata.CC_number, 'CC_number')" [(ngModel)]="paymentdata.CC_number" name="CC_number" type="text" required class="fs-block">
                                            <img *ngIf="getCardType(paymentdata.CC_number) == 'Mastercard'" src="../../../../assets/images/mastercard.png" class="card_img mastercard"/>
                                            <img *ngIf="getCardType(paymentdata.CC_number) == 'Visa'" src="../../../../assets/images/visa.png" class="card_img visa"/>
                                            <img *ngIf="getCardType(paymentdata.CC_number) == 'American Express'" src="../../../../assets/images/amex.png" class="card_img amex"/>
                                            <img *ngIf="getCardType(paymentdata.CC_number) == 'Discover'" src="../../../../assets/images/discover.png" class="card_img discover"/>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Card Number</label>
                                            <span class="error">{{paymentdata_error.CC_number.help}}</span>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr *ngIf="payment_mode == 'cc'">
                                    <td></td>
                                    <td>
                                        <div class="group pr20 fs-block" [ngClass]="paymentdata_error.CC_expiry.error ? 'has-error' : ''">      
                                            <input maxlength="7" (input)="verifyField(paymentdata.CC_expiry, 'CC_expiry')" [(ngModel)]="paymentdata.CC_expiry" name="CC_expiry" type="text" (blur)="validateCardExpiry(paymentdata.CC_expiry)" required class="fs-block">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Expiry (mm/yyyy)</label>
                                            <span class="error">{{paymentdata_error.CC_expiry.help}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="group fs-block" [ngClass]="paymentdata_error.CC_CVV.error ? 'has-error' : ''">      
                                            <input maxlength="4" (input)="verifyField(paymentdata.CC_CVV, 'CC_CVV')" [(ngModel)]="paymentdata.CC_CVV" name="CC_CVV" type="text" required class="fs-block">
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>CVV</label>
                                            <span class="error">{{paymentdata_error.CC_CVV.help}}</span>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr *ngIf="change_payment_method">
                                    <td></td>
                                    <td colspan="2">
                                        <div (click)="submitPayment()" class="pl20 pr20 btn btn-primary">
                                            <div *ngIf="isPaymentFormSubmitted">
                                                <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                            </div>
                                            Save Payment Method
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                    <td colspan="1" class="align-right">
                                        <div (click)="global.goBack()" class="pl20 fontSize16 pr20 btn btn-default">
                                            Cancel
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="payment_mode == 'check'">
                                    <td></td>
                                    <td colspan="3" class="pt30">
                                        <div *ngIf="appData.get('buildingData').country == 'IN' && show_tax_note" class="payByCheckIns mt20 mb20 fs14"><span class="bold">NOTE: </span><span>Taxes display in pricing section are based on the billing address.</span></div>
                                        <span class="card-header fw-bold">Instructions</span>
                                        <div class="payByCheckIns mt20">
                                            <p>
                                                Upon completing the billing form above and clicking Generate Invoice, an invoice will be generated and emailed to your email address.
                                            </p>
                                            <p *ngIf="appData.get('buildingData').country != 'IN'">
                                                Make checks payable to <span class="fw-bold">Green Business Certification Inc.</span> Please include the invoice number on the payment to ensure prompt processing. Due to our current teleworking status, all mail sent to our physical locations, is not being monitored. In order to avoid any service or product delays, please remit all payments by check to the Bank Lockbox info provided.
                                            </p>
                                            <p *ngIf="appData.get('buildingData').country != 'IN'">
                                                PNC Bank Lockbox Info:<br>
                                                Green Business Certification Inc.<br>
                                                P.O. Box 822964<br>
                                                Philadelphia, PA, 19182-2964<br>
                                                Telephone 1.800.795.1747
                                            </p>
                                            <p *ngIf="appData.get('buildingData').country == 'IN'">
                                                Mail the invoice and check to the following address:
                                            </p>
                                            <p *ngIf="appData.get('buildingData').country == 'IN'">
                                                Green Business Certification Institute Pvt. Ltd<br>
                                                10th Floor, Unit No 1006 and 1007, C-1, WTT Plot,<br>
                                                Sector 16, Noida, Gautam Buddha Nagar, Uttar Pradesh 201301
                                            </p>
                                            <p class="fw-bold">
                                                To avoid delays in processing, please write the invoice number on the check.
                                            </p>
                                            <p>
                                                Once GBCI receives the check, <span class="fw-bold">please allow 2-3 business days for processing.</span>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div *ngIf="integration == undefined || (integration != undefined && !change_payment_method && !integration_payment_duration)" class="payment-card" id="details-form" [ngClass]="refreshing_promocode || reload_price ? 'not-active' : ''">
                    <table class="width-100-perc promo_code_table">
                        <thead>
                            <tr>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="grey fw600 fontSize16">Promotional Code</span>
                                </td>
                                <td class="flex">
                                    <div class="group pb0" [ngClass]="invalid_promo_code ? 'has-error': ''">      
                                        <input maxlength="35" (blur)="getPromocode(selected_price.term)" [(ngModel)]="paymentdata.promocode" name="promocode" type="text" required>
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label>Enter Code</label>
                                        <span class="error">Invalid Promocode</span>
                                    </div>
                                    <div *ngIf="promocode_applied" (click)="removePromocode(promocode_price.term)" data-tooltip-placement="top" data-tooltip="Remove Promotional Code" class="remove_promo cursor-pointer fa fa-times-circle-o"></div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div *ngIf="integration == undefined || (integration != undefined && !change_payment_method)" class="payment-card" id="details-form" [ngClass]="refreshing_promocode || refreshing_tax ? 'not-active' : ''">
                    <table class="width-100-perc align-right">
                        <thead>
                            <tr>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                                <th class="w20p"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="integration != undefined">
                            <tr *ngIf="promocode_applied">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Subtotal</span>
                                </td>
                                <td class="fontSize16 pt10">
                                    <span class="fw-600">${{global.addCommas(global.formatNumber(selected_price.price, 2))}}</span>
                                    <span class="fw-600">{{!integration_payment_duration ? "annual payment" : "per month"}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="promocode_applied">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Discount</span>
                                </td>
                                <td class="fontSize16 pt10">
                                    <span class="fw-600">${{global.addCommas(global.formatNumber(selected_price.discount_price, 2))}}</span>
                                    <span class="grey fontSize13 cursor-pointer" (click)="removePromocode(promocode_price.term)">
                                        <u>Remove</u>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Total</span>
                                </td>
                                <td class="fontSize16 pt10">
                                    <span class="fw-600">${{global.addCommas(global.formatNumber(final_price, 2))}}</span>
                                    <span class="fw-600">{{!integration_payment_duration ? "annual payment" : "per month"}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td class="pt30" colspan="2">
                                    <div [ngClass]="disablePayButton ? 'not-active' : ''" (click)="submitPayment()" class="pl20 pr20 btn btn-primary">
                                        <div *ngIf="isPaymentFormSubmitted">
                                            <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                        </div>
                                        {{project_payment_nxt_btn}}
                                    </div>
                                    <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="integration == undefined">
                            <tr>
                                <td></td>
                                <td></td>
                                <td class="fontSize16">
                                    <span class="grey fw-400">USGBC Member</span>
                                </td>
                                <td class="fontSize16">
                                    <span class="fw-400">{{project_price.usgbc_member == 'N' ? 'No' : 'Yes'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">{{promocode_applied || (taxes != false && !paymentdata.is_sez) ? 'Subtotal' : 'Total'}}</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school'" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.annual_price, 2))}}</span>
                                    for
                                    <span class="fw-600">{{selected_price.term == 1 ? '1 Year' : selected_price.term + " Years"}}</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school'" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.annual_price, 2))}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="taxes != false && !paymentdata.is_sez">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Estimated Tax</span>
                                </td>
                                <td class="pt10">
                                    <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes.price, 2))}}</span>
                                    <span class="fw-400" *ngIf="only_IGST()"> (18% IGST)</span>
                                    <span class="fw-400" *ngIf="!only_IGST()"> (9% CGST)</span>
                                </td>
                            </tr>
                            <tr *ngIf="taxes_s != false && !paymentdata.is_sez">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                </td>
                                <td class="pt10">
                                    <span class="fontSize16 fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(taxes_s.price, 2))}}</span>
                                    <span class="fw-400"> (9% SGST)</span>
                                </td>
                            </tr>
                            <tr *ngIf="promocode_applied">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Discount</span>
                                </td>
                                <td class="fontSize16 pt10">
                                    <span class="fw-600"><span style="color: #dd5826;">-  </span>{{payment_currency}}{{global.addCommas(global.formatNumber(selected_price.annual_price - promocode_price.annual_price, 2))}}</span>
                
                                    <span class="grey fontSize13 cursor-pointer" (click)="removePromocode(promocode_price.term)">
                                        <u>Remove</u>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="taxes != false && !promocode_applied && !paymentdata.is_sez">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Total</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school'" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([selected_price.annual_price, taxes.price, taxes_s.price]), 2))}}</span>
                                    for
                                    <span class="fw-600">{{selected_price.term == 1 ? '1 Year' : selected_price.term + " Years"}}</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school'" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([selected_price.annual_price, taxes.price, taxes_s.price]), 2))}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="promocode_applied">
                                <td class="pt10"></td>
                                <td class="pt10"></td>
                                <td class="fontSize16 pt10">
                                    <span class="grey fw-400">Total</span>
                                </td>
                                <td *ngIf="(appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school') && !taxes" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(promocode_price.annual_price, 2))}}</span>
                                    for
                                    <span class="fw-600">{{promocode_price.term == 1 ? '1 Year' : promocode_price.term + " Years"}}</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school' && !taxes" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(promocode_price.annual_price, 2))}}</span>
                                </td>
                                <td *ngIf="(appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school') && taxes != false" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([promocode_price.annual_price, taxes.price, taxes_s.price]), 2))}}</span>
                                    for
                                    <span class="fw-600">{{promocode_price.term == 1 ? '1 Year' : promocode_price.term + " Years"}}</span>
                                </td>
                                <td *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school' && taxes != false" class="fontSize16 pt10">
                                    <span class="fw-600">{{payment_currency}}{{global.addCommas(global.formatNumber(summation([promocode_price.annual_price, taxes.price, taxes_s.price]), 2))}}</span>
                                </td>
                            </tr>
                            <tr *ngIf="appData.get('buildingData').country == 'IN'">
                                <td></td>
                                <td colspan="3" class="pt30">
                                    <!-- SEZ-->
                                    <div class="checkbox">
                                        <input type="checkbox" class="cursor-pointer" [(ngModel)]="paymentdata.is_sez" (change)="check_sez(paymentdata.is_sez)">
                                        <label for="checkbox">
                                            <span>Does this project belong to Special Economic Zone (SEZ)?</span>
                                        </label>
                                    </div>
                
                                    <div *ngIf="appData.get('buildingData').country == 'IN' && paymentdata.is_sez">
                                        <div>
                                            <div class="mb10 align_r" *ngFor="let uploaded_file of config.uploaded_files">
                                                <a (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                                <div (click)="global.deleteFile(uploaded_file, 'sez_docs/temp')">
                                                    <span class='far fa-trash-alt ml10 cursor-pointer'></span>
                                                </div>
                                            </div>                        
                                            
                                            <div class="mb10" *ngIf="config.currentUpload">
                                                <span class='uploadLoaderSpinner'></span>
                                                <span [innerHTML]="config.currentUploadName"></span>
                                            </div>
                                        </div>
                                        <div class="width-100-perc">
                                            <input #elem (input)="global.fileNameChanged(elem)" class="hide uploadFile" type="file" multiple>
                                            <div (click)="global.openUploadDialog('laptop', 'sez_docs/temp', 'S3')" type="energy" class="floatNone viewUpload laptop">
                                                <i class="fa fa-upload" aria-hidden="true"></i>
                                            </div>
                                            <span>Upload supporting documents.</span>
                                        </div>
                                    </div>
                                    <!-- SEZ-->
                                </td>
                            </tr>
                            <tr>
                                <td class="pt30 align-left">
                                    <div *ngIf="appData.get('buildingData').project_type != 'parksmart' && !IsTrialSelectedAndExpired(appData.get('buildingData')) && !global.trialStatus()" [ngClass]="disablePayButton ? 'not-active' : ''" (click)="goToProjectSetup()" class="pl20 pr20 btn btn-default fontSize16 line_height_24 gray-color">
                                        Skip and select trial
                                    </div>
                                </td>
                                <td></td>
                                <td class="pt30" colspan="2">
                                    <div [ngClass]="disablePayButton ? 'not-active' : ''" (click)="submitPayment()" class="pl20 pr20 btn btn-primary">
                                        <div *ngIf="isPaymentFormSubmitted">
                                            <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                        </div>
                                        {{project_payment_nxt_btn}}
                                    </div>
                                    <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
        <div class="payment-card payment_info" [hidden]="appData.get('buildingData').project_type == 'parksmart'">

            <p *ngIf="integration == undefined" class="access">LETS GET STARTED</p>
            <p *ngIf="integration != undefined" class="access">INTEGRATION</p>
            <p class="access-fee-includes" *ngIf="integration == undefined && (appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school')">
                Select a term to access the full suite of Arc tools including performance benchmarking, analytics, data input, portfolios and more! 
                <br>
                <br>
                You can choose to submit your score for review and certification as long as your access term is current. 
                <br>
            </p>
            <p class="access-fee-includes" *ngIf="appData.get('buildingData').project_type != 'building' && integration == undefined">
                Continue below to access the full suite of Arc tools including performance benchmarking, analytics, data input, and more!
            </p>

            <p *ngIf="integration != undefined" class="access-fee-includes">
                Fee includes access to Benchmarker version of the Measurabl Platform for streamlined utility data management and LEED Certification.
                <br>
                <br>
                To use Certifications, your project’s Arc Access must be current.
                Learn more about the benefits <a href="//www.measurabl.com/" target="_blank">here</a>.
            </p>

        </div>
    </div>
</div>

<!-- Paymetric form starts-->
<iframe id="paymetric_iframe">
</iframe>
<!-- Paymetric form ends -->

<div eview_edit="false" class="spaceTypeModal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding pb25 pr30" style="line-height: 25px;overflow:auto;">
                Thank you for leveraging Arc to benchmark your property's performance. Given the unique nature of your building kindly schedule a call to discuss next steps, by emailing <a href='mailto:contact@arskoru.com' target='_top'>contact@arskoru.com</a>.
                <div class="mt10 viewUpload bottomButtons showSubmit">
                    <button (click)="completeRegistration()" data-dismiss="modal" type="button" class="btn btn-default" title="" data-popover-placement="left" data-popover="">Close</button>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="no_gstin_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p30 modal_radius">
            <p class="align-center bold">Bill To Confirmation</p>
            <p class="pt10">You have not entered GSTIN, so please confirm your following billing address to proceed.</p>
                <table class="table ml10 align-center pt10">
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">Bill-to-party name</td>
                        <td class="align-center">{{paymentdata.name}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">Bill-to-party email</td>
                        <td class="align-center">{{paymentdata.email}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">Address 1</td>
                        <td class="align-center">{{paymentdata.street}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">City</td>
                        <td class="align-center">{{paymentdata.city}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">State</td>
                        <td class="align-center">{{complete_state}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">Zip Code</td>
                        <td class="align-center">{{paymentdata.zip_code}}</td>
                    </tr>
                    <tr>
                        <td class="vertical-align-middle text-left ff-ss-r w25p">Country/Region</td>
                        <td class="align-center">{{complete_country}}</td>
                    </tr>
                </table>
            <div class="mt30 align-center">
                <button class="btn btn-primary light_blue_btn mr20" data-dismiss="modal" (click)="makePayment()">Continue</button>
                <button (click)="cancelPayment()" class="btn action-button btnGreen" data-dismiss="modal" >Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade portfolio-modal-wbg ofac_invalid_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            
            <div class="modal-header">
                <span class="fw-600 fs16">Owner organization is blocked</span>
            </div>
            <div class="modal-body pl25 mb20">
                <p class="fw-400">
                    This project is located in a sanctioned country or belongs to a sanctioned entity 
                    according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                    or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                    contact us</a> for more details.
                </p>  
                </div>
            <div class="modal-footer">
                <button class="floatl btn btn-primary mr10" (click)="resetOrganizationDetails()">Continue</button>
                <button type="button" class="floatl btn btn-default" (click)="resetOrganizationDetails()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div id="leed_registration_block" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p30 modal_radius">
            <p *ngIf="appData.get('buildingData').project_type == 'building'">If you're interested in registering this project for LEED certification, please select the "Register for LEED certification now" button below and you'll be routed to LEED Online.</p>
            <p *ngIf="appData.get('buildingData').project_type == 'building'">Otherwise, you can get started now with Arc for free. Just select the "Not now" button below.</p>
            <p *ngIf="appData.get('buildingData').project_type == 'building'">You can enter your performance data in Arc and choose to register for LEED later. Your performance data will get you most of the way, but you'll need to complete the LEED prerequisites, and possibly other requirements before submitting for certification.</p>
            <p *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'" class="align-center">LEED for Cities and Communities Pilot closed to new registrations on April 1, 2019. To register for LEED for Cities v4.1 or LEED for Communities v4.1, login to <a (click)='moveToLORegistrationPage()'>LEED Online</a> and select "Create New Project". If you have any questions in the meantime please email <a (click)="global.openEmail('cities@usgbc.org')">cities@usgbc.org</a> and we would be happy to help.</p>
            <div class="mt30 align-center"> 
                <button class="btn btn-primary" [disabled]="go_to_LO_flag" (click)="moveToLORegistrationPage('yes')">{{go_to_LO_text}}</button>
                <button class="btn action-button ccc_border ml10" [disabled]="go_to_LO_flag" (click)="moveToLORegistrationPage('no')">
                    <span *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">Cancel</span>
                    <span *ngIf="appData.get('buildingData').project_type == 'building'">Not now</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="gstin_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p30 modal_radius">
            <p class="align-center bold">{{gstin_modal_subject}}</p>
            <p class="align-center pt10">{{gstin_modal_body}}</p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen" data-dismiss="modal" >Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="sez_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p30 modal_radius">
            <p class="align-center bold">Clause X: Taxes</p>
            <p class="pt10" style="height: 300px;overflow-y: scroll;">
                The provisions of this Clause X shall have effect, notwithstanding anything inconsistent contained in any other part of the[1] Agreement. The [Price][2] does not include Goods and Services Tax and such other taxes, cess, levies and other charges that are now or hereafter levied in relation to the matters specified herein (together “Taxes”). The Company will separately indicate such Taxes payable in the invoices, in appropriate cases, and shall raise the invoices. If the Customer has made any statement requiring the Company to deduct (i) lesser Taxes or (ii) no Taxes, the Company shall be entitled to (without being an obligation) rely on such statements of the Customer. If however, claims for Taxes, interest, penalty and other charges (including denial of credit for such Taxes paid), are subsequently made on the Company (for any reason attributable in part or whole to the Customer), the Company shall have the right to recover all such Taxes and amounts from the Customer in any manner including without limitation, by way of setting off such amounts from any other amounts due from the Customer to the Company under this Agreement or otherwise. The Customer agrees and undertakes to comply with all applicable law (including that relating to Taxes) and if due to any act, omission or negligence on the part of the Customer, the Company suffers any damage, loss, cost, expenses and other charges (together “Loss”), the Customer agrees to indemnify the Company, its directors, officers, employees and other representatives (together “Company Indemnified Persons”) for any such Loss that the Company Indemnified Persons may suffer.                     
            </p>
            
            <div class="mt30 align-center">
                <button class="btn btn-primary light_blue_btn mr20" data-dismiss="modal" (click)="set_sez(true)">I Agree</button>
                <button class="btn action-button btnGreen" data-dismiss="modal" (click)="set_sez(false)">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal portfolio-modal-wbg review_not_allowed_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md"><div class="modal-content"><div class="p2535">
    
    <div class="left-right-align mb20">
        <h4 class="mb10 mt10 fw-600">Purchase not allowed</h4> 
    </div>

    <div class="left-right-align fs16">
        <div class="danger-color">
            <div>Payments for test projects not allowed.</div>
        </div>
    </div>

    <div class="mt30">
        <div class="align-left">
            <button (click)="global.goBack()" data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
        </div>
    </div>
</div></div></div>
</div>

<modal #modal>
    <app-organization (success_data)="getDataFromModal($event)"></app-organization>
</modal>
<div class="input_param_header_project insight_widget">
    <div class="flex">
        <div class="input_item w25p">
            <div class="dropdown zipcode">
                <input (input)="searchZipCode(zipcode_input)" [(ngModel)]="zipcode_input" placeholder="Enter zipcode" class="dropdown-toggle dropdown-toggle-it insight_input" type="text" (focus)="showDropdown()" (blur)="hideDropdown()" autocomplete="off">
                <div>
                    <svg *ngIf="searching_zip" class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>
                    <svg *ngIf="!searching_zip" class="search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                </div>
                <ul class="dropdown-menu normal dropdown-menu-fixed">
                    <li (click)="changeZipCode(zipcode)" *ngFor="let zipcode of zipcode_list" class="water_unit">
                        <a>{{zipcode}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="input_item">
            <div class="dropdown">
                <button title="Range of mile" class="dropdown-toggle dropdown-toggle-it btn-default" type="button" data-toggle="dropdown">
                    <span>{{range_in_mile_input}} {{range_in_mile_input == 1 ? 'Mile' : 'Miles'}}</span>
                    <i class="toggle fa fa-angle-down"></i>
                </button>
                <ul class="dropdown-menu left dropdown-menu-fixed">
                    <li (click)="changeRange(value)" *ngFor="let value of range_list" class="water_unit">
                        <a>{{value}} {{value == 1 ? 'Mile' : 'Miles'}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="project_type_wrapper input_item add-dropdown-toggle">
            <select (input)="projectTypeChange()" [(ngModel)]="project_types_input" id="project_type" title="Project Types" class="selectpicker" multiple data-live-search="true">
                <option *ngFor="let type of project_type_list">
                    {{type}}
                </option>
            </select>
        </div>

        <div (click)="searchData(range_in_mile_input, zipcode_input, project_types_input)" [ngClass]="disableSearch() ? 'not-active btn-default' : 'btn-primary'" id="search_overview" class="ml20 btn flex">
            <svg class="search-icon" width="14" height="14" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
            <span>Search</span>
        </div>

        <div (click)="exportInsight(range_in_mile_input, zipcode_input)" id="search_overview" class="ml20 btn btn-default flex">
            <i style="width: 15px;height: 18px;" class="mr10 pt5 fa fa-download"></i>
            <span>Export</span>
        </div>
    </div>

    <div *ngIf="climate_code == 'Climate Zone not available'" class="fs12 error-color mt8">
        Sorry, this project's zip code, {{project.zip_code}} has not been found of Insight Tool's database. Please double check that it's correct. If you're still having trouble, contact USGBC using the feedback button.
    </div>
</div>
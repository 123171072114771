<div id="portfolio_component" class="content-wrap" [ngClass]="loading? 'not-active': ''">
    <app-loader *ngIf="loading"></app-loader>
	<div id="content" class="content">
        
        <h3 class="fw-500 black-color pl15 mb25 pt5">
            Activity
        </h3>
        
        <section class="widget">
            <table class="width-100-perc history_table">
                <thead>
                    <tr>
                        <th>
                            Action
                        </th>
                        <th>
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of myprojects">
                        <td class="flex">
                            <div class="initials">{{getInitials(project.created_by)}}</div>
                            <span class="info">
                                {{project.created_by}} added a new project <span class="project">{{project.name}}</span>
                            </span>
                        </td>
                        <td>
                            <span class="date">
                                {{date_format_timeline(global.dateTimeFormat(project.created_at))}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        

    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-downgrade-score-version',
  templateUrl: './downgrade-score-version.component.html',
  styleUrls: ['./downgrade-score-version.component.css']
})
export class DowngradeScoreVersionComponent implements OnInit {
  checkInputCert: boolean = false;
  loaderCert: boolean = false;
  leed_id: any = '';

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  checkInputsCert() {
    if (isNaN(this.leed_id)) {
      this.checkInputCert = false;
    }
    else {
      this.checkInputCert = true;
    }
  };

  returnReview() {
    this.loaderCert = true;
    this.api.get('/assets/LEED:' + this.leed_id + '/').subscribe(
      data => {
        if ((data.project_type == 'school' && data.score_code_version.name > 3) || (data.project_type == 'building' && data.score_code_version.name > 3)) {
          var payload = {}
          if (data.project_type == 'school') {
            payload = { "new_version": 11 }
          }
          else if (data.project_type == 'building') {
            payload = { "new_version": 10 }
          }
          this.api.put( '/assets/LEED:' + this.leed_id + '/scores/version/update/', payload ).subscribe(
            data => {
              this.loaderCert = false;
              this.alertService.alert('success', "Downgraded successfully", 5);
            }, error => {
              this.loaderCert = false;
              var message = "Something went wrong. Please try again";
              if ("message" in error.error) {
                message = error.error.message;
              }
              else if ("detail" in error.error) {
                message = error.error.detail;
              }
              else if ("error" in error.error) {
                message = error.error.error;
              }
              this.alertService.alert('error', message, 5);
            }
          );
        }
        else {
          this.loaderCert = false;
          this.alertService.alert('error', 'Not available for this project', 5);
        }
      },
      error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
      }
    );
  }

  ngOnInit(): void {
  }

}

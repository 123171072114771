<app-sidebar [sidebar_type]="'portfolio'"></app-sidebar>

<app-portfolio-overview *ngIf="appData.get('app_name') == 'portfolio-overview'"></app-portfolio-overview>
<app-portfolio-performance *ngIf="appData.get('app_name') == 'portfolio-performance'"></app-portfolio-performance>
<app-portfolio-reports *ngIf="appData.get('app_name') == 'portfolio-reports'"></app-portfolio-reports>

<app-portfolio *ngIf="appData.get('app_name') == 'portfolio'"></app-portfolio>
<app-portfolio-team *ngIf="appData.get('app_name') == 'portfolio-team'"></app-portfolio-team>
<app-activity *ngIf="appData.get('app_name') == 'activity'"></app-activity>

<app-portfolio-efficiency-analysis *ngIf="appData.get('app_name') == 'portfolio-efficiency-analysis'"></app-portfolio-efficiency-analysis>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../models/env-vars.model';
import { AppData } from './appdata.service';
import { Global } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class InsightService
{
	headers: any = {};
	climate_codes: any = null;
	data_matrix: any = null;
	insight_user_id: string = '';
	insight_password: string = '';

	constructor(
		private appData: AppData,
		private global: Global,
		private http: HttpClient,
	)
	{
		this.headers = {
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.it_subscription_key,
			"Cache-Control": "no-cache",
			"Cache-Pragma": "no-cache",
			"If-Modified-Since": String(new Date().getTime())
		};

		if(this.appData.get('insight_session') != null)
		{
			this.headers["Authorization"] = "Bearer " + this.appData.get('insight_session').authorization_token
		}
		else if(this.global.getURLFromParam('token') != null || this.global.getURLFromParam('token') != undefined)
		{
			this.headers["Authorization"] = "Bearer " + this.global.getURLFromParam('token');
		}
		
		this.appData.set('insight_header', this.headers);
	}

	insightLogin(username: string, password: string)
	{
		this.appData.set('enable_insights', false);
		var data = {
			"username": username,
			"password": password
		}
		Config.loading_insights = true;
		var config_header = 
		{
			headers: 
			{
				"Ocp-Apim-Subscription-Key": Config.it_subscription_key
			}
		};
		this.post('/auth/login/', data, config_header).subscribe(
			data =>
			{
				for(var n = 0; n < Config.config.settings.show_insights.length; n++)
				{
					if(Config.config.settings.show_insights[n] == this.appData.get('user_id').split("@")[1].toLowerCase())
					{
						this.appData.set('enable_insights', true);
					}
				}
				setTimeout(function()
				{
					$('.insight_login').modal('hide');
				}, 1000);
				Config.loading_insights = false;
				this.appData.set('insight_session', data);
				var insight_header = 
				{
					"Content-Type": "application/json",
					"Ocp-Apim-Subscription-Key": Config.it_subscription_key,
					"Authorization": "Bearer " + this.appData.get('insight_session').authorization_token
				};
				this.appData.set('insight_header', insight_header);
				this.headers = insight_header;
				this.global.goToApp("selected_project", 
				{
					"leed_id": this.appData.get('leed_id'),
					"app_name": 'insight'
				});
				this.appData.set('insight_app', "home");
			},
			function(error)
			{
				Config.loading_insights = false;
			}
		)
	};

	openInsights()
	{
		this.appData.set('enable_insights', false);
		if(this.appData.get('insight_session') != undefined)
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": 'insight'
			});
			this.appData.set('insight_app', "home");
			return;
		}

		if(!(Config.config.settings.ENV_FLAG == 'DEV' || Config.config.settings.ENV_FLAG == 'PRD'))
		{
			$('.insight_login').modal('show');
			return;
		}
		
		if(Config.loading_insights)
		{
			return;
		}
		var data = {
			"username": this.appData.get('user_id'),
			"guid": this.appData.get('user_guid')
		}

		var config_header = 
		{
			headers: 
			{
				"Ocp-Apim-Subscription-Key": Config.config.settings.it_subscription_key
			}
		};
		Config.loading_insights = true;
		this.post('/auth/login/', data, config_header).subscribe(
			data =>
			{
				for(var n = 0; n < Config.config.settings.show_insights.length; n++)
				{
					if(Config.config.settings.show_insights[n] == this.appData.get('user_id').split("@")[1].toLowerCase())
					{
						this.appData.set('enable_insights', true);
					}
				}
				Config.loading_insights = false;
				this.appData.set('insight_session', data);
				var insight_header = 
				{
					"Content-Type": "application/json",
					"Ocp-Apim-Subscription-Key": Config.config.settings.it_subscription_key,
					"Authorization": "Bearer " + this.appData.get('insight_session').authorization_token
				};
				this.appData.set('insight_header', insight_header);
				this.headers = insight_header;
				this.global.goToApp("selected_project", 
				{
					"leed_id": this.appData.get('leed_id'),
					"app_name": 'insight'
				});
				this.appData.set('insight_app', "home");
			},
			function(error)
			{
				Config.loading_insights = false;
			}
		);
	}

	getProjectTypes(data: any)
	{
		for(var n in data)
		{
			data[n] = data[n].trim();
		}
		return data.join(",")
	}

	getData(radius: any, address: any, project_types_input: any, reduction_trigger: any, strategies_trigger: any, credits_trigger?: any)
	{                
		var url = '/assets/search/';
		url += "?radius=" + radius;
		url += "&address=" + address;
		
		if(project_types_input.length > 0)
		{
			url += "&space_type=" + this.getProjectTypes(project_types_input);
		}

		if(reduction_trigger)
		{
			url += "&reduction=" + reduction_trigger;
		}
		if(strategies_trigger)
		{
			url += "&strategies=" + strategies_trigger;
		}
		if(credits_trigger)
		{
			url += "&credits=" + credits_trigger;
		}
		return this.get(url);
	}

	getAssets(page: any, page_size: any, ordering?: any)
	{                
		page = typeof page !== 'undefined' ? String(page) : '1';
		page_size = typeof page_size !== 'undefined' ? String(page_size) : '50';
		var get_project_url = '/assets/?page=' + page + '&page_size='+ page_size;
		return this.get(get_project_url);
	}

	getZipList(address: any)
	{
		var url = '/address/predictions/';
		url += "?address=" + address;
		return this.get(url);
	}

	reductionChanged(type: any, val: any, project_reduction: any)
	{
		for(var k in project_reduction)
		{
			if(project_reduction[k].credit_id == type)
			{
				project_reduction[k].estimation = parseInt(val);
			}
		}

		return project_reduction;
	}

	initScorecard(project_reduction: any, score_card: any, rating_system: any, override_scorecard?: any)
	{
		if(override_scorecard == undefined)
		{
			override_scorecard = false;
		}
		var main_total = 0;
		var energy_estimate, water_estimate, waste_estimate, total = 0;
		var net_zero_energy = false, net_zero_water = false, net_zero_waste = false;

		for(var k in project_reduction)
		{
			if(project_reduction[k].credit_id == 'energy_estimate')
			{
				energy_estimate = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'water_estimate')
			{
				water_estimate = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'waste_estimate')
			{
				waste_estimate = project_reduction[k].estimation;
			}

			if(project_reduction[k].credit_id == 'net_zero_energy')
			{
				net_zero_energy = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'net_zero_water')
			{
				net_zero_water = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'net_zero_waste')
			{
				net_zero_waste = project_reduction[k].estimation;
			}
		}

		for(var i in score_card)
		{
			total = 0;
			for(var j in score_card[i].points_gained)
			{
				if(score_card[i].points_gained[j].id == 'EA903' && energy_estimate != undefined)
				{
					var points = Math.round((energy_estimate - 10) / 2);
					points = points < 0 ? 0 : points;
					score_card[i].points_gained[j]["attempted"] = points > score_card[i].points_gained[j].max ? score_card[i].points_gained[j].max : points;
					total += score_card[i].points_gained[j]["attempted"];
				}
				else if(score_card[i].points_gained[j].id == 'WE903' && water_estimate != undefined && rating_system == 'LEED 2009')
				{
					if(water_estimate >= 30 && water_estimate < 35)
					{
						var points = 2;
					}
					else if(water_estimate >= 35 && water_estimate < 40)
					{
						var points = 3;
					}
					else if(water_estimate >= 40)
					{
						var points = 4;
					}
					else
					{
						var points = 0;
					}
					score_card[i].points_gained[j]["attempted"] = points > score_card[i].points_gained[j].max ? score_card[i].points_gained[j].max : points;
					total += score_card[i].points_gained[j]["attempted"];
				}
				else if(score_card[i].points_gained[j].id == 'MR103' && waste_estimate != undefined && rating_system == 'LEED 2009')
				{
					if(waste_estimate == 0)
					{
						var points = 0;
					}
					else if(waste_estimate <= 50)
					{
						var points = 1;
					}
					else if(waste_estimate > 50)
					{
						var points = 2;
					}
					else
					{
						var points = 0;
					}
					points = points < 0 ? 0 : points;
					score_card[i].points_gained[j]["attempted"] = points > score_card[i].points_gained[j].max ? score_card[i].points_gained[j].max : points;
					total += score_card[i].points_gained[j]["attempted"];
				}
				else if(score_card[i].points_gained[j].id == 'WE902' && water_estimate != undefined && rating_system == 'LEED v4')
				{
					var points = Math.round((water_estimate - 20) / 5);
					points = points < 0 ? 0 : points;
					score_card[i].points_gained[j]["attempted"] = points > score_card[i].points_gained[j].max ? score_card[i].points_gained[j].max : points;
					total += score_card[i].points_gained[j]["attempted"];
				}
				else if(score_card[i].points_gained[j].id == 'MR123' && waste_estimate != undefined && rating_system == 'LEED v4')
				{
					var points = Math.round((waste_estimate - 25) / 25);
					points = points < 0 ? 0 : points;
					score_card[i].points_gained[j]["attempted"] = points > score_card[i].points_gained[j].max ? score_card[i].points_gained[j].max : points;
					total += score_card[i].points_gained[j]["attempted"];
				}

				if(score_card[i].points_gained[j]["attempted"] == undefined)
				{
					score_card[i].points_gained[j]["attempted"] = 0;
				}
			}
			score_card[i]["attempted"] = total;
			main_total += total;
		}

		if(!override_scorecard)
		{
			for(var i in score_card)
			{
				total = 0;
				for(var j in score_card[i].points_gained)
				{
					for(var k in project_reduction)
					{
						if(project_reduction[k].credit_id == score_card[i].points_gained[j].id)
						{
							score_card[i].points_gained[j]["attempted"] = project_reduction[k].estimation;
							total += project_reduction[k].estimation
						}
					}
				}

				score_card[i]["attempted"] = total;
				main_total += total;
			}
		}

		return {
			"project_reduction": project_reduction,
			"score_card": score_card,
			"net_zero_energy": net_zero_energy,
			"net_zero_water": net_zero_water,
			"net_zero_waste": net_zero_waste,
			"total": main_total,
			"energy_estimate": energy_estimate,
			"water_estimate": water_estimate,
			"waste_estimate": waste_estimate
		}
	}

	scoreCardChange(val: any, credit_item: any, score_card: any, project: any, project_reduction: any)
	{
		var net_zero_energy = false, net_zero_water = false, net_zero_waste = false;
		for(var k in project_reduction)
		{
			if(project_reduction[k].credit_id == 'net_zero_energy')
			{
				net_zero_energy = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'net_zero_water')
			{
				net_zero_water = project_reduction[k].estimation;
			}
			else if(project_reduction[k].credit_id == 'net_zero_waste')
			{
				net_zero_waste = project_reduction[k].estimation;
			}
		}

		var main_total = 0;
		var project_reduction: any = [];
		var energy_estimate = 0, water_estimate = 0, waste_estimate = 0, total = 0;
		credit_item.attempted = val;

		for(var i in score_card)
		{
			total = 0;
			for(var j in score_card[i].points_gained)
			{
				total += score_card[i].points_gained[j]["attempted"];
				project_reduction.push({
					'credit_id': score_card[i].points_gained[j].id,
					'estimation': score_card[i].points_gained[j].attempted,
					'zip_code': project.zip_code
				});

				if(score_card[i].points_gained[j].id == 'EA903')
				{
					energy_estimate = (2 * score_card[i].points_gained[j].attempted) + 10;
					energy_estimate = energy_estimate > 48 ? 48 : energy_estimate;
				}
				else if(score_card[i].points_gained[j].id == 'WE903' && project.rating_system == 'LEED 2009')
				{
					score_card[i].points_gained[j].attempted = score_card[i].points_gained[j].attempted > 4 ? 4 : score_card[i].points_gained[j].attempted;
					if(score_card[i].points_gained[j].attempted < 2)
					{
						water_estimate = 0;
					}
					else if(score_card[i].points_gained[j].attempted == 2)
					{
						water_estimate = 30;
					}
					else if(score_card[i].points_gained[j].attempted == 3)
					{
						water_estimate = 35;
					}
					else if(score_card[i].points_gained[j].attempted >= 4)
					{
						water_estimate = 40;
					}
					water_estimate = water_estimate > 100 ? 100 : water_estimate;
				}
				else if(score_card[i].points_gained[j].id == 'MR103' && project.rating_system == 'LEED 2009')
				{
					score_card[i].points_gained[j].attempted = score_card[i].points_gained[j].attempted > 2 ? 2 : score_card[i].points_gained[j].attempted;
					if(score_card[i].points_gained[j].attempted == 0)
					{
						waste_estimate = 0;
					}
					else if(score_card[i].points_gained[j].attempted == 1)
					{
						waste_estimate = 50;
					}
					else if(score_card[i].points_gained[j].attempted >= 2)
					{
						waste_estimate = 75;
					}
					waste_estimate = waste_estimate > 100 ? 100 : waste_estimate;
				}
				else if(score_card[i].points_gained[j].id == 'WE902' && project.rating_system == 'LEED v4')
				{
					water_estimate = 20 + (score_card[i].points_gained[j].attempted * 5);
					water_estimate = water_estimate > 100 ? 100 : water_estimate;
				}
				else if(score_card[i].points_gained[j].id == 'MR123' && project.rating_system == 'LEED v4')
				{
					waste_estimate = 25 + (score_card[i].points_gained[j].attempted * 25);
					waste_estimate = waste_estimate > 100 ? 100 : waste_estimate;
				}                    
			}
			score_card[i]["attempted"] = total;
			main_total += total;
		}

		project_reduction.push({
			'credit_id': "energy_estimate",
			'estimation': energy_estimate,
			'zip_code': project.zip_code
		});
		project_reduction.push({
			'credit_id': "water_estimate",
			'estimation': water_estimate,
			'zip_code': project.zip_code
		});
		project_reduction.push({
			'credit_id': "waste_estimate",
			'estimation': waste_estimate,
			'zip_code': project.zip_code
		});

		project_reduction.push({
			'credit_id': "net_zero_energy",
			'estimation': net_zero_energy,
			'zip_code': project.zip_code
		});
		project_reduction.push({
			'credit_id': "net_zero_water",
			'estimation': net_zero_water,
			'zip_code': project.zip_code
		});
		project_reduction.push({
			'credit_id': "net_zero_waste",
			'estimation': net_zero_waste,
			'zip_code': project.zip_code
		});

		return {
			"project_reduction": project_reduction,
			"score_card": score_card,
			"total": main_total
		}
	}

	getClimateCode(zipcode: any)
	{
		var response;
		for(var a in this.climate_codes)
		{
			if(this.climate_codes[a].zip_code == zipcode)
			{
				response = this.climate_codes[a];
				break;
			}
		}
		if(response == undefined)
		{
			return {zip_code: zipcode, climate_code: "Climate Zone not available"}
		}
		else
		{
			return response;
		}
	}
	
	getAsset(url: string, responseType?: string)
	{
		if(responseType == undefined)
		{
			responseType = 'json'
		}
		return this.http.get<any>(url, {responseType: responseType as 'json'});
	}

	getDataMatrix()
	{
		if(this.data_matrix == null || this.climate_codes == null)
		{
			const urls = [
				'../../assets/insight_json/zip.json',
				'../../assets/insight_json/data_matrix.json'
			];

			const allRequests = urls.map(function(url)
			{
				return fetch(url).then(function(response)
				{
					return response.json();
				});
			});

			return Promise.all(allRequests).then(function(response)
			{
				return response;
			});
		}
		else
		{
			return new Promise(
				(resolve, reject) =>
				{
					resolve([this.climate_codes, this.data_matrix]);
				}
			);
		}
	}

	getZepi(project: any, zepi_energyPoints: any, net_zero_energy: any)
	{
		var zip_code = project.zip_code;
		var spaceType = project.spaceType;
		var rating_system = project.rating_system;

		if(net_zero_energy == undefined)
		{
			net_zero_energy = false;
		}

		return this.getDataMatrix().then((response: any) =>
		{
			this.climate_codes = response[0];
			this.data_matrix = response[1];

			var zepi_climateZone = project.climate_code;
			var selectedLEEDVersion = undefined;
			for(var a = 0; a < this.data_matrix.rating_systems.length; a++)
			{
				if(this.data_matrix.rating_systems[a].name == rating_system)
				{
					selectedLEEDVersion = this.data_matrix.rating_systems[a];
					break;
				}
			}

			var baseline_code = selectedLEEDVersion.base_code;
			var baseline_eui = this.data_matrix[selectedLEEDVersion.base_code][spaceType][zepi_climateZone];
			var percent_red = eval(selectedLEEDVersion.formula);
			var est_building_eui = baseline_eui * (1 - (percent_red / 100));
			var zepi_baseline_eui = this.data_matrix["90.1-2004"][spaceType][zepi_climateZone];
			if(zepi_climateZone == "Climate Zone not available")
			{
				var zepi_score = net_zero_energy ? 0 : 100;
			}
			else
			{
				var zepi_score = net_zero_energy ? 0 : (est_building_eui / zepi_baseline_eui) * 75
			}

			return {
				"baseline_code": baseline_code,
				"baseline_eui": baseline_eui,
				"percent_red": percent_red,
				"est_building_eui": est_building_eui,
				"zepi_baseline_eui": zepi_baseline_eui,
				"zepi_climateZone": zepi_climateZone,
				"zepi_score": zepi_score
			};
		});
	}

	post(url: string, payload: any, headers?: any)
	{
		if(headers == undefined)
		{
			headers = this.headers;
		}
		else
		{
			headers = headers.headers;
		}
		return this.http.post<any>(Config.insight_url + url, payload, { headers: headers });
	}

	put(url: string, payload: any, header?: any)
	{
		if(header == undefined)
		{
			header = this.headers;
		}
		return this.http.put<any>(Config.insight_url + url, payload, { headers: header });
	}

	get(url: string, responseType?: string)
	{
		if(responseType == undefined)
		{
			responseType = 'json'
		}
		return this.http.get<any>(Config.insight_url + url, { headers: new HttpHeaders(this.headers), responseType: responseType as 'json' });
	}

	delete(url: string, payload: any)
	{
		let options = {
			body: payload,
			headers: 
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": Config.it_subscription_key,
				"Authorization": "Bearer " + this.appData.get('insight_session').authorization_token
			}
		};
		return this.http.delete<any>(Config.insight_url + url, options);
	}
}

<div class="content-wrap">
	<div id="content" class="content">
        
        <div class="clearfix">
            <div>
                <h3 class="page-title">{{getHeading()}}</h3>
            </div>
        </div>
        <section class="widget">
            <div class="widget-body">
                <div class="">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>                        
                                <th class="no-sort bg_none" width="5%">Status</th>
                                <th class="bg_none w3p"></th>
                                <th width="55%">Name</th>
                                <th>Assigned to</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let action of global.copy(appData.get('all_actions'))" [hidden]="!showRow(action)">                        
                                <td class="vertical-align-middle text-center">
                                    <span [ngClass]="action.CreditStatus == 'Ready for Review' || action.CreditStatus == 'Under Review' ? 'fa fa-check' : 'far fa-circle'"></span>
                                </td>
                                <td class="bg_none"></td>
                                <td class="vertical-align-middle">
                                    <img [ngClass]="action.CreditShortId == 'base_score' ? 'p2px' : ''" [src]="getCreditIcon(action)" class="height-24 mr5 w25px">
                                    <a (click)="clickAction(action)">
                                        <span class="fw-semi-bold">{{action.CreditDescription}}
                                        </span>
                                    </a>
                                </td>
                                <td class="vertical-align-middle">
                                    <div>
                                        <span>
                                            {{action.AssignedTo}}
                                        </span> 
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
        

    </div>
</div>

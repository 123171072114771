<div class="relative-position">
    <div *ngIf="type != 'custom_cal'" [ngClass]="config.access == 'R' && !appData.get('is_admin') && config.EUserRole != 'GBCI Reviewer' ? 'not-active' : ''" class="dropdown dropdown_v2 align-right mb10 mr15">
        <div class="cursor-pointer fw-500 dropdown-toggle" data-toggle="dropdown">
            <span class="mr10">
                {{appData.get('selected_range') == 'Custom date range...' ? (global.changeDateFormat(config.range_start, 'MMM DD, YYYY') + " - " + global.changeDateFormat(config.range_end, 'MMM DD, YYYY')) : appData.get('selected_range')}}
            </span>
            <i class="toggle arrow-down initial-position"></i>
        </div>
        <ul id="range_dropdown" class="dropdown-menu left">
            <li (click)="selectRange('Last 12 months')" >
                <a translate [ngClass]="appData.get('selected_range') == 'Last 12 months' ? 'active' : ''">
                    Last 12 months
                </a>
            </li>
            <li (click)="selectRange('End of calendar')">
                <a translate [ngClass]="appData.get('selected_range') == 'End of calendar' ? 'active' : ''">
                    End of calendar 
                </a>
            </li>
            <li *ngIf="config.show_last_certified" (click)="selectRange('Last certification date')">
                <a [ngClass]="appData.get('selected_range') == 'Last certification date' ? 'active' : ''">
                    <span translate>Last certification date</span> - <span class="fs13 gray_color_707479">{{global.changeDateFormat(config.last_certified_date, 'MMM DD, YYYY')}}</span>
                </a>
            </li>
            <li (click)="selectRange('Custom date range...')">
                <a translate [ngClass]="appData.get('selected_range') == 'Custom date range...' ? 'active' : ''">
                    Custom date range...
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="type == 'custom_cal' && mode != 'project'" class="cursor-pointer divider_bottom pb10" style="width: fit-content;" (click)="selectRange('Custom date range...');selectYear(undefined, config.range_end)" [ngClass]="mode == 'portfolio_ea' ? 'arc-input-ea' : ''">
        <span class="mr10">
            {{global.changeDateFormat(config.range_start, 'MMM DD, YYYY')}} - {{global.changeDateFormat(config.range_end, 'MMM DD, YYYY')}}
        </span>
        <i class="toggle arrow-down initial-position"></i>
    </div>

    <div *ngIf="type == 'custom_cal' && mode == 'project'" class="cursor-pointer divider_bottom pb10" style="width: fit-content;" (click)="selectRange('Custom date range...');selectMonth(undefined, config.range_end)">
        <span class="mr10">
            {{global.changeDateFormat(config.range_start, 'MMM DD, YYYY')}} - {{global.changeDateFormat(config.range_end, 'MMM DD, YYYY')}}
        </span>
        <i class="toggle arrow-down initial-position"></i>
    </div>
    
    <div id="{{cal_id}}" class="{{type == 'custom_cal' ? 'default' : ''}} {{mode}} custom_cal" *ngIf="mode == 'portfolio' || mode == 'portfolio_ea'">
        <div class="p16 pb0 fs15 line_height_24 mb8">
            Select a 12-month range
        </div>
        <div class="pr16 pl16 flex pb10 divider_bottom">
            <div class="cursor-pointer mr5 cursor_previous range_start range_input">{{global.changeDateFormat(config.range_start, 'MMM DD, YYYY')}}</div>
            <div class="mr5">-</div>
            <div class="cursor-pointer cursor_next range_end range_input">{{global.changeDateFormat(config.range_end, 'MMM DD, YYYY')}}</div>
        </div>
        <div id="calendar_range" class="divider_bottom">
            <div class="range_month_selector">
                <div (click)="selectYear('subtract', config.range_month)"><i class="fa fa-angle-left"></i></div>
                <div class="fw-500">{{global.changeDateFormat(config.range_month, 'YYYY')}}</div>
                <div (click)="selectYear('add', config.range_month)"><i class="fa fa-angle-right"></i></div>
            </div>
            <div>
                <div *ngFor="let row of calendar_matrix" class="days_grid">
                    <div (click)="selectDate(day)" class="grid_item {{addMonthHighlights(day)}}" *ngFor="let day of row">
                        {{global.changeDateFormat(day, 'MMM')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="p16 flex">
            <div *ngIf="mode == 'portfolio'" class="btn btn-primary btn-md mr15" (click)="saveRanges()">
                Apply
            </div>
            <div *ngIf="mode == 'portfolio_ea'" class="btn btn-primary btn-md mr15" (click)="saveEARanges()">
                Apply
            </div>
            <div (click)="hideCal()" class="cursor-pointer fs15" style="padding: 5px 0px;">
                Cancel
            </div>
        </div>
    </div>
    
    <div id="{{cal_id}}" class="custom_cal" [ngClass]="type == 'custom_cal' ? 'default': ''" *ngIf="mode == 'project'">
        <div class="p16 pb0 fs15 line_height_24 mb8" translate>
            Select a 12-month range end date
        </div>
        <div class="pr16 pl16 flex pb10 divider_bottom">
            <div [ngClass]="change_range == 'range_start' ? 'active' : ''" class="cursor-pointer mr5 cursor_previous range_start range_input">{{global.changeDateFormat(config.range_start, 'MMM DD, YYYY')}}</div>
            <div class="mr5">-</div>
            <div [ngClass]="change_range == 'range_end' ? 'active' : ''" class="cursor-pointer cursor_next range_end range_input">{{global.changeDateFormat(config.range_end, 'MMM DD, YYYY')}}</div>
        </div>
        <div id="calendar_range" class="divider_bottom">
            <div class="range_month_selector">
                <div (click)="selectMonth('subtract')"><i class="fa fa-angle-left"></i></div>
                <div class="fw-500">{{global.changeDateFormat(config.range_month, 'MMMM, YYYY')}}</div>
                <div (click)="selectMonth('add')"><i class="fa fa-angle-right"></i></div>
            </div>
            <div class="ranges days_grid fw-500">
                <div class="grid_item" *ngFor="let day of weekdays">{{day}}</div>
            </div>
            <div>
                <div *ngFor="let row of calendar_matrix" class="days_grid">
                    <div *ngFor="let day of row" (click)="selectDate(day)" class="grid_item {{addHighlights(day)}}">{{global.changeDateFormat(day, 'DD')}}</div>
                </div>
            </div>
        </div>
        <div class="p16 flex">
            <div class="btn btn-primary btn-md mr15" (click)="saveRanges()" translate>
                Apply
            </div>
            <div (click)="hideCal()" class="cursor-pointer fs15" style="padding: 5px 0px;" translate>
                Cancel
            </div>
        </div>
    </div>
</div>
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from '../../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { PortfoliosService } from 'src/app/services/portfolios.service';

import { Config } from 'src/app/models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-portfolio-efficiency-analysis',
  templateUrl: './portfolio-efficiency-analysis.component.html',
  styleUrls: ['./portfolio-efficiency-analysis.component.css']
})
export class PortfolioEfficiencyAnalysisComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent

	loading: boolean = false;
	loading_assets: boolean = false;
	show_filter: boolean = false;
	valid_coverage: boolean = false;

	selected_projects: any = [];
	selected_codes: any = [];
	projects: any = [];
	climatelist: any = [];
	remove_projects: any = [];
	analytics_summary: any = {};
	canceler: any;
	previous_dates: any;

	pf_id: number = this.appData.get('portfolio_id');
	area_from: number = 0;
	area_to: number = 0;
	annual_emission: number = 0;
	total_certified_area_sqft: number = 0;
	page: number = 1;
	count: number = 0;
	pages: number = 1;

	selected_tab: string | null = null;
	unit_type: string = "ip";
	si_unit_label: string = "IP units (feet)";

    constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData,
		private portfolios: PortfoliosService
	)
    {}

    ngOnInit(): void
    {
		this.loading = true;
		this.api.async(
			[
				'/portfolios/ID:'+ this.pf_id +'/efficiency/climatelist/',
				'/portfolios/ID:'+ this.pf_id +'/efficiency/minmaxgfa/',
				'/portfolios/ID:'+ this.pf_id +'/efficiency/assets/?selected=True'
			]
		).subscribe(
			data =>
			{
				this.loading = false;
				this.climatelist = data[0];
				this.area_from = data[1].minimum_area;
				this.area_to = data[1].maximum_area;
				this.selected_projects = data[2].results;

				this.setTab('Summary');
				this.getAssets();
			},
			error =>
			{
				this.loading = false;
			}
		);

		if(this.appData.get('unit_preference').gfa_unit == 'sqm')
		{
			this.unit_type = 'si';
			this.si_unit_label = 'SI units (meters)';
		}
		
		this.getAnalyticsSummary(this.unit_type);
	}

	getAnalyticsSummary(unit_type: string)
	{
		this.loading = true;
		this.portfolios.getEASummary(this.pf_id, unit_type.toUpperCase()).subscribe(
			response =>
			{
				this.analytics_summary = response;
				this.total_certified_area_sqft = response.total_certified_area_sqft;
				this.annual_emission = response.total_emissions;
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	changeUnit(unit: any)
	{
		this.unit_type = unit;
		if (unit == "si")
			this.si_unit_label = "SI units (meters)";
		else if (unit == "ip")
			this.si_unit_label = "IP units (feet)";

		this.getAnalyticsSummary(unit.toUpperCase());
	};

	setClimateZone(climate_code: string)
	{
		if(this.selected_codes.indexOf(climate_code) == -1)
		{
			this.selected_codes.push(climate_code);
		}
		else
		{
			this.selected_codes.splice(this.selected_codes.indexOf(climate_code), 1);
		}
		this.getAssets();
	}

	setTab(tab: string)
	{
		if(this.selected_projects.length == 0 && tab != 'About')
		{
			this.selected_tab = 'Get Started';
			return;
		}
		this.selected_tab = tab;
	}

	openModal(dates?: any)
	{
		this.previous_dates = dates;
		this.modal.open({ windowClass: 'certificate_modal manage_team_modal', size: 'lg' });
		for(let i = 0; i < this.selected_projects.length; i++)
		{
			this.selected_projects[i].coverage = 'loading';
			this.selected_projects[i].coverage_flag = 'loading';
			this.dataValidate(this.selected_projects[i]);
		}
	}

	closeModal()
	{
		if(this.previous_dates != undefined)
		{
			Config.range_end = moment(this.previous_dates.previous_range_end);
			Config.range_start = moment(this.previous_dates.previous_range_start);
			this.previous_dates = undefined;
		}
		this.modal.close();
	}

	checkValidCoverage()
	{
		let valid_coverage = true;
		for(let i in this.selected_projects)
		{
			if(this.selected_projects[i].coverage < 100)
			{
				valid_coverage = false;
				break;
			}
		}
		this.valid_coverage = valid_coverage;
	}

	dataValidate(project: any)
	{
		this.api.get(
			"/assets/LEED:"+ project.leed_id +"/datavalidate/energy/?at=" + moment(Config.range_end).format('YYYY-MM-DD')
		).subscribe(
			data =>
			{
				for(let i in this.selected_projects)
				{
					if(this.selected_projects[i].leed_id == project.leed_id)
					{
						this.selected_projects[i].coverage = data.percentage;
						this.selected_projects[i].coverage_flag = data.coverage_flag;
						break;
					}
				}
				this.checkValidCoverage();
			},
			error =>
			{
				this.alertService.alert('error', 'Error getting data coverage for ' + project.name, 5);
			}
		);
	}

	getDates(dates: any)
	{
		this.previous_dates = dates;
		for(let i = 0; i < this.selected_projects.length; i++)
		{
			this.selected_projects[i].coverage = 'loading';
			this.selected_projects[i].coverage_flag = 'loading';
			this.dataValidate(this.selected_projects[i]);
		}
	}

	getCoverage(project: any)
	{
		if(this.selected_projects.length == 10)
		{
			this.checkValidCoverage();
			return;
		}
		for(let i in this.selected_projects)
		{
			if(this.selected_projects[i].leed_id == project.leed_id)
			{
				this.selected_projects.splice(i, 1);
				this.remove_projects.push(project.leed_id);
				this.checkValidCoverage();
				return;
			}
		}
		this.selected_projects.push(
			{
				"name": project.name,
				"leed_id": project.leed_id,
				"coverage": 'loading',
				"coverage_flag": 'loading'
			}
		);
		this.api.get(
			"/assets/LEED:"+ project.leed_id +"/datavalidate/energy/?at=" + moment(Config.range_end).format('YYYY-MM-DD')
		).subscribe(
			data =>
			{
				for(let i in this.selected_projects)
				{
					if(this.selected_projects[i].leed_id == project.leed_id)
					{
						this.selected_projects[i].coverage = data.percentage;
						this.selected_projects[i].coverage_flag = data.coverage_flag;
						break;
					}
				}
				this.checkValidCoverage();
			},
			error =>
			{
				this.alertService.alert('error', 'Error getting data coverage for ' + project.name, 5);
			}
		);
	}

	removeProject(assets: [])
	{
		if(assets.length == 0)
		{
			return;
		}
		this.api.delete(
			'/portfolios/ID:'+ this.pf_id +'/efficiency/assets/',
			{
				"assets": assets,
				"start_date": Config.range_start.format('YYYY-MM-DD'),
				"end_date": Config.range_end.format('YYYY-MM-DD')
			}
		).subscribe(
			data =>
			{

			}
		);
	}
	
	saveRanges()
	{
		Config.app_loader = true;
		let payload = {
			"performance_start_date": Config.range_start.format('YYYY-MM-DD'),
			"performance_end_date": Config.range_end.format('YYYY-MM-DD'),
			"name": this.appData.get('portfolio').name
		}
		if(this.appData.get('selected_range') == 'Last 12 months')
		{
			payload["performance_end_date"] = null;
			payload["performance_start_date"] = null;
		}

		this.portfolios.savePerformancePeriod(
			payload
		).subscribe(
			data =>
			{
				Config.app_loader = false;
				this.appData.set('portfolio', data);
				this.createModel();
			},
			error =>
			{

			}
		);
	}

	createModel()
	{
		this.removeProject(this.remove_projects);
		this.remove_projects = [];

		let assets = [], payload = {};
		for(let idx in this.selected_projects)
		{
			assets.push(
				this.selected_projects[idx].leed_id
			);
		}
		payload = {
			"assets": assets,
			"start_date": Config.range_start.format('YYYY-MM-DD'),
			"end_date": Config.range_end.format('YYYY-MM-DD')
		};

		this.loading = true;
		this.closeModal();

		this.api.post(
			'/portfolios/ID:'+ this.pf_id +'/efficiency/assets/',
			payload
		).subscribe(
			data =>
			{
				window.location.reload();
				this.loading = false;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	getAssets(page?: number)
	{
		if(page == undefined)
		{
			page = 1;
		}
		this.page = page;
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		if(Config.range_end == undefined)
		{
			let end_date = this.appData.get('portfolio').performance_end_date == null ? new Date() : this.appData.get('portfolio').performance_end_date;
			Config.range_end = moment(end_date);
			Config.range_start = moment(end_date).subtract(1, 'year').add(1, 'day');
		}
		let url = '/portfolios/ID:'+ this.pf_id +'/efficiency/assets/?page=' + this.page;
		if(this.selected_codes.length > 0)
		{
			url += '&climatezone=' + this.selected_codes.join(',');
		}
		if(!(this.area_from == 0 && this.area_to == 0))
		{
			url += '&area_from='+ this.area_from +'&area_to='+ this.area_to;
		}

		this.loading_assets = true;
		this.canceler = this.api.get(
			url
		).subscribe(
			data =>
			{
				this.loading_assets = false;
				this.count = data.count;
				this.pages = Math.ceil(this.count/ 10);
				if(this.page == 1)
				{
					this.projects = data.results;
				}
				else
				{
					this.projects = this.projects.concat(data.results);
				}
			},
			error =>
			{
				this.loading_assets = false;
				this.alertService.alert('error', 'Error getting Projects', 5);
			}
		);
	}

	onWindowScroll($event: any)
	{
		let pos = ((<any>document.getElementById('project_list')).scrollTop || document.body.scrollTop) + (<any>document.getElementById('project_list')).offsetHeight;
		let max = ((<any>document.getElementById('project_list')).scrollHeight);
		if((Math.round(max) - Math.round(pos) < 10) && this.page < this.pages && !this.loading_assets)
		{
			this.getAssets(this.page + 1);
		}

	}

}

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

declare let AmCharts: any;

@Component({
  selector: 'app-insight-strategies',
  templateUrl: './insight-strategies.component.html',
  styleUrls: ['./insight-strategies.component.css']
})
export class InsightStrategiesComponent implements OnInit
{
	@ViewChildren('score_card_elem') score_card_elem!: QueryList<any>;

	zipcode: any = ''
	score_card: any = [];
	loading_data: boolean = false;
	range_in_mile: any = 1;
	project_types: any;
	project = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');

	net_zero_energy: boolean = false;
	net_zero_water: boolean = false;
	net_zero_waste: boolean = false;
	energy_estimate: number = 0;
	water_estimate: number = 0;
	waste_estimate: number = 0;
	project_reduction: any = {};

	data: any = {
		"reduction": {
			"energy": {
				"list": [],
				"meta": {}
			},
			"water": {
				"list": [],
				"meta": {}
			},
			"waste": {
				"list": [],
				"meta": {}
			},
			"zepi_list": []
		},
		"credits": {},
		"total_projects": 0
	};
	climate_code: string = '';

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngAfterViewInit()
	{
		this.score_card_elem.changes.subscribe(t =>
		{
			let credit: any;
			for(let n in this.score_card)
			{
				let item = this.score_card[n];
				if(this.data.credits.meta == undefined)
				{
					credit = {
						"count": 0,
						"max": 0,
						"average": 0,
						"points": 0,
						"min": 0
					}
				}
				else
				{
					credit = this.data.credits.meta[item.id];
				}
				if(credit == undefined)
					continue;
				this.drawChart(item.id + '_category', credit.min, credit.max, credit.average, item.attempted, item.max_points);
			}
		});
	}

	ngOnInit(): void
	{
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.version_id).subscribe(
            //success handler
            response =>
            {
                this.project_reduction = response;
                let reduction_data = response.reduction_data;
                for(let k in reduction_data)
                {
                    if(reduction_data[k].credit_id == 'net_zero_energy')
                    {
                        this.net_zero_energy = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_water')
                    {
                        this.net_zero_water = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_waste')
                    {
                        this.net_zero_waste = reduction_data[k].estimation;
                    }

                    if(reduction_data[k].credit_id == 'energy_estimate')
                    {
                        this.energy_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'water_estimate')
                    {
                        this.water_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'waste_estimate')
                    {
                        this.waste_estimate = reduction_data[k].estimation;
                    }
                }

                if(this.net_zero_energy)
                {
                    this.energy_estimate = 0;
                }
                if(this.net_zero_water)
                {
                    this.water_estimate = 100;
                }
                if(this.net_zero_waste)
                {
                    this.waste_estimate = 100;
                }

                this.climate_code = this.insight.getClimateCode(this.project.zip_code).climate_code;
            },
            //error handler
            function(response)
            {
                
            }
        );
	}

	searchData(data: any)
	{
		setTimeout(() =>
		{
			this.loading_data = true;
		});
		this.insight.getData(data.range_in_mile_input, data.zipcode_input, data.project_types_input, false, true, true).subscribe(
			response =>
			{
				this.data = response;
				this.zipcode = data.zipcode_input;
				this.range_in_mile = data.range_in_mile_input;
				this.project_types = data.project_types_input;
				(<any>$("#project_type")).selectpicker('render');
				console.log(this.project)
				this.getScoreCard(this.project.rating_system);
				setTimeout(() =>
				{
					this.loading_data = false;
				});
			},
			error =>
			{
				setTimeout(() =>
				{
					this.loading_data = false;
				});
			}
		);
	}

	getScoreCard(rating_system: string)
	{
		this.insight.getAsset('/assets/insight_json/'+ rating_system +'.json').subscribe((response) =>
		{
			this.score_card =  this.insight.initScorecard(this.project_reduction.reduction_data, response, this.project.rating_system).score_card;
		});
	};

	drawChart(type: string, min: number, max: number, average: number, value: number, max_points: number)
	{
		min = Math.round(min);
		max = Math.round(max);
		average = Math.round(average);
		value = Math.round(value);

		if(average > max_points)
		{
			average = max_points;
		}

		let addClassNames = false;
		let reversed = false;
		let project_value = '<div class="ballon"><p class="heading">Points</p><div class="values">[[project_value]]</div></div>';
		let labelFunction = function(valueText: any, serialDataItem: any, categoryAxis: any)
		{
			return String(valueText);
		};

		let local_average = '<div class="ballon"><p class="heading">Points(s)</p><div class="values">[[local_average]]</div></div>';
		let chart = AmCharts.makeChart(type, {
			"type": "serial",
			"theme": "none",
			"addClassNames": addClassNames,
			"dataProvider": [{
				"axis": 0,
				"project_value": value,
				"local_average": average,
				"start_range": min,
				"end_range": max,
			}],
			"valueAxes": [{
				"axisAlpha":1,
				"minimum": 0,
				"maximum": max_points + 5,
				"strictMinMax": true,
				"color": "#68737D",
				"axisColor": "#BAC1CA",
				"fontSize": 13,
				"gridAlpha": 0,
				"tickLength": 12,
				"offset": -14,
				"reversed": reversed,
				"labelFunction": labelFunction
			}],
			"graphs": [{
				"title": "Column",
				"type": "column",
				"fillAlphas": 1,
				"openField": "start_range",
				"valueField": "end_range",
				"fixedColumnWidth": 20,
				"lineColor": "#EBEDED",
				"showBalloon": false
			}, {
				"bullet": "round",
				"bulletBorderAlpha": 1,
				"bulletColor": "#00A5C8",
				"bulletSize": 10,
				"title": "Local Average Score",
				"valueField": "local_average",
				"balloonText": local_average,
				"balloonColor": "white",
				"balloon": {
					"borderAlpha": 0,
					"borderThickness": 0,
					"fillAlpha": 0
				},
			},
			{
				"bulletSize": 20,
				"title": "zEPI Score",
				"valueField": "project_value",
				"customBullet": "//static-app.arconline.io/data-input-widget-assets-nonprd/svg/concentric.svg",
				"balloonText": project_value,
				"balloonColor": "white",
				"balloon": {
					"borderAlpha": 0,
					"borderThickness": 0,
					"fillAlpha": 0
				},
				"bulletColor": "#FFFFFF"
			}],
			"rotate": true,
			"categoryField": "axis",
			"categoryAxis": {
				"axisAlpha": 0,
				"gridAlpha": 0,
				"labelsEnabled": false
			}            
		});
	};

}

<div class="content-wrap">
	<div id="content" class="content">
        
        <script type="text/ng-template" id="recursive_renderer.html">
            <div [hidden]="checkView(option) || option.hidden" [ngClass]="option.disabled ? 'not-active' : ''">
                <div id="{{option.id}}" ng-style="{'padding-left': (option.indent_level * 25) + 'px'}" class="mt10 cursor-pointer">
                    <div [ngClass]="loader ? 'not-active' : ''" *ngIf="option.content_type == 'checkbox' || option.content_type == 'button' || option.content_type == 'input'" class="{{option.content_type}}">
                        <div class="affirmationLoaderSpinner" *ngIf="loader"></div>
                        <input (input)="changeMeasure(option, this, $parent, null)" [(ngModel)]="option.data" type="{{option.content_type}}"/> 
                    
                        <label>
                            <span *ngIf="option.points != undefined">
                                <span *ngIf="option.points == '1'">[{{option.points}} Point]</span>
                                <span *ngIf="option.points != '1'">[{{option.points}} Points]</span>
                            </span>            
                            <span [[innerHTML]]="option.content"></span>
                        </label>
                    </div>
        
                    <div *ngIf="option.content_type == 'textarea'">
                        <!-- {{option.id}} -->
                        <textarea class="measureTextarea" placeholder="{{option.placeholder}}" [(ngModel)]="option.data" (blur)="changeMeasure(option, this, $parent, null)">{{option.data}}</textarea> 
                    </div>
                    <div *ngIf="option.content_type == 'list_item_circle'">
                        <span><div class="measureBullet fa fa-circle fontSize7" aria-hidden="true"></div> </span>
                        <span [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'list_item_circle-o'">
                        <span><div class="measureBullet fa fa-circle-o fontSize7" aria-hidden="true"></div> </span>
                        <span [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'list_item_square'">
                        <span><div class="measureBullet fa fa-square fontSize7" aria-hidden="true"></div> </span>
                        <span [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'list_item'">
                        <span><div class="measureBullet fa fa-square fontSize7" aria-hidden="true"></div> </span>
                        <span [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'heading_item'">
                        <span class="fw-bold" [innerHTML]="option.content"></span>
                    </div>
                    <div *ngIf="option.content_type == 'info_item'">
                        <span [innerHTML]="option.content"></span>
                    </div>
                </div>
                <div *ngIf="option.children != null">
                    <div *ngFor="option in option.children" ng-include="'recursive_renderer.html'">
                    </div>
                </div>
            </div>
        </script> 
        
        <div class="between-div">
            <h3 class="page-title">{{credit.CreditDescription}}</h3>
            <div class="dataTables_paginate paging_bootstrap" id="DataTables_Table_1_paginate">
                <ul class="pagination no-margin">
                    <button type="button" class="btn btn-default prev mr10" (click)="creditScreenChange('previous')"><i class="font11 mr7 fa fa-chevron-left" aria-hidden="true"></i> Previous</button>
                    <button type="button" class="btn btn-default next" (click)="creditScreenChange('next')">Next <i class="font11 ml7 fa fa-chevron-right" aria-hidden="true"></i></button>
                </ul>
            </div>
        </div>
        <app-loader *ngIf="parent_loader"></app-loader>
        <div [ngClass]="parent_loader ? 'not-active' : ''">
            <div class="row" id="actions-form">
                <div class="col-md-8">
        
                    <!-- Affirmations section is common for all project types -->
                    <section class="widget">
                        <header>
                            <h4>Affirmations&nbsp;<span class="fw-semi-bold"></span></h4>
                        </header>
                        <div class="widget-body" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''">
                            <div *ngIf="config.static_credit" class="checkbox" [ngClass]="config.access == 'R' || config.file_loader || credit.CreditStatus == 'Ready for Review' ||  credit.CreditStatus == 'Under Review' ? 'not-active' : ''">
                                <div class="affirmationLoaderSpinner" *ngIf="config.policy_loader"></div>

                                <div class="checkbox pl0 blue_check ck_gray_border mt20 mb20">
                                    <input id="policy_check" type="checkbox" (change)="toggleAffirmation('policy_check', credit.CreditId)" [(ngModel)]="config.policy_check">                
                                    
                                    <label class="pl5 line_height_18" for="checkbox">The <a target="_blank" href="{{global.getPolicyUrl(credit.CreditShortId)}}">policy</a> on the <a target="_blank" href="{{resource_url}}">resources page</a> has been implemented for the project.</label>
                                </div>
                            </div>
                            <div class="checkbox">
                                <div class="affirmationLoaderSpinner" *ngIf="config.file_loader" [ngClass]="config.access == 'R' || config.policy_loader || credit.CreditStatus == 'Ready for Review' || credit.CreditStatus == 'Under Review' ? 'not-active' : ''"></div>

                                <div class="checkbox pl0 blue_check ck_gray_border">
                                    <input id="file_check" type="checkbox" (change)="toggleAffirmation('file_check', credit.CreditId)" [(ngModel)]="config.file_check">                
                                    
                                    <label class="pl5 line_height_18" *ngIf="config.static_credit" for="checkbox">And/or a custom policy meeting the prerequisite requirements has been implemented for the project. A copy of the policy has been uploaded.</label>
                                    <label class="pl5 line_height_18" *ngIf="!(config.static_credit)" for="checkbox">All required files for the current performance period have been uploaded.</label>
                                </div>

                                <!-- <input type="checkbox" (change)="toggleAffirmation('file_check', credit.CreditId)" class="file_check cursor-pointer" [(ngModel)]="config.file_check"> 

                                <label *ngIf="config.static_credit" for="checkbox">And/or a custom policy meeting the prerequisite requirements has been implemented for the project. A copy of the policy has been uploaded.</label>
                                <label *ngIf="!(config.static_credit)" for="checkbox">All required files for the current performance period have been uploaded.</label> -->
                            </div>
                        </div>
                    </section>
                    <!-- for city/district/neighborhood only -->
                    <section class="widget" *ngIf="project_type !='building' && project_type !='transit'">
                        <!-- Commit to sharing data -->
                        <!-- Project boundary -->
                        <!-- Governance -->
                        <!-- Skateholders -->
                        <!-- Meeting -->
                        <!-- Roadmap -->
                        <div [hidden]="measure_list.length == 0" class="" *ngFor="let option of measure_list" ng-include="'recursive_renderer.html'">
                        </div>
                    </section>
                    <!-- for city/district/neighborhood only -->
                    
                    <!-- Activities section is common for all project types -->
                    <section class="widget">
                        <h4>Activity</h4>
                        <section class="event activity_container">
                            <ul>
                                <li *ngFor="let credit_activity of credit_activities">
                                    <div class="comment-body">
                                        <h5 class="author fw-semi-bold"><span [innerHTML]="credit_activity.verb"></span> <span class="fs-sm text-muted">on {{global.dateTimeFormat(credit_activity.timestamp)}}</span></h5>
                                    </div>
                                </li>
                            </ul>
                            <div *ngIf="credit_activities.length == 0" class='text-center no_act_div'>No activity present</div>
                        </section>
                    </section>
                </div>
                
                <!-- for building only -->
                <form name="FileS3" action="" method="POST" enctype="multipart/form-data" class="form-horizontal hide" novalidate="">
                </form>
                <div class="col-md-4" *ngIf="project_type =='building' || project_type =='transit'">
                    <section class="widget">
                        <div class="widget-body">
                            <div [ngClass]="(credit.CreditDescription).toLowerCase().indexOf('policy') > -1 ? true ? '' : 'not-active' : (config.file_check) ? '' : 'not-active'" class="mt20 mln10">
                                <div *ngIf="credit.CreditStatus != 'Under Review'" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''">
                                    <span class="far fa-clock" aria-hidden="true"></span>
                                    <div class="dropdown display-inline ml10">
                                        <button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
                                        <span>{{new_credit_status}}</span>
                                        <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="creditChanged('Attempted', this)"><a>Attempted</a></li>
                                            <li (click)="creditChanged('Ready for Review', this)"><a>Ready for Review</a></li>
                                        </ul>
                                        <div *ngIf="changing_credit_flag" class="dataInputLoaderSpinner ml10"></div>
                                    </div>
                                </div>
                            
                                <div *ngIf="credit.CreditStatus == 'Under Review'">
                                    <span class="far fa-clock" aria-hidden="true"></span>
                                    <span class="ml10">Under Review</span>
                                </div>
                            
                            </div>
                            <span *ngIf="(credit.CreditDescription).toLowerCase().indexOf('policy') > -1 ? true ? false : true : (config.file_check) ? false : true" class="danger-color">Affirmation required before changing the status</span>
                        
                            <div class="mt20 mln10" [ngClass]="config.access != 'CRUD' || credit.CreditStatus == 'Under Review' ? 'not-active' : ''">
                                <span class="fa fa-user" aria-hidden="true"></span>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
                                    <span>{{team.teamMember}}</span>
                                    <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li *ngFor="let team of project_team" (click)="teamChanged(team)"><a>{{team.name}} ({{team.email}})</a></li>
                                    </ul>
                                    <div *ngIf="changing_assignee_flag" class="dataInputLoaderSpinner ml10"></div>
                                </div>
                            </div>
                            <div class="row mt20">
                                <p class="pl15">
                                    <a type="button" class="btn btn-default" target="_blank" href="{{credit_lib_link}}">Credit Library</a>
                                </p>
                                
                                <p class="btn-toolbar col-md-5"  *ngIf="config.resource_credits">
                                    <a type="button" class="btn btn-default" target="_blank" href="{{resource_url}}">Resources</a>
                                </p>
                            </div>   
                        
                        </div>
                    </section>
                    <section class="widget col-md-12">
                        <div class="widget-body">
                            <div class="row">
                                <h3 class="page-title mt0 pl15 mb10 fs20">File Upload</h3>
                            </div>
                            <div class="width-100-perc pull-left pl15" id="uploaded_files">
                                <div *ngIf="config.currentUpload">
                                    <span class='uploadLoaderSpinner'></span>
                                    <span [innerHTML]="config.currentUploadName"></span>
                                </div>
                                <div *ngFor="let uploaded_file of config.uploaded_files" class="flex mb3">
                                    <a class="anchor_blue_color" (click)="global.downloadFile(uploaded_file)"><span class="hover_underline">{{global.getFileName(uploaded_file)}}</span></a>
                                    <div (click)="global.deleteFile(uploaded_file, config.upload_category)" [ngClass]="{'deactivateSelect': config.access != 'CRUD'}">
                                        <span id="project-structure-images" title="Delete file" class='ml10 far fa-trash-alt cursor-pointer'></span>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="process_status || credit.CreditStatus == 'Ready for Review' ||  credit.CreditStatus == 'Under Review' ? 'not-active' : ''" class="row">
                                <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="col-md-12 mt10">
        
                                    <div *ngIf="!global.getBrowser().ie">
                                        <input type="file" id="credit_file" name="credit_file" class="hide" #elem (input)="global.fileNameChanged(elem)"/>
                                        <label data-tooltip-placement="top" data-tooltip="Upload using computer" (click)="clearAllFiles()" [ngClass]="uploading_file_flag ? 'not-active' : ''" class="viewUpload laptop" for="credit_file">
                                            <i class="fa fa-upload" aria-hidden="true"></i>
                                        </label>
                                        <div data-tooltip-placement="top" data-tooltip="Upload using Dropbox" *ngIf="appData.get('dropbox_installed')" (click)="global.openUploadDialog('dropbox', CreditShortId, 'form')" type="energy" class="viewUpload dropBox">
                                            <i class="fab fa-dropbox" aria-hidden="true"></i>
                                        </div>
                                        <div data-tooltip-placement="top" data-tooltip="Upload using OneDrive" *ngIf="appData.get('oneDrive_installed')" (click)="global.openUploadDialog('windows', CreditShortId, 'form')" type="energy" class="viewUpload oneDrive">
                                            <i class="fab fa-windows" aria-hidden="true"></i>
                                        </div>
                                        <div  data-tooltip-placement="top" data-tooltip="Upload using Google Drive" *ngIf="appData.get('googleDrive_installed')" (click)="global.openUploadDialog('google', CreditShortId, 'form')" type="energy" class="viewUpload googleDrive"></div>
                                    </div>
        
                                    <div *ngIf="global.getBrowser().ie" class="btn-group dropdown open" data-dropdown="" >
                                        <button class="btn btn-default dropdown-toggle" data-dropdown-toggle="" data-original-title="" title="" aria-haspopup="true" aria-expanded="true"><i class="fa fa-upload"></i> Upload<i class="ml10 fa fa-caret-down"></i>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a for="credit_file" (click)="clearAllFiles()" [ngClass]="uploading_file_flag ? 'not-active' : ''" class="pl10" href="#">
                                                    <i class="fa fa-desktop"></i> From Computer 
                                                </a>
                                                <input type="file" id="credit_file" name="credit_file" class="hide" onchange="angular.element(this).scope().uploadFiles(this.files)" multiple/>
                                            </li>
                                            <li>
                                                <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('dropbox_installed')" (click)="global.openUploadDialog('dropbox', CreditShortId, 'form')" class="pl10" href="#">
                                                    <i class="dropBoxDrp"></i> From Dropbox
                                                </a>
                                            </li>
                                            <li>
                                                <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('oneDrive_installed')" (click)="global.openUploadDialog('windows', CreditShortId, 'form')"  class="pl10" href="#">
                                                    <i class="oneDriveDrp"></i> From OneDrive
                                                </a>
                                            </li>
                                            <li>
                                                <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('googleDrive_installed')" (click)="global.openUploadDialog('google', CreditShortId, 'form')" class="pl10" href="#">
                                                    <i class="googleDriveDrp"></i> From Google Drive
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
        
                                    <div *ngIf="uploading_file_flag" class="pt10"><span class="uploadLoaderSpinner"></span>{{selected_file}}</div>
                                </div>
                                <div *ngIf="error_file_flag" class="danger-color">{{error_msz_file_upload}}</div>
                            </div>
        
                            <div class="row mt10">
                                <h3 class="page-title mt0 pl15 mb10 fs20" *ngIf="!(config.static_credit)">Credit Form</h3>
                            </div>
                            
                            <div class="col-md-5 mb10" *ngIf="!(config.static_credit)">
                                <div class="row">
                                    <form name="Fileform" action="" method="POST" enctype="multipart/form-data" class="form-horizontal pull-left" novalidate="">
                                        <p>
                                            <a *ngIf="!global.getBrowser().ie" data-tooltip-placement="top" data-tooltip="Download credit form" [ngClass]="downloading_flag ? 'not-active' : ''" class="btn btn-default pull-left" (click)="downloadCreditForm()">{{download_msz}}</a>
                                            <a *ngIf="global.getBrowser().ie" [ngClass]="downloading_flag ? 'not-active' : ''" class="btn btn-default pull-left" (click)="downloadCreditForm()">{{download_msz}}</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            
                            <div class="col-md-7 mb10" *ngIf="!(config.static_credit)" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''">
                                <div class="">
                                    <input #files type="file" id="credit_form" name="credit_form" class="hide" (input)="uploadForm(files)"/>
                                    <label data-tooltip-placement="top" data-tooltip="Upload the filled pdf form file" [ngClass]="credit.CreditStatus == 'Ready for Review' ||  credit.CreditStatus == 'Under Review' ? 'not-active' : ''" (click)="clearAllForm()" [ngClass]="uploading_form_flag ? 'not-active' : ''" class="btn btn-default display_inline_tbl" for="credit_form">{{upload_form_msz}}</label>
                                    <div class="word-wrap"><span>{{selected_form}}</span></div>
                                    <div *ngIf="error_form_flag" class="danger-color">{{error_msz_form_upload}}</div>
                                </div>
                            </div>
        
                        </div>
                    </section>
                </div>
        
                <!-- for city/district/neighborhood only -->
                <div class="col-md-4" *ngIf="project_type !='building' && project_type !='transit'">
                    <section class="widget col-md-12">
                        <div class="widget-body">
                            <h3>Status</h3>
                            <div [ngClass]="(credit.CreditDescription).toLowerCase().indexOf('policy') > -1 ? (config.policy_check && config.file_check) ? '' : 'not-active' : (config.file_check) ? '' : 'not-active'">
                                <div *ngIf="credit.CreditStatus != 'Under Review'" [ngClass]="config.access != 'CRUD' ? 'not-active' : ''">
                                    <span class="far fa-clock" aria-hidden="true"></span>
                                    <div class="dropdown display-inline ml10">
                                        <button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
                                        <span>{{new_credit_status}}</span>
                                        <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li (click)="creditChanged('Attempted', this)"><a>Attempted</a></li>
                                            <li (click)="creditChanged('Ready for Review', this)"><a>Ready for Review</a></li>
                                        </ul>
                                        <div *ngIf="changing_credit_flag" class="dataInputLoaderSpinner ml10"></div>
                                    </div>
                                </div>
                            
                                <div *ngIf="credit.CreditStatus == 'Under Review'">
                                    <span class="far fa-clock" aria-hidden="true"></span>
                                    <span class="ml10">Under Review</span>
                                </div>
                            </div>
                            <span *ngIf="(credit.CreditDescription).toLowerCase().indexOf('policy') > -1 ? (config.policy_check && config.file_check) ? false : true : (config.file_check) ? false : true" class="danger-color">Affirmation required before changing the status</span>
                            <h3>Team</h3>
                            <div class="mt10" [ngClass]="config.access != 'CRUD' || credit.CreditStatus == 'Under Review' ? 'not-active' : ''">
                                <span class="fa fa-user" aria-hidden="true"></span>
                                <div class="dropdown display-inline ml10">
                                    <button class="btn dropdown-toggle btn-default btn-sm" type="button" data-toggle="dropdown" >
                                    <span>{{team.teamMember}}</span>
                                    <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li *ngFor="let team of project_team" (click)="teamChanged(team)"><a>{{team.name}} ({{team.email}})</a></li>
                                    </ul>
                                    <div *ngIf="changing_assignee_flag" class="dataInputLoaderSpinner ml10"></div>
                                </div>
                            </div>
                            <h3>Add supporting documents</h3>
                            <div>
                                <div *ngFor="let uploaded_file of config.uploaded_files_form" class="col-md-12 pl0 mb5 flex">
                                    <div (click)="global.deleteFile(uploaded_file)">
                                        <span [ngClass]="credit.CreditStatus == 'Ready for Review' ||  credit.CreditStatus == 'Under Review' || config.access != 'CRUD' ? 'not-active' : ''" class='far fa-trash-alt cursor-pointer align_file_name'></span>
                                    </div>
                                    <a class="word_wrap" (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                </div>
                                <p *ngIf="config.currentUpload" class="mb5">
                                    <span class='uploadLoaderSpinner'></span>
                                    <span [innerHTML]="config.currentUploadName"></span>
                                </p>
                            </div>
                            <div [ngClass]="config.currentUpload || credit.CreditStatus == 'Ready for Review' ||  credit.CreditStatus == 'Under Review' ? 'not-active' : ''" class="col-md-12 pl0 mb20 mt10">
                                <div *ngIf="!global.getBrowser().ie">
                                    <input #elem (input)="global.fileNameChanged(elem)" class="hide uploadFile" type="file">
                                    <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" data-tooltip-placement="top" data-tooltip="Upload using computer" (click)="global.openUploadDialog('laptop', credit.CreditShortId, 'S3')" class="viewUpload laptop"><i class="fa fa-upload" aria-hidden="true"></i></div>
                                    <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" data-tooltip-placement="top" data-tooltip="Upload using Dropbox" *ngIf="appData.get('dropbox_installed')" (click)="global.openUploadDialog('dropbox', credit.CreditShortId, 'S3')" class="viewUpload dropBox"><i class="fab fa-dropbox" aria-hidden="true"></i></div>
                                    <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" data-tooltip-placement="top" data-tooltip="Upload using OneDrive" *ngIf="appData.get('oneDrive_installed')" (click)="global.openUploadDialog('windows', credit.CreditShortId, 'S3')"  class="viewUpload oneDrive"><i class="fab fa-windows" aria-hidden="true"></i></div>
                                    <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" data-tooltip-placement="top" data-tooltip="Upload using Google Drive" *ngIf="appData.get('googleDrive_installed')" (click)="global.openUploadDialog('google', credit.CreditShortId, 'S3')" class="viewUpload googleDrive"></div>
                                </div>
        
                                <div *ngIf="global.getBrowser().ie" class="btn-group dropdown open" data-dropdown="" >
                                    <button class="btn btn-default dropdown-toggle" data-dropdown-toggle="" data-original-title="" title="" aria-haspopup="true" aria-expanded="true"><i class="fa fa-upload"></i> Upload<i class="ml10 fa fa-caret-down"></i>
                                    </button>
                                    <input #elem (input)="global.fileNameChanged(elem)" class="hide uploadFile" type="file">
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" (click)="global.openUploadDialog('laptop', credit.CreditShortId, 'S3')" class="pl10" href="#">
                                                <i class="fa fa-desktop"></i> From Computer 
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('dropbox_installed')" (click)="global.openUploadDialog('dropbox', credit.CreditShortId, 'S3')" class="pl10" href="#">
                                                <i class="dropBoxDrp"></i> From Dropbox
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('oneDrive_installed')" (click)="global.openUploadDialog('windows', credit.CreditShortId, 'S3')"  class="pl10" href="#">
                                                <i class="oneDriveDrp"></i> From OneDrive
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" *ngIf="appData.get('googleDrive_installed')" (click)="global.openUploadDialog('google', credit.CreditShortId, 'S3')" class="pl10" href="#">
                                                <i class="googleDriveDrp"></i> From Google Drive
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        
            </div>
            
            <div class="modal fade confirmation_modal_credit" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-sm" style="width: 460px;">
                    <div class="modal-content p30 modal_radius">
                        <p class="align-center">This credit will automatically display in your base score, but will have to undergo review by GBCI before being awarded.</p>
                        <div class="mt30 align-center"> 
                            <button (click)="changeCreditStatus(selectedCredit)" type="button" class="mr30 mb10 btn btn-default" title="" data-popover-placement="left" data-popover="" data-dismiss="modal">Ok</button>
        
                            <button (click)="changeCreditStatusCancel()" type="button" class="mb10 btn btn-default" title="" data-popover-placement="left" data-popover="" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        

    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from '../../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import * as moment from 'moment';
import * as stream from 'getstream';

@Component({
  selector: 'app-financial-models',
  templateUrl: './financial-models.component.html',
  styleUrls: ['./financial-models.component.css']
})
export class FinancialModelsComponent implements OnInit
{
	config = Config;
	certificate_annually: number = 0;
	feeds: any = [];
	retry: any = [];
	reports: any = [];
	subscription: any = {
		"card": {}
	};

	tab: string = '';
	current_status: string | undefined = '';
	offline_sub: boolean = false;
	loading: boolean = false;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		if(["Current Models", "Past Models", "Subscription"].indexOf(this.global.getURLFromParam('tab')) != -1)
        {
            this.tab = this.global.getURLFromParam('tab');
			if(this.tab == 'Past Models')
			{
				this.getReports();
			}
        }
		else
		{
			this.tab = 'Current Models';
		}

		this.initFeed();
		this.getSubscription();
	}

	toggleTab(tab: string)
	{
		if(tab == this.tab)
		{
			return;
		}
		this.tab = tab;
		if(tab == 'Past Models')
		{
			this.getReports();
		}
	};

	getReports()
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=autocase_report'
		).subscribe(data =>
		{
			data = data.sort((a: any, b: any) =>
			{ 
				return new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime() 
			}).reverse();
			this.loading = false;
			for(var n = 0; n < data.length; n++)
			{
				data[n].document_metadata = JSON.parse(data[n].document_metadata.replace(/'/g, '"'));
			}
			this.reports = data;
		});
	}

	getMonthDiff(month_diff: number): any
	{
		var date = "01-" + String(this.subscription.card.exp_month) + "-" + String(this.subscription.card.exp_year);
		return moment(date, "DD-MM-YYYY").diff(new Date(), 'months', true) > month_diff ? false : true;
	}

	importReport()
	{
		this.loading = true;
		var payload = {
			"baseline_date": moment(new Date()).format('YYYY-MM-DD'),
		};

		this.api.post('/assets/LEED:'+ this.appData.get('leed_id') +'/autocase/import/', payload).subscribe(
			data =>
			{
				this.loading = false;
				this.alertService.alert('success', 'Your project data has been synced with Autocase.', 10);
			},
			response =>
			{
				this.loading = false
			}
		);
	}

	puchaseSubs()
	{
		this.global.upgrade("add_certificate", this.appData.get('leed_id'));
	}

	downloadCert(data: any)
	{
		var url = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + data.id + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		var name   = this.global.getFileName(data);

		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
	}

	deleteReport(document: any)
	{
		var putData = 
		{
			doc_id : document.id 
		};

		this.loading = true;
		this.api.put('/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
			//success handler
			data =>
			{
				this.getReports();
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		); 
	}

	cancelSubs()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"email": this.appData.get('user_id')
		};

		var custom_basic_config_header = 
		{
			data: payload,
			headers: 
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};

		this.api.delete('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/', custom_basic_config_header).subscribe(
			data =>
			{
				this.loading = false;
				this.getSubscription();
			},
			data =>
			{
				this.loading = false;
			}
		);
	}

	editBilling()
	{
		this.global.upgrade("edit_certificate_billing", this.appData.get('leed_id'), this.subscription);
	}

	editCard()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"pmupdate": true
		};

		this.api.put('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/', payload).subscribe(
			data =>
			{
				var stripe_url = this.config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
				window.open(stripe_url, "_self");
			},
			data =>
			{
				this.loading = false
			}
		);
	}

	initFeed()
	{
		var feeds: any = [];
		var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/notificationitems/';

		this.api.get(url).subscribe(
			response =>
			{
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				var retry = []
				
				for(var i = 0; i < response.activities.length; i++)
				{
					if(response.activities[i].foreign_id.indexOf("autocase_report") != -1)
					{
						var time = moment(response.activities[i].time);
						var date = moment(new Date);
						if(time.diff(date, 'days') > 1)
						{
							// this.deleteFeed(response.activities[i]);
						}
						else
						{
							feeds.push(response.activities[i]);
						}
					}
					else if(response.activities[i].foreign_id.indexOf("data_report_error_") != -1 && response.activities[i].foreign_id.indexOf("autocase_report") != -1)
					{
						retry.push(response.activities[i]);
					}
					
					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						// this.removeFeed(response.activities[i].foreign_id);
					}
				}
				
				this.retry = retry.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});
				this.feeds = feeds.sort(function(a: any,b: any) 
				{ 
					return new Date(a.time).getTime() - new Date(b.time).getTime() 
				});

				var subscription = notification.subscribe((data: any) =>
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < feeds.length; a++)
						{
							if(feeds[a].id == data.deleted[d])
							{
								this.getReports();
								feeds.splice(a, 1);
							}
						}
					}

					if(data.new.length > 0)
					{
						if(data.new[0].foreign_id.indexOf("autocase_report") != -1)
						{
							feeds.push(data.new[0]);
						}
					}
					
					this.feeds = this.feeds.sort(function(a: any,b: any) 
					{ 
						return new Date(a.time).getTime() - new Date(b.time).getTime() 
					});
				});
			},
			function(error)
			{

			}
		);
	}

	getSubscription()
	{
		this.current_status = undefined; //inactive, active, expired;
		this.loading = true;
		var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/';
		this.api.get(url).subscribe(
			data =>
			{
				this.loading = false;
				this.subscription = data;	
				if(this.subscription.status == undefined)
				{
					this.current_status = 'inactive';
				}					
				else if(this.subscription.status == 'settled')
				{
					this.current_status = 'active';
				}					
				else if(this.subscription.status == 'cancelled_active')
				{
					this.current_status = 'cancelled_active';
				}					
				else if(this.subscription.status == 'expired')
				{
					this.current_status = 'expired';
				}			
				
				try
				{
					if(this.subscription.subscription_id.indexOf('sub_free') != -1 || this.subscription.subscription_id.indexOf('sub_offline_paid') != -1)
					{
						this.offline_sub = true;
					}
					else
					{
						this.offline_sub = false;
					}
				}
				catch(e)
				{
					this.offline_sub = false;
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	renewSubs()
	{
		this.global.upgrade("renew_certificate", this.appData.get('leed_id'), this.subscription);
	}

	freeReport()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": "create-models",
			"report_mode": 'free_report',
			"current_status": this.current_status
		});
	}

	paidReport()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": "create-models",
			"report_mode": 'premium_report',
			"current_status": this.current_status
		});
	}
}

<div [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
    <div class="w100p align_lr">
        <div class="w60p">
            <div class="mt10 mb25 fs16 model_template_label">Enter the <span class="fw-500">Estimated Points</span> you expect to achieve for each strategy.</div>
            <div class="credit_list mb40" *ngFor="let credit of score_card">
                <div class="credit_name_header">            
                    <div class="credit_info">
                        <i class="icon-caret-down"></i>
                        <span class="credit_name">{{credit.name}}</span>
                    </div>
                    <div class="credit_icon">
                        <div class="credit_points">{{credit.attempted}} of {{credit.max_points}}</div>
                        <img class="scorecard_icon" src="../../.././../assets/images/icons/leed/{{credit.id.toLowerCase()}}-border.png"/>
                    </div>
                </div>
    
                <div class="credit_items">
                    <table id="credit_items" class="width-100-perc">
                        <thead>
                            <tr>
                                <th class="fw-500">Credit</th>
                                <th class="fw-500">Points Possible</th>
                                <th class="fw-500">Estimated<br>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let credit_item of credit.points_gained; let idx = index;">
                                <td class="fs16">
                                    {{credit_item.name}}
                                </td>
                                <td class="fs16">
                                    0 - {{credit_item.max}}
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle dropdown-toggle-it btn-default" type="button" data-toggle="dropdown">
                                            <span class="fs14">{{credit_item.attempted}}</span>
                                            <i class="toggle fa fa-angle-down"></i>
                                        </button>
                                        <ul class="dropdown-menu left dropdown-menu-fixed">
                                            <li (click)="scoreCardChange(idx2, credit_item)" *ngFor="let x of [].constructor(credit_item.max + 1); let idx2 = index;" class="water_unit">
                                                <a>
                                                    {{idx2}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="strategies_summary summary_box">
            <h4 class="fw-500">Estimated score ({{project.rating_system}})</h4>

            <h2 class="fw-500">
                {{total}}<span class="grey_color">/100</span>
            </h2>

            <div class="fs16 grey_color">Estimated LEED points</div>
        </div>
    </div>
</div>
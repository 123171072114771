import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { API } from '../../../services/api.service';
import { Global } from '../../../services/global.service';
import { Projects } from '../../../services/projects.service';
import { alertService } from 'src/app/services/alert-service.service';

import * as moment from 'moment';
import * as stream from 'getstream';

import { Config } from '../../../../../src/app/models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

declare var AmCharts: any;

@Component({
  selector: 'app-advanced-scoring',
  templateUrl: './advanced-scoring.component.html',
  styleUrls: ['./advanced-scoring.component.css']
})
export class AdvancedScoringComponent implements OnInit {
    @ViewChild('modal') private modal!: ModalComponent
    @ViewChild('report') private report!: ModalComponent
    config = Config;
    init_module: any = '';
    current_tab = 'Dashboard';
    last_opened_widget = '';
    preference_sort_by = '';
    reports:any = [];
    AS_program_data: any = [];
    AS_program_data_for_search: any = [];
    total_AS_filters = 0;
    hard_coded_programs = ['arc_performance_score', 'arc_improvement_score', 'arc_peer_score', 'energy_star', 'gresb', 'gresb_2021', 'seattle_washington', 'city_of_orlando']
    new_approach_program = ['chicago_illinois', 'philadelphia_pennsylvania', 'reeb', 'true'];
    
    loading = false;
    searchng_program = false;
    search_name = '';
    search_option = {
        'location': ['Global', 'National', 'Local'],
        'metric': ['Site energy', 'Source energy', 'GHG']
    };

    top_scorecard = {
        "id_name": "",
        "name": "",
        "iframe_url_input": ""
    };

    seattle_data = {
        "result": "NA",
        "threshold": "NA",
        "threshold_number": "NA",
        "gsf": "NA",
        "espm_score": "NA"
    };
    city_of_orlando_data = {
        "espm_score": "NA",
        "espm_threshold": "NA",
        "espm_result": "NA",
        "threshold_number": "NA",
        "gsf": "NA",
        "meets_gsf_criteria": "NA",
        "gsf_criteria": "NA"
    };

    scorecard_loading = false;
    preferences_loading = false;
    overview_loading = false;
    show_widgets = false;
    recomputing_analysis = false;
    
    peer_score = null;
    espm_score = null;
    gresb_data_result = null;
    gresb_2021_data_result = null;
    seattle_data_result = 'NA';
    city_of_orlando_data_espm_result = 'NA';
    
    energy_performance_score = null;
    energy_improvement_score = null;
    preferences_modal_visited = false;
    program_name = '';
    program_description = '';
    widget_loading = false;
    advanced_modal_active_program_id: any = null;
    doc_category = "advanced_scoring_report_title";
    icons: any = {};
    show_required_text = false;
    
    espm_score_recomputing = false;
    recompting_energy_kpi_dates: any = [];
    recompting_water_kpi_dates: any = [];
    recompting_waste_kpi_dates: any = [];
    
    propertyName = 'name';
    reverse = false;
    
    custom_target_header = 'Create a Custom Target';
    edit_CT_active = false;
    fetching_ct_data = false;

    feeds: any = [];
    retry = [];

    ct_btn_text = 'Create Custom Target';
    custom_target_loading = false;
    ct_create_target_name = '';
    ct_create_target_description = '';
    ct_create_target_kpi = '';
    ct_create_target_unit = '';
    ct_create_target_unit_options: string[] = [];
    ct_create_target_type = '';
    ct_static_years: any = [];
    ct_error_name = false;
    ct_error_kpi = false;
    ct_error_unit = false;
    ct_error_description = false;
    ct_error_target_type = false;
    ct_create_target_data: any = [{
        'year': 2010,
        'target': null
    }];

    came_from_preference = false;
    ct_edit_program_id = null;

    selected_icon: any = null;
    header = "Create Advanced Scoring Report";
    report_sent = false;
    report_type = true;

    custom_target_program = false;

    deleting_ct_target = true;
    deleting_ct_target_btn_txt = 'Deleting...';

    program_ids: any = [];
    delete_ct_modal_program: any = {};

    ct_error_target_value: boolean = false;
    user_role: string = this.appData.get('user_role')

    constructor(
        public global: Global,
        private api: API,
        private projects: Projects,
        public appData: AppData,
        private alertService: alertService
    )
    {
        if(this.config.state_params != null)
        {
            this.config.state_params.init_module = this.config.state_params.init_module != '' ? this.config.state_params.init_module : '';
        }
    }

    sortBy(propertyName: any) {
        this.reverse = (this.propertyName === propertyName) ? !this.reverse : false;
        this.propertyName = propertyName;
        if (this.reverse){
            propertyName = "-" + propertyName
        }
        this.AS_program_data.sort(this.global.sortList(propertyName))
    };
  
    updateASProgramData(id_name: string, key: any, value: any){
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (this.AS_program_data[i]['id_name'] == id_name){
                this.AS_program_data[i][key] = value;
                return;
            }
        }
    };

    deleteASProgramData(id_name: string){
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (this.AS_program_data[i].id_name == id_name){
                this.AS_program_data.splice(i, 1);
                return;
            }
        }
    };

    getKeyValueFromASProgram(id_name: string, key: string){
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (this.AS_program_data[i].id_name == id_name){
                return this.AS_program_data[i][key];
            }
        }
    };

    getScoringPreferences(force_show: boolean){
        this.preferences_loading = true;
        this.api.get('/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/')
        .subscribe(
            data => {
                for (var i = data.length - 1; i >= 0; i--) {
                    this.updateASProgramData(data[i].program.id_name, 'name', data[i].program.name);
                    this.updateASProgramData(data[i].program.id_name, 'widget_url', data[i].widget_url);
                    this.updateASProgramData(data[i].program.id_name, 'scorecard_selected', data[i].mapped);
                    this.updateASProgramData(data[i].program.id_name, 'added_to_preference', true);
                    this.updateASProgramData(data[i].program.id_name, 'selected_for_overview', data[i].selected_for_overview);
                    this.updateASProgramData(data[i].program.id_name, 'updated_at', data[i].updated_at);
                    if (data[i].modal_seen == true){
                        this.preferences_modal_visited = true;
                    }
                }
                if (!this.preferences_modal_visited && data.length != 0){
                    let payload = {"modal_seen": true}
                    this.api.put('/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/' + data[0].program.id + '/', payload ).subscribe(data => {}, error => {});
                }
                if (!this.preferences_modal_visited || data.length == 0 || force_show){
                    if (!$('#advanced_scoring_preferences').is(':visible')){
                        $('#advanced_scoring_preferences').modal('show');
                    }
                }
                this.preferences_loading = false;

                if (!force_show){
                    this.getSummaryData('all');
                }
                this.AS_program_data_for_search = this.AS_program_data;
                this.searchProgram();
            },
            error => {
                this.preferences_loading = false;
                if (!$('#advanced_scoring_preferences').is(':visible')){
                    $('#advanced_scoring_preferences').modal('show');
                }
                this.AS_program_data_for_search = this.AS_program_data;
                this.searchProgram();
            }
        );
    };

    recomputeAnalysisInfo(){
        this.recomputing_analysis = true;
        var urls = [
            '/assets/LEED:'+ this.appData.get('leed_id') +'/analysisinfo-recompute/?type=energy_use_intensity',
            '/assets/LEED:'+ this.appData.get('leed_id') +'/analysisinfo-recompute/?type=emissions_by_floor_area',
            '/assets/LEED:'+ this.appData.get('leed_id') +'/analysisinfo-recompute/?type=source_energy_use_intensity'
        ];
        if (this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1){
            urls.push('/assets/LEED:'+ this.appData.get('leed_id') +'/analysisinfo-recompute/?type=emissions_by_floor_area&need_baseline=true');
            urls.push('/assets/LEED:'+ this.appData.get('leed_id') +'/analysisinfo-recompute/?type=source_energy_use_intensity&need_baseline=true');
        }
        this.api.async(urls).subscribe(data => {
            this.recomputing_analysis = false;
        }, error => {
            this.recomputing_analysis = false;
        });
    };

    getScoringPrograms(){
        this.AS_program_data = [];
        this.api.get('/assets/LEED:'+ this.appData.get('leed_id') + '/advanced-scoring-programs/?page_size=0')
        .subscribe(
            data => {
                for (var i = 0; i < data.length; i++) {
                    if (data[i]['id_name'] == 'gsa'){
                        if (!this.appData.get('is_gsa_user') || this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) == -1){
                            continue;
                        }
                    }
                    if (this.hard_coded_programs.indexOf(data[i].id_name) > -1){
                        data[i]['widget_id'] = data[i]['id_name'] + "_widget";
                    }
                    else{
                        data[i]['widget_id'] = 'advanced_scoring_widget';
                    }
                    data[i]['scorecard_selected'] = false;
                    data[i]['selected_for_overview'] = false;
                    data[i]['summary_result'] = "";
                    data[i]['added_to_preference'] = false;
                    this.AS_program_data.push(data[i]);
                }
                this.showPreferencesModal(false);
            },
            error => {}
        );
    };

    getSummaryData(program_id: string){
        this.scorecard_loading = true;
        var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-summary/';
        if (program_id != 'all'){
            url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-summary/?program_id=' + program_id;
        }
        this.api.get(url)
        .subscribe(
            data => {
                for (var i = data.length - 1; i >= 0; i--) {
                    if (data[i].id_name == 'washington_dc'){
                        if (data[i].other_result == null || data[i].other_result == undefined){
                            this.updateASProgramData(data[i].id_name, 'summary_result', this.formatvalue(data[i].result));
                        }
                        else{
                            this.updateASProgramData(data[i].id_name, 'summary_result',  this.formatvalue(data[i].other_result));
                        }
                    }
                    else{
                        this.updateASProgramData(data[i].id_name, 'summary_result',  this.formatvalue(data[i].result));
                    }

                    this.updateASProgramData(data[i].id_name, 'baseline_end_date', data[i].baseline_end_date);
                    this.updateASProgramData(data[i].id_name, 'end_date', data[i].end_date);
                    this.updateASProgramData(data[i].id_name, 'result_loader', false);
                    
                    if (data[i].id_name == 'arc_performance_score'){
                        this.energy_performance_score = this.global.formatNumber(data[i].result, 0);
                    }
                    else if (data[i].id_name == 'arc_improvement_score'){
                        this.energy_improvement_score = data[i].result;
                    }
                    else if (data[i].id_name == 'arc_peer_score'){
                        this.peer_score = data[i].result;
                    }
                    else if (data[i].id_name == 'energy_star'){
                        this.espm_score = data[i].result;
                    }
                    else if (data[i].id_name == 'gresb'){
                        this.gresb_data_result = data[i].result;
                    }
                    else if (data[i].id_name == 'gresb_2021'){
                        this.gresb_2021_data_result = data[i].result;
                    }
                    else if (data[i].id_name == 'seattle_washington'){
                        this.seattle_data_result = data[i].result;
                        this.seattle_data['espm_score'] = data[i].other_result.value;
                        this.seattle_data['threshold_number'] = data[i].other_result.threshold;
                    }
                    else if (data[i].id_name == 'city_of_orlando'){
                        this.city_of_orlando_data_espm_result = data[i].result;
                        this.city_of_orlando_data['espm_score'] = data[i].other_result.value;
                        this.city_of_orlando_data['threshold_number'] = data[i].other_result.threshold;
                    }
                }
                this.scorecard_loading = false;
                if (program_id == 'all'){
                    this.refreshTopScorecard();
                }
                else{
                    for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
                        if (this.AS_program_data[i]['id'] == program_id &&  this.AS_program_data[i]['selected_for_overview']){
                            this.refreshTopScorecard();
                        }
                    }
                }
                this.refreshKPILoader();
            },
            error => {
                this.scorecard_loading = false;
            }
        );
    };

    showPreferencesModal(force_show: boolean){
        this.preference_sort_by = '';
        if (force_show){
            $('#advanced_scoring_preferences').modal('show');
        }
        this.getScoringPreferences(force_show);
    };

    updatePreferenceSort(value: any){
        this.preference_sort_by = value;
    };

    updateSelection(obj: any, event: any){
        this.preferences_loading = true;
        let payload: any = {"program_id": obj.id}
        var url = '';
        if (event.target.checked){
            if (obj.added_to_preference){
                url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/' + obj.id + '/';
                payload = {"mapped": true};
                this.api.put(
                    url,
                    payload
                ).subscribe(
                    data => {
                        this.updateASProgramData(obj.id_name, 'scorecard_selected', event.target.checked);
                        this.getSummaryData(obj.id);
                        this.preferences_loading = false;
                    }, error => {
                        this.updateASProgramData(obj.id_name, 'scorecard_selected', false);
                        this.preferences_loading = false;
                    }
                );
            }
            else{
                url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/';
                this.api.post(
                    url,
                    payload
                ).subscribe(
                    data => {
                        this.updateASProgramData(obj.id_name, 'scorecard_selected', event.target.checked);
                        this.updateASProgramData(obj.id_name, 'widget_url', data.widget_url);
                        this.getSummaryData(obj.id);
                        this.preferences_loading = false;
                    }, error => {
                        this.updateASProgramData(obj.id_name, 'scorecard_selected', false);
                        this.preferences_loading = false;
                    }
                );
            }
            
        }
        else{
            url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/' + obj.id + '/';
            this.api.delete(
                url,
                {}
            ).subscribe(data => {
                this.updateASProgramData(obj.id_name, 'scorecard_selected', event.target.checked);
                this.preferences_loading = false;
                if (this.getKeyValueFromASProgram(obj.id_name, 'selected_for_overview')){
                    this.updateASProgramData('arc_performance_score', 'selected_for_overview', true);
                }
            }, error => {
                this.updateASProgramData(obj.id_name, 'scorecard_selected', true);
                this.preferences_loading = false;
            });
        }
    };

    openOverviewSelection(){
        $('#advanced_scoring_overview input').prop('checked', false);
        $("#advanced_scoring_overview").modal('show');
        var latest_overview_id_name: null = null;
        var latest_overview_updated_at: null = null;
        this.overview_loading = true;
        var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/';
        this.api.get(url)
        .subscribe(
            data => {
                for (var i = data.length - 1; i >= 0; i--) {
                    this.updateASProgramData(data[i].program.id_name, 'selected_for_overview', false);
                    $('#advanced_scoring_overview input[name=' + data[i].program.id_name + ']').prop('checked', false);
                    if ( (latest_overview_updated_at == null && data[i].selected_for_overview) || (data[i].selected_for_overview && latest_overview_updated_at != null && data[i].updated_at > latest_overview_updated_at) ){
                        latest_overview_id_name =  data[i].program.id_name;
                        latest_overview_updated_at =  data[i].updated_at;
                    }
                }
                if (latest_overview_id_name != null){
                    this.updateASProgramData(latest_overview_id_name, 'selected_for_overview', true);
                    $('#advanced_scoring_overview input[name=' + latest_overview_id_name + ']').prop('checked', true);
                }
                this.overview_loading = false;
            },
            error => {
                this.overview_loading = false;
            }
        );
    };

    refreshTopScorecard(){
        var latest_overview_updated_at: null = null;
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if ( (latest_overview_updated_at == null && this.AS_program_data[i].selected_for_overview) || (this.AS_program_data[i].selected_for_overview && latest_overview_updated_at != null && this.AS_program_data[i].updated_at > latest_overview_updated_at) ){
                this.top_scorecard.id_name = this.AS_program_data[i]['id_name'];
                this.top_scorecard.name = this.AS_program_data[i]['name'];
                latest_overview_updated_at =  this.AS_program_data[i].updated_at;
            }
        }
        (<any>document.getElementById('AS_overview_widget')).src = this.getGraphWidgetUrl(this.top_scorecard.id_name);
    };

    updateOverviewSelection(event: any, program: any){
        var target = $( event.target );
        if (target.is( "path" ) || target.is( "svg" )){
            this.openLearnMoreLink(<any>target.closest('i').attr('id_name'));
        }
        else{
            this.overview_loading = true;
            var payload = {"selected_for_overview": true}
            var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/advanced-scoring-preference/' + program.id + '/';
            for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
                this.AS_program_data[i]['selected_for_overview'] = false;
                $('#advanced_scoring_overview input[name=' + this.AS_program_data[i]['id_name'] + ']').prop('checked', false);
            }
            $('#advanced_scoring_overview input[name=' + program.id_name + ']').prop('checked', true);
            this.api.put(
                url,
                payload
            ).subscribe(
                data => {
                    this.updateASProgramData(program.id_name, 'selected_for_overview', true);
                    this.overview_loading = false;
                }, error => {
                    this.overview_loading = false;
                }
            );
        }
    };

    convertTypeToProgramId(type: any){
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (this.AS_program_data[i].id_name == type){
                return this.AS_program_data[i].id;
            }
        }
        return null;
    };

    formatvalue(val: any){
        if (val == null || val == undefined){
            if (!this.show_required_text)
            {
                setTimeout(() =>
                {
                    this.show_required_text = true;
                })
            }
            return "NA*";
        }
        else if (String(val).indexOf('Not applicable') > -1){
            return "Fail";
        }
        else {
            return val;
        }
    };

    getGraphWidgetUrl(id_name: string){
        var token = "&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
        let language = this.appData.get('language') == null ? "en" : this.appData.get('language');
        return this.config.basic_api_url + this.getKeyValueFromASProgram(id_name, 'widget_url') + '&overview=true' + token + "&language=" + language;
    };

    openLearnMoreLink (id_name: string){
        var external_link = this.getKeyValueFromASProgram(id_name, "learn_more_link")
        if (external_link != ''){
            window.open(external_link, "_blank");
        }
    };

    showContentModal (obj: any, program: any){
        this.custom_target_program = false;
        if (obj && ($(obj.target).is( "path" ) || $(obj.target).is( "svg" ))){
            this.openLearnMoreLink(<any>$(obj.target).closest('tr').attr('id_name'));
        }
        else{
            if (program.program_type == 'custom'){
                this.custom_target_program = true;
            }
            this.last_opened_widget = program.id_name;
            this.advanced_modal_active_program_id = program.id;
            $('.widget_loading').css('display', 'block');
            this.program_name = program.name;
            this.program_description = program.description;
            var token = "&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
            var url = this.config.basic_api_url + program.widget_url + token;
            if(this.config.access == 'R')
            {
                url += "&readonly=true";
            }
            $('#advanced_scoring_widget').modal('show');
            $('#advanced_scoring_widget_iframe').attr('src', url)
        }
    };

    hideContentModal(){
        if (this.getKeyValueFromASProgram(this.last_opened_widget, 'selected_for_overview')){
            this.refreshTopScorecard();
        }
        this.getSummaryData(this.advanced_modal_active_program_id);
        $('#advanced_scoring_widget_iframe').attr('src', 'about:blank');
    };

    initModule(){
        if (this.user_role == 'arc_pro'){
            this.getScoringPrograms();
            this.recomputeAnalysisInfo();
        }
    };

    toggleTab(tab: string){
        this.current_tab = tab;
        if(tab == 'Reports')
        {
            this.getReports();
        }
        else if (tab == 'Dashboard'){
            this.refreshTopScorecard();
        }
    };

    reloadSummaryOnDelete(end_date: any, category: string){
        if (category == 'energy' && this.recompting_energy_kpi_dates.indexOf(end_date) > -1){
            for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
                if (((this.new_approach_program.indexOf(this.AS_program_data[i]['id_name']) > -1 || this.AS_program_data[i]['id_name'] == 'gresb' || this.AS_program_data[i]['id_name'] == 'gresb_2021' || this.AS_program_data[i]['id_name'] == 'washington_dc' || this.AS_program_data[i]['id_name'] == 'gsa' || this.AS_program_data[i]['id_name'] == 'colorado_bps' || this.AS_program_data[i]['id_name'] == 'energize_denver' || this.AS_program_data[i]['id_name'] == 'st_louis_missouri' || this.AS_program_data[i]['id_name'] == 'washington_state') && this.AS_program_data[i]['end_date'] && this.AS_program_data[i]['end_date'] == end_date) || ((this.AS_program_data[i]['id_name'] == 'gsa' || this.AS_program_data[i]['id_name'] == 'colorado_bps' || this.AS_program_data[i]['id_name'] == 'energize_denver') && this.AS_program_data[i]['baseline_end_date'] && this.AS_program_data[i]['baseline_end_date'] == end_date)) {
                    this.getSummaryData(this.AS_program_data[i]['id']);
                }
            }
        }
        else if (category == 'water' && this.recompting_water_kpi_dates.indexOf(end_date) > -1){
            for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
                if ((this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['end_date'] && this.AS_program_data[i]['end_date'] == end_date) || (this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['baseline_end_date'] && this.AS_program_data[i]['baseline_end_date'] == end_date)) {
                    this.getSummaryData(this.AS_program_data[i]['id']);
                }
            }
        }
        else if (category == 'waste' && this.recompting_waste_kpi_dates.indexOf(end_date) > -1){
            for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
                if ((this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['end_date'] && this.AS_program_data[i]['end_date'] == end_date) || (this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['baseline_end_date'] && this.AS_program_data[i]['baseline_end_date'] == end_date)) {
                    this.getSummaryData(this.AS_program_data[i]['id']);
                }
            }
        }
    };

    initFeed(){
        var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/notificationitems/';
        this.api.get(url)
        .subscribe(
            data => {
                var client = stream.connect(data.stream_key, null, data.app_id);
                var notification = client.feed(data.feed_name, data.feed_user, data.token);
                var retry = []
                
                for(var i = 0; i < data.activities.length; i++)
                {
                    if(data.activities[i].foreign_id.indexOf("advanced_scoring") != -1)
                    {
                        var time = moment(data.activities[i].time);
                        var date = moment(new Date);
                        if(time.diff(date, 'days') > 1)
                        {
                            // this.deleteFeed(data.activities[i]);
                        }
                        else
                        {
                            this.feeds.push(data.activities[i]);
                        }
                    }
                    else if(data.activities[i].foreign_id.indexOf("data_report_error_") != -1)
                    {
                        retry.push(data.activities[i]);
                    }
                    else if (data.activities[i].foreign_id == 'data_sync_energy_star'){
                        this.espm_score_recomputing = true;
                    }
                    else if (data.activities[i].foreign_id.indexOf('energy-kpi-') > -1){
                        let date = data.activities[i].foreign_id.split('energy-kpi-')[1];
                        if (this.recompting_energy_kpi_dates.indexOf(date) == -1){
                            this.recompting_energy_kpi_dates.push(date);
                            this.refreshKPILoader();
                        }
                    }
                    else if (data.activities[i].foreign_id.indexOf('water-kpi-') > -1){
                        let date = data.activities[i].foreign_id.split('water-kpi-')[1];
                        if (this.recompting_water_kpi_dates.indexOf(date) == -1){
                            this.recompting_water_kpi_dates.push(date);
                            this.refreshKPILoader();
                        }
                    }
                    else if (data.activities[i].foreign_id.indexOf('waste-kpi-') > -1){
                        let date = data.activities[i].foreign_id.split('waste-kpi-')[1];
                        if (this.recompting_waste_kpi_dates.indexOf(date) == -1){
                            this.recompting_waste_kpi_dates.push(date);
                            this.refreshKPILoader();
                        }
                    }
                    
                    var timestamp = data.activities[i].timestamp;
                    var today = moment();

                    if(Math.abs(today.diff(timestamp, 'days')) > 0)
                    {
                        // this.removeFeed(data.activities[i].foreign_id);
                    }
                }
                
                this.retry = this.retry.sort((a:any,b:any) =>
                { 
                    return new Date(a.time).getTime() - new Date(b.time).getTime() 
                });
                var subscription = notification.subscribe((data: any) => 
                {
                    for(var d = 0; d < data.deleted.length; d++)
                    {
                        for(var a = 0; a < this.feeds.length; a++)
                        {
                            if(this.feeds[a].id == data.deleted[d])
                            {
                                this.getReports();
                                this.feeds.splice(a, 1);
                            }
                        }
                    }

                    for(var i = 0; i < data.deleted_foreign_ids.length; i++){
                        if(data.deleted_foreign_ids[i][0] == "data_sync_energy_star")
                        {
                            this.espm_score_recomputing = false;
                            this.refreshESPMPrograms();
                        }
                        else if (data.deleted_foreign_ids[i][0].indexOf('energy-kpi-') > -1){
                            var date = data.deleted_foreign_ids[i][0].split('energy-kpi-')[1];
                            this.reloadSummaryOnDelete(date, 'energy');
                            var index = this.recompting_energy_kpi_dates.indexOf(date);
                            if (index > -1) {
                                this.recompting_energy_kpi_dates.splice(index, 1);
                                this.refreshKPILoader();
                            }
                        }
                        else if (data.deleted_foreign_ids[i][0].indexOf('water-kpi-') > -1){
                            var date = data.deleted_foreign_ids[i][0].split('water-kpi-')[1];
                            this.reloadSummaryOnDelete(date, 'water');
                            var index = this.recompting_water_kpi_dates.indexOf(date);
                            if (index > -1) {
                                this.recompting_water_kpi_dates.splice(index, 1);
                                this.refreshKPILoader();
                            }
                        }
                        else if (data.deleted_foreign_ids[i][0].indexOf('waste-kpi-') > -1){
                            var date = data.deleted_foreign_ids[i][0].split('waste-kpi-')[1];
                            this.reloadSummaryOnDelete(date, 'waste');
                            var index = this.recompting_waste_kpi_dates.indexOf(date);
                            if (index > -1) {
                                this.recompting_waste_kpi_dates.splice(index, 1);
                                this.refreshKPILoader();
                            }
                        }
                    }

                    if(data.new.length > 0)
                    {
                        if(data.new[0].foreign_id.indexOf("advanced_scoring") != -1)
                        {
                            this.feeds.push(data.new[0]);
                        }
                        else if(data.new[0].foreign_id == 'data_sync_energy_star')
                        {
                            this.espm_score_recomputing = true;
                        }
                        else if (data.new[0].foreign_id.indexOf('energy-kpi-') > -1){
                            var date = data.new[0].foreign_id.split('energy-kpi-')[1];
                            if (this.recompting_energy_kpi_dates.indexOf(date) == -1){
                                this.recompting_energy_kpi_dates.push(date);
                                this.refreshKPILoader();
                            }
                        }
                        else if (data.new[0].foreign_id.indexOf('water-kpi-') > -1){
                            var date = data.new[0].foreign_id.split('water-kpi-')[1];
                            if (this.recompting_water_kpi_dates.indexOf(date) == -1){
                                this.recompting_water_kpi_dates.push(date);
                                this.refreshKPILoader();
                            }
                        }
                        else if (data.new[0].foreign_id.indexOf('waste-kpi-') > -1){
                            var date = data.new[0].foreign_id.split('waste-kpi-')[1];
                            if (this.recompting_waste_kpi_dates.indexOf(date) == -1){
                                this.recompting_waste_kpi_dates.push(date);
                                this.refreshKPILoader();
                            }
                        }
                    }
                });
            },
            error => {
            }
        );
    };

    getReports(){
        this.loading = true;
        this.projects.getS3DocumentsForCategory("advanced_scoring").subscribe(data =>
        {
            data = data.sort(function(a:any, b:any) 
            { 
                return new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime() 
            }).reverse();
            this.loading = false;
            for(var n = 0; n < data.length; n++)
            {
                data[n].document_metadata = JSON.parse(data[n].document_metadata.replace(/'/g, '"'));
            }
            this.reports = data;
        });
    };

    downloadCert(data: any){
        var url = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + data.id + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
        var name   = this.global.getFileName(data);
        var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
    };

    deleteReport(document: any){
        let putData = 
        {
            doc_id : document.id 
        };
        this.scorecard_loading = true;
        this.api.put('/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
            data => {
                this.getReports();
                this.scorecard_loading = false;
            }, error => {
                this.scorecard_loading = false;
            }
        );
    };

    selectImage(sequence: any, modal?: boolean){
        this.selected_icon = sequence;
        if(!modal)
		{
			$('.upload_report_title').click();
		}
    };

    fileNameChanged(elem: any){
        let filePath: any = $(elem).val();
        filePath = filePath.replace("/\\/", "\\\\");
        var index = filePath.lastIndexOf("\\");
        filePath = filePath.substring(index + 1, filePath.length);
        //var type = $(this).attr('category');
        var data;
        for(var a = 0; a < $(elem)[0].files.length; a++)
        {
            data = new FormData();
            var mimeType = $(elem)[0].files[a].type;

            if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
            {
                let file: any = new Blob([$(elem)[0].files[a]], { type: mimeType});
                file.lastModifiedDate = new Date();
                data.append('action_file', file, this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
            }
            else
            {
                let file: any = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1], {type: mimeType, lastModified: <any>(new Date())});
                data.append('action_file', file);
            }

            // var file = new File([$(elem)[0].files[a]], this.selected_icon + "." + $(elem)[0].files[a].name.split('.')[1]);
            // data.append('action_file', file);
            this.upload(data, this.doc_category, $(elem)[0].files[a].type, $(elem)[0].files[a].size);
        }
    };

    getPath(doc: any, id?: any){
        let url: any = '';
        if(id != undefined && isNaN(parseInt(id)))
        {
            if(id == 'play_to_zero.jpg' && this.appData.get('is_gsa_user') && this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) > -1)
            {
                id = "play_to_zero.jpg";
            }
            return window.location.origin + "/assets/images/" + id;
        }
        
        try
        {
            if(doc == undefined)
            {
                doc = {"id": id};
            }
            url = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + doc["id"] + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
        }
        catch(e)
        {
            url = undefined;
        }
        return url;
    }

    getIcons(){
        this.projects.getS3DocumentsForCategory(this.doc_category).subscribe(data =>
        {
            this.loading = false;
            for(var n = 0; n < data.length; n++)
            {
                var file_name = this.global.getFileName(data[n]);
                file_name = file_name.split('.')[0];
                if(file_name == 'first')
                {
                    this.icons['first'] = data[n];
                }
                else if(file_name == 'second')
                {
                    this.icons['second'] = data[n];
                }
                else if(file_name == 'third')
                {
                    this.icons['third'] = data[n];
                }
                else if(file_name == 'fourth')
                {
                    this.icons['fourth'] = data[n];
                }
                else if(file_name == 'fifth')
                {
                    this.icons['fifth'] = data[n];
                }
            }
        });
    };

    deleteIcon(uploaded_file: any, sequence: string){
        var putData = 
        {
            doc_id : uploaded_file.id 
        };
        this.loading = true;
        this.api.put(
           '/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/',
            putData
        ).subscribe(
            data => {
                this.icons[sequence] = undefined;
                this.loading = false;
            }, error => {
                this.alertService.alert('error', 'Not able to remove logo.', 5);
                this.loading = false;
            }
        );
    };
    
    upload(form_data: any, category: any, type: any, size: any){        
        (<any>$('.upload_report_title')[0]).value = "";  
        if(type.split('/')[0] != 'image')
        {
            this.alertService.alert('error', 'Only images are allowed', 5);
            return;
        }
        
        if((size / 1024000) > 5.0)
        {
            this.alertService.alert('error', 'File size should not exceed 5MB', 5);
            return;
        }
        
        let custom_basic_config_header = 
        {
            headers: 
            {
                "responseType": "arraybuffer",
                "Ocp-Apim-Subscription-Key": this.config.sub_key,
                "Authorization": "Bearer " + this.appData.get('token')
            }
        };
        form_data.append('upload_category', category)

        this.loading = true;
        this.api.post('/assets/LEED:'+ this.appData.get('leed_id') +'/uploadS3/?upload_category=' + category, form_data, custom_basic_config_header).subscribe(
            data =>
            {
                this.getIcons();
            },
            error =>
            {
                this.loading = false;
                this.alertService.alert('error', 'Not able to upload logo.', 5);
            }
        );
    };

    searchProgram(){
        this.countASFilter();
        var q = 'page_size=0&name=' + this.search_name.trim();
        let location_arr: any = [];
        $('.search_checkbox_location:checkbox:checked').each(function() {
            location_arr.push($(this).attr('name'));
        });

        let metric_arr: any = [];
        $('.search_checkbox_metric:checkbox:checked').each(function() {
            if ($(this).attr('name') == 'Site energy'){
                metric_arr.push('site');
            }
            else if ($(this).attr('name') == 'Source energy'){
                metric_arr.push('source');
            }
            else if ($(this).attr('name') == 'GHG'){
                metric_arr.push('ghg');
            }
        });

        q += '&location=' + location_arr.join(',') + '&metric=' + metric_arr.join(',');

        this.searchng_program = true;

        this.api.get('/assets/LEED:'+ this.appData.get('leed_id') + '/advanced-scoring-programs/?' + q)
        .subscribe(
            data => {
                var filtered_id_name = [];
                for (var i = 0; i < data.length; i++) {
                    if (data['id_name'] == 'gsa'){
                        if (!this.appData.get('is_gsa_user') || this.appData.get('space_type_gsa').indexOf(this.appData.get('buildingData').spaceType) == -1){
                            continue;
                        }
                    }
                    filtered_id_name.push(data[i]['id_name']);
                }
                this.AS_program_data_for_search = [];
                for (var i = 0; i < this.AS_program_data.length; i++) {
                    if (filtered_id_name.indexOf(this.AS_program_data[i]['id_name']) > -1){
                        this.AS_program_data_for_search.push(this.AS_program_data[i]);
                    }
                }
                this.searchng_program = false;
                if (this.init_module != ''){
                    (<any>document.getElementById(this.init_module)).click();
                }
            },
            error => {
                this.searchng_program = false;
            }
        );
        $("#AS_program_filters").hide();
    };

    countASFilter(){
        this.total_AS_filters = 0;
        if (this.search_name.trim() != ''){
            this.total_AS_filters += 1;
        }
        $('.search_checkbox_location:checkbox:checked').each(() => {
            this.total_AS_filters += 1;
        });
        $('.search_checkbox_metric:checkbox:checked').each(() => {
            this.total_AS_filters += 1;
        });
    };

    resetSearchFilters(){
        this.search_name = '';
        $( ".search_checkbox_location" ).prop( "checked", false );
        $( ".search_checkbox_metric" ).prop( "checked", false );
        this.searchProgram();
    };

    refreshESPMPrograms(){
        let ids = [];
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (this.AS_program_data[i]['kpi_used_for_result'] == 'espm_score'){
                ids.push(this.AS_program_data[i]['id']);
            }
        }
        this.getSummaryData(ids.join(','));
    };

    refreshKPILoader(){
        for (var i = this.AS_program_data.length - 1; i >= 0; i--) {
            if (((this.new_approach_program.indexOf(this.AS_program_data[i]['id_name']) > -1 || this.AS_program_data[i]['id_name'] == 'gresb' || this.AS_program_data[i]['id_name'] == 'gresb_2021' || this.AS_program_data[i]['id_name'] == 'washington_dc' || this.AS_program_data[i]['id_name'] == 'gsa' || this.AS_program_data[i]['id_name'] == 'colorado_bps' || this.AS_program_data[i]['id_name'] == 'energize_denver' || this.AS_program_data[i]['id_name'] == 'st_louis_missouri' || this.AS_program_data[i]['id_name'] == 'washington_state') && this.AS_program_data[i]['end_date'] && this.recompting_energy_kpi_dates.indexOf(this.AS_program_data[i]['end_date']) > -1) || ((this.AS_program_data[i]['id_name'] == 'gsa' || this.AS_program_data[i]['id_name'] == 'colorado_bps' || this.AS_program_data[i]['id_name'] == 'energize_denver') && this.AS_program_data[i]['baseline_end_date'] && this.recompting_energy_kpi_dates.indexOf(this.AS_program_data[i]['baseline_end_date']) > -1) || (this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['end_date'] && (this.recompting_water_kpi_dates.indexOf(this.AS_program_data[i]['end_date']) > -1 || this.recompting_waste_kpi_dates.indexOf(this.AS_program_data[i]['end_date']) > -1)) || (this.AS_program_data[i]['id_name'] == 'gsa' && this.AS_program_data[i]['baseline_end_date'] && (this.recompting_water_kpi_dates.indexOf(this.AS_program_data[i]['baseline_end_date']) > -1 || this.recompting_waste_kpi_dates.indexOf(this.AS_program_data[i]['baseline_end_date']) > -1))) {
                this.AS_program_data[i]['result_loader'] = true;
            }
            else{
                this.AS_program_data[i]['result_loader'] = false;
            }
        }
    };

    foramtPerformanceDate(date: any){
        if (date == null || date == undefined){
            return this.global.changeDateFormat(new Date(), 'MMM DD, YYYY');
        }
        else{
            return this.global.changeDateFormat(date, 'MMM DD, YYYY');
        }
    };

    getIconForResult(formated_result: any) {
        if (formated_result == 'NA*'){
            return "https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/alert_circle_icon.svg";
        }
        else if (formated_result.toLowerCase().indexOf('pass') > -1){
            return "https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/done_green_icon.svg";
        }
        else if (formated_result.toLowerCase().indexOf('fail') > -1){
            return "https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/notdone_x_icon.svg";
        }
        else{
            return "https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/alert_circle_icon.svg";
        }
    };

    refreshCTStaticYear(){
        this.ct_static_years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060];
        if (this.ct_create_target_data){
            for (var i = this.ct_create_target_data.length - 1; i >= 0; i--) {
                var index = this.ct_static_years.indexOf(this.ct_create_target_data[i]['year']);
                if (index >= 0){
                    this.ct_static_years.splice(index, 1);
                }
            }
        }
    };

    showCustomTargetModal(){
        $('#advanced_scoring_preferences').modal('hide');
        this.custom_target_header = 'Create a Custom Target';
        this.edit_CT_active = false;
        this.ct_btn_text = 'Create Custom Target';
        this.custom_target_loading = false;
        this.ct_create_target_name = '';
        this.ct_create_target_description = '';
        this.ct_create_target_kpi = '';
        this.ct_create_target_unit = '';
        this.ct_create_target_unit_options = [];
        this.ct_create_target_type = '';
        this.ct_create_target_data = [];
        this.ct_static_years = [];
        this.ct_error_name = false;
        this.ct_error_kpi = false;
        this.ct_error_unit = false;
        this.ct_error_description = false;
        this.ct_error_target_type = false;
        this.ct_create_target_data = [{
            'year': 2010,
            'target': null
        }];
        $('#advanced_scoring_custom_target').modal('show');
        this.refreshCTStaticYear();
        this.refreshChart();
    };

    refreshTargetUnitOptions(){
        this.ct_create_target_unit = '';
        this.ct_create_target_unit_options = [];
        if (this.ct_create_target_kpi == 'Site EUI' || this.ct_create_target_kpi == 'Source EUI'){
            this.ct_create_target_unit_options = ['kBtu/sqft/year', 'kWh/sqft/year', 'GJ/sqft/year', 'kBtu/sqm/year', 'kWh/sqm/year', 'GJ/sqm/year'];
        }
        else if (this.ct_create_target_kpi == 'GHG Intensity'){
            this.ct_create_target_unit_options = ['kgCO2e/sqft/year', 'mtCO2e/sqft/year', 'kgCO2e/sqm/year', 'mtCO2e/sqm/year'];
        }
    };

    addCTDataRow(){
        if (this.ct_create_target_data.length < 60){
            this.ct_create_target_data.push({
                'year': null,
                'target': null
            });
            this.refreshCTStaticYear();
        }
    };

    deleteCustomTarget(){
        this.deleting_ct_target = true;
        this.deleting_ct_target_btn_txt = 'Deleting...';

        this.api.delete(
            '/advanced-scoring/custom-target/' + this.delete_ct_modal_program.id + '/',
            {}
        ).subscribe(
            data => {
                this.deleteASProgramData(this.delete_ct_modal_program.id_name);
                $('#delete_ct_confirmation').modal('hide');
                this.showPreferencesModal(true);
                this.deleting_ct_target = false;
                this.deleting_ct_target_btn_txt = 'Delete';
                this.alertService.alert('success', 'Successfully deleted.', 5);
            },
            error => {
                this.deleting_ct_target = false;
                this.deleting_ct_target_btn_txt = 'Delete';
            }
        );
    };

    showDeleteCTModal(program: any){
        this.delete_ct_modal_program = program;
        this.deleting_ct_target = false;
        this.deleting_ct_target_btn_txt = 'Delete';
        $('#advanced_scoring_preferences').modal('hide');
        $('#delete_ct_confirmation').modal('show');
    };

    removeCTDataRow(item: any){
        if (this.edit_CT_active && item.id){
            this.deleteCTValue(item);
            return;
        }
        var index = this.ct_create_target_data.indexOf(item);
        this.ct_create_target_data.splice(index, 1);
        this.refreshCTStaticYear();
        this.refreshChart();
    };

    deleteCTValue(obj: any){
        this.custom_target_loading = true;

        this.api.delete(
            '/advanced-scoring/custom-target/' + this.ct_edit_program_id + '/value/' + obj.id + '/',
            {}
        ).subscribe(
            data => {
                this.custom_target_loading = false;
                var index = this.ct_create_target_data.indexOf(obj);
                this.ct_create_target_data.splice(index, 1);
                this.refreshCTStaticYear();
                this.refreshChart();
            },
            error => {
                this.custom_target_loading = false;
            }
        );
    };

    saveCTValueData(obj: any){
        if (obj.id){
            this.custom_target_loading = true;
            var url = '/advanced-scoring/custom-target/' + this.ct_edit_program_id + '/value/' + obj.id + '/';
            var payload = {"year": obj.year, "target": obj.target};

            this.api.put(url, payload ).subscribe(
                data => {
                    this.custom_target_loading = false;
                }, error => {
                    this.custom_target_loading = false;
                }
            );
        }
        else if (obj.year && obj.target){
            this.custom_target_loading = true;
            var url = '/advanced-scoring/custom-target/' + this.ct_edit_program_id + '/value/';
            var payload = {"year": obj.year, "target": obj.target};
            this.api.post(url, payload ).subscribe(
                data => {
                    this.api.get('/advanced-scoring/custom-target/' + this.ct_edit_program_id + '/') .subscribe(
                        data => {
                            this.ct_create_target_data = data.target_values;
                            this.ct_static_years = [];
                            this.refreshChart();
                            this.refreshCTStaticYear();
                            this.custom_target_loading = false;
                        },
                        error => {
                            this.custom_target_loading = false;
                        }
                    );
                }, error => {
                    this.custom_target_loading = false;
                }
            );
        }
        
    };

    saveCTData(field: string){
        this.custom_target_loading = true;
        var url = '/advanced-scoring/custom-target/' + this.ct_edit_program_id + '/';
        var payload = {};
        if (field == 'name'){
            payload = {"name": this.ct_create_target_name};
        }
        else if (field == 'unit'){
            if (this.ct_create_target_kpi == 'Site EUI'){
                payload = {"metric": 'site_eui', "unit": this.ct_create_target_unit.replace(/\//g, "_per_")};
            }
            else if (this.ct_create_target_kpi == 'Source EUI'){
                payload = {"metric": 'source_eui', "unit": this.ct_create_target_unit.replace(/\//g, "_per_")};
            }
            else if (this.ct_create_target_kpi == 'GHG Intensity'){
                payload = {"metric": 'ghg_intensity', "unit": this.ct_create_target_unit.replace(/\//g, "_per_")};
            }
        }
        else if (field == 'description'){
            payload = {"description": this.ct_create_target_description};
        }
        else if (field == 'target_type'){
            payload = {"graph_target_type": this.ct_create_target_type};
        }
        this.api.put(url, payload ).subscribe(
            data => {
                this.custom_target_loading = false;
            }, error => {
                this.custom_target_loading = false;
            }
        );
    };

    removeErroClass(field: string, obj?: any){
        if (field == 'name'){
            this.ct_error_name = false;
        }
        else if (field == 'kpi'){
            this.ct_error_kpi = false;
        }
        else if (field == 'unit'){
            this.ct_error_unit = false;
        }
        else if (field == 'description'){
            this.ct_error_description = false;
        }
        else if (field == 'target_type'){
            this.ct_error_target_type = false;
        }
        else if (field == 'value'){
            $('#target_value_' + obj.year).removeClass('error-border');
        }
    };

    updateCTData(type: string, value: any, obj: any){
        this.removeErroClass(type, obj);
        if (type == 'name'){
            this.ct_create_target_name = value.replace(/^\s+|\s+$/gm,'');
            if (this.ct_create_target_name == ''){
                this.ct_error_name = true;
                return;
            }
        }
        else if (type == 'description'){
            this.ct_create_target_description = value.replace(/^\s+|\s+$/gm,'');
            if (this.ct_create_target_description == ''){
                this.ct_error_description = true;
                return;
            }
        }
        else if (type == 'kpi'){
            if (value == this.ct_create_target_kpi){
                return;
            }
            this.ct_create_target_kpi = value;
            this.refreshTargetUnitOptions();
            this.ct_error_unit = true;
        }
        else if (type == 'unit'){
            if (value == this.ct_create_target_unit){
                return;
            }
            this.ct_create_target_unit = value;
            this.ct_error_unit = false;
        }
        else if (type == 'target_type'){
            if (value == this.ct_create_target_type){
                return;
            }
            this.ct_create_target_type = value;
        }
        else if (type == 'year'){
            if (value == obj.year){
                return;
            }
            obj.year = value;
            this.refreshCTStaticYear();
        }
        else if (type == 'value'){
            if (!value){
                $('#target_value_' + obj.year).addClass('error-border');
            }
        }

        this.refreshChart();
        if (this.edit_CT_active && type != 'kpi'){
            if (type == 'year' || type == 'value'){
                this.saveCTValueData(obj);
            }
            else{
                this.saveCTData(type);
            }
        }
    };

    drawChart(attr: any){
        var ballon = '<div class="ballon"><p class="heading">[[date]]</p><div class="values">[[target]]</div></div>';
        AmCharts.makeChart(attr.chart_id,
        {
            "type": "serial",
            "theme": "none",
            "dataProvider": attr.chartData,
            "valueAxes": [
            {
                "axisAlpha": 0,
                "gridAlpha": 0.5,
                "position": "left",
                "minimum": 0,
                "labelsEnabled": true,
                "title": attr.eui_label,
                "titleBold": false,
                "titleColor": "#28323B",
                "titleFontSize": 12,
                "gridColor": "#E9EBED",
                "axisColor": "#E9EBED",
                "color": "#68737D",
                "fontSize": 12
            }],
            "graphs": [
            {
                "id": "g1",
                "balloonText": ballon,
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#FFFFFF",
                "title": "red line",
                "valueField": attr.valueField,
                "useLineColorForBulletBorder": true,
                "lineColor": attr.color,
                "lineThickness": 2,
                "bulletBorderThickness": 2,
                "balloonColor": "white",
                "balloon": {
                    "borderAlpha": 0,
                    "borderThickness": 0,
                    "fillAlpha": 0
                },
                "bulletSize": 7,
                "fontSize": 12
            }],
            "chartCursor":
            {
                "limitToGraph": "g1",
                "cursorColor": attr.color
            },
            "categoryField": "date",
            "categoryAxis":
            {
                "axisColor": "lightgrey",
                "gridAlpha": 0,
                "color": "#68737D",
                "fontSize": 12,
                "title": "Year",
                "titleBold": false,
                "titleColor": "#28323B",
                "titleFontSize": 12,
                "autoGridCount" : true,
                "minHorizontalGap" : 100,
                // "gridPosition" : "start",
                "equalSpacing" : false,
                "parseDates" : false,
                "startOnAxis" : true,
                "showLastLabel" : true,
                "labelFunction": function(valueText: any, date: any, categoryAxis: any)
                {
                    return valueText;
                }
            }
        });
    };

    refreshChart(){
        var chartData = [];
        this.ct_create_target_data = this.sortByKey(this.ct_create_target_data, 'year');
        for (var i = 0; i < this.ct_create_target_data.length; i++) {
            chartData.push({
                "date": this.ct_create_target_data[i].year,
                "target": this.ct_create_target_data[i].target,
            });
            if (this.ct_create_target_type == 'Stepped' && this.ct_create_target_data[i+1]){
                if (this.ct_create_target_data[i].year + 1 < this.ct_create_target_data[i+1].year){
                    var year = this.ct_create_target_data[i].year + 1;
                    while (year < this.ct_create_target_data[i+1].year){
                        chartData.push({
                            "date": year,
                            "target": this.ct_create_target_data[i].target,
                        });
                        year += 1;
                    }
                }
            }
        }
        var color = '#D0DD3D';
        if (this.ct_create_target_kpi == 'GHG Intensity'){
            color = '#68737D';
        }
        this.drawChart({
            "chartData": chartData,
            "color": color,
            "valueField": "target",
            "unit": "",
            "eui_label": this.ct_create_target_unit,
            "chart_type": 'area',
            "chart_id": "ct_graph"
        });
        
    };

    sortByKey(array: any, key: any){
        return array.sort(function(a: any, b: any){

            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    };

    createCustomTarget() {

        this.ct_error_name = false;
        this.ct_error_kpi = false;
        this.ct_error_unit = false;
        this.ct_error_description = false;
        this.ct_error_target_type = false;
        this.ct_error_target_value = false;

        if (this.ct_create_target_name == ''){
            this.ct_error_name = true;
        }
        if (this.ct_create_target_kpi == ''){
            this.ct_error_kpi = true;
        }
        if (this.ct_create_target_unit == ''){
            this.ct_error_unit = true;
        }
        if (this.ct_create_target_description == ''){
            this.ct_error_description = true;
        }
        if (this.ct_create_target_type == ''){
            this.ct_error_target_type = true;
        }

        for (var i = 0; i < this.ct_create_target_data.length; i++) {
            $('#target_value_' + this.ct_create_target_data[i].year).removeClass('error-border');
            if (!this.ct_create_target_data[i].target){
                $('#target_value_' + this.ct_create_target_data[i].year).addClass('error-border');
                this.ct_error_target_value = true;
            }
        }

        if (this.ct_error_name || this.ct_error_kpi || this.ct_error_unit || this.ct_error_description || this.ct_error_target_type || this.ct_error_target_value){
            return;
        }

        this.custom_target_loading = true;
        this.ct_btn_text = 'Creating...';
        var metric = '';
        if (this.ct_create_target_kpi == 'Site EUI'){
            metric = 'site_eui';
        }
        else if (this.ct_create_target_kpi == 'Source EUI'){
            metric = 'source_eui';
        }
        else if (this.ct_create_target_kpi == 'GHG Intensity'){
            metric = 'ghg_intensity';
        }
        var payload = {
            "name": this.ct_create_target_name,
            "description": this.ct_create_target_description,
            "metric": metric,
            "graph_target_type": this.ct_create_target_type,
            "unit": this.ct_create_target_unit.replace(/\//g, "_per_"),
            "custom_data": this.ct_create_target_data
        };
        this.api.post( '/advanced-scoring/custom-target/', payload ).subscribe(
            data => {
                data['widget_id'] = 'advanced_scoring_widget';
                data['scorecard_selected'] = false;
                data['selected_for_overview'] = false;
                data['summary_result'] = "";
                data['added_to_preference'] = false;
                this.AS_program_data.push(data);
                this.custom_target_loading = false;
                this.ct_btn_text = 'Create Custom Target';
                $('#advanced_scoring_custom_target').modal('hide');
                this.showPreferencesModal(true);
            }, error => {
                this.custom_target_loading = false;
                this.ct_btn_text = 'Create Custom Target';
            }
        );
    };

    showEditCustomTarget(progra_id: any, came_from_preference: boolean){
        this.came_from_preference = came_from_preference;
        this.ct_edit_program_id = progra_id;
        this.fetching_ct_data = true;
        this.custom_target_header = 'Update a Custom Target';
        this.edit_CT_active = true;
        this.ct_error_name = false;
        this.ct_error_kpi = false;
        this.ct_error_unit = false;
        this.ct_error_description = false;
        this.ct_error_target_type = false;
        this.api.get('/advanced-scoring/custom-target/' + progra_id + '/') .subscribe(
            data => {
                if (data.metric == 'site_eui'){
                    data.metric = 'Site EUI';
                }
                else if (data.metric == 'source_eui'){
                    data.metric = 'Source EUI';
                }
                else if (data.metric == 'ghg_intensity'){
                    data.metric = 'GHG Intensity';
                }
                this.ct_create_target_name = data.name;
                this.ct_create_target_description = data.description;
                this.ct_create_target_kpi = data.metric;
                this.ct_create_target_unit = data.unit.replace(/_per_/g, "/");
                this.ct_create_target_type = data.graph_target_type;
                this.ct_create_target_data = data.target_values;
                this.ct_static_years = [];
                this.refreshChart();
                this.refreshCTStaticYear();
                
                this.ct_create_target_unit_options = [];
                if (this.ct_create_target_kpi == 'Site EUI' || this.ct_create_target_kpi == 'Source EUI'){
                    this.ct_create_target_unit_options = ['kBtu/sqft/year', 'kWh/sqft/year', 'GJ/sqft/year', 'kBtu/sqm/year', 'kWh/sqm/year', 'GJ/sqm/year'];
                }
                else if (this.ct_create_target_kpi == 'GHG Intensity'){
                    this.ct_create_target_unit_options = ['kgCO2e/sqft/year', 'mtCO2e/sqft/year', 'kgCO2e/sqm/year', 'mtCO2e/sqm/year'];
                }
                $('#advanced_scoring_widget').modal('hide');
                $('#advanced_scoring_preferences').modal('hide');
                this.fetching_ct_data = false;
                $('#advanced_scoring_custom_target').modal('show');
            },
            error => {
                this.fetching_ct_data = false;
            }
        );
    };

    openPreviousModal(){
        if (this.came_from_preference){
            this.showPreferencesModal(true);
        }
    };

    cancel()
	{
		this.modal.close();
		this.getScoringPrograms();
	}

    editProgramForMultipleProjects()
	{
		this.modal.open({ windowClass: 'certificate_modal manage_team_modal', size: 'lg' });
	};

    ShowSortBy(preference_sort_by: string, location: string, program: any){
        if (preference_sort_by == 'Geographical'){
            if ((location != 'USA' && program.geographic_scope == location) || (location == 'USA' && program.geographic_scope != 'United Kingdom' && program.geographic_scope != 'Global' && program.program_type != 'custom')){
                return true;
            }
            else{
                return false;
            }
        }
    };

    initDropDownHold(){
        $('ul.dropdown-menu.filter-menu').on('click', function(event)
        {
            event.stopPropagation();
        });
    };

    ngOnInit(): void
    {
        $('#advanced_scoring_widget_iframe').ready(function () {
            $('.widget_loading').css('display', 'none');
        });

        $('#advanced_scoring_preferences .show_filters').on('click', function (e){
            $("#AS_program_filters").show();
        });

        $('#advanced_scoring_preferences .close_filter').on('click', function (e){
            $("#AS_program_filters").hide();
        });

        if(this.appData.get('buildingData').arctool_permission)
        {
            this.user_role = 'arc_pro';
        }

        this.getIcons();
        this.initFeed();
        this.initModule();
        this.refreshCTStaticYear();

        window.onpopstate = function(e){ 
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        };
    }

	createReportModal()
	{
		for(var i = 0; i < this.AS_program_data.length; i++)
		{
			this.AS_program_data[i]["selected"] = true;
		}
		this.report.open({windowClass: 'certificate_modal'});
	}

	toggleCheck(program: any)
	{
		if(program.selected == undefined)
		{
			program.selected = true;
			return
		}
		else
		{
			program.selected = !program.selected;
		}
	};

	cancelReport()
	{
		this.report.close();
	}

	createReport()
	{
		var url = Config.basic_api_url + "/assets/LEED:"+ this.appData.get('leed_id') +"/report/advanced-scoring-template/?access-token=<access-token>&subscription-key="+ Config.sub_key +"&END=" + Config.range_end.format('YYYY-MM-DD');
                        
		if(this.selected_icon == 'gsa' && this.appData.get('is_gsa_user') && Config.config.settings.space_type_gsa.indexOf(this.appData.get('buildingData').spaceType) > -1)
		{
			url += '&report_title=play_to_zero.jpg';
		}
		else if(this.icons[this.selected_icon] != undefined || this.icons[this.selected_icon] != null)
		{
			url += '&report_title=' + this.icons[this.selected_icon].id;
		}

		var selected_ids = [];
		for(var i = 0; i < this.AS_program_data.length; i++)
		{
			if(this.AS_program_data[i]["selected"] && this.AS_program_data[i]["scorecard_selected"])
			{
				selected_ids.push(this.AS_program_data[i].id);
			}
		}
		url += '&selected=' + selected_ids.join(",");
		var payload = {
			"source_url": url,
			"doc_category": "advanced_scoring",
			"file_name": "Advanced Scoring",
			"callback": "advanced_scoring",
			"crawl": true
		};
		this.loading = true;
		this.api.post('/assets/LEED:'+ this.appData.get('leed_id') +'/createPDF/', payload).subscribe(
			response =>
			{
				this.loading = false;
				if ("message" in response)
				{
					this.alertService.alert('success', response['message'], 5);
				}
				this.cancelReport();
			},
			error =>
			{
				this.loading = false
				this.alertService.alert('error', 'Something went wrong. Try again.', 5);
			}
		);
	}

}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from '../../services/global.service';
import * as moment from 'moment';
import { CertificationService } from 'src/app/services/certification.service';
import { data, get } from 'jquery';

@Component({
  selector: 'app-certification-base-score',
  templateUrl: './certification-base-score.component.html',
  styleUrls: ['./certification-base-score.component.css']
})
export class CertificationBaseScoreComponent implements OnInit {

  checkInput: boolean = false;
  checkInputCert: boolean = false;
  loader: boolean = false;
  loaderCert: boolean = false;
  rating_system_text: string = "";
  certification_level = "platinum";
  source = "Arc";
  term = "N/A";
  date_certified: any = moment(new Date()).format("MMM DD, YYYY");
  rating_systems: any = [];
  rating_system: any = '';
  leed_id: any = "";
  // base_score: any = "";
  leed_id_cert: any = "";
  source_id_cert: any = "";
  leed_id_cert_list: any = "";
  // energy_score: any;
  water_score: any = "";
  waste_score: any = "";
  transportation_score: any = "";
  human_experience_score: any = "";
  base_score_cert: any = "";
  certification_points: any = "";
  energy_score: string = '';
  mycertifications: any = [];
  projectType: string = 'LEED';

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    public CertificationService: CertificationService,
    private alertService: alertService
  ) { }

  getData() {
    this.CertificationService.getRatingSystemList().subscribe(
      (data: any) => {
        this.rating_system_text = data[0].name + " (" + data[0].value + ")";
        this.rating_system = data[0].id;
        this.rating_systems = data;
      },
      (error: any) => {
      }
    )
  }

  refreshCertForms() {
    this.checkInput = false;
    this.checkInputCert = false;
    this.loader = false;
    this.loaderCert = false;
    this.rating_system_text = "";
    this.certification_level = "platinum";
    this.source = "Arc";
    this.term = "N/A";
    this.date_certified = moment(new Date()).format("MMM DD, YYYY");
    this.leed_id_cert = "";
    this.source_id_cert = "";
    this.energy_score = "";
    this.water_score = "";
    this.waste_score = "";
    this.transportation_score = "";
    this.human_experience_score = "";
    this.base_score_cert = "";
    this.certification_points = "";

    this.CertificationService.getRatingSystemList().subscribe(
      (data: any) => {

        this.rating_system_text = data[0].name + " (" + data[0].value + ")";
        this.rating_system = data[0].id;
        this.rating_systems = data;
      },
      (error: any) => {
      }
    )
  };

  refreshForms() {
    this.leed_id = "";
    this.loader = false;
    this.leed_id_cert_list = "";
    // this.base_score = "";
  };

  selectRatingSystem(rating_system: any) {
    this.rating_system_text = rating_system.name + " (" + rating_system.value + ")";
    this.rating_system = rating_system.id;
  };

  checkInputs() {
    // if (isNaN(this.leed_id) || this.base_score == '' || isNaN(this.base_score) || parseInt(this.base_score) > 100 || (parseInt(this.base_score)) % 10 != 0) {
    //   this.checkInput = false;
    // }
    if (isNaN(this.leed_id)) {
      this.checkInput = false;
    }
    else {
      this.checkInput = true;
    }
  };

  checkInputsCert() {
    if ((isNaN(this.leed_id_cert) || isNaN(this.source_id_cert) || this.date_certified == '' ||
      this.certification_points == '' || isNaN(this.certification_points) ||
      parseInt(this.energy_score) > 33 || parseInt(this.water_score) > 15 ||
      parseInt(this.waste_score) > 8 || parseInt(this.transportation_score) > 14 ||
      parseInt(this.human_experience_score) > 20 || parseInt(this.base_score_cert) > 10) &&
      (this.energy_score == '' || this.water_score == '' || this.waste_score == '' ||
        this.transportation_score == '' || this.human_experience_score == '')) {
      this.checkInputCert = false;
    }
    else {
      this.checkInputCert = true;
    }
  };
  changeCert(certification_level: any) {
    this.certification_level = certification_level;
  };

  changeSource(val: any) {
    this.source = val;
  };

  changeTerm(val: any) {
    this.term = val;
  };

  saveStatus() {
    this.loader = true;
    var putData =
    {
      base_score: 100
    };

    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
      {
        this.projectType = 'assets/property:';
      }
  
    
      else if (this.leed_id > '4900000000' && this.leed_id < '6000000000')
      {
        this.projectType = 'project/';
      }
      else
      {
        this.projectType = 'assets/LEED:';
      }

    this.api.put('/' + this.projectType + this.leed_id + '/', putData).subscribe(
      data => {
        this.refreshForms();
        this.alertService.alert('success', 'Base Score updated', 5);
      }, error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  saveCert() {
    this.loaderCert = true;

    var putData: any =
    {
      "certification_points": this.certification_points,
      "certification_level": this.certification_level,
      "date_certified": moment(this.date_certified).format("YYYY-MM-DD"),
      "rating_system": this.rating_system,
      "source": this.source,
      "source_id": this.source_id_cert,
      "certification_term": this.term,
      "energy_score": this.energy_score,
      "water_score": this.water_score,
      "waste_score": this.waste_score,
      "transportation_score": this.transportation_score,
      "human_experience_score": this.human_experience_score,
      "base_score": this.base_score_cert
    };


    if (this.term == "N/A") {
      delete putData['certification_term'];
    }

    if (this.energy_score == '' || this.water_score == '' || this.waste_score == '' || this.transportation_score == '' || this.human_experience_score == '') {
      this.checkInputCert = false;
      delete putData['energy_score'];
      delete putData['water_score'];
      delete putData['waste_score'];
      delete putData['transportation_score'];
      delete putData['human_experience_score'];
    }

    if (this.leed_id_cert > '3900000000' && this.leed_id_cert < '5000000000' )
      {
        this.projectType = 'assets/property:';
      }
  
    
      else if (this.leed_id_cert > '4900000000' && this.leed_id_cert < '6000000000')
      {
        this.projectType = 'project/';
      }
      else
      {
        this.projectType = 'assets/LEED:';
      }

    this.api.post('/' + this.projectType + this.leed_id_cert + '/certifications/', putData).subscribe(
      data => {
        this.refreshCertForms();
        this.alertService.alert('success', 'Certification added successfully', 5);
        this.loaderCert = false;

      }, error => {
        this.loaderCert = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };


  enableFdatePicker(): void {
    (<any>$('#date_certified')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.date_certified = $('#date_certified').val();
      });

  };

  getCertification(){
    this.loader = false;
    if (this.leed_id_cert_list > '3900000000' && this.leed_id_cert_list < '5000000000' )
    {
      this.projectType = 'assets/property:';
    }

  
    else if (this.leed_id_cert_list > '4900000000' && this.leed_id_cert_list < '6000000000')
    {
      this.projectType = 'project/';
    }
    else
    {
      this.projectType = 'assets/LEED:';
    }

    this.api.get('/' + this.projectType + this.leed_id_cert_list + '/certifications/').subscribe(
      data => { 
        if (data.certificates.length != 0) {
            data.certificates = data.certificates;
            data.certificates = data.certificates.sort(function (a: any, b: any) {
                return new Date(a['date_certified']).getTime() - new Date(b['date_certified']).getTime()
            });

            for (var i = 0; i < data.certificates.length; i++) {
                data.certificates[i].certification_expire = String(moment(data.certificates[i].certification_expire, "YYYY-MM-DD").format("MMM DD, YYYY")).toLowerCase() != 'invalid date' ? moment(data.certificates[i].certification_expire, "YYYY-MM-DD").format("MMM DD, YYYY") : 'N/A';
                data.certificates[i].date_certified = String(moment(data.certificates[i].date_certified, "YYYY-MM-DD").format("MMM DD, YYYY")).toLowerCase() != 'invalid date' ? moment(data.certificates[i].date_certified, "YYYY-MM-DD").format("MMM DD, YYYY") : 'N/A';
            }
            this.mycertifications = data.certificates;
        }
        else {
          this.mycertifications = data.certificates;
          this.alertService.alert('success', 'No certification record found', 5);
        }
      },
      error => {
      }
    );
  };

  removeCertification(index: any, certification: any){
    
    var cert_id = certification.id;

    if (this.leed_id_cert_list > '3900000000' && this.leed_id_cert_list < '5000000000' )
      {
        this.projectType = 'assets/property:';
      }
  
    
      else if (this.leed_id_cert_list > '4900000000' && this.leed_id_cert_list < '6000000000')
      {
        this.projectType = 'project/';
      }
      else
      {
        this.projectType = 'assets/LEED:';
      }

    this.api.delete('/' + this.projectType + this.leed_id_cert_list + '/certifications/ID:' + cert_id + '/', {} ).subscribe(
      data => {
        this.getCertification();
        this.alertService.alert('success', 'Certification removed successfully.', 5);
      },
      error => {
        this.alertService.alert('error', 'Please try again.', 5);
      }
    );

  };


  ngOnInit(): void {
    this.getData();
  }

}

import { Component, OnInit, Input } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ScoreCodeVersionService } from 'src/app/services/score-code-version.service';

import * as moment from 'moment';

@Component({
  selector: 'app-admin-score-version',
  templateUrl: './admin-score-version.component.html',
  styleUrls: ['./admin-score-version.component.css']
})
export class AdminScoreVersionComponent implements OnInit {
  complete_content: any = "";
  query: any = '';
  canceler: any;

  nextPage = null;
  noProjects: boolean = false;
  leed_id: any = "";
  requested_data: any = [];
  extension_approve_till: any = moment(new Date()).format("MMM DD, YYYY");
  loading_more_projects: boolean = true;
  @Input() sidebar_type: string = '';

  initAllResult(query: any) {
    this.nextPage = null;
    this.noProjects = false;
    this.leed_id = "";
    this.requested_data = [];
    this.extension_approve_till = moment(new Date()).format("MMM DD, YYYY");
    this.loading_more_projects = true;
    this.scorecodeversionservice.getAllExtensionRequest(1, 50, query).subscribe(data => {
      this.requested_data = data.results;
      for (let index = 0; index < this.requested_data.length; index++) {
        this.requested_data[index]['edit_request_flag'] = false;
        this.requested_data[index]['admin_extension_loader'] = false;
      }
      if (data.next == null) {
        this.nextPage = null;
      } else {
        this.nextPage = data.next.split('?page=')[1];
      }
      this.loading_more_projects = false;
      this.noProjects = true;
    }, error => {
      this.loading_more_projects = false;
      this.noProjects = true;
    });
  };

  searchProject(query: any) {
    if (this.canceler) {
      this.canceler.unsubscribe();
    }
    if (query.length == 0) {
      this.initAllResult(query);
      return;
    }
    this.nextPage = null;
    this.noProjects = false;
    this.leed_id = "";
    this.requested_data = [];
    this.extension_approve_till = moment(new Date()).format("MMM DD, YYYY");
    this.loading_more_projects = true;

    this.canceler = this.api.get(
      '/scores/version/extension/request/?page=1&page_size=50&q=' + query
    ).subscribe(
      data => {
        this.requested_data = data.results;
        for (let index = 0; index < this.requested_data.length; index++) {
          this.requested_data[index]['edit_request_flag'] = false;
          this.requested_data[index]['admin_extension_loader'] = false;
        }
        if (data.next == null) {
          this.nextPage = null;
        } else {
          this.nextPage = data.next.split('?page=')[1];
        }
        this.loading_more_projects = false;
        this.noProjects = true;
      }, error => {
        this.loading_more_projects = false;
        this.noProjects = true;
      }
    );
  };

  enableFdatePicker() {
    (<any>$('.approval_date')).fdatepicker({
      format: 'M dd, yyyy'
    });
  };

  approveDenyRequest(obj: any, action: any) {
    if (!obj.latest) {
      return;
    }
    obj.admin_extension_loader = true;
    obj.edit_request_flag = true;
    var request_id = obj.id;
    var is_granted = false;
    var grant_till = moment($('.extension_approve_till_' + obj.id).val()).format("YYYY-MM-DD");
    if (action == 'approved') {
      is_granted = true;
    }
    else if (action == 'denied') {
      is_granted = false;
    }
    this.scorecodeversionservice.approveExtensionRequest(obj.asset.leed_id, request_id, grant_till, is_granted).subscribe(data => {
      obj.admin_extension_loader = false;
      obj.grant_till = moment($('.extension_approve_till_' + obj.id).val()).format("YYYY-MM-DD");
      if (action == 'approved') {
        obj.is_granted = true;
      }
      else if (action == 'denied') {
        obj.is_granted = false;
      }
      this.alertService.alert('success', data.message, 5);
      obj.edit_request_flag = !obj.edit_request_flag;
    }, error => {
      obj.admin_extension_loader = false;
      if (error.data.non_field_errors == undefined) {
        this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
      }
      else {
        var error_text = error.data.non_field_errors[0];
        this.alertService.alert('error', error_text, 5);
      }
    });
  };

  showAllConetnt(obj: any) {
    this.complete_content = obj.reason;
    $('#show_all_content').modal('show');
  };

  edit_request(obj: any) {
    obj.edit_request_flag = !obj.edit_request_flag;
  };

  constructor(
    public global: Global,
    private api: API,
    public appData: AppData,
    private alertService: alertService,
    private scorecodeversionservice: ScoreCodeVersionService,
  ) { }

  ngOnInit(): void {
    (<any>$(window)).unbind('scroll');
    this.initAllResult('');
    $('#search_leed_id').val('');

    (<any>$(window)).scroll(() => {
      if (this.nextPage == null || this.appData.get('app_name') != 'admin_score_version' || this.loading_more_projects == true) {
        return;
      }
      if ((<any>$(window)).scrollTop() + (<any>$(window)).height() > ((<any>$(document)).height() - (<any>$(window)).height()) / 2) {
        if (this.loading_more_projects) return;
        if (this.nextPage == null) {
          return;
        }

        this.loading_more_projects = true;
        this.scorecodeversionservice.getAllExtensionRequest(this.nextPage, 50, this.query).subscribe(data => {
          for (var i in data.results) {
            data.results[i]['edit_request_flag'] = false;
            data.results[i]['admin_extension_loader'] = false;
            this.requested_data.push(data.results[i]);
          }
          this.loading_more_projects = false;
          if (data.next == null) {
            this.nextPage = null;
          } else {
            this.nextPage = data.next.split('?page=')[1];
          }
        }, error => {
          this.loading_more_projects = false;
        });
      }
    });
  }

}

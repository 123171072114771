import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';

@Component({
  selector: 'app-insight-home',
  templateUrl: './insight-home.component.html',
  styleUrls: ['./insight-home.component.css']
})
export class InsightHomeComponent implements OnInit
{
	country: string = 'US';
	state: string = 'DC';
	zipcode: string = '';
	user_role: string = this.appData.get('user_role')
	zipcode_input: string = '2101 L Street, NW, Washington, DC 20037';
	range_in_mile_input: number = 1;
	searching_zip: boolean = false;
	loading: boolean = false;

	project_types_input: any = [];
	countries: any = [];
	range_list: any = [];
	zipcode_list: any = [];
	assets: any = [];
	canceler: any;
	project_type_list: any = ["Datacenter", "Educational Facilities", "Health Care", "Higher Ed", "Industrial Manufacturing", "K-12", "Laboratory", "Lodging", "Military Base", "Multi-Family Residential", "Neighborhood Development", "Office: Mixed Use", "Office", "Other", "Public Assembly", "Public Order and Safety", "Religious Worship", "Retail", "Service", "Single-Family Home", "Warehouse and Distribution"];

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('buildingData').arctool_permission)
        {
            this.user_role = 'arc_pro';
        }
		
		(<any>$("#project_type")).selectpicker('render');

		this.range_list = this.generateRange(150, 5);
		this.getCountries();
        this.getAssets(1, 4);
	}

	filterAccessInsights(app: string)
	{
		this.appData.set('insight_app', app);
	}

	getCountries()
	{
		if(Config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				Config.countries = data;
				this.countries = this.global.getCountryList();
			});
		}
		else
		{
			this.countries = this.global.getCountryList()
		}
	}

	generateRange(max: any, diff: any)
	{
		var range = [1];
		for(var i = 1; i <= max; i++)
		{    
			if(i % diff == 0)
			{
				range.push(i);
			}
		}
		return range;
	}

	getAssets(page: any, page_size: any)
	{
		this.loading = true;
		this.insight.getAssets(page, page_size).subscribe(
			data =>
			{
				this.loading = false;
				this.assets = data;
				(<any>$("#project_type")).selectpicker('render');
			},
			data =>
			{
				this.loading = false;
			}
		);
	}

	changeZipCode(zipcode: string)
	{
		this.zipcode_input = zipcode;
	}

	searchZipCode(zipcode: string)
	{
		this.searching_zip = true;
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}

		this.canceler = this.insight.getZipList(zipcode).subscribe(
			data =>
			{
				this.searching_zip = false;
				this.zipcode_list = data;
				this.showDropdown();
			},
			erro =>
			{
				this.searching_zip = false;
			}
		);
	}

	changeRange(range_in_mile: any)
	{
		this.range_in_mile_input = range_in_mile;
	}

	showDropdown()
	{
		if(this.zipcode_list.length > 0)
		{
			$('.dropdown.zipcode .dropdown-menu').slideDown(200);
		}
	}

	hideDropdown()
	{
		$('.dropdown.zipcode .dropdown-menu').slideUp(200);
	}

	goToSearch()
	{
		let query_params: any = {
			'zipcode': this.zipcode_input,
			'range_in_mile': this.range_in_mile_input,
			'country': 'US'
		};
		if(this.insight.getProjectTypes(this.project_types_input).length != 0)
		{
			query_params["project_type"] = this.insight.getProjectTypes(this.project_types_input);
		}
		// this.global.goToApp("insight", 
		// {
		// 	"app_name": 'market',
		// },
		// 	query_params
		// );
		this.appData.set('insight_app', 'market');
	}

	goToModel(app: string, payload: any)
	{
		this.appData.set('leed_id_insight', payload.project.leed_id);
		this.appData.set('buildingData_insight', payload.project);
		this.appData.set('selected_version', payload.selected_version);
		this.appData.set('version', payload.version);
		this.appData.set('cs_fullname_insight', this.global.getState(payload.project.state, payload.project.country) + ", " + this.global.getCountry(payload.project.country));
		// this.global.goToApp('selected_model',
		// {
		// 	"app_name": "reduction",
		// 	"leed_id": payload.project.leed_id,
		// });
		this.appData.set('insight_app', 'reduction');
	}

	downloadPDF(name: string)
	{
		this.global.anchorDownload(name, "/../../../assets/pdf/"+ name);
	}

	createModel()
	{
		this.appData.set("current_step", "Model details");
		this.global.goToApp('create-model')
	}

}

import { Component, OnInit } from '@angular/core';
import { AfterContentInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';

import { Config } from 'src/app/models/env-vars.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit, AfterContentInit
{
	config = Config;
	leed_id: number = this.appData.get('leed_id');
	type: string = '';
	all_actions: any = this.appData.get('all_actions');

	constructor(
		public global: Global,
		public appData: AppData,
		private route: ActivatedRoute
	)
	{
		this.route.queryParams.subscribe((p: any) => 
		{
			this.type = p.type;
		});
	}

	ngOnInit(): void
	{
		this.type = this.global.getURLFromParam('type') != undefined ? this.global.getURLFromParam('type') : 'allactions';
	}

	ngAfterContentInit(): void
	{
		this.appData.set('all_actions', []);
		this.global.getActionList(true);
	}

	getHeading()
	{
		if(this.type == 'allactions')
		{
			return 'All Actions';
		}
		else if(this.type == 'prerequisites')
		{
			return 'Prerequisites';
		}
		else if(this.type == 'assignedtome')
		{
			return 'My Actions';
		}
		else if(this.type == 'basepoints')
		{
			return 'Base Points';
		}
	}

	showRow(obj: any): boolean
	{
		if(this.type == 'allactions')
		{
			return true;
		}
		else if(this.type == 'prerequisites' && obj.Mandatory == 'X')
		{
			return true;
		}
		else if(this.type == 'assignedtome' && ((obj.AssignedTo).toLowerCase() == (this.appData.get('user_firstname') + ' ' + this.appData.get('user_lastname')).toLowerCase()))
		{
			return true;
		}
		else if(this.type == 'basepoints' && ((obj.Mandatory != 'X') && (obj.CreditcategoryDescrption != 'Performance Category') && (obj.CreditcategoryDescrption != 'Performance')))
		{
			return true;
		}
		return false;
	}

	getCreditIcon(credit: any)
	{
		var iconPath = "";
		var iconPathArray: any = [];

		if(this.appData.get('buildingData').project_type == 'sites')
		{
			if(credit.category == 'Site Context')
			{
				return "../../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'Pre-Design Assessment + Planning')
			{
				return "../../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'Site Design - Water')
			{
				return "../../../assets/images/icons/leed/we-border.png";
			}
			else if(credit.category == 'SITE DESIGN - SOIL + VEGETATION')
			{
				return "../../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'SITE DESIGN - MATERIALS SELECTION')
			{
				return "../../../assets/images/icons/leed/mr-border.png";
			}
			else if(credit.category == 'SITE DESIGN - HUMAN HEALTH + WELL-BEING')
			{
				return "../../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'CONSTRUCTION')
			{
				return "../../../assets/images/icons/city/settings.svg";
			}
			else if(credit.category == 'OPERATIONS + MAINTENANCE')
			{
				return "../../../assets/images/icons/city/settings.svg";
			}
			else if(credit.category == 'EDUCATION + PERFORMANCE MONITORING')
			{
				return "../../../assets/images/icons/leed/id-border.png";
			}
			else if(credit.category == 'INNOVATION OR EXEMPLARY PERFORMANCE')
			{
				return "../../../assets/images/icons/leed/id-border.png";
			}
		}

		var creditId = credit.CreditShortId;

		if((this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community') && creditId == "base_score")
		{
			return "../../../assets/images/icons/city/plus_base.png"; 
		}

		if(credit.CreditcategoryDescrption=="Base Score"){
			// city prereqs
			return "../../../assets/images/icons/city/settings.svg"; 
		}  
		
		if(credit.CreditShortId == 'PF901' || credit.CreditShortId == 'PF902' || credit.CreditShortId == 'PF903' || credit.CreditShortId == 'PF904' || credit.CreditShortId == 'PF905' || credit.CreditShortId == 'PF906'){
			// building/city data input                
			
			iconPathArray["PF901"] = "../../../assets/images/icons/energy.svg";
			iconPathArray["PF902"] = "../../../assets/images/icons/water.svg";
			iconPathArray["PF903"] = "../../../assets/images/icons/waste.svg";
			iconPathArray["PF904"] = "../../../assets/images/icons/transport.svg";
			iconPathArray["PF905"] = "../../../assets/images/icons/human.svg";
			iconPathArray["PF906"] = "../../../assets/images/icons/city/settings.svg";

		}else if(!(credit.CreditcategoryDescrption).includes("Performance") 
				&& credit.CreditcategoryDescrption!="Prerequiste"){
			// building prereqs
			var creditCategory = credit.CreditcategoryDescrption;

			iconPathArray["Sustainable Sites"] = "../../../assets/images/icons/leed/ss-border.png";
			iconPathArray["Water Efficiency"] = "../../../assets/images/icons/leed/we-border.png";
			iconPathArray["Energy and Atmosphere"] = "../../../assets/images/icons/leed/ea-border.png";
			iconPathArray["Materials and Resources"] = "../../../assets/images/icons/leed/mr-border.png";
			iconPathArray["Indoor Environmental Quality"] = "../../../assets/images/icons/leed/iq-border.png";
			iconPathArray["Innovation"] = "../../../assets/images/icons/leed/id-border.png";
			
			return iconPathArray[creditCategory];
		
		}else if(credit.CreditcategoryDescrption=="Prerequiste"){
			// city prereqs
			return "../../../assets/images/icons/city/settings.svg"; 
		}  
		return iconPathArray[creditId];              
	};

	clickAction(credit_obj: any)
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		
		
		let short_id = credit_obj.CreditShortId.toLowerCase(), app_name: string = '', type: string = '';
		this.appData.set('credit', credit_obj)
		
		if (short_id.substring(0, 3) == "pf9")
		{
			if (short_id == "pf901"){
				app_name = 'data-input';
				type = 'energy'
			}
			else if (short_id == "pf902")
			{
				app_name = 'data-input';
				type = 'water'
			}
			else if (short_id == "pf903")
			{
				app_name = 'data-input';
				type = 'waste'
			}
			else if (short_id == "pf904")
			{
				app_name = 'data-input';
				type = 'transport'
			}
			else if (short_id == "pf905")
			{
				app_name = 'data-input';
				type = 'human'
			}else if (short_id == "pf906")
			{
				app_name = 'data-input';
				type = 'energy'
			}
		}
		else if((this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community') && short_id == "base_score")
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.leed_id,
				"app_name": "cc_basepoints"
			});
			return;
		}
		else
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.leed_id,
				"app_name": "actions-form"
			});
			return;
		}

		this.global.goToApp("selected_project", 
		{
			"leed_id": this.leed_id,
			"app_name": app_name,
			"type": type
		});
	};

}

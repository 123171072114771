import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from '../../../services/api.service';
import { Projects } from 'src/app/services/projects.service';

import { Config } from 'src/app/models/env-vars.model';

import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-performance-certificates',
  templateUrl: './performance-certificates.component.html',
  styleUrls: ['./performance-certificates.component.css']
})

export class PerformanceCertificatesComponent implements OnInit
{
	@ViewChild('modal') private modal!: ModalComponent
	
	config = Config;
	order_id: string = '';
	local_sort_order: string = '';
	current_status: string | undefined = '';
	current_tab: string = '';
	local_sort_column: string = '';
	loading: boolean = false;
	offline_sub: boolean | null = null;
	invoice_generated: boolean = false;

	subscription: any = null;
	improvement_scores: any = {
		"energy": {},
		"water": {},
		"waste": {},
		"transportation": {},
		"human_experience": {}
	};
	project_scores: any = {};
	download_cert: any = {
		"energy": null,
		"water": null,
		"waste": null,
		"transportation": null,
		"human_experience": null
	};
	past_certificates: any = [];
	payments: any;
	payment_history: any;

	data_labels: any = [{
		"type": "energy",
		"label": "Energy",
	},
	{
		"type": "water",
		"label": "Water",
	},
	{
		"type": "waste",
		"label": "Waste",
	},
	{
		"type": "transportation",
		"label": "Transportation",
	},
	{
		"type": "human_experience",
		"label": "Human Experience",
	}];

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private projects: Projects,
		private modalWindow: NgbModal
	)
	{
	}

	ngOnInit(): void
	{
		if(this.appData.get('session_id') != undefined)
		{
			this.successURL();
		}
		else
		{
			this.getSubscription();
		}
	}

	successURL()
	{
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/'+ this.appData.get('session_id') +'/'
		).subscribe(data => 
		{
			this.appData.set('session_id', undefined);
			this.getSubscription();
		}, error =>
		{
			this.appData.set('session_id', undefined);
			this.loading = false;
		});
	}

	getData()
	{
		let urls = [
			'/assets/LEED:' + this.appData.get('leed_id') + '/scores/v2/', 
			'/assets/LEED:' + this.appData.get('leed_id') + '/scores/improvement/', 
			'/assets/LEED:' + this.appData.get('leed_id') + '/certifications/performance/',
			'/subscriptions/'+ this.appData.get('leed_id') +'/payments/history/?page_size=0'
		];
		this.api.async(urls).subscribe(data =>
		{
			this.loading = false;
			this.project_scores = data[0];
			this.project_scores['transportation'] = this.project_scores['transport'];
			this.improvement_scores = data[1];
			this.past_certificates = data[2].certificates;

			this.payment_history = data[3];

			this.invoice_generated = false;
			for(var i = 0; i < this.payment_history.length; i++)
			{
				if(this.payment_history[i].type == "performance" && this.payment_history[i].status == "processing")
				{
					this.invoice_generated = true;
					this.order_id = this.payment_history[i].sap_order_id;
					break;
				}
			}

			for(var i =0; i < this.past_certificates.length; i++)
			{
				if(this.past_certificates[i].certification_level.toLowerCase().indexOf('energy') != -1 && this.download_cert.energy == null)
				{
					this.download_cert.energy = this.past_certificates[i];
				}
				else if(this.past_certificates[i].certification_level.toLowerCase().indexOf('water') != -1 && this.download_cert.water == null)
				{
					this.download_cert.water = this.past_certificates[i];
				}
				else if(this.past_certificates[i].certification_level.toLowerCase().indexOf('waste') != -1 && this.download_cert.waste == null)
				{
					this.download_cert.waste = this.past_certificates[i];
				}
				else if(this.past_certificates[i].certification_level.toLowerCase().indexOf('trans') != -1 && this.download_cert.transportation == null)
				{
					this.download_cert.transportation = this.past_certificates[i];
				}
				else if(this.past_certificates[i].certification_level.toLowerCase().indexOf('human') != -1 && this.download_cert.human_experience == null)
				{
					this.download_cert.human_experience = this.past_certificates[i];
				}
			}

			this.local_sort_column = 'date_certified';
			this.local_sort_order = '';
			this.sortLocalList('', 'date_certified', this.past_certificates.certificates);
		}, error =>
		{

		});
	}

	getCertStatus(certificate: any)
	{
		if (!certificate.certification_expire){
			return "N/A";
		}
		else if(moment(certificate.certification_expire).isBefore(new Date()))
		{
			return "<span class='danger-color'>Expired</span>";
		}			
		else
		{
			return "<span>Valid</span>";
		}			
	}

	getSubscription()
	{
		this.current_status = undefined; //inactive, active, expired;
		this.loading = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/'
		).subscribe(data =>
		{
			this.subscription = data;	
			
			if(this.subscription.status == undefined)
			{
				this.current_status = 'inactive';
				this.loading = false;
			}					
			else if(this.subscription.status == 'settled')
			{
				this.current_status = 'active';
			}					
			else if(this.subscription.status == 'cancelled_active')
			{
				this.current_status = 'cancelled_active';
			}					
			else if(this.subscription.status == 'expired')
			{
				this.current_status = 'expired';
			}					

			this.getData();
			if(this.current_status == 'inactive')
			{
				this.current_tab = 'Subscription Purchase';
			}
			else
			{
				this.current_tab = 'Current Certificates';
			}
			
			try
			{
				if(this.subscription.subscription_id.indexOf('sub_free') != -1 || this.subscription.subscription_id.indexOf('sub_offline_paid') != -1 || this.subscription.amount == undefined)
				{
					this.offline_sub = true;
				}
				else
				{
					this.offline_sub = false;
				}
			}
			catch(e)
			{
				this.offline_sub = false;
			}
		}, error => 
		{
			this.loading = false;
		});
	}

	cancelSubs()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"email": this.appData.get('user_id')
		};

		this.api.delete(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/',
			payload
		).subscribe(data =>
		{
			this.loading = false;
			this.getSubscription();
		}, error =>
		{
			this.loading = false;
		});
	};

	toggleTab(tab: string)
	{
		this.current_tab = tab;
	};

	editBilling()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("edit_certificate_billing", this.appData.get('leed_id'), this.subscription);
	}

	editCard()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"pmupdate": true
		};

		this.api.put(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/performance/',
			payload
		).subscribe(data =>
		{
			let stripe_url = this.config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
			window.open(stripe_url, "_self");
		}, error =>
		{
			this.loading = false
		});
	}

	sortLocalList(sort_order: string, sort_column: string, list: any)
	{

	}

	downloadCert(doc_id: number)
	{
		var url = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + doc_id + "&doc_source=certificate&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		var name   = "Cerfiticate.pdf";
		
		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
	};

	goToCertificate(type: string, label: string)
	{
		if(this.current_status == 'active' || this.current_status == 'cancelled_active')
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "purchase-certificates"
			},
			{
				'type': type,
				'label': label
			});
		}
		else
		{
			this.modal.open({ windowClass: 'certificate_modal' })
		}
	};

	renewSubs()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("renew_certificate", this.appData.get('leed_id'), this.subscription);
	};

	puchaseSubs()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("add_certificate", this.appData.get('leed_id'));
	};

	downloadInvoice(order_id: string)
	{
		this.loading = true;
		this.projects.getBillingDetails().subscribe(data =>
		{
			for (var i = 0; i < data.ItOrders.length; i++)
			{
				if (data.ItOrders[i]['Vbeln'] == order_id)
				{
					window.open(data.ItOrders[i]["receipt_url"], "_blank");
				}
			}
			this.loading = false;
		}, error =>
		{
			this.loading = false;
		});
	};

	getMonthDiff(month_diff: any)
	{
		if(this.subscription.card == null)
		{
			return false
		}
		var date = "01-" + String(this.subscription.card.exp_month) + "-" + String(this.subscription.card.exp_year);
		return moment(date, "DD-MM-YYYY").diff(new Date(), 'months', true) > month_diff ? false : true;
	};

	showList(payload: any)
	{
		let modalRef = this.modalWindow.open(ModalContent, { windowClass: 'certificate_modal' });
		modalRef.componentInstance.parent_object = this;
		modalRef.componentInstance.payload = payload;
	};

}

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';  
@Component({
	selector: 'modal-content',
	standalone: true,
	templateUrl: '../../../components/modal/templates/performance_certificate_list.html',
	imports: [CommonModule],
})
class ModalContent implements OnInit
{
	@Input() parent_object: any;
	@Input() payload: any;
	data_labels: any;
	project_scores: any;
	improvement_scores: any;
	download_cert: any;

	constructor(
		public activeModal: NgbActiveModal,
		public global: Global,
		public appData: AppData,
	)
	{}

	ngOnInit(): void
	{
		this.data_labels = this.parent_object.data_labels;
		this.project_scores = this.parent_object.project_scores;
		this.improvement_scores = this.parent_object.improvement_scores;
		this.download_cert = this.parent_object.download_cert;
	}

	cancel()
	{
		this.activeModal.close('Close click');
	};

	downloadCert(document?: any)
	{
		this.parent_object.downloadCert(this.payload.document.id);
	};
	
	goToCertificate(type: string, label: string)
	{
		this.parent_object.goToCertificate(type, label);
		this.cancel();
	};
}

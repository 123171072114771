import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import * as stream from 'getstream';
import 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-create-multiple-projects',
  templateUrl: './create-multiple-projects.component.html',
  styleUrls: ['./create-multiple-projects.component.css']
})
export class CreateMultipleProjectsComponent implements OnInit
{
	config = Config;
	@Output() close = new EventEmitter<any>();

	last_updated: string = 'Last updated: 15th April, 2023';
	excel_upload_status: string = "Upload";
	uploading_excel: boolean = false;
    loading: boolean = false;
    sign_agreement_project: boolean = false;
    showExcelConfirmation: boolean = false;
	projectExcelName: any = null;
	excelUploadFormData: any = null;

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{

	}

	cancel()
	{
		this.close.emit('cancel');
	}

	selectProjectExcel()
	{
		$('.uploadProjectExcelFile').click();
	}

	downloadExcel()
	{
		let excel_href = "ArcProjectCreationTemplate-v1.4.xlsm";
		this.global.downloadXLS(excel_href);
	}

	removeFeed(key: string)
	{
		var url = "/portfolios/myportfolios/notifications/?feed=" + String(key);

		this.api.delete(url, {}).subscribe(
			data =>
			{}
		);
	};

	initFeed(key: string, refresh: boolean)
	{
		this.loading = true;
		this.api.get(
			'/portfolios/'+ key +'/notifications/'
		).subscribe(
			response =>
			{
				let feeds = [];
				this.loading = false;
				
				for(var i = 0; i < response.activities.length; i++)
				{
					feeds.push(response.activities[i]);

					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						this.removeFeed(response.activities[i].foreign_id);
					}
				}
				if(feeds.length > 0)
				{
					this.config.stream_upload = true;
					this.global.showTippy();

					setTimeout(() =>
					{
						this.initFeed(this.appData.get('user_pkey'), true);
					}, 5000);
				}
				else
				{
					this.config.stream_upload = false;
					this.config.show_refresh = refresh;
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	startUpload()
	{
		let custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};

		if(this.excelUploadFormData == null)
		{
			this.projectExcelName = "Select excel template.";
			return
		}
		
		this.excel_upload_status = "Uploading...";
		this.uploading_excel = true;
		var url = '/arcexcel/assets/';

		this.api.post(url, this.excelUploadFormData, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				this.resetExcelUpload();
				this.showExcelConfirmation = true;
				this.global.showTippy();
				Config.stream_upload = true;
				this.cancel();

				setTimeout(() =>
				{
					this.initFeed(this.appData.get('user_pkey'), true);
				}, 5000);
			},
			//error handler
			response =>
			{
				this.projectExcelName = "Something went wrong";
				try
				{
					if("error" in response.data)
					{
						this.projectExcelName = response.data.error;
					}
				}
				catch(e)
				{}
				this.resetExcelUpload();
			}
		);
	}

	resetExcelUpload()
	{
		this.projectExcelName = null;
		this.excelUploadFormData = null;
		this.excel_upload_status = "Upload";
		this.sign_agreement_project = false;
		$('#sign_agreement_project').prop('checked', false);
		this.uploading_excel = false;
		this.showExcelConfirmation = false;
		this.last_updated = "Last updated: 15th April, 2023";
		$('.uploadProjectExcelFile').val("");
	}

	uploadProjectExcel(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);

		var form_data = new FormData();
		form_data.append('file', $(elem)[0].files[0]);
		
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 10; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		
		this.projectExcelName = filePath;
		
		if(this.projectExcelName.split('.')[this.projectExcelName.split('.').length - 1] == 'xlsm' || this.projectExcelName.split('.')[this.projectExcelName.split('.').length - 1] == 'xlsx')
		{
			this.excelUploadFormData = form_data;
		}
		else
		{
			this.resetExcelUpload();
			this.projectExcelName = "Incorrect File Type";
		}
	}

}

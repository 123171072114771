import { Directive, ElementRef, Input } from '@angular/core';
import { Global } from '../services/global.service';

@Directive({
  selector: '[data-tooltip], [data_tooltip]'
})
export class DataTooltipDirective
{
	@Input() data_tooltip: any;
	characters: string ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

	constructor(
		private el: ElementRef,
		private global: Global
	)
	{
		console.log(this.data_tooltip, this.el)
		let custom_class_name = this.generateString(5);
		this.el.nativeElement.classList.add(custom_class_name);
		this.el.nativeElement.setAttribute('title', this.el.nativeElement.getAttribute('data-tooltip'));
		setTimeout(() =>
		{
			this.global.initTippy("." + custom_class_name);
		});
	}

	generateString(length: number)
	{
		let result = '';
		const charactersLength = this.characters.length;
		for ( let i = 0; i < length; i++ )
		{
			result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}

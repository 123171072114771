import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'iframe'
})

export class CacheSrcDirective
{
	@Input() 
    public get cacheSrc(): string
	{
        return this.elRef.nativeElement.src;
    }
    public set cacheSrc(src: string)
	{
        if (this.elRef.nativeElement.src !== src) {
            this.renderer.setAttribute(this.elRef.nativeElement, 'src', src);
        }
    }

    constructor(
        private elRef: ElementRef,
        private renderer : Renderer2
    ) { }
}

<div id="arc_help"> 

    <div class="header_container">
        <span class="title">How Arc scores work</span>
        <div (click)="cancel()">
            <i class="fa cancel fa-times"></i>
        </div>
    </div>

    <div class="p2535">
        <div class="arcp"> 
            <b>Understanding Scores</b>
        </div>
        <div class="arcp">
            Arc distinguishes between three related concepts: (1) the ability to generate any score, (2) a “valid” score, and (3) a score of  “0”.
        </div>
        <div class="arcp">
            The following bullets describe the minimum information needed to generate any Arc Performance Score or Improvement Score. Projects without this minimum information receive an “n/a” indicating a score is “not available” for the category.
        </div>
        <br>
        <div class="arcp">
            <b>Minimum Data for Any Arc Score</b>
        </div>
        <div class="arcp">
            <b>Source energy and Carbon energy:</b> Gross area, occupancy, operating hours and at least 1 energy reading in 1 year.<br>
            <b>Water:</b> Gross area, occupancy, operating hours and at least 1 water reading in 1 year.<br>
            <b>Waste:</b> At least 1 waste generated reading and 1 waste diverted reading in 1 year.<br>
            <b>Transportation and occupancy satisfaction:</b> Survey response from at least X % of occupants in last 1 year.<br>
            <b>CO2 and TVOC:</b> At least 1 reading in last 1 year.<br>
        </div>
        <div class="arcp">
            These data are the absolute minimum required to provide an initial score. However, this information is not sufficient to provide a  “valid” score with respect to Arc Performance Certificate requirements. Requirements for a valid score for a Performance Certificate include:
        </div>
        <br>
        <div class="arcp">
            <b>Arc Performance Score Data Requirements</b>
        </div>
        <div class="arcp">
            <b>Source energy and Carbon energy:</b> Gross area, occupancy, operating hours and at least 12 consecutive months of energy measurements during a one year period ending within the last year.<br>
            <b>Water:</b> Gross area, occupancy, operating hours and at least 12 consecutive months of water measurements during a one year period ending within the last year.<br>
            <b>Waste:</b> At least 1 waste generated measurement and 1 waste diversion measurement during a one year period ending within the last year.<br>
            <b>Transportation and occupancy satisfaction:</b> Survey response from at least X% of occupants during a one year period ending within the last year. The percentage (X%) is determined by <a href="https://www.usgbc.org/resources/leed-v41-om-beta-guide" target="_blank">LEED v4.1 O+M requirements</a>.<br>
            <b>CO2 and TVOC:</b> At least 1 reading during a one year period ending within the last year.<br>
        </div>
        <br>
        <div class="arcp">
            <b>Arc Improvement Score Data Requirements</b>
        </div>
        <div class="arcp">
            <b>Source energy and Carbon energy:</b> Gross area, occupancy, operating hours and at least 12 consecutive months of energy measurements in the 12-month baseline period and the 12-month performance period. The performance period must end within 1 year of the current date.<br>
            <b>Water:</b> Gross area, occupancy, operating hours and at least 12 consecutive months of water measurements in the 12-month baseline period and the 12-month performance period. The performance period must end within 1 year of the current date.<br>
            <b>Waste:</b> At least 1 waste generation measurement and 1 waste diversion measurement  in the 12-month baseline period and the 12-month performance period. The performance period must end within one year of the current date.<br>
            <b>Transportation and occupancy satisfaction:</b> Survey response from at least X % of occupants in the 12-month baseline period and the 12-month performance period. The performance period must end within one year of the current date. The percentage (X%) is determined by <a href="https://www.usgbc.org/resources/leed-v41-om-beta-guide" target="_blank">LEED v4.1 O+M requirements</a>.<br>
            <b>CO2 and TVOC:</b>  Improvement score is not available.<br>
        </div>
        <br>
        <div class="arcp">
            The distinction between any score and a valid score reflects the desire to make initial scores available for projects, even if they have incomplete data. However, the concept of a valid score requires a more robust and complete data set. This distinction is made on Performance Certificates:
        </div>
        <div class="arcp">
            <b>A valid certificate has the text:</b> This certificate covers a performance period between &lt;date> - &lt;date> and is valid until.<br><br>
            <b>A non-valid certificate has the text:</b> This certificate covers a performance period between &lt;date> - &lt;date> and the project did not pass all data tests for this period. This certificate may not represent performance during this period.<br>
        </div>

        <div class="arcp">
            An Arc Performance Score of 0 can be generated based on minimum data requirements and, potentially, can be a valid score.  An Arc Performance Score of 0 is not equivalent to “n/a”.
            <br><br>
            Learn more about <a href="https://arc.gbci.org/arc-performance-certificates" target="_blank">Performance Certificates   </a> and <a href="https://arc.gbci.org/automated-tests-arc-performance-certificates" target="_blank">Automated Data Checks</a>. 
        </div>
    </div>
</div>

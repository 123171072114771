<div>
	<div>
        
        <div>
    
            <div class="align_lr" class="pl15 pr15 floatr" style="text-align: right;padding-top: 15px;padding-right: 40px !important;">
                <div class="fs15 line_height_24 fw-500 flex cursor-pointer" (click)="openAddUser()">
                    <div class="plus_icon position-initial mr5" data-ng-include="'assets/images/plus.svg'"></div>
                    <div class="blue-color-1775D2">Add team member</div>
                </div>
            </div>
        
            <div [ngClass]="loading ? 'not-active' : ''" class="insight_project_reduction mt0 pt20" style="background-color: white;margin: 0 15px;">
                <app-loader *ngIf="loading"></app-loader>
        
                <table class="table table-striped arc-table insight_table manage_teams mb30">
                    <thead>
                        <tr>
                            <th class="fs15 init_text_transform">Access to this project</th>
                            <th class="fs15 init_text_transform">Email</th>
                            <th class="fs15 init_text_transform">Access</th>
                            <th class="fs15 init_text_transform">Date Added</th>
                            <th class="fs15 init_text_transform"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fs15" *ngFor="let user of team; let idx = index;">
                            <td>
                                <div class="initials">
                                    {{getInitials(user.user.first_name + " " + user.user.last_name)}}
                                </div>
                                <div style="position: relative;top: 5px;">
                                    {{user.user.first_name + " " + user.user.last_name}}
                                </div>
                            </td>
                            <td>
                                <span>{{user.user.username}}</span>
                            </td>
                            <td *ngIf="appData.get('user_id') == user.user.username || !user.edit">
                                <span>{{user.user_level.trim() == 'manager' ? 'Can edit' : 'Can view only'}}</span>
                            </td>
                            <td *ngIf="appData.get('user_id') != user.user.username && user.edit">
                                <select [(ngModel)]="user.user_level" name="user_level">
                                    <option value="manager">Can edit</option>
                                    <option value="member">Can view only</option>
                                    <option value="none">None</option>
                                </select>
        
                            </td>
                            <td>
                                <span>{{global.dateFormat(user.created_at)}}</span>
                            </td>
                            <td>
                                <a *ngIf="appData.get('user_id') != user.user.username && !user.edit" (click)="editRole(user)">Edit</a>
                                <a #elem *ngIf="appData.get('user_id') != user.user.username && user.edit" (click)="saveRole(elem, user, idx)">Save</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        

    </div>
</div>

<div class="modal team_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius">
            
            <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
                <div class="left-right-align border-bottom pb16 mb0">
                    <div class="fs24 line_height_40 fw-500">
                        Add team member
                    </div> 
                </div>
            
                <div class="pb25 pt20">
                    <div class="fs15 fw-500 mb10">Enter user E-Mail</div>
                    <input (input)="validateEmail(new_user)" class="insight_input" [ngClass]="!valid_email ? 'error-border' : ''" placeholder="Enter E-Mail" [(ngModel)]="new_user">
                    <div class="error-color mt5 fw-500" *ngIf="!valid_email">{{error_msg}}</div>
            
                    <div class="fs15 fw-500 mb10 mt20">User access</div>
                    <div class="flex">
                        <div class="mr25">
                            <span (click)="toggleField('new_user_role')" class="checkcontainer">
                                Can edit
                                <input type="radio" name="new_user_role" [value]="true" [(ngModel)]="new_user_role">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
            
                        <div>
                            <span (click)="toggleField('new_user_role')" class="checkcontainer">
                                Can view only
                                <input type="radio" name="new_user_role" [value]="false" [(ngModel)]="new_user_role">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
            
                <div class="border-top pt16">
                    <button [ngClass]="!valid_email ? 'not-active' : ''" (click)="addUser(new_user, new_user_role ? 'manager' : 'member')" data-dismiss="modal" type="button" class="btn btn-primary btn-md mr25">Add</button>
                    <button (click)="cancel()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
                </div>
            </div>

        </div>
    </div>
</div>
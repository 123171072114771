import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

declare var AmCharts: any;

@Component({
  selector: 'app-insight-reduction',
  templateUrl: './insight-reduction.component.html',
  styleUrls: ['./insight-reduction.component.css']
})
export class InsightReductionComponent implements OnInit
{

	loading_data: boolean = false;
	project_reduction: any = {};
	project_types: any;
	data: any = {
		"reduction": {
			"energy": {
				"list": [],
				"meta": {}
			},
			"water": {
				"list": [],
				"meta": {}
			},
			"waste": {
				"list": [],
				"meta": {}
			},
			"zepi_list": []
		},
		"credits": {},
		"total_projects": 0
	};
	zepi_drawn: any = false;
	project: any = this.appData.get('buildingData_insight');
	selected_version: any = this.appData.get('selected_version');
	version_id: any = this.appData.get('version');

	net_zero_energy: boolean = false;
	net_zero_water: boolean = false;
	net_zero_waste: boolean = false;
	energy_estimate: number = 0;
	water_estimate: number = 0;
	waste_estimate: number = 0;
	energy_reduction_points: number = 0;
	zepi_score: number = 0;
	climate_code: string = '';

	zipcode: any = ''
	range_in_mile: any = 1;

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.version_id).subscribe(
            //success handler
            response =>
            {
				this.project_reduction = response;
                var reduction_data = response.reduction_data;
                for(var k in reduction_data)
                {
                    if(reduction_data[k].credit_id == 'net_zero_energy')
                    {
                        this.net_zero_energy = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_water')
                    {
                        this.net_zero_water = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'net_zero_waste')
                    {
                        this.net_zero_waste = reduction_data[k].estimation;
                    }

                    if(reduction_data[k].credit_id == 'energy_estimate')
                    {
                        this.energy_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'water_estimate')
                    {
                        this.water_estimate = reduction_data[k].estimation;
                    }
                    else if(reduction_data[k].credit_id == 'waste_estimate')
                    {
                        this.waste_estimate = reduction_data[k].estimation;
                    }
                    
                    if(reduction_data[k].credit_id == 'EA903')
                    {
                        this.energy_reduction_points = reduction_data[k].estimation;
                    }
                }

                if(this.net_zero_energy)
                {
                    this.energy_estimate = 0;
                }
                if(this.net_zero_water)
                {
                    this.water_estimate = 100;
                }
                if(this.net_zero_waste)
                {
                    this.waste_estimate = 100;
                }

                this.insight.getZepi(this.project, this.energy_reduction_points, this.net_zero_energy).then((response) =>
                {
                    this.zepi_score = response.zepi_score;
                    this.climate_code = response.zepi_climateZone;
                });
            },
            //error handler
            response =>
            {

            }
        );
	}

	searchData(data: any)
	{
		setTimeout(() =>
		{
			this.loading_data = true;
		})
		this.insight.getData(data.range_in_mile_input, data.zipcode_input, data.project_types_input, true, false, true).subscribe(
			response =>
			{
				this.data = response;

				this.zipcode = data.zipcode_input;
				this.range_in_mile = data.range_in_mile_input;
				this.project_types = data.project_types_input;

				if(this.data.reduction.energy == undefined)
				{
					this.data.reduction.energy = {
						"list": [],
						"meta": {
							"max": 100,
							"average": 100,
							"min": 0
						},
					}
				}
				if(this.data.reduction.water == undefined)
				{
					this.data.reduction.water = {
						"list": [],
						"meta": {
							"min": 0,
							"max": 0,
							"average": 0,
						}
					}
				}
				if(this.data.reduction.waste == undefined)
				{
					this.data.reduction.waste = {
						"list": [],
						"meta": {
							"min": 0,
							"max": 0,
							"average": 0,
						}
					}
				}

				this.drawChart('zepi_score_category', this.data.reduction.energy.meta.min, this.data.reduction.energy.meta.max, this.data.reduction.energy.meta.average, this.zepi_score);
				this.drawChart('water_category', this.data.reduction.water.meta.min, this.data.reduction.water.meta.max, this.data.reduction.water.meta.average, this.water_estimate);
				this.drawChart('waste_category', this.data.reduction.waste.meta.min, this.data.reduction.waste.meta.max, this.data.reduction.waste.meta.average, this.waste_estimate);

				(<any>$("#project_type")).selectpicker('render');
				setTimeout(() =>
				{
					this.loading_data = false;
				});
			},
			error =>
			{
				setTimeout(() =>
				{
					this.loading_data = false;
				});
			}
		);
	}

	drawChart(type: string, min: number, max: number, average: number, value: number)
	{
		min = Math.round(min);
		max = Math.round(max);
		average = Math.round(average);
		value = Math.round(value);
		let labelFunction: any, project_value: any;

		if(type == 'zepi_score_category')
		{
			var addClassNames = true;
			var reversed = true;
			labelFunction = function(valueText: any, serialDataItem: any, categoryAxis: any)
			{
				return String(valueText);
			};
			project_value = '<div class="ballon"><p class="heading">zEPI Score</p><div class="values">[[project_value]]</div></div>';
		}
		else
		{
			var addClassNames = false;
			var reversed = false;
			
			if(type == 'water_category')
			{
				project_value = '<div class="ballon"><p class="heading">Water reduction</p><div class="values">[[project_value]]</div></div>';

				labelFunction = function(valueText: any, serialDataItem: any, categoryAxis: any)
				{
					return String(valueText) + "%";
				};
			}
			else if(type == 'waste_category')
			{
				project_value = '<div class="ballon"><p class="heading">Waste diversion</p><div class="values">[[project_value]]</div></div>';

				labelFunction = function(valueText: any, serialDataItem: any, categoryAxis: any)
				{
					return "-" + String(valueText) + "%";
				};
			}
		}

		var local_average = '<div class="ballon"><p class="heading">Project(s)</p><div class="values">[[local_average]]</div></div>';
		var chart = AmCharts.makeChart(type, {
			"type": "serial",
			"theme": "none",
			"addClassNames": addClassNames,
			"dataProvider": [{
				"axis": 0,
				"project_value": value,
				"local_average": average,
				"start_range": min,
				"end_range": max,
			}],
			"valueAxes": [{
				"axisAlpha":1,
				"minimum": 0,
				"maximum": 100,
				"strictMinMax": true,
				"color": "#68737D",
				"axisColor": "#BAC1CA",
				"fontSize": 13,
				"gridAlpha": 0,
				"tickLength": 12,
				"offset": -14,
				"reversed": reversed,
				"labelFunction": labelFunction
			}],
			"graphs": [{
				"title": "Column",
				"type": "column",
				"fillAlphas": 1,
				"openField": "start_range",
				"valueField": "end_range",
				"fixedColumnWidth": 20,
				"lineColor": "#EBEDED",
				"showBalloon": false
			}, {
				"bullet": "round",
				"bulletBorderAlpha": 1,
				"bulletColor": "#00A5C8",
				"bulletSize": 10,
				"title": "Local Average Score",
				"valueField": "local_average",
				"balloonText": local_average,
				"balloonColor": "white",
				"balloon": {
					"borderAlpha": 0,
					"borderThickness": 0,
					"fillAlpha": 0
				},
			},
			{
				"bulletSize": 20,
				"title": "zEPI Score",
				"valueField": "project_value",
				"customBullet": "//static-app.arconline.io/data-input-widget-assets-nonprd/svg/concentric.svg",
				"balloonText": project_value,
				"balloonColor": "white",
				"balloon": {
					"borderAlpha": 0,
					"borderThickness": 0,
					"fillAlpha": 0
				},
				"bulletColor": "#FFFFFF"
			}],
			"rotate": true,
			"categoryField": "axis",
			"categoryAxis": {
				"axisAlpha": 0,
				"gridAlpha": 0,
				"labelsEnabled": false
			}            
		});

		if(this.zepi_drawn || type != 'zepi_score_category')
		{
			return;
		}

		let stopGradient = () =>
		{
			clearInterval(gradient);
			this.zepi_drawn = true;
		}

		var gradient = setInterval(function()
		{
			try
			{
				var max: any = $($('#zepi_score_category .amcharts-value-axis .amcharts-axis-label')[0]).offset();
				var min: any = $($('#zepi_score_category .amcharts-value-axis .amcharts-axis-label')[$('#zepi_score_category .amcharts-value-axis .amcharts-axis-label').length - 1]).offset();
				var width = max.left - min.left - 7;
				var top = 8;
				var left = 15;
				$(".zepi_gradient").css("width", width + "px");
				$(".zepi_gradient").css("left", left + "px");

				$(".zepi_gradient_100").css("top", (top + 5 + 8) + "px");
				$(".zepi_gradient_100").css("left", left + "px");

				$(".zepi_gradient_60").css("top", (top + 5 + 8) + "px");
				$(".zepi_gradient_60").css("left", "40%");

				$(".zepi_gradient_50").css("top", (top + 5 + 8) + "px");
				$(".zepi_gradient_50").css("left", "50%");

				$(".zepi_gradient_0").css("top", (top + 5 + 8) + "px");
				$(".zepi_gradient_0").css("left", "97%");
				stopGradient();
			}
			catch(e)
			{

			}
		}, 1);
	}
}

<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold visible-xs-inline" translate>Agreements</span>
            <span class="fw-semi-bold" translate>Agreements</span>
        </h1>
        <div class="row">
            <div>        
                <section [ngClass]="loading ? 'not-active' : ''" class="widget manage_agreements">
                    <app-loader *ngIf="loading"></app-loader>
                    <div>
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th translate class="w50p">Date Signed</th>
                                    <th translate class="w40p">Agreement Type</th>
                                    <th class="w10p" translate>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let agreements of myagreements" class="cursor-pointer" [hidden]="!(agreements.Description == 'ProjReg_Terms_And_Cond' || agreements.Description == 'Certification_Agreement_SLL_Review' || agreements.Description == 'ProjAddendum_Terms_And_Cond')">
                                    <td><span>{{global.unixDateFormat(agreements.CreatedAt)}}</span></td>
                                    <td><span>{{agreements.Description == 'ProjReg_Terms_And_Cond' ? 'Registration' : agreements.Description == 'ProjAddendum_Terms_And_Cond' ? 'Addendum' : 'Certification'}}</span></td>
                                    <td class="edit_buttons">
                                        <button type="button" class="btn btn-default" (click)="downloadAgreement(agreements)"><i class="fa fa-download" aria-hidden="true"></i> <span translate>Download</span></button>
                                    </td>
                                </tr>				    
                            </tbody>
                        </table>
                    </div>

                    <div class="mt40">
                        <h4 class="fw-500 page-title pl0">Other Legal Documents</h4>
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th translate class="w40p">Type</th>
                                    <th translate class="w25p">Uploaded by</th>
                                    <th translate class="w25p">Uploaded at</th>
                                    <th translate class="w10p">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr [hidden]="other_legal_documents.length > 0">
                                    <td class="noProjects" colspan="5"> No document found.</td>
                                </tr>
                                <tr *ngFor="let other_legal_document of other_legal_documents" [hidden]="other_legal_documents.length == 0">
                                    <td class="vertical-align-middle"><span>{{other_doc_categories[other_legal_document.doc_category]}}</span></td>
                                    <td class="vertical-align-middle"><span>{{other_legal_document.uploaded_by}}</span></td>
                                    <td class="vertical-align-middle"><span>{{global.unixDateFormat(other_legal_document.uploaded_at)}}</span></td>
                                    <td class="vertical-align-middle edit_buttons flex">
                                        <button type="button" class="btn btn-default" (click)="global.downloadFile(other_legal_document)"><i class="fa fa-download" aria-hidden="true"></i> <span translate>Download</span></button>
                                        <div class="ml20 delete_report floatNone" (click)="openDeleteModal(other_legal_document)"></div>
                                    </td>
                                </tr>				    
                            </tbody>
                        </table>
                    </div>
                    <button class="review_button fw-500 white_color pl20 pr20 fs15 mr30" (click)="openDownload()">
                        <i class="icon-download-alt mr5"></i>
                        Download a legal template
                    </button>
                    <input #elem (input)="nameChanged(elem)" class="hide uploadFile" type="file">
                    <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="review_button fw-500 white_color pl20 pr20 fs15" (click)="openModal('other_legal_doc')">
                        <i class="icon-upload-alt mr5"></i>
                        Upload a legal document
                    </button>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal" id="other_legal_doc" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md">
        <div class="modal-content modal_radius" [ngClass]="uploading_agreement ? 'not-active removeOpacity' : ''">
            <app-loader *ngIf="uploading_agreement"></app-loader>
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs18 fw-600 ">Upload Document</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body score_version_modal_body mb20">
                <form>
                    <div class="form-group">
                        <label class="fw-500 mb10 fs16">Document type</label>
                        <select name="requested_cert" [(ngModel)]="document_type" class="form-control cursor-pointer">
                            <option value="change_of_owner_agreement" selected>{{other_doc_categories['change_of_owner_agreement']}}</option>
                            <option value="agent_authority_agreement">{{other_doc_categories['agent_authority_agreement']}}</option>
                            <option value="primary_owner_agreement">{{other_doc_categories['primary_owner_agreement']}}</option>
                            <option value="amaendments_to_cert_service_agreements">{{other_doc_categories['amaendments_to_cert_service_agreements']}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="fw-500 mb10 mt20 fs16">Select a file</div>
                        <div class="flex pt10">
                            <button [ngClass]="uploading_agreement ? 'not-active' : ''" (click)="global.openUploadDialog('laptop', '', 'S3')" class="btn btn-default">
                                <i class="icon-folder-open-alt mr5"></i>
                                Browse
                            </button>
                            <span *ngIf="uploaded_data != undefined" class="ellipsis fw-600 fontSize13 ml10 pt5 w100p">{{uploadName}}</span>
                        </div>
                    </div>
                    <div class="form-group mb50" [ngClass]="uploading_agreement ? 'not-active' : ''">
                        <button [ngClass]="uploaded_data == undefined ? 'not-active' : ''" class="review_button fw-500 white_color floatr pl20 pr20 fs15" (click)="uploadDocument()">
                            <i class="icon-upload-alt mr5"></i>
                            {{upload_btn_text}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade score_version_modal overflow-auto" id="agreement_delete_confirmation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md diplay_grid">
        <div class="modal-content modal_radius removeOpacity pb20">
            <div class="modal-header score_version_modal_header">
                <h5 class="modal-title fs24 fw-600 ">Delete agreement</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body score_version_modal_body pl15 pr15 pt0">
                <div class="row pt20">
                    <div class="col-md-12 fs16">
                        Are you sure you want to permanently delete this file?
                    </div>
                </div>
                <div class="row pt30" [ngClass]="loading ? 'not-active' : ''">
                    <div class="col-md-12">
                        <button class="btn btn-primary fs16 h40 pl20 pr20" (click)="deleteFile()">
                            {{deleting_text}}
                        </button>
                        <button class="btn btn-default fs16 h40 ml10 pl20 pr20" data-dismiss="modal" aria-label="Close">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal #download_survey>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-lg">
        <div class="left-right-align mb40 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Which template would you like to download?
            </div> 
            <div class="pt10 cursor-pointer" (click)="closeSurvey('download_survey')">
                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" data-dismiss="modal">
                    <path d="M18.5 2.31286L16.6871 0.5L9.5 7.68714L2.31286 0.5L0.5 2.31286L7.68714 9.5L0.5 16.6871L2.31286 18.5L9.5 11.3129L16.6871 18.5L18.5 16.6871L11.3129 9.5L18.5 2.31286Z" fill="#68737D"/>
                </svg>    
            </div>
        </div>
        <div class="left-right-align survey_option vertical_center_flex">
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{other_doc_categories['change_of_owner_agreement']}}</div>
            </div>
            <div>
                <button class="btn btn-primary line_height_24" (click)="downloadSurvey('change_of_owner_agreement')">
                    Download
                </button>
            </div>
        </div>
        <div class="left-right-align survey_option vertical_center_flex">
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{other_doc_categories['agent_authority_agreement']}}</div>
            </div>
            <div>
                <button class="btn btn-primary line_height_24" (click)="downloadSurvey('agent_authority_agreement')">
                    Download
                </button>
            </div>
        </div>
        <div class="left-right-align survey_option vertical_center_flex">
            <div>
                <div class="fs16 line_height_24 mb2 fw-500">{{other_doc_categories['primary_owner_agreement']}}</div>
            </div>
            <div>
                <button class="btn btn-primary line_height_24" (click)="downloadSurvey('primary_owner_agreement')">
                    Download
                </button>
            </div>
        </div>
    </div>
</modal>
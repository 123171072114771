import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-test-project',
  templateUrl: './test-project.component.html',
  styleUrls: ['./test-project.component.css']
})
export class TestProjectComponent implements OnInit {

  constructor(
    public global: Global,
    private api: API,
    private alertService: alertService,
    public appData: AppData
  ) { }

  bld_data: any = {};
  checkLEED: boolean = false;
  loader: boolean = false;
  not_active: boolean = false;
  leed_id: any = '';
  btn_text: string = "";
  projectType: string = 'LEED';

  checkLEEDID() {
    if (isNaN(this.leed_id) || String(this.leed_id).length == 0) {
      this.checkLEED = false;
    }
    else {
      this.checkLEED = true;
    }
  };

  searchProject() {
    if (!this.checkLEED) {
      return false;
    }

    if (this.leed_id > '3900000000' && this.leed_id < '5000000000' )
      {
        this.projectType = 'property';
      }
      else
      {
        this.projectType = 'LEED';
      }
    this.loader = true;
    this.api.get('/assets/' + this.projectType + ':' + this.leed_id + '/').subscribe(
      data => {
        var dataGET = data;
        this.bld_data = dataGET;
        this.loader = false;

        if (this.bld_data.test_project) {
          this.btn_text = "ADDED";
        }
        else {
          this.btn_text = "ADD";
        }
      },
      error => {
        var data = error;
        this.loader = false;
        this.bld_data = {};
        try {
          this.alertService.alert('error', data.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  makeAction(bld: any) {

    var putData = { test_project: true };
    this.not_active = true;

    if (bld.test_project) {
      putData = { test_project: false };
    }

    if (bld.leed_id > '3900000000' && bld.leed_id < '5000000000' )
      {
        this.projectType = 'property';
      }
      else
      {
        this.projectType = 'LEED';
      }

    this.api.put('/assets/' + this.projectType + ':' + bld.leed_id + '/', putData).subscribe(
      data => {
        this.not_active = false;
        this.bld_data = data;

        if (this.bld_data.test_project) {
          this.btn_text = "ADDED";
        }
        else {
          this.btn_text = "ADD";
        }
        this.alertService.alert('success', 'Updated successfully', 5);
      }, error => {
        this.not_active = false;
        this.alertService.alert('error', 'Something went wrong', 5);
      }
    );
  };

  ngOnInit(): void {
  }

}

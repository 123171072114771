<nav class="page-controls navbar_info navbar-default" *ngIf="appData.get('current_app') == 'selected_project' && ['purchase-certificates', 'create-models', 'payment', 'review-selection', 'review-payment', 'survey', 're-entry-survey'].indexOf(appData.get('app_name')) == -1">
	<div class="align_lr">
		<div class="flex">
			<div>
				<h4 class="mr15 mb5 fw-400 fs17 mt13 project_name_banner">{{appData.get('buildingData').name}}, {{appData.get('cs_fullname')}}</h4>
				<div>
					<span class="fs13 gray_color_707479">{{appData.get('buildingData').leed_id}}</span>
				</div>
			</div>
			<button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.upgradeUser('purchase_arc_pro')" class="mt15 ml20 arc_pro_button btn btn-default">
				<div class="arc_logo_racetrack"></div>
				<a class="fw-500 fs14">Upgrade my account</a>
			</button>

			<div *ngIf="appData.get('user_role') == 'arc_pro'" class="arc_essential_label mt20 ml20">
				<div class="arc_logo_racetrack"></div>
				<span class="fw-500 fs15">{{config.arc_pro_label}}</span>
			</div>

			<div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20" (click)="global.redirect_to_gsa()" [ngClass]="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school') && appData.get('space_type_gsa').indexOf(appData.get('buildingData').spaceType) > -1 ? 'cursor-pointer' : ''">
				<div class="arc_gsa_logo"></div>
				<span class="fw-500 fs15">{{config.arc_gsa_label}}</span>
			</div>
		</div>
		<div id="add_to_portfolio" class="relative-position">
			<button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="add_to_portfolio blue outline mt15 dropdown-toggle" data-toggle="dropdown" translate>
				<img class="plus_icon" src="../../../assets/images/plus.svg">
				Add to Portfolio
			</button>
			<div id="portfolio_filters" class="dropdown-menu add_to_portfolio">
				<div class="project_filter_header">
					<div class="fw-500">Select a portfolio</div>
					<div>
						<a (click)="createPortfolio()">Create a new portfolio</a>
					</div>
				</div>

				<div class="project_filter_header">
					<div id="portfolio_list" class="user-list name-list" >
						<div class="cursor-pointer fs14 line_height_24 mb5" *ngFor="let port of portfolios" (click)="selectPortfolio(port)">
							<div class="checkbox mr5 blue_check ck_gray_border">
                                <input name="{{port.pf_id}}" type="checkbox">                
                                <label class="line_height_18" for="checkbox">
                                    {{port.name}} <span class="fs13 gray_color_707479 pt2">{{port.pf_id}}</span>
                                </label>
                            </div>
						</div>
					</div>
				</div>

				<div [ngClass]="selected_portfolios.length == 0 ? 'not-active' : ''" class="project_filter_item">
					<div class="mr25">
						<button (click)="addToPortfolio()" class="btn btn-primary btn-md">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>

<app-sidebar [hidden]="config.hide_sidebar" [sidebar_type]="'project'"></app-sidebar>

<app-measures *ngIf="appData.get('app_name') == 'measures'"></app-measures>
<app-measure-form *ngIf="appData.get('app_name') == 'measure-form'"></app-measure-form>

<app-actions *ngIf="appData.get('app_name') == 'actions'"></app-actions>
<app-actions-form *ngIf="appData.get('app_name') == 'actions-form'"></app-actions-form>

<app-project-overview *ngIf="appData.get('app_name') == 'project-overview'"></app-project-overview>
<app-project-performance *ngIf="appData.get('app_name') == 'project-performance'"></app-project-performance>
<app-data-input *ngIf="appData.get('app_name') == 'data-input'"></app-data-input>
<app-models *ngIf="appData.get('app_name') == 'models'"></app-models>
<app-insight *ngIf="appData.get('app_name') == 'insight'"></app-insight>

<app-project-tools-home *ngIf="appData.get('app_name') == 'project-tools-home'"></app-project-tools-home>
<app-performance-certificates *ngIf="appData.get('app_name') == 'performance-certificates'"></app-performance-certificates>
<app-purchase-certificates *ngIf="appData.get('app_name') == 'purchase-certificates'"></app-purchase-certificates>
<app-climate-risk *ngIf="appData.get('app_name') == 'climate-risk'"></app-climate-risk>
<app-financial-models *ngIf="appData.get('app_name') == 'financial-models'"></app-financial-models>
<app-create-models *ngIf="appData.get('app_name') == 'create-models'"></app-create-models>
<app-advanced-scoring *ngIf="appData.get('app_name') == 'advanced-scoring'"></app-advanced-scoring>
<app-certifications *ngIf="appData.get('app_name') == 'certifications'"></app-certifications>
<app-payment *ngIf="appData.get('app_name') == 'payment'"></app-payment>
<app-review-selection *ngIf="appData.get('app_name') == 'review-selection'"></app-review-selection>
<app-review-payment *ngIf="appData.get('app_name') == 'review-payment'"></app-review-payment>
<app-review-success *ngIf="appData.get('app_name') == 'review-success'"></app-review-success>
<app-play-to-zero *ngIf="appData.get('app_name') == 'play-to-zero'"></app-play-to-zero>
<app-eu-taxonomy *ngIf="appData.get('app_name') == 'eu-taxonomy'"></app-eu-taxonomy>
<app-efficiency-analysis *ngIf="appData.get('app_name') == 'efficiency-analysis'"></app-efficiency-analysis>
<app-re-entry *ngIf="appData.get('app_name') == 're-entry'"></app-re-entry>
<app-reports *ngIf="appData.get('app_name') == 'reports'"></app-reports>

<app-team *ngIf="appData.get('app_name') == 'team'"></app-team>
<app-project *ngIf="appData.get('app_name') == 'project'"></app-project>
<app-apps *ngIf="appData.get('app_name') == 'apps'"></app-apps>
<app-billing *ngIf="appData.get('app_name') == 'billing'"></app-billing>
<app-paynow *ngIf="appData.get('app_name') == 'paynow'"></app-paynow>
<app-agreement *ngIf="appData.get('app_name') == 'agreement'"></app-agreement>
<app-settings *ngIf="appData.get('app_name') == 'settings'"></app-settings>
<app-score-version *ngIf="appData.get('app_name') == 'score-version'"></app-score-version>

<app-survey *ngIf="appData.get('app_name') == 'survey'"></app-survey>
<app-re-entry-survey *ngIf="appData.get('app_name') == 're-entry-survey'"></app-re-entry-survey>
import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { alertService } from '../../services/alert-service.service';
import { ValidationService } from 'src/app/services/validation.service';

import { Config } from 'src/app/models/env-vars.model';

import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-create-models',
  templateUrl: './create-models.component.html',
  styleUrls: ['./create-models.component.css']
})
export class CreateModelsComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent

	regex: any = this.validator.regex;
	heading: string = 'Create a Cost Benefit Model'; 
	data_template: string = 'details'
	title: string = 'Analyze energy and water cost-benefits';
	report_mode: string | null = null;
	button_text: string = 'Next';
	mode: string = 'paid';
	current_status: string | null = null;
	submit_error: boolean = false;
	loading: boolean = false;
	leed_id: number = this.appData.get('leed_id');
	autocase_modal: boolean = true;
	error_json: any = {};
	message: string = '';
	heading_title: string = '';
	picker_loaded: boolean = false;

	no_error = {
		'error': false,
		'help': undefined
	};
	formdata_error: any = {
		"baseline_date": this.no_error,
		"model_type": this.no_error,
		"elec_percent_reduction": this.no_error,
		"ng_percent_reduction": this.no_error,
		"water_percent_reduction": this.no_error,
		"water_reclaimed_percent_reduction": this.no_error,
		"daily_average_energy_elect_design": this.no_error,
		"daily_average_energy_ng_design": this.no_error,
		"daily_water_potable_design": this.no_error,
		"daily_water_reclaimed_design": this.no_error,
		"daily_average_renewable_design": this.no_error
	};
	formdata: any = {
		"baseline_date": moment(new Date()).format("MMM DD, YYYY"),
		'model_type': 'null',

		'elec_percent_reduction': '',
		'ng_percent_reduction': '',
		'water_percent_reduction': '',
		'water_reclaimed_percent_reduction': '',

		'daily_average_energy_elect_design': '',
		'daily_average_energy_ng_design': '',
		'daily_water_potable_design': '',
		'daily_water_reclaimed_design': '',
		'daily_average_renewable_design': ''
	}
	past_performance: any = null;
    future_performance: any = null;
    future_payload: any = {};
    baseline_data: any = {};
    data_coverage: any = {
		daily_average_energy_elect: '',
		daily_average_energy_ng: '',
		daily_average_renewable: '',
		daily_water_potable: '',
		daily_water_reclaimed: ''
	};
	future_date: any = moment(this.formdata.baseline_date).subtract(1, 'year').add(1, 'day').add(25, 'years').subtract(1, 'day').format('MMM DD, YYYY');

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService,
		private validator: ValidationService
	){ }

	ngOnInit(): void
	{
		this.report_mode = this.config.state('report_mode') != undefined ? this.config.state('report_mode') : 'free_report';
		this.current_status = this.config.state('current_status');
		if(this.mode == 'free')
		{
			this.formdata["model_type"] = 'net zero';
			this.formdata["model_type_readonly"] = true;
			this.formdata["baseline_date_readonly"] = true;
		}
		this.getSubscription();
	}

	goBack()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": "financial-models"
		});
	}

	puchaseSubs()
	{
		this.global.upgrade("add_certificate", this.appData.get('leed_id'));
	}

	verifyDetails()
	{
		if(this.formdata.model_type == "null" && this.report_mode != 'free_report')
		{
			this.submit_error = true;
		}
		else
		{
			this.submit_error = false;
		}
		if(this.formdata.model_type == 'net zero' || this.report_mode == 'free_report')
		{
			delete this.formdata.elec_percent_reduction;
			delete this.formdata.ng_percent_reduction;
			delete this.formdata.water_percent_reduction;
			delete this.formdata.water_reclaimed_percent_reduction;

			delete this.formdata.daily_average_energy_elect_design;
			delete this.formdata.daily_average_energy_ng_design;
			delete this.formdata.daily_water_potable_design;
			delete this.formdata.daily_water_reclaimed_design;
			delete this.formdata.daily_average_renewable_design;

			this.formdata_error.elec_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.ng_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.water_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.water_reclaimed_percent_reduction = {"error": false, "help": ""};

			this.formdata_error.daily_average_energy_elect_design = {"error": false, "help": ""};
			this.formdata_error.daily_average_energy_ng_design = {"error": false, "help": ""};
			this.formdata_error.daily_water_potable_design = {"error": false, "help": ""};
			this.formdata_error.daily_water_reclaimed_design = {"error": false, "help": ""};
			this.formdata_error.daily_average_renewable_design = {"error": false, "help": ""};
		}
		else if(this.formdata.model_type == 'percentage reduction')
		{
			delete this.formdata.daily_average_energy_elect_design;
			delete this.formdata.daily_average_energy_ng_design;
			delete this.formdata.daily_water_potable_design;
			delete this.formdata.daily_water_reclaimed_design;

			this.formdata_error.daily_average_energy_elect_design = {"error": false, "help": ""};
			this.formdata_error.daily_average_energy_ng_design = {"error": false, "help": ""};
			this.formdata_error.daily_water_potable_design = {"error": false, "help": ""};
			this.formdata_error.daily_water_reclaimed_design = {"error": false, "help": ""};
			this.formdata_error.daily_average_renewable_design = {"error": false, "help": ""};
		}
		else if(this.formdata.model_type == 'target')
		{
			delete this.formdata.elec_percent_reduction;
			delete this.formdata.ng_percent_reduction;
			delete this.formdata.water_percent_reduction;
			delete this.formdata.water_reclaimed_percent_reduction;

			this.formdata_error.elec_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.ng_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.water_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.water_reclaimed_percent_reduction = {"error": false, "help": ""};
			this.formdata_error.daily_average_renewable_design = {"error": false, "help": ""};
		}
		
		for(var a in this.formdata)
		{
			var optional = false;
			if(a == 'daily_average_renewable_design')
			{
				optional = true;
			}
			this.verifyField(this.formdata[a], a, optional);
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				return;
			}
		}

		this.loading = true;
		this.button_text = 'Submitting data...';
		var baseline_date = moment(this.formdata.baseline_date, 'MMM DD, YYYY').format('YYYY-MM-DD');
		this.api.get('/assets/LEED:' + this.leed_id + '/autocase/past-performance/?baseline_date=' + baseline_date).subscribe(
			data =>
			{
				this.loading = false;
				this.button_text = 'Next';
				
				this.data_template = 'overview';
				this.title = 'Analyze energy and water performance';
				// this.heading = 'Create a Past Performance Report'; 
				this.button_text = 'Create';
				this.baseline_data = data;
				this.future_date = moment(baseline_date).subtract(1, 'year').add(1, 'day').add(25, 'years').subtract(1, 'day').format('MMM DD, YYYY');
				this.dataCoverage();
			},
			error =>
			{
				this.loading = false;
				this.button_text = 'Next'
			}
		);
	}

	enableFdatePicker()
	{
		if(this.picker_loaded == false)
		{
			this.picker_loaded = true;
			var disable_before = new Date('2019-12-31');
			(<any>$('#baseline_date')).fdatepicker(
			{
				format: 'M dd, yyyy',
				onRender: (date: any) =>
				{
					return date.valueOf() < disable_before.valueOf() ? 'disabled' : '';
				}
			}).on('changeDate', (ev: any) =>
			{
				this.formdata.baseline_date = $('#baseline_date').val();
			});
		}
	}

	verifyField(data: string, key: string, optional?: boolean)
	{
		if(this.regex[key] == undefined)
		{
			return;
		}
		
		if(data == undefined || data == null || data.length == 0)
		{
			if(optional)
			{
				this.formdata_error[key] = {
					'error': false,
					'help': undefined
				};
				delete this.formdata[key];
				return;
			}
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				break;
			}
		}
	}

	modelTypeChange()
	{
		this.submit_error = false;
		this.formdata_error = {
			"baseline_date": this.no_error,
			"model_type": this.no_error,
			"elec_percent_reduction": this.no_error,
			"ng_percent_reduction": this.no_error,
			"water_percent_reduction": this.no_error,
			"water_reclaimed_percent_reduction": this.no_error,
			"daily_average_energy_elect_design": this.no_error,
			"daily_average_energy_ng_design": this.no_error,
			"daily_water_potable_design": this.no_error,
			"daily_water_reclaimed_design": this.no_error,
			"daily_average_renewable_design": this.no_error
		};

		this.formdata['elec_percent_reduction'] = '';
		this.formdata['ng_percent_reduction'] = '';
		this.formdata['water_percent_reduction'] = '';
		this.formdata['water_reclaimed_percent_reduction'] = '';

		this.formdata['daily_average_energy_elect_design'] = '';
		this.formdata['daily_average_energy_ng_design'] = '';
		this.formdata['daily_water_potable_design'] = '';
		this.formdata['daily_water_reclaimed_design'] = '';
		this.formdata['daily_average_renewable_design'] = '';
	}

	autocaseSubmit()
	{
		this.button_text= 'Verifying data...';
		this.loading = true;
		var baseline_date = moment(this.formdata.baseline_date, 'MMM DD, YYYY').format('YYYY-MM-DD');
		var payload =
		{
			"baseline_date": baseline_date
		};
		this.api.post('/assets/LEED:' + this.leed_id + '/autocase/past-performance/', payload).subscribe(
			data =>
			{
				this.past_performance = data;
				this.future_payload = 
				{
					"baseline_date": baseline_date,
					"model_type": "nze",
					"target_period": 1
				};
				if(this.formdata.model_type == 'percentage reduction')
				{
					this.future_payload = 
					{
						"baseline_date": baseline_date,
						"model_type": "percent-reduction",
						"target_period": 1,
						"elec_percent_reduction": this.formdata.elec_percent_reduction,
						"ng_percent_reduction": this.formdata.ng_percent_reduction,
						"water_percent_reduction": this.formdata.water_percent_reduction,
						"water_reclaimed_percent_reduction": this.formdata.water_reclaimed_percent_reduction,
						"daily_average_renewable_design": this.formdata.daily_average_renewable_design
					};
				}
				else if(this.formdata.model_type == 'target')
				{
					this.future_payload = 
					{
						"baseline_date": baseline_date,
						"model_type": "target",
						"target_period": 1,
						"daily_average_energy_elect_design": this.formdata.daily_average_energy_elect_design,
						"daily_average_energy_ng_design": this.formdata.daily_average_energy_ng_design,
						"daily_water_potable_design": this.formdata.daily_water_potable_design,
						"daily_water_reclaimed_design": this.formdata.daily_water_reclaimed_design,
						"daily_average_renewable_design": this.formdata.daily_average_renewable_design
					};
				}
				if(this.report_mode != 'free_report')
				{
					this.api.post('/assets/LEED:' + this.leed_id + '/autocase/business-case/performance/', this.future_payload).subscribe(
						data =>
						{
							this.future_performance = data;
							this.loading = false;
							this.button_text= 'Next';
							this.createReport();
						},
						error =>
						{
							if(error.status == 400)
							{
								this.showWarning(error);
							}
							this.loading = false;
							this.button_text= 'Next'
						}
					);
				}
				else
				{
					this.loading = false;
					this.button_text= 'Next';
					this.createReport();
				}
			},
			error =>
			{
				if(error.status == 400)
				{
					this.showWarning(error);
				}
				this.loading = false;
				this.button_text= 'Next'
			}
		);
	}

	changeView()
	{
		this.title = 'Analyze energy and water cost-benefits';
		this.data_template = 'details';
		this.heading = 'Create a Cost Benefit Model'; 
	}

	dataCoverage()
	{
		this.loading = true;
		var url = '/assets/LEED:'+ this.leed_id +'/datacoverage/?category=autocase&at=' + moment(this.formdata.baseline_date, 'MMM DD, YYYY').format('YYYY-MM-DD');
		this.api.get(url).subscribe(
			data =>
			{
				this.loading = false;
				this.data_coverage = data;
				// tippy('.tippy_init', {size: 'large', animation: 'shift-away', theme: 'light', placement: 'right'});
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	createReport()
	{
		var payload = {
			"past_performance": this.past_performance,
			"future_performance": this.future_performance,
			"formdata": this.future_payload
		}
		var form_data = new FormData();

		if(this.global.getBrowser().ie == true || this.global.getBrowser().safari == true)
		{
			var f: any = new Blob([JSON.stringify(payload)], { type: "application/json"});
			f.lastModifiedDate = new Date();
			form_data.append('action_file', f, "payload.json");
		}
		else
		{
			var f: any = new File([JSON.stringify(payload)], "payload.json", {type: "application/json", lastModified: <any>new Date()})
			form_data.append('action_file', f);
		}
		
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', "autocase_payloads")
		this.loading = true;
		this.api.post(
			'/assets/LEED:'+ this.leed_id +'/uploadS3/?upload_category=autocase_payloads',
			form_data,
			custom_basic_config_header
		).subscribe(
			//success handler
			data =>
			{
				var report_title = data.id;
				var baseline_date = moment(this.formdata.baseline_date, 'MMM DD, YYYY').format('YYYY-MM-DD');
				var url = '/assets/LEED:' + this.leed_id + '/createreport/';
				url += '?report_type=autocase_report';
				url += '&report_title=' + report_title;
				url += '&report_mode=' + this.report_mode;
				url += "&START=" + moment(baseline_date).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
				url += "&END=" + baseline_date;
				this.api.get(url).subscribe(
					//success handler
					data =>
					{
						this.global.goToApp("selected_project", 
						{
							"leed_id": this.appData.get('leed_id'),
							"app_name": "financial-models"
						}, 
						{
							'tab': 'Past Models'
						});
						this.loading = false;
						if ("message" in data)
						{
							this.alertService.alert('success', data['message'], 10);
						}
					},
					//error handler
					error =>
					{
						this.loading = false;
						this.alertService.alert('error', error, 10);
					}
				);
			},
			//error handler
			error =>
			{
				this.loading = true;
				this.alertService.alert('error', error, 10);
			}
		);
	}

	showWarning(rejection: any)
	{
		var error_json: any = false;
		var message = "Your data for the selected reporting period failed our data check. Please go back and check your data and ensure that everything is correct. If you have already validated your data and are sure that the data is correct, you can click proceed anyway.";
		if(rejection.status == 500)
		{
			message = "Server Error. Please try again later.";
		}
		else if(rejection.status == 400)
		{
			if(typeof(rejection.data) == 'string')
			{
				message = rejection.status + ": " + rejection.data;
			}
			else if('message' in rejection.data)
			{
				message = rejection.status + ": " + rejection.data.message;
			}
			else if('error' in rejection.data)
			{
				message = rejection.status + ": " + rejection.data.error;
			}
			else if(rejection.status == 400 && rejection.data.constructor === Array)
			{
				try
				{
					if(rejection.data[0].indexOf('gross_area') > -1)
					{
						message = "Gross area not present for the selected input date."
					}
					else
					{
						error_json = JSON.parse(rejection.data);
						message = error_json.description;
					}
				}
				catch(e)
				{
					message = 'Bad Request: ' + rejection.data[0];
				}
			}
			else if(rejection.status == 400 && rejection.data.constructor === Object)
			{
				message = this.global.capitalFirstLetter(Object.keys(rejection.data)[0].replace("_", " ").replace("-", " ")) + ": " + rejection.data[Object.keys(rejection.data)[0]];
			}
		}
		this.autocase_modal = true;
		this.error_json = error_json;
		this.message = message;
		this.data_coverage = {"performance": {"score": null}};
		this.heading_title = 'Input warning';
		this.modal.open({ windowClass: 'certificate_modal' });
	}

	adjustPeriod()
	{
		(<any>$('#baseline_date')).fdatepicker('show');
		this.cancel();
	};

	goToDI()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": "data-input"
		});
		this.cancel();
	};

	cancel()
	{
		this.modal.close();
	}

	getSubscription()
	{
		this.loading = true;
		var url = '/assets/LEED:'+ this.leed_id +'/subscriptions/performance/';
		this.api.get(url).subscribe(
			data =>
			{
				this.loading = false;
				if(data.status == undefined)
				{
					this.current_status = 'inactive';
				}					
				else if(data.status == 'settled')
				{
					this.current_status = 'active';
				}					
				else if(data.status == 'cancelled_active')
				{
					this.current_status = 'cancelled_active';
				}					
				else if(data.status == 'expired')
				{
					this.current_status = 'expired';
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}
}

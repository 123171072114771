<div class="content-wrap">
	<div id="content" class="content">
        
        <div class="align_lr">
            <h3 class="fw-500 black-color pl32 mb25 pt5" translate>
                Climate Risk Summary
            </h3>
        </div>
        
        <div class="widget ml0 climate_risk_widget"  [ngClass]="loading ? 'not-active' : ''" style="background-color: #F4F5F8 !important;">
            <app-loader *ngIf="loading"></app-loader>
            <div>
                <div *ngIf="current_status == 'active' || current_status == 'expired' || current_status == 'cancelled_active'" class="between-div">
                    <div id="certificate_list" class="w70p widget_mimic mb0">
                        <div class="fs14 mb15">
                            Data for <span class="fw-500">{{global.changeDateFormat(start, 'MMM DD, YYYY')}} through {{global.changeDateFormat(end, 'MMM DD, YYYY')}}</span>
                        </div>
                        <div id="climate_risk">
                            <div class="between-div">
                                <div class="w30p border-right-light">
                                    <div class="fs18 line_height_32 mb10 fw-500">
                                        Transition Risk
                                    </div>
                                    <div class="fs14 line_height_32 mb10 fw-500">
                                        Arc Carbon Score
                                    </div>
                                    <div>
                                        <div class="mb20 flex">
                                            <div class="arc-breadcrumbs {{carbon_color}}">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <span class="re_entry_reading">
                                                {{global.formatNumber(data_coverage.carbon, 0)}}<span class="grey-text">/100</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="w30p border-right-light">
                                    <div class="fs18 line_height_32 mb10 fw-500">
                                        Electrification
                                    </div>
                                    <div class="fs14 line_height_32 mb10 fw-500">
                                        Arc Electricity Score
                                    </div>
                                    <div>
                                        <div class="mb20 flex">
                                            <div class="arc-breadcrumbs {{electrification_color}}">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <span class="re_entry_reading">
                                                {{global.formatNumber(data_coverage.electrification, 0)}}<span class="grey-text">/100</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="w30p">
                                    <div class="fs18 line_height_32 mb10 fw-500">
                                        Physical Risk
                                    </div>
                                    <div class="fs14 line_height_32 mb10 fw-500">
                                        Physical Risk Report
                                    </div>
                                    <div>
                                        <div *ngIf="report_generated == true">
                                            <div class="mb20">
                                                <span class="re_entry_reading">
                                                    <img src="assets/images/done_green_icon.svg" class="w30px ml-5px "> Yes
                                                </span>
                                            </div>
                                            <div class="fs14 line_height_32">
                                                <a (click)="downloadReport()">View Report</a>
                                            </div>
                                        </div>
                                        <div *ngIf="report_generated == false || report_generated == 'inprogress'">
                                            <div class="mb20">
                                                <span class="re_entry_reading">
                                                    <img src="assets/images/notdone_x_icon.svg" class="w30px ml-5px "> No
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="account_upgrade_data account_upgrade_info border-top w30p">
                        <div class="fw-500 fs18 line_height_24 mb10">
                            Notes
                        </div>
                        <div class="gray_color_707479 mb10 fs16">
                            The purchase of the Arc Climate Risk tool provides access to one physical risk report from Moody's ESG Solutions within a one year period, unlimited Climate Risk Performance Certificates and key performance indicators.
                        </div>
                    </div>
                </div>
                
                
                <div *ngIf="current_status == 'inactive' && current_tab == 'Subscription Purchase'">
                    <div class="flex">
                        <div class="w50p" style="background: white;margin-right: 32px;">
                            <div class="cert_banner_img floatNone col-md-12 w100p"></div>
                            <div id="inactive_certificate" class="w100p floatNone">
                                <div class="pb24 border-bottom mb16">
                                    <div class="fs18 line_height_24 fw-500 mb16" translate>Arc Climate Risk</div>
                                    <div class="fs16 line_height_22 mb16" translate>
                                        Understand transition and physical climate risks to spaces and buildings, including GHG emissions, electrification, and site-specific conditions. Climate Risk is a available for a one-time purchase of <span class="fw-500"translate>{{config.currency}}{{global.formatNumberCommas(config.price.climaterisk_price, 0)}} per project</span>. <a href="//arc.gbci.org/pricing" target="_blank" translate>Volume pricing is available</a>.
                                    </div>
                                    <div class="col-md-12 pt20 pb20 pl0" style="float: none;">
                                        <button *ngIf="invoice_generated == false" [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10" (click)="puchaseSubs()" translate>Purchase</button>
        
                                        <div class="fs16 line_height_22" *ngIf="invoice_generated == true" translate>
                                            We have emailed a copy of the invoice for this product with Order ID: <span class="fw-500">{{order_id}}</span> to <span class="fw-500" translate>{{appData.get('user_id')}}</span>. The invoice is also available in the billing page. Please follow the instructions in the invoice to make the payment.
                                            <br><br>
                                            Click <a (click)="downloadInvoice(order_id)">here</a> to download the invoice.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="fs16 line_height_22 mb16" translate>
                                        <span class="fw-500" translate>Arc Climate Risk</span>
                                        <span translate>provides a set of tools to evaluate transition risk, progress toward electrification, and physical risk. Scores and indicators support</span>
                                        <a href="//www.fsb-tcfd.org/recommendations/" target="_blank" translate>recommendations from the Task Force for Climate-Related Financial Disclosure</a> 
                                        <span translate>. Features include:</span> 
                                    </p>
                
                                    <p><span class="fw-500" translate>Arc Climate Risk</span></p>
                                    <ul class="fs16 mb16">
                                        <li translate>Arc Carbon Score</li>
                                        <li translate>Arc Electrification Score</li>
                                    </ul>
                                    <p><span class="fw-500" translate>Key Performance Indicators</span></p>
                                    <ul class="fs16 mb16">
                                        <li translate>GHG emissions</li>
                                        <li translate>Energy use intensity</li>
                                        <li translate>Electricity consumption</li>
                                    </ul>
                                    <p><span class="fw-500" translate>Physical Climate Risk Assessments*</span></p>
                                    <ul class="fs16 mb16">
                                        <li translate>
                                            Moody’s ESG Solutions 
                                            <span class="fs13">
                                                (Sample report: 
                                                <a (click)="global.downloadPDF('Risk Report.pdf')" translate>
                                                    PDF
                                                </a>,<a (click)="global.downloadXLS('1000000117 - Arc physical risk report - Powered by Moody\'s ESG Solutions.xlsx')" translate>
                                                    Excel
                                                </a>)
                                            </span>
                                        </li>
                                    </ul>
                                    <p><span class="fw-500" translate>Purchase includes:.</span></p>
                                    <ul class="fs16 mb16">
                                        <li translate>Performance scores for transition risk and electrification</li>
                                        <li translate>Climate Risk Performance Certificate</li>
                                        <li translate>Climate Risk Report</li>
                                        <li translate>Physical Climate Risk Assessment (once per year)</li>
                                    </ul>
                                    <p>
                                        *<span class="gray_color_68737D fs13">
                                            <i translate>
                                                Arc Climate Risk provides users with their choice of one site risk assessment during a 12-month period. Moody's ESG Solutions assessments are available globally. 
                                            </i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="account_upgrade_info w30p mb15">
                            <div>
                                <div class="account_upgrade_data border-top">
                                    <div class="fw-500 fs18 line_height_24 mb10" translate>
                                        Notes
                                    </div>
                                    <div class="gray_color_707479 mb10 fs16" translate>
                                        The purchase of the Arc Climate Risk tool provides access to one physical risk report from Moody's ESG Solutions within a one year period, unlimited Climate Risk Performance Certificates and key performance indicators.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="current_status == 'active' || current_status == 'expired' || current_status == 'cancelled_active'" id="tools">
                    <h3 class="fw-500 black-color mb25 pt5">
                        Tools
                    </h3>
                    <div class="between-div">
                        <div class="w70p widget_mimic mb0 pb0">
                            <div class="border-bottom mb30">
                                <div class="fw-500 fs18 line_height_24 mb10">
                                    Assess Physical Risk
                                </div>
                                <div *ngIf="report_generated == 'inprogress'" class="fs16 line_height_24 mb16">
                                    This project's report request was submitted on {{report_doc.created_at == null ? global.dateFormat() : report_doc.created_at}}
                                </div>
                                <div *ngIf="report_generated == true" class="fs16 line_height_24 mb16">
                                    This project's report was generated on {{getCreatedAt()}}
                                </div>
                                <div *ngIf="report_generated == false" class="flex">
                
                                    <div class="w50p">
                                        <div class="fs16 line_height_24 fw-500">
                                            Moody’s ESG Solutions
                                        </div>
                                        <div class="fs16 line_height_24 mb16">
                                            Physical risk information for projects located anywhere in the world. Available as a PDF report or an Excel spreadsheet download.
                                        </div>
                                        <button [ngClass]="config.access == 'R' ? 'not-active' : ''" (click)="createPhysicalReport()" class="btn btn-primary fs15 fw-500 line_height_24 mb16">
                                            Create a report
                                        </button>
                                        <!-- <div class="mb30">
                                            <a>Learn More</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="account_upgrade_info w30p mb15">
                            <div>
                                <div class="account_upgrade_data border-top">
                                    <div class="fw-500 fs18 line_height_24 mb10">
                                        Notes
                                    </div>
                                    <div class="gray_color_707479 mb10 fs16">
                                        The Arc Climate Risk tool includes one physical risk report to be created within a 12 month period. Additional reports are available for purchase at <a href="mailto:contact@arcskoru.com">contact@arcskoru.com</a>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="between-div">
                        <div class="w70p widget_mimic pt0">
                            <div class="border-bottom mb30">
                                <div class="fw-500 fs18 line_height_24 mb10">Recognize and Communicate Performance</div>
                                <div class="fs16 line_height_24 fw-500">
                                    Climate Risk Performance Certificate
                                </div>
                                <div class="fs16 line_height_24 mb16">
                                    This tool creates a one-page summary of climate risk metrics, including transition (carbon score), electrification (electricity score), and the presence of a physical risk report. This certificate may support disclosures recommended by the Taskforce for Climate-Related Financial Disclosures.
                                </div>
                                <button [ngClass]="config.access == 'R' ? 'not-active' : ''" (click)="goToCertificate('climaterisk', 'Climate Risk')" class="btn btn-primary fs15 fw-500 line_height_24 mb30">
                                    Create a Certificate
                                </button>
                            </div>
                            <div class="">
                                <div class="fw-500 fs18 line_height_24 mb10">Export Performance Data</div>
                                <div class="fs16 line_height_24 fw-500">
                                    Download Data to Excel Spreadsheet
                                </div>
                                <div class="fs16 line_height_24 mb16">
                                    This tools provides performance data available for this reporting period. Data are provided as an Excel spreadsheet.
                                </div>
                                <button (click)="downloadData()" class="btn btn-primary fs15 fw-500 line_height_24 mb30">
                                    Download Data
                                </button>
                            </div>
                        </div>
                        <div class="account_upgrade_info w30p mb15" style="margin-top: -30px;">
                            <div>
                                <div class="account_upgrade_data border-top">
                                    <div class="fw-500 fs18 line_height_24 mb10">
                                        Notes
                                    </div>
                                    <div class="gray_color_707479 mb10 fs16">
                                        Information presented in Arc Climate Risk certificate reflect user provided data. Arc does not make any representations about its accuracy or suitability for any specific action or analysis.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="current_status == 'active' || current_status == 'expired' || current_status == 'cancelled_active'" id="cr_kpi">
                    <h3 class="fw-500 black-color mb25 pt5">
                        Key Performance Indicators
                    </h3>
                    <div ng-init="loadManual()" class="widget_container widget_mimic ml0 w70p">
                        <app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaverageemissions'" [param]="null" [width]="'100%'" [height]="'395px'"></app-widget>
                        <div class="border-bottom"></div>
                        <app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=carbon_emission'" [width]="'100%'" [height]="'395px'"></app-widget>
                        <div class="border-bottom"></div>
                        <app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=source_energy'" [width]="'100%'" [height]="'395px'"></app-widget>
                        <div class="border-bottom"></div>
                        <app-widget [leed_id]="appData.get('leed_id')" [src]="'arcaveragecategoryusage'" [param]="'&type=electricity&intensity=energy'" [width]="'100%'" [height]="'395px'"></app-widget>
                    </div>
                </div>
        
                <div id="past_certificates">
                    <h3 class="fw-500 black-color mb25 pt5" translate>
                        Past Reports & Certificates
                    </h3>
                    <div class="widget_mimic w70p">
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th class="cursor-pointer unselectable" (click)="global.sortTable(0, 'date_created', reports)" translate>
                                        Date Requested
                                        <span *ngIf="config.sort_col == 0" class="icon-caret-{{config.sort_order == 'desc' ? 'down': 'up'}}"></span>
                                    </th>
                                    <th translate>
                                        Type
                                    </th>
                                    <th translate>
                                        Status
                                    </th>
                                    <th translate>
                                        Download Report
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="feeds.foreign_id == 'physicalrisk_report'">
                                    <td class="vertical-align-middle">
                                        <span>{{global.dateFormat()}}</span>
                                    </td>
                                    <td class="vertical-align-middle">
                                        <span>Physical Risk Report</span>
                                    </td>
                                    <td  class="vertical-align-middle">
                                        In Progress
                                    </td>
                                    <td class="vertical-align-middle">
                                        N/A
                                    </td>
                                    <td class="vertical-align-middle">
                                        N/A
                                    </td>
                                </tr>
                                <tr *ngFor="let obj of reports; let idx = index">
                                    <td class="vertical-align-middle">
                                        {{obj.created_at}}
                                    </td>
                                    <td class="vertical-align-middle">
                                        <svg *ngIf="obj.file_type == 'pdf'" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-pdf" class="svg-inline--fa fa-file-pdf fa-w-12 pdf_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"></path></svg>
                                        <svg *ngIf="obj.file_type == 'excel'" aria-hidden="true" focusable="false" data-prefix="far" data-icon="file-excel" class="svg-inline--fa fa-file-excel fa-w-12 pdf_icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"></path></svg>
                                        <span>{{obj.type}}</span>
                                    </td>
                                    <td  class="vertical-align-middle">
                                        {{obj.status}}
                                    </td>
                                    <td *ngIf="obj.document == null" class="vertical-align-middle">
                                        N/A
                                    </td>
                                    <td *ngIf="obj.document != null" class="vertical-align-middle">
                                        <div title="{{global.getFileName(obj.document)}}" class="fs13 gray_color_707479 ellipsis cursor-pointer">{{global.getFileName(obj.document)}}</div><br>
                                        <a (click)="downloadCert(obj.document.id, global.getFileName(obj.document), obj.type)">Download</a>
                                        <div *ngIf="idx == reports.length - 1" ng-init="tippyClassInit('ellipsis')"></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="climate_risk_reports.length == 0 && past_certificates.length == 0 && feeds.foreign_id != 'physicalrisk_report'">
                                <tr>
                                    <td colspan="5" class="noProjects" translate>
                                        No reports/certificates created.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<modal #modal>
    <div class="p2535">

        <div class="left-right-align">
            <div class="flex fontSize18 mb25 fw-600 error-color">
                <span class="error_symbol_trial"></span>
                <span class="error-lg-msg"> Subscription Expired!</span>
            </div> 
        </div>
    
        <p class="mb20 fontSize16 fw-400">To create new certificates you need to renew your Arc Performance Certificates Subscription</p>
        <p class="mb20 fontSize16 fw-400">
            <a>View Arc Performance Certificates Subscription pricing details</a>
        </p>
    
        <div class="mt30 pb50">
            <button (click)="renewSubs();modal.close()" type="button" class="btn btn-primary btn-md floatl">Renew Subscription</button>
            <button (click)="modal.close()" ng-click="cancel()" type="button" class="btn grey btn-default btn-md floatr">Close</button>
        </div>
    </div>
</modal>

<modal #crate_report_modal>
    <div [ngClass]="loading ? 'not-active' : ''" class="p1632 semi-md">
        <app-loader *ngIf="loading"></app-loader>
        <div *ngIf="!report_sent">
            <div class="left-right-align">
                <div class="fs24 line_height_40">
                    <span class="fw-500">{{header}}</span>
                    <div class="w100p line_height_24 manage_note pl0 mb30">
                        NOTE: To change the address, please <a href="mailto:contact@arcskoru.com">contact us</a>. If the ownership of the project has changed, please fill our change of owner <a href="http://www.gbci.org/sites/default/files/change-of-owner.pdf" target="_blank">form</a> and send it along with your email.
                    </div>
                </div> 
            </div>
            
            <div class="fs16 line_height_24 fw-500">
                Confirm project location:
            </div>
            <div class="fs16 line_height_24 mb30">
                {{appData.get('buildingData').street}}<br>
                {{appData.get('buildingData').city}}, {{global.getState(appData.get('buildingData').state, appData.get('buildingData').country)}}, {{appData.get('buildingData').zip_code}}<br>
                {{global.getCountry(appData.get('buildingData').country)}}
            </div>
        
            <div class="checkbox form-group mb16">
                <input type="checkbox" class="cursor-pointer" [(ngModel)]="agreement">
                <label for="checkbox">
                    <span *ngIf="type == 'coastal_risk'">I agree with <a (click)="global.downloadPDF('CRCTermsConditionsV5031221.pdf')">RiskFootprint Terms of Service</a>.</span>
                    <span *ngIf="type == '427'">I agree with <a (click)="global.showHelpText('cr_disclaimer')">Moody’s ESG Solution's Terms of Service</a></span>
                </label>
            </div>
        
            <div class="">
                <button [ngClass]="!agreement ? 'not-active' : ''" (click)="createReport()" data-dismiss="modal" type="button" class="btn btn-primary btn-md mr25">Create</button>
                <button (click)="cancel()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
            </div>
        </div>
        <div *ngIf="report_sent">
            <div class="left-right-align mb30">
                <div class="fs24 line_height_40">
                    <span class="fw-500">{{header}}</span>
                </div> 
            </div>
            <div *ngIf="type == '427'" class="fs16 line_height_24 mb30">
                Physical Risk Report generated. Please download it from 'Past Reports & Certificates' section.
            </div>
            <div *ngIf="type == 'coastal_risk'" class="fs16 line_height_24 mb30">
                We have received your request and the report will be available in 48 hours. You will receive an email once the report is available.
            </div>
            <div class="">
                <button (click)="cancel()" type="button" class="btn grey btn-default btn-md">Close</button>
            </div>
        </div>
    </div>
</modal>
<div class="mr15 ml15">
	<div>
        
        <div [ngClass]="loading ? 'not-active' : ''">
            <app-loader *ngIf="loading"></app-loader>
        
            <!-- <h3 class="fw-500 line_height_32 mt24 mb24">Insight</h3> -->
        
            <div class="align_lr mb32">
                <div class="insight_home_card mr15">
                    <div class="insight_market_banner banner fw-500">
                        Markets
                    </div>
                    <div *ngIf="user_role != 'arc_pro' && !appData.get('enable_insights')" class="insight_card_content" id="details-form">
                        <div class="line_height_24 fw-500 fs18 mb16">Arc Insight Markets</div>
        
                        <div class="fs16 mb16">
                            Analyze and score the real world performance of spaces, buildings and places.
                        </div>
                        <button (click)="global.learnMore('arc_pro')" class="fs16 btn btn-primary inverse">
                            Learn more
                        </button>
                    </div>
                    <div *ngIf="user_role == 'arc_pro' || appData.get('enable_insights')" class="insight_card_content" id="details-form">
                        <div class="line_height_24 fw-500 fs18 mb16">Select a market:</div>
        
                        <div class="width-100-perc">
                            <div class="w50p select-group group pb0">
                                Enter an address
                            </div>
            
                            <div class="mb20 flex">
                                <div class="input_item w75p">
                                    <div class="dropdown zipcode">
                                        <input (input)="searchZipCode(zipcode_input)" [(ngModel)]="zipcode_input" placeholder="Enter address" class="dropdown-toggle dropdown-toggle-it insight_input" type="text" (focus)="showDropdown()" (blur)="hideDropdown()" autocomplete="off">
                                        <div>
                                            <svg *ngIf="!searching_zip" class="search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                        </div>
                                        <ul class="dropdown-menu normal dropdown-menu-fixed">
                                            <li (click)="changeZipCode(zipcode)" *ngFor="let zipcode of zipcode_list" class="water_unit">
                                                <a>{{zipcode}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="gray_color_707479 fs13" style="position: relative;padding-top: 11px;padding-left: 25px;" *ngIf="searching_zip">
                                    <svg style="height: 20px;width: 20px;top: 10px;" class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg> Searching...
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w50p">
                                <div class="select-group group pb0 mb10">
                                    Select range in mile
                                </div>
                                <div class="dropdown mb20 input_item">
                                    <button title="Range in mile" class="dropdown-toggle dropdown-toggle-it btn-default" type="button" data-toggle="dropdown">
                                        <span>{{range_in_mile_input}} {{range_in_mile_input == 1 ? 'Mile' : 'Miles'}}</span>
                                        <i class="toggle fa fa-angle-down"></i>
                                    </button>
                                    <ul class="dropdown-menu left dropdown-menu-fixed">
                                        <li (click)="changeRange(value)" *ngFor="let value of range_list" class="water_unit">
                                            <a>{{value}} {{value == 1 ? 'Mile' : 'Miles'}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="w50p project_type_wrapper">
                                <div class="select-group group pb0 mb10">
                                    Select project type
                                </div>
                                <div class="input_item add-dropdown-toggle">
                                    <select [(ngModel)]="project_types_input" id="project_type" title="Project Types" class="selectpicker" multiple data-live-search="true">
                                        <option *ngFor="let type of project_type_list">
                                            {{type}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
        
                        <button (click)="goToSearch()" class="btn btn-primary fs16 fw-500 line_height_24 w100px">Search</button>
                    </div>
                </div>
        
                <div class="insight_home_card">
                    <div class="insight_model_banner banner">
                        <div class="mb16">Models</div>
                        <div>{{assets.count}}</div>
                    </div>
                    <div *ngIf="user_role != 'arc_pro' && !appData.get('enable_insights')" class="insight_card_content" id="details-form">
                        <div class="line_height_24 fw-500 fs18 mb16">Arc Insight Models</div>
        
                        <div class="fs16 mb16">
                            Compare sustainability and see how this project stacks up against the performance of other buildings.
                        </div>
                        <button (click)="global.learnMore('arc_pro')" class="fs16 btn btn-primary inverse">
                            Learn more
                        </button>
                    </div>
                    <div *ngIf="assets.count == 0 && (user_role == 'arc_pro' || appData.get('enable_insights'))" class="insight_card_content">
                        <div class="line_height_24 fw-500 fs18 mb16">Arc Insight Models</div>
                        <div class="fs16 line_height_22 mb16">
                            Compare sustainability strategies and see how this project stacks up against the performance of other buildings.
                        </div>
                        <button (click)="createModel()" class="btn btn-primary inverse">
                            Create your first model
                        </button>
                    </div>
                    <div *ngIf="assets.count > 0 && (user_role == 'arc_pro' || appData.get('enable_insights'))" class="insight_card_content">
                        <div class="line_height_24 fs18 fw-500 mb5">Recent models</div>
                        <div (click)="goToModel('project_reduction', {'project_id': project.leed_id, 'project': project, 'selected_version': project.versions[0], 'version': project.versions[0].version_id})" class="model_card" *ngFor="let project of assets.results">
                            <i class="fa mr8 fa-copy"></i>
                            {{project.name}}
                        </div>
                        <div class="align-right pt15">
                            <span (click)="filterAccessInsights('model')" class="cursor-pointer fs14 line_height_24 blue-color-1775D2 fw-500">See all models</span>
                            <svg width="18" height="18" viewBox="0 0 24 24" style="fill: #1775D2;top: 5px;position: relative;">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        
            <h3 class="fw-500 line_height_32 mb16 grey_color mt0">Explore Markets</h3>
        
            <div class="mb55">
                <div (click)="downloadPDF('Washington.pdf')" class="insight_market">
                    <div class="market_image wdc"></div>
                    <div class="market_meta">
                        <div class="blue_0071BB fs15 line_height_24 mb8 mt8">USA office buildings</div>
                        <div class="fw-500 fs18 line_height_24 mb24">Washington D.C., United States</div>
                    </div>
                </div>
                
                <div (click)="downloadPDF('Ohio.pdf')" class="insight_market">
                    <div class="market_image ohio"></div>
                    <div class="market_meta">
                        <div class="blue_0071BB fs15 line_height_24 mb8 mt8">Primary schools</div>
                        <div class="fw-500 fs18 line_height_24 mb24">State of Ohio, United States</div>
                    </div>
                </div>
                
                <div (click)="downloadPDF('Santa Barbara.pdf')" class="insight_market">
                    <div class="market_image california"></div>
                    <div class="market_meta">
                        <div class="blue_0071BB fs15 line_height_24 mb8 mt8">CA higher education</div>
                        <div class="fw-500 fs18 line_height_24 mb24">Santa Barbara, United States</div>
                    </div>
                </div>
                
                <!-- <div (click)="downloadPDF(3)" class="insight_market">
                    <div class="market_image stroke"></div>
                    <div class="market_meta">
                        <div class="blue_0071BB fs15 line_height_24 mb8 mt8">Storke Tower</div>
                        <div class="fw-500 fs18 line_height_24 mb24">California, United States</div>
                    </div>
                </div> -->
            </div>
        </div>
        

    </div>
</div>

<div class="mr15 ml15">
	<div>
        
        <div>
            <div [ngClass]="loading_data ? 'not-active' : ''">
                <div class="input_param_header">
                    <div class="input_item w25p">
                        <div class="dropdown zipcode">
                            <input (input)="searchZipCode(zipcode_input)" [(ngModel)]="zipcode_input" placeholder="Enter zipcode" class="dropdown-toggle dropdown-toggle-it insight_input" type="text" (focus)="showDropdown()" (blur)="hideDropdown()" autocomplete="off">
                            <div>
                                <svg *ngIf="searching_zip" class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>
                                <svg *ngIf="!searching_zip" class="search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                            </div>
                            <ul class="dropdown-menu normal dropdown-menu-fixed">
                                <li (click)="changeZipCode(zipcode)" *ngFor=" let zipcode of zipcode_list" class="water_unit">
                                    <a>{{zipcode}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="input_item">
                        <div class="dropdown">
                            <button title="Range of mile" class="dropdown-toggle dropdown-toggle-it btn-default" type="button" data-toggle="dropdown">
                                <span>{{range_in_mile_input}} {{range_in_mile_input == 1 ? 'Mile' : 'Miles'}}</span>
                                <i class="toggle fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu left dropdown-menu-fixed">
                                <li (click)="changeRange(value)" *ngFor=" let value of range_list" class="water_unit">
                                    <a>{{value}} {{value == 1 ? 'Mile' : 'Miles'}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="project_type_wrapper input_item add-dropdown-toggle">
                        <select (input)="projectTypeChange()" [(ngModel)]="project_types_input" id="project_type" title="Project Types" class="selectpicker" multiple data-live-search="true">
                            <option *ngFor=" let type of project_type_list">
                                {{type}}
                            </option>
                        </select>
                    </div>

                    <div (click)="searchData(range_in_mile_input, zipcode_input, project_types_input)" [ngClass]="disableSearch() ? 'not-active btn-default' : 'btn-primary'" id="search_overview" class="ml20 btn flex">
                        <svg class="search-icon" width="14" height="14" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                        <span>Search</span>
                    </div>

                    <div (click)="exportInsight(range_in_mile_input, zipcode_input)" id="search_overview" class="ml20 btn btn-default flex">
                        <i style="width: 15px;height: 18px;" class="mr10 pt5 fa fa-download"></i>
                        <span>Export</span>
                    </div>
                    
                </div>

                <div class="map_and_projects">
                    <app-loader *ngIf="loading_data"></app-loader>
                    <div class="world_map_wrapper">
                        <div id="world_map"></div>
                    </div>
                    <div class="project_metrics">
                        <div class="project_metrics_header">
                            <div (click)="toggleMetrics('Projects')" [ngClass]="metric_tab == 'Projects' ? 'active' : ''">Projects</div>
                            <div (click)="toggleMetrics('Metrics')" [ngClass]="metric_tab == 'Metrics' ? 'active' : ''">Metrics</div>
                            <div (click)="toggleMetrics('Strategies')" [ngClass]="metric_tab == 'Strategies' ? 'active' : ''">Strategies</div>
                        </div>

                        <div *ngIf="metric_tab == 'Metrics' || metric_tab == 'Strategies'" class="metric_info_header">
                            <span class="fw-500">{{insight_data.reduction.zepi_list.length}} Projects</span> within <span>{{range_in_mile}} {{range_in_mile == 1 ? 'Mile' : 'Miles'}}</span> of {{zipcode}}
                        </div>

                        <div *ngIf="metric_tab == 'Projects'">
                            <div class="projects_meta flex">
                                <div class="basic" style="width: 33%;">
                                    <h1 class="fw-500 mt0 fs40">{{insight_data.reduction.zepi_list.length}}</h1>
                                    <div class="fw-500 fontSize15">Projects</div>
                                    <div class="fontSize15">within {{range_in_mile}} {{range_in_mile == 1 ? 'mile' : 'miles'}} of {{zipcode}}</div>
                                </div>
                                <div class="chart" style="width: 29%;">
                                    <div id="piechart" style="width: 150px; height: 150px;"></div>
                                </div>
                                <div class="cert_meta" style="width: 50%;">
                                    <div class="cert_item" *ngFor=" let cert of project_certs">
                                        <div *ngIf="cert[0] != 'Certification'">
                                            <div>
                                                <div class="ui label {{cert[0]}}"></div>
                                                <span class="fw-500" style="padding-left: 23px;">{{cert[0]}}</span>
                                            </div>
                                            <span class="abs_r">
                                                <span class="fw-500">{{cert[1]}}</span>
                                                <span class="grey_color">({{global.formatNumber((cert[1] / insight_data.reduction.zepi_list.length) * 100, 0)}} %)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="insight_data.reduction.zepi_list.length > 0" class="project_list">
                                <div>
                                    <table class="table table-striped arc-table arc-table-insight">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>zEPI <br> Score</th>
                                                <th>Latest <br> Certification</th>
                                                <th>Points</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor-pointer" *ngFor=" let project of project_list">
                                                <td class="fw-500">
                                                    <span class="ellipsis">{{project.name}}</span>
                                                </td>
                                                <td>
                                                    <span class="ellipsis_120">{{project.clean_space_type}}</span>
                                                </td>
                                                <td>{{global.formatNumber(project.zepi_score, 0)}}</td>
                                                <td>
                                                    <span class="project_label {{project.certification_level.toLowerCase()}}">
                                                        <span>LEED {{project.certification_level}}</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <div class="smallScorepuck ">
                                                        <span>
                                                            {{global.formatNumber(project.points_awarded, 0)}}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>				    
                                        </tbody>
                                    </table>
                                </div>
            
                                <div class="align_r list_controls fw-500 mb20" *ngIf="insight_data.reduction.zepi_list.length > 0">
                                    <div class="dropdown display-inline ml10">
                                        <span class="mr10">Rows per page:</span>
                                        <button class="btn dropdown-clean btn-default btn-sm" type="button" data-toggle="dropdown" >
                                            <span>{{page_size}}</span>
                                            <i class="fa fontSize16 ml10 fa-caret-down"></i>
                                        </button>
                                        <ul class="dropdown-menu" style="top: -122px;">
                                            <li (click)="paginateArray(insight_data.reduction.zepi_list, 10, 1)">
                                                <a>10</a>
                                            </li>
                                            <li (click)="paginateArray(insight_data.reduction.zepi_list, 20, 1)">
                                                <a>20</a>
                                            </li>
                                            <li (click)="paginateArray(insight_data.reduction.zepi_list, 30, 1)">
                                                <a>30</a>
                                            </li>
                                            <li (click)="paginateArray(insight_data.reduction.zepi_list, 40, 1)">
                                                <a>40</a>
                                            </li>
                                            <li (click)="paginateArray(insight_data.reduction.zepi_list, 50, 1)">
                                                <a>50</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex pt2 ml40">
                                        <span class="mr30">
                                            Page {{page_number}} of {{pages}}
                                        </span>
                                        <div class="flex">
                                            <div class="cursor-pointer" [ngClass]="(page_number - 1) == 0 ? 'not-active' : ''" (click)="paginateArray(insight_data.reduction.zepi_list, page_size, page_number - 1)">
                                                <i class="mr40 fa fa-chevron-left"></i>
                                            </div>
                                            <div class="cursor-pointer" [ngClass]="page_number == pages ? 'not-active' : ''" (click)="paginateArray(insight_data.reduction.zepi_list, page_size, page_number + 1)">
                                                <i class="fa fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="!(metric_tab == 'Metrics')">
                            <div class="metric_overview">
                                <div class="metric_item">
                                    <div class="metric_type">
                                        Average zEPI Score
                                    </div>
                                    <div class="metric_value">
                                        {{global.formatNumber(insight_data.reduction.energy.meta.average, 0)}}
                                    </div>
                                    <div class="metric_label">
                                        zEPI Score
                                    </div>
                                </div>
                                <div class="metric_item pl25">
                                    <div class="metric_type">
                                        Average Water reduction
                                    </div>
                                    <div class="metric_value">
                                        {{global.formatNumber(insight_data.reduction.water.meta.average, 0)}}%
                                    </div>
                                    <div class="metric_label">
                                        Reduction below EPAct 1992
                                    </div>
                                </div>
                                <div class="metric_item pl25">
                                    <div class="metric_type">
                                        Average Waste diversion
                                    </div>
                                    <div class="metric_value">
                                        {{global.formatNumber(insight_data.reduction.waste.meta.average, 0)}}%
                                    </div>
                                    <div class="metric_label">
                                        Landfill waste diversion
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="insight_data.reduction.zepi_list.length == 0" class="energy_chart">
                                <div class="fw-500 fs18 mb30">Number of projects by zEPI score</div>
                                <span class="grey_color fontSize13">Projects</span>
                                <div id="zepi_chart"></div>
                                <div class="zepi_labels">
                                    <div class="zepi_gradient"></div>
                                    <div class="zepi_gradient_100 zepi_gradient_label">
                                        <p>|</p>
                                        Avg. performance of U.S. <br> building stock of 2000
                                    </div>
                                    <div class="zepi_gradient_60 zepi_gradient_label">
                                        <p>|</p>
                                        ASHRAE <br> 90.1 2010
                                    </div>
                                    <div class="zepi_gradient_50 zepi_gradient_label">
                                        <p>|</p>
                                        LEED v4 Prerequisite
                                    </div>
                                    <div class="zepi_gradient_0 zepi_gradient_label">
                                        <p>|</p>
                                        Net Zero Energy
                                    </div>
                                </div>
                                <div class="mt90 align-center mb40 fw-500">zEPI score</div>
                            </div>
                            
                            <div *ngIf="insight_data.reduction.zepi_list.length > 0" class="water_chart">
                                <div class="fw-500 fs18 mb30">Number of projects by percent of reduction (Below EPAct 1992)</div>
                                <span class="grey_color fontSize13">Projects</span>
                                <div id="water_reduction_chart"></div>
                                <div class="mt10 align-center mb40 fw-500">Percent of reduction (Below EPAct 1992)</div>
                            </div>

                            <div *ngIf="insight_data.reduction.zepi_list.length > 0" class="water_chart">
                                <div class="fw-500 fs18 mb30">Number of projects by waste diverted from landfill</div>
                                <span class="grey_color fontSize13">Projects</span>
                                <div id="waste_diversion_chart"></div>
                                <div class="mt10 align-center mb40 fw-500">Percent of waste diverted from landfill</div>
                            </div>
                        </div>

                        <div *ngIf="metric_tab == 'Strategies'">
                           <div *ngIf="insight_data.reduction.zepi_list.length > 0">
                                <div class="fw-500 fs18 mt16 mb16">LEED Strategies</div>

                                <!-- <div *ngFor=" let (credit_id, credit_value) of insight_data.strategies" class="mb32"> -->
                                <div *ngFor=" let obj of insight_data.strategies | keys" class="mb32">
                                    <div class="mb10">
                                        <img style="width: 32px;height: 32px;" src="../../../assets/images/icons/leed/{{obj.value.short_id}}-border.png"/>
                                        <span class="fontSize16 fw-500 ml10">{{obj.value.name}}</span>
                                        <span class="floatr">Achieved by (%)</span>
                                    </div>

                                    <div class="credit_row" *ngFor=" let credit of obj.value.data">
                                        <div class="credit_name">
                                            <span *ngIf="checkPreq(credit)" class="fw-500">Prerequisite -</span> {{credit.name}}
                                        </div>
                                        <div class="credit_value fw-500">
                                            <div class="mini_progress_bar">
                                                <div [ngStyle]="global.getCSS('width', credit.count, '%')" class="credits_achieved {{obj.key}}"></div>
                                            </div>
                                            <div class="w35px" *ngIf="!checkPreq(credit)">{{global.formatNumber(credit.count, 2).replace(".00", '')}}%</div>
                                            <div class="w35px" *ngIf="checkPreq(credit)">100%</div>
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-insight-model',
  templateUrl: './insight-model.component.html',
  styleUrls: ['./insight-model.component.css']
})
export class InsightModelComponent implements OnInit
{
	assets: any = {
		"results": []
	};
	loading: boolean = false;
	page: number = 1;
    page_size: number = 100;

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		this.getAssets(this.page, this.page_size);
	}

	exportProjects()
	{
		this.loading = true;
		var url = Config.insight_url + '/project/export/';
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = ()=>
		{
			var a;
			if (xhttp.readyState === 4 && xhttp.status === 200) {
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(xhttp.response, "Models List.xlsx");
					this.loading = false;
				}
				else
				{
					a = document.createElement('a');
					a.href = window.URL.createObjectURL(xhttp.response);
					a.download = "Models List.xlsx";
					a.style.display = 'none';
					document.body.appendChild(a);
					this.loading = false;
					return a.click();
				}
			}
		};
		xhttp.open("GET", url, true);
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", Config.it_subscription_key);
		xhttp.setRequestHeader("Authorization",  "Bearer " + this.appData.get('insight_session').authorization_token);
		xhttp.responseType = 'blob';
		xhttp.send();
	}

	showVersions(project: any)
	{
		if(project['show_versions'])
		{
			project['show_versions'] = false;
			return;
		}
		project['show_versions'] = true;
	}

	getAssets(page: number, page_size: number)
	{
		this.loading = true;
		this.insight.getAssets(page, page_size).subscribe(
			data =>
			{
				this.loading = false;
				this.assets = data;
			},
			error =>
			{
				this.loading = false;
			}
		);
	};

	goToModel(app: any, payload: any)
	{
		this.appData.set('leed_id_insight', payload.project.leed_id);
		this.appData.set('buildingData_insight', payload.project);
		this.appData.set('selected_version', payload.selected_version);
		this.appData.set('version', payload.version);
		this.appData.set('cs_fullname_insight', this.global.getState(payload.project.state, payload.project.country) + ", " + this.global.getCountry(payload.project.country));
		// this.global.goToApp('selected_model',
		// {
		// 	"app_name": "reduction",
		// 	"leed_id": payload.project.leed_id,
		// });
		this.appData.set('insight_app', 'reduction');
	}

}

<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold visible-xs-inline" File Management Tools>Manage</span>
            <span class="fw-semi-bold" translate>Billing</span>
        </h1>
        <a *ngIf="appData.get('buildingData').country == 'IN'" [href]="global.getGBCIDocsUrl()" target='_blank' class="pull-right fw600 mr32" style="margin-top: -40px;">
            <i class="icon-download-alt"></i> Download GBCI India documents
        </a>
        <div [ngClass]="loading_integrations || loading ? 'not-active' : ''">
            <div class="row" id="billings-page">
            <!-- <div class="row" id="billings-page"> -->
                <div>
                    <section class="widget">
                        <app-loader *ngIf="loading_integrations || loading"></app-loader>
                        <ul class="meterNav">
                            <li class="meterNav--item" (click)="toggleTab('history')">
                                <span [ngClass]="current_tab == 'history' ? 'meterNav--item__active' : ''">Payment History</span>
                            </li>
                            <li class="meterNav--item" (click)="toggleTab('purchase')" *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type') == 'school')">
                                <span [ngClass]="current_tab == 'purchase' ? 'meterNav--item__active' : ''">Make a Purchase</span>
                            </li>
                        </ul>
                        <div *ngIf="current_tab == 'history'">
                            <table class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th class="w15p" translate>Date</th>
                                        <th class="w15p" translate>Order ID</th>
                                        <th class="w15p" translate>Order Type</th>
                                        <th class="w15p" translate>Total</th>
                                        <th class="w20p" translate>Status</th>
                                        <th class="align-center hidden-xs w20p" translate>Invoice / Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payments of mypayments" [hidden]="payments.OrderType == ''">
                                        <td class="vertical-align-middle"><span>{{global.dateFormat(payments.Erdat)}}</span>
                                        </td>
                                        <td class="vertical-align-middle"><span>{{payments.Vbeln}}</span>
                                        </td>
                                        <td class="vertical-align-middle"> 
                                            <span *ngIf="payments.OrderType == 'MEASURABL'">{{payments.OrderType}}</span> 
                                            <span *ngIf="payments.OrderType == 'REGISTRATION'">REGISTRATION</span> 
                                            <span *ngIf="payments.OrderType == 'REVIEW' || payments.OrderType == 'SCORECARD' || payments.OrderType == 'PERFORMANCE' || payments.OrderType == 'PERFORMANC'">REVIEW</span> 
                                            <span *ngIf="payments.OrderType == 'RISKFTPRINT' || payments.OrderType == 'RISKFOOTPRINT' || payments.OrderType == 'climaterisk'">RiskFootprint</span> 
                                            <span *ngIf="payments.OrderType == 'SUBSCRIPTION' || payments.OrderType == 'PLAQUE' || payments.OrderType == 'LEASE' || payments.OrderType == 'SUBSPAIDFULL' || payments.OrderType == 'PAIDFULL' || payments.OrderType == 'Subscription'">SUBSCRIPTION</span>
                                            <p class="help-block" *ngIf="(payments.OrderType == 'REVIEW' || payments.OrderType == 'SCORECARD' || payments.OrderType == 'PERFORMANCE' || payments.OrderType == 'PERFORMANC') && payments.certification_type != '' && payments.certification_type != payments.review_type">{{payments.certification_type}} : {{payments.review_type}}</p>
                                            <p class="help-block" *ngIf="(payments.OrderType == 'REVIEW' || payments.OrderType == 'SCORECARD' || payments.OrderType == 'PERFORMANCE' || payments.OrderType == 'PERFORMANC') && payments.certification_type != '' && payments.certification_type == payments.review_type">{{payments.certification_type}}</p>
                                        </td>
                                        <td class="vertical-align-middle"><span>{{getTotalPriceAndTaxes(payments.Netwr, payments.Currency)}}</span>
                                        </td>
                                        <td class="vertical-align-middle" [ngClass]="config.access == 'R' ? 'not-active' : ''">
                                            <span title="{{checkTooltipStatus(payments.OrderStatus)}}" class="text-capitalize" [ngClass]="{'danger-color': payments.OrderStatus != 'Payment Cleared'}">{{checkStatus(payments.OrderStatus)}}</span>
                                            <span *ngIf="payments.OrderStatus == 'Payment Pending' && IsArcOrder(payments)">
                                                <button data-tooltip="Pay now using credit card" (click)="payNow(payments)" class="btn btn-default ml10" *ngIf="payments.Currency=='USD'"><i class="fa fa-credit-card" aria-hidden="true"></i> Pay now</button>
        
                                                <button data-tooltip="Pay now using net banking" (click)="payNowCcAvenue(payments.Vbeln)" class="btn btn-default ml10" *ngIf="payments.Currency=='INR'"><i class="fa fa-credit-card" aria-hidden="true"></i> Pay now</button>
                                            </span>
                                        </td>
                                        <td class="edit_buttons align-center hidden-xs vertical-align-middle">
                                            <button type="button" class="btn btn-default" (click)="downloadInvoice(payments)" *ngIf="payments.OrderStatus != 'Payment Cancelled' && payments.OrderStatus != 'Auto Cancelled' && payments.Invoiceno != ''"><i class="fa fa-download" aria-hidden="true"></i> <span translate>Download</span></button>
        
                                            <span (click)="getSEZDocsForOrder(payments.Vbeln)">
                                                <i *ngIf="payments.Currency=='INR'" class="far fa-copy cursor-pointer pull-right mt5 fs20" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="current_tab == 'purchase' && (appData.get('project_type') == 'building' || appData.get('project_type') == 'school')">
                            <div class="{{config.config.settings.NO_REVIEW_COUNTRY.indexOf(appData.get('buildingData').country) > -1 || config.config.settings.NO_REVIEW_COUNTRY.indexOf(appData.get('buildingData').manageEntityCountry) > -1 ? 'not-active' : ''}} col-md-12 mt20">
                                
                                <div class="col-md-4" (click)="makePayment('recertification', $event)" *ngIf="global.isLEEDCertified(appData.get('buildingData').certification)">
                                    <div class="col-md-12 cursor-pointer-hover billing-apps p20">
                                        <div class="fw-500 fs18 pt10">Pre-pay LEED Recertification Fee</div>
                                        <br>
                                        <br>
                                        <div class="fs14 lh24 pre_pay_card">You can elect to pre-pay LEED v4.1 Operations and Maintenance recertification fees. <a href="https://www.usgbc.org/tools/leed-certification/fees" target="_blank">LEED recertification fees</a> are based on your project’s Gross Floor Area. Ensure the Gross Floor Area is accurate prior to pre-paying fees.</div>
                                    </div>
                                </div>
        
                                <div class="col-md-4" (click)="makePayment('climaterisk', $event)">
                                    <div class="col-md-12 cursor-pointer-hover billing-apps p20">
                                        <div class="fw-500 fs18 pt10">Climate Risk</div>
                                        <br>
                                        <br>
                                        <div class="fs14 lh24 pre_pay_card">Understand transition and physical risk</div>
                                        <br>
                                        <div class="fs14 lh24 pre_pay_card">Arc provides tools to understand, manage, and communicate climate-related risks and opportunities.</div>
                                        <br>
                                        <br>
                                        <a>Learn more</a>
                                    </div>
                                </div>
        
        
                                <div class="col-md-4" (click)="makePayment('certificate', $event)">
                                    <div class="col-md-12 cursor-pointer-hover billing-apps p20">
                                        <div class="fw-500 fs18 pt10">Performance Certificates</div>
                                        <br>
                                        <br>
                                        <div class="fs14 lh24 pre_pay_card">Arc Performance Certificates recognize leadership for spaces and buildings. Get your project recognized for operational performance in any of the five Arc performance categories.</div>
                                        <br>
                                        <br>
                                        <a>Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        
            <div id="billing-sez-docs-modal" class="modal portfolio-modal portfolio-modal-wbg create-portfolio-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content modal_radius width-100-perc" style="min-height: 200px;">
                        <div class="modal-header pl25">
                            <span class="fw600">Uploaded documents for order# {{orderNo}}</span>
                            <span class="pull-right fw600 cursor-pointer fs2em mtn10" (click)="closeBillingDocsModal()">&times;</span>
                        </div>
                        <div class="modal-body pl25 pb30">
                            <div class="pl20">
                                <div *ngFor="let uploaded_file of uploaded_files">
                                    <a (click)="global.downloadFile(uploaded_file)" class="fw-500">
                                        <span>{{global.getFileName(uploaded_file)}}</span>
                                    </a>
                                </div> 
        
                                <span class="fs16 fw-400" *ngIf="uploaded_files.length == 0">
                                    No documents available for this order.
                                   </span>                      
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        
            <div id="cc_avenue_block_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false" style="top: 20% !important;">
                <div class="modal-dialog modal-md" ng-style="{ 'width' : (!loading) ? '28%' : '' }">
                    <div class="modal-content p30 modal_radius">
                        <p class="align-center fw-600 m0">
                            <img src="../../../../../assets/images/gif/loading-spin.gif" class="w25px" *ngIf="loading"/>
                            <span>{{cc_avenue_block_modal_msg}}</span>
                        </p>
                        <p class="align-center fw-500 m0 mt10 fs13" *ngIf="allow_popups">
                            <span>(Please make sure to allow pop-ups to proceed with payment)</span>
                        </p>
                        <div class="mt30 align-center" *ngIf="!loading"> 
                            <button class="btn action-button btnGreen" data-dismiss="modal" >Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="block_pre_pay modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p40 modal_radius fs16 line_height_24">
                        <p class="col-md-12 pr0 danger-color mt10 mb30">
                            You have already prepaid the certification fees for this project. You will be able to make another prepay payment once the project is submitted for review. For questions, please <a href="mailto:contact@arcskoru.com">contact us</a>.
                        </p>
                        <div class="pt50 align-center">
                            <button class="btn btn-default" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        

    </div>
</div>
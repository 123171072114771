
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="col-md-8 pb50">
            <h1 class="page-title pl0 pb10">Check modules associated with user<span
                    class="fw-semi-bold"></span></h1>
            <section class="widget">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group pt30">
                                <div class="col-md-12" class="form-group" [ngClass]="get_loader ? 'not-active' : ''">
                                    <div class="col-md-10">
                                        <input [(ngModel)]="user_email_get" type="email" class="form-control"
                                            placeholder="User email" (input)=checkGetUser() [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="wizard display-block">
                                        <button (click)="searchUser()" class="btn btn-default pull-left mr10"
                                            [ngClass]="check_get_user ? '' : 'not-active'"><i class="fa fa-search"
                                                aria-hidden="true"></i> Search</button>
                                        <div *ngIf="get_loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <table class="table no-footer" *ngIf="table_area">
                        <tr>
                            <th class="pl20">Module name</th>
                            <th>User objects in module</th>
                            <th>Module importance</th>
                        </tr>
                        <tr *ngFor="let item of userData | keyvalue">
                            <td class="pl20 fw-semi-bold">{{getModuleName(item.key)}}</td>
                            <td class="fw-semi-bold align-left">{{item.value}}</td>
                            <td *ngIf="item.key == 'user_profile'">Can be ignored</td>
                            <td *ngIf="item.key == 'agreements'">Need to check</td>
                            <td *ngIf="item.key == 'enduser_agr'">Can be ignored</td>
                            <td *ngIf="item.key == 'assets'">Need to check</td>
                            <td *ngIf="item.key == 'cctoken'">Need to check</td>
                            <td *ngIf="item.key == 'payments'">Need to check</td>
                            <td *ngIf="item.key == 'measurabl_subscription'">Need to check</td>
                            <td *ngIf="item.key == 'ldp_subscriptions'">Need to check</td>
                            <td *ngIf="item.key == 'other_review_payment'">Need to check</td>
                            <td *ngIf="item.key == 'meters'">Need to check</td>
                            <td *ngIf="item.key == 'consumptions'">Need to check</td>
                            <td *ngIf="item.key == 'wastes'">Need to check</td>
                            <td *ngIf="item.key == 'measures'">Need to check</td>
                            <td *ngIf="item.key == 'unclaimed_schools'">Need to check</td>
                            <td *ngIf="item.key == 'documents'">Need to check</td>
                            <td *ngIf="item.key == 'notification_subscriptions'">Can be ignored</td>
                            <td *ngIf="item.key == 'thirdparty_partners'">Need to check</td>
                            <td *ngIf="item.key == 'portfolio_partners'">Need to check</td>
                            <td *ngIf="item.key == 'portfolios'">Need to check</td>
                            <td *ngIf="item.key == 'portfolio_team'">Need to check</td>
                            <td *ngIf="item.key == 'portfolio_documents'">Need to check</td>
                            <td *ngIf="item.key == 'activity'">Can be ignored</td>
                            <td *ngIf="item.key == 'fullstory'">Can be ignored</td>
                            <td *ngIf="item.key == 'gbig_favorites'">Can be ignored</td>
                            <td *ngIf="item.key == 'gbig_follows'">Can be ignored</td>
                            <td *ngIf="item.key == 'gbig_collections'">Can be ignored</td>
                            <td *ngIf="item.key == 'subscription_customer'">Need to check</td>
                            <td *ngIf="item.key == 'subscribers'">Need to check</td>
                            <td *ngIf="item.key == 'subscriptions'">Need to check</td>
                            <td *ngIf="item.key == 'triconsumption'">Need to check</td>
                            <td *ngIf="item.key == 'teammember'">Need to check</td>
                            <td *ngIf="item.key == 'leagueuser'">Need to check</td>
                            <td *ngIf="item.key == 'essentildiscount'">Need to check</td>
                            <td *ngIf="item.key == 'subscriptionpayment'">Need to check</td>
                        </tr>
                    </table>
                </div>
            </section>
        </div>

        <div class="col-md-4 pb50">
            <h1 class="page-title pl0 pb10">Delete user data<span class="fw-semi-bold"></span></h1>
            <section class="widget">
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group pt30">
                                <div class="col-md-12 pl0 pr0" class="form-group"
                                    [ngClass]="delete_loader ? 'not-active' : ''">
                                    <div class="col-md-8">
                                        <input [(ngModel)]="user_email_delete" type="email" class="form-control"
                                            placeholder="User email" (input)=checkDeleteUser() [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="wizard display-block">
                                        <button (click)="deletehUser()" class="btn btn-default pull-left mr10"
                                            [ngClass]="check_delete_user ? '' : 'not-active'"><i class="fa fa-trash"
                                                aria-hidden="true"></i> Delete</button>
                                        <div *ngIf="delete_loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';
import { ValidationService } from '../services/validation.service';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';

@Component({
  selector: 'app-gsa-panel',
  templateUrl: './gsa-panel.component.html',
  styleUrls: ['./gsa-panel.component.css']
})
export class GsaPanelComponent implements OnInit
{
	leagues: any = [];
	league: any = {};
	formdata: any = {};
	loading: boolean = false;
	create: boolean = false;

	save_button: string = 'Save';
	submit_error: boolean = false;
	no_error = {
		'error': false,
		'help': undefined
	};
	formdata_error: any = {
		"owner_email": this.no_error,
		"name": this.no_error
	};
	
	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private validator: ValidationService
	)
	{
		
	}

	ngOnInit(): void
	{
		this.getData();
	}

	getData()
	{
		this.loading = true;
		this.api.get('/gsaleague/?page_size=0').subscribe(
			response =>
			{
				this.loading = false;
				this.leagues = response;
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	editLeague(league?: any)
	{
		this.league = league;
		if(this.league != undefined)
		{
			this.create = false;
		}
		else
		{
			this.create = true;
			this.submit_error = false;
			this.save_button = 'Add';
			this.league = {
				"user_email": "",
				"league_name": ""
			};
		}
		$('.gsa_modal').modal('show');
	}

	deleteLeague(league: any)
	{
		this.loading = true;
		var payload = league;

		this.api.delete('/gsaleague/', payload).subscribe(
			response =>
			{
				this.loading = false;
				this.getData();
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	verifyField(data: any, key: string, optional?: boolean)
	{
		if(this.validator.regex.formValidation[key] == undefined)
		{
			return;
		}

		if(data == undefined || data == null || data.length == 0)
		{
			if(optional)
			{
				this.formdata_error[key] = {
					'error': false,
					'help': undefined
				};
				delete this.formdata[key];
				return;
			}
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.validator.regex.formValidation[key].test(data))
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.validator.regex.formValidation[key + 'Msg']
			};
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				break;
			}
		}
	};

	saveData()
	{
		let payload: any;
		this.loading = true;
		if(this.create)
		{
			payload = {
				"user_email": this.league.user_email,
				"league_name": this.league.league_name
			};
			this.api.post('/gsaleague/', payload).subscribe(
				response =>
				{
					this.loading = false;
					this.getData();
					$('.gsa_modal').modal('hide');
				},
				error =>
				{
					this.loading = false;
				}
			);
		}
		else
		{
			payload = {
				"user_email": this.league.user_email,
				"league_name": this.league.league_name,
				"league_id": this.league.id
			};
			this.api.put('/gsaleague/', payload).subscribe(
				response =>
				{
					this.loading = false;
					this.getData();
					$('.gsa_modal').modal('hide');
				},
				error =>
				{
					this.loading = false;
				}
			);
		}
	};

}

<div id="payment-details-wrapper" [ngClass]="loading ? 'not-active' : ''">
    <app-loader *ngIf="loading"></app-loader>
    <div class="content-wrap">
        <div id="content" class="content">
            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">Create a Portfolio</h4>            
                </div>
                <div (click)="global.goBack()" class="pt20">
                    <svg class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>

            <div>

                <main id="payment-details" class="view-animate fade-up overflow_y_initial portfolio_create" role="main" data-ui-view="">
                    <div class="payment-card">
                        <h4 class="fw600 fontSize20">Enter Portfolio Details</h4>
                        
                        <div id="details-form">
                            <div class="flex">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-margin-20">
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.name.error ? 'has-error' : ''">      
                                                    <input maxlength="40" (input)="verifyField(formdata.name, 'name')" [(ngModel)]="formdata.name" name="name" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Portfolio name</label>
                                                    <span class="error">{{formdata_error.name.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.organization.error ? 'has-error' : ''">      
                                                    <input id="organization" (input)="searchOrganization(formdata.organization)" [(ngModel)]="formdata.organization" name="organization" type="text" required autocomplete="off">
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Portfolio organization</label>
                                                    <span class="error">{{formdata_error.organization.help}}</span>
                                                    <div class="mt8 pl5" style="color: #707479;"><span (click)="openModal()" class="cursor-pointer">Can't find your organization? Create a <a>new organization</a></span></div>
                
                                                    <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization">
                                                        <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name);">
                
                                                            <div class="cursor-pointer vertical_align_middle">          
                                                                <span>{{org_name}}</span>
                                                            </div>
                
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="select-group group" [ngClass]="formdata_error.organization_country.error ? 'has-error' : ''">   
                                                     <label [ngClass]="formdata.organization_country != undefined ? 'selected_val' : ''">Portfolio organization country/region</label>   
                                                     <select name="organization_country" (change)="verifyField(formdata.organization_country, 'organization_country')" [(ngModel)]="formdata.organization_country">
                                                         <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                                     </select>
                                                     <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                     <span class="highlight"></span>
                                                     <span class="bar"></span>
                                                     <span class="error">{{formdata_error.organization_country.help}}</span>
                                                 </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.organization_contact.error ? 'has-error' : ''">      
                                                    <input maxlength="40" (input)="verifyField(formdata.organization_contact, 'organization_contact')" [(ngModel)]="formdata.organization_contact" name="organization_contact" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Portfolio organization contact</label>
                                                    <span class="error">{{formdata_error.organization_contact.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.address.error ? 'has-error' : ''">      
                                                    <input maxlength="40" (input)="verifyField(formdata.address, 'address')" [(ngModel)]="formdata.address" name="address" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Portfolio organization location</label>
                                                    <span class="error">{{formdata_error.address.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.organization_email.error ? 'has-error' : ''">      
                                                    <input maxlength="40" (input)="verifyField(formdata.organization_email, 'organization_email')" [(ngModel)]="formdata.organization_email" name="organization_email" type="text" required>
                                                    <span class="highlight"></span>
                                                    <span class="bar"></span>
                                                    <label>Portfolio organization Email</label>
                                                    <span class="error">{{formdata_error.organization_email.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <div class="group" [ngClass]="formdata_error.description.error ? 'has-error' : ''">  
                                                    <textarea id="portfolio_text" placeholder="Portfolio description (optional)" maxlength="100" [(ngModel)]="formdata.description" (input)="verifyField(formdata.description, 'description', true)" name="description" type="text" required></textarea>
                                                    <span class="error">{{formdata_error.description.help}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div (click)="verifyDetails()" class="pl20 pr20 btn btn-primary">
                                                    <div *ngIf="loading">
                                                        <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                    </div>
                                                    <span>{{project_detail_nxt_btn}}</span>
                                                </div>
                                                <p *ngIf="submit_error" class="fw-600 danger-color mt10">Please provide valid data</p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </div>
    </div>
</div>

<modal #modal>
    <app-organization (close_data)="closeModal()" (success_data)="getDataFromModal($event)"></app-organization>
</modal>
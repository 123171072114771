
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="dropdown display-inline mb20 mt20 col-md-12">
            <button class="btn dropdown-toggle btn-default flex" type="button" data-toggle="dropdown">
                <h1 class="page-title pl0 mb0 mt0 mr30 pb5">{{subscription_value}}<span
                        class="fw-semi-bold"></span></h1>
                <svg width="15" height="14" style="position: relative;top: 10px;" viewBox="0 0 13 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.91 0.589996L6.5 5.17L11.09 0.589996L12.5 2L6.5 8L0.5 2L1.91 0.589996Z" fill="#68737D" />
                </svg>
            </button>
            <ul class="dropdown-menu">
                <li (click)="changeSubscriptionType('Arc Essentials')" class="energy_unit"><a>Arc Essentials</a></li>
                <li (click)="changeSubscriptionType('Arc Performance Certificate')" class="energy_unit"><a>Arc
                        Performance Certificate</a></li>
                <li (click)="changeSubscriptionType('Arc Efficiency Analysis')" class="energy_unit"><a>Arc Efficiency
                        Analysis</a></li>
            </ul>
        </div>

        <div class="col-md-8" *ngIf="subscription_value == 'Arc Essentials'">
            <!-- <h1 class="page-title pl0 pb10">Arc Essentials<span class="fw-semi-bold"></span></h1> -->
            <section class="widget pt30" [ngClass]="loading ? 'not-active' : ''">
                <app-loader *ngIf="loading"></app-loader>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <h5 class="mb20">Please use this [<a href="../../assets/pdf/Data-transfer.pdf" download>link</a>] guide to learn how to use this tool.</h5>
                                <label for="normal-field" class="control-label">Add user</label>
                                <div class="relative-position">
                                    <input autocomplete="off" (focus)="isSearch(true)"
                                        (input)="searchUser(search_user)" [(ngModel)]="search_user"
                                        placeholder="Add a person" class="arc-pro-search" [ngModelOptions]="{standalone: true}">
                                    <svg class="arc-pro-search-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24">
                                        <path
                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                        <path d="M0 0h24v24H0z" fill="none" />
                                    </svg>
                                    <div *ngIf="search_results.length > 0 && is_search" class="user-list">
                                        <div *ngFor="let user of search_results">
                                            <div *ngIf="!selectedUser(user.email)" [ngClass]="user.user_role == 'arc_pro' ? 'darkgray' : ''" class="user-item">
                                                <div class="" [innerHtml]="getUser(user, search_user)"></div>
                                                <button (click)="addUser(user)" *ngIf="!(user.user_role == 'arc_pro')"
                                                    class="add-user-button">Add</button>
                                                <div *ngIf="user.user_role == 'arc_pro'" class="fs14 pt3 fw-500">
                                                    <div class="arc_logo_racetrack"></div>
                                                    {{config.arc_pro_label}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="search_results.length == 0 && is_search && search_user.length > 0 && !searching_user"
                                        class="user-list">
                                        <div class="user-item align-center fs16 gray_color_707479 block">
                                            No user found
                                        </div>
                                    </div>
                                </div>
                                <div class="arc-pro-users mt15">
                                    <table class="table table-striped arc-table">
                                        <thead>
                                            <tr>
                                                <th class="fw-500">Subscriber</th>
                                                <th class="fw-500">Email</th>
                                                <th></th>
                                        </thead>
                                        <tbody *ngIf="selected_users.length == 0">
                                            <td colspan="3" class="noProjects">
                                                No user selected
                                            </td>
                                        </tbody>
                                        <tbody *ngIf="selected_users.length > 0">
                                            <tr *ngFor="let user of selected_users;let i = index">
                                                <td>
                                                    <span class="fw-500">{{user.name}}</span>
                                                </td>
                                                <td>
                                                    <span>{{user.email}}</span>
                                                </td>
                                                <td>
                                                    <a class="align-right" (click)="removeUser(i)">Remove</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class=" control-label" for="prepended-input">Expiration Date</label>
                                <div class="">
                                    <input style="width: 300px;" id="expire_date"
                                        class="col-sm-7 form-control"
                                        [ngInit]="enableFdatePicker()" name="expire_date">
                                </div>
                            </div>
                            <div class="radio_buttons group pt5 pb45">
                                <span class="head fs16 lh30">Mode</span>
                                <div class="flex mt10">
                                    <div class="mr25">
                                        <span class="checkcontainer" (click)="selectCheckbox('mode_user', 'free')">
                                            Free
                                            <input type="radio" name="mode_user" [value]="'free'" [(ngModel)]="mode_user">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>

                                    <div>
                                        <span class="checkcontainer"
                                            (click)="selectCheckbox('mode_user', 'offline_paid')">
                                            Offline Paid
                                            <input type="radio" name="mode_user" [value]="'offline_paid'"
                                                [(ngModel)]="mode_user">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" for="prepended-input"></label>
                                <div class="col-sm-7 pl0">
                                    <button (click)="addSubs()"
                                        [ngClass]="expire_date.length > 0 && selected_users.length > 0 ? '' : 'not-active'"
                                        class="btn btn-default pull-left mr10"><i
                                            class="icon-save" aria-hidden="true"></i> Add</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>

        <div class="col-md-8" *ngIf="subscription_value == 'Arc Performance Certificate'">
            <section class="widget pt30" [ngClass]="loading ? 'not-active' : ''">
                <app-loader *ngIf="loading"></app-loader>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class=" control-label">Add project</label>
                                <div class="relative-position flex">
                                    <input id="search_project"
                                        style="font-size: 16px; line-height: 24px; padding: 8px 12px; border: 1px solid #BAC1CA; border-radius: 3px; width: 300px; margin-bottom: 5px;"
                                        [(ngModel)]="search_project" placeholder="Add a project" class="mr30" [ngModelOptions]="{standalone: true}">
                                    <div>
                                        <button [ngClass]="search_project.length == 0 ? 'not-active' : ''"
                                            (click)="searchProject(search_project)" class="btn btn-sm btn-primary">
                                            Add LEED ID
                                        </button>
                                    </div>
                                </div>
                                <div class="arc-pro-users mt15">
                                    <table class="table table-striped arc-table">
                                        <thead>
                                            <tr>
                                                <th class="fw-500">Project ID</th>
                                                <th></th>
                                        </thead>
                                        <tbody *ngIf="selected_projects.length == 0">
                                            <td colspan="3" class="noProjects">
                                                No project selected
                                            </td>
                                        </tbody>
                                        <tbody *ngIf="selected_projects.length > 0">
                                            <tr *ngFor="let project of selected_projects;let i = index">
                                                <td>
                                                    <span class="fw-500">{{project}}</span>
                                                </td>
                                                <td>
                                                    <a class="align-right" (click)="removeProject(i)">Remove</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class=" control-label" for="prepended-input">Expiration Date</label>
                                <div class="">
                                    <input style="width: 300px;" id="expire_date_project"
                                        class="col-sm-7 form-control" [ngInit]="enableFdatePicker()"
                                        name="expire_date_project">
                                </div>
                            </div>
                            <div class="radio_buttons group pt5 pb45">
                                <span class="head fs16 lh30">Mode</span>
                                <div class="flex mt10">
                                    <div class="mr25">
                                        <span class="checkcontainer" (click)="selectCheckbox('mode_project', 'free')">
                                            Free
                                            <input type="radio" name="mode_project" [value]="'free'"
                                                [(ngModel)]="mode_project">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>

                                    <div>
                                        <span class="checkcontainer"
                                            (click)="selectCheckbox('mode_project', 'offline_paid')">
                                            Offline Paid
                                            <input type="radio" name="mode_project" [value]="'offline_paid'"
                                                [(ngModel)]="mode_project">
                                            <span class="radiobtn"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" for="prepended-input"></label>
                                <div class="col-sm-7 pl0">
                                    <button (focus)="false" type="button" (click)="addCert(true)"
                                        [ngClass]="expire_date_project.length > 0 && selected_projects.length > 0 ? '' : 'not-active'"
                                        class="btn btn-default pull-left mr10"><i class="icon-save"
                                            aria-hidden="true"></i> Add</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>

        <div class="col-md-8" *ngIf="subscription_value == 'Arc Efficiency Analysis'">
            <section class="widget pt30" [ngClass]="loading ? 'not-active' : ''">
                <app-loader *ngIf="loading"></app-loader>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class=" control-label">Add project</label>
                                <div class="relative-position flex">
                                    <input id="search_project"
                                        style="font-size: 16px; line-height: 24px; padding: 8px 12px; border: 1px solid #BAC1CA; border-radius: 3px; width: 300px; margin-bottom: 5px;"
                                        [(ngModel)]="search_project" placeholder="Add a project" class="mr30" [ngModelOptions]="{standalone: true}">
                                    <div>
                                        <button [ngClass]="search_project.length == 0 ? 'not-active' : ''"
                                            (click)="searchProject(search_project)" class="btn btn-sm btn-primary">
                                            Add LEED ID
                                        </button>
                                    </div>
                                </div>
                                <div class="arc-pro-users mt15">
                                    <table class="table table-striped arc-table">
                                        <thead>
                                            <tr>
                                                <th class="fw-500">Project ID</th>
                                                <th></th>
                                        </thead>
                                        <tbody *ngIf="selected_projects.length == 0">
                                            <td colspan="3" class="noProjects">
                                                No project selected
                                            </td>
                                        </tbody>
                                        <tbody *ngIf="selected_projects.length > 0">
                                            <tr *ngFor="let project of selected_projects;let i = index">
                                                <td>
                                                    <span class="fw-500">{{project}}</span>
                                                </td>
                                                <td>
                                                    <a class="align-right" (click)="removeProject(i)">Remove</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class=" control-label" for="prepended-input">Expiration Date</label>
                                <div class="">
                                    <input style="width: 300px;" id="expire_date_project_ea"
                                        class="col-sm-7 form-control"
                                        [ngInit]="enableFdatePicker()" name="expire_date_project_ea">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" for="prepended-input"></label>
                                <div class="col-sm-7 pl0">
                                    <button (focus)="false" type="button" (click)="addEASubs(true)"
                                        [ngClass]="expire_date_project_ea.length > 0 && selected_projects.length > 0 ? '' : 'not-active'"
                                        class="btn btn-default pull-left mr10"><i class="icon-save"
                                            aria-hidden="true"></i> Add</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

let init = false;

@Component({
  selector: 'app-model-strategies',
  templateUrl: './model-strategies.component.html',
  styleUrls: ['./model-strategies.component.css']
})
export class ModelStrategiesComponent implements OnInit
{
	loading: boolean = false;
	@Input() create_mode: boolean = true;

	total: any;
	score_card: any = [];
	project: any = this.appData.get('buildingData_insight');
	project_reduction: any = this.appData.get('reductionData');
	selected_version: any = this.appData.get('selected_version');

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{
		
	}

	ngOnInit(): void
	{
		this.total = 0;
        
        if(this.appData.get('current_app') == "create-model")
        {
            this.create_mode = true;
            $('.content-wrap').css('left', '0%');
            $('.content-wrap').css('margin', '0px auto');
            $('.content-wrap').css('width', '83%');
            this.getScoreCard(this.project.rating_system);
        }
        else
        {
            this.create_mode = false;
            this.getReductionData();
            this.loading = true;
        }
	}

	scoreCardChange(val: number, credit_item: any)
	{
		var insights_data =  this.insight.scoreCardChange(val, credit_item, this.score_card, this.project, this.project_reduction);
		this.project_reduction = insights_data.project_reduction;
		this.score_card = insights_data.score_card;
		this.total = insights_data.total;

		if(!this.create_mode && init)
		{
			this.saveScoreCard();
		}
		init = true;
	}

	saveScoreCard()
	{
		var payload = {
			"reduction_data": this.project_reduction
		}
		this.loading = true;
		this.insight.post('/assets/LEED:'+ this.project.leed_id + '/reduction/?saveAs=false&version_name='+ this.selected_version.version_name +'&setLatest=false&version_id=' + String(this.selected_version.version_id), payload).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
			},
			//error handler
			response =>
			{
				this.loading = false;                    
			}
		);
	}

	getScoreCard(rating_system: string)
	{
		this.insight.getAsset('../../../../assets/insight_json/'+ rating_system +'.json').subscribe(response =>
		{
			var insights_data =  this.insight.initScorecard(this.project_reduction, this.global.copy(response), this.project.rating_system);
			this.project_reduction = insights_data.project_reduction;
			this.score_card = insights_data.score_card;
			this.total = insights_data.total;
			this.scoreCardChange(0, {"attempted": 0});
		});
	}

	getReductionData()
	{
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.selected_version.version_id).subscribe(
			//success handler
			response =>
			{
				this.appData.set('reductionData', response.reduction_data);
				this.project_reduction = response.reduction_data;
				this.loading = false;
				this.getScoreCard(this.project.rating_system);
			},
			//error handler
			response =>
			{
				this.loading = false;                    
			}
		);
	}

}

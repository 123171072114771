import { Component, OnInit } from '@angular/core';
import { AppData } from 'src/app/services/appdata.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from 'src/app/services/global.service';
import * as moment from 'moment';
import { Config } from '../../models/env-vars.model';

@Component({
  selector: 'app-cagbc-invoice',
  templateUrl: './cagbc-invoice.component.html',
  styleUrls: ['./cagbc-invoice.component.css']
})
export class CAGBCInvoiceComponent implements OnInit {
  config = Config;
  review_data: any = {};
  review_payment: any = {};
  bld_data: any = {};
  checkLEED: boolean = false;
  validation_error: boolean = true;
  show_details: boolean = false;
  no_review: boolean = false;
  loader: boolean = false;
  leed_id: any = '';
  order_number: any = '';
  order_date: any = moment(new Date()).format("MMM DD, YYYY");
  order_status: any = 'completed';
  amount: any = '';
  pdfUploadName: any = undefined;
	pdfUploadData: any = undefined;

  constructor(
    public global: Global,
    public appData: AppData,
    private api: API,
    private alertService: alertService
  ) { }

  checkLEEDID() {
    if (isNaN(this.leed_id) || String(this.leed_id).length == 0) {
      this.checkLEED = false;
    }
    else {
      this.checkLEED = true;
    }
  };

  checkInputs(field: any){
    if (field == 'amount'){
      this.amount = this.numberWithCommas(this.amount.replace(/,/g, ''));
    }
    if (isNaN(this.order_number) || this.amount == '' || this.order_number == '' || this.pdfUploadData == undefined) {
      this.validation_error = true;
    }
    else {
      this.validation_error = false;
    }
  };

  searchProject() {
    if (!this.checkLEED) {
      return false;
    }
    this.bld_data = {};
    this.order_number = '';
    this.order_date = moment(new Date()).format("MMM DD, YYYY");
    this.order_status = 'completed';
    this.amount = '';
    this.loader = true;
    this.show_details = false;
    this.no_review = false;
    this.review_data = {};
    this.review_payment = {};
    this.pdfUploadData = undefined;
    this.pdfUploadName = undefined;
    (<any>$('.uploadFile')[0]).value = "";
    var url = '/assets/LEED:' + this.leed_id + '/review/';
    if (this.leed_id.startsWith('4')){
      url = '/assets/property:' + this.leed_id + '/review/';
    }
    else if (this.leed_id.startsWith('5')){
      url = '/project/' + this.leed_id + '/review/';
    }
    this.api.get(url).subscribe(
      data => {
        if (data.building) {
          this.bld_data = data.building;
        }
        if (data.review_data) {
          this.review_data = data.review_data[0]
        }
        if (data.review_payment) {
          this.review_payment = data.review_payment[0]
          this.show_details = true;
        }
        else{
          this.show_details = false;
          this.no_review = true;
        }
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.show_details = false;
        this.review_data = {};
        this.bld_data = {};
        try {
          this.alertService.alert('error', error.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  saveDetails() {
    if(this.pdfUploadData == undefined)
		{
			return;
		}
    this.loader = true;
    let custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
    var putData = this.pdfUploadData;
    putData.append('order_number', this.order_number);
    putData.append('status', this.order_status);
    putData.append('payment_date', moment(this.order_date).format("YYYY-MM-DD"));
    putData.append('actual_amount', parseFloat(this.amount.replace(/,/g, '')));
    putData.append('amount_paid', parseFloat(this.amount.replace(/,/g, '')));
    
    var url = '/assets/LEED:' + this.leed_id + '/review/ID:' + this.review_payment.id + '/';
    if (this.leed_id.startsWith('4')){
      url = '/assets/property:' + this.leed_id + '/review/ID:' + this.review_payment.id + '/';
    }
    else if (this.leed_id.startsWith('5')){
      url = '/project/' + this.leed_id + '/review/ID:' + this.review_payment.id + '/';
    }

    this.api.put(url, putData, custom_basic_config_header).subscribe(
      data => {
        this.loader = false;
        this.pdfUploadData = undefined;
        this.pdfUploadName = undefined;
        (<any>$('.uploadFile')[0]).value = "";
        this.order_number = '';
        this.order_date = moment(new Date()).format("MMM DD, YYYY");
        this.order_status = 'completed';
        this.amount = '';
        this.checkInputs('');
        $('#updated_successfully').modal('show');
      }, error => {
        this.loader = false;
        this.pdfUploadData = undefined;
        this.pdfUploadName = undefined;
        (<any>$('.uploadFile')[0]).value = "";
        this.checkInputs('');
        try {
          this.alertService.alert('error', error.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  enableFdatePicker(): void {
    (<any>$('#order_date')).fdatepicker(
      {
        format: 'M dd, yyyy',
        onRender: (date: any) => {
          return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
        }
      }).on('changeDate', (ev: any) => {
        this.order_date = $('#date_certified').val();
      });

  };

  nameChanged(elem: any)
	{
      var filePath: any = $(elem).val();
      filePath = filePath.replace("/\\/", "\\\\");
      var index = filePath.lastIndexOf("\\");
      filePath = filePath.substring(index + 1, filePath.length);
      var data;
      var temp_uploading = [];
      if($(elem)[0].files[0].type != 'application/pdf')
      {
        this.alertService.alert('error', 'Not a valid PDF file', 10);
        return;
      }
      for(var a = 0; a < $(elem)[0].files.length; a++)
      {
        data = new FormData();
        data.append('file', $(elem)[0].files[a]);
        this.pdfUploadData = data;
      }
      this.pdfUploadName = filePath;
      this.checkInputs('');
	};

  numberWithCommas(x: any) {
    return this.global.addCommas(x);
  }

  ngOnInit(): void {
      if (this.appData.get('app_data').leed_id != undefined){
          this.leed_id = this.appData.get('app_data').leed_id;
          this.checkLEEDID();
          this.searchProject();
      }
      this.pdfUploadData = undefined;
      this.pdfUploadName = undefined;
      (<any>$('.uploadFile')[0]).value = "";
  }

}

<div class="content-wrap">
    <div id="content" class="content">
        <div>
            <div class="row pt30 pl25 pb0 ml35">
                <div class="col-md-8 mb25 border p20">
                    <div class="row border_bottom_gray_1 pb20">
                        <div class="vertical_center_flex">
                            <img src="assets/images/logo/leed.png" class="cert_list_image">
                            <span
                                class="cert_list_rs fw-500 line_height_24 fs18">{{appData.get('reviewData').cert_rating_system}}</span>
                        </div>
                    </div>
                    <div class="row pt15">
                        <div class="vertical_center_flex">
                            <div class="col-md-3 pl0" [hidden]="appData.get('reviewData').canada_payment">
                                <div class="fw-500 line_height_24 fs16 pb10">Gross area ({{this.global.getUnitTypeForJson()}})</div>
                                <div class="line_height_24 fs16">{{appData.get('buildingData').gross_area}}</div>
                            </div>
                            <div class="col-md-3 pl0" [hidden]="!appData.get('reviewData').canada_payment">
                                <div class="fw-500 line_height_24 fs16 pb10">Gross area (sqm)</div>
                                <div class="line_height_24 fs16">{{appData.get('reviewData').area_in_sqm}}</div>
                            </div>
                            <div class="col-md-3">
                                <div class="fw-500 line_height_24 fs16 pb10">Certification</div>
                                <div class="line_height_24 fs16">
                                    <span *ngIf="appData.get('reviewData').review_type == 'LEED Certification'">
                                        <span
                                            *ngIf="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school'">
                                            {{appData.get('reviewData').cert_rating_system}}</span>
                                        <span
                                            *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school'">{{appData.get('reviewData').review_type}}</span>
                                    </span>
                                    <span
                                        *ngIf="appData.get('reviewData').review_type != 'LEED Certification'">{{appData.get('reviewData').review_type}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="fw-500 line_height_24 fs16 pb10">Reporting period end date</div>
                                <div class="line_height_24 fs16">{{appData.get('reviewData').review_end_date}}</div>
                            </div>
                            <div class="col-md-2">
                                <div class="fw-500 line_height_24 fs16 pb10">Total Score</div>
                                <div class="line_height_24 fs16">{{appData.get('reviewData').target_score}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="row mt10">
                        <p class="fw-600 pl15 fs24 pull-left status_complete">
                            <img src="../../assets/images/done_green_icon.svg" class="w40px">
                            <span>Successfully Submitted</span>
                        </p>
                    </div>

                    <div class="row mt10">
                        <p class="fw-500 pl20 fs24 line_height_32 light_gray">
                            What happens next.
                        </p>
                    </div>
                    <div class="row pl20">
                        <div class="col-md-12 light_gray_border"></div>
                    </div>

                    <div class="row pl20 mt20">
                        <p class="fs18 fw-500 line_height_24">Review</p>
                        <p [hidden]="appData.get('reviewData').canada_payment" class="fs16 line_height_24">Your project has been received by GBCI.<span *ngIf="appData.get('reviewData').final_payment_status != 'completed'"> Your review will commence once you pay the review fee.</span> You will receive a confirmation email with additional details.</p>
                        <p [hidden]="!appData.get('reviewData').canada_payment" class="fs16 line_height_24"><span *ngIf="appData.get('reviewData').final_payment_status == 'completed'">Your project has been received by GBCI. You will receive a confirmation email with additional details.</span><span *ngIf="appData.get('reviewData').final_payment_status != 'completed'">CAGBC will provide you with an invoice by email. If you have a question, or you are not contacted in the next 5 business days, please email <a href = "mailto:LEEDcertification@cagbc.org">LEEDcertification@cagbc.org</a>. Your review will commence once you pay the review fee.</span></p>
                        <p [hidden]="appData.get('reviewData').canada_payment" class="fw-400"><a href="mailto:contact@arcskoru.com" target="_top">Contact us </a> with any questions.</p>
                    </div>
                    <div class="row pl20 mt10">
                        <div class="col-md-12 light_gray_border"></div>
                    </div>

                    <div class="row pl20 mt20">
                        <button class="btn btn-primary mr25 mb150"
                            (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'data-input'})">Continue<img
                                src="assets/images/arrow_right_white.svg" class="pl5"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { ValidationService } from 'src/app/services/validation.service';
import { alertService } from 'src/app/services/alert-service.service';
import { PortfoliosService } from 'src/app/services/portfolios.service';

import { Config } from 'src/app/models/env-vars.model';
import { ModalComponent } from 'src/app/components/modal/modal.component';

import * as stream from 'getstream';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent
	@ViewChild('modal_estimate') private modal_estimate!: ModalComponent
	saving: boolean = false;
	portfolio_id: number = this.appData.get('portfolio_id');
	
	is_any_greb_agreement_pending: boolean | undefined = undefined;
	is_filter_applied_no_response: boolean = false;
	deleting: boolean = false;
	name_pattern: boolean = false;
	showThis: boolean = true;
	owner_email_pattern: boolean = false;
	accept_agreement_flag: boolean = false;
	accept_agreement_flag_gresb: boolean = false;
	save_text: string = 'Save';
	accept_agreement_text_gresb_all: string = "Activate all";
	accept_agreement_text_gresb: string = "Sign Agreement"
	total_projects: number = 0;
	filterCount: number = 0;
	sort_order: string = 'asc';
	sort_col: string = 'name';
	current_page: number = 1;
	previous_page: any = null;
	next_page: any = null;
	total_pages: number = 1;
	page_size: number = 10;

	portfolio_obj: any = this.appData.get('portfolio');
	portfolio_source: string = this.appData.get('portfolio_source');
	certifications: any =[
		{name:'Platinum', value:'platinum'},
		{name:'Gold', value:'gold'},
		{name:'Silver', value:'silver'},
		{name:'Certified', value:'certified'},
		{name:'Pre-Certified', value:'pre-certified'},
		{name:'Not-Certified', value:'not-certified'}
	]
	all_organizations: any = [];
	countries: any = [];
	myportfolioprojects: any = [];
	project_selected: any = {};
	formdata: any = {
		"organization": ""
	};
	oldformdata: any = {};
	selected_cert: any = [];
	feeds: any = [];
	regex: any = this.validator.regex;

	searchingOrganization: boolean = false;
	page_loading: boolean = false;
	org_query: string = '';
	isUpdateOrgModal: boolean = false;
	isOrganizationBlocked: boolean = false;
	update_label: string = 'Update';

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private validator: ValidationService,
		private alertService: alertService,
		private portfolio: PortfoliosService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
			});
		}
		else
		{
			this.countries = this.global.getCountryList()
		}

		this.portfolio.get().subscribe(
            //success handler
            data =>
			{
                this.formdata = {
                    "portfolio_name": data.name,
                    "organization": data.organization,
                    "portfolio_organization_contact": data.organization_contact,
                    "portfolio_description": data.description,
                    "portfolio_location": data.address,
                    "portfolio_email": data.organization_email,
                    "portfolio_organization_country": data.organization_country
                };

                if(this.formdata.portfolio_organization_country==null || this.formdata.portfolio_organization_country==""){
                    this.formdata.portfolio_organization_country = "US";
                }

                this.oldformdata = data;

                this.org_query = data.organization;
                this.is_any_greb_agreement_pending = data.pending_agreement;
            }, 
            //error handler
            data =>
			{
                this.save_text = 'Save';
                this.saving = false;
            }
        );

		$('#portfolio_component').click((e: any) =>
        {
            try
            {
                if($(e.target).attr('id') == 'organization' || $(e.target).attr('id') == 'organization1')
                {
                    this.searchingOrganization = true;
                    $('.searchDropdownOrganization').show();
                }
                else
                {
                    this.searchingOrganization = false;
                    $('.searchDropdownOrganization').hide();
                }
            }
            catch (e)
            {
                
            }
        });

		this.initFeed(this.appData.get('portfolio'));
		this.getPortfolioProjectsData(this.current_page, this.page_size, this.sort_order, this.sort_col, false);
	}

	initFeed(portfolio: any)
	{
		var pf_id = portfolio.pf_id;
		var url = '/portfolios/' + pf_id + '/notifications/';

		this.api.get(url).subscribe(
			response =>
			{
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				
				for(var i = 0; i < response.activities.length; i++)
				{
					if(response.activities[i].foreign_id == 'sign_gresb_trial_assets_agreement')
					{
						this.feeds.push(response.activities[i]);
						this.accept_agreement_flag_gresb = true;
					}
				}

				var subscription = notification.subscribe((data: any) => 
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < this.feeds.length; a++)
						{
							if(this.feeds[a].id == data.deleted[d])
							{
								if(this.feeds[a].foreign_id == 'sign_gresb_trial_assets_agreement')
								{
									this.accept_agreement_flag_gresb = false;
									this.accept_agreement_text_gresb = "Sign Agreement";
									this.accept_agreement_text_gresb_all = "Activate all";
									this.getPortfolioProjectsData(this.current_page, this.page_size, this.sort_order, this.sort_col, false);
									this.checkActivateAll();
								}
								this.feeds.splice(a, 1)
							}
						}
					}

					if(data.new.length > 0)
					{
						this.feeds.push(data.new[0]);
					}
				});
			},
			error =>
			{

			}
		);
	}

	checkActivateAll()
	{
		this.api.get(
			'/portfolios/ID:' + this.portfolio_id + "/"
		).subscribe(
			//success handler
			data =>
			{
				if(data.pending_agreement)
				{
					this.is_any_greb_agreement_pending = true;
				}
				else
				{
					this.is_any_greb_agreement_pending = false;
				}
			},
			//error handler
			error =>
			{
				this.global.triggerReload();
			}
		);
	}

	removeProject(project: any, index: number)
	{
		this.deleting = true;
		var postData = 
		{
			"asset_partner_id": project.building.leed_id
		}

		this.api.delete('/portfolios/ID:' + this.portfolio_id + '/assets/' + project.building.leed_id + '/', {}).subscribe(
			//success handler
			response =>
			{
				this.deleting = false;
				this.myportfolioprojects.splice(index, 1);
				if (this.total_projects > 0)
				{
					this.total_projects -= 1;
				}
				this.checkActivateAll();
			},
			//error handler
			response =>
			{
				this.deleting = false;
			}
		);
	}

	cancel()
	{
		this.modal_estimate.close();
	}

	openEstimateModal(project?: any)
	{
		this.project_selected = project;
		this.modal_estimate.open({ windowClass: 'estimate_modal', size: 'lg' });
	};

	openAddOrgModal()
	{
		this.modal.open();
	};

	getDataFromModal(event: any)
	{
		this.formdata.organization = event.formdata;
		this.org_query = event.org_query;
		this.isUpdateOrgModal = event.isUpdateOrgModal;
		this.isOrganizationBlocked = event.isOrganizationBlocked;
		this.modal.close();
	}

	resetPortfolioDetails()
	{  
		this.formdata.portfolio_organization_country = this.oldformdata.organization_country;
		this.formdata.portfolio_organization_contact = this.oldformdata.organization_contact;
		this.org_query = this.oldformdata.organization;
		this.formdata.portfolio_name = this.oldformdata.name
		this.formdata.portfolio_description = this.oldformdata.description
		this.formdata.portfolio_location = this.oldformdata.address
		this.formdata.portfolio_email = this.oldformdata.organization_email

		this.save_text = 'Save';
		this.saving = false;
	 
		$('#update_organization_modal').modal('hide');
		$('#update_organization_modal').on('hidden.bs.modal', () =>
		{
			this.isUpdateOrgModal = true;
			this.isOrganizationBlocked = false;
		})
	}

	emailValidation(email: string)
	{
		if(this.regex.test(email) == false)
		{
			this.owner_email_pattern = true;
		}
		else
		{
			this.owner_email_pattern = false;
		}
		
		if(email.length == 0)
		{
			this.owner_email_pattern = false;
		}
	}
	
	nameValidation(name: string)
	{
		if(name.length == 0)
		{
			this.name_pattern = true;
		}
		else
		{
			this.name_pattern = false;
		}
	}

	searchOrganization(query: string)
	{
		this.searchingOrganization = true;
		
		this.formdata.organization = "";
		this.all_organizations = [];
		
		this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(
			data =>
			{
				this.all_organizations = data.owner_type;
			},
			error =>
			{

			}
		);
	};

	editPort()
	{
		this.showThis = false;
	}

	updatePortfolio(putData: any)
	{
		this.api.put('/portfolios/ID:' + this.portfolio_id + '/', putData).subscribe(
			//success handler
			data =>
			{
				this.save_text = 'Save';
				this.saving = false;
				this.saving = false;
				this.appData.set('portfolio', data);
				this.appData.set('portfolio_name', data.name);
				this.portfolio_obj = data;
			},
			//error handler
			error =>
			{
				this.save_text = 'Save';
				this.saving = false;
			}
		);
	}

	validateOwnerOrgDetails(org_country: string, org_name: string, user_name: string, postData: any)
	{
		if ((org_country != null && org_country != "" && org_name != null && org_name != "") || (org_country != null && org_country != "" && user_name != null && user_name != ""))
		{
			let org_full_country = this.global.getCountry(org_country);
			//validating owner org details
			this.validator.validateOrganization(org_name, org_full_country).subscribe(
				data =>
				{
					if (data.status)
					{
						if (data.is_blocked)
						{
							$('#update_organization_modal').modal('show');

							this.isUpdateOrgModal = false;
							this.isOrganizationBlocked = true;

							this.update_label = "Update";

							return;
						}
						else
						{
							//validating owner name details if org details are not blocked
							this.validator.validateOwner(user_name, org_full_country).subscribe(
								data =>
								{
									if (data.status)
									{
										if (data.is_blocked)
										{
											$('#update_organization_modal').modal('show');

											this.isUpdateOrgModal = false;
											this.isOrganizationBlocked = true;

											this.update_label = "Update";
											return;
										}
										//create/update portolio if owner org details not blocked
										else
										{
											this.updatePortfolio(postData);
										}
									}
									else
									{
										this.save_text = 'Save';
										this.showThis = false;
										this.saving = false;
									}
								},
								data =>
								{
									this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
								}
							);
						}
					}
					else
					{
						this.save_text = 'Save';
						this.showThis = false;
						this.saving = false;
					}
				},
				data =>
				{
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
			);
		
		}
	}

	savePort()
	{            
		this.save_text = 'Saving...';
		this.showThis = true;
		this.saving = true;

		var type = "update";

		var postData = 
		{
			"name": this.formdata.portfolio_name,
			"organization": this.formdata.organization,
			"organization_contact": this.formdata.portfolio_organization_contact,
			"description": this.formdata.portfolio_description,
			"address": this.formdata.portfolio_location,
			"organization_email": this.formdata.portfolio_email,
			"organization_country": this.formdata.portfolio_organization_country
		};

		//OFAC validation starts
		var org_country = postData.organization_country;
		var org_name = postData.organization;
		var user_name = postData.organization_contact;

		this.validateOwnerOrgDetails(org_country, org_name, user_name, postData);
		//OFAC validation ends
	}

	selectOrganization(org: any)
	{
		this.formdata.organization = org;
		this.org_query = org;
		this.searchingOrganization = false;
	};

	filterProjectsBasedOnCert(cert: any)
	{        
		var idx = this.selected_cert.indexOf(cert);    
		if(cert == 'all'){                
			if (idx > -1)
				this.selected_cert = [];
			else
				this.selected_cert = ["all", "platinum", "gold", "silver", "certified", "pre-certified", "not-certified"];         
		}
		else{
			if (idx > -1)
				this.selected_cert.splice(idx, 1);
			else
				this.selected_cert.push(cert);

			if(this.selected_cert.length == 6)
				if(this.selected_cert.indexOf("all") > -1)
					this.selected_cert.splice(this.selected_cert.indexOf("all"), 1);
				else
					this.selected_cert.push("all");
		}
		if(this.selected_cert.length == 7)
			this.filterCount = 6;
		else            
			this.filterCount = this.selected_cert.length;
		var selected_cert = this.selected_cert.join();
		this.getPortfolioProjectsData(1, this.page_size, this.sort_order, this.sort_col, null, selected_cert, true);
	};

	clearFilters(type: string)
	{
		if(type == "certification"){
			this.is_filter_applied_no_response = false;
			this.selected_cert = [];
			this.filterCount = this.selected_cert.length;
			this.getPortfolioProjectsData(this.current_page, this.page_size, this.sort_order, this.sort_col, false);
		}
	}

	searchMoveToProjectLock(project: any, index: number, portfolio?: any)
	{
		this.saving = true;
		Config.agreement_project = project;
		Config.agreement_portfolio = portfolio;
		if (project.building_status == 'Pending Registration')
		{
			var space_type_mapping: any = {
				"RSM": "Retail: Enclosed Mall",
				"OFF": "Office: Other Office",
				"DWH": "Warehouse: Nonrefrigerated Distribution/Shipping",
				"MAN": "Industrial Manufacturing",
				"RMF": "Multifamily Residential: Apartment",
				"RFA": "Single family home (attached)",
				"RSE": "Lodging: Other lodging",
				"RST": "Lodging: Other lodging",
				"HOT": "Lodging: Hotel/Motel/Report, Full Service",
				"HEC": "Healthcare: Clinic/Other outpatient",
				"MED": "Office: Medical (non-diagnostic)",
				"LEI": "Public Assembly: Recreation",
				"DAT": "Data Center",
				"SST": "Warehouse: Self Storage Units",
				"null": ""
			}
			var space_type = space_type_mapping[String(project.space_type)];
			this.global.goToApp("forms", 
			{
				"app_name": "project_creation",
				"project_type": project.project_type,
				"name": project.name,
				"country": project.country,
				"address": project.address,
				"space_type": space_type,
				"partner_asset_id": project.partner_asset_id
			});
		}
		else{
			this.api.get('/assets/LEED:' + project.leed_id + '/').subscribe(
				//success handler
				response =>
				{
					this.saving = false;
					this.global.moveToProject(response, index, undefined);
				},
				//error handler
				response =>
				{
					this.saving = false;
					this.alertService.alert('success', 'Sorry! You don\'t have access to this project.', 5);
				}
			);
		}
	};

	signAgreement(type: string, id: any, el: any)
	{
		if (type == 'single')
		{
			el.accept_agreement_text_gresb = "Processing...";
			el.accept_agreement_flag_gresb = true;
			this.api.post('/assets/LEED:' + id + '/agreements/', {"SoReference": "ADDENDUM"}).subscribe(
				//success handler
				response =>
				{
					this.api.get('/portfolios/ID:' + this.portfolio_id + '/assets/' + id + '/').subscribe(
						//success handler
						data =>
						{
							el.project.building = data.building;
							el.project.is_gresb_activated = data.building == 'GRESB';
							el.project.is_gresb = data.building == 'GRESB';
							this.alertService.alert('success', 'Agreement signed successfully.', 5);
						},
						//error handler
						response =>
						{
							this.global.triggerReload();
						}
					);
				},
				//error handler
				response =>
				{
					el.accept_agreement_text_gresb = "Sign Agreement";
					el.accept_agreement_flag_gresb = false;
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
				}
			);
		}
		else
		{
			this.accept_agreement_text_gresb = "Processing...";
			this.accept_agreement_text_gresb_all = "Processing...";
			this.accept_agreement_flag_gresb = true;
			this.api.post('/portfolios/ID:' + this.portfolio_id + '/agreements/', {}).subscribe(
				//success handler
				response =>
				{

				},
				//error handler
				response =>
				{
					this.accept_agreement_text_gresb = "Sign Agreement";
					this.accept_agreement_text_gresb_all = "Activate all";
					this.accept_agreement_flag_gresb = false;
				}
			);
		}
	};

	getPortfolioProjectsData(page_number: number, page_size: number, order: string, category: string, unit_type?: any, cert_type?: string, is_filter_applied?: boolean)
	{   
		this.page_size = page_size;
		if(page_number > this.total_pages || page_number < 1)
			return

		this.page_loading = true;
		this.portfolio.getPortfolioProjects(this.portfolio_id, order, "name", page_number, page_size, unit_type, cert_type).subscribe(
			//success handler
			data =>
			{   
				var data_results = data.results;

				for(var i = 0; i < data_results.length; i++)
				{
					if(data_results[i].building.activation_source == 'GRESB')
					{
						data_results[i]['is_gresb'] = true;
						if (data_results[i].building.building_status != 'activated_addendum_agreement_pending'){
							data_results[i]['is_gresb_activated'] = true;
						}
						else{
							data_results[i]['is_gresb_activated'] = false;
						}
					}
					else
					{
						data_results[i]['is_gresb'] = false;
					}

				}

				for (var i = 0; i < data_results.length; i++) 
				{
					if(data_results[i].building.base_score !=null)
						data_results[i].building["base"] = (data_results[i].building.base_score)/10;
					else
						data_results[i].building["base"] = data_results[i].building.base_score;
				}

				if(data.count == 0 && is_filter_applied)
				{
					this.is_filter_applied_no_response = true;
				}
				
				if(data.count > 0)
				{
					var total_projects = data.count;
					var total_pages = Math.ceil(total_projects / page_size);
					this.current_page = page_number; //1;
					this.total_pages = total_pages;
					this.is_filter_applied_no_response = false;

					this.page_size = this.page_size > data.count ? data.count : this.page_size;
					this.page_size = this.page_size < 10 ? 10 : this.page_size;
					
					if(page_number >= total_pages)
						this.next_page = null;
					else
						this.next_page = this.current_page + 1;
					
					if(page_number <= 1)
						this.previous_page = null;
					else
						this.previous_page = this.current_page - 1;
				}                    
				this.myportfolioprojects = data_results;
				this.total_projects = data.count;
				this.page_loading = false;
			},
			//error handler
			error =>
			{
				this.page_loading = false;
			}
		);
	}

}

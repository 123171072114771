import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paymetric',
  templateUrl: './paymetric.component.html',
  styleUrls: ['./paymetric.component.css']
})
export class PaymetricComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

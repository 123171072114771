import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { Config } from 'src/app/models/env-vars.model';
import { InsightService } from 'src/app/services/insight.service';

@Component({
  selector: 'app-sidebar', 
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit
{
	config = Config;
	@Input() sidebar_type: string = '';
	@Output() sidebar_selection = new EventEmitter<string>();

	asset_analytics: any = {
		"total_projects": 0,
		"bst_projects": 0,
		"certified_projects": 0,
		"building_project": 0,
		"transit_projects": 0,
		"parksmart_project": 0,
		"city_projects": 0,
		"community_projects": 0,
		"school_projects": 0,
		"bst_floor_area": 0,
		"bst_emissions": 0,
		"bst_occupants": 0,
		"bst_certified_area": 0
	};
	loading: boolean = false;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		public insight: InsightService
	)
	{}

	ngOnInit(): void
	{
		this.loading = true;
		if(this.sidebar_type == 'projects')
		{
			this.api.get(
				'/assets/basic/analytics/'
			).subscribe((result: any) =>
			{
				this.loading = false;
				this.asset_analytics = result;
			},
			error =>
			{
				this.loading = false;
			});
		}
	}

	changeProjectType(project_type: string)
	{
		this.appData.set('project_type', project_type);
		this.sidebar_selection.emit(project_type);
	}

	goTo(component: string)
	{
		this.appData.set('project_type', 'building,school');
		if(this.appData.get('project_type') == 'building,school')
		{
			this.appData.set('project_type', 'all');
		}
		this.global.goToApp(component);
	}

	changeApp(app_name: string)
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": app_name
		});
	}

	changeAppPortfolio(app_name: string)
	{
		this.global.goToApp("selected_portfolio", 
		{
			"portfolio_id": this.appData.get('portfolio_id'),
			"app_name": app_name
		});
	}

	changeModel(app_name: string)
	{
		this.global.goToApp("selected_model", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": app_name,
			'selected_version': this.appData.get('selected_version'),
			'version': this.appData.get('version')
		});
	}

	changeAdminApp(app_name: string)
	{
		this.global.goToApp("selected-admin-app", 
		{
			"app_name": app_name
		});
	}

	setInsightComponent(component_name: string)
	{
		this.appData.set('insight_app', component_name);
	}
}

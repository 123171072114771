<div class="modal-body"> 
    <div class="row p20 fw-400">
        <p> 
            <b>Performance score:</b><br><br>

            The Performance score is on a scale of <br>0 - 100. It is an aggregation of the weighted scores across five categories- Energy, Water, Waste, Transportation and Human Experience, and the Base Score. Higher scores indicate better performance. Click on the racetrack to see additional information.

        </p>
        <br>
        <p> 
            <b>Category Score:</b><br><br>          
            The Performance Score is based on a global data set. At any point in time, the score reflects the most recent 365 day rolling average in each category. <span *ngIf="!(appData.get('project_type') == 'city' || appData.get('project_type') == 'community' || appData.get('project_type') == 'building' || appData.get('project_type') == 'school')" >More information is available under <b ng-click="filterTrial('app.performancereport', {project_id: appData.project_id})"><a ng-click="cancel()">Analytics</a></b></span>
        </p>

        <p>
            <b>Energy:</b>  The total energy use from the most recent 365 days is converted into equivalent carbon emissions. The lower the overall carbon emissions generally the better the score. A perfect score reflects net zero emissions. You can view the energy score on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').energy}}. <br><br>
            
            <b>Water:</b> The total water use from the most recent 365 days, is reflected in the score. Generally the lower the overall water consumption the better the score, with a perfect score reflecting net zero potable water use. You can view the scores on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').water}}.<br><br>
            
            <b>Waste:</b> The weight of the materials generated and diverted from landfills and incineration is reflected in the score. You can view the scores on a scale of 0 – 100 or weighted to a score of 0 – {{appData.get('maxima').waste}}.<br><br>
            
            <b>Transportation:</b> Commuting distances and modes of travel provided through survey responses are converted to carbon emissions. The lower the emissions the better the score. You can view the scores on a scale of 1- 100 or weighted to a score of 0 - {{appData.get('maxima').transport}}.<br><br>

            <b>Human Experience:</b> Occupant satisfaction survey responses, interior carbon dioxide (CO2) levels, and Interior total volatile organic compound levels (TVOC) contribute to the score equally. Enhanced comfort and environmental quality result in higher scores. You can view the scores on a scale of 1- 100 or weighted to a score of 0 – {{appData.get('maxima').human_experience}}.<br><br>
            
            <b><a href="https://www.usgbc.org/resources/data-management" target="_blank">Here</a></b> is more information on data management.<br><br>
        </p>

        <p *ngIf="(appData.get('project_type') == 'building' || appData.get('project_type')=='transit') && (this.appData.get('project_rating_system')!='other' && this.appData.get('project_rating_system')!='none')">
        	<b>Base points:</b> <br><br> 
			
			LEED credits contribute to the Base points. Best practices foundational to a building’s successful sustainable design and infrastructure that result in long term benefits are identified towards base points for every LEED rating system and version.

        </p>

    </div>
</div>

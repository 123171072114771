import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit
{
	config = Config;
	loading = false;
	leed_id = this.appData.get('leed_id');
	animation_link: string = window.location.origin + '/plaque/' + this.leed_id + '/' + this.appData.get('buildingData').key;
	leed_score_public: any = this.appData.get('buildingData').leed_score_public;
	lobby_survey_status: any = this.appData.get('buildingData').lobby_survey_status;
	monthly_email: any;

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		this.config.default_page = this.appData.get('buildingData').first_app_visited; 
		this.loading = true;

		this.api.get(
			'/assets/LEED:'+ this.leed_id +'/subscriptions/'
		).subscribe(
			data =>
			{
				if (data.stype == null)
				{
                    this.monthly_email = false;
                }
                else
				{
                    this.monthly_email = data.monthly_email_notification;
                }
				this.loading = false;
			},
			error =>
			{
				this.appData.set('all_actions', []);
				this.loading = false;
			}
		);
	}

	editAsset(putData: any)
	{
		this.loading = true;
		this.api.put('/assets/LEED:'+ this.leed_id +'/', putData).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.appData.set('buildingData', response);
			},
			//error handler
			response =>
			{     
				this.loading = false;               
			}
		);
	};

	monthlyEmailNotification(putData: any)
	{
		this.loading = true;
		this.api.post('/assets/LEED:'+ this.leed_id +'/subscriptions/', putData).subscribe(
			//success handler
			response =>
			{      
				this.loading = false;              
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};
	
	redirectToDataResource()
	{
		window.open("https://www.usgbc.org/resources/data-management", '_blank');
	};

	downloadDataReviewChecklist()
	{
		this.global.anchorDownload("Data Review Checklist.pdf", '../../../../assets/pdf/Data Review Checklist.pdf');
	};  
		
	
	downloadTransitManual()
	{
		this.global.anchorDownload("LEED V4 BDC Transit User Manual.pdf", '../../../../assets/pdf/TransitManual.pdf');
	};  
	
	saveDefaultPage(first_app_visited: any)
	{
		this.loading = true;
		var putData = 
		{
			"first_app_visited": first_app_visited
		};

		this.api.put('/assets/LEED:'+ this.leed_id +'/', putData).subscribe(
			//success handler
			response =>
			{
				var data = response;
				this.loading = false;
				this.appData.set('buildingData', response);
				this.config.default_page = this.appData.get('buildingData').first_app_visited
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	};
	
	getLabel(first_app_visited: string)
	{
		if(first_app_visited == 'allactions')
		{
			if(this.appData.get('new_arc_project'))
				return 'All Actions'
			else
				return 'Data Input'
		}
		else if(first_app_visited == 'totalscore')
		{
			return 'Performance Score'
		}
	};

	copyLink(language: string)
	{
		var val = this.animation_link;
		if(language != undefined)
		{
			val += "?language=" + language; 
		}
		var dummy = document.createElement("input");
		document.body.appendChild(dummy);
		dummy.setAttribute('value', val);
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		$('.tippe_notif').click();

		$('.copy_notif').fadeIn();
		setTimeout(function()
		{
			$('.copy_notif').fadeOut();
		}, 2500);
	};

}

<div [ngClass]="loading ? 'not-active': ''">
    <app-loader *ngIf="loading"></app-loader>
    <div [hidden]="loading" class="center-div">
        <section class=" col-md-6 p0 mb0">
            
            <div class="survey-header">
                <div class="survey-logo"></div>
            </div>
            <div class="p10 align-center borderBottom survey-heading survey-card">
                {{building.name}}, {{building.street}}
            </div>
            
            <div *ngIf="!survey_submitted">

                <div class="survey-card mb10" style="padding: 20px 40px;">
                    <div *ngIf="building.skipsurvey" class="fs16 align-center mb20">
                        {{lang.getText("submit_both")}}
                    </div>
                    <div class="progress_create w100p">
                        <div [ngClass]="current_step == 'Transportation Survey' ? 'active' : ''" class="step_parent step flex">
                            <div class="step_name">{{lang.getText("transportation_label")}} {{lang.getText("survey")}}</div>
                        </div>
                        <div [ngClass]="current_step == 'Human Experience Survey' ? 'active' : ''" class="step_parent step flex">
                            <div class="step_name">{{lang.getText("human_experience")}} {{lang.getText("survey")}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="survey-card" *ngIf="current_step == 'Transportation Survey'">
                
                    <div class="relative-position align-right mb20">
                        <label class="fw-600"> Language: &nbsp; </label> 
                        <select id="survey_language" [(ngModel)]="lang.language" (change)="onChange(lang.language)" [ngInit]="langDropInit()" arc-drp="languageSelect" class="cursor-pointer">
                            <option *ngFor="let obj of lang.getLanguages()" [ngValue]="obj">{{obj.label}}</option>
                        </select>
                        <i class="custom-chevron fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                
                    <div class="fontSize16 fw-600 " [ngClass]="routeError || visitorRouteError ? 'sliderError' : ''">
                        {{lang.getText("typical_day")}}
                        <p *ngIf="routeError" class="error">{{lang.getText("mandatory_field_error")}}</p>
                        <p *ngIf="visitorRouteError" class="error">{{lang.getText("visitorRouteError")}}</p>
                    </div>

                    <div class="routes mb15 " *ngFor="let route of routes; let idx = index;">
                        <div class="left-right-align">
                            <div class="mt10 mb5 fw-600">
                                {{lang.getText("route")}} {{global.getArabicNumber((idx + 1), lang.language.code)}}
                            </div>
                            <div arc-btn="removeRoute" *ngIf="routes.length > 1" class="new-route mt10 mb5 fw-600" (click)="removeRoute(idx)">
                                x {{lang.getText("remove_route")}}
                            </div>
                        </div>
                        <button arc-btn="editRoute" (click)="editRoute(route, idx)  " class="btn btn-default btn-survey width-100-perc" type="button" data-toggle="dropdown">
                            <span>{{lang.getText("select_travel")}}</span>
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </button>
                        <div arc-btn="editRoute" *ngIf="showDivBorder(route.data)" class="cursor-pointer" (click)="editRoute(route, idx)">
                            <div *ngFor="let obj of route.data | keys" [hidden]="(obj.value == null || obj.value == '' || obj.key == 'noofdays')" class="mode-list mode-option mode-option-border">
                                <div class="route-label" *ngIf="obj.key != 'noofdays'">
                                    <div class="route-icon {{obj.key}}"></div>
                                    <div class="pt5">{{lang.getText(obj.key)}}</div>
                                </div>
                                <!-- <div class="route-label no-border" *ngIf="obj.key == 'noofdays'">
                                    {{lang.getText("Number of days in a week, you use this route")}}
                                </div> -->
                                <span class="route-value pt5 fw400" *ngIf="lang.language.code == 'ar'">
                                    {{obj.value}} {{lang.getText(route.unit)}}(س)
                                </span>
                                <span class="route-value pt5 fw400" *ngIf="lang.language.code != 'ar'">
                                    {{obj.value}}<span *ngIf="obj.key != 'noofdays'"> {{lang.getText(route.unit)}}(s)</span>
                                    <!-- <span *ngIf="obj.key == 'noofdays'"> {{lang.getText("day")}}(s)</span>  -->
                                </span>
                            </div>
                        </div>
                    </div>
                    <div arc-btn="addRoute" class="new-route " (click)="addRoute()">
                        <span class="mr5">+</span> {{lang.getText("add_route")}}
                    </div>
                </div>
                
                <div class="survey-card" *ngIf="current_step == 'Human Experience Survey'">
                
                    <div class="relative-position align-right mb20">
                        <label class="fw-600"> Language: &nbsp; </label> 
                        <select id="survey_language" [(ngModel)]="lang.language" (change)="onChange(lang.language)" [ngInit]="langDropInit()" arc-drp="languageSelect" class="cursor-pointer">
                            <option *ngFor="let obj of lang.getLanguages()" [ngValue]="obj">{{obj.label}}</option>
                        </select>
                        <i class="custom-chevron fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                
                    <div class="fontSize16 fw-600" [ngClass]="showSliderError ? 'sliderError' : ''">
                        {{lang.getText("how_satisfied")}}
                        <p *ngIf="showSliderError" class="error">{{lang.getText("blank_satisfaction_error")}}</p>
                    </div>
                    
                    <div *ngIf="isLatest">
                        <h4 arc-lbl="slide_to_select" *ngIf="!selected_slider" class="slider-text fw-400">{{lang.getText("slide_to_select")}}</h4>
                        <h4 arc-lbl="neutral" *ngIf="satisfaction == 0 && selected_slider" class="slider-text fw-400">{{lang.getText("neutral")}}</h4>
                        <h4 arc-lbl="satisfied" *ngIf="satisfaction == 1" class="slider-text fw-400">{{lang.getText("satisfied")}}</h4>
                        <h4 arc-lbl="very_satisfied" *ngIf="satisfaction == 2" class="slider-text fw-400">{{lang.getText("very_satisfied")}}</h4>
                        <h4 arc-lbl="extremely_satisfied" *ngIf="satisfaction == 3" class="slider-text fw-400">{{lang.getText("extremely_satisfied")}}</h4>
                        <h4 arc-lbl="unsatisfied" *ngIf="satisfaction == -1" class="slider-text fw-400">{{lang.getText("unsatisfied")}}</h4>
                        <h4 arc-lbl="very_unsatisfied" *ngIf="satisfaction == -2" class="slider-text fw-400">{{lang.getText("very_unsatisfied")}}</h4>
                        <h4 arc-lbl="extremely_unsatisfied" *ngIf="satisfaction == -3" class="slider-text fw-400">{{lang.getText("extremely_unsatisfied")}}</h4>
                    </div>
                
                    <div *ngIf="isLatest" class="mb20" [ngClass]="selected_slider ? 'selected' : 'blank'">
                
                        <input [ngInit]="initRangeSlider()" arc-inp="sliderValue" (input)="satisfactionChange(satisfaction_slider_new, true)" [(ngModel)]="satisfaction_slider_new" type="range" min="-3" max="3" value="0" step="1" class="width-100-perc"> 
                
                        <div class="between-div mt15">
                            <span class="fw-600">{{lang.getText("extremely_unsatisfied")}}</span>
                            <span class="fw-600">{{lang.getText("extremely_satisfied")}}</span>
                        </div>
                    </div>
                
                    <div *ngIf="!isLatest">
                        <h4 arc-lbl="slide_to_select" *ngIf="!selected_slider" class="slider-text fw-400">{{lang.getText("slide_to_select")}}</h4>
                        <h4 arc-lbl="extremely_satisfied" *ngIf="satisfaction > 9" class="slider-text fw-400">{{lang.getText("extremely_satisfied")}}</h4>
                        <h4 arc-lbl="very_satisfied" *ngIf="satisfaction <= 9 && satisfaction >=8" class="slider-text fw-400">{{lang.getText("very_satisfied")}}</h4>
                        <h4 arc-lbl="satisfied" *ngIf="satisfaction < 8 && satisfaction >= 6" class="slider-text fw-400">{{lang.getText("satisfied")}}</h4>
                        <h4 arc-lbl="neutral" *ngIf="satisfaction < 6 && satisfaction > 4 && selected_slider" class="slider-text fw-400">{{lang.getText("neutral")}}</h4>
                        <h4 arc-lbl="unsatisfied" *ngIf="satisfaction <= 4 && satisfaction > 2" class="slider-text fw-400">{{lang.getText("unsatisfied")}}</h4>
                        <h4 arc-lbl="very_unsatisfied" *ngIf="satisfaction <= 2 && satisfaction > 1" class="slider-text fw-400">{{lang.getText("very_unsatisfied")}}</h4>
                        <h4 arc-lbl="extremely_unsatisfied" *ngIf="satisfaction <= 1" class="slider-text fw-400">{{lang.getText("extremely_unsatisfied")}}</h4>
                    </div>
                
                    <div *ngIf="!isLatest" class="mb20" [ngClass]="selected_slider ? 'selected' : 'blank'">
                
                        <input [ngInit]="initRangeSlider()" arc-inp="sliderValue" (input)="satisfactionChange(satisfaction_slider, false)" [(ngModel)]="satisfaction_slider" type="range" min="0" max="10" value="5" step="1" class="width-100-perc"> 
                
                        <div class="between-div mt15">
                            <span class="fw-600">{{lang.getText("extremely_unsatisfied")}}</span>
                            <span class="fw-600">{{lang.getText("extremely_satisfied")}}</span>
                        </div>
                    </div>
                
                    <div *ngIf="isLatest">
                        <div class="satisfaction_plus1" *ngIf="satisfaction == 1"></div>
                        <div class="satisfaction_plus2" *ngIf="satisfaction == 2"></div>
                        <div class="satisfaction_plus3" *ngIf="satisfaction == 3"></div>
                        <div class="satisfaction_0" *ngIf="satisfaction == 0"></div>
                        <div class="satisfaction_minus1" *ngIf="satisfaction == -1"></div>
                        <div class="satisfaction_minus2" *ngIf="satisfaction == -2"></div>
                        <div class="satisfaction_minus3" *ngIf="satisfaction == -3"></div> 
                    </div>
                
                    <div *ngIf="!isLatest">
                        <div class="satisfaction_plus3" *ngIf="satisfaction > 9"></div>
                        <div class="satisfaction_plus2" *ngIf="satisfaction <= 9 && satisfaction >=8"></div>
                        <div class="satisfaction_plus1" *ngIf="satisfaction < 8 && satisfaction >= 6"></div>
                        <div class="satisfaction_0" *ngIf="satisfaction < 6 && satisfaction > 4"></div>
                        <div class="satisfaction_minus1" *ngIf="satisfaction <= 4 && satisfaction > 2"></div>
                        <div class="satisfaction_minus2" *ngIf="satisfaction <= 2 && satisfaction > 1"></div>
                        <div class="satisfaction_minus3" *ngIf="satisfaction <= 1"></div> 
                    </div>
                    
                    <div *ngIf="satisfaction > 0 && isLatest" class="mt30">
                        <span class="fontSize16 fw-600 col-md-12 pl0">{{lang.getText("compliment_message")}}</span>
                        
                        <div class="col-md-4 survey-options mt20 pr0" *ngFor="let option of enhance">
                            <div class="checkbox">
                                <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                <label>{{lang.getText(option.label)}}</label>
                            </div>
                        </div>
                    </div>
                
                    <div *ngIf="(satisfaction < 0 && isLatest) || (satisfaction < 5 && !isLatest)" class="mt30">
                        <span class="fontSize16 fw-600 col-md-12 pl0">{{lang.getText("sorry_message")}}</span>
                    
                        <div class="row">
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce_1" [hidden]="!((isLatest) || (!isLatest && !option.latest))">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce_2" [hidden]="!((isLatest) || (!isLatest && !option.latest))">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce_3" [hidden]="!((isLatest) || (!isLatest && !option.latest))">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce_4" [hidden]="!((isLatest) || (!isLatest && !option.latest))">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 survey-options mt20" *ngFor="let option of reduce_5" [hidden]="!((isLatest) || (!isLatest && !option.latest))">
                                <div class="checkbox">
                                    <input [(ngModel)]="option.data" value={{option.data}} type="checkbox"/> 
                                    <label>{{lang.getText(option.label)}}</label>
                                </div>
                            </div>
                        </div>
                        
                
                    </div>
                
                    <div class="col-md-12 pl0" *ngIf="selected_slider">
                        <p class="fontSize13 fw-600 mt30">{{lang.getText("comments")}}</p>
                        <input arc-inp="other_attributes" value="{{other_attributes}}" [(ngModel)]="other_attributes" class="other_attributes input-md fw400 w70p" maxlength="256"/>
                        <p class="fontSize13 fw-600 mt15">{{lang.getText("other_location")}}</p>
                        <input value="{{location}}" [(ngModel)]="location" class="location input-md mb15 fw400 w70p" maxlength="76"/>
                        <!-- <p class="fontSize13 fw-600 mt15">{{lang.getText("name")}}</p>
                        <input value="{{name}}" [(ngModel)]="name" class="name input-md fw400 w70p mb15" maxlength="64"/> -->
                
                        <!-- <div *ngIf="isLatest" class="fontSize16 fw-600 {{occupantError ? 'mb15' : ''}}" [ngClass]="occupantError ? 'sliderError' : ''">
                            <p class="fontSize13 fw-600">{{lang.getText("occupant_type_question")}}</p>
                            <p *ngIf="occupantError" class="error" style="margin-bottom: 0px;">{{lang.getText("mandatory_field_error")}}</p>
                        </div> -->
                    </div>
                    <!-- <div class="col-md-12 pl0">
                        <div *ngIf="isLatest" class="mb20 relative-position">
                            <select arc-drp="occupant_category" [(ngModel)]="occupant_category" id="occupant_category" class="w70p cursor-pointer">
                                <option value="regular_occupant">{{lang.getText("regular")}}</option>
                                <option value="visitor_occupant">{{lang.getText("visitor")}}</option>
                            </select>
                            <i class="fa fa-chevron-down" aria-hidden="true" style="right: 38px;position: relative;"></i>
                        </div>
                    </div> -->
                </div>
                <div class="survey-card mt10">
                    <div *ngIf="isLatest" class="fontSize16 fw-600 {{occupantError ? 'mb15' : ''}}" [ngClass]="occupantError ? 'sliderError' : ''">
                        <p class="fontSize16 mb10">{{lang.getText("occupant_type_question")}}</p>
                        <p *ngIf="occupantError" class="error" style="margin-bottom: 0px;">{{lang.getText("mandatory_field_error")}}</p>
                    </div>
                    <div class="col-md-12 pl0">
                        <div *ngIf="isLatest" class="mb20 relative-position">
                            <select arc-drp="occupant_category" [(ngModel)]="occupant_category" id="occupant_category" class="w70p cursor-pointer">
                                <option value="regular_occupant">{{lang.getText("regular")}}</option>
                                <option value="visitor_occupant">{{lang.getText("visitor")}}</option>
                            </select>
                            <i class="fa fa-chevron-down" aria-hidden="true" style="right: 38px;position: relative;"></i>
                        </div>
                    </div>
                </div>
                <div class="survey-card mt10 align_lr next-wrapper">
                    <div *ngIf="current_step == 'Transportation Survey'">
                        <button *ngIf="building.skipsurvey" arc-btn="skipStepTS" type="button" class="btn btn-primary" (click)="skipStep('Transportation Survey')" style="height: 40px">
                            {{lang.getText("skip")}}
                        </button>
                    </div>
                    <div *ngIf="current_step == 'Transportation Survey'" class="align-right">
                        <button arc-btn="goToStepHE" type="button" class="btn btn-primary" (click)="goToStep('Human Experience Survey')">
                            {{lang.getText("next")}}
                            <i class="toggle fa fa-angle-right fs15 bold ml10"></i>
                        </button>
                        <p *ngIf="routeError" class="fw-600 error">{{lang.getText("submit_error")}}</p>
                    </div>
                    <div *ngIf="current_step == 'Human Experience Survey'">
                        <button arc-btn="skipStepTS" type="button" class="btn btn-default" (click)="goToStep('Transportation Survey')" style="height: 40px">
                            <i class="toggle fa fa-angle-left fs15 bold mr10"></i>
                            {{lang.getText("previous")}}
                        </button>
                    </div>
                    <div *ngIf="current_step == 'Human Experience Survey'" class="align-right">
                        <button arc-btn="skipStepHE" type="button" class="btn btn-primary" (click)="skipStep('Human Experience Survey')" *ngIf="!skipped_t && building.skipsurvey">
                            {{lang.getText("submit")}}
                        </button>
                        <button arc-btn="submitSurvey" type="button" class="btn btn-primary" (click)="submitSurvey()" *ngIf="skipped_t || !building.skipsurvey">
                            {{lang.getText("submit")}}
                        </button>
                        <p *ngIf="routeError || showSliderError || occupantError" class="fw-600 error">{{lang.getText("submit_error")}}</p>
                    </div>
                </div>

            </div>
            <div *ngIf="survey_submitted">

                <div class="survey-card" *ngIf="!skipped_t && !building.lobby_survey_status">
                    <h4 class="fw-600 mb20 align-center">{{lang.getText("thank_you_message")}}</h4>
                
                    <div class="fw-600 fontSize16 mb10">{{lang.getText("transportation")}}</div>
                    <div class="routes mb10 col-md-12 pl0" *ngFor="let route of routes; let idx = index;">
                        <div class="mt10 mb5 fw-600">
                            {{lang.getText("route")}} {{(idx + 1)}}
                        </div>
                        <div class="mode-list cursor-pointer">
                            <div *ngFor="let obj of route.data | keys" [hidden]="!(obj.value != null && obj.value != '')" class="mode-option">
                                <div class="route-label" *ngIf="obj.key != 'noofdays'">
                                    <div class="route-icon {{obj.key}}"></div>
                                    <div class="pt5">{{lang.getText(obj.key)}}</div>
                                </div>
                                <div *ngIf="obj.key == 'noofdays'">{{lang.getText("Number of days in a week, you use this route")}}</div>
                                <span class="route-value pt5 fw400" *ngIf="lang.language.code == 'ar'">
                                    {{obj.value}} {{lang.getText(route.unit)}}(س)
                                </span>
                                <span class="route-value pt5 fw400" *ngIf="lang.language.code != 'ar'">
                                    {{obj.value}}<span *ngIf="obj.key != 'noofdays'"> {{lang.getText(route.unit)}}(s)</span>
                                    <span *ngIf="obj.key == 'noofdays'"> {{lang.getText("day")}}(s)</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="survey-card" *ngIf="!skipped_he && !building.lobby_survey_status" [hidden]="!isLatest">
                    <h4 class="fw-600 mb20 align-center" *ngIf="skipped_t">{{lang.getText("thank_you_message")}}</h4>
                    <div class="fw-600 fontSize16 mb10">{{lang.getText("satisfaction")}}</div>
                    
                    <div class="satisfaction_plus1" *ngIf="satisfaction == 3"></div>
                    <div class="satisfaction_plus2" *ngIf="satisfaction == 2"></div>
                    <div class="satisfaction_plus3" *ngIf="satisfaction == 1"></div>
                    <div class="satisfaction_0" *ngIf="satisfaction == 0"></div>
                    <div class="satisfaction_minus1" *ngIf="satisfaction == -1"></div>
                    <div class="satisfaction_minus2" *ngIf="satisfaction == -2"></div>
                    <div class="satisfaction_minus3" *ngIf="satisfaction == -3"></div> 
                
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == 3">{{lang.getText("extremely_satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == 2">{{lang.getText("very_satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == 1">{{lang.getText("satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == 0">{{lang.getText("satisfaction_none")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == -1">{{lang.getText("unsatisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == -2">{{lang.getText("very_unsatisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction == -3">{{lang.getText("extremely_unsatisfied")}}</p>
                
                    <div *ngIf="satisfaction != 0" class="align-center">
                        <p *ngIf="satisfaction < 0">{{lang.getText("reduce_message")}}</p>
                        <p *ngIf="satisfaction > 0">{{lang.getText("enhance_message")}}</p>
                        <div *ngIf="satisfaction < 0" class="pl65 mt20 survey-satisfaction-summary">
                            <div class=" survey-options " *ngFor="let option of reduce" [hidden]="!option.data">
                                <label class="fw-400"> <div class="list-item"></div> {{lang.getText(option.label)}}</label>
                            </div>
                        </div>
                        <div *ngIf="satisfaction > 0" class="pl65 mt20 survey-satisfaction-summary">
                            <div class=" survey-options " *ngFor="let option of enhance" [hidden]="!option.data">
                                <label class="fw-400"> <div class="list-item"></div> {{lang.getText(option.label)}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="survey-card" *ngIf="!building.lobby_survey_status" [hidden]="isLatest">
                    <h4 class="fw-600 mb20 align-center" *ngIf="skipped_t">{{lang.getText("thank_you_message")}}</h4>
                    <div class="fw-600 fontSize16 mb10">{{lang.getText("satisfaction")}}</div>
                    
                    <div class="satisfaction_plus3" *ngIf="satisfaction > 9"></div>
                    <div class="satisfaction_plus2" *ngIf="satisfaction <= 9 && satisfaction >=8"></div>
                    <div class="satisfaction_plus1" *ngIf="satisfaction < 8 && satisfaction >= 6"></div>
                    <div class="satisfaction_0" *ngIf="satisfaction < 6 && satisfaction > 4"></div>
                    <div class="satisfaction_minus1" *ngIf="satisfaction <= 4 && satisfaction > 2"></div>
                    <div class="satisfaction_minus2" *ngIf="satisfaction <= 2 && satisfaction > 1"></div>
                    <div class="satisfaction_minus3" *ngIf="satisfaction <= 1"></div> 
                
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction > 9">{{lang.getText("extremely_satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction <= 9 && satisfaction >=8">{{lang.getText("very_satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction < 8 && satisfaction >= 6">{{lang.getText("satisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction < 6 && satisfaction > 4">{{lang.getText("neutral")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction <= 4 && satisfaction > 2">{{lang.getText("unsatisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction <= 2 && satisfaction > 1">{{lang.getText("very_unsatisfied")}}</p>
                    <p class="mt30 mb20 fw-600 fontSize16 align-center" *ngIf="satisfaction <= 1">{{lang.getText("extremely_unsatisfied")}}</p>
                
                    <div *ngIf="satisfaction < 5" class="align-center">
                        <p *ngIf="satisfaction < 5">{{lang.getText("reduce_message")}}</p>
                        <div *ngIf="satisfaction < 5" class="pl65 mt20 survey-satisfaction-summary">
                            <div class=" survey-options " *ngFor="let option of reduce" [hidden]="!option.data">
                                <label class="fw-400"> <div class="list-item"></div> {{lang.getText(option.label)}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="survey-card align-center" *ngIf="building.lobby_survey_status">
                    <div class="buildings_green"></div>
                    <h4 class="fw-400 mb20">{{lang.getText("lobby_page_thankyou")}}</h4>
                    <button arc-btn="reloadSurvey" type="button" class="btn btn-success reload-survey" (click)="reloadSurvey()">
                        {{lang.getText("start_survey")}}
                    </button>
                </div>

            </div>

            <div class="modal fade route_list" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="fw-600 floatl">{{lang.getText("route")}} {{global.getArabicNumber((selected_index + 1), lang.language.code)}}</h4>
                            <div arc-btn="closeRoute" class="modal-close cursor-pointer floatr" data-dismiss="modal">&times;</div>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="selected_route.unit == 'miles'" class="fontSize16 mb10">1. {{lang.getText("modal_message")}}</div>
                            <div *ngIf="selected_route.unit == 'kilometers'" class="fontSize16 mb10">{{lang.getText("modal_message_si")}}</div>
                            <div *ngIf="routeError" class="w100p pb25">
                                <p *ngIf="routeError" class="reading_error_label floatnone" [ngClass]="routeError ? 'Showing' : ''">{{lang.getText("mandatory_field_error")}}</p>
                            </div>
                            <div class="mode-option mode-option-border">
                                <div class="route-label">
                                    <div class="pt5 fw-600">{{lang.getText("travel_method")}}</div>
                                </div>
                                <div class="survey-unit">
                                    <div arc-dro="changeUnit" class="dropdown">
                                        <div class="cursor-pointer btn-survey-unit dropdown-toggle" type="button" data-toggle="dropdown">
                                            <span class="fw-600">{{lang.getText("distance_in")}} 
                                                <span class="fw-400 ml5">{{lang.getText(selected_route.unit)}}</span>
                                            </span>
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li arc-lst="miles" (click)="routeUnitChanged('miles')">
                                                <a>{{lang.getText('miles')}}</a>
                                            </li>
                                            <li arc-lst="kilometers" (click)="routeUnitChanged('kilometers')">
                                                <a>{{lang.getText('kilometers')}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let obj of selected_route.data | keys" class="mode-option" [ngClass]="obj.key != 'noofdays' ? 'mode-option-border' : 'pt30'">
                                <div class="route-label">
                                    <div class="route-icon {{obj.key}}" *ngIf="obj.key != 'noofdays'"></div>
                                    <div class="pt5">{{lang.getText(obj.key)}}</div>
                                    <!-- <div *ngIf="obj.key == 'noofdays'" class="fontSize16">2. {{lang.getText("Number of days in a week, you use this route")}}</div> -->
                                </div>
                                <div *ngIf="obj.key != 'noofdays'">
                                    <input [ngClass]="selected_route[obj.key] != undefined && selected_route[obj.key]['error'] ? 'error' : ''" value="{{selected_route.data[obj.key]}}" [(ngModel)]="selected_route.data[obj.key]" class="numbers-only input-sm route-value fw-400 {{obj.key}}_input" (input)="checkMileage(selected_route, obj.key)"/>
                                    <p *ngIf="selected_route[obj.key] != undefined && selected_route[obj.key]['error']" class="route-value-error">
                                        <svg class="error_symbol error_symbol-sm"></svg> {{lang.getText('high_mileage')}}. 
                                        <a (click)="ingnoreMileage(obj.key)">{{lang.getText('ignore')}}</a>
                                    </p>
                                </div>
                                <!-- <div *ngIf="obj.key == 'noofdays'" class="align-right">
                                    
                                    <select name="route_days" (change)="noOfDays(route_days)" [(ngModel)]="selected_route.data[obj.key]" value="{{selected_route.data[obj.key]}}" class="btn btn-default btn-survey numbers-only input-sm route-value" type="button" data-toggle="dropdown">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                    </select>
                                    
                                    <p *ngIf="error_for_days_flag" class="mt5">
                                        <span class="reading_error_label floatnone" [ngClass]="error_for_days_flag ? 'Showing' : ''">{{lang.getText("mandatory_field_error")}}</span>                                    
                                    </p>
                                </div> -->
                            </div>
                            <div class="mt10 align-left">
                                <span (click)="clearRoute()" class="fw-600 cursor-pointer underline">{{lang.getText("clear")}}</span>
                            </div>
                        </div>
                        <div class="align_lr" style="padding: 10px 35px 25px 35px;">
                            <button type="button" class="btn btn-default" data-dismiss="modal" style="height: 40px">
                                <span>{{lang.getText("cancel")}}</span>
                            </button>
                            <button arc-btn="updateRoute" [ngClass]="found_bad_input ? 'not-active' : ''" type="button" class="btn btn-primary btn-survey" (click)="updateRoute(selected_route, selected_index)"y>
                                <span>{{lang.getText("save")}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <p class="survey-footer">
        <span (click)="goTo('//arcskoru.com/about')" class="cursor-pointer">{{lang.getText("about_arc")}}</span> - <span (click)="goTo('//arcskoru.com/privacy-statement')" class="cursor-pointer">{{lang.getText("arc_policy")}}</span>
    </p>
    <div class="survey_submission_blank modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-obj.keyboard="false">
        <div class="modal-dialog modal-sm" style="width: 460px;">
            <div class="modal-content p50 modal_radius">
                <p class="align-center">
                    {{building.skipsurvey ? lang.getText("submit_both") : lang.getText("submit_error")}}
                </p>
                <div class="mt30 align-center"> 
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        {{lang.getText("ok")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="invalid_route modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm" style="width: 460px;">
        <div class="modal-content p50 modal_radius">
            <p class="align-center fs16">
                Visitors can only add 1 route.
            </p>
            <div class="mt30 align-center"> 
                <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" >Close</button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.css']
})
export class MiddlewareComponent implements OnInit
{

	constructor(
		private appData: AppData,
		private router: Router,
		private global: Global
	)
	{
		this.appData.set('current_app', 'login_middleware');
		this.appData.set('app_name', 'login_middleware');
	}

	ngOnInit()
	{
		if(this.global.getURLFromParam('next') != undefined)
		{
			this.appData.set('post_sso_redirect', decodeURIComponent(this.global.getURLFromParam('next')).replace('/', ''));
		}
		this.global.postLoginAppInit();
	}

}

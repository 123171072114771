<div class="content-wrap">
    <div id="content" class="content">
        <div [ngClass]="loading ? 'not-active' : ''">
            <app-loader *ngIf="loading"></app-loader>
            <div class="model_create_nav">
                <div class="progress_create">
                    <div [ngClass]="current_step == 'Select Projects' ? 'active' : ''" class="step flex">
                        <div class="step_val">1</div>
                        <div class="step_name">Select Projects</div>
                    </div>
                    <div [ngClass]="current_step == 'Billing Information' ? 'active' : ''" class="step flex">
                        <div class="step_val">2</div>
                        <div class="step_name">Billing Information</div>
                    </div>
                    <div [ngClass]="current_step == 'Generate Invoice' ? 'active' : ''" class="step flex">
                        <div class="step_val">3</div>
                        <div class="step_name">Download Invoice Now</div>
                    </div>
                </div>
                <div class="model_create_nav_btn">
                    <div (click)="goBack()" class="pl20 pr20 btn btn-default">
                        <span>
                            {{current_step == 'Generate Invoice' ? 'Done' : 'Cancel'}}
                        </span>
                    </div>
                </div>
            </div>
        
            <div class="form_wrapper flex">
                <div class="w350p">
                    <div id="price_summary">
                        <div class="total_price_eco fw-500">
                            <span>${{global.addCommas(total_price)}}</span>
                            <span class="fontSize15 grey ml5">per year</span>
                        </div>
                        <div class="line_height_18 fs15 fw-500 align-center">
                            {{selected_projects.length == 0 ? 'No' : selected_projects.length}} projects selected
                        </div>
                    </div>
                    <button class="btn btn-primary mt15 w350p fs16 line_height_16 p10_0" *ngIf="current_step == 'Select Projects'" [ngClass]="selected_projects.length == 0 ? 'not-active' : ''" (click)="goToBilling()">Next</button>
        
                    <div *ngIf="current_step == 'Billing Information'">
                        <div class="checkbox pl20">
                            <input style="width: initial;" [(ngModel)]="sign_agreement" type="checkbox" value="confidential" (click)="toggleFlag('sign_agreement')">
                            <label for="checkbox">
                                I agree to the terms and conditions of the <a (click)="global.openLink(global.getServiceAgreementUrl(), true)">service agreement</a>.
                            </label>
                        </div>
                    </div>
        
                    <button class="btn btn-primary mt5 w350p fs16 line_height_16 p10_0" *ngIf="current_step == 'Billing Information'" [ngClass]="total_price == 0 || total_price == undefined || submit_error || !sign_agreement ? 'not-active' : ''" (click)="verifyDetails()">Continue</button>
                    <button class="btn btn-primary mt15 w350p fs16 line_height_16 p10_0" *ngIf="current_step == 'Generate Invoice'" (click)="downloadInvoice()">Download Invoice</button>
        
                    <button class="btn btn-default mt15 w350p fs16 line_height_16 p10_0" *ngIf="current_step != 'Select Projects' && current_step != 'Generate Invoice'" (click)="goToProject()">Back</button>
        
                    <p *ngIf="submit_error && current_step == 'Billing Information'" class="fw-600 danger-color mt10">Please provide valid data</p>
                </div>
        
                <div class="w75p mb24" *ngIf="current_step == 'Select Projects'">
                    <div [ngClass]="loading ? 'not-active' : ''">
                        <app-loader *ngIf="loading"></app-loader>
                        
                        <div class="ml24 fs24 line_height_29 cursor-pointer flex" (click)="toggleList()">
                            <svg id="caret_project_selection" viewBox="0 0 448 512" data-fa-i2svg="" class="down_arrow">
                                <path _ngcontent-cuu-c143="" fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                            </svg>
                            <div>
                                <span>View My Projects</span>
                                <span class="fontSize15 grey ml5">({{count}} projects)</span>
                            </div>
                        </div>
                    
                        <div id="project_selection_table" class="ml24 mr24">
                            
                            <div class="table_dis mt10 w100p mb10">
                                <div class="table_cell checkbox mr5 blue_check select_all">
                                    <input (input)="toggleCheck('all', !select_all)" [(ngModel)]="select_all" name="select_all" type="checkbox" value="select_all">                
                                    <label for="checkbox" class="fs15 line_height_19 mb40">Select / Deselect All</label>
                                </div>
    
                                <div class="table_cell input-holder mt10 mb10 relative-position">
                                    <input type="text" placeholder="Search projects" class="arc-input" [(ngModel)]="query" (input)="searchProjects(query)">
                                    <svg (click)="disableSearch()" *ngIf="query.length > 0" width="24" height="24" viewBox="0 0 24 24" class="svg_hover"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                </div>
                            </div>

                            <table class="table table-striped arc-table">
                                <thead>
                                    <tr>
                                        <th class="unselectable"></th>
                                        <th class="unselectable cursor-pointer">
                                            Name  
                                            <span *ngIf="sort_column == 'name'" class="fa fa-caret-{{sort_order == '-' ? 'down': 'up'}}"></span>
                                        </th>
                                        <th class="hidden-xs unselectable cursor-pointer">
                                            Location
                                            <span *ngIf="sort_column == 'city'" class="fa fa-caret-{{sort_order == '-' ? 'down': 'up'}}"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let project of app_assets; let idx = index;" class="project_row" id="{{project.id}}" [hidden]="project.found == false" (click)="toggleCheck(idx)" [ngClass]="project.synced ? 'not-active' : ''">
                                        <td>
                                            <div [hidden]="project.synced || project.datavalidate.length > 0" class="checkbox mr16 mt-4 blue_check ck_gray_border select_all">
                                                <input [(ngModel)]="project.select_all" name="{{project.id}}" type="checkbox">                
                                                <label for="checkbox"></label>
                                            </div>

                                            <div *ngIf="project.datavalidate.length" class="error_symbol cursor-pointer" (click)="openErrorModal(project)"></div>
                                        </td>
                                        <td class="vertical-align-middle cursor-pointer">
                                            <div class="pl10">
                                                <span  class="fw600">{{project.name}}</span>
                                            </div>                               	
                                        </td>
                                        <td class="hidden-xs vertical-align-middle cursor-pointer">
                                            <div class="pl10">
                                                <span *ngIf="project.postalAddress.stateProvince == ''" >{{project.postalAddress.address}}, {{project.postalAddress.city}}, {{project.postalAddress.countryIsoCode}}</span>
                                                <span *ngIf="project.postalAddress.stateProvince != ''" >{{project.postalAddress.address}}, {{project.postalAddress.city}}, {{project.postalAddress.stateProvince}} {{project.postalAddress.countryIsoCode}}</span>
                                            </div>                              	
                                        </td>
                                    </tr>				    
                                </tbody>
                            </table>
                            <div class="noProjects" *ngIf="app_assets.length == 0">
                                Hello, Can't see your project? 
                                <a (click)="global.openEmail('contact@arcskoru.com')">Email </a> us the name or address and we will get back to you.
                            </div>
                        
                            <div [hidden]="true" class="align_r list_controls fw-500" *ngIf="app_assets.length > 0">
                                <div class="dropdown display-inline ml10">
                                    <span class="mr10">Rows per page:</span>
                                    <button class="btn dropdown-toggle dropdown-clean btn-default btn-sm" type="button" data-toggle="dropdown" >
                                        <span>{{page_size}}</span>
                                        <i class="fa fontSize16 ml10 fa-caret-down"></i>
                                    </button>
                                    <ul class="dropdown-menu" style="top: -122px;">
                                        <li (click)="getProjects(1, 10)">
                                            <a>10</a>
                                        </li>
                                        <li (click)="getProjects(1, 20)">
                                            <a>20</a>
                                        </li>
                                        <li (click)="getProjects(1, 30)">
                                            <a>30</a>
                                        </li>
                                        <li (click)="getProjects(1, 40)">
                                            <a>40</a>
                                        </li>
                                        <li (click)="getProjects(1, 50)">
                                            <a>50</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex pt2 ml40">
                                    <span class="mr30">
                                        Page {{page}} of {{page_count}}
                                    </span>
                                    <div>
                                        <i [ngClass]="(page - 1) == 0 ? 'not-active' : ''" (click)="getProjects(page - 1, page_size, (sort_order + sort_column))" class="mr40 fa fa-chevron-left"></i>
                                        <i [ngClass]="page == page_count ? 'not-active' : ''" (click)="getProjects(page + 1, page_size, (sort_order + sort_column))" class="fa fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div> 
                </div>
                <div class="w75p mb24" *ngIf="current_step == 'Billing Information'">
                    <div [ngClass]="loading ? 'not-active' : ''" class="project_create">
                        <app-loader *ngIf="loading"></app-loader>
                        <div class="ml24 fs24 line_height_29 cursor-pointer mb32" (click)="toggleList()">
                            Billing Information
                        </div>
                        <div id="details-form" class="ml24 mr40">
                            <table class="width-100-perc">
                                <thead>
                                    <tr>
                                        <th class="w15p"></th>
                                        <th class="w20p"></th>
                                        <th class="w20p"></th>
                                        <th class="w20p"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-margin-20">
                                    <tr>
                                        <td colspan="4">
                                            <div class="">
                                                <span class="fontSize16 fw600">Enter Billing Details</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt20" colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.first_name.error ? 'has-error' : ''">      
                                                <input maxlength="20" (input)="verifyField(paymentdata.first_name, 'first_name')" [(ngModel)]="paymentdata.first_name" name="first_name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>First Name</label>
                                                <span class="error">{{paymentdata_error.first_name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.last_name.error ? 'has-error' : ''">      
                                                <input maxlength="19" (input)="verifyField(paymentdata.last_name, 'last_name')" [(ngModel)]="paymentdata.last_name" name="last_name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Last Name</label>
                                                <span class="error">{{paymentdata_error.last_name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.email.error ? 'has-error' : ''">      
                                                <input maxlength="60" (input)="verifyField(paymentdata.email, 'email')" [(ngModel)]="paymentdata.email" name="email" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Email</label>
                                                <span class="error">{{paymentdata_error.email.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.phone.error ? 'has-error' : ''">      
                                                <input maxlength="40" (input)="verifyField(paymentdata.phone, 'phone')" [(ngModel)]="paymentdata.phone" name="phone" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Phone Number</label>
                                                <span class="error">{{paymentdata_error.phone.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.street.error ? 'has-error' : ''">      
                                                <input maxlength="60" (input)="verifyField(paymentdata.street, 'street')" [(ngModel)]="paymentdata.street" name="street" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Address</label>
                                                <span class="error">{{paymentdata_error.street.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.city.error ? 'has-error' : ''">      
                                                <input maxlength="40" (input)="verifyField(paymentdata.city, 'city')" [(ngModel)]="paymentdata.city" name="city" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>City</label>
                                                <span class="error">{{paymentdata_error.city.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="select-group group pr20" [ngClass]="paymentdata_error.country.error ? 'has-error' : ''">   
                                                <label [ngClass]="paymentdata.country != undefined ? 'selected_val' : ''">Country/Region</label>   
                                                <select autocomplete="false" name="country" (change)="updateCountry(paymentdata.country)" [(ngModel)]="paymentdata.country">
                                                    <option *ngFor="let country of country_list" value="{{country.code}}">{{country.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{paymentdata_error.country.help}}</span>
                                            </div>
                                        </td>
                                        <td colspan="2">
                                            <div class="select-group group" [ngClass]="paymentdata_error.state.error ? 'has-error' : ''">   
                                                <label [ngClass]="paymentdata.state != undefined ? 'selected_val' : ''">State</label>   
                                                <select autocomplete="false" [(ngModel)]="paymentdata.state" name="state">
                                                    <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{paymentdata_error.state.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group pr20" [ngClass]="paymentdata_error.zip_code.error ? 'has-error' : ''">      
                                                <input maxlength="10" (input)="verifyField(paymentdata.zip_code, 'zip_code')" [(ngModel)]="paymentdata.zip_code" name="zip_code" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Zip Code</label>
                                                <span class="error">{{paymentdata_error.zip_code.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                    
                                    <tr>
                                        <td colspan="4">
                                            <div class="">
                                                <span class="fontSize16 fw600">Owner Details</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pt20" colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.owner_name.error ? 'has-error' : ''">      
                                                <input maxlength="20" (input)="verifyField(paymentdata.owner_name, 'owner_name')" [(ngModel)]="paymentdata.owner_name" name="owner_name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>Owner Name</label>
                                                <span class="error">{{paymentdata_error.owner_name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="select-group group" [ngClass]="paymentdata_error.ownerType.error ? 'has-error' : ''">   
                                                <label class="blank" [ngClass]="paymentdata.ownerType != undefined ? 'selected_val' : ''">Owner Type</label>   
                                                <select (change)="verifyField(paymentdata.ownerType, 'ownerType')" [(ngModel)]="paymentdata.ownerType">
                                                    <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{paymentdata_error.ownerType.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="paymentdata_error.organization.error ? 'has-error' : ''">      
                                                <input id="organization" (input)="searchOrganization(paymentdata.organization)" [(ngModel)]="paymentdata.organization" type="text" required autocomplete="off">
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label>
                                                    Owner Organization
                                                </label>
                                                <span class="error">{{paymentdata_error.organization.help}}</span>
                                                
                                                <span class="">(<span (click)="openModal()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>
                    
                                                <div *ngIf="searchingOrganization && all_organizations.length > 0" class="searchDropdownOrganization">
                                                    <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name);">
                    
                                                        <div class="cursor-pointer vertical_align_middle">          
                                                            <span>{{org_name}}</span>
                                                        </div>
                    
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                             <div class="group" [ngClass]="paymentdata_error.owner_email.error ? 'has-error' : ''">      
                                                 <input (input)="verifyField(paymentdata.owner_email, 'owner_email')" [(ngModel)]="paymentdata.owner_email" type="text" required>
                                                 <span class="highlight"></span>
                                                 <span class="bar"></span>
                                                 <label>Owner Email</label>
                                                 <span class="error">{{paymentdata_error.owner_email.help}}</span>
                                             </div>
                                         </td>
                                     </tr>
                                     <tr>
                                          <td colspan="4">
                                             <div class="select-group group" [ngClass]="paymentdata_error.manageEntityCountry.error ? 'has-error' : ''">   
                                                 <label class="blank" [ngClass]="paymentdata.manageEntityCountry != undefined ? 'selected_val' : ''">Owner Country/Region</label>   
                                                 <select (change)="verifyField(paymentdata.manageEntityCountry, 'manageEntityCountry')" [(ngModel)]="paymentdata.manageEntityCountry">
                                                     <option *ngFor="let country of country_list" value="{{country.code}}">{{country.name}}</option>
                                                 </select>
                                                 <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                 <span class="highlight"></span>
                                                 <span class="bar"></span>
                                                 <span class="error">{{paymentdata_error.manageEntityCountry.help}}</span>
                                             </div>
                                         </td>
                                     </tr>
                                </tbody>
                            </table>
                        </div>
                    
                    </div>
                    
                    <modal #modal>
                        <app-organization (close_data)="closeModal()" (success_data)="getDataFromModal($event)"></app-organization>
                    </modal>
                    
                    <div class="modal fade portfolio-modal-wbg ofac_invalid_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                        <div class="modal-dialog modal-md">
                            <div class="modal-content">
                                
                                <div class="modal-header">
                                    <span class="fw-600 fs16">Owner organization is blocked</span>
                                </div>
                                <div class="modal-body pl25 mb20">
                                    <p class="fw-400">
                                        This project is located in a sanctioned country or belongs to a sanctioned entity 
                                        according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                                        or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                                        contact us</a> for more details.
                                    </p>  
                                    </div>
                                <div class="modal-footer">
                                    <button class="floatl btn btn-primary mr10" (click)="resetOrganizationDetails()">Continue</button>
                                    <button type="button" class="floatl btn btn-default" (click)="resetOrganizationDetails()">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w75p mb24" *ngIf="current_step == 'Generate Invoice'">
                    <div [ngClass]="loading ? 'not-active' : ''">
                        <app-loader *ngIf="loading"></app-loader>
                        
                        <div class="ml24 fs24 line_height_29">
                            Invoice Generated
                        </div>
                    
                        <div class="ml24 fs16 line_height_20 mt8">
                            Your invoice has successfully been created and emailed to <a>{{paymentdata.email}}</a>.
                        </div>
                    
                        <div class="ml24 fs16 line_height_20 mt8">
                            Please follow the instructions in the email.
                        </div>
                    
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="current_step != 'Generate Invoice'" [ngClass]="loading ? 'not-active' : ''" id="bottom_nav" class="bottom_nav">
    <div class="align_lr">
        <div class="fw-500 fs16 mr15 line_height_35">
            {{selected_projects.length == 0 ? 'No' : selected_projects.length}} {{selected_projects.length == 1 ? 'project' : 'projects'}} selected
        </div>
        <div>
            <button (click)="openProjectsModal()" class="btn btn-primary relative-position">
                <div class="fs15 fw-500">View selected projects</div>
            </button>
        </div>
    </div>
</div>

<modal #projects_modal>
    
    <div class="p1632 project_creation_modal certificate_modal">
        <div class="left-right-align mt16 pb16">
            <div class="fs24 line_height_40 fw-500">
                <div class="svg_header_modal">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path>
                    </svg>
                </div>
                <span translate>Selected projects</span>
            </div> 
        </div>

        <div>

            <table class="table table-striped arc-table">
                <thead>
                    <tr>
                        <th class="fw500 unselectable cursor-pointer">
                            Name
                        </th>
                        <th class="fw500 hidden-xs unselectable cursor-pointer">
                            Location
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of app_assets; let idx = index;" class="project_row" id="{{project.id}}" [hidden]="project.select_all == false">
                        <td class="vertical-align-middle cursor-pointer">
                            <div class="pl0">
                                <span  class="fw600">{{project.name}}</span>
                            </div>                               	
                        </td>
                        <td class="hidden-xs vertical-align-middle cursor-pointer">
                            <div class="pl10">
                                <span *ngIf="project.postalAddress.stateProvince == ''" >{{project.postalAddress.address}}, {{project.postalAddress.city}}, {{project.postalAddress.countryIsoCode}}</span>
                                <span *ngIf="project.postalAddress.stateProvince != ''" >{{project.postalAddress.address}}, {{project.postalAddress.city}}, {{project.postalAddress.stateProvince}} {{project.postalAddress.countryIsoCode}}</span>
                            </div>                              	
                        </td>
                    </tr>				    
                </tbody>
            </table>

        </div>
        
        <div>
            <button (click)="closeProjectsModal()" type="button" class="btn btn-default btn-md">
                Close
            </button>
        </div>
    </div>

</modal>

<modal #error_modal>
    
    <div class="p1632 project_creation_modal certificate_modal">
        <div class="left-right-align mt16 pb16">
            <div class="fs24 line_height_40 fw-500">
                <div class="svg_header_modal">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path>
                    </svg>
                </div>
                <span translate>Missing Details</span>
            </div> 
        </div>

        <div>
            <div class="fs16 mb20">
                Following details are missing for the project {{error_project.name}}. Please login to the EcoStruxure platform to add the missing information and then try syncing the project again in Arc.
            </div>

            <ul class="mb25">
                <li class="mb10 fs15" *ngFor="let item of error_project.datavalidate">
                    {{fields_mapping[item]}}
                </li>
            </ul>
        </div>
        
        <div>
            <button (click)="closeErrorModal()" type="button" class="btn btn-default btn-md">
                Close
            </button>
        </div>
    </div>

</modal>
import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

@Component({
  selector: 'app-data-transfer',
  templateUrl: './data-transfer.component.html',
  styleUrls: ['./data-transfer.component.css']
})
export class DataTransferComponent implements OnInit {
  source_leed_id: any = "";
  destonation_leed_id: any = "";
  loader: boolean = false;

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  refreshForms() {
    this.source_leed_id = "";
    this.destonation_leed_id = "";
  };

  transferData() {
    var data =
    {
      "source_id": this.source_leed_id,
      "target_id": this.destonation_leed_id
    };

    if (this.source_leed_id.length == 0) {
      this.alertService.alert('error', 'Enter source LEED ID', 5);
      return;
    }
    else if (this.destonation_leed_id.length == 0) {
      this.alertService.alert('error', 'Enter target LEED ID', 5);
      return;
    }
    this.loader = true;
    this.api.post('/optask/transferdata/', data ).subscribe(
      data => {
        this.loader = false;
        this.refreshForms();
        this.alertService.alert('success', 'Data transfer started.', 5);
      }, error => {
        this.loader = false;
        try {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class AppData
{
	constructor()
	{

	}

	public set(key: string, value: any)
	{
		sessionStorage.setItem(key, JSON.stringify(value));
	}

	public get(key: string)
	{
		if(sessionStorage.getItem(key) == 'undefined')
		{
			return undefined
		}
		let response = JSON.parse(<any>sessionStorage.getItem(key));
		if(key == 'buildingData' && response == undefined)
		{
			return {};
		}
		return response;
	}
	
	public delete(key: string)
	{
		sessionStorage.removeItem(key);
	}

	public clearAll()
	{
		sessionStorage.clear();
	}

	public setLocal(key: string, value: any)
	{
		localStorage.setItem(key, JSON.stringify(value));
	}

	public getLocal(key: string)
	{
		if(localStorage.getItem(key) == null)
		{
			return undefined
		}
		let response = JSON.parse(<any>localStorage.getItem(key));
		if(response == undefined)
		{
			return {};
		}
		return response;
	}

	public deleteLocal(key: string)
	{
		localStorage.removeItem(key);
	}
}

import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';
import { LanguageService } from '../services/language.service';

import { Config } from '../models/env-vars.model';
import { ActivatedRoute } from '@angular/router';

declare var Raphael: any;
declare var TimelineMax: any;
declare var Cubic: any;
declare var TweenMax: any;
declare var d3: any;
declare var Modernizr: any;

@Component({
  selector: 'app-plaque',
  templateUrl: './plaque.component.html',
  styleUrls: ['./plaque.component.css']
})
export class PlaqueComponent implements OnInit
{
	config = Config;

	cert: string = '';
	last_certified_score: any = '';
	name: string = '';
	key: string = '';
	last_certified_level: any = '';
	last_certified_date: any = '';
	isNotHardware: boolean = false;
	leed_id: number = 0;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		public lang: LanguageService,
		private route: ActivatedRoute
	)
	{
		route.params.subscribe((params: any) =>
		{
			this.leed_id = params['leed_id'];
			this.key = params['key'];
		});
	}

	ngOnInit(): void
	{
		this.cert = "Denied";
		this.isNotHardware = true;
		var global_leed_id = this.leed_id;
		var myKey = this.key;
		var project_key       = myKey;
		var subscription_key  = Config.sub_key;
		var basic_api_url     = Config.basic_api_url;

		var plaque: any = {
			width: 750,
			height: 750,
			radius: 216 / 2, // radius for the inner-most track
			radiusIncrement: 37,
			totalScore: 0,
			fadeSpeed: 1000,
			pieSpeed: 250,
			trackColors: ["#D0DD3D", "#55CAF5", "#84CCAF", "#A39F92", "#F2AC41"],
			loopCount: 0,
			offlineMode: 0,
			upScale: .88,
			downScale: .85,
			sliceUpScale: 1.1,
			sliceDownScale: 1.07,
			plaqueDownScale: 0.32,
			tempScale: .85,
			OSName: "Unknown OS",
			physicalScale: 1.25,
			//extract identity key from file
			identify: ()=>
			{
				var request = (<any>$).get('https://localhost/.identity').done((data: any) =>
				{
					var keyString = 'NAME';
					var result = null;
					var keys = data.split('\n');
					$.each(keys, function(index, key)
					{
						if(key.indexOf(keyString) > -1)
						{
							result = key.substring(keyString.length + 1);
						}
					});
					if(result)
					{
						plaque.identity = result;
						this.isNotHardware = false;
						//$("#container").css('top', '50%');
					}
					else
					{
						plaque.identity = '';
						this.isNotHardware = true;
						//$("#container").css('top', '42%');
					}
				}).error((data: any) =>
				{
					plaque.identity = '';
					this.isNotHardware = true;
					//$("#container").css('top', '42%');
				});
			},
			// plaque.setup gets called when the DOM is ready
			setup: (leed_id: any) =>
			{
				plaque.checkOS();
				plaque.leed_id = leed_id;
				plaque.raphael = Raphael("racetrack", plaque.width, plaque.height);
				plaque.tl = new TimelineMax(); // setup new http://www.greensock.com/tweenmax/ 
				plaque.customShapes();
				plaque.identify(); // get identity key from file
				plaque.getBuilding(
				{
					success: (data: any) =>
					{   
						plaque.offlineMode = 0;
						//these values need to be in plaque.data for async cert score calculations
						
						if(this.isNotHardware)
							plaque.getProjectNameAddress(data.name, data.state, data.country, data.street);

						plaque.data = {
							'certification': data.certification,
							'points': data.points,
							'base_score': data.base_score
						}
						plaque.initialize(
						{
							cert: plaque.data.certification,
							cert_points: plaque.data.points
						});
					},
					error: function(data: any)
					{
						plaque.offlineMode = 1;
						plaque.offline();
					}
				});
				// TODO: this is a placeholder for offline mode - probably delete eventually
				// plaque.offline();
			},

			getProjectNameAddress: (name: any, state: any, country: any, street: any)=>
			{
				this.api.get('/country/states/?subscription-key=' + subscription_key).subscribe(
					//success handler
					(data: any) =>
					{
						var country_perf_title = this.global.getCountryName(country, this.global.doSorting(data.countries));
                        var state_perf_title = this.global.getStateName(state, this.global.doSorting(data.divisions[country]));  
						
						if(state_perf_title == undefined)
						{
							state_perf_title = '';
						}

						if(state_perf_title != '')
						{
							street += ','
						}
						if(country_perf_title != '')
						{
							state_perf_title += ','
						}
						
						this.name = name;
						// this.street = street;
						// this.state = state_perf_title;
						// this.country = country_perf_title;
					},
					//error handler
					function(response: any)
					{
						
					}
				);
			},

			// checks the Operating System and sets scaling values accordingly the plaque accordingly
			checkOS: function()
			{
				// var OSName = "Unknown OS";
				if(navigator.appVersion.indexOf("Win") != -1) plaque.OSName = "Windows";
				if(navigator.appVersion.indexOf("Mac") != -1) plaque.OSName = "MacOS";
				if(navigator.appVersion.indexOf("X11") != -1) plaque.OSName = "UNIX";
				if(navigator.appVersion.indexOf("Linux") != -1) plaque.OSName = "Linux";
				// plaque.OSName = "Linux";
				if(plaque.OSName === 'MacOS' || plaque.OSName === 'Windows')
				{
					$('body').addClass('mac_win');
					$('<div class="pie_chart_container"></div>').appendTo('.front');
					plaque.upScale = 1.03;
					plaque.downScale = 1;
					plaque.sliceUpScale = 1.1;
					plaque.sliceDownScale = 1.07;
					plaque.plaqueDownScale = 0.32;
					plaque.tempScale = .9;
					plaque.physicalScale = 1.1;
				}
				else if(plaque.OSName === 'UNIX' || plaque.OSName === 'Linux')
				{
					$('body').addClass('unix_linux');
					$('<div class="pie_chart_container"></div>').appendTo('#container');
				}
			},
			// reload the plaque every tenth time because memory leak and animations get jumpy after a while
			refresh: function()
			{
				plaque.loopCount = 0;
				plaque.offlineMode = 0;
				//Change the opacity of the container from 0 to 1 at the time of page reload for smooth transition
				setTimeout(function()
				{
					plaque.getData(
					{
						cert: plaque.data.certification,
						cert_points: plaque.data.points
					});
				}, 1000);
			},
			plaqueImage: function()
			{
				var score = plaque.data.totalScore;
				var plaque_certification = plaque.data.certification;
				if(plaque_certification == "Certified" || plaque_certification == "Silver" || plaque_certification == "Gold" || plaque_certification == "Platinum" || plaque_certification == "certified" || plaque_certification == "silver" || plaque_certification == "gold" || plaque_certification == "platinum")
				{
					if(40 <= score && score <= 49)
					{
						$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_certified_front.svg');
						$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_certified.svg');
						$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_certified_front.svg');
					}
					else if(50 <= score && score <= 59)
					{
						$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_silver_front.svg');
						$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_silver.svg');
						$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_silver_front.svg');
					}
					else if(60 <= score && score <= 79)
					{
						$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_gold_front.svg');
						$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_gold.svg');
						$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_gold_front.svg');
					}
					else if(80 <= score)
					{
						$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_platinum_front.svg');
						$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_platinum.svg');
						$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_platinum_front.svg');
					}
					else
					{
						//Changed below urls to point to blank images instead of certified to fix this.
						$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank_front.svg');
						$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank.svg');
						$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank_front.svg');
					}
				}
				else if (plaque_certification == "5-STAR" || plaque_certification == "4-STAR" || plaque_certification == "3-STAR"){
					$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank.svg');
					$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank.svg');
					$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_blank.svg');
				}
				else
				{
					$('.front .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_nonleed.svg');
					$('.back .plaque_top').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_nonleed.svg');
					$('.temp .plaque_top_temp').attr('src', '../../assets/images/LEED-plaques/plaque_big_score_nonleed.svg');
				}
			},
			animateSlices: function()
			{
				// DG 091013: Adding check to see if server is available before refreshing plaque. If server not available, let the plaque continue running in offline mode
				if(plaque.loopCount === 3)
				{
					var checkServiceFlag = plaque.checkService();
					// alert("checkServiceFlag is " + checkServiceFlag);
					if(checkServiceFlag === 1)
					{
						// alert("OK to refresh page");
						plaque.offlineMode = 0;
						plaque.refresh();
					}
					else
					{
						// alert("Server offline; do not refresh page");
						// If plaque is already offline, keep it running
						if(plaque.offlineMode === 1)
						{
							if(!plaque.offlineData)
							{
								plaque.offline();
								//plaque.loopCount = 0;
							}
						}
						else
						{
							// if plaque is just gone offline, get the right data for offline display
							plaque.offlineMode = 1;
							plaque.offline();
						}
					}
					plaque.loopCount = 0;
				}
				else
				{
					plaque.loopCount += 1;
				}
				$('.slice').each(function(index)
				{
					var delay = index * 100;
					(<any>$(this)).transition(
					{
						scale: plaque.sliceUpScale,
						delay: delay
					}, plaque.pieSpeed, () =>
					{
						(<any>$(this)).transition(
						{
							scale: plaque.sliceDownScale
						}, plaque.pieSpeed, function()
						{
							if(index === 4)
							{
								// Pulse pie chart a little more, while showing the latest official LEED score
								(<any>$('.pie_chart_container')).transition(
								{
									delay: 500,
									scale: plaque.upScale,
									duration: plaque.fadeSpeed
								}).transition(
								{
									scale: plaque.downScale,
									duration: plaque.fadeSpeed
								}).transition(
								{
									delay: 500,
									scale: plaque.upScale,
									duration: plaque.fadeSpeed
								}).transition(
								{
									scale: plaque.downScale,
									duration: plaque.fadeSpeed
								}, function()
								{
									if(plaque.OSName === 'MacOS' || plaque.OSName === 'Windows')
									{
										//remove the pie chart if it's there already
										$('.pie_chart_container').clone().appendTo('.back');
									}
									plaque.animateAll();
								});
							}
						});
					})
				});
			},
			//Initiate all animations from here
			animateAll: function() {
				//$(".plaque_score").text(plaque.data.totalCertScore);
				
				if(!!(<any>navigator).systemLanguage || navigator.userAgent.search("Safari") >= 0){  

				 $(".plaque_score").text(plaque.data.totalScore);
				 
					 // Hide the pie chart
					 //$('.pie_chart_container').hide();
				 
					 //Flip the badge
					if (plaque.OSName === 'Linux' || plaque.OSName === 'UNIX') {
					   (<any>$('.pie_chart_container').css({ transformOrigin: '401px 401px' }))
					   .transition({rotateY: '180deg', duration: 1000});
					 }

					  TweenMax.to(".front",1,{rotationY:-180,backfaceVisibility:"hidden"}); 
					  TweenMax.to(".back",1,{rotationY:0,onComplete:pulse_piechart});
		 
		 
					 function pulse_piechart(){

					  if (plaque.OSName === 'MacOS' || plaque.OSName === 'Windows') {

							$('.pie_chart_container').first().remove();
					   }
						// }
					 
					 // Pulse pie chart a little more, while showing the latest official LEED score
					   (<any>$('.pie_chart_container')).transition({ delay:500, scale: plaque.upScale, duration: plaque.fadeSpeed})
						.transition({ scale: plaque.downScale, duration: plaque.fadeSpeed})
						.transition({ delay: 500, scale: plaque.upScale, duration: plaque.fadeSpeed})
						.transition({ scale: plaque.downScale, duration: plaque.fadeSpeed});
					// }
					 // Start puck & tracks animations
			 
					  (<any>$('.flipper').css({ transformOrigin: '375px 375px' }))
				 
					   .transition({delay:4000}, function() {
						 
					 //Animate out slices
				   
					   $('.slice').each(function(index) {

						 var timing = (index*100+100)/1000;
						 var a=$(this);
						 (<any>$(this)).transition({scale:0,delay:plaque.fadeSpeed/2}, plaque.fadeSpeed/1.5, 'cubic-bezier(' + timing + ', 0.100, 0.25, 1.000)')
				  
						 TweenMax.to(a,0.2,{opacity:0});
					 //  $(this).css("transform", "scale(0.32");
						 });
				 
						 var offScore = {s:plaque.data.totalCertScore};
						 TweenMax.to(
						   offScore, plaque.fadeSpeed/1500, 
						   {
							 s: plaque.data.baseScore, 
							 ease:Cubic.easeInOut,
							 onUpdate:function(score: any){
							   $(".plaque_score").text(Math.round(plaque.data.baseScore));
							 }, 
							 onUpdateParams:[offScore], 
							 delay: plaque.fadeSpeed/3000
						   }
						 );
				 
						 //Start racetrack animations
						 plaque.animateTracks(1000); 
				 
						 (<any>$('.plaque_top, .plaque_score').css({ transformOrigin: '375px 375px' }))
						   .transition({scale: plaque.plaqueDownScale, duration: plaque.fadeSpeed/1.5, delay: plaque.fadeSpeed/3}, function() {
							 //Fade out numbers and ghost tracks after a given amount of time
							 
							 // This code switched between last certified scores and current score
							 // $('#racetrack').transition({delay:30000}, function(){
							 //   plaque.water.animateValue(plaque.data.water.score);
							 //   plaque.waste.animateValue(plaque.data.waste.score);
							 //   plaque.transportation.animateValue(plaque.data.transportation.score);
							 //   plaque.humanexperience.animateValue(plaque.data.humanexperience.score);
							 //   plaque.energy.animateValue(plaque.data.energy.score);
				 
							 //   $('#score-puck').transition({rotateY: '360deg', duration:plaque.fadeSpeed/2});
							 //   $('.flipper').transition({rotateY: '0deg', duration: plaque.fadeSpeed/2}).css({scale:0.01});
							 // })
				 
							 (<any>$('#racetrack')).transition({delay:45000}, function(){
							   plaque.water.simplify();
							   plaque.waste.simplify();
							   plaque.transportation.simplify();
							   plaque.humanexperience.simplify();
							   plaque.energy.simplify();
							 })
							 //Fade out tracks after a given amount of time
							 .transition({
							   opacity: 0,
							   duration: plaque.fadeSpeed,
							   delay: 15000
							 }, function(){
							   if (plaque.OSName === 'MacOS' || plaque.OSName === 'Windows') {
								 //Scale down puck
								   $('#score-puck').css({scale: 0.001, opacity: 0});
							  
							   function again_racetracks()
								{
									  
								 (<any>$('.temp'))
								   .hide()
								   .css({ scale: .32, transformOrigin: '375px 375px'})
								   .show()
								   .transition({scale: plaque.tempScale, duration:plaque.fadeSpeed}, function() {
									 
									 TweenMax.to(".plaque_top",0,{transformOrigin: '375px 375px', scale:1});
									 TweenMax.to(".plaque_score",0,{transformOrigin: '375px 375px', scale:1});
								   
									 //Reset and scale plaque back up
									 // $('.flipper').css({ transformOrigin: '375px 375px', rotateY:'0deg', scale: 1 });
			 
									 plaque.raphael.clear();
									 plaque.setupTracks();
									 //Reset and restart animation
									 $('#score-puck').css({opacity:1});
									 $('#score-puck>.text').text("0");
									 $('#racetrack').css({opacity:1});
									 $('.temp').hide();
								 });
								 $('.pie_chart_container').clone().appendTo('.front');
								 $('.pie_chart_container').last().remove();
											 
								   setTimeout(function(){
				 
									  $('.slice').each(function(index) {
						   var timing = (index*100+100)/1000;
						   var a=$(this);
						TweenMax.to(a,0.2,{opacity:1});
					 //  $(this).css("transform", "scale(0.32");
						 });
				 
				 
								   },800);
				  
									 plaque.animateSlices();
				   
						 } 
									  TweenMax.to(".back",1,{rotationY:180,backfaceVisibility:"hidden"}); 
									  TweenMax.to(".front",1,{rotationY:0,onComplete:again_racetracks});
							   }
							   else if (plaque.OSName === 'UNIX' || plaque.OSName === 'Linux') {
								   //Scale down puck
								   $('#score-puck').css({scale: 0.001, opacity: 0});
								   //Reset and scale plaque back up
								   (<any>$('.flipper')).transition({rotateY: '0deg', duration: 1000}, function() {
									 // .temp div in place to deal with artifact issues in Chrome on Linux Ubuntu (on the Intel Nuc)
									 (<any>$('.temp'))
									   .hide()
									   .css({ scale: .32, transformOrigin: '375px 375px'})
									   .show()
									   .transition({scale: plaque.tempScale, duration:plaque.fadeSpeed}, function() {
										 $('.plaque_top, .plaque_score').css({ scale: 1, transformOrigin: '375px 375px'});
										 plaque.raphael.clear();
										 plaque.setupTracks();
										 //Reset and restart animation
										 $('#score-puck').css({opacity:1});
										 $('#score-puck>.text').text("0");
										 $('#racetrack').css({opacity:1});
										 $('.temp').hide();
									 });
									 $('.pie_chart_container').clone().appendTo('#container');
									 $('.pie_chart_container').first().remove();
									 $('.pie_chart_container').css("transform", "scale(0.85, 0.85) rotateY(0deg)")
									 plaque.animateSlices();
							   });
							   }
							   
							 });
						 });
					   });
					  }
				}                   

				else{
   

					$(".plaque_score").text(plaque.data.totalScore);

					// Hide the pie chart
					//$('.pie_chart_container').hide();

					//Flip the badge
					if (plaque.OSName === 'Linux' || plaque.OSName === 'UNIX') {

					  (<any>$('.pie_chart_container').css({ transformOrigin: '401px 401px' }))
					  .transition({rotateY: '180deg', duration: 1000});
					}

					(<any>$('.flipper').css({ transformOrigin: '375px 375px' }))
					  .transition({rotateY: '180deg', duration: 1000}, function(){

						if (plaque.OSName === 'MacOS' || plaque.OSName === 'Windows') {

						  $('.pie_chart_container').first().remove();
						}

						
					// Pulse pie chart a little more, while showing the latest official LEED score
					(<any>$('.pie_chart_container')).transition({ delay:500, scale: plaque.upScale, duration: plaque.fadeSpeed})
					 .transition({ scale: plaque.downScale, duration: plaque.fadeSpeed})
					 .transition({ delay: 500, scale: plaque.upScale, duration: plaque.fadeSpeed})
					 .transition({ scale: plaque.downScale, duration: plaque.fadeSpeed});

					})

					// Start puck & tracks animations
					.transition({delay:4000}, function() {
					  //Animate out slices
					  $('.slice').each(function(index) {
					  var timing = (index*100+100)/1000;
					  (<any>$(this)).transition({ scale: 0, delay: plaque.fadeSpeed/2}, plaque.fadeSpeed/1.5, 'cubic-bezier(' + timing + ', 0.100, 0.25, 1.000)')
					 
					});

					// Scale down large plaque, timing wise it hits the score puck as it starts scaling up
					//alert("Scaling down plaque");
					var offScore = {s:plaque.data.totalCertScore};
					TweenMax.to(
					  offScore, plaque.fadeSpeed/1500, 
					  {
						s: plaque.data.baseScore, 
						ease:Cubic.easeInOut,
						onUpdate:function(score: any){
						  $(".plaque_score").text(Math.round(plaque.data.baseScore));
						}, 
						onUpdateParams:[offScore], 
						delay: plaque.fadeSpeed/3000
					  }
					);

					//Start racetrack animations
					plaque.animateTracks(1000); 

					(<any>$('.plaque_top, .plaque_score').css({ transformOrigin: '375px 375px' }))
					  .transition({scale: plaque.plaqueDownScale, duration: plaque.fadeSpeed/1.5, delay: plaque.fadeSpeed/3});
					setTimeout(function(){
						//Fade out numbers and ghost tracks after a given amount of time
						
						// This code switched between last certified scores and current score
						// $('#racetrack').transition({delay:30000}, function(){
						//   plaque.water.animateValue(plaque.data.water.score);
						//   plaque.waste.animateValue(plaque.data.waste.score);
						//   plaque.transportation.animateValue(plaque.data.transportation.score);
						//   plaque.humanexperience.animateValue(plaque.data.humanexperience.score);
						//   plaque.energy.animateValue(plaque.data.energy.score);

						//   $('#score-puck').transition({rotateY: '360deg', duration:plaque.fadeSpeed/2});
						//   $('.flipper').transition({rotateY: '0deg', duration: plaque.fadeSpeed/2}).css({scale:0.01});
						// })

						(<any>$('#racetrack')).transition({delay:45000}, function(){
						  plaque.water.simplify();
						  plaque.waste.simplify();
						  plaque.transportation.simplify();
						  plaque.humanexperience.simplify();
						  plaque.energy.simplify();
						})

						//Fade out tracks after a given amount of time
						.transition({
						  opacity: 0,
						  duration: plaque.fadeSpeed,
						  delay: 15000
						}, function(){
						  if (plaque.OSName === 'MacOS' || plaque.OSName === 'Windows') {
							//Scale down puck
							  $('#score-puck').css({scale: 0.001, opacity: 0});
							  //Reset and scale plaque back up
							  (<any>$('.flipper')).transition({rotateY: '0deg', duration: 1000}, function() {
								// .temp div in place to deal with artifact issues in Chrome on Linux Ubuntu (on the Intel Nuc)
								(<any>$('.temp'))
								  .hide()
								  .css({ scale: .32, transformOrigin: '375px 375px'})
								  .show()
								  .transition({scale: plaque.tempScale, duration:plaque.fadeSpeed}, function() {
									$('.plaque_top, .plaque_score').css({ scale: 1, transformOrigin: '375px 375px'});
									//Reset and scale plaque back up
									// $('.flipper').css({ transformOrigin: '375px 375px', rotateY:'0deg', scale: 1 });
									plaque.raphael.clear();
									plaque.setupTracks();
									//Reset and restart animation
									$('#score-puck').css({opacity:1});
									$('#score-puck>.text').text("0");
									$('#racetrack').css({opacity:1});
									$('.temp').hide();
								});
								$('.pie_chart_container').clone().appendTo('.front');
								$('.pie_chart_container').last().remove();
								(<any>$('.plaque_top, .plaque_score').css({ transformOrigin: '375px 375px' })).transition({scale: 1, duration:plaque.fadeSpeed});
								plaque.animateSlices();
						  });
						  }
						  else if (plaque.OSName === 'UNIX' || plaque.OSName === 'Linux') {
							  //Scale down puck
							  $('#score-puck').css({scale: 0.001, opacity: 0});
							  //Reset and scale plaque back up
							  (<any>$('.flipper')).transition({rotateY: '0deg', duration: 1000}, function() {
								// .temp div in place to deal with artifact issues in Chrome on Linux Ubuntu (on the Intel Nuc)
								(<any>$('.temp'))
								  .hide()
								  .css({ scale: .32, transformOrigin: '375px 375px'})
								  .show()
								  .transition({scale: plaque.tempScale, duration:plaque.fadeSpeed}, function() {
									$('.plaque_top, .plaque_score').css({ scale: 1, transformOrigin: '375px 375px'});
									//Reset and scale plaque back up
									// $('.flipper').css({ transformOrigin: '375px 375px', rotateY:'0deg', scale: 1 });
									plaque.raphael.clear();
									plaque.setupTracks();
									//Reset and restart animation
									$('#score-puck').css({opacity:1});
									$('#score-puck>.text').text("0");
									$('#racetrack').css({opacity:1});
									$('.temp').hide();
								});
								$('.pie_chart_container').clone().appendTo('#container');
								$('.pie_chart_container').first().remove();
								$('.pie_chart_container').css("transform", "scale(0.85, 0.85) rotateY(0deg)")
								plaque.animateSlices();
						  });
						  }
						  
						});
					}, plaque.fadeSpeed/3);
				 });
			   } 
			},
			pieChart: function() {

				var h = 800,
				w = 800,
				roomForGrowth = 400,
				r: any = 350                            //radius

				var data = [{"value": (<any>Math).map(plaque.data.energy.score, 0, plaque.data.energy.max, 0, 100)}, 
						{"value": (<any>Math).map(plaque.data.water.score, 0, plaque.data.water.max, 0, 100)}, 
						{"value": (<any>Math).map(plaque.data.waste.score, 0, plaque.data.waste.max, 0, 100)},
						{"value": (<any>Math).map(plaque.data.transportation.score, 0, plaque.data.transportation.max, 0, 100)}, 
						{"value": (<any>Math).map(plaque.data.humanexperience.score, 0, plaque.data.humanexperience.max, 0, 100)}];
				
				if(navigator.userAgent.search("Chrome") >= 0 || navigator.userAgent.search("Firefox") >= 0)
					  {
						  r = "350";
					   }
					   else
					   {
						  r = "375";
					   }

				$(".pie_chart_container").empty();

				this.vis = d3.select(".pie_chart_container")
					.append("svg:svg")              //create the SVG element inside .pie_chart_container
					.data([data])                   //associate our data with the document
					.attr("width", w)           //set the width and height of our visualization (these will be attributes of the <svg> tag
					.attr("height", h)
					.append("svg:g")                //make a group to hold our pie chart
					.attr("transform", "translate(" + roomForGrowth + "," + roomForGrowth + ")")    //move the center of the pie chart from 0, 0 to radius, radius

				this.arc = d3.svg.arc()              //this will create <path> elements for us using arc data
					.outerRadius(r);

				this.arcLarge = d3.svg.arc()
					.outerRadius(r + 25);        

				this.arcPulse = d3.svg.arc()
					.outerRadius(r + 45);                

				this.pie = d3.layout.pie()           //this will create arc data for us given a list of values
					.value(function(d: any) { return d.value; })
					.sort(null);    //we must tell it out to access the value of each element in our data array


				this.arcs = this.vis.selectAll("g.slice")     //this selects all <g> elements with class slice (there aren't any yet)
					.data(this.pie)                          //associate the generated pie data (an array of arcs, each having startAngle, endAngle and value properties) 
					.enter()                            //this will create <g> elements for every "extra" data element that should be associated with a selection. The result is creating a <g> for every object in the data array
					.append("svg:g")
					.attr("id", function(d: any, i: any) {return "slice_" + i;})                    //create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
					.attr("class", "slice");

				  
				this.arcs.append("svg:path")
						.attr("fill", function(d: any, i: any) { return plaque.trackColors[i]; } ) //set the color for each slice
						.attr("d", this.arc);                                    //this creates the actual SVG path using the associated data (pie) with the arc drawing function

			  },

			animateTracks: function(delay: any)
			{
				//Animate tracks in
				delay /= 1000; //convert ms to s for tweenmax
				//this.icon = plaque.raphael.image(image, 109, (plaque.height/2 - this.radius - 10), 20, 20).attr({opacity:1}); 
				//plaque.humanexperience.icon.attr({opacity:1});
				//plaque.humanexperience.icon.attrs.opacity = 1;
				plaque.humanexperience.animateIn(delay);
				plaque.transportation.animateIn(delay);
				plaque.waste.animateIn(delay);
				plaque.water.animateIn(delay);
				plaque.energy.animateIn(delay);
				//====== Start changes for ldpf-137===========
				plaque.iconDisplay();
				//===================== END ==================
			},
			// track constructor
			track: function(label: any, val: any, max: any, radius: any, color: any)
			{
				this.val = 0;
				this.rVal = val; //for storing a potential real value for visualizing sub 12 o'clock values
				this.max = max;
				this.radius = radius;
				this.name = label;
				this.front = plaque.raphael.set(); //top layer  (for most text)
				this.back = plaque.raphael.set(); //bottom layer (for gfx + max labels) 
				this.color = color;
				if(this.name === "energy")
				{
					this.category_type = 'ENERGY'
				}
				else if(this.name === "water")
				{
					this.category_type = 'WATER'
				}
				else if(this.name === "waste")
				{
					this.category_type = 'WASTE'
				}
				else if(this.name === "transportation")
				{
					this.category_type = 'TRANSPORTATION'
				}
				else if(this.name === "human_experience")
				{
					this.category_type = 'HUMAN_EXPERIENCE'
				}
				//draw ghost track
				this.ghost = plaque.raphael.path().attr(
				{
					arc: [100, 100, this.radius, false]
				}).attr(
				{
					stroke: '#414041',
					opacity: 0
				});
				//draw max label
				var x = this.ghost.getPointAtLength(this.ghost.getTotalLength()).x;
				var y = 306; //for some reason Raphael.js does not calculate the correct/same Y value for all track radii, so we're setting it manually.
				this.maxLabel = plaque.raphael.text(x, y, this.max);
				this.maxLabel.node.setAttribute('class', 'arc-label max');
				//draw name label
				this.label = plaque.raphael.text(140, plaque.height / 2 - this.radius + 2, this.name).attr(
				{
					'text-anchor': 'start',
					opacity: 0
				});
				this.label.node.setAttribute('class', 'arc-label name');
				// draw icon
				//Check the browser support for svg
				if(!Modernizr.svg)
				{
					var image = "../../assets/images/icon_water_2x.png";
					if(this.name === "human_experience")
					{
						image = "../../assets/images/icon_human_2x.png";
					}
					else
					{
						image = "../../assets/images/icon_" + this.category_type.toLowerCase() + "_2x.png";
					}
					if(this.name === "water" || this.name === "energy" || this.name === "human_experience")
					{
						this.icon = plaque.raphael.image(image, 113, (plaque.height / 2 - this.radius - 10), 11, 20).attr(
						{
							opacity: 0
						});
					}
					else
					{
						this.icon = plaque.raphael.image(image, 109, (plaque.height / 2 - this.radius - 10), 20, 20).attr(
						{
							opacity: 0
						});
					}
				}
				else
				{
					var image = "../../assets/images/icon_water_2x.svg";
					if(this.name === "human_experience")
					{
						image = "../../assets/images/icon_human_2x.svg";
					}
					else
					{
						image = "../../assets/images/icon_" + this.category_type.toLowerCase() + "_2x.svg";
					}
					if(this.name === "water" || this.name === "energy" || this.name === "human_experience")
					{
						this.icon = plaque.raphael.image(image, 113, (plaque.height / 2 - this.radius - 10), 11, 20).attr(
						{
							opacity: 0
						});
					}
					else
					{
						this.icon = plaque.raphael.image(image, 109, (plaque.height / 2 - this.radius - 10), 20, 20).attr(
						{
							opacity: 0
						});
					}
				}
				//draw track & get endpoint
				this.track = plaque.raphael.path().attr(
				{
					arc: [this.val, this.max, this.radius, true]
				}).attr(
				{
					stroke: color,
					opacity: 0
				});
				var endPoint = this.track.getPointAtLength(this.track.getTotalLength() + 1);
				//draw little triangle at the end of the track
				this.triangle = plaque.raphael.path().attr(
				{
					triangle: [endPoint.x, endPoint.y, 30]
				}).attr(
				{
					"fill": this.color,
					"stroke": 0,
					opacity: 0
				});
				//draw value label
				this.valueLabel = plaque.raphael.text(endPoint.x, endPoint.y, this.val).attr(
				{
					opacity: 0
				});
				this.valueLabel.node.setAttribute('class', 'arc-label value');
				//Put stuff on their layers to make sure the order is correct
				// this.front.push(this.label, this.valueLabel,this.icon,this.triangle);
				// this.front.toFront();
				this.back.push(this.track, this.maxLabel, this.ghost);
				this.back.toBack();
				this.front.push(this.label, this.valueLabel);
				this.front.toFront();
				//Function for the intro animation
				this.animateIn = function(del: any)
					{
						//Animate ghost first
						//====== Start changes for ldpf-137===========
						/*TweenMax.to(this.icon, 0.2,
						{
							raphael:
							{
								opacity: 1
							},
							delay: del
						});*/
						//===================== END ==================
						plaque.tl.add([
							TweenMax.to(this.ghost, 1,
							{
								raphael:
								{
									opacity: 1
								},
								delay: del
							}),
							TweenMax.to(this.icon, 0.5,
							{
								raphael:
								{
									opacity: 1
								},
								delay: 0.8 + del
							}),
							TweenMax.to(this.label, 0.5,
							{
								raphael:
								{
									opacity: 1
								},
								delay: 0.8 + del
							})
						]);
						//Fix value if it results in an animation below 12 o'clock
						var twelveoclock = this.max * (this.track.thresholds.break1 / 1000);
						var adjusted = false;
						if(val < twelveoclock)
						{
							val += twelveoclock; //momentarily altering val while animating, real val is stored in rVal
							adjusted = true;
						}
						//Then the rest
						var timing = (val / this.max) * 4; //4 = max 4 seconds
						plaque.tl.add([
							TweenMax.to(this.track, 1,
							{
								raphael:
								{
									opacity: 1
								},
								delay: del
							}),
							TweenMax.to(this.triangle, 1,
							{
								raphael:
								{
									opacity: 1
								},
								delay: del
							}),
							TweenMax.to(this, timing,
							{
								val: val,
								ease: Cubic.easeOut,
								onUpdate: plaque.changeTrack,
								onUpdateParams: [this, adjusted],
								delay: del
							})
						]);
					}
					//TODO Stupidly redundant. Fix when you have time.
				this.animateValue = function(nVal: any)
					{
						//Fix value if it results in an animation below 12 o'clock
						var twelveoclock = this.max * (this.track.thresholds.break1 / 1000);
						var adjusted = false;
						if(nVal < twelveoclock)
						{
							nVal += twelveoclock; //momentarily altering val while animating, real val is stored in rVal
							adjusted = true;
						}
						var timing = 2; //Fixed timing right now
						TweenMax.to(this, timing,
						{
							val: nVal,
							ease: Cubic.easeInOut,
							onUpdate: plaque.changeTrack,
							onUpdateParams: [this, adjusted]
						})
					}
					//Function for mid-animation visual simplification
				this.simplify = function()
				{
					TweenMax.to(this.ghost, 2,
					{
						raphael:
						{
							opacity: 0
						}
					});
					TweenMax.to(this.valueLabel, 2,
					{
						raphael:
						{
							opacity: 0
						}
					});
				}
			},
			//====== Start changes for ldpf-137===========
			iconDisplay: function()
			{
				$("image").each(function(index)
				{
				   //var $userAgent = '';
					if(navigator.userAgent.search("Chrome") >= 0 || navigator.userAgent.search("Firefox") >= 0)
						   {
							   this.style.display="none";
							}
							else
							{
							   //this.style.display="none"; 
							}
					});
			   },
			//============= END ==========================
			// gets called by tweenmax at each moment of animation
			changeTrack: function(obj: any, adjusted: any)
			{
				//====== Start changes for ldpf-137===========
				if(obj.name == "human_experience")
				{
					$("image").each(function(index)
					{
						if(index == 4)
						{
							this.style.display = "block";
						}
					});
				}
				else if(obj.name == "transportation")
				{
					$("image").each(function(index)
					{
						if(index == 3)
						{
							this.style.display = "block";
						}
					});
				}
				else if(obj.name == "waste")
				{
					$("image").each(function(index)
					{
						if(index == 2)
						{
							this.style.display = "block";
						}
					});
				}
				else if(obj.name == "water")
				{
					$("image").each(function(index)
					{
						if(index == 1)
						{
							this.style.display = "block";
						}
					});
				}
				else if(obj.name == "energy")
				{
					$("image").each(function(index)
					{
						if(index == 0)
						{
							this.style.display = "block";
						}
					});
				}
				//================ END ======================
				var val = obj.val;
				if(adjusted)
				{
					val = obj.rVal;
				}
				obj.track.attr(
				{
					arc: [obj.val, obj.max, obj.radius, true]
				});
				//Update fill, calculate the endpoint
				var endPoint = obj.track.getPointAtLength(obj.track.getTotalLength() - 1);
				//Check if we have a valid endPoint, otherwise skip ahead – it'll be ready in the next iteration
				if(!isNaN(endPoint.x) && !isNaN(endPoint.y))
				{
					//Calculate angle between center and endpoint, so we can rotate the triangle
					var mid = {
						x: plaque.width / 2,
						y: plaque.height / 2
					};
					var angle = (Math.atan2(mid.y - endPoint.y, mid.x - endPoint.x) * 180 / Math.PI) - 90; //angle in degrees
					if(obj.track.progress < obj.track.thresholds.break1)
					{
						angle = 0;
					}
					else if(angle > -90 && angle < -1)
					{
						angle = -90;
					}
					//Rotate that pesky triangle
					obj.triangle.attr(
					{
						triangle: [endPoint.x, endPoint.y, 30]
					}); //move first
					obj.triangle.transform("r" + angle + "," + endPoint.x + "," + endPoint.y); //then rotate 
					//Move the label
					obj.valueLabel.attr(
					{
						x: endPoint.x,
						y: endPoint.y,
						text: Math.round(val)
					});
				}
				// fade-in value label after 12oclock
				if(obj.track.progress >= obj.track.thresholds.break1)
				{
					TweenMax.to(obj.valueLabel, 0.2,
					{
						raphael:
						{
							opacity: 1
						}
					})
				}
				// Update the total score puck
				obj.val = val;
				plaque.totalScore = plaque.data.baseScore + plaque.water.val + plaque.waste.val + plaque.humanexperience.val + plaque.energy.val + plaque.transportation.val;
				//plaque.puck.attr({text: Math.round(plaque.totalScore)});
				$('.plaque_score').text(Math.round(plaque.totalScore));
				$('#score-puck>.text').text(Math.round(plaque.totalScore));
			},
			//Set up the 5 tracks
			setupTracks:() =>
			{
				plaque.energy = new plaque.track("energy", plaque.data.energy.certScore, plaque.data.energy.max, plaque.radius + plaque.radiusIncrement * 4, plaque.data.energy.color);
				plaque.water = new plaque.track("water", plaque.data.water.certScore, plaque.data.water.max, plaque.radius + plaque.radiusIncrement * 3, plaque.data.water.color);
				plaque.waste = new plaque.track("waste", plaque.data.waste.certScore, plaque.data.waste.max, plaque.radius + plaque.radiusIncrement * 2, plaque.data.waste.color);
				plaque.transportation = new plaque.track("transportation", plaque.data.transportation.certScore, plaque.data.transportation.max, plaque.radius + plaque.radiusIncrement, plaque.data.transportation.color);
				plaque.humanexperience = new plaque.track("human_experience", plaque.data.humanexperience.certScore, plaque.data.humanexperience.max, plaque.radius, plaque.data.humanexperience.color);
			},
			//Set up Raphael.js custom attributes
			customShapes: function()
			{
				//Triangle
				plaque.raphael.customAttributes.triangle = function(x: any, y: any, size: any)
				{
					var path: any = ["M", x, y - size / 2]; //start out in the top left corner
					path = path.concat(["L", x + 5, y - size / 2]); //short straight line
					path = path.concat(["L", x + size / 2, y]); //line to the tip
					path = path.concat(["L", x + 5, y + size / 2]); //line back to lower left corner
					path = path.concat(["L", x, y + size / 2]); //short straight line
					path = path.concat(["z"]).join(" ");
					return {
						path: path
					};
				};
				//Custom arc
				plaque.raphael.customAttributes.arc = function(value: any, total: any, radius: any, shortened: any)
				{
					var strokewidth = 30;
					var stopline = (plaque.height / 2) - plaque.radius + strokewidth - 7; //stop y
					var startline = 104; //start x
					var hwidth = plaque.radius + (4 * plaque.radiusIncrement) + strokewidth / 2; //horizontal line max width
					//start path
					var path: any = ["M", startline, plaque.width / 2 - radius];
					//progress is max 1000 units
					this.progress = 1000 / total * value;
					this.thresholds = {
						break1: 220,
						break2: 950
					};
					//first draw the straight line until we hit thresholds.break1
					hwidth = Math.min(hwidth, hwidth * this.progress / this.thresholds.break1);
					path.push(["H", hwidth + startline]);
					//draw arc until progress hits thresholds.break2
					if(this.progress > this.thresholds.break1)
					{
						var alpha = Math.min((<any>Math).map(this.progress, this.thresholds.break1, this.thresholds.break2, 0, 270), 270);
						var a = (90 - alpha) * Math.PI / 180;
						var x = plaque.width / 2 + radius * Math.cos(a);
						var y = plaque.height / 2 - radius * Math.sin(a);
						path.push(['M', plaque.width / 2, plaque.height / 2 - radius]);
						path.push(['A', radius, radius, 0, +(alpha > 180), 1, x, y]);
						//and the last 100 is the little bit at the end
						if(this.progress > this.thresholds.break2)
						{
							path.push(['M', x, y]);
							if(shortened)
							{
								stopline += 14;
							} //shorten the stopline
							path.push(['V', (<any>Math).map(this.progress, this.thresholds.break2, 1000, stopline + (plaque.height / 2 - stopline), stopline)]);
						}
					}
					//return the complete path
					return {
						path: path,
						'stroke-width': strokewidth
					};
				};
			},
			offline: function()
			{
				//Create object based on early sketch
				if(!plaque.offlineData)
				{
					// alert("Offline Data not available.")
					// If this code is being reached, something is wrong and this should be replaced with a static image instead of hardcoded values
					plaque.data = JSON.parse(<any>(localStorage.getItem('offline_data')));
				}
				else
				{
					// alert("Using offline data");
					plaque.setData();
					$(".plaque_score").text(plaque.data.totalCertScore);                         
					plaque.setupTracks();
					plaque.plaqueImage();
					plaque.animateSlices();
					plaque.offlineData = plaque.data;
				}
				//Set up data dependent graphics
				// $(".plaque_score").text(plaque.data.totalCertScore);
				$(".plaque_score").text(plaque.data.totalScore);
				plaque.setupTracks();
				plaque.pie = new plaque.pieChart();
				plaque.animateSlices();
			},
			//kickoff function takes the building args, loads data once, begins animation
			initialize: function(args: any)
			{
				plaque.getData(args);
				plaque.beginAnimation();
			},
			//function for data pulls
			getData: function(args: any)
			{
				this.effective_at = '';
				if(!args)
				{
					var args: any = {
						cert: plaque.data.certification,
						cert_points: plaque.data.points,
					}
				}
				var dataFromRemote;
				$.ajax(
				{
					type: "GET",
					url: Config.basic_api_url + "/assets/LEED:" + this.leed_id + "/scores/v2/?key=" + project_key + "&subscription-key=" + subscription_key,
					async: false,
					beforeSend: function(request)
					{
						if(plaque.identity && plaque.identity.length)
						{
							request.setRequestHeader('id', plaque.identity);
						}
					},
					data:
					{
						'id': plaque.identity
					},
					error: function()
					{
						plaque.offlineMode = 1;
						plaque.offline();
					},
					success: function(data)
					{
						this.effective_at = data.effective_at;
						if(!data.scores)
						{
							data = {
								"scores":
								{
									"energy": null,
									"water": null,
									"base": plaque.data.base_score,
									"human_experience": null,
									"waste": null,
									"transport": null
								}
							};
						}
						dataFromRemote = data;
						var score_water = plaque.checkValue(data.scores.water);
						var score_human = plaque.checkValue(data.scores.human_experience);
						var score_trans = plaque.checkValue(data.scores.transport);
						var score_energy = plaque.checkValue(data.scores.energy);
						var score_waste = plaque.checkValue(data.scores.waste);
						var score_base = plaque.checkValue(data.scores.base);
						var total_score = score_water + score_human + score_trans + score_energy + score_waste + score_base;

						this.total_score = total_score;

						data['maxima'] = data.maxima;

						//Check maxima values
						if(data.maxima == undefined)
						{
							$.ajax(
							{
								type: "GET",
								url: Config.basic_api_url + "/assets/LEED:" + plaque.leed_id + "/scores/weights/?key=" + project_key + "&subscription-key=" + subscription_key,
								async: false,
								success: function(data_maxima)
								{
									data.maxima = data_maxima
								}
							});
						}
						plaque.data = {
								'certification': args.cert,
								'points': args.cert_points,
								'totalScore': total_score,
								'baseScore': score_base,
								'totalCertScore': args.cert_points,
								'water':
								{
									score: score_water,
									certScore: score_water,
									color: "#55CAF5", //"#78c5b5"
									max: plaque.checkValue(data.maxima.water)
								},
								'humanexperience':
								{
									score: score_human,
									certScore: score_human,
									color: "#F2AC41", //"#6c7fb9"
									max: plaque.checkValue(data.maxima.human_experience)
								},
								'transportation':
								{
									score: score_trans,
									certScore: score_trans,
									color: "#A39F92", //"#b5a68c"
									max: plaque.checkValue(data.maxima.transport)
								},
								'energy':
								{
									score: score_energy,
									certScore: score_energy,
									color: "#D0DD3D", //"#f59a44"
									max: plaque.checkValue(data.maxima.energy)
								},
								'waste':
								{
									score: score_waste,
									certScore: score_waste,
									color: "#84CCAF", //"#c5da4f"
									max: plaque.checkValue(data.maxima.waste)
								}
							}
							//Start changes for ldpf-152
						localStorage.setItem('offline_data', JSON.stringify(plaque.data));
						//End changes
						plaque.setData(); //update chart
					}
				});
			},
			beginAnimation: function()
			{
				//Set up data dependent graphics
				$(".plaque_score").text(plaque.data.totalCertScore);
				//$(".plaque_score").text(plaque.data.totalScore);
				plaque.setupTracks();
				plaque.plaqueImage();
				plaque.pie = new plaque.pieChart();
				plaque.animateSlices();
				//alert("Saving data to offline");
				plaque.offlineData = plaque.data;
			},
			checkValue: function(value: any)
			{
				if(!value)
				{
					return 0; //we don't want nothing
				}
				else
				{
					return value;
				}
			},
			getBuilding: (args: any) =>
			{
				$.ajax(
				{
					type: "GET",
					url: Config.basic_api_url + "/assets/LEED:" + this.leed_id + "/?key=" + project_key + "&subscription-key=" + subscription_key,
					dataType: 'json',
					beforeSend: function(request)
					{
						if(plaque.identity && plaque.identity.length)
						{
							request.setRequestHeader('id', plaque.identity);
						}
					},
					data:
					{
						'id': plaque.identity
					},
					success: (data) =>
					{
						this.cert = data.certification.toLowerCase();
						
						if(this.cert == 'gold' || this.cert == 'silver' || this.cert == 'bronze' || this.cert == 'platinum' || this.cert == 'certified')    
						{
							
						}
						else
						{
							this.cert = 'Denied';
						}
						
						if(data.certifications.length > 0)
						{
							var certifications = data.certifications[0];
							for(var a = 1; a < data.certifications.length; a++)
							{
								if(new Date(data.certifications[a].date_certified) > new Date(certifications.date_certified))
								{
									certifications = data.certifications[a];
								}
							}
							this.last_certified_score = certifications.certification_points;
							this.last_certified_level = certifications.certification_level;
							this.last_certified_date = certifications.date_certified;
						}
						else
						{
							this.last_certified_score = undefined;
							this.last_certified_level = undefined;
							this.last_certified_date = undefined;
						}

						args.success(data);
					}
				});
			},
			checkService: (args: any) =>
			{
				var strReturn = 0;
				$.ajax(
				{
					type: "GET",
					url: Config.basic_api_url + "/assets/LEED:" + this.leed_id + "/?key=" + project_key + "&subscription-key=" + subscription_key,
					dataType: 'json',
					beforeSend: function(request)
					{
						if(plaque.identity && plaque.identity.length)
						{
							request.setRequestHeader('id', plaque.identity);
						}
					},
					data:
					{
						'id': plaque.identity
					},
					success: (data) =>
					{
						this.cert = data.certification.toLowerCase();
						
						if(this.cert == 'gold' || this.cert == 'silver' || this.cert == 'bronze' || this.cert == 'platinum' || this.cert == 'certified')    
						{
							
						}
						else
						{
							this.cert = 'Denied';
						}

						var certifications = data.certifications[0];
						for(var a = 1; a < data.certifications; a++)
						{
							if(new Date(data.certifications[a].date_certified) > new Date(certifications.date_certified))
							{
								certifications = data.certifications[a];
							}
						}
						this.last_certified_score = certifications.certification_points;
						this.last_certified_level = certifications.certification_level;
						this.last_certified_date = certifications.date_certified;
						
						args.success(data);
						strReturn = 1;
					},
					error: function()
					{
						strReturn = 0;
					},
					async: false
				});
				return strReturn;
			},
			//update the chart slices with changed data (used by getData)
			setData: function()
			{
				this.vis = d3.select(".pie_chart_container").data(plaque.data);
				this.pie = d3.layout.pie() //this will create arc data for us given a list of values
					.value(function(d: any)
					{
						return d.value;
					}).sort(null);
				this.arcs = this.vis.selectAll("g.slice") //this selects all <g> elements with class slice (there aren't any yet)
					.data(this.pie);
			}
		};

		$(document).ready(function()
		{
			plaque.setup(global_leed_id);
		});
		//Extend Math class with a map function
		(<any>Math).map = function(value: any, low1: any, high1: any, low2: any, high2: any)
			{
				return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
			}
			// Start changes for ldpf-189
		var screenSettings: any = () =>
		{   
			if(Modernizr.touch)
			{
				plaque.physicalScale = 1.03;
			}
			var relative_width: any = 765;
			var relative_height: any = 765;
			var body_width: any = $('body').width();
			var body_height: any = "";
			if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)))
			{
				body_height = window.innerHeight;
				//alert("body height = " + body_height);
			}
			else
			{
				body_height = $('body').height();
			}
			// Check if this is the physical plaque, or a different device
			if(body_width == 1024 && body_height == 768 && plaque.OSName == "Linux")
			{
				// This is assumed to be the physical plaque - so don't change its scaling factor       
				plaque.physicalScale = 1.25;
			}
			else
			{
				plaque.physicalScale = 1.1;
			}
			// If width is less than height, limit the plaque size by width
			if(body_width <= body_height)
			{                   

				var required_plaque_size: any = ((<any>((body_width) / relative_width)).toFixed(2)) * plaque.physicalScale;
				$("#container").css('-webkit-transform', 'scale(' + required_plaque_size + ')');
				$("#container").css('position', 'relative');
				$("#container").css('top', '42%');
				//$("#app").css('overflow', 'auto');
			}
			else if(body_width > body_height)
			{          

				var required_plaque_size: any = ((<any>((body_height-(.25*body_height)) / relative_height)).toFixed(2)) * plaque.physicalScale;
				$("#container").css('-webkit-transform', 'scale(' + required_plaque_size + ')');
				$("#container").css('position', 'relative');
				$("#container").css('top', '35%');
			}
			// default; or in conditions where body height or width aren't identified
			else
			{
				$("#container").css('-webkit-transform', 'scale(' + plaque.physicalScale + ')');
				$("#container").css('position', 'relative');
				$("#container").css('top', '42%');
			}
			
			if(!this.isNotHardware) {
				$("#container").css('top', '50%');
			}
		};
		$(window).resize(screenSettings);
		$(document).ready(screenSettings);
		$('#container').show();
	}

}

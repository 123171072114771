<div class="content-wrap">
	<div id="content" class="content">
        <div class="gsa_logo_container">
            <div class="gsa_logo"></div>
            <h3 class="fw-500 black-color mt12">Green Sports Alliance</h3>
        </div>
        <div class="row">
            <section class="widget play_to_zero">
                <div class="row pl0 pt15" *ngIf="!appData.get('is_gsa_user')">
                    <div class="col-md-8 pl0">
                        <div class="row">
                            <div class="col-md-10 col-sm-10 pl0 pr0 border_lrb pb30 mb30 bg-white">
                                <div class="model_banner_img_gsa col-md-12"></div>
                                <div class="col-md-12 fs16 pb5 pl25 pr25 pt20">Play to Zero is the new leadership recognition platform and sustainability performance toolkit that will guide and celebrate progress towards a resilient future for sport and our communities. Join the Green Sports Alliance to participate in Play to Zero.</div>
                                <div class="col-md-12 pt20 pl25 pb20">
                                    <button [ngClass]="config.access == 'R' ? 'not-active' : ''" class="btn btn-primary fs16 h40 br2p mr10" onclick="window.open('https://greensportsalliance.org/join/')">Sign Up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pl0">
                        <div class="ptz-badge-container bg-white p25 border_lrb align-center w300px">
                            <div class="ptz-badge-logo"></div>
                            <div class="bold mt20">Green Sports Alliance members can sign up and beginning playing for free</div>
                            <div class="gsa-net-zero-logo mt20"></div>
                            <div class="mt20 color-6B7882">Join as we guide and celebrate progress towards a more resilient future for sport and our communities.</div>
                        </div>
                    </div>
                </div>
                <div class="row pl0 pt15" *ngIf="appData.get('is_gsa_user')">
                    <div class="col-md-7 pl0">
                        <div class="row">
                            <div class="col-md-12 pl0 pr0 border_lrb pb30 mb30 bg-white">
                                <div class="model_banner_img_gsa"></div>
                                <div class="fs16 p25 border-bottom">Play to Zero participants can use Arc to collect and manage data and submit for recognition.</div>
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Set Up a Team</div>
                                    <div class="fs16 line_height_22">
                                        Play to Zero participants need to add GSA to their team. This allows GSA to review performance and aggregate program impacts. Participants should add <a href="mailto:analytics@greensportsalliance.org">analytics@greensportsalliance.org</a> as a read-only team member.
                                    </div>
                                    <div class="pt20 pb20">
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'team'})">Manage Team</button>
                                    </div>
                                </div>
        
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Add Data</div>
                                    <div class="fs16 line_height_22">
                                        Play to Zero participants upload data on energy, water, and waste. Participants should add “baseline” data, as well as a “reporting” period. <a href="https://arc.gbci.org/sites/default/files/GSA%20Play%20to%20Zero%20Guide.pdf" target="_blank">Learn why and how</a>.
                                    </div>
                                    <div class="pt20 pb20">
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'data-input', 'type': 'energy'})">Add Energy</button>
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'data-input', 'type': 'water'})">Add Water</button>
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'data-input', 'type': 'waste'})">Add Waste</button>
                                    </div>
                                </div>
        
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Sustainability Survey</div>
                                    <div class="fs16 line_height_22">
                                        The Play to Zero Sustainability Survey is a free tool that allows the GSA to analyze deeper insights into an sport organization's sustainability initiatives, allowing for tailored recommendations and more customized service options. This allows the GSA to work with our members to find cost effective solutions to minimize their environmental impact.
                                    </div>
                                    <div class="pt20 pb20">
                                        <button class="btn btn-primary fs16 h40 br2p mr10" onclick="window.open('https://docs.google.com/forms/d/e/1FAIpQLSdptKWuRm8nnWzHa6-bZ1yJYzwmjIkRCBcrycImLIN9MpzQaQ/viewform')">Sustainability Survey</button>
                                    </div>
                                </div>
        
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">View Scores</div>
                                    <div class="fs16 line_height_22">
                                        Play to Zero recognizing leadership in GHG emissions reduction, water conservation and waste management. View your Play to Zero Score to evaluate readiness for Leader or Champion awards.
                                    </div>
                                    <div class="pt20 pb20">
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="showContentModal()">View PTZ Score</button>
                                    </div>
                                </div>
        
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Generate a Report</div>
                                    <div class="fs16 line_height_22">
                                        Play to Zero participants can create Play to Zero branded reports, including a range of scores and key performance indicators.
                                    </div>
                                    <div class="pt20 pb20">
                                        <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.goToApp('selected_project', 
                                        {
                                            'leed_id': this.appData.get('leed_id'),
                                            'app_name': 'reports'
                                        })">Report</button>
                                    </div>
                                </div>
        
                                <div class="border-bottom p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Apply for Awards</div>
                                    <div class="fs16 line_height_22">
                                        Let the Green Sports Alliance know that you are ready for your Play to Zero award. Clicking the button will let the team know you’re ready, and they will review your materials in Arc.
                                    </div>
                                    <div class="pt20 pb20">
                                        <a class="btn btn-primary fs16 h40 br2p mr10" href="mailto:analytics@greensportsalliance.org?subject=PTZ Support Request: PID {{appData.get('leed_id')}}&body=Hi Play to Zero Team,%0D%0A %0D%0A My project PID {{appData.get('leed_id')}} requests review on {{current_date}}.%0D%0A %0D%0A I am the point-of-contact for this project.%0D%0A %0D%0A [Type name as signature]%0D%0A [Type organization]%0D%0A [Type phone number for contact]%0D%0A" target="_blank" rel="noopener noreferrer">Apply</a>
                                    </div>
                                </div>
        
                                <div class="p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Get Help</div>
                                    <div class="fs16 line_height_22">
                                        The Play to Zero team is ready to help you succeed. Use this button to connect with the Play to Zero team.
                                    </div>
                                    <div class="pt20 pb20">
                                        <a class="btn btn-primary fs16 h40 br2p mr10" href="mailto:analytics@greensportsalliance.org?subject=PTZ Support Request: PID {{appData.get('leed_id')}}&body=Hi Play to Zero Team,%0D%0A%0D%0AI would like support for my PTZ project PID {{appData.get('leed_id')}} on {{current_date}}. %0D%0A%0D%0AHere’s the issue: [provide more information here]%0D%0A%0D%0AI would like to be contacted by: [email, phone, smoke signals]%0D%0A%0D%0A[Type name as signature]%0D%0A[Type organization] %0D%0A[Type phone number for contact]" target="_blank" rel="noopener noreferrer">Support</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-12 pl0 pr0 border_lrb pb30 mb30 bg-white">
                                <div class="p25">
                                    <div class="fs18 line_height_24 fw-500 mb16">Partners</div>
                                    <div class="fs16 line_height_22 pb20">
                                        Check out our partners that will help you reach any of your sustainability goals.
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://true.gbci.org" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer">
                                                <div class="partner-true-logo"></div>
                                                <div class="info-list fw-500 mb16">TRUE</div>
                                                <div class="info-list fs14">TRUE is a zero waste certification program dedicated to measuring, improving and recognizing zero waste performance.</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://www.rubicon.com/" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="partner-rubicon-logo"></div>
                                                <div class="info-list fw-500 mb16">Rubicon Global</div>
                                                <div class="info-list fs14">Rubicon helps its partners find economic value in their waste streams and confidently execute on their sustainability goals.</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://www.bluestrikeenvironmental.com/" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="partner-bluestike-logo"></div>
                                                <div class="info-list fw-500 mb16">Blue Strike Environmental</div>
                                                <div class="info-list fs14">Blue Strike Environmental offers climate action and practical sustainability solutions to our clients to increase their social footprint and lessen their environmental impact</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://www.vomela.com/" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="partner-tvc-horiz-logo"></div>
                                                <div class="info-list fw-500 mb16">The Vomela Companies</div>
                                                <div class="info-list fs14">The Vomela Companies are a full-service specialty graphics provider capable of producing a wide variety of promotional, store décor, event, fleet, OEM, commercial, and transportation graphics.</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://www.live2re.com/" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="partner-live2re-logo"></div>
                                                <div class="info-list fw-500 mb16">LIVE2RE</div>
                                                <div class="info-list fs14">LIVE2RE leverages technology and fan engagement throughout our 3-wave process - Create, Collect and Connect - to reimagine cost efficient sustainability in venue.</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://buildings.honeywell.com/us/en/home" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="partner-honeywell-logo"></div>
                                                <div class="info-list fw-500 mb16">Honeywell Sustainability Institute</div>
                                                <div class="info-list fs14">Honeywell is the Play To Zero Learning &amp; Enablement partner and works to help members incorporate sustainability best practices in their businesses, facilities and venues, no matter where they are on their sustainability journey.</div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://arc.gbci.org/checksammy" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="checksammy-logo"></div>
                                                <div class="info-list fw-500 mb16">CheckSammy</div>
                                                <div class="info-list fs14">
                                                    CheckSammy understands that Sustainability Projects must be both economically and environmentally sustainable for brands to pursue sustainable outcomes.
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://arc.gbci.org/new-wincup-holdings-inc-makers-phade" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="phade-logo"></div>
                                                <div class="info-list fw-500 mb16">New WinCup Holdings, Inc. - the Makers of phade</div>
                                                <div class="info-list fs14">
                                                    Rigorously tested and certified, phade® has been adopted by top retailers and institutions. Plus, we've won awards for our innovative approach to sustainability.
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://arc.gbci.org/keramida" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="keramida-logo"></div>
                                                <div class="info-list fw-500 mb16">KERAMIDA Inc.</div>
                                                <div class="info-list fs14">
                                                    KERAMIDA is a WBE-Certified, Global Sustainability, Climate, Assurance, and EHS Consultancy.
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://arc.gbci.org/APTIM" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="sustainable-sport-index-logo"></div>
                                                <div class="info-list fw-500 mb16">APTIM</div>
                                                <div class="info-list fs14">
                                                    Sustainable Sport Index (SSI) provides a sports venue benchmarking tool at no-cost, complementing APTIM's sustainability and energy solutions.
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="fs16 line_height_22">
                                        <div class="" id="partners-grid-unit">
                                            <a href="https://arc.gbci.org/Plastiks" target="_blank">
                                            <div class="partners-grid p25 cursor-pointer" >
                                                <div class="plastiks-logo"></div>
                                                <div class="info-list fw-500 mb16">Plastiks.io</div>
                                                <div class="info-list fs14">
                                                    Plastiks is a Greentech company that improves plastic waste recovery and recycling rates worldwide.
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
        <div class="modal fade advanced_scoring_widget_modal score_version_modal overflow-hidden" id="advanced_scoring_widget" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <app-loader *ngIf="widget_loading"></app-loader>
                    <div class="modal-header score_version_modal_header pr20 pl20">
                        <h5 class="modal-title fs22 fw-600">Play to Zero score
                            <div class="arc_gsa_logo mt6"></div>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="fw-500 modal-body p0 h100p w100p position-fixed">
                        <iframe (load)="widget_loading = false" id="advanced_scoring_widget_iframe" frameBorder="0" width="100%" height="100%"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

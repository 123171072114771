import { Component, Input, OnInit } from '@angular/core';

import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { InsightService } from 'src/app/services/insight.service';

var init = false;

@Component({
  selector: 'app-model-reduction',
  templateUrl: './model-reduction.component.html',
  styleUrls: ['./model-reduction.component.css']
})
export class ModelReductionComponent implements OnInit
{
	@Input() create_mode: boolean = true;
	
	project: any = this.appData.get('buildingData_insight');
	project_reduction: any = this.appData.get('reductionData');
	selected_version: any = this.appData.get('selected_version');
	score_card: any = {};

	total: number = 0;
	energy_estimate: number = 0;
	water_estimate: number = 0;
	waste_estimate: number = 0;

	loading: boolean = false;
	net_zero_energy: boolean = false;
	net_zero_water: boolean = false;
	net_zero_waste: boolean = false;

	constructor(
		public appData: AppData,
		public global: Global,
		private insight: InsightService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('current_app') == "create-model")
        {
            this.create_mode = true;
            $('.content-wrap').css('left', '0%');
            $('.content-wrap').css('margin', '0px auto');
            $('.content-wrap').css('width', '83%');
            this.getScoreCard(this.project.rating_system);
        }
        else
        {
            this.create_mode = false;
            this.getReductionData();
            this.loading = true;
        }
	}

	scoreCardChange(val: number, credit_item: any)
	{
		var insights_data =  this.insight.scoreCardChange(val, credit_item, this.score_card, this.project, this.project_reduction);
		this.project_reduction = insights_data.project_reduction;
		this.score_card = insights_data.score_card;
		this.total = insights_data.total;

		if(!this.create_mode && init)
		{
			this.saveScoreCard();
		}
		init = true;
	}

	saveScoreCard()
	{
		var payload = {
			"reduction_data": this.project_reduction
		}
		this.loading = true;
		this.insight.post('/assets/LEED:'+ this.project.leed_id + '/reduction/?saveAs=false&version_name='+ this.selected_version.version_name +'&setLatest=false&version_id=' + String(this.selected_version.version_id), payload).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
			},
			//error handler
			response =>
			{
				this.loading = false;                    
			}
		);
	}

	reductionChanged(type: string, val: any)
	{
		if(type == '')
		{
			this.energy_estimate = val;
		}
		else if(type == '')
		{
			this.water_estimate = val;
		}
		else if(type == '')
		{
			this.waste_estimate = val;
		}
		this.project_reduction = this.insight.reductionChanged(type, val, this.project_reduction);
		var insights_data =  this.insight.initScorecard(this.project_reduction, this.score_card, this.project.rating_system, true);
		this.project_reduction = insights_data.project_reduction;
		this.score_card = insights_data.score_card;
		this.total = insights_data.total;
		this.scoreCardChange(0, {"attempted": 0});
	}

	getScoreCard(rating_system: string)
	{
		this.insight.getAsset('../../../../assets/insight_json/'+ rating_system +'.json').subscribe(response =>
		{
			var insights_data =  this.insight.initScorecard(this.project_reduction, this.global.copy(response), this.project.rating_system);
			this.net_zero_energy = insights_data.net_zero_energy;
			this.net_zero_water = insights_data.net_zero_water;
			this.net_zero_waste = insights_data.net_zero_waste;
			this.project_reduction = insights_data.project_reduction;
			this.score_card = insights_data.score_card;
			this.total = insights_data.total;
			this.scoreCardChange(0, {"attempted": 0});

			for(var k in this.project_reduction)
			{
				if(this.project_reduction[k].credit_id == 'energy_estimate')
				{
					this.energy_estimate = this.project_reduction[k].estimation;
				}
				else if(this.project_reduction[k].credit_id == 'water_estimate')
				{
					this.water_estimate = this.project_reduction[k].estimation;
				}
				else if(this.project_reduction[k].credit_id == 'waste_estimate')
				{
					this.waste_estimate = this.project_reduction[k].estimation;
				}
			}
		});
	}

	getReductionData()
	{
		this.insight.get('/assets/LEED:'+ this.project.leed_id + '/reduction/?version_id=' + this.selected_version.version_id).subscribe(
			//success handler
			response =>
			{
				this.appData.set('reductionData', response.reduction_data);
				this.project_reduction = response.reduction_data;
				this.loading = false;
				this.getScoreCard(this.project.rating_system);
			},
			//error handler
			respons =>
			{
				this.loading = false;                    
			}
		);
	}

	goToModel(app: any, payload: any)
	{
		// this.global.goToApp("selected_model", 
		// {
		// 	"leed_id": this.appData.get('leed_id'),
		// 	"app_name": app,
		// 	'selected_version': this.appData.get('selected_version'),
		// 	'version': this.appData.get('version')
		// });

		this.appData.set('leed_id_insight', payload.project.leed_id);
		this.appData.set('buildingData_insight', payload.project);
		this.appData.set('selected_version', payload.selected_version);
		this.appData.set('version', payload.version);
		this.appData.set('cs_fullname_insight', this.global.getState(payload.project.state, payload.project.country) + ", " + this.global.getCountry(payload.project.country));

		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": 'insight'
		});
		this.appData.set('insight_app', 'reduction');
	}

	netZeroChanged()
	{
		for(var k in this.project_reduction)
		{
			if(this.project_reduction[k].credit_id == 'net_zero_energy')
			{
				this.project_reduction[k].estimation = this.net_zero_energy;
			}
			else if(this.project_reduction[k].credit_id == 'net_zero_water')
			{
				this.project_reduction[k].estimation = this.net_zero_water;
			}
			else if(this.project_reduction[k].credit_id == 'net_zero_waste')
			{
				this.project_reduction[k].estimation = this.net_zero_waste;
			}
		}

		if(!this.create_mode)
		{
			this.saveScoreCard();
		}
	}

	saveReduction()
	{
		var saveAs = false, setLatest = false;   
		var version_id = this.project.version;  
		var version_name: any; 
		for(var k in this.project.versions)
		{
			if(this.project.versions[k].version_id == version_id)
			{
				version_name = this.project.versions[k].version_name;
			}
		}    
		var url = '/assets/LEED:'+ this.project.leed_id +'/reduction/?saveAs='+ saveAs +'&version_name='+ version_name +'&setLatest='+ setLatest + '&version_id=' + version_id;
		
		this.loading = true;
		this.insight.post(url, {"reduction_data": this.project_reduction}).subscribe(
			response =>
			{
				this.loading = false;  
				this.goToModel('reduction', {'project_id': this.project.leed_id, 'project': this.project, 'selected_version': this.project.versions[0], 'version': this.project.version})                  
			},
			errpr =>
			{
				this.loading = false;
			}
		);
	};

}

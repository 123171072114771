import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';

import { Config } from '../models/env-vars.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit
{
	config = Config;

	constructor(
		public global: Global,
		public appData: AppData,
		private api: API
	)
	{

	}

	ngOnInit(): void
	{

	}

}

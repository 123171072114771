<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold" translate>Team</span>
        </h1>
        
        <div [ngClass]="loading ? 'not-active' : ''">
            <div class="row manage_teams">
                <div class="col-lg-8 pl0">
                    <section class="widget">
                        <div class="no-footer">	
                            <table *ngIf="is_school_trial" class="table table-striped arc-table mb25">
                                <thead>
                                    <tr>
                                        <th class="" translate>Access to this project</th>
                                        <th class="" translate>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let team_member of team_members">
                                        <td class="">{{team_member.Firstname}} {{team_member.Lastname}}</td>
                                        <td class="">{{team_member.Useralias}}</td>
                                    </tr>			    
                                </tbody>
                            </table>
                            
                            <table *ngIf="!is_school_trial" class="table table-striped arc-table mb25">
                                <thead>
                                    <tr>
                                        <th class="cursor-pointer">
                                            <span *ngIf="orderByField == 'Firstname'">
                                                <span *ngIf="reverseSort" class="fa fa-caret-down"></span>
                                                <span *ngIf="!reverseSort" class="fa fa-caret-up"></span>
                                            </span>
                                            <span (click)="orderByField='Firstname'; reverseSort = !reverseSort" translate>
                                                Access to this project
                                            </span>
                                        </th>
                                        <th class="" translate>Email</th>
                                        <th class="" translate>Access</th>
                                        <th class="no-sort"></th>
                                        <th class="no-sort"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let team_member of team_members; let idx = index" [hidden]="team_member.Responsibility == 'D'">
                                        <td class="">
                                            <div class="initials">
                                                {{getInitials(team_member.Firstname + " " + team_member.Lastname)}}
                                            </div>
                                            <div style="position: relative;top: 5px;">
                                                {{team_member.Firstname}} {{team_member.Lastname}}
                                            </div>
                                        </td>
                                        <td class="">{{team_member.Useralias}}</td>
                                        <td class="">
                                            <div *ngIf="team_member.Responsibility != 'D'">
                                                <select [ngClass]="makeEditable(team_member) ? '' : 'drpToText'" (change)="updateAuthLevel(auth_level)" [(ngModel)]="team_member.Roleid" name="team_access">
                                                    <option *ngFor="let type of auth_levels" value="{{type.Reltyp}}">{{type.Rtitl}}</option>
                                                </select>
                                            </div>
                                            
                                            <div *ngIf="team_member.Responsibility == 'D'">
                                                <select [ngClass]="makeEditable(team_member) ? '' : 'drpToText'" (change)="updateAuthLevel(auth_level)" [(ngModel)]="team_member.Roleid" name="team_access">
                                                    <option *ngFor="let type of auth_levels" value="{{type.Reltyp}}">{{type.Rtitl}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td [ngClass]="access_team != 'CRUD' ? 'not-active' : ''" class=" no-sort">
                                            <div class="edit_buttons" >
                                                <button *ngIf="!makeEditable(team_member)" type="button" class="btn btn-default btn-sm" (click)="editRole(team_member)" translate>Edit</button>
                                                <button type="button" [ngClass]="save_role_flag ? 'not-active' : ''" class="btn btn-default btn-sm" *ngIf="makeEditable(team_member)" (click)="saveRole(team_member, idx)">{{save_role_msz}}</button>
                                            </div>
                                        </td>
                                        <td [ngClass]="access_team != 'CRUD'? 'not-active' : ''">
                                            <div title="Remove User" class="ml10 delete_report tippy_init floatNone" (click)="removeUser(team_member)"></div>
                                            <div *ngIf="idx == team_members.length - 1"></div>
                                        </td>
                                    </tr>			    
                                </tbody>
                            </table>
                        </div>          
                    </section>

                    <section class="widget mb40">
                        <div><span class="fw600">Note: </span><span>Please be advised that we have consolidated our team roles. Team managers and Project Admins will now become Arc administrators. Core functionality is unchanged for these users. Arc administrators are encouraged to review these changes and update roles and teams as necessary.</span></div>
                    </section>
                    
                    <section *ngIf="appData.get('project_type') !='parksmart'" class="ml32 mb40 permission_request">
                        <app-loader *ngIf="loading"></app-loader>
                        <h4 translate>
                            Permission requests
                        </h4>
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th class="" translate>Email</th>
                                    <th class="" translate>Date Requested</th>
                                    <th class="" translate>Status</th>
                                    <th class="" translate>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let request of requests">
                                    <td>{{request.user_name}}</td>
                                    <td>{{global.dateTimeFormat(request.created_at)}}</td>
                                    <td>
                                        <span translate *ngIf="request.is_seen == false">Pending</span>
                                        <span translate *ngIf="request.is_seen == true && request.is_granted == false">Denied</span>
                                        <span translate *ngIf="request.is_seen == true && request.is_granted == true">Accepted</span>
                                    </td>
                                    <td [ngClass]="savingPermission ? 'not-active' : ''">
                                        <div *ngIf="request.is_seen == false">
                                            <button [ngClass]="access_team != 'CRUD' ? 'not-active' : ''" class="team_agreement_button btn btn-primary btn-sm" (click)="approveRequest(request, this)">
                                                <span *ngIf="savingPermissionAccept" style="float: left !important;margin-right: 7px;margin-top: 4px;border-top-color: white;border-left-color: white;" class="dataInputLoaderSpinner"></span>
                                                <span translate>Approve</span>
                                            </button>
                                            <button [ngClass]="access_team != 'CRUD' ? 'not-active' : ''" class="ml10 btn btn-default btn-sm" (click)="denyRequest(request, this)">
                                                <span *ngIf="savingPermissionDenied" style="float: left !important;margin-right: 7px;margin-top: 4px;" class="dataInputLoaderSpinner"></span>
                                                <span translate>Deny</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>			    
                            </tbody>
                        </table>
                    </section>

                    <section *ngIf="appData.get('buildingData').building_status == 'activated_under_review'" class="ml32 mb40 permission_request">
                        <app-loader *ngIf="review_data_loading"></app-loader>
                        <h4 translate>
                            <span>Select the primary point of contact for the review</span>
                            <span (click)="global.showHelpText('team_roles_help')" class="cursor-pointer vertical_align_middle pl5">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
                            </span>
                        </h4>
                        <select id="cert_point_of_contact" class="border_radius_zero cert_selection_container_field cursor-pointer" (change)="updatePointOfContact(cert_point_of_contact)" [(ngModel)]="cert_point_of_contact" [ngClass]="access_team != 'CRUD' ? 'not-active' : ''">
                            <option hidden disabled selected value></option>
                            <option *ngFor="let team_member of point_of_contact_options" [value]="team_member.Useralias.toLowerCase()" [hidden]="team_member.Responsibility == 'D'">
                                {{team_member.Firstname}} {{team_member.Lastname}} ({{team_member.Useralias.toLowerCase()}})
                            </option>
                        </select>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </section>
                </div>
                
                <div class="flex ml32 col-lg-3 pl0 mb10 flex-end pr0">
                    <span class="mr5 gray_color_68737D" translate>How team roles work</span>
                    <span (click)="global.showHelpText('team_roles_help')" class="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
                    </span>
                </div>
                <div class="col-lg-3 team_invite mb24">
                    
                    <section class="">
                        <div class="">
                            <h4 class="mt0" translate>Invite team members</h4>
                               <form name="addteamForm" class="row" [ngClass]="add_team_flag ? 'not-active' : ''">
                                   <div [ngClass]="access_team != 'CRUD' ? 'not-active' : ''" id="details-form">
                                    <div class="group pb0 mb25" [ngClass]="error_add_team_flag ? 'has-error' : ''">      
                                        <input maxlength="150" [(ngModel)]="email_form.new_email" name="input" type="email" required (focus)="clearAll()">
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label translate>Enter email address</label>
                                        <span class="error">
                                            <!-- <p *ngIf="addteamForm.input.$error.email" translate>Not a valid email</p> -->
                                            <p *ngIf="error_add_team_flag">{{error_add_team_msz}}</p>
                                        </span>
                                    </div>
                                   </div>
                                   <div [ngClass]="access_team != 'CRUD'  ? 'not-active' : ''">
                                       <button id="invite_team" class="btn btn-success pr5 pl5" [ngClass]="add_team_flag ? 'not-active' : ''" (click)="addMemberToTeam()">{{add_team_msz}}</button>
                                   </div>               			
                               </form>
                        </div>
                    </section>
                </div>
        
                <div class="col-lg-3 team_invite" *ngIf="appData.get('project_type') == 'building' || appData.get('project_type') == 'school'">
                    <section class="">
                        <div class="">
                            <h4 class="mt0 mb20" translate>Add/Remove user from multiple projects</h4>
                               <form name="removeteamForm" class="row" [ngClass]="remove_team_flag ? 'not-active' : ''">
                                   <div [ngClass]="access_team != 'CRUD' ? 'not-active' : ''" >
                                       <button id="invite_team" class="btn btn-success" [ngClass]="remove_team_flag? 'not-active' : ''" (click)="manageMembers()">{{remove_team_msz}}</button>
                                   </div>               			
                               </form>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        

    </div>
</div>

<modal #modal>
    <app-multiple-team-manage (close)="cancel()"></app-multiple-team-manage>
</modal>
import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { ValidationService } from '../../services/validation.service';
import { API } from '../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { ArcAuthService } from 'src/app/services/auth.service';

import { Config } from 'src/app/models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-project-payment',
  templateUrl: './project-payment.component.html',
  styleUrls: ['./project-payment.component.css']
})
export class ProjectPaymentComponent implements OnInit
{
	config = Config;
	@ViewChild('modal') private modal!: ModalComponent;
	loading: boolean = false;
	syncing_project: boolean = false;
	searchingOrganization: boolean = false;
	invalid_promo_code: boolean = false;
	disablePayButton: boolean = false;
	submit_error: boolean = false;
	isPaymentFormSubmitted: boolean = false;
	promocode_applied: boolean = false;
	show_tax_note: boolean = false;
	submit_error_payment: boolean = false;
	go_to_LO_flag: boolean = false;
	change_payment_method: boolean = this.appData.get('change_payment_method');
	taxes: any = false;
	taxes_s: any = false;
	integration_payment_duration: boolean = false;
	go_back: string = this.appData.get('previous_app_name');
	leed_id: number = this.appData.get('buildingData').leed_id;
	final_price: number = 0;
	complete_state: string = '';
	complete_country: string = '';
	project_type_full_name: string = '';
	payment_currency: string = '';
	gstin_modal_subject: string = '';
	project_payment_nxt_btn: string = 'Submit Payment';
	project_detail_nxt_btn: string = '';
	gstin_modal_body: string = '';
	go_to_LO_text: string = 'Register for LEED certification now';
	refreshing_promocode: boolean = false;
	refreshing_tax: boolean = false;
	reload_price: boolean = false;
	reload_price_loader: boolean = false;
	reload_price_txt: string = 'Unable to fetch price';
	payment_mode: string = this.appData.get('buildingData').country == 'IN' ? 'check' : 'cc';
	integration_annual_soreference: string = 'MEASURABL ANNUAL SUBSCRIPTION';
    integration_monthly_soreference: string = 'MEASURABL MONTHLY SUBSCRIPTION';
	pf_id: number = 0;
	phone_number: number = 0;
	mode: string = '';

	integration_monthly_price: any = 0;
	integration_annual_price: any = 0;
	integration: any = this.appData.get('integration');
	spaceType: any = [];
	ownerType: any = [];
	all_countries: any = [];
	all_states: any = [];
	all_organizations: any = [];
	project_price: any = [];
	selected_price: any = {};
	price_obj: any = {};
	promocode_price: any = {};
	uploaded_file: any = {};
	canceler: any;
	regex = this.validator.regex;
	rating_system: any = 
	[
		{"name":"LEED for transit", "value":  Config.config.settings.rating_systems.transit, "type":"building", "project_type": "transit"},
		{"name":"Parksmart", "value": Config.config.settings.rating_systems.parksmart, "type":"building", "project_type": "parksmart"},         
		{"name":"None", "value":"none", "type":"building", "project_type": "building"},
		{"name":"None", "value":"none", "type":"city", "project_type": "city"},
		{"name":"None", "value":"none", "type":"community", "project_type": "community"},
	];
	no_error = {
		'error': false,
		'help': undefined
	};
	paymentdata: any = {
		'name': '',
		'first_name': '',
		'last_name': '',
		'email': this.appData.get('user_id'),
		'unit_price': 0,
		'price_per_unit': 0,
		'material_code': '',
		'material_description': '',
		'quantity': '',
		'company_code': '',
		'street': '',
		'city': '',
		'state': 'DC',
		'country': 'US',
		'zip_code':"",
		'year': '',
		'price':'',
		'currency':'',
		'discount':'',
		'discount_percent':'',
		"SoReference": "REGISTRATION",
		'paymetric_r': '',
		'paymetric_s': '',
		"is_sez": false
	};
	paymentdata_error: any = {
		'name': this.no_error,
		'first_name': this.no_error,
		'last_name': this.no_error,
		'email': this.no_error,
		'unit_price': this.no_error,
		'price_per_unit': this.no_error,
		'material_code': this.no_error,
		'material_description': this.no_error,
		'quantity': this.no_error,
		'company_code': this.no_error,
		'street': this.no_error,
		'city': this.no_error,
		'state': this.no_error,
		'country': this.no_error,
		'zip_code':  this.no_error,
		'year':  this.no_error,
		'price': this.no_error,
		'currency': this.no_error,
		'discount': this.no_error,
		'discount_percent': this.no_error,
		"SoReference": this.no_error,
		'paymetric_r': this.no_error,
		'paymetric_s': this.no_error,
		"is_sez": this.no_error,
		"CC_number": this.no_error,
		"gstin": this.no_error,
		"CC_CVV": this.no_error,
		"CC_expiry": this.no_error
	};
	formdata: any = {
		'rating_system': this.updateViewBasedOnRatingSystem(this.appData.get('buildingData').project_type),
		"organization": this.appData.get('buildingData').organization,
		"owner_email": this.appData.get('buildingData').owner_email,
		"owner_name": this.appData.get('buildingData').owner_name,
		"manageEntityCountry": this.appData.get('buildingData').manageEntityCountry == null ? 'US' : this.appData.get('buildingData').manageEntityCountry,
		"IsLeedRegistration": this.isPricingValid() ? false : ""
	};
	formdata_error: any = {
		'rating_system': this.no_error,
		'organization': this.no_error,
		'owner_email': this.no_error,
		'owner_name': this.no_error,
		'manageEntityCountry': this.no_error,
		'IsLeedRegistration': this.no_error
	};

	constructor(
		public appData: AppData,
		public global: Global,
		private validator: ValidationService,
		private alertService: alertService,
		private api: API,
		private auth: ArcAuthService
	)
	{

	}

	ngOnInit(): void
	{
		this.api.getAsset(
			'../../assets/json/spaceTypes.json'
		).subscribe(data => 
		{
			this.spaceType = data;
			this.formdata['spaceType'] = this.appData.get('buildingData').spaceType == null ? this.spaceType[0] : this.appData.get('buildingData').spaceType;
		});

		this.api.getAsset(
			'../../assets/json/ownerType.json'
		).subscribe(data => 
		{
			this.ownerType = data;
			this.formdata['ownerType'] = this.appData.get('buildingData').ownerType == null ? this.ownerType[0] : this.appData.get('buildingData').ownerType;
		});

		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.all_countries = this.global.getCountryList();
				this.getAuthDetail();
			});
		}
		else
		{
			this.all_countries = this.global.getCountryList();
			this.getAuthDetail();
		}

		if(this.appData.get('buildingData').country == 'IN')
		{
            this.paymentdata.country = 'IN';
            this.paymentdata.state = '26';
        }

        if(this.appData.get('buildingData').project_type == "city")
        {
            this.project_type_full_name = "City";
        }
        else if(this.appData.get('buildingData').project_type == "community")
        {
            this.project_type_full_name = "Community";
        }
        else
        {
            this.project_type_full_name = "Project";
        }  

		if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community')
		{
            this.go_to_LO_text = 'Go to LEED Online';
        }

		if(this.config.state('pf_id') != undefined)
        {
            this.pf_id = this.config.state('pf_id');
            this.mode = 'project_add_portfolio';
        }
        else
        {
            this.mode = 'project_creation';
        }

        this.createPaymetricForm();

		if(this.appData.get('buildingData').test_project)
        {
            $('.review_not_allowed_modal').modal('show');
        }

        if (this.appData.get('buildingData').country == 'IN')
        {
            Config.uploaded_files = [];
			this.api.get(
				'/assets/LEED:'+ this.leed_id + '/s3documents/?doc_category=sez_docs/temp'
			).subscribe(
				data =>
				{
					if (data.length >= 1)
                    {
                        Config.uploaded_files = data;
                        this.paymentdata.is_sez = true;
                    }
				},
				error =>
				{
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
				}
			);
            
            this.getPrice('0');
            if (this.appData.get('buildingData').project_type == "building" || this.appData.get('buildingData').project_type == "school")
			{
                this.getPrice('1');
            }
        }
        else
        {
            this.getPrice('0');
        }
	}

	isPricingValid(): boolean
	{
		return (String(this.leed_id)[0] == '1');
	}

	getAuthDetail()
	{
		this.api.get(
			'/auth/detail/'
		).subscribe(
			data =>
			{
				let auth_data: any = data;
				this.paymentdata["name"] = auth_data.Fullname;
				this.paymentdata["first_name"] = auth_data.Firstname;
				this.paymentdata["last_name"] = auth_data.Lastname;
				this.paymentdata["street"] = auth_data.Street;
				this.paymentdata["city"] = auth_data.City;
				this.paymentdata["zip_code"] = auth_data.PostlCode;
				if (this.appData.get('buildingData').country == 'IN' && auth_data.Country != 'IN'){
					auth_data.Country = 'IN';
					auth_data.Region = '26';
				}
				this.paymentdata["country"] = auth_data.Country;
				this.all_states = this.global.getStateList(auth_data.Country);
				this.updateCountry(auth_data.Country);
				var valid_state_found = false;
				for(var a = 0; a < this.all_states.length; a++)
				{
					if(this.all_states[a].code == auth_data.Region)
					{
						this.paymentdata["state"] = auth_data.Region;
						valid_state_found = true;
						break;
					}
				}
				if(!valid_state_found)
				{
					this.all_states.unshift({
						code: "select_state",
						name: "Select State"
					});
					this.paymentdata['state'] = "select_state";
				}

				if(this.appData.get('buildingData').country == 'IN')
				{
					this.all_countries = [{code:'IN', name:'India'}];
					this.all_states = this.global.getStateList('IN');
					this.paymentdata.state    = '26';
					this.paymentdata.country  = 'IN';
				}
			},
			error =>
			{
				
			}
		);
	}

	checkPaymetricReturn()
	{
		$('iframe#paymetric_iframe').off();
		$('iframe#paymetric_iframe').load(<any>(() =>
		{
			this.paymentdata.paymetric_r = this.global.getParameterFromURLByName('r', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);
			this.paymentdata.paymetric_s = this.global.getParameterFromURLByName('s', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);

			if (((<any>document.getElementById('paymetric_iframe')).contentWindow.location.href).indexOf("paymetric") > -1 && this.paymentdata.paymetric_r && this.paymentdata.paymetric_s){
				if (this.payment_mode == "cc")
				{
					this.paymentdata["cc_user_name"] = this.paymentdata["name"];
				}
				this.payNow();
			}
		}));
	}

	createPaymetricForm()
	{
		var redirectURLHost = window.location.origin;
		this.api.get('/assets/LEED:' + this.leed_id + '/payments/paymetricform/?redirectURLHost=' + redirectURLHost, 'text/html').subscribe(
			//success handler
			data =>
			{
				var doc = (<any>document.getElementById('paymetric_iframe')).contentWindow.document;
				doc.open();
				doc.write(data);
				doc.close();
				this.checkPaymetricReturn();
			},
			//error handler
			data =>
			{
			}
		);
	}

	ratingSystemChange(rating_system:string, old_value:string)
	{
		if (rating_system == 'LEED V4 O+M: EB WP' || rating_system == 'LEED-CT' || rating_system == 'LEED-CM')
		{
			this.formdata.rating_system = old_value;
			$('#leed_registration_block').appendTo("body").modal('show');
			return;
		}
	}

	updateViewBasedOnRatingSystem(projectType: string)
	{
		for(var i = 0; i < this.rating_system.length; i++)
		{
			if(this.rating_system[i].project_type == projectType)
			{
				return this.rating_system[i].value;
			}
		}
	}

	summation(data: any): any
	{
		return data.reduce((a: any, b: any) => { return (isNaN(parseFloat(a)) ? 0 : parseFloat(a)) + (isNaN(parseFloat(b)) ? 0 : parseFloat(b)); }, 0);
	}

	isRemoved(): any
	{
		return !(this.appData.get('buildingData').project_type == "city" || this.appData.get('buildingData').project_type == "community");
	}

	getCardType(number: string): any
	{
		if(number == undefined)
		{
			return false;
		}
		// visa
		var re = new RegExp("^4");
		if(number.match(re) != null) return "Visa";
		// Mastercard
		re = new RegExp("^5[1-5]");
		if(number.match(re) != null) return "Mastercard";
		// AMEX
		re = new RegExp("^3[47]");
		if(number.match(re) != null) return "American Express";
		// Discover
		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if(number.match(re) != null) return "Discover";
		
		return "";
	}

	only_IGST(): any
	{
		if (this.price_obj != undefined && this.price_obj.length <= 2)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	IsTrialSelectedAndExpired(project: any): any
	{
		if(project == undefined)
		{
			project = this.appData.get('buildingData');
		}
		var is_trial = false;
		if(project.is_trial_selected && project.trial_expire_date)
		{
			if(moment(new Date()).isAfter(project.trial_expire_date))
			{
				is_trial = true;
			}
			else
			{
				is_trial = false;
			}
		}
		return is_trial;
	}

	searchDisableOrganization()
	{
		this.searchingOrganization = false;
	}
	
	searchEnableOrganization()
	{
		this.searchingOrganization = true;
	}

	checkSubmitError()
	{
		if(this.isPricingValid())
		{
			this.submit_error_payment = false;
			for(var a in this.paymentdata_error)
			{
				if(this.paymentdata_error[a].error)
				{
					this.submit_error_payment = true;
					break;
				}
			}
		}
		else
		{
			this.submit_error = false;
			for(var a in this.formdata_error)
			{
				if(this.formdata_error[a].error)
				{
					this.submit_error = true;
					break;
				}
			}
		}
	};

	searchOrganization(query: string)
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		query = query.substr(0, 100);
		this.searchEnableOrganization();

		this.all_organizations = [];

		this.formdata_error['organization'] = {
			'error': true,
			'help': 'This field is required'
		};

		this.canceler = this.api.get(
			'/assets/searchowner/?q=' + query
		).subscribe(
			data =>
			{
				this.all_organizations = data.owner_type;
			},
			error =>
			{
				
			}
		);

		this.checkSubmitError();
	}

	verifyField(data: any, key: string, optional?: boolean)
	{
		if(this.regex[key] == undefined || key == 'state' || key == 'organization')
		{
			this.checkSubmitError();
			return;
		}
		
		if(data == undefined || data == null || data.length == 0)
		{
			this.formdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.formdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.formdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		if(data == undefined || data == null || data.length == 0)
		{
			if(optional)
			{
				this.paymentdata_error[key] = {
					'error': false,
					'help': undefined
				};
				this.checkSubmitError();
				return;
			}
			this.paymentdata_error[key] = {
				'error': true,
				'help': 'This field is required'
			};
		}
		else if(this.regex[key].test(data))
		{
			this.paymentdata_error[key] = {
				'error': false,
				'help': undefined
			};
		}
		else
		{
			this.paymentdata_error[key] = {
				'error': true,
				'help': this.regex[key + 'Msg']
			};
		}

		this.checkSubmitError();
	}

	togglePricing()
	{
		this.integration_payment_duration = !this.integration_payment_duration;
		if(!this.integration_payment_duration)
		{
			this.selected_price = this.integration_annual_price;
		}
		else
		{
			this.promocode_applied = false;
			this.paymentdata['promocode'] = '';
			this.selected_price = this.integration_monthly_price;
		}
		this.final_price = this.selected_price.price;
	}

	updateCountry(selectedCountry: any)
	{
		this.all_states = this.global.getStateList(selectedCountry);
		if(this.all_states.length > 0)
		{
			this.paymentdata['state'] = this.all_states[0].code;
		}
		else
		{
			this.all_states = [
			{
				code: "",
				name: "Not Available"
			}];
			this.formdata['state'] = "";
			this.paymentdata['state'] = "";
		}
	}

	updateState(term: any)
	{
		for(var a = 0; a < this.all_states.length; a++)
		{
			if(this.all_states[a].code == 'select_state')
			{
				this.all_states.splice(a, 1);
				break;
			}
		}
		if (this.appData.get('buildingData').country == 'IN'){
			 this.getPrice(term);
		}
	}

	getPriceURL(term: any): string
	{
		var url = '/assets/LEED:' + this.leed_id + '/payments/price/?term=' + term;

		if(this.appData.get('buildingData').country == 'IN')
		{
			this.show_tax_note = true;

			var gstin = this.paymentdata.gstin; 
			if (this.paymentdata.gstin == undefined || this.paymentdata.gstin == null || this.paymentdata.gstin == '') 
			{
				gstin = '';
			}

			var is_sez = "0";
			if(this.paymentdata.is_sez)
				is_sez = "1";

			
			url += "&name=" + this.paymentdata.name + "&email=" + this.paymentdata.email + "&street=" + this.paymentdata.street + "&city=" + this.paymentdata.city + "&state=" + this.paymentdata.state + "&country=" + this.paymentdata.country + "&zip_code=" + this.paymentdata.zip_code + "&gstin=" + gstin + "&is_sez=" + is_sez;
		}

		if(this.promocode_applied && this.paymentdata['promocode'] != undefined && this.paymentdata['promocode'] != null && this.paymentdata['promocode'] != '')
		{
			url += "&promocode=" + this.paymentdata['promocode'];
		}

		return url;
	};

	getPrice(term: any, show_loaders?: any, verify?: boolean)
	{
		if(verify)
		{
			this.verifyField(this.paymentdata.gstin, 'gstin', true)
		}
		
		if(this.integration != undefined && !this.change_payment_method)
		{
			let url = ['/assets/LEED:' + this.leed_id + '/payments/price/?soreference=' + this.integration_monthly_soreference, '/assets/LEED:' + this.leed_id + '/payments/price/?soreference=' + this.integration_annual_soreference];

			this.api.async(
				url
			).subscribe(
				data =>
				{
					this.integration_monthly_price = data[0].price[0];
					this.integration_annual_price = data[1].price[0];
					
					if(!this.integration_payment_duration)
					{
						this.selected_price = this.integration_annual_price;
					}
					else
					{
						this.selected_price = this.integration_monthly_price;
					}
					this.final_price = this.selected_price.price;
				}
			);

			return;    
		}

		if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
			term = 5;
		}

		if(!this.isPricingValid())
		{
			return;
		}
		var url: any = this.getPriceURL(term);

		this.promocode_price = {};
		
		this.taxes = false;
		this.taxes_s = false;

		if(show_loaders == undefined)
		{
			this.refreshing_promocode = true;
			this.reload_price = true;
			this.reload_price_loader = true;
			this.reload_price_txt = 'Refreshing Price';
			this.refreshing_tax = true;
		}

		this.api.get(url.replace('select_state', '')).subscribe(
			data =>
			{
				data.price = data.price.sort((a: any,b: any) =>
				{ 
					return a.term - b.term;
				});
				if(term == '0')
				{
					this.project_price = data;
				}
				else if(this.appData.get('buildingData').country == 'IN')
				{
					if(data.price.length == 2)
					{
						this.taxes = data.price[1];
					}
					else if(data.price.length == 3)
					{
						this.taxes = data.price[1];
						this.taxes_s = data.price[2];
					}
				}
				this.payment_currency = data.currency == 'INR' ? '₹' : '$';
				this.price_obj = data.price;

				if (this.promocode_applied){
					this.promocode_price = data.price[0];
					if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
						this.promocode_price["annual_price"] = this.promocode_price["price"];
					}
				}
				else{
					this.selected_price = data.price[0];
					if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
						this.selected_price["annual_price"] = this.selected_price["price"];
					}
					this.final_price = this.selected_price["annual_price"];
				}

				this.refreshing_promocode = false;
				this.refreshing_tax = false;
				this.reload_price_loader = false;
				this.reload_price = false;
				this.refreshing_tax = false;
			},
			error =>
			{
				this.refreshing_promocode = false;
				this.refreshing_tax = false;
				this.reload_price_loader = false;
				this.refreshing_tax = false;
				this.reload_price_txt = 'Unable to fetch price';
			}
		);
	}

	normalizeYear(year: number): number
	{
		var YEARS_AHEAD = 20;
		if (year < 100)
		{
			var nowYear = new Date().getFullYear();
			year += Math.floor(nowYear / 100) * 100;
			if (year > nowYear + YEARS_AHEAD)
			{
				year -= 100;
			}
			else if (year <= nowYear - 100 + YEARS_AHEAD)
			{
				year += 100;
			}
		}
		return year;
	}

	validateCardExpiry(card_expiry: string)
	{
		if (card_expiry == undefined)
			return;

		var match: any = card_expiry.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
		if (!match)
			return;

		var exp = new Date(this.normalizeYear(1 * match[2]), 1 * match[1] - 1, 1).valueOf();
		var now = new Date();
		var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
		if (exp <= currMonth)
		{
			this.paymentdata_error["CC_expiry"] = {
				'error': true,
				'help': "Invalid expiry date"
			};
		}
		else
		{
			this.paymentdata_error["CC_expiry"] = {
				'error': false,
				'help': ""
			};
		}
	}

	getPromocode(term: any)
	{
		this.invalid_promo_code = false;
		if(this.integration != undefined && !this.change_payment_method)
		{
			if(!this.integration_payment_duration)
			{
				var soreference = this.integration_annual_soreference;
			}
			else
			{
				var soreference = this.integration_monthly_soreference
			}
			var url = '/assets/LEED:' + this.leed_id + '/payments/price/?soreference=' + soreference;

			if(this.paymentdata['promocode'] != undefined && this.paymentdata['promocode'] != null && this.paymentdata['promocode'] != '')
			{
				url += "&promocode=" + this.paymentdata['promocode'];
				this.refreshing_promocode = true;
				this.promocode_applied = true;
			}
			else
			{
				this.refreshing_promocode = false;
				this.promocode_applied = false;
				return;
			}

			this.api.async(
				[url]
			).subscribe(
				data =>
				{
					this.selected_price = data[0].price[0];
					this.final_price = this.selected_price.annual_price;
					this.refreshing_promocode = false;
				},
				error =>
				{
					this.invalid_promo_code = true;
					this.promocode_applied = false;
					this.refreshing_promocode = false;
				}
			);

			return;    
		}

		if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
			term = 5;
		}

		this.invalid_promo_code = false;
		if(!this.isPricingValid())
		{
			return;
		}
		var url = '/assets/LEED:' + this.leed_id + '/payments/price/?term=' + term;

		if(this.appData.get('buildingData').country == 'IN')
		{
			this.show_tax_note = true;
	
			var gstin = this.paymentdata.gstin; 
			if (this.paymentdata.gstin == undefined || this.paymentdata.gstin == null || this.paymentdata.gstin == '') 
			{
				gstin = '';
			}

			var is_sez = "0";
			if(this.paymentdata.is_sez)
				is_sez = "1";

			
			url += "&name=" + this.paymentdata.name + "&email=" + this.paymentdata.email + "&street=" + this.paymentdata.street + "&city=" + this.paymentdata.city + "&state=" + this.paymentdata.state + "&country=" + this.paymentdata.country + "&zip_code=" + this.paymentdata.zip_code + "&gstin=" + gstin + "&is_sez=" + is_sez;
		}

		if(this.paymentdata['promocode'] != undefined && this.paymentdata['promocode'] != null && this.paymentdata['promocode'] != '')
		{
			url += "&promocode=" + this.paymentdata['promocode'];
			this.refreshing_promocode = true;
			this.promocode_applied = true;
		}
		else
		{
			this.refreshing_promocode = false;
			this.promocode_applied = false;
			return;
		}

		this.api.get(url.replace('select_state', '')).subscribe(
			data =>
			{
				data.price = data.price.sort((a: any,b: any) => 
				{ 
					return a.term - b.term;
				});
				var prices = data.price;
				this.promocode_price = prices[0];

				if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
					this.promocode_price["annual_price"] = this.promocode_price["price"];
				}
				this.final_price = this.promocode_price["annual_price"];

				this.promocode_applied = true;
				this.refreshing_promocode = false;

				if(data.price.length == 2)
				{
					this.taxes = data.price[1];
				}
				else if(data.price.length == 3)
				{
					this.taxes = data.price[1];
					this.taxes_s = data.price[2];
				}
			},
			error =>
			{
				this.invalid_promo_code = true;
				this.promocode_applied = false;
				this.refreshing_promocode = false;
			}
		);
	}

	removePromocode(term: string)
	{
		this.promocode_applied = false;
		this.paymentdata['promocode'] = '';
		this.getPrice(term);
	}

	selectOrganization(org: string)
	{
		this.formdata_error['organization'] = {
			'error': false,
			'help': ''
		};
		this.formdata.organization = org;
		this.searchDisableOrganization();
		this.checkSubmitError();
	}

	registerLEEDOrNonLEED(val: string)
	{
		if (val == 'yes'){
			this.formdata.IsLeedRegistration = true;
			this.verifyField(this.formdata.IsLeedRegistration, 'IsLeedRegistration');
			$('#leed_registration_block').modal('show');
			return;
		}
		else if (val == 'no'){
			this.formdata.IsLeedRegistration = false;
			this.verifyField(this.formdata.IsLeedRegistration, 'IsLeedRegistration');
		}
	}

	syncSAP()
	{
		let payload: any;
		if(!this.isPricingValid())
		{
			if(!this.isRemoved())
			{
				payload = {
					'rating_system': this.formdata['rating_system']
				}
				if((this.appData.get('buildingData').manageEntityCountry != null || this.appData.get('buildingData').manageEntityCountry != undefined) && (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community'))
				{
					payload['manageEntityCountry'] = this.appData.get('buildingData').manageEntityCountry;
				}
			}
			else
			{
				payload = this.formdata;
			}

			if(this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school' || this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community')
			{
				payload['rating_system'] = "none";
			}

			payload["sign_agreement"] = true;
		
			this.api.post('/assets/LEED:'+ this.leed_id +'/syncsap/', payload).subscribe(
				data =>
				{
					this.syncing_project = false;
					this.leed_id = data.leed_id;
					this.appData.set('project_id', data.leed_id);
					this.appData.set('leed_id', data.leed_id);
					this.appData.set('project_type', this.appData.get('buildingData').project_type);
					this.appData.set('project_name', data.name);
					this.appData.set('project_rating_system', data.rating_system);
					this.appData.get('buildingData').leed_id = data.leed_id;
					this.appData.get('buildingData')['owner_email'] = data.owner_email;
					this.appData.get('buildingData')['owner_name'] = data.owner_name;
					this.appData.get('buildingData')['organization'] = data.organization;
					this.appData.get('buildingData')['manageEntityCountry'] = data.manageEntityCountry;
					this.appData.get('buildingData')['ownerType'] = data.ownerType;
					this.appData.get('buildingData')['spaceType'] = data.spaceType;
					this.appData.get('buildingData')['rating_system'] = data.rating_system;
					this.getPrice('0');

					var numberPattern = /\d+/g;
					var pathname = window.location.pathname;
					pathname = pathname.replace(numberPattern, data.leed_id);
					if(this.mode == 'project_add_portfolio')
					{
						pathname += "?pf_id=" + this.pf_id;
					}
					window.history.pushState('', '', pathname);
				},
				error =>
				{
					this.syncing_project = false;
				}
			);
		}
		else
		{
			if(this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community')
			{
				payload = {
					'rating_system': this.formdata.rating_system,
					'manageEntityCountry': this.appData.get('buildingData').manageEntityCountry
				};
			}
			else
			{
				payload = this.formdata
				delete payload['organization'];
				delete payload['owner_email'];
				delete payload['owner_name'];
				delete payload['manageEntityCountry'];
			}

			delete payload['rating_system'];
			
			this.api.put('/assets/LEED:'+ this.leed_id +'/', payload).subscribe(
				data =>
				{
					this.syncing_project = false;
				},
				error =>
				{
					this.syncing_project = false;
				}
			);
		}
	}

	verifySyncSAP()
	{
		if(!this.isRemoved())
		{
			delete this.formdata['owner_email'];
			delete this.formdata['owner_name'];
			this.verifyField(this.formdata["IsLeedRegistration"], "IsLeedRegistration");
			if(this.formdata_error["IsLeedRegistration"].error){
				this.submit_error = true;
				this.syncing_project = false;
				return;
			}
			this.syncing_project = true;
			this.syncSAP();
			return;
		}
		else{
			delete this.formdata['IsLeedRegistration'];
		}

		for(var a in this.formdata)
		{
			this.verifyField(this.formdata[a], a);
		}

		this.submit_error = false;
		for(var a in this.formdata_error)
		{
			if(this.formdata_error[a].error)
			{
				this.submit_error = true;
				this.syncing_project = false;
				return;
			}
		}
		
		//OFAC validation starts
		this.syncing_project = true;
		var org_country = this.formdata.manageEntityCountry;

		var org_name = this.formdata.organization;
		var org_full_country = this.global.getCountry(org_country);
		this.validator.validateOrganization(org_name, org_full_country).subscribe(
			data =>
			{
				if (data.status)
				{
					if (data.is_blocked)
					{
						this.syncing_project = false;
						$('.ofac_invalid_modal').modal('show');
						this.project_detail_nxt_btn = "Next";
						$("#project-details-form").removeClass("not-active");
					}
					else
					{
						// register project if not blocked
						this.syncSAP();
					}
				}
			},
			data =>
			{
				this.syncing_project = false;
				this.project_detail_nxt_btn = "Next";
				$("#project-details-form").removeClass("not-active");
				this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
			}
		);

		//OFAC validation ends
	}

	selectPrice(option: any)
	{
		this.selected_price = option;
		if(this.promocode_applied)
		{
			this.getPromocode(option.term);
		}
		else if(this.appData.get('buildingData').country == 'IN')
		{
			this.getPrice(option.term, false);
		}
	}

	togglePaymentMode(mode: string)
	{
		this.payment_mode = mode;
		var reset_field = {
			'error': false,
			'help': undefined
		};
		this.paymentdata_error['CC_CVV'] = reset_field;
		this.paymentdata_error['CC_expiry'] = reset_field;
		this.paymentdata_error['CC_number'] = reset_field;
		if(mode == 'cc')
		{
			this.paymentdata.CC_number = "";
			this.paymentdata.CC_expiry = "";
			this.paymentdata.CC_CVV = "";
		}
		this.checkSubmitError();
	}

	submitPayment()
	{
		if(this.payment_mode != 'cc')
		{
			delete this.paymentdata.CC_number;
			delete this.paymentdata.CC_expiry;
			delete this.paymentdata.CC_CVV;

			this.paymentdata_error.CC_number = {"error": false, "help": ""};
			this.paymentdata_error.CC_expiry = {"error": false, "help": ""};
			this.paymentdata_error.CC_CVV = {"error": false, "help": ""};
		}
		else if(this.payment_mode == 'cc')
		{
			this.paymentdata['CC_number'] = this.paymentdata['CC_number'] == undefined ? '' : this.paymentdata['CC_number'];
			this.paymentdata['CC_expiry'] = this.paymentdata['CC_expiry'] == undefined ? '' : this.paymentdata['CC_expiry'];
			this.paymentdata['CC_CVV'] = this.paymentdata['CC_CVV'] == undefined ? '' : this.paymentdata['CC_CVV'];
		}

		delete this.paymentdata.first_name;
		delete this.paymentdata.last_name;

		for(var a in this.paymentdata)
		{
			if (a=='gstin'){
				this.verifyField(this.paymentdata[a], a, true);
			}
			else{
				this.verifyField(this.paymentdata[a], a);
			}
		}

		this.submit_error_payment = false;
		for(var a in this.paymentdata_error)
		{
			if(this.paymentdata_error[a].error)
			{
				this.submit_error_payment = true;
				return;
			}
		}

		let data: any;
		if (this.paymentdata.state == '' || this.paymentdata.state == null)
		{
			data = {
				"country": this.paymentdata.country,
				"zip_code": this.paymentdata.zip_code
			}
		}
		else
		{
			data = {
				"state": this.paymentdata.state,
				"country": this.paymentdata.country,
				"zip_code": this.paymentdata.zip_code
			}
		}

		this.isPaymentFormSubmitted = true;
		this.project_payment_nxt_btn = "Validating info..."
		this.api.post('/assets/validation/', data).subscribe(
			//success handler
			data =>
			{    
				$("#zip_code").removeClass("parsley-error")
				$("#zip_code").next('ul.parsley-errors-list').remove();

				this.submit_error_payment = false;
				
				//GSTIN check for India projects
				if (this.appData.get('buildingData').country == 'IN')
				{
					this.gstin_modal_subject = "";
					this.gstin_modal_body = "";
		
					$("#project-payment-form").removeClass("not-active");
					this.project_payment_nxt_btn = "Submit Payment";
		
					var gstinMap: any = {
						"28": "01", // Andra Pradesh
						"37": "01", // Andra Pradesh
						"12": "02", // Arunachal Pradesh
						"18": "03", // Assam
						"10": "04", // Bihar
						"30": "05", // Goa
						"24": "06", // Gujarat
						"06": "07", // Haryana
						"02": "08", // Himachal Pradesh
						"01": "09", // Jammu and Kashmir
						"29": "10", // Karnataka
						"32": "11", // Kerala
						"23": "12", // Madhya Pradesh
						"27": "13", // Maharashtra
						"14": "14", // Manipur
						"17": "15", // Megalaya
						"15": "16", // Mizoram
						"13": "17", // Nagaland
						"21": "18", // Orissa
						"03": "19", // Punjab
						"08": "20", // Rajasthan
						"11": "21", // Sikkim
						"33": "22", // Tamil Nadu
						"36": "TG", // Telangana
						"16": "23", // Tripura
						"09": "24", // Uttar Pradesh
						"19": "25", // West Bengal
						"35": "26", // Andaman and Nico.In.
						"04": "27", // Chandigarh
						"26": "28", // Dadra and Nagar Hav.
						"25": "29", // Daman and Diu
						"07": "30", // Delhi
						"31": "31", // Lakshadweep
						"34": "32", // Pondicherry
						"22": "33", // Chhaattisgarh
						"20": "34", // Jharkhand
						"05": "35" // Uttaranchal
					};
		
					let gstin: any;
					try
					{
						gstin = this.paymentdata.gstin.trim();
					}
					catch(e)
					{
						gstin = '';
					}
		
					if (gstin.length)
					{
						var gstState: any = gstin[0] + gstin[1];
						var requiredState = gstinMap[gstState];
		
						if (requiredState != this.paymentdata.state)
						{
							for (var i = 0; i < this.all_states.length; i++)
							{
								if (this.all_states[i].code == requiredState)
								{
									requiredState = this.all_states[i].name;
								}
							}
		
							if (requiredState == undefined)
							{
								requiredState = '';
							}
		
							this.gstin_modal_subject = "State mismatch!";
							this.gstin_modal_body = "Entered GSTIN '" + gstin + "'' belongs to '" + requiredState + "'. Please change your billing address to '" + requiredState + "'.";
							$('.gstin_modal').modal('show');
							this.isPaymentFormSubmitted = false;
							this.project_payment_nxt_btn = "Submit Payment";
							this.submit_error_payment = true;
							return false;
						}
						else
						{
							this.makePayment();
						}
					}
					else
					{
						for (var i = 0; i < this.all_states.length; i++)
						{
							if (this.all_states[i].code == this.paymentdata.state)
							{
								this.complete_state = this.all_states[i].name;
							}
						}
						for (var i = 0; i < this.all_countries.length; i++)
						{
							if (this.all_countries[i].code == this.paymentdata.country)
							{
								this.complete_country = this.all_countries[i].name;
							}
						}
						$('.no_gstin_modal').modal('show');
						// this.submit_error_payment = true;
						return false;
					}
				}
				else
				{
					this.makePayment();
				}
				//GSTIN check emds
			},
			//error handler
			data =>
			{
				// $("#zip_code").addClass("parsley-error");
				// if (!$("#zip_code").next('ul.parsley-errors-list').length)
				// {
				//     $("#zip_code").after('<ul class="parsley-errors-list filled"><li class="parsley-required">Enter a valid zip code.</li></ul>');
				// }

				this.submit_error_payment = true;
				try
				{
					this.paymentdata_error['zip_code'] = {
						'error': true,
						'help': "Invalid zip code/state combination"
					};
				}
				catch(e)
				{
					this.paymentdata_error['zip_code'] = {
						'error': true,
						'help': "Enter a valid zip code"
					};
				}

				this.project_payment_nxt_btn = "Submit Payment";
				this.isPaymentFormSubmitted = false;
			}
		);
	}

	check_sez(is_sez: boolean)
	{
		if (is_sez)
		{
			this.taxes = false;
			this.taxes_s = false;
			$('.sez_modal').modal('show');
			return;
		}
		this.paymentdata.is_sez = false;
		this.getPrice(this.selected_price.term);
	}

	fillPaymetricForm()
	{
		var CC_type = this.getCardType(this.paymentdata.CC_number);
		var CC_expiry = this.paymentdata.CC_expiry.split("/");
		var CC_number = this.paymentdata.CC_number;
		var CC_CVV = this.paymentdata.CC_CVV;

		if (CC_type == "American Express")
		{
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 0;
		}
		else if (CC_type == "Visa")
		{
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 1;
		}
		else if (CC_type == "Mastercard")
		{
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 2;
		}
		else if (CC_type == "Discover")
		{
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 3;
		}

		(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardNumber').value = CC_number;
		(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Month').value = CC_expiry[0];
		(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Year').value = CC_expiry[1].slice(-2);
		(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CVV').value = CC_CVV;
		(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('PayNowButton').click();

		if ((<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML != '')
		{
			$("#project-payment-form").removeClass("not-active");
			this.project_payment_nxt_btn = "Submit Payment";
			this.alertService.alert('error', (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML, 5);
			(<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML = "";
			this.isPaymentFormSubmitted = false;
			this.submit_error_payment = true;
			this.project_payment_nxt_btn = "Submit Payment";
		}
	}

	payNow()
	{
		if (this.final_price == 0 && !this.promocode_applied)
		{
			this.alertService.alert('error', 'Something went wrong. Try again.', 5);
			return;
		}

		var paymentdata = this.paymentdata
		if(paymentdata.state == '' || paymentdata.state == null)
		{
			paymentdata.state = '';
		}
		if(paymentdata.is_sez)
			paymentdata["is_sez"] = "1";
		else
			paymentdata["is_sez"] = "0";
		
		paymentdata['material_code'] = this.selected_price.material;
		paymentdata['material_description'] = this.selected_price.description;
		paymentdata['price_per_unit'] = this.selected_price.price_per_unit;
		paymentdata['quantity'] = this.selected_price.quantity;
		paymentdata['unit_price'] = this.selected_price.price;
		paymentdata['company_code'] = this.selected_price.company_code;
		paymentdata['actual_price'] = this.final_price;
		paymentdata['term'] = this.selected_price.term;

		if(this.integration != undefined && !this.change_payment_method)
		{
			if(!this.integration_payment_duration)
			{
				paymentdata['SoReference'] = this.integration_annual_soreference;
			}
			else
			{
				paymentdata['SoReference'] = this.integration_monthly_soreference;
			}
			delete paymentdata['term'];
		}

		delete paymentdata['currency'];
		delete paymentdata['discount'];
		delete paymentdata['discount_percent'];
		delete paymentdata['year'];
		delete paymentdata['price'];

		delete paymentdata['CC_CVV'];
		delete paymentdata['CC_expiry'];
		delete paymentdata['CC_number'];

		if (this.appData.get('buildingData').project_type != "building" && this.appData.get('buildingData').project_type != "school"){
			paymentdata['term'] = 5;
		}

		if(!this.promocode_applied)
		{
			delete paymentdata['promocode'];
		}

		if(this.change_payment_method)
		{
			var cc_new_info = {
				"name": paymentdata.name,
				"email": paymentdata.email,
				"street": paymentdata.street,
				"city": paymentdata.city,
				"state": paymentdata.state,
				"country": paymentdata.country,
				"zip_code": paymentdata.zip_code,
				"subscription_id": this.integration.id,
				"paymetric_r": paymentdata.paymetric_r,
				"paymetric_s": paymentdata.paymetric_s
			}
			this.api.put('/assets/LEED:' + this.leed_id + '/payments/subscriptions/', cc_new_info).subscribe(
				data =>
				{
					this.isPaymentFormSubmitted = false;
					this.global.goToApp("selected_project", 
					{
						"leed_id": this.leed_id,
						"app_name": 'billing'
					});
				},
				error =>
				{
					this.isPaymentFormSubmitted = false;
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
			);
			return;
		}

		this.api.post('/assets/LEED:' + this.leed_id + '/payments/order/', paymentdata).subscribe(
			//success handler
			response =>
			{
				var success_msg = 'Payment received.';
				if (this.payment_mode != 'cc')
				{
					success_msg = 'Invoice generated.';
				}
				if(this.integration != undefined && !this.change_payment_method)
				{
					this.global.goToApp("selected_project", 
					{
						"leed_id": this.leed_id,
						"app_name": 'billing'
					});
					return;
				}
				this.project_payment_nxt_btn = "Setting up your project...";
				this.alertService.alert('success', success_msg, 5);
				this.addTimeline();
			},
			//error handler
			response =>
			{
				var data = response.data;
				this.isPaymentFormSubmitted = false;
				this.project_payment_nxt_btn = "Submit Payment";
				this.createPaymetricForm();
				try
				{
					if("detail" in data)
					{
						this.alertService.alert('error', data.detail, 5);
						return;
					}
					if (data != undefined)
					{
						for (var i = 0; i < data.length; i++)
						{
							this.alertService.alert('error', data[i], 5);
						}
					}
					else
					{
						this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
					}
				}
				catch(e)
				{
					this.alertService.alert('error', 'Something went wrong. Try again.', 5);
				}
			}
		);
	}

	makePayment()
	{
		this.isPaymentFormSubmitted = true;
		this.project_payment_nxt_btn = "Processing payment...";
		if (this.payment_mode == "cc")
		{
			this.fillPaymetricForm();
		}
		else
		{
			delete this.paymentdata.paymetric_r;
			delete this.paymentdata.paymetric_s;
			this.payNow();
		}
	}

	cancelPayment()
	{
		this.disablePayButton = false;
		this.project_payment_nxt_btn = 'Submit Payment';
		this.isPaymentFormSubmitted = false;
	}

	goToProjectSetup()
	{
		this.global.goToApp("project-setup", 
		{
			"leed_id": this.leed_id,
			"app_name": 'project-setup',
		});
	}

	completeRegistration()
	{
		$('.spaceTypeModal').modal('hide');
		setTimeout(() =>
		{
			this.api.get('/assets/LEED:' + this.leed_id + '/').subscribe(
				data =>
				{
					if(this.mode == 'project_add_portfolio')
					{
						// this.addToPortfolio();
						this.global.addToPortfolio([this.pf_id], [this.leed_id]).subscribe(
							data =>
							{
								this.project_detail_nxt_btn = "Add project";
								this.loading = false;
								this.global.goToApp("selected_portfolio", 
								{
									"portfolio_id": this.pf_id,
									"app_name": "portfolio-overview"
								});
								this.alertService.alert('success', 'Project(s) added to portfolio', 5);
							},
							error =>
							{
								this.loading = false;
								this.alertService.alert('error', 'Error adding projects to portfolio', 5);
							}
						);
					}
					else
					{
						this.appData.set('buildingData', data);
						this.global.moveToProject(this.appData.get('buildingData'), 0);
					} 
				},
				error =>
				{

				}
			);
		}, 500)
	}

	moveToLORegistrationPage(val?: string)
	{
		if (val == "yes"){
			this.go_to_LO_text = 'Redirecting...';
			this.go_to_LO_flag = true;
			this.auth.getLeedOnlineToken().subscribe(
				//success handler
				data =>
				{
					if(this.appData.get('buildingData').project_type == 'city')
					{
						var redirectPage = "cities/register";
					}
					else if(this.appData.get('buildingData').project_type == 'community')
					{
						var redirectPage = "communities/register";
					}
					else
					{
						var ratingSystem = "LEED V4.1 O+M: EB";   
						var redirectPage = "projects/register?type=INP&rs=" + ratingSystem; 
					}
					var redirectPage = encodeURIComponent(redirectPage);
					var loUrl = Config.config.settings.LO_URL + "user/login?token=" + data.token + "&clientKey=" + data.clientKey + "&redirect=" + redirectPage;
					window.open(loUrl, "_target");
					this.go_to_LO_text = 'Register for LEED certification now';
					if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community'){
						this.go_to_LO_text = 'Go to LEED Online';
					}
					this.go_to_LO_flag = false;
				},
				//error handler
				data =>
				{
					this.go_to_LO_text = 'Register for LEED certification now';
					if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community')
					{
						this.go_to_LO_text = 'Go to LEED Online';
					}
					this.go_to_LO_flag = false;
				}
			);
		}
		else{
			$('#leed_registration_block').modal('hide');
			this.registerLEEDOrNonLEED("no");
		}
	}

	resetOrganizationDetails()
	{
		this.formdata.manageEntityCountry = "US";
		this.formdata.organization = "";
		this.formdata_error['organization'] ={
			'error': true,
			'help': 'This field is required'
		};
		$('.ofac_invalid_modal').modal('hide');
	}

	set_sez(set_sez: boolean)
	{
		if (set_sez)
		{
			this.paymentdata.is_sez = true;
		}
		else
		{
			this.paymentdata.is_sez = false;
		}
		this.getPrice(this.selected_price.term);
	}

	addTimeline()
	{
		this.api.post('/assets/LEED:' + this.leed_id + '/timeline/',
		{}).subscribe(
			//success handler
			data =>
			{
				this.alertService.alert('success', 'Setup successful', 5);
				this.appData.get('buildingData').building_status = 'activated_payment_done';

				var arr_temp = this.paymentdata.name.split(' ');
				var result_temp = arr_temp.splice(0, 1);
				result_temp.push(arr_temp.join(' '));
				var phone = this.phone_number;

				var street2 = '';

				var final_data = {
					'name': this.paymentdata.name,
					'first_name': ((this.paymentdata.name).substr(0,(this.paymentdata.name).indexOf(' '))).trim().substring(0, 20),
					'last_name': ((this.paymentdata.name).substr((this.paymentdata.name).indexOf(' ')+1)).trim().substring(0, 19),
					'email': this.paymentdata.email,
					'street1': this.paymentdata.street,
					'street2': street2,
					'street': (this.paymentdata.street + ' ' + street2).trim(),
					'city': this.paymentdata.city,
					'state': this.paymentdata.state,
					'country': this.paymentdata.country,
					'zip_code': this.paymentdata.zip_code,
					'phone': phone
				};

				if (this.appData.get('buildingData').country == 'IN')
				{
					this.appData.set('INR_payment_data', final_data);
				}
				else
				{
					this.appData.set('USD_payment_data', final_data);
				}

				if(Config.config.settings.space_type_notification.indexOf(this.appData.get('buildingData').spaceType) != -1)
				{
					$('.spaceTypeModal').modal('show');
				}
				else
				{
					this.completeRegistration();
				}
			},
			//error handler
			response =>
			{
				$("#project-payment-form").removeClass("not-active");
				this.project_payment_nxt_btn = "Next";
				this.alertService.alert('error', 'Something went wrong while setting up your project', 5);
				this.completeRegistration();
			}
		);
	}

	getDataFromModal(event: any)
	{
		this.formdata.organization = event.formdata;
		// this.org_query = event.org_query;
		// this.isUpdateOrgModal = event.isUpdateOrgModal;
		// this.isOrganizationBlocked = event.isOrganizationBlocked;
		this.modal.close();
	}

	openModal()
	{
		this.modal.open();
	}
}

<div class="p2535">
    
    <div class="left-right-align">
        <h4 class="mb25 fw-600">Why is my score showing as an estimate?</h4> 
        <!-- <span class="gray-color cursor-pointer">X</span>   -->
    </div>

    <p class="fw-400 mb20">
        Arc helps you drill down to the individual asset level to track and improve performance over time. To help you get started, Arc has made some assumptions about your projects.
    </p>

    <div *ngIf="project != undefined" class="estimate_score_card mb20">
        <div class="item">
            <p class="mb20 fw-600">SCORE {{project.estimated_data_color == 'green' ? 'COMPLETE' : 'INCOMPLETE'}}</p>
            <div class="arc-breadcrumbs {{project.estimated_data_color}}">
                <div class="w15p"></div>
                <div class="w15p"></div>
                <div class="w15p"></div>
            </div>
        </div>

        <div class="item">
            <p class="fw-600 m0">SCORE</p>
            <p class="m0">
                <span class="fw-600 fontSize25">{{project.total_score}}</span> <span class="fw-400">Estimated</span>
            </p>
        </div>
    </div>

    <p class="fw-400">
        To move a project with red or orange status to green all of the following actions must be completed:
    </p>

    <ul class="fw-400 estimate_list mb20">
        <li>Update the Arc generated default operating hours (Arc bases the estimate on a 60 hour work week).</li>
        <li>Update the Arc generated occupancy value using the occupancy calculator available in Arc (Arc bases the occupancy estimate on your total gross area divided by 25.</li>
        <li>One or more of the meters in your asset does not have 100% data coverage. In order to provide an estimate, Arc has extrapolated your reported consumption value to the maximum coverage.</li>
        <li><span class="error-color">Red</span>: A red flag means that no actions have been taken to provide updated information into Arc and the score you’re seeing is still an estimate.</li>
        <li><span class="orange-color">Orange</span>: An orange flag means that some actions have been taken to provide updated information into Arc and the score you’re seeing is still an estimate.</li>
        <li><span class="green-color">Green</span>: A green flag means that you have completed all actions needed to update information in Arc and the score you’re seeing is no longer an estimate.</li>
    </ul>

    <div class="align-right">
        <button (click)="cancel()" data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Global } from 'src/app/services/global.service';

@Component({
  selector: 'app-covid-flag',
  templateUrl: './covid-flag.component.html',
  styleUrls: ['./covid-flag.component.css']
})
export class CovidFlagComponent implements OnInit {
  bld_data: any = {};
  checkLEED: boolean = false;
  loader: boolean = false;
  not_active: boolean = false;
  leed_id: any = '';

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  checkLEEDID() {
    if (isNaN(this.leed_id) || String(this.leed_id).length == 0) {
      this.checkLEED = false;
    }
    else {
      this.checkLEED = true;
    }
  };

  searchProject() {
    if (!this.checkLEED) {
      return false;
    }
    this.loader = true;
    this.api.get('/assets/LEED:' + this.leed_id + '/').subscribe(
      data => {
        var dataGET = data;
        this.bld_data = dataGET;
        this.loader = false;

        if (this.bld_data.impacted_by_covid) {
          $('#impacted_by_covid').prop('checked', true);
        }
        else {
          $('#impacted_by_covid').prop('checked', false);
        }

        if (this.bld_data.time_weighted_occupancy) {
          $('#time_weighted_occupancy').prop('checked', true);
        }
        else {
          $('#time_weighted_occupancy').prop('checked', false);
        }

        if (this.bld_data.time_weighted_waste) {
          $('#time_weighted_waste').prop('checked', true);
        }
        else {
          $('#time_weighted_waste').prop('checked', false);
        }

        if (this.bld_data.approved_covid_extension) {
          $('#approved_covid_extension').prop('checked', true);
        }
        else {
          $('#approved_covid_extension').prop('checked', false);
        }
      },
      error => {
        this.loader = false;
        this.bld_data = {};
        try {
          this.alertService.alert('error', error.error.detail, 5);
        }
        catch (e) {
          this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  makeAction(bld: any, id: any) {
    this.not_active = true;
    var putData = {};
    if (id == 'impacted_by_covid') {
      putData = { "impacted_by_covid": $('#impacted_by_covid').prop('checked') };
    }
    else if (id == 'time_weighted_occupancy') {
      putData = { "time_weighted_occupancy": $('#time_weighted_occupancy').prop('checked') };
    }
    else if (id == 'time_weighted_waste') {
      putData = { "time_weighted_waste": $('#time_weighted_waste').prop('checked') };
    }
    else if (id == 'approved_covid_extension') {
      putData = { "approved_covid_extension": $('#approved_covid_extension').prop('checked') };
    }
    this.api.put('/assets/LEED:' + bld.leed_id + '/', putData).subscribe(
      data => {
        this.not_active = false;
        this.bld_data = data;

        if (this.bld_data.impacted_by_covid) {
          $('#impacted_by_covid').prop('checked', true);
        }
        else {
          $('#impacted_by_covid').prop('checked', false);
        }

        if (this.bld_data.time_weighted_occupancy) {
          $('#time_weighted_occupancy').prop('checked', true);
        }
        else {
          $('#time_weighted_occupancy').prop('checked', false);
        }

        if (this.bld_data.time_weighted_waste) {
          $('#time_weighted_waste').prop('checked', true);
        }
        else {
          $('#time_weighted_waste').prop('checked', false);
        }

        if (this.bld_data.approved_covid_extension) {
          $('#approved_covid_extension').prop('checked', true);
        }
        else {
          $('#approved_covid_extension').prop('checked', false);
        }
        this.alertService.alert('success', 'Updated successfully', 5);
      }, error => {
        this.not_active = false;
        this.alertService.alert('error', 'Something went wrong', 5);
      }
    );
  };

  ngOnInit(): void {
  }

}

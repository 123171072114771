import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
import { InsightService } from 'src/app/services/insight.service';

import { Config } from '../../models/env-vars.model';
import { ModalComponent } from '../modal/modal.component';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit
{
	@Input() current_nav: string = '';
	@Output() appChanged = new EventEmitter<string>();
	@ViewChild('modal') modal!: ModalComponent;
	@ViewChild('create_projects') create_projects!: ModalComponent;

	config = Config;
	loading_prj_access: boolean = false;
	isSearchActive: boolean = false;
	nav_search: boolean = false;
	arc_pro_label: string = Config.arc_pro_label;
	arc_gsa_label: string = Config.arc_gsa_label;
	query: string = '';
	canceler: any;
	
	username: string = '';
	profileimg: string = '';

	constructor(
		public appData: AppData,
		public global: Global,
		public insight: InsightService,
		private api: API,
		private alertService: alertService
	)
	{

	}

	goBackToLO()
	{
		var url = Config.config.settings.LO_URL + "projects";
		if(this.appData.get('LO_referer') && this.appData.get('LO_referer_id') != undefined)
		{
			url = Config.config.settings.LO_URL + "projects/credits/" + this.appData.get('LO_referer_id');
		}
		window.location.href = url;
	}

	searchDisable()
	{
		this.nav_search = false;
		this.query = "";
		$('.searchDropdown').hide();
		Config.searchResults_leed_id = [];
		Config.searchResults_pf_id = [];
	}

	searchEnable()
	{
		$('.searchDropdown').show();
		this.nav_search = Config.searchResults_leed_id.length != 0 || Config.searchResults_pf_id.length != 0 ? true : false;
	}

	deleteSearch()
	{
		this.isSearchActive = false;
		this.nav_search = false;
		this.query = '';
		this.searchDisable();
		this.searchToggle();
	}

	searchToggle(): void
	{
		var container = $('.search-wrapper');
		var nav_options = $('.nav_options');
		if (!container.hasClass('active'))
		{
			$('#search-input').focus();
			container.addClass('active');
			nav_options.addClass('tranparent');
			this.isSearchActive = true;
		}
		else if (container.hasClass('active'))
		{
			this.searchDisable();
			container.removeClass('active');
			nav_options.removeClass('tranparent');
			container.find('.search-input').val('');
			this.isSearchActive = false;
		}
	}

	search(): void
	{
		if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
		if(this.query.length == 0)
		{
			Config.searchResults_leed_id = [];
			Config.searchResults_pf_id = [];
			return;
		}
		this.canceler = this.api.get(
			'/assets/search/?q=' + encodeURIComponent(this.query)
		).subscribe(result =>
		{
			this.nav_search = true;
			let data = result.building;
			let data_portfolio = result.portfolio;
			let searchResults_leed_id = [];
			let searchResults_pf_id   = [];
			let iterator = data.length;
			let proj_counter = 0;

			if(data.length > 5)
			{
				iterator = 5
			}
			
			for (let i = 0; i < data.length; i++)
			{
				if(proj_counter < iterator)
				{
					proj_counter += 1;
					searchResults_leed_id.push(data[i])   
				}
			}

			if (!data.length)
			{
				searchResults_leed_id = [];
			}

			Config.searchResults_leed_id = searchResults_leed_id;
			
			proj_counter = 0;
			iterator = data_portfolio.length;
			
			if(data_portfolio.length > 5)
			{
				iterator = 5
			}
			
			for (let i = 0; i < data_portfolio.length; i++)
			{
				if(proj_counter < iterator)
				{
					proj_counter += 1;
					searchResults_pf_id.push(
					{
						'name': data_portfolio[i].name,
						'pf_id': data_portfolio[i].pf_id,
						'organization_country': data_portfolio[i].organization_country,
						'organization': data_portfolio[i].organization,
						'organization_contact': data_portfolio[i].organization_contact,
						'source': data_portfolio[i].source
					});   
				}
			}

			if (!data_portfolio.length)
			{
				searchResults_pf_id = [];
			}

			Config.searchResults_pf_id = searchResults_pf_id;
		});
	}

	goTo(component: string)
	{
		this.appData.set('project_type', 'building,school');
		if(this.appData.get('project_type') == 'building,school')
		{
			this.appData.set('project_type', 'all');
		}
		this.global.goToApp(component);
	}

	addProjectToPortfolioModal()
	{
		this.modal.open();
	}

	createProjects()
	{
		this.create_projects.open({ windowClass: 'certificate_modal' });
	}

	closeModal()
	{
		this.create_projects.close();
	}
	
	ngOnInit(): void
	{
		this.username = this.global.getUsername();
		this.profileimg = this.appData.get('profileimg');

		$('#portfolio_filters').on('click', function(e)
		{
			if($(this).hasClass('dropdown-menu')) 
			{
				e.stopPropagation();
			}
		});

		$('body').click((e: any) =>
        {
            try
            {
				if($(e.target).attr('id') == 'searchBar1' || $(e.target).attr('id') == 'search-input' || $(e.target).attr('id') == 'searchBar2' || (<any>$(e.target).attr('class')).split(' ')[0] == 'search_button' || $(e.target).parents('div.searchDropdown').length > 0)
                {
                    this.nav_search = true;
                    this.searchEnable();
                }
                else
                {
                    this.nav_search = false;
                    this.searchDisable();
                }
            }
            catch (e)
            {}
        });
	}

}

import { Injectable } from '@angular/core';
import { API } from './api.service';
import { AppData } from './appdata.service';


@Injectable({
  providedIn: 'root'
})
export class CertificationService {
  
  config: any;   

  constructor(
    private api: API,
		private appData: AppData

  ) { }
  
  
  get(): any
  {  
    return this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/certifications/'
		);    
  }
  getRatingSystemList(): any
  {  
    return this.api.get(
			'/ratingsystem/'
		);  
  }
  

}

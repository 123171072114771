<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold visible-xs-inline" translate>Manage</span>
            <span class="fw-semi-bold" translate>Settings</span>
        </h1>
        
        <section [ngClass]="loading ? 'not-active' : ''" class="widget overflow-auto">
            <app-loader *ngIf="loading"></app-loader>
            <div class="col-md-7 pl0 manage_settings">
        
                <div class="settings_row" *ngIf="appData.get('project_type')=='building' || appData.get('project_type')=='transit' || appData.get('project_type') == 'school'">
                    <div class="fieldset">
                        <div class="w80p">
                            <div class="label" translate>Activate Lobby Survey</div>
                            <p class="desc" translate>Administer the survey on multiple devices within a common area.</p>
                        </div>
                        <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="pull-left mr10">
                            <input (ngModelChange)="editAsset({
                                'lobby_survey_status': !lobby_survey_status
                            })" [(ngModel)]="lobby_survey_status" type="checkbox" class="switch_lobby_survey"/>
                        </div>  
                    </div>
                </div>
        
        
                <div class="settings_row">
                    <div class="fieldset">
                        <div class="w80p">
                            <div class="label" translate>Show the score animation on the Arc app</div>
                            <p class="desc" translate>Selecting 'Yes' will show the score animation on the Arc app. By selecting 'No,' the score animation will not be visible on the Arc app.</p>
                        </div>
                        <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="pull-left mr10">
                            <input (ngModelChange)="editAsset({
                                'leed_score_public': !leed_score_public
                            })" [(ngModel)]="leed_score_public" type="checkbox" class="leed_score_public"/>
                        </div>  
                    </div>
                </div>
        
                <div *ngIf="appData.get('project_type')=='building' || appData.get('project_type') == 'school'" class="settings_row">
                    <div class="fieldset">
                        <div class="w80p">
                            <div class="heading_label" translate>Email Updates</div>
                        </div>
                    </div>
                    <div class="fieldset">
                        <div class="w80p">
                            <div class="label" translate>Receive email updates for this project</div>
                            <p class="desc" translate>Get a monthly email update of scores and metrics.</p>
                        </div>
                        <div [ngClass]="config.access != 'CRUD' ? 'not-active' : ''" class="pull-left mr10">
                            <input (ngModelChange)="monthlyEmailNotification({
                                'destination': appData.get('user_id'),
                                'monthly_email_notification': !monthly_email,
                                'stype': 'monthly_email'
                            })" [(ngModel)]="monthly_email" type="checkbox" class="monthly_email"/>
                        </div>  
                    </div>
                </div>
            </div>
        
            <div class="col-md-5 settings_resources">
                <h4 translate>
                    Score animation
                </h4>
                <p class="help mb5" translate>Link to share your current score</p>
                
                <div class="dropdown email-dropdown mb16">
                    <button class="btn-vertical dropdown-toggle btn btn-default" type="button" data-toggle="dropdown">
                        <span translate>Copy Link</span>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                    <!-- <ul ng-init="initCopyTippy()" class="dropdown-menu"> -->
                    <ul class="dropdown-menu">
                        <li (click)="copyLink('en')" class="language_unit">
                            <a>English</a>
                        </li>
                        <li (click)="copyLink('fr')" class="language_unit">
                            <a>French</a>
                        </li>
                    </ul>
                    <span class="copy_notif">Copied!</span>
                </div>
        
                <h4 class="mb8" translate>
                    Data Resources
                </h4>
                <div (click)="redirectToDataResource()" class="link" translate>Data guide for buildings</div>
                <div (click)="downloadDataReviewChecklist()" class="link" translate>Data review checklist (pdf)</div>
            </div>
        </section>

    <div>
<div>

<div class="content-wrap">
    <div id="content" class="content admin_tool_content">		
		<div class="admin_review_body">
			<h3 class="reviewWrapper">Project Review<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb10 pt30">Enter LEED ID to view submitted documents for review</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group" [ngClass]="loader ? 'not-active' : ''">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="leed_id" name = "leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID">
                                </div>
                                <div class="wizard display-block">
                                    <button (click)="getReviewDataFromS3()" class="btn btn-default pull-left mr10"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                    <div hidden="loader" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>                    
                        </fieldset>
                    </form>
                    <table class="table table-striped table-hover no-footer">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date submitted</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="downloading ? 'not-active': ''" *ngFor="let data of filtered_data" class="cursor-pointer">
                                <td class="capitalize" (click)="downloadKey(data)">
                                    <span hidden="downloading" class="dataInputLoaderSpinner pull-left mr5 mt5"></span>
                                    <a>{{data.name}}</a>
                                </td>
                                <td class="capitalize">{{global.dateTimeFormat(data.last_modified)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</div>
<div id="portfolio_report" class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title">
            <span class="fw-semi-bold">Reports</span>
          </h1>
          
          <section class="widget">
          
              <ul class="meterNav mb25">
                  <li (click)="toggleTab('Report Settings')" class="meterNav--item">
                      <span [ngClass]="current_tab == 'Report Settings' ? 'meterNav--item__active' : ''">Report Settings</span>
                  </li>
                  <li (click)="toggleTab('Past Reports')" class="meterNav--item">
                      <span [ngClass]="current_tab == 'Past Reports' ? 'meterNav--item__active' : ''">Past Reports</span>
                  </li>
              </ul>
          
              <div [ngClass]="loading ? 'not-active' : ''">
                  <app-loader *ngIf="loading"></app-loader>
              
                  <div *ngIf="current_tab == 'Report Settings'">
          
                      <div *ngIf="appData.get('user_role') != 'arc_pro'">
                          <div class="row">
                              <div class="col-md-8 pl0">
                                  <div class="row">
                                      <div class="col-md-10 pl0 pr0 border_lrb mb30">
                                          <div class="report_banner_img col-md-12"></div>
                                          <div class="col-md-12 fs18 fw-500 pt25 pb10">Create custom reports with {{config.arc_pro_label}}</div>
                                          <div class="col-md-12 fs16 pb5">Use advanced features to improve performance.</div>
                                          <div class="col-md-12 fs16 fw-500">{{config.currency}}{{config.price.arc_pro_annually}}/user/month when billed annually or {{config.currency}}{{config.price.arc_pro_monthly}}/user/month when paying month to month.</div>
                                          <div class="col-md-12 pt20 pb20">
                                              <button class="btn btn-primary fs16 h40 br2p mr10" (click)="global.filterAccess('app.arc_pro_purchase')">Purchase Subscription</button>
                                              <button (click)="global.learnMore('arc_pro')" class="btn btn-primary inverse fs16 h40 br2p">Learn more</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
          
                      <div *ngIf="appData.get('user_role') == 'arc_pro'">
                          <div class="mt24 fs16 line_height_24 mb16">
                              Create a downloadable report for this portfolio
                          </div>
                          <button [ngClass]="appData.get('is_admin') || config.portfolio_access != 'can_edit' ? 'not-active' : ''" (click)="createReportModal()" class="btn btn-primary inverse fs15 fw-500 mb40">
                              Create a report
                          </button>
                          <div id="report_settings">
                              <div class="fw-500 fs18 line_height_32">Report title page images</div>
                              <div class="line_height_24 gray_color_68737D mb24">Upload company logo for inclusion on your report title pages.</div>
              
                              <div [ngClass]="appData.get('is_admin') || config.portfolio_access != 'can_edit' ? 'not-active' : ''" class="flex mb16">
                                  <input accept="image/*" #elem (input)="fileNameChanged(elem)" class="hide upload_report_title" type="file">
                                  <div class="title_images">
                                      <div *ngIf="icons.first == undefined" (click)="selectImage('first')" class="placeholder">
                                          <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                      </div>
                                      <div class="icon_wrapper" *ngIf="icons.first != undefined">
                                          <svg (click)="deleteIcon(icons.first, 'first')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                          <img src="{{getPath(icons.first)}}">
                                      </div>
                                  </div>
                                  <div class="title_images">
                                      <div *ngIf="icons.second == undefined" (click)="selectImage('second')" class="placeholder">
                                          <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                      </div>
                                      <div class="icon_wrapper" *ngIf="icons.second != undefined">
                                          <svg (click)="deleteIcon(icons.second, 'second')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                          <img src="{{getPath(icons.second)}}">
                                      </div>
                                  </div>
                                  <div class="title_images">
                                      <div *ngIf="icons.third == undefined" (click)="selectImage('third')" class="placeholder">
                                          <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                      </div>
                                      <div class="icon_wrapper" *ngIf="icons.third != undefined">
                                          <svg (click)="deleteIcon(icons.third, 'third')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                          <img src="{{getPath(icons.third)}}">
                                      </div>
                                  </div>
                                  <div class="title_images">
                                      <div *ngIf="icons.fourth == undefined" (click)="selectImage('fourth')" class="placeholder">
                                          <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                      </div>
                                      <div class="icon_wrapper" *ngIf="icons.fourth != undefined">
                                          <svg (click)="deleteIcon(icons.fourth, 'fourth')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                          <img src="{{getPath(icons.fourth)}}">
                                      </div>
                                  </div>
                                  <div class="title_images">
                                      <div *ngIf="icons.fifth == undefined" (click)="selectImage('fifth')" class="placeholder">
                                          <svg height="28" viewBox="0 0 24 24" width="28"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"/></svg>
                                      </div>
                                      <div class="icon_wrapper" *ngIf="icons.fifth != undefined">
                                          <svg (click)="deleteIcon(icons.fifth, 'fifth')" class="remote_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0V0z" fill="transparent" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" stroke="black" fill="white"/></svg>
                                          <img src="{{getPath(icons.fifth)}}">
                                      </div>
                                  </div>
                              </div>
                              
                              <div class="line_height_24 gray_color_68737D">Drag photos to the boxes above or chose files from your computer.<br> Images should be no larger than 5MB</div>
                          </div>
                      </div>
                  </div>
          
                  <div *ngIf="current_tab == 'Past Reports'">
                      <table id="reports_table" class="table table-striped arc-table">
                          <thead>
                              <tr>
                                  <th>Report</th>
                                  <th>Created by</th>
                                  <th>Date Created</th>
                                  <th>Report Type</th>
                                  <th>View Report</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr title="{{data.message}}" class="tippy_init cursor-pointer" *ngFor="let data of feeds">
                                  <td class="flex">
                                      <div class="report_images" *ngIf="data.data.report_title != null">
                                          <img src="{{getPath(undefined, data.data.report_title)}}">
                                      </div>
                                      <div class="report_images" *ngIf="data.data.report_title == null">
                                          <div class="arc_logo_white arc_logo_white-sm"></div>
                                      </div>
                                      <div class="ml15 pt15">{{data.data.file_name}}</div>
                                  </td>
                                  <td class="fw-500">
                                      {{data.data.username}}
                                  </td>
                                  <td class="">
                                      {{data.data.timestamp}}
                                  </td>
                                  <td>
                                      
                                  </td>
                                  <td colspan="2">
                                      In Progress...
                                  </td>
                              </tr>				    
                              <tr [ngClass]="appData.get('is_admin') || config.portfolio_access != 'can_edit' ? 'not-active' : ''" *ngFor="let data of retry; let idx = index">
                                  <td class="flex">
                                      <div class="report_images" *ngIf="data.verb != null">
                                          <img src="{{getPath(undefined, data.verb)}}">
                                      </div>
                                      <div class="report_images" *ngIf="data.verb == null">
                                          <div class="arc_logo_white arc_logo_white-sm"></div>
                                      </div>
                                      <div class="ml15 pt15">{{data.foreign_id.split("/")[2]}}</div>
                                  </td>
                                  <td class="fw-500">
                                      {{data.actor}}
                                  </td>
                                  <td class="">
                                      {{data.data.timestamp}}
                                  </td>
                                  <td></td>
                                  <td class="relative-position">
                                      <div title="Report creation failed. Click here to retry" class="tippy_init cursor-pointer error-color">
                                          Error
                                      </div>
                                  </td>
                                  <td [ngClass]="appData.get('is_admin') || config.portfolio_access != 'can_edit' ? 'not-active' : ''">
                                      <div title="Cancel Task" class="delete_report tippy_init" (click)="deleteFeed(data, idx)"></div>
                                  </td>
                              </tr>	
                              <tr [hidden]="reports.length == 0" *ngFor="let data of reports">
                                  <td class="flex">
                                      <div class="report_images" *ngIf="data.document_metadata != undefined && data.document_metadata.doc_id != null">
                                          <img src="{{getPath(undefined, data.document_metadata.doc_id)}}">
                                      </div>
                                      <div class="report_images" *ngIf="data.document_metadata == undefined || data.document_metadata.doc_id == null">
                                          <div class="arc_logo_white arc_logo_white-sm"></div>
                                      </div>
                                      <div class="ml15 pt15">{{global.getFileName(data)}}</div>
                                  </td>
                                  <td class="fw-500">
                                      {{data.uploaded_by}}
                                  </td>
                                  <td class="">
                                      {{global.dateFormat(data.uploaded_at)}}
                                  </td>
                                  <td>
                                      <span *ngIf="type == 'portfolio_report'">
                                        {{data.doc_source == 'report_export' ? 'Portfolio Excel Report' : 'Portfolio Performance Report'}}
                                      </span>
                                      <span *ngIf="type == 'ea_report'">
                                        Efficiency Analysis Report
                                      </span>
                                  </td>
                                  <td class="">
                                      <a (click)="downloadCert(data)">
                                          Download
                                      </a>
                                  </td>
                                  <td [ngClass]="appData.get('is_admin') || config.portfolio_access != 'can_edit' ? 'not-active' : ''">
                                      <div title="Delete Report" class="delete_report tippy_init" (click)="deleteReport(data)"></div>
                                  </td>
                              </tr>			    
                          </tbody>
                          <tbody *ngIf="reports.length == 0 && feeds.length == 0">
                              <tr>
                                  <td colspan="5" class="noProjects">
                                      No reports created.
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>

    </div>
</div>

<modal #modal>
    <div class="p1632 semi-md">
        <div class="left-right-align mb8 border-bottom pb16">
            <div class="fs24 line_height_40 fw-500">
                Create a report
            </div> 
        </div>
        <div *ngIf="type == 'portfolio_report'">
            <div class="line_height_32 fs18 fw-500 mb15">1. Select report type</div>
            <div class="radio_buttons mb30">
                <div class="flex mt8">
                    <div class="mr25">
                        <span class="checkcontainer" [ngClass]="report_type ? 'fw-500' : ''" (click)="toggleType()">
                            Portfolio Performance Report
                            <input type="radio" name="report_type" [value]="true" [(ngModel)]="report_type">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
        
                    <div>
                        <span class="checkcontainer" [ngClass]="!report_type ? 'fw-500' : ''" (click)="toggleType()">
                            Portfolio Excel Report
                            <input type="radio" name="report_type" [value]="false" [(ngModel)]="report_type">
                            <span class="radiobtn"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="line_height_32 fs18 fw-500 mb15">2. Select a 12-month range</div>
            <div class="relative-position mb30">
                <app-performance-date-picker [mode]="'portfolio'" [type]="'custom_cal'"></app-performance-date-picker>
            </div>
        </div>
        <div *ngIf="report_type" class="line_height_32 fs18 fw-500 mb15">
            <span *ngIf="type == 'portfolio_report'">3.</span> Select title page image:
        </div>
        <div *ngIf="report_type" class="left-right-align mb55">
            <div id="select_title">
                <div class="flex mb16">
                    <div class="title_images" [ngClass]="selected_icon == null ? 'active' : ''" (click)="selectImageModal(null)">
                        <div class="icon_wrapper">
                            <div class="arc_logo_white"></div>
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'first' ? 'active' : ''" (click)="selectImageModal('first')" *ngIf="icons.first != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.first)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'second' ? 'active' : ''" (click)="selectImageModal('second')" *ngIf="icons.second != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.second)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'third' ? 'active' : ''" (click)="selectImageModal('third')" *ngIf="icons.third != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.third)}}">
                        </div>
                    </div>
                    <br>
                </div>
                <div class="flex mb16">
                    <div class="title_images" [ngClass]="selected_icon == 'fourth' ? 'active' : ''" (click)="selectImageModal('fourth')" *ngIf="icons.fourth != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.fourth)}}">
                        </div>
                    </div>
                    <div class="title_images" [ngClass]="selected_icon == 'fifth' ? 'active' : ''" (click)="selectImageModal('fifth')" *ngIf="icons.fifth != undefined">
                        <div class="icon_wrapper">
                            <svg class="check_title" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#1E88E5"/></svg>
                            <img src="{{getPath(icons.fifth)}}">
                        </div>
                    </div>
                </div>
            </div>
    
            <div>
                <div class="fs16 line_height_24 fw-500 mb8">Report preview:</div>
                <div id="report_preview">
                    <div [ngClass]="selected_icon != null ? 'white_bg' : ''" class="banner">
                        <div class="title_images" *ngIf="selected_icon != null">
                            <div class="icon_wrapper">
                                <img src="{{getPath(icons[selected_icon])}}">
                            </div>
                        </div>
                        <div class="title_images" *ngIf="selected_icon == null">
                            <div class="icon_wrapper">
                                <div class="arc_logo_white"></div>
                            </div>
                        </div>
                    </div>
                    <div class="report_content">
                        <div class="thin w50p" style="margin-bottom: 9.31px;"></div>
                        <div class="fat w100p" style="margin-bottom: 3.49px;"></div>
                        <div class="fat w100p" style="margin-bottom: 9.31px;"></div>
                        <div class="thin w25p" style="margin-bottom: 4.65px;"></div>
                        <div class="thin w25p"></div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="border-top pt16">
            <button (click)="createReport()" data-dismiss="modal" type="button" class="btn btn-primary btn-md mr25">Create</button>
            <button (click)="cancel()" data-dismiss="modal" type="button" class="btn grey btn-default btn-md">Cancel</button>
        </div>
    </div>
</modal>
import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { Projects } from '../../services/projects.service';
import { ValidationService } from '../../services/validation.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
    config = Config;
    regex: any = this.validator.regex;

    canada_payment: boolean = false;

    payment_mode: string = this.appData.get('buildingData').country == 'IN' ? 'check' : 'cc';
    no_error = {
        'error': false,
        'help': undefined
    };
    paymentdata_error: any = {
        'first_name': this.no_error,
        'last_name': this.no_error,
        'email': this.no_error,
        'street': this.no_error,
        'city': this.no_error,
        'phone': this.no_error,
        'zip_code': this.no_error,
        'gstin': this.no_error,
        'CC_CVV': this.no_error,
        'CC_expiry': this.no_error,
        'CC_number': this.no_error
    };
    disablePayButton: boolean = false;
    project_payment_nxt_btn: string = 'Submit Payment';
    canada_payment_nxt_btn: string = 'Submit';
    taxes: any = false;
    taxes_s: any = false;
    refreshing_price: boolean = true;
    refreshing_price_txt: string = 'Refreshing Price';
    promocode_price: any = {};
    promocode_applied: boolean = false;
    invalid_promo_code: boolean = false;
    final_price: any = 0;
    paymentdata: any = {
        'name': '',
        'first_name': '',
        'last_name': '',
        'email': this.appData.get('user_id'),
        'street': '',
        'city': '',
        'state': 'DC',
        'country': 'US',
        'zip_code': "",
        'phone': "",
        'gstin': "",
        'unit_price': 0,
        'price_per_unit': 0,
        'material_code': '',
        'material_description': '',
        'quantity': '',
        'company_code': '',
        'price': '',
        'currency': '',
        'discount': '',
        'discount_percent': '',
        "SoReference": "",
        'paymetric_r': '',
        'paymetric_s': '',
        "is_sez": false
    };
    places: any = [];
    searching_place: boolean = false;
    uploaded_files: any = [];
    countries: any = [];
    states: any = [];
    data_divisions: any = [];
    auth_data: any = {};

    payment_currency: string = '';
    price_obj: any = [];
    selected_price: any = {};
    review_price: any = {};
    isPaymentFormSubmitted: boolean = false;
    gstin_modal_subject: string = "";
    gstin_modal_body: string = "";
    complete_state: any = '';
    complete_country: any = '';
    upload_category: any = '';
    uploading: any = [];
    currentUpload: boolean = false;
    currentUploadName: string = '';
    project_price: any = {};
    canceler: any;

    soreference: string = this.global.getURLFromParam('type') != undefined ? this.global.getURLFromParam('type') : 'recertification';

    constructor(
        public global: Global,
        private api: API,
        private projects: Projects,
        public appData: AppData,
        private alertService: alertService,
        private validator: ValidationService
    ) { }

    doSorting(obj: any) {
        var newList = [];
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                newList.push(
                    {
                        code: key,
                        name: obj[key]
                    });
            }
        }
        newList = newList.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return newList;
    };

    getCountries() {
        this.api.get('/country/states/').subscribe(
            data => {
                this.data_divisions = data.divisions;
                this.countries = this.doSorting(data.countries);
                if (this.appData.get('buildingData').country == 'IN') {
                    this.countries = [{ code: 'IN', name: 'India' }];
                    this.states = this.doSorting(data.divisions['IN']);
                    this.paymentdata.state = '26';
                    this.paymentdata.country = 'IN';
                }
                else {
                    this.states = this.doSorting(data.divisions['US']);
                    this.paymentdata.state = 'DC';
                    this.paymentdata.country = 'US';
                }
            },
            error => {
            }
        );
    };

    updateCountry(selectedCountry: string) {
        var state_flag = false;
        for (var key in this.data_divisions) {
            if (selectedCountry == key) {
                state_flag = true;
                this.states = this.doSorting(this.data_divisions[key]);
                this.paymentdata['state'] = this.states[0].code;
            }
        }
        if (state_flag == false) {
            this.states = [
                {
                    code: "",
                    name: "Not Available"
                }];
            this.paymentdata['state'] = "";
        }
    };

    getAuthDetail() {
        this.api.get('/auth/detail/').subscribe(
            data => {
                this.auth_data = data;
                this.paymentdata["name"] = this.auth_data.Fullname;
                this.paymentdata["first_name"] = this.auth_data.Firstname;
                this.paymentdata["last_name"] = this.auth_data.Lastname;
                this.paymentdata["street"] = this.auth_data.Street;
                this.paymentdata["city"] = this.auth_data.City;
                this.paymentdata["zip_code"] = this.auth_data.PostlCode;
                if (this.appData.get('buildingData').country == 'IN' && this.auth_data.Country != 'IN') {
                    this.auth_data.Country = 'IN';
                    this.auth_data.Region = '26';
                }
                this.paymentdata["country"] = this.auth_data.Country;
                this.states = this.doSorting(this.data_divisions[this.auth_data.Country]);
                this.updateCountry(this.auth_data.Country);
                var valid_state_found = false;
                for (var a = 0; a < this.states.length; a++) {
                    if (this.states[a].code == this.auth_data.Region) {
                        this.paymentdata["state"] = this.auth_data.Region;
                        valid_state_found = true;
                        break;
                    }
                }
            },
            error => {
            }
        );
    };

    normalizeYear(year: number) {
        // Century fix
        var YEARS_AHEAD = 20;
        if (year < 100) {
            var nowYear = new Date().getFullYear();
            year += Math.floor(nowYear / 100) * 100;
            if (year > nowYear + YEARS_AHEAD) {
                year -= 100;
            }
            else if (year <= nowYear - 100 + YEARS_AHEAD) {
                year += 100;
            }
        }
        return year;
    };

    checkExp(card_expiry: any) {
        if (card_expiry == undefined)
            return;

        var match = card_expiry.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
        if (!match)
            return;

        var exp = new Date(this.normalizeYear(1 * match[2]), 1 * match[1] - 1, 1).valueOf();
        var now = new Date();
        var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
        if (exp <= currMonth) {
            this.paymentdata_error["CC_expiry"] = {
                'error': true,
                'help': "Invalid expiry date"
            };
        }
        else {
            this.paymentdata_error["CC_expiry"] = {
                'error': false,
                'help': ""
            };
        }
    };

    validateCardExpiry(card_expiry: any) {
        this.checkExp(card_expiry);
    };

    getCardType(number: any) {
        if (number == undefined) {
            return false;
        }
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null) return "Visa";
        // Mastercard
        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "Mastercard";
        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null) return "American Express";
        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null) return "Discover";

        return "";
    };

    checkSubmitError() {
        this.disablePayButton = false;
        for (var a in this.paymentdata_error) {
            if (this.paymentdata_error[a].error) {
                this.disablePayButton = true;
                break;
            }
        }
    };

    togglePaymentMode(mode: any) {
        this.payment_mode = mode;
        var reset_field = {
            'error': false,
            'help': undefined
        };
        this.paymentdata_error['CC_CVV'] = reset_field;
        this.paymentdata_error['CC_expiry'] = reset_field;
        this.paymentdata_error['CC_number'] = reset_field;
        if (mode == 'cc') {
            this.paymentdata.CC_number = "";
            this.paymentdata.CC_expiry = "";
            this.paymentdata.CC_CVV = "";
        }
        this.checkSubmitError();
    };

    verifyField(data: any, key: string, optional?: boolean) {
        if (this.regex[key] == undefined || key == 'state') {
            return;
        }

        if (data == undefined || data == null || data.length == 0) {
            if (optional) {
                this.paymentdata_error[key] = {
                    'error': false,
                    'help': undefined
                };
                this.checkSubmitError();
                return;
            }
            this.paymentdata_error[key] = {
                'error': true,
                'help': 'This field is required'
            };
        }
        else if (this.regex[key].test(data)) {
            this.paymentdata_error[key] = {
                'error': false,
                'help': undefined
            };
        }
        else {
            this.paymentdata_error[key] = {
                'error': true,
                'help': this.regex[key + 'Msg']
            };
        }

        this.checkSubmitError();
    };

    getPriceURL(type: string) {
        var url = '/assets/LEED:' + this.appData.get('leed_id') + '/payments/price/?soreference=' + type;

        if (this.appData.get('buildingData').country == 'IN') {
            var gstin = this.paymentdata.gstin;
            if (this.paymentdata.gstin == undefined || this.paymentdata.gstin == null || this.paymentdata.gstin == '') {
                gstin = '';
            }

            var is_sez = "0";
            if (this.paymentdata.is_sez) {
                is_sez = "1";
            }

            url += "&name=" + (this.paymentdata.first_name.trim() + " " + this.paymentdata.last_name).trim() + "&email=" + this.paymentdata.email + "&street=" + this.paymentdata.street + "&city=" + this.paymentdata.city + "&state=" + this.paymentdata.state + "&country=" + this.paymentdata.country + "&zip_code=" + this.paymentdata.zip_code + "&gstin=" + gstin + "&is_sez=" + is_sez;
        }

        if (this.promocode_applied && this.paymentdata['promocode'] != undefined && this.paymentdata['promocode'] != null && this.paymentdata['promocode'] != '') {
            url += "&promocode=" + this.paymentdata['promocode'];
        }

        return url;
    };

    goToCertFee() {
        if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') {
            if (this.soreference == 'certification') {
                window.open("https://new.usgbc.org/cert-guide/fees#om", "_blank");
            }
            else {
                window.open("https://new.usgbc.org/cert-guide/fees#recert", "_blank");
            }
        }
        else if (this.appData.get('buildingData').project_type == 'transit') {
            window.open("https://new.usgbc.org/cert-guide/fees#om", "_blank");
        }
        else if (this.appData.get('buildingData').project_type == 'city') {
            window.open("https://new.usgbc.org/cert-guide/fees#cities", "_blank");
        }
        else if (this.appData.get('buildingData').project_type == 'community') {
            window.open("https://new.usgbc.org/cert-guide/fees#cities", "_blank");
        }
    };

    getPrice() {
        this.refreshing_price = true;
        this.refreshing_price_txt = 'Refreshing Price';
        this.promocode_price = {};
        this.taxes = false;
        this.taxes_s = false;
        var url = this.getPriceURL(this.soreference);

        this.api.get(url).subscribe(
            data => {
                if (this.appData.get('buildingData').country == 'IN') {
                    if (data.price.length == 2) {
                        this.taxes = data.price[1];
                    }
                    else if (data.price.length == 3) {
                        this.taxes = data.price[1];
                        this.taxes_s = data.price[2];
                    }
                }

                this.payment_currency = data.currency == 'INR' ? '₹' : '$';
                this.price_obj = data.price;
                this.project_price = data;

                if (this.promocode_applied) {
                    this.promocode_price = data.price[0];
                    this.invalid_promo_code = false;
                }
                else {
                    this.selected_price = data.price[0];
                    this.final_price = this.selected_price["price"];
                }

                this.refreshing_price = false;
            },
            error => {
                if (this.promocode_applied && error.error[0] != undefined && error.error[0].indexOf("promotional code") > -1) {
                    this.invalid_promo_code = true;
                    this.promocode_applied = false;
                    this.getPrice();
                }
                this.refreshing_price = false;
                this.refreshing_price_txt = 'Unable to fetch price';
            }
        );
    };

    removePromocode() {
        this.promocode_applied = false;
        this.invalid_promo_code = false;
        this.paymentdata['promocode'] = '';
        this.getPrice();
    };

    checkPromocode() {
        if (this.paymentdata.promocode == undefined || this.paymentdata.promocode == null || this.paymentdata.promocode.length == 0) {
            if (this.promocode_applied) {
                this.removePromocode();
            }
        }
        else {
            this.promocode_applied = true;
            this.getPrice();
        }
    };

    only_IGST() {
        if (this.price_obj != undefined && this.price_obj.length <= 2) {
            return true;
        }
        else {
            return false;
        }
    };

    check_sez(is_sez: boolean) {
        if (is_sez) {
            this.taxes = false;
            this.taxes_s = false;
            $('.sez_modal').modal('show');
            return;
        }
        this.paymentdata.is_sez = false;
        this.getPrice();
    };

    set_sez(set_sez: boolean) {
        if (set_sez) {
            this.paymentdata.is_sez = true;
        }
        else {
            this.paymentdata.is_sez = false;
        }
        this.getPrice();
    };

    payNow() {
        if (this.final_price == 0 && !this.promocode_applied) {
            this.alertService.alert('error', 'Something went wrong. Try again.', 5);
            return;
        }

        if (this.paymentdata.state == '' || this.paymentdata.state == null) {
            this.paymentdata.state = '';
        }

        if (this.promocode_applied) {
            this.review_price = this.promocode_price;
        }
        else {
            this.review_price = this.selected_price;
        }

        var payment_payload: any =
        {
            "first_name": this.paymentdata.first_name,
            "last_name": this.paymentdata.last_name,
            "email": this.paymentdata.email,
            "street": this.paymentdata.street,
            "city": this.paymentdata.city,
            "state": this.paymentdata.state,
            "country": this.paymentdata.country,
            "zip_code": this.paymentdata.zip_code,
            "phone": this.paymentdata.phone,
            "promocode": this.paymentdata.promocode,
            "is_sez": this.paymentdata.is_sez,
            "material_code": this.review_price.material,
            "material_desc": this.review_price.description,
            "unit_price": this.review_price.price_per_unit,
            "quantity": this.review_price.quantity,
            "material_price_grp": this.review_price.mat_price_grp,
            "actual_price": this.review_price.price,
            "soreference": "recertification",
            "annual_payterm": "1",
            "review_type": "LEED Certification"
        }

        if (!this.promocode_applied) {
            delete payment_payload['promocode'];
        }

        if (payment_payload["is_sez"]) {
            payment_payload["is_sez"] = 1;
        }
        else {
            payment_payload["is_sez"] = 0;
        }

        if (this.payment_mode == 'cc') {
            payment_payload['paymetric_r'] = this.paymentdata.paymetric_r
            payment_payload['paymetric_s'] = this.paymentdata.paymetric_s
            payment_payload['cc_user_name'] = (this.paymentdata.first_name + ' ' + this.paymentdata.last_name).trim();
        }
        else if (this.appData.get('buildingData').country == 'IN') {
            payment_payload['gstin'] = this.paymentdata.gstin;
        }

        if (this.canada_payment){
            payment_payload['material_code'] = 'canada_invoice';
            payment_payload['material_desc'] = 'canada_invoice'
        }

        var form_data = new FormData();

        for (var key in payment_payload) {
            if ((key == 'state' && payment_payload[key] == 'Not Available') || (key == 'gstin' && payment_payload[key] == undefined)) {
                payment_payload[key] = "";
            }
            form_data.append(key, payment_payload[key]);
        }

        var custom_basic_config_header =
        {
            headers:
            {
                "responseType": "arraybuffer",
                "Ocp-Apim-Subscription-Key": this.config.sub_key,
                "Authorization": "Bearer " + this.appData.get('token')
            }
        };
        this.isPaymentFormSubmitted = true;

        this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/payments/pre-pay/', form_data, custom_basic_config_header).subscribe(
            data => {
                var success_msg = 'Payment received.';
                if (this.payment_mode != 'cc') {
                    success_msg = 'Invoice generated.';
                }
                this.isPaymentFormSubmitted = false;
                if (this.canada_payment){
                    success_msg = 'Invoice requested.';
                }
                this.alertService.alert('success', success_msg, 5);
                this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing' })
            }, error => {
                this.isPaymentFormSubmitted = false;
            }
        );
    };

    checkPaymetricReturn() {
        $('iframe#paymetric_iframe').off();
        $('iframe#paymetric_iframe').load(<any>(() => {
            this.paymentdata.paymetric_r = this.global.getParameterFromURLByName('r', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);
            this.paymentdata.paymetric_s = this.global.getParameterFromURLByName('s', (<any>document.getElementById('paymetric_iframe')).contentWindow.location.href);

            if (((<any>document.getElementById('paymetric_iframe')).contentWindow.location.href).indexOf("paymetric") > -1 && this.paymentdata.paymetric_r && this.paymentdata.paymetric_s) {
                if (this.payment_mode == "cc") {
                    this.paymentdata["cc_user_name"] = (this.paymentdata.first_name + ' ' + this.paymentdata.last_name).trim();
                }
                this.payNow();
            }
        }));
    }

    fillPaymetricForm() {
        var CC_type = this.getCardType(this.paymentdata.CC_number);
        var CC_expiry = this.paymentdata.CC_expiry.split("/");
        var CC_number = this.paymentdata.CC_number;
        var CC_CVV = this.paymentdata.CC_CVV;

        if (CC_type == "American Express") {
            (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 0;
        }
        else if (CC_type == "Visa") {
            (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 1;
        }
        else if (CC_type == "Mastercard") {
            (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 2;
        }
        else if (CC_type == "Discover") {
            (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardType').selectedIndex = 3;
        }

        (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CreditCardNumber').value = CC_number;
        (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Month').value = CC_expiry[0];
        (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_Exp_Year').value = CC_expiry[1].slice(-2);
        (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_CVV').value = CC_CVV;
        (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('PayNowButton').click();

        if ((<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML != '') {
            $("#project-payment-form").removeClass("not-active");
            this.project_payment_nxt_btn = "Submit Payment";
            this.canada_payment_nxt_btn = "Submit";
            this.alertService.alert('error', (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML, 5);
            (<any>document.getElementById('paymetric_iframe')).contentWindow.document.getElementById('Paymetric_ErrorLogging').innerHTML = "";
            this.isPaymentFormSubmitted = false;
            this.disablePayButton = true;
            this.project_payment_nxt_btn = "Submit Payment";
            this.canada_payment_nxt_btn = "Submit";
        }
    };

    createPaymetricForm() {
        var redirectURLHost = window.location.origin;
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/payments/paymetricform/?redirectURLHost=' + redirectURLHost, "text/html").subscribe(
            data => {
                var doc = (<any>document.getElementById('paymetric_iframe')).contentWindow.document;
                doc.open();
                doc.write(data);
                doc.close();
                this.checkPaymetricReturn();
            },
            error => {
            }
        );
    };

    cancelPayment() {
        this.disablePayButton = false;
        this.project_payment_nxt_btn = 'Submit Payment';
        this.canada_payment_nxt_btn = "Submit";
        this.isPaymentFormSubmitted = false;
    };

    makePayment() {
        this.project_payment_nxt_btn = "Processing payment...";
        this.canada_payment_nxt_btn = "Processing payment...";
        if (this.payment_mode == "cc") {
            this.fillPaymetricForm();
        }
        else {
            delete this.paymentdata.paymetric_r;
            delete this.paymentdata.paymetric_s;
            this.payNow();
        }
    };

    submitPayment() {
        if (this.payment_mode != 'cc') {
            delete this.paymentdata.CC_number;
            delete this.paymentdata.CC_expiry;
            delete this.paymentdata.CC_CVV;

            this.paymentdata_error.CC_number = { "error": false, "help": "" };
            this.paymentdata_error.CC_expiry = { "error": false, "help": "" };
            this.paymentdata_error.CC_CVV = { "error": false, "help": "" };
        }
        else if (this.payment_mode == 'cc') {
            this.paymentdata['CC_number'] = this.paymentdata['CC_number'] == undefined ? '' : this.paymentdata['CC_number'];
            this.paymentdata['CC_expiry'] = this.paymentdata['CC_expiry'] == undefined ? '' : this.paymentdata['CC_expiry'];
            this.paymentdata['CC_CVV'] = this.paymentdata['CC_CVV'] == undefined ? '' : this.paymentdata['CC_CVV'];
        }

        for (var a in this.paymentdata) {
            if (a == 'gstin' || a == 'name') {
                this.verifyField(this.paymentdata[a], a, true);
            }
            else {
                this.verifyField(this.paymentdata[a], a);
            }
        }

        this.disablePayButton = false;
        for (var a in this.paymentdata_error) {
            if (this.paymentdata_error[a].error) {
                this.disablePayButton = true;
                return;
            }
        }

        var data: any = {};
        if (this.paymentdata.state == '' || this.paymentdata.state == null) {
            data = {
                "country": this.paymentdata.country,
                "zip_code": this.paymentdata.zip_code
            }
        }
        else {
            data = {
                "state": this.paymentdata.state,
                "country": this.paymentdata.country,
                "zip_code": this.paymentdata.zip_code
            }
        }

        this.isPaymentFormSubmitted = true;
        this.project_payment_nxt_btn = "Validating info...";
        this.canada_payment_nxt_btn = "Validating info...";
        this.api.post('/assets/validation/', data).subscribe(
            data => {
                $("#zip_code").removeClass("parsley-error")
                $("#zip_code").next('ul.parsley-errors-list').remove();

                this.disablePayButton = false;

                //GSTIN check for India projects
                if (this.appData.get('buildingData').country == 'IN') {
                    this.gstin_modal_subject = "";
                    this.gstin_modal_body = "";

                    $("#project-payment-form").removeClass("not-active");
                    this.project_payment_nxt_btn = "Submit Payment";
                    this.canada_payment_nxt_btn = "Submit";

                    var gstinMap: any = {
                        "28": "01", // Andra Pradesh
                        "37": "01", // Andra Pradesh
                        "12": "02", // Arunachal Pradesh
                        "18": "03", // Assam
                        "10": "04", // Bihar
                        "30": "05", // Goa
                        "24": "06", // Gujarat
                        "06": "07", // Haryana
                        "02": "08", // Himachal Pradesh
                        "01": "09", // Jammu and Kashmir
                        "29": "10", // Karnataka
                        "32": "11", // Kerala
                        "23": "12", // Madhya Pradesh
                        "27": "13", // Maharashtra
                        "14": "14", // Manipur
                        "17": "15", // Megalaya
                        "15": "16", // Mizoram
                        "13": "17", // Nagaland
                        "21": "18", // Orissa
                        "03": "19", // Punjab
                        "08": "20", // Rajasthan
                        "11": "21", // Sikkim
                        "33": "22", // Tamil Nadu
                        "36": "TG", // Telangana
                        "16": "23", // Tripura
                        "09": "24", // Uttar Pradesh
                        "19": "25", // West Bengal
                        "35": "26", // Andaman and Nico.In.
                        "04": "27", // Chandigarh
                        "26": "28", // Dadra and Nagar Hav.
                        "25": "29", // Daman and Diu
                        "07": "30", // Delhi
                        "31": "31", // Lakshadweep
                        "34": "32", // Pondicherry
                        "22": "33", // Chhaattisgarh
                        "20": "34", // Jharkhand
                        "05": "35" // Uttaranchal
                    };

                    var gstin: any = '';
                    try {
                        gstin = this.paymentdata.gstin.trim();
                    }
                    catch (e) {
                        gstin = '';
                    }

                    if (gstin.length) {
                        var gstState = gstin[0] + gstin[1];
                        var requiredState = gstinMap[gstState];

                        if (requiredState != this.paymentdata.state) {
                            for (var i = 0; i < this.states.length; i++) {
                                if (this.states[i].code == requiredState) {
                                    requiredState = this.states[i].name;
                                }
                            }

                            if (requiredState == undefined) {
                                requiredState = '';
                            }

                            this.gstin_modal_subject = "State mismatch!";
                            this.gstin_modal_body = "Entered GSTIN '" + gstin + "'' belongs to '" + requiredState + "'. Please change your billing address to '" + requiredState + "'.";
                            $('.gstin_modal').modal('show');
                            this.isPaymentFormSubmitted = false;
                            this.project_payment_nxt_btn = "Submit Payment";
                            this.canada_payment_nxt_btn = "Submit";
                            this.disablePayButton = true;
                            return false;
                        }
                        else {
                            this.makePayment();
                        }
                    }
                    else {
                        for (var i = 0; i < this.states.length; i++) {
                            if (this.states[i].code == this.paymentdata.state) {
                                this.complete_state = this.states[i].name;
                            }
                        }
                        for (var i = 0; i < this.countries.length; i++) {
                            if (this.countries[i].code == this.paymentdata.country) {
                                this.complete_country = this.countries[i].name;
                            }
                        }
                        $('.no_gstin_modal').modal('show');
                        // this.disablePayButton = true;
                        return false;
                    }
                }
                else {
                    this.makePayment();
                }
                //GSTIN check emds
            }, error => {
                this.disablePayButton = true;
                try {
                    this.paymentdata_error['zip_code'] = {
                        'error': true,
                        'help': "Invalid zip code/state combination"
                    };
                }
                catch (e) {
                    this.paymentdata_error['zip_code'] = {
                        'error': true,
                        'help': "Enter a valid zip code"
                    };
                }

                this.project_payment_nxt_btn = "Submit Payment";
                this.canada_payment_nxt_btn = "Submit";
                this.isPaymentFormSubmitted = false;
            }
        );
    };

    deleteFile(uploaded_file: any) {
        var putData = {
            doc_id: uploaded_file.id
        };

        this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
            data => {
                this.projects.getS3DocumentsForCategory("sez_docs/temp").subscribe(data => {
                    this.uploaded_files = data;
                });
            }, error => {
                this.alertService.alert('error', 'Something went wrong. Try again.', 5);
            }
        );
    }

    openUploadDialog(source: any, upload_category: any, classname?: string) {
        this.upload_category = upload_category;
        if (source == 'laptop') {
            $('.' + classname).click();
        }
    };

    updateState() {
        for (var a = 0; a < this.states.length; a++) {
            if (this.states[a].code == 'select_state') {
                this.states.splice(a, 1);
                break;
            }
        }
        if (this.appData.get('buildingData').country == 'IN') {
            this.getPrice();
        }
    };

    fileNameChanged(elem: any) {
        var filePath: any = $(elem).val();
        filePath = filePath.replace("/\\/", "\\\\");
        var index = filePath.lastIndexOf("\\");
        filePath = filePath.substring(index + 1, filePath.length);
        //var type = $(this).attr('category');
        var data;
        var temp_uploading = [];
        for (var a = 0; a < $(elem)[0].files.length; a++) {
            data = new FormData();
            data.append('action_file', $(elem)[0].files[a]);
            this.uploading.push($(elem)[0].files[a]);
            this.uploadToS3(data, this.upload_category);
        }
        //$rootScope.uploading = temp_uploading;
    };

    uploadToS3(form_data: any, category: any) {
        (<any>$('.uploadFile')[0]).value = "";
        (<any>$('.uploadFile2')[0]).value = "";
        this.currentUpload = true;
        this.currentUploadName = 'Uploading...';
        this.disablePayButton = true;
        var custom_basic_config_header = {
            headers:
            {
                "responseType": "arraybuffer",
                "Ocp-Apim-Subscription-Key": this.config.sub_key,
                "Authorization": "Bearer " + this.appData.get('token')
            }
        };
        form_data.append('upload_category', this.upload_category);
        this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/uploadS3/?upload_category=' + this.upload_category, form_data, custom_basic_config_header).subscribe(
            data => {
                this.disablePayButton = false;
                this.currentUpload = false;
                this.currentUploadName = '';
                this.getUploadedFiles(category);
                this.alertService.alert('success', 'File successfully uploaded.', 5);
            }, error => {
                this.currentUpload = false;
                this.disablePayButton = false;
                this.currentUploadName = '';
                this.getUploadedFiles(category);
                this.alertService.alert('error', 'Error uploading file. Please try again.', 5);
            }
        );
    };

    getUploadedFiles(doc_category: any) {
        this.projects.getS3DocumentsForCategory(doc_category).subscribe(
            data => {
                if (this.uploading.length > 0) {
                    for (var a in data) {
                        for (var b in this.uploading) {
                            if (this.global.getFileName(data[a]) == this.uploading[b].name) {
                                this.uploading.splice(b, 1)
                            }
                        }
                    }
                }
                this.uploaded_files = data;
            }
        );
    };

    summation(data: any) {
        return data.reduce(function (a: any, b: any) { return (isNaN(parseFloat(a)) ? 0 : parseFloat(a)) + (isNaN(parseFloat(b)) ? 0 : parseFloat(b)); }, 0);
    };

    showDropdown() {
        $('.dropdown-menu.address').slideDown(200);
    };

    hideDropdown() {
        $('.dropdown-menu.address').slideUp(200);
    };

    checkDropdown() {
        if (this.places.length > 0) {
            this.showDropdown();
        }
        else {
            this.hideDropdown();
        }
    };

    getPlace(address: any) {
        if (this.canceler) {
            this.canceler.unsubscribe();
        }
        this.verifyField(this.paymentdata.street, 'street');
        this.searching_place = true;
        this.canceler = this.api.get(
            '/address/predictions/' + "?address=" + address
        ).subscribe(
            data => {
                this.places = data;
                this.searching_place = false;
                this.checkDropdown();
            }, error => {
                this.searching_place = false;
            }
        );
    };

    selectPlace(place: any) {
        this.hideDropdown();
        var postal_code, state, country, city, state_long_name;
        for (var i = 0; i < place.address_components.length; i++) {
            var comp = place.address_components[i];
            if (comp.types.indexOf("postal_code") != -1) {
                postal_code = comp.short_name;
            }
            else if (comp.types.indexOf("administrative_area_level_1") != -1) {
                state = comp.short_name;
                state_long_name = comp.long_name;
            }
            else if (comp.types.indexOf("locality") != -1) {
                city = comp.long_name;
            }
            else if (comp.types.indexOf("country") != -1) {
                country = comp.short_name;
            }
        }
        if (city == undefined) {
            city = state_long_name;
        }
        this.paymentdata.street = place.name
        this.paymentdata.city = city;
        this.paymentdata.zip_code = postal_code;
        this.paymentdata.country = country;
        this.updateCountry(country);
        var found = false;
        for (var i = 0; i < this.states.length; i++) {
            if (state_long_name == this.states[i].name) {
                this.paymentdata.state = this.states[i].code;
                found = true;
                break;
            }
        }
        if (!found && state != undefined) {
            this.paymentdata.state = state;
        }
        else if (state == undefined) {
            this.paymentdata.state = this.states[0].code;
        }
        this.verifyField(this.paymentdata.street, 'street');
        this.verifyField(this.paymentdata.city, 'city');
        this.verifyField(this.paymentdata.zip_code, 'zip_code');
    };

    goBack() {
        setTimeout(() => {
            window.history.back();
            window.onpopstate = (e) => {
                if (location.href.indexOf('login') != -1 && location.href.indexOf('leed_id') != -1) {
                    if (this.config.state('initiator') == 'billing') {
                        this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing' }, { 'tab': 'purchase' });
                    }
                    else {
                        this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'certifications' });
                    }
                }
                else if (location.href.indexOf('login') != -1) {
                    this.global.filterAccess('app.homeOverview');
                }
                else if (this.config.state('initiator') == 'billing') {
                    this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing' }, { 'tab': 'purchase' });
                }
                else if (this.config.state('initiator') == 'certifications') {
                    this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'certifications' });
                }
            };
        }, 100)
    };

    ngOnInit(): void {

        if (this.appData.get('buildingData').country == 'CA' && (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school')) {
            this.canada_payment = true;
            this.payment_mode = 'check';
        }

        if (this.appData.get('buildingData').country == 'IN') {
            this.paymentdata.country = 'IN';
            this.paymentdata.state = '26';
        }

        if (this.appData.get('buildingData').country == 'IN') {
            this.uploaded_files = [];
            this.projects.getS3DocumentsForCategory("sez_docs/temp").subscribe(
                data => {
                    if (data.length >= 1) {
                        this.uploaded_files = data;
                        this.paymentdata.is_sez = true;
                    }
                },
                error => {
                    this.alertService.alert('error', 'Something went wrong. Try again.', 5);
                }
            );
        }

        this.getCountries();
        this.getAuthDetail();
        this.createPaymetricForm();
        this.getPrice();
    }

}

import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import * as moment from 'moment';

@Component({
  selector: 'app-transit-survey',
  templateUrl: './transit-survey.component.html',
  styleUrls: ['./transit-survey.component.css']
})
export class TransitSurveyComponent implements OnInit {
  commute_date: any = moment(new Date()).format("MMM DD, YYYY");
  teleworkers: any = "";
  leed_id: any = "";
  loader: boolean = false;

  constructor(
    private api: API,
    private alertService: alertService
  ) { }

  refreshForms()
  {
      this.commute_date = moment(new Date()).format("MMM DD, YYYY");
      this.teleworkers = "";
      this.leed_id = "";
  };

  enableFdatePicker()
  {
      (<any>$('#commute_date')).fdatepicker(
      {
          format: 'M dd, yyyy',
          onRender: function (date: any) 
          {
              return date.valueOf() > new Date().valueOf() ? 'disabled' : '';
          }
      });
  };
  
  addData()
  {
      var data = 
      {
          "commute_date": moment($('#commute_date').val()).format("YYYY-MM-DD"),
          "teleworkers": this.teleworkers,
          "target_id": this.leed_id
      };

      if(this.leed_id.length == 0)
      {
          this.alertService.alert('error', 'Enter Property ID (starting with 4) or LEED ID (starting with 1 or 8)', 5);
          return;
      }
      else if(this.teleworkers.length == 0)
      {
          this.alertService.alert('error', 'Enter number of telecommuters', 5);
          return;
      }
      
      this.loader = true;
      this.api.post('/optask/telecommute/', data ).subscribe(
        data => {
          this.loader = false;
              this.refreshForms();
              this.alertService.alert('success', 'Survey uploaded successfully.', 5);
        }, error => {
          this.loader = false;
              try
              {
                  this.alertService.alert('error', error.error[0].message, 5);
              }
              catch(e)
              {
                  this.alertService.alert('error', 'Something went wrong', 5);
              }
        }
      );
  };

  ngOnInit(): void {
  }

}

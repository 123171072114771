import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from '../../../services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

import 'moment-timezone';
import * as moment from 'moment';
import * as stream from 'getstream';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-climate-risk',
  templateUrl: './climate-risk.component.html',
  styleUrls: ['./climate-risk.component.css']
})
export class ClimateRiskComponent implements OnInit
{
	@ViewChild('modal') private modal!: ModalComponent
	@ViewChild('crate_report_modal') private crate_report_modal!: ModalComponent
	config = Config;
	offline_sub: null | boolean = null;
	report_doc: any = {};
	start = this.global.last12Month()[0];
	end = this.global.last12Month()[1];
	report_type = '';
	download_cert: any = {
		"climaterisk": null
	};

	loading: boolean = false;
	invoice_generated: boolean = false;
	current_tab: string = '';
	carbon_color: string = '';
	electrification_color: string = '';
	report_generated: string | boolean = '';
	current_status: string | undefined = undefined;
	order_id: number | null = null;
	data_coverage: any = {};
	payment_history: any = {};
	subscription: any = {};
	reports: any = [];
	feeds: any = [];
	climate_risk_reports: any = [];
	past_certificates: any = [];
	retry: any = [];

	type = "427";
	header = "Create a Physical Risk Report";
	report_sent = false;
	agreement: boolean = false;
	
	constructor(
		public global: Global,
		public appData: AppData,
		private api: API,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.appData.get('buildingData').country == 'US' || this.appData.get('buildingData').country == 'CA')
		{
			this.report_type = 'RiskFootprint Report';
		}
		else
		{
			this.report_type = 'Physical Risk Report';
		}
		this.getSubscription();
	}

	downloadData()
	{
		var start_date = this.global.changeDateFormat(this.start, 'YYYY-MM-DD');
		var end_date = this.global.changeDateFormat(this.end, 'YYYY-MM-DD');
		var url = Config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + '/export/?start_date='+ start_date +"&end_date=" + end_date;	
		this.loading = true;
		var xhttp: any = new XMLHttpRequest();
		xhttp.onreadystatechange = () =>
		{
			var a, today;
			if (xhttp.readyState === 4 && xhttp.status === 200)
			{
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(xhttp.data, "Export Data.xlsx");
					this.loading = false;
				}
				else
				{
					this.loading = false;
					a = document.createElement('a');
					a.download = "Export Data.xlsx";
					a.style.display = 'none';
					a.href = window.URL.createObjectURL(xhttp.response);
					document.body.appendChild(a);
					return a.click();
				}
			}
		};
		xhttp.open("GET", url, true);
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", Config.sub_key);
		xhttp.setRequestHeader("Authorization", "Bearer " + this.appData.get('token'));
		xhttp.responseType = 'blob';
		xhttp.send();
	}

	selectReport(type: string)
	{
		this.report_type = type;
	};

	getCreatedAt()
	{
		if(Array.isArray(this.report_doc))
		{
			return this.report_doc[0].created_at;
		}
		else
		{
			return this.report_doc.created_at;
		}
	};

	downloadReport()
	{
		if(Array.isArray(this.report_doc))
		{
			this.downloadCert(this.report_doc[0].document.id, this.global.getFileName(this.report_doc[0].document), this.report_doc[0].type);
			setTimeout(()=>
			{
				this.downloadCert(this.report_doc[1].document.id, this.global.getFileName(this.report_doc[1].document), this.report_doc[1].type);
			}, 3000);
		}
		else
		{
			this.downloadCert(this.report_doc.document.id, this.global.getFileName(this.report_doc.document), this.report_doc.type);
		}
	};

	downloadCert(doc_id: number, name: string, type?: string)
	{
		let url: string;
		if(type == undefined || type == 'Certificate')
		{
			url = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + doc_id + "&doc_source=certificate&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		}
		else
		{
			url    = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + doc_id + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key;
		}
		this.global.anchorDownload(name, url);
	}

	puchaseSubs()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("climate_risk_certificate", this.appData.get('leed_id'));
	}

	renewSubs()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("renew_certificate", this.appData.get('leed_id'), this.subscription);
	}

	editBilling()
	{
		if(this.config.maintenance_banner)
		{
			this.global.showMaintenanceModal();
			return;
		}
		this.global.upgrade("edit_certificate_billing", this.appData.get('leed_id'), this.subscription);
	}

	downloadInvoice(order_id: number | null)
	{

	}

	goToCertificate(type: string, label: string)
	{
		if(this.current_status == 'active' || this.current_status == 'cancelled_active')
		{
			this.global.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": "purchase-certificates"
			},
			{
				'type': type,
				'label': label
			});
		}
		else
		{
			this.modal.open({ windowClass: 'certificate_modal' })
		}
	}

	getData()
	{
		this.loading = true;
		var urls = [
			'/assets/LEED:' + this.appData.get('leed_id') + '/datavalidate/electricity/?at=' + moment(new Date()).format('YYYY-MM-DD'),
			'/assets/LEED:' + this.appData.get('leed_id') + '/certifications/riskreports/',
			'/assets/LEED:' + this.appData.get('leed_id') + '/riskreports/climaterisk/',
			'/subscriptions/'+ this.appData.get('leed_id') +'/payments/history/?page_size=0'
		];
		this.api.async(urls).subscribe(data =>
		{
			this.loading = false;
			this.data_coverage = data[0];
			this.report_generated = this.data_coverage.risk_report;
			this.past_certificates = data[1].certificates;
			this.climate_risk_reports = data[2].results;

			this.data_coverage.carbon = isNaN(parseFloat(this.data_coverage.carbon)) ? 0 : this.data_coverage.carbon;
			this.data_coverage.electrification = isNaN(parseFloat(this.data_coverage.electrification)) ? 0 : this.data_coverage.electrification;
			if(this.data_coverage.carbon < 40)
			{
				this.carbon_color = "red";
			}
			else if(this.data_coverage.carbon >= 40 && this.data_coverage.carbon < 60)
			{
				this.carbon_color = "orange";
			}
			else
			{
				this.carbon_color = "green";
			}

			if(this.data_coverage.electrification < 50)
			{
				this.electrification_color = "red";
			}
			else if(this.data_coverage.electrification >= 50 && this.data_coverage.electrification < 75)
			{
				this.electrification_color = "orange";
			}
			else
			{
				this.electrification_color = "green";
			}
			this.payment_history = data[3];

			this.invoice_generated = false;
			for(var i = 0; i < this.payment_history.length; i++)
			{
				if(this.payment_history[i].type == "climaterisk" && this.payment_history[i].status == "processing")
				{
					this.invoice_generated = true;
					this.order_id = this.payment_history[i].sap_order_id;
					break;
				}
			}

			var reports = [];
			for(var i = 0; i < this.climate_risk_reports.length; i++)
			{
				if(this.climate_risk_reports[i].report == null)
				{
					var date_certified = "N/A";
					var status = 'In Progress';
					var document = null;
				}
				else
				{
					var date_certified = this.global.dateTimeFormat(this.climate_risk_reports[i].report.uploaded_at);
					var status = 'Completed';
					var document = this.climate_risk_reports[i].report;
				}
				reports.push({
					"created_at": this.global.dateTimeFormat(this.climate_risk_reports[i].requested_at),
					"date_created": this.climate_risk_reports[i].requested_at,
					"date_certified": date_certified,
					"type": "Climate Risk Report",
					"status": status,
					"document": document
				});
			}
			for(var i = 0; i < this.past_certificates.length; i++)
			{
				var file_type = "";
				if(this.past_certificates[i].certification_type == 'climaterisk')
				{
					this.download_cert.climaterisk = this.past_certificates[i];
					var type = 'Certificate';
					var status = "Expires on " + this.global.dateFormat(this.past_certificates[i].certification_expire);
				}
				else
				{
					var type =  "Physical Risk Report";
					if(this.past_certificates[i].certificate.doc_description == 'physicalrisk_report_excel')
					{
						file_type = 'excel';
					}
					else
					{
						file_type = 'pdf';
					}
					var status = "Completed";
				}
				reports.push({
					"created_at": this.global.dateTimeFormat(this.past_certificates[i].created_at),
					"date_created": this.past_certificates[i].created_at,
					"date_certified": 'N/A',
					"type": type,
					"status": status,
					"document": this.past_certificates[i].certificate,
					"file_type": file_type
				});
			}

			for(var i = 0; i < reports.length; i++)
			{
				if(reports[i].type == "Climate Risk Report")
				{
					this.report_doc = reports[i];
					break;
				}
				else if(reports[i].type.indexOf("Physical Risk Report") != -1)
				{
					if(this.report_doc.length == undefined)
					{
						this.report_doc = [];
					}
					this.report_doc.push(reports[i]);
				}
			}

			if(this.feeds != undefined && this.feeds.foreign_id == 'physicalrisk_report')
			{
				this.report_generated = 'inprogress';
			}
			else if(this.report_doc != false && this.report_doc.document != null)
			{
				this.report_generated = true;
			}

			this.reports = reports;
		});
	}

	getCertStatus(certificate: any)
	{
		if (!certificate.certification_expire)
		{
			return "N/A";
		}
		else if(moment(certificate.certification_expire).isBefore(new Date()))
		{
			return "<span class='danger-color'>Expired</span>";
		}			
		else
		{
			return "<span>Valid</span>";
		}
	}

	toggleTab(tab: string)
	{
		if(tab == this.current_tab)
		{
			return;
		}
		this.current_tab = tab;
	}

	getSubscription()
	{
		this.loading = true;
		var url = '/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/climaterisk/';
		this.api.get(url).subscribe(
			data =>
			{
				this.subscription = data;	
				if(this.subscription.status == undefined)
				{
					this.current_status = 'inactive';
					this.loading = false;
				}					
				else if(this.subscription.status == 'settled')
				{
					this.current_status = 'active';
				}					
				else if(this.subscription.status == 'cancelled_active')
				{
					this.current_status = 'cancelled_active';
				}					
				else if(this.subscription.status == 'expired')
				{
					this.current_status = 'expired';
				}					
				this.getData();
				if(this.current_status == 'inactive')
				{
					this.current_tab = 'Subscription Purchase';
				}
				else if(["Current Certificates", "Past Certificates"].indexOf(this.global.getURLFromParam('tab')) != -1)
				{
					this.current_tab = this.global.getURLFromParam('tab');
				}
				else
				{
					this.current_tab = 'Current Certificates';
					if(["Current Certificates", "Past Certificates"].indexOf(this.global.getURLFromParam('tab')) != -1)
					{
						this.current_tab = this.global.getURLFromParam('tab');
					}
				}
				
				try
				{
					if(this.subscription.subscription_id.indexOf('sub_free') != -1 || this.subscription.subscription_id.indexOf('sub_offline_paid') != -1)
					{
						this.offline_sub = true;
					}
					else
					{
						this.offline_sub = false;
					}
				}
				catch(e)
				{
					this.offline_sub = false;
				}

				if(this.current_status == 'active' || this.current_status == 'expired' || this.current_status == 'cancelled_active')
				{
					this.initFeed();
				}
			},
			error =>
			{
				this.loading = false;
			}
		);
	}

	getMonthDiff(month_diff: number)
	{
		if(this.subscription.card == null)
		{
			return false
		}
		var date = "01-" + String(this.subscription.card.exp_month) + "-" + String(this.subscription.card.exp_year);
		return moment(date, "DD-MM-YYYY").diff(new Date(), 'months', true) > month_diff ? false : true;
	}

	editCard()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"pmupdate": true
		};

		this.api.put('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/climaterisk/', payload).subscribe(
			data =>
			{
				var stripe_url = this.config.basic_api_url + "/auth/stripe/payment-form/?subscription-key="+ this.config.sub_key +"&access_token="+ this.appData.get('token') +"&session_id=" + data.id;
				window.open(stripe_url, "_self");
			},
			data =>
			{
				this.loading = false
			}
		);
	}

	successURL()
	{
		this.loading = true;
		this.api.get('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/climaterisk/'+ this.appData.get('session_id') +'/').subscribe(
			data =>
			{
				this.appData.set('session_id', undefined);
				this.getSubscription();
			},
			data =>
			{
				this.appData.set('session_id', undefined);
				this.loading = false;
			}
		);
	}

	cancelSubs()
	{
		this.loading = true;
		var payload = {
			"subscription_id": this.subscription.subscription_id,
			"email": this.appData.get('user_id')
		};

		var custom_basic_config_header = 
		{
			data: payload,
			headers: 
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};

		this.api.delete('/assets/LEED:'+ this.appData.get('leed_id') +'/subscriptions/climaterisk/', custom_basic_config_header).subscribe(
			data =>
			{
				this.loading = false;
				this.getSubscription();
			},
			data =>
			{
				this.loading = false;
			}
		);
	}

	deleteFeed(foreign_id: string, index?: number)
	{
		var custom_basic_config_header = 
		{
			data: {
				"foreign_id": foreign_id
			},
			headers: 
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};

		this.loading = true;
		this.api.delete('/assets/LEED:'+ this.appData.get('leed_id') + '/notificationitems/', custom_basic_config_header).subscribe(
			//success handler
			data =>
			{
				this.loading = false;
				this.retry.splice(index, 1);
			},
			//error handler
			data =>
			{
				this.loading = false;
			}
		);
	}

	initFeed()
	{
		var feeds: any = {};
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/notificationitems/'
		).subscribe(data =>
		{
			var client = stream.connect(data.stream_key, null, data.app_id);
			var notification = client.feed(data.feed_name, data.feed_user, data.token);
			var retry = []
			
			for(var i = 0; i < data.activities.length; i++)
			{
				if(data.activities[i].foreign_id.indexOf("physicalrisk_report") != -1)
				{
					var time = moment(data.activities[i].time);
					var date = moment(new Date);
					if(time.diff(date, 'days') > 1)
					{
						this.deleteFeed(data.activities[i].foreign_id);
					}
					else
					{
						feeds = data.activities[i];
					}
				}
				else if(data.activities[i].foreign_id.indexOf("data_report_error_") != -1)
				{
					retry.push(data.activities[i]);
				}
				
				var timestamp = data.activities[i].timestamp;
				var today = moment();

				if(Math.abs(today.diff(timestamp, 'days')) > 0)
				{
					this.deleteFeed(data.activities[i].foreign_id);
				}
			}
			
			this.retry = retry.sort(function(a,b) 
			{ 
				return new Date(a.time).getTime() - new Date(b.time).getTime() 
			});
			this.feeds = feeds;
			if(this.feeds.foreign_id == 'physicalrisk_report')
			{
				this.report_generated = 'inprogress';
			}

			var subscription = notification.subscribe((data: any) =>
			{
				for(var d = 0; d < data.deleted.length; d++)
				{
					if(feeds.id == data.deleted[d])
					{
						this.getData();
						feeds = {};
					}
				}

				if(data.new.length > 0)
				{
					if(data.new[0].foreign_id.indexOf("physicalrisk_report") != -1)
					{
						feeds = data.new[0];
					}
				}
				
				this.feeds = feeds;
				if(this.feeds.foreign_id == 'physicalrisk_report')
				{
					this.report_generated = 'inprogress';
				}
			});
		},
		error =>
		{

		});
	}

	toggleType(report_type: any)
	{
		this.report_type = report_type;
	};

	createReport()
	{
		this.loading = true;
		this.api.post(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/riskreports/physicalrisk/',
			{}
		).subscribe((data: any) =>
			{
				this.loading = false;
				this.alertService.alert('success', "Your reports are still being generated. You can check the status of the existing report request in the 'Past Reports' tab below.", 5);
				this.cancel()
			},
			(data: any) =>
			{
				this.loading = false
				this.alertService.alert('error', 'Something went wrong. Try again.', 5);
			}
		);
	};

	cancel()
	{
		this.crate_report_modal.close()
	}

	createPhysicalReport()
	{
		this.crate_report_modal.open({ windowClass: 'certificate_modal' });
	}
}

<div class="content-wrap">
	<div id="content" class="content">
        
        <h3 class="fw-500 black-color pl15 mb25 pt5">
            LEED BD+C Insight
        </h3>

        <div class="align_lr height-100-perc insight_nav">
            <div class="options_header">
                <div *ngIf="user_role == 'arc_pro' || appData.get('enable_insights')" class="option_selection" [ngClass]="appData.get('app_name') == 'market' ? 'active': ''" (click)="filterAccessInsights('market')">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/></svg>
                    Markets                    
                </div>
                <div *ngIf="user_role == 'arc_pro' || appData.get('enable_insights')" class="option_selection" [ngClass]="appData.get('app_name') == 'model' ? 'active': ''" (click)="filterAccessInsights('model')">
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4zm2 2H5V5h14v14zm0-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
                    Models
                </div>
            </div>
            <div *ngIf="user_role == 'arc_pro'" class="nav_buttons flex">
                <button style="padding-left: 22px;" (click)="insightSurvey()" class="blue outline mr25 mt12">
                    Feedback
                </button>
                <button (click)="createModel()" class="blue outline mt12">
                    <img class="plus_icon" src="../../../assets/images/plus.svg">
                    Create a model
                </button>
            </div>
        </div>

        <div *ngIf="['model', 'home', 'market'].indexOf(appData.get('insight_app')) == -1" id="model_name" class="height-100-perc insight_nav">
            <div class="flex">
                <div>
                    <h4 class="mr15 mb5 fw-400 fs17">{{appData.get('buildingData_insight').name}}, {{appData.get('cs_fullname')}}, {{appData.get('buildingData_insight').zip_code}}</h4>
                    <div>
                        <span class="fs13 gray_color_707479">
                            {{appData.get('buildingData_insight').leed_id}} - {{appData.get('selected_version').version_name}} <span class="current_version_label" *ngIf="appData.get('buildingData_insight').version == appData.get('selected_version').version_id">Current Version</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <app-insight-home *ngIf="appData.get('insight_app') == 'home'"></app-insight-home>
        <app-insight-market *ngIf="appData.get('insight_app') == 'market'"></app-insight-market>
        <app-insight-model *ngIf="appData.get('insight_app') == 'model'"></app-insight-model>
        <app-insight-reduction *ngIf="appData.get('insight_app') == 'reduction'"></app-insight-reduction>
        <app-insight-strategies *ngIf="appData.get('insight_app') == 'strategies'"></app-insight-strategies>
        <app-insight-comparison *ngIf="appData.get('insight_app') == 'comparison'"></app-insight-comparison>
        <app-insight-zepi-score *ngIf="appData.get('insight_app') == 'zepi-score'"></app-insight-zepi-score>

        <app-insight-manage *ngIf="appData.get('insight_app') == 'manage'"></app-insight-manage>
        <app-insight-team *ngIf="appData.get('insight_app') == 'team'"></app-insight-team>
    </div>
</div>

<div data-toggle="modal" data-backdrop="static" data-keyboard="false" class="feedback_modal insight_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" [ngClass]="loading ? 'not-active' : ''">
        <app-loader *ngIf="loading"></app-loader>
        <div class="modal-content upload_modal modal_radius gotham">

            <div class="modal-body pl30 modal_padding fontSize19 pb25 pr30" style="overflow:auto;">
                
                <div [ngClass]="loading ? 'not-active' : ''">
                    <app-loader *ngIf="loading"></app-loader>
                    
                    <div class="insight_header">
                        <div class="fs20 fw-600">
                            Feedback
                        </div> 
                        <div>
                            <svg (click)="cancel()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                        </div>
                    </div>
                
                    <div class="insight_content description">
                        <div *ngFor="let option of survey_options; let idx = index;">
                            <div *ngIf="idx == survey_screen">
								<p *ngIf="!option.splash_screen" class="">Question {{idx}}</p>
								<p class="mt15 fontSize15">{{option.title}} <span *ngIf="option.is_required" class="error-color">*</span></p>
								<div *ngIf="!option.splash_screen && option.options != undefined" class="survey_icons">
									<div (click)="selectFace(face, option.options)" [ngClass]="face.status ? 'active' : ''" class="align-center {{face.class}}_div" *ngFor="let face of option.options">
										<i class="far fa-{{face.class}}"></i>
										<p class="fontSize14">{{face.title}}</p>
									</div>
								</div>
					
								<div *ngIf="option.form != undefined" class="">
									<div class="checkbox">
										<input (input)="checkBoxListner(survey_permission)" [(ngModel)]="survey_permission" type="checkbox" name="survey_permission">
										<label>Yes</label>
									</div>
									<div class="flex" [ngClass]="!survey_permission ? 'not-active' : ''">
										<div class="mr10">
											<label>E-Mail <span class="error-color">*</span></label>
											<input class="tenant_inp" (input)="isSurveyCorrect()" id="survey_email" placeholder="E-Mail" type="text">
										</div>
										<div class="mr10">
											<label>Phone <span class="error-color">*</span></label>
											<input class="tenant_inp" (input)="isSurveyCorrect()" id="survey_phone" placeholder="Phone" type="text">
										</div>
										<div class="mr10">
											<label>Organization <span class="error-color">*</span></label>
											<input class="tenant_inp" (input)="isSurveyCorrect()" id="survey_organization" placeholder="Organization Name" type="text">
										</div>
									</div>
					
									<div *ngIf="!isSurveyCorrect()" class="show ui error message">
										<ul class="list pl10 mb0">
											<li *ngIf="survey_form_error['email']">Please enter a valid email</li>
											<li *ngIf="survey_form_error['phone']">Please enter a valid phone number</li>
											<li *ngIf="survey_form_error['organization']">Please enter an organization name</li>
										</ul>
									</div>
								</div>
					
								<div *ngIf="option.comments != undefined" class="form">
									<div class="field">
										<label class="fw-500">Comments:</label>
										<textarea [(ngModel)]="option.comments" style="height: 112px;"></textarea>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div class="insight_footer align-right">
                        <div (click)="changeSurvey(1)" class="pl20 pr20 btn btn-primary">
                            {{ survey_screen == survey_options.length - 1 ? 'Submit' : survey_options[survey_screen].button_text }}
                            <svg *ngIf="survey_screen != survey_options.length - 1" width="18" height="18" viewBox="0 0 24 24" style="fill: white;top: 4px;position: relative;">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
</div>
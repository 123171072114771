<app-sidebar [sidebar_type]="'notifications'"></app-sidebar>

<div class="content-wrap">
	<div id="content" class="content">

        <div>
            <nav class="page-controls navbar_info navbar-default">
                <div class="flex">
                    <h3>Notifications</h3>
                    <button *ngIf="appData.get('user_role') != 'arc_pro'" (click)="global.filterAccess('app.arc_pro_purchase')" class="mt15 ml20 arc_pro_button btn btn-default">
                        <div class="arc_logo_racetrack"></div>
                        <a class="fw-500 fs14">Upgrade my account</a>
                    </button>
        
                    <div *ngIf="appData.get('user_role') == 'arc_pro'" class="arc_essential_label mt20 ml20">
                        <div class="arc_logo_racetrack"></div>
                        <span class="fw-500 fs15">{{config.arc_pro_label}}</span>
                    </div>
        
                    <div *ngIf="appData.get('is_gsa_user')" class="arc_essential_label mt20 ml20">
                        <div class="arc_gsa_logo"></div>
                        <span class="fw-500 fs15">{{config.arc_gsa_label}}</span>
                    </div>
                </div>
            </nav>
        
            <div class="widget_container">
                <app-widget id="arcnotification_all" [src]="'arcnotification_all'" [param]="null" [width]="'100%'" [height]="'375px'"></app-widget>
            </div>
        </div>

    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';
import { CreditListService } from 'src/app/services/credit-service.service';

import { Config } from 'src/app/models/env-vars.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-measure-form',
  templateUrl: './measure-form.component.html',
  styleUrls: ['./measure-form.component.css']
})
export class MeasureFormComponent implements OnInit
{
	config = Config;
	leed_id: number = this.appData.get('leed_id');
	measure: any = {};

	measure_list: any = [];
	loader = false;
	points_pursued = 0;
	points_awarded = 0;
	measure_activities: any = [];

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData,
		private alertService: alertService,
		private CreditService: CreditListService,
		private router: Router
	)
	{

	}

	ngOnInit(): void
	{
		this.loader = true;
		this.measure = this.appData.get('measure');
		this.appData.set('credit', {
			'CreditShortId': this.measure.measure_id,
			'CreditStatus': '',
			'AssignedTo': '',
			'IvAttchPolicy': '',
			'IvReqFileupload': '',
		});
		this.config.currentUpload = false;
		this.config.currentUploadName = ';';

		var measure_id = this.measure.measure_id;
		var measure_response = "/assets/LEED:" + this.leed_id + "/measures/" + measure_id + "/";
		this.api.get(measure_response).subscribe(
			//success handler
			response =>
			{
				this.CreditService.getMeasureList(measure_id, this.measure.user_version.version).subscribe(
					data =>
					{
						this.loader = false;
						var measure_response_data = response;
						var list = this.constructTree(data.measure_list, measure_response_data);
						this.measure_list = list;
						this.points_pursued = this.count_points(0, this.measure_list);
					},
					data =>
					{
						this.loader = false;
						this.measure_list = "";
					}
				);
			},
			//error handler
			response =>
			{
				this.CreditService.getMeasureList(measure_id, this.measure.user_version.version).subscribe(
					data =>
					{
						this.loader = false;
						var measure_response_data = response;
						var list = this.constructTree(data.measure_list, measure_response_data);
						this.measure_list = list;
						this.points_pursued = this.count_points(0, this.measure_list);
					},
					data =>
					{
						this.loader = false;
						this.measure_list = "";
					}
				);
			}
		);

		this.measureActivity();
		
		this.global.getUploadedFiles(this.measure.measure_id);
	}

	getOptionLabel(measure_id: string)
	{

		if(
			measure_id == "A5" || 
			measure_id == "A7" ||
			measure_id == "A9" ||
			measure_id == "A10" ||
			measure_id == "A11" ||
			measure_id == "A13" ||
			measure_id == "A14" ||
			measure_id == "A15" ||
			measure_id == "A16" ||
			measure_id == "B4" ||
			measure_id == "B5" ||
			measure_id == "B10" ||
			measure_id == "B11" ||
			measure_id == "B12" ||
			measure_id == "C17" ||
			measure_id == "C18"


			){
			return "option";
		}              

		return "option(s)";
	};

	// this.$on('loading:finish', function (state, response)
	// {
	// 	try
	// 	{
	// 		if(response.config.method != 'GET')
	// 		{
	// 			this.measureActivity();    
	// 		}
	// 	}
	// 	catch(e)
	// 	{}
	// });

	measureActivity()
	{
		this.CreditService.getDataInputActivity(this.measure.measure_id).subscribe(
			//success handler
			data => {
				this.measure_activities = data;
			}, 
			//error handler
			data =>{
			}
		);
	};

	downloadMeasure(measure: any)
	{
		this.global.anchorDownload(measure.measure_id + " - " + measure.name, "../../../assets/json-v2/parksmart/measure_pdf/" + measure.measure_id + ".pdf");
	};

	getChildrenObject(list: any, child_list: any)
	{
		for(var i = 0; i < child_list.length; i++)
		{
			for(var a = 0; a < list.length; a++)
			{
				if(list[a].parent != null && child_list[i].id == list[a].id)
				{
					if(list[a].children != null)
					{
						list[a].children = this.getChildrenObject(list, list[a].children);
					}
					child_list[i] = list[a]
					list.splice(a, 1)
				}
			}
		}

		return child_list;
	};

	getParent(option: any, list: any): any
	{
		if(option.parent != null)
		{
			for(var a = 0; a < list.length; a++)
			{
				if(list[a].id == option.parent)
				{
					return list[a];
				}
				else if(list[a].children != null)
				{
					var result = this.getParent(option, list[a].children);
					if(result != undefined)
						return result
				}
			}   
		}
		else
		{
			return option.parent;
		}
	};

	constructTree(list: any, measure_data: any)
	{
		for(var a = 0; a < measure_data.length; a++)
		{
			for(var b = 0; b < list.length; b++)
			{
				if(list[b].id == measure_data[a].measure_id)
				{
					if(measure_data[a].content_type == 'checkbox')
					{
						measure_data[a].data = (measure_data[a].data == 'true');
					}

					list[b]['data'] = measure_data[a].data;
					list[b]['key'] = measure_data[a].id;
					list[b].hidden = false;
					
					if(list[b].allow_toggle == true && list[b].children != null)
					{
						list[b].collapsed_children = list[b]['data'] ? false : true;
					}
					break;
				}
			}
		}

		for(var i = 0; i < list.length; i++)
		{
			if(list[i].children != null)
			{
				list[i].children = this.getChildrenObject(list, list[i].children);
			}
		}
		return list;
	}

	count_points(points_pursued: any, measure_list: any)
	{
		for(var a = 0; a < measure_list.length; a++)
		{
			if(measure_list[a].content_type == 'checkbox' && measure_list[a].data == true)
			{
				var points_parse = measure_list[a].points;
				if(typeof(points_parse) == 'string')
				{
					points_parse = parseInt(points_parse.split(" - ")[1]);
				}
				points_pursued += isNaN(points_parse) || points_parse == null ? 0 : points_parse;
			}
			if(measure_list[a].children != null)
			{
				points_pursued = this.count_points(points_pursued, measure_list[a].children);
			}
		}

		if(points_pursued > this.measure.max_points)
		{
			points_pursued = this.measure.max_points;
		}
		if(points_pursued < 0)
		{
			points_pursued = 0;
		}
		return points_pursued;
	}

	changeMeasure(option: any, parent: any)
	{
		if(option.content_type == 'checkbox')
		{
			option.data = (<any>document.getElementById(option.id)).checked;
		}
		var version = this.measure.user_version.version;

		var payload = {
			"measure_id": option.id,
			"content_type": option.content_type,
			"data": String(option.data)
		}
		var measure_id = this.measure.measure_id;
		var points_pursued = this.count_points(0, this.measure_list);
		parent.loader = true;
		
		if(option["key"] == undefined)
		{
			this.api.post('/assets/LEED:'+ this.leed_id +'/measures/'+ measure_id +"/?points_pursued=" + points_pursued, payload).subscribe(
				//success handler
				response =>{
					this.measureActivity();
					var data = response;
					option["key"] = data.id;
					parent.loader = false;
					if(option.allow_toggle)
					{
						option.collapsed_children = !option.collapsed_children;
					}
					else
					{
						return;
					}
					
					this.points_pursued = points_pursued;
				},
				//error handler
				response =>{
					
				}
			);
		}
		else
		{
			let payload = {
				"measure_id": option.id,
				"data": String(option.data)
			}
			this.api.put('/assets/LEED:'+ this.leed_id +'/measures/'+ measure_id +"/?points_pursued=" + points_pursued, payload).subscribe(
				//success handler
				response =>{
					this.measureActivity();
					var data = response;
					option["key"] = data.id;
					parent.loader = false;
					if(option.allow_toggle)
					{
						option.collapsed_children = !option.collapsed_children;
					}
					else
					{
						return;
					}
					
					this.points_pursued = points_pursued;
				},
				//error handler
				response =>{
					
				}
			);
		}
	};

	checkView(option: any)
	{
		if( option != undefined && option.parent != null)
		{
			var parent = this.getParent(option, this.measure_list);
			if(parent == undefined)
				return
		}
		else
			return
		
		return parent.collapsed_children;
	};

	measureScreenChange(direction: string)
	{
		if(direction == 'next')
		{
			for(var i = 0; i < this.appData.get('all_measures').length; i++)
			{
				if(this.appData.get('all_measures')[i].measure_id == this.appData.get('measure').measure_id)
				{
					if(i == this.appData.get('all_measures').length - 1)
					{
						this.appData.set('measure', this.appData.get('all_measures')[0]);
					}
					else
					{
						this.appData.set('measure', this.appData.get('all_measures')[i + 1]);
					}
					break;
				}
			}
		}
		else if(direction == 'previous')
		{
			for(var i = 0; i < this.appData.get('all_measures').length; i++)
			{
				if(this.appData.get('all_measures')[i].measure_id == this.appData.get('measure').measure_id)
				{
					if(i == 0)
					{
						this.appData.set('measure', this.appData.get('all_measures')[this.appData.get('all_measures').length - 1]);
					}
					else
					{
						this.appData.set('measure', this.appData.get('all_measures')[i - 1]);
					}
					break;
				}
			}
		}
		this.ngOnInit();
	}
}

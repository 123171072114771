<div class="content-wrap">
    <main id="content" class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="" style="    top: -160px;">
        <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
            <div class="align-center mt11 width-100-perc">
                <h4 class="fw-500">Project Setup</h4>            
            </div>
            <div (click)="global.goBack()" class="pt20">
                <svg class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
            </div>
        </nav>
        <div class="">
           
            <main id="payment-details" class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="">
                <div class="payment-card" [ngClass]="saving_project ? 'not-active' : ''">
                    <div id="payment-options-form">
                        <div class="limited-access">
                            <i class="icon-calendar mb20"></i>
                            <h3 class="page-title bold mb20">Preview Access</h3>
                            <h4 class="page-title fw-400 mb20 fontSize20">90 Days</h4>
                            <div (click)="selectTrial()" class="btn btn-success mb50">
                                <span class="fw-600">Preview Access</span>
                            </div>
                            <div>
                                <p class="fw-600 mb20 fontSize16">Preview the Arc Platform:</p>
                                <div class="arc-list green fontSize16">
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>Generate a total performance score</label>
                                    </div>
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>View and benchmark all category scores</label>
                                    </div>
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>Import historic data</label>
                                    </div>
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>Assess your performance readiness for LEED v4.1</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="full-access">
                            <i class="icon-shopping-cart mb20"></i>
                            <h3 class="page-title bold mb20">Full Access</h3>
                            <h4 class="page-title fw-400 mb20 fontSize20">
                                {{project.project_type == 'building' || project.project_type == 'school' ? '1 - 5 Years' : '5 Years'}}
                            </h4>
                            <div (click)="goToPayment(project)" class="btn btn-primary mb50">
                                <span class="fw-600">Pay Now</span>
                            </div>
                            <div>
                                <p class="fw-600 mb20 fontSize16">Full access to the Arc platform:</p>
                                <div class="arc-list blue fontSize16">
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label *ngIf="appData.get('buildingData').project_type != 'city' && appData.get('buildingData').project_type != 'community'">Pursue LEED Recertification</label>
                                        <label *ngIf="appData.get('buildingData').project_type == 'city' || appData.get('buildingData').project_type == 'community'">Pursue LEED Certification</label>
                                    </div>
                                    <div class="arc-list-item" *ngIf="project.project_type != 'city' && project.project_type != 'community'">
                                        <div class="list-dot"></div>
                                        <label>Access score analytics and improvement metrics</label>
                                    </div>
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>Add multiple team members</label>
                                    </div>
                                   <div class="arc-list-item">
                                        <div class="list-dot"></div>
                                        <label>View and publish performance score animation</label>
                                    </div>
                                    <div class="arc-list-item" *ngIf="project.project_type != 'city' && project.project_type != 'community'">
                                        <div class="list-dot"></div>
                                        <label>View historic score trends</label>
                                    </div>
                                    <div class="arc-list-item" *ngIf="project.project_type != 'city' && project.project_type != 'community'">
                                        <div class="list-dot"></div>
                                        <label>Leverage our data integration partners for deeper insights</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
    
        </div>
    </main>
</div>
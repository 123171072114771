import { Component, OnInit } from '@angular/core';
import { AppData } from 'src/app/services/appdata.service';

@Component({
  selector: 'app-admin-tool',
  templateUrl: './admin-tool.component.html',
  styleUrls: ['./admin-tool.component.css']
})
export class AdminToolComponent implements OnInit
{
	constructor(
		private appData: AppData
	)
	{

	}
	ngOnInit(): void
	{
		this.appData.set('app_type', "admin");
	}

}

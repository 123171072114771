import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.css']
})
export class MeasuresComponent implements OnInit
{
	config = Config;
	leed_id: number = this.appData.get('leed_id');
	type: string = '';
	loading: boolean = false;
	all_measures: any = [];
	
	constructor(
		public global: Global,
		public appData: AppData,
		private route: ActivatedRoute,
		private api: API
	)
	{
		this.route.queryParams.subscribe((p: any) => 
		{
			this.type = p.type;
		});
	}

	ngOnInit(): void
	{
		this.loading = true;
		this.type = this.global.getURLFromParam('type') != undefined ? this.global.getURLFromParam('type') : 'allmeasures';

		this.api.get(
			'/assets/LEED:' + this.leed_id + '/measures/?page_size=0'
		).subscribe(
			data =>
			{
				this.loading = false;
				this.appData.set('all_measures', data);
				this.all_measures = data;
			},
			error =>
			{
				this.loading = false;
			}
		)
	}

	getHeading()
	{
		if(this.type == 'allmeasures')
		{
			return 'All Measures';
		}
		else if(this.type == 'management')
		{
			return 'Management';
		}
		else if(this.type == 'programs')
		{
			return 'Programs';
		}
		else if(this.type == 'technology')
		{
			return 'Technology & Structure Design';
		}
		else if(this.type == 'innovation')
		{
			return 'Innovation';
		}
	}

	clickAction(measure: any)
	{
		this.appData.set('measure', measure);
        this.global.goToApp("selected_project", 
		{
			"leed_id": this.leed_id,
			"app_name": "measure-form"
		});
	}

	showRow(obj: any): boolean
	{
		if(this.type == 'allmeasures')
		{
			return true;
		}
		else if(this.type == 'management' && obj.category == 'Management')
		{
			return true;
		}
		else if(this.type == 'programs' && obj.category == 'Programs')
		{
			return true;
		}
		else if(this.type == 'technology' && obj.category == 'Technology & Structure Design')
		{
			return true;
		}
		else if(this.type == 'innovation' && obj.category == 'Innovation')
		{
			return true;
		}
		return false;
	}

}

<div class="hub-dialog">
    <app-loader *ngIf="isLoading" [app]="'hub'"></app-loader>

    <div class="hub-dialog-close">
        <svg [ngClass]="isLoading ? 'not-active' : ''" (click)="close()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
    </div>

    <div *ngIf="page == 1" class="hub-dialog-title">
        Here are some of the upgrades
    </div>
    <div *ngIf="page == 2" class="hub-dialog-title">
        In the new version you'll find...
    </div>

    <div class="hub-dialog-content">
        <ng-container *ngIf="page == 1">
            <div class="hub-dialog-content-row bottom-align">
                <img class="right-align" src="../../../assets/images/hub/infographic_one.svg">
                <img src="../../../assets/images/hub/infographic_two.svg">
            </div>
            <div class="hub-dialog-content-row">
                <img class="right-align" src="../../../assets/images/hub/infographic_three.svg">
                <img src="../../../assets/images/hub/infographic_four.svg">
            </div>
        </ng-container>

        <ng-container *ngIf="page == 2">
            <div class="hub-dialog-content-features">
                <div class="hub-dialog-content-features-item" *ngFor="let feature of hubFeatures">
                    <div *ngIf="feature.label" class="hub-dialog-content-features-item-icon">
                        <img src="../../../assets/images/hub/{{feature.label}}" >
                    </div>
                    <div class="hub-dialog-content-features-item-label">
                        <div class="hub-dialog-content-features-item-label-name">{{feature.name}}</div>
                    </div>
                </div>
            </div>

            <div class="hub-dialog-content-features-coming-soon">
                <div class="hub-dialog-content-features-coming-soon-header">
                    Features coming soon
                </div>
                <div class="hub-dialog-content-features-coming-soon-wrapper">
                    <div class="hub-dialog-content-features-coming-soon-wrapper-item" *ngFor="let feature of hubFeaturesComingSoon">
                        <div *ngIf="feature.label" class="hub-dialog-content-features-coming-soon-wrapper-item-icon">
                            <img src="../../../assets/images/hub/{{feature.label}}" >
                        </div>
                        <div class="hub-dialog-content-features-coming-soon-wrapper-item-label">
                            <div class="hub-dialog-content-features-coming-soon-wrapper-item-label-name">{{feature.name}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="coming-soon-footer">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49993 1.42856C3.9752 1.42856 1.9285 3.47526 1.9285 5.99999C1.9285 8.52472 3.9752 10.5714 6.49993 10.5714C9.02466 10.5714 11.0714 8.52472 11.0714 5.99999C11.0714 3.47526 9.02466 1.42856 6.49993 1.42856ZM0.785645 5.99999C0.785645 2.84408 3.34402 0.285706 6.49993 0.285706C9.65584 0.285706 12.2142 2.84408 12.2142 5.99999C12.2142 9.1559 9.65584 11.7143 6.49993 11.7143C3.34402 11.7143 0.785645 9.1559 0.785645 5.99999ZM5.9285 3.71428C5.9285 3.39869 6.18434 3.14285 6.49993 3.14285H6.50564C6.82124 3.14285 7.07707 3.39869 7.07707 3.71428C7.07707 4.02987 6.82124 4.28571 6.50564 4.28571H6.49993C6.18434 4.28571 5.9285 4.02987 5.9285 3.71428ZM5.35707 5.99999C5.35707 5.6844 5.61291 5.42856 5.9285 5.42856H6.49993C6.81552 5.42856 7.07136 5.6844 7.07136 5.99999V7.71428C7.38695 7.71428 7.64279 7.97011 7.64279 8.28571C7.64279 8.6013 7.38695 8.85713 7.07136 8.85713H6.49993C6.18434 8.85713 5.9285 8.6013 5.9285 8.28571V6.57142C5.61291 6.57142 5.35707 6.31558 5.35707 5.99999Z" fill="#356FE3"/>
                </svg>
                    
                You can switch back to legacy Arc at any time.
            </div>
        </ng-container>
    </div>

    <div class="hub-dialog-footer" [ngClass]="isLoading ? 'not-active' : ''">
        <div class="hub-dialog-footer-page">
            <button *ngIf="page == 2" (click)="setPage(1)" class="hub-button flat mr24">
                Back
            </button>
            <span>{{page}} of 2</span>
        </div>
        <div class="hub-dialog-footer-action">
            <button *ngIf="page == 1" (click)="setPage(2)" class="hub-button flat">
                Next
            </button>
            <button *ngIf="page == 2" class="hub-button" (click)="switchToHub()">
                Switch to the new version
            </button>
        </div>
    </div>
</div>
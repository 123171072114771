
<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div>
            <h1 class="page-title pl0 pb10">Recompute Improvement Scores<span class="fw-semi-bold"></span></h1>
            <section class="widget">
                <header>
                    <h5 class="mb10">Please provide details below to get the improvement scores data of the project</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset [ngClass]="loader ? 'not-active' : ''">
                            <div class="form-group mb10">
                                <label for="normal-field" class="col-sm-2 control-label">LEED ID</label>
                                <div class="col-sm-7">
                                    <input [(ngModel)]="leed_id" type="text" id="normal-field" class="form-control"
                                        placeholder="LEED ID" [ngModelOptions]="{standalone: true}">
                                </div>
                                <ul class="wizard display-block">
                                    <li class="next list_style_none">
                                        <button (click)=getInfo() class="pull-left mr10 btn btn-default"><i
                                                class="fa fa-search" aria-hidden="true"></i> Recompute</button>
                                        <div *ngIf="loader" class="dataInputLoaderSpinner pull-left"></div>
                                    </li>
                                </ul>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
        <div>

            <div class="clearfix">
                <ul id="tabs1" class="nav nav-tabs pull-left w100p">
                    <li class="active"><a data-target="#tab1" data-toggle="tab" aria-expanded="true">Energy</a>
                    </li>
                    <li class=""><a data-target="#tab2" data-toggle="tab" aria-expanded="false">Water</a>
                    </li>
                    <li class=""><a data-target="#tab3" data-toggle="tab" aria-expanded="false">Waste</a>
                    </li>
                    <li class=""><a data-target="#tab4" data-toggle="tab" aria-expanded="false">Transportation </a>
                    </li>
                    <li class=""><a data-target="#tab5" data-toggle="tab" aria-expanded="false">Human Experience </a>
                    </li>
                </ul>
            </div>
            <div id="tabs1c" class="tab-content mb-lg">
                <!-- Energy Score -->
                <div class="tab-pane clearfix active" id="tab1">
                    <div *ngIf="!energyArray.length" class="emptyData">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        No Energy Data
                    </div>

                    <table *ngIf="energyArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of energyArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Energy Score -->

                <!-- Water Score -->
                <div class="tab-pane" id="tab2">
                    <div *ngIf="!waterArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Water Data</div>

                    <table *ngIf="waterArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of waterArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Water Score -->

                <!-- Waste Score -->
                <div class="tab-pane" id="tab3">
                    <div *ngIf="!wasteArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Waste Data</div>

                    <table *ngIf="wasteArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="w40p align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of wasteArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Waste Score -->

                <!-- Transport Score -->
                <div class="tab-pane" id="tab4">
                    <div *ngIf="!transportArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Transport Data</div>

                    <table *ngIf="transportArray.length" class="table table-hover no-footer">
                        <thead>
                            <tr role="row">
                                <th class="w60p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left w40p" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of transportArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Transport Score -->

                <!-- Human Experience Score -->
                <div class="tab-pane" id="tab5">
                    <div *ngIf="!humanExperienceArray.length" class="emptyData"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i> No Human Experience Data</div>

                    <table *ngIf="humanExperienceArray.length" class="table no-footer">
                        <thead>
                            <tr role="row">
                                <th class="" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                    aria-label="Id: activate to sort column descending">Attribute</th>
                                <th class="align-left" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                    colspan="1" aria-label="Name: activate to sort column ascending">Value</th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                                <th rowspan="1" colspan="1"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of humanExperienceArray">
                            <tr>
                                <td class="fw-600 cursor-pointer fs15">
                                    {{item.attr}}
                                </td>
                                <td class="fw-semi-bold align-left fs15">
                                    {{item.value}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Human Experience Score -->
            </div>
        </div>
    </div>
</div>
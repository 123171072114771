import { Component, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';
import * as moment from 'moment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit
{
	config = Config;
	loading: boolean = true;
	total_reports: number = 0;

	reports: any = [];
	userprofile: any = {};
	user_preference: any = {};

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void 
	{
		this.api.get('/auth/usgbc/userprofile/').subscribe(
            response =>
            {
                this.loading = false;
                this.userprofile = response;
                if(this.appData.get('profileimg') == null && this.userprofile.profileimg != null)
                {
                    this.appData.set('profileimg', this.userprofile.profileimg);
                }
            },
            response =>
            {
                this.loading = false;
            }
        );

        this.api.get('/auth/user/certifications/').subscribe(
            response =>
            {
                var certs = response.certificates;
                var reports = response.reports;
                var total = [];
				let obj: any;

                for(var n in certs)
                {
                    obj = {
                        'name': certs[n].name,
                        'type': certs[n].certification_level,
                        'date': certs[n].date_certified,
                        'id': certs[n].certificate,
                        'source': 'certificate',
                        'leed_id': certs[n].leed_id
                    };
                    total.push(obj);
                }
                for(var n in reports)
                {
                    obj = {
                        'name': reports[n].name,
                        'type': 'Project Report',
                        'date': reports[n].uploaded_at,
                        'id': reports[n].id,
                        'source': 'S3',
                        'leed_id': reports[n].leed_id
                    };
                    total.push(obj);
                }
                this.total_reports = total.length;
                this.reports = total.slice(0, 5);
            },
            response =>
            {

            }
        );

		this.getPreference();
	}

	getVal(val: any): boolean
	{
		if(val == undefined || val == null || val == '')
		{
			return false;
		}
		return true;
	}

	editProfile()
	{
		window.open(Config.usgbc_basic_url + '?destination=/account');
	}

	dateFormat(date: string)
	{
		return moment(date, "MM/DD/YYYY").format('MMM DD, YYYY')
	}

	getPreference()
	{
		this.user_preference = {
			"gfa_unit": "sqft",
			"electricity_unit": "kbtu",
			"water_unit": "gal",
			"waste_unit": "lbs",
			"transport_unit": "miles"
		};
		this.api.get('/auth/user/preference/').subscribe(
			//success handler
			response =>
			{
				if(response.length > 0)
				{
					this.user_preference = response[0];
				}
			},
			//error handler
			response =>
			{
				
			}
		);
	}

	changePreference(key: string, value: any)
	{
		this.loading = true;
		var data: any = {};
		data[key] = value;
		this.api.post('/auth/user/preference/', data).subscribe(
			//success handler
			response =>
			{
				this.loading = false;
				this.user_preference[key] = value;
				this.appData.set('unit_preference', this.user_preference);
			},
			//error handler
			response =>
			{
				this.loading = false;
			}
		);
	}

	downloadCert(doc_id: string, source: string, leed_id: string)
	{
		var url = Config.basic_api_url + "/assets/LEED:" + leed_id + "/documents/download/?doc_id=" + doc_id + "&doc_source="+ source +"&access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		var name   = "Cerfiticate.pdf";
		this.global.anchorDownload(name, url);
	}

}

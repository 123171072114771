<div class="content-wrap">
    <div id="content" class="content arc_japan_invoice">
        <h1 class="page-title pl0 align-center pb10">Arc Japan Invoices</h1>
        <div class="row">
            <app-loader *ngIf="loading"></app-loader>
            <div [ngClass]="loading ? 'not-active' : ''">
                <section class="widget pl0">
                    <div class="widget-body">
                        <form class="form-horizontal pt0 mb20" role="form">
                            <fieldset>
                                <div class="form-group">
                                    <label for="normal-field" class="col-sm-2 control-label">Search</label>
                                    <div class="col-sm-7">
                                        <input autocomplete="off" id="search_leed_id" [(ngModel)]="query"
                                            (input)="searchInvoices(query)" type="text" class="form-control"
                                            placeholder="Order ID" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <div class="w100p align-right mb20">
                            <button class="btn action-button btnGreen ccc_border" data-dismiss="modal"
                                (click)='exportInvoices()'>Export all invoices</button>
                        </div>
                        <table class="table table-striped no-footer">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Order Type</th>
                                    <th>Price</th>
                                    <th>Submitted on</th>
                                    <th>Settled on</th>
                                    <th>Invoice</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="requested_data.length == 0">
                                    <td height="50" class="align-center vertical-align-middle" colspan="4">
                                        No invoices found
                                    </td>
                                </tr>
                                <tr *ngFor="let data of requested_data">
                                    <td height="50" class="vertical-align-middle">
                                        {{data.sap_order_id}}
                                    </td>
                                    <td height="50" class="vertical-align-middle">
                                        <span [innerHtml]="data.type"></span>
                                    </td>
                                    <td height="50" class="vertical-align-middle">
                                        ¥{{global.addCommas(data.amount)}}
                                    </td>
                                    <td height="50" class="vertical-align-middle">
                                        <span class="fs13 gray_color_707479">Invoice generated on</span><br>
                                        {{global.dateTimeFormat(data.created_at)}}
                                    </td>
                                    <td *ngIf="data.settled_at != null" height="50" class="vertical-align-middle">
                                        <span class="fs13 gray_color_707479">Payment cleared on</span><br>
                                        {{global.dateTimeFormat(data.settled_at)}}
                                    </td>
                                    <td *ngIf="data.settled_at == null" height="50"
                                        class="danger-color vertical-align-middle">
                                        Pending
                                    </td>
                                    <td height="50" class="vertical-align-middle">
                                        <a (click)="downloadFile(data.invoice)">Download</a>
                                    </td>
                                    <td height="50" class="vertical-align-middle">
                                        <a *ngIf="data.settled_at == null" (click)="selectPayment(data)">Clear
                                            Payment</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="clearpayment fade modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
    <div class="modal-dialog modal-md">
        <div class="modal-content p50 modal_radius">
            <p class="fs16 line_height_24 mb16 align-center">
                Mark this invoice as payment complete?
            </p>
            <div class="mt30 align-center">
                <button class="btn mr20 action-button btnGreen ccc_border" data-dismiss="modal"
                    (click)='clearPayment()'>Yes</button>
                <button class="btn action-button btnGreen ccc_bordere" type="button" data-dismiss="modal">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div id="payment-details-wrapper">
    <app-loader *ngIf="loading"></app-loader>
    <div class="content-wrap">
        <div id="content" class="content">

            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;" *ngIf="appData.get('current_app') == 'forms'">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500" translate>Add a Project</h4>            
                </div>
                <div class="pt20">
                    <svg (click)="global.goBack(go_back)" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>
            
            <main id="payment-details" class="view-animate fade-up overflow_y_initial project_create" role="main" data-ui-view="">
                <div class="payment-card" [ngClass]="loading ? 'not-active' : ''">
                    <h4 class="fw600 fontSize20">{{title}}</h4>
                    
                    <form id="details-form" (ngSubmit)="verifyDetails()">
                        <div class="flex">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody class="table-margin-20">
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.name.error ? 'has-error' : ''">      
                                                <input name="name" autocomplete="off" maxlength="40" (input)="verifyField(formdata.name, 'name')" [(ngModel)]="formdata.name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Project Name</label>
                                                <span class="error">{{formdata_error.name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div *ngIf="formdata.project_type != 'parksmart'" class="checkbox test_project pl20">
                                                <input name="test_project" [(ngModel)]="formdata.test_project" type="checkbox" value="test_project">                
                                                <label for="checkbox" translate>
                                                    This is a test project
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="mode == 'project_creation'">
                                        <td colspan="4">
                                            <div *ngIf="formdata.project_type == 'school'" class="select-group group">
                                                <label class="selected_val" translate>Project Type</label>   
                                                <select name="project_type" [(ngModel)]="formdata.project_type">
                                                    <option value="school">Buildings - School</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                            </div>
                                            <div *ngIf="formdata.project_type != 'school'" class="select-group group" [ngClass]="formdata_error.project_type.error ? 'has-error' : ''">   
                                                <label class="blank" [ngClass]="formdata.project_type != 'null' ? 'selected_val' : ''" translate>Project Type</label>   
                                                <select name="project_type" (change)="projectTypeChange()" [(ngModel)]="formdata.project_type">
                                                    <option value="community">Communities</option>
                                                    <option value="city">Cities</option>
                                                    <option value="building">Buildings</option>
                                                    <option value="transit">Buildings - Transit</option>
                                                    <option value="parksmart">Buildings - Parking (Parksmart)</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.project_type.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="mode == 'project_add_portfolio'">
                                        <td colspan="4">
                                            <div class="select-group pb5 group">
                                                <label class="selected_val" translate>Project Type</label>   
                                                <select name="project_type" [(ngModel)]="formdata.project_type">
                                                    <option value="building">Buildings</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr *ngIf="mode == 'project_add_portfolio'">
                                        <td class="pb30" colspan="4">
                                            <span class="fontSize12 grey" translate>
                                                Only Building/School project types can be added to a Portfolio
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.noOfParkingSpace.error ? 'has-error' : ''">      
                                                <input name="noOfParkingSpace" (input)="verifyField(formdata.noOfParkingSpace, 'noOfParkingSpace')" [(ngModel)]="formdata.noOfParkingSpace" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Number of parking spaces</label>
                                                <span class="error">{{formdata_error.noOfParkingSpace.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.noOfParkingLevels.error ? 'has-error' : ''">      
                                                <input name="noOfParkingLevels" ng-init="enableFdatePicker()" (input)="verifyField(formdata.noOfParkingLevels, 'noOfParkingLevels')" [(ngModel)]="formdata.noOfParkingLevels" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>How many levels in your parking structure?</label>
                                                <span class="error">{{formdata_error.noOfParkingLevels.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr [hidden]="(formdata.project_type != 'parksmart')">
                                        <td colspan="4">
                                            <div [ngInit]="enableFdatePicker()" class="group" [ngClass]="formdata_error.year_constructed.error ? 'has-error' : ''">      
                                                <input name="year_constructed" id="year_constructed" (input)="verifyField(formdata.year_constructed, 'year_constructed')" [(ngModel)]="formdata.year_constructed" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Date Commissioned</label>
                                                <span class="error">{{formdata_error.year_constructed.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="select-group group" [ngClass]="formdata_error.station_type.error ? 'has-error' : ''">   
                                                <label [ngClass]="formdata.station_type != undefined ? 'selected_val' : ''" translate>Is the station</label>   
                                                <select name="station_type" (change)="verifyField(formdata.station_type, 'station_type')" [(ngModel)]="formdata.station_type">
                                                    <option value="underground">Underground</option>
                                                    <option value="aboveground">Above ground</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.station_type.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.annual_ridership.error ? 'has-error' : ''">      
                                                <input name="annual_ridership" float-input (blur)="calculateWeeklyOccupancy(formdata.annual_ridership, formdata.operating_hours, formdata.full_time_staff, formdata.time_spent_by_riders)" (input)="verifyField(formdata.annual_ridership, 'annual_ridership')" [(ngModel)]="formdata.annual_ridership" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>What is the annual ridership for this station?</label>
                                                <span class="error">{{formdata_error.annual_ridership.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.operating_hours.error ? 'has-error' : ''">      
                                                <input name="operating_hours" float-input (blur)="calculateWeeklyOccupancy(formdata.annual_ridership, formdata.operating_hours, formdata.full_time_staff, formdata.time_spent_by_riders)" (input)="verifyField(formdata.operating_hours, 'operating_hours')" [(ngModel)]="formdata.operating_hours" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Weekly Operating hours</label>
                                                <span class="error">{{formdata_error.operating_hours.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.full_time_staff.error ? 'has-error' : ''">      
                                                <input name="full_time_staff" float-input (blur)="calculateWeeklyOccupancy(formdata.annual_ridership, formdata.operating_hours, formdata.full_time_staff, formdata.time_spent_by_riders)" (input)="verifyField(formdata.full_time_staff, 'full_time_staff')" [(ngModel)]="formdata.full_time_staff" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Full time staff at station</label>
                                                <span class="error">{{formdata_error.full_time_staff.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.time_spent_by_riders.error ? 'has-error' : ''">      
                                                <input name="time_spent_by_riders" float-input (blur)="calculateWeeklyOccupancy(formdata.annual_ridership, formdata.operating_hours, formdata.full_time_staff, formdata.time_spent_by_riders)" (input)="verifyField(formdata.time_spent_by_riders, 'time_spent_by_riders')" [(ngModel)]="formdata.time_spent_by_riders" type="text" required />
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Average time spent by riders at the station (in minutes. Max: 1,440)</label>
                                                <span class="error">{{formdata_error.time_spent_by_riders.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'transit'">
                                        <td colspan="4">
                                            <div class="group not-active">      
                                                <input name="occupancy" float-input (input)="calculateWeeklyOccupancy(formdata.annual_ridership, formdata.operating_hours, formdata.full_time_staff, formdata.time_spent_by_riders)" [(ngModel)]="formdata.occupancy" type="text" required [readonly]="true"/>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Weighted Daily Occupancy</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="group pb5 pr20" [ngClass]="formdata_error.gross_area.error ? 'has-error' : ''">      
                                                <input name="gross_area" float-input id="gross_area" (input)="verifyField(formdata.gross_area, 'gross_area')" [(ngModel)]="formdata.gross_area" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Gross Area</label>
                                                <span class="error">{{formdata_error.gross_area.help}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="select-group pb5 group" [ngClass]="formdata_error.unitType.error ? 'has-error' : ''">   
                                                <label class="blank" [ngClass]="formdata.unitType != undefined ? 'selected_val' : ''" translate>Unit Type</label>   
                                                <select (change)="verifyField(formdata.unitType, 'unitType')" [(ngModel)]="formdata.unitType" name="unitType">
                                                    <option *ngIf="!(formdata.project_type =='city' || formdata.project_type =='community')" value="IP">square feet</option>
                                                    <option *ngIf="!(formdata.project_type =='city' || formdata.project_type =='community')" value="SI">square meters</option>
                                                    <option *ngIf="formdata.project_type =='city' || formdata.project_type =='community'" value="IP">square miles</option>
                                                    <option *ngIf="formdata.project_type =='city' || formdata.project_type =='community'" value="SI">square kilometers</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.unitType.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td [hidden]="!(formdata.unitType == undefined || formdata.project_type == 'null')" class="pb30" colspan="4">
                                            <span class="fontSize12 grey" translate>
                                                Enter total built area
                                            </span>
                                        </td>
                                        <td [hidden]="!(formdata.unitType != undefined && formdata.project_type != 'null')" class="pb30" colspan="4">
                                            <span [hidden]="formdata.project_type == 'parksmart'" class="fontSize12 grey">
                                                Enter total built area in {{global.getUnit(formdata.unitType, formdata.project_type)}} (Max: <span class="fw-400">{{global.addCommas(global.getMax(formdata.unitType, formdata.project_type))}}</span> {{global.getUnit(formdata.unitType, formdata.project_type)}})
                                            </span>
                    
                                            <span [hidden]="(formdata.project_type != 'parksmart')" class="fontSize12 grey">Enter total parking structure area (Max: <span class="fw-400">{{global.addCommas(global.getMax(formdata.unitType, formdata.project_type))}}</span> {{global.getUnit(formdata.unitType, formdata.project_type)}})
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type =='city' || formdata.project_type =='community'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.occupancy.error ? 'has-error' : ''">      
                                                <input float-input (input)="verifyField(formdata.occupancy, 'occupancy', true)" [(ngModel)]="formdata.occupancy" type="text" required name="occupancy">
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Population</label>
                                                <span class="error">{{formdata_error.occupancy.help}}</span>
                                                <span class="grey fontSize12">Enter the total population (optional)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'building' || formdata.project_type =='city' || formdata.project_type =='community'">
                                        <td colspan="4">
                    
                                            <div  [ngClass]="{'has-error' : formdata_error.IsLeedRegistration.error }" class="radio_buttons group pt5 pb45">
                                                <span class="head fs16 lh30" translate>Are you registering for a LEED Certification?</span>
                                                <div class="flex mt10">
                                                    <div class="mr25">
                                                        <span class="checkcontainer" (click)="registerLEEDOrNonLEED('yes')">
                                                            Yes
                                                            <input type="radio" value="true" [(ngModel)]="formdata.IsLeedRegistration" name="IsLeedRegistration">
                                                            <span class="radiobtn"></span>
                                                        </span>
                                                    </div>
                    
                                                    <div>
                                                        <span class="checkcontainer" (click)="registerLEEDOrNonLEED('no')">
                                                            No
                                                            <input type="radio" value="false" [(ngModel)]="formdata.IsLeedRegistration" name="IsLeedRegistration">
                                                            <span class="radiobtn"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="fontSize12 grey pt10" *ngIf="opted_for_non_leed" translate>This project has to register for LEED Certification later by going to the certifications page in Arc.</div>
                                                <div class="error">{{formdata_error.IsLeedRegistration.help}}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school'">
                                        <td colspan="4">
                                            <div class="select-group group" [ngClass]="formdata_error.spaceType.error ? 'has-error' : ''">   
                                                <label class="blank" [ngClass]="formdata.spaceType != undefined ? 'selected_val' : ''" translate>Space Type</label>   
                                                <select (change)="verifyField(formdata.spaceType, 'spaceType')" [(ngModel)]="formdata.spaceType" name="spaceType">
                                                    <option *ngFor="let type of spaceType" value="{{type}}">{{type}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.spaceType.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school' || formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                            <div class="select-group group" [ngClass]="formdata_error.ownerType.error ? 'has-error' : ''">   
                                                <label class="blank" [ngClass]="formdata.ownerType != undefined ? 'selected_val' : ''" translate>Owner Type</label>   
                                                <select (change)="verifyField(formdata.ownerType, 'ownerType')" [(ngModel)]="formdata.ownerType" name="ownerType">
                                                    <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.ownerType.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school' || formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.organization.error ? 'has-error' : ''">      
                                                <input name="organization" id="organization" (input)="searchOrganization(formdata.organization)" [(ngModel)]="formdata.organization" type="text" required autocomplete="off">
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>
                                                    Owner Organization
                                                </label>
                                                <span class="error">{{formdata_error.organization.help}}</span>
                                                
                                                <span class="">(<span (click)="openModal()" class="cursor-pointer"><a >Click here</a></span> to add new organization if not existing.)</span>
                    
                                                <div [hidden]="!(searchingOrganization && all_organizations.length > 0)" class="searchDropdownOrganization">
                                                    <div *ngFor="let org_name of all_organizations" class="projectPort_row" (click)="selectOrganization(org_name);">
                    
                                                        <div class="cursor-pointer vertical_align_middle">          
                                                            <span>{{org_name}}</span>
                                                        </div>
                    
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school' || formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                             <div class="group" [ngClass]="formdata_error.owner_email.error ? 'has-error' : ''">      
                                                 <input name="owner_email" (input)="verifyField(formdata.owner_email, 'owner_email')" [(ngModel)]="formdata.owner_email" type="text" required>
                                                 <span class="highlight"></span>
                                                 <span class="bar"></span>
                                                 <label translate>Owner Email</label>
                                                 <span class="error">{{formdata_error.owner_email.help}}</span>
                                             </div>
                                         </td>
                                     </tr>
                                     <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school' || formdata.project_type == 'parksmart'">
                                        <td colspan="4">
                                             <div class="group" [ngClass]="formdata_error.owner_name.error ? 'has-error' : ''">      
                                                 <input name="owner_name" (input)="verifyField(formdata.owner_name, 'owner_name')" [(ngModel)]="formdata.owner_name" type="text" required>
                                                 <span class="highlight"></span>
                                                 <span class="bar"></span>
                                                 <label translate>Owner Representative</label>
                                                 <span class="error">{{formdata_error.owner_name.help}}</span>
                                             </div>
                                         </td>
                                     </tr>
                                     <tr *ngIf="formdata.project_type == 'building' || formdata.project_type == 'school' || formdata.project_type == 'parksmart'">
                                         <td colspan="4">
                                             <div class="select-group group" [ngClass]="formdata_error.manageEntityCountry.error ? 'has-error' : ''">   
                                                 <label class="blank" [ngClass]="formdata.manageEntityCountry != undefined ? 'selected_val' : ''" translate>Owner Country/Region</label>   
                                                 <select (change)="verifyField(formdata.manageEntityCountry, 'manageEntityCountry')" [(ngModel)]="formdata.manageEntityCountry" name="manageEntityCountry">
                                                     <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                                 </select>
                                                 <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                 <span class="highlight"></span>
                                                 <span class="bar"></span>
                                                 <span class="error">{{formdata_error.manageEntityCountry.help}}</span>
                                             </div>
                                         </td>
                                     </tr>
                                    <tr>
                                        <td>
                                            <div class="checkbox pl20">
                                                <input [(ngModel)]="formdata.confidential" type="checkbox" value="confidential" name="confidential">                
                                                <label for="checkbox" translate>
                                                    This project is private                
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class= "parksmart_image">
                                <div *ngIf="formdata.project_type == 'parksmart'">
                                    <img src="../../assets/images/parksmart.jpg">
                                </div>
                            </div>
                        </div>
                        <span class="fw-600 fontSize20" translate>Enter Project Location</span>
                        <div class="desktop_flex">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="pt20">
                                            <div class="group" [ngClass]="formdata_error.street.error ? 'has-error' : ''">   
                                                <input class="address_dropdown" maxlength="60" (focus)="checkDropdown()" (input)="getPlace(formdata.street)" [(ngModel)]="formdata.street"  type="text" required name="street">
                                                <div *ngIf="searching_place" class="address_loader">
                                                    <svg class='circular-loader' viewBox='25 25 50 50'><circle class='loader-path' cx='50' cy='50' r='20' fill='none' stroke='#949EAB' stroke-width='5' /></svg>
                                                </div>
                                                <ul class="dropdown-menu address normal dropdown-menu-fixed address_dropdown">
                                                    <li (click)="selectPlace(place)" *ngFor="let place of places" class="water_unit">
                                                        <a>
                                                            <span class="place_name fw-500">{{place.name}}</span> - 
                                                            <span class="formatted_address">{{place.formatted_address}}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Address</label>
                                                <span class="error">{{formdata_error.street.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="group" [ngClass]="formdata_error.city.error ? 'has-error' : ''">      
                                                <input maxlength="40" (blur)="getGeocode()" (input)="verifyField(formdata.city, 'city')" [(ngModel)]="formdata.city" type="text" required name="city">
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>City</label>
                                                <span class="error">{{formdata_error.city.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="width50p" colspan="1">
                                            <div *ngIf="formdata.project_type != 'school'" class="select-group group pr20" [ngClass]="formdata_error.country.error ? 'has-error' : ''">   
                                                <label [ngClass]="formdata.country != undefined ? 'selected_val' : ''" translate>Country/Region</label>   
                                                <select name="country" (change)="updateCountry(formdata.country)" [(ngModel)]="formdata.country">
                                                    <option *ngFor="let country of countries" [ngValue]="country.code">{{country.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.country.help}}</span>
                                            </div>
                                            <div *ngIf="formdata.project_type == 'school'" class="select-group group">
                                                <label class="selected_val">Country/Region</label>   
                                                <select [(ngModel)]="formdata.country" name="country">
                                                    <option value="US">United States</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                            </div> 
                                        </td>
                                        <td colspan="2">
                                            <div class="select-group group" [ngClass]="formdata_error.state.error ? 'has-error' : ''">   
                                                <label [ngClass]="formdata.state != undefined ? 'selected_val' : ''" translate>State</label>   
                                                <select name="state" (change)="verifyField(formdata.state, 'state')" [(ngModel)]="formdata.state" (input)="getGeocode()">
                                                    <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <span class="error">{{formdata_error.state.help}}</span>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="width50p project_zip_code group pr20" [ngClass]="formdata_error.zip_code.error ? 'has-error' : ''">      
                                                <input name="zip_code" maxlength="10" (blur)="getGeocode()" (input)="verifyField(formdata.zip_code, 'zip_code')" [(ngModel)]="formdata.zip_code" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Zip Code</label>
                                                <span class="error">{{formdata_error.zip_code.help}}</span>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr class="hide900">
                                        <td colspan="2">
                                            <div class="checkbox pl20">
                                                <input name="sign_agreement" style="width: initial;" [(ngModel)]="sign_agreement" type="checkbox" value="confidential">
                                                <label *ngIf="formdata.project_type != 'parksmart'" for="checkbox" translate>
                                                    I agree to the terms and conditions of the 
                                                </label>
                                                <label *ngIf="formdata.project_type == 'parksmart'" for="checkbox" translate>
                                                    I agree to the terms of the 
                                                </label>
                                            </div>
                                            <span (click)="global.openLink(global.getServiceAgreementUrl(), true)" class="agreement_tag reg" translate>service agreement</span>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr class="hide900">
                                        <td colspan="2">
                                            <div (click)="verifyDetails()" class="pl20 pr20 btn btn-primary" [ngClass]="!sign_agreement || loading ? 'not-active' : ''">
                                                <div *ngIf="loading">
                                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                </div>
                                                <span>{{project_detail_nxt_btn}}</span>
                                            </div>
                                            <p *ngIf="submit_error" class="fw-600 danger-color mt10" translate>Please provide valid data</p>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                    
                            <div class="geolocation-picker">
                                <span class="fw-600 fontSize20">
                                    <svg style="fill: #949EAB;" width="30" height="30" viewBox="0 0 24 24">
                                        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>                            
                                    <span class="label_location" style="color: #949EAB;" translate>Geo Location (Optional)</span>
                                </span>
                                <p class="fw-400 black-color label_location_help" translate>Addresses are great, but dropping a pin is even better! Help us make sure we’ve got your project's exact location.</p>
                                <p class="fw-600 black-color pt20 mb25 pl10">Option 1 - <span class="agreement_tag" (click)="showMap()">Find location on a map</span></p>
                                <p class="fw-600 black-color pl10" translate>Option 2 - <span>Manually enter location data</span></p>
                                <div class="flex pl10">
                                    <div class="group pr20 mt10 pb0">      
                                        <input name="geoLat" [(ngModel)]="formdata.geoLat" type="text" required>
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label translate>Latitude</label>
                                    </div>
                                    <div class="group pr20 mt10 pb0">      
                                        <input name="geoLang" [(ngModel)]="formdata.geoLang" type="text" required>
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label translate>Longitude</label>
                                    </div>
                                </div>
                                <p class="fw-400"></p>
                            </div>
                    
                            <table class="show900">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            <div class="checkbox pl0">
                                                <input name="sign_agreement" style="width: initial;" [(ngModel)]="sign_agreement" type="checkbox" value="confidential">
                                                <label *ngIf="formdata.project_type != 'parksmart'" for="checkbox" translate>
                                                    I agree to the terms and conditions of the 
                                                </label>
                                                <label *ngIf="formdata.project_type == 'parksmart'" for="checkbox" translate>
                                                    I agree to the terms of the 
                                                </label>
                                            </div>
                                            <span (click)="global.openLink(global.getServiceAgreementUrl(), true)" class="agreement_tag reg" translate>service agreement</span>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div (click)="verifyDetails()" class="pl20 pr20 btn btn-primary" [ngClass]="!sign_agreement ? 'not-active' : ''">
                                                <div *ngIf="loading">
                                                    <div class="dataInputLoaderSpinner-white dataInputLoaderSpinner dataInputLoaderSpinner-sm"></div>
                                                </div>
                                                <span>{{project_detail_nxt_btn}}</span>
                                            </div>
                                            <p *ngIf="submit_error" class="fw-600 danger-color mt10" translate>Please provide valid data</p>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    
                    </form>
                </div>
            </main>
            
            <div id="payment_owner_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p30 modal_radius width-100-perc">
                        
                        <h4 translate>Add Owner Organization</h4>
            
                        <form name="organizationForm">           
                            <fieldset>
                                <section class="widget pt20" style="margin-bottom: 0px !important;padding: 15px 0px;"> 
                                    <table style="border: none;" class="width-100-perc">
                                        <thead>
                                            <tr>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgNamePError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Organization Name:</div>
                                                        <input name="orgNameP" (input)="validateOrgValues(orgNameP, 'orgNameP')"  class="form-control" maxlength="100" type="text" [(ngModel)]="orgNameP">
            
                                                        <span class="floatl width-100-perc error_message mb0 help-block required-field" *ngIf="(orgNamePError)">This field is required.</span>
                                                        
                                                        <span class="floatl error_message help-block required-field" *ngIf='(isFormSubmittedOwner)'>{{regex.nameMsg}}</span>
            
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgContactNamePError ? 'has-error': ' ' " class="mt10 mb10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Owner's Representative(Employee or Officer of Owner):</div>
                                                        <input (input)="validateOrgValues(orgContactNameP, 'orgContactNameP')" class="form-control" maxlength="100" type="text" [(ngModel)]="orgContactNameP" name="orgContactNameP">
                                                        
                                                        <span class="floatl error_message help-block required-field" *ngIf="(orgContactNamePError)">This field is required.</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgContactEmailPErrorLength || orgContactEmailPError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Owner Contact Email:</div>
                                                        <input (input)="validateOrgValues(orgContactEmailP, 'orgContactEmailP')"   class="form-control" maxlength="40" type="text" [(ngModel)]="orgContactEmailP" ng-pattern="regex.email" name="orgContactEmailP">
                                                        <span class="mb0 floatl width-100-perc error_message help-block required-field" *ngIf="(orgContactEmailPError)">This field is required.</span>
                                                        
                                                        <span class="floatl error_message help-block required-field" *ngIf='(isFormSubmittedOwner)'>{{regex.emailMsg}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgWebSitePError ? 'has-error': ' ' " class="mb10 mt10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Website (Optional):</div>
                                                        <input (input)="validateOrgValues(orgWebSiteP, 'orgWebSiteP')" class="form-control" maxlength="256" type="text" [(ngModel)]="orgWebSiteP" name="orgWebSiteP">
                                                        
                                                        <span class="floatl error_message help-block" *ngIf="(orgWebSitePError)" translate>Please enter valid web url.</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgCategoryPError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Industry Category:</div>
                                                        
                                                        <select id="orgCategoryP" (change)="resetSubCat(orgCategoryP)" class="form-control" [(ngModel)]="orgCategoryP" name="orgCategoryP">
                                                            <option disabled="disabled" value="_blank">Select Category</option>
                                                            <option value="2">Product Manufacturers</option>
                                                            <option value="3">Utility / Energy Service Companies</option>
                                                            <option value="4">Real Estate Providers</option>
                                                            <option value="5">Professional Societies and Trade Associations</option>
                                                            <option value="6">Nonprofit and Environmental Organizations</option>
                                                            <option value="7">State and Local Governments</option>
                                                            <option value="8">Federal Government</option>
                                                            <option value="9">Professional Firms</option>
                                                            <option value="10">Contractors and Builders</option>
                                                            <option value="12">Educational Institutions</option>
                                                            <option value="13">Corporate and Retail</option>
                                                            <option value="501">Financial Institutions</option>
                                                        </select>    
                                                        <span class="floatl error_message help-block" *ngIf="(orgCategoryPError)" translate>Please select a cateogry.</span>                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <div [ngClass]="orgSubCategoryPError ? 'has-error': ' ' " class="mb10" style="height: 66px;">
                                                        <div class="mb10 font-weight-600" translate>Industry Sub-Category:</div>
                                                        
                                                        <select id="orgSubCategoryP" (change)="resetSubCat2(orgSubCategoryP)" class="form-control" [(ngModel)]="orgSubCategoryP" name="orgSubCategoryP">
                                                            <option *ngIf="orgCategoryP == '_blank'" disabled="disabled" value="_blank">Select Category</option>
            
                                                            <option *ngIf="orgCategoryP == '2'" value="27">PM:Product Manufacturer</option>
                                                            <option *ngIf="orgCategoryP == '2'" value="28">PM:Building Controls</option>
                                                            <option *ngIf="orgCategoryP == '2'" value="61">PM:Distributors</option>
                                                            
                                                            <option *ngIf="orgCategoryP == '3'" value="30">Energy Service Companies</option>
                                                            <option *ngIf="orgCategoryP == '3'" value="31">Utilities</option>
            
                                                            <option *ngIf="orgCategoryP == '4'" value="108">RE:Comm-Brokage and Mgm</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="109">RE:Commerical REITs</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="110">RE: Residential Brokerage  and  Management</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="111">RE:Residential Property Investor</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="112">RE: Institutional Investor</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="113">RE:Private Held Owner</option>
                                                            <option *ngIf="orgCategoryP == '4'" value="114">RE:Service Contractor</option>
            
                                                            <option *ngIf="orgCategoryP == '5'" value="5">Prof Soc/Trade Assn</option>
                                                            <option *ngIf="orgCategoryP == '5'" value="104">TradeGrp and Labor-union</option>
                                                            <option *ngIf="orgCategoryP == '5'" value="105">Advocacy Organization</option>
            
                                                            <option *ngIf="orgCategoryP == '6'" value="100">Charitable.org and Foundation</option>
                                                            <option *ngIf="orgCategoryP == '6'" value="101">Social and Membership org</option>
                                                            <option *ngIf="orgCategoryP == '6'" value="102">Religious</option>
                                                            <option *ngIf="orgCategoryP == '6'" value="103">Other Non-Profit</option>
            
                                                            <option *ngIf="orgCategoryP == '7'" value="62">SLG: State Government</option>
                                                            <option *ngIf="orgCategoryP == '7'" value="63">SLG: Local Government</option>
            
                                                            <option *ngIf="orgCategoryP == '8'" value="8">Federal Government</option>
            
                                                            <option *ngIf="orgCategoryP == '9'" value="32">PF:Accountants</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="33">PF:Architects</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="34">PF:Architects/Engineers</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="35">PF:Attorneys</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="36">PF:Building/LEED Consultant</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="56">PF:Engineers</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="57">PF:Interior Designer</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="58">PF:Landscape Architect</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="59">PF:Planners</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="60">PF:Commissioning Pr</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="74">PF:Residential Designer</option>
                                                            <option *ngIf="orgCategoryP == '9'" value="107">PF:Other</option>
            
                                                            <option *ngIf="orgCategoryP == '10'" value="75">CB:Commercial</option>
                                                            <option *ngIf="orgCategoryP == '10'" value="76">CB:Residential</option>
                                                            <option *ngIf="orgCategoryP == '10'" value="77">CB:Mixed</option>
                                                            <option *ngIf="orgCategoryP == '10'" value="78">CB:Others</option>
            
                                                            <option *ngIf="orgCategoryP == '12'" value="91">Indp/Nonprofit.Education</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="92">E and S-sch:Public</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="93">E and S-sch:Public Charter</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="94">E and S-sch:Indpendent</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="95">C and Uni:2yrs-Instiution</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="96">C and Uni:4yrs-Inst(public)</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="97">C and Uni:4yrs-Inst(private)</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="98">C and Uni:4yrs-grad(public)</option>
                                                            <option *ngIf="orgCategoryP == '12'" value="99">C and Uni:4yrs-grad(private)</option>
            
                                                            <option *ngIf="orgCategoryP == '13'" value="11">Insurance Companies</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="81">Health,Pharma and Biotec</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="83">Retail: Restaurants, Bars and Food Services</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="84">Retail: Grocery, Convenience Store</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="85">Retail: Other</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="86">Technology</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="87">Transportation, Delivery and Warehousing Services</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="89">Other Corporate</option>
                                                            <option *ngIf="orgCategoryP == '13'" value="501">Financial Institution</option>
            
                                                            <option *ngIf="orgCategoryP == '501'" value="501">Financial Institutions</option>
                                                        </select>
                                                        <span class="floatl error_message help-block required-field" *ngIf="(orgSubCategoryPError)">This field is required.</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
            
                                <div class="clearfix" [ngClass]="loading ? 'not-active' : ''">
                                    <div class="btn-toolbar clear-left">
                                        <button  class="floatl btn btn-primary mr25" type="submit" translate (click)="createOrganization()">
                                            ADD
                                        </button> 
                                        <button data-dismiss="modal" type="button" class="floatl btn cancel-btn" translate>
                                            CANCEL
                                        </button>
                                        <div style="margin-top: 7px;" *ngIf="(loading)" class="ml10 mr10 dataInputLoaderSpinner floatl"></div>
                                    </div>
                                    <div class="error_message " *ngIf="(error_org)">{{error_msg_org}}</div>
                                </div>
                            </fieldset>
                        </form>    
            
                    </div>
                </div>
            </div>
            
            <div class="modal fade portfolio-modal-wbg ofac_invalid_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        
                        <div class="modal-header">
                            <span class="fw-600 fs16">Owner organization is blocked</span>
                        </div>
                        <div class="modal-body pl25 mb20">
                            <p class="fw-400">
                                This project is located in a sanctioned country or belongs to a sanctioned entity 
                                according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                                or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                                contact us</a> for more details.
                            </p>  
                            </div>
                        <div class="modal-footer">
                            <button class="floatl btn btn-primary mr10" (click)="resetOrganizationDetails()">Continue</button>
                            <button type="button" class="floatl btn btn-default" (click)="resetOrganizationDetails()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="leed_registration_block" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p30 modal_radius">
                        <p>If you're interested in registering this project for LEED certification, please select the "Register for LEED certification now" button below and you’ll be routed to LEED Online.</p>
                        <p>Otherwise, you can get started now with Arc for free. Just select the "Not now" button below.</p>
                        <p>You can enter your performance data in Arc and choose to register for LEED later. Your performance data will get you most of the way, but you'll need to complete the LEED prerequisites, and possibly other requirements before submitting for certification.</p>
                        <div class="mt30 align-center"> 
                            <button class="btn btn-primary" [disabled]="go_to_LO_flag" (click)="moveToLORegistrationPage('yes')">{{go_to_LO_text}}</button>
                            <button class="btn action-button ccc_border ml10" [disabled]="go_to_LO_flag" (click)="moveToLORegistrationPage('no')">Not now</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<modal #modal>
    <app-organization (close_data)="closeModal()" (success_data)="getDataFromModal($event)"></app-organization>
</modal>

<div id="mapModal" eview_edit="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content upload_modal modal_radius gotham">

            <div style="height: 500px;width: 100%;padding: 10px;" class="modal-body modal_padding">
                <div id="panel">
                    <form action="#"style="margin:0">
                        <input class="form-control input-sm" name="address" style="width:300px;display: inline-block;" type="textbox" [(ngModel)]="mapAddress" placeholder="Enter location">
                        <button (click)="showAddress(mapAddress)" id="geocodeButton" type="submit" class="btn btn-default"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                        <button (click)="saveLatLon()" class="btn btn-default" id="doMapClose"><i class="icon-save" aria-hidden="true"></i> Save</button>
                    </form>
                </div>
                <div style="height:100%;width: 100%;" id="map-canvas">
                
                </div>
            </div>

        </div>
    </div>
</div>
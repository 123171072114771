<div id="arc_help" [ngClass]="loading ? 'not-active' : ''">
    <!-- <app-loader *ngIf="loading"></app-loader> -->
    <div class="header_container">
        <span class="title">{{project.name}}</span>
       <div (click)="cancel()">
            <i class="fa cancel fa-times"></i>
       </div>
    </div>

    <div *ngIf="loading == false" class="p2535">

        <div class="arcp fw-500 mb10 fs17">
            Most recent certification details:
        </div>

        <div class="arcp">
            <span>Certification: </span>
            <span class="fw-500">{{certification.rating_system.name}}</span>
        </div>
        
        <div class="arcp">
            <span>Certification level: </span>
            <span class="fw-500 capitalize">{{certification.certification_level}}</span>
        </div>

        <div class="arcp">
            <span>Certification score: </span>
            <span class="fw-500">{{certification.certification_points}}</span>
        </div>

        <div class="arcp">
            <span>Certification date: </span>
            <span class="fw-500">{{global.dateFormat(certification.date_certified, true)}}</span>
        </div>

        <!-- <arcp ng-if="expire != '-'">
            <span>Certification expiration date: </span>
            <span class="fw-500">{{expire}}</span>
        </arcp> -->
    </div>
</div>
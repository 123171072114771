import { Component, OnInit } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from '../../services/api.service';
import { Projects } from '../../services/projects.service';

import * as moment from 'moment';

@Component({
  selector: 'app-review-success',
  templateUrl: './review-success.component.html',
  styleUrls: ['./review-success.component.css']
})
export class ReviewSuccessComponent implements OnInit {

  constructor(
    public appData: AppData,
    public global: Global,
    private api: API,
    private projects: Projects,
  ) { }

  ngOnInit(): void {
    this.projects.getAssetDetails(this.appData.get('leed_id')).subscribe(
      //success handler
      data => {
        this.appData.set('buildingData', data);
      }
    );
    if (!this.appData.get('reviewData').review_successfully_submitted){
      this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'certifications'})
    }
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-multiple-target-manage',
  templateUrl: './multiple-target-manage.component.html',
  styleUrls: ['./multiple-target-manage.component.css']
})
export class MultipleTargetManageComponent implements OnInit
{
	config = Config;
	@Output() close = new EventEmitter<string>();
	
  loading: boolean = false;
  search_program_status: boolean = false;
  search_program: any = null;
  selected_projets: any = [];
  selected_program: any = [];
  final_selected_program: any = [];
  current_tab: string = '1. Select programs';
  visited: string[] = ['1. Select programs'];
  operation: any = null;
  programs: any = [];
  tabs: string[] = ['1. Select programs', '2. Add/Remove program', '3. Select projects', '4. Summary'];

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{

	}

  getProgram(){
      this.loading = true;
      this.api.get('/advanced-scoring-programs/?page_size=0') .subscribe(
        data => {
            for (var i=0; i < data.length; i++) {
                  this.selected_program.push({
                      "id": data[i].id,
                      "name": data[i].name,
                      "geographic_scope": data[i].geographic_scope,
                      "learn_more_link": data[i].learn_more_link,
                      "selected": false
                  });
              }
              this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  };

  refreshFinalSelectedPrograms(){
    this.final_selected_program = [];
    for (var i = this.selected_program.length - 1; i >= 0; i--) {
        if (this.selected_program[i].selected){
            this.final_selected_program.push(this.selected_program[i]);
        }
    }
  };

  openLearnMoreLink(external_link: any){
    if (external_link != ''){
        window.open(external_link, "_blank");
    }
  };

  addRemoveProgram(program: any, event: any){
    var target = $( event.target );
    if (target.is( "input" )){
        this.refreshFinalSelectedPrograms();
        return;
    }
    if (target.is( "path" ) || target.is( "svg" )){
        this.openLearnMoreLink(program.learn_more_link);
    }
    else{
        program.selected = !program.selected;
    }
    this.refreshFinalSelectedPrograms();
  };

  cancel()
  {
      this.close.emit('close');
  };

  toggleTab(tab: string)
  {
      if(tab == this.current_tab)
      {
          return;
      }
      else if(tab == '1. Select programs')
      {
          this.current_tab = tab;
      }
      else if(tab == '2. Add/Remove program')
      {
          if(this.final_selected_program.length == 0)
          {
              return;
          }
          this.visited.push(tab);
          this.current_tab = tab;
      }
      else if(tab == '3. Select projects')
      {
          if(this.operation == null)
          {
              return;
          }
          this.visited.push(tab);
          this.current_tab = tab;
      }
      else if(tab == '4. Summary')
      {
          if(this.selected_projets.length == 0)
          {
              return;
          }
          this.visited.push(tab);
          this.current_tab = tab;
      }
  };

  toggleType(operation: any)
  {
      this.operation = operation;
  }

  goBack()
  {
      var idx = this.tabs.indexOf(this.current_tab);
      if(idx > 0)
      {
          this.toggleTab(this.tabs[idx - 1]);
      }
  }

  continueProgram()
  {
      if(this.current_tab == '1. Select programs')
      {
          this.toggleTab('2. Add/Remove program');
      }
      else if(this.current_tab == '2. Add/Remove program')
      {
          this.toggleTab('3. Select projects');
      }
      else if(this.current_tab == '3. Select projects')
      {
          this.toggleTab('4. Summary');
      }
  };

  saveProgram()
  {
      var program_ids = [];
      for(var i = 0; i < this.final_selected_program.length; i++)
      {
          program_ids.push(this.final_selected_program[i].id);
      }
      if(this.operation)
      {
          var payload = {
              "program_ids": program_ids,
              "leed_ids": this.selected_projets
          };
          this.loading = true;
          this.api.post('/projects/advanced-scoring-programs/', payload ).subscribe(
            data => {
              this.loading = false;
              this.alertService.alert('success', 'Program(s) added successfully.', 5);
              this.cancel();
            }, error => {
              this.loading = false;
              this.alertService.alert('error', 'Error in adding program(s).', 5);
            }
          );
      }
      else if(this.operation == false)
      {
          this.loading = true;
          var payload = {
              "program_ids": program_ids,
              "leed_ids": this.selected_projets
          };
          this.api.delete('/projects/advanced-scoring-programs/', payload ).subscribe(
            data => {
              this.loading = false;
              this.alertService.alert('success', 'Program(s) removed successfully.', 5);
              this.cancel();
            },
            error => {
              this.loading = false;
              this.alertService.alert('error', 'Error in removing program(s).', 5);
            }
         );
      }
  };

  getSelectedProjects(data: any){
		this.selected_projets = data;
	};

  ngOnInit(): void
	{
    this.getProgram();
	}
}


<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="mb50">
            <h1 class="page-title pl0 mb35">Team Lookup<span class="fw-semi-bold"></span></h1>
            <section class="widget" [ngClass]="loader ? 'not-active' : ''">
                <app-loader *ngIf="loader"></app-loader>
                <div class="widget-body">
                    <div class="arc-pro-add-user">
                        <header>
                            <h5 class="mb10">Provide a user email to view the list of projects that the user have access to. Please use this [<a href="../../assets/pdf/Team-member-lookup.pdf" download>link</a>] guide to learn how to use this tool.</h5>
                        </header>
                        <div class="fs16 fw-500 line_height_24 mb8">
                            Search user 
                        </div>
                        <div class="relative-position">
                            <input autocomplete="off" (focus)="isSearch(true)" (input)="searchUser(search_user)" [(ngModel)]="search_user" placeholder="Search" class="arc-pro-search" [ngModelOptions]="{standalone: true}">
                            <svg class="arc-pro-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            <div *ngIf="search_results.length > 0 && is_search" class="user-list">
                                <div [ngClass]="user.user_role == 'arc_pro' ? 'darkgray' : ''" class="user-item" *ngFor="let user of search_results">
                                    <div class="flex">
                                        <div class="mr10" [innerHtml]="getUser(user, search_user)"></div>
                                        <div *ngIf="user.user_role == 'arc_pro'" class="arc_logo_racetrack"></div>
                                    </div>
                                    <button (click)="selectUser(user)" class="add-user-button">Select</button>
                                </div>
                            </div>
                            <div *ngIf="search_results.length == 0 && is_search && search_user.length > 0 && !searching_user" class="user-list">
                                <div class="user-item align-center fs16 gray_color_707479 block">
                                    No user found
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div *ngIf="projects.length > 0" class="mt30">
                        <div class="align_lr">
                            <div><span class="fw-500">{{projects.length}}</span> Projects for <span class="fw-500">{{selected_user.email}}</span></div>
                            <div>
                                <button (click)="exportData()" class="btn btn-default"><i class="fa fa-download mr5" aria-hidden="true"></i> Export</button>
                            </div>
                        </div>
                        <table class="table table-striped mt20">
                            <thead>
                                <tr>
                                    <th class="bold">Project ID</th>
                                    <th class="bold">Name</th>
                                    <th class="bold">City</th>
                                    <th class="bold">Country</th>
                                    <th class="bold">Project Status</th>
                                    <th class="bold">Organization</th>
                                    <th class="bold">Confidential</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let asset of projects">
                                    <td>{{asset.leed_id}}</td>
                                    <td>{{asset.name}}</td>
                                    <td>{{asset.city}}</td>
                                    <td>{{asset.country}}</td>
                                    <td>{{getStatus(asset.status)}}</td>
                                    <td>{{asset.organization}}</td>
                                    <td>{{asset.confidential == true ? 'Yes' : 'No'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
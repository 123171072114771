import { Component, HostListener, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { Global } from '../services/global.service';
import { API } from '../services/api.service';
import { ValidationService } from '../services/validation.service';
import { PortfoliosService } from '../services/portfolios.service';
import { alertService } from '../services/alert-service.service';

import { Config } from '../models/env-vars.model';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import * as stream from 'getstream';
import * as moment from 'moment';

@Component({
  selector: 'app-portfolios',
  templateUrl: './portfolios.component.html',
  styleUrls: ['./portfolios.component.css']
})
export class PortfoliosComponent implements OnInit
{
	config = Config;

	feed_loader: any = {};
	myportfolios: any = [];
	feeds: any = [];
	org_list: any = [];
	ownerType: any = [];
	countries: any = [];
	formValidation: any = this.validator.regex;

	portfolio_name_message: string = '';
	organization_message: string = '';
	email_message: string = '';
	owner_name_message: string = '';
	upload_gresb_text: string = 'UPLOAD';
	nextPage: any = undefined;
	excelUploadName: any = undefined;
	excelUploadData: any = undefined;

	cancellerOrganization: any = undefined;
	errorOrganization: string = '';
	queryOrganization: string = '';
	all_organizations: any = [];
	loading: boolean = false;
	creating_org: boolean = false;
	name_error: boolean = false;
	uploading_gresb: boolean = false;
	searchingOrganization: boolean = false;
	loading_org_list: boolean = false;
	loading_more_portfolios: boolean = false;
	option_org: boolean = false;
	orgCategory: string = '2';
	org_query: string = '';
	orgSubCategory: string = '27';
	isFormSubmittedOwner: boolean = false;
	orgNameError: boolean = false;
	orgContactNameError: boolean = false;
	orgContactEmailError: boolean = false;
	orgWebSiteError: boolean = false;
	orgSubCategoryError: boolean = false;
	error_org: boolean = false;
	portfolio_name_error: boolean = false;
	organization_error: boolean = false;
	email_error: boolean = false;
	owner_name_error: boolean = false;
	asset_type_error: boolean = false;
	error_msg_org: string = '';
	orgContactEmailErrorLength: boolean = false;
	whole: boolean = false;
	base: boolean = false;
	tenant: boolean = false;
	orgName: string = '';
	orgContactName: string = '';
	orgContactEmail: string = '';
	orgWebSite: string = '';
	portfolio_name: string = '';
	unit_type: string = '';
	owner_type: string = '';
	organization: string = '';
	owner_email: string = '';
	owner_name: string = '';
	owner_country: string = '';

	constructor(
		public global: Global,
		public appData: AppData,
		private portfolio: PortfoliosService,
		private api: API,
		private validator: ValidationService,
		private alertService: alertService
	)
	{

	}

	ngOnInit(): void
	{
		if(this.config.countries == null)
		{
			this.global.fetchCountryStates().subscribe(data =>
			{
				this.config.countries = data;
				this.countries = this.global.getCountryList();
			});
		}
		else
		{
			this.countries = this.global.getCountryList()
		}

		this.api.getAsset(
			'../../assets/json/ownerType.json'
		).subscribe(data => 
		{
			this.ownerType = data;
		});
		this.initFeed('myportfolios');
		this.getPortfolios(false);
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event: any)
	{
		let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
		let max = document.documentElement.scrollHeight;
		if(pos == max )
		{
			if(this.loading_more_portfolios) return;
			if(this.nextPage == null)
			{
				return;
			}
			this.loading_more_portfolios = true;
			this.api.get('/portfolios/?page_size=50&' + this.nextPage).subscribe(
				//success handler
				response =>
				{
					var data = response;
					for(var i in data.results)
					{
						this.myportfolios.push(data.results[i]);
					}
					this.loading_more_portfolios = false;
					if(data.next == null)
					{
						this.nextPage = null;
					}
					else
					{
						this.nextPage = data.next.split('?')[data.next.split('?').length - 1];
					}
				},
				//error handler
				response =>
				{
					this.loading_more_portfolios = false;
				}
			);
		}
	}

	getPortfolios(call_feed: boolean)
	{
		this.portfolio.getPortfolios().subscribe(
			//success handler
			data =>
			{
				this.config.portfolios_count = data.count;
				this.myportfolios = data.results;
				if (data.next == null) 
				{
					this.nextPage = null;
				} else
				{
					this.nextPage = data.next.split('?')[data.next.split('?').length - 1];
				}
				this.loading_more_portfolios = false;
				if(call_feed)
				{
					this.initFeed('myportfolios');
				}
			}, 
			//error handler
			data =>
			{
				this.loading_more_portfolios = false;
			}
		);  
	};
	

	resetExcelSelection()
	{
		this.excelUploadName = undefined;
		this.excelUploadData = undefined;
		(<any>$('.uploadFile')[0]).value = "";
	};

	openModal(class_name: string)
	{
		if(class_name == 'gresb_modal')
		{
			this.whole = false;
			this.base = false;
			this.tenant = false;
			this.portfolio_name = '';
			this.owner_type = this.ownerType[0];
			this.organization = '';
			this.owner_email = '';
			this.owner_name = '';
			this.owner_country = 'US';
		}
		this.resetExcelSelection();
		this.queryOrg("A");
		this.option_org = false;
		$('.' + class_name).modal('show');
	}

	switchView()
	{
		this.option_org = !this.option_org;

		$('#owner_modal').modal('show');  
		this.orgNameError = false;
		this.orgContactNameError = false;
		this.orgContactEmailError = false;
		this.orgContactEmailErrorLength = false;
		this.orgWebSiteError = false;
		this.orgSubCategoryError = false;
		this.orgName = '';
		this.orgContactName = '';
		this.orgContactEmail = '';
		this.orgWebSite = '';
		this.orgCategory = '2';
		this.orgSubCategory = '27';
		this.error_org = false;
		this.error_msg_org = '';
		this.creating_org = false;
	}

	uploadGRESB()
	{
		if(this.excelUploadData == undefined)
		{
			return;
		}

		this.uploading_gresb = true;
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": this.config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};

		var gresb_assettype = '';
		gresb_assettype += this.whole ? 'whole,' : '';
		gresb_assettype += this.base ? 'base,' : '';
		gresb_assettype += this.tenant ? 'tenant,' : '';
		if(gresb_assettype[gresb_assettype.length - 1] == ',')
		{
			gresb_assettype = gresb_assettype.substr(0, gresb_assettype.length - 1);
		}
		var data = this.excelUploadData;
		data.append('owner_type', this.owner_type);
		data.append('organization', this.organization);
		data.append('owner_email', this.owner_email);
		data.append('owner_country', this.owner_country);
		data.append('owner_name', this.owner_name);
		data.append('portfolio_name', this.portfolio_name);
		data.append('unit_type', this.unit_type);
		if(gresb_assettype != '')
		{
			data.append('gresb_assettype', gresb_assettype);
		}

		this.upload_gresb_text = "UPLOADING..."
		
		this.api.post('/portfolios/gresb/excel/', data, custom_basic_config_header).subscribe(
			response =>
			{
				this.resetExcelSelection();
				$('.gresb_modal').modal('hide');
				this.uploading_gresb = false;
				this.upload_gresb_text = "UPLOAD";
				this.myportfolios.unshift(response);
				this.getPortfolios(true);
			},
			response =>
			{
				this.uploading_gresb = false;
				this.upload_gresb_text = "UPLOAD";
				this.alertService.alert('error', 'Error uploading file. Please try again.', 5);
			}
		);
	}

	selectOrg(organization: any)
	{
		this.organization = organization;
		this.organization_error = false;
	}

	createOrganization_gresb()
	{
		this.creating_org = true;
		this.isFormSubmittedOwner = true;
		if(this.isFormSubmittedOwner && (this.orgName == undefined || this.orgName.length == 0))
		{
			this.orgNameError = true;
		}
		else
		{
			this.orgNameError = false;
		}
		if(this.isFormSubmittedOwner && (this.formValidation.email.test(this.orgContactEmail) == false))
		{
			this.orgContactEmailError = true;
		}
		else
		{
			this.orgContactEmailError = false;
		}
		if(this.isFormSubmittedOwner && (this.orgContactName == undefined || this.orgContactName.length == 0))
		{
			this.orgContactNameError = true;
		}
		else
		{
			this.orgContactNameError = false;
		}
		if(this.isFormSubmittedOwner && (this.orgSubCategory == undefined || this.orgSubCategory.length == 0))
		{
			this.orgSubCategoryError = true;
		}
		else
		{
			this.orgSubCategoryError = false;
		}
		
		if(this.isFormSubmittedOwner && (this.orgWebSite.length > 0) && (this.formValidation.weburl.test(this.orgWebSite) == false))
		{
			this.orgWebSiteError = true;
		}
		else
		{
			this.orgWebSiteError = false;
		}
		
		if(this.orgNameError == true || this.orgSubCategoryError == true || this.orgContactEmailError == true || this.orgContactNameError == true || this.orgContactEmailErrorLength == true || this.orgWebSiteError == true)
		{
			this.creating_org = false;
			return 
		}
		
		var postData = 
		{
			orgName:  this.orgName, 
			orgContactName:  this.orgContactName, 
			orgContactEmail: this.orgContactEmail,
			orgWebSite: this.orgWebSite,
			orgCategory: this.orgCategory,
			orgSubCategory: this.orgSubCategory
		};
		
		this.api.post('/assets/searchowner/', postData).subscribe(
			//success handler
			response =>{
				var data = response;
				this.isFormSubmittedOwner = false;
				this.switchView();
				if(data['record_duplicate'] != undefined)
				{
					this.error_org = true;
					this.error_msg_org = data['record_duplicate'];
					return
				}
				$('#owner_modal').modal('hide'); 
				this.error_org = false;
				this.error_msg_org = '';
				this.selectOrg(data.orgName);
				this.org_query = data.orgName;
				this.creating_org = false;
			},
			//error handler
			response =>{
				if(response['record_duplicate'] != undefined)
				{
					this.error_org = true;
					this.creating_org = false;
					this.error_msg_org = response['record_duplicate'];
					return
				}
			}
		);
	}

	validateOrgValues(type: string)
	{
		if(type == 'orgName')
		{
			if(this.isFormSubmittedOwner && (this.orgName == undefined || this.orgName.length == 0))
			{
				this.orgNameError = true;
			}
			else
			{
				this.orgNameError = false;
			}
		}
		
		if(type == 'orgContactEmail')
		{
			if(this.isFormSubmittedOwner && (this.formValidation.email.test(this.orgContactEmail) == false))
			{
				this.orgContactEmailError = true;
			}
			else
			{
				this.orgContactEmailError = false;
			}
		}
		
		if(type == 'orgContactName')
		{
			if(this.isFormSubmittedOwner && (this.orgContactName == undefined || this.orgContactName.length == 0))
			{
				this.orgContactNameError = true;
			}
			else
			{
				this.orgContactNameError = false;
			}
		}
		
		if(type == 'orgWebSite')
		{
			if(this.isFormSubmittedOwner && (this.orgWebSite.length > 0) && (this.formValidation.weburl.test(this.orgWebSite) == false))
			{
				this.orgWebSiteError = true;
			}
			else
			{
				this.orgWebSiteError = false;
			}
		}
	}

	resetSubCat(elem: any)
	{
		this.orgSubCategory = elem.orgCategory;
	}

	validateInputs(type: string, value: string)
	{
		if(type == 'portfolio_name')
		{
			if(!this.formValidation[type].test(value))
			{
				this.portfolio_name_error = true;
				this.portfolio_name_message = this.formValidation[type + "Msg"];
			}
			else
			{
				this.portfolio_name_error = false;
			}	
		}
		else if(type == 'email')
		{
			if(!this.formValidation[type].test(value))
			{
				this.email_error = true;
				this.email_message = this.formValidation[type + "Msg"];
			}
			else
			{
				this.email_error = false;
			}
		}
		else if(type == 'owner_name')
		{
			if(!this.formValidation[type].test(value))
			{
				this.owner_name_error = true;
				this.owner_name_message = this.formValidation[type + "Msg"];
			}
			else
			{
				this.owner_name_error = false;
			}
		}
	}

	checkAssetInput()
	{
		this.asset_type_error = !(this.tenant || this.base || this.whole);
	}

	showOrgList()
	{
		$('.search-list').slideDown(300);
	}

	hideOrgList()
	{
		$('.search-list').slideUp(300);
	}

	queryOrg(organization: string)
	{
		this.validateInputs('organization', organization);
		this.api.get(
			'/assets/searchowner/?q=' + organization + "&page_size=5"
		).subscribe(
			data =>
			{
				this.org_list = data.owner_type;
			}
		)
	}

	removeFeed(portfolio_id: number)
	{
		var url = "/portfolios/myportfolios/notifications/?feed=" + String(portfolio_id);

		this.api.delete(url, {}).subscribe(
			function(response)
			{

			}
		);
	};

	refreshRowData(pf_id: any)
	{
		var url = '/portfolios/ID:'+ pf_id +'/';
		this.api.get(url).subscribe(
			response =>
			{
				for(var i = 0; i < this.myportfolios.length; i++)
				{
					if(this.myportfolios[i].pf_id == pf_id)
					{
						this.myportfolios[i] = response;
						break;
					}
				}
			}
		);
	};

	initFeed(key: string)
	{
		var url = '/portfolios/'+ key +'/notifications/';

		this.api.get(url).subscribe(
			response =>
			{
				response = response;
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				
				for(var i = 0; i < response.activities.length; i++)
				{
					this.feed_loader[response.activities[i].foreign_id] = true;
					this.feeds.push(response.activities[i]);

					var timestamp = response.activities[i].timestamp;
					var today = moment();

					if(Math.abs(today.diff(timestamp, 'days')) > 0)
					{
						this.removeFeed(response.activities[i].foreign_id);
					}
				}

				var subscription = notification.subscribe((data: any) =>
				{
					for(var d = 0; d < data.deleted.length; d++)
					{
						for(var a = 0; a < this.feeds.length; a++)
						{
							if(this.feeds[a].id == data.deleted[d])
							{
								this.refreshRowData(this.feeds[a].foreign_id);
								this.feed_loader[this.feeds[a].foreign_id] = false;
								this.feeds.splice(a, 1);
							}
						}
					}

					if(data.new.length > 0)
					{
						this.feed_loader[data.new[0].foreign_id] = true;
						this.feeds.push(data.new[0]);
					}
				});
			},
			function(error)
			{

			}
		);
	};

	nameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		var data;
		var temp_uploading = [];
		if($(elem)[0].files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
		{
			this.alertService.alert('error', 'Not a valid file type', 10);
			return;
		}
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			data.append('file', $(elem)[0].files[a]);
			this.excelUploadData = data;
		}
		this.excelUploadName = filePath;

		this.name_error = false;
		this.organization_error = !this.formValidation["organization"].test(this.organization);
		this.email_error = !this.formValidation["email"].test(this.owner_email);
		this.owner_name_error = !this.formValidation["owner_name"].test(this.owner_name);
		this.portfolio_name_error = !this.formValidation["portfolio_name"].test(this.portfolio_name);
		this.asset_type_error = !(this.tenant || this.base || this.whole);
	};
}

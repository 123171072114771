import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.css']
})
export class EstimateComponent implements OnInit
{
	@Input() project: any = undefined;
	@Output() close = new EventEmitter<string>();

	constructor()
	{

	}

	ngOnInit(): void
	{

	}

	cancel()
    {
		this.close.emit('close');
    };

}

import { Component, OnInit, ViewChild } from '@angular/core';

import { AppData } from '../../services/appdata.service';
import { Global } from '../../services/global.service';
import { API } from 'src/app/services/api.service';

import { Config } from 'src/app/models/env-vars.model';

import 'moment-timezone';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-re-entry',
  templateUrl: './re-entry.component.html',
  styleUrls: ['./re-entry.component.css']
})
export class ReEntryComponent implements OnInit
{
	config = Config;
	@ViewChild('fm_modal') private fm_modal!: ModalComponent
	@ViewChild('oe_modal') private oe_modal!: ModalComponent

	loading: boolean = false;
	data_given: boolean = false;
	survey_submitted: boolean = false;
	iaq_data_submitted: boolean = false;
	re_entry_scores: any = {};
	fmpolicy: any = {};

	fm_link: string = '';
	oe_link: string = '';
	tenant: string = '';
	group: string | undefined = undefined;
	scope: string | undefined = undefined;

	constructor(
		public global: Global,
		private api: API,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{
		this.generateLink();
		this.getData();
	}

	monthRange()
	{
		return moment(new Date()).subtract(1, 'month').format('MMM DD, YYYY') + " through " + moment(new Date()).subtract(1, 'day').format('MMM DD, YYYY');
	};

	getData()
	{
		this.loading = true;
		var urls = [
			'/assets/LEED:'+ this.appData.get('leed_id') +'/scores/re-entry/',
			'/assets/LEED:'+ this.appData.get('leed_id') +'/survey/fmpolicy/',
		]

		this.api.async(urls).subscribe(
			response =>
			{
				this.re_entry_scores = response[0];
				this.fmpolicy = response[1];
				this.loading = false;

				try
				{
					this.data_given = true;
					if(this.re_entry_scores.facility == null && this.re_entry_scores.iaq == null && this.re_entry_scores.occupant == null)
					{
						this.data_given = false;
					}
				}
				catch(e)
				{}

				this.survey_submitted = !jQuery.isEmptyObject(this.fmpolicy);
				try
				{
					if(this.re_entry_scores.iaq_date.maxima == null || this.re_entry_scores.iaq_date.minima == null)
					{
						this.iaq_data_submitted = false;
					}
					else
					{
						this.iaq_data_submitted = true;
					}
				}
				catch(e)
				{
					this.iaq_data_submitted = false;
				}
			}
		);
	};

	openDI()
	{
		this.global.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": "data-input",
			"type": 're_entry'
		});
	};

	cancel()
	{
		this.fm_modal.close();
	};

	cancelOE()
	{
		this.oe_modal.close();
	}

	generateLink()
	{
		this.fm_link = window.location.origin + '/app/project/' + this.appData.get('leed_id') + '/re-entry-survey/?key=' + this.appData.get('buildingData').key + "&type=fm";

		if(this.group == undefined || this.scope == undefined)
		{
			this.oe_link = '';
			return;
		}
		this.oe_link = window.location.origin + '/app/project/' + this.appData.get('leed_id') + '/re-entry-survey/?key=' + this.appData.get('buildingData').key + "&type=oe&scope=" + this.scope + "&group=" + this.group;
		if(this.tenant != undefined)
		{
			this.oe_link += "&tenant=" + this.tenant;
		}
	};

	copyLink(url: string)
	{
		var text = url;
		navigator.clipboard.writeText(text).then(function()
		{
		},
		function(err)
		{
		});

		$('.copy_notif').fadeIn();
		setTimeout(function()
		{
			$('.copy_notif').fadeOut();
		}, 2500);
	};

	openFM()
	{
		this.fm_modal.open({ windowClass: 'estimate_modal' })
	}

	openOE()
	{
		this.oe_modal.open({ windowClass: 'estimate_modal' })
	}

}
